import { FC } from 'react';
import { Tooltip } from '@/Components';
import { useTranslation } from 'react-i18next';
import { useFormikContext } from 'formik';
import { IAlternativeUnit1 } from './interfaces';
import { WeightInput } from '@/Components/Inputs/WeightInput';
import { useAlternativeUnit1Memorizations } from './Hooks/useAlternativeUnit1Memorizations';

export const AlternativeUnit1: FC<IAlternativeUnit1> = ({
  product,
  disabledForm,
  defineOriginalUnit,
  unidadeAlternativaList,
}) => {
  const { t } = useTranslation();
  const form: any = useFormikContext();

  const { dropdownLeft, dropdownRight } = useAlternativeUnit1Memorizations({
    unidadeAlternativaList,
  });

  return (
    <Tooltip
      title={`${t('products.create.originalAlternativeUnit1')} ${
        product && product.materiaPrima
          ? defineOriginalUnit()?.originalUnitAlternative1
          : ''
      }`}
      showMe={
        product &&
        !disabledForm &&
        (product.materiaPrima?.quantidadeUnidadeAlternativa1 !==
          form?.values?.informacaoTecnica?.quantidadeUnidadeAlternativa1 ||
          product.materiaPrima?.unidadeAlternativaId1 !==
            form?.values?.informacaoTecnica?.unidadeAlternativaId1 ||
          product.materiaPrima?.unidadeAlternativaConversaoId1 !==
            form?.values?.informacaoTecnica?.unidadeAlternativaConversaoId1)
      }
    >
      <WeightInput
        name="informacaoTecnica.quantidadeUnidadeAlternativa1"
        label={[
          {
            children: t('supply.lot.create.alternativeUnitOne'),
          },
        ]}
        placeHolder={'999'}
        dropdownLeft={dropdownLeft}
        dropdownRight={dropdownRight}
        disabled={disabledForm}
        withStatusIcon={!!product}
      />
    </Tooltip>
  );
};
