import { FETCH_LIST_PURCHASE_ORDER } from '@/ControllerApiHook/UniqIds/Purchase/PurchaseOrderKeys';
import { useControllerQueryListApiHook } from '@/ControllerApiHook/Controller';
import { PurchaseAPI } from '@/Data/API/Purchase/PurchaseOrderApi';

export const useListCallAPI = () => {
  const {
    data: purchaseOrderList,
    isLoading: isLoadingList,
    refetch: refetchPurchaseOrderList,
  } = useControllerQueryListApiHook({
    uniqId: FETCH_LIST_PURCHASE_ORDER,
    entityApi: PurchaseAPI.getPurchaseOrderList,
    pagination: {
      sorter: { column: 'sequenciaGroupTenant', direction: 'DESC' },
    },
  });

  return {
    purchaseOrderList,
    isLoadingList,
    refetchPurchaseOrderList,
  };
};
