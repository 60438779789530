import { useTranslation } from 'react-i18next';
import { history } from '@/Store';
import { PermissionEnum } from '@/Data/Interfaces/model/permissionEnum';
import { IPurchaseOrderListData } from '@/Data/Interfaces/response/Purchase/IPurchaseOrderResponse';
import { TypePurchaseOrderEnum } from '@/Data/Interfaces/model/typePurchaseOrderEnum';

export const useListMemorizations = () => {
  const { t } = useTranslation();

  const headerOptions = {
    hasSearch: true,
    searchPlaceholder: t(
      'purchasing.purchaseOrder.list.searchPlaceholder'
    ),
    newButton: {
      onClick: () => history.push('/order/purchase/create'),
      label: 'purchasing.purchaseOrder.newButton',
      permission: {
        permission: PermissionEnum.Compras_PedidoCompra_Cadastrar,
        tooltip: 'noPermission.purchasing.purchaseOrder.create',
      },
    },
    editButton: {
      permission: {
        permission: PermissionEnum.Compras_PedidoCompra_EditarDetalhes,
        tooltip: 'noPermission.purchasing.purchaseOrder.edit',
      },
    },
    deleteButton: {
      permission: {
        permission: PermissionEnum.Compras_PedidoCompra_Excluir,
        tooltip: 'noPermission.purchasing.purchaseOrder.delete',
      },
    },
    nameKey: 'sequenciaGroupTenant',
  };

  const rowsOptions = {
    onRowDoubleClick: (x: IPurchaseOrderListData) =>
      x?.status === TypePurchaseOrderEnum.draft
        ? history.push(`/order/purchase/create/${x.externalId}/draft`)
        : history.push(`/order/purchase/${x.externalId}/details`),
    permission: {
      permission: PermissionEnum.Compras_PedidoCompra_VerDetalhes,
      tooltip: 'noPermission.purchasing.purchaseOrder.detail',
    },
  }

  return {
    headerOptions,
    rowsOptions
  };
};