import { 
  IListPharmaceuticalFormDropdownSimpleData 
} from "@/Data/Interfaces/response/PharmaceuticalForm/IPharmaceuticalFormResponse";
import { IUseMemorizations } from "./interfaces";

export const usePharmaceuticalFormMemorizations = ({
  listPharmaceuticalForm
}: IUseMemorizations) => {
  const items = listPharmaceuticalForm?.data?.map((item: IListPharmaceuticalFormDropdownSimpleData) => {
    return {
      id: item.formaFarmaceuticaExternalId,
      label: item.formaFarmaceuticaDescricao,
      disabledItem: item.seVinculoProduto,
    };
  });

  return { items };
};