import { useState } from 'react';

export const useDosageInputsStates = () => {
  const [unitAbbreviation, setUnitAbbreviation] = useState<string>('');

  const [ratio, setRatio] = useState<string>('');

  return {
    unitAbbreviation,
    setUnitAbbreviation,
    ratio,
    setRatio,
  };
};
