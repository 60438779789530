import { FC } from 'react';
import { InputLabel } from '@/Components/Inputs/LabelTotal';
import { useTranslation } from 'react-i18next';
import { ITotalDiscounts } from './interfaces';
import { useTotalDiscountsMemorizations } from './Hooks/useTotalDiscountsMemorizations';

export const TotalDiscounts: FC<ITotalDiscounts> = ({
  withSelectedRows,
  productsValue,
  purchaseOrder,
  hasProducts,
}) => {
  const { t } = useTranslation();

  const { labelValue, style } = useTotalDiscountsMemorizations({
    withSelectedRows,
    productsValue,
    purchaseOrder,
    hasProducts,
  });

  return (
    <>
      <InputLabel
        labelTitle={t(
          'purchasing.purchaseOrder.create.totalCard.totalDiscounts'
        )}
        labelValue={labelValue}
        customSize={14}
        type="ui-tiny-content"
        style={style}
        labelValueStyle={{ width: '100%' }}
      />
    </>
  );
};
