import { useCallback } from 'react';
import { handleModalError } from '@/Utils/ErrorCodeUtils/ErrorHandlingUtils';
import { App } from 'antd';
import { IUseFunctions } from './interfaces';
import { messageErrorToast } from '@/Utils/MessageErrorToast';

export const useListFunctions = ({ removePacking }: IUseFunctions) => {
  const app = App.useApp();

  const removePackingClassification = useCallback(
    async (selectedRowsOnTable: any[]) => {
      try {
        return await removePacking(
          {
            externalId: selectedRowsOnTable.map(
              (x) => x?.classificacaoEmbalagemFormaFarmaceuticaExternalId
            ),
          },
          { onError: handleModalError(app) }
        );
      } catch (error) {
        messageErrorToast(error);
      }
    },
    [removePacking, app]
  );
  return {
    removePackingClassification,
  };
};
