import { FC } from 'react';
import { TextInput } from '@/Components';
import { useTranslation } from 'react-i18next';
import { useModalBodyCallAPI } from './Hooks/useModalBodyCallAPI';
import { useModalBodyFunctions } from './Hooks/useModalBodyFunctions';

export const CreateModalBody: FC = () => {
  const { t } = useTranslation();

  const { mutateAsync } = useModalBodyCallAPI();

  const { fetchSpecialtiesValidationName } = useModalBodyFunctions({
    mutateAsync,
  });

  return (
    <div style={{ padding: '20px', minWidth: '400px' }}>
      <TextInput
        name="descricao"
        label={t('specialties.create.label')}
        placeHolder={t('specialties.create.placeholder')}
        required
        onChange={fetchSpecialtiesValidationName}
        withoutMarginBottom
      />
    </div>
  );
};
