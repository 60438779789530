export const useFormatUnitNumberOnPurchaseOrderList = () => {
  const formatUnitNumberOnPurchaseOrderList = (value: number) => {
    const formattedValue = value.toFixed(4);

    return formattedValue.replace(/\.(\d+)$/, (_, p1) => {
      const zerosToAdd = 4 - p1.length;
      const zeros = '0'.repeat(zerosToAdd);
      return `.${p1}${zeros}`;
    });
  };

  return { formatUnitNumberOnPurchaseOrderList };
};
