import { useState } from 'react';

export const useEditStates = () => {
  const [
    pharmaceuticalFormExternalIdState,
    setPharmaceuticalFormExternalIdState,
  ] = useState<string>('');

  const [
    pharmaceuticalFormExternalIdChanged,
    setPharmaceuticalFormExternalIdStateChanged,
  ] = useState<string>('');

  return {
    pharmaceuticalFormExternalIdState,
    setPharmaceuticalFormExternalIdState,
    pharmaceuticalFormExternalIdChanged,
    setPharmaceuticalFormExternalIdStateChanged,
  };
};
