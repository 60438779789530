import { IReasonLossListData } from '@/Data/Interfaces/response/Loss/ILossResponse';
import { IUseCreateMemorizations } from './interfaces';

export const useCreateMemorizations = ({
  reasonLossList,
}: IUseCreateMemorizations) => {
  const reasonLossListDropdown = reasonLossList?.data?.map(
    (value: IReasonLossListData) => ({
      id: value.externalId,
      label: `${value.id} - ${value.descricao}`,
    })
  );

  return { reasonLossListDropdown };
};
