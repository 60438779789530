import { useMemo } from 'react';
import { IUseMemorizations } from './interfaces';
import { useTranslation } from 'react-i18next';
import { history } from '@/Store';
import { BodyHandler, TitleHandler } from '../lotListUtils';
import { IDeleteModalTable } from '@/Components/Table';
import { IListStatusLotResponse } from '@/Data/Interfaces/response/Lot/IListLot';
import { lotStatusTypes } from '../lot.columns';

export const useListMemorizations = ({
  selectedRowsOnTable,
  statusList,
}: IUseMemorizations) => {
  const { t } = useTranslation();

  const headerOptions = useMemo(
    () => ({
      searchPlaceholder: t('supply.lot.placeholder.searchPlaceholder'),
      hasSearch: true,
      newButton: {
        onClick: () => history.push('/inventory/lot/create'),
        permission: {
          permission: 2003,
          tooltip: 'noPermission.supply.lot.create',
        },
      },
      deleteButton: {
        permission: {
          permission: 2006,
          tooltip: 'noPermission.supply.lot.delete',
        },
      },
      editButton: {
        permission: {
          permission: 2005,
          tooltip: 'noPermission.supply.lot.statusEdit',
        },
        options: [
          {
            label: t('common.status'),
            isStatusOnly: true,
          },
        ],
      },
      nameKey: 'produto',
    }),
    [t]
  );

  const deleteModal = useMemo(
    (): IDeleteModalTable | undefined => ({
      customEndTitle: t('common.lots'),
      title: TitleHandler(selectedRowsOnTable, t),
      body: BodyHandler(selectedRowsOnTable, t),
      buttons: {
        okButtonColor: 'status-danger-base',
      },
      widthModal: 400,
    }),
    [selectedRowsOnTable, t]
  );

  const rowOptions = useMemo(
    () => ({
      onRowDoubleClick: (x: any) =>
        history.push(`/inventory/lot/${x.externalId}/details`),
      permission: {
        permission: 2002,
        tooltip: 'noPermission.supply.lot.detail',
      },
    }),
    []
  );

  const editOptions = useMemo(() => {
    return statusList?.map((x: IListStatusLotResponse) => ({
      id: x.id,
      status: lotStatusTypes[x.id - 1],
      name: x.descricao,
    }));
  }, [statusList]);

  const editModal = useMemo(
    () => ({
      title:
        selectedRowsOnTable?.length === 1
          ? `${t('modal.editModal.single.title.before')} "${
              selectedRowsOnTable ? selectedRowsOnTable[0]?.produto : ''
            }"`
          : `${t('modal.editModal.many.title.before')} ${
              selectedRowsOnTable?.length
            } ${t('common.lots')}`,
    }),
    [selectedRowsOnTable, t]
  );

  return {
    headerOptions,
    deleteModal,
    rowOptions,
    editOptions,
    editModal,
  };
};
