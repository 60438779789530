import { TFunction } from 'react-i18next';
import { ICannotDeleteModalInfo } from '../ErrorHandlingUtils';

export const cannotDeleteClientWithMovementsInTheSystemProps = (
  t: TFunction<'translation', undefined>
): ICannotDeleteModalInfo => ({
  formName: 'clienteNome',
  oneErrorModal: {
    titleModal: `${t('common.modalCannotDelete.messageTitle')} ${t(
      'client.modalCannotDelete.cannotDeleteClientWithMovementsInTheSystem.oneEntity.messageSubtitle'
    )}`,
    subTitleModal: `${t('common.modalCannotDelete.canNotDelete')} ${t(
      'client.modalCannotDelete.cannotDeleteClientWithMovementsInTheSystem.oneEntity.messageSubtitle'
    )}`,
    reasonTranslation: t(
      'client.modalCannotDelete.cannotDeleteClientWithMovementsInTheSystem.oneEntity.reasonTranslation'
    ),
    warningModal: t(
      'client.modalCannotDelete.cannotDeleteClientWithMovementsInTheSystem.warning'
    ),
  },
  manyErrorsModal: {
    entity: t(
      'client.modalCannotDelete.cannotDeleteClientWithMovementsInTheSystem.manyEntity.couldNotDelete'
    ),
    complement: t(
      'client.modalCannotDelete.cannotDeleteClientWithMovementsInTheSystem.clients'
    ),
    subTitleModal: t(
      'client.modalCannotDelete.cannotDeleteClientWithMovementsInTheSystem.manyEntity.messageSubtitle'
    ),
    reasonTranslation: t(
      'client.modalCannotDelete.cannotDeleteClientWithMovementsInTheSystem.manyEntity.reasonTranslation'
    ),
    warningModal: t(
      'client.modalCannotDelete.cannotDeleteClientWithMovementsInTheSystem.warning'
    ),
  },
});
