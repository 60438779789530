import { useControllerQueryListApiHook } from '@/ControllerApiHook/Controller';
import { FETCH_LIST_BALANCE_ADJUSTMENTS } from '@/ControllerApiHook/UniqIds/Supply/BalanceAdjustmentsKeys';
import { BalanceAdjustmentsAPI } from '@/Data/API/Supply/BalanceAdjustmentsApi';

export const useListCallAPI = () => {
  const balanceAdjustmentsList = useControllerQueryListApiHook({
    uniqId: FETCH_LIST_BALANCE_ADJUSTMENTS,
    entityApi: BalanceAdjustmentsAPI.getBalanceAdjustmentsList,
    pagination: {
      sorter: { column: 'dataLancamento', direction: 'ASC' },
    },
  });

  return { balanceAdjustmentsList };
};
