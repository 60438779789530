import { IUseMemorizations } from "./interfaces";

export const useAssociatedProductDetailsMemorizations = ({
  associatedProductData
}: IUseMemorizations) => {
  const initialValues = {
    ...associatedProductData,
    acumula: associatedProductData && associatedProductData.acumula ? 1 : 2,
  };

  return {
    initialValues
  };
};