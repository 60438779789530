import {
  DELETE_ORDER_PURCHASE
} from '@/ControllerApiHook/UniqIds/Purchase/PurchaseOrderKeys';
import { useControllerMutateApiHook } from '@/ControllerApiHook/Controller';
import { PurchaseAPI } from '@/Data/API/Purchase/PurchaseOrderApi';
import { messageErrorToast, messageSucessToast } from '@/Utils/MessageErrorToast';
import { i18n } from '@/Components';

export const useDeleteDraftModalCallAPI = () => {
  const deleteDraftOrderPurchase: any = useControllerMutateApiHook({
    uniqId: DELETE_ORDER_PURCHASE,
    entityApi: PurchaseAPI.deleteOrderPurchase,
    options: {
      onError: (x) => messageErrorToast(x),
      onSuccess: () =>
        messageSucessToast(
          i18n.t('purchasing.purchaseOrder.successMessageToasts.deleteDraft')
        )
    }
  });

  return {
    deleteDraftOrderPurchase
  };
};