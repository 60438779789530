import { LIST_USER_GROUP_TYPE } from '@/ControllerApiHook/UniqIds/Accountmanager/UserGroupKeys';
import { useControllerQueryListApiHook } from '@/ControllerApiHook/Controller';
import { UserGroupAPI } from '@/Data/API/Accountmanager/UserGroupApi';

export const usePersonalDataCardCallAPI = () => {
  const {
    data: group,
    refetch: refetchGroupList,
    fetchNewPage: fetchNewPageGroup,
    isLoading: isLoadingGroupList,
  } = useControllerQueryListApiHook({
    uniqId: LIST_USER_GROUP_TYPE,
    entityApi: UserGroupAPI.listUserGroup,
    autoIncrement: true,
    pagination: {
      sorter: { column: 'nome', direction: 'DESC' },
    },
  });

  return { group, refetchGroupList, fetchNewPageGroup, isLoadingGroupList };
};
