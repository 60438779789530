import { NumberInput } from '@/Components';
import { DiscountInput } from '@/Components/Inputs/DiscountInput';
import { IAddFinishedProductRequest } from '@/Data/Interfaces/request/Sales/Service/IServiceRequest';
import { IFinishedProductListData } from '@/Data/Interfaces/response/Product/IProductRespose';
import { currencyFormater, getCurrencySymbol } from '@/Utils/Currency';
import { Col, Row } from 'antd';
import { FormikContextType, useFormikContext } from 'formik';
import { FC } from 'react';
import AddProductUtils from '../../../AddProductUtils';
import { useTranslation } from 'react-i18next';
import { usePriceRowFunctions } from './Hooks/usePriceRowFunctions';
import { usePriceRowCallAPI } from './Hooks/usePriceRowCallAPI';

interface IPriceRow {
  productSelected?: IFinishedProductListData;
}

interface IFormValues extends IAddFinishedProductRequest {
  total?: number;
}

export const PriceRow: FC<IPriceRow> = ({ productSelected }) => {
  const form: FormikContextType<IFormValues> = useFormikContext();
  const { t } = useTranslation();

  usePriceRowCallAPI({ form, productSelected });

  const { changeTotalInput, changeTotalValue } = usePriceRowFunctions();

  return (
    <Row gutter={[16, 0]}>
      <Col flex="25%">
        <NumberInput
          name="valorUnitario"
          label={t('saleOrder.editOrder.SaleOrder.addProductModal.value')}
          placeHolder={currencyFormater(0)}
          rightIcon={{ titleString: 'un' }}
          fixedDecimalScale
          decimalScale={2}
          withDecimalSeparator
          withThousandSeparator
          required
          disabled
          prefix={getCurrencySymbol()}
          dataTestId={'unitValue'}
        />
      </Col>
      <Col flex="25%">
        <NumberInput
          name="quantidade"
          label={t('saleOrder.editOrder.SaleOrder.addProductModal.amout')}
          placeHolder="0"
          dataTestId="amount-test-id"
          rightIcon={{ titleString: 'un' }}
          decimalScale={0}
          required
          onChange={(amount) => changeTotalValue(form, { amount })}
          disabled={!form.values.produtoExternalId}
          subText={
            productSelected &&
            `${productSelected?.saldoEstoque} un ${
              productSelected?.saldoEstoque === 1
                ? t(
                    'saleOrder.editOrder.SaleOrder.addProductModal.balanceAvaible'
                  )
                : t(
                    'saleOrder.editOrder.SaleOrder.addProductModal.balanceAvaibles'
                  )
            }`
          }
          subTextTestId="amount-sub-text"
          withoutMarginBottom
          allowNegative={false}
        />
      </Col>
      <Col flex="25%">
        <DiscountInput
          name="desconto"
          label={t(
            'saleOrder.editOrder.SaleOrder.addProductModal.unitDiscount'
          )}
          placeHolder="0,00 %"
          dataTestId="discount-test-id"
          dropdownLeft={{
            name: 'tipoDesconto',
            onChange: (discountType) =>
              form.setFieldValue(
                'desconto',
                AddProductUtils.getDiscountValue(form, parseInt(discountType))
              ),
            dataTestId: 'discountType',
          }}
          onChange={(discount) => changeTotalValue(form, { discount })}
          withoutPrefix
          withoutSuffix
          allowNegative={false}
        />
      </Col>
      <Col flex="25%">
        <NumberInput
          name="total"
          label={t('saleOrder.editOrder.SaleOrder.addProductModal.totalValue')}
          placeHolder={currencyFormater(0)}
          fixedDecimalScale
          decimalScale={2}
          withDecimalSeparator
          withThousandSeparator
          required
          value={form.values.total}
          onChange={(x) => changeTotalInput(form, x)}
          onBlur={() => changeTotalInput(form, form.values.total ?? 0)}
          error={
            !!form.values.total &&
            form.values.valorUnitario * form.values.quantidade <
              form.values.total
          }
          disabled={!form.values.produtoExternalId}
          prefix={getCurrencySymbol()}
          dataTestId="total-test-id"
          allowNegative={false}
        />
      </Col>
    </Row>
  );
};
