import { FC, useEffect } from 'react';
import { Col } from 'antd';
import { useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';
import { Tooltip } from '@/Components';
import { CurrencyInput } from '@/Components/Inputs/CurrencyInput';
import StorageUtils from '@/Utils/StorageUtils';
import { ISaleValue } from './interfaces';
import { useSaleValueStates } from './Hooks/useSaleValueStates';
import { useSaleValueFunctions } from './Hooks/useSaleValueFunctions';
import { useSaleValueMemorizations } from './Hooks/useSaleValueMemorizations';

export const SaleValue: FC<ISaleValue> = ({
  product,
  disabledForm,
  listunitsMeasurements,
}) => {
  const preference = StorageUtils.getPreference();
  const { t } = useTranslation();
  const form: any = useFormikContext();

  const { price, setPrice } = useSaleValueStates();

  const { onChange } = useSaleValueFunctions({ setPrice });

  const { supportText } = useSaleValueMemorizations({
    product,
    listunitsMeasurements,
  });

  useEffect(() => {
    const currentFormMargemLucro = form?.values?.margemLucro;
    const margemLucro =
      (form?.values.valorVenda * 100) / form?.values.valorCusto - 100;
    const hasChanges = currentFormMargemLucro !== margemLucro;

    form?.values.valorCusto > 0 &&
      hasChanges &&
      form.setFieldValue('margemLucro', margemLucro || 0);
  }, [price]);

  return (
    <Col span={4}>
      <Tooltip
        title={`${t('products.toolTips.products.saleValue')} ${
          preference?.tipoMoedaId === 0 ? 'R$' : '$'
        } ${product?.valorVenda}`}
        showMe={
          product &&
          product?.valorVenda !== form?.values?.valorVenda &&
          !disabledForm
        }
      >
        <CurrencyInput
          name="valorVenda"
          label={[
            {
              children: t('products.create.sellPrice'),
            },
          ]}
          placeHolder={t('products.create.sellPricePlaceholder')}
          withoutMarginBottom={true}
          disabled={disabledForm}
          onChange={onChange}
          required={!product}
          withStatusIcon={!!product}
          supportText={supportText}
        />
      </Tooltip>
    </Col>
  );
};
