import { IProductResponse } from '../../../../../../../../../Data/Interfaces/response/Product/IProductRespose';

export const DetailsInitialValues = (product?: IProductResponse | null) => {
  if (!product) return;
  return (
    product && {
      externalId: product.externalId,
      descricao: product.descricao,
      descricaoRotulo: product.descricaoRotulo,
      classeProdutoId: product.classeProdutoId,
      grupoExternalId: product.grupoExternalId,
      grupoLabelInitialValue: {
        id: product.grupoExternalId,
        label: product.grupo,
      },
      subGrupoExternalId: product.subGrupoExternalId,
      subGrupoLabelInitialValue: {
        id: product.subGrupoExternalId,
        label: product.subGrupo,
      },
      unidadeEstoqueId: product.unidadeEstoqueId,
      fornecedorExternalId: product.fornecedorExternalId,
      fornecedorLabelInitialValue: {
        id: product.fornecedorExternalId,
        label: product.fornecedor,
      },
      controlaLote: product.controlaLote,
      usoContinuo: product.usoContinuo,
      etiqueta: product.etiqueta,
      controlaQualidade: product.controlaQualidade,
      ...product.materiaPrima,
      ...product.tipoCapsula,
      ...product.embalagem,
      ...product.capsulaPronta,
      materiaPrimaAssociacao: product.capsulaPronta
        ? product.capsulaPronta.materiaPrimaAssociacoes.map((e) => ({
            ...e,
            produtoMateriaPrimaExternalId: e.produtoExternalId,
          }))
        : '',
      capsulaTamanhoId: product.tipoCapsula
        ? product.tipoCapsula.numeroCapsulaId
        : product.capsulaPronta
        ? product.capsulaPronta.numeroCapsulaId
        : '',
      numeroCapsulaAssociacao:
        product && product.embalagem
          ? product.embalagem.numeroCapsulaAssociacoes
              .map((e) => ({
                numeroCapsulaId: e.numeroCapsulaId,
                quantidadeCapsula: e.quantidadeCapsula,
              }))
              .sort((a, b) => b.numeroCapsulaId - a.numeroCapsulaId)
          : [],

      embalagemAssociacao:
        product && product.embalagem
          ? product.embalagem.embalagemAssociacoes.map((e) => {
              return {
                produtoEmbalagemExternalId: e.produtoExternalId,
                produtoDescricao: e.produtoDescricao,
                quantidadeEmbalagem: e.quantidadeEmbalagem,
              };
            })
          : [],

      materiaPrimaAssociacoes: undefined,
      informacaoTecnica: {
        densidade: product.materiaPrima? product.materiaPrima.densidade : undefined,
        diluicaoFornecedor: product.materiaPrima? product.materiaPrima.diluicaoFornecedor : undefined,
        fatorDiluicaoFornecedor: product.materiaPrima? product.materiaPrima.fatorDiluicaoFornecedor : undefined,
        concentracaoAgua: product.materiaPrima? product.materiaPrima.concentracaoAgua : undefined,
        fatorConcentracaoAgua: product.materiaPrima? product.materiaPrima.fatorConcentracaoAgua : undefined,
        quantidadeUnidadeAlternativa1: product.materiaPrima? product.materiaPrima.quantidadeUnidadeAlternativa1 ?? '' : '',
        quantidadeUnidadeAlternativa2: product.materiaPrima? product.materiaPrima.quantidadeUnidadeAlternativa2 ?? '' : '',
        unidadeAlternativaId1: product.materiaPrima? product.materiaPrima.unidadeAlternativaId1 ?? '' : '',
        unidadeAlternativaId2: product.materiaPrima? product.materiaPrima.unidadeAlternativaId2 ?? '' : '',
        unidadeAlternativaConversaoId1: product.materiaPrima? product.materiaPrima.unidadeAlternativaConversaoId1 ?? '' : '',
        unidadeAlternativaConversaoId2: product.materiaPrima? product.materiaPrima.unidadeAlternativaConversaoId2 ?? '' : '',
      },
    }
  );
};
