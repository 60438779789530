import { IconName } from '@/Components/Icon/IconClasses';
import { Color } from '@/Utils/ColorUtils';
import { FC } from 'react';
import Spinner from '../Spinner';
import { Icon } from '@/Components';

import styles from './LeftIcon.module.scss';

interface ILeftIcon {
  leftIcon?: IconName;
  loading?: boolean;

  noIconPadding?: boolean;
  leftIconColor?: Color;
  size: 'xs' | 'xl';

  withOnlyIconDescription: boolean;
}

export const LeftIcon: FC<ILeftIcon> = ({
  leftIcon,
  loading,
  noIconPadding,
  leftIconColor,
  size,
  withOnlyIconDescription,
}) => {
  return loading ? (
    <Spinner className={`button-spinner ${styles['spinner']}`} />
  ) : (
    <div
      className={`${styles['div-leftIcon']} ${
        noIconPadding ? styles['div-noPadding'] : ''
      }`}
      style={withOnlyIconDescription ? { margin: 'auto' } : undefined}
    >
      <Icon
        className="button-leftIcon"
        size={size === 'xs' ? 'SM' : 'M'}
        icon={leftIcon}
        color={leftIconColor ? leftIconColor : 'text-50'}
      />
    </div>
  );
};
