import { FC } from "react";
import { InputWithSearch, Tooltip } from '@/Components';
import { Col } from 'antd';
import { useTranslation } from 'react-i18next';
import { ICapsuleColor } from "./interfaces";
import { useCapsuleColorCallAPI } from "./Hooks/useCapsuleColorCallAPI";
import { useCapsuleColorMemorizations } from "./Hooks/useCapsuleColorMemorizations";
import { useCapsuleColorFunctions } from "./Hooks/useCapsuleColorFunctions";

export const CapsuleColor: FC<ICapsuleColor> = ({
  product,
  disabledForm
}) => {
  const { t } = useTranslation();

  const {
    listCapsuleColor,
    isLoadingCapsuleColor,
    fetchNewPageCapsuleColor,
    refetchCapsuleColor
  } = useCapsuleColorCallAPI();

  const {
    title,
    showMe,
    items
  } = useCapsuleColorMemorizations({
    product,
    disabledForm,
    listCapsuleColor
  });

  const {
    onSearch
  } = useCapsuleColorFunctions({ refetchCapsuleColor });

  return (
    <Col span={4}>
      <Tooltip
        title={title}
        showMe={showMe}
      >
        <InputWithSearch
          name="capsulaCorExternalId"
          label={t('products.create.capsuleColor')}
          placeHolder={t('products.create.capsuleColorPlaceholder')}
          isLoading={isLoadingCapsuleColor}
          onScrollEnd={fetchNewPageCapsuleColor}
          onSearch={onSearch}
          items={items}
          disabled={disabledForm}
          withStatusIcon={!!product}
        />
      </Tooltip>
    </Col>
  );
};