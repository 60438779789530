import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Table } from '@/Components/Table';
import { ContainerPage } from '@/Layouts/ContainerPage';
import { useParams } from 'react-router-dom';
import { stockBalanceColumns } from './stock-balance.columns';
import { useStockBalanceCallAPI } from './Hooks/useStockBalanceCallAPI';
import { useStockBalanceMemorizations } from './Hooks/useStockBalanceMemorizations';

export const StockBalanceTab: FC = () => {
  const { t } = useTranslation();
  const { externalId } = useParams<{ externalId: string }>();

  const { stockBalanceByProduct, isLoading, refetchStockBalanceByProduct } =
    useStockBalanceCallAPI({
      externalId,
    });

  const { headerOptions } = useStockBalanceMemorizations();

  return (
    <ContainerPage>
      <Table
        columns={stockBalanceColumns(t)}
        hasSelection={false}
        headerOptions={headerOptions}
        loading={isLoading}
        paginationCustomExternalId="sequenciaGroupTenant"
        pagination={stockBalanceByProduct}
        onRefetch={refetchStockBalanceByProduct}
      />
    </ContainerPage>
  );
};
