import CreateCapsuleColors from '@/Pages/Sectors/Production/CapsuleColors/Create';
import { EditPackingClassification } from '@/Pages/Sectors/Production/CapsuleColors/Form';
import CapsuleColorsList from '@/Pages/Sectors/Production/CapsuleColors/List';
import { Route, Switch } from 'react-router-dom';

export const CapsuleColorsRoutes = () => (
  <Switch>
    <Route
      path="/production/capsule-colors/create"
      component={CreateCapsuleColors}
    />
    <Route
      path="/production/capsule-colors/:externalId/:tab"
      component={EditPackingClassification}
    />
    <Route path="/production/capsule-colors" component={CapsuleColorsList} />
  </Switch>
);
