export enum TypePurchaseOrderEnum {
  waitingApproval = 1,
  disapproved = 2,
  released = 3,
  canceled = 4,
  confirmedWithSupplier = 5,
  receivedPartial = 6,
  receivedTotal = 7,
  draft = 8,
}
