import { FC } from 'react';
import {
  ICommonError
} from '@/Utils/ErrorCodeUtils/ErrorHandlingUtils';
import { useTranslation } from 'react-i18next';

export const Title: FC<ICommonError> = ({ content }) => {
  const { t } = useTranslation();
  return content?.length > 1 ? (
    <>
      {t('supply.group.modal.cannotDelete.batchTitle', {
        group: content.length,
      })}
    </>
  ) : (
    <>
      {t('supply.group.modal.cannotDelete.title', {
        group: content[0].grupoDescricao,
      })}
    </>
  );
};