import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Divflex } from '../../../../../../../../Layouts/DivWhithFlex';
import { Text } from '../../../../../../../../Components';
import { FooterThisActionIsPermanent } from '../../../../../../../../Components/Table/components/Modals/DeleteModal';
import ReactMarkdown from 'react-markdown';

interface ILinkedProduct {
  selectedRowsOnTable: any;
  messageSelected?: string;
}

export const RemoveLinkedProductModal: FC<ILinkedProduct> = ({
  selectedRowsOnTable,
  messageSelected,
}) => {
  const { t } = useTranslation();
  return (
    <div style={{ padding: '20px' }}>
      <Divflex style={{ flexDirection: 'column', marginBottom: 10 }}>
        <Text type="ui-tiny-content" withoutMarginBottom>
          <ReactMarkdown>
            {t(
              'supply.productMessage.details.modalRemoveLinked.messageStartBody',
              {
                action: `**"${selectedRowsOnTable[0]?.descricao}"** ${t(
                  'supply.productMessage.details.modalRemoveLinked.messageFinishBody'
                )}`,
              }
            )}
          </ReactMarkdown>
        </Text>

        <Text
          color="text-50"
          type="ui-tiny-bold"
          children={`"${messageSelected}"`}
        />
      </Divflex>
      <FooterThisActionIsPermanent />
    </div>
  );
};
