import { v4 as uuidV4 } from 'uuid';
import { IUseFunctions } from './interfaces';
import { useFormikContext } from 'formik';

export const useProductFunctions = ({
  index,
  totalRows,
  setProductRowAmount,
  refetchSearchProductPage,
}: IUseFunctions) => {
  const form: any = useFormikContext();
  const addRow = () => {
    if (index === totalRows - 1) {
      setProductRowAmount((oldState: string[]) => [...oldState, uuidV4()]);
    }
  };

  let selectedItemFromForm;
  const itemExternalId =
    form?.values?.pedidoCompraItens?.[index]?.pedidoCompraItemExternalId;

  if (itemExternalId) {
    selectedItemFromForm = `item_${itemExternalId}`;
  }

  const onSearch = (search: string | undefined) =>
    refetchSearchProductPage({
      search: search,
    });

  return {
    addRow,
    onSearch,
    selectedItemFromForm,
  };
};
