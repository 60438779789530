import { useControllerQueryListApiHook } from '@/ControllerApiHook/Controller';
import { UnitMeasurementAPI } from '@/Data/API/Supply/UnitMeasurementApi';
import { FETCH_LIST_UNIT_MEASUREMENT_POSOLOGY } from '@/ControllerApiHook/UniqIds/Supply/UnitMeasurementKeys';
import { LaboratoryAPI } from '@/Data/API/Production/Laboratory';
import { FETCH_LIST_LABORATORY_FOR_DROPDOWN_TYPE } from '@/ControllerApiHook/UniqIds/Production/LaboratoryKeys';

export const useCreateCallAPI = () => {
  const {
    data: unitMeasurementPosologyList,
    isLoading: isLoadingUnitMeasurement,
  } = useControllerQueryListApiHook({
    entityApi: UnitMeasurementAPI.getListUnitMeasurementPosologyDropDown,
    uniqId: FETCH_LIST_UNIT_MEASUREMENT_POSOLOGY,
    autoIncrement: true,
  });

  const {
    data: laboratoryList,
    isLoading: isLoadingLaboratoryList,
    refetch: refetchSearchLaboratoryPage,
    fetchNewPage: fetchNewPageLaboratory,
  } = useControllerQueryListApiHook({
    entityApi: LaboratoryAPI.getListLaboratoryDropDown,
    uniqId: FETCH_LIST_LABORATORY_FOR_DROPDOWN_TYPE,
    autoIncrement: true,
  });

  return {
    unitMeasurementPosologyList,
    isLoadingUnitMeasurement,
    laboratoryList,
    isLoadingLaboratoryList,
    refetchSearchLaboratoryPage,
    fetchNewPageLaboratory,
  };
};
