import { useMemo } from 'react';
import { IUseMemorizations } from './interfaces';
import { MaskForCNPJ, MaskForCPF } from '@/Utils/MasksUtils';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { NotificationActions } from '@/Store/Notification/Notification.actions';
import { IListDropdownClientData } from '@/Data/Interfaces/response/Client/IClientResponse';

export const useBodyMemorizations = ({
  clientList,
  setVisibleClientModal,
  serviceChannelList,
}: IUseMemorizations) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const clientDataList = clientList?.data?.map((x: IListDropdownClientData) => ({
        avatar: { name: x.nome },
        id: x.externalId,
        label: x.nome,
        subLabel: `${t('callCenter.createService.code')}: ${x.codigo} | ${
          x.cpf ? `CPF: ${MaskForCPF(x.cpf)}` : `CNPJ: ${MaskForCNPJ(x.cnpj)}`
        }`,
      })) || [];

  const clientEmptyState = useMemo(
    () => ({
      onClick: () => {
        dispatch(NotificationActions.setCurrentFormIsDirty(false));

        setVisibleClientModal(true);
      },
      suffix: ` (${t('callCenter.createService.newClient')})`,
    }),
    [dispatch, setVisibleClientModal, t]
  );

  const serviceChannelDataList = useMemo(
    () =>
      serviceChannelList?.map((x: any) => ({
        id: x.id,
        label: x.descricao,
        leftIcon: x.icon,
      })) || [],
    [serviceChannelList]
  );

  return {
    clientDataList,
    clientEmptyState,
    serviceChannelDataList,
  };
};
