import { FC } from 'react';
import { Card } from '@/Components';
import { AdderList } from '@/Components/AdderList';
import { IFChildren } from '@/Components/Form';
import { useTranslation } from 'react-i18next';
import { useAddressCardFunctions } from './Hooks/useAddressCardFunctions';
import { useAddressCardMemorizations } from './Hooks/useAddressCardMemorizations';

export const AddressCard: FC<{ props: IFChildren }> = ({ props }) => {
  const { t } = useTranslation();

  const { onChange } = useAddressCardFunctions({ props });

  const { column, modal } = useAddressCardMemorizations();

  return (
    <Card title={t('address.adresses')} withoutCollapse>
      <AdderList
        favoriteFormName="principal"
        onChange={onChange}
        collum={column}
        modal={modal}
        type="adrressType"
        buttonLabel={t('address.addAddress')}
        empytDataDescription={t('address.addressEmpyt')}
        entityExternalIdName="prescritorEnderecoExternalId"
        actions
      />
    </Card>
  );
};
