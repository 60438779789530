import { IListFreightTypeData } from '../../Interfaces/response/Purchase/IPurchaseResponse';
import { QueryFunctionContext } from '@tanstack/react-query';
import { Http, IQueryListParams } from '../../../Utils/Http';

export class PurchaseAPI {
  public static async listDropdownFreightType(
    context: QueryFunctionContext
  ): Promise<IListFreightTypeData[]> {
    return await Http.fetcher<IListFreightTypeData[]>(
      Http.AddQueryParams(
        '/pharma/compras/v1/listarTipoFrete',
        context.queryKey[1] as IQueryListParams
      )
    );
  }
}
