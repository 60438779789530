import { FC } from "react";
import { Col } from 'antd';
import { useTranslation } from 'react-i18next';
import { TextInputNumber } from "@/Components/Inputs/TextInputNumber";

export const SeriesInvoice: FC = () => {
  const { t } = useTranslation();

  return (
    <Col span={2}>
      <TextInputNumber
        name="serieNf"
        label={t('supply.lot.create.seriesInvoice')}
        placeHolder={t('supply.lot.placeholder.seriesInvoice')}
        required
        withoutArrows
      />
    </Col>
  );
};