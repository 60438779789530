import { FC } from "react";
import { Button } from '@/Components';
import { IDisapproveButton } from "./interfaces";
import { useDisapproveButtonMemorizations } from "./Hooks/useDisapproveButtonMemorizations";
import { useDisapproveButtonFunctions } from "./Hooks/useDisapproveButtonFunctions";

export const DisapproveButton: FC<IDisapproveButton> = ({
  statusPurchaseOrder,
  setReproveModal,
  setReverseModal
}) => {
  const {
    leftIcon,
    permission,
    isDisabled,
    children
  } = useDisapproveButtonMemorizations({
    statusPurchaseOrder
  });

  const {
    onClick
  } = useDisapproveButtonFunctions({
    statusPurchaseOrder,
    setReproveModal,
    setReverseModal
  });

  return (
    <>
      <Button
        leftIcon={leftIcon}
        type="secondary"
        permission={permission}
        style={{ width: '100%' }}
        fullWidth
        children={children}
        disabled={isDisabled}
        onClick={onClick}
      />
    </>
  );
};