import { useMemo } from "react";
import { StandardFormulaDetail } from '../Components/Detail';
import { useTranslation } from "react-i18next";
import { IUseMemorizations } from "./interfaces";
import { PermissionEnum } from "@/Data/Interfaces/model/permissionEnum";
import { IButtonDropDownItems } from "@/Components/Button/ButtonDropdown";

export const useEditMemorizations = ({
  disabled,
  setDisabled,
  setVisibleModal,
  tab,
  standardFormula
}: IUseMemorizations) => {
  const { t } = useTranslation();

  const items = useMemo(() => (
    [
      {
        label: t('common.details'),
        key: 'details',
        children: (
          <StandardFormulaDetail
            disabled={disabled}
            onChangeDisabled={(x: boolean) => setDisabled(x)}
          />
        ),
      },
    ]
  ), [t, disabled, setDisabled]);

  const dropdownItems: IButtonDropDownItems | undefined = [
    {
      label: t('common.delete'),
      icon: 'trash',
      onClick: () => {
        setVisibleModal(true);
      },
      permission: {
        permission: PermissionEnum.Producao_FormulaPadrao_Excluir,
        tooltip: 'noPermission.production.standardFormula.delete',
      },
    },
  ];

  const rightButton = {
    ...(disabled &&
      tab === 'details' &&
      standardFormula && {
        onClick: () => setDisabled(false),
        isVisible: disabled,
        codePermissionEdit:
          PermissionEnum.Producao_FormulaPadrao_EditarDetalhes,
        messageTooltipPermission:
          'noPermission.production.standardFormula.edit',
      }),
  };

  return {
    items,
    dropdownItems,
    rightButton
  };
};