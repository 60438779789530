import { useTranslation } from 'react-i18next';

export const useDefineStatus = () => {
  const { t } = useTranslation();

  const defineStatus = (isEqualForm: boolean, isSaved: boolean) => {
    if (isEqualForm && isSaved) {
      return t('common.savedChanges');
    }

    if (isEqualForm && !isSaved) {
      return '';
    }

    return t('common.hearChanges');
  };

  return { defineStatus };
};
