import { useCallback } from 'react';
import { IModulo } from '@/Data/Interfaces/response/User/IGetUserReponse';
import { IRow } from '@/Components/CollapseList';
import { IconName } from '@/Components/Icon/IconClasses';

export const usePermissionFormFunctions = () => {
  const transformModule = useCallback((module: IModulo): IRow => {
    let itens: IRow[] | undefined;
    let totalPermissions: number | undefined = 0;
    let activePermissions: number | undefined = 0;

    if (module.itens) {
      itens = module.itens.map((x) => transformModule(x));
    }

    if (!module.permissaoId) {
      totalPermissions +=
        itens
          ?.map((x) => x.totalPermissions)
          .reduce((a, b) => (a || 0) + (b || 0), 0) || 0;
      totalPermissions += itens?.filter((x) => !!x.permissionId).length || 0;

      activePermissions +=
        itens
          ?.map((x) => x.activePermissions)
          .reduce((a, b) => (a || 0) + (b || 0), 0) || 0;
      activePermissions +=
        itens?.filter((x) => !!x.permissionId && x.active === true).length || 0;
    }

    return {
      name: module.permissaoId?.toString(),
      displayName: module.nome,
      permissionId: module.permissaoId,
      isCollapse: !module.permissaoId,
      active: module.ativo,
      inherited: module.herdado,
      inheritedFrom: module.grupos || [],
      children: itens,
      totalPermissions,
      activePermissions,
      icon: {
        backgroundColor: module.corDeFundo,
        icon: module.icone as IconName,
        color: !!module?.corDeFundo ? 'white' : 'text-50',
      },
    };
  }, []);

  return {
    transformModule,
  };
};
