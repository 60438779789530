import { IUseFunctions } from './interfaces';

export const usePharmaceuticalFormFunctions = ({
  refetchSearchPharmaceuticalFormPage,
}: IUseFunctions) => {
  const onSearch = (search?: string) => {
    refetchSearchPharmaceuticalFormPage({
      search: search,
    });
  };

  return {
    onSearch,
  };
};
