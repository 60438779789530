import { Row } from 'antd';
import { useFormikContext } from 'formik';
import { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Card } from '@/Components';
import { IFinanceSaleCard } from './interfaces';
import { CostValue } from './Components/CostValue';
import { ProfitMargin } from './Components/ProfitMargin';
import { SaleValue } from './Components/SaleValue';
import { useFinanceProductCardCallAPI } from './Hooks/useFinanceProductCardCallAPI';

export const FinanceSaleCard: FC<IFinanceSaleCard> = ({
  disabledForm,
  product,
}) => {
  const { t } = useTranslation();
  const form: any = useFormikContext();

  const { listunitsMeasurements } = useFinanceProductCardCallAPI();

  useEffect(() => {
    form.resetForm();
  }, [disabledForm]);

  return (
    <>
      <Card title={t('products.create.financeTitle')} withoutCollapse>
        <Row gutter={[16, 0]} justify="start">
          <CostValue
            product={product}
            disabledForm={disabledForm}
            listunitsMeasurements={listunitsMeasurements}
          />
          <ProfitMargin product={product} disabledForm={disabledForm} />
          <SaleValue
            product={product}
            disabledForm={disabledForm}
            listunitsMeasurements={listunitsMeasurements}
          />
        </Row>
      </Card>
    </>
  );
};
