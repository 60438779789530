import { QueryFunctionContext } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';
import { IQueryParams } from '../../../Components/Table';
import { Http, IQueryListParams } from '../../../Utils/Http';
import {
  IStockBalanceByLotListResponse,
  IStockBalanceByProductGetResponse,
  IStockBalanceByProductListResponse,
  IStockBalanceListData,
} from '../../Interfaces/response/StockBalance/IStockBalanceResponse';

export class StockBalanceAPI {
  public static async getStockBalanceList(
    body: IQueryParams
  ): Promise<AxiosResponse<IStockBalanceListData>> {
    const axios = await Http.axios();
    const result = axios.get<IStockBalanceListData>(
      Http.AddODataQueryParams('/estoque/odata/v1/SaldoEstoque', body)
    );

    return result;
  }

  public static async stockBalanceByProductList(
    context: any
  ): Promise<IStockBalanceByProductListResponse | null> {
    return await Http.fetcher(
      Http.AddQueryParams(
        `/pharma/estoque/v1/SaldoEstoque/ListarDetalhadoPorLocalEstoque`,
        context.queryKey[1] as IQueryListParams
      )
    );
  }

  public static async stockBalanceByLotList(
    context: any
  ): Promise<IStockBalanceByLotListResponse | null> {
    return await Http.fetcher(
      Http.AddQueryParams(
        `/pharma/estoque/v1/SaldoEstoque/ListarDetalhadoPorLote`,
        context.queryKey[1] as IQueryListParams
      )
    );
  }

  public static async getStockBalanceByProductGet(
    context: QueryFunctionContext
  ) {
    if (!context.queryKey[1]) return;
    return await Http.fetcher<IStockBalanceByProductGetResponse>(
      `/pharma/estoque/v1/SaldoEstoque/ObterSaldoProduto/${context.queryKey[1]}`
    );
  }
}
