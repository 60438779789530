import { CreatePrescribers } from '@/Pages/Sectors/Sales/Prescribers/Create';
import { EditPrescribers } from '@/Pages/Sectors/Sales/Prescribers/Edit';
import { List } from '@/Pages/Sectors/Sales/Prescribers/List';
import { Route, Switch } from 'react-router-dom';

export const PrescribersRoutes = () => (
  <Switch>
    <Route path="/sales/prescribers/create" component={CreatePrescribers} />
    <Route
      path="/sales/prescribers/:externalId/:tabs"
      component={EditPrescribers}
    />
    <Route path="/sales/prescribers" component={List} />
  </Switch>
);
