import { INewPasswordRequest } from '@/Data/Interfaces/request/Auth/ILoginRequest';
import { FormikHelpers, FormikValues } from 'formik';
import { IUseNewPasswordFunctions } from './interfaces';

export const useNewPasswordFunctions = ({
  externalId,
  code,
  newPassword,
}: IUseNewPasswordFunctions) => {
  const onSubmit = (
    values: INewPasswordRequest,
    helpers: FormikHelpers<FormikValues>
  ) => {
    if (externalId && code) {
      const body: INewPasswordRequest = {
        ...values,
        externalId,
        code,
        helpers,
      };

      newPassword.mutateAsync(body);
    }
  };
  return { onSubmit };
};
