import { FC } from "react";
import { Row } from 'antd';
import { Product } from "./Components/Product";
import { Class } from "./Components/Class";
import { Launch } from "./Components/Launch";
import { ManufacturingDate } from "./Components/ManufacturingDate";
import { ExpirationDate } from "./Components/ExpirationDate";
import { IGetLotResponse } from "@/Data/Interfaces/response/Lot/IListLot";

interface IBatchInfo {
  lots: IGetLotResponse | undefined;
  disabled: boolean;
};

export const BatchInfo: FC<IBatchInfo> = ({
  lots,
  disabled
}) => {
  return (
    <Row gutter={[16, 0]}>
      <Product />
      <Class />
      <Launch />
      <ManufacturingDate
        lots={lots}
        disabled={disabled}
      />
      <ExpirationDate
        lots={lots}
        disabled={disabled}
      />
    </Row>
  );
};