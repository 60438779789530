import { FC } from 'react';
import { Modal } from '@/Components/Modal';
import { useTranslation } from 'react-i18next';
import { IDeleteDraftModal } from './interfaces';
import { useDeleteDraftModalCallAPI } from './Hooks/useDeleteDraftModalCallAPI';
import { useDeleteDraftModalFunctions } from './Hooks/useDeleteDraftModalFunctions';

export const DeleteDraftModal: FC<IDeleteDraftModal> = ({
  orderPurchase,
  visibleModal,
  changeVisibleModal,
}) => {
  const { t } = useTranslation();

  const {
    deleteDraftOrderPurchase
  } = useDeleteDraftModalCallAPI();

  const {
    onOkClick,
    onCancelClick,
    onClose
  } = useDeleteDraftModalFunctions({
    deleteDraftOrderPurchase,
    orderPurchase,
    changeVisibleModal
  });

  return (
    <Modal
      title={t('common.modalDraftDelete.title')}
      body={
        <div style={{ padding: '20px', width: 400 }}>
          {t('common.modalDraftDelete.subTitle')}
        </div>
      }
      okButtonColor="status-danger-base"
      visible={visibleModal}
      onOkClick={onOkClick}
      okButtonName={
        deleteDraftOrderPurchase.isLoading
          ? t('common.deleting')
          : t('common.delete')
      }
      loading={deleteDraftOrderPurchase.isLoading}
      onCancelClick={onCancelClick}
      onClose={onClose}
    />
  );
};
