import _ from 'lodash';
import { IEditPackingClassification } from '@/Data/Interfaces/request/PackingClassification/IPackingClassificationRequest';
import { IUseFunctions } from './interfaces';
import { useCallback } from 'react';
import { App } from 'antd';
import { useQueryClient } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';

export const useEditFunctions = ({
  editPackingClassification,
  getPackingClassification,
}: IUseFunctions) => {
  const app = App.useApp();
  const queryClient = useQueryClient();
  const { t } = useTranslation();

  const onSubmit = useCallback(
    async (values: IEditPackingClassification) => {
      if (getPackingClassification) {
        return await editPackingClassification({
          externalId: getPackingClassification.externalId,
          descricao: values.descricao,
          ativo: values.ativo,
        });
      }
    },
    [getPackingClassification]
  );

  return { onSubmit };
};
