import { Button } from '@/Components';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import styles from '../../PharmaceuticalForm.module.scss';
import { IFChildren } from '@/Components/Form';
import { NotificationActions } from '@/Store/Notification/Notification.actions';
import { useDispatch } from 'react-redux';

interface IFooterButtons {
  isLoadingEditPharmaceuticalForm: boolean;
  onChangeDisabled: (x: boolean) => void;
  form: IFChildren;
}

export const FooterButtons: FC<IFooterButtons> = ({
  isLoadingEditPharmaceuticalForm,
  onChangeDisabled,
  form,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  return (
    <div className={styles['footer-button']}>
      <Button
        type="secondary"
        children={t('common.cancel')}
        onClick={() => {
          onChangeDisabled(true);
          form.resetForm();
          dispatch(NotificationActions.setCurrentFormIsDirty(false));
        }}
      />
      <Button
        type="primary"
        children={
          isLoadingEditPharmaceuticalForm
            ? t('common.saving')
            : t('common.save')
        }
        htmlType="submit"
        loading={isLoadingEditPharmaceuticalForm}
      />
    </div>
  );
};
