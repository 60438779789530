import { StatusPurchaseOrderEnum } from '../../../enum';
import { 
  TypePurchaseOrderEnum 
} from '@/Data/Interfaces/model/typePurchaseOrderEnum';
import { PermissionEnum } from '@/Data/Interfaces/model/permissionEnum';
import { useTranslation } from 'react-i18next';
import { IUseMemorizations } from './interfaces';
import { IconName } from '@/Components/Icon/IconClasses';

export const useDisapproveButtonMemorizations = ({
  statusPurchaseOrder
}: IUseMemorizations) => {
  const { t } = useTranslation();

  const leftIcon: IconName | undefined =           statusPurchaseOrder === StatusPurchaseOrderEnum.Liberado ||
  statusPurchaseOrder ===
    StatusPurchaseOrderEnum.ConfirmadoComFornecedor ||
  statusPurchaseOrder === StatusPurchaseOrderEnum.RecebidoParcial
    ? 'reply'
    : 'close-x';

  const permission = statusPurchaseOrder === TypePurchaseOrderEnum.waitingApproval
  ? {
      permission: PermissionEnum.Compras_PedidoCompra_Reprovar,
      tooltip:
        'noPermission.purchasing.purchaseOrder.disapprove',
    }
  : statusPurchaseOrder === TypePurchaseOrderEnum.released
  ? {
      permission:
        PermissionEnum.Compras_PedidoCompra_EstornarLiberado,
      tooltip:
        'noPermission.purchasing.purchaseOrder.reverseReleased',
    }
  : {
      permission:
        PermissionEnum.Compras_PedidoCompra_EstornarConfirmadoComFornecedor,
      tooltip:
        'noPermission.purchasing.purchaseOrder.reverseConfirmWithProvider',
    };

  const isDisabled = statusPurchaseOrder === StatusPurchaseOrderEnum.RecebidoParcial;

  const children = statusPurchaseOrder ===
  StatusPurchaseOrderEnum.AguardandoAprovacao
    ? t('common.reprove')
    : t('common.reverse');

  return {
    leftIcon,
    permission,
    isDisabled,
    children
  };
};