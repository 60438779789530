import { useState } from "react";

export const useStandardFormulaDetailStates = () => {
  const unitGramDefault = 7;
  
  const [classificationProductId, setClassificationProductId] =
  useState<number>(1);

  const [unidadeMedidaId, setUnidadeMedidaId] =
    useState<number>(unitGramDefault);

  return {
    classificationProductId,
    setClassificationProductId,
    unidadeMedidaId,
    setUnidadeMedidaId
  };
};