import { useState } from 'react';

export const useCreateStates = () => {
  const [untAbbrev, setUntAbbrev] = useState<string>('');
  const [idUntAbbrev, setIdUnitAbbrev] = useState<any>();

  return {
    untAbbrev,
    setUntAbbrev,
    idUntAbbrev,
    setIdUnitAbbrev,
  };
};
