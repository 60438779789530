import {
  DELETE_GROUP,
  FETCH_LIST_GROUP,
} from '@/ControllerApiHook/UniqIds/Supply/ProductKeys';
import {
  useControllerMutateApiHook,
  useControllerQueryListApiHook,
} from '@/ControllerApiHook/Controller';
import { ProductAPI } from '@/Data/API/Supply/Product';

export const useListCallAPI = () => {
  const deleteGroup: any = useControllerMutateApiHook({
    uniqId: DELETE_GROUP,
    entityApi: ProductAPI.deleteProductGroup,
  });

  const {
    data: groupList,
    isLoading,
    refetch: refetchGroupList,
  } = useControllerQueryListApiHook({
    uniqId: FETCH_LIST_GROUP,
    entityApi: ProductAPI.groupList,
    pagination: {
      sorter: { column: 'descricao', direction: 'ASC' },
    },
  });

  return {
    deleteGroup,
    groupList,
    isLoading,
    refetchGroupList,
  };
};
