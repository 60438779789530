import { useCallback, useEffect } from 'react';
import { IUseFunctions } from './interfaces';

export const useCreateFunctions = ({
  form,
  convertUnitOfMeasureAsync,
}: IUseFunctions) => {
  const onUnitOfMeasureChange = useCallback(
    async (selectedId: any) => {
      const currentUnitOfMeasureId = form.values.unidadeMedidaId;
      const quantity = form.values.amount;
      const currentQuantity = form.values.prevQnt;

      const result = await convertUnitOfMeasureAsync([
        {
          quantidade: currentQuantity || 0,
          unidadeMedidaConversaoId: selectedId,
          unidadeMedidaOrigemId: currentUnitOfMeasureId,
        },
        {
          quantidade: quantity || 0,
          unidadeMedidaConversaoId: selectedId,
          unidadeMedidaOrigemId: currentUnitOfMeasureId,
        },
      ]);

      if (result && result.length > 0) {
        const saldoAtual = result[0].quantidadeConvertida;
        const quantity = result[1].quantidadeConvertida;
        form.setFieldValue('prevQnt', saldoAtual);
        form.setFieldValue('amount', quantity);
        form.setFieldValue('newQnt', saldoAtual - quantity);
      }
    },
    [convertUnitOfMeasureAsync, form.values]
  );

  const validadeCalcBalance = useEffect(() => {
    let prevQntNumber: number = parseFloat(form.values.prevQnt);
    let newQntNumber: number = parseFloat(form.values.newQnt);

    if (isNaN(prevQntNumber)) {
      prevQntNumber = 0;
    }

    if (isNaN(newQntNumber)) {
      newQntNumber = 0;
    }

    let amountValue = newQntNumber - prevQntNumber;

    form?.setFieldValue('amount', amountValue);
  }, [form.values.prevQnt, form.values.newQnt]);

  return { onUnitOfMeasureChange, validadeCalcBalance };
};
