import { FC, useEffect } from 'react';
import { Card } from '@/Components';
import { useTranslation } from 'react-i18next';
import { ProductLine } from './Components/ProductLine';
import { v4 as uuidV4 } from 'uuid';
import { IStandardFormulaDetailForm } from './interfaces';
import { useProductCardStates } from './Hooks/useProductCardStates';
import { useProductCardFunctions } from './Hooks/useProductCardFunctions';

export const ProductCard: FC<IStandardFormulaDetailForm> = ({
  disabled,
  onChangeDisabled,
  products,
  pharmaceuticalFormExternalId,
}) => {
  const { t } = useTranslation();

  const { productRowAmount, setProductRowAmount } = useProductCardStates();

  const { removeRow } = useProductCardFunctions({
    setProductRowAmount,
  });

  useEffect(() => {
    let newProductRowAmount = products.map(() => uuidV4());
    if (!disabled) newProductRowAmount = [...newProductRowAmount, uuidV4()];
    setProductRowAmount(newProductRowAmount);
  }, [products, disabled]);

  return (
    <>
      <Card title={t('common.productsUppercase')} withoutCollapse={true}>
        {productRowAmount.map((uuid, index) => (
          <ProductLine
            key={uuid}
            index={index}
            onDelete={removeRow}
            totalRows={productRowAmount.length}
            uuidV4={uuidV4}
            setProductRowAmount={setProductRowAmount}
            disabled={disabled}
            onChangeDisabled={onChangeDisabled}
            pharmaceuticalFormExternalId={pharmaceuticalFormExternalId}
            classeProdutoId={products[index]?.classeProdutoId}
          />
        ))}
      </Card>
    </>
  );
};
