import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { TextInputWithMask } from '@/Components/Inputs/TextInputWithMask';
import StorageUtils from '@/Utils/StorageUtils';
import DateUtils from '@/Utils/DateUtils';
import { useDeliveryForecastFunctions } from './Hooks/useDeliveryForecastFunctions';

export const DeliveryForecast: FC = () => {
  const { t } = useTranslation();
  const preferences = StorageUtils.getPreference();

  const {
    onDeliveryDateChange
  } = useDeliveryForecastFunctions();

  return (
    <>
      <TextInputWithMask
        name="previsaoEntrega"
        mask="dataMask"
        label={t('purchasing.purchaseOrder.create.deliveryForecast')}
        leftIcon="calendar"
        placeHolder={
          preferences &&
          DateUtils.getDatePlaceholder(
            preferences?.idioma,
            preferences?.padraoData
          )
        }
        onChange={onDeliveryDateChange}
        withoutMarginBottom
      />
    </>
  );
};