import { ICommonError } from '../../../../../../../Utils/ErrorCodeUtils/ErrorHandlingUtils';
import { FC } from 'react';
import { App } from 'antd';
import { Icon, Text } from '../../../../../../../Components';
import { useTranslation } from 'react-i18next';
import style from './errorCreate.module.scss';
import './errorCreate.override.scss';

export const CreateLotErrorToast: FC<ICommonError> = ({ errorCode }) => {
  const { t } = useTranslation();
  const app = App.useApp();

  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <Text type="ui-tiny-bold">{t('toast.inventory.lot.create.title')}</Text>
      <Text type="small-text" color="text-100" style={{ marginTop: '5px' }}>
        {t('toast.inventory.lot.create.description')}
      </Text>
      <div className={style['leftBar']} />
      <div
        className="iconToast"
        onClick={() => app.notification.destroy(errorCode)}
      >
        <Icon icon={'close-x'} />
      </div>
    </div>
  );
};
