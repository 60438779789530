import { i18n } from '@/Components';
import { useControllerMutateApiHook } from '@/ControllerApiHook/Controller';
import {
  ADD_ADRESS_CLIENT,
  FETCH_ADDRESS_CLIENT_LIST,
} from '@/ControllerApiHook/UniqIds/People/ClientKeys';
import { FETCH_GET_SALES_ORDER } from '@/ControllerApiHook/UniqIds/Sales/SalesOrderKeys';
import { ClientAPI } from '@/Data/API/People/ClientApi';
import {
  messageErrorToast,
  messageSucessToast,
} from '@/Utils/MessageErrorToast';
import { useQueryClient } from '@tanstack/react-query';
import { IuseCreateAddressCallAPI } from './interfaces';

export const useCreateAddressCallAPI = ({
  changeVisibleModal,updateDeliveryForHome
}: IuseCreateAddressCallAPI) => {
  const queryClient = useQueryClient();

  const { mutateAsync, isLoading } = useControllerMutateApiHook({
    uniqId: ADD_ADRESS_CLIENT,
    entityApi: ClientAPI.addAddressClient,
    options: {
      onError: (x) => messageErrorToast(x),
      onSuccess: (x) => {
        messageSucessToast(i18n.t('address.messages.createAddress'));

        queryClient.invalidateQueries([FETCH_GET_SALES_ORDER]);

        queryClient.invalidateQueries([FETCH_ADDRESS_CLIENT_LIST]);

        changeVisibleModal(false);
        updateDeliveryForHome(x.externalId)
      },
    },
  });

  return { mutateAsync, isLoading };
};
