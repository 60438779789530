import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

export const useStockLocationMemorizations = () => {
  const { t } = useTranslation();

  const headerOptions = useMemo(
    () => ({
      hasSearch: true,
      searchPlaceholder: t(
        'supply.lot.details.stockLocation.searchPlaceholder'
      ),
    }),
    [t]
  );

  return {
    headerOptions,
  };
};
