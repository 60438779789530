import { history } from '@/Store';
import {
  FETCH_LIST_STANDARD_FORMULA
} from '@/ControllerApiHook/UniqIds/Production/StandardFormulaKeys';
import { FormikHelpers, FormikValues } from 'formik';
import { 
  NotificationActions 
} from '@/Store/Notification/Notification.actions';
import { useDispatch } from 'react-redux';
import { useQueryClient } from '@tanstack/react-query';
import { IUseFunctions } from './interfaces';

export const useCreateFunctions = ({
  createStandardFormula
}: IUseFunctions) => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const onSubmit = async (
    values: FormikValues,
    helpers: FormikHelpers<FormikValues>
  ) => {
    try {
      var body: any = { ...values };
      const res = await createStandardFormula.mutateAsync({
        produtoExternalId: body.produtoExternalId,
        formaFarmaceuticaExternalId: body.formaFarmaceuticaExternalId,
        formulaPadraoDesmembramento: body.formulaPadraoDesmembramento,
        unidadeMedidaId: body.unidadeMedidaId,
        quantidadePadrao: body.quantidadePadrao,
        diasValidade: body.diasValidade,
        diluicao: body.diluicao,
        densidade: body.densidade,
        procedimento: body.procedimento,
        rodape: body.rodape,
        produtos: values.produtos.map((x: any) => ({
          produtoExternalId: x.produtoExternalId,
          tipoItem: x.tipoItem,
          unidadeMedidaId: x.unidadeMedidaId,
          quantidade: x.quantidade,
          fase: !!x.fase ? x.fase : 0,
        })),
      });
      if (res.success) {
        dispatch(NotificationActions.setCurrentFormIsDirty(false));
        setTimeout(() => {
          history.push('/production/standard-formula');
        }, 10);
        queryClient.invalidateQueries([FETCH_LIST_STANDARD_FORMULA]);
      }
    } catch {
      helpers.setSubmitting(false);
    }
  };

  const onClickCancelButton = () => {
    history.push('/production/standard-formula');
  };

  return {
    onSubmit,
    onClickCancelButton
  };
};