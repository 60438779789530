import { useCallback } from 'react';
import { IUseFunctions } from './interfaces';

export const useProductCardFunctions = ({
  refetchNewPageSubGroupPege,
  setLotControlModal,
}: IUseFunctions) => {
  const handlerGroupDropdown = useCallback(
    (groupExternalId: string) => {
      refetchNewPageSubGroupPege({
        filter: [
          {
            filterName: 'grupoExternalId',
            value: groupExternalId,
          },
        ],
      });
    },
    [refetchNewPageSubGroupPege]
  );

  const onCancelClick = useCallback(
    () => setLotControlModal(false),
    [setLotControlModal]
  );

  return {
    handlerGroupDropdown,
    onCancelClick,
  };
};
