import { IIListUnitMeasurementAllData } from '@/Data/Interfaces/response/UnitMeasurement/IUnitMeasurementResponse';
import { IUseMemorizations } from './interfaces';

export const useQuantityMemorizations = ({
  unitsMeasurementsDefault,
  listunitsMeasurementsPrescription,
  isUnitOfMeasurePrescriptionLoading,
  index,
}: IUseMemorizations) => {
  const unitMeasureIdDefault = unitsMeasurementsDefault?.unidadeEstoqueId;

  const unitOfMeasureDropdownPrescription = listunitsMeasurementsPrescription
    ? {
        name: 'unidadeMedidaId',
        loading: isUnitOfMeasurePrescriptionLoading,
        defaultValue: unitMeasureIdDefault,
        options:
          listunitsMeasurementsPrescription?.data?.map(
            (x: IIListUnitMeasurementAllData) => ({
              id: x.id,
              content: x.unidadeAbreviacao,
            })
          ) || [],
      }
    : undefined;

  const dropdownRight = {
    options: unitOfMeasureDropdownPrescription
      ? unitOfMeasureDropdownPrescription.options
      : [],
    name: `produtos.${index}.unidadeMedidaId`,
  };

  return {
    dropdownRight,
  };
};
