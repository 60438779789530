import { 
  TypePurchaseOrderEnum 
} from '@/Data/Interfaces/model/typePurchaseOrderEnum';
import { IUseFunctions } from './interfaces';

export const useApproveButtonFunctions = ({
  statusPurchaseOrder,
  setConfirmWithSupplierModal,
  setApproveModal
}: IUseFunctions) => {
  const onClick = () =>
    statusPurchaseOrder === TypePurchaseOrderEnum.released
      ? setConfirmWithSupplierModal(true)
      : setApproveModal(true);

  return {
    onClick
  };
};