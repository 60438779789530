import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Modal } from '@/Components/Modal';

import {
  getPermissionDependencyModalData,
  getShowPermissionDependencyModal,
} from '@/Store/Group/Group.selector';
import { useRequiredPermissionModalFunctions } from './Hooks/useRequiredPermissionModalFunctions';
import { ModalBody } from './Components/ModalBody';

export const RequiredPermissionModal: FC = () => {
  const modalData = useSelector(getPermissionDependencyModalData);
  const show = useSelector(getShowPermissionDependencyModal);
  const { t } = useTranslation();
  const { onCloseHandler, onOkClick } = useRequiredPermissionModalFunctions();

  return (
    <Modal
      visible={show}
      onClose={onCloseHandler}
      onCancelClick={onCloseHandler}
      onOkClick={onOkClick}
      body={<ModalBody />}
      okButtonColor={
        modalData?.request.ativo! ? undefined : 'status-danger-base'
      }
      title={t('admin.editUserPage.permission.modal.modalTitle')}
      okButtonName={t(
        `admin.editUserPage.permission.modal.ok${
          modalData?.request.ativo! ? 'Active' : 'Inactive'
        }Button`
      )}
    />
  );
};
