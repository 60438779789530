import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Modal } from '@/Components/Modal';
import { IConfirmWithSupplierModal } from './interfaces';
import { 
  useConfirmWithSupplierModalMemorizations 
} from './Hooks/useConfirmWithSupplierModalMemorizations';

export const ConfirmWithSupplierOrderModal: FC<IConfirmWithSupplierModal> = ({
  isVisible,
  onCancelClick,
  onOkClick,
  onClose,
  okButtonName,
  loading
}) => {
  const { t } = useTranslation();

  const {
    body
  } = useConfirmWithSupplierModalMemorizations();
 
  return (
    <Modal
      title={t('purchasing.purchaseOrder.modal.confirmWithSupplier.title')}
      body={body}
      onCancelClick={onCancelClick}
      visible={isVisible}
      onOkClick={onOkClick}
      onClose={onClose}
      okButtonName={okButtonName}
      loading={loading}
      okButtonLeftIcon={!loading ? 'check' : undefined}
      okButtonStatus="success"
      maxWidth={399}
    />
  );
};
