import { FC } from 'react';
import { Col } from 'antd';
import { useTranslation } from 'react-i18next';
import { Dropdown } from '@/Components';
import { useBatchLocalCallAPI } from './Hooks/useBatchLocalCallAPI';
import { useBatchLocalMemorizations } from './Hooks/useBatchLocalMemorizations';

export const BatchLocal: FC = () => {
  const { t } = useTranslation();

  const { storageLocationList, fetchNewPage, isLoading } =
    useBatchLocalCallAPI();

  const { items } = useBatchLocalMemorizations({ storageLocationList });

  return (
    <Col span={6}>
      <Dropdown
        items={items}
        name="saldoInicial.localEstoqueExternalId"
        label={t('supply.lot.create.stockLocation')}
        placeHolder={t('supply.lot.placeholder.stockLocation')}
        onScrollEnd={fetchNewPage}
        autoIncrementBy="id"
        isLoading={isLoading}
      />
    </Col>
  );
};
