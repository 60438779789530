import { Icon, Link, Text, Tooltip } from '../../../../../Components';
import { Divflex } from '../../../../../Layouts/DivWhithFlex';
import { hasPermission } from '../../../../../Components/PermissionWrapper';
import moment from 'moment';
import { CurrencyText } from '../../../../../Components/CurrencyText';

export const balanceAdjustmentsColumns = (t: any) => [
  {
    title: t('supply.balanceAdjustment.list.adjustmentDate'),
    width: '13%',
    dataIndex: 'dataLancamento',
    key: 'dataLancamento',
    sortColumn: 'dataLancamento',
    sorter: true,
    defaultSortOrder: 'ascend',
    isHighlightable: true,
    render: (data: string) => (
      <Text type="ui-tiny-bold" color="text-50">
        {moment(data).format('DD/MM/yyyy')} {t('common.dateBreakApart')}{' '}
        {moment(data).format('HH:mm')}
      </Text>
    ),
  },

  {
    title: t('supply.balanceAdjustment.list.product'),
    width: '30%',
    dataIndex: 'produtoDescricao',
    key: 'produtoDescricao',
    sortColumn: 'produtoDescricao',
    sorter: true,
    isHighlightable: true,
    render: (x: any, row: any) => {
      return !hasPermission(2021) ? (
        <Text type="ui-tiny-content" color="text-50">
          {`${row?.produtoDescricao}`}
        </Text>
      ) : (
        <Link
          type="ui-tiny-content"
          to={`/inventory/balance-adjustments/${row.externalId}/details`}
          style={{ width: 'fit-content' }}
        >
          {row?.produtoDescricao}
        </Link>
      );
    },
  },

  {
    title: t('supply.balanceAdjustment.list.number'),
    dataIndex: 'loteNumero',
    key: 'loteNumero',
    sortColumn: 'loteNumero',
    sorter: true,
    defaultSortOrder: 'ascend',
    isHighlightable: true,
  },
  {
    title: t('supply.balanceAdjustment.list.supplyPlace'),
    dataIndex: 'localEstoqueDescricao',
    key: 'localEstoqueDescricao',
    sortColumn: 'localEstoqueDescricao',
    sorter: true,
    isHighlightable: true,
    render: (x: any, row: any) => `${x} (${row.nomeEmpresa})`,
  },
  {
    title: t('supply.balanceAdjustment.list.moviment'),
    dataIndex: 'quantidadeDoAjuste',
    key: 'quantidadeDoAjuste',
    sortColumn: 'quantidadeDoAjuste',
    sorter: false,
    isHighlightable: false,
    render: (x: any, row: any) => (
      <Text
        type="ui-tiny-content"
        color={
          row.tipoOperacao === 1 ? 'status-danger-base' : 'status-success-base'
        }
      >
        <Text type={'ui-tiny-content'}>
          <CurrencyText
            value={x}
            suffix={` ${row?.unidadeMedida.abreviacao}`}
            decimalScale={4}
            customPrefix={row.tipoOperacao === 1 ? '-' : '+'}
          />
        </Text>
      </Text>
    ),
  },
  {
    title: (
      <Divflex>
        {t('supply.balanceAdjustment.list.balance')}
        <Tooltip
          title={t('supply.balanceAdjustment.list.toolTip.balance')}
          showMe={true}
          placement="topRight"
        >
          <Icon
            icon={'exclamation-upside-down'}
            size="S"
            color="text-400"
            style={{ marginLeft: '5px', marginTop: '4px' }}
          />
        </Tooltip>
      </Divflex>
    ),
    dataIndex: 'quantidade',
    key: 'quantidade',
    sortColumn: 'quantidade',
    sorter: false,
    isHighlightable: false,
    filterIcon: <div></div>,
    render: (x: any, row: any) => (
      <Text type={'ui-tiny-content'}>
        <CurrencyText
          value={x}
          suffix={` ${row.unidadeMedida.abreviacao}`}
          decimalScale={4}
        />
      </Text>
    ),
  },
];
