import { useCallback } from 'react';
import {
  FETCH_LIST_STANDARD_FORMULA
} from '@/ControllerApiHook/UniqIds/Production/StandardFormulaKeys';
import { history } from '@/Store';
import { 
  IStandardFormulaListData 
} from '@/Data/Interfaces/response/StandardFormula/IStandardFormulaResponse';
import { messageErrorToast } from '@/Utils/MessageErrorToast';
import { handleModalError } from '@/Utils/ErrorCodeUtils/ErrorHandlingUtils';
import { App } from 'antd';
import { 
  cannotDeleteStandardFormulaWithMovementsInTheSystemProps 
} from '@/Utils/ErrorCodeUtils/ErrorModalUtils/ErrorModalStandardFormula';
import { useQueryClient } from '@tanstack/react-query';
import { IUseFunctions } from './interfaces';
import { useTranslation } from 'react-i18next';

export const useListFunctions = ({
  deleteStandardFormula
}: IUseFunctions) => {
  const app = App.useApp();
  const queryClient = useQueryClient();
  const { t } = useTranslation();

  const onDeleteStandardFormula = useCallback(
    async (standardForm: IStandardFormulaListData[]) => {
      const res = await deleteStandardFormula.mutateAsync(
        { externalId: standardForm.map((x) => x?.externalId) },
        {
          onError: handleModalError(
            app,
            cannotDeleteStandardFormulaWithMovementsInTheSystemProps(t)
          ),
        }
      );

      if (res.success) {
        queryClient.invalidateQueries([FETCH_LIST_STANDARD_FORMULA]);
        history.push('/production/standard-formula');
      }
      if (res.error) messageErrorToast(res.error);
    },

    [deleteStandardFormula, queryClient]
  );

  return {
    onDeleteStandardFormula,
  };
};