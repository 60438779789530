import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Icon, Text } from '@/Components';
import { useWithoutSaleOrderCallAPI } from './Hooks/useWithoutSaleOrderCallAPI';

import styles from './WithoutSaleOrder.module.scss';

interface IWithoutSaleOrder {
  serviceExternalId: string;
}

export const WithoutSaleOrder: FC<IWithoutSaleOrder> = ({
  serviceExternalId,
}) => {
  const { t } = useTranslation();

  const { createSaleOrder } = useWithoutSaleOrderCallAPI();

  return (
    <div className={styles['container']}>
      <div className={styles['div-content']}>
        <Icon icon="cart" size="XXLL" color="text-500" />
        <Text
          type="ui-tiny-semibold"
          children={t('saleOrder.editOrder.withoutSaleOrder.title')}
          color="text-50"
        />
        <Text
          type="ui-tiny-content"
          children={t('saleOrder.editOrder.withoutSaleOrder.description')}
          color="text-200"
        />
        <Button
          type="primary"
          leftIcon="plus"
          children={t('saleOrder.editOrder.withoutSaleOrder.buttonText')}
          onClick={async () =>
            await createSaleOrder.mutateAsync({
              atendimentoExternalId: serviceExternalId,
            })
          }
          loading={createSaleOrder.isLoading}
        />
      </div>
    </div>
  );
};
