import { useCallback } from 'react';
import { IUseFunctions } from './interfaces';

export const useRegisterInputFunctions = ({
  refetchRecordTypeList,
}: IUseFunctions) => {
  const onSearch = useCallback(
    (search?: string) => {
      refetchRecordTypeList({
        search: search,
      });
    },
    [refetchRecordTypeList]
  );

  return { onSearch };
};
