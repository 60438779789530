import { FC } from "react";
import { TextArea } from '@/Components';
import { Col } from 'antd';
import { useTranslation } from "react-i18next";

export const Observation: FC = () => {
  const { t } = useTranslation();

  return (
    <Col span={24}>
      <TextArea
        name="observacao"
        label={t('prescribers.createPage.info.observation')}
        maxLength={500}
        heightTextArea={104}
        placeholder={t(
          'prescribers.createPage.info.observationPlaceholder'
        )}
      />
    </Col>
  );
};