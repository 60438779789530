import { useMemo } from "react";
import { useTranslation } from 'react-i18next';
import { Text } from '@/Components';
import ReactMarkdown from 'react-markdown';
import { FooterThisActionIsPermanent } from '@/Components/Table/components/Modals/DeleteModal';
import { IUseMemorizations } from "./interfaces";

export const useDeleteModalMemorizations = ({
  specialtie
}: IUseMemorizations) => {
  const { t } = useTranslation();

  const modalBody = useMemo(() => (
    <div style={{ padding: '20px' }}>
    <Text color="text-50" type="ui-tiny-content">
      <ReactMarkdown>
        {t('modal.deleteModal.single.productQuestion', {
          produto: `**${specialtie?.descricao}**`,
        })}
      </ReactMarkdown>
    </Text>
    <FooterThisActionIsPermanent />
  </div>
  ), [specialtie, t]);

  return {
    modalBody
  };
};