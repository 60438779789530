import {
  getPermissionDependencyModalData
} from '@/Store/Group/Group.selector';
import { useFormikContext } from "formik";
import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { UserGroupActions } from '@/Store/Group/Group.actions';

export const useRequiredPermissionModalFunctions = () => {
  const form = useFormikContext();
  const modalData = useSelector(getPermissionDependencyModalData);
  const dispatch = useDispatch();

  const onCloseHandler = useCallback(() => {
    dispatch(UserGroupActions.hidePermissionDependencyModal());

    // Revert form status
    form.setFieldValue(
      modalData?.data?.permissaoId!,
      !modalData?.request.ativo
    );
  }, [dispatch, form, modalData]);

  const onOkClick = useCallback(() => {
    if (modalData) {
      dispatch(
        UserGroupActions.updatePermission({
          ...modalData?.request,
          dependencias: modalData.data.dependencias.map(
            (dep) => dep.permissaoId
          ),
        })
      );
      modalData.data.dependencias.forEach((dep) => {
        form.setFieldValue(dep.permissaoId, modalData.request.ativo);
      });
    }
  }, [dispatch, form, modalData]);

  return { onCloseHandler, onOkClick };
};