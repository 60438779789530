import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Card,
  Checkbox,
  TextArea,
  Text
} from '@/Components';
import { Col, Row } from 'antd';
import _ from 'lodash';
import { IProductMessageDescription } from './interfaces';
import { useProductMessageDescriptionStates } from './Hooks/useProductMessageDescriptionStates';
import { useProductMessageDescriptionCallAPI } from './Hooks/useProductMessageDescriptionCallAPI';
import { useProductMessageDescriptionFunctions } from './Hooks/useProductMessageDescriptionFunctions';

import styles from './ProductMessageDescription.module.scss';

export const ProductMessageDescription: FC<IProductMessageDescription> = ({
  disabled
}) => {
  const { t } = useTranslation();

  const {
    hasErrorAlreadyExistsProductName,
    setHasErrorAlreadyExistsProductName
  } = useProductMessageDescriptionStates();

  const {
    onProductMessageError
  } = useProductMessageDescriptionFunctions({
    setHasErrorAlreadyExistsProductName
  });

  const {
    mutateAsync
  } = useProductMessageDescriptionCallAPI({
    onProductMessageError,
    setHasErrorAlreadyExistsProductName
  });

  const fetchProductMessageName = useMemo(
    () =>
      _.debounce(async (value: string) => {
        try {
          return await mutateAsync(value);
        } catch {
          return undefined;
        }
      }, 500),
    [mutateAsync]
  );

  return (
    <Card title={t('common.information')} withoutCollapse={true}>
      <Row gutter={[16, 0]} style={{ marginBottom: '20px' }}>
        <TextArea
          name="descricao"
          label={t('supply.productMessage.create.label')}
          placeholder={t('supply.productMessage.create.placeholder')}
          maxLength={5000}
          rows={1}
          heightTextArea={100}
          disable={disabled}
          onChange={fetchProductMessageName}
          error={hasErrorAlreadyExistsProductName}
          withStatusIcon
        />
      </Row>
      <Row gutter={[16, 0]} style={{ marginBottom: '20px' }}>
        <Col span={5}>
          <div className={styles['marginBottom']}>
            <Text
              children={t('supply.productMessage.create.primaryLabel')}
              type="ui-tiny-semibold"
              color="text-100"
            />
          </div>
          <Checkbox
            name="exibeVenda"
            label={t('supply.productMessage.create.checkBox1')}
            style={{
              fontWeight: 'var(--font-weight-light)',
              fontSize: 'var(--font-size-xs)',
              marginBottom: 5,
            }}
            disabled={disabled}
            checked={false}
          />
          <Checkbox
            name="exibeRotulagem"
            label={t('supply.productMessage.create.checkBox2')}
            style={{
              fontWeight: 'var(--font-weight-light)',
              fontSize: 'var(--font-size-xs)',
              marginBottom: 5,
            }}
            disabled={disabled}
            checked={false}
          />
        </Col>
        <Col span={9}>
          <div className={styles['marginBottom']}>
            <Text
              children={t('supply.productMessage.create.secondaryLabel')}
              type="ui-tiny-semibold"
              color="text-100"
            />
          </div>
          <Checkbox
            name="exibeFichaPesagem"
            label={t('supply.productMessage.create.checkBox3')}
            style={{
              fontWeight: 'var(--font-weight-light)',
              fontSize: 'var(--font-size-xs)',
              marginBottom: 5,
            }}
            disabled={disabled}
            checked={false}
          />
          <Checkbox
            name="exibeImpressaoRotulo"
            label={t('supply.productMessage.create.checkBox4')}
            style={{
              fontWeight: 'var(--font-weight-light)',
              fontSize: 'var(--font-size-xs)',
              marginBottom: 5,
            }}
            disabled={disabled}
            checked={false}
          />
        </Col>
      </Row>
    </Card>
  );
};

export default ProductMessageDescription;
