import { Button } from '@/Components';
import _ from 'lodash';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { history } from '../../../../../../../Store';
import { IFChildren } from '@/Components/Form';
import { TypePersonEnum } from '@/Data/Interfaces/model/typePersonEnum';

interface IFooterButtons {
  isLoadingCreateProvider: boolean;
  props: IFChildren;
}

export const FooterButtons: FC<IFooterButtons> = ({
  isLoadingCreateProvider,
  props,
}) => {
  const { t } = useTranslation();

  return (
    <div style={{ display: 'flex' }}>
      <Button
        type="secondary"
        style={{ marginRight: 8 }}
        onClick={() => history.push('/order/supplier')}
      >
        {t('common.cancel')}
      </Button>
      <Button
        type="primary"
        htmlType="submit"
        loading={isLoadingCreateProvider}
        children={
          isLoadingCreateProvider ? t('common.creating') : t('common.create')
        }
      />
    </div>
  );
};
