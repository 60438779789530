import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { PageLoading } from '@/Layouts/PageLoading';
import { HorizontalTabs } from '@/Components';
import { DeleteSpecialtieModal } from './Modal/DeleteModal';
import { useEditStates } from './Hooks/useEditStates';
import { useEditCallAPI } from './Hooks/useEditCallAPI';
import { useEditFunctions } from './Hooks/useEditFunctions';
import { useEditMemorizations } from './Hooks/useEditMemorizations';

export const EditExpertise: FC = () => {
  const { t } = useTranslation();

  const { disabledForm, setDisabledForm, visibleModal, setVisibleModal } =
    useEditStates();

  const { data, isLoading } = useEditCallAPI();

  const { items, rightButton, dropdownItems } = useEditMemorizations({
    disabledForm,
    setDisabledForm,
    setVisibleModal,
  });

  const { changeVisibleModal } = useEditFunctions({ setVisibleModal });

  return (
    <PageLoading loading={isLoading}>
      <HorizontalTabs
        type="secondary"
        items={items}
        dropdownDirection="bottomRight"
        dropdownTitle={t('common.moreActions')}
        leftIcon="menu-ellipsis-horizontal"
        rightButton={rightButton}
        dropdownItens={dropdownItems}
      />
      <DeleteSpecialtieModal
        specialtie={data || undefined}
        visibleModal={visibleModal}
        changeVisibleModal={changeVisibleModal}
      />
    </PageLoading>
  );
};
