import { useState } from 'react';

export const useProductLineStates = () => {
  const [classeProdutoId, setClasseProdutoId] = useState<number | undefined>();

  return {
    classeProdutoId,
    setClasseProdutoId,
  };
};
