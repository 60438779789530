import { useTranslation } from 'react-i18next';
import { IUseMemorizations } from './interfaces';
import { useFormikContext } from 'formik';
import { ICapsuleColorListData } from '@/Data/Interfaces/response/CapsuleColors/ICapsuleColorsResponse';

export const useCapsuleColorMemorizations = ({
  product,
  disabledForm,
  listCapsuleColor,
}: IUseMemorizations) => {
  const { t } = useTranslation();
  const form: any = useFormikContext();

  const title = `${t('products.toolTips.products.originalValue')} ${
    product && product.tipoCapsula
      ? product?.tipoCapsula?.capsulaCorDescricao ??
        t('products.toolTips.products.empty')
      : ''
  }`;

  const showMe =
    product &&
    !disabledForm &&
    product?.tipoCapsula?.capsulaCorExternalId !==
      form?.values?.capsulaCorExternalId;

  const items = listCapsuleColor.data
  ? listCapsuleColor.data.map((e: ICapsuleColorListData) => ({
      id: e.externalId,
      label: e.corCapsula,
    }))
  : [];

  return {
    title,
    showMe,
    items,
  };
};
