import { useCallback } from 'react';
import { IEditFunctions } from './interfaces';
import DateUtils from '@/Utils/DateUtils';
import StorageUtils from '@/Utils/StorageUtils';

export const useEditFunctions = ({ editLoss, externalId }: IEditFunctions) => {
  const preferences = StorageUtils.getPreference();

  const onSubmit = useCallback(async (values: any) => {
    return await editLoss.mutateAsync({
      perdaExternalId: externalId,
      dataPerda: DateUtils.convertDateToApi(
        values.lossDate,
        preferences.padraoData
      ),
      motivoPerdaExternalId: values.lossReason,
      observacao: values.comments,
    });
  }, []);

  return { onSubmit };
};
