import { FETCH_LIST_INVOICE } from '../../../../../../ControllerApiHook/UniqIds/Purchase/InvoiceKeys';
import { history } from '../../../../../../Store';
import { currencyFormater } from '../../../../../../Utils/Currency';
import i18n from 'i18next';

const formatNCM = (ncm: string): string => {
  if (!ncm) return '';
  const firstPart = ncm.substring(0, 3);
  const secondPart = ncm.substring(3, 5);
  const thirdPart = ncm.substring(5, 8);

  return `${firstPart}.${secondPart}.${thirdPart}`;
};

const formatQuantity = (quantity: number): string => {
  return `${quantity},0000`;
};

export const formatProduct = (product: any) => {
  return {
    ...product,
    ncmDescricao: formatNCM(product.ncmDescricao),
    valorUnitario: currencyFormater(product.valorUnitario),
    quantidadeComprada: formatQuantity(product.quantidadeComprada),
    total: currencyFormater(product.total),
    baseCalculoIcms: currencyFormater(product.baseCalculoIcms),
    valorIcms: currencyFormater(product.valorIcms),
    valorIpi: currencyFormater(product.valorIpi),
  };
};

export const defineIconToProductRow = (status: 1 | 2 | 3): any => {
  const icon = {
    1: 'time',
    2: 'cube',
    3: 'check',
  };

  return icon[status];
};

export const defineIconTypeToProductRow = (status: 1 | 2 | 3): any => {
  const iconType = {
    1: 'default',
    2: 'attention',
    3: 'success',
  };

  return iconType[status];
};

export const defineProductToolTip = (status: 1 | 2 | 3): any => {
  const toolTip = {
    1: i18n.t('purchasing.invoice.details.lotNotThrew'),
    2: i18n.t('purchasing.invoice.details.lotThrew'),
    3: i18n.t('purchasing.invoice.details.lotThrewAndMoving'),
  };

  return toolTip[status];
};

export const deleteInvoiceFunction = async (
  invoiceId: string,
  deleteHook: any,
  queryClient: any,
  openCanNotDeleteModal: () => void
) => {
  try {
    const res = await deleteHook.mutateAsync({
      notaFiscalEntradaExternalId: [invoiceId],
    });
    if (res.success) {
      queryClient.invalidateQueries([FETCH_LIST_INVOICE]);
      history.push(`/order/invoice`);
    }
  } catch (error: any) {
    const errorCode = error?.errorCode;
    if (errorCode === 'ComprasErrorCode-2') {
      openCanNotDeleteModal();
    }
  }
};
