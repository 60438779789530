import { Text } from '../../../../../Components/Text';
import { hasPermission } from '../../../../../Components/PermissionWrapper';
import { Link } from '../../../../../Components';
import { Avatar } from '../../../../../Components/Avatar';
import { CurrencyText } from '../../../../../Components/CurrencyText';
import DateUtils from '@/Utils/DateUtils';
import { IPreferences } from '@/Utils/ThemeUtils';

export const lossesColumns = (t: any, preference?: IPreferences) => [
  {
    title: t('supply.losses.list.lossDate'),
    dataIndex: 'dataPerda',
    key: 'dataPerda',
    sortColumn: 'dataPerda',
    width: '12%',
    sorter: true,
    isHighlightable: true,
    defaultSortOrder: 'ascend',
    render: (data: string, row: any) => {
      return !hasPermission(2017) ? (
        <Text type="ui-tiny-bold" color="text-50">
          {`${DateUtils.convertDate(data, preference?.padraoData)} ${t(
            'common.dateBreakApart'
          )} ${DateUtils.convertDate(data, preference?.padraoHora)}`}
        </Text>
      ) : (
        <Link
          type="ui-tiny-bold"
          to={`/inventory/losses/${row.externalId}/details`}
          style={{ width: 'fit-content' }}
        >
          {`${DateUtils.convertDate(data, preference?.padraoData)} ${t(
            'common.dateBreakApart'
          )} ${DateUtils.convertDate(data, preference?.padraoHora)}`}
        </Link>
      );
    },
  },
  {
    title: t('supply.losses.list.product'),
    dataIndex: 'produtoDescricao',
    key: 'produtoDescricao',
    sortColumn: 'produtoDescricao',
    width: '20%',
    isHighlightable: true,
    render: (data: string, row: any) => {
      return !hasPermission(2017) ? (
        <Text type="ui-tiny-content" color="text-50">
          {`${data}`}
        </Text>
      ) : (
        <Link
          type="ui-tiny-content"
          to={`/inventory/losses/${row.externalId}/details`}
          style={{ width: 'fit-content' }}
        >
          {`${data}`}
        </Link>
      );
    },
  },
  {
    title: t('supply.losses.list.number'),
    dataIndex: 'loteNumero',
    key: 'loteNumero',
    isHighlightable: true,
  },

  {
    title: t('supply.losses.list.supplyPlace'),
    dataIndex: 'localEstoqueDescricao',
    key: 'localEstoqueDescricao',
    isHighlightable: true,
    sortColumn: 'localEstoqueDescricao',
    render: (stockLocation: string, company: any) =>
      `${stockLocation} (${company.empresaNome})`,
  },
  {
    title: t('supply.losses.list.quantity'),
    dataIndex: 'quantidade',
    key: 'quantidade',
    isHighlightable: false,
    sortColumn: 'quantidade',
    render: (quantidy: any, unity: any) => (
      <Text type={'ui-tiny-content'}>
        <CurrencyText
          value={quantidy}
          suffix={` ${unity.unidadeAbreviacao}`}
          decimalScale={4}
        />
      </Text>
    ),
  },

  {
    title: t('supply.losses.list.owner'),
    dataIndex: 'usuarioNome',
    key: 'usuarioNome',
    isHighlightable: true,
    sortColumn: 'usuarioNome',
    render: (name: string, surname: any) => (
      <Avatar
        size="M"
        type="ui-tiny-bold"
        fisrtName={name}
        lastName={surname.usuarioSobrenome}
        description={{
          description: `${name} ${surname.usuarioSobrenome}`,
          type: 'ui-tiny-content',
          color: 'text-50',
        }}
        color="white"
      />
    ),
  },
];
