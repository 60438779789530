import { getMenuById } from '@/Components/SideMenu/SideMenu.items';
import { useControllerQueryApiHook } from '@/ControllerApiHook/Controller';
import { GET_ME } from '@/ControllerApiHook/UniqIds/Accountmanager/UserKeys';
import { UserAPI } from '@/Data/API/Accountmanager/UserApi';
import { IGetUserResponse } from '@/Data/Interfaces/response/User/IGetUserReponse';
import { AuthenticatedRoutes, UnauthenticatedRoutes } from '@/Routes';
import { history } from '@/Store';
import { ChangeLanguage } from '@/Utils/ChangeLanguageUtils';
import { useQueryClient } from '@tanstack/react-query';
import React, { useCallback, useEffect } from 'react';

export type CurrentContextType = {
  updateContext: (context?: string) => void;
  currentContext?: string;
};

export const Context = React.createContext<CurrentContextType | undefined>(
  undefined
);

const ContextProvider: React.FC<{ isAuthenticated: boolean }> = ({
  isAuthenticated,
}) => {
  const [currentContext, setCurrentContext] = React.useState<
    string | undefined
  >(undefined);

  const queryClient = useQueryClient();

  const updateContext = useCallback(
    (context?: string) => setCurrentContext(context),
    []
  );

  const { data: getMe } = useControllerQueryApiHook<IGetUserResponse>({
    uniqId: GET_ME,
    entityApi: UserAPI.getMe,
    onSuccess: (value: IGetUserResponse) => {
      const menu = getMenuById(value.modulosSistema[0]);
      if (!currentContext && menu?.items[0].link) {
        history.push(menu?.items[0].link);
        setCurrentContext(value.modulosSistema[0]);
      }
      ChangeLanguage(value.preferencias.idioma);
    },
    enabled: !!isAuthenticated,
  });

  useEffect(() => {
    if (!isAuthenticated) setCurrentContext(undefined);
    else {
      queryClient.invalidateQueries([GET_ME]);
    }
  }, [isAuthenticated, queryClient]);

  return (
    <Context.Provider value={{ currentContext, updateContext }}>
      {isAuthenticated ? (
        <AuthenticatedRoutes getMe={getMe} />
      ) : (
        <UnauthenticatedRoutes />
      )}
    </Context.Provider>
  );
};

export default ContextProvider;
