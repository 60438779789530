import { CreateCustomers } from '@/Pages/Sectors/Sales/Customers/Create';
import { EditCustomers } from '@/Pages/Sectors/Sales/Customers/Edit';
import { List } from '@/Pages/Sectors/Sales/Customers/List';
import { Route, Switch } from 'react-router-dom';

export const CustomersRoutes = () => (
  <Switch>
    <Route path="/sales/customers/:externalId/:tab" component={EditCustomers} />
    <Route path="/sales/customers/create" component={CreateCustomers} />
    <Route path="/sales/customers" component={List} />
  </Switch>
);
