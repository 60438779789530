import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Divflex } from '../../../../../../../../Layouts/DivWhithFlex';
import { Text } from '../../../../../../../../Components';
import { FooterThisActionIsPermanent } from '../../../../../../../../Components/Table/components/Modals/DeleteModal';
import ReactMarkdown from 'react-markdown';

interface ILinkedProduct {
  selectedRowsOnTable: any;
  packingClassificationSelected?: string;
}

export const RemoveLinkedModal: FC<ILinkedProduct> = ({
  selectedRowsOnTable,
  packingClassificationSelected,
}) => {
  const { t } = useTranslation();
  return (
    <div style={{ padding: '20px' }}>
      <Divflex
        style={{ flexDirection: 'column', marginBottom: 10, width: '400px' }}
      >
        <Text type="ui-tiny-content" withoutMarginBottom>
          <ReactMarkdown>
            {t(
              'production.packingClassification.linkedList.modalRemoveLinked.messageStartBody',
              {
                action: `**"${selectedRowsOnTable[0]?.descricao}"** ${t(
                  'production.packingClassification.linkedList.modalRemoveLinked.messageFinishBody'
                )}"**${packingClassificationSelected}**"?`,
              }
            )}
          </ReactMarkdown>
        </Text>
      </Divflex>
      <FooterThisActionIsPermanent />
    </div>
  );
};
