import { Icon } from '@/Components';
import { IconName } from '@/Components/Icon/IconClasses';
import { FC } from 'react';

import styles from './StaticOptionsDropdown.module.scss';

interface IStaticOptionsDropdown {
  menu: React.ReactElement<any, string | React.JSXElementConstructor<any>>;
  inputDataTestId?: string;
  name?: string;

  staticOptions: {
    name: string;
    icon: IconName;
    onClick: () => void;
  }[];
}

export const StaticOptionsDropdown: FC<IStaticOptionsDropdown> = ({
  menu,
  staticOptions,
  inputDataTestId,
  name
}) => (
  <>
    {menu}
    <div className={styles['divider']} />
    {staticOptions.map((option, i) => (
      <div
        id={option.name}
        data-testid={`${inputDataTestId || name}-static-${i}`}
        key={option.name}
        onClick={option.onClick}
        className={styles['static-option']}
      >
        <Icon icon={option.icon} size="XXL" color="text-300" />
        <span className={styles['text']}>{option.name}</span>
      </div>
    ))}
  </>
);
