import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Modal } from '@/Components/Modal';
import { IApproveModal } from './interfaces';
import { 
  useApproveOrderModalMemorizations 
} from './Hooks/useApproveOrderModalMemorizations';

export const ApproveOrderModal: FC<IApproveModal> = ({
  isVisible,
  onCancelClick,
  onOkClick,
  onClose,
  okButtonName,
  loading,
}) => {
  const { t } = useTranslation();

  const {
    hasProducts,
    body
  } = useApproveOrderModalMemorizations();

  return (
    <Modal
      title={t('purchasing.purchaseOrder.modal.approve.title')}
      body={body}
      onCancelClick={onCancelClick}
      visible={isVisible}
      onOkClick={onOkClick}
      onClose={onClose}
      okButtonName={okButtonName}
      loading={loading}
      okButtonLeftIcon={!loading ? 'check' : undefined}
      okButtonStatus="success"
      minWidth={750}
      disabledButton={!hasProducts}
    />
  );
};
