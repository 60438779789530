import { useTranslation } from 'react-i18next';
import { IUseMemorizations } from './interfaces';
import { useFormikContext } from 'formik';
import { ICapsuleTypeListData } from '@/Data/Interfaces/response/CapsuleType/ICapsuleTypeResponse';

export const useCapsuleTypeMemorizations = ({
  product,
  disabledForm,
  listCapsuleType,
}: IUseMemorizations) => {
  const { t } = useTranslation();
  const form: any = useFormikContext();

  const title = `${t('products.toolTips.products.originalValue')} ${
    product && product.tipoCapsula
      ? product.tipoCapsula.tipoCapsulaDescricao ??
        t('products.toolTips.products.empty')
      : ''
  }`;

  const showMe =
    product &&
    !disabledForm &&
    product?.tipoCapsula?.numeroCapsulaId !== form?.values?.tipoCapsulaId;

  const items = listCapsuleType.data
  ? listCapsuleType.data.map((e: ICapsuleTypeListData) => ({
      id: e.id,
      label: e.descricao,
    }))
  : [];

  return {
    title,
    showMe,
    items,
  };
};
