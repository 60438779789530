import { IUseFunctions } from "./interfaces";

export const useProductCardFunctions = ({
  setProductRowAmount
}: IUseFunctions) => {
  const removeRow = (index: number) => {
    setProductRowAmount((oldState: string[]) => {
      return oldState.filter((_: string, i: number) => i !== index);
    });
  };

  return {
    removeRow
  };
};