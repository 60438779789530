import { FC } from "react";
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { TextInput } from '@/Components';
import { Col } from 'antd';

export const CorporateName: FC = () => {
  const { t } = useTranslation();
  const { externalId } = useParams<{ externalId: string }>();

  return (
    <Col span={9}>
      <TextInput
        name="razaoSocial"
        label={t('supply.lot.details.details.corporateName')}
        placeHolder={t('supply.lot.details.details.corporateName')}
        disabled={!!externalId}
      />
    </Col>
  );
};