import { Divflex } from '@/Layouts/DivWhithFlex';
import { Text } from '@/Components';
import { FooterThisActionIsPermanent } from '@/Components/Table/components/Modals/DeleteModal';
import { DeleteModalOnTable } from '@/Utils/DeleteModalUtils';
import ReactMarkdown from 'react-markdown';
import { IProductListData } from '@/Data/Interfaces/response/Product/IProductRespose';
import { TFunction } from 'react-i18next';

export const TitleHandler = (
  selectedRowsOnTable: IProductListData[], 
  t: TFunction<"translation", undefined>
) =>
  selectedRowsOnTable?.length === 1
    ? `${t('common.delete')} ${t('common.product')} "${
        selectedRowsOnTable[0]?.descricao
      }"?`
    : `${t('modal.delete')} ${selectedRowsOnTable?.length} ${t(
        'common.products'
      )}?`;

export const BodyHandler = (
  selectedRowsOnTable: IProductListData[], 
  t: TFunction<"translation", undefined>
) =>
  selectedRowsOnTable?.length > 1 ? (
    DeleteModalOnTable(
      selectedRowsOnTable,
      `${t('modal.deleteModal.many.title')} ${t('common.theProducts')}:`,
      'descricao',
      t('common.products'),
      undefined,
      4
    )
  ) : (
    <>
      <div style={{ padding: '20px' }}>
        <Divflex style={{ flexDirection: 'column', marginBottom: 15 }}>
          <Text color="text-50" type="ui-tiny-content">
            <ReactMarkdown>
              {t('modal.deleteModal.single.productQuestion', {
                produto: `**${
                  selectedRowsOnTable && selectedRowsOnTable[0]?.descricao
                }**`,
              })}
            </ReactMarkdown>
          </Text>
        </Divflex>
        <FooterThisActionIsPermanent />
      </div>
    </>
  );
