import { Col, Row } from 'antd';
import { CircularStatus } from '@/Components/CircularStatus';
import { WeightInput } from '@/Components/Inputs/WeightInput';
import { FETCH_LIST_UNIT_MEASUREMENT_FOR_DROPDOWN_TYPE } from '@/ControllerApiHook/UniqIds/Supply/UnitMeasurementKeys';
import { Button, Icon, TextInput } from '@/Components';
import { TextInputWithMask } from '@/Components/Inputs/TextInputWithMask';
import DateUtils from '@/Utils/DateUtils';
import { Tooltip } from '@/Components/Tooltip';
import { useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';
import { useCallback, useEffect, useState } from 'react';
import {
  configColumnsSpacing,
  debounceQuantity,
  defineLotStatus,
  formatQuantityExcess,
} from '../../utils/functions';
import { CONVERT_UNIT_OF_MEASURE } from '@/ControllerApiHook/UniqIds/Supply/SupplyKeys';
import useMediaQuery from '@/Utils/CustomHooks/useMediaQuery';
import {
  useControllerMutateApiHook,
  useControllerQueryListApiHook,
} from '@/ControllerApiHook/Controller';
import { UnitMeasurementAPI } from '@/Data/API/Supply/UnitMeasurementApi';
import { IIListUnitMeasurementAllData } from '@/Data/Interfaces/response/UnitMeasurement/IUnitMeasurementResponse';
import StorageUtils from '@/Utils/StorageUtils';

import styles from './productRow.module.scss';

interface IProductRowProps {
  product: any;
  index: number;
  selectedRow: number | null;
  selectRow: (rowNumber: number) => void;
  showTechnicalInformation: boolean;
  showTechInformation: () => void;
  lotsListController: any[];
  createNewLot: (itemId: number) => void;
  removeLot: (uuid: string) => void;
  selectLotIndex: (index: number) => void;
  setLotErrorNamesIndexes: (value: number[]) => void;
  lotErrorNamesIndexes: number[];
  savedLots: any[];
  setSubmitButtonState: (value: boolean) => void;
}

interface ICalendarInputProps {
  name: string;
  placeHolder: string;
  label: string;
  error: boolean;
  errorMessage: string;
  onClick?: () => void;
  onChange?: (value: string) => void;
}

export const ProductRow: React.FC<IProductRowProps> = ({
  product,
  selectedRow,
  selectRow,
  showTechnicalInformation,
  showTechInformation,
  lotsListController,
  createNewLot,
  removeLot,
  selectLotIndex,
  setLotErrorNamesIndexes,
  lotErrorNamesIndexes,
  setSubmitButtonState,
}) => {
  const form: any = useFormikContext();
  const preferences = StorageUtils.getPreference();
  const { t } = useTranslation();
  const [lots, setLots] = useState<any[]>([]);
  const [productExcess, setProductExcess] = useState<number>(0);
  const [productDeficit, setProductDeficit] = useState<number>(0);
  const [tooltipQuantity, setTooltipQuantity] = useState<string>('');
  const showDatesWidth = '2133px';
  const keepDatesOnScreen = useMediaQuery(`(min-width: ${showDatesWidth})`);

  const { firstColumn, restColumns } = configColumnsSpacing(
    keepDatesOnScreen,
    showTechnicalInformation
  );

  const [productQuantity, setProductQuantity] = useState<number>(
    product.quantidadeComprada
  );
  const [currentUnitId, setCurrentUnitId] = useState<{
    unitId: number;
    unitAbbreviation: string;
  }>({
    unitId: product.unidadeMedidaId,
    unitAbbreviation: product.unidadeMedidaAbreviacao,
  });

  const { mutateAsync: convertUnitOfMeasureAsync }: any =
    useControllerMutateApiHook({
      uniqId: CONVERT_UNIT_OF_MEASURE,
      entityApi: UnitMeasurementAPI.convertUnitOfMeasure,
    });

  const { data: unitOfMeasure } = useControllerQueryListApiHook({
    uniqId: FETCH_LIST_UNIT_MEASUREMENT_FOR_DROPDOWN_TYPE,
    entityApi: UnitMeasurementAPI.getListUnitMeasurementDropDown,
    pagination: {
      pageSize: 50,
      filter: [{ filterName: 'tipoUnidade', value: product.tipoUnidade }],
    },
  });

  const validateDates = (
    lotDataIndex: number
  ): {
    error: boolean;
    messageExpirationDate?: string;
    errorReason?: string;
    messageManufacturingDate?: string;
  } => {
    const formManufacturingDate =
      form.values.lotes[lotDataIndex]?.dataFabricacao;
    const formExpirationDate = form.values.lotes[lotDataIndex]?.dataValidade;
    const formReleaseDate = form.values.dataLancamento;
    const formDeliveryDate = form.values.dataEntrega;

    if (formManufacturingDate && formExpirationDate) {
      const manufacturingDate = parseDate(formManufacturingDate);
      const expirationDate = parseDate(formExpirationDate);
      if (manufacturingDate > expirationDate) {
        return {
          error: manufacturingDate > expirationDate,
          messageExpirationDate: t(
            'purchasing.invoice.registerLots.expiryDateError'
          ),
          messageManufacturingDate: t(
            'purchasing.invoice.registerLots.manufacturingDateError'
          ),
          errorReason: 'manufacturingDate',
        };
      }
    }

    if (formDeliveryDate && formManufacturingDate) {
      const manufacturingDate = parseDate(formManufacturingDate);
      const deliveryDate = parseDate(formDeliveryDate);

      if (manufacturingDate > deliveryDate) {
        return {
          error: manufacturingDate > deliveryDate,
          messageManufacturingDate: t(
            'purchasing.invoice.registerLots.releaseDateErrorManufacturing'
          ),
          errorReason: 'manufacturingDate',
        };
      }
    }

    if (formReleaseDate && formExpirationDate) {
      const expirationDate = parseDate(formExpirationDate);
      const releaseDate = parseDate(formReleaseDate);
      if (releaseDate > expirationDate) {
        return {
          error: releaseDate > expirationDate,
          messageExpirationDate: t(
            'purchasing.invoice.registerLots.releaseDateError'
          ),
          errorReason: 'releaseDate',
        };
      }
    }

    return { error: false };
  };

  const onUnitOfMeasureChange = useCallback(
    async (lotIndex: number, unitId: number) => {
      const currentUnitId = form.values.lotes[lotIndex].unidadeMedidaId;
      const valuesToConvert = lots.map((e) => {
        return {
          quantidade: form.values.lotes[e.lotIndex].quantidade,
          unidadeMedidaConversaoId: unitId,
          unidadeMedidaOrigemId: form.values.lotes[e.lotIndex].unidadeMedidaId,
        };
      });

      const result: any = await convertUnitOfMeasureAsync([
        {
          quantidade: productQuantity,
          unidadeMedidaConversaoId: unitId,
          unidadeMedidaOrigemId: currentUnitId,
        },
        ...valuesToConvert,
      ]);

      const totalConfig = result?.shift();

      lots.forEach((e, i) => {
        form.setFieldValue(
          `lotes.${e.lotIndex}.quantidade`,
          result[i].quantidadeConvertida
        );
        form.setFieldValue(
          `lotes.${e.lotIndex}.unidadeMedidaId`,
          result[i].unidadeMedidaConversaoId
        );
      });

      setProductQuantity(
        totalConfig ? totalConfig.quantidadeConvertida : productQuantity
      );
    },
    [convertUnitOfMeasureAsync, form, lots, productQuantity]
  );

  useEffect(() => {
    const indexedLots = lotsListController.map((e, index) => {
      return { ...e, lotIndex: index };
    });

    const initialLots = indexedLots.filter(
      (e) => e.itemId === product.notaFiscalEntradaItemId
    );

    setLots(initialLots);
  }, [lotsListController]);

  useEffect(() => {
    if (productDeficit === 0) {
      setTooltipQuantity(
        t('purchasing.invoice.registerLots.quantityError', {
          excess: `${formatQuantityExcess(productExcess)} ${
            currentUnitId.unitAbbreviation
          }`,
        })
      );
    } else {
      setTooltipQuantity(
        t('purchasing.invoice.registerLots.deficitError', {
          deficit: `${formatQuantityExcess(productDeficit)} ${
            currentUnitId.unitAbbreviation
          }`,
        })
      );
    }
  }, [productDeficit]);

  return (
    <>
      {lots.map((e, indexLot) => {
        return (
          <Row
            key={e.uuid}
            wrap={false}
            gutter={[16, 0]}
            className={`${styles['productRow']} ${
              selectedRow === e.lotIndex ? styles['selectedRow'] : ''
            }`}
            onClick={() => {
              selectRow(e.lotIndex);
              showTechInformation();
              selectLotIndex(e.lotIndex);
            }}
          >
            <Col span={firstColumn}>
              <div
                className={styles['productMainInfo']}
                style={{ display: indexLot !== 0 ? 'none' : '' }}
              >
                <CircularStatus
                  icon={defineIconToProductRow(
                    defineLotStatus(
                      form,
                      productQuantity,
                      product.notaFiscalEntradaItemId,
                      product.classeProdutoDescricao,
                      !!productDeficit
                    )
                  )}
                  type={defineColorTypeToProductRow(
                    defineLotStatus(
                      form,
                      productQuantity,
                      product.notaFiscalEntradaItemId,
                      product.classeProdutoDescricao,
                      !!productDeficit
                    )
                  )}
                  color={defineColorTypeToProductRow(
                    defineLotStatus(
                      form,
                      productQuantity,
                      product.notaFiscalEntradaItemId,
                      product.classeProdutoDescricao,
                      !!productDeficit
                    )
                  )}
                />
                <div className={styles['textContainer']}>
                  <p className={styles['productDescription']}>
                    {product.produtoDescricao}
                  </p>
                  <p className={styles['productQuantity']}>
                    {formatQuantity(
                      product.quantidadeComprada,
                      preferences ? preferences.idioma : 'pt-BR'
                    )}{' '}
                    {product.unidadeMedidaAbreviacao}
                  </p>
                </div>
              </div>
            </Col>
            <Col
              span={restColumns}
              className={styles['alignVerticalContent']}
              onClick={(e) => e.stopPropagation()}
            >
              <Tooltip
                title={tooltipQuantity}
                showMe={!!productExcess || !!productDeficit}
              >
                <WeightInput
                  error={!!productExcess || !!productDeficit}
                  name={`lotes.${e.lotIndex}.quantidade`}
                  dropdownRight={{
                    options:
                      unitOfMeasure && unitOfMeasure.data
                        ? unitOfMeasure.data.map(
                            (e: IIListUnitMeasurementAllData) => ({
                              ...e,
                              content: e.unidadeAbreviacao,
                            })
                          )
                        : [],
                    name: `lotes.${e.lotIndex}.unidadeMedidaId`,
                    onChange: async (unitId: any) => {
                      await onUnitOfMeasureChange(e.lotIndex, unitId);
                      const newUnitAbbreviation = unitOfMeasure.filter(
                        (e: IIListUnitMeasurementAllData) => {
                          return e.id === +unitId;
                        }
                      )[0].unidadeAbreviacao;
                      setCurrentUnitId({
                        unitAbbreviation: newUnitAbbreviation,
                        unitId: unitId,
                      });
                    },
                  }}
                  placeHolder="0,0000"
                  required
                  onChange={(value) => {
                    debounceQuantity.cancel();
                    let totalQuantity = 0;
                    const formLots = form.values.lotes;
                    const lastLotProductIndex = lots[lots.length - 1].lotIndex;
                    const lastProductLot = formLots[lastLotProductIndex];
                    let realValue = value.replace('.', '');
                    realValue = +realValue.replace(',', '.');

                    const filteredLots = form.values.lotes.filter(
                      (lot: any, i: number) => {
                        const firstCondition = i !== e.lotIndex;
                        const lastCondition =
                          lot.notaFiscalEntradaItemId ===
                          product.notaFiscalEntradaItemId;
                        return firstCondition && lastCondition;
                      }
                    );

                    totalQuantity += realValue;

                    filteredLots.forEach((lot: any) => {
                      totalQuantity += lot.quantidade ?? 0;
                    });

                    if (totalQuantity > productQuantity) {
                      const excess = totalQuantity - productQuantity;
                      setProductExcess(excess);
                    } else {
                      setProductExcess(0);
                    }

                    const conditionOne = totalQuantity < productQuantity;
                    const conditionTwo = totalQuantity > 0 && realValue;
                    const conditionThree = indexLot === lots.length - 1;

                    const conditionFour =
                      conditionOne && lastProductLot.quantidade && realValue;

                    debounceQuantity(() => {
                      if (
                        (conditionOne && conditionTwo && conditionThree) ||
                        conditionFour
                      ) {
                        const newLotIndex = form.values.lotes.length;
                        form.setFieldValue(`lotes.${newLotIndex}`, {
                          unidadeMedidaId: currentUnitId.unitId,
                          notaFiscalEntradaItemId:
                            product.notaFiscalEntradaItemId,
                          quantidade: productQuantity - totalQuantity,
                          produtoEntradaItemExternalId:
                            product.produtoExternalId,
                          fornecedorExternalId:
                            form.values.fornecedorExternalId,
                          invalid: true,
                        });
                        setSubmitButtonState(false);
                        setProductDeficit(0);
                        createNewLot(product.notaFiscalEntradaItemId);
                      }

                      if (
                        totalQuantity === productQuantity &&
                        !lastProductLot.quantidade &&
                        e.lotIndex !== lastLotProductIndex
                      ) {
                        const newFormLots = form.values.lotes.filter(
                          (_: any, index: number) => {
                            return index !== lastLotProductIndex;
                          }
                        );
                        form.values.lotes = newFormLots;
                        removeLot(lots[lots.length - 1].uuid);
                      }
                    });
                  }}
                />
              </Tooltip>
            </Col>
            <Col
              span={restColumns}
              className={styles['alignVerticalContent']}
              onClick={(e) => e.stopPropagation()}
            >
              <Tooltip
                title={t('purchasing.invoice.registerLots.lotNameError')}
                showMe={lotErrorNamesIndexes.includes(e.lotIndex)}
              >
                <TextInput
                  error={lotErrorNamesIndexes.includes(e.lotIndex)}
                  name={`lotes.${e.lotIndex}.numeroLote`}
                  placeHolder={t(
                    'purchasing.invoice.registerLots.lotPlaceHolder'
                  )}
                  onChange={(lotNumber) => {
                    let hasError = false;
                    const filteredLots = form.values.lotes.filter(
                      (element: any) => {
                        return (
                          element.notaFiscalEntradaItemId ===
                          product.notaFiscalEntradaItemId
                        );
                      }
                    );
                    filteredLots.forEach((lot: any) => {
                      if (lot.numeroLote === lotNumber && !!lotNumber) {
                        hasError = true;
                      }
                    });
                    if (hasError) {
                      const newLotErrorNamesIndexes: number[] = [
                        ...lotErrorNamesIndexes,
                        e.lotIndex,
                      ];
                      setLotErrorNamesIndexes(newLotErrorNamesIndexes);
                    } else {
                      const newLotErrorNamesIndexes: number[] =
                        lotErrorNamesIndexes.filter((indexLotName) => {
                          return indexLotName !== e.lotIndex;
                        });
                      setLotErrorNamesIndexes(newLotErrorNamesIndexes);
                    }
                  }}
                />
              </Tooltip>
            </Col>
            <Col
              span={restColumns}
              className={`${styles['alignVerticalContent']} ${
                showTechnicalInformation && !keepDatesOnScreen
                  ? styles['hideInformation']
                  : ''
              }`}
              onClick={(e) => e.stopPropagation()}
            >
              <CalendarInput
                name={`lotes.${e.lotIndex}.dataFabricacao`}
                placeHolder={configDatePlaceHolder(preferences) as string}
                error={!!validateDates(e.lotIndex).messageManufacturingDate}
                errorMessage={
                  validateDates(e.lotIndex).messageManufacturingDate ?? ''
                }
                label=""
              />
            </Col>
            <Col
              span={restColumns}
              className={`${styles['alignVerticalContent']} ${
                showTechnicalInformation && !keepDatesOnScreen
                  ? styles['hideInformation']
                  : ''
              }`}
              onClick={(e) => e.stopPropagation()}
            >
              <CalendarInput
                name={`lotes.${e.lotIndex}.dataValidade`}
                placeHolder={configDatePlaceHolder(preferences) as string}
                error={!!validateDates(e.lotIndex).messageExpirationDate}
                errorMessage={
                  validateDates(e.lotIndex).messageExpirationDate ?? ''
                }
                label=""
              />
            </Col>
            <Col
              className={`${styles['alignVerticalContent']} ${
                showTechnicalInformation ? styles['hideInformation'] : ''
              }`}
              onClick={(e) => e.stopPropagation()}
            >
              <div style={{ display: lots.length !== 1 ? 'block' : 'none' }}>
                <Button
                  type="secondary"
                  style={{
                    display: 'none',
                  }}
                  onClick={() => {
                    const lotQuantity =
                      form.values.lotes[e.lotIndex].quantidade;
                    const newFormLots = form.values.lotes.filter(
                      (_: any, index: number) => {
                        return index !== e.lotIndex;
                      }
                    );
                    form.setFieldValue('lotes', newFormLots);
                    setSubmitButtonState(true);
                    setProductDeficit(lotQuantity ?? 0);
                    removeLot(e.uuid);
                  }}
                >
                  <Icon icon="trash" size="M" />
                </Button>
              </div>
            </Col>
          </Row>
        );
      })}
    </>
  );
};

const formatQuantity = (numero: number, language?: string) => {
  return numero.toLocaleString(language, {
    minimumFractionDigits: 4,
    maximumFractionDigits: 4,
  });
};

const configDatePlaceHolder = (datePattern: any) => {
  if (datePattern) {
    return DateUtils.getDatePlaceholder(
      datePattern.preferencias?.idioma,
      datePattern.preferencias?.padraoData
    );
  }
};

const CalendarInput = ({
  name,
  placeHolder,
  label,
  error,
  errorMessage,
  onClick,
  onChange,
}: ICalendarInputProps) => {
  return (
    <div onClick={() => onClick?.()}>
      <Tooltip title={errorMessage} showMe={error}>
        <TextInputWithMask
          name={name}
          leftIcon="calendar"
          placeHolder={placeHolder}
          required
          label={label}
          mask="dataMask"
          error={error}
          onChange={(value) => onChange?.(value)}
        />
      </Tooltip>
    </div>
  );
};

const parseDate = (date: string) => {
  const parts = date.split('/');
  return new Date(+parts[2], +parts[1] - 1, +parts[0]);
};

const defineIconToProductRow = (status: 'default' | 'error' | 'check'): any => {
  const icon = {
    default: 'time',
    error: 'close-x',
    check: 'check',
  };

  return icon[status];
};

const defineColorTypeToProductRow = (
  status: 'default' | 'error' | 'check'
): any => {
  const type = {
    default: 'default',
    error: 'danger',
    check: 'success',
  };

  return type[status];
};
