import React from 'react';
import { FC } from 'react';
import { i18n, Text } from '../../../../../../../Components';
import { Modal } from '../../../../../../../Components/Modal';
import {
  FooterThisActionInitialStockBalance,
  FooterThisActionIsPermanent,
} from '../../../../../../../Components/Table/components/Modals/DeleteModal';
import { useTranslation } from 'react-i18next';
import ReactMarkdown from 'react-markdown';
import { IGetSupplierResponse } from '../../../../../../../Data/Interfaces/response/Purchase/ISupplierResponse';
import { useEditCallAPI } from '../../Hooks/useEditCallAPI';
import { useEditFunctions } from '../../Hooks/useEditFunctions';
import { useParams } from 'react-router';

interface ISupplierLotModal {
  supplier?: IGetSupplierResponse;
  visibleModal: boolean;
  changeVisibleModal: (value: boolean) => void;
  content?: any[];
}

export const DeleteSupplierModal: FC<ISupplierLotModal> = ({
  supplier,
  visibleModal,
  changeVisibleModal,
}) => {
  const { externalId } = useParams<{ externalId: string }>();

  const { t } = useTranslation();

  const { deleteSupplier, isLoadingDeleteSupplier } = useEditCallAPI({
    externalId,
  });

  const { onDelete } = useEditFunctions({
    changeVisibleModal,
    supplier,
    deleteSupplier,
  });

  return (
    <Modal
      title={t('common.deleteSupplier')}
      body={
        <div style={{ padding: '20px' }}>
          <Text type="ui-tiny-content" color="text-50">
            <ReactMarkdown>
              {t('modal.deleteModal.single.supplierQuestion', {
                fornecedor: `**${supplier?.nome}**`,
              })}
            </ReactMarkdown>
          </Text>
          <FooterThisActionIsPermanent />
        </div>
      }
      okButtonColor="status-danger-base"
      visible={visibleModal}
      onOkClick={onDelete}
      widthModal={400}
      loading={isLoadingDeleteSupplier}
      okButtonName={
        isLoadingDeleteSupplier ? t('common.deleting') : t('common.delete')
      }
      onCancelClick={() => changeVisibleModal(false)}
      onClose={() => changeVisibleModal(false)}
    />
  );
};
