import { history } from '@/Store';
import { useQueryClient } from '@tanstack/react-query';
import {
  useControllerMutateApiHook,
  useControllerQueryListApiHook,
} from '@/ControllerApiHook/Controller';
import {
  CREATE_STOCK_LOCATION,
  FETCH_GET_STOCK_LOCATION,
  FETCH_LIST_STOCK_LOCATION,
  THERE_IS_STOCK_LOCATION_TYPE,
} from '@/ControllerApiHook/UniqIds/Supply/SupplyKeys';
import { StorageLocationApi } from '@/Data/API/Supply/StorageLocationApi';
import { LIST_FOR_DROPDOWN_TYPE } from '@/ControllerApiHook/UniqIds/Accountmanager/CompanyKeys';
import { CompanyAPI } from '@/Data/API/Accountmanager/CompanyApi';
import { IUseCallAPI } from './interfaces';

export const useCreateCallAPI = ({ form, t }: IUseCallAPI) => {
  const queryClient = useQueryClient();

  const {
    mutateAsync: createStorageLocation,
    isLoading: isLoadingCreateStorageLocation,
  }: any = useControllerMutateApiHook({
    uniqId: CREATE_STOCK_LOCATION,
    entityApi: StorageLocationApi.createStorageLocation,
    options: {
      onSuccess: () => {
        queryClient.invalidateQueries([FETCH_LIST_STOCK_LOCATION]);
        queryClient.invalidateQueries([FETCH_GET_STOCK_LOCATION]);
        history.push('/inventory/supply-places');
      },
    },
  });

  const {
    mutateAsync: fetchStorageLocationDescription,
    isLoading: isLoadingFetchStorageLocationDescription,
  }: any = useControllerMutateApiHook({
    uniqId: THERE_IS_STOCK_LOCATION_TYPE,
    entityApi: StorageLocationApi.isStorageLocationNameAlreadyRegistred,
    options: {
      onSuccess: () => {
        form?.setErrors({
          ...form.errors,
          descricao: undefined,
        });
      },
      onError: () => {
        form?.setErrors({
          ...form.errors,
          descricao: t(
            'supply.supplyPlaces.errors.errorStorageLocationNameExist'
          ),
        });
      },
    },
  });

  const { data: listCompanysDropdown, isLoading: isCompanyLoading } =
    useControllerQueryListApiHook({
      uniqId: LIST_FOR_DROPDOWN_TYPE,
      entityApi: CompanyAPI.listCompanyForDropdown,
    });

  return {
    createStorageLocation,
    isLoadingCreateStorageLocation,
    listCompanysDropdown,
    isCompanyLoading,
    fetchStorageLocationDescription,
    isLoadingFetchStorageLocationDescription,
  };
};
