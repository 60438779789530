import { InputWithSearch, TextInput, Tooltip } from '@/Components';
import { TextInputWithMask } from '@/Components/Inputs/TextInputWithMask';
import { Col, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import { useSupplierList } from './hooks/useSupplierList';
import { useFormikContext } from 'formik';
import { useGetDocumentProvider } from '@/Hooks/HooksServices/People/Supplier/useGetDocumentProvider';
import { useEffect, useState } from 'react';
import { useControlSupplierId } from './hooks/useControlSupplierId';
import { useInvalidatePurchaseList } from './hooks/useInvalidatePurchaseList';
import { RemovePurchaseOrdersModal } from './components/RemovePurchaseOrdersModal';

interface IRowThree {
  setKeepSupplier: any;
}

export const RowThree: React.FC<IRowThree> = ({ setKeepSupplier }) => {
  const form: any = useFormikContext();
  const { t } = useTranslation();
  const { supplier } = useSupplierList();
  const supplierId = form.values.fornecedorExternalId;
  const { setRealSupplierId } = useControlSupplierId();
  const { data: documentProviderData } = useGetDocumentProvider({
    externalIdProvider: supplierId ? [supplierId] : [],
  });
  const [
    removePurchaseOrdersModalVisible,
    setRemovePurchaseOrdersModalVisible,
  ] = useState<boolean>(false);

  const { invalidatePurchaseList } = useInvalidatePurchaseList();

  useEffect(() => {
    if (documentProviderData) {
      form.setFieldValue('razaoSocial', documentProviderData.razaoSocial);
      form.setFieldValue('cnpj', documentProviderData.cnpj);
    }
  }, [documentProviderData]);

  return (
    <Row gutter={[16, 0]}>
      <Col span={8}>
        <Tooltip
          showMe={
            form.values.fornecedorExternalId !==
            form.initialValues.fornecedorExternalId
          }
          title={`${t('purchasing.invoice.edit.originalProvider')}${
            form.initialValues.fornecedorDescricao
          }`}
          targetOffset={[150, 0]}
        >
          <InputWithSearch
            {...supplier}
            withoutMarginBottom
            placeHolder={t('purchasing.invoice.create.placeHolder.provider')}
            required
            label={t('purchasing.invoice.create.provider')}
            onChange={(value) => {
              supplier.items.forEach((item: any) => {
                if (item.id === value) {
                  form.setFieldValue('fornecedorDescricao', item.label);
                }
              });
              const values = form.values;
              if (values.pedidosExternalId && values.pedidosExternalId.length) {
                setRemovePurchaseOrdersModalVisible(true);
              } else {
                invalidatePurchaseList();
                setKeepSupplier(false);
                setRealSupplierId(value);
              }
            }}
            selectedItemFromForm="fornecedorInicial"
            withStatusIcon
          />
        </Tooltip>
      </Col>
      <Col span={9}>
        <TextInput
          name="razaoSocial"
          label={t('purchasing.invoice.create.socialReason')}
          disabled
          isLoading={supplier.isLoading}
        />
      </Col>
      <Col span={7}>
        <TextInputWithMask
          name="cnpj"
          mask="cnpjMask"
          label={t('purchasing.invoice.create.cnpj')}
          withoutMarginBottom={false}
          disabled
          isLoading={supplier.isLoading}
        />
      </Col>
      <RemovePurchaseOrdersModal
        visible={removePurchaseOrdersModalVisible}
        changeVisibleModal={(state: boolean) =>
          setRemovePurchaseOrdersModalVisible(state)
        }
        providerDescription={form.values.fornecedorDescricao}
        onOkClick={() => {
          setKeepSupplier(false);
          form.setFieldValue('pedidosExternalId', []);
          setRealSupplierId(form.values.fornecedorExternalId);
          invalidatePurchaseList();
        }}
        onCancelClick={() => {
          form.setFieldValue(
            'fornecedorExternalId',
            form.values.fornecedorExternalIdReal
          );

          setRemovePurchaseOrdersModalVisible(false);
        }}
      />
    </Row>
  );
};
