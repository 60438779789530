import { FC } from 'react';
import { Col } from 'antd';
import { InputWithSearch } from '@/Components';
import { useTranslation } from 'react-i18next';
import { useRegisterTypeMemorizations } from './Hooks/useRegisterTypeMemorizations';
import { useRegisterTypeFunctions } from './Hooks/useRegisterTypeFunctions';
import { IRegisterType } from './interfaces';

export const RegisterType: FC<IRegisterType> = ({
  refetchStateList,
  recordTypeList,
}) => {
  const { t } = useTranslation();

  const { items } = useRegisterTypeMemorizations({
    recordTypeList: recordTypeList.data,
  });

  const { onSearch } = useRegisterTypeFunctions({ refetchStateList });

  return (
    <Col flex="24%">
      <InputWithSearch
        name="tipoRegistroId"
        placeHolder={t('prescribers.createPage.info.registerPlaceholder')}
        label={t('prescribers.createPage.info.register')}
        required
        items={items}
        onScrollEnd={recordTypeList.fetchNewPage}
        isLoading={recordTypeList.isLoading}
        onSearch={onSearch}
        withoutSearchIcon
      />
    </Col>
  );
};
