import { FC } from 'react';
import { Table } from '@/Components';
import { useTranslation } from 'react-i18next';
import { editTableStatus } from '@/Utils/EditModalTableUtils';
import { PageLoading } from '@/Layouts/PageLoading';
import { useListStates } from './Hooks/useListStates';
import { useListCallAPI } from './Hooks/useListCallAPI';
import { useListFunctions } from './Hooks/useListFunctions';
import { useListMemorizations } from './Hooks/useListMemorizations';
import { ticketsCollums } from './tickets.columns';
import { CreateServiceModal } from './Modal/CreateOrder';
import { IColumn } from '@/Components/Table';
import { IItems } from '@/Components/Inputs/InputWithSearch/Interface';

interface IList {
  initialValueCustomer?: IItems;
  filterName: string;
  serviceCollums?: IColumn[];
}

export const List: FC<IList> = ({
  serviceCollums,
  initialValueCustomer,
  filterName,
}) => {
  const { visibleCreateModal, setVisibleCreateModal } = useListStates();

  const {
    createService,
    serviceList,
    serviceChannel,
    serviceListIsLoading,
    refetchServiceList,
  } = useListCallAPI({
    filterName,
    setVisibleCreateModal,
    externalId: initialValueCustomer?.id,
  });

  const { onSubmit, changeVisible } = useListFunctions({
    createService,
    setVisibleCreateModal,
  });

  const { headerOptions, rowOptions, preferences } = useListMemorizations({
    setVisibleCreateModal,
  });

  const { t } = useTranslation();

  return (
    <PageLoading loading={serviceListIsLoading}>
      <Table
        columns={
          serviceCollums || ticketsCollums(t, preferences, serviceChannel?.data)
        }
        headerOptions={headerOptions}
        rowsOptions={rowOptions}
        editOptions={editTableStatus()}
        loading={serviceListIsLoading}
        fieldStatus="ativo"
        pagination={serviceList}
        onRefetch={refetchServiceList}
      />
      <CreateServiceModal
        changeVisible={changeVisible}
        visible={visibleCreateModal}
        initialValueCustomer={initialValueCustomer}
        onSubmit={onSubmit}
        isLoading={createService.isLoading}
        t={t}
      />
    </PageLoading>
  );
};
