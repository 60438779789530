import { EditProductGroupForm } from '@/Pages/Sectors/Inventory/Products/Groups/Edit';
import { List } from '@/Pages/Sectors/Inventory/Products/Groups/List';
import { Route, Switch } from 'react-router-dom';

export const GroupRoutes = () => (
  <Switch>
    <Route
      path="/inventory/groups/:externalId/:tab"
      component={EditProductGroupForm}
    />
    <Route path="/inventory/groups" component={List} />
  </Switch>
);
