import { Divflex } from "@/Layouts/DivWhithFlex";
import { useMemo } from "react";
import ReactMarkdown from "react-markdown";
import { Text } from '@/Components';
import { IUserGroup } from "@/Data/Interfaces/response/User/IGetUserReponse";
import { useTranslation } from "react-i18next";

export const useDeleteModalMemorizations = (userGroup: IUserGroup) => {
  const { t } = useTranslation();

  const body = useMemo(() => (
    <div style={{ padding: '20px' }}>
    <Divflex>
      <Text color="text-50" type="ui-tiny-content" withoutMarginBottom>
        <ReactMarkdown>
          {t('admin.editUserGroups.deleteModalBody.deleteUserGroup', {
            userGroup: `**${userGroup.nome}**`,
          })}
        </ReactMarkdown>
      </Text>
    </Divflex>
    <Text
      color="text-50"
      type="ui-tiny-content"
      children={t('common.finalDescription')}
    />
  </div>
  ), [t, userGroup]);

  return { body };
};