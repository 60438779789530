import { IUseMemorizations } from "./interfaces";

export const useSpecialtiesMemorizations = ({
  specialtiesData
}: IUseMemorizations) => {
  const items = specialtiesData?.data?.map((x) => ({
    id: x.externalId,
    label: x.descricao,
  }));

  return {
    items
  };
};