import { Col } from "antd";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { ILabelDescription } from "./interfaces";
import { TextInput, Tooltip } from "@/Components";
import { useFormikContext } from "formik";

export const LabelDescription: FC<ILabelDescription> = ({
  product,
  disabledForm
}) => {
  const { t } = useTranslation();
  const form: any = useFormikContext();

  return (
    <Col span={product ? 10 : 12}>
      <Tooltip
        title={`${t('products.toolTips.products.labelDescription')} ${
          product?.descricaoRotulo
        }`}
        showMe={
          product &&
          !disabledForm &&
          product.descricaoRotulo !== form?.values?.descricaoRotulo
        }
      >
        <TextInput
          name="descricaoRotulo"
          label={[
            {
              children: t('products.create.descriptionLabel'),
            },
          ]}
          placeHolder={t('products.create.descriptionLabel')}
          disabled={disabledForm}
          withStatusIcon={!!product}
          maxLength={200}
        />
      </Tooltip>
    </Col>
  );
};