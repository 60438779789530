import { useTranslation } from 'react-i18next';
import { IUseMemorizations } from './interfaces';
import { useFormikContext } from 'formik';
import { ICapsuleColorListData } from '@/Data/Interfaces/response/CapsuleColors/ICapsuleColorsResponse';
import { ICapsuleSizeListData } from '@/Data/Interfaces/response/CapsuleSize/ICapsuleSizeResponse';

export const useCapsuleNumberMemorizations = ({
  product,
  disabledForm,
  listCapsuleSize,
}: IUseMemorizations) => {
  const { t } = useTranslation();
  const form: any = useFormikContext();

  const title = `${t('products.toolTips.products.originalValue')} ${
    product && product.tipoCapsula
      ? product.tipoCapsula.numeroCapsulaId ??
        t('products.toolTips.products.empty')
      : ''
  }`;

  const showMe = product &&
  !disabledForm &&
  product?.tipoCapsula?.numeroCapsula !==
    form?.values?.capsulaTamanhoId

  const items = listCapsuleSize.data
  ? listCapsuleSize.data.map((e: ICapsuleSizeListData) => ({
      id: e.id,
      label: e.numeroCapsula,
      subLabel: `${e.volumeMl}ml`,
    }))
  : [];

  return {
    title,
    showMe,
    items,
  };
};
