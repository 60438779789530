import { Modal } from '@/Components/Modal';
import { useTranslation } from 'react-i18next';
import { FC, Fragment, useEffect } from 'react';
import { RadioButtonMessageType } from './Components/RadioButtonMessageType';
import { DisplayType } from './Components/DisplayType';
import BreakLineProductMessageModal from './Components/BreakLineProductMessageModal';
import { PageLoading } from '@/Layouts/PageLoading';
import { ILinkedProdutoMessageModal } from './interfaces';
import { useLinkedProductMessageModalStates } from './Hooks/useLinkedProductMessageModalStates';
import { useLinkedProductMessageModalCallAPI } from './Hooks/useLinkedProductMessageModalCallAPI';
import { useLinkedProductMessageModalFunctions } from './Hooks/useLinkedProductMessageModalFunctions';

export const LinkedProdutoMessageModal: FC<ILinkedProdutoMessageModal> = ({
  visible,
  changeVisible,
  produtoExternalId
}) => {
  const newMessageLinkedProduct: number = 2;
  const { t } = useTranslation();
  
  const {
    visibleLinkedProductMessageModalSearch,
    setVisibleLinkedProductMessageModalSearch,
    typeMessageSelected,
    setTypeMessageSelected
  } = useLinkedProductMessageModalStates();
  
  const {
    createProductLinkedMessage,
    createProductMessageLinked
  } = useLinkedProductMessageModalCallAPI();

  const {
    handlerMessageTypeSelected,
    onOkClickForm,
    changeVisibleModalSearch,
    onCancelClick,
    onClose
  } = useLinkedProductMessageModalFunctions({
    typeMessageSelected,
    setTypeMessageSelected,
    newMessageLinkedProduct,
    createProductLinkedMessage,
    createProductMessageLinked,
    produtoExternalId,
    changeVisible,
    visible,
    setVisibleLinkedProductMessageModalSearch
  });

  useEffect(() => {
    if (visibleLinkedProductMessageModalSearch === false) {
      changeVisible(false);
    }
  }, [visibleLinkedProductMessageModalSearch]);

  return (
    <PageLoading
      loading={
        typeMessageSelected === newMessageLinkedProduct
          ? createProductLinkedMessage.isLoading
          : createProductMessageLinked.isLoading
      }
    >
      <Modal
        onOkClickForm={onOkClickForm}
        disabledButton={false}
        okButtonName={t('common.create')}
        title={t(
          'products.productList.productMessage.modalProductLinkedMessage.messageTitle'
        )}
        body={
          <Fragment>
            <RadioButtonMessageType
              productExternalId={produtoExternalId}
              onTypeMessageSelected={handlerMessageTypeSelected}
              visible={visible}
              changeVisibleModalSearch={changeVisibleModalSearch}
            />
            <BreakLineProductMessageModal />
            <DisplayType />
          </Fragment>
        }
        loading={false}
        visible={visible}
        onCancelClick={onCancelClick}
        htmlType="submit"
        onClose={onClose}
        minWidth={400}
        withForm
      />
    </PageLoading>
  );
};
