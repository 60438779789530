import { FC } from "react";
import {
  Tooltip
} from '@/Components';
import { Col } from 'antd';
import { useTranslation } from "react-i18next";
import { useFormikContext } from "formik";
import { IToleranceWeighingBelow } from "./interfaces";
import { PercentageInput } from "@/Components/Inputs/PercentageInput";

export const ToleranceWeighingBelow: FC<IToleranceWeighingBelow> = ({
  product,
  disabledForm
})  => {
  const { t } = useTranslation();
  const form: any = useFormikContext();

  return (
    <Col span={4}>
      <Tooltip
        title={`${t(
          'products.toolTips.products.originalToleranceWeighingBelow'
        )} ${
          product && product.materiaPrima
            ? product.materiaPrima?.toleranaciaPesagemDown
            : ''
        }%`}
        showMe={
          product &&
          !disabledForm &&
          product.materiaPrima?.toleranaciaPesagemDown !==
            form?.values?.toleranaciaPesagemDown
        }
      >
        <PercentageInput
          withStatusIcon={!!product}
          name="toleranaciaPesagemDown"
          placeHolder="0%"
          label={t('products.create.weighingToleranceBelow')}
          disabled={disabledForm}
        />
      </Tooltip>
    </Col>
  );
};