import { FC } from "react";
import { Col } from 'antd';
import { Label, Text } from '@/Components';
import { IObservation } from "./interfaces";
import { useTranslation } from "react-i18next";

export const Observation: FC<IObservation> = ({ prescriber }) => {
  const { t } = useTranslation();

  return (
    <Col span={24}>
      <Label
        type="ui-tiny-content"
        color="text-200"
        children={t('prescribers.editPage.observation')}
        withBottomMargin
      />
      <Text
        type="ui-tiny-content"
        color="text-50"
        children={prescriber?.observacao}
      />
    </Col>
  );
};