import { useMemo } from 'react';
import { IUseMemorizations } from './interfaces';
import DateUtils from '@/Utils/DateUtils';

export const useModalMemorizations = ({ client }: IUseMemorizations) => {
  const initialValuesInfoModal = useMemo(
    () => ({
      ...client,
      dataNascimento: DateUtils.convertDate(client?.dataNascimento),
      pessoa: client?.tipoPessoa,
    }),
    [client]
  );

  return {
    initialValuesInfoModal,
  };
};
