import React from 'react';
import { StatusCard } from '@/Components';
import { IconName } from '@/Components/Icon/IconClasses';
import { ActionButtons } from './Components/ActionButtons';

import styles from './Status.module.scss';


export interface IStatus {
  typePurchase: number;
  children?: string;

  onOkClick?: () => void;
  onCancelClick?: () => void;
  onClickDropdownItem?: () => void;

  disabledButton?: boolean;
}

export const StatusHeader: React.FC<IStatus> = ({
  typePurchase,
  children,
  onOkClick,
  onCancelClick,
  disabledButton,
  onClickDropdownItem,
}) => {
  const statusIcon: IconName[] = [
    'time',
    'calculator',
    'like',
    'disapproved',
    'partialcube',
    'Box',
    'close-x',
  ];

  const statusColor = [
    'pending',
    'budget',
    'approved',
    'reproved',
    'partialDelivery',
    'delivery',
    'canceled',
  ];

  return (
    <>
      <StatusCard
        type="ui-sub-semibold"
        className={`${styles[`${statusColor[typePurchase]}-status`]} ${
          styles['status']
        }`}
        icon={{ icon: statusIcon[typePurchase] }}
        children={children}
      />
      <ActionButtons
        typePurchase={typePurchase}
        disabledButton={disabledButton}
        onCancelClick={onCancelClick}
        onClickDropdownItem={onClickDropdownItem}
        onOkClick={onOkClick}
      />
      <div className={`${styles['cardTotal-separator-line']}`} />
    </>
  );
};
