export interface IUpdatePermissionRequest {
  usuarioId: string;
  permissaoId: string;
  ativo: boolean;
  herdado: boolean;

  dependencias?: string[];

  callback?: () => void;
}

export const createPermissionRequest = (
  userId: string,
  permissaoId: string,
  value: boolean | 'not-inherited' | 'inherited'
): IUpdatePermissionRequest => {
  const isInherited = value === 'inherited' || value === 'not-inherited';
  let ativo = value as boolean;

  if (isInherited) {
    if (value === 'inherited') ativo = true;
    else ativo = false;
  }

  return {
    usuarioId: userId,
    permissaoId,
    herdado: isInherited,
    ativo,
  };
};
