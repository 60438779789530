import { useFormikContext } from 'formik';

export const useGetRowTotalValue = () => {
  const form = useFormikContext<any>();

  const calculateTotalValue = (index: number, withOutDiscount = false) => {
    if (!form.values.pedidoCompraItens || !form.values.pedidoCompraItens[index])
      return;

    const unitValue = form.values.pedidoCompraItens[index].precoUnitario;
    const quantity = form.values.pedidoCompraItens[index].quantidade;
    const unitDiscount =
      form.values.pedidoCompraItens[index].valorDescontoUnitario || 0;
    const totalValue = unitValue * quantity;
    const totalValueWithDiscount = withOutDiscount
      ? totalValue
      : totalValue - unitDiscount * quantity;

    return totalValueWithDiscount;
  };

  return calculateTotalValue;
};
