import React from 'react';
import { useTranslation } from 'react-i18next';
import { Modal } from '@/Components/Modal';
import { useDeleteModalCallAPI } from './Hooks/useDeleteModalCallAPI';
import { useDeleteModalMemorizations } from './Hooks/useDeleteModalMemorizations';
import { IEditModal } from './interface';
import { useDeleteModalFunctions } from './Hooks/useDeleteModalFunctions';

export const DeleteUserGroupModal: React.FC<IEditModal> = ({
  visible,
  userGroup,
  onCancelClick,
}) => {
  const { t } = useTranslation();

  const { deleteUserGroup } = useDeleteModalCallAPI();

  const { onDelete } = useDeleteModalFunctions({ userGroup });

  const { body } = useDeleteModalMemorizations(userGroup);

  return (
    <Modal
      onOkClick={onDelete}
      title={`${t('common.delete')} "${userGroup.nome}"?`}
      body={body}
      visible={visible}
      onCancelClick={onCancelClick}
      htmlType="submit"
      okButtonName={t('common.delete')}
      okButtonColor="status-danger-base"
      onClose={onCancelClick}
      loading={deleteUserGroup.isLoading}
    />
  );
};
