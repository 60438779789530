import { useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';

export const useAddPurchaseItems = () => {
  const { t } = useTranslation();
  const form: any = useFormikContext();
  const currentPurchases = form.values.pedidosExternalId;

  const addPurchaseItems = (purchases: any[]) => {
    const purchaseItems = purchases.map((purchase) => purchase.itens).flat();
    form.setFieldValue('pedidosExternalId', [
      ...currentPurchases,
      ...purchases,
    ]);

    const currentItems = form.values.itens;
    const newItems = purchaseItems.map((item) => ({
      produtoExternalId: item.produtoExternalId,
      unidadeMedidaId: item.unidadeMedidaId,
      quantidadeComprada: item.quantidade,
      classeProdutoId: item.classeProdutoId,
      codProduto: item.codigoProduto,
      valorUnitario: item.valorUnitario,
      productExtraOption: {
        label: item.descricao,
        id: item.produtoExternalId,
        subLabel: `${t('purchasing.purchaseOrder.create.subLabelProduct')} ${
          item.codigoProduto
        }`,
      },
    }));

    form.setFieldValue('itens', [...currentItems, ...newItems]);
  };

  return {
    addPurchaseItems,
  };
};
