import { Http, IQueryListParams } from '@/Utils/Http';
import { QueryFunctionContext } from '@tanstack/react-query';

export interface IListNcmResponse {
  pageIndex: number;
  pageSize: number;
  count: number;
  data: INcmListData[];
}

export interface INcmListData {
  id: number;
  codigo: string;
  descricao: string;
  categoria: string;
}

export const ListNcm = async (
  context: QueryFunctionContext
): Promise<IListNcmResponse | undefined> => {
  return await Http.fetcher<IListNcmResponse>(
    Http.AddQueryParams(
      '/pharma/compras/v1/ListarNcm',
      context.queryKey[1] as IQueryListParams
    )
  );
};
