import { useFormikContext } from 'formik';
import { useState } from 'react';

export const useSetProductData = () => {
  const [classId, setClassId] = useState<number[]>([]);
  const form: any = useFormikContext();

  const setProductData = (product: any, index: number) => {
    form.setFieldValue(
      `itens.${index}.codProduto`,
      product.sequenciaGroupTenant
    );
    form.setFieldValue(
      `itens.${index}.unidadeMedidaId`,
      product.unidadeMedidaId
    );

    setClassId([product.classeProdutoId]);
  };

  return { setProductData, classId, setClassId };
};
