import { ModalFuncProps } from 'antd';
import { FC } from 'react';
import { BodyModal } from '../../../../../../../../../Components/Modal';
import { Footer as ModalFooter } from '../../../../../../../../../Components/Modal/Components/Footer';
import {
  ICommonError,
  IGlobalModal,
} from '../../../../../../../../../Utils/ErrorCodeUtils/ErrorHandlingUtils';
import { Text, Icon } from '../../../../../../../../../Components';
import { useTranslation } from 'react-i18next';
import { Divflex } from '../../../../../../../../../Layouts/DivWhithFlex';
import { ReactMarkdown } from 'react-markdown/lib/react-markdown';

const Content: FC<ICommonError> = ({ content }) => {
  const { t } = useTranslation();

  return (
    <>
      <BodyModal
        body={
          <>
            <Text type="ui-tiny-content">
              <ReactMarkdown>
                {t(
                  'products.diluted.errorsCannotCreateModal.cannotCreateModal7.description',
                  {
                    product: `**${content[0]}**`,
                  }
                )}
              </ReactMarkdown>
            </Text>

            <Divflex style={{ marginTop: '20px' }}>
              <Icon
                icon={'exclamation-upside-down'}
                size={'SM'}
                color={'text-400'}
                style={{ padding: '18px 18px 0 8px' }}
              />
              <Text
                type="paragraph2"
                color={'text-400'}
                children={t(
                  'products.diluted.errorsCannotCreateModal.cannotCreateModal7.warning'
                )}
              />
            </Divflex>
          </>
        }
        style={{ padding: 20 }}
      />
    </>
  );
};

const Title: FC<ICommonError> = () => {
  const { t } = useTranslation();
  return t('products.diluted.errorsCannotCreateModal.cannotCreateModal7.title');
};

const Footer: FC<{
  params: { localEstoqueExternalId: string[] };
  instance: IGlobalModal;
}> = ({ instance }) => {
  const { t } = useTranslation();

  return (
    <ModalFooter
      cancelButtonName={t('supply.supplyPlaces.modal.cannotDelete.ok')}
      onOkClick={() => {
        instance.destroy();
      }}
      onCancelClick={() => instance.destroy()}
      withoutOkButton
    />
  );
};

export const CannotDeleteModal: (
  error: ICommonError,
  params: { localEstoqueExternalId: string[] },
  instance: IGlobalModal
) => ModalFuncProps = (error, params, instance) => ({
  closable: true,
  icon: <></>,
  title: <Title {...error} />,
  content: <Content {...error} />,
  footer: <Footer params={params} instance={instance} />,
  width: 400,
});
