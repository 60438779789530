import { FC } from 'react';
import { Col } from 'antd';
import { InputWithSearch } from '@/Components';
import { useTranslation } from 'react-i18next';
import { IProductName } from './interfaces';
import { useProductNameCallAPI } from './Hooks/useProductNameCallAPI';
import { useProductNameFunctions } from './Hooks/useProductNameFunctions';
import { useProductNameMemorizations } from './Hooks/useProductNameMemorizations';

export const ProductName: FC<IProductName> = ({
  index,
  setCurrentProduct,
  addRow,
  setCurrentProductExternalId,
}) => {
  const { t } = useTranslation();
  const { productList, fetchNewProductPage, refetchSearchProductPage } =
    useProductNameCallAPI();

  const { setProductCodeValueAndProductName, onSearch } =
    useProductNameFunctions({
      index,
      productList,
      setCurrentProduct,
      addRow,
      refetchSearchProductPage,
      setCurrentProductExternalId,
    });

  const { items } = useProductNameMemorizations({
    productList,
  });

  return (
    <Col span={5}>
      <InputWithSearch
        label={
          index === 0
            ? t('production.standardFormula.create.productLine.productName')
            : ''
        }
        name={`produtos.${index}.produtoExternalId`}
        required
        placeHolder={t(
          'production.standardFormula.create.productLine.productNamePlaceholder'
        )}
        items={items}
        onScrollEnd={fetchNewProductPage}
        onChange={setProductCodeValueAndProductName}
        onSearch={onSearch}
      />
    </Col>
  );
};
