import { IFinishedProductListData } from '@/Data/Interfaces/response/Product/IProductRespose';
import { useState } from 'react';

export const useAddProductModalStates = () => {
  const [insufficientFundsModalVisible, setInsufficientFundsModalVisible] =
    useState(false);

  const [productSelected, setProductSelected] = useState<
    IFinishedProductListData | undefined
  >(undefined);

  return {
    insufficientFundsModalVisible,
    setInsufficientFundsModalVisible,
    productSelected,
    setProductSelected,
  };
};
