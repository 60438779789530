import { TFunction } from 'react-i18next';
import { ICannotDeleteModalInfo } from '../ErrorHandlingUtils';

const errorI18N = 'adminBootis.companyList.errorDeleteModal.';

export const cannotDeleteCompanyWithMovementsInTheSystemProps = (
  t: TFunction<'translation', undefined>
): ICannotDeleteModalInfo => ({
  formName: 'nomeFantasia',
  oneErrorModal: {
    titleModal: t(`${errorI18N}oneErrorModal.titleModal`),
    subTitleModal: t(`${errorI18N}oneErrorModal.subTitleModal`),
    reasonTranslation: t(`${errorI18N}oneErrorModal.reasonTranslation`),
    warningModal: t(`${errorI18N}oneErrorModal.warningModal`),
  },
  manyErrorsModal: {
    entity: t(`${errorI18N}manyErrorsModal.entity`),
    complement: t(`${errorI18N}manyErrorsModal.complement`),
    subTitleModal: t(`${errorI18N}manyErrorsModal.subTitleModal`),
    reasonTranslation: t(`${errorI18N}manyErrorsModal.reasonTranslation`),
    warningModal: t(`${errorI18N}manyErrorsModal.warningModal`),
  },
});
