import { useTranslation } from 'react-i18next';
import { IUseMemorizations } from './interfaces';

export const useLotMemorizations = ({ createLot }: IUseMemorizations) => {
  const { t } = useTranslation();
  const uiUnitDefault: number = 1;
  const utrUnitDefault: number = 9;
  const grUnitDefault: number = 7;
  const ufcMiUnitDefault: number = 14;

  const unitysMeasuresDefault = {
    loteUnidadeAlternativa1: {
      unidadeAlternativaId: uiUnitDefault,
      unidadeAlternativaConversaoId: grUnitDefault,
    },
    loteUnidadeAlternativa2: {
      unidadeAlternativaId: utrUnitDefault,
      unidadeAlternativaConversaoId: ufcMiUnitDefault,
    },
  };

  const initialValues = {
    loteInformacaoTecnica: unitysMeasuresDefault,
  };

  const buttonChildren = createLot.isLoading
    ? t('supply.lot.create.saveLoading')
    : t('supply.lot.create.save');

  const orderedErrors = [
    'produtoExternalId',
    'numero',
    'dataFabricacao',
    'dataValidade',
    'fornecedorExternalId',
    'numeroNf',
    'serieNf',
    'loteInformacaoTecnica.densidade',
    'loteInformacaoTecnica.diluicaoFornecedor',
    'loteInformacaoTecnica.fatorDiluicaoFornecedor',
    'loteInformacaoTecnica.concentracaoAgua',
    'loteInformacaoTecnica.fatorConcentracaoAgua',
  ];

  return {
    initialValues,
    buttonChildren,
    orderedErrors,
  };
};
