import * as yup from 'yup';
import i18n from '@/Components/i18n';

export const subgroupCreateSchema = yup.object().shape({
  descricao: yup
    .string()
    .required(i18n.t('products.subGroupList.modal.errorNameRequired')),
  grupoPaiExternalId: yup
    .string()
    .required(i18n.t('products.subGroupList.modal.errorGroupRequired')),
});
