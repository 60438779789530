import { Col, Row } from 'antd';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import {
  Card,
  Dropdown,
  Form,
  Icon,
  TextInput,
} from '../../../../../../../../Components';
import { TextArea } from '../../../../../../../../Components/Inputs/TextArea';
import { useEditCallAPI } from '../../../Hooks/useEditCallAPI';
import { useEditMemorizations } from '../../../Hooks/useEditMemorizations';
import StorageUtils from '@/Utils/StorageUtils';

export const InformationTransferForm: FC = () => {
  const { externalId } = useParams<{ externalId: string }>();

  const preferences = StorageUtils.getPreference();

  const { t } = useTranslation();

  const { transfers } = useEditCallAPI({ externalId });

  const { initialValuesInfoCard } = useEditMemorizations({
    transfers,
    preferences,
  });

  return (
    <Form initialValues={initialValuesInfoCard}>
      <Card
        title={t('supply.transfers.detailsForm.titleInformation')}
        withoutCollapse={true}
      >
        <Row gutter={[16, 0]}>
          <Col span={3}>
            <TextInput
              name="sequenciaNumeroTransferencia"
              label={t('supply.transfers.detailsForm.transferId')}
              rightIcon={{
                icon: 'copy',
                copyName: 'sequenciaNumeroTransferencia',
                toolTipTitle: [
                  t('supply.lot.details.toolTips.copy'),
                  t('supply.lot.details.toolTips.copySuccess'),
                ],
              }}
              disabled
            />
          </Col>
          <Col span={3.5}>
            <TextInput
              name="dataTransferencia"
              label={t('supply.transfers.detailsForm.dateOfTransfer')}
              disabled
            />
          </Col>
          <Col span={7}>
            {transfers && (
              <Dropdown
                name="responsavel"
                items={[
                  {
                    id: transfers?.responsavel?.usuarioExternalId,
                    avatar: {
                      name: transfers?.responsavel?.usuarioNome || '',
                      lastName: transfers.responsavel?.usuarioSobrenome,
                      email: '',
                    },
                    label: transfers?.responsavel?.usuarioNome,
                  },
                ]}
                label={t('supply.transfers.detailsForm.responsible')}
                disabled
              />
            )}
          </Col>
        </Row>
        <Row gutter={[68, 0]} style={{ position: 'relative' }}>
          <Col span={12}>
            <TextInput
              name="localDeEstoqueOrigem"
              label={t('supply.transfers.detailsForm.placeOfOrigin')}
              disabled
            />
          </Col>
          <Icon
            icon={'Arrow-right'}
            color={'text-400'}
            size={'S'}
            style={{
              position: 'absolute',
              top: '28px',
              left: 'calc(50% - 8px)',
            }}
          />
          <Col span={12}>
            <TextInput
              name="localEstoqueDestino"
              label={t('supply.transfers.detailsForm.destination')}
              disabled
            />
          </Col>
        </Row>
        <Row justify="space-between">
          <Col span={24}>
            <TextArea
              name="observacao"
              label={t('supply.transfers.detailsForm.comments')}
              maxLength={200}
              rows={8}
              disable
            />
          </Col>
        </Row>
      </Card>
    </Form>
  );
};
