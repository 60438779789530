import { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Dropdown,
  Tooltip
} from '@/Components';
import { useFormikContext } from 'formik';
import { dismembermentTypes } from '../../../../../../../Create/Components/utils';
import { IDismembermentDescription, IDismembermentType } from "./interfaces";
import { useDismembermentTypeStates } from './Hooks/useDismembermentTypeStates';
import { useDismembermentTypeMemorizations } from './Hooks/useDismembermentTypeMemorizations';

export const DismembermentType: FC<IDismembermentType> = ({
  standardFormula,
  disabled
}) => {
  const { t } = useTranslation();
  const form: any = useFormikContext();

  const {
    dismembermentDescriptionState,
    setDismembermentDescriptionState
  } = useDismembermentTypeStates();

  const {
    items
  } = useDismembermentTypeMemorizations();

  useEffect(() => {
    let dismembermentDescription: IDismembermentDescription | undefined;
    if (standardFormula?.formulaPadraoDesmembramentoId) {
      dismembermentDescription = dismembermentTypes.find(
        (obj) => obj.id === standardFormula?.formulaPadraoDesmembramentoId
      );
      dismembermentDescription?.name &&
        setDismembermentDescriptionState(dismembermentDescription.name);
    }
  }, [standardFormula?.formulaPadraoDesmembramentoId]);

  return (
    <Tooltip
      title={`${t(
        'production.standardFormula.details.tooltips.previousDismemberment'
      )} ${dismembermentDescriptionState}`}
      targetOffset={[0, 0]}
      showMe={
        !disabled &&
        standardFormula?.formulaPadraoDesmembramentoId !==
          form.values.formulaPadraoDesmembramento
      }
    >
      <Dropdown
        name="formulaPadraoDesmembramento"
        items={items}
        disabled={disabled}
        placeHolder={t(
          'production.standardFormula.details.dismembermentTypePlaceholder'
        )}
        required={!disabled}
        label={t('production.standardFormula.details.dismembermentType')}
        withStatusIcon
      />
    </Tooltip>
  );
};