import {
  EDIT_INFO_PRESCRIBERS,
  GET_PRESCRIBER,
} from '@/ControllerApiHook/UniqIds/People/PrescribersKeys';
import { useQueryClient } from '@tanstack/react-query';
import { IUseCallAPI } from './interfaces';
import { useControllerMutateApiHook } from '@/ControllerApiHook/Controller';
import { PrescribersAPI } from '@/Data/API/People/PrescribersApi';
import {
  messageErrorToast,
  messageSucessToast,
} from '@/Utils/MessageErrorToast';
import { i18n } from '@/Components';

export const useInfoModalCallAPI = ({ changeVisibleModal }: IUseCallAPI) => {
  const queryClient = useQueryClient();

  const editInfo: any = useControllerMutateApiHook({
    uniqId: EDIT_INFO_PRESCRIBERS,
    entityApi: PrescribersAPI.editInfoPrescriber,
    options: {
      onError: (x) => messageErrorToast(x),
      onSuccess: () => {
        messageSucessToast(i18n.t('prescribers.editPage.success.contact'));

        queryClient.invalidateQueries([GET_PRESCRIBER]);

        changeVisibleModal(true);
      },
    },
  });

  return {
    editInfo,
  };
};
