import { Http, IQueryListParams } from '@/Utils/Http';
import { QueryFunctionContext } from '@tanstack/react-query';

export interface IListUnitMeasurementByClassResponse {
  pageIndex: number;
  pageSize: number;
  count: number;
  data: IListUnitMeasurementByClassData[];
}

export interface IListUnitMeasurementByClassData {
  id: number;
  unidadeAbreviacao: string;
  descricao: string;
  ativo: boolean;
  unidadeAlternativa: boolean;
}

export const ListUnitByClass = async (
  context: QueryFunctionContext
): Promise<IListUnitMeasurementByClassResponse | undefined> => {
  return await Http.fetcher<IListUnitMeasurementByClassResponse>(
    Http.AddQueryParams(
      `/pharma/estoque/v1/UnidadeMedida/ListarDetalhadoUnidadesPorClasseProduto`,
      context.queryKey[1] as IQueryListParams
    )
  );
};
