import { history } from '@/Store';
import { useQueryClient } from '@tanstack/react-query';
import { useControllerMutateApiHook } from '@/ControllerApiHook/Controller';
import { PharmaceuticalFormAPI } from '@/Data/API/Production/PharmaceuticalForm';
import {
  CREATE_PHARMACEUTICAL_FORM,
  FETCH_GET_PHARMACEUTICAL_FORM,
  FETCH_LIST_FOR_PHARMACEUTICAL_FORM,
} from '@/ControllerApiHook/UniqIds/Production/PharmaceuticalFormKeys';

export const useCreateCallAPI = () => {
  const queryClient = useQueryClient();

  const {
    mutateAsync: createPharmaceuticalForm,
    isLoading: isLoadingCreatePharmaceuticalForm,
  } = useControllerMutateApiHook({
    uniqId: CREATE_PHARMACEUTICAL_FORM,
    entityApi: PharmaceuticalFormAPI.createPharmaceuticalForm,
    options: {
      onSuccess: () => {
        queryClient.invalidateQueries([FETCH_LIST_FOR_PHARMACEUTICAL_FORM]);
        queryClient.invalidateQueries([FETCH_GET_PHARMACEUTICAL_FORM]);
        history.push('/production/dosage-form');
      },
    },
  });

  return {
    createPharmaceuticalForm,
    isLoadingCreatePharmaceuticalForm,
  };
};
