import { useMemo } from 'react';
import { IUseMemorizations } from './interfaces';
import { useTranslation } from 'react-i18next';

export const useCreateMemorizations = ({
  productLossList,
  storageLocationDropDownList,
}: IUseMemorizations) => {
  const { t } = useTranslation();

  const productLossListData = useMemo(() => {
    return productLossList?.data?.map((value: any) => ({
      id: value?.externalId,
      label: value?.descricao,
      subLabel: `${t('supply.losses.create.codeSubLabel')} ${
        value?.sequenciaGroupTenant
      }`,
      allItem: value,
    }));
  }, [productLossList]);

  const storageLocationListData = useMemo(() => {
    if (!storageLocationDropDownList.data) return [];

    return storageLocationDropDownList?.data?.map((value: any) => ({
      id: value?.externalId,
      label: value?.descricao,
      code: value?.nomeEmpresa,
      customSelectedLabel: `${value.descricao} (${value.nomeEmpresa})`,
    }));
  }, [storageLocationDropDownList]);

  return { productLossListData, storageLocationListData };
};
