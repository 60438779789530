import StorageUtils from '@/Utils/StorageUtils';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

export const useCreateMemorizations = () => {
  const { t } = useTranslation();

  const modalBody = useMemo(
    () => (
      <div style={{ padding: 20, maxWidth: 400 }}>
        {t('supply.balanceAdjustment.backModal.body')}
      </div>
    ),
    [t]
  );

  const preferences = useMemo(() => StorageUtils.getPreference(), []);

  return { modalBody, preferences };
};
