import { Link, Status, i18n } from '../../../../../Components';
import { PermissionEnum } from '../../../../../Data/Interfaces/model/permissionEnum';
import { hasPermission } from '../../../../../Components/PermissionWrapper';
import { Text } from '../../../../../Components/Text';

export const posologyColumns = (t: any) => [
  {
    title: t('production.posology.list.posologyDescription'),
    dataIndex: 'descricaoPosologia',
    key: 'descricaoPosologia',
    isHighlightable: true,
    sortColumn: 'descricaoPosologia',
    sorter: true,
    defaultSortOrder: 'ascend',
    render: (x: string, row: any) =>
      !hasPermission(PermissionEnum.Producao_Posologia_VerDetalhes) ? (
        <Text type="ui-tiny-bold" style={{ width: 'fit-content' }}>
          {x}
        </Text>
      ) : (
        <Link
          type="ui-tiny-bold"
          to={`/production/posology/${row.externalId}/details`}
          style={{ width: 'fit-content' }}
        >
          {x}
        </Link>
      ),
  },
  {
    title: t('production.posology.list.pharmaceuticalFormDescription'),
    dataIndex: 'descricaoFormaFarmaceutica',
    key: 'descricaoFormaFarmaceutica',
    sortColumn: 'descricaoFormaFarmaceutica',
    width: '18%',
    isHighlightable: true,
  },
  {
    title: t('production.posology.list.amountDosePeriod'),
    dataIndex: 'quantidadeDosePorPeriodo',
    key: 'quantidadeDosePorPeriodo',
    sortColumn: 'quantidadeDosePorPeriodo',
    width: '20%',
    isHighlightable: true,
    render: (amount: any, unit: any) =>
      amount > 1
        ? `${amount} ${unit.unidadeMedidaDescricao}s`
        : `${amount} ${unit.unidadeMedidaDescricao}`,
  },
  {
    title: t('production.posology.list.period'),
    dataIndex: 'periodoId',
    key: 'periodoId',
    sortColumn: 'periodoId',
    width: '20%',
    isHighlightable: true,
    render: (prop: any) => `${PeriodDescription(prop)}`,
  },
  {
    title: t('production.posology.list.status'),
    dataIndex: 'ativo',
    key: 'ativo',
    width: '15%',
    render: (prop: any) => (
      <Status type={prop ? 'success' : 'disabled'}>
        {t(
          `production.posology.list.statusTypes.${prop ? 'active' : 'inactive'}`
        )}
      </Status>
    ),
  },
];

const PeriodDescription = (value: any) => {
  switch (value) {
    case 1:
      return `${i18n.t('production.posology.list.day')}`;
    case 2:
      return `${i18n.t('production.posology.list.week')}`;
    case 3:
      return `${i18n.t('production.posology.list.month')}`;
    default:
      return `${i18n.t('production.posology.list.day')}`;
  }
};
