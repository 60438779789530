import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { currencyFormater } from '@/Utils/Currency';
import { useFormikContext } from 'formik';
import { IFreightCost } from './interfaces';
import { CurrencyInput } from '@/Components/Inputs/CurrencyInput';
import { Tooltip } from '@/Components';

export const FreightCost: FC<IFreightCost> = ({
  orderPurchase
}) => {
  const { t } = useTranslation();
  const form: any = useFormikContext();

  return (
    <>
      <Tooltip
        title={`${t('common.originalValue')} ${currencyFormater(
          orderPurchase?.frete
        )}`}
        showMe={orderPurchase?.frete !== form?.values?.frete}
        targetOffset={[60, -12]}
      >
        <CurrencyInput
          name="frete"
          label={t(
            'purchasing.purchaseOrder.create.totalCard.freightCost'
          )}
          withStatusIcon
          withoutMarginBottom
          placeHolder={t(
            'purchasing.purchaseOrder.create.productsCardPlaceHolders.discountValue'
          )}
        />
      </Tooltip>
    </>
  );
};