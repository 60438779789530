import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { HorizontalTabs } from '../../../../../Components';
import { PageLoading } from '../../../../../Layouts/PageLoading';
import { useParams } from 'react-router';
import { HeaderSaleOrder } from './Header';
import { LabelOrder, Order } from './Tabs/Order';
import StorageUtils from '@/Utils/StorageUtils';
import { useEditCallAPI } from './Hooks/useEditCallApi';

import styles from './EditTickets.module.scss';

export const EditTickets: FC = () => {
  const { externalId } = useParams<{ externalId: string }>();

  const { service, saleOrder, getServiceLoading } = useEditCallAPI({
    externalId,
  });

  const preferences = StorageUtils.getPreference();

  const { t } = useTranslation();

  return (
    <PageLoading loading={getServiceLoading}>
      <HeaderSaleOrder service={service} preferences={preferences} t={t} />
      <HorizontalTabs
        className={styles['horizontal-tab']}
        type="secondary"
        items={[
          {
            label: t('saleOrder.editOrder.header.tabs.messages'),
            key: 'message',
            children: <></>,
            disabled: true,
          },
          {
            label: <LabelOrder t={t} saleOrder={saleOrder} />,
            key: 'order',
            children: service && externalId && (
              <Order service={service} saleOrder={saleOrder} />
            ),
          },
          {
            label: t('saleOrder.editOrder.header.tabs.attachments'),
            key: 'attachments',
            children: <></>,
            disabled: true,
          },
          {
            label: t('saleOrder.editOrder.header.tabs.notes'),
            key: 'Notes',
            children: <></>,
            disabled: true,
          },
          {
            label: t('saleOrder.editOrder.header.tabs.historic'),
            key: 'Historic',
            children: <></>,
            disabled: true,
          },
        ]}
      />
    </PageLoading>
  );
};
