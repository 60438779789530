import { useControllerMutateApiHook } from '@/ControllerApiHook/Controller';
import { IUseCallAPI } from './interfaces';
import { ProductMessageAPI } from '@/Data/API/Supply/ProductMensage';
import { THERE_IS_MESSAGE_PRODUCT_TYPE } from '@/ControllerApiHook/UniqIds/Supply/ProductMessageKeys';

export const useEditCallAPI = ({ t, form }: IUseCallAPI) => {
  const { mutateAsync: messageProductAlreadyRegistred }: any =
    useControllerMutateApiHook({
      uniqId: THERE_IS_MESSAGE_PRODUCT_TYPE,
      entityApi: ProductMessageAPI.isMessageProductAlreadyRegistred,
      options: {
        onSuccess: () => form?.setFieldError('descricao', undefined),
        onError: () =>
          form?.setFieldError(
            'descricao',
            t('supply.productMessage.create.erros.errorMessageNameExist')
          ),
      },
    });

  return {
    messageProductAlreadyRegistred,
  };
};
