import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { InputWithSearch } from '../../../../../../../Components';
import { IFChildren } from '../../../../../../../Components/Form';
import { useCreateCallAPI } from './Hooks/useCreateCallAPI';
import { useCreateMemorizations } from './Hooks/useCreateMemorizations';

import styles from './BalanceAdjustmentsRegistration.module.scss';

export interface IInputWithSearchLot {
  form: IFChildren;
  onLotSelected: (lotExternalId: string) => void;
}

export const InputWithSearchLot: FC<IInputWithSearchLot> = ({
  form,
  onLotSelected,
}) => {
  const { t } = useTranslation();
  const { lotList, isLotLoading, refetchNewPageLot, refetchLot } =
    useCreateCallAPI({
      form,
    });

  const { lotDropdownList } = useCreateMemorizations({ lotList });

  return (
    <InputWithSearch
      items={lotDropdownList}
      className={styles['Left-Icon']}
      name="loteExternalId"
      label={t('supply.losses.create.lot')}
      placeHolder={t('supply.losses.create.lotPlaceholder')}
      disabled={
        !form.values.produtoExternalId || !form.values.localEstoqueExternalId
      }
      onSearch={(search) => refetchLot({ search })}
      withoutMarginBottom
      isLoading={isLotLoading}
      onScrollEnd={refetchNewPageLot}
      onChange={(_, value) => {
        if (value) {
          onLotSelected(value.loteExternalId);
        }

        form?.setFieldValue('fornecedor', undefined);
        form?.setFieldValue('numeroNf', undefined);
        form?.setFieldValue('serieNf', undefined);
      }}
    />
  );
};
