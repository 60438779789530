import { FC, useState } from 'react';
import { Card } from '@/Components';
import { useTranslation } from 'react-i18next';
import { ProductLine } from './Components/ProductLine';
import { v4 as uuidV4 } from 'uuid';

interface IProductCard {
  pharmaceuticalFormExternalId: string;
}

export const ProductCard: FC<IProductCard> = ({
  pharmaceuticalFormExternalId,
}) => {
  const { t } = useTranslation();
  const [productRowAmount, setProductRowAmount] = useState([uuidV4()]);

  const removeRow = (index: number) => {
    setProductRowAmount((oldState) => {
      return oldState.filter((_, i) => i !== index);
    });
  };

  return (
    <>
      <Card title={t('common.productsUppercase')} withoutCollapse={true}>
        {productRowAmount.map((uuid, index) => (
          <ProductLine
            key={uuid}
            index={index}
            onDelete={removeRow}
            totalRows={productRowAmount.length}
            uuidV4={uuidV4}
            setProductRowAmount={setProductRowAmount}
            pharmaceuticalFormExternalId={pharmaceuticalFormExternalId}
          />
        ))}
      </Card>
    </>
  );
};
