import {
  TypeSupplierEnum,
  useListSupplier,
} from '@/Hooks/HooksServices/People/Supplier/useListSupplier';

export const useCarrierList = () => {
  const carrier = useListSupplier({
    fieldName: 'transportadoraExternalId',
    pagination: {
      pageSize: 10,
      pageIndex: 1,
      filter: [
        {
          filterName: 'tipoFornecedorId',
          value: TypeSupplierEnum.shippingCompanyId,
        },
      ],
    },
  });

  return { carrier };
};
