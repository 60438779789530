import { Card } from '@/Components';
import FormBreakLine from '@/Components/FormBreakLine';
import { Col, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import { IInfoForFormCalc } from './interfaces';
import { Density } from './Components/Density';
import { Dilution } from './Components/Dilution';
import { SupplierDilutionFactor } from './Components/SupplierDilutionFactor';
import { ConcentrationWater } from './Components/ConcentrationWater';
import { ConcentrationWaterFactor } from './Components/ConcentrationWaterFactor';
import { AlternativeUnit1 } from './Components/AlternativeUnit1';
import { AlternativeUnit2 } from './Components/AlternativeUnit2';
import { useInfoForFormCalcCallAPI } from './Hooks/useInfoForFormCalcCallAPI';
import { useInfoForFormCalcFunctions } from './Hooks/useInfoForFormCalcFunctions';

export const InfoForFormCalc: React.FC<IInfoForFormCalc> = ({
  disabledForm = false,
  product,
}) => {
  const { t } = useTranslation();

  const {
    unidadeAlternativaList
  } = useInfoForFormCalcCallAPI();

  const {
    defineOriginalUnit
  } = useInfoForFormCalcFunctions({
    product,
    unidadeAlternativaList
  });

  defineOriginalUnit();

  return (
    <Card
      title={t('products.create.infoForFormCalc')}
      withTooltip={{
        icon: 'question-mark',
        title: t('products.create.infoForFormCalcTooltip'),
      }}
    >
      <Row gutter={[16, 0]}>
        <Col span={3}>
          <Density
            product={product}
            disabledForm={disabledForm}
          />
        </Col>
      </Row>
      <Row gutter={[16, 0]}>
        <Col span={5}>
          <Dilution
            product={product}
            disabledForm={disabledForm}
          />
        </Col>
        <Col span={5}>
          <SupplierDilutionFactor
            product={product}
            disabledForm={disabledForm}
          />
        </Col>
        <Col span={5}>
          <ConcentrationWater
            product={product}
            disabledForm={disabledForm}
          />
        </Col>
        <Col span={5}>
          <ConcentrationWaterFactor
            product={product}
            disabledForm={disabledForm}
          />
        </Col>
      </Row>
      <FormBreakLine />
      <Row gutter={[16, 0]}>
        <Col span={6}>
          <AlternativeUnit1
            product={product}
            disabledForm={disabledForm}
            defineOriginalUnit={defineOriginalUnit}
            unidadeAlternativaList={unidadeAlternativaList}
          />
        </Col>
        <Col span={6}>
          <AlternativeUnit2
            product={product}
            disabledForm={disabledForm}
            defineOriginalUnit={defineOriginalUnit}
            unidadeAlternativaList={unidadeAlternativaList}
          />
        </Col>
      </Row>
    </Card>
  );
};
