import { QueryFunctionContext } from '@tanstack/react-query';
import { Http, IQueryListParams } from '../../../Utils/Http';
import {
  IInvoiceGetResponse,
  IInvoiceListResponse,
  IListCfopResponse,
  IListCstResponse,
  IListNcmResponse,
} from '../../Interfaces/response/Purchase/IInvoiceResponse';
import { IDeleteInvoiceRequest } from '../../Interfaces/request/Purchasing/Invoice/IInvoiceRequest';
import { ICreateInvoiceLotRequest } from '@/Data/Interfaces/request/Invoice/IInvoiceRequest';
export class InvoiceAPI {
  public static async getInvoiceList(
    context: QueryFunctionContext
  ): Promise<IInvoiceListResponse | null> {
    if (!context.queryKey[1]) return null;

    return await Http.fetcher<IInvoiceListResponse>(
      Http.AddQueryParams(
        `/pharma/compras/v1/NotaFiscalEntrada/ListarDetalhado`,
        context.queryKey[1] as IQueryListParams
      )
    );
  }

  public static async deleteInvoice(body: IDeleteInvoiceRequest) {
    return await Http.fetcher(`/pharma/compras/v1/NotaFiscalEntrada`, {
      method: 'DELETE',
      body: JSON.stringify(body),
    });
  }

  public static async listDropdownNcm(
    context: QueryFunctionContext
  ): Promise<IListNcmResponse> {
    return await Http.fetcher<IListNcmResponse>(
      Http.AddQueryParams(
        '/pharma/compras/v1/ListarNcm',
        context.queryKey[1] as IQueryListParams
      )
    );
  }

  public static async listDropdownCfop(
    context: QueryFunctionContext
  ): Promise<IListCfopResponse> {
    return await Http.fetcher<IListCfopResponse>(
      Http.AddQueryParams(
        '/pharma/compras/v1/ListarCfop',
        context.queryKey[1] as IQueryListParams
      )
    );
  }

  public static async listDropdownCst(
    context: QueryFunctionContext
  ): Promise<IListCstResponse> {
    return await Http.fetcher<IListCstResponse>(
      Http.AddQueryParams(
        '/pharma/compras/v1/ListarCstCsosn',
        context.queryKey[1] as IQueryListParams
      )
    );
  }

  public static async postInvoice(body: any) {
    const res = await Http.fetcher(`/pharma/compras/v1/NotaFiscalEntrada`, {
      method: 'POST',
      body: JSON.stringify(body),
    });
    if (!res) return [];
    return res;
  }

  public static async postInvoiceDraft(body: any) {
    const res = await Http.fetcher(
      `/pharma/compras/v1/NotaFiscalEntrada/Rascunho`,
      {
        method: 'POST',
        body: JSON.stringify(body),
      }
    );
    if (!res) return [];
    return res;
  }

  public static async getInvoice(context: QueryFunctionContext) {
    if (!context.queryKey[1]) return null;
    return await Http.fetcher<IInvoiceGetResponse>(
      `/pharma/compras/v1/NotaFiscalEntrada/${context.queryKey[1]}`
    );
  }

  public static async savePutDraftInvoice(body: any) {
    return await Http.fetcher(`/pharma/compras/v1/NotaFiscalEntrada/Rascunho`, {
      method: 'PUT',
      body: JSON.stringify(body),
    });
  }

  public static async savePutInvoice(body: any) {
    return await Http.fetcher(`/pharma/compras/v1/NotaFiscalEntrada`, {
      method: 'PUT',
      body: JSON.stringify(body),
    });
  }

  public static async postInvoiceLots(body: ICreateInvoiceLotRequest) {
    return await Http.fetcher(`/pharma/compras/v1/NotaFiscalEntrada/lancar`, {
      method: 'POST',
      body: JSON.stringify(body),
    });
  }

  public static async postInvoiceLotsDraft(body: ICreateInvoiceLotRequest) {
    return await Http.fetcher(
      `/pharma/compras/v1/NotaFiscalEntrada/Lote/Rascunho`,
      {
        method: 'POST',
        body: JSON.stringify(body),
      }
    );
  }

  public static async getInvoiceLots(context: QueryFunctionContext) {
    if (!context.queryKey[1]) return;
    return await Http.fetcher<IInvoiceGetResponse>(
      `/pharma/compras/v1/NotaFiscalEntrada/${context.queryKey[1]}/Lote`
    );
  }

  public static async deleteInvoiceLotDraft(invoiceExternalId: string) {
    return await Http.fetcher(
      `/pharma/compras/v1/NotaFiscalEntrada/${invoiceExternalId}/Lote/Rascunho`,
      {
        method: 'DELETE',
      }
    );
  }

  public static async savePutInvoiceLotDraft(body: ICreateInvoiceLotRequest) {
    return await Http.fetcher(
      `/pharma/compras/v1/NotaFiscalEntrada/Lote/Rascunho`,
      {
        method: 'PUT',
        body: JSON.stringify(body),
      }
    );
  }
}
