import { useCallback } from 'react';
import { IUseFunctions } from './interfaces';

export const usePharmaceuticalFormFunctions = ({
  refetchSearchPharmaceuticalFormPage,
}: IUseFunctions) => {
  const onSearch = useCallback(
    (search?: string) => {
      refetchSearchPharmaceuticalFormPage({
        search: search,
      });
    },
    [refetchSearchPharmaceuticalFormPage]
  );

  return {
    onSearch,
  };
};
