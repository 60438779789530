import { QueryFunctionContext } from '@tanstack/react-query';
import { Http } from '../../../Utils/Http';
import { IGetCEPResponse } from '../../Interfaces/response/CEP/ICEPResponse';

export class CEPAPI {
  public static async getCEP(
    context: QueryFunctionContext
  ): Promise<IGetCEPResponse | null> {
    if (!context?.queryKey[1]) return null;
    let cep = context.queryKey[1] as string;
    cep = cep.replaceAll(/[._-]/g, '');
    if (!cep || cep.length !== 8) return null;
    return await Http.fetcher(`/pharma/localidade/v1/Cep/Cep/${cep}`);
  }
}
