import { FC } from "react";
import { IFChildren } from "@/Components/Form";
import { Card } from '@/Components';
import { AdderList } from '@/Components/AdderList';
import { useTranslation } from 'react-i18next';
import { useContactCardMemorizations } from "./Hooks/useContactCardMemorizations";

export const ContactCard: FC<{props: IFChildren}> = ({ props }) => {
  const { t } = useTranslation();

  const { column, modal } = useContactCardMemorizations();

  return(
    <Card title={t('contact.contacts')} withoutCollapse>
      <AdderList
        favoriteFormName="principal"
        onChange={(values) => props.setFieldValue('contatos', values)}
        collum={column}
        modal={modal}
        type="contactType"
        buttonLabel={t('contact.addContact')}
        empytDataDescription={t('contact.contactEmpyt')}
        entityExternalIdName="prescritorContatoExternalId"
        actions
      />
    </Card>
  );
};