import { useCallback } from "react";
import { 
  FETCH_INCOMPATIBLE_PRODUCT, 
  FETCH_LIST_FOR_DROPDOWN_INCOMPATIBLE_PRODUCT 
} from "@/ControllerApiHook/UniqIds/Supply/ProductKeys";
import { useQueryClient } from "@tanstack/react-query";
import { history } from '@/Store';
import { IUseFunctions } from "./interfaces";

export const useCreateIncompatibleProductFunctions = ({
  createIncompatibleProduct,
  productExternalId
}: IUseFunctions) => {
  const queryClient = useQueryClient();

  const handleSubmit = useCallback(async (values: any) => {
    const result = await createIncompatibleProduct.mutateAsync({
      ...values,
      produtoExternalId: productExternalId,
    });
    if (result) {
      queryClient.invalidateQueries([FETCH_INCOMPATIBLE_PRODUCT]);
      queryClient.invalidateQueries([
        FETCH_LIST_FOR_DROPDOWN_INCOMPATIBLE_PRODUCT,
      ]);
      history.push(`/inventory/products/${productExternalId}/incompatible`);
    }
  }, [createIncompatibleProduct]);

  const onClickCancelButton = () => history.push('/inventory/products');

  return {
    handleSubmit,
    onClickCancelButton
  };
};