import { Button, Card, Form, TextInput } from '@/Components';
import { history } from '@/Store';
import { Col, Row } from 'antd';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { Synonym } from './Components/Synonym';
import { EquivalenceFactor } from './Components/EquivalenceFactor';
import { CorrectionPercentage } from './Components/CorrectionPercentage';
import { LabelDescription } from './Components/LabelDescription';
import { ISynonymDetailProps } from './interfaces';
import { useSynonymDetailsCallAPI } from './Hooks/useSynonymDetailsCallAPI';
import { useSynonymDetailsFunctions } from './Hooks/useSynonymDetailsFunctions';

export const SynonymDetail: FC<ISynonymDetailProps> = ({
  disableForm,
  disabledForm
}) => {
  const { t } = useTranslation();
  const { associationProductId, externalId: productExternalId } = useParams<{
    associationProductId: string;
    externalId: string;
  }>();

  const {
    currentProduct,
    updateSynonym,
    synonymData
  } = useSynonymDetailsCallAPI({
    productExternalId,
    associationProductId
  });

  const {
    handleSubmit,
    onClickBackButton
  } = useSynonymDetailsFunctions({
    updateSynonym,
    associationProductId,
    productExternalId,
    disableForm
  });

  return (
    <Form
      onSubmit={handleSubmit}
      initialValues={synonymData ? synonymData : {}}
    >
      {(props: any) => (
        <>
          <>
            <Card
              title={t('products.synonym.registerSynonymTitle')}
              withoutCollapse
            >
              <Row gutter={[16, 0]}>
                <Col span={7}>
                  <TextInput
                    name="produtoExternalId"
                    value={currentProduct ? currentProduct.descricao : ''}
                    label={t('products.incompatible.product')}
                    disabled
                  />
                </Col>
              </Row>
              <Row gutter={[16, 0]}>
                <Synonym
                  synonymData={synonymData}
                  currentProduct={currentProduct}
                  props={props}
                  disabledForm={disabledForm}
                />
                <EquivalenceFactor
                  synonymData={synonymData}
                  props={props}
                  disabledForm={disabledForm}
                />
                <CorrectionPercentage
                  synonymData={synonymData}
                  props={props}
                  disabledForm={disabledForm}
                />
              </Row>
              <Row gutter={[16, 0]}>
                <LabelDescription
                  synonymData={synonymData}
                  props={props}
                  disabledForm={disabledForm}
                />
              </Row>
            </Card>
            {!disabledForm && (
              <div style={{ display: 'flex' }}>
                <Button
                  type="secondary"
                  style={{ marginRight: 8 }}
                  children={t('common.cancel')}
                  onClick={() => {
                    disableForm();
                    if (props.dirty) {
                      history.push(
                        `/inventory/products/${productExternalId}/synonym`
                      );
                    }
                  }}
                />
                <Button
                  type="primary"
                  children={
                    updateSynonym.isLoading
                      ? t('common.saving')
                      : t('common.save')
                  }
                  htmlType="submit"
                  loading={updateSynonym.isLoading}
                  disabled={!props.values.sinonimo}
                />
              </div>
            )}
            {disabledForm && (
              <Button
                type="secondary"
                onClick={onClickBackButton}
                children={t('common.back')}
              />
            )}
          </>
        </>
      )}
    </Form>
  );
};
