import { history } from '@/Store';
import {
  FETCH_GET_ORDER_PURCHASE,
  FETCH_LIST_PURCHASE_ORDER,
} from '@/ControllerApiHook/UniqIds/Purchase/PurchaseOrderKeys';
import DateUtils from '@/Utils/DateUtils';
import { ICreateOrderPurchaseRequest } from '@/Data/Interfaces/request/OrderPurchase/IOrderPurchaseRequest';
import { useQueryClient } from '@tanstack/react-query';
import { FormikHelpers, FormikValues } from 'formik';
import StorageUtils from '@/Utils/StorageUtils';
import { IUseFunctions } from './interfaces';
import { NotificationActions } from '@/Store/Notification/Notification.actions';
import { useDispatch } from 'react-redux';

export const useEditOrderFunctions = ({
  editOrderPurchase,
  externalId,
}: IUseFunctions) => {
  const dispatch = useDispatch();
  const preferences = StorageUtils.getPreference();
  const queryClient = useQueryClient();

  const onSubmit = async (
    values: any,
    helpers: FormikHelpers<FormikValues>
  ) => {
    try {
      var body: ICreateOrderPurchaseRequest = { ...values };
      if (!preferences) return;
      if (body.previsaoEntrega)
        body.previsaoEntrega = DateUtils.convertDateToApi(
          body.previsaoEntrega,
          preferences.padraoData
        );
      values.pedidoCompraItens.pop();
      const res = await editOrderPurchase.mutateAsync({
        externalId: body.externalId,
        fornecedorExternalId: body.fornecedorExternalId,
        previsaoEntrega: body.previsaoEntrega,
        observacao: body.observacao,
        frete: body.frete,
        valorAdicional: body.valorAdicional,
        tipoDesconto: body.tipoDesconto,
        valorDesconto: body.valorDesconto,
        pedidoCompraItens: values.pedidoCompraItens.map((x: any) => ({
          pedidoCompraItemExternalId: x.pedidoCompraItemExternalId,
          produtoExternalId: x.produtoExternalId,
          quantidade: x.quantidade,
          unidadeMedidaId: x.unidadeMedidaId,
          precoUnitario: x.precoUnitario,
          valorDescontoUnitario: x.valorDescontoUnitario
            ? x.valorDescontoUnitario
            : 0,
          tipoDesconto: x.tipoDescontoProdutoId ? x.tipoDescontoProdutoId : 2,
        })),
      });
      if (res.success) {
        queryClient.invalidateQueries([FETCH_GET_ORDER_PURCHASE]);
        queryClient.invalidateQueries([FETCH_LIST_PURCHASE_ORDER]);
        history.push(`/order/purchase/${externalId}/details`);
      }
    } catch {
    } finally {
      helpers.setSubmitting(false);
    }
  };

  const onClick = () => {
    history.push(`/order/purchase/${externalId}/details`);
  };

  return {
    onSubmit,
    onClick,
  };
};
