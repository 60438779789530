import { FC } from "react";
import { Col } from 'antd';
import { useTranslation } from 'react-i18next';
import {
  TextInput
} from '@/Components';

export const CorporateName: FC = () => {
  const { t } = useTranslation();

  return (
    <Col span={9}>
      <TextInput
        name="razaoSocial"
        label={t('supply.lot.create.corporateName')}
        disabled
        placeHolder="-"
      />
    </Col>
  );
};