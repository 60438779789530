import { FC } from "react";
import { Col } from 'antd';
import { useTranslation } from 'react-i18next';
import { IAssociatedProduct } from "./interfaces";
import { InputWithSearch } from "@/Components";
import { useAssociatedProductCallAPI } from "./Hooks/useAssociatedProductCallAPI";
import { useAssociatedProductMemorizations } from "./Hooks/useAssociatedProductMemorizations";
import { useAssociatedProductFunctions } from "./Hooks/useAssociatedProductFunctions";

export const AssociatedProduct: FC<IAssociatedProduct> = ({
  productExternalId,
  setAssociatedProductExternalId
}) => {
  const { t } = useTranslation();

  const {
    productList,
    fetchNewProductPage,
    refetchSearchProductPage
  } = useAssociatedProductCallAPI({
    productExternalId
  });

  const {
    items
  } = useAssociatedProductMemorizations({
    productList,
    productExternalId
  });

  const {
    onChange,
    onSearch
  } = useAssociatedProductFunctions({
    refetchSearchProductPage,
    setAssociatedProductExternalId
  });

  return (
    <Col span={7}>
      <InputWithSearch
        name="produtoAssociadoExternalId"
        required
        label={t('products.associated.associatedProduct')}
        placeHolder={t(
          'products.associated.searchForAssociatedProductPlaceholder'
        )}
        items={items}
        onScrollEnd={fetchNewProductPage}
        onSearch={onSearch}
        onChange={onChange}
      />
    </Col>
  );
};