import { FC } from 'react';
import { IDropdown, IIcon } from '../../types';
import { Tooltip, Text, Icon } from '@/Components';
import { Select } from 'antd';
import { useField } from 'formik';

import styles from '../VisualInput.module.scss';

export interface BeforeAddonProps {
  leftIcon?: IIcon;
  dropdownLeft?: IDropdown;
  disabled?: boolean;
}

export const BeforeAddon: FC<BeforeAddonProps> = ({
  leftIcon,
  dropdownLeft,
  disabled,
}) => {
  const dropdownLeftField = useField(dropdownLeft?.name || 'dropdownLeft');

  if (leftIcon?.titleString) {
    return (
      <Tooltip
        title={leftIcon.toolTipTitle && leftIcon.toolTipTitle[0]}
        showMe={!!leftIcon.toolTipTitle}
        placement="bottomRight"
      >
        <Text
          className={styles['Icon-TitleString']}
          type="ui-tiny-content"
          color="text-300"
          children={leftIcon?.titleString}
        />
      </Tooltip>
    );
  }

  if (!dropdownLeft) {
    return null;
  }

  return (
    <Select
      value={dropdownLeftField[1]?.value}
      defaultValue={dropdownLeft.defaultValue}
      style={{ width: 53 }}
      loading={dropdownLeft.loading}
      onChange={(selectedValue) => dropdownLeftField[2].setValue(selectedValue)}
      disabled={disabled}
      onSelect={(id) => dropdownLeft.onChange && dropdownLeft.onChange(id)}
      optionLabelProp="content"
      options={dropdownLeft?.options.map((x) => ({
        value: x.id,
        label: x.content ? (
          <Text
            type="ui-tiny-content"
            color="text-50"
            children={x.customDescription ? x.customDescription : x.content}
          />
        ) : (
          x.icons && <Icon icon={x.icons} color="text-300" />
        ),
        content: (
          <Text type="ui-sub-content" color="text-300" children={x.content} />
        ),
      }))}
    />
  );
};
