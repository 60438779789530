import { FC, useMemo } from 'react';
import { Col } from 'antd';
import { useTranslation } from 'react-i18next';
import { InputWithSearch } from '@/Components';
import { ISpecialty } from './interfaces';
import { useSpecialtyFunctions } from './Hooks/useSpecialtyFunctions';
import { useDispatch } from 'react-redux';
import { NotificationActions } from '@/Store/Notification/Notification.actions';
import { useSpecialtyState } from './Hooks/useSpecialtyState';
import { CreateExpertiseModal } from '@/Pages/Sectors/Sales/Expertise/List/Modal/CreateExpertiseModal';

export const Specialty: FC<ISpecialty> = ({
  specialtiesData,
  fetchNewSpecialtiesPage,
  refetchSearchSpecialties,
  specialtiesLoading,
}) => {
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const {
    descriptionValue,
    setDescriptionValue,
    isFastSpecialtyVisible,
    setIsFastSpecialtyVisible,
  } = useSpecialtyState();

  const { onSearch } = useSpecialtyFunctions({ refetchSearchSpecialties });

  const emptyState = useMemo(
    () => ({
      onClick: () => {
        dispatch(NotificationActions.setCurrentFormIsDirty(false));
        setIsFastSpecialtyVisible(true);
      },
      suffix: ` (${t('common.newExpertise')})`,
    }),
    [dispatch, setIsFastSpecialtyVisible, t]
  );

  return (
    <Col span={24}>
      <InputWithSearch
        name="especialidadesIds"
        label={t('prescribers.createPage.info.specialties')}
        placeHolder={t('prescribers.createPage.info.specialtiesPlaceholder')}
        items={specialtiesData?.data?.map((x: any) => ({
          id: x.externalId,
          label: x.descricao,
        }))}
        multipleSelection
        onScrollEnd={fetchNewSpecialtiesPage}
        onSearch={onSearch}
        withoutSearchIcon
        isLoading={specialtiesLoading}
        emptyState={emptyState}
        required
        dataTestId="client-dropdown"
        inputDataTestId="client-input"
        onChangeInputText={(x) =>
          !isFastSpecialtyVisible && setDescriptionValue(x)
        }
      />
      <CreateExpertiseModal
        createModalOpen={isFastSpecialtyVisible}
        setCreateModalOpen={setIsFastSpecialtyVisible}
        initialDescription={descriptionValue}
      />
    </Col>
  );
};
