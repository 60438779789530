import { useMemo } from 'react';
import { IUseMemorizations } from './interfaces';
import { useTranslation } from 'react-i18next';
import { ModalBody } from '../Modal';

export const useListMemorizations = ({
  rowSelected,
  onRowDoubleClick,
  modalTitle,
}: IUseMemorizations) => {
  const { t } = useTranslation();

  const headerOptions = useMemo(
    () => ({
      hasSearch: true,
      searchPlaceholder: t('admin.companyList.searchPlaceholder'),
      nameKey: 'nomeFantasia',
      editButton: {
        permission: {
          permission: 1017,
          tooltip: 'noPermission.administrative.userGroups.statusEdit',
        },
        options: [
          {
            label: t('common.responsible'),
            isStatusOnly: true,
          },
        ],
      },
    }),
    [t]
  );

  const editModal = useMemo(
    () => ({
      disabledButtonFormNames: ['usuarioResponsavelExternalId'],
      withForm: true,
      title: modalTitle(rowSelected),
      body: <ModalBody />,
    }),
    [rowSelected, modalTitle]
  );

  const rowOptions = useMemo(
    () => ({
      permission: {
        permission: 1017,
        tooltip: 'noPermission.administrative.company.detail',
      },
      onRowDoubleClick: onRowDoubleClick,
    }),
    [onRowDoubleClick]
  );

  return {
    headerOptions,
    editModal,
    rowOptions,
  };
};
