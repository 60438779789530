import { useTranslation } from 'react-i18next';
import { StandardFormulaDetails } from './Components/StandardFormulaDetails';
import { Form, Card, Button } from '@/Components';
import { ProductCard } from './Components/ProductCard/index';
import { Col, Row } from 'antd';
import { StandardFormulaSpecifications } from './Components/StandardFormulaSpecifications';
import { StandardFormulaCreateSchema } from './CreateStandardFormula.schema';
import { useCreateCallAPI } from './Hooks/useCreateCallAPI';
import { useCreateStates } from './Hooks/useCreateStates';
import { useCreateFunctions } from './Hooks/useCreateFunctions';

import styles from './Create.module.scss';
import { ConnectedFocusError } from '@/Components/ConnectedFocusError';

export const CreateStandardFormula: React.FC = () => {
  const { t } = useTranslation();

  const { createStandardFormula } = useCreateCallAPI();

  const {
    pharmaceuticalFormExternalIdSelected,
    setPharmaceuticalFormExternalIdSelected,
  } = useCreateStates();

  const { onSubmit, onClickCancelButton } = useCreateFunctions({
    createStandardFormula,
  });

  return (
    <>
      <Form
        onSubmit={onSubmit}
        schema={StandardFormulaCreateSchema}
        initialValues={{
          produtos: [
            {
              fase: 0,
            },
          ],
        }}
      >
        {() => (
          <>
            <ConnectedFocusError />
            <Card title={t('common.details')} withoutCollapse={true}>
              <StandardFormulaDetails
                setPharmaceuticalFormSelected={
                  setPharmaceuticalFormExternalIdSelected
                }
              />
            </Card>
            <Row justify="space-between" gutter={[16, 0]}>
              <Col span={24}>
                <ProductCard
                  pharmaceuticalFormExternalId={
                    pharmaceuticalFormExternalIdSelected
                  }
                />
              </Col>
            </Row>
            <Card title={t('common.specifications')} withoutCollapse={true}>
              <StandardFormulaSpecifications />
            </Card>
            <div className={styles['footer-button']}>
              <Button
                type="secondary"
                children={t('common.cancel')}
                style={{ marginBottom: '20px' }}
                onClick={onClickCancelButton}
              />
              <Button
                type="primary"
                children={t('common.create')}
                htmlType="submit"
                loading={createStandardFormula.isLoading}
              />
            </div>
          </>
        )}
      </Form>
    </>
  );
};
