import { FC } from "react";
import { Tooltip } from '@/Components';
import { Col } from 'antd';
import { ISynonym } from "./interfaces";
import { useTranslation } from "react-i18next";
import { SynonymInput } from "./Components/SynonymInput";

export const Synonym: FC<ISynonym> = ({
  synonymData,
  currentProduct,
  props,
  disabledForm
}) => {
  const { t } = useTranslation();

  return (
    <Col span={8}>
      <Tooltip
        title={`${t('products.synonym.originalSynonym')}${
          synonymData?.sinonimo
        }`}
        showMe={
          synonymData &&
          synonymData.sinonimo !== props?.values?.sinonimo
        }
        targetOffset={[80, -10]}
      >
        <SynonymInput
          currentProductDescription={
            currentProduct ? currentProduct.descricao : ''
          }
          disabled={disabledForm}
          synonymInitial={synonymData?.sinonimo}
        />
      </Tooltip>
    </Col>
  );
};
