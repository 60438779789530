import { FC } from "react";
import { Col } from 'antd';
import {
  InputWithSearch,
} from '@/Components';
import { useTranslation } from "react-i18next";
import { IProvider } from "./interfaces";
import { useProviderMemorizations } from "./Hooks/useProviderMemorizations";
import { useProviderFunctions } from "./Hooks/useProviderFunctions";
import { useProviderCallAPI } from "./Hooks/useProviderCallAPI";

export const Provider: FC<IProvider> = ({
  disabledForm,
  setProviderId,
}) => {
  const { t } = useTranslation();

  const {
    providersList,
    isProviderLoading,
    fetchNewProviderPage,
    refetchSearchProviderPage
  } = useProviderCallAPI();

  const {
    items
  } = useProviderMemorizations({ providersList });

  const {
    onChange,
    onSearch
  } = useProviderFunctions({
    providersList,
    setProviderId,
    refetchSearchProviderPage
  });

  return (
    <Col span={9}>
      <InputWithSearch
        items={items}
        onScrollEnd={fetchNewProviderPage}
        name="fornecedorExternalId"
        label={t('products.create.provider')}
        placeHolder={t('products.create.providerPlaceholder')}
        disabled={disabledForm}
        onChange={onChange}
        onSearch={onSearch}
        isLoading={isProviderLoading}
        required
      />
    </Col>
  );
};