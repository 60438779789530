import { CreateUserGroup } from '@/Pages/Sectors/Administrative/UserGroups/CreateForm';
import EditForm from '@/Pages/Sectors/Administrative/UserGroups/EditForm';
import UserGroupsList from '@/Pages/Sectors/Administrative/UserGroups/List';
import { Route, Switch } from 'react-router-dom';

export const AdminUserGroupRoutes = () => (
  <Switch>
    <Route path="/admin/user-group/:externalId/:tab" component={EditForm} />
    <Route path="/admin/user-group/create" component={CreateUserGroup} />
    <Route path="/admin/user-group" component={UserGroupsList} />
  </Switch>
);
