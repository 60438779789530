import { Http, IQueryListParams } from '@/Utils/Http';
import { QueryFunctionContext } from '@tanstack/react-query';

export interface IListNatureOfOperationResponse {
  pageIndex: number;
  pageSize: number;
  count: number;
  data: INatureOfOperationListData[];
}

export interface INatureOfOperationListData {
  id: number;
  descricao: string;
}

export const ListNatureOfOperation = async (
  context: QueryFunctionContext
): Promise<IListNatureOfOperationResponse | undefined> => {
  return await Http.fetcher<IListNatureOfOperationResponse>(
    Http.AddQueryParams(
      '/pharma/compras/v1/NaturezaOperacao/Listar',
      context.queryKey[1] as IQueryListParams
    )
  );
};
