import { FC } from 'react';
import { i18n } from '@/Components';
import { Modal } from '@/Components/Modal';
import { IDeleteLotModal } from './interfaces';
import { useModalCallAPI } from './Hooks/useModalCallAPI';
import { useModalMemorizations } from './Hooks/useModalMemorizations';
import { useModalFunctions } from './Hooks/useModalFunctions';

export const DeleteLotModal: FC<IDeleteLotModal> = ({
  lot,
  visibleModal,
  changeVisibleModal,
}) => {
  const { deleteLot } = useModalCallAPI();

  const { modalBody } = useModalMemorizations({ lot });

  const { onOkClick, onCancelClick, onClose } = useModalFunctions({
    lot,
    changeVisibleModal,
    deleteLot,
  });

  return (
    <Modal
      title={`${i18n.t('common.delete')} ${i18n.t('common.theLot')} "${
        lot?.numero
      }"?`}
      body={modalBody}
      okButtonColor="status-danger-base"
      visible={visibleModal}
      onOkClick={onOkClick}
      okButtonName={i18n.t('common.delete')}
      onCancelClick={onCancelClick}
      onClose={onClose}
      okButtonType="danger"
    />
  );
};
