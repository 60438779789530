import { useCallback, useMemo } from 'react';
import { IUseFunctions } from './interfaces';
import _ from 'lodash';

export const useFormDescriptionBodyFunctions = ({
  mutateAsync,
  form,
}: IUseFunctions) => {
  const fetchSpecialtiesValidationName = useMemo(
    () =>
      _.debounce(async (descricao: string) => {
        return await mutateAsync({
          descricao,
        });
      }, 500),
    [mutateAsync]
  );

  const onChange = useCallback(
    (value: string) =>
      form.initialValues.descricao.toLowerCase() === value.toLowerCase()
        ? form.setFieldError('descricao', undefined)
        : fetchSpecialtiesValidationName(value),
    [fetchSpecialtiesValidationName, form]
  );

  return {
    onChange,
  };
};
