import { useCallback, useEffect } from "react";
import { IUseFunctions } from "./interfaces";

export const useEditFunctions = ({
  setModalDeleteProductGroup,
  setDisabled,
  params
}: IUseFunctions) => {
  const onCancelClick = useCallback(
    () => setModalDeleteProductGroup(false), [setModalDeleteProductGroup]
  );

  const onOkClick = useCallback(
    () => setModalDeleteProductGroup(false), [setModalDeleteProductGroup]
  );

  useEffect(() => {
    params.tab && setDisabled(true);
  }, [params.tab]);

  return {
    onCancelClick,
    onOkClick
  };
};