import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormikContext } from 'formik';
import { IAmount } from './interfaces';
import { NumberInput } from '@/Components/Inputs/NumberInput';
import { useAmountMemorizations } from './Hooks/useAmountMemorizations';

export const Amount: FC<IAmount> = ({
  index
}) => {
  const { t } = useTranslation();
  const form = useFormikContext<any>();

  const {
    label,
    rightIcon
  } = useAmountMemorizations({ index });

  return (
    <>
      <NumberInput
        name={`pedidoCompraItens.${index}.quantidade`}
        label={label}
        required
        placeHolder={t(
          'purchasing.purchaseOrder.create.productsCardPlaceHolders.amount'
        )}
        withoutMarginBottom
        rightIcon={rightIcon}
        disabled={
          !form?.values?.pedidoCompraItens?.[index]?.produtoExternalId
        }
        decimalScale={4}
        fixedDecimalScale
        withThousandSeparator
        withDecimalSeparator
      />
    </>
  );
};