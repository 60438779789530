import { isEqual, isNil, omitBy } from 'lodash';
import DateUtils from './DateUtils';

export interface ICompareEqualObj {
  obj: {
    firstObj: any;
    secondObj: any;
  };
}

export const compareEqualObj = ({ obj }: ICompareEqualObj) => {
  const firstObjClean = omitBy(obj.firstObj, (x) => isNil(x) || x === '');
  const secondObjClean = omitBy(obj.secondObj, (x) => isNil(x) || x === '');
  const isEqualObjects = isEqual(firstObjClean, secondObjClean);
  return isEqualObjects;
};

export const isEqualByProps = (
  obj1: any,
  obj2: any,
  props: {
    name: string;
    type: 'string' | 'date' | 'array' | 'number';
    keys?: string[];
  }[]
) => {
  let isEqual = true;

  if (!obj1 || !obj2) return false;

  for (const prop of props) {
    let val1 = obj1[prop.name];
    let val2 = obj2[prop.name];

    switch (prop.type) {
      case 'string':
        isEqual = val1 === val2;
        break;
      case 'number':
        isEqual = val1 === val2;
        break;
      case 'date':
        if (val1 && val1.indexOf('T') > -1) {
          const split = val1.split('T');
          val1 = split[0];
        }
        if (val2 && val2.indexOf('T') > -1) {
          const split = val2.split('T');
          val2 = split[0];
        }
        const tmp1 = val1 && DateUtils.convertDateToApi(val1) === val2;
        const tmp2 = val2 && DateUtils.convertDateToApi(val2) === val1;
        if (
          (val1 === '__/__/____' && isNil(val2)) ||
          (val2 === '__/__/____' && isNil(val1)) ||
          (val1 === '' && isNil(val2)) ||
          (val2 === '' && isNil(val1)) ||
          (val1 === '' && val2 === '')
        ) {
          isEqual = true;
        } else {
          isEqual = tmp1 || tmp2 || (isNil(val1) && isNil(val2));
        }
        break;
      case 'array':
        return (
          val1.length === val2.length &&
          //TODO: QUANDO ADICIONO DUAS LINHAS EXATAMENTES IGUAIS, ELE MOSTRA QUE NÃO HÁ MUDANÇA.
          val1.every((currentItem1: any) =>
            val2.some((currentItem2: any) => {
              for (const key of prop.keys || []) {
                const isEquals = currentItem1[key] === currentItem2[key];
                if (!isEquals) {
                  return false;
                }
              }
              return true;
            })
          )
        );
    }

    if (!isEqual) {
      break;
    }
  }

  return isEqual;
};
