import { i18n } from '../../../../../../Components';

export const dismembermentTypes = [
  {
    id: 1,
    name: i18n.t('production.standardFormula.list.typeToken.notDismember'),
  },
  {
    id: 2,
    name: i18n.t(
      'production.standardFormula.list.typeToken.dismemberSameToken'
    ),
  },
  {
    id: 3,
    name: i18n.t(
      'production.standardFormula.list.typeToken.dismemberTokenSeparate'
    ),
  },
];

export const formulaProductTypes = [
  {
    id: 1,
    name: i18n.t(
      'production.standardFormula.create.productLine.formulaProductTypes.normal'
    ),
  },
  {
    id: 2,
    name: i18n.t(
      'production.standardFormula.create.productLine.formulaProductTypes.qsp'
    ),
  },
  {
    id: 3,
    name: i18n.t(
      'production.standardFormula.create.productLine.formulaProductTypes.qs'
    ),
  },
];
