import { IUseMemorizations } from './interfaces';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { history } from '@/Store';
import { BodyHandler, TitleHandler } from '../Modal/deleteModal';
import { IDeleteModalTable } from '@/Components/Table';
import { PermissionEnum } from '@/Data/Interfaces/model/permissionEnum';

export const useListMemorizations = ({
  selectedRowsOnTable,
}: IUseMemorizations) => {
  const { t } = useTranslation();

  const headerOptions = {
    nameKey: 'nomeFantasia',
    hasSearch: true,
    searchPlaceholder: t('purchasing.provider.list.searchPlaceholder'),
    newButton: {
      onClick: () => history.push('/order/supplier/create'),
      label: 'purchasing.provider.newButton',
      permission: {
        permission: PermissionEnum.Compras_Fornecedores_Cadastrar,
        tooltip: 'noPermission.purchasing.supplier.create',
      },
    },
    editButton: {
      options: [
        {
          label: t('common.status'),
          isStatusOnly: true,
          permission: {
            permission: PermissionEnum.Compras_Fornecedores_AlterarStatus,
            tooltip: 'noPermission.purchasing.supplier.statusEdit',
          },
        },
      ],
    },
    deleteButton: {
      permission: {
        permission: PermissionEnum.Compras_Fornecedores_Excluir,
        tooltip: 'noPermission.purchasing.supplier.delete',
      },
    },
  };

  const rowOptions = {
    permission: {
      permission: PermissionEnum.Compras_Fornecedores_VerDetalhes,
      tooltip: 'noPermission.purchasing.supplier.detail',
    },
    onRowDoubleClick: (x: any) =>
      history.push(`/order/supplier/${x.externalId}/details`),
  };

  const deleteModal = useMemo(
    (): IDeleteModalTable | undefined => ({
      title: TitleHandler(selectedRowsOnTable, t),
      body: BodyHandler(selectedRowsOnTable, t),
      buttons: {
        okButtonColor: 'status-danger-base',
      },
       
    }),
    [selectedRowsOnTable, t]
  );

  const editModal = useMemo(
    (): IDeleteModalTable | undefined => ({
      title:
        selectedRowsOnTable?.length > 1
          ? `${t('modal.editModal.many.title.before')} ${
              selectedRowsOnTable?.length
            } ${t('common.suppliers')}?`
          : undefined,
    }),
    [selectedRowsOnTable, t]
  );

  return {
    headerOptions,
    rowOptions,
    deleteModal,
    editModal,
  };
};
