import { useState } from "react";

export const useCompanyCreateStates = () => {
  const [radioCompanyGroup, setRadioCompanyGroup] = useState(true);

  const [radioUser, setRadioUser] = useState(true);

  const [radioCompanyBillings, setRadioCompanyBillings] = useState(true);

  const [validateAfterSubmit, setValidateAfterSubmit] = useState(false);
  
  const [companyCardSchema, setCompanyCardSchema] = useState<any>();

  return {
    radioCompanyGroup,
    setRadioCompanyGroup,
    radioUser,
    setRadioUser,
    radioCompanyBillings,
    setRadioCompanyBillings,
    validateAfterSubmit,
    setValidateAfterSubmit,
    companyCardSchema,
    setCompanyCardSchema
  };
};