import { FC } from "react";
import { Col } from 'antd';
import {
  TextInput
} from '@/Components';
import { useTranslation } from "react-i18next";
import { IProductCode } from "./interfaces";

export const ProductCode: FC<IProductCode> = ({
  index,
  disabled,
  currentProduct
}) => {
  const { t } = useTranslation();

  return (
    <Col span={2}>
      <TextInput
        label={
          index === 0
            ? t(
                'production.standardFormula.details.productLine.productCode'
              )
            : ''
        }
        placeHolder={t(
          'production.standardFormula.details.productLine.productCodePlaceholder'
        )}
        disabled
        name={`produtos.${index}.codigoProduto`}
        required={!disabled}
        value={currentProduct?.sequenciaGroupTenant.toString()}
      />
    </Col>
  );
};