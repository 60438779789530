import { useMemo } from "react";
import { FooterThisActionInitialStockBalance } from '@/Components/Table/components/Modals/DeleteModal';
import { useTranslation } from 'react-i18next';
import ReactMarkdown from 'react-markdown';
import { IUseMemorizations } from "./interfaces";

export const useModalMemorizations = ({
  lot
}: IUseMemorizations) => {
  const { t } = useTranslation();

  const modalBody = useMemo(() => (
    <div style={{ padding: '20px', maxWidth: 410 }}>
      <ReactMarkdown>
        {t('modal.deleteModal.single.question', {
          numero: `**${lot?.numero}**`,
          produto: `**${lot?.produto}**`,
        })}
      </ReactMarkdown>
      <FooterThisActionInitialStockBalance />
    </div>
  ), [lot, t]);

  return {
    modalBody
  };
};