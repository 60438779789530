import { IUseMemorizations } from './interfaces';
import { IPackingClassificationListData } from '@/Data/Interfaces/response/PackingClassification/IPackingClassificationResponse';

export const usePackingClassificationMemorizations = ({
  packingClassificationList,
}: IUseMemorizations) => {
  let packingClassificationListArray: any = packingClassificationList;
  const items = Array.isArray(packingClassificationListArray)
    ? packingClassificationListArray
    : packingClassificationListArray.data.map(
        (e: IPackingClassificationListData) => ({
          id: e.externalId,
          label: e.descricao,
        })
      );

  return { items };
};
