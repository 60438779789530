import { FC } from "react";
import { Col } from 'antd';
import { useTranslation } from 'react-i18next';
import { NumberInput } from '@/Components/Inputs/NumberInput';
import { IPackingQuantity } from "./interfaces";

export const PackingQuantity: FC<IPackingQuantity> = ({
  index,
  disabledForm
}) => {
  const { t } = useTranslation();

  return(
    <Col span={3}>
      <NumberInput
        name={`embalagemAssociacao.${index}.quantidadeEmbalagem`}
        label={index === 0 ? t('products.create.quantity') : ''}
        placeHolder="0"
        disabled={disabledForm}
      />
    </Col>
  );
};