import { createAction, Action, ActionsUnion } from '../Base/actionHelpers';
import { IUpdateUserGroupPermission } from '../../Data/Interfaces/request/UserGroup/IUpdateUserGroupPermission';
import { IUpdatePermissionResponse } from '../../Data/Interfaces/response/User/IUpdatePermissionResponse';

export enum UserGroupActionKeys {
  UPDATE_USER_GROUP_PERMISSION = '[GROUP] UPDATE_USER_GROUP_PERMISSION',

  SHOW_PERMISSION_DEPENDENCY_MODAL = '[GROUP] SHOW_PERMISSION_DEPENDENCY_MODAL',
  HIDE_PERMISSION_DEPENDENCY_MODAL = '[GROUP] HIDE_PERMISSION_DEPENDENCY_MODAL',

  UPDATE_USER_GROUP_MODULE_STATUS = '[GROUP] UPDATE_USER_GROUP_MODULE_STATUS',
}

export const UserGroupActions = {
  updatePermission: (
    body: IUpdateUserGroupPermission
  ): UpdateUserGroupPermission =>
    createAction(UserGroupActionKeys.UPDATE_USER_GROUP_PERMISSION, body),

  showPermissionDependencyModal: (
    body: IUpdatePermissionResponse<IUpdateUserGroupPermission>
  ): ShowPermissionDependencyModal =>
    createAction(UserGroupActionKeys.SHOW_PERMISSION_DEPENDENCY_MODAL, body),
  hidePermissionDependencyModal: (): HidePermissionDependencyModal =>
    createAction(UserGroupActionKeys.HIDE_PERMISSION_DEPENDENCY_MODAL),

  updateGroupModuleStatus: (payload: {
    permissionId: string;
    value: boolean;
  }): UpdateGroupModuleStatusAction =>
    createAction(UserGroupActionKeys.UPDATE_USER_GROUP_MODULE_STATUS, payload),
};

export type UserGroupActionUnion = ActionsUnion<typeof UserGroupActions>;

export type UpdateGroupModuleStatusAction = Action<
  UserGroupActionKeys.UPDATE_USER_GROUP_MODULE_STATUS,
  {
    permissionId: string;
    value: boolean;
  }
>;

export type UpdateUserGroupPermission = Action<
  UserGroupActionKeys.UPDATE_USER_GROUP_PERMISSION,
  IUpdateUserGroupPermission
>;

export type ShowPermissionDependencyModal = Action<
  UserGroupActionKeys.SHOW_PERMISSION_DEPENDENCY_MODAL,
  IUpdatePermissionResponse<IUpdateUserGroupPermission>
>;

export type HidePermissionDependencyModal =
  Action<UserGroupActionKeys.HIDE_PERMISSION_DEPENDENCY_MODAL>;
