export const cnaeItems = [
  {
    id: '4771702',
    label:
      '4771702 - COMERCIO VAREJISTA DE PRODUTOS FARMACEUTICOS, COM MANIPULACAO DE FORMULAS',
  },
  {
    id: '1099699',
    label:
      '1099699 - FABRICACAO DE OUTROS PRODUTOS ALIMENTICIOS NAO ESPECIFICADOS ANTERIORMENTE',
  },
  {
    id: '2063100',
    label:
      '2063100 - FABRICACAO DE COSMETICOS, PRODUTOS DE PERFUMARIA E DE HIGIENE PESSOAL',
  },
  {
    id: '2099199',
    label:
      '2099199 - FABRICACAO DE OUTROS PRODUTOS QUIMICOS NAO ESPECIFICADOS ANTERIORMENTE',
  },
  {
    id: '2121101',
    label: '2121101 - FABRICACAO DE MEDICAMENTOS ALOPATICOS PARA USO HUMANO',
  },
  {
    id: '2121103',
    label: '2121103 - FABRICACAO DE MEDICAMENTOS FITOTERAPICOS PARA USO HUMANO',
  },
  {
    id: '2122000',
    label: '2122000 - FABRICACAO DE MEDICAMENTOS PARA USO VETERINARIO',
  },
  {
    id: '4644301',
    label:
      '4644301 - COMERCIO ATACADISTA DE MEDICAMENTOS E DROGAS DE USO HUMANO',
  },
  {
    id: '4771701',
    label:
      '4771701 - COMERCIO VAREJISTA DE PRODUTOS FARMACEUTICOS, SEM MANIPULACAO DE FORMULAS',
  },
  {
    id: '4771703',
    label:
      '4771703 - COMERCIO VAREJISTA DE PRODUTOS FARMACEUTICOS HOMEOPATICOS',
  },
  {
    id: '4771704',
    label: '4771704 - COMERCIO VAREJISTA DE MEDICAMENTOS VETERINARIOS',
  },
  {
    id: '4772500',
    label:
      '4772500 - COMERCIO VAREJISTA DE COSMETICOS, PRODUTOS DE PERFUMARIA E DE HIGIENE PESSOAL',
  },
];
