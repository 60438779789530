import { Button } from '@/Components';
import { PermissionWrapper } from '@/Components/PermissionWrapper';
import { PermissionEnum } from '@/Data/Interfaces/model/permissionEnum';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import styles from './ReverseButton.module.scss';

interface IReverseButton {
  onCancelClick?: () => void;
}

export const ReverseButton: FC<IReverseButton> = ({ onCancelClick }) => {
  const { t } = useTranslation();
  return (
    <PermissionWrapper
      permission={PermissionEnum.Vendas_PedidosVenda_Estornar}
      tooltip={t('noPermission.sale.order.reserve')}
    >
      <Button
        className={styles['reverse-button']}
        leftIcon="reply"
        type="secondary"
        wrapperStyle={{ width: '100%' }}
        fullWidth
        children={t('common.reverse')}
        onClick={onCancelClick}
      />
    </PermissionWrapper>
  );
};
