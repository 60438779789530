import { IProductGroupListData } from "@/Data/Interfaces/response/Product/IProductRespose";
import { useFormikContext } from "formik";
import { useCallback } from "react";
import { IUseFunctions } from "./interfaces";

export const useTransfersFunctions = ({
  index,
  refetchUnitsMeasurements,
  refetchProducts,
  addRow,
  balanceOriginDestiny,
  productSelected,
  setProductSelected
}: IUseFunctions) => {
  const form = useFormikContext<any>();

  const onChangeProducts = useCallback((_: string, productSelected: IProductGroupListData) => {
    form.setFieldValue(`transfers.${index}.lot`, undefined);

    setProductSelected?.(productSelected);

    refetchProducts({
      search: '',
    });
  }, [
    refetchProducts, 
    form
  ]);

  const onSearchProduct = useCallback((search: string | undefined) => {
    refetchProducts({
      search: search,
    })
  }, [refetchProducts]);

  const onChangeLots = useCallback(() => {
    form.setFieldValue(
      `transfers.${index}.unidadeMedidaId`,
      productSelected?.unidadeMedidaId
    );

    form.setFieldValue(
      `transfers.${index}.originFunds`,
      balanceOriginDestiny?.saldoLoteOrigem
    );

    refetchUnitsMeasurements({
      pageSize: 50,
      filter: [
        {
          filterName: 'tipoClasseProduto',
          value: productSelected?.classeProdutoId?.toString(),
        },
      ],
    });

    addRow();
  }, [
    refetchUnitsMeasurements,
    balanceOriginDestiny, 
    productSelected,
    form, 
    addRow
  ]);

  return {
    onChangeProducts,
    onSearchProduct,
    onChangeLots
  };
};