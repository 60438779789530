import { InputWithSearchControlled } from '@/Components/Inputs/InputWithSearchControlled';
import {
  useControllerQueryApiHook,
  useControllerQueryListApiHook,
} from '@/ControllerApiHook/Controller';
import { ClientAPI } from '@/Data/API/People/ClientApi';
import {
  IGetSimplifiedClientResponse,
  IListDropdownClientData,
} from '@/Data/Interfaces/response/Client/IClientResponse';
import {
  IGetSimplifiedPrescriberResponse,
  ISimpleListPrescribersData,
} from '@/Data/Interfaces/response/Prescribers/IPrescribersResponse';
import {
  FETCH_DROPDOWN_LIST_CLIENT,
  GET_SIMPLIFIED_CLIENT,
} from '@/ControllerApiHook/UniqIds/People/ClientKeys';
import {
  FETCH_SIMPLE_LIST_PRESCRIBERS,
  GET_SIMPLIFIED_PRESCRIBER,
} from '@/ControllerApiHook/UniqIds/People/PrescribersKeys';
import DateUtils from '@/Utils/DateUtils';
import { MaskForCNPJ, MaskForCPF } from '@/Utils/MasksUtils';
import { Col } from 'antd';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { PrescribersAPI } from '@/Data/API/People/PrescribersApi';

interface IInputsWithPopoverOfTheClientAndPrescriber {
  form?: any;
}

export const InputsWithPopoverOfTheClientAndPrescriber: FC<
  IInputsWithPopoverOfTheClientAndPrescriber
> = ({ form }) => {
  const { t } = useTranslation();

  const {
    data: clientList,
    isLoading: clientListLoading,
    fetchNewPage: fetchNewProductPage,
    refetch: refetchClientList,
  } = useControllerQueryListApiHook({
    uniqId: FETCH_DROPDOWN_LIST_CLIENT,
    entityApi: ClientAPI.listDropdownClient,
    pagination: {
      pageSize: 50,
      sorter: { column: 'nome', direction: 'DESC' },
    },
  });

  const [selectedClientExternalId, setSelectedClientExternalId] = useState<
    string | undefined
  >();

  const { data: clientGet, isLoading: clientLoading } =
    useControllerQueryApiHook<IGetSimplifiedClientResponse>({
      uniqId: GET_SIMPLIFIED_CLIENT,
      entityApi: ClientAPI.getSimplifiedClient,
      externalIds: [selectedClientExternalId],
    });

  const [selectedClient, setSelectedClient] = useState<
    IListDropdownClientData | undefined
  >(undefined);

  useEffect(() => {
    const clientData = clientList?.data?.find(
      (prod: IListDropdownClientData) =>
        prod.externalId === form?.values?.clienteExternalId
    );
    setSelectedClient(clientData);
  }, [selectedClientExternalId, selectedClient, clientList]);

  const prescriberList = useControllerQueryListApiHook({
    uniqId: FETCH_SIMPLE_LIST_PRESCRIBERS,
    entityApi: PrescribersAPI.listPrescribersForDropdown,
    pagination: {
      sorter: { column: 'nomeCompleto', direction: 'DESC' },
      pageSize: 50,
    },
  });

  const [selectedPrescriberExternalId, setSelectedPrescriberExternalId] =
    useState(form?.values?.prescritorExternalId);

  const [selectedPrescriber, setSelectedPrescriber] = useState<
    ISimpleListPrescribersData | undefined
  >(undefined);

  useEffect(() => {
    const prescriberData = prescriberList?.data?.data?.find(
      (prod: ISimpleListPrescribersData) =>
        prod.externalId === selectedPrescriberExternalId
    );
    setSelectedPrescriber(prescriberData);
  }, [selectedPrescriberExternalId, selectedPrescriber]);

  const { data: prescriberGet, isLoading: prescriberLoading } =
    useControllerQueryApiHook<IGetSimplifiedPrescriberResponse>({
      uniqId: GET_SIMPLIFIED_PRESCRIBER,
      entityApi: PrescribersAPI.getSimplifiedPrescriber,
      externalIds: [selectedPrescriberExternalId],
    });

  function calculateAge(date: string | undefined): number | undefined {
    if (date === undefined) {
      return;
    }

    const dob: Date = new Date(date);

    const currentDate: Date = new Date();

    const timeDiff: number = currentDate.getTime() - dob.getTime();

    const age: number = Math.floor(timeDiff / (1000 * 60 * 60 * 24 * 365.25));

    return age;
  }

  return (
    <>
      <Col span={24}>
        <InputWithSearchControlled
          label={t(
            'saleOrder.editOrder.SaleOrder.addManipulatedRecipe.patient'
          )}
          items={clientList?.data?.map((x: IListDropdownClientData) => ({
            avatar: { name: x.nome },
            id: x.externalId,
            label: x.nome,
            subLabel: `${t('common.code')} ${x.codigo} | ${
              x.cpf
                ? `CPF: ${MaskForCPF(x.cpf)}`
                : `CNPJ: ${MaskForCNPJ(x.cnpj)}`
            }`,
          }))}
          onChange={(client) => {
            setSelectedClientExternalId(client);
          }}
          name="clienteExternalId"
          withoutSearchIcon
          placeholderWithAvatar
          isLoading={clientListLoading}
          onScrollEnd={fetchNewProductPage}
          onSearch={(search) =>
            refetchClientList({
              search: search,
            })
          }
          withoutMarginBottom
          withAvatarPopover={{
            onOpenChange: (x) =>
              x
                ? setSelectedClientExternalId(form?.values?.clienteExternalId)
                : setSelectedClientExternalId(undefined),
            link: `/sales/customers/${form?.values?.clienteExternalId}/details`,
            titleContent: {
              name: selectedClient?.nome,
              code: selectedClient?.codigo,
              typeUser: 'Cliente',
            },
            isLoading: clientLoading,
            content: {
              forClient: {
                firstLine: MaskForCPF(clientGet?.cpf),
                secondLine: {
                  date: DateUtils.convertDate(clientGet?.dataNascimento),
                  age: calculateAge(clientGet?.dataNascimento),
                },
                thirdLine: {
                  icon: clientGet?.tipoContatoIcon,
                  content: clientGet?.contatoPrincipal,
                },
              },
            },
          }}
        />
      </Col>
      <Col span={24}>
        <InputWithSearchControlled
          name="prescritorExternalId"
          label={t('prescribers.listPage.prescriber')}
          placeHolder={t(
            'saleOrder.editOrder.SaleOrder.addProductModal.prescriberPlaceholder'
          )}
          items={prescriberList?.data?.data?.map(
            (x: ISimpleListPrescribersData) => ({
              avatar: { name: x.nomeCompleto },
              label: x.nomeCompleto,
              id: x.externalId,
            })
          )}
          onChange={(prescriber) => {
            setSelectedPrescriberExternalId(prescriber);
          }}
          placeholderWithAvatar
          withoutSearchIcon
          withoutMarginBottom
          withAvatarPopover={{
            link: `/sales/prescribers/${form?.values?.prescritorExternalId}/details`,
            titleContent: {
              name: selectedPrescriber?.nomeCompleto,
              code: selectedPrescriber?.codigoRegistro,
              typeUser: 'Prescritor',
            },
            isLoading: prescriberLoading,
            content: {
              forPrescriber: {
                firstLine: `${prescriberGet?.siglaRegistro}/${prescriberGet?.abreviacaoUfRegistro} ${prescriberGet?.codigoRegistro}`,
                secondLine: {
                  specialties: prescriberGet?.especialidades.map(
                    (especialidade) => especialidade.descricao
                  ),
                },
                thirdLine: {
                  icon: prescriberGet?.contatoPrincipal[0]?.tipoContatoIcon,
                  content:
                    prescriberGet?.contatoPrincipal[0]?.contatoIdentificacao,
                },
              },
            },
          }}
        />
      </Col>
    </>
  );
};
