import { FC } from 'react';
import {
  Dropdown,
  Tooltip
} from '@/Components';
import { Col } from 'antd';
import { useTranslation } from 'react-i18next';
import { useLevelIncompatibleMemorizations } from './Hooks/useLevelIncompatibleMemorizations';
import { useLevelIncompatibleFunctions } from './Hooks/useLevelIncompatibleFunctions';
import { ILevelIncompatible } from './interfaces';

export const LevelIncompatible: FC<ILevelIncompatible> = ({
  incompatibleProductData,
  props,
  disabledForm
}) => {
  const { t } = useTranslation();

  const {
    incompatibilityLevel,
    originalIncompatibleLevel
  } = useLevelIncompatibleMemorizations({
    incompatibleProductData
  });

  const {
    onChange
  } = useLevelIncompatibleFunctions({
    incompatibleProductData,
    props
  });

  return (
    <Col span={5}>
      <Tooltip
        title={`${t(
          'products.incompatible.originalIncompatibleLevel'
        )}${originalIncompatibleLevel?.label}`}
        showMe={
          incompatibleProductData &&
          incompatibleProductData.nivelIncompatibilidade !==
            props?.values?.nivelIncompatibilidade
        }
      >
        <Dropdown
          name="nivelIncompatibilidade"
          label={t('products.incompatible.levelIncompatibility')}
          placeHolder={t(
            'products.incompatible.levelIncompatibilityPlaceholder'
          )}
          required={!disabledForm}
          disabled={disabledForm}
          items={incompatibilityLevel}
          withStatusIcon
          onChange={onChange}
        />
      </Tooltip>
    </Col>
  );
};
