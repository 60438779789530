import { i18n, Modal, Text } from '@/Components';
import { useControllerMutateApiHook } from '@/ControllerApiHook/Controller';
import { SalesOrderAPI } from '@/Data/API/Sales/SalesOrderApi';
import { IGetSaleOrderResponse } from '@/Data/Interfaces/response/Sales/SalesOrder/ISalesOrderResponse';
import {
  APPROVE_SALES_ORDER,
  FETCH_GET_SALES_ORDER
} from '@/ControllerApiHook/UniqIds/Sales/SalesOrderKeys';
import { Divflex } from '@/Layouts/DivWhithFlex';
import { messageErrorToast, messageSucessToast } from '@/Utils/MessageErrorToast';
import { useQueryClient } from '@tanstack/react-query';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import ReactMarkdown from 'react-markdown';

interface IApproveSaleOrderModal {
  visibleModal: boolean;
  changeVisibleModal: (value: boolean) => void;
  saleOrder: IGetSaleOrderResponse;
}

export const ApproveSaleOrderModal: FC<IApproveSaleOrderModal> = ({
  changeVisibleModal,
  visibleModal,
  saleOrder,
}) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  const approveSaleOrder: any = useControllerMutateApiHook({
    uniqId: APPROVE_SALES_ORDER,
    entityApi: SalesOrderAPI.approveSaleOrder,
    options: {
      onError: (x) => messageErrorToast(x),
      onSuccess: () =>
        messageSucessToast(
          i18n.t('saleOrder.editOrder.sideRightMenu.messageSuccessToast.approve')
        )
    }
  });

  const allItemsSelected = saleOrder.pedidoVendaItens.every(
    (item) => item.selecionado === true
  );

  return (
    <Modal
      title={`${t('saleOrder.editOrder.sideRightMenu.approveModal.title')} "#${
        saleOrder.codigoPedido
      }"?`}
      body={
        <Divflex style={{ padding: '20px' }}>
          <Text type="ui-tiny-content">
            <ReactMarkdown>
              {allItemsSelected
                ? t(
                    'saleOrder.editOrder.sideRightMenu.approveModal.descriptionAllSelected',
                    {
                      codigo: `**#${saleOrder.codigoPedido}**`,
                      cliente: `**${saleOrder.cliente.nome}**`,
                    }
                  )
                : t(
                    'saleOrder.editOrder.sideRightMenu.approveModal.descriptionSomeSelected',
                    {
                      codigo: `**#${saleOrder.codigoPedido}**`,
                      cliente: `**${saleOrder.cliente.nome}**`,
                    }
                  )}
            </ReactMarkdown>
          </Text>
        </Divflex>
      }
      visible={visibleModal}
      okButtonName={
        approveSaleOrder.isLoading
          ? t('common.approveLoading')
          : t('common.approve')
      }
      loading={approveSaleOrder.isLoading}
      okButtonLeftIcon={!approveSaleOrder.isLoading ? 'check' : undefined}
      okButtonStatus="success"
      onCancelClick={() => changeVisibleModal(false)}
      onClose={() => changeVisibleModal(false)}
      maxWidth={400}
      htmlType="submit"
      onOkClick={async () => {
        const res = await approveSaleOrder.mutateAsync({
          pedidoVendaExternalId: saleOrder.externalId,
        });
        if (res.success) {
          queryClient.invalidateQueries([FETCH_GET_SALES_ORDER]);
          changeVisibleModal(false);
        }
      }}
    />
  );
};
