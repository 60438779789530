import { FC } from 'react';
import { InputWithSearch, Dropdown } from '@/Components';
import { SelectedAvatar } from '@/Components/SelectedAvatar';
import { Col, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import { CreateClientModal } from '../../CreateClient/CreateClientModal';
import { useBodyStates } from './Hooks/useBodyStates';
import { useBodyCallAPI } from './Hooks/useBodyCallAPI';
import { useBodyMemorizations } from './Hooks/useBodyMemorizations';
import { useBodyFunctions } from './Hooks/useBodyFunctions';

interface IModalBody {
  disabledClientDropdown?: boolean;
}

export const ModalBody: FC<IModalBody> = ({ disabledClientDropdown }) => {
  const { t } = useTranslation();

  const {
    clientName,
    setClientName,
    visibleClientModal,
    setVisibleClientModal,
  } = useBodyStates();

  const {
    clientList,
    fetchNewPageClientList,
    refetchClientList,
    isLoadingClientList,
    myUser,
    serviceChannelList,
  } = useBodyCallAPI();

  const { clientDataList, clientEmptyState, serviceChannelDataList } =
    useBodyMemorizations({
      clientList,
      setVisibleClientModal,
      serviceChannelList: serviceChannelList?.data?.data,
    });

  const { onChangeInputText, onSearch } = useBodyFunctions({
    setClientName,
    refetchClientList,
  });

  return (
    <div style={{ padding: '20px' }}>
      <InputWithSearch
        label={t('callCenter.createService.client')}
        required
        items={clientDataList}
        dataTestId="client-dropdown"
        inputDataTestId="client-input"
        name="clienteExternalId"
        withoutSearchIcon
        placeHolder={t('callCenter.createService.clientPlaceholder')}
        placeholderWithAvatar
        displayFormattedValue={clientName}
        isLoading={isLoadingClientList}
        onScrollEnd={fetchNewPageClientList}
        onSearch={onSearch}
        onChangeInputText={(x) => !visibleClientModal && onChangeInputText(x)}
        emptyState={clientEmptyState}
        disabled={disabledClientDropdown}
        selectedItemFromForm="clientInitialValue"
      />
      <Row gutter={[16, 0]}>
        <Col span={12}>
          <Dropdown
            name="canalAtendimentoId"
            items={serviceChannelDataList}
            isLoading={serviceChannelList.isLoading}
            label={t('callCenter.createService.channel')}
            required
            dataTestId="channel-dropdown"
            id="channel"
          />
        </Col>
        <Col span={12}>
          <SelectedAvatar
            name={myUser?.nome}
            lastName={myUser?.sobrenome}
            label={t('callCenter.createService.attendant')}
            withoutMarginBottom
          />
        </Col>
      </Row>
      <CreateClientModal
        visible={visibleClientModal}
        changeVisible={() => {
          setVisibleClientModal(false);
          refetchClientList({ search: undefined });
        }}
        nameClient={clientName}
        changeSelectedUser={
          (value) => {}
          // form?.setFieldValue('clienteExternalId', value)
        }
      />
    </div>
  );
};
