import { useCallback } from 'react';
import { IStatus, IUseFunctions } from './interfaces';
import { IListUserGroupData } from '@/Data/Interfaces/response/User/IGetUserReponse';

export const useListFunctions = ({
  changeUserGroupStatus,
  deleteUserGroup,
}: IUseFunctions) => {
  const onEdit = useCallback(
    async (userGroup: IListUserGroupData[], status: IStatus) => {
      try {
        await changeUserGroupStatus.mutateAsync({
          gruposId: userGroup?.map((x) => x?.externalId),
          ativa: status?.status === 'success' ? true : false,
        });
      } catch {};
    },
    [changeUserGroupStatus]
  );

  const onDelete = useCallback((userGroups: IListUserGroupData[]) => {
    try {
      deleteUserGroup.mutateAsync(userGroups?.map((x) => x?.externalId));
    } catch {};
  }, [deleteUserGroup]);

  return { onEdit, onDelete };
};
