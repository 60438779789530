export const commonEN = {
  noResults: 'We could not found what you are searching :(',
  waiting: "You didn't searching anything yet",
  email: 'E-mail',
  dateInvalid: 'Invalid date or format.',

  delete: 'Delete',
  deleting: 'Deleting...',
  status: 'Status',

  remove: 'Remove',
  removeLoading: 'Removing...',

  save: 'Save',
  saveDraft: 'Salve Draft',
  saving: 'Saving...',
  cancel: 'Change',
  change: 'Alterar',
  back: 'Back',

  import: 'Import',
  continue: 'Continue',

  close: 'Close',

  moreActions: 'More actions',

  details: 'Details',
  specifications: 'Specifications',
  inventory: 'Inventory',
  financial: 'Financial',
  packingClassification: 'Packing Classification',

  editDetails: 'Edit Details',
  editContacts: 'Edit Contacts',
  editAdresses: 'Edit Adresses',
  editDocuments: 'Edit Documents',

  approve: 'Approve',
  approveLoading: 'Approving...',
  reprove: 'Reprove',
  reproveLoading: 'Reproving...',
  reverse: 'Reverse',
  confirmReverse: 'Yes, reverse.',
  reverseLoading: 'Reversing...',
  confirmCancel: 'Yes, cancel.',
  cancelLoading: 'Canceling...',
  confirmPurchase: 'Confirm purchase',
  confirmLoading: 'Confirming...',
  receiveProducts: 'Receive products',
  linkedProducts: 'Packaging',
  forTheProducts: 'for the products',

  savedChanges: 'Saved changes.',
  hearChanges: 'Some changes were not saved',

  edit: 'Edit',
  create: 'Create',
  dontRegister: "Don't register",
  creating: 'Creating',

  changing: 'Changing',

  add: 'Add',
  adding: 'Adding',
  addProduct: 'Add products',

  exampleAbbreviation: 'E.g',

  observation: 'Note:',

  years: 'years',

  information: 'Information',
  complementDateHour: 'at',
  toastMessages: {
    createdUser: 'Created user!',
    addedUser: 'User added!',
    addedUserErr:
      'An error ocurred while adding an user, try again in some minutes.',
    updatedUser: 'Updated user!',
    updatedPreferenceUser: 'Updated preferences!',
    changePassword: 'Updated password!',
    editUserGroup: 'User Group updated!',
    deletedUserGroup: 'User group deleted!',
    deleteGroupProduct: 'Removed produtc group!',
    deleteSubGroupProduct: 'Removed produtc sub-group!',
  },
  activate: 'Active',
  inactivated: 'Inactive',
  inactivate: 'Inactive',
  inactivating: 'Inactivating',
  qualityControl: 'Quality Control',
  blocked: 'Blocked',
  company: 'company',
  theCompany: 'the company',
  companys: 'companys',
  theCompanys: 'the companys',
  companyGroups: 'company groups',
  theCompanyGroup: 'the company groups',
  theCompanyGroups: 'the company groups',
  userGroups: 'user groups',
  userGroup: 'User Groups',
  theUserGroups: 'the user groups',
  theUsersGroups: 'the user groups',
  group: 'group',
  groups: 'groups',
  theGroup: 'the group',
  theGroups: 'the groups',
  subGroup: 'sub-group',
  subGroups: 'sub-groups',
  theSubGroup: 'the sub-group',
  theSubGroups: 'the sub-groups',
  product: 'product',
  products: 'products',
  user: 'user',
  users: 'users',
  theProduct: 'the product',
  theProducts: 'the products',
  productsUppercase: 'Products',
  productUppercase: 'Produtc',
  ofProduct: 'of product',
  new: 'New',

  lot: 'lot',
  deleteLot: 'Delete lot',
  lots: 'lots',
  theLot: 'the lot',
  theLots: 'the lots',

  supplier: 'supplier',
  suppliers: 'suppliers',
  theSupplier: 'the supplier',
  theSuppliers: 'the suppliers',

  invoice: 'Invoice',
  invoices: 'Invoices',
  invoiceAbbreviation: 'Inv',
  theInvoices: 'the Invoices',

  stockLocation: 'stock location',
  stockLocationsQuestionModalStatus: 'locations',
  deleteStockLocationDetail: 'Delete stock location',
  deleteStockLocationList: 'Are you sure you want to delete the stock location',
  stockLocations: 'stock locations',
  thestockLocation: 'the stock location',
  thestockLocations: 'the stock locations',

  deleteProductMessageList:
    'Are you sure you want to delete the product message:',
  theManyProductMessage: 'Product Message',
  productMessageName: 'Product Message',
  theProductMessage: 'Product Messages',
  productMessage: 'Product Messages',
  linkedProduct: 'Linked Products',
  tabProductMessage: 'Product Messages',

  responsible: 'Responsible',
  changeResponsible: 'Change responsible of ',

  for: 'for',

  dateBreakApart: 'at',

  copy: 'Copy',
  copied: 'Copied!',

  code: 'Code',
  name: 'name',

  item: 'Item',
  items: 'Items',

  total: 'Total',

  empty: 'Empty',

  days: 'days',

  finalDescription: 'This action is permanent and cannot be undone!',

  orderPurchase: 'purchase orders',
  order: 'Order',
  theOrderPurchase: 'the purchase orders',
  deleteOrderPurchaseList: 'Are you sure you want to delete the purchase order',

  originalValue: 'Original value:',
  originalProvider: 'Original provider:',
  originalDate: 'Original date:',

  permissionType: {
    inherited: 'Inherit from:',
    allow: 'Allow',
    deny: 'Deny',
  },

  error: {
    requiredField: 'This field is required',
    emailAlreadyRegistred: 'Email is already being used',
    invalidEmail: 'Invalid e-mail',
    userGroupNameRegistred: 'A group with that name already exists',
  },

  deletePackingClassificationList:
    'Are you sure you want to delete the package classification',
  theManyPackingClassification: 'packaging classifications',
  thePackingClassification: 'packing classifications',
  deletePackingClassificationDetail: 'Delete Packing Classification',

  deletePosologyList: 'Are you sure you want to delete the posology',
  theManyPosology: 'Posologies',
  posologies: 'posologies',
  posology: 'posology',
  thePosology: 'the dosages',
  deletePosologynDetail: 'Delete posology "{{posology}}"?',

  updating: 'Updating',

  updateDataAddress: 'Update address data?',
  updateCurrentAddress: 'Update current address',
  creteNewAddress: 'Create new address',

  newAddress: 'New address',

  favoriteAddressQuestion: 'Set as favorite address?',
  favoriteAddressClientQuestion:
    'Do you want to set the address as a customer favorite?',
  toDefineFavorite: 'Set as favorite',
  toDefineLoading: 'Defining...',
  notDefine: 'Do not define',

  confirmEditAddress:
    'Do you want to save changes made to the current address or do you want to create a new address with the new information?',

  deletePharmaceuticalFormList:
    'Are you sure you want to delete the pharmaceutical form',
  theManyPharmaceuticalForm: 'pharmaceutical forms',
  thePharmaceuticalForm: 'the pharmaceutical forms',
  pharmaceuticalForms: 'pharmaceutical forms',

  packings: 'Packings',

  deleteStandardFormulaList:
    'Are you sure you want to delete the default formula',
  theManyStandardFormula: 'Standard Formula',
  theStandardFormula: 'the standard formulas',
  standardsFormulas: 'standard formulas',
  deleteStandardFormulaDetail: 'Delete the default formula',

  deleteLaboratoryList: 'Are you sure you want to delete the laboratory',
  deleteLaboratoryListCompany: 'of company',
  disableLaboratoryList: 'Are you sure you want to disable the laboratory',
  theManyLaboratory: 'laboratory',
  theLaboratory: 'laboratories',
  laboratory: 'laboratories',
  laboratories: 'Laboratories',

  writeAComment: 'Write a comment...',

  editDelivery: 'Edit delivery',

  showValues: 'Show values',

  deleteLaboratoryModal: {
    laboratory: 'the laboratory',
    laboratories: 'Laboratories',
  },

  backModal: {
    title: 'Are you sure?',
    body: 'Are you sure that you want to leave without save? Your changes will not be saved.',
    okButton: 'Leave without save',
  },

  modalCannotDelete: {
    messageTitle: 'Could not delete',
    canNotDelete: 'Can not delete',
    messageSubTitle: 'Could not delete',
  },

  modalDraftDelete: {
    title: 'Delete Draft',
    subTitle: 'Are you sure you want to delete the draft?',
  },

  technicalInformation: 'Technical Information',

  people: 'People',

  components: 'Components',

  next: 'Next',

  amount: 'Amount',

  pharmaceuticalForm: 'Pharmaceutical Form',

  capsule: 'Capsule',

  packing: 'Packing',

  noImageAvailable: 'No image available',

  amountWithDot: 'Qty.',

  viewCalculation: 'View Calculation',

  revenueValue: 'Revenue value',

  newPrescriber: 'New Prescriber',

  createNewPrescriber: 'Create new prescriber',

  newExpertise: 'New Expertise',

  packagingAlreadyBound: 'Packaging already bound',

  deleteSupplier: 'Delete supplier?',

  invalidCpf: 'Invalid CPF',
  requiredCpf: 'CPF is required',
  invalidCNPJ: 'Invalid CNPJ',
  requiredCNPJ: 'CNPJ is required',
};

export const commonPT = {
  noResults: 'Não conseguimos encontrar o que você está procurando :(',
  waiting: 'Você ainda não fez uma pesquisa.',
  email: 'E-mail',
  dateInvalid: 'Data ou formato inválido.',

  delete: 'Excluir',
  deleting: 'Excluindo...',

  status: 'Status',

  remove: 'Remover',
  removeLoading: 'Removendo...',

  save: 'Salvar',
  saveDraft: 'Salvar Rascunho',
  saving: 'Salvando...',
  cancel: 'Cancelar',

  change: 'Alterar',
  changing: 'Alterando',

  back: 'Voltar',

  import: 'Importar',
  continue: 'Continuar',

  close: 'Fechar',

  moreActions: 'Mais ações',

  details: 'Detalhes',
  packingClassification: 'Classificação de Embalagem',

  editDetails: 'Editar Detalhes',
  editContacts: 'Editar Contatos',
  editAdresses: 'Editar Endereços',
  editDocuments: 'Editar Documentos',

  approve: 'Aprovar',
  approveLoading: 'Aprovando...',
  reprove: 'Reprovar',
  reproveLoading: 'Reprovando...',
  reverse: 'Estornar',
  confirmReverse: 'Sim, estornar.',
  reverseLoading: 'Estornando...',
  confirmCancel: 'Sim, cancelar.',
  cancelLoading: 'Cancelando...',
  confirmPurchase: 'Confirmar compra',
  confirmLoading: 'Confirmando...',
  receiveProducts: 'Receber produtos',
  linkedProducts: 'Embalagens',
  forTheProducts: 'para os produtos',

  savedChanges: 'Alterações salvas.',
  hearChanges: 'Algumas alterações não foram salvas',

  edit: 'Editar',
  create: 'Cadastrar',
  dontRegister: 'Não Cadastrar',
  creating: 'Cadastrando',

  inventory: 'Estoque',
  financial: 'Financeiro',
  specifications: 'Especificações',

  add: 'Adicionar',
  adding: 'Adicionando',
  addProduct: 'Adicionar Produto',

  exampleAbbreviation: 'Ex',

  observation: 'Obs:',

  years: 'anos',

  information: 'Informações',
  complementDateHour: 'às',
  toastMessages: {
    createdUser: 'Usuário criado!',
    addedUser: 'Usuário adicionado!',
    addedUserErr:
      'Ocorreu um erro ao adicionar o usuário, tente novamente em alguns minutos.',
    updatedUser: 'Usuário atualizado!',
    updatedPreferenceUser: 'Preferências atualizadas!',
    changePassword: 'Senha atualizada!',
    editUserGroup: 'Grupo de usuários atualizado!',
    deletedUserGroup: 'Grupo de usuário deletado!',
    deleteGroupProduct: 'Grupo de produto deletado!',
    deleteSubGroupProduct: 'Subgrupo de produto deletado!',
  },
  activate: 'Ativo',
  inactivated: 'Inativo',
  inactivate: 'Inativar',
  inactivating: 'Inativando',
  qualityControl: 'Controle de qualidade',
  blocked: 'Bloqueado',
  company: 'empresa',
  theCompany: 'a empresa',
  companys: 'empresas',
  theCompanys: 'as empresas',
  companyGroups: 'grupos de empresas',
  theCompanyGroup: 'o grupo de empresas',
  theCompanyGroups: 'os grupos de empresas',
  userGroups: 'grupos de usuários',
  userGroup: 'Grupo de Usuários',
  theUserGroups: 'o grupo de usuários',
  theUsersGroups: 'os grupos de usuários',
  group: 'grupo',
  groups: 'grupos',
  theGroup: 'o grupo',
  theGroups: 'os grupos',
  subGroup: 'subgrupo',
  subGroups: 'subgrupos',
  theSubGroup: 'o subgrupo',
  theSubGroups: 'os subgrupos',
  product: 'produto',
  products: 'produtos',
  productsUppercase: 'Produtos',
  productUppercase: 'Produto',
  user: 'usuário',
  users: 'usuários',
  theProduct: 'o produto',
  theProducts: 'os produtos',
  ofProduct: 'do produto',
  new: 'Novo',

  lot: 'lote',
  deleteLot: 'Excluir lote',
  lots: 'lotes',
  theLot: 'o lote',
  theLots: 'os lotes',

  supplier: 'fornecedor',
  suppliers: 'fornecedores',
  theSupplier: 'o fornecedor',
  theSuppliers: 'os fornecedores',

  invoice: 'Nota Fiscal',
  invoices: 'Notas Fiscais',
  invoiceAbbreviation: 'NF',
  theInvoices: 'as Notas Fiscais',

  stockLocation: 'local de estoque',
  stockLocations: 'locais de estoque',
  stockLocationsQuestionModalStatus: 'locais',
  thestockLocation: 'o local de estoque',
  thestockLocations: 'os locais de estoque',
  deleteStockLocationList:
    'Você tem certeza que deseja excluir o local de estoque',
  deleteStockLocationDetail: 'Excluir o local de estoque',

  deleteProductMessageList:
    'Você tem certeza que deseja excluir a mensagem do produto:',
  theManyProductMessage: 'Mensagem do Produto',
  productMessageName: 'Mensagem do Produto',
  theProductMessage: 'as mensagens do produto',
  productMessage: 'mensagens do produto',
  linkedProduct: 'Produtos Vinculados',
  tabProductMessage: 'Mensagem do Produto',

  responsible: 'Responsável',
  changeResponsible: 'Alterar responsável de ',

  for: 'para',

  dateBreakApart: 'às',

  copy: 'Copiar',
  copied: 'Copiado!',

  code: 'Código',
  name: 'nome',

  item: 'Item',
  items: 'Itens',

  total: 'Total',

  empty: 'Vazio',

  days: 'dias',

  finalDescription: 'Essa ação é permanente e não pode ser desfeita!',

  orderPurchase: 'pedidos de compra',
  order: 'Pedido',
  theOrderPurchase: 'os pedidos de compra',
  deleteOrderPurchaseList:
    'Você tem certeza que deseja excluir o pedido de compra',

  originalValue: 'Valor original:',
  originalProvider: 'Fornecedor original:',
  originalDate: 'Data original:',

  permissionType: {
    inherited: 'Herdado de:',
    allow: 'Permitir',
    deny: 'Negar',
  },
  error: {
    requiredField: 'Este campo é obrigatório',
    emailAlreadyRegistred: 'E-mail já está sendo utilizado',
    invalidEmail: 'E-mail inválido!',
    userGroupNameRegistred: 'Já existe um grupo com esse nome',
  },

  deletePackingClassificationList:
    'Você tem certeza que deseja excluir a classificação de embalagem',
  theManyPackingClassification: 'Classificações de Embalagem',
  thePackingClassification: 'classificações de embalagem',
  deletePackingClassificationDetail: 'Excluir a Classificação de Embalagem',

  deletePosologyList: 'Você tem certeza que deseja excluir a posologia',
  theManyPosology: 'Posologias',
  thePosology: 'as posologias',
  posology: 'a posologia',
  posologies: 'posologias',
  deletePosologynDetail: 'Excluir a Posologia "{{posology}}"?',

  updating: 'Atualizando',

  updateDataAddress: 'Atualizar dados do endereço?',
  updateCurrentAddress: 'Atualizar endereço atual',
  creteNewAddress: 'Criar novo endereço',

  newAddress: 'Novo endereço',

  favoriteAddressQuestion: 'Definir como endereço favorito?',
  favoriteAddressClientQuestion:
    'Você deseja definir o endereço como favorito do cliente?',
  toDefineFavorite: 'Definir como favorito',
  toDefineLoading: 'Definindo...',
  notDefine: 'Não definir',

  confirmEditAddress:
    'Você deseja salvar as alterações feitas no endereço atual ou deseja criar um novo endereço com as novas informações?',

  deletePharmaceuticalFormList:
    'Você tem certeza que deseja excluir a forma farmacêutica',
  theManyPharmaceuticalForm: 'Formas Farmacêuticas',
  thePharmaceuticalForm: 'as formas farmacêuticas',
  pharmaceuticalForms: 'formas farmacêuticas',

  packings: 'Embalagens',

  deleteStandardFormulaList:
    'Você tem certeza que deseja excluir a fórmula padrão',
  theManyStandardFormula: 'Fórmulas Padrão',
  theStandardFormula: 'as fórmulas padrão',
  standardsFormulas: 'fórmulas padrão',
  deleteStandardFormulaDetail: 'Excluir a fórmula padrão',

  deleteLaboratoryList: 'Você tem certeza que deseja excluir o laboratório',
  deleteLaboratoryListCompany: 'da empresa',
  disableLaboratoryList: 'Você tem certeza que deseja desativar o laboratório',
  theManyLaboratory: 'laboratório',
  theLaboratory: 'os laboratórios',
  laboratory: 'laboratórios',
  laboratories: 'Laboratórios',

  writeAComment: 'Escreva uma observação...',

  editDelivery: 'Editar entrega',

  showValues: 'Mostrar valores',

  deleteLaboratoryModal: {
    laboratory: 'o laboratório',
    laboratories: 'Laboratórios',
  },

  backModal: {
    title: 'Deseja sair sem salvar?',
    body: 'Você tem certeza que deseja sair da página sem salvar? As alterações que você fez talvez não sejam salvas.',
    okButton: 'Sair sem salvar',
  },

  modalCannotDelete: {
    messageTitle: 'Não foi possível excluir',
    canNotDelete: 'Não é possível excluir',
    messageSubTitle: 'Não foi possível excluir',
  },

  modalDraftDelete: {
    title: 'Excluir Rascunho',
    subTitle: 'Você tem certeza que deseja excluir o rascunho?',
  },

  technicalInformation: 'Informações Técnicas',

  people: 'Pessoa',

  components: 'Componentes',

  next: 'Próximo',

  amount: 'Quantidade',

  pharmaceuticalForm: 'Forma Farmacêutica',

  capsule: 'Cápsula',

  packing: 'Embalagem',

  noImageAvailable: 'Sem imagem disponível',

  amountWithDot: 'Quant.',

  viewCalculation: 'Visualizar Cálculo',

  revenueValue: 'Valor da Receita',

  newPrescriber: 'Novo Prescritor',

  createNewPrescriber: 'Cadastrar novo prescritor',

  newExpertise: 'Nova Especialidade',

  packagingAlreadyBound: 'Embalagem já vinculada',

  deleteSupplier: 'Excluir fornecedor?',

  invalidCpf: 'CPF inválido',
  requiredCpf: 'CPF é obrigatório',
  invalidCNPJ: 'CNPJ inválido',
  requiredCNPJ: 'CPNJ é obrigatório',
};
