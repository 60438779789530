import { useMemo } from 'react';
import { Avatar } from '@/Components/Avatar';
import { IUseMemorizations } from './interfaces';
import { PermissionEnum } from '@/Data/Interfaces/model/permissionEnum';
import StorageUtils from '@/Utils/StorageUtils';

export const useInfoCardMemorizations = ({ prescriber }: IUseMemorizations) => {
  const titleCard = useMemo(
    () => (
      <Avatar
        fisrtName={prescriber?.nomeCompleto}
        size="M"
        type="ui-tiny-bold"
        description={{
          description: prescriber?.nomeCompleto,
          type: 'heading4',
          color: 'text-50',
        }}
        color="white"
      />
    ),
    [prescriber]
  );

  const buttonCardPermission = useMemo(
    () => ({
      permission: PermissionEnum.Vendas_Prescritores_EditarDetalhes,
      tooltip: 'noPermission.sale.prescriber.edit',
    }),
    []
  );

  const preferences = useMemo(() => StorageUtils.getPreference(), []);

  return {
    preferences,
    titleCard,
    buttonCardPermission,
  };
};
