import { i18n } from '@/Components';
import * as yup from 'yup';

export const CreateOrderEditSchema = yup.object().shape({
  clienteExternalId: yup
    .string()
    .required(
      i18n.t(
        'callCenter.createService.createClientModal.error.client'
      )
    ),
  canalAtendimentoId: yup
    .string()
    .required(
      i18n.t(
        'callCenter.createService.createClientModal.error.channel'
      )
    ),
});
