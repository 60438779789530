import {
  DELETE_SUBGROUP,
  FETCH_LIST_SUBGROUP,
} from '@/ControllerApiHook/UniqIds/Supply/ProductKeys';
import {
  useControllerMutateApiHook,
  useControllerQueryListApiHook,
} from '@/ControllerApiHook/Controller';
import { ProductAPI } from '@/Data/API/Supply/Product';

export const useListCallAPI = () => {
  const deleteSubGroup: any = useControllerMutateApiHook({
    uniqId: DELETE_SUBGROUP,
    entityApi: ProductAPI.deleteProductSubGroup,
  });

  const {
    data: subGroupList,
    isLoading,
    refetch: refetchSubGroupList,
  }: any = useControllerQueryListApiHook({
    uniqId: FETCH_LIST_SUBGROUP,
    entityApi: ProductAPI.subGroupList,
    pagination: {
      sorter: { column: 'descricao', direction: 'ASC' },
    },
    autoIncrement: true,
  });

  return {
    deleteSubGroup,
    subGroupList,
    refetchSubGroupList,
    isLoading,
  };
};
