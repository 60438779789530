import { i18n } from '@/Components';
import {
  CREATE_STANDARD_FORMULA,
} from '@/ControllerApiHook/UniqIds/Production/StandardFormulaKeys';
import { useControllerMutateApiHook } from '@/ControllerApiHook/Controller';
import { StandardFormulaAPI } from '@/Data/API/Production/StandardFormula';
import { 
  messageErrorToast, 
  messageSucessToast 
} from '@/Utils/MessageErrorToast';

export const useCreateCallAPI = () => {
  const createStandardFormula: any = useControllerMutateApiHook({
    uniqId: CREATE_STANDARD_FORMULA,
    entityApi: StandardFormulaAPI.postStandardFormula,
    options: {
      onError: (x) => messageErrorToast(x),
      onSuccess: () =>
        messageSucessToast(
          i18n.t(
            'production.standardFormula.create.successMessageToasts.create'
          )
        )
    }
  });

  return {
    createStandardFormula
  };
};