import { ICreateCapsuleColors } from '@/Data/Interfaces/request/CapsuleColors/ICapsuleColorsRequest';
import { IUseFunctions } from './interfaces';
import { FormikHelpers, FormikValues } from 'formik';

export const useCreateFunctions = ({ createCapsuleColors }: IUseFunctions) => {
  const onSubmit = async (
    values: ICreateCapsuleColors,
    helpers: FormikHelpers<FormikValues>
  ) => {
    var body: ICreateCapsuleColors = { ...values, helpers };
    const capsuleColorName: string = body.corCapsula.trim();
    return await createCapsuleColors({
      corCapsula: capsuleColorName,
      transparente: body.transparente,
      helpers,
    });
  };
  return {
    onSubmit,
  };
};
