import { useCallback } from 'react';
import { IUseFunctions } from './interfaces';
import { ICreateServiceRequest } from '@/Data/Interfaces/request/Sales/Service/IServiceRequest';

export const useListFunctions = ({
  createService,
  setVisibleCreateModal,
}: IUseFunctions) => {
  const onSubmit = useCallback(
    async (values: ICreateServiceRequest) => {
      await createService.mutateAsync(values);
    },
    [createService]
  );

  const changeVisible = useCallback(
    (x: boolean) => setVisibleCreateModal(x),
    [setVisibleCreateModal]
  );

  return {
    onSubmit,
    changeVisible,
  };
};
