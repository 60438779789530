import { FC } from "react";
import { Tooltip } from '@/Components';
import { PercentageInput } from '@/Components/Inputs/PercentageInput';
import { useTranslation } from 'react-i18next';
import { useFormikContext } from 'formik';
import { IDilution } from "./interfaces";
import { useDilutionFunctions } from "./Hooks/useDilutionFunctions";

export const Dilution: FC<IDilution> = ({
  product,
  disabledForm
}) => {
  const { t } = useTranslation();
  const form: any = useFormikContext();

  const {
    onChange
  } = useDilutionFunctions();

  return (
    <Tooltip
      title={`${t('products.create.originalSupplierDilutionTooltip')} ${
        product && product.materiaPrima
          ? product.materiaPrima?.diluicaoFornecedor + '%'
          : ''
      }`}
      showMe={
        product &&
        !disabledForm &&
        product.materiaPrima?.diluicaoFornecedor !==
          form?.values?.informacaoTecnica?.diluicaoFornecedor
      }
    >
      <PercentageInput
        label={t('products.create.supplierDilution')}
        name="informacaoTecnica.diluicaoFornecedor"
        placeHolder="100%"
        maxLength={6}
        withTooltip={{
          icon: 'question-mark',
          title: t('products.create.supplierDilutionTooltip'),
        }}
        disabled={disabledForm}
        withStatusIcon={!!product}
        onChange={onChange}
      />
    </Tooltip>
  );
};