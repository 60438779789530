import React from 'react';

const BreakLineProductMessageModal: React.FC = () => {
  return (
    <div
      style={{
        borderTop: '1px solid var(--color-background-04)',
        margin: '0px 20px 10px 20px',
      }}
    />
  );
};

export default BreakLineProductMessageModal;
