import { useState } from 'react';

export const useCreateClientStates = () => {
  const [formValues, setFormValues] = useState<any>();

  return {
    formValues,
    setFormValues,
  };
};
