import { FC } from 'react';
import {
  TextInput
} from '@/Components';
import { Col } from 'antd';
import { useTranslation } from 'react-i18next';
import { IProduct } from './interfaces';
import { useProductCallAPI } from './Hooks/useProductCallAPI';

export const Product: FC<IProduct> = ({
  productExternalId
}) => {
  const { t } = useTranslation();

  const {
    currentProduct
  } = useProductCallAPI({ productExternalId });

  return (
    <Col span={7}>
      <TextInput
        name="produtoExternalId"
        value={currentProduct ? currentProduct.descricao : ''}
        label={t('products.incompatible.product')}
        disabled
      />
    </Col>
  );
};