import { FC } from 'react';
import { PageLoading } from '../../../../../Layouts/PageLoading';
import { InfoBalanceAdjustmentCard } from './Components/Cards/InfoBalanceAdjustmentCard';
import { BalanceAdjustmentCard } from './Components/Cards/BalanceAdjustmentCard';

export const BalanceAdjustmentDetails: FC = () => {
  return (
    <PageLoading>
      <InfoBalanceAdjustmentCard />
      <BalanceAdjustmentCard />
    </PageLoading>
  );
};

export default BalanceAdjustmentDetails;
