import { useMemo } from 'react';
import { Text } from '@/Components';
import { FooterThisActionIsPermanent } from '@/Components/Table/components/Modals/DeleteModal';
import ReactMarkdown from 'react-markdown';
import { useTranslation } from 'react-i18next';
import { IUseMemorizations } from './interfaces';

export const useModalMemorizations = ({ clientName }: IUseMemorizations) => {
  const { t } = useTranslation();
  const deleteModalBody = useMemo(
    () => (
      <div style={{ padding: '20px' }}>
        <Text
          type="ui-tiny-content"
          withoutMarginBottom
          style={{ marginBottom: 20 }}
        >
          <ReactMarkdown>
            {t('client.listPage.deleteModal.description', {
              client: `**${clientName}**`,
            })}
          </ReactMarkdown>
        </Text>
        <FooterThisActionIsPermanent />
      </div>
    ),
    [t, clientName]
  );

  return {
    deleteModalBody,
  };
};
