export const FETCH_GET_PRODUCT_MESSAGE =
  '[PRODUCT_MESSAGE] FETCH_GET_PRODUCT_MESSAGE';

export const FETCH_LIST_PRODUCT_MESSAGE =
'[PRODUCT_MESSAGE] FETCH_LIST_PRODUCT_MESSAGE';
export const FETCH_LIST_PRODUCT_LINKED_BY_MESSAGE =
  '[PRODUCT_MESSAGE] FETCH_LIST_PRODUCT_LINKED_BY_MESSAGE';
export const FETCH_LIST_MESSAGE_PRODUCT_DROPDOWN_TYPE =
  '[PRODUCT_MESSAGE] FETCH_LIST_MESSAGE_PRODUCT_DROPDOWN_TYPE';

export const CREATE_PRODUCT_MESSAGE =
  '[PRODUCT_MESSAGE] CREATE_PRODUCT_MESSAGE';
export const EDIT_PRODUCT_MESSAGE = '[PRODUCT_MESSAGE] EDIT_PRODUCT_MESSAGE';
export const DELETE_PRODUCT_MESSAGE =
  '[PRODUCT_MESSAGE] DELETE_PRODUCT_MESSAGE';
export const DELETE_PRODUCT_LINKED_MESSAGE =
'[PRODUCT_MESSAGE] DELETE_PRODUCT_LINKED_MESSAGE';

export const THERE_IS_MESSAGE_PRODUCT_TYPE =
  '[PRODUCT_MESSAGE] THERE_IS_MESSAGE_PRODUCT_TYPE';
