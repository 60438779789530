import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { AddProductModalBody } from './Body/AddProductModalBody';
import StorageUtils from '@/Utils/StorageUtils';
import { AddProductModalSchema } from './AddProductModal.schema';
import { useAddProductModalCallAPI } from './Hooks/useAddProductModalCallAPI';
import { useAddProductModalStates } from './Hooks/useAddProductModalStates';
import { useAddProductModalFunctions } from './Hooks/useAddProductModalFunctions';
import { Modal } from '@/Components';

interface IAddProductModal {
  visibleModal: boolean;
  changeVisibleModal: (value: boolean) => void;
  initialValues?: any;
  editModal?: boolean;
}

export const AddProductModal: FC<IAddProductModal> = ({
  changeVisibleModal,
  visibleModal,
  initialValues,
  editModal,
}) => {
  const {
    insufficientFundsModalVisible,
    setInsufficientFundsModalVisible,
    productSelected,
    setProductSelected,
  } = useAddProductModalStates();

  const { t } = useTranslation();

  const preferences = StorageUtils.getPreference();

  const { addProduct, editProduct, productList } = useAddProductModalCallAPI({
    changeVisibleModal,
    setProductSelected,
  });

  const { onClickForm } = useAddProductModalFunctions({
    addProduct,
    editProduct,
    setInsufficientFundsModalVisible,
    editModal,
    productSelected,
  });

  return (
    <Modal
      title={t(
        `saleOrder.editOrder.SaleOrder.addProductModal.${
          editModal ? 'editTitle' : 'title'
        }`
      )}
      schema={AddProductModalSchema}
      body={
        <AddProductModalBody
          productList={productList}
          addProduct={addProduct}
          editProduct={editProduct}
          datePattern={preferences}
          insufficientFundsModalVisible={insufficientFundsModalVisible}
          setInsufficientFundsModalVisible={(x) =>
            setInsufficientFundsModalVisible(x)
          }
          productSelected={productSelected}
          setProductSelected={(value) => setProductSelected(value)}
          editModal={!!editModal}
        />
      }
      loading={addProduct.isLoading}
      visible={visibleModal}
      okButtonName={t(
        `saleOrder.editOrder.SaleOrder.addProductModal.${
          editModal ? 'save' : 'add'
        }`
      )}
      dataTestIdOkButton="add-product"
      onCancelClick={() => {
        changeVisibleModal(false);
        setProductSelected(undefined);
      }}
      onClose={() => {
        changeVisibleModal(false);
        setProductSelected(undefined);
      }}
      widthModal={600}
      withForm
      htmlType="submit"
      initialValues={{ tipoDesconto: 1, ...initialValues }}
      onOkClickForm={onClickForm}
    />
  );
};
