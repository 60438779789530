import { FormikHelpers, FormikValues } from 'formik';
import { openNotification } from '../Components/Feedback/Toast';

export const messageErrorToast = (
  error?: any,
  helpers?: FormikHelpers<FormikValues>
) => {
  if (error) {
    try {
      const data = error.errors;
      const keys = Object.keys(data);

      if (keys && keys.length > 0) {
        const msgs = keys.map((x) => !!data[x] && data[x].join(', '));
        openNotification('error', 'Ops...', msgs.join('\n'), false);
      } else
        data.fieldErrors.map((x: any) =>
          helpers?.setFieldError(x.fieldName, x.message)
        );
    } catch {
      if (error.detail) {
        openNotification('error', 'Ops...', error.detail, false);
      } else {
        openNotification('error', 'Ops...', 'Algo deu errado', false);
      }
    }
  } else {
    openNotification('error', 'Ops...', 'Algo deu errado', false);
  }
};

export const messageSucessToast = (sucess: any) => {
  openNotification('sucess', '', sucess, false);
};
