import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { TextInputNumber } from '@/Components/Inputs/TextInputNumber';

export const Validity: FC = () => {
  const { t } = useTranslation();

  return (
    <>
      <TextInputNumber
        name="diasValidade"
        placeHolder={t(
          'production.standardFormula.create.validityPlaceholder'
        )}
        label={t('production.standardFormula.create.validity')}
        withTooltip={{
          icon: 'exclamation-upside-down',
          title: t(
            'production.standardFormula.create.toolTips.validyMessage'
          ),
        }}
      />
    </>
  );
};