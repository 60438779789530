import { IListUserGroupForDropdownData } from '@/Data/Interfaces/response/User/IGetUserReponse';
import { useCallback } from 'react';
import { IUseFunctions } from './interfaces';

export const useUserFormFunctions = ({
  externalId,
  removeUserInUserGroup,
  setSelectedRowsOnTable,
}: IUseFunctions) => {
  const deleteUsers = async (users: IListUserGroupForDropdownData[]) => {
    await removeUserInUserGroup.mutateAsync({
      grupoExternalId: externalId,
      usuarios: users.map((x) => x.externalId),
    });
  };

  const selectRowsOnTable = useCallback(
    (x: any) => setSelectedRowsOnTable(x),
    [setSelectedRowsOnTable]
  );

  return {
    deleteUsers,
    selectRowsOnTable,
  };
};
