import { IUseFunctions } from './interfaces';
export const useCreateFunctions = ({
  setPackingClassificationRowAmount,
}: IUseFunctions) => {
  const removeRow = (index: number) => {
    setPackingClassificationRowAmount((oldState) => {
      return oldState.filter((_, i) => i !== index);
    });
  };

  return {
    removeRow,
  };
};
