import { FC, ReactNode, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IModal, Modal } from '../../../../Modal';
import { Dropdown } from '../../../../StateInputs/Dropdown';
import { editOptions } from '../../..';
import { statusTypes } from '../../../../../Utils/StatusUtils';

import styles from './EditModal.module.scss';

export interface IEditModalTranslations {
  title?: ITitleTranslations;
  customEndTitle?: string;
}

export interface ITitleTranslations {
  singleItemBefore?: string;

  manyItemsBefore?: string;
  manyItemsAfter?: string;
}

export interface IConfirmEdit
  extends Omit<
    IModal,
    'visible' | 'onOkClick' | 'onClose' | 'onCancelClick' | 'loading'
  > {}

export interface IEditModal {
  visible: boolean;
  itemNames: string[];

  items?: editOptions;

  body?: ReactNode;
  title?: string;

  translations?: IEditModalTranslations;
  confirmationModal?: IConfirmEdit;

  disabledButtonFormNames?: string[];

  onOkClick: (
    item: { id: any; name: string; status?: statusTypes } | any
  ) => void;
  onCancelClick: () => void;

  statusSelectedOnRow?: boolean;

  okButtonName?: string;

  withForm?: boolean;

  loading?: boolean;

  widthModal?: number;

  dataTestIdOkButton?: string;
}

export const EditStatusModal: FC<IEditModal> = ({
  visible,
  itemNames,
  translations,
  items,
  disabledButtonFormNames,
  confirmationModal,
  onOkClick,
  onCancelClick,
  body,
  title,
  statusSelectedOnRow,
  loading,
  okButtonName,
  withForm,
  widthModal,
  dataTestIdOkButton,
}) => {
  const [showConfirmation, setShowConfirmation] = useState(false);
  const { t } = useTranslation();
  const [selected, setSelected] = useState<any>();
  const isSingleItem = itemNames.length === 1;

  const onOk = useCallback(
    (value?: string) => {
      onOkClick(value || selected);
    },
    [onOkClick, selected]
  );

  const onCancel = useCallback(() => {
    onCancelClick();
  }, [onCancelClick]);

  const onConfirmModalCancel = useCallback(
    () => setShowConfirmation(false),
    []
  );

  useEffect(() => {
    !loading && setShowConfirmation(false);
  }, [loading, setShowConfirmation]);

  useEffect(() => {
    setSelected({ id: statusSelectedOnRow });
  }, [statusSelectedOnRow]);

  return (
    <>
      <Modal
        withForm={withForm}
        visible={(visible || loading) && !showConfirmation}
        loading={loading}
        disabledButtonFormNames={disabledButtonFormNames}
        disabledButton={
          !disabledButtonFormNames &&
          ((!body && selected?.id === statusSelectedOnRow) ||
            selected?.id === undefined)
        }
        okButtonName={okButtonName ? okButtonName : 'modal.save'}
        onClose={onCancel}
        onOkClick={() => {
          if (confirmationModal && selected?.status === 'disabled') {
            setShowConfirmation(true);
          } else {
            onOk();
          }
        }}
        onOkClickForm={(value) => {
          confirmationModal ? setShowConfirmation(true) : onOk(value);
        }}
        htmlType={withForm ? 'submit' : 'button'}
        onCancelClick={onCancel}
        widthModal={widthModal || 400}
        dataTestIdOkButton={dataTestIdOkButton}
        title={
          title
            ? title
            : isSingleItem
            ? `${
                translations?.title?.singleItemBefore
                  ? t(translations?.title?.singleItemBefore)
                  : t('modal.editModal.single.title.before')
              } ”${itemNames[0]}”`
            : `${
                translations?.title?.manyItemsBefore
                  ? t(translations?.title?.manyItemsBefore)
                  : t('modal.editModal.many.title.before')
              } ${itemNames.length} ${
                translations?.customEndTitle
                  ? translations.customEndTitle
                  : translations?.title?.manyItemsAfter
                  ? t(translations?.title?.manyItemsAfter)
                  : t('modal.editModal.many.title.after')
              }`
        }
        body={
          body ? (
            body
          ) : (
            <div className={styles['EditBody']}>
              <Dropdown
                value={selected?.id}
                label="Status"
                items={items?.map((x) => ({
                  id: x.id,
                  name: x.name,
                  status: x.status,
                }))}
                placeholder={t('modal.editModal.statusPlaceHolder')}
                onChange={(value) =>
                  setSelected(items?.find((x) => x.id === value))
                }
                allowClear={false}
                dataTestId="change-status-input"
              />
            </div>
          )
        }
      />
      {confirmationModal && (
        <Modal
          visible={showConfirmation}
          onOkClick={onOk}
          onClose={onConfirmModalCancel}
          onCancelClick={onConfirmModalCancel}
          loading={loading}
          {...confirmationModal}
        />
      )}
    </>
  );
};
