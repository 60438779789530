export const FETCH_GET_INVOICE = '[INVOICE] FETCH_GET_INVOICE';

export const FETCH_LIST_INVOICE = '[INVOICE] FETCH_LIST_INVOICE';
export const FETCH_LIST_DROPDOWN_NCM = '[INVOICE] FETCH_LIST_DROPDOWN_NCM';
export const FETCH_LIST_DROPDOWN_CFOP = '[INVOICE] FETCH_LIST_DROPDOWN_CFOP';
export const FETCH_LIST_DROPDOWN_CST = '[INVOICE] FETCH_LIST_DROPDOWN_CST';
export const FETCH_LIST_LOTS = '[INVOICE] FETCH_LIST_LOTS';

export const CREATE_INVOICE = '[INVOICE] CREATE_INVOICE';
export const DELETE_INVOICE = '[INVOICE] DELETE_INVOICE';
export const CREATE_INVOICE_LOTS = '[INVOICE] CREATE_INVOICE_LOTS';
export const DELETE_INVOICE_LOT_DRAFT = '[INVOICE] DELETE_INVOICE_LOT_DRAFT';

export const SAVE_PUT_DRAFT_INVOICE = '[INVOICE] SAVE_PUT_DRAFT_INVOICE';
export const SAVE_PUT_INVOICE = '[INVOICE] SAVE_PUT_INVOICE';