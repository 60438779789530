import { useState } from "react";

export const useEditStates = () => {
  const [modalDeleteSubGroup, setModalDeleteSubGroup] = useState(false);
  
  const [disabled, setDisabled] = useState(true);

  return {
    modalDeleteSubGroup,
    setModalDeleteSubGroup,
    disabled,
    setDisabled
  };
};