import { useState } from "react";

export const useProductMessageStates = () => {
  const [selectedRowsOnTable, setSelectedRowsOnTable] = useState<any>([]);

  const [
    visibleLinkedProductMessageModal,
    setVisibleLinkedProductMessageModal,
  ] = useState<boolean>(false);

  return {
    selectedRowsOnTable,
    setSelectedRowsOnTable,
    visibleLinkedProductMessageModal,
    setVisibleLinkedProductMessageModal
  };
};