import { Modal } from '@/Components/Modal';
import { InputLabel } from '@/Components/Inputs/LabelTotal';
import { CardTotal } from '@/Pages/Sectors/Order/Purchase/Create/Components/TotalCard/Components/CardTotal';
import { Col, Row } from 'antd';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './recipeCalculationModal.module.scss';

interface IRecipeCalculationModal {
  visibleModal: boolean;
  changeVisibleModal: (value: boolean) => void;
  initialValues?: any;
}

export const RecipeCalculationModal: FC<IRecipeCalculationModal> = ({
  changeVisibleModal,
  visibleModal,
  initialValues,
}) => {
  const { t } = useTranslation();

  return (
    <>
      <Modal
        title={t('saleOrder.editOrder.SaleOrder.recipeCalculationModal.title')}
        body={
          <div style={{ padding: 20 }}>
            <CardTotal
              style={{ backgroundColor: 'var(--color-background-02)' }}
            >
              <Row gutter={[10, 0]} style={{ justifyContent: 'space-between' }}>
                <Col span={4}>
                  <InputLabel
                    labelTitle={t(
                      'saleOrder.editOrder.SaleOrder.recipeCalculationModal.firstCard.pharmaceuticalForm'
                    )}
                    className={styles['recipe-labels']}
                    labelValue={'Creme'}
                    customSize={14}
                    type="ui-tiny-content"
                    typeLabel="ui-tiny-semibold"
                  />
                </Col>
                <Col span={5}>
                  <InputLabel
                    labelTitle={t(
                      'saleOrder.editOrder.SaleOrder.recipeCalculationModal.firstCard.prescribedQuantity'
                    )}
                    className={styles['recipe-labels']}
                    labelValue={'120 g'}
                    type="ui-tiny-content"
                    typeLabel="ui-tiny-semibold"
                    customSize={14}
                  />
                </Col>
                <Col span={4}>
                  <InputLabel
                    labelTitle={t(
                      'saleOrder.editOrder.SaleOrder.recipeCalculationModal.firstCard.calculatedVolume'
                    )}
                    className={styles['recipe-labels']}
                    labelValue={'120 mL'}
                    type="ui-tiny-content"
                    typeLabel="ui-tiny-semibold"
                    customSize={14}
                    style={{
                      textDecorationLine: 'underline',
                      textDecorationStyle: 'dotted',
                      textDecorationColor: 'var(--color-text-300)',
                    }}
                  />
                </Col>
                <Col span={3}>
                  <InputLabel
                    labelTitle={t(
                      'saleOrder.editOrder.SaleOrder.recipeCalculationModal.firstCard.packaging'
                    )}
                    className={styles['recipe-labels']}
                    labelValue={'1 un.'}
                    type="ui-tiny-content"
                    typeLabel="ui-tiny-semibold"
                    customSize={14}
                    leftIcon={{ color: 'text-300', icon: 'Box', size: 'ML' }}
                  />
                </Col>
                <Col span={5}>
                  <InputLabel
                    labelTitle={t(
                      'saleOrder.editOrder.SaleOrder.recipeCalculationModal.firstCard.expectedDeliveryDate'
                    )}
                    className={styles['recipe-labels']}
                    labelValue={'08/02/2024'}
                    typeLabel="ui-tiny-semibold"
                    type="ui-tiny-content"
                    customSize={14}
                    leftIcon={{
                      color: 'text-300',
                      icon: 'calendar',
                      size: 'ML',
                    }}
                  />
                </Col>
              </Row>
            </CardTotal>
            <div style={{ height: 200 }}>
              'Aguardando definição para implementar a tabela'
            </div>
            <CardTotal
              style={{ backgroundColor: 'var(--color-background-02)' }}
            >
              <Row gutter={[12, 0]} style={{ justifyContent: 'flex-end' }}>
                <Col span={5}>
                  <InputLabel
                    labelTitle={t(
                      'saleOrder.editOrder.SaleOrder.recipeCalculationModal.secondCard.grossValue'
                    )}
                    labelValue={'R$ 30,40'}
                    className={styles['recipe-labels']}
                    type="ui-tiny-content"
                    typeLabel="ui-tiny-semibold"
                    customSize={14}
                  />
                </Col>
                <Col span={5}>
                  <InputLabel
                    labelTitle={t(
                      'saleOrder.editOrder.SaleOrder.recipeCalculationModal.secondCard.discount'
                    )}
                    className={styles['recipe-labels']}
                    labelValue={'R$ 0,00'}
                    type="ui-tiny-content"
                    typeLabel="ui-tiny-semibold"
                    customSize={14}
                  />
                </Col>
                <Col span={5}>
                  <InputLabel
                    labelTitle={t(
                      'saleOrder.editOrder.SaleOrder.recipeCalculationModal.secondCard.revenueValue'
                    )}
                    className={styles['recipe-labels']}
                    labelValue={'R$ 30,40'}
                    type="ui-tiny-content"
                    typeLabel="ui-tiny-semibold"
                    customSize={24}
                  />
                </Col>
              </Row>
            </CardTotal>
          </div>
        }
        visible={visibleModal}
        withoutOkButton
        onCancelClick={() => changeVisibleModal(false)}
        cancelButtonName={t('common.close')}
        onClose={() => changeVisibleModal(false)}
        widthModal={900}
        initialValues={initialValues}
        showReturnButton
        returnButtonIcon="eye"
        returnButtonName={t('common.showValues')}
        withForm
      />
    </>
  );
};
