import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Dropdown
} from '@/Components';
import { useDismembermentTypeMemorizations } from './Hooks/useDismembermentTypeMemorizations';

export const DismembermentType: FC = () => {
  const { t } = useTranslation();

  const {
    items
  } = useDismembermentTypeMemorizations();

  return (
    <>
      <Dropdown
        name="formulaPadraoDesmembramento"
        items={items}
        placeHolder={t(
          'production.standardFormula.create.dismembermentTypePlaceholder'
        )}
        required
        label={t('production.standardFormula.create.dismembermentType')}
      />
    </>
  );
};