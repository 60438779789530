import { FC } from 'react';
import { Col } from 'antd';
import { CountryDropdown } from '@/Components/Inputs/CountryDropdown';
import { useTranslation } from 'react-i18next';
import { useOriginCountryCallAPI } from './Hooks/useOriginCountryCallAPI';
import { useOriginCountryMemorizations } from './Hooks/useOriginCountryMemorizations';

export const OriginCountry: FC = () => {
  const { t } = useTranslation();

  const { paisOrigemList, fetchNewPage } = useOriginCountryCallAPI();

  const { items } = useOriginCountryMemorizations({
    paisOrigemList,
  });

  return (
    <Col span={5}>
      <CountryDropdown
        name="loteInformacaoTecnica.paisOrigemExternalId"
        placeHolder={t('supply.lot.placeholder.countryOfOrigin')}
        items={items}
        label={t('supply.lot.create.countryOfOrigin')}
        onScrollEnd={fetchNewPage}
        autoIncrementBy="id"
      />
    </Col>
  );
};
