import { useQueryClient } from "@tanstack/react-query";
import { App } from "antd";
import { history } from '@/Store';
import { handleModalError } from '@/Utils/ErrorCodeUtils/ErrorHandlingUtils';
import { FETCH_LIST_GROUP } from "@/ControllerApiHook/UniqIds/Supply/ProductKeys";
import { IUseFunctions } from "./interfaces";

export const useDeleteProductGroupModalFunctions = ({
  deleteGroup,
  productGroup
}: IUseFunctions) => {
  const app = App.useApp();
  const queryClient = useQueryClient();

  const onDelete = async () => {
    try {
      const res = await deleteGroup.mutateAsync(
        {
          gruposExternalId: [productGroup?.externalId] as any,
        },
        {
          onError: handleModalError(app),
        }
      );
      if (res.success) {
        queryClient.invalidateQueries([FETCH_LIST_GROUP]);
        history.push(`/inventory/groups/`);
      }
    } catch {}
  };

  return {
    onDelete
  };
};