import { FC } from "react";
import { useTranslation } from "react-i18next";
import {
  InputWithSearch
} from '@/Components';
import { Col } from 'antd';
import { IPharmaceuticalForm } from "./interfaces";
import { usePharmaceuticalFormCallAPI } from "./Hooks/usePharmaceuticalFormCallAPI";
import { usePharmaceuticalFormMemorizations } from "./Hooks/usePharmaceuticalFormMemorizations";
import { usePharmaceuticalFormFunctions } from "./Hooks/usePharmaceuticalFormFunctions";

export const PharmaceuticalForm: FC<IPharmaceuticalForm> = ({
  props
}) => {
  const { t } = useTranslation();

  const {
    listPharmaceuticalForm,
    isPharmaceuticalFormLoading,
    fetchNewFarmaceuticalFormPage,
    refetchSearchPharmaceuticalFormPage
  } = usePharmaceuticalFormCallAPI();

  const {
    items
  } = usePharmaceuticalFormMemorizations({
    listPharmaceuticalForm
  });

  const {
    onSearch
  } = usePharmaceuticalFormFunctions({
    refetchSearchPharmaceuticalFormPage
  });
  
  return (
    <Col span={8}>
      <InputWithSearch
        required
        name="formaFarmaceuticaExternalId"
        label={t('products.diluted.pharmaceuticalForm')}
        placeHolder={t('products.diluted.searchForPharmaceuticalForm')}
        items={items}
        disabled={props.values.seTodasFormasFarmaceuticas}
        isLoading={isPharmaceuticalFormLoading}
        onScrollEnd={fetchNewFarmaceuticalFormPage}
        onSearch={onSearch}
      />
    </Col>
  );
};