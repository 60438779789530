import { useState } from 'react';

export const useSubGroupFormStates = () => {
  const [selectedRowsOnTable, setSelectedRowsOnTable] = useState<any>([]);

  const [visibleModal, setVisibleModal] = useState(false);

  const [visibleEditModal, setVisibleEditModal] = useState(false);

  return {
    selectedRowsOnTable,
    setSelectedRowsOnTable,
    visibleModal,
    setVisibleModal,
    visibleEditModal,
    setVisibleEditModal,
  };
};
