import { FC } from 'react';
import { Form } from '../../../../../Components';
import { LossCreateSchema } from './LossCreate.schema';
import { useCreateStates } from './Components/LossInfo/Hooks/useCreateStates';
import { LossInfo } from './Components/LossInfo';
import { ConnectedFocusError } from '@/Components/ConnectedFocusError';
import { LossRegister } from './Components/LossRegister';
import { FooterButtons } from './Components/FooterButtons';
import { useCreateFunctions } from './Components/LossInfo/Hooks/useCreateFunctions';
import { useCreateCallAPI } from './Components/Hooks/useCreateCallAPI';

const LossesRegistration: FC = () => {
  const {
    onClassProductIdSelected,
    setOnClassProductIdSelected,
    setOnProdutoExternalIdSelected,
  } = useCreateStates();

  const { createLosses, isLoading, preferences } = useCreateCallAPI();

  const { onProductSelected, onSubmit } = useCreateFunctions({
    setOnProdutoExternalIdSelected,
    setOnClassProductIdSelected,
    createLosses,
    preferences,
  });

  return (
    <Form onSubmit={onSubmit} schema={LossCreateSchema}>
      {(form) => (
        <>
          <ConnectedFocusError />
          <LossInfo form={form} onProductSelected={onProductSelected} />
          <LossRegister typeClassProduct={onClassProductIdSelected} />
          <FooterButtons isLoadingCreateLoss={isLoading} form={form} />
        </>
      )}
    </Form>
  );
};

export default LossesRegistration;
