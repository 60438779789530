import DateUtils from '@/Utils/DateUtils';
import StorageUtils from '@/Utils/StorageUtils';

export const configInvoiceValues = (values: any) => {
  const copyValues = JSON.parse(JSON.stringify(values));
  if (!copyValues.valorBaseIcms) copyValues.valorBaseIcms = 0;
  if (!copyValues.valorIcms) copyValues.valorIcms = 0;
  if (!copyValues.valorBaseIcmsSubstituicao)
    copyValues.valorBaseIcmsSubstituicao = 0;
  if (!copyValues.valorIcmsSubstituicao) copyValues.valorIcmsSubstituicao = 0;
  if (!copyValues.valorFrete) copyValues.valorFrete = 0;
  if (!copyValues.valorSeguro) copyValues.valorSeguro = 0;
  if (!copyValues.valorDesconto) copyValues.valorDesconto = 0;
  if (!copyValues.valorOutrasDespesas) copyValues.valorOutrasDespesas = 0;
  if (!copyValues.valorIpi) copyValues.valorIpi = 0;
  let totalProducts = 0;

  const purchaseOrderIds = copyValues.pedidosExternalId.map(
    (e: any) => e.externalId
  );

  const newItemsValues = copyValues.itens.map((item: any) => {
    if (!item.quantidadeComprada || !item.valorUnitario) return;
    totalProducts += item.quantidadeComprada * item.valorUnitario;

    return {
      ...item,
      baseCalculoIcms: item.baseCalculoIcms || 0,
      valorIcms: item.valorIcms || 0,
      valorIpi: item.valorIpi || 0,
      aliquotaIpi: item.aliquotaIpi || 0,
    };
  });

  return {
    ...copyValues,
    itens: newItemsValues,
    valorProdutos: totalProducts,
    pedidosExternalId: purchaseOrderIds,
    notaFiscalEntradaExternalId: values.externalId,
  };
};

export const useFormatData = () => {
  const preferences = StorageUtils.getPreference();
  const formatData = (data: any) => {
    if (!preferences) return;
    if (data.dataEmissao && data.dataEntrega) {
      data.dataEmissao = DateUtils.convertDateToApi(
        data.dataEmissao,
        preferences.padraoData
      );

      data.dataEntrega = DateUtils.convertDateToApi(
        data.dataEntrega,
        preferences.padraoData
      );
    }

    let formattedValues = configInvoiceValues(data);
    return formattedValues;
  };
  return { formatData };
};
