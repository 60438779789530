import { FC } from "react";
import { useTranslation } from "react-i18next";
import {
  TextInput
} from '@/Components';
import { Col } from 'antd';
import { IProduct } from "./interfaces";
import { useProductCallAPI } from "./Hooks/useProductCallAPI";

export const Product: FC<IProduct> = ({
  productExternalId
}) => {
  const { t } = useTranslation();

  const {
    currentProduct
  } = useProductCallAPI({
    productExternalId
  });

  return (
    <Col span={7}>
      <TextInput
        name="product"
        label={t('products.incompatible.product')}
        disabled
        value={currentProduct ? currentProduct.descricao : ''}
      />
    </Col>
  );
};