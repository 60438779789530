import { useFormikContext } from 'formik';

export const useInternalDilutionsFunctions = () => {
  const form: any = useFormikContext();

  const onChange = (stringValue: string) => {
    const currentFormInternalDiluition = stringValue
      ? parseFloat(stringValue.replace(',', '.'))
      : null;

    if (
      currentFormInternalDiluition === null ||
      isNaN(currentFormInternalDiluition)
    ) {
      return;
    }

    const currentFormInternalDiluitionFactor =
      100 / currentFormInternalDiluition;

    if (isNaN(currentFormInternalDiluitionFactor)) {
      return;
    }

    const hasChanges =
      currentFormInternalDiluitionFactor !==
      form.values.loteInformacaoTecnica.fatorDiluicaoInterna;

    if (hasChanges) {
      form.setFieldValue(
        'loteInformacaoTecnica.fatorDiluicaoInterna',
        currentFormInternalDiluitionFactor
      );
    }
  };

  return { onChange };
};
