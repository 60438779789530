import { IIListUnitMeasurementAllData } from '@/Data/Interfaces/response/UnitMeasurement/IUnitMeasurementResponse';
import { IUseMemorizations } from './interfaces';
import { IProductGroupListData } from '@/Data/Interfaces/response/Product/IProductRespose';

export const useProductRowMemorizations = ({
  unitsMeasurementsDefault,
  listunitsMeasurementsPrescription,
  isUnitOfMeasurePrescriptionLoading,
  productList,
  disabledProductList,
  index,
}: IUseMemorizations) => {
  const unitMeasureIdDefault = unitsMeasurementsDefault?.unidadeEstoqueId;

  const unitOfMeasurePrescriptionDropdown = listunitsMeasurementsPrescription
    ? {
        name: 'unidadeMedidaId',
        loading: isUnitOfMeasurePrescriptionLoading,
        defaultValue: unitMeasureIdDefault,
        options:
          listunitsMeasurementsPrescription?.data?.map(
            (x: IIListUnitMeasurementAllData) => ({
              id: x.id,
              content: x.unidadeAbreviacao,
            })
          ) || [],
      }
    : undefined;

  const items = productList
    ? productList?.data?.map((e: IProductGroupListData) => ({
        id: e.externalId,
        label: e.descricao,
        disabledItem: disabledProductList.includes(e.externalId),
      }))
    : [];

  const dropdownRight = {
    options: unitOfMeasurePrescriptionDropdown
      ? unitOfMeasurePrescriptionDropdown.options
      : [],
    name: `materiaPrimaAssociacao.${index}.unidadeId`,
  };

  return {
    unitOfMeasurePrescriptionDropdown,
    items,
    dropdownRight,
  };
};
