import { useControllerQueryApiHook } from "@/ControllerApiHook/Controller";
import { CompanyAPI } from "@/Data/API/Accountmanager/CompanyApi";
import { IGetCompanyResponse } from "@/Data/Interfaces/response/Company/ICompanyResponse";
import { GET_COMPANY_TYPE } from "@/ControllerApiHook/UniqIds/Accountmanager/CompanyKeys";
import { IUseCallAPI } from "./interfaces";

export const useCompanyFormCallAPI = ({
  externalId
}: IUseCallAPI) => {
  const { data: company, isLoading } = useControllerQueryApiHook<IGetCompanyResponse>({
    uniqId: GET_COMPANY_TYPE,
    entityApi: CompanyAPI.getCompany,
    externalIds: [externalId]
  });

  return {
    company,
    isLoading
  };
};