import { GET_DOCUMENT_PROVIDER } from '@/ControllerApiHook/UniqIds/Purchase/SupplierKeys';
import { useControllerQueryApiHook } from '@/ControllerApiHook/Controller';
import { IGetDocumentProviderResponse } from '@/Data/Interfaces/response/Provider/IProviderResponse';
import { SupplierAPI } from '@/Data/API/Purchase/SupplierApi';
import { useFormikContext } from 'formik';

export const useInfoCardCallAPI = () => {
  const form: any = useFormikContext();

  const { data: documentProvider } =
    useControllerQueryApiHook<IGetDocumentProviderResponse>({
      uniqId: GET_DOCUMENT_PROVIDER,
      entityApi: SupplierAPI.getDocumentProvider,
      externalIds: [form.values.fornecedorExternalId],
    });

  return {
    documentProvider,
  };
};
