import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useCreateCallAPI } from './Hooks/useCreateCallAPI';
import { InputWithSearch, Card, Dropdown, TextInput } from '@/Components';
import { Col, Row } from 'antd';
import { IFChildren } from '@/Components/Form';
import { LossLot } from '../LossLot/index';
import { useCreateMemorizations } from './Hooks/useCreateMemorizations';

export interface ILossInfo {
  form: IFChildren;
  onProductSelected: (product: string, classProductId: number) => void;
}

export const LossInfo: FC<ILossInfo> = ({ onProductSelected }) => {
  const { t } = useTranslation();

  const {
    productLossList,
    isLoadingProduct,
    refetchSearchProductPage,
    fetchNewProductPage,
    storageLocationDropDownList,
    isLoadingStorageLocation,
    fetchNewPageStorageLocation,
  } = useCreateCallAPI();

  const { productLossListData, storageLocationListData } =
    useCreateMemorizations({
      productLossList,
      storageLocationDropDownList,
    });

  return (
    <>
      <Card
        title={t('supply.losses.create.titleInformation')}
        withoutCollapse={true}
      >
        <Row gutter={[16, 0]}>
          <Col span={12}>
            <InputWithSearch
              items={productLossListData}
              name="produtoExternalId"
              label={t('supply.losses.create.product')}
              placeHolder={t('supply.losses.create.productPlaceholder')}
              required
              disabled={false}
              isLoading={isLoadingProduct}
              onChange={(_, value) => {
                value &&
                  onProductSelected(value.externalId, value.classeProdutoId);
              }}
              onSearch={(search) =>
                refetchSearchProductPage({
                  search: search,
                })
              }
              onScrollEnd={fetchNewProductPage}
            />
          </Col>

          <Col span={12}>
            <Dropdown
              items={storageLocationListData}
              name="localEstoqueExternalId"
              label={t('supply.losses.create.stockLocation')}
              placeHolder={t('supply.losses.create.stockLocationPlaceholder')}
              required
              isLoading={isLoadingStorageLocation}
              onScrollEnd={fetchNewPageStorageLocation}
            />
          </Col>
        </Row>

        <Row gutter={[16, 0]}>
          <Col span={11}>
            <LossLot />
          </Col>

          <Col span={6}>
            <TextInput
              name="fornecedor"
              label={t('supply.losses.create.provider')}
              required={false}
              withoutMarginBottom
              disabled
            />
          </Col>

          <Col span={5}>
            <TextInput
              name="numeroNf"
              label={t('supply.losses.create.invoice')}
              required={false}
              withoutMarginBottom
              disabled={true}
            />
          </Col>

          <Col span={2}>
            <TextInput
              name="serieNf"
              type="number"
              label={t('supply.losses.create.serialNumberInvoice')}
              required={false}
              withoutMarginBottom
              disabled
            />
          </Col>
        </Row>
      </Card>
    </>
  );
};
