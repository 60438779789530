import { i18n, Modal, Text } from '@/Components';
import { useControllerMutateApiHook } from '@/ControllerApiHook/Controller';
import { SalesOrderAPI } from '@/Data/API/Sales/SalesOrderApi';
import { IGetSaleOrderResponse } from '@/Data/Interfaces/response/Sales/SalesOrder/ISalesOrderResponse';
import {
  CANCEL_SALES_ORDER,
  FETCH_GET_SALES_ORDER
} from '@/ControllerApiHook/UniqIds/Sales/SalesOrderKeys';
import { messageErrorToast, messageSucessToast } from '@/Utils/MessageErrorToast';
import { useQueryClient } from '@tanstack/react-query';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import ReactMarkdown from 'react-markdown';

interface ICancelSaleOrderModal {
  visibleModal: boolean;
  changeVisibleModal: (value: boolean) => void;
  saleOrder: IGetSaleOrderResponse;
}

export const CancelSaleOrderModal: FC<ICancelSaleOrderModal> = ({
  changeVisibleModal,
  visibleModal,
  saleOrder,
}) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  const cancelOrderModal: any = useControllerMutateApiHook({
    uniqId: CANCEL_SALES_ORDER,
    entityApi: SalesOrderAPI.cancelSaleOrder,
    options: {
      onError: (x) => messageErrorToast(x),
      onSuccess: () =>
        messageSucessToast(
          i18n.t('saleOrder.editOrder.sideRightMenu.messageSuccessToast.cancel')
        )
    }
  });

  return (
    <Modal
      title={`${t('saleOrder.editOrder.sideRightMenu.cancelModal.title')} "#${
        saleOrder.codigoPedido
      }"?`}
      body={
        <>
          <div style={{ padding: '20px' }}>
            <Text type="ui-tiny-content">
              <ReactMarkdown>
                {t(
                  'saleOrder.editOrder.sideRightMenu.cancelModal.description',
                  {
                    codigo: `**#${saleOrder.codigoPedido}**`,
                    cliente: `**${saleOrder.cliente.nome}**`,
                  }
                )}
              </ReactMarkdown>
            </Text>
            <Text type="ui-tiny-content">
              {t(
                'saleOrder.editOrder.sideRightMenu.cancelModal.secundaryDescription'
              )}
            </Text>
          </div>
        </>
      }
      visible={visibleModal}
      okButtonName={
        cancelOrderModal.isLoading
          ? t('common.cancelLoading')
          : t('common.cancel')
      }
      loading={cancelOrderModal.isLoading}
      okButtonStatus="danger"
      cancelButtonName={t('common.back')}
      onCancelClick={() => changeVisibleModal(false)}
      onClose={() => changeVisibleModal(false)}
      maxWidth={400}
      htmlType="submit"
      onOkClick={async () => {
        const res = await cancelOrderModal.mutateAsync({
          pedidoVendaExternalId: saleOrder.externalId,
        });
        if (res.success) {
          queryClient.invalidateQueries([FETCH_GET_SALES_ORDER]);
          changeVisibleModal(false);
        }
      }}
    />
  );
};
