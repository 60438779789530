export const GET_SPECIALTIES = '[SPECIALTIES] GET_SPECIALTIES';

export const FETCH_LIST_SPECIALTIES = '[SPECIALTIES] FETCH_LIST_SPECIALTIES';
export const FETCH_LIST_SPECIALTIES_FOR_DROPDOWN =
  '[SPECIALTIES] FETCH_LIST_SPECIALTIES_FOR_DROPDOWN';

export const CREATE_SPECIALTIES = '[SPECIALTIES] CREATE_SPECIALTIES';
export const EDIT_SPECIALTIES = '[SPECIALTIES] EDIT_SPECIALTIES';
export const DELETE_SPECIALTIES = '[SPECIALTIES] DELETE_SPECIALTIES';

export const CHANGE_STATUS_SPECIALTIES =
  '[SPECIALTIES] CHANGE_STATUS_SPECIALTIES';
export const VALIDATION_SPECIALTIES_NAME =
  '[SPECIALTIES] VALIDATION_SPECIALTIES_NAME';