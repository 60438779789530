import React from 'react';
import { Link, Status, Text } from '../../../../../Components';
import { hasPermission } from '../../../../../Components/PermissionWrapper';

export const supplyPlacesColumns = (t: any) => [
  {
    title: t('supply.places.list.code'),
    dataIndex: 'sequenciaGroupTenant',
    key: 'sequenciaGroupTenant',
    width: '5%',
  },
  {
    title: t('supply.places.list.placeName'),
    dataIndex: 'descricao',
    key: 'descricao',
    isHighlightable: true,
    sortColumn: 'descricao',
    sorter: true,
    defaultSortOrder: 'ascend',
    render: (x: string, row: any) =>
      !hasPermission(2008) ? (
        <Text type="ui-tiny-bold" style={{ width: 'fit-content' }}>
          {x}
        </Text>
      ) : (
        <Link
          type="ui-tiny-bold"
          to={`/inventory/supply-places/${row.externalId}/details`}
          style={{ width: 'fit-content' }}
        >
          {x}
        </Link>
      ),
  },
  {
    title: t('supply.places.list.status'),
    dataIndex: 'ativo',
    key: 'ativo',
    width: '15%',
    render: (prop: any) => (
      <Status type={prop ? 'success' : 'disabled'}>
        {t(`supply.places.list.statusTypes.${prop ? 'active' : 'inactive'}`)}
      </Status>
    ),
  },
  {
    title: t('supply.places.list.company'),
    dataIndex: 'nomeEmpresa',
    key: 'nomeEmpresa',
    isHighlightable: true,
    sortColumn: 'nomeEmpresa',
  },
];
