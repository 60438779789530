import { useControllerMutateApiHook } from '@/ControllerApiHook/Controller';
import { DELETE_USER_TYPE } from '@/ControllerApiHook/UniqIds/Accountmanager/UserKeys';
import { UserAPI } from '@/Data/API/Accountmanager/UserApi';
import { history } from '@/Store';

export const useDeleteUserCallAPI = () => {
  const deleteUser = useControllerMutateApiHook({
    uniqId: DELETE_USER_TYPE,
    entityApi: UserAPI.deleteUser,
    options: { onSuccess: () => history.push('/admin/user') },
  });

  return {
    deleteUser,
  };
};
