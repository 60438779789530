import { QueryFunctionContext } from '@tanstack/react-query';
import { Http, IQueryListParams } from '../../../Utils/Http';
import {
  IGetSaleOrderResponse,
  IHistoricSaleOrder,
  IListSalesOrderResponse,
} from '../../Interfaces/response/Sales/SalesOrder/ISalesOrderResponse';
import {
  IAddFinishedProductRequest,
  IBasicSaleOrderRequest,
  IChangeItemAmoutSaleOrderRequest,
  IChangeSelectedFinishedProductRequest,
  IEditFinishedProductRequest,
  IPatchSaleOrderRequest,
  IUpdateDeliverySaleOrderRequest,
} from '../../Interfaces/request/Sales/Service/IServiceRequest';

export class SalesOrderAPI {
  public static async createSaleOrder(body: { atendimentoExternalId: string }) {
    return await Http.fetcher('/pharma/vendas/v1/PedidoVenda', {
      method: 'POST',
      body: JSON.stringify(body),
    });
  }
  public static async deleteSaleOrder(body: { pedidoVendaItemId: number }) {
    return await Http.fetcher('/pharma/vendas/v1/PedidoVenda/RemoverItem', {
      method: 'DELETE',
      body: JSON.stringify(body),
    });
  }
  public static async addFinishedProduct(body: IAddFinishedProductRequest) {
    return await Http.fetcher(
      '/pharma/vendas/v1/PedidoVenda/CadastrarItemProdutoAcabado',
      {
        method: 'POST',
        body: JSON.stringify(body),
      }
    );
  }
  public static async editFinishedProduct(body: IEditFinishedProductRequest) {
    return await Http.fetcher(
      '/pharma/vendas/v1/PedidoVenda/AtualizarItemProdutoAcabado',
      {
        method: 'PUT',
        body: JSON.stringify(body),
      }
    );
  }
  public static async changeSelectedFinishedProduct(
    body: IChangeSelectedFinishedProductRequest
  ) {
    return await Http.fetcher('/pharma/vendas/v1/PedidoVenda/SelecionarItem', {
      method: 'PUT',
      body: JSON.stringify(body),
    });
  }

  public static async listSalesOrder(
    context: QueryFunctionContext
  ): Promise<IListSalesOrderResponse> {
    return await Http.fetcher<IListSalesOrderResponse>(
      Http.AddQueryParams(
        '/pharma/vendas/v1/PedidoVenda/ListarDetalhado',
        context.queryKey[1] as IQueryListParams
      )
    );
  }

  public static async getSaleOrder(
    context: QueryFunctionContext
  ): Promise<IGetSaleOrderResponse | null> {
    if (!context.queryKey[1]) return null;
    return await Http.fetcher<IGetSaleOrderResponse>(
      `/pharma/vendas/v1/PedidoVenda/${context.queryKey[1]}`
    );
  }

  public static async getHistoricSaleOrder(
    context: QueryFunctionContext
  ): Promise<IHistoricSaleOrder | null> {
    if (!context.queryKey[1]) return null;
    return await Http.fetcher<IHistoricSaleOrder>(
      `/pharma/vendas/v1/PedidoVenda/ObterHistorico/${context.queryKey[1]}`
    );
  }

  public static async changeItemAmountSaleOrder(
    body: IChangeItemAmoutSaleOrderRequest
  ) {
    return await Http.fetcher(
      '/pharma/vendas/v1/PedidoVenda/AtualizarQuantidadeItem',
      {
        method: 'PUT',
        body: JSON.stringify(body),
      }
    );
  }

  public static async patchSaleOrder(body: IPatchSaleOrderRequest) {
    return Http.fetcher(`/pharma/vendas/v1/PedidoVenda/${body.externalId}`, {
      method: 'PATCH',
      body: JSON.stringify(Http.ConvertFieldsToPatch(body.data)),
    });
  }

  public static async updateDeliverySaleOrder(
    body: IUpdateDeliverySaleOrderRequest
  ) {
    return await Http.fetcher(
      '/pharma/vendas/v1/PedidoVenda/AtualizarEntrega',
      {
        method: 'PUT',
        body: JSON.stringify(body),
      }
    );
  }

  public static async approveSaleOrder(body: IBasicSaleOrderRequest) {
    const res = await Http.fetcher(`/pharma/vendas/v1/PedidoVenda/Aprovar`, {
      method: 'PUT',
      body: JSON.stringify(body),
    });

    if (!res) return [];
    return res;
  }

  public static async reproveSaleOrder(body: IBasicSaleOrderRequest) {
    const res = await Http.fetcher(`/pharma/vendas/v1/PedidoVenda/Reprovar`, {
      method: 'PUT',
      body: JSON.stringify(body),
    });

    if (!res) return [];
    return res;
  }

  public static async cancelSaleOrder(body: IBasicSaleOrderRequest) {
    const res = await Http.fetcher(`/pharma/vendas/v1/PedidoVenda/Cancelar`, {
      method: 'PUT',
      body: JSON.stringify(body),
    });

    if (!res) return [];
    return res;
  }

  public static async reverseSaleOrder(body: IBasicSaleOrderRequest) {
    const res = await Http.fetcher(`/pharma/vendas/v1/PedidoVenda/Estornar`, {
      method: 'PUT',
      body: JSON.stringify(body),
    });

    if (!res) return [];
    return res;
  }
}
