import React from 'react';
import { Link, Status, Text } from '../../../../../Components';
import { hasPermission } from '../../../../../Components/PermissionWrapper';
import { PermissionEnum } from '../../../../../Data/Interfaces/model/permissionEnum';
import { LinkButton } from '../../../../../Components/Layouts/LinkButton';

export const packinkgClassificationColumns = (t: any) => [
  {
    title: t('production.packingClassification.list.description'),
    dataIndex: 'descricao',
    key: 'descricao',
    isHighlightable: true,
    sortColumn: 'descricao',
    sorter: true,
    defaultSortOrder: 'ascend',
    render: (x: string, row: any) =>
      !hasPermission(
        PermissionEnum.Producao_ClassificacaoEmbalagem_VerDetalhes
      ) ? (
        <Text type="ui-tiny-bold" style={{ width: 'fit-content' }}>
          {x}
        </Text>
      ) : (
        <Link
          type="ui-tiny-bold"
          to={`/production/packing-classification/${row.externalId}/details`}
          style={{ width: 'fit-content' }}
        >
          {x}
        </Link>
      ),
  },
  {
    title: t('production.packingClassification.list.linkedProduct'),
    dataIndex: 'produtosVinculados',
    key: 'produtosVinculados',
    render: (x: any, value: any) => (
      <LinkButton
        link={`/production/packing-classification/${value.externalId}/linkedProducts`}
        text={
          x !== 1
            ? `${x} ${t('products.common.products')}`
            : `${x} ${t('products.common.product')}`
        }
      />
    ),
  },
  {
    title: t('production.packingClassification.list.status'),
    dataIndex: 'ativo',
    key: 'ativo',
    width: '15%',
    render: (prop: any) => (
      <Status type={prop ? 'success' : 'disabled'}>
        {t(
          `production.packingClassification.list.statusTypes.${
            prop ? 'active' : 'inactive'
          }`
        )}
      </Status>
    ),
  },
];
