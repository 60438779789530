import * as yup from 'yup';
import i18n from '../../../../../Components/i18n';

export const BalanceAdjustmentEditSchema = yup.object().shape({
  produtoExternalId: yup
    .string()
    .required(i18n.t('supply.balanceAdjustment.create.errors.requiredName')),

  localEstoqueExternalId: yup
    .string()
    .nullable()
    .required(i18n.t('supply.balanceAdjustment.create.errors.localEstoqueId')),

  loteExternalId: yup
    .string()
    .nullable()
    .required(
      i18n.t('supply.balanceAdjustment.create.errors.requiredLoteExternalId')
    ),

  amount: yup
    .number()
    .nullable()
    .required(i18n.t('supply.balanceAdjustment.create.errors.quantidade')),
});
