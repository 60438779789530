import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Card } from '@/Components/Card';
import { useTotalPurchaseFunctions } from './Hooks/useTotalPurchaseFunctions';
import { OrderValues } from './Components/OrderValues';
import { ProductsValues } from './Components/ProductsValues';

export const TotalPurchase: FC = () => {
  const { t } = useTranslation();

  const {
    productsTotal,
    productTotalWithDiscount,
    finalTotal
  } = useTotalPurchaseFunctions();

  return (
    <>
      <Card title={t('common.productsUppercase')} withoutCollapse>
        <ProductsValues
          productsTotal={productsTotal}
          productTotalWithDiscount={productTotalWithDiscount}
        />
      </Card>
      <Card title={t('common.order')} withoutCollapse>
        <OrderValues finalTotal={finalTotal} />
      </Card>
    </>
  );
};
