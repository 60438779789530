import { useState } from "react";

export const useDismembermentTypeStates = () => {
  const [
    dismembermentDescriptionState, 
    setDismembermentDescriptionState
  ] = useState<string>();

  return {
    dismembermentDescriptionState,
    setDismembermentDescriptionState
  };
};