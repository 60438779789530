import React from 'react';
import { notification as AntdNotification } from 'antd';

import { Button, Icon, Text } from '../..';

import style from './Toast.module.scss';
import './Toast.override.scss';

import i18n from '../../i18n';

export type ToastType = 'sucess' | 'error' | 'warning';

export const openNotification = (
  type: ToastType,
  title: string,
  description: string,
  confirmationButton: boolean
) => {
  const key = `open${Date.now()}`;
  const btn = (
    <div className={style['divConfirm']}>
      <Button
        className={style['buttonOk']}
        type="primary"
        onClick={() => AntdNotification.destroy(key)}
      >
        {i18n.t('toast.ok')}
      </Button>
      <Button className={style['help-button']} type="link">
        {i18n.t('toast.help')}
      </Button>
    </div>
  );
  AntdNotification.config({ maxCount: 3 });
  AntdNotification.open({
    className: 'toast-' + type + '-style',
    message: (
      <Text
        className={title ? style['title'] : ''}
        type="heading4"
        color="text-50"
      >
        {title}
      </Text>
    ),
    description: (
      <>
        <Text type="ui-tiny-content" color="text-50">
          {description}
        </Text>
        <div
          className={style['leftBar-' + type]}
          onClick={() => AntdNotification.destroy(key)}
        />
        <div className="iconClose">
          <Icon icon="close-x" />
        </div>
        <div
          className="iconToast"
          onClick={() => AntdNotification.destroy(key)}
        >
          <Icon
            icon={
              type === 'sucess'
                ? 'check'
                : type === 'error'
                ? 'close-x'
                : type === 'warning'
                ? 'exclamation-upside-down'
                : 'close-x'
            }
          />
        </div>
      </>
    ),
    placement: 'bottomRight',
    btn: confirmationButton && btn,
    key,
    duration: 2.5,
  });
};
