import { useCallback } from 'react';
import { IUseFunctions } from './interfaces';
import { history } from '@/Store';
import { IPagination } from '@/Components/Table';
import { App } from 'antd';
import { handleModalError } from '@/Utils/ErrorCodeUtils/ErrorHandlingUtils';
import { cannotDeleteClientWithMovementsInTheSystemProps } from '@/Utils/ErrorCodeUtils/ErrorModalUtils/ErrorModalClient';
import { useTranslation } from 'react-i18next';

export const useListFunctions = ({
  changeStatus,
  setSelectedRowsOnTable,
  deleteClient,
}: IUseFunctions) => {
  const app = App.useApp();

  const { t } = useTranslation();

  const onEdit = useCallback(
    async (prescriber: any[], values: any) =>
      await changeStatus.mutateAsync({
        clientesExternalId: prescriber.map((x) => x.externalId),
        ativo: values.status === 'success',
      }),
    [changeStatus]
  );

  const onSelectedRowsOnTable = useCallback(
    (x: any) => setSelectedRowsOnTable(x),
    [setSelectedRowsOnTable]
  );

  const onRowDoubleClick = useCallback(
    (x: any) => history.push(`/sales/customers/${x.externalId}/details`),
    [history]
  );

  const onDelete = useCallback(
    async (client: any[]) => {
      await deleteClient.mutateAsync(
        {
          clientesExternalId: client.map((x) => x?.externalId),
        },
        {
          onError: handleModalError(
            app,
            cannotDeleteClientWithMovementsInTheSystemProps(t)
          ),
        }
      );
    },
    [deleteClient]
  );

  return {
    onEdit,
    onSelectedRowsOnTable,
    onRowDoubleClick,

    onDelete,
  };
};
