import React, { FC, useRef, useState } from 'react';
import { Input, InputRef, Select } from 'antd';
import { Field, useField } from 'formik';
import { IField } from '../../interfaces/IField';
import { InputLabel } from '../../Label';
import { Icon, Text, TextShortner, Tooltip } from '../../..';
import { LocalizaPropriedadePorPath } from '../../../../Utils/FormikUtils';
import { Divflex } from '../../../../Layouts/DivWhithFlex';
import Spinner from '../../../Spinner';

import styles from '../NumberInput.module.scss';
import '../NumberInput.override.scss';
import { INumberInput } from '../types';

export const InnerNumberInput: FC<
  INumberInput & {
    currentPrefix?: string;
    currentSuffix?: string;
    separators?: {
      decimalScale?: number;
      thousandSeparator?: string;
      decimalSeparator?: string;
    };
    outsideRef?: any;
    acceptOnlyNumbers?: boolean;
  }
> = ({
  name,
  label,
  maxLength,
  nulable,
  required,
  placeHolder,
  error,
  success,
  rightIcon,
  rightIconTooltipOnly,
  leftIcon,
  withTooltip,
  withoutMarginBottom,
  disabled,
  className,
  supportText,
  onChange,
  onBlur,
  isLoading,
  value,
  withStatusIcon,
  dropdownLeft,
  dropdownRight,
  currentPrefix,
  currentSuffix,
  outsideRef,
  withLoadingLabel,
  isSuccessIcon,
  withBoldContent,
  dataTestId,
  containerDataTestId,
  leftSelectDataTestId,
  rightSelectDataTestId,
  acceptOnlyNumbers,
}) => {
  const [copied, setCopied] = useState(false);
  const field = useField(name);
  const dropdownLeftField = useField(dropdownLeft?.name || 'dropdownLeft');
  const dropdownRightField = useField(dropdownRight?.name || 'dropdownRight');
  const innerRef = useRef<React.Ref<InputRef> | null>();
  const inputRef = outsideRef || innerRef;

  return (
    <div
      className={`${withoutMarginBottom ? '' : styles['margin-bottom']} ${
        styles['Container']
      }`}
    >
      <Field name={name}>
        {(props: IField) => {
          const hasError =
            error ||
            !!(
              props.form &&
              props.form.errors &&
              props.meta.touched &&
              LocalizaPropriedadePorPath(props.form.errors, name)
            );
          const classNames = className;

          const onPointerLeave = (event: any) => {
            if (nulable && (event.target === null || event.target.value === ''))
              props.form.setFieldValue(name, null);
          };

          return (
            <>
              {label && (
                <Divflex>
                  <InputLabel
                    label={label}
                    props={props}
                    withStatusIcon={withStatusIcon}
                    required={required}
                    withLoadingIcon={withLoadingLabel}
                    isSuccessIcon={isSuccessIcon}
                  />
                  {withTooltip && (
                    <Tooltip
                      title={withTooltip.title}
                      showMe
                      children={
                        <Icon
                          className={styles['icon-with-tooltip']}
                          icon={withTooltip.icon}
                          color="text-300"
                          size="S"
                        />
                      }
                    />
                  )}
                </Divflex>
              )}

              <div
                data-testid={containerDataTestId}
                className={`
              ${styles['input-container']} 
              ${classNames} 
              ${leftIcon ? styles['with-left-icon'] : null}
              ${
                rightIcon || success || hasError
                  ? styles['with-right-icon']
                  : null
              } ${hasError ? styles['error'] : null}
              ${success ? styles['success'] : null}
              ${disabled ? styles['disabled'] : null}`}
              >
                {supportText && (
                  <div className={styles['supportText']}>{supportText}</div>
                )}

                <Input
                  id={name}
                  // @ts-ignore
                  ref={inputRef}
                  {...props.field}
                  data-testid={dataTestId}
                  className={`${
                    withBoldContent ? styles['bold-content-input'] : null
                  } ${styles['TextInput']}`}
                  style={{
                    paddingRight: supportText ? '48px' : undefined,
                  }}
                  autoComplete="off"
                  maxLength={maxLength}
                  placeholder={placeHolder}
                  onPointerLeave={onPointerLeave}
                  disabled={disabled || props.form.isSubmitting}
                  value={value || props.field.value}
                  onBlur={onBlur}
                  onChange={(event) => {
                    if (acceptOnlyNumbers) {
                      const numericValue = event.target.value.replace(
                        /[^\d.-]/g,
                        ''
                      ); // remove all non-numeric characters except for period and minus

                      // Update formik field value
                      field[2].setValue(numericValue);
                      onChange && onChange(event);
                      return;
                    }

                    // @ts-ignore
                    const input = inputRef?.current?.input;
                    let currentValue = event.target.value;
                    currentValue = currentValue.replace(',', '.');

                    // This is needed when the input is undefined OR you select the value and try to change it
                    if (
                      (input &&
                        currentPrefix &&
                        currentValue.indexOf(currentPrefix) === -1) ||
                      (currentSuffix &&
                        currentValue.indexOf(currentSuffix) === -1) ||
                      currentValue.toString().length + 1 <
                        (value || '')?.toString().length
                    ) {
                      field[2].setValue(parseFloat(currentValue));

                      input.focus();

                      // This does put the cursor in the correct position after changing the value
                      setTimeout(() => {
                        const valueWithPrefixSuffix = `${
                          currentPrefix || ''
                        }${currentValue}${currentSuffix || ''}`;
                        let lastIndex = valueWithPrefixSuffix.length;

                        if (currentSuffix) {
                          lastIndex = lastIndex - currentSuffix.length;
                        }

                        input.setSelectionRange(lastIndex, lastIndex);
                      }, 1);
                    }

                    onChange && onChange(event);
                  }}
                  addonBefore={
                    leftIcon?.titleString ? (
                      <Tooltip
                        title={
                          leftIcon.toolTipTitle &&
                          (!copied
                            ? leftIcon.toolTipTitle[0]
                            : leftIcon.toolTipTitle[1])
                        }
                        showMe={!!leftIcon.toolTipTitle}
                        placement="bottomRight"
                        copied={copied}
                      >
                        <TextShortner
                          className={styles['left-icon-selected']}
                          type="ui-sub-content"
                          color="text-300"
                          children={leftIcon?.titleString}
                          maxCharacters={3}
                        />
                      </Tooltip>
                    ) : (
                      dropdownLeft && (
                        <Select
                          className={styles['dropdown-left']}
                          data-testid={leftSelectDataTestId}
                          value={dropdownLeftField[1]?.value}
                          defaultValue={dropdownLeft.defaultValue}
                          style={{ width: 60 }}
                          loading={dropdownLeft.loading}
                          onChange={(selectedValue) => {
                            props.field &&
                              props.form.setFieldValue(
                                dropdownLeft.name,
                                selectedValue
                              );
                          }}
                          rootClassName={dropdownLeft.id}
                          disabled={disabled}
                          onSelect={(id) =>
                            dropdownLeft.onChange && dropdownLeft.onChange(id)
                          }
                        >
                          {dropdownLeft?.options.map((x) => (
                            <Select.Option
                              data-testid={`${
                                leftSelectDataTestId || `${name}-dropdown-left`
                              }-option-${x.id}`}
                              key={`${x.id}_${x.content}`}
                              value={x.id}
                            >
                              {x.content ? (
                                <Text
                                  type="ui-tiny-content"
                                  color="text-300"
                                  children={x.content}
                                />
                              ) : (
                                x.icons && (
                                  <Icon icon={x.icons} color="text-300" />
                                )
                              )}
                            </Select.Option>
                          ))}
                        </Select>
                      )
                    )
                  }
                  addonAfter={
                    rightIcon?.titleString ? (
                      <Tooltip
                        title={
                          rightIcon.toolTipTitle &&
                          (!copied
                            ? rightIcon.toolTipTitle[0]
                            : rightIcon.toolTipTitle[1])
                        }
                        showMe={!!rightIcon.toolTipTitle}
                        placement="bottomRight"
                        copied={copied}
                      >
                        <TextShortner
                          className={styles['right-icon-selected']}
                          type="ui-tiny-content"
                          color="text-300"
                          children={rightIcon?.titleString}
                          maxCharacters={3}
                        />
                      </Tooltip>
                    ) : (
                      dropdownRight && (
                        <Select
                          data-testid={rightSelectDataTestId}
                          value={dropdownRightField[1]?.value}
                          defaultValue={dropdownRight.defaultValue}
                          style={{ width: 60 }}
                          loading={dropdownRight.loading}
                          onChange={(selectedValue) => {
                            props.field &&
                              props.form.setFieldValue(
                                dropdownRight.name,
                                selectedValue
                              );
                          }}
                          disabled={dropdownRight.disabled || disabled}
                          onSelect={(id) =>
                            dropdownRight.onChange && dropdownRight.onChange(id)
                          }
                          className="number-input-dropdown-right"
                        >
                          {dropdownRight?.options.map((x) => (
                            <Select.Option
                              data-testid={`${
                                rightSelectDataTestId || `${name}-right`
                              }-option-${x.id}`}
                              key={`${x.id}_${x.content}`}
                              value={x.id}
                            >
                              {x.content ? (
                                <Text
                                  type="ui-tiny-content"
                                  color="text-300"
                                  children={x.content}
                                />
                              ) : (
                                x.icons && (
                                  <Icon icon={x.icons} color="text-300" />
                                )
                              )}
                            </Select.Option>
                          ))}
                        </Select>
                      )
                    )
                  }
                />
                {dropdownLeft ? (
                  <Icon
                    icon="chevron-down"
                    size="S"
                    color="text-300"
                    style={{
                      marginLeft: '38px ',
                      marginTop: '-24px ',
                    }}
                    disabled={dropdownLeft.disabled || disabled}
                  />
                ) : dropdownRight ? (
                  <Icon
                    icon="chevron-down"
                    size="S"
                    color="text-300"
                    style={{
                      right: '11px ',
                      top: '13px ',
                      position: 'absolute',
                    }}
                    disabled={dropdownRight.disabled || disabled}
                  />
                ) : null}
                {hasError ? (
                  <Icon
                    className={styles['Icon']}
                    size="M"
                    color="status-danger-base"
                  />
                ) : null}

                {success ? (
                  <Icon
                    className={styles['Icon']}
                    icon="check"
                    size="M"
                    color="status-success-base"
                  />
                ) : null}

                {rightIconTooltipOnly?.icon ? (
                  <Tooltip
                    title={rightIconTooltipOnly.toolTipTitle}
                    showMe={!!rightIconTooltipOnly.toolTipTitle}
                    placement="bottomRight"
                  >
                    <Icon
                      className={styles['Icon']}
                      icon={rightIconTooltipOnly.icon}
                      size="SM"
                      color="text-400"
                    />
                  </Tooltip>
                ) : null}

                {isLoading && (
                  <div className={styles['spinner']}>
                    <Spinner />
                  </div>
                )}
                {props.form &&
                props.form.errors &&
                LocalizaPropriedadePorPath(props.form.errors, name) ? (
                  <p className={styles['errorText']}>
                    {LocalizaPropriedadePorPath(props.form.errors, name)}
                  </p>
                ) : null}
              </div>
            </>
          );
        }}
      </Field>
    </div>
  );
};
