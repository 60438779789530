import { Dropdown, TextInput, Tooltip } from '@/Components';
import { Col, Row } from 'antd';
import { FormikContextType, useFormikContext } from 'formik';
import _ from 'lodash';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { IListCompanyData } from '@/Data/Interfaces/response/Company/ICompanyResponse';
import { useEditCallAPI } from '../../../Hooks/useEditCallAPI';
import { useParams } from 'react-router';
import { useCreateFunctions } from '@/Pages/Sectors/Inventory/SupplyPlaces/Create/Hooks/useCreateFunctions';
import styles from '../../../../Details/DetailsForm.module.scss';

interface IDetailsForm {
  disabled: boolean;
}

export const RowDescription: FC<IDetailsForm> = ({ disabled }) => {
  const { externalId } = useParams<{ externalId: string }>();
  const form: FormikContextType<any> = useFormikContext();
  const { t } = useTranslation();

  const {
    listCompanys,
    isCompanyLoading,
    storageLocation,
    fetchStorageLocationDescription,
  } = useEditCallAPI({ externalId, t, form });

  const { fetchStorageLocationName } = useCreateFunctions({
    fetchStorageLocationDescription,
    storageLocation,
  });

  return (
    <Row gutter={[16, 0]} justify="space-between">
      <Col span={12}>
        <Tooltip
          title={`${t('supply.supplyPlaces.toolTips.nameOfStorageLocation')} ${
            storageLocation?.descricao
          }`}
          showMe={storageLocation?.descricao !== form.values.descricao}
        >
          <TextInput
            className={styles['text-input']}
            name="descricao"
            label={[
              {
                children: t('supply.supplyPlaces.details.name'),
              },
            ]}
            placeHolder={t('supply.supplyPlaces.details.namePlaceholder')}
            required
            withoutMarginBottom
            disabled={disabled}
            onChange={(x) => {
              fetchStorageLocationName(x, form.values.empresaId);
            }}
            withStatusIcon
          />
        </Tooltip>
      </Col>
      <Col span={12}>
        <Tooltip
          title={`${t('supply.supplyPlaces.toolTips.nameOfCompany')} ${
            storageLocation?.nomeEmpresa
          }`}
          showMe={
            !disabled &&
            storageLocation?.empresaExternalID !== form.values.empresaId
          }
        >
          <Dropdown
            items={listCompanys?.data?.map((value: IListCompanyData) => ({
              id: value.externalId,
              label: value.nomeFantasia,
            }))}
            isLoading={isCompanyLoading}
            disabled={disabled}
            name="empresaId"
            label={[
              {
                children: t('supply.supplyPlaces.create.company'),
              },
            ]}
            required
            placeHolder={t('supply.supplyPlaces.create.companyPlaceholder')}
            withStatusIcon
            onChange={(x) =>
              fetchStorageLocationName(form?.values?.descricao, x)
            }
          />
        </Tooltip>
      </Col>
    </Row>
  );
};
