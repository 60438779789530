import { i18n } from '@/Components';
import {
  useControllerMutateApiHook,
  useControllerQueryListApiHook,
} from '@/ControllerApiHook/Controller';
import {
  CREATE_FINISHED_PRODUCT,
  EDIT_FINISHED_PRODUCT,
  FETCH_GET_SALES_ORDER,
} from '@/ControllerApiHook/UniqIds/Sales/SalesOrderKeys';
import { FETCH_LIST_FINISHED_PRODUCT } from '@/ControllerApiHook/UniqIds/Supply/ProductKeys';
import { SalesOrderAPI } from '@/Data/API/Sales/SalesOrderApi';
import { ProductAPI } from '@/Data/API/Supply/Product';
import {
  messageErrorToast,
  messageSucessToast,
} from '@/Utils/MessageErrorToast';
import { useQueryClient } from '@tanstack/react-query';
import { IUseAddProductModalCallAPI } from './interfaces';

export const useAddProductModalCallAPI = ({
  setProductSelected,
  changeVisibleModal,
}: IUseAddProductModalCallAPI) => {
  const queryClient = useQueryClient();

  const productList = useControllerQueryListApiHook({
    uniqId: FETCH_LIST_FINISHED_PRODUCT,
    entityApi: ProductAPI.finishedProductList,
    pagination: {
      sorter: { column: 'descricao', direction: 'DESC' },
    },
  });

  const addProduct = useControllerMutateApiHook({
    uniqId: CREATE_FINISHED_PRODUCT,
    entityApi: SalesOrderAPI.addFinishedProduct,
    options: {
      onSuccess: () => {
        changeVisibleModal(false);
        queryClient.invalidateQueries([FETCH_GET_SALES_ORDER]);
        setProductSelected(undefined);
        messageSucessToast(
          i18n.t('saleOrder.editOrder.SaleOrder.addProductModal.success')
        );
      },
      onError: (x) => messageErrorToast(x),
    },
  });

  const editProduct = useControllerMutateApiHook({
    uniqId: EDIT_FINISHED_PRODUCT,
    entityApi: SalesOrderAPI.editFinishedProduct,
    options: {
      onSuccess: () => {
        changeVisibleModal(false);
        queryClient.invalidateQueries([FETCH_GET_SALES_ORDER]);
        setProductSelected(undefined);
        messageSucessToast(
          i18n.t('saleOrder.editOrder.SaleOrder.editProductModal.success')
        );
      },
      onError: (x) => messageErrorToast(x),
    },
  });

  return { productList, addProduct, editProduct };
};
