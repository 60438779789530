import { useControllerQueryApiHook } from '@/ControllerApiHook/Controller';
import { FETCH_GET_PRODUCT } from '@/ControllerApiHook/UniqIds/Supply/ProductKeys';
import { ProductAPI } from '@/Data/API/Supply/Product';
import {
  IFinishedProductListData,
  IProductResponse,
} from '@/Data/Interfaces/response/Product/IProductRespose';
import { FormikContextType } from 'formik';

interface IusePriceRowCallAPI {
  productSelected?: IFinishedProductListData;
  form: FormikContextType<any>;
}
export const usePriceRowCallAPI = ({
  productSelected,
  form,
}: IusePriceRowCallAPI) => {
  useControllerQueryApiHook<IProductResponse>({
    uniqId: FETCH_GET_PRODUCT,
    entityApi: ProductAPI.getProduct,
    externalIds: [productSelected?.externalId],
    onSuccess: (product: IProductResponse) => {
      form.setFieldValue('valorUnitario', product?.valorCusto);
    },
  });

  return {};
};
