import { i18n } from '@/Components';
import { useControllerMutateApiHook } from '@/ControllerApiHook/Controller';
import {
  CREATE_SPECIALTIES,
  FETCH_LIST_SPECIALTIES,
} from '@/ControllerApiHook/UniqIds/People/SpecialtiesKeys';
import { SpecialtiesAPI } from '@/Data/API/People/SpecialtiesApi';
import {
  messageErrorToast,
  messageSucessToast,
} from '@/Utils/MessageErrorToast';
import { useQueryClient } from '@tanstack/react-query';
import { IuseCreateExpertiseCallAPI } from './interface';

export const useCreateExpertiseCallAPI = ({
  setCreateModalOpen,
}: IuseCreateExpertiseCallAPI) => {
  const queryClient = useQueryClient();

  const {
    mutateAsync: createSpecialties,
    isLoading: createSpecialtiesLoading,
  }: any = useControllerMutateApiHook({
    uniqId: CREATE_SPECIALTIES,
    entityApi: SpecialtiesAPI.createSpecialties,
    options: {
      onSuccess: () => {
        messageSucessToast(i18n.t('specialties.create.success'));

        queryClient.invalidateQueries([FETCH_LIST_SPECIALTIES]);

        setCreateModalOpen(false);
      },
      onError: (x) => messageErrorToast(x),
    },
  });

  return { createSpecialties, createSpecialtiesLoading };
};
