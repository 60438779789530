import {
  VALIDATION_NAME_GROUP
} from '@/ControllerApiHook/UniqIds/Supply/ProductKeys';
import { IUseCallAPI } from './interfaces';
import { useControllerMutateApiHook } from '@/ControllerApiHook/Controller';
import { ProductAPI } from '@/Data/API/Supply/Product';

export const useModalBodyCallAPI = ({
  onGroupValidationError,
  onGroupValidationSuccess
}: IUseCallAPI) => {
  const { mutateAsync }: any = useControllerMutateApiHook({
    uniqId: VALIDATION_NAME_GROUP,
    entityApi: ProductAPI.productGroupNameValidation,
    options: {
      onError: onGroupValidationError,
      onSuccess: onGroupValidationSuccess
    }
  });

  return { mutateAsync };
};