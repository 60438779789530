import { FC } from 'react';
import { Form } from '../../../../../Components';
import { InfoCard } from './Components/Cards/InfoCard';
import { TransferCard } from './Components/Cards/TransfersCard';
import { CreateTransferSchema } from './CreateTransfer.schema';
import { useCreateStates } from './Hooks/useCreateStates';
import { useCreateCallAPI } from './Hooks/useCreateCallAPI';
import { useCreateFunctions } from './Hooks/useCreateFunctions';
import { FooterButtons } from './Components/FooterButtons';
import { ConnectedFocusError } from '@/Components/ConnectedFocusError';

export const CreateTransfers: FC = () => {
  const {
    selectedDestiny,
    selectedOrigin,
    setSelectedDestiny,
    setSelectedOrigin,
  } = useCreateStates();

  const { createTransfer, isLoadingCreateTransfer } = useCreateCallAPI();

  const { onSubmit } = useCreateFunctions({ createTransfer });

  return (
    <>
      <Form
        schema={CreateTransferSchema}
        onSubmit={onSubmit}
        initialValues={{
          transfers: [],
        }}
      >
        <>
          <InfoCard
            onDestinyChange={(value) => setSelectedDestiny(value)}
            onOriginChange={(value) => setSelectedOrigin(value)}
          />
          <TransferCard
            originSupplyPlaceId={selectedOrigin}
            destinySupplyPlaceId={selectedDestiny}
          />
          <FooterButtons
            isLoadingCreateTransfer={isLoadingCreateTransfer}
          />
          <ConnectedFocusError />
        </>
      </Form>
    </>
  );
};
