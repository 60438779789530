import { useMemo } from 'react';
import { ISpecialtites, IUseMemorizations } from './interfaces';
import DateUtils from '@/Utils/DateUtils';

export const useInfoModalMemorizations = ({
  prescriber,
  preferences,
}: IUseMemorizations) => {
  const initialValues = useMemo(
    () => ({
      externalId: prescriber?.externalId,
      specialtiesLabel: prescriber?.prescritorEspecialidades.map((x) => ({
        label: x.descricao,
        id: x.especialidadeExternalId,
      })),
      nomeCompleto: prescriber?.nomeCompleto,
      tipoRegistroId:
        prescriber?.tipoRegistroId && parseInt(prescriber.tipoRegistroId),
      ufRegistroExternalId: prescriber?.ufRegistroExternalId,
      codigoRegistro: prescriber?.codigoRegistro,
      dataNascimento: DateUtils.convertDate(
        prescriber?.dataNascimento,
        preferences?.padraoData
      ),
      observacao: prescriber?.observacao,
      descontoProdutosAcabados: prescriber?.descontoProdutosAcabados,
      descontoFormulas: prescriber?.descontoFormulas,
      especialidadesExternalId: prescriber?.prescritorEspecialidades.map(
        (x: ISpecialtites) => x.especialidadeExternalId
      ),
    }),
    [prescriber, preferences]
  );

  return {
    initialValues,
  };
};
