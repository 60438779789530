import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Form } from '@/Components';
import { InfoCard } from './Components/InfoCard';
import { TechnicalInfoCard } from './Components/TechnicalInfoCard';
import { Modal } from '@/Components/Modal';
import { IDetailsForm } from './interfaces';
import { useDetailsCardsStates } from './Hooks/useDetailsCardsStates';
import { useDetailsCardsCallAPI } from './Hooks/useDetailsCardsCallAPI';
import { useDetailsCardsFunctions } from './Hooks/useDetailsCardsFunctions';
import { useDetailsCardsMemorizations } from './Hooks/useDetailsCardsMemorizations';

import styles from './detailsForm.module.scss';
import { ConnectedFocusError } from '@/Components/ConnectedFocusError';
import { NotificationActions } from '@/Store/Notification/Notification.actions';
import { useDispatch } from 'react-redux';

export const DetailsForm: FC<IDetailsForm> = ({ disabled, toogleDisabled }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { isBackModalOpen, setIsBackModalOpen } = useDetailsCardsStates();

  const { lots, editLot } = useDetailsCardsCallAPI();

  const { initalValues, modalBody, preferences } = useDetailsCardsMemorizations(
    {
      lots,
    }
  );
  const { onSubmit, onOkClick, onCancelClick, onClose } =
    useDetailsCardsFunctions({
      preferences,
      lots,
      editLot,
      disabled,
      toogleDisabled,
      setIsBackModalOpen,
    });

  return (
    <>
      <Form initialValues={initalValues} onSubmit={onSubmit}>
        {(props) => (
          <>
            <InfoCard disabled={disabled} />
            <TechnicalInfoCard disabled={disabled} />
            {!disabled && (
              <div className={styles['footer-button']}>
                <Button
                  type="secondary"
                  children={t('admin.editUserPage.footerButtons.cancel')}
                  onClick={() => {
                    props.resetForm()
                    toogleDisabled(true)
                    dispatch(NotificationActions.setCurrentFormIsDirty(false));
                  }}
                />
                <Button
                  type="primary"
                  children={
                    editLot.isLoading
                      ? t('products.create.saveLoading')
                      : t('products.create.save')
                  }
                  htmlType="submit"
                  loading={editLot.isLoading}
                />
              </div>
            )}
            <ConnectedFocusError />
          </>
        )}
      </Form>
      <Modal
        visible={isBackModalOpen}
        onOkClick={onOkClick}
        onCancelClick={onCancelClick}
        onClose={onClose}
        okButtonName={t('supply.balanceAdjustment.backModal.okButton')}
        title={t('supply.balanceAdjustment.backModal.title')}
        body={modalBody}
      />
    </>
  );
};
