import { useCallback } from 'react';
import { handleModalError } from '@/Utils/ErrorCodeUtils/ErrorHandlingUtils';
import { App } from 'antd';
import { cannotDeleteProductMessagermWithMovementsInTheSystemProps } from '@/Utils/ErrorCodeUtils/ErrorModalUtils/ErrorModalProductMessage';
import { useTranslation } from 'react-i18next';
import { IUseFunctions } from './interfaces';

export const useEditFunctions = ({
  productMessage,
  deleteProductMessage,
  changeVisibleModal,
}: IUseFunctions) => {
  const { t } = useTranslation();
  const app = App.useApp();

  const onDelete = useCallback(async () => {
    try {
      if (!productMessage) return;
      return await deleteProductMessage(
        { mensagemExternalIds: [productMessage.externalId] },
        {
          onError: handleModalError(
            app,
            cannotDeleteProductMessagermWithMovementsInTheSystemProps(t)
          ),
        }
      );
    } catch {
      changeVisibleModal && changeVisibleModal(false);
    }
  }, [deleteProductMessage, productMessage, app, t, changeVisibleModal]);

  return {
    onDelete,
  };
};
