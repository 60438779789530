import { i18n, Modal, Text } from '@/Components';
import { useControllerMutateApiHook } from '@/ControllerApiHook/Controller';
import { SalesOrderAPI } from '@/Data/API/Sales/SalesOrderApi';
import { IGetSaleOrderResponse } from '@/Data/Interfaces/response/Sales/SalesOrder/ISalesOrderResponse';
import {
  FETCH_GET_SALES_ORDER,
  REVERSE_SALES_ORDER
} from '@/ControllerApiHook/UniqIds/Sales/SalesOrderKeys';
import { Divflex } from '@/Layouts/DivWhithFlex';
import { messageErrorToast, messageSucessToast } from '@/Utils/MessageErrorToast';
import { useQueryClient } from '@tanstack/react-query';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import ReactMarkdown from 'react-markdown';

interface IReverseSaleOrderModal {
  visibleModal: boolean;
  changeVisibleModal: (value: boolean) => void;
  saleOrder: IGetSaleOrderResponse;
}

export const ReverseSaleOrderModal: FC<IReverseSaleOrderModal> = ({
  changeVisibleModal,
  visibleModal,
  saleOrder,
}) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  const reverseSaleOrder: any = useControllerMutateApiHook({
    uniqId: REVERSE_SALES_ORDER,
    entityApi: SalesOrderAPI.reverseSaleOrder,
    options: {
      onError: (x) => messageErrorToast(x),
      onSuccess: () =>
        messageSucessToast(
          i18n.t('saleOrder.editOrder.sideRightMenu.messageSuccessToast.reverse')
        )
    }
  });

  return (
    <Modal
      title={`${t('saleOrder.editOrder.sideRightMenu.reverseModal.title')} "#${
        saleOrder.codigoPedido
      }"?`}
      body={
        <>
          <Divflex style={{ padding: '20px' }}>
            <Text type="ui-tiny-content">
              <ReactMarkdown>
                {t(
                  'saleOrder.editOrder.sideRightMenu.reverseModal.description',
                  {
                    codigo: `**#${saleOrder.codigoPedido}**`,
                    cliente: `**${saleOrder.cliente.nome}**`,
                  }
                )}
              </ReactMarkdown>
            </Text>
          </Divflex>
        </>
      }
      visible={visibleModal}
      okButtonName={
        reverseSaleOrder.isLoading
          ? t('common.reverseLoading')
          : t('common.confirmReverse')
      }
      loading={reverseSaleOrder.isLoading}
      okButtonStatus="danger"
      onCancelClick={() => changeVisibleModal(false)}
      onClose={() => changeVisibleModal(false)}
      maxWidth={400}
      htmlType="submit"
      onOkClick={async () => {
        const res = await reverseSaleOrder.mutateAsync({
          pedidoVendaExternalId: saleOrder.externalId,
        });
        if (res.success) {
          queryClient.invalidateQueries([FETCH_GET_SALES_ORDER]);
          changeVisibleModal(false);
        }
      }}
    />
  );
};
