import { useQueryClient } from '@tanstack/react-query';
import {
  useControllerMutateApiHook,
  useControllerQueryListApiHook,
} from '@/ControllerApiHook/Controller';
import {
  DELETE_STOCK_LOCATION,
  EDIT_STATUS_STOCK_LOCATION,
  FETCH_LIST_STOCK_LOCATION,
} from '@/ControllerApiHook/UniqIds/Supply/SupplyKeys';
import { StorageLocationApi } from '@/Data/API/Supply/StorageLocationApi';

export const useListCallAPI = () => {
  const queryClient = useQueryClient();

  const {
    data: supplyPlaceList,
    isLoading: isLoadingSupplyPlaceList,
    refetch: refetchSupplyPlaceList,
  } = useControllerQueryListApiHook({
    uniqId: FETCH_LIST_STOCK_LOCATION,
    entityApi: StorageLocationApi.getStorageLocationList,
  });

  const editSupplyPlaceStatus = useControllerMutateApiHook({
    uniqId: EDIT_STATUS_STOCK_LOCATION,
    entityApi: StorageLocationApi.patchStorageLocationStatus,
    options: {
      onSuccess: () =>
        queryClient.invalidateQueries([FETCH_LIST_STOCK_LOCATION]),
    },
  });

  const deleteSupplyPlace = useControllerMutateApiHook({
    uniqId: DELETE_STOCK_LOCATION,
    entityApi: StorageLocationApi.deleteStorageLocation,
    options: {
      onSuccess: () =>
        queryClient.invalidateQueries([FETCH_LIST_STOCK_LOCATION]),
    },
  });

  return {
    deleteSupplyPlace,
    editSupplyPlaceStatus,
    supplyPlaceList,
    isLoadingSupplyPlaceList,
    refetchSupplyPlaceList,
  };
};
