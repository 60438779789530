import React, { FC, ReactNode } from 'react';
import { Icon } from '..';

import styles from './List.module.scss';

interface IListItem {
  id?: any;
  data?: ReactNode;
}
export interface IList {
  items?: IListItem[];
  onDelete: (value: any) => void;
}

export const List: FC<IList> = ({ items, onDelete }) => {
  return (
    <div
      className={`${
        items
          ? items.findIndex((x) => x.id) < 0
            ? styles['no-items']
            : ''
          : styles['no-items']
      } ${styles['Containers']}`}
    >
      {items &&
        items.map(
          (item) =>
            item.id && (
              <div className={styles['row']} key={item.id}>
                <div className={styles['content']}>{item.data}</div>
                <div className={styles['delete-container']}>
                  <Icon
                    icon="trash"
                    color="text-400"
                    size="ML"
                    onClick={() => onDelete(item.id)}
                    className={styles['icon']}
                  />
                </div>
              </div>
            )
        )}
    </div>
  );
};
