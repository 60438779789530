import { useControllerMutateApiHook } from '@/ControllerApiHook/Controller';
import { VALIDATION_SPECIALTIES_NAME } from '@/ControllerApiHook/UniqIds/People/SpecialtiesKeys';
import { SpecialtiesAPI } from '@/Data/API/People/SpecialtiesApi';
import { useTranslation } from 'react-i18next';
import { IUseCallAPI } from './interfaces';

export const useFormDescriptionBodyCallAPI = ({ form }: IUseCallAPI) => {
  const { t } = useTranslation();

  const { mutateAsync }: any = useControllerMutateApiHook({
    uniqId: VALIDATION_SPECIALTIES_NAME,
    entityApi: SpecialtiesAPI.validationSpecialtiesName,
    options: {
      onError: () =>
        form.setFieldError(
          'descricao',
          t('specialties.create.validationDescription')
        ),
      onSuccess: () => form.setFieldError('descricao', undefined),
    },
  });

  return {
    mutateAsync,
  };
};
