import { useCallback } from 'react';
import { IEditLotRequest } from '@/Data/Interfaces/request/Lot/IPatchLotStatusRequest';
import {
  FETCH_GET_LOT,
  FETCH_LIST_LOTS,
} from '@/ControllerApiHook/UniqIds/Supply/LotKeys';
import { removeDefaultUnitMensures } from '../indexHelpers';
import { useQueryClient } from '@tanstack/react-query';
import DateUtils from '@/Utils/DateUtils';
import { history } from '@/Store';
import { IUseFunctions } from './interfaces';
import { FormikHelpers, FormikValues } from 'formik';

export const useDetailsCardsFunctions = ({
  preferences,
  lots,
  editLot,
  disabled,
  toogleDisabled,
  setIsBackModalOpen,
}: IUseFunctions) => {
  const queryClient = useQueryClient();
  const productClassRawMaterial = 1;

  const onSubmit = useCallback(
    async (values: IEditLotRequest, helpers: FormikHelpers<FormikValues>) => {
      try {
        if (!preferences) return;

        const body: any = {
          externalId: values.externalId,
          numero: values.numero,
          produtoExternalId: values.produtoExternalId,
          fornecedorExternalId: values.fornecedorExternalId,
          dataFabricacao: values.dataFabricacao,
          dataValidade: values.dataValidade,
          unidadeId: values.unidadeId,
          numeroNf: values.numeroNf,
          serieNf: values.serieNf,
          loteInformacaoTecnica: values.loteInformacaoTecnica,
        };

        removeDefaultUnitMensures(body);

        if (body.dataFabricacao) {
          body.dataFabricacao = DateUtils.convertDateToApi(
            body.dataFabricacao,
            preferences.padraoData
          );
        }
        if (body.dataValidade) {
          body.dataValidade = DateUtils.convertDateToApi(
            body.dataValidade,
            preferences.padraoData
          );
        }

        if (lots?.classeProdutoId !== productClassRawMaterial) {
          body.loteInformacaoTecnica = null;
        }

        if (
          body.loteInformacaoTecnica &&
          !body.loteInformacaoTecnica.loteUnidadeAlternativa2
            ?.quantidadeUnidadeAlternativa
        ) {
          body.loteInformacaoTecnica.loteUnidadeAlternativa2 = undefined;
        }
        if (
          body.loteInformacaoTecnica &&
          !body.loteInformacaoTecnica.loteUnidadeAlternativa1
            ?.quantidadeUnidadeAlternativa
        ) {
          body.loteInformacaoTecnica.loteUnidadeAlternativa1 = undefined;
        }

        if (lots) {
          const res = await editLot.mutateAsync(body);

          if (res.success) {
            queryClient.invalidateQueries([FETCH_GET_LOT]);
            queryClient.invalidateQueries([FETCH_LIST_LOTS]);
            toogleDisabled(!disabled);
          }
        }
      } catch (error) {
      } finally {
        helpers.setSubmitting(false);
      }
    },
    [preferences, lots, editLot, toogleDisabled, disabled, queryClient]
  );

  const onOkClick = useCallback(() => history.push('/inventory/lot'), []);

  const onCancelClick = useCallback(
    () => setIsBackModalOpen(false),
    [setIsBackModalOpen]
  );

  const onClose = useCallback(
    () => setIsBackModalOpen(false),
    [setIsBackModalOpen]
  );

  return {
    onSubmit,
    onOkClick,
    onCancelClick,
    onClose,
  };
};
