import { useCallback } from "react";
import { useQueryClient } from '@tanstack/react-query';
import { messageErrorToast } from '@/Utils/MessageErrorToast';
import { history } from '@/Store';
import { App } from 'antd';
import { handleModalError } from '@/Utils/ErrorCodeUtils/ErrorHandlingUtils';
import {
  DELETE_STANDARD_FORMULA
} from '@/ControllerApiHook/UniqIds/Production/StandardFormulaKeys';
import { 
  cannotDeleteStandardFormulaWithMovementsInTheSystemProps 
} from '@/Utils/ErrorCodeUtils/ErrorModalUtils/ErrorModalStandardFormula';
import { IUseFunctions } from "./interfaces";
import { useTranslation } from "react-i18next";

export const useDeleteStandardFormulaModalFunctions = ({
  standardFormula,
  deleteStandardFormula,
  changeVisibleModal
}: IUseFunctions) => {
  const queryClient = useQueryClient();
  const app = App.useApp();
  const { t } = useTranslation();

  const onDelete = useCallback(async () => {
    try {
      if (!standardFormula) return;
      const res = await deleteStandardFormula.mutateAsync(
        { externalId: [standardFormula.externalId] },
        {
          onError: handleModalError(
            app,
            cannotDeleteStandardFormulaWithMovementsInTheSystemProps(t)
          ),
        }
      );

      if (res.success) {
        queryClient.invalidateQueries([DELETE_STANDARD_FORMULA]);
        history.push('/production/standard-formula');
      }
      if (res.error) messageErrorToast(res.error);
    } catch {
      changeVisibleModal(false);
    }
  }, [deleteStandardFormula, queryClient, standardFormula, app]);

  const onCancelClick = () => changeVisibleModal(false);
  
  const onClose = () => changeVisibleModal(false);

  return {
    onDelete,
    onCancelClick,
    onClose
  };
};