import { IListUnitMeasurementByClassData } from '@/Data/Interfaces/response/UnitMeasurement/IUnitMeasurementResponse';
import { IUseMemorizations } from './interfaces';

export const useCreateMemorizations = ({
  listunitsMeasurements,
  isUnitOfMeasureLoading,
  onUnitOfMeasureChange,
  unitMeasureIdDefault,
}: IUseMemorizations) => {
  const unitOfMeasureDropdown = listunitsMeasurements
    ? {
        name: 'unidadeMedidaId',
        loading: isUnitOfMeasureLoading,
        defaultValue: unitMeasureIdDefault,
        options:
          listunitsMeasurements?.data?.map(
            (x: IListUnitMeasurementByClassData) => ({
              id: x.id,
              content: x.unidadeAbreviacao,
            })
          ) || [],
        onChange: onUnitOfMeasureChange,
      }
    : undefined;

  return { unitOfMeasureDropdown };
};
