import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Text } from '@/Components';
import { Modal } from '@/Components/Modal';
import { FooterThisActionIsPermanent } from '@/Components/Table/components/Modals/DeleteModal';
import { Divflex } from '@/Layouts/DivWhithFlex';
import ReactMarkdown from 'react-markdown';
import { IDeleteProductModal } from './interfaces';
import { useDeleteModalCallAPI } from './Hooks/useDeleteModalCallAPI';
import { useDeleteModalFunctions } from './Hooks/useDeleteModalFunctions';

export const DeleteProductModal: FC<IDeleteProductModal> = ({
  product,
  visibleModal,
  changeVisibleModal
}) => {
  const { t } = useTranslation();

  const {
    deleteProduct
  } = useDeleteModalCallAPI();

  const {
    onOkClick,
    onCancelClick,
    onClose
  } = useDeleteModalFunctions({
    product,
    deleteProduct,
    changeVisibleModal
  });

  return (
    <Modal
      title={`${t('common.delete')} ${t('common.product')} "${
        product?.descricao
      }"?`}
      body={
        <div style={{ padding: '20px' }}>
          <Divflex
            style={{
              flexDirection: 'column',
              marginBottom: 15,
              width: '400px',
            }}
          >
            <Text color="text-50" type="ui-tiny-content">
              <ReactMarkdown>
                {t('modal.deleteModal.single.productQuestion', {
                  produto: `**${product?.descricao}**`,
                })}
              </ReactMarkdown>
            </Text>
          </Divflex>
          <FooterThisActionIsPermanent />
        </div>
      }
      okButtonColor="status-danger-base"
      visible={visibleModal}
      onOkClick={onOkClick}
      okButtonName={t('common.delete')}
      onCancelClick={onCancelClick}
      onClose={onClose}
      okButtonType="danger"
      widthModal={420}
    />
  );
};
