import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { IRightIcon, IUseMemorizations } from "./interfaces";

export const useInputProductNameMemorizations = ({
  showIconCopy
}: IUseMemorizations) => {
  const { t } = useTranslation();

  const rightIcon: IRightIcon = useMemo(() => (
    !!showIconCopy
    ? {
        icon: 'copy',
        copyName: 'descricao',
        toolTipTitle: [
          `${t('products.toolTips.products.copyName')}`,
          t('common.copied'),
        ],
        copyValueForInput: 'descricaoRotulo',
      }
    : {}
  ), [showIconCopy]);

  return {
    rightIcon
  };
};