import { IChangeProductStatusRequest } from '@/Data/Interfaces/request/Product/IProductRequest';
import {
  DELETE_PRODUCT,
  EDIT_STATUS_PRODUCT,
  FETCH_LIST_PRODUCT,
} from '@/ControllerApiHook/UniqIds/Supply/ProductKeys';
import { useQueryClient } from '@tanstack/react-query';
import {
  useControllerMutateApiHook,
  useControllerQueryListApiHook,
} from '@/ControllerApiHook/Controller';
import { ProductAPI } from '@/Data/API/Supply/Product';

export const useListCallAPI = () => {
  const queryClient = useQueryClient();

  const deleteProduct: any = useControllerMutateApiHook({
    uniqId: DELETE_PRODUCT,
    entityApi: ProductAPI.deleteProduct,
  });

  const editProductStatus: any = useControllerMutateApiHook({
    uniqId: EDIT_STATUS_PRODUCT,
    entityApi: ProductAPI.productStatus,
  });

  const {
    data: productList,
    isLoading,
    refetch: refetchProductList,
  }: any = useControllerQueryListApiHook({
    uniqId: FETCH_LIST_PRODUCT,
    entityApi: ProductAPI.productList,
    pagination: {
      sorter: { column: 'descricao', direction: 'ASC' },
    },
    autoIncrement: true,
  });

  const patchProductStatus = async (values: IChangeProductStatusRequest) => {
    var body: IChangeProductStatusRequest = { ...values };

    const res = await editProductStatus.mutateAsync({
      ...body,
    });

    if (res.success) queryClient.invalidateQueries([FETCH_LIST_PRODUCT]);
  };

  return {
    deleteProduct,
    editProductStatus,
    productList,
    isLoading,
    patchProductStatus,
    refetchProductList,
  };
};
