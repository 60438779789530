import { useMemo } from "react";
import { useUserGroupNameFieldCallAPI } from "./useUserGroupNameFieldCallAPI";
import _ from "lodash";

export const useUserGroupNameFieldMemorizations = () => {
  const { getThereIsUserGroupName } = useUserGroupNameFieldCallAPI();

  const thereIsUserGroupName = useMemo(
    () =>
      _.debounce(
        async (value: string) => await getThereIsUserGroupName(value),

        600
      ),
    [getThereIsUserGroupName]
  );

  return { thereIsUserGroupName };
};