import { useTranslation } from 'react-i18next';
import { history } from '@/Store';

export const useListMemorizations = () => {
  const { t } = useTranslation();

  const headerOptions = {
    nameKey: 'descricao',
    hasSearch: true,
    searchPlaceholder: t('products.productList.searchPlaceholder'),
    editButton: {
      permission: {
        permission: 2011,
        tooltip: 'noPermission.supply.supplyPlaces.statusEdit',
      },
      options: [
        {
          label: t('supply.supplyPlaces.edit.status'),
          isStatusOnly: true,
        },
        {
          label: t('supply.supplyPlaces.edit.company'),
        },
      ],
    },
    deleteButton: {
      permission: {
        permission: 2012,
        tooltip: 'noPermission.supply.supplyPlaces.delete',
      },
    },
  };

  const rowOptions = {
    onRowDoubleClick: (x: any) =>
      history.push(`/inventory/products/${x.externalId}/details`),
    permission: {
      permission: 3002,
      tooltip: 'noPermission.supply.products.detail',
    },
  };

  return {
    headerOptions,
    rowOptions,
  };
};
