import { Card } from '../../../../../../../../../Components';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ProductRow, ProductRowTitles } from './rows';
import FormBreakLine from '../../../../../../../../../Components/FormBreakLine';

interface IProductSection {
  products: any[];
}

export const ProductsSection: React.FC<IProductSection> = ({ products }) => {
  const { t } = useTranslation();

  return (
    <Card withoutCollapse title={t('purchasing.invoice.create.products')}>
      <ProductRowTitles />
      <FormBreakLine />
      {products.map((e, i) => {
        return <ProductRow product={e} key={i} />;
      })}
    </Card>
  );
};
