import { useMemo } from 'react';
import { Text } from '@/Components';
import { FooterThisActionIsPermanent } from '@/Components/Table/components/Modals/DeleteModal';
import { Divflex } from '@/Layouts/DivWhithFlex';
import ReactMarkdown from 'react-markdown';
import { useTranslation } from 'react-i18next';
import { IUseMemorizations } from './interfaces';

export const useDeleteModalMemorizations = ({
  prescriberName,
}: IUseMemorizations) => {
  const { t } = useTranslation();

  const modalBody = useMemo(
    () => (
      <div style={{ padding: '20px', maxWidth: '400px' }}>
        <Divflex>
          <Text color="text-50" type="ui-tiny-content">
            <ReactMarkdown>
              {t('prescribers.editPage.deleteModal.description', {
                prescriber: `**${prescriberName}**`,
              })}
            </ReactMarkdown>
          </Text>
        </Divflex>
        <FooterThisActionIsPermanent />
      </div>
    ),
    [t, prescriberName]
  );

  return {
    modalBody,
  };
};
