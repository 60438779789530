export const useCompanyCreateMemorizations = () => {
  const orderedErrors = [
    "cnpj",
    "razaoSocial",
    "nomeFantasia",
    "inscricaoEstadual", 
    "cnae",
    "endereco\\.cep",
    "endereco\\.logradouro",
    "endereco\\.numero",
    "endereco\\.bairro",
    "endereco\\.cidade",
    "endereco\\.estado",
    "nomeConglomerado",
    "usuario\\.email",
    "usuario\\.nome",
    "usuario\\.sobrenome",
    "usuario\\.cpf"
  ];

  return {
    orderedErrors
  };
};