import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Tabs } from '../../Components/Tabs';
import { Currency } from './Currency';
import Personalization from './Personalization';

import styles from './SystemConfig.module.scss';

export const SystemConfig: FC = () => {
  const { t } = useTranslation();

  return (
    <main className={styles['container']}>
      <Tabs
        items={[
          {
            label: t('systemConfig.personalization'),
            key: 'personalization',
            children: <Personalization />,
          },
          {
            label: t('systemConfig.currency'),
            key: 'currency',
            children: <Currency />,
          },
        ]}
      />
    </main>
  );
};
