import * as yup from 'yup';
import i18n from '../../../../../../../Components/i18n';

export const StandardFormulaDetailSchema = yup.object().shape({
  quantidadePadrao: yup
    .string()
    .required(
      i18n.t('production.standardFormula.details.errors.requiredAmount')
    ),
  formulaPadraoDesmembramento: yup
    .string()
    .required(
      i18n.t(
        'production.standardFormula.details.errors.requiredDismembermentType'
      )
    ),
  produtos: yup.array().of(
    yup.object().shape({
      tipoItem: yup
        .string()
        .required(
          i18n.t(
            'production.standardFormula.details.errors.requiredProductType'
          )
        ),
      quantidade: yup
        .string()
        .required(
          i18n.t(
            'production.standardFormula.details.errors.requiredAmountProduct'
          )
        ),
    })
  ),
});
