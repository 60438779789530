import { IGetStorageLocationDropDownResponse } from '@/Data/Interfaces/response/StorageLocation/IStorageLocationResponse';
import { useState } from 'react';

export const useCreateStates = () => {
  const [storageLocations, setStorageLocations] = useState<
    IGetStorageLocationDropDownResponse[]
  >([]);

  return {
    storageLocations,
    setStorageLocations,
  };
};
