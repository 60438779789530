import { useCallback } from 'react';
import { IUseDeleteUserModalFunctions } from './interfaces';

export const useDeleteUserFunctions = ({
  userExternalId,
  deleteUser,
}: IUseDeleteUserModalFunctions) => {
  const onDelete = useCallback(async () => {
    await deleteUser.mutateAsync([userExternalId]);
  }, [deleteUser, userExternalId]);

  return {
    onDelete,
  };
};
