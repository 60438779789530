import { Table } from '@/Components';
import { PageLoading } from '@/Layouts/PageLoading';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { productIncompatibleColumns } from './columns';
import { useParams } from 'react-router';
import { useIncompatibleProductStates } from './Hooks/useIncompatibleProductStates';
import { useIncompatibleProductCallAPI } from './Hooks/useIncompatibleProductCallAPI';
import { useIncompatibleProductMemorizations } from './Hooks/useIncompatibleProductMemorizations';
import { useIncompatibleProductFunctions } from './Hooks/useIncompatibleProductFunctions';

export const IncompatibleProductTab: FC = () => {
  const { t } = useTranslation();
  const { externalId: productExternalId } = useParams<{ externalId: string }>();

  const { selectedRowsOnTable, setSelectedRowsOnTable } =
    useIncompatibleProductStates();

  const {
    currentProduct,
    productList,
    isProductListLoading,
    deleteProductIncompatible,
    refetchProductList,
  } = useIncompatibleProductCallAPI({
    productExternalId,
  });

  const { headerOptions, deleteModal, rowsOptions } =
    useIncompatibleProductMemorizations({
      productExternalId,
      currentProduct,
      selectedRowsOnTable,
    });

  const { onDelete, selectRowsOnTable } = useIncompatibleProductFunctions({
    deleteProductIncompatible,
    setSelectedRowsOnTable,
  });

  return (
    <PageLoading loading={isProductListLoading}>
      <Table
        loading={isProductListLoading}
        columns={productIncompatibleColumns(t)}
        hasSelection
        onDelete={onDelete}
        headerOptions={headerOptions}
        pagination={productList}
        onRefetch={refetchProductList}
        selectedRowsOnTable={selectRowsOnTable}
        deleteModal={deleteModal}
        rowsOptions={rowsOptions}
      />
    </PageLoading>
  );
};
