import { useTranslation } from 'react-i18next';
import { v4 as uuidV4 } from 'uuid';
import { Card } from '../../../../../../../../Components';
import PackingClassificationRow from './Rows';
import { useCreateStates } from './Hooks/useCreateStates';
import { useCreateFunctions } from './Hooks/useCreateFunctions';

export const PackagingsCard = () => {
  const { t } = useTranslation();

  const { packingClassificationRowAmount, setPackingClassificationRowAmount } =
    useCreateStates();

  const { removeRow } = useCreateFunctions({
    setPackingClassificationRowAmount,
  });

  return (
    <Card title={t('common.packings')} withoutCollapse>
      {packingClassificationRowAmount.map((uuid, index) => (
        <PackingClassificationRow
          totalRows={packingClassificationRowAmount.length}
          setProductRowAmount={setPackingClassificationRowAmount}
          index={index}
          key={uuid}
          onDelete={removeRow}
          uuidV4={uuidV4}
        />
      ))}
    </Card>
  );
};
