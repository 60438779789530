import { ManyAvatars } from '@/Components/ManyAvatars';
import { Text } from '@/Components';
import {
  IListUserData,
  IUserGroup,
} from '@/Data/Interfaces/response/User/IGetUserReponse';
import ReactMarkdown from 'react-markdown';

export const deleteModalTitle = (text: IListUserData[], t: any) => {
  return text.length === 1
    ? `${t('common.remove')} "${text[0]?.nome} ${text[0]?.sobrenome}"?`
    : `${t('common.remove')} ${text.length} ${t(
        'admin.userList.deleteUserModal.title.users'
      )}?`;
};

export const deleteModalBody = (
  text: IListUserData[],
  t: any,
  group: IUserGroup
) => (
  <div style={{ padding: 20 }}>
    <ManyAvatars
      users={text.map((userGroup) => ({
        name: userGroup.nome,
      }))}
    ></ManyAvatars>
    <Text color="text-50" type="paragraph2">
      <ReactMarkdown>
        {text.length !== 1
          ? t('admin.editUserGroups.deleteModalBody.deleteUser', {
              userName: `**${text.length} ${t('common.users')}**`,
              financeName: `**${group.nome}**`,
            })
          : t('admin.editUserGroups.deleteModalBody.deleteUser', {
              userName: `**${text[0]?.nome} ${text[0]?.sobrenome}**`,
              financeName: `**${group.nome}**`,
            })}
      </ReactMarkdown>
    </Text>
  </div>
);
