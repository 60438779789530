import { useMemo } from 'react';
import { IUsePermissonFormMemorizations } from './interfaces';

export const usePermissonFormMemorizations = ({
  user,
  transformModule,
}: IUsePermissonFormMemorizations) => {
  const modules = useMemo(
    () => user?.modulos.map((x) => transformModule(x)),
    [user, transformModule]
  );

  return { modules };
};
