import { IUseFunctions } from './interfaces';
import { useCallback } from 'react';
import { handleModalError } from '@/Utils/ErrorCodeUtils/ErrorHandlingUtils';
import { App } from 'antd';
import { IEditSupplierInfoRequest } from '@/Data/Interfaces/request/Purchasing/Providers/IProviderRequest';
import { TypePersonEnum } from '@/Data/Interfaces/model/typePersonEnum';

export const useEditFunctions = ({
  changeVisibleModal,
  supplier,
  deleteSupplier,
  editSupplierInfo,
}: IUseFunctions) => {
  const app = App.useApp();

  const onDelete = useCallback(async () => {
    try {
      changeVisibleModal && changeVisibleModal(false);
      if (supplier) {
        return await deleteSupplier!(
          {
            externalId: [supplier?.fornecedorExternalId] as any,
          },
          {
            onError: handleModalError(app),
          }
        );
      }
    } catch {}
  }, [deleteSupplier, supplier]);

  const onEditInfoSupplier = async (values: IEditSupplierInfoRequest) => {
    if (supplier?.tipoPessoa === TypePersonEnum.juridical_person)
      var body: any = {
        cnpj: values.cnpj,
        nome: values.nome,
        razaoSocial: values.razaoSocial,
        tipoFornecedorId: values.tipoFornecedorId,
        externalId: supplier.fornecedorExternalId,
        observacao: values.observacao,
        tipoPessoa: supplier.tipoPessoa,
      };
    else {
      var body: any = {
        cpf: values.cpf,
        nome: values.nome,
        tipoFornecedorId: values.tipoFornecedorId,
        externalId: supplier?.fornecedorExternalId,
        observacao: values.observacao,
        tipoPessoa: supplier?.tipoPessoa,
      };
    }
    return await editSupplierInfo!({
      ...body,
    });
  };

  return {
    onDelete,
    onEditInfoSupplier,
  };
};
