import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Tooltip
} from '@/Components';
import { useFormikContext } from 'formik';
import { TextInputNumber } from '@/Components/Inputs/TextInputNumber';
import { IValidity } from "./interfaces";

export const Validity: FC<IValidity> = ({
  standardFormula,
  disabled
}) => {
  const { t } = useTranslation();
  const form: any = useFormikContext();

  return (
    <Tooltip
      title={`${t(
        'production.standardFormula.details.tooltips.previousValidity'
      )} ${standardFormula?.diasValidade}  ${t('common.days')}`}
      targetOffset={[0, 0]}
      showMe={
        !disabled &&
        standardFormula?.diasValidade !== form.values.diasValidade
      }
    >
      <TextInputNumber
        name="diasValidade"
        placeHolder={t(
          'production.standardFormula.details.validityPlaceholder'
        )}
        disabled={disabled}
        label={t('production.standardFormula.details.validity')}
        withTooltip={{
          icon: 'exclamation-upside-down',
          title: t(
            'production.standardFormula.details.toolTips.validyMessage'
          ),
        }}
        withStatusIcon
      />
    </Tooltip>
  );
};