import { useTranslation } from 'react-i18next';
import { FC, useMemo } from 'react';
import { StatusSaleOrderType } from '@/Utils/StatusUtils';
import { IGetSaleOrderResponse } from '@/Data/Interfaces/response/Sales/SalesOrder/ISalesOrderResponse';
import { StatusHeader } from './Components/StatusHeader';
import { useSideRightMenuStates } from './Hooks/useSideRightMenuStates';
import { ContentBody } from './Components/ContentBody';
import { StatusSalesOrderEnum } from './Components/StatusHeader/Components/ActionButtons';

import styles from './sideRightMenu.module.scss';

interface ISideRightMenu {
  saleOrder: IGetSaleOrderResponse;
  patchSaleOrder: _.DebouncedFunc<
    (value: any, propName: string) => Promise<any>
  >;
  patchSaleOrderLoading: boolean;
  patchSaleOrderSuccess: boolean;
  patchVariables: string;
}

export const SideRightMenu: FC<ISideRightMenu> = ({
  saleOrder,
  patchSaleOrder,
  patchSaleOrderLoading,
  patchSaleOrderSuccess,
  patchVariables,
}) => {
  const { t } = useTranslation();

  const statusSalesOrder = useMemo(
    () => saleOrder?.statusPedido,
    [saleOrder?.statusPedido]
  );

  const noItemsSelected = saleOrder.pedidoVendaItens.every(
    (item) => item.selecionado === false
  );

  const {
    approveSaleOrderModal,
    cancelSaleOrderModal,
    reproveSaleOrderModal,
    reverseSaleOrderModal,
    setApproveSaleOrderModal,
    setCancelSaleOrderModal,
    setReproveSaleOrderModal,
    setReverseSaleOrderModal,
  } = useSideRightMenuStates();

  return (
    <>
      <div
        id="container-side-right-menu-sale-order"
        className={styles['content-side-right-menu']}
        style={{ height: '100%' }}
      >
        <StatusHeader
          typePurchase={statusSalesOrder}
          onOkClick={() =>
            statusSalesOrder === StatusSalesOrderEnum.Orcado &&
            setApproveSaleOrderModal(true)
          }
          onCancelClick={() =>
            statusSalesOrder === StatusSalesOrderEnum.Orcado
              ? setReproveSaleOrderModal(true)
              : setReverseSaleOrderModal(true)
          }
          onClickDropdownItem={() =>
            statusSalesOrder === StatusSalesOrderEnum.Orcado &&
            setCancelSaleOrderModal(true)
          }
          disabledButton={noItemsSelected}
        >
          {StatusSaleOrderType({ statusId: statusSalesOrder, t }).children}
        </StatusHeader>
        <ContentBody
          patchSaleOrder={patchSaleOrder}
          patchSaleOrderLoading={patchSaleOrderLoading}
          patchSaleOrderSuccess={patchSaleOrderSuccess}
          patchVariables={patchVariables}
          saleOrder={saleOrder}
          approveSaleOrderModal={approveSaleOrderModal}
          cancelSaleOrderModal={cancelSaleOrderModal}
          reproveSaleOrderModal={reproveSaleOrderModal}
          reverseSaleOrderModal={reverseSaleOrderModal}
          setApproveSaleOrderModal={setApproveSaleOrderModal}
          setCancelSaleOrderModal={setCancelSaleOrderModal}
          setReproveSaleOrderModal={setReproveSaleOrderModal}
          setReverseSaleOrderModal={setReverseSaleOrderModal}
        />
      </div>
    </>
  );
};
