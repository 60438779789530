import { FETCH_GET_PRODUCT } from '@/ControllerApiHook/UniqIds/Supply/ProductKeys';
import { useFormikContext } from 'formik';
import { IProductResponse } from '@/Data/Interfaces/response/Product/IProductRespose';
import { ProductAPI } from '@/Data/API/Supply/Product';
import { useControllerQueryApiHook } from '@/ControllerApiHook/Controller';

export const useStandardFormulaDetailsCallAPI = () => {
  const form: any = useFormikContext();

  const { data: unitsMeasurementsDefault } =
    useControllerQueryApiHook<IProductResponse>({
      uniqId: FETCH_GET_PRODUCT,
      entityApi: ProductAPI.getProduct,
      externalIds: [form?.values?.produtoExternalId],
    });

  return {
    unitsMeasurementsDefault,
  };
};
