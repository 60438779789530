import React, { FC } from 'react';
import { Pagination as AntdPagination, Select } from 'antd';
import { useTranslation } from 'react-i18next';
import { ISorter } from '../..';
import { Text } from '@/Components';

import styles from './Pagination.module.scss';
import './Pagination.override.scss';

export interface IPaginationInput extends IPagination {
  search?: string;
  count: number;
}

export interface IPagination {
  search?: string;
  sorter?: ISorter;
  pageSize: number;
  pageIndex: number;
  count?: number;
  data?: [];
  incrementing?: any[];
}

export interface ITablePagination {
  loading: boolean;

  search?: string;
  count: number;
  pageSize: number;
  pageIndex: number;
  sorter?: ISorter;

  onRefetch?: (pagination: IPagination) => void;
  hideItemsPerPage?: boolean;
  alignPagination?: 'left' | 'center' | 'right' | '';
}

export const Pagination: FC<ITablePagination> = ({
  loading,
  pageSize,
  pageIndex,
  count,
  search,
  sorter,
  onRefetch,
  hideItemsPerPage = false,
  alignPagination = '',
}) => {
  const { t } = useTranslation();
  return (
    <div className={`${styles['Pagination']} ${styles[alignPagination]}`}>
      {!hideItemsPerPage && (
        <div className={styles['start']}>
          <Text
            className={styles['page-size-text']}
            color="text-50"
            type="ui-tiny-content"
            children={t('table.pagination.itemsPerPage')}
          />

          <Select
            value={pageSize}
            onChange={(value) => {
              onRefetch &&
                onRefetch({
                  sorter: sorter,
                  search: search,
                  pageIndex: 0,
                  pageSize: value,
                });
            }}
            showArrow={false}
            className={styles['select-size']}
          >
            {[10, 20, 50, 100].map((x, index) => (
              <Select.Option key={`pageSize_${index}`} value={x}>
                {x}
              </Select.Option>
            ))}
          </Select>

          {!loading ? (
            <Text
              type="ui-tiny-content"
              color="text-50"
              className={styles['count']}
            >
              | {count} {count > 1 ? t('common.items') : t('common.item')}
            </Text>
          ) : null}
        </div>
      )}

      <div className={styles['end']}>
        {count > 0 ? (
          <AntdPagination
            data-testid="table-pagination"
            total={count}
            current={pageIndex}
            pageSize={pageSize}
            showTitle={false}
            onChange={(page) =>
              onRefetch &&
              onRefetch({
                search: search,
                pageIndex: page,
                pageSize: pageSize,
                sorter: sorter,
              })
            }
          />
        ) : null}
      </div>
    </div>
  );
};
