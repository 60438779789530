import { InvoiceRoutes } from './Invoice';
import { PurchaseRoutes } from './Purchase';
import { SupplierRoutes } from './Supplier';

export const OrderRoutes = () => (
  <>
    <SupplierRoutes />
    <PurchaseRoutes />
    <InvoiceRoutes />
  </>
);
