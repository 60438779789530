import { FC } from 'react';
import { IInputDescriptionGroup } from './interfaces';
import { useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';
import { TextInput, Tooltip } from '@/Components';
import _ from 'lodash';
import { useInputDescriptionGroupCallAPI } from './Hooks/useInputDescriptionGroupCallAPI';
import { useInputDescriptionGroupFunctions } from './Hooks/useInputDescriptionGroupFunctions';

import styles from '../../DetailsForm.module.scss';

export const InputDescriptionGroup: FC<IInputDescriptionGroup> = ({
  disabled,
  initialDescription,
}) => {
  const form: any = useFormikContext();
  const { t } = useTranslation();

  const { mutateAsync, data } = useInputDescriptionGroupCallAPI({ form });

  const { onChange } = useInputDescriptionGroupFunctions({
    mutateAsync,
    initialDescription,
  });

  return (
    <Tooltip
      title={`${t('products.toolTips.groups.nameOfGroup')} ${data?.descricao}`}
      showMe={data?.descricao !== form?.values?.descricao && !disabled}
      placement={'topLeft'}
    >
      <TextInput
        className={styles['text-input']}
        name="descricao"
        disabled={disabled}
        withoutMarginBottom={disabled}
        label={t('products.groupList.modal.label')}
        onChange={onChange}
        withStatusIcon
        maxLength={50}
        dataTestId="group-description"
      />
    </Tooltip>
  );
};
