import { ICreateLaboratory } from '@/Data/Interfaces/request/Laboratory/ILaboratoryRequest';
import { IUseFunctions } from './interfaces';

export const useCreateFunctions = ({ createLaboratory }: IUseFunctions) => {
  const onSubmit = async (values: ICreateLaboratory) => {
    var body: ICreateLaboratory = { ...values };
 
    return await createLaboratory({
      nomeLaboratorio: body.nomeLaboratorio,
      empresaExternalId: body.empresaExternalId,
      localEstoqueExternalId: body.localEstoqueExternalId,
    });
  };
  return {
    onSubmit,
  };
};
