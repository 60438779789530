import { TFunction } from 'react-i18next';
import { ICannotDeleteModalInfo } from '../ErrorHandlingUtils';

export const cannotDeleteProviderWithMovementsInTheSystemProps = (
  t: TFunction<'translation', undefined>
): ICannotDeleteModalInfo => ({
  formName: 'nome',
  oneErrorModal: {
    titleModal: `${t('common.modalCannotDelete.messageTitle')} ${t(
      'purchasing.provider.modal.cannotDelete.title'
    )}`,
    subTitleModal: `${t('common.modalCannotDelete.canNotDelete')} ${t(
      'purchasing.provider.modal.cannotDelete.title'
    )}`,
    reasonTranslation: t('purchasing.provider.modal.cannotDelete.description'),
    warningModal: t('purchasing.provider.modal.cannotDelete.warningFooter'),
  },
  manyErrorsModal: {
    entity: `${t('common.modalCannotDelete.messageTitle')}`,
    complement: `${t('purchasing.provider.modal.cannotDelete.batchTitle')}`,

    subTitleModal: t('purchasing.provider.modal.cannotDelete.batchSubTitle'),
    reasonTranslation: t(
      'purchasing.provider.modal.cannotDelete.batchDescription'
    ),
    warningModal: t(
      'purchasing.provider.modal.cannotDelete.batchWarningFooter'
    ),
  },
});
