import { useCallback } from 'react';
import { handleModalError } from '@/Utils/ErrorCodeUtils/ErrorHandlingUtils';
import { App } from 'antd';
import { IUseFunctions } from './interfaces';
import { IStorageLocationListData } from '@/Data/Interfaces/response/StorageLocation/IStorageLocationResponse';
import { IPatchStorageLocationStatusRequest } from '@/Data/Interfaces/request/StorageLocation/IStorageLocationRequest';

export const useListFunctions = ({
  setSelectedRowsOnTable,
  editSupplyPlaceStatus,
  deleteSupplyPlace,
}: IUseFunctions) => {
  const app = App.useApp();

  const onSelectedRowsOnTable = useCallback(
    (x: IStorageLocationListData[]) => setSelectedRowsOnTable(x),
    [setSelectedRowsOnTable]
  );

  const changeSupplyPlaceStatus = useCallback(
    async (values: IPatchStorageLocationStatusRequest) => {
      var body: IPatchStorageLocationStatusRequest = { ...values };
      return await editSupplyPlaceStatus.mutateAsync({
        ...body,
      });
    },
    [editSupplyPlaceStatus]
  );

  const onEdit = useCallback(
    (supplyPlace: IStorageLocationListData[], values: any) =>
      changeSupplyPlaceStatus({
        externalId: supplyPlace.map(
          (x: IStorageLocationListData) => x.externalId
        ),
        ativo: values.status === 'success',
      }),
    [changeSupplyPlaceStatus]
  );

  const onDelete = useCallback(
    async (supplyPlace: IStorageLocationListData[]) => {
      await deleteSupplyPlace.mutateAsync(
        {
          locaisEstoqueExternalId: supplyPlace.map((x) => x?.externalId),
        },
        {
          onError: handleModalError(app),
        }
      );
    },
    [deleteSupplyPlace, app]
  );

  return {
    onSelectedRowsOnTable,
    onEdit,
    onDelete,
  };
};
