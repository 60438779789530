import { useFormikContext } from "formik";
import { useCallback } from "react";

export const useDilutionFunctions = () => {
  const form: any = useFormikContext();

  const onChange = useCallback((e: any) => {
    if (!e) {
      return form.setFieldValue(
        'informacaoTecnica.fatorDiluicaoFornecedor',
        ''
      );
    }
    const numericValue = +e.replace('%', '').replace(',', '.');
    const supplierDilutionFacto = 100 / numericValue;
    form.setFieldValue(
      'informacaoTecnica.fatorDiluicaoFornecedor',
      supplierDilutionFacto.toFixed(2)
    );
  }, [form]);

  return {
    onChange
  };
};