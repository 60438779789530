import { FC } from "react";
import { Col } from 'antd';
import { useTranslation } from 'react-i18next';
import { Dropdown } from "@/Components";

export const Relationship: FC = () => {
  const { t } = useTranslation();

  return (
    <Col span={5}>
      <Dropdown
        name="tipoRelacaoQuantidade"
        items={[
          { id: 1, label: t('products.associated.quantityProduct') },
          { id: 2, label: t('products.associated.quantityForm') },
        ]}
        label={t('products.associated.quantityRelationType')}
        placeHolder={t('products.associated.selectReletionShip')}
        withTooltip={{
          icon: 'exclamation-upside-down',
          title: t('products.associated.relationShipTooltip'),
        }}
      />
    </Col>
  );
};