import { FC } from 'react';
import { Col } from 'antd';
import { Label, Text } from '@/Components';
import { IFormulaDescription } from './interfaces';
import { useTranslation } from 'react-i18next';

export const FormulaDescription: FC<IFormulaDescription> = ({
  prescriber,
  datePattern,
}) => {
  const { t } = useTranslation();

  return (
    <Col span={12}>
      <Label
        type="ui-tiny-content"
        color="text-200"
        children={t('prescribers.editPage.descFormulas')}
        withBottomMargin
      />
      <Text
        type="ui-tiny-content"
        color="text-50"
        children={`${
          prescriber?.descontoFormulas
            ? `${prescriber.descontoFormulas?.toLocaleString(
                datePattern?.idioma,
                {
                  style: 'decimal',
                  minimumFractionDigits: 2,
                }
              )}%`
            : '0'
        }`}
      />
    </Col>
  );
};
