import { FC } from "react";
import { Col } from 'antd';
import { useTranslation } from "react-i18next";
import { TextInputPhoneMask } from '@/Components/Inputs/TextInputPhoneMask';
import { IPhone } from "./interfaces";
import { usePhoneFunctions } from "./Hooks/usePhoneFunctions";

export const Phone: FC<IPhone> = ({ 
  createClientModalI18n
 }) => {
  const { t } = useTranslation();

  const { onChange } = usePhoneFunctions();

  return (
    <Col flex="40%">
      <TextInputPhoneMask
        name="phone"
        label={t(`${createClientModalI18n}.cellphone`)}
        placeHolder={`(DDD) + ${t(`${createClientModalI18n}.cellphone`)}`}
        onChange={onChange}
      />
    </Col>
  );
};