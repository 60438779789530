import { FC } from 'react';
import { Modal } from '@/Components';
import { useTranslation } from 'react-i18next';
import { IGetSaleOrderResponse } from '@/Data/Interfaces/response/Sales/SalesOrder/ISalesOrderResponse';
import { CreateDeliveryModalBody } from './Body';
import { useCreateAddressCallAPI } from './Hooks/useCreateAddressCallAPI';
import { useCreateAddressFunctions } from './Hooks/useCreateAddressFunctions';
import { FormikHelpers, FormikValues } from 'formik';

interface IEditDeliveryModal {
  visibleModal: boolean;
  changeVisibleModal: (value: boolean) => void;
  saleOrder: IGetSaleOrderResponse;
  updateDeliveryForHome: (
    x?: any,
    formik?: FormikHelpers<FormikValues>
  ) => Promise<void>;
}

export const CreateDeliveryModal: FC<IEditDeliveryModal> = ({
  changeVisibleModal,
  visibleModal,
  saleOrder,
  updateDeliveryForHome
}) => {
  const { t } = useTranslation();

  const { mutateAsync, isLoading } = useCreateAddressCallAPI({
    changeVisibleModal,
    updateDeliveryForHome
  });

  const { onOkClickForm } = useCreateAddressFunctions({
    mutateAsync,
    saleOrder,
  });

  return (
    <Modal
      title={t('common.newAddress')}
      body={<CreateDeliveryModalBody />}
      visible={visibleModal}
      okButtonName={isLoading ? t('common.adding') : t('common.add')}
      onCancelClick={() => changeVisibleModal(false)}
      onClose={() => changeVisibleModal(false)}
      minWidth={800}
      withForm
      disabledButtonFormNames={['descricao']}
      onOkClickForm={onOkClickForm}
      loading={isLoading}
      htmlType="submit"
    />
  );
};
