import { useMemo } from "react";
import { ModalBody } from '../Components/ModalBody';
import { IUseMemorizations } from "./interfaces";

export const useFormGroupModalMemorizations = ({
  selectedRowsOnTable,
  setProductGroupAlreadyRegistred
}: IUseMemorizations) => {
  const body = useMemo(() => (
    <ModalBody
      isProductGroupAlreadyRegistred={(x: boolean) =>
        setProductGroupAlreadyRegistred(x)
      }
    />

  ), [setProductGroupAlreadyRegistred]);

  const initialValues = useMemo(() => (
    {
      descricao: selectedRowsOnTable && selectedRowsOnTable[0]?.descricao,
    }
  ), [selectedRowsOnTable]);

  return {
    body,
    initialValues
  };
};