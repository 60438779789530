import { FETCH_LIST_CLASS_TYPE } from '@/ControllerApiHook/UniqIds/Supply/ClassTypeKeys';
import { useControllerQueryListApiHook } from '@/ControllerApiHook/Controller';
import { ClassAPI } from '@/Data/API/Supply/ClassApi';

export const useClassCallAPI = () => {
  const { data: listClassTypes, isLoading: classTypesLoading } =
    useControllerQueryListApiHook({
      entityApi: ClassAPI.listClassType,
      uniqId: FETCH_LIST_CLASS_TYPE,
      autoIncrement: true,
    });

  return {
    listClassTypes,
    classTypesLoading,
  };
};
