import { FC } from 'react';
import { Button, Form } from '@/Components';
import { Col, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { TotalPurchase } from './Cards/TotalCard';
import { PurchaseOrderEditSchema } from './PurchaseOrderEdit.schema';
import { useEditOrderCallAPI } from './Hooks/useEditOrderCallAPI';
import { useEditOrderFunctions } from './Hooks/useEditOrderFunctions';
import { PurchaseOrderInfoEditCard } from './Cards/InfoCard';
import { useEditOrderMemorizations } from './Hooks/useEditOrderMemorizations';
import { PurchaseOrderProductEditCard } from './Cards/ProductCard';

import styles from './EditOrder.module.scss';

export const EditPurchase: FC = () => {
  const { t } = useTranslation();
  const { externalId } = useParams<{ externalId: string }>();

  const { purchaseOrder, editOrderPurchase } = useEditOrderCallAPI({
    externalId,
  });

  const { onSubmit, onClick } = useEditOrderFunctions({
    editOrderPurchase,
    externalId,
  });

  const { initialValues } = useEditOrderMemorizations({
    purchaseOrder,
  });

  return (
    <Form
      onSubmit={onSubmit}
      schema={PurchaseOrderEditSchema}
      initialValues={initialValues}
    >
      {(values) => (
        <>
          <PurchaseOrderInfoEditCard />
          <Row
            justify="space-between"
            style={{ marginBottom: '16px' }}
            gutter={[16, 0]}
          >
            <Col span={18}>
              <PurchaseOrderProductEditCard />
              <div className={styles['footer-button']}>
                <Button
                  type="secondary"
                  style={{ marginRight: 8 }}
                  children={t('common.cancel')}
                  onClick={onClick}
                />
                <Button
                  type="primary"
                  children={
                    editOrderPurchase.isLoading
                      ? t('common.saving')
                      : t('common.save')
                  }
                  htmlType="submit"
                  disabled={values.values.pedidoCompraItens?.length === 1}
                />
              </div>
            </Col>
            <Col span={6}>
              <TotalPurchase />
            </Col>
          </Row>
        </>
      )}
    </Form>
  );
};
