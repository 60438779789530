import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { InputWithSearch } from '@/Components';
import { IProduct } from './interfaces';
import { useProductCallAPI } from './Hooks/useProductCallAPI';
import { useProductMemorizations } from './Hooks/useProductMemorizations';
import { useProductFunctions } from './Hooks/useProductFunctions';

export const Product: FC<IProduct> = ({
  index,
  totalRows,
  setProductRowAmount,
}) => {
  const { t } = useTranslation();

  const { productList, fetchNewProductPage, refetchSearchProductPage } =
    useProductCallAPI();

  const { items } = useProductMemorizations({ productList });

  const { addRow, onSearch, selectedItemFromForm } = useProductFunctions({
    index,
    totalRows,
    setProductRowAmount,
    refetchSearchProductPage,
  });

  return (
    <>
      <InputWithSearch
        name={`pedidoCompraItens.${index}.produtoExternalId`}
        items={items}
        onChange={() => addRow()}
        label={
          index === 0
            ? t('purchasing.purchaseOrder.create.productsCardField.product')
            : ''
        }
        required
        placeHolder={t(
          'purchasing.purchaseOrder.create.productsCardPlaceHolders.product'
        )}
        onScrollEnd={fetchNewProductPage}
        withoutMarginBottom
        onSearch={onSearch}
        selectedItemFromForm={selectedItemFromForm}
      />
    </>
  );
};
