import {
  useControllerMutateApiHook,
  useControllerQueryListApiHook,
} from '@/ControllerApiHook/Controller';
import { IUseCallAPI } from './interfaces';
import {
  DELETE_PRODUCT_LINKED_PACKING,
  FETCH_LIST_PRODUCT_LINKED_BY_PACKING,
} from '@/ControllerApiHook/UniqIds/Production/PackingClassificationKeys';
import { PackingClassificationAPI } from '@/Data/API/Production/PackingClassification';
import { useQueryClient } from '@tanstack/react-query';

export const useEditCallAPI = ({ externalId }: IUseCallAPI) => {
  const queryClient = useQueryClient();
  const {
    data: productLinkedList,
    isLoading,
    refetch: refetchPackingClassificationLinkedList,
  } = useControllerQueryListApiHook({
    uniqId: FETCH_LIST_PRODUCT_LINKED_BY_PACKING,
    entityApi: PackingClassificationAPI.getProductLinkedByPackingList,
    autoIncrementCustomId: 'produtoExternalId',
    autoIncrement: true,
    pagination: {
      sorter: { column: 'descricao', direction: 'ASC' },
      filter: [
        { filterName: 'embalagemClassificacaoExternalId', value: externalId },
      ],
    },
  });

  const removeProductLinked = useControllerMutateApiHook({
    uniqId: DELETE_PRODUCT_LINKED_PACKING,
    entityApi: PackingClassificationAPI.deleteProductLinkedPacking,
    options: {
      onSuccess: () =>
        queryClient.invalidateQueries([FETCH_LIST_PRODUCT_LINKED_BY_PACKING]),
    },
  });

  return {
    productLinkedList,
    isLoading,
    removeProductLinked,
    refetchPackingClassificationLinkedList,
  };
};
