import { useTranslation } from 'react-i18next';
import { Status } from '../../../Status';
import { useCallback, useState } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { Modal } from '../../../../../../Modal';
import { Divflex } from '../../../../../../../Layouts/DivWhithFlex';
import { Text } from '../../../../../../Text';
import { PermissionWrapper } from '../../../../../../PermissionWrapper';
import {
  EDIT_STATUS_LABORATORY,
  FETCH_GET_LABORATORY,
  FETCH_LIST_FOR_LABORATORY
} from '../../../../../../../ControllerApiHook/UniqIds/Production/LaboratoryKeys';
import { PermissionEnum } from '../../../../../../../Data/Interfaces/model/permissionEnum';
import { useControllerMutateApiHook, useControllerQueryApiHook } from '@/ControllerApiHook/Controller';
import { LaboratoryAPI } from '@/Data/API/Production/Laboratory';
import { IGetLaboratory } from '@/Data/Interfaces/response/Laboratory/ILaboratoryResponse';

export const DynamicEditLaboratoryBreadcrumb = ({ match }: any) => {
  const { data: laboratoryForm } = useControllerQueryApiHook<IGetLaboratory>({
    uniqId: FETCH_GET_LABORATORY,
    entityApi: LaboratoryAPI.getCurrentLaboratory,
    externalIds: [match.params.externalId]
  });

  return <div style={{ display: 'flex' }}>{laboratoryForm?.laboratorio}</div>;
};

export const DynamicEditLaboratoryStatusBreadcrumb = ({ match }: any) => {
  const { data: laboratoryForm } = useControllerQueryApiHook<IGetLaboratory>({
    uniqId: FETCH_GET_LABORATORY,
    entityApi: LaboratoryAPI.getCurrentLaboratory,
    externalIds: [match.params.externalId]
  });

  const editStatus: any = useControllerMutateApiHook({
    uniqId: EDIT_STATUS_LABORATORY,
    entityApi: LaboratoryAPI.laboratoryStatus
  });

  const queryClient = useQueryClient();

  const { t } = useTranslation();

  const [modalVisible, setModalVisible] = useState(false);
  const [status, setStatus] = useState(undefined);

  const changeStatus = useCallback(
    async (value: boolean) => {
      const res = await editStatus.mutateAsync({
        externalId: [match.params.externalId],
        ativo: value,
      });

      if (res.success) {
        queryClient.invalidateQueries([FETCH_LIST_FOR_LABORATORY]);
        queryClient.invalidateQueries([FETCH_GET_LABORATORY]);
        setModalVisible(false);
      }
    },
    [editStatus, match.params.externalId, queryClient]
  );

  const onEditStatus = useCallback(
    async (status: any) => {
      const res = await editStatus.mutateAsync({
        externalId: [match.params.externalId],
        ativo: status,
      });
      if (res.success) {
        queryClient.invalidateQueries([FETCH_LIST_FOR_LABORATORY]);
        queryClient.invalidateQueries([FETCH_GET_LABORATORY]);
        setModalVisible(false);
      }
    },
    [queryClient, match.params.externalId, editStatus]
  );

  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      {
        `${t('breadCrumb.production.laboratory.laboratoryListTitle')} | `
        + `${laboratoryForm?.laboratorio} | `
        + `${laboratoryForm?.empresa}`
      }
      <PermissionWrapper
        permission={PermissionEnum.Producao_Laboratorio_AlterarStatus}
        tooltip="noPermission.production.laboratory.statusEdit"
      >
        <Status
          type={laboratoryForm?.ativo ? 'success' : 'disabled'}
          ative={laboratoryForm?.ativo || false}
          onClick={(value) => {
            value ? changeStatus(value) : setModalVisible(true);
            setStatus(value);
          }}
        />
      </PermissionWrapper>

      <Modal
        body={
          <div style={{ padding: '20px' }}>
            <Divflex
              style={{
                flexDirection: 'column',
                marginBottom: 10,
              }}
            >
              <Text
                color="text-50"
                type="ui-tiny-content"
                children={`${t('common.disableLaboratoryList')} `}
              />

              <Divflex>
                <Text
                  color="text-50"
                  type="ui-tiny-bold"
                  children={`${laboratoryForm?.laboratorio} `}
                />
                <Text
                  color="text-50"
                  type="ui-tiny-content"
                  children={`${t('common.deleteLaboratoryListCompany')} `}
                />
                <Text
                  color="text-50"
                  type="ui-tiny-bold"
                  children={`${laboratoryForm?.empresa}`}
                />
                <Text
                  color="text-50"
                  type="ui-tiny-content"
                  children={`${'?'} `}
                />
              </Divflex>
            </Divflex>
          </div>
        }
        title={` ${t('production.laboratory.details.disable')} "${
          laboratoryForm?.laboratorio
        }"?`}
        onCancelClick={() => setModalVisible(false)}
        visible={modalVisible}
        onOkClick={() => status !== undefined && onEditStatus(status)}
        onClose={() => setModalVisible(false)}
        okButtonName={t('production.laboratory.details.disable')}
      />
    </div>
  );
};
