import { FC } from "react";
import { InputLabel } from '@/Components/Inputs/LabelTotal';
import { useTranslation } from "react-i18next";
import { useFormikContext } from "formik";
import { ITotalOrder } from "./interfaces";
import { useTotalOrderCallAPI } from "./Hooks/useTotalOrderCallAPI";
import { useParams } from "react-router";
import { useTotalOrderMemorizations } from "./Hooks/useTotalOrderMemorizations";

export const TotalOrder: FC<ITotalOrder> = ({
  withSelectedRows
}) => {
  const { t } = useTranslation();
  const form: any = useFormikContext();
  const { externalId } = useParams<{ externalId: string }>();
  
  const {
    purchaseOrder
  } = useTotalOrderCallAPI({ externalId });

  const {
    hasProducts,
    labelValue,
    withTooltip,
    style
  } = useTotalOrderMemorizations({
    purchaseOrder
  });

  return (
    <>
      <InputLabel
        labelTitle={t('purchasing.purchaseOrder.create.totalCard.totalOrder')}
        labelValue={labelValue}
        withTooltip={withTooltip}
        customSize={24}
        withStatusIconInText={withSelectedRows && hasProducts && form.dirty}
        type="ui-tiny-content"
        style={style}
      />
    </>
  );
};