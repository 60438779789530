export const FETCH_GET_LABORATORY = '[LABORATORY] FETCH_GET_LABORATORY';

export const FETCH_LIST_LABORATORY_FOR_DROPDOWN_TYPE =
  '[LABORATORY] FETCH_LIST_LABORATORY_FOR_DROPDOWN_TYPE';
export const FETCH_LIST_FOR_LABORATORY =
  '[LABORATORY] FETCH_LIST_FOR_LABORATORY';
export const LIST_COMPANY_FOR_DROPDOWN_TYPE_TO_LABORATORY =
  '[LABORATORY] LIST_COMPANY_FOR_DROPDOWN_TYPE_TO_LABORATORY';
export const LIST_SUPPLYPLACE_FOR_DROPDOWN_TYPE_TO_LABORATORY =
  '[LABORATORY] LIST_SUPPLYPLACE_FOR_DROPDOWN_TYPE_TO_LABORATORY';

export const CREATE_LABORATORY = '[LABORATORY] CREATE_LABORATORY';
export const EDIT_STATUS_LABORATORY = '[LABORATORY] EDIT_STATUS_LABORATORY';
export const EDIT_LABORATORY = '[LABORATORY] EDIT_LABORATORY';
export const DELETE_LABORATORY = '[LABORATORY] DELETE_LABORATORY';
export const DELETE_LABORATORY_DETAILS =
  '[LABORATORY] DELETE_LABORATORY_DETAILS';

export const THERE_IS_LABORATORY_NAME_TYPE =
  '[LABORATORY] THERE_IS_LABORATORY_NAME_TYPE';
export const THERE_IS_BIND_LABORATORY_TO_COMPANY =
  '[LABORATORY] THERE_IS_BIND_LABORATORY_TO_COMPANY';

