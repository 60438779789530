import { history } from '@/Store';
import {
  DELETE_PRESCRIBER,
  FETCH_LIST_PRESCRIBERS
} from '@/ControllerApiHook/UniqIds/People/PrescribersKeys';
import { useQueryClient } from '@tanstack/react-query';
import { IUseCallAPI } from './interfaces';
import { useControllerMutateApiHook } from '@/ControllerApiHook/Controller';
import { PrescribersAPI } from '@/Data/API/People/PrescribersApi';
import { messageErrorToast, messageSucessToast } from '@/Utils/MessageErrorToast';
import { i18n } from '@/Components';

export const useDeleteModalCallAPI = ({ changeVisibleModal }: IUseCallAPI) => {
  const queryClient = useQueryClient();

  const deletePrescriber: any = useControllerMutateApiHook({
    uniqId: DELETE_PRESCRIBER,
    entityApi: PrescribersAPI.deletePrescribers,
    options: {
      onError: (x: any) => x && !x.errorCode && messageErrorToast(x),
      onSuccess: () => {
        messageSucessToast(i18n.t('prescribers.listPage.deleteModal.success'));
        
        queryClient.invalidateQueries([FETCH_LIST_PRESCRIBERS]);

        history.push(`/sales/prescribers`);

        changeVisibleModal(false);
      }
    }
  });

  return { deletePrescriber };
};