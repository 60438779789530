import { FormikHelpers, FormikValues } from 'formik';
import { ICreateProductRequest } from '@/Data/Interfaces/request/Product/ICreateProductRequest';
import { history } from '@/Store';
import {
  FETCH_LIST_PRODUCT
} from '@/ControllerApiHook/UniqIds/Supply/ProductKeys';
import { useQueryClient } from '@tanstack/react-query';
import { IUseFunctions } from './interfaces';
import { useCallback } from 'react';

export const useCreateFunctions = ({
  createProduct
}: IUseFunctions) => {
  const queryClient = useQueryClient();
  
  const onSubmit = useCallback(async (
    values: ICreateProductRequest,
    helpers: FormikHelpers<FormikValues>
  ) => {
    var body: any = { ...values, helpers };

    delete body.grupoExternalId;

    const res = await createProduct.mutateAsync({
      ...body,
      helpers,
    });

    if (res.success) {
      queryClient.invalidateQueries([FETCH_LIST_PRODUCT]);
      history.push('/inventory/products');
    };
  }, [createProduct]);

  const onClick = useCallback(
    () => history.push('/inventory/products'), []
  );

  return {
    onSubmit,
    onClick
  };
};