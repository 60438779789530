import { useCallback } from 'react';
import { handleModalError } from '@/Utils/ErrorCodeUtils/ErrorHandlingUtils';
import { App } from 'antd';
import { IUseFunctions } from './interfaces';
import { messageErrorToast } from '@/Utils/MessageErrorToast';
import { useQueryClient } from '@tanstack/react-query';
import { FETCH_LIST_PRODUCT_LINKED_BY_MESSAGE } from '@/ControllerApiHook/UniqIds/Supply/ProductMessageKeys';

export const useListFunctions = ({
  deleteProductLinkedByMessage,
  externalId,
}: IUseFunctions) => {
  const app = App.useApp();
  const queryClient = useQueryClient();

  const onDelete = useCallback(
    async (selectedRowsOnTable: any[]) => {
      try {
        await deleteProductLinkedByMessage(
          {
            mensagemExternalId: externalId,
            produtosExternalIds: selectedRowsOnTable.map(
              (x) => x?.produtoExternalId
            ),
          },
          { onError: handleModalError(app) }
        );

        queryClient.invalidateQueries([FETCH_LIST_PRODUCT_LINKED_BY_MESSAGE]);
      } catch (error) {
        messageErrorToast(error);
      }
    },
    [deleteProductLinkedByMessage, app]
  );

  return {
    onDelete,
  };
};
