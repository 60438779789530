import { useState } from 'react';
import { v4 as uuidV4 } from 'uuid';

export const useCreateStates = () => {
  const [packingClassificationRowAmount, setPackingClassificationRowAmount] =
    useState([uuidV4()]);
  return {
    packingClassificationRowAmount,
    setPackingClassificationRowAmount,
  };
};
