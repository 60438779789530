import { FC } from 'react';
import { Card } from '@/Components';
import { Row } from 'antd';
import { useTranslation } from 'react-i18next';
import { CapsuleType } from './Components/CapsuleType';
import { ICapsuleTypeCard } from './interfaces';
import { CapsuleNumber } from './Components/CapsuleNumber';
import { CapsuleColor } from './Components/CapsuleColor';

export const CapsuleTypeCard: FC<ICapsuleTypeCard> = ({
  disabledForm = false,
  product
}) => {
  const { t } = useTranslation();

  return (
    <Card title={t('products.create.additionalInformation')}>
      <Row gutter={[16, 0]}>
        <CapsuleType
          product={product}
          disabledForm={disabledForm}
        />
        <CapsuleNumber
          product={product}
          disabledForm={disabledForm}
        />
        <CapsuleColor 
          product={product}
          disabledForm={disabledForm}
        />
      </Row>
    </Card>
  );
};
