import { IGetUserResponse } from '@/Data/Interfaces/response/User/IGetUserReponse';

export const CreateInitialValuesForPlaceAndTime = (
  user: IGetUserResponse | undefined
) => {
  if (user?.preferencias) {
    const { timeZone, idioma, padraoHora, padraoData } = user.preferencias;

    const initialValues = {
      timeZone,
      idioma,
      padraoData,
      padraoHora,
    };

    return initialValues;
  }
  return undefined;
};
