import { FC } from "react";
import { Col, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import { InputLabel } from '@/Components/Inputs/LabelTotal';
import { DiscountInput } from '@/Components/Inputs/DiscountInput';
import { currencyFormater } from '@/Utils/Currency';
import { CurrencyInput } from '@/Components/Inputs/CurrencyInput';
import { FormikContextType, useFormikContext } from 'formik';
import { 
  ISaveDraftOrderPurchaseRequest 
} from '@/Data/Interfaces/request/OrderPurchase/IOrderPurchaseRequest';
import { IOrderValues } from "./interfaces";

import styles from '../../../TotalCard/Components/CardTotal/CartTotal.module.scss';

export const OrderValues: FC<IOrderValues> = ({
  finalTotal
}) => {
  const { t } = useTranslation();
  const form: FormikContextType<ISaveDraftOrderPurchaseRequest> =
  useFormikContext();

  return (
    <>
      <Row justify="start" style={{ marginTop: '16px' }} gutter={[16, 0]}>
        <Col span={24}>
          <CurrencyInput
            name="frete"
            label={t('purchasing.purchaseOrder.create.totalCard.freightCost')}
            withoutMarginBottom
            placeHolder={t(
              'purchasing.purchaseOrder.create.productsCardPlaceHolders.discountValue'
            )}
          />
        </Col>
      </Row>
      <Row justify="start" style={{ marginTop: '16px' }} gutter={[16, 0]}>
        <Col span={24}>
          <CurrencyInput
            name="valorAdicional"
            label={t(
              'purchasing.purchaseOrder.create.totalCard.additionalValue'
            )}
            withoutMarginBottom
            placeHolder={t(
              'purchasing.purchaseOrder.create.productsCardPlaceHolders.discountValue'
            )}
          />
        </Col>
      </Row>
      <Row
        justify="start"
        style={{
          marginTop: '16px',
        }}
        gutter={[16, 0]}
      >
        <Col span={24}>
          <DiscountInput
            name="valorDesconto"
            label={t(
              'purchasing.purchaseOrder.create.totalCard.fullDiscount'
            )}
            placeHolder={
              form.values.tipoDesconto === 1
                ? t(
                    'purchasing.purchaseOrder.create.productsCardPlaceHolders.discountValue'
                  )
                : t(
                    'purchasing.purchaseOrder.create.productsCardPlaceHolders.discountPercentage'
                  )
            }
            withoutMarginBottom
            dropdownLeft={{
              name: 'tipoDesconto',
            }}
          />
        </Col>
      </Row>
      <div className={`${styles['cardTotal-separator-line']}`}></div>
      <Row
        justify="start"
        style={{
          marginTop: '32px',
        }}
        gutter={[16, 0]}
      >
        <Col span={24}>
          <InputLabel
            labelTitle={t(
              'purchasing.purchaseOrder.create.labelTotalValueName'
            )}
            labelValue={currencyFormater(finalTotal)}
            customSize={24}
          />
        </Col>
      </Row>
    </>
  );
};