import { useCallback } from "react";
import { IUseFunctions } from "./interface";

export const useContactCardFunctions = ({ props }: IUseFunctions) => {
  const onChange = useCallback(
    (values: any[] | undefined) => props.setFieldValue('contatos', values), [props.setFieldValue]
  );

  return {
    onChange
  };
};