import { Col } from "antd";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { TextInputState } from '@/Components/StateInputs/TextInput';
import { IProductCode } from "./interfaces";

export const ProductCode: FC<IProductCode> = ({
  product
}) => {
  const { t } = useTranslation();

  return (
    <Col span={4} style={{ marginTop: '10px' }}>
      <TextInputState
        label={t('products.create.productCode')}
        placeHolder={t('products.create.productCode')}
        disabled
        rightIconWithCopy={{
          icon: 'copy',
          copyName: 'codigoProduto',
          toolTipTitle: [
            `${t('common.copy')} ${t('common.code')}`,
            t('common.copied'),
          ],
        }}
        value={product?.codigoProduto.toString()}
      />
    </Col>
  );
};