import { i18n } from '@/Components';
import * as yup from 'yup';

export const AddProductModalSchema = yup.object().shape({
  produtoExternalId: yup
    .string()
    .required(
      i18n.t(
        'saleOrder.editOrder.SaleOrder.addProductModal.error.productRequired'
      )
    ),
  valorUnitario: yup
    .string()
    .required(
      i18n.t(
        'saleOrder.editOrder.SaleOrder.addProductModal.error.unitValueRequired'
      )
    ),
  quantidade: yup
    .string()
    .required(
      i18n.t(
        'saleOrder.editOrder.SaleOrder.addProductModal.error.amountRequired'
      )
    ),
  total: yup
    .string()
    .required(
      i18n.t(
        'saleOrder.editOrder.SaleOrder.addProductModal.error.totalRequired'
      )
    ),
});
