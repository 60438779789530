import { IDismembermentDescription } from "../interfaces";
import { dismembermentTypes } from '../../../../../../../../Create/Components/utils';

export const useDismembermentTypeMemorizations = () => {
  const items = dismembermentTypes?.map((values: IDismembermentDescription) => ({
    id: values.id,
    label: values.name,
  }));

  return {
    items
  };
};