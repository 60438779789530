import { FC } from 'react';
import { Col } from 'antd';
import { Tooltip } from '@/Components';
import { CountryDropdown } from '@/Components/Inputs/CountryDropdown';
import { useTranslation } from 'react-i18next';
import { useFormikContext } from 'formik';
import { ICountryOrigin } from './interfaces';

export const CountryOrigin: FC<ICountryOrigin> = ({
  lots,
  disabled,
  paisOrigemList,
}) => {
  const { t } = useTranslation();
  const form: any = useFormikContext();

  return (
    <Col span={5}>
      <Tooltip
        title={`${t('supply.lot.details.toolTips.originalCountryOfOrigin')} ${
          lots?.loteInformacaoTecnica?.descricaoPais
        }`}
        showMe={
          lots?.loteInformacaoTecnica?.paisOrigemExternalId !==
            form?.values?.loteInformacaoTecnica?.paisOrigemExternalId &&
          !disabled
        }
      >
        <CountryDropdown
          name="loteInformacaoTecnica.paisOrigemExternalId"
          placeHolder={t('supply.lot.placeholder.countryOfOrigin')}
          items={paisOrigemList?.data?.map((x: any) => ({
            id: x.externalId,
            name: x.descricao,
            flag: x.abreviacao,
          }))}
          label={t('supply.lot.create.countryOfOrigin')}
          disabled={disabled}
          withStatusIcon
        />
      </Tooltip>
    </Col>
  );
};
