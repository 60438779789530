import { FETCH_LIST_SPECIALTIES_FOR_DROPDOWN } from '@/ControllerApiHook/UniqIds/People/SpecialtiesKeys';
import { useControllerQueryListApiHook } from '@/ControllerApiHook/Controller';
import { SpecialtiesAPI } from '@/Data/API/People/SpecialtiesApi';

export const useInfoModalCallAPI = () => {
  const {
    data: specialtiesData,
    refetch: refetchSearchSpecialties,
    fetchNewPage: fetchNewPageSpecialties,
  } = useControllerQueryListApiHook({
    uniqId: FETCH_LIST_SPECIALTIES_FOR_DROPDOWN,
    entityApi: SpecialtiesAPI.listSpecialtiesForDropdown,
    autoIncrement: true,
  });

  return {
    specialtiesData,
    refetchSearchSpecialties,
    fetchNewPageSpecialties,
  };
};
