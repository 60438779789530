import { useCallback } from "react";
import { FETCH_SYNONYM_PRODUCT } from "@/ControllerApiHook/UniqIds/Supply/ProductKeys";
import { ICreateSynonymProductRequest } from "@/Data/Interfaces/request/Product/ICreateSynonymProductRequest";
import { history } from '@/Store';
import { useQueryClient } from "@tanstack/react-query";
import { IUseFunctions } from "./interfaces";

export const useCreateSynonymFunctions = ({
  createSynonym,
  productExternalId
}: IUseFunctions) => {
  const queryClient = useQueryClient();

  const handleSubmit = useCallback(async (values: ICreateSynonymProductRequest) => {
    const result = await createSynonym.mutateAsync({
      ...values,
      produtoExternalId: productExternalId,
      descricaoRotulo: values.descricaoRotulo ?? '',
    });

    if (result) {
      queryClient.invalidateQueries([FETCH_SYNONYM_PRODUCT]);
      history.push(`/inventory/products/${productExternalId}/synonym`);
    }
  }, [createSynonym]);

  const onClickCancelButton = () =>
    history.push(`/inventory/products/${productExternalId}/synonym`);

  return {
    handleSubmit,
    onClickCancelButton
  };
};