import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useField } from 'formik';
import { Text } from '../..';
import CurrencyFormat from 'react-currency-format';
import StorageUtils from '../../../Utils/StorageUtils';
import { INumberInput } from './types';
import { VisualInput } from './VisualInput';
import { InputRef } from 'antd';
import { formatAsNumber } from '@/Utils/numberUtils';

import styles from './VisualInput/VisualInput.module.scss';

export const WeightInput = ({
  name,
  prefix,
  allowExtraDecimal,
  suffix,
  onChange,
  onBlur,
  subText,
  dataTestId,
  className,
  id,
  hideArrow,
  ...props
}: Omit<
  INumberInput,
  | 'fixedDecimalScale'
  | 'withCurrencyPrefix'
  | 'decimalScale'
  | 'withDecimalSeparator'
  | 'withThousandSeparator'
> & { allowExtraDecimal?: boolean; id?: string; hideArrow?: boolean }) => {
  const ref = useRef<React.Ref<InputRef> | null>();
  const [numberFieldConfig, _, numberFieldHelpers] = useField(name);
  const [metadataConfig, metadataProps, metadataHelpers] = useField(
    `metadata.${name}`
  );
  const [useDefaultDecimalScale, setUseDefaultDecimalScale] = useState(true);
  const preference = StorageUtils.getPreference();
  const DEFAULT_DECIMAL_SCALE = 4;

  let thousandSeparator: string = preference?.idioma === 'pt-BR' ? '.' : ',';
  let decimalSeparator: string = preference?.idioma === 'pt-BR' ? ',' : '.';

  /**
   * This function is responsible for allowing users to add more than the default amount of decimals.
   * Adding this in the onChange, should make it automatic
   */
  const allowExtraFields = useCallback(
    (
      innerRef: any,
      metadata?: { value: string; formattedValue: string; floatValue?: number }
    ) => {
      // @ts-ignore
      const current: any = innerRef?.current?.input;
      const currentValue = current.value;
      const currentCursorPosition = current?.selectionStart;
      const currentDecimalPosition = currentValue.indexOf(decimalSeparator!);

      // Keep in mind that this is outdated with the actual value on the input
      let currentDecimalAmt = 0;

      // metadata.value is a valid number stringified so only '.' is allowed as decimal separator
      if ((metadata?.value?.indexOf('.') || -1) > -1) {
        currentDecimalAmt = metadata?.value?.split('.')[1].length || 0;
      }

      if (!metadata?.value) {
        return;
      }

      // This does happen when you delete the decimal separator
      // At this point the metadata didn't update yet, but the field became an integer
      // Wee need to add back the decimals otherwise a lot of bugs happens
      if (!!currentValue && currentValue?.indexOf(decimalSeparator) === -1) {
        const decimalIndex = metadata?.formattedValue?.indexOf(
          decimalSeparator!
        );

        setTimeout(() => {
          metadataHelpers.setValue(metadata);
          numberFieldHelpers.setValue(metadata?.floatValue);

          setTimeout(() => {
            current.setSelectionRange(decimalIndex, decimalIndex);
          }, 1);
        }, 1);

        return;
      }

      const hasCursorOnLastChar =
        currentCursorPosition >= (metadata?.formattedValue?.length || 0);
      if (hasCursorOnLastChar) {
        setUseDefaultDecimalScale(false);
        return;
      }

      // Changing integer value not decimals
      if (currentCursorPosition <= currentDecimalPosition) {
        return;
      }

      if (currentDecimalAmt <= DEFAULT_DECIMAL_SCALE + 1) {
        setUseDefaultDecimalScale(true);
      }
    },
    [decimalSeparator, metadataHelpers, numberFieldHelpers]
  );

  // The main reason of this useEffect is to detect changes in the input value that
  // are out of sync with metadata (means that you changed the input value manualy with 'setFieldValue')
  useEffect(() => {
    const currentFieldValue = numberFieldConfig.value;
    const currentMetadataFloatValue = metadataConfig.value?.floatValue;

    const hasDifference = currentFieldValue !== currentMetadataFloatValue;
    const isStartingNegativeNumber =
      metadataConfig?.value?.formattedValue === '-';
    if (!hasDifference || isStartingNegativeNumber) {
      return;
    }

    if (
      !currentFieldValue &&
      (!useDefaultDecimalScale ||
        metadataConfig?.value?.formattedValue !== '' ||
        metadataConfig?.value?.value !== '' ||
        metadataConfig?.value?.floatValue !== undefined)
    ) {
      setUseDefaultDecimalScale(true);
      metadataHelpers.setValue({
        formattedValue: '',
        value: '',
        floatValue: undefined,
      });
      return;
    }

    const strValue = currentFieldValue?.toString();

    // only '.' are available here since we expect a valid number
    const decimalAmount =
      strValue.indexOf('.') > -1 ? strValue.split('.')[1].length : 0;
    let decimalScale: number | undefined = DEFAULT_DECIMAL_SCALE;

    if (allowExtraDecimal) {
      const hasMoreDecimalsThanDefault = decimalAmount >= DEFAULT_DECIMAL_SCALE;
      decimalScale = hasMoreDecimalsThanDefault
        ? undefined
        : DEFAULT_DECIMAL_SCALE;
      setUseDefaultDecimalScale(!hasMoreDecimalsThanDefault);
    }

    const formattedValue = formatAsNumber(
      strValue,
      decimalScale,
      thousandSeparator,
      decimalSeparator,
      prefix,
      suffix
    );

    metadataHelpers.setValue({
      formattedValue,
      value: strValue,
      floatValue: currentFieldValue,
    });
  }, [
    numberFieldConfig,
    metadataConfig,
    metadataHelpers,
    useDefaultDecimalScale,
    thousandSeparator,
    decimalSeparator,
    prefix,
    allowExtraDecimal,
    suffix,
  ]);

  return (
    <>
      <CurrencyFormat
        decimalScale={
          useDefaultDecimalScale ? DEFAULT_DECIMAL_SCALE : undefined
        }
        thousandSeparator={thousandSeparator}
        decimalSeparator={decimalSeparator}
        fixedDecimalScale={useDefaultDecimalScale}
        customInput={VisualInput}
        className={className}
        value={metadataProps?.value?.formattedValue}
        prefix={prefix}
        suffix={suffix}
        onBlur={onBlur}
        id={id}
        onChange={(x) => {
          if (allowExtraDecimal) {
            allowExtraFields(ref, metadataProps.value);
          }

          onChange && onChange(x.target.value);
        }}
        onValueChange={(x) => {
          const valueWithoutSuffix = x?.formattedValue?.replace(
            suffix || '',
            ''
          );
          const lastChar = valueWithoutSuffix[valueWithoutSuffix.length - 1];

          const availableChars = [',', '.', '-'];
          if (availableChars.includes(lastChar)) {
            metadataHelpers.setValue(x);
            return x?.formattedValue;
          }

          metadataHelpers.setValue(x);
          return numberFieldHelpers.setValue(
            isNaN(x.floatValue) ? undefined : x.floatValue
          );
        }}
        {...({
          ...props,
          prefix,
          dataTestId,
          name,
          currentSuffix: suffix,
          outsideRef: ref,
          separators: {
            decimalSeparator,
            thousandSeparator,
          },
          hideArrow,
        } as any)}
      />
      {subText && (
        <Text
          type="ui-tiny-content"
          color="text-300"
          children={subText}
          className={styles['sub-text']}
        />
      )}
    </>
  );
};
