import { QueryFunctionContext } from '@tanstack/react-query';
import { Http, IQueryListParams } from '../../../Utils/Http';
import {
  IGetPrescriberResponse,
  IGetSimplifiedPrescriberResponse,
  IListPrescribersResponse,
  ISimpleListPrescribersResponse,
} from '../../Interfaces/response/Prescribers/IPrescribersResponse';
import {
  IAddAdressPrescriber,
  IAddContactPrescriber,
  IChangePrescriberStatusRequest,
  ICreateFastPrescriberRequest,
  IEditAdressPrescriber,
  IEditContactPrescriber,
  IEditDocumentPrescriber,
  IEditInfoPrescriber,
  IValidateRegistrationPrescriberRequest,
} from '../../Interfaces/request/Prescribers/IPrescribersRequest';
import { ICreatePrescriberRequest } from '../../Interfaces/request/Prescribers/IPrescribersRequest';
import { IBaseResponse } from '@/Data/Interfaces/response/Base/IBase';

export class PrescribersAPI {
  public static async createPrescriber(body: ICreatePrescriberRequest) {
    return await Http.fetcher('/pharma/pessoa/v1/Prescritor', {
      method: 'POST',
      body: JSON.stringify(body),
    });
  }

  public static async createFastPrescriber(body: ICreateFastPrescriberRequest) {
    return await Http.fetcher('/pharma/pessoa/v1/Prescritor/CadastrarRapido', {
      method: 'POST',
      body: JSON.stringify(body),
    });
  }

  public static async listPrescribers(
    context: QueryFunctionContext
  ): Promise<IListPrescribersResponse> {
    return await Http.fetcher<IListPrescribersResponse>(
      Http.AddQueryParams(
        '/pharma/pessoa/v1/Prescritor/ListarDetalhado',
        context.queryKey[1] as IQueryListParams
      )
    );
  }
  public static async listPrescribersForDropdown(
    context: QueryFunctionContext
  ): Promise<ISimpleListPrescribersResponse> {
    return await Http.fetcher<ISimpleListPrescribersResponse>(
      Http.AddQueryParams(
        '/pharma/pessoa/v1/Prescritor/Listar',
        context.queryKey[1] as IQueryListParams
      )
    );
  }
  public static async getPrescribers(
    context: QueryFunctionContext
  ): Promise<IGetPrescriberResponse> {
    return await Http.fetcher<IGetPrescriberResponse>(
      `/pharma/pessoa/v1/Prescritor/${context.queryKey[1]}`
    );
  }

  public static async changePrescriberStatus(
    body: IChangePrescriberStatusRequest
  ) {
    return await Http.fetcher('/pharma/pessoa/v1/Prescritor/AtualizarStatus', {
      method: 'PUT',
      body: JSON.stringify(body),
    });
  }

  public static async editInfoPrescriber(body: IEditInfoPrescriber) {
    return await Http.fetcher('/pharma/pessoa/v1/Prescritor', {
      method: 'PUT',
      body: JSON.stringify(body),
    });
  }

  public static async editContactPrescriber(body: IEditContactPrescriber) {
    return await Http.fetcher('/pharma/pessoa/v1/Prescritor/AtualizarContato', {
      method: 'PUT',
      body: JSON.stringify(body),
    });
  }
  public static async addAddressPrescriber(body: IAddAdressPrescriber) {
    return await Http.fetcher(
      '/pharma/pessoa/v1/Prescritor/CadastrarEndereco',
      {
        method: 'POST',
        body: JSON.stringify(body),
      }
    );
  }
  public static async addContactPrescriber(body: IAddContactPrescriber) {
    return await Http.fetcher('/pharma/pessoa/v1/Prescritor/CadastrarContato', {
      method: 'POST',
      body: JSON.stringify(body),
    });
  }
  public static async deleteContactPrescriber(contactExternalId: string) {
    return await Http.fetcher(
      `/pharma/pessoa/v1/Prescritor/RemoverContato/${contactExternalId}`,
      {
        method: 'DELETE',
      }
    );
  }
  public static async changeFavoriteContactPrescriber(body: {
    prescritorContatoExternalId: string;
    principal: boolean;
  }) {
    return await Http.fetcher(
      '/pharma/pessoa/v1/Prescritor/AtualizarContatoPrincipal',
      {
        method: 'PUT',
        body: JSON.stringify(body),
      }
    );
  }
  public static async editAddressPrescriber(body: IEditAdressPrescriber) {
    return await Http.fetcher(
      '/pharma/pessoa/v1/Prescritor/AtualizarEndereco',
      {
        method: 'PUT',
        body: JSON.stringify(body),
      }
    );
  }
  public static async deleteAddressPrescriber(prescriberExternalId: string) {
    return await Http.fetcher(
      `/pharma/pessoa/v1/Prescritor/RemoverEndereco/${prescriberExternalId}`,
      {
        method: 'DELETE',
      }
    );
  }
  public static async changeFavoriteAddressPrescriber(body: {
    prescritorEnderecoExternalId: string;
    principal: boolean;
  }) {
    return await Http.fetcher(
      '/pharma/pessoa/v1/Prescritor/AtualizarEnderecoPrincipal',
      {
        method: 'PUT',
        body: JSON.stringify(body),
      }
    );
  }

  public static async editDocumentPrescriber(body: IEditDocumentPrescriber) {
    return await Http.fetcher(
      '/pharma/pessoa/v1/Prescritor/AtualizarDocumento',
      {
        method: 'PUT',
        body: JSON.stringify(body),
      }
    );
  }
  public static async addDocumentPrescriber(body: IEditDocumentPrescriber) {
    return await Http.fetcher(
      '/pharma/pessoa/v1/Prescritor/CadastrarDocumento',
      {
        method: 'POST',
        body: JSON.stringify(body),
      }
    );
  }
  public static async deleteDocumentPrescriber(documentExternalId: string) {
    return await Http.fetcher(
      `/pharma/pessoa/v1/Prescritor/RemoverDocumento/${documentExternalId}`,
      {
        method: 'DELETE',
      }
    );
  }

  public static async deletePrescribers(externalId: string[]) {
    return await Http.fetcher('/pharma/pessoa/v1/Prescritor', {
      method: 'DELETE',
      body: JSON.stringify({ prescritoresExternalId: externalId }),
    });
  }

  public static async getSimplifiedPrescriber(
    context: QueryFunctionContext
  ): Promise<IGetSimplifiedPrescriberResponse | null> {
    if (!!context.queryKey[1])
      return await Http.fetcher<IGetSimplifiedPrescriberResponse>(
        `/pharma/pessoa/v1/Prescritor/ObterSimplificado/${context.queryKey[1]}`
      );
    else return null;
  }

  public static async validationRegistrationPrescriber(
    body: IValidateRegistrationPrescriberRequest
  ): Promise<IBaseResponse<any>> {
    return await Http.fetcher('/pharma/pessoa/v1/Prescritor/ValidarRegistro', {
      method: 'POST',
      body: JSON.stringify(body),
    });
  }
}
