import React, { FC, ReactNode, useEffect, useMemo, useState } from 'react';
import { Icon, Skeleton } from '..';
import StorageUtils from '../../Utils/StorageUtils';
import { IMenuItem, MenuItem } from './MenuItem';
import { SectorMenu } from './SectorMenu';
import { motion } from 'framer-motion';
import { IGetUserResponse } from '@/Data/Interfaces/response/User/IGetUserReponse';
import { Context, CurrentContextType } from '@/Contexts/context';
import { useLocation } from 'react-router';
import { redirectToDefaultContext } from './SideMenu.helpers';

import styles from './SideMenu.module.scss';

export interface ISideMenu {
  items?: IMenuItem[];
  children: ReactNode;
  onCollapsedChange: (any: any) => any;
  getMe?: IGetUserResponse;
}

export const SideMenu: FC<ISideMenu> = ({
  items,
  children,
  onCollapsedChange,
  getMe,
}) => {
  const { currentContext, updateContext } = React.useContext(
    Context
  ) as CurrentContextType;

  const COLLAPSE_SIDEBAR = 'COLLAPSE-SIDEBAR';

  const location = useLocation();

  const [isCollapsed, setIsCollapsed] = useState(
    StorageUtils.GetItem(COLLAPSE_SIDEBAR) === 'true'
  );

  const isMobile = useMemo(() => {
    const MOBILE_SCREEN_WIDTH = 1200;

    return window.screen.width < MOBILE_SCREEN_WIDTH;
  }, [window.screen.width]);

  const onCollapseClick = () => {
    StorageUtils.SetItem(COLLAPSE_SIDEBAR, (!isCollapsed).toString());

    setIsCollapsed(!isCollapsed);
  };

  useEffect(() => {
    const isAlreadyCollapsed =
      StorageUtils.GetItem(COLLAPSE_SIDEBAR) === 'true';

    if (isMobile && !isAlreadyCollapsed) {
      StorageUtils.SetItem(COLLAPSE_SIDEBAR, 'true');
      setIsCollapsed(true);
    }
  }, [isMobile]);

  useEffect(() => {
    onCollapsedChange(isCollapsed);
  }, [isCollapsed, onCollapsedChange]);

  useEffect(() => {
    redirectToDefaultContext(updateContext, location);
  }, [location, updateContext]);

  return (
    <main className={styles['Content']} id="content-pages">
      <motion.div
        animate={{
          width: !isCollapsed ? 225 : 65,
          position: !isCollapsed && isMobile ? 'absolute' : undefined,
          height: !isCollapsed && isMobile ? '100vh' : undefined,
        }}
      >
        <div
          className={`${styles['sideMenu']} ${
            isCollapsed ? styles['collapsed'] : ''
          }`}
          data-testid="side-menu"
        >
          <div className={styles['sector-menu']}>
            <SectorMenu
              isCollapsed={isCollapsed}
              modules={getMe?.modulosSistema}
            />

            <div className={styles['separator']}></div>
          </div>

          {currentContext ? (
            items &&
            items.map((x, index) => (
              <div
                className={`${styles['side-menu-items']} ${
                  isCollapsed ? styles['collapsed-side-menu-items'] : ''
                }`}
                key={x.id}
              >
                <MenuItem
                  {...x}
                  isCollapsed={isCollapsed}
                  key={`side-menu-${index}`}
                />
              </div>
            ))
          ) : (
            <div className={styles['skeleton']}>
              <Skeleton size="gg" />
              <Skeleton size="gg" />
              <Skeleton size="gg" />
            </div>
          )}
        </div>
        <div
          className={`${styles['footer']} ${
            isCollapsed ? styles['collapsed-footer'] : ''
          }`}
          style={{
            position: !isCollapsed && isMobile ? 'absolute' : undefined,
            bottom: !isCollapsed && isMobile ? 50 : undefined,
          }}
        >
          <Icon
            icon={isCollapsed ? 'double-chevron-right' : 'double-chevron-left'}
            size="XXL"
            color="text-400"
            onClick={onCollapseClick}
          />
        </div>
      </motion.div>

      <div
        className={`${styles['main']} ${
          isCollapsed ? styles['collapsed-main'] : ''
        }`}
        style={{ width: !isCollapsed && isMobile ? '100%' : undefined }}
      >
        {children}
      </div>
    </main>
  );
};
