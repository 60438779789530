import { i18n } from '../../../../../../Components';

export const periodTypes = [
  {
    id: 1,
    description: i18n.t('production.posology.create.periodType.day'),
  },
  {
    id: 2,
    description: i18n.t('production.posology.create.periodType.week'),
  },
  {
    id: 3,
    description: i18n.t('production.posology.create.periodType.month'),
  },
];
