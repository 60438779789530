import StorageUtils from '@/Utils/StorageUtils';
import DateUtils from '../../../../../../Utils/DateUtils';

export const formatInvoice = (invoice: any) => {
  if (!invoice) return {};
  invoice.pedidosVinculados = invoice.pedidosVinculados ?? [];

  const preferences = StorageUtils.getPreference();

  return {
    ...invoice,
    itens: invoice.itens.map((item: any) => ({
      ...item,
      codProduto: item.produtoCodigo,
    })),
    dataEmissao: DateUtils.format(
      invoice.dataEmissao,
      preferences.padraoData ?? 'dd/MM/yyyy'
    ),
    dataEntrega: DateUtils.convertDate(
      invoice.dataEntrega,
      preferences.padraoData ?? 'dd/MM/yyyy'
    ),
    dataLancamento: DateUtils.convertDate(
      invoice.dataLancamento,
      preferences.padraoData ?? 'dd/MM/yyyy'
    ),
    pedidosExternalId: invoice.pedidosVinculados.map(
      (e: any) => e.pedidoCompraExternalId
    ),
    lotes: invoice.itens.map((item: any) => ({
      unidadeMedidaId: item.unidadeMedidaId,
      notaFiscalEntradaItemId: item.notaFiscalEntradaItemId,
      quantidade: item.quantidadeComprada,
      produtoEntradaItemExternalId: item.produtoExternalId,
      fornecedorExternalId: invoice.fornecedorExternalId,
    })),
  };
};
