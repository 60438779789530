import { Avatar, Text } from '@/Components';
import { FC } from 'react';

import styles from './AvatarDropdown.module.scss';

interface IAvatar {
  name: string;
  lastName?: string;
  email?: string;
}

interface IAvatarDropdown {
  avatar: IAvatar;
}

export const AvatarDropdown: FC<IAvatarDropdown> = ({ avatar }) => (
  <>
    <Avatar
      fisrtName={avatar.name}
      lastName={avatar.lastName}
      size="SM"
      type="paragraph2-bold"
      color="white"
    />
    <div className={styles['div-avatar']}>
      <Text
        className={styles['description']}
        type="ui-sub-content"
        color="text-50"
        children={`${avatar.name}${
          avatar.lastName ? ` ${avatar.lastName}` : ''
        }`}
      />
      {avatar.email && (
        <Text
          className={styles['description']}
          type="small-text"
          color="text-400"
          children={avatar.email}
        />
      )}
    </div>
  </>
);
