import { IProductListData } from '@/Data/Interfaces/response/Product/IProductRespose';
import { useCallback } from 'react';
import { IUseFunctions } from './interfaces';
import { useQueryClient } from '@tanstack/react-query';
import { handleModalError } from '@/Utils/ErrorCodeUtils/ErrorHandlingUtils';
import { FETCH_LIST_PRODUCT } from '@/ControllerApiHook/UniqIds/Supply/ProductKeys';
import { App } from 'antd';

export const useListFunctions = ({
  setSelectedRowsOnTable,
  patchProductStatus,
  deleteProduct,
}: IUseFunctions) => {
  const queryClient = useQueryClient();
  const app = App.useApp();

  const onSelectedRowsOnTable = useCallback(
    (x: IProductListData[]) => setSelectedRowsOnTable(x),
    [setSelectedRowsOnTable]
  );

  const onEdit = useCallback(
    (user: IProductListData[], values: any) =>
      patchProductStatus({
        produtosExternalId: user.map((x: IProductListData) => x.externalId),
        ativo: values.status === 'success',
      }),
    [patchProductStatus]
  );

  const onDelete = useCallback(
    async (product: IProductListData[]) => {
      try {
        const res = await deleteProduct.mutateAsync(
          {
            produtosExternalId: product.map((x) => x?.externalId),
          },
          {
            onError: handleModalError(app),
          }
        );

        if (res.success) queryClient.invalidateQueries([FETCH_LIST_PRODUCT]);
      } catch {}
    },
    [deleteProduct, app, queryClient]
  );

  return {
    onSelectedRowsOnTable,
    onEdit,
    onDelete,
  };
};
