import React from 'react';
import { Row, Col } from 'antd';
import { useTranslation } from 'react-i18next';
import { TextInputWithMask } from '@components/Inputs/TextInputWithMask';
import { Tooltip } from '@/Components';
import DateUtils from '@/Utils/DateUtils';
import StorageUtils from '@/Utils/StorageUtils';

interface ICalendarInputProps {
  name: string;
  placeHolder: string;
  label: string;
  error?: boolean;
  errorMessage?: string;
}

const SecondRow: React.FC = () => {
  const { t } = useTranslation();

  const preferences = StorageUtils.getPreference();

  const configPlaceHolder = () => {
    if (preferences) {
      return DateUtils.getDatePlaceholder(
        preferences.idioma,
        preferences.padraoData
      );
    }
  };

  return (
    <Row gutter={[16, 0]}>
      <Col span={4}>
        <CalendarInput
          name="dataEmissao"
          label={t('purchasing.invoice.create.invoiceIssueDate')}
          placeHolder={configPlaceHolder() ?? ''}
        />
      </Col>
      <Col span={4}>
        <CalendarInput
          name="dataEntrega"
          placeHolder={configPlaceHolder() ?? ''}
          label={t('purchasing.invoice.create.invoiceDeliveryDate')}
        />
      </Col>
      <Col span={4}>
        <CalendarInput
          name="dataLancamento"
          placeHolder={configPlaceHolder() ?? ''}
          label={t('purchasing.invoice.create.invoiceThrownDate')}
        />
      </Col>
    </Row>
  );
};

const CalendarInput = ({
  name,
  placeHolder,
  label,
  error,
  errorMessage,
}: ICalendarInputProps) => {
  return (
    <>
      <Tooltip title={errorMessage} showMe={error}>
        <TextInputWithMask
          name={name}
          leftIcon="calendar"
          placeHolder={placeHolder}
          label={label}
          mask="dataMask"
          error={error}
          withTooltip={{
            icon: 'question-mark',
            title: 'titulo',
          }}
          withStatusIcon={error}
          disabled
        />
      </Tooltip>
    </>
  );
};

export default SecondRow;
