export const purchasingEN = {
  purchaseOrder: {
    newButton: 'New Purchase Order',
    list: {
      searchPlaceholder: 'Search by an order, provider, date, status, etc.',
      purchaseOrderNumber: 'Order Nº.',
      provider: 'Provider',
      releaseDate: 'Delivery date',
      deliveryForecastDate: 'Delivery Forecast',
      amount: 'Amount',
      status: 'Status',

      statusType: [
        'Waiting for Approval',
        'Disapproved',
        'Released',
        'Canceled',
        'Confirmed with Provider',
        'Partial Received',
        'Total Received',
        'Drafit',
      ],

      statusDescriptionTooltips: [
        'Product is under review',
        'Product was rejected',
        'Product was approved',
        'Product has been cancelled',
        'Product was received',
      ],

      statusHistoryTooltips: [
        'Order created',
        'Status changed to: Awaiting Approval',
        'Status changed to: Disapproved',
        'Status changed to: Released',
        'Status changed to: Canceled',
        'Status changed to: Confirmed with Supplier',
        'Status changed to: Partial Received',
        'Status changed to: Received Total',
      ],

      statusTypeDescription: [
        '{{nomeUsuario}} sent for approval',
        '{{nomeUsuario}} disapproved the purchase order',
        '{{nomeUsuario}} approved {{itensAprovados}} of {{totalItens}} products in the purchase order',
        '{{nomeUsuario}} canceled the purchase order',
        '{{nomeUsuario}} confirmed the purchase order with the supplier',
        '{{nomeUsuario}} received {{itensAprovados}} from {{totalItens}} products in the purchase order',
        '{{nomeUsuario}} received {{itensAprovados}} from {{totalItens}} products in the purchase order',
        'Drafit',
        '{{nomeUsuario}} reversed the purchase order',
      ],
      invalidStatusId: 'Status invalid/unknown',
      defaultCreateStatus: '{{nomeUsuario}} created the purchase order',
    },

    successMessageToasts: {
      create: 'Purchase order successfully created!',
      edit: 'Purchase order successfully edited!',
      reprove: 'Purchase order disapproved successfully!',
      approve: 'Purchase order approved successfully!',
      reverse: 'Purchase order reversed successfully!',
      cancel: 'Purchase order canceled successfully!',
      confirmWithProvider: 'Purchase order successfully confirmed!',
      createDraft: 'Successfully created draft!',
      editDraft: 'Successfully edited draft!',
      deleteDraft: 'Successfully deleted draft!',
    },

    disabledMessageTooltips: {
      editReproveOrder:
        'It is not possible to edit purchase orders that have already been disapproved',
      cancelReproveOrder:
        'It is not possible to cancel purchase orders that have already been disapproved',
      editCancelOrder:
        'It is not possible to edit purchase orders that have already been cancelled',
      deleteCancelOrder:
        'Unable to delete purchase orders that have been cancelled',
      editConfirmWithProviderOrder:
        'It is not possible to edit purchase orders that have already been confirmed with the supplier',
      cancelConfirmWithProviderOrder:
        'It is not possible to cancel purchase orders that have already been confirmed with the supplier',
      editReleased:
        'It is not possible to edit purchase orders that have already been released',
      cancelReleased:
        'It is not possible to cancel purchase orders that have already been released',
      editRecivedInFull:
        'It is not possible to edit purchase orders that have already been fully received',
      cancelRecivedInFull:
        'It is not possible to cancel purchase orders that have already been received in full',
    },

    create: {
      errors: {
        requiredProvider: 'Provider is required',
        requiredDate: 'Date is required',
        dateIsEarlier: 'Delivery forecast cannot be prior to the current day.',
        requiredProduct: 'Product is required',
        requiredUnitaryValue: 'Value is required',
        requiredDiscount: 'Discount is required',
        requiredAmount: 'Amount is required',
        discountCannotBeGreater: 'Discount cannot be greater',
      },

      info: 'Information',
      orderItems: 'Order Items',
      subLabelProduct: 'Code',
      provider: 'Provider',
      corporateName: 'Corporate Name',
      cnpj: 'CNPJ',
      mainContact: 'Main Contact',
      deliveryForecast: 'Delivery Forecast',
      comments: 'Comments',
      dangerMessage: 'Enter at least one product to save the purchase order.',
      labelTotalValueName: 'Final Value',
      total: 'Total',
      unitaryDiscount: 'Discounts',
      productValues: 'Value',

      placeHolder: {
        provider: 'Pesquise por um fornecedor',
        comments: 'Comments',
      },

      toolTips: {
        mainContact: 'Information main contact',
      },

      productsCardField: {
        product: 'Product',
        unitaryValue: 'Value Unitary',
        unValue: 'Value Un.',
        discount: 'Discount',
        unitaryDiscount: 'Discount Unitary',
        amount: 'Amount',
        total: 'Total',
      },

      productsCardPlaceHolders: {
        product: 'Search for a product',
        unitaryValue: '$ 0.00',
        discountValue: '$ 0.00',
        discountPercentage: '0.00%',
        amount: '0.0000',
        total: '$ 0.00',
      },

      actions: {
        cancel: 'Cancel',
        save: 'Save',
        register: 'Register',
        registerLoading: 'Registering',
        addProduct: 'Add Product',
      },

      discountType: {
        discountValues: 'U$',
        discountPercentage: '%',
      },

      totalCard: {
        totalProducts: 'Total Products',
        totalDiscounts: 'Total Discounts',
        totalOrder: 'Total Order',
        freightCost: 'Freight',
        additionalValue: 'Additional Value',
        fullDiscount: 'Discount',
        totalValues: 'Amount',
      },
    },

    modal: {
      cannotDelete: {
        title: 'Unable to delete purchase order “#{{orderPurchaseNumber}}”',
        subTitle: 'Unable to delete purchase order #{{orderPurchaseNumber}}!',
        description:
          'This purchase order has already been approved, finalized or already had stock movement, and therefore it is not possible to delete it.',
        ok: 'Close',
      },
      reprove: {
        title: 'Disapprove the purchase order',
        description:
          'Are you sure you want to reject purchase order {{numeroPedido}} from supplier {{fornecedor}}? All products will be disapproved and it will not be possible to reopen the purchase order.',
      },
      released: {
        title: 'Reverse purchase order',
        description:
          'Are you sure you want to reverse the purchase order {{numeroPedido}} from supplier {{fornecedor}}? The order status will be changed to {{status}}.',
      },
      cancel: {
        title: 'Cancel purchase order',
        description:
          'Are you sure you want to cancel purchase order {{numeroPedido}} from supplier {{fornecedor}}? All products will be canceled and it will not be possible to reopen the purchase order.',
      },
      confirmWithSupplier: {
        title: 'Confirm purchase with supplier',
        description:
          'Are you sure you want to confirm the purchase order {{numeroPedido}} with the supplier {{fornecedor}}? Confirm the purchase order amounts and delivery forecast with your supplier:',
      },
      approve: {
        title: 'Approve purchase order',
        attentionMessage:
          'Products not selected will have the status of “Rejected”.',
        dangerMessage:
          'To approve a purchase order, you must select at least one product.',
      },
    },
  },

  provider: {
    newButton: 'New Supplier',
    list: {
      searchPlaceholder: 'Search for a supplier',
      nameOfSupplier: 'Name of Supplier',
      cnpjOrCpf: 'CNPJ/CPF',
      mainContact: 'Main contact',
      typePerson: 'Type Person',
      status: 'Status',
    },

    modal: {
      cannotDelete: {
        title: 'Unable to delete supplier “{{fornecedor}}”',
        batchTitle: 'Unable to delete {{fornecedor}} suppliers',

        subTitle: 'Unable to delete supplier {{fornecedor}}!',
        batchSubTitle: 'Unable to delete suppliers:',

        description:
          'This supplier has already had movement within the system, it is associated with a batch or accounts payable and therefore it is not possible to exclude it.',
        batchDescription:
          'These suppliers have already had movements within the system, they are associated with a batch or accounts payable and therefore it is not possible to exclude them.',

        warningFooter:
          'If you no longer want to use this supplier, change its status to “Inactive”.',
        batchWarningFooter:
          'If you no longer want to use these suppliers, change their status to “Inactive”.',
      },
    },

    details: {
      breadCrumb: {
        title: 'Supplier',
      },
      tabs: {
        details: 'Details',
        purchaseOrder: 'Purchase requests',
        productBatches: 'Product batches',
      },
      status: {
        confirmationTitle: 'Deactivate supplier?',
        confirmationQuestion:
          'Are you sure you want to inactivate supplier {{fornecedor}}?',
      },
      cards: {
        infoCard: {
          editDetail: 'Edit details',
          personType: 'Type of person',
          cnpj: 'CNPJ',
          cpf: 'CPF',
          corporateName: 'Corporate name',
          fantasyName: 'Fantasy name',
          fullName: 'Full name',
          supplierType: 'Type of supplier',
          observations: 'Observations',
        },
        contactCard: {
          title: 'Contacts',
          obs: 'Obs:',
        },
        adressCard: {
          title: 'Adresses',
        },
        documentCard: {
          title: 'Documents',
        },
      },
      successMessage: {
        addProvider: 'Successfully registered supplier!',
        addContact: 'Added Supplier contact!',
        addAddress: 'Added Supplier address!',
        addDocument: 'Added Supplier document!',
        editInfo: 'Edited Supplier information!',
        editContact: 'Edited Supplier contact!',
        editFavoriteContact: 'Favorite contact changed!',
        editAddress: 'Edited Supplier address!',
        editFavoriteAddress: 'Favorite address changed!',
        editDocument: 'Edited Supplier document!',
        deleteContact: 'Supplier contact deleted!',
        deleteAddress: 'Supplier address deleted!',
        deleteDocument: 'Supplier document deleted!!',
      },
    },

    create: {
      errors: {
        requiredProviderType: 'Provider type is required',
        requiredName: 'Name is required',
        requiredCnpj: 'Enter a valid CNPJ',
        requiredCpf: 'Enter a valid CPF',
        cpfAlreadyUsed: 'CPF already registered',
        cnpjAlreadyUsed: 'CNPJ already registered',
      },

      info: 'Info',
      contact: 'Contacts',
      address: 'Addresses',

      pf: 'Person',
      pj: 'Company',

      type: 'Provider type',
      typePlaceholder: 'Select a provider type',

      fantasyName: 'Fantasy Name',
      fantasyNamePlaceholder: 'Ex: Farmácia Souza e Filhos',

      fullName: 'Full name',
      fullNamePlaceholder: 'E.g: Marcos Almeida',

      social: 'Legal Name',
      socialPlaceholder: 'Ex: FARMACIA SOUZA E FILHOS LTDA',

      cnpj: 'CNPJ',
      cnpjPlaceholder: 'Ex: 42.163.509/0001-03',

      cpf: 'CPF',
      cpfPlaceholder: 'Ex: 123.123.123-02',

      obs: 'Notes',
      obsPlaceholder: 'Add extra notes...',

      contactTable: {
        type: 'Contact Type',
        contact: 'Contact',
        obs: 'Notes',
      },

      addressTable: {
        desc: 'Description',
        address: 'Address',
      },

      addressModal: {
        addAddress: 'Add address',
        save: 'Save',

        title: 'Add address',
        editTitle: 'Edit address',

        desc: 'Descriptions',
        descPlaceholder: 'Ex: Principal',

        cep: 'CEP',
        cepPlaceholder: '00000-000',

        street: 'Street',
        streetPlaceholder: 'Ex: Street 123',

        number: 'Number',
        numberPlaceholder: 'Ex: 465',

        extra: 'Extra',
        extraPlaceholder: 'Ex: Room 01',

        hood: 'Hood',
        hoodPlaceholder: 'Ex: Center',

        city: 'City',
        cityPlaceholder: 'Choose a city',

        state: 'State',
        statePlaceholder: 'Choose a state',
      },

      documentModal: {
        title: 'Add document',

        type: 'Document Type',
        typePlaceholder: 'Select a document type',

        document: 'Document',
        documentPlaceholder: 'Document',

        desc: 'Notes',
        descPlaceholder: 'Add a note...',

        add: 'Create',
        save: 'Save',
        cancel: 'Cancel',
      },

      contactModal: {
        addAddress: 'Add contacts',
        title: 'Add contact',
        editTitle: 'Edit contact',

        contactType: 'Contact Type',
        contact: 'Contact',

        obs: 'Notes',
        obsPlaceholder: 'Add a note...',

        ok: 'Add',
        save: 'Save',
        cancel: 'Cancel',

        errors: {
          required: 'This is a required field',
        },
      },
    },
  },

  invoice: {
    newButton: 'New Invoice',
    list: {
      searchPlaceholder:
        'Search by a provider, invoice number, issue/launch date or status',
      nameOfSupplier: 'Supplier name',
      serialNumber: 'Nº/Serial',
      releaseDate: 'Release Date',
      dateIssue: 'Issue Date',
      invoiceValue: 'Invoice Value',
      status: 'Status',
      statusTypes: {
        finished: 'Finished',
        released: 'Released',
        pending: 'Pending',
        draft: 'Draft',
      },
    },

    successMessageToasts: {
      create: 'Invoice successfully created!',
      createDraft: 'Draft successfully created!',
      editDraft: 'Draft successfully edited!',
      deleteDraft: 'Draft successfully deleted!',
      editInvoice: 'Invoice successfully edited!',
      createLot: 'Lot successfully created!',
      createLotDraft: 'Draft successfully created!',
      editLotDraft: 'Draft successfully edited!',
    },

    modal: {
      cannotDelete: {
        title: 'Unable to delete invoice',
        batchTitle: 'Unable to delete {{notas}} Invoices',

        subTitle:
          'Unable to delete invoice number {{numero}} series {{serie}} from {{fornecedor}}',
        batchSubTitle: 'Unable to delete invoices:',

        description:
          'This invoice is already associated with a batch or accounts payable and therefore it is not possible to delete it.',
        batchDescription:
          'These invoices are already associated with a batch or accounts payable and therefore it is not possible to delete them.',
      },

      foundPurchaseOrderModal: {
        titleSingle: 'Purchase order found',
        descriptionSingle:
          'The purchase order {{numero}} was found open for the supplier {{nomeFornecedor}} in the amount of {{totalPedido}}. Do you want to import the products from the purchase order?',

        titleMany: 'Purchase orders found',
        descriptionMany:
          'Were found {{numeroPedidos}} open purchase orders for the supplier {{nomeFornecedor}}. Do you want to continue to import a purchase order?',
      },

      changeSupplier: {
        title: 'Change Supplier',
        description:
          'Are you sure you want to change the supplier {{nomeFornecedor}} from this invoice? The purchase order will be unlinked and you will need to add other purchase orders.',
        okButtonDescription: 'Change Supplier',
      },

      removePurchaseOrderModal: {
        title: 'Unlink purchase order ',
        description:
          'Are you sure you want to unlink the purchase order {{numero}} from the invoice? If unlinked, the products in the purchase order will not be written off.',
        unlink: 'Unlink',
      },
    },

    create: {
      errors: {
        requiredProvider: 'Provider is required',
        requiredProduct: 'Product is required',
        requiredUnitaryValue: 'Value is required',
        requiredAmount: 'Amount is required',
        requiredNumber: 'Number is required',
        requiredSeries: 'Series is required',
        requiredInvoiceIssueDate: 'Issue date is required',
        requiredInvoiceDeliveryDate: 'Delivery date is required',
        requiredInvoiceCarrier: 'Carrier is required',
        requiredInvoiceFreight: 'Freight is required',
        requiredNatureOperation: 'Nature of operation is required',
        requiredInvoiceProductCode: 'Product code is required',
        requiredNcm: 'NCM is required',
        requiredQuantity: 'Quantity is required',
        requiredUnitValue: 'Unit value is required',
        requiredIcmsBase: 'ICMS base is required',
        requiredIcmsValue: 'ICMS value is required',
        requiredCFOP: 'CFOP is required',
        requiredCST: 'CST is required',
        requiredIpiValue: 'IPI value is required',
        requiredAliquotIPI: 'IPI rate is required',
      },

      info: 'Information',
      invoiceModel: 'Invoice model',
      invoiceNumber: 'Invoice number',
      invoiceSeries: 'Invoice series',
      natureOfOperation: 'Nature of operation',
      invoiceIssueDate: 'Issue date',
      invoiceDeliveryDate: 'Invoice delivery date',
      invoiceThrownDate: 'Invoice thrown date',
      provider: 'Provider',
      socialReason: 'Social reason',
      cnpj: 'CNPJ',
      carrierCode: 'Carrier',
      frightType: 'Fright type',
      complementaryInformation: 'Complementary information',
      purchaseOrder: 'Purchase order',
      invoiceIssueDateError:
        'The issue date cannot be greater than the delivery date',
      invoiceDeliveryDateError:
        'The delivery date cannot be less than the issue date',
      searchForPurchase: 'Search for a purchase order',
      selectPurchaseOrder: 'Select a purchase order',
      noPurchaseOrder: 'No purchase orders',
      selected: 'selected',
      singlePurchaseOrder: 'Purchase order',
      manySelected: 'selected',
      manyPurchaseOrder: 'Purchase orders',

      value: 'Values',
      ICMSBase: 'ICMS Base',
      ICMSValue: 'ICMS Value',
      ICMSBaseSubstitution: 'ICMS Base Substitution',
      ICMSValueSubstitution: 'ICMS Value Substitution',
      totalProducts: 'Total Products',
      freight: 'Freight',
      insurance: 'Insurance',
      discount: 'Discount',
      otherExpenses: 'Other Expenses',
      IPIValue: 'IPI Value',
      totalInvoice: 'Total Invoice',
      totalInvoiceTooltip:
        'Total invoice is calculated by the sum of the values of the products, freight, insurance, discount and other expenses',

      products: 'Products',
      product: 'Product',
      productCode: 'Product Code',
      NCM: 'NCM/SH',
      CST: 'CST/CSOSN',
      CFOP: 'CFOP',
      unitValue: 'Unit Value',
      quantity: 'Quantity',
      total: 'Total',
      calcICMS: 'ICMS Base',
      ICMSVal: 'ICMS Value',
      IPIVal: 'IPI Value',
      aliquota: 'IPI Rate',
      remove: 'remove',
      removeQuestion: 'Are you sure you want to remove',
      andMore: 'and more',

      releaseDate: 'Release date',
      requestNumber: 'Request N°',
      deliveryForecast: 'Delivery forecast',
      totalValuePurchaseOrder: 'Total value',

      placeHolder: {
        provider: 'Search for a provider',
        searchForCarrier: 'Search for a carrier',
        complementaryInformation:
          'Additional information of interest to the taxpayer',
        invoiceModelValue: '55 - Electronic Invoice (NF-e)',
        natureOfOperation: 'Search for a nature of operation',
        noPurchaseOrder: 'No purchase orders selected',

        CurrencyPlaceHolder: 'Eg: BRL 0.00',

        NCMPlaceHolder: '99999999',
        CSTPlaceHolder: '99',
        CFOPPlaceHolder: '9999',
        searchForProduct: 'Search for a product',
        quantityPlaceHolder: '0,000',
      },
    },

    details: {
      details: 'Details',
      lots: 'Lots',
      throwLots: 'Throw Lots',
      exclude: 'Exclude',
      moreActions: 'More Actions',
      cantEditInvoiceWithThrownLots: 'Cannot edit an invoice with lots thrown',

      lotNotThrew: 'Lot not thrown yet.',
      lotThrew: 'Lot thrown, but without movement.',
      lotThrewAndMoving: 'Lot thrown and moved.',
      unitValueToolTip: 'Unit Value',
      baseIcmsToolTip: 'ICMS Base calc',

      throwLotsModalTitle: 'Throw Lots?',
      throwLotsModalQuestion:
        'Are you sure you want to throw the lots of the invoice?',
      throwLotsModalOk: 'Throw Lots',
    },

    edit: {
      originalNumber: 'Original Invoice Number: ',
      originalSerie: 'Original Invoice Series: ',
      originalNatureOperation: 'Original Nature of Operation: ',
      originalDateIssue: 'Original Issue Date: ',
      originalDateDelivery: 'Original Delivery Date: ',
      originalCarrier: 'Original Carrier: ',
      originalFrightType: 'Original Freight Type: ',
      originalProvider: 'Original Provider: ',
      originalPurchaseOrder: 'Original Purchase Orders: ',
      originalIcmsBase: 'Original ICMS Base: ',
      originalIcmsValue: 'Original ICMS Value: ',
      originalIcmsBaseSubstitution: 'Original ICMS Substitution Base: ',
      originalIcmsValueSubstitution: 'Original ICMS Substitution Value: ',
      originalTotalProducts: 'Original Total Products: ',
      originalFreight: 'Original Freight: ',
      originalInsurance: 'Original Insurance: ',
      originalDiscount: 'Original Discount: ',
      originalOtherExpenses: 'Original Other Expenses Value: ',
      originalIpiValue: 'Original IPI Value: ',
      originalTotalInvoice: 'Original Total Invoice Amount: ',
    },

    registerLots: {
      lotsRegisterTitle: 'Lots register | Invoice: ',
      stockLocations: 'Stock Locations',
      lotsCardTitle: 'Lots',
      lotsCardHeader: {
        product: 'Product',
        quantity: 'Quantity',
        lot: 'Lot',
        manufacturingDate: 'Manufacturing Date',
        expiryDate: 'Expiry Date',
      },

      supplyPlacesPlaceholder: 'Select a supply place',
      lotPlaceHolder: 'Lot',
      lotsDetails: "Lot's technical information",

      manufacturingDateError:
        'Manufacturing date cannot be greater than expiry date',
      expiryDateError: 'Expiry date cannot be less than manufacturing date',
      releaseDateError:
        'The expiration date cannot be earlier than the release date',
      releaseDateErrorManufacturing:
        'The release date cannot be earlier than the manufacturing date',

      quantityError: 'excess of {{excess}} from the original value.',
      deficitError: 'deficit of {{deficit}} from the original value.',
      lotNameError: 'This lot already exists in this Invoice.',

      showDetails: 'Show Details',
      hideDetails: 'Hide Details',

      technicalInformationInputs: {
        product: 'Product',
        productClass: 'Product Class',
        quantity: 'Quantity',
        lot: 'Lot',
        manufacturingDate: 'Manufacturing Date',
        expiryDate: 'Expiry Date',
        stockLocations: 'Stock Locations',
        labelsQuantity: 'Labels Quantity',
        originCountry: 'Origin Country',
        density: 'Density',
        dilution: 'Dilution (%)',
        dilutionFactory: 'Dilution Factor',
        waterConcentration: 'Water Concentration (%)',
        waterConcentrationFactory: 'Water Concentration Factor',

        lotPlaceHolder: 'Lot',

        tooltips: {
          dilution:
            'Percentage of Dilution as stated in the Supplier Certificate of Analysis. This value typically ranges from 1% to 100%.',
          dilutionFactory:
            'Factor resulting from the Supplier Dilution. Example: If the Supplier Dilution is 40%, the Dilution Factor will be 2.5, i.e., 100 / 40.',
          waterConcentration:
            'Concentration/Water Content as stated in the Supplier Certificate of Analysis. This value typically ranges from 1% to 17%.',
          waterConcentrationFactory:
            'Factor resulting from Water Concentration. Example: If the Water Concentration is 8.5%, the Water Concentration Factor will be 1.093, i.e., 100 / (100 - 8.5).',
        },
      },

      button: {
        save: 'Release Lots',
      },
    },
  },
};

export const purchasingPT = {
  purchaseOrder: {
    newButton: 'Novo Pedido de Compra',
    list: {
      searchPlaceholder:
        'Pesquise por um pedido, fornecedor, data, status, etc',
      purchaseOrderNumber: 'Nº Pedido',
      provider: 'Fornecedor',
      releaseDate: 'Data de Lançamento',
      deliveryForecastDate: 'Previsão de Entrega',
      amount: 'Valor Total',
      status: 'Status',

      statusType: [
        'Aguardando Aprovação',
        'Reprovado',
        'Liberado',
        'Cancelado',
        'Confirmado com Fornecedor',
        'Recebido Parcialmente',
        'Recebido Total',
        'Rascunho',
      ],

      statusDescriptionTooltips: [
        'Produto está em análise',
        'Produto foi reprovado',
        'Produto foi aprovado',
        'Produto foi cancelado',
        'Produto foi recebido',
      ],

      statusHistoryTooltips: [
        'Pedido criado',
        'Status mudado para: Aguardando Aprovação',
        'Status mudado para: Reprovado',
        'Status mudado para: Liberado',
        'Status mudado para: Cancelado',
        'Status mudado para: Confirmado com Fornecedor',
        'Status mudado para: Recebido Parcial',
        'Status mudado para: Recebido Total',
      ],

      statusTypeDescription: [
        '{{nomeUsuario}} enviou para aprovação',
        '{{nomeUsuario}} reprovou o pedido de compra',
        '{{nomeUsuario}} aprovou {{itensAprovados}} de {{totalItens}} produtos do pedido de compra',
        '{{nomeUsuario}} cancelou o pedido de compra',
        '{{nomeUsuario}} confirmou o pedido de compra com o fornecedor',
        '{{nomeUsuario}} recebeu {{itensAprovados}} de {{totalItens}} produtos do pedido de compra',
        '{{nomeUsuario}} recebeu {{itensAprovados}} de {{totalItens}} produtos do pedido de compra',
        'Rascunho',
        '{{nomeUsuario}} estornou o pedido de compra',
      ],

      invalidStatusId: 'Status inválido/desconhecido',
      defaultCreateStatus: '{{nomeUsuario}} criou o pedido de compra',
    },

    successMessageToasts: {
      create: 'Pedido de compra criado com sucesso!',
      edit: 'Pedido de compra editado com sucesso!',
      reprove: 'Pedido de compra reprovado com sucesso!',
      approve: 'Pedido de compra aprovado com sucesso!',
      reverse: 'Pedido de compra estornado com sucesso!',
      cancel: 'Pedido de compra cancelado com sucesso!',
      confirmWithProvider: 'Pedido de compra confirmado com sucesso!',
      createDraft: 'Rascunho criado com sucesso!',
      editDraft: 'Rascunho editado com sucesso!',
      deleteDraft: 'Rascunho deletado com sucesso!',
    },

    disabledMessageTooltips: {
      editReproveOrder:
        'Não é possível editar pedidos de compra que já foram reprovados',
      cancelReproveOrder:
        'Não é possível cancelar pedidos de compra que já foram reprovados',
      editCancelOrder:
        'Não é possível editar pedidos de compra que já foram cancelados',
      deleteCancelOrder:
        'Não é possível excluir pedidos de compra que foram cancelados',
      editConfirmWithProviderOrder:
        'Não é possível editar pedidos de compra que já foram confirmados com o fornecedor',
      cancelConfirmWithProviderOrder:
        'Não é possível cancelar pedidos de compra que já foram confirmados com o fornecedor',
      editReleased:
        'Não é possível editar pedidos de compra que já foram liberados',
      cancelReleased:
        'Não é possível cancelar pedidos de compra que já foram liberados',
      editRecivedInFull:
        'Não é possível editar pedidos de compra que já foram recebidos totalmente',
      cancelRecivedInFull:
        'Não é possível cancelar pedidos de compra que já foram recebidos totalmente',
    },

    create: {
      errors: {
        requiredProvider: 'Fornecedor é obrigatório',
        requiredDate: 'Data é obrigatória',
        dateIsEarlier:
          'A previsão de entrega não pode ser anterior ao dia atual.',
        requiredProduct: 'Produto é obrigatório',
        requiredUnitaryValue: 'Valor é obrigatório',
        requiredDiscount: 'Desconto é obrigatório',
        requiredAmount: 'Quantidade é obrigatório',
        discountCannotBeGreater:
          'Desconto não pode ser maior que valor do produto.',
      },
      info: 'Informações',
      orderItems: 'Itens do Pedido',
      subLabelProduct: 'Código',
      provider: 'Fornecedor',
      corporateName: 'Razão Social',
      cnpj: 'CNPJ',
      mainContact: 'Contato Principal',
      deliveryForecast: 'Previsão de Entrega',
      comments: 'Observações',
      dangerMessage:
        'Insira ao menos um produto para salvar o pedido de compra.',
      labelTotalValueName: 'Valor Final',
      total: 'Total',
      unitaryDiscount: 'Descontos',
      productValues: 'Valor',

      placeHolder: {
        provider: 'Pesquise por um fornecedor',
        comments: 'Observações',
      },

      toolTips: {
        mainContact: 'Informações do contato principal',
      },

      productsCardField: {
        product: 'Produto',
        unitaryValue: 'Valor Unitário',
        unValue: 'Valor Un.',
        discount: 'Desconto',
        unitaryDiscount: 'Desconto Unitário',
        amount: 'Quantidade',
        total: 'Total',
      },

      productsCardPlaceHolders: {
        product: 'Pesquise por um produto',
        unitaryValue: 'R$ 0,00',
        discountValue: 'R$ 0,00',
        discountPercentage: '0,00%',
        amount: '0,0000',
        total: 'R$ 0,00',
      },

      actions: {
        cancel: 'Cancelar',
        save: 'Salvar',
        register: 'Cadastrar',
        registerLoading: 'Cadastrando',
        addProduct: 'Adicionar Produto',
      },

      discountType: {
        discountValues: 'R$',
        discountPercentage: '%',
      },

      totalCard: {
        totalProducts: 'Total Produtos',
        totalDiscounts: 'Total Descontos',
        totalOrder: 'Total Pedido',
        freightCost: 'Frete',
        additionalValue: 'Valor Adicional',
        fullDiscount: 'Desconto',
        totalValues: 'Valor total',
      },
    },

    modal: {
      cannotDelete: {
        title:
          'Não foi possível excluir o pedido de compra “#{{orderPurchaseNumber}}”',
        subTitle:
          'Não é possível excluir o pedido de compra #{{orderPurchaseNumber}}!',
        description:
          'Esse pedido de compra já foi aprovado, finalizado ou já teve movimentação de estoque, e por isso não é possível a sua exclusão.',
        ok: 'Fechar',
      },
      reprove: {
        title: 'Reprovar o pedido de compra',
        description:
          'Você tem certeza que deseja reprovar o pedido de compra {{numeroPedido}} do fornecedor {{fornecedor}}? Todos os produtos serão reprovados e não será possível reabrir o pedido de compra.',
      },
      released: {
        title: 'Estornar pedido de compra',
        description:
          'Você tem certeza que deseja estornar o pedido de compra {{numeroPedido}} do fornecedor {{fornecedor}}? O status do pedido será alterado para {{status}}.',
      },
      cancel: {
        title: 'Cancelar pedido de compra',
        description:
          'Você tem certeza que deseja cancelar o pedido de compra {{numeroPedido}} do fornecedor {{fornecedor}}? Todos os produtos serão cancelados e não será possível reabrir o pedido de compra.',
      },
      confirmWithSupplier: {
        title: 'Confirmar compra com fornecedor',
        description:
          'Você tem certeza que deseja confirmar o pedido de compra {{numeroPedido}} com o fornecedor {{fornecedor}}? Confirme os valores do pedido de compra e a previsão de entrega com seu fornecedor:',
      },
      approve: {
        title: 'Aprovar pedido de compra',
        attentionMessage:
          'Os produtos não selecionados ficarão com o status de “Reprovados”.',
        dangerMessage:
          'Para aprovar um pedido de compra é necessário selecionar ao menos um produto.',
      },
    },
  },

  provider: {
    newButton: 'Novo Fornecedor',
    list: {
      searchPlaceholder: 'Pesquise por um fornecedor',
      nameOfSupplier: 'Nome do Fornecedor',
      cnpjOrCpf: 'CNPJ/CPF',
      mainContact: 'Contato principal',
      typePerson: 'Tipo Pessoa',
      status: 'Status',
    },

    modal: {
      cannotDelete: {
        title: 'o fornecedor',
        batchTitle: 'fornecedores',

        batchSubTitle: 'Não é possível excluir os fornecedores:',

        description:
          'Esse fornecedor já teve movimentação dentro do sistema, está associado a algum lote ou contas a pagar e por isso não é possível a sua exclusão.',
        batchDescription:
          'Esses fornecedores já tiveram movimentações dentro do sistema, estão associados a algum lote ou contas a pagar e por isso não é possível suas exclusões.',

        warningFooter:
          'Caso não queira mais utilizar esse fornecedor, mude o status dele para “Inativo”.',
        batchWarningFooter:
          'Caso não queira mais utilizar esses fornecedores, mude os status deles para “Inativo”.',
      },
    },

    details: {
      breadCrumb: {
        title: 'Fornecedor',
      },
      tabs: {
        details: 'Detalhes',
        purchaseOrder: 'Pedidos de compra',
        productBatches: 'Lotes de produto',
      },
      status: {
        confirmationTitle: 'Inativar fornecedor?',
        confirmationQuestion:
          'Você tem certeza que deseja inativar o fornecedor {{fornecedor}}?',
      },
      cards: {
        infoCard: {
          editDetail: 'Editar detalhes',
          personType: 'Tipo de pessoa',
          cnpj: 'CNPJ',
          cpf: 'CPF',
          corporateName: 'Razão Social',
          fantasyName: 'Nome Fantasia',
          fullName: 'Nome completo',
          supplierType: 'Tipo de fornecedor',
          observations: 'Observações',
        },
        contactCard: {
          title: 'Contatos',
          obs: 'Obs:',
        },
        adressCard: {
          title: 'Endereços',
        },
        documentCard: {
          title: 'Documentos',
        },
      },
      successMessage: {
        addProvider: 'Fornecedor cadastrado com sucesso!',
        addContact: 'Contato do Fornecedor adicionado!',
        addAddress: 'Endereço do Fornecedor adicionado!',
        addDocument: 'Documento do Fornecedor adicionado!',
        editInfo: 'Informação do Fornecedor editada!',
        editContact: 'Contato do Fornecedor editado!',
        editFavoriteContact: 'Contato favorito alterado!',
        editAddress: 'Endereço do Fornecedor editado!',
        editFavoriteAddress: 'Endereço favorito alterado!',
        editDocument: 'Documento do Fornecedor editado!',
        deleteContact: 'Contato do Fornecedor deletado!',
        deleteAddress: 'Endereço do Fornecedor deletado!',
        deleteDocument: 'Documento do Fornecedor deletado!',
      },
    },

    create: {
      errors: {
        requiredProviderType: 'Tipo do fornecedor é obrigatório',
        requiredName: 'Nome é obrigatório',
        requiredCnpj: 'Insira um CNPJ válido',
        requiredCpf: 'Insira um CPF válido',
        cpfAlreadyUsed: 'CPF já cadastrado',
        cnpjAlreadyUsed: 'CNPJ já cadastrado',
      },

      info: 'Informações',
      contact: 'Contatos',
      address: 'Endereços',

      pf: 'Pessoa física',
      pj: 'Pessoa jurídica',

      type: 'Tipo de fornecedor',
      typePlaceholder: 'Selecione um tipo de fornecedor',

      fantasyName: 'Nome Fantasia',
      fantasyNamePlaceholder: 'Ex: Farmácia Souza e Filhos',

      fullName: 'Nome completo',
      fullNamePlaceholder: 'Ex: Marcos Almeida',

      social: 'Razão Social',
      socialPlaceholder: 'Ex: FARMACIA SOUZA E FILHOS LTDA',

      cnpj: 'CNPJ',
      cnpjPlaceholder: 'Ex: 42.163.509/0001-03',

      cpf: 'CPF',
      cpfPlaceholder: 'Ex: 123.123.123-02',

      obs: 'Observação',
      obsPlaceholder: 'Adicione uma observação...',

      contactTable: {
        type: 'Tipo de Contato',
        contact: 'Contato',
        obs: 'Observações',
      },

      addressTable: {
        desc: 'Descrição',
        address: 'Endereço',
      },

      addressModal: {
        addAddress: 'Adicionar endereço',
        save: 'Salvar',

        title: 'Adicionar endereço',
        editTitle: 'Editar endereço',

        desc: 'Descrição',
        descPlaceholder: 'Ex: Matriz',

        cep: 'CEP',
        cepPlaceholder: '00000-000',

        street: 'Logradouro',
        streetPlaceholder: 'Ex: Rua João Augusto de Moraes',

        number: 'Número',
        numberPlaceholder: 'Ex: 465',

        extra: 'Complemento',
        extraPlaceholder: 'Ex: Sala 01',

        hood: 'Bairro',
        hoodPlaceholder: 'Ex: Centro',

        city: 'Cidade',
        cityPlaceholder: 'Selecione uma cidade',

        state: 'Estado',
        statePlaceholder: 'Selecione um estado',
      },

      contactModal: {
        addAddress: 'Adicionar contato',
        title: 'Adicionar contato',
        editTitle: 'Editar contato',

        contactType: 'Tipo de Contato',
        contact: 'Contato',

        obs: 'Observações',
        obsPlaceholder: 'Adicione uma observação...',

        ok: 'Adicionar',
        save: 'Salvar',
        cancel: 'Cancelar',

        errors: {
          required: 'Este campo e obrigatório',
        },
      },

      documentModal: {
        title: 'Adicionar documento',
        editTitle: 'Editar documento',

        type: 'Tipo de Documento',
        typePlaceholder: 'Selecione um tipo de documento',

        document: 'Documento',
        documentPlaceholder: 'Documento',

        desc: 'Observações',
        descPlaceholder: 'Adicione uma observação...',

        add: 'Adicionar',
        save: 'Salvar',
        cancel: 'Cancelar',
      },

      documentTable: {
        title: 'Documentos',

        columns: {
          type: 'Tipo de Documento',
          document: 'Documento',
          desc: 'Observações',
        },
      },
    },
  },
  invoice: {
    newButton: 'Cadastrar NF',
    list: {
      searchPlaceholder:
        'Pesquise por um fornecedor, nº da NF, data de emissão/lançamento ou status',
      nameOfSupplier: 'Nome do Fornecedor',
      serialNumber: 'Nº/Série',
      releaseDate: 'Data Lançamento',
      dateIssue: 'Data Emissão',
      invoiceValue: 'Valor NF',
      status: 'Status',
      statusTypes: {
        finished: 'Finalizada',
        released: 'Lançada',
        pending: 'Pendente',
        draft: 'Rascunho',
      },
    },

    successMessageToasts: {
      create: 'Nota fiscal criada com sucesso',
      createDraft: 'Rascunho criado com sucesso!',
      editDraft: 'Rascunho editado com sucesso!',
      deleteDraft: 'Rascunho deletado com sucesso!',
      editInvoice: 'Nota fiscal editada com sucesso!',
      createLot: 'Lote criado com sucesso!',
      createLotDraft: 'Rascunho criado com sucesso!',
      editLotDraft: 'Rascunho editado com sucesso!',
    },

    modal: {
      cannotDelete: {
        title: 'Não foi possível excluir a Nota Fiscal',
        batchTitle: 'Não foi possível excluir {{notas}} Notas Fiscais',

        subTitle:
          'Não é possível excluir a Nota Fiscal número {{numero}} série {{serie}} do fornecedor {{fornecedor}}',
        batchSubTitle: 'Não é possível excluir as Notas Fiscais:',

        description:
          'Essa Nota Fiscal já está associada a algum lote ou contas a pagar e por isso não é possível a sua exclusão.',
        batchDescription:
          'Essas Notas Fiscais já estão associadas a algum lote ou contas a pagar e por isso não é possível suas exclusões.',
      },

      foundPurchaseOrderModal: {
        titleSingle: 'Pedido de compra encontrado',
        descriptionSingle:
          'Foi encontrado o pedido de compra {{numero}} em aberto para o fornecedor {{nomeFornecedor}} no valor de {{totalPedido}}. Deseja importar os produtos do pedido de compra?',

        titleMany: 'Pedidos de compra encontrados',
        descriptionMany:
          'Foram encontrados {{numeroPedidos}} pedidos de compra em aberto para o fornecedor {{nomeFornecedor}}. Deseja continuar para importar um pedido de compra?',
      },

      changeSupplier: {
        title: 'Alterar Fornecedor',
        description:
          'Você tem certeza que deseja alterar o fornecedor {{nomeFornecedor}} dessa nota fiscal? Os pedidos de compra serão desvinculados e você terá que vincular novos pedidos.',
        okButtonDescription: 'Alterar Fornecedor',
      },

      removePurchaseOrderModal: {
        title: 'Desvincular pedido de compra ',
        description:
          'Você tem certeza que deseja desvincular o pedido de compra {{numero}} do cadastro de Nota Fiscal? Caso seja desvinculado, não será dado baixa nos produtos do pedido de compra.',
        unlink: 'Desvincular',
      },
    },

    create: {
      errors: {
        requiredProvider: 'Fornecedor é obrigatório',
        requiredProduct: 'Produto é obrigatório',
        requiredUnitaryValue: 'Valor é obrigatório',
        requiredAmount: 'Quantidade é obrigatório',
        requiredNumber: 'Número da NF é obrigatório',
        requiredSeries: 'Série NF é obrigatório',
        requiredInvoiceIssueDate: 'Data de Emissão é obrigatório',
        requiredInvoiceDeliveryDate: 'Data de Entrega é obrigatório',
        requiredInvoiceCarrier: 'Transportadora é obrigatório',
        requiredInvoiceFreight: 'Tipo de Frete é obrigatório',
        requiredNatureOperation: 'Natureza de Operação é obrigatório',
        requiredInvoiceProductCode: 'Código do produto é obrigátorio',
        requiredNcm: 'NCM é obrigatório',
        requiredQuantity: 'Quantidade é obrigatório',
        requiredUnitValue: 'Valor unitario é obrigatório',
        requiredIcmsBase: 'Base de ICMS é obrigatório',
        requiredIcmsValue: 'Valor de ICMS é obrigatório',
        requiredCFOP: 'CFOP é obrigatório',
        requiredCST: 'CST/CSOSN é obrigatório',
        requiredIpiValue: 'Valor de IPI é obrigatório',
        requiredAliquotIPI: 'Alíquota IPI é obrigatório',
      },

      info: 'Informações',
      invoiceModel: 'Modelo NF',
      invoiceNumber: 'Número da NF',
      invoiceSeries: 'Série NF',
      natureOfOperation: 'Natureza da Operação',
      invoiceIssueDate: 'Data de Emissão',
      invoiceDeliveryDate: 'Data de Entrega',
      invoiceThrownDate: 'Data de Lançamento',
      provider: 'Fornecedor',
      socialReason: 'Razão Social',
      cnpj: 'CNPJ',
      carrierCode: 'Transportadora',
      frightType: 'Tipo de Frete',
      complementaryInformation: 'Informações Complementares',
      purchaseOrder: 'Pedidos de Compra',
      invoiceIssueDateError:
        'A data de emissão não pode ser maior que a data de entrega',
      invoiceDeliveryDateError:
        'A data de entrega não pode ser menor que a data de emissão',
      searchForPurchase: 'Pesquise por um pedido',
      selectPurchaseOrder: 'Selecione pedidos de compra',
      noPurchaseOrder: 'Nenhum pedido de compra',
      selected: 'selecionado',
      singlePurchaseOrder: 'Pedido de compra',
      manySelected: 'selecionados',
      manyPurchaseOrder: 'Pedidos de compra',

      value: 'Valores',
      ICMSBase: 'Base de ICMS',
      ICMSValue: 'Valor do ICMS',
      ICMSBaseSubstitution: 'Base de ICMS Substituição',
      ICMSValueSubstitution: 'Valor do ICMS Substituição',
      totalProducts: 'Total Produtos',
      freight: 'Frete',
      insurance: 'Seguro',
      discount: 'Desconto',
      otherExpenses: 'Outras Despesas Acessórias',
      IPIValue: 'Valor de IPI',
      totalInvoice: 'Total Nota Fiscal',
      totalInvoiceTooltip:
        'Total da Nota Fiscal é calculado pela soma do total dos Produtos + Frete + Seguro + Desconto + outras despesas',

      products: 'Produtos',
      product: 'Produto',
      productCode: 'Cód. Prod',
      NCM: 'NCM/SH',
      CST: 'CST/CSOSN',
      CFOP: 'CFOP',
      unitValue: 'Valor Un.',
      quantity: 'Quantidade',
      total: 'Total',
      calcICMS: 'B. Cálc. ICMS',
      ICMSVal: 'Valor ICMS',
      IPIVal: 'Valor IPI',
      aliquota: 'Alíquota IPI',
      remove: 'Remover',
      removeQuestion: 'Tem certeza que deseja remover',
      andMore: 'e mais',

      releaseDate: 'Data lançamento',
      requestNumber: 'Nº Pedido',
      deliveryForecast: 'Previsão de entrega',
      totalValuePurchaseOrder: 'Valor total',

      placeHolder: {
        provider: 'Pesquise por um fornecedor',
        searchForCarrier: 'Pesquise por uma transportadora',
        complementaryInformation:
          'Informações complementares de interesse do contruibuinte',
        invoiceModelValue: '55 - Nota Fiscal Eletrônica (NF-e)',
        natureOfOperation: 'Pesquise por uma natureza de operação',
        noPurchaseOrder: 'Nenhum pedido selecionado',

        CurrencyPlaceHolder: 'R$ 0,00',

        NCMPlaceHolder: '99999999',
        CSTPlaceHolder: '99',
        CFOPPlaceHolder: '9999',
        searchForProduct: 'Pesquise por um produto',
        quantityPlaceHolder: '0,000',
      },
    },

    details: {
      details: 'Detalhes',
      lots: 'Lotes',
      throwLots: 'Lançar Lotes',
      exclude: 'Excluir',
      moreActions: 'Mais ações',
      cantEditInvoiceWithThrownLots:
        'Não é possível editar uma NF com lotes lançados',

      lotNotThrew: 'Lote ainda não lançado.',
      lotThrew: 'Lote lançado, mas sem movimentação.',
      lotThrewAndMoving: 'Lote lançado e movimentado.',
      unitValueToolTip: 'Valor Unitário',
      baseIcmsToolTip: 'Base de Cálculo do ICMS',

      throwLotsModalTitle: 'Lançar lotes da Nota Fiscal?',
      throwLotsModalQuestion:
        'Você deseja realizar o lançamento dos lotes para essa Nota Fiscal?',
      throwLotsModalOk: 'Lançar Lotes',
    },

    edit: {
      originalNumber: 'Número NF original: ',
      originalSerie: 'Série NF original: ',
      originalNatureOperation: 'Natureza de Operação original: ',
      originalDateIssue: 'Data de Emissão original: ',
      originalDateDelivery: 'Data de Entrega original: ',
      originalCarrier: 'Transportadora original: ',
      originalFrightType: 'Tipo de Frete original: ',
      originalProvider: 'Fornecedor original: ',
      originalPurchaseOrder: 'Pedidos de Compra originais: ',
      originalIcmsBase: 'Base ICMS original: ',
      originalIcmsValue: 'Valor ICMS original: ',
      originalIcmsBaseSubstitution: 'Base ICMS Substituição original: ',
      originalIcmsValueSubstitution: 'Valor ICMS Substituição original: ',
      originalTotalProducts: 'Total Produtos original: ',
      originalFreight: 'Frete original: ',
      originalInsurance: 'Seguro original: ',
      originalDiscount: 'Desconto original: ',
      originalOtherExpenses: 'Valor despesas original: ',
      originalIpiValue: 'Valor IPI original: ',
      originalTotalInvoice: 'Total original da Nota Fiscal: ',
    },

    registerLots: {
      lotsRegisterTitle: 'Lançamento de Lotes | NF: ',
      stockLocations: 'Local de Estoque',
      lotsCardTitle: 'Lotes',
      lotsCardHeader: {
        product: 'Produto',
        quantity: 'Quantidade',
        lot: 'Lote',
        manufacturingDate: 'Data Fabricação',
        expiryDate: 'Data Validade',
      },

      supplyPlacesPlaceholder: 'Selecione um local de estoque',
      lotPlaceHolder: 'Lote',
      lotsDetails: 'Informações Técnicas do Lote',

      showDetails: 'Mostrar Detalhes',
      hideDetails: 'Ocultar Detalhes',

      manufacturingDateError:
        'A data de fabricação não pode ser maior que a data de validade',
      expiryDateError:
        'A data de validade não pode ser menor que a data de fabricação',
      releaseDateError:
        'A data de validade não pode ser menor que a data de lançamento',
      releaseDateErrorManufacturing:
        'A data de fabricação não pode ser maior que a data de entrega"',

      quantityError: 'Excesso de {{excess}} do valor original.',
      deficitError: 'Déficit de {{deficit}} do valor original.',
      lotNameError: 'Já existe esse lote nessa Nota Fiscal.',

      technicalInformationInputs: {
        product: 'Produto',
        productClass: 'Classe do Produto',
        quantity: 'Quantidade',
        lot: 'Lote',
        manufacturingDate: 'Data de Fabricação',
        expiryDate: 'Data de Validade',
        stockLocations: 'Locais de Estoque',
        labelsQuantity: 'Quant. Rótulos',
        originCountry: 'País de Origem',
        density: 'Densidade',
        dilution: 'Diluição (%)',
        dilutionFactory: 'Fator de Diluição',
        waterConcentration: 'Conc. de Água (%)',
        waterConcentrationFactory: 'Fator de Conc. de Água',

        lotPlaceHolder: 'Lote',

        tooltips: {
          dilution:
            'Percentual de Diluição informado no Certificado de Análise do Fornecedor. Esse valor normalmente está entre 1% e 100%.',
          dilutionFactory:
            'Fator resultante da Diluição do Fornecedor. Exemplo: Se a Diluição do Fornecedor é 40%, o Fator de Diluição será de 2,5, isto é 100 / 40.',
          waterConcentration:
            'Concentração/Teor de Água informado no Certificado de Análise do Fornecedor. Esse valor normalmente está entre 1% e 17%.',
          waterConcentrationFactory:
            'Fator resultante da Concentração de Água. Exemplo: Se a Concentração de Água for de 8,5%, o Fator de Concentração de Água será de 1,093, isto é 100 / (100 - 8,5).',
        },
      },

      button: {
        save: 'Lançar Lotes',
      },
    },
  },
};
