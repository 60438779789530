import { useCallback } from 'react';
import { handleModalError } from '@/Utils/ErrorCodeUtils/ErrorHandlingUtils';
import { App } from 'antd';
import { cannotDeletePrescitorWithMovementsInTheSystemProps } from '@/Utils/ErrorCodeUtils/ErrorModalUtils/ErrorModalPrescriber';
import { useTranslation } from 'react-i18next';
import { IUseFunctions } from './interfaces';

export const useDeleteModalFunctions = ({
  prescriberExternalId,
  deletePrescriber,
  changeVisibleModal,
}: IUseFunctions) => {
  const app = App.useApp();
  const { t } = useTranslation();

  const onOkClick = useCallback(async () => {
    if (prescriberExternalId) {
      await deletePrescriber.mutateAsync([prescriberExternalId], {
        onError: handleModalError(
          app,
          cannotDeletePrescitorWithMovementsInTheSystemProps(t)
        ),
      });
    }
  }, [prescriberExternalId, deletePrescriber, app, t]);

  const onCancelClick = useCallback(
    () => changeVisibleModal(false),
    [changeVisibleModal]
  );
  const onClose = useCallback(
    () => changeVisibleModal(false),
    [changeVisibleModal]
  );

  return {
    onOkClick,
    onCancelClick,
    onClose,
  };
};
