import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormikContext } from 'formik';
import { PercentageInput } from '@/Components/Inputs/PercentageInput';
import { useDilutionFunctions } from './Hooks/useDilutionFunctions';

export const Dilution: FC = () => {
  const { t } = useTranslation();
  const form: any = useFormikContext();

  const {
    onChange
  } = useDilutionFunctions();

  return (
    <>
      <PercentageInput
        name="diluicao"
        label={t('production.standardFormula.create.dilution')}
        placeHolder={t(
          'production.standardFormula.create.dilutionPlaceholder'
        )}
        withoutMarginBottom={true}
        withTooltip={{
          icon: 'exclamation-upside-down',
          title: t(
            'production.standardFormula.create.toolTips.dilutionMessage'
          ),
        }}
        onChange={onChange}
      />
    </>
  );
};