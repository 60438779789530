import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { ContactModal } from '@/Components/EntityInformation/Modal';

export const useContactCardMemorizations = () => {
  const { t } = useTranslation();

  const column = useMemo(
    () => [
      {
        label: t('contact.contactType'),
        width: 6,
        key: 'tipoContatoId',
      },
      {
        label: t('contact.contact'),
        width: 8,
        key: 'identificacao',
      },
      {
        label: t('contact.observation'),
        width: 8,
        key: 'observacao',
      },
    ],
    [t]
  );

  const modal = {
    editTitle: t('contact.editContact'),
    addTitle: t('contact.addContact'),
    modalFormName: ['tipoContatoId', 'identificacao', 'observacao'],
    modalFormRequiredName: ['tipoContatoId', 'identificacao'],
    body: <ContactModal />,
    okAddButtonLabel: t('common.add'),
    okEditButtonLabel: t('common.save'),
    widthModal: 600,
  };

  return {
    column,
    modal,
  };
};
