import { decodeToken } from 'react-jwt';
import { Cookies } from 'react-cookie';

export const CURRENT_USER_ROLES = 'CURRENT_USER_ROLES';

export const setRoles = (access_token: string) => {
  const cookies = new Cookies();

  cookies.set(
    CURRENT_USER_ROLES,
    decodeToken<{ role: string[] }>(access_token)?.role.map((x: string) =>
      parseInt(x)
    ),
    { path: '/' }
  );
};

export const getCurrentUserRoles = (): number[] => {
  const cookies = new Cookies();

  return cookies.get(CURRENT_USER_ROLES);
};
