import { IUseFunctions } from './interfaces';
import { ILinkPackingClassificationRequest } from '@/Data/Interfaces/request/PackingClassificationLinkedPharmaceuticalForm/IPackingClassificationLinkedPharmaceuticalForm';

export const useEditFunctions = ({
  setPackingClassificationRowAmount,
  linkPackingClassification,
  pharmaceuticalFormExternalId,
}: IUseFunctions) => {
  const onSubmit = async (form: ILinkPackingClassificationRequest) => {
    pharmaceuticalFormExternalId &&
      (await linkPackingClassification({
        embalagemClassificacaoExternalId:
          form.embalagemClassificacaoExternalId?.map((x: any) => x.externalId),
        formaFarmaceuticaExternalId: pharmaceuticalFormExternalId,
      }));
  };

  const removeRow = (index: number) => {
    setPackingClassificationRowAmount?.((oldState) => {
      return oldState.filter((_, i) => i !== index);
    });
  };

  return {
    removeRow,
    onSubmit,
  };
};
