import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { Tabs } from '../../../Components/Tabs';

import { Accessibility } from './Accessibility';
import { PlaceAndTime } from './PlaceAndTime';
import { Theme } from './Theme';

import styles from './UserPreference.module.scss';

export const UserPreference: FC = () => {
  const { t } = useTranslation();

  return (
    <main className={styles['container']}>
      <Tabs
        items={[
          {
            label: t('preferences.placeAndTime'),
            key: 'place-and-time',
            children: <PlaceAndTime />,
          },
          {
            label: t('preferences.accessibility'),
            key: 'accessibility',
            children: <Accessibility />,
          },
          { label: t('preferences.theme'), key: 'theme', children: <Theme /> },
        ]}
      />
    </main>
  );
};
