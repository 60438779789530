import { IStandardFormulaListData } from "@/Data/Interfaces/response/StandardFormula/IStandardFormulaResponse";
import { useState } from "react";

export const useListStates = () => {
  const [
    selectedRowsOnTable, 
    setSelectedRowsOnTable
  ] = useState<IStandardFormulaListData[]>([]);

  return {
    selectedRowsOnTable,
    setSelectedRowsOnTable
  };
};