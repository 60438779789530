import { IUseFunctions } from "./interfaces";
import { useDeleteModalCallAPI } from "./useDeleteModalCallAPI";

export const useDeleteModalFunctions = ({ userGroup }: IUseFunctions) => {
  const { deleteUserGroup } = useDeleteModalCallAPI();
  
  const onDelete = () => {
    deleteUserGroup.mutateAsync([userGroup.externalId]);
  };

  return { onDelete };
};