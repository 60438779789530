import { useCallback } from 'react';
import DateUtils from '@/Utils/DateUtils';
import { IUseFunctions } from './interfaces';

export const useInfoModalFunctions = ({
  editInfo,
  preferences,
  changeVisibleModal,
}: IUseFunctions) => {
  const onOkClickForm = useCallback(
    async (x: any) =>
      await editInfo.mutateAsync({
        ...x,
        dataNascimento:
          x.dataNascimento &&
          DateUtils.convertDateToApi(x.dataNascimento, preferences?.padraoData),
      }),
    [editInfo, preferences?.padraoData]
  );

  const onCancelClick = useCallback(
    () => changeVisibleModal(true),
    [changeVisibleModal]
  );

  const onClose = useCallback(
    () => changeVisibleModal(true),
    [changeVisibleModal]
  );

  return {
    onOkClickForm,
    onCancelClick,
    onClose,
  };
};
