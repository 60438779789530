import { IUseMemorizations } from './interfaces';

export const useOriginCountryMemorizations = ({
  paisOrigemList,
}: IUseMemorizations) => {
  const items = paisOrigemList?.data?.map((x: any) => ({
    id: x.externalId,
    name: x.descricao,
    flag: x.abreviacao,
  }));

  return { items };
};
