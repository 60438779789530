import { currencyFormater } from '@/Utils/Currency';
import { useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';
import { IUseMemorizations } from './interfaces';
import { useGetRowTotalValue } from '../../../../ProductCard/Components/ProductLine/Hooks/useGetRowTotalValue';

export const useProductValuesMemorizations = ({
  orderPurchase,
}: IUseMemorizations) => {
  const calculateTotalValueLine = useGetRowTotalValue();
  const { t } = useTranslation();
  const form: any = useFormikContext();
  const totalItemsArray: number[] = [];
  let totalValue = 0;

  if (form?.values?.pedidoCompraItens && form.values.pedidoCompraItens.length) {
    form.values.pedidoCompraItens.forEach((_: any, index: number) => {
      totalItemsArray.push(calculateTotalValueLine(index, true) || 0);
    });

    totalValue = totalItemsArray.reduce((acc, curr) => acc + curr, 0);
  }

  const withStatusIconInLabel =
    orderPurchase?.totalizadores?.totalProdutosBruto !== totalValue;

  const withTooltip = {
    showMe: orderPurchase?.totalizadores?.totalProdutosBruto !== totalValue,
    title: `${t('common.originalValue')} ${currencyFormater(
      orderPurchase?.totalizadores?.totalProdutosBruto
    )}`,
  };

  return {
    withStatusIconInLabel,
    withTooltip,
  };
};
