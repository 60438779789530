import {
  useControllerMutateApiHook,
  useControllerQueryApiHook,
} from '@/ControllerApiHook/Controller';
import { GET_ME } from '@/ControllerApiHook/UniqIds/Accountmanager/UserKeys';
import {
  FETCH_GET_SALES_ORDER,
  UPDATE_DELIVERY_SALES_ORDER,
} from '@/ControllerApiHook/UniqIds/Sales/SalesOrderKeys';
import { UserAPI } from '@/Data/API/Accountmanager/UserApi';
import { SalesOrderAPI } from '@/Data/API/Sales/SalesOrderApi';
import { IGetUserResponse } from '@/Data/Interfaces/response/User/IGetUserReponse';
import { messageErrorToast } from '@/Utils/MessageErrorToast';
import { useQueryClient } from '@tanstack/react-query';

export const useContentBodyCallAPI = () => {
  const queryClient = useQueryClient();

  const { data: myUser } = useControllerQueryApiHook<IGetUserResponse>({
    uniqId: GET_ME,
    entityApi: UserAPI.getMe,
  });

  const {
    mutateAsync: updateDelivery,
    isLoading,
    isSuccess,
  } = useControllerMutateApiHook({
    uniqId: UPDATE_DELIVERY_SALES_ORDER,
    entityApi: SalesOrderAPI.updateDeliverySaleOrder,
    options: {
      onSuccess: () => {
        queryClient.invalidateQueries([FETCH_GET_SALES_ORDER]);
      },
      onError: (x) => messageErrorToast(x),
    },
  });

  return {
    myUser,

    updateDelivery,
    isLoading,
    isSuccess,
  };
};
