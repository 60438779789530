import { Modal } from '@/Components';
import { useTranslation } from 'react-i18next';
import { CreateModalBody } from './ModalBody';
import { FC } from 'react';
import { useCreateExpertiseCallAPI } from './Hooks/useCreateExpertiseCallAPI';
import { CreateExpertiseSchema } from './CreateExpertise.schema';

interface ICreateExpertiseModal {
  createModalOpen: boolean;
  setCreateModalOpen: (value: boolean) => void;
  initialDescription?: string;
}

export const CreateExpertiseModal: FC<ICreateExpertiseModal> = ({
  createModalOpen,
  setCreateModalOpen,
  initialDescription,
}) => {
  const { t } = useTranslation();

  const { createSpecialties, createSpecialtiesLoading } =
    useCreateExpertiseCallAPI({ setCreateModalOpen });

  return (
    <Modal
      title={t('specialties.create.title')}
      body={<CreateModalBody />}
      okButtonName={t('common.create')}
      visible={createModalOpen}
      withForm
      onOkClickForm={(values, formik) =>
        createSpecialties(values, {
          onError: () => formik.setSubmitting(false),
        })
      }
      htmlType="submit"
      onCancelClick={() => setCreateModalOpen(false)}
      onClose={() => setCreateModalOpen(false)}
      loading={createSpecialtiesLoading}
      schema={CreateExpertiseSchema()}
      initialValues={{ descricao: initialDescription }}
    />
  );
};
