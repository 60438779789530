import { takeLatest, all } from 'redux-saga/effects';
import { NotificationActionKeys } from './Notification/Notification.actions';
import { getNotifications } from './Notification/Notification.sagas';

export default function* rootSaga(): any {
  return yield all([
    takeLatest(
      NotificationActionKeys.GET_NOTIFICATIONS_REQUEST,
      getNotifications
    ),
  ]);
}
