import { FC } from "react";
import { Tooltip } from '@/Components';
import { Col } from 'antd';
import { NumberInput } from '@/Components/Inputs/NumberInput';
import { useTranslation } from "react-i18next";
import { IEquivalenceFactor } from "./interfaces";

export const EquivalenceFactor: FC<IEquivalenceFactor> = ({
  synonymData,
  props,
  disabledForm
}) => {
  const { t } = useTranslation();

  return (
    <Col span={4}>
      <Tooltip
        title={`${t('products.synonym.originalEquivalenceFactor')}${
          synonymData?.fatorEquivalencia
        }`}
        showMe={
          synonymData &&
          synonymData.fatorEquivalencia !==
            props?.values?.fatorEquivalencia
        }
      >
        <NumberInput
          name="fatorEquivalencia"
          label={t('products.synonym.equivalenceFactor')}
          placeHolder="Ex: 1"
          disabled={disabledForm}
          withStatusIcon
        />
      </Tooltip>
    </Col>
  );
};