import { useFormikContext } from "formik";

export const useQspFunctions = () => {
  const form: any = useFormikContext();

  const onClick = () => {
    form.setFieldValue('isPellets', false);
    form.setFieldValue('isExcipiente', false);
  };

  return { onClick };
};