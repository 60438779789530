import React from 'react';
import InputMask from 'react-input-mask';
import { IMask, Mask } from '../../../Utils/MasksUtils';
import { ITextInput } from '../TextInput';
import { Input } from 'antd';
import { Field } from 'formik';
import { IField } from '../interfaces/IField';
import { InputLabel } from '../Label';
import { Icon } from '../..';
import { LocalizaPropriedadePorPath } from '../../../Utils/FormikUtils';

import './TextInput.override.scss';
import styles from './TextInput.module.scss';
import { Loading } from '../../Loading';

export interface ITextInputWitchMask extends ITextInput {
  mask?: IMask;
  onBlur?: (value: string) => void;
  loading?: boolean;
  customMask?: string;
  withStatusIcon?: boolean;
  dataTestId?: string;
}

export const TextInputWithMask: React.FC<ITextInputWitchMask> = ({
  mask,
  name,
  label,
  required,
  withoutMarginBottom,
  nulable,
  placeHolder,
  error,
  success,
  rightIcon,
  leftIcon,
  disabled,
  className,
  onBlur,
  loading,
  customMask,
  onChange,
  withStatusIcon,
  dataTestId,
}) => {
  return (
    <div
      className={`${withoutMarginBottom ? '' : styles['margin-bottom']} ${
        styles['Container']
      }`}
    >
      <Field name={name}>
        {(props: IField) => {
          const hasError =
            error ||
            !!(
              props.form &&
              props.form.errors &&
              LocalizaPropriedadePorPath(props.form.errors, name)
            );

          const classNames = className;

          const onPointerLeave = (event: any) => {
            if (nulable && (event.target === null || event.target.value === ''))
              props.form.setFieldValue(name, null);
          };
          return (
            <>
              {label && (
                <InputLabel
                  label={label}
                  required={required}
                  props={props}
                  withStatusIcon={withStatusIcon}
                />
              )}
              <div
                className={`
              	${styles['input-container']}
              	${classNames}
              	${leftIcon ? styles['with-left-icon'] : null}
              	${
                  rightIcon || success || hasError
                    ? styles['with-right-icon']
                    : null
                } ${hasError ? styles['error'] : null}
              	${success ? styles['success'] : null}`}
              >
                {leftIcon ? (
                  <Icon
                    className={styles['Left-Icon']}
                    icon={leftIcon}
                    size="M"
                    color={error || hasError ? 'status-danger-base' : 'text-50'}
                    style={{ zIndex: 1000 }}
                  />
                ) : null}
                <InputMask
                  {...props.field}
                  onBlur={(x) => {
                    onBlur && onBlur(props.field.value);
                    mask === 'dataMask'
                      ? props.field && props.field.onBlur(x)
                      : props.form.setFieldValue(
                          name,
                          x.target.value.replaceAll(/[^\d]+/g, '')
                        );
                  }}
                  mask={customMask ? customMask : Mask(mask ? mask : 'cep')}
                  disabled={disabled || props.form.isSubmitting}
                  placeholder={placeHolder}
                  onChange={(x) => {
                    onChange && onChange(x.target.value);
                    mask === 'dataMask'
                      ? props.field && props.field.onChange(x)
                      : props.form.setFieldValue(
                          name,
                          x.target.value.replaceAll(/[^\d]+/g, '')
                        );
                  }}
                  children={
                    <Input
                      autoComplete="off"
                      data-testid={dataTestId}
                      className={styles['TextInput']}
                      placeholder={placeHolder}
                      onPointerLeave={onPointerLeave}
                      disabled={disabled || props.form.isSubmitting}
                      id={name}
                    />
                  }
                />

                {success ? (
                  <Icon
                    className={styles['Icon']}
                    icon="check"
                    size="M"
                    color="status-success-base"
                  />
                ) : null}

                {rightIcon?.icon ? (
                  <Icon
                    className={styles['Icon']}
                    icon={rightIcon.icon}
                    size="M"
                    color="text-50"
                  />
                ) : null}
                {loading ? <Loading className={styles['loading']} /> : null}
                {props.form &&
                props.form.errors &&
                LocalizaPropriedadePorPath(props.form.errors, name) ? (
                  <p className={styles['errorText']}>
                    {LocalizaPropriedadePorPath(props.form.errors, name)}
                  </p>
                ) : null}
              </div>
            </>
          );
        }}
      </Field>
    </div>
  );
};
