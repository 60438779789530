import { InputWithSearch, NumberInput, TextInput, Tooltip } from '@/Components';
import { TextInputWithMask } from '@/Components/Inputs/TextInputWithMask';
import { useListNatureOfOperation } from '@/Hooks/HooksServices/Purchase/NatureOfOperation/useListNatureOfOperation';
import { Col, Row } from 'antd';
import { useFormikContext } from 'formik';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

export const RowOne: React.FC = () => {
  const [seriesMaskVisible, setSeriesMaskVisible] = useState(false);
  const form: any = useFormikContext();
  const { t } = useTranslation();
  const natureOfOperation = useListNatureOfOperation({
    fieldName: 'naturezaOperacaoId',
  });

  const formatNumber = (number: string) => {
    const formattedNumber = number.replace(/\B(?=(\d{3})+(?!\d))/g, '.');

    return formattedNumber;
  };

  return (
    <Row gutter={[16, 0]}>
      <Col span={6}>
        <TextInput
          name="notaFiscal"
          label={t('purchasing.invoice.create.invoiceModel')}
          disabled
          value={t('purchasing.invoice.create.placeHolder.invoiceModelValue')}
        />
      </Col>
      <Col span={4}>
        <Tooltip
          showMe={form.values.numero !== form.initialValues.numero}
          title={`${t('purchasing.invoice.edit.originalNumber')}${formatNumber(
            `${form.initialValues.numero}`
          )}`}
        >
          <NumberInput
            name="numero"
            required
            label={t('purchasing.invoice.create.invoiceNumber')}
            placeHolder={`${t('common.exampleAbbreviation')}: 0025`}
            withThousandSeparator
            withDecimalSeparator
            maxLength={11}
            acceptOnlyNumbers
            withStatusIcon
          />
        </Tooltip>
      </Col>
      <Col span={2}>
        <Tooltip
          showMe={form.values.serie !== form.initialValues.serie}
          title={`${t('purchasing.invoice.edit.originalSerie')}${
            form.initialValues.serie
          }`}
        >
          <TextInputWithMask
            name="serie"
            mask={seriesMaskVisible ? 'invoiceSeries' : undefined}
            required
            onChange={(value) => {
              if (value.length === 10) {
                setSeriesMaskVisible(true);
              } else {
                setSeriesMaskVisible(false);
              }
            }}
            label={t('purchasing.invoice.create.invoiceSeries')}
            placeHolder={`${t('common.exampleAbbreviation')}: 5`}
            withStatusIcon
          />
        </Tooltip>
      </Col>
      <Col span={12}>
        <Tooltip
          showMe={
            form.values.naturezaOperacaoId !==
            form.initialValues.naturezaOperacaoId
          }
          title={`${t('purchasing.invoice.edit.originalNatureOperation')}${
            form.initialValues.naturezaOperacaoDescricao
          }`}
          targetOffset={[200, 0]}
        >
          <InputWithSearch
            label={t('purchasing.invoice.create.natureOfOperation')}
            placeHolder={t(
              'purchasing.invoice.create.placeHolder.natureOfOperation'
            )}
            required
            {...natureOfOperation}
            withStatusIcon
          />
        </Tooltip>
      </Col>
    </Row>
  );
};
