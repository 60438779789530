import { currencyFormater } from '@/Utils/Currency';
import { useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';
import { IUseMemorizations } from './interfaces';

export const useTotalMemorizations = ({
  totalEnding,
  orderPurchase,
}: IUseMemorizations) => {
  const { t } = useTranslation();
  const form: any = useFormikContext();

  const withStatusIconInLabel =
    totalEnding !== form?.values?.totalizadores?.totalProdutosLiquido;

  const withTooltip = {
    showMe: totalEnding !== form?.values?.totalizadores?.totalProdutosLiquido,
    title: `${t('common.originalValue')} ${currencyFormater(
      orderPurchase?.totalizadores?.totalProdutosLiquido || 0
    )}`,
  };

  return {
    withStatusIconInLabel,
    withTooltip,
  };
};
