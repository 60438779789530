import { IProductGroupListData } from '@/Data/Interfaces/response/Product/IProductRespose';
import { useFormikContext } from 'formik';
import { IUseFunctions } from './interfaces';

export const useProductNameFunctions = ({
  index,
  productList,
  setCurrentProduct,
  addRow,
  refetchSearchProductPage,
}: IUseFunctions) => {
  const form: any = useFormikContext();

  const setProductCodeValueAndProductName = (value: string) => {
    productList.data.forEach((e: IProductGroupListData) => {
      if (e.externalId === value) {
        setCurrentProduct(e);
        if (!form.values.produtos) {
          form.values.produtos = [];
        }
        form.values.produtos[index] = form.values.produtos[index] ?? {};
        form.values.produtos[index].descricaoProduto = e.descricao;
        form.values.produtos[index].unidadeMedidaId = e.unidadeMedidaId;
      }
    });
    addRow();
  };

  const onSearch = (search?: string) => {
    refetchSearchProductPage({
      pageSize: 50,
      search: search,
    });
  };

  return {
    setProductCodeValueAndProductName,
    onSearch,
  };
};
