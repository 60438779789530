import { HorizontalTabs } from '../../../../../Components';
import { PageLoading } from '../../../../../Layouts/PageLoading';
import { useParams } from 'react-router';
import {
  DELETE_INVOICE,
  FETCH_GET_INVOICE
} from '../../../../../ControllerApiHook/UniqIds/Purchase/InvoiceKeys';
import {
  dropdownTitle,
  leftButtonConfig,
  itemsOnTab,
  rightButtonConfig,
  dropdownItems,
} from './utils/constants';
import { useState } from 'react';
import { DeleteModal } from './components/DeleteModal';
import { formatSerialNumber } from '../List/utils/functions';
import { deleteInvoiceFunction } from './utils/functions';
import { useQueryClient } from '@tanstack/react-query';
import { ThrowLotsModal } from './components/ThrowLotsModal';
import { CanNotDeleteModal } from './components/CanNotDeleteModal';
import { history } from '@/Store';
import { useControllerMutateApiHook, useControllerQueryApiHook } from '@/ControllerApiHook/Controller';
import { InvoiceAPI } from '@/Data/API/Purchase/InvoiceApi';
import { IInvoiceGetResponse } from '@/Data/Interfaces/response/Purchase/IInvoiceResponse';

export const InvoiceDetails: React.FC = () => {
  const { externalId } = useParams<{ externalId: string }>();
  const { data: invoice } = useControllerQueryApiHook<IInvoiceGetResponse>({
    uniqId: FETCH_GET_INVOICE,
    entityApi: InvoiceAPI.getInvoice,
    externalIds: [externalId]
  });
  const [visibleDeleteModal, setVisibleDeleteModal] = useState(false);
  const [visibleThrowLotsModal, setVisibleThrowLotsModal] = useState(false);
  const [visibleCanNotDeleteModal, setVisibleCanNotDeleteModal] =
    useState(false);

  const queryClient = useQueryClient();

  const deleteInvoice = useControllerMutateApiHook({
    uniqId: DELETE_INVOICE,
    entityApi: InvoiceAPI.deleteInvoice
  });

  const openDeleteModal = () => setVisibleDeleteModal(true);
  const closeDeleteModal = () => setVisibleDeleteModal(false);
  const openThrowLotsModal = () => setVisibleThrowLotsModal(true);
  const closeThrowLotsModal = () => setVisibleThrowLotsModal(false);
  const openCanNotDeleteModal = () => setVisibleCanNotDeleteModal(true);
  const closeCanNotDeleteModal = () => setVisibleCanNotDeleteModal(false);

  return (
    <PageLoading>
      <HorizontalTabs
        type="secondary"
        items={itemsOnTab(invoice)}
        dropdownDirection="bottomRight"
        dropdownTitle={dropdownTitle}
        leftButton={leftButtonConfig(openThrowLotsModal, invoice)}
        rightButton={rightButtonConfig(invoice)}
        leftIcon="menu-ellipsis-horizontal"
        dropdownItens={dropdownItems(openDeleteModal)}
      />
      <DeleteModal
        onCancelClick={closeDeleteModal}
        onOkClick={async () => {
          deleteInvoiceFunction(
            invoice?.externalId ?? '',
            deleteInvoice,
            queryClient,
            openCanNotDeleteModal
          );
          closeDeleteModal();
        }}
        visible={visibleDeleteModal}
        provider={invoice?.fornecedorDescricao ?? ''}
        number={invoice ? formatSerialNumber(invoice.numero, 9) : ''}
        series={invoice ? formatSerialNumber(invoice.serie, 3) : ''}
      />

      <ThrowLotsModal
        onCancelClick={closeThrowLotsModal}
        onOkClick={() =>
          history.push(`/order/invoice/${invoice?.externalId}/register-lots`)
        }
        visible={visibleThrowLotsModal}
      />

      <CanNotDeleteModal
        onCancelClick={closeCanNotDeleteModal}
        onOkClick={closeDeleteModal}
        visible={visibleCanNotDeleteModal}
        provider={invoice?.fornecedorDescricao ?? ''}
        number={invoice ? formatSerialNumber(invoice.numero, 9) : ''}
        series={invoice ? formatSerialNumber(invoice.serie, 3) : ''}
      />
    </PageLoading>
  );
};
