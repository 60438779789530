import { FC } from 'react';
import { Footer as ModalFooter } from '@/Components/Modal/Components/Footer';
import { IGlobalModal } from '@/Utils/ErrorCodeUtils/ErrorHandlingUtils';
import { useTranslation } from 'react-i18next';
import { useFooterFunctions } from './Hooks/useFooterFunctions';

export const Footer: FC<{
  params: { gruposExternalId: string[] };
  instance: IGlobalModal;
  content: any;
}> = ({ params, instance, content }) => {
  const { t } = useTranslation();

  const { onOkClick } = useFooterFunctions({
    params,
    instance,
  });

  return content?.length > 1 ? (
    <ModalFooter
      onCancelClick={instance.destroy}
      withoutOkButton
      cancelButtonName={t('common.close')}
      cancelDataTestId="cancel-button-cannotDelete"
    />
  ) : (
    <ModalFooter
      okButtonName={t('supply.group.modal.cannotDelete.ok')}
      onOkClick={onOkClick}
      onCancelClick={instance.destroy}
      cancelDataTestId="cancel-button-cannotDelete"
    />
  );
};
