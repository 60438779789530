import { FC } from 'react';
import { useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';
import { Card } from '@/Components';
import { TechnicalInfoNotRawMaterialCard } from '../../../../CreateForm/CardsForm/TechnicalInfoCard/Components/TechnicalInfoNotRawMaterialCard';
import { TechnicalInfoDiluitionsRows } from './Components/Diluitions';
import { IDetailsForm } from './interfaces';
import { useTechnicalInfoCardCallAPI } from './Hooks/useTechnicalInfoCardCallAPI';
import { CountryDensityInfo } from './Components/CountryDensityInfo';
import { AlternateUnitInfo } from './Components/AlternateUnitInfo';

export const TechnicalInfoCard: FC<IDetailsForm> = ({ disabled }) => {
  const { t } = useTranslation();
  const form: any = useFormikContext();

  const {
    lots,
    paisOrigemList,
    unidadeAlternativaList
  } = useTechnicalInfoCardCallAPI();

  return form?.values?.classeProdutoId === 1 ||
    !form?.values?.classeProdutoId ? (
    <Card
      title={t('supply.lot.details.details.technicalInformation')}
      withoutCollapse={true}
    >
      <CountryDensityInfo
        lots={lots}
        disabled={disabled}
        paisOrigemList={paisOrigemList}
      />
      <TechnicalInfoDiluitionsRows disabled={disabled} />
      <AlternateUnitInfo
        lots={lots}
        disabled={disabled}
        unidadeAlternativaList={unidadeAlternativaList}
      />
    </Card>
  ) : (
    <TechnicalInfoNotRawMaterialCard />
  );
};
