import { DELETE_USER_GROUP, LIST_USER_GROUP_TYPE } from "@/ControllerApiHook/UniqIds/Accountmanager/UserGroupKeys";
import { useQueryClient } from "@tanstack/react-query";
import { history } from '@/Store';
import { useControllerMutateApiHook } from "@/ControllerApiHook/Controller";
import { UserGroupAPI } from "@/Data/API/Accountmanager/UserGroupApi";

export const useDeleteModalCallAPI = () => {
  const queryClient = useQueryClient();

  const deleteUserGroup = useControllerMutateApiHook({
    uniqId: DELETE_USER_GROUP,
    entityApi: UserGroupAPI.DeleteUserGroup,
    options: {
      onSuccess: () => {
        queryClient.invalidateQueries([LIST_USER_GROUP_TYPE]);
        history.push('/admin/user-group');
      }
    }
  });

  return { deleteUserGroup };
};