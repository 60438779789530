import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { AdderList, Card } from '../../../../../../../../Components';
import { IFChildren } from '@/Components/Form';
import { ContactModal } from '@/Components/EntityInformation/Modal';

interface IContactCard {
  props: IFChildren;
}

export const ContactCard: FC<IContactCard> = ({ props }) => {
  const { t } = useTranslation();

  return (
    <Card title={t('contact.contacts')} withoutCollapse>
      <AdderList
        favoriteFormName="principal"
        onChange={(values) => props.setFieldValue('contatos', values)}
        collum={[
          {
            label: t('contact.contactType'),
            width: 8,
            key: 'tipoContatoId',
          },
          {
            label: t('contact.contact'),
            width: 8,
            key: 'identificacao',
          },
          {
            label: t('contact.observation'),
            width: 8,
            key: 'observacao',
          },
        ]}
        modal={{
          editTitle: t('contact.editContact'),
          addTitle: t('contact.addContact'),
          modalFormName: ['tipoContatoId', 'identificacao', 'observacao'],
          modalFormRequiredName: ['tipoContatoId', 'identificacao'],
          body: <ContactModal />,
          okAddButtonLabel: t('common.add'),
          okEditButtonLabel: t('common.save'),
          widthModal: 600,
        }}
        buttonLabel={t('contact.addContact')}
        empytDataDescription={t('contact.contactEmpyt')}
        entityExternalIdName="prescritorEnderecoExternalId"
        actions
        type="contactType"
      />
    </Card>
  );
};
