import React, { FC } from 'react';
import { Checkbox as AntCheckbox } from 'antd';

import styles from './CheckBox.module.scss';

export interface ICheckbox {
  onChange?: (value?: any) => void;
  checked?: boolean;
  disabled?: boolean;
}

export const Checkbox: FC<ICheckbox> = ({ onChange, checked, disabled }) => (
  <div className={styles['divCheckbox']}>
    <AntCheckbox onChange={onChange} checked={checked} disabled={disabled} />
  </div>
);
