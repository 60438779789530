import { FC } from 'react';
import { Modal } from '@/Components/Modal';
import { useTranslation } from 'react-i18next';
import { ModalBody } from './Body';
import { useCreateClientStates } from './Hooks/useCreateClientStates';
import { useCreateClientCallAPI } from './Hooks/useCreateClientCallAPI';
import { useCreateClientMemorizations } from './Hooks/useCreateClientMemorizations';
import { useCreateClientFunctions } from './Hooks/useCreateCLientFunctions';
import { ICreateClientModal } from './interfaces';

export const CreateClientModal: FC<ICreateClientModal> = ({
  changeVisible,
  visible,
  nameClient,
  changeSelectedUser,
}) => {
  const { t } = useTranslation();

  const { formValues, setFormValues } = useCreateClientStates();

  const { createClient } = useCreateClientCallAPI({
    changeVisible,
    changeSelectedUser,
  });

  const { dropdownRight } = useCreateClientMemorizations({ formValues });

  const { onOkClickForm } = useCreateClientFunctions({
    createClient,
  });

  return (
    <Modal
      noLeaveWithoutLeaving
      onOkClickForm={onOkClickForm}
      title={t('callCenter.createService.createClientModal.newClient')}
      body={<ModalBody />}
      visible={visible}
      onCancelClick={() => changeVisible(!visible)}
      htmlType="submit"
      loading={createClient.isLoading}
      disabledButton={createClient.isLoading}
      onClose={() => changeVisible(!visible)}
      withForm
      minWidth={650}
      initialValues={{ nome: nameClient }}
      okButtonName={t('common.create')}
      formValues={(x) => setFormValues(x)}
      dropdownRight={dropdownRight}
    />
  );
};
