import React from 'react';
import { useTranslation } from 'react-i18next';
import { history } from '../../../../../../Store';
import { Button } from '../../../../../../Components';

import styles from './CompanyForm.module.scss';

interface IFooterButton {
  onClick: (value: boolean) => void;
  loading: boolean;
}

export const FooterButton: React.FC<IFooterButton> = ({ onClick, loading }) => {
  const { t } = useTranslation();

  return (
    <div className={styles['footer-button']}>
      <Button
        type="secondary"
        children={t('adminBootis.createCompany.footerButton.cancel')}
        onClick={() => history.push('/admin-bootis/company')}
      />
      <Button
        type="primary"
        children={t('adminBootis.createCompany.footerButton.submit')}
        htmlType="submit"
        onClick={() => onClick(true)}
        loading={loading}
        dataTestId="submit"
      />
    </div>
  );
};
