import { useControllerMutateApiHook } from '@/ControllerApiHook/Controller';
import { PackingClassificationAPI } from '@/Data/API/Production/PackingClassification';
import {
  CREATE_PACKING_CLASSIFICATION,
  FETCH_LIST_PACKING_CLASSIFICATION,
} from '@/ControllerApiHook/UniqIds/Production/PackingClassificationKeys';
import { history } from '@/Store';
import { messageErrorToast } from '@/Utils/MessageErrorToast';
import { useQueryClient } from '@tanstack/react-query';

export const useCreateCallAPI = () => {
  const queryClient = useQueryClient();

  const { mutateAsync: createPacking, isLoading: createPackingIsLoading }: any =
    useControllerMutateApiHook({
      uniqId: CREATE_PACKING_CLASSIFICATION,
      entityApi: PackingClassificationAPI.createPackingClassification,
      options: {
        onSuccess: () => {
          queryClient.invalidateQueries([FETCH_LIST_PACKING_CLASSIFICATION]);
          history.push('/production/packing-classification');
        },
        onError: (error) => messageErrorToast(error),
      },
    });

  return { createPacking, createPackingIsLoading };
};
