import { FC, useEffect, useState } from 'react';
import { AddProductModal } from '../../TopButtons/Modals/AddProductModal';
import { SaleOrderItem } from '@/Data/Interfaces/response/Sales/SalesOrder/ISalesOrderResponse';
import DateUtils from '@/Utils/DateUtils';
import StorageUtils from '@/Utils/StorageUtils';

interface IEditItemModalSaleOrder {
  item?: SaleOrderItem;
  onClose: () => void;
}

export const EditItemModalSaleOrder: FC<IEditItemModalSaleOrder> = ({
  item,
  onClose,
}) => {
  const [productModalOpen, setProductModalOpen] = useState(false);
  const preferences = StorageUtils.getPreference();

  useEffect(() => {
    item !== undefined && setProductModalOpen(true);
  }, [item]);

  return (
    <AddProductModal
      changeVisibleModal={(x) => {
        setProductModalOpen(x);
        onClose();
      }}
      visibleModal={productModalOpen}
      initialValues={{
        pedidoVendaItemId: item?.pedidoVendaItemId,
        produtoExternalId: item?.produtoAcabado.produtoExternalId,
        prescritorExternalId: item?.produtoAcabado.prescritorExternalId,
        dataPrescricao: DateUtils.convertDate(
          item?.produtoAcabado.dataPrescricao,
          preferences?.padraoData
        ),
        valorUnitario: item?.valorUnitario,
        quantidade: item?.quantidade,
        tipoDesconto: item?.tipoDescontoId,
        desconto:
          item?.tipoDescontoId === 1
            ? item.percentualDescontoUnitario
            : item?.valorDescontoUnitario,
        total: item?.valorTotalItem,
      }}
      editModal
    />
  );
};
