import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { LoginLayout } from '@/Components/Layouts';
import { Button, Link, Text } from '@/Components';
import { history } from '@/Store';

import styles from './Success.module.scss';

export const ForgotPasswordSuccess: FC = () => {
  const { t } = useTranslation();
  const location: any = useLocation();

  return (
    <LoginLayout
      className={styles['title']}
      title={t('forgotPasswordSuccess.title')}
    >
      <div className={styles['Form']}>
        <Text type="ui-tiny-content" color="text-50" className={styles['text']}>
          <p>
            {`${t('forgotPasswordSuccess.successMsg')} `}(
            <span className={styles['email']}>
              {location && location.state ? location.state.email : ''}
            </span>
            ){`. ${t('forgotPasswordSuccess.successEndMsg')}`}
          </p>
        </Text>
        <Button
          type="primary"
          onClick={() => history.push('/login')}
          fullWidth
          children={t('forgotPasswordSuccess.ok')}
        />

        <Text
          type="small-text"
          color="text-50"
          className={styles['info-label']}
          children={t('forgotPasswordSuccess.infoLabel')}
        />
        <Link
          type="small-text"
          color="primary-base"
          to="/forgot-password"
          className={styles['login-link']}
          children={t('forgotPasswordSuccess.tryOtherMail')}
        />
      </div>
    </LoginLayout>
  );
};
