import { Button } from '@/Components';
import _ from 'lodash';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { history } from '../../../../../../../Store';
import styles from '../../CapsuleColors.module.scss';

interface IFooterButtons {
  isLoadingCreateCapsuleColors: boolean;
}

export const FooterButtons: FC<IFooterButtons> = ({
  isLoadingCreateCapsuleColors,
}) => {
  const { t } = useTranslation();
  return (
    <div className={styles['footer-button']}>
      <Button
        type="secondary"
        children={t('common.cancel')}
        onClick={() => {
          history.push('/production/capsule-colors');
        }}
      />
      <Button
        type="primary"
        children={
          isLoadingCreateCapsuleColors
            ? t('common.creating')
            : t('common.create')
        }
        htmlType="submit"
        loading={isLoadingCreateCapsuleColors}
        dataTestId="submit-capsule-color"
      />
    </div>
  );
};
