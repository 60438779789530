import { ICreateSubGroupProductRequest } from '@/Data/Interfaces/request/Product/IProductRequest';
import { FETCH_LIST_SUBGROUP } from '@/ControllerApiHook/UniqIds/Supply/ProductKeys';
import { useQueryClient } from '@tanstack/react-query';
import { FormikHelpers, FormikValues } from 'formik';
import { IUseFunctions } from './interfaces';

export const useSubGroupModalFunctions = ({
  title,
  selectedRowsOnTable,
  editSubGroup,
  createSubGroup,
  changeVisible,
  visible,
}: IUseFunctions) => {
  const queryClient = useQueryClient();

  const onSubmit = async (
    values: ICreateSubGroupProductRequest,
    helpers: FormikHelpers<FormikValues>
  ) => {
    const body: ICreateSubGroupProductRequest = { ...values, helpers };
    if (title && selectedRowsOnTable) {
      const res = await editSubGroup.mutateAsync({
        subGrupoExternalId: selectedRowsOnTable[0]?.externalId,
        descricao: body.descricao,
        grupoPaiExternalId: body.grupoPaiExternalId,
        helpers,
      });
      if (res.success) {
        queryClient.invalidateQueries([FETCH_LIST_SUBGROUP]);
      }
    } else {
      const res = await createSubGroup.mutateAsync({
        descricao: body?.descricao,
        grupoPaiExternalId: body.grupoPaiExternalId,
        helpers,
      });
      if (res.success) queryClient.invalidateQueries([FETCH_LIST_SUBGROUP]);
    }
  };

  const onOkClickForm = (values: any, formik: any) => {
    onSubmit(values, formik);
    changeVisible(!visible);
  };

  const onClose = () => changeVisible(!visible);

  const onCancelClick = () => changeVisible(!visible);

  return {
    onSubmit,
    onOkClickForm,
    onClose,
    onCancelClick,
  };
};
