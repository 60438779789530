import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { StockLocationsList } from '../StockLocation';
import { DetailsForm } from '../DetailsCards';
import { useParams } from 'react-router-dom';
import { history } from '@/Store';
import { IUseMemorizations } from './interfaces';
import { IPermissionEditButon } from '@/Components/HorizontalTabs';
import { IButtonDropDownItems } from '@/Components/Button/ButtonDropdown';

export const useEditMemorizations = ({
  disabled,
  setDisabled,
  setVisibleModal,
  lot,
}: IUseMemorizations) => {
  const { t } = useTranslation();
  const params: Readonly<
    Partial<{
      externalId: string;
      tab: string;
    }>
  > = useParams();

  const items = useMemo(
    () => [
      {
        label: t('Detalhes'),
        key: 'details',
        children: (
          <DetailsForm
            disabled={disabled}
            toogleDisabled={(x: boolean) => setDisabled(x)}
          />
        ),
      },
      {
        label: t('Rastreabilidade'),
        key: 'traceability',
        disabled: true,
      },
      {
        label: t('Locais de Estoque'),
        key: 'stockLocations',
        children: <StockLocationsList />,
      },
    ],
    [t, disabled]
  );

  const rightButton = useMemo(
    () => ({
      ...(disabled &&
        params.tab === 'details' && {
          onClick: () => setDisabled(false),
          isVisible: disabled,
          codePermissionEdit: 2004,
          messageTooltipPermission: 'noPermission.supply.lot.edit',
        }),
    }),
    [disabled]
  );

  const leftButton = useMemo(
    (): IPermissionEditButon | undefined => ({
      isVisible: true,
      onClick: () =>
        history.push(`/inventory/products/${lot?.produtoExternalId}/details`),
      leftIcon: 'Box',
      children: t('supply.lot.details.goToProducts'),
      codePermissionEdit: 2007,
      messageTooltipPermission: 'noPermission.supply.lot.detailProducts',
    }),
    [t, lot]
  );

  const dropdownItems = useMemo(
    (): IButtonDropDownItems | undefined => [
      {
        label: 'Excluir',
        icon: 'trash',
        onClick: () => setVisibleModal(true),
        permission: {
          permission: 2006,
          tooltip: 'noPermission.supply.lot.delete',
        },
      },
    ],
    []
  );

  return {
    items,
    rightButton,
    leftButton,
    dropdownItems,
  };
};
