import { FC } from 'react';
import { Modal } from '@/Components/Modal';
import { IInfoModal } from './interfaces';
import { useInfoModalCallAPI } from './Hooks/useInfoModalCallAPI';
import { useInfoModalMemorizations } from './Hooks/useInfoModalMemorizations';
import { useInfoModalFunctions } from './Hooks/useInfoModalFunctions';
import { InfoModalSchema } from './InfoModal.schema';
import { InfoBodyModal } from './InfoBodyModal';

export const InfoModal: FC<IInfoModal> = ({
  visible,
  changeVisibleModal,
  prescriber,
  preferences,
}) => {
  const { editInfo } = useInfoModalCallAPI({ changeVisibleModal });

  const { initialValues } = useInfoModalMemorizations({
    prescriber,
    preferences,
  });

  const { onOkClickForm, onCancelClick, onClose } = useInfoModalFunctions({
    editInfo,
    preferences,
    changeVisibleModal,
  });

  return (
    <Modal
      body={<InfoBodyModal />}
      minWidth={800}
      maxWidth={900}
      title={'Editar detalhes'}
      visible={visible}
      withForm
      htmlType="submit"
      onCancelClick={onCancelClick}
      onClose={onClose}
      initialValues={initialValues}
      onOkClickForm={onOkClickForm}
      loading={editInfo.isLoading}
      schema={InfoModalSchema}
    />
  );
};
