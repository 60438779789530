import {
  FETCH_GET_DROPDOWN_GROUP,
  VALIDATION_NAME_SUBGROUP,
} from '@/ControllerApiHook/UniqIds/Supply/ProductKeys';
import { IUseCallAPI } from './interfaces';
import {
  useControllerMutateApiHook,
  useControllerQueryListApiHook,
} from '@/ControllerApiHook/Controller';
import { ProductAPI } from '@/Data/API/Supply/Product';
import { useCallback } from 'react';

export const useModalBodyCallAPI = ({
  onSubGroupValidationError,
  onSubGroupValidationSuccess,
}: IUseCallAPI) => {
  const {
    data: dataGroupDropDown,
    fetchNewPage: fetchNewGroupPage,
    refetch: refetchGroupItems,
  } = useControllerQueryListApiHook({
    uniqId: FETCH_GET_DROPDOWN_GROUP,
    entityApi: ProductAPI.getGroupListDropdown,
    autoIncrement: true,
    pagination: {
      pageSize: 10,
      pageIndex: 1,
    },
  });

  const onSearch = useCallback(
    (search?: string) => {
      refetchGroupItems({
        search: search,
        pageIndex: 1,
        pageSize: 10,
      });
    },
    [refetchGroupItems]
  );

  const { mutateAsync }: any = useControllerMutateApiHook({
    uniqId: VALIDATION_NAME_SUBGROUP,
    entityApi: ProductAPI.productSubGroupNameValidation,
    options: {
      onError: onSubGroupValidationError,
      onSuccess: onSubGroupValidationSuccess,
    },
  });

  return {
    dataGroupDropDown,
    fetchNewGroupPage,
    mutateAsync,
    onSearch,
  };
};
