import { TFunction } from 'react-i18next';
import { ICannotDeleteModalInfo } from '../ErrorHandlingUtils';

export const cannotDeletePrescitorWithMovementsInTheSystemProps = (
  t: TFunction<'translation', undefined>
): ICannotDeleteModalInfo => ({
  formName: 'prescritorNome',
  oneErrorModal: {
    titleModal: `${t('common.modalCannotDelete.messageTitle')} ${t(
      'prescribers.modalCannotDelete.cannotDeletePrescitorWithMovementsInTheSystem.oneEntity.messageSubtitle'
    )}`,
    subTitleModal: `${t('common.modalCannotDelete.messageSubTitle')} ${t(
      'prescribers.modalCannotDelete.cannotDeletePrescitorWithMovementsInTheSystem.oneEntity.messageSubtitle'
    )}`,
    reasonTranslation: t(
      'prescribers.modalCannotDelete.cannotDeletePrescitorWithMovementsInTheSystem.oneEntity.reasonTranslation'
    ),
    warningModal: t(
      'prescribers.modalCannotDelete.cannotDeletePrescitorWithMovementsInTheSystem.warning'
    ),
  },
  manyErrorsModal: {
    complement: t('prescribers.prescribers'),
    entity: t('common.modalCannotDelete.messageTitle'),
    subTitleModal: t(
      'prescribers.modalCannotDelete.cannotDeletePrescitorWithMovementsInTheSystem.manyEntity.messageSubtitle'
    ),
    reasonTranslation: t(
      'prescribers.modalCannotDelete.cannotDeletePrescitorWithMovementsInTheSystem.manyEntity.reasonTranslation'
    ),
    warningModal: t(
      'prescribers.modalCannotDelete.cannotDeletePrescitorWithMovementsInTheSystem.warning'
    ),
  },
});
