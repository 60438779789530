import { Http, IQueryListParams } from '@/Utils/Http';
import { QueryFunctionContext } from '@tanstack/react-query';

export interface IListProviderResponse {
  pageIndex: number;
  pageSize: number;
  count: number;
  data: IListProviderData[];
}

export interface IListProviderData {
  externalId: string;
  nome: string;
  tipoFornecedor: string;
  tipoPessoa: number;
  tipoPessoaDescricao: string;
}

export const ListSupplier = async (
  context: QueryFunctionContext
): Promise<IListProviderResponse | undefined> => {
  return await Http.fetcher<IListProviderResponse>(
    Http.AddQueryParams(
      '/pharma/pessoa/v1/Fornecedor/Listar',
      context.queryKey[1] as IQueryListParams
    )
  );
};
