import { useState } from "react";

export const useCreateStates = () => {
  const [
    pharmaceuticalFormExternalIdSelected,
    setPharmaceuticalFormExternalIdSelected,
  ] = useState<string>('');

  return {
    pharmaceuticalFormExternalIdSelected,
    setPharmaceuticalFormExternalIdSelected
  };
};