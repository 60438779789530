import { FC } from "react";
import {
  TextInput
} from '@/Components';
import { Col } from 'antd';
import { useTranslation } from "react-i18next";
import { useFormikContext } from "formik";
import { IEquivalenceFactor } from "./interfaces";

export const EquivalenceFactor: FC<IEquivalenceFactor> = ({
  product,
  equivalenceFactor
})  => {
  const { t } = useTranslation();
  const form: any = useFormikContext();

  return (
    <Col span={4}>
      <TextInput
        withStatusIcon={!!product}
        name="fatorEquivalencia"
        placeHolder="0000,00"
        label={t('products.create.equivalenceFactor')}
        disabled
        value={equivalenceFactor}
        withTooltip={{
          icon: 'exclamation-upside-down',
          title: t('products.create.tooltips.equivalenceFactor'),
        }}
      />
    </Col>
  );
};