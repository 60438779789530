import { useState } from "react";
import { v4 as uuidV4 } from 'uuid';

export const usePackingCardStates = () => {
  const [
    associateCapsulesListController, 
    setAssociateCapsulesListController
  ] = useState<string[]>([uuidV4()]);

  const [
    associatePackingListController,
    setAssociatePackingListController] = useState<string[]>([uuidV4()]);

  const [
    disabledCapsulesTypes,
    setDisabledCapsulesTypes
  ] = useState<number[]>([]);

  const [
    disabledPackingTypes,
    setDisabledPackingTypes
  ] = useState<string[]>([]);

  return {
    associateCapsulesListController,
    setAssociateCapsulesListController,
    associatePackingListController,
    setAssociatePackingListController,
    disabledCapsulesTypes,
    setDisabledCapsulesTypes,
    disabledPackingTypes,
    setDisabledPackingTypes
  };
};