import { getCurrencySymbol } from './Currency';
import DateUtils from './DateUtils';
import StorageUtils from './StorageUtils';

export type IMask =
  | 'dataMask'
  | 'cellMaskWithDDI'
  | 'cnpjMask'
  | 'cellMaskWithDDD'
  | 'cepMask'
  | 'cpfMask'
  | 'cep'
  | 'stateRegistration'
  | 'municipalRegistration'
  | 'invoiceNumber'
  | 'phaseMask'
  | 'invoiceSeries';

export const Mask = (mask: IMask) => {
  switch (mask) {
    case 'dataMask':
      return '99/99/9999';

    case 'cellMaskWithDDI':
      return '+99 (99) 99999-9999';

    case 'cnpjMask':
      return '99.999.999/9999-99';

    case 'cellMaskWithDDD':
      return '(99) 99999-9999';

    case 'cepMask':
      return '99999-999';

    case 'cpfMask':
      return '999.999.999-99';

    case 'stateRegistration':
      return '999999999999';

    case 'municipalRegistration':
      return '99999999999';

    case 'invoiceNumber':
      return '999999999';

    case 'invoiceSeries':
      return '999999999';

    case 'phaseMask':
      return '99';

    default:
      return '';
  }
};

export const MaskWithRegex = (text: string) => {
  return text.replaceAll(/R\$.+\//gi, 'I');
};

export const RemoveMask = (
  value: string | undefined,
  dataPattern?: any | undefined
) => {
  if (!value) return;
  if (value && dataPattern)
    return DateUtils.convertDateToApi(value, dataPattern);
  else return value?.replace(/[^\d]+/g, '');
};

export function removeMaskByCurrencyType(value: string): number {
  let valueWithoutMask;

  const preference = StorageUtils.getPreference();

  switch (preference.tipoMoedaId) {
    case 0:
      valueWithoutMask = value.replace(/[R$\s]/g, '').replace(',', '.');
      break;
    case 1:
      valueWithoutMask = value.replace(/[$\s]/g, '').replace(',', '.');
      break;
    default:
      valueWithoutMask = value.replace(/[^\d.-]/g, '');
  }

  return parseFloat(valueWithoutMask) || 0;
}

export const MaskNumberWithCustomDecimalsAndSuffix = (
  input?: number | string,
  decimals: number = 4,
  suffix: string = ''
) => {
  if (!input) return '';

  const inputNumber =
    typeof input === 'string' ? parseFloat(input.replace(',', '.')) : input;
  if (isNaN(inputNumber)) return '';

  const formattedDecimalPart = inputNumber.toFixed(decimals).replace('.', ',');

  return suffix ? `${formattedDecimalPart} ${suffix}` : formattedDecimalPart;
};

export const MaskForCNPJ = (cnpj?: string) =>
  cnpj?.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5') ?? '';

export const MaskForCPF = (cpf?: string) =>
  cpf?.replace(/^(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4') ?? '';

export type IMaskInputNumber = 'currencyMask' | 'percentageMask';

export const FormatterInputNumber = (
  value: string,
  mask?: IMaskInputNumber
) => {
  const symbol = getCurrencySymbol();

  switch (mask) {
    case 'currencyMask':
      return `${symbol} ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',');

    case 'percentageMask':
      return `${value}%`;

    default:
      return value;
  }
};

export const ParserInputNumber = (value?: string, mask?: IMaskInputNumber) => {
  switch (mask) {
    case 'currencyMask':
      return value!.replace(/R?\$\s?|(,*)/g, '');

    case 'percentageMask':
      return value!.replace('%', '');

    default:
      return value;
  }
};

export const TextWithoutExtraBlankValue = (value: string | undefined): string => {
  if(!value) return '';

  if(value[value?.length - 1] === ' ' && value[value?.length - 2] === ' ') {
    return value?.replace(/(?<=\S)\s+(?=\S)/g, ' ').slice(0, -1).trimStart();
  } else {
    return value?.replace(/(?<=\S)\s+(?=\S)/g, ' ').trimStart();
  }
};
