import { useState } from "react";

export const useRadioButtonMessageTypeStates = () => {
  const [selectedOptionsMessageType, setSelectedOptionsMessageType] =
  useState<number>(1);

  const [messageSearch, setMessageSearch] = useState<string>('');

  return {
    selectedOptionsMessageType,
    setSelectedOptionsMessageType,
    messageSearch,
    setMessageSearch
  };
};