import { useControllerQueryListApiHook } from '@/ControllerApiHook/Controller';
import { UnitMeasurementAPI } from '@/Data/API/Supply/UnitMeasurementApi';
import { FETCH_LIST_UNITS_BY_CLASS } from '@/ControllerApiHook/UniqIds/Supply/UnitMeasurementKeys';
import { useFormikContext } from 'formik';
import { isNil } from 'lodash';
import { useEffect } from 'react';

export const useFinanceProductCardCallAPI = () => {
  const form: any = useFormikContext();

  const { data: listunitsMeasurements, refetch } =
    useControllerQueryListApiHook({
      uniqId: FETCH_LIST_UNITS_BY_CLASS,
      entityApi: UnitMeasurementAPI.listUnitMeasurementByClass,
      autoIncrement: true,
      enabled: !isNil(form?.values?.classeProdutoId),
    });

  useEffect(() => {
    refetch({
      pageIndex: 1,
      pageSize: 50,
      filter: [
        {
          value: form?.values?.classeProdutoId,
          filterName: 'tipoClasseProduto',
        },
      ],
    });
  }, [form.values.classeProdutoId]);

  return {
    listunitsMeasurements,
  };
};
