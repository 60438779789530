import { Link, Text, i18n } from '../../../../../Components';
import DateUtils from '../../../../../Utils/DateUtils';
import { currencyFormater } from '../../../../../Utils/Currency';
import { Status } from './Components/Status';
import { TypePurchaseOrderEnum } from '../../../../../Data/Interfaces/model/typePurchaseOrderEnum';
import { IPreferences } from '@/Utils/ThemeUtils';

export const purchaseOrderColumn = (t: any, preference?: IPreferences) => [
  {
    title: t('purchasing.purchaseOrder.list.purchaseOrderNumber'),
    width: '10%',
    dataIndex: 'sequenciaGroupTenant',
    key: 'sequenciaGroupTenant',
    sortColumn: 'sequenciaGroupTenant',
    sorter: true,
    isHighlightable: true,
    defaultSortOrder: 'ascend',
    render: (x: any, row: any) => (
      <Link
        type="ui-tiny-bold"
        to={
          row.status === TypePurchaseOrderEnum.draft
            ? `/order/purchase/create/${row?.externalId}/draft`
            : `/order/purchase/${row?.externalId}/details`
        }
        color="text-50"
      >
        {`#${x}`}
      </Link>
    ),
  },

  {
    title: t('purchasing.purchaseOrder.list.provider'),
    width: '33%',
    dataIndex: 'fornecedor',
    key: 'fornecedor',
    sortColumn: 'fornecedor',
    sorter: true,
    isHighlightable: true,
    render: (x: any, row: any) => (
      <Link
        type="ui-tiny-content"
        to={
          row.status === TypePurchaseOrderEnum.draft
            ? `/order/purchase/create/${row?.externalId}/draft`
            : `/order/purchase/${row?.externalId}/details`
        }
        color="text-50"
      >
        {row?.fornecedor}
      </Link>
    ),
  },
  {
    title: t('purchasing.purchaseOrder.list.releaseDate'),
    width: '13%',
    dataIndex: 'dataLancamento',
    key: 'dataLancamento',
    sortColumn: 'dataLancamento',
    sorter: true,
    isHighlightable: true,
    render: (x: string, row: any) => (
      <Text type="ui-tiny-content" color="text-50">
        {row.status === TypePurchaseOrderEnum.draft
          ? '-'
          : `${DateUtils.convertDate(x, preference?.padraoData)} ${t(
              'common.dateBreakApart'
            )} ${DateUtils.convertDate(x, preference?.padraoHora)}`}
      </Text>
    ),
  },
  {
    title: t('purchasing.purchaseOrder.list.deliveryForecastDate'),
    width: '13%',
    dataIndex: 'previsaoEntrega',
    key: 'previsaoEntrega',
    sortColumn: 'previsaoEntrega',
    sorter: true,
    isHighlightable: true,
    render: (x: string) => (
      <Text type="ui-tiny-content" color="text-50">
        {x === null
          ? '-'
          : `${DateUtils.convertDate(x, preference?.padraoData)}`}
      </Text>
    ),
  },

  {
    title: t('purchasing.purchaseOrder.list.amount'),
    dataIndex: 'totalPedido',
    key: 'totalPedido',
    render: (x: any, row: any) => (
      <Text type={'ui-tiny-content'}>
        {row.status === TypePurchaseOrderEnum.draft
          ? '-'
          : currencyFormater(x, row?.unidadeMedida)}
      </Text>
    ),
  },
  {
    title: t('purchasing.purchaseOrder.list.status'),
    dataIndex: 'status',
    key: 'status',
    sortColumn: 'status',
    sorter: true,
    isHighlightable: true,
    render: (status: any) => (
      <Status
        typePurchase={status}
        style={{ cursor: 'text' }}
        descriptionType="ui-tiny-bold"
      >
        {i18n.t(`purchasing.purchaseOrder.list.statusType.${status - 1}`)}
      </Status>
    ),
  },
];
