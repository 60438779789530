import { useTranslation } from 'react-i18next';
import { Table } from '@/Components/Table';
import { PageLoading } from '@/Layouts/PageLoading';
import { standardFormulaColumns } from './StandardFormula.column';
import { editTableStatus } from '@/Utils/EditModalTableUtils';
import { useListStates } from './Hooks/useListStates';
import { useListCallAPI } from './Hooks/useListCallAPI';
import { useListFunctions } from './Hooks/useListFunctions';
import { useListMemorizations } from './Hooks/useListMemorizations';

const StandardFormulaList: React.FC = () => {
  const { t } = useTranslation();

  const {
    selectedRowsOnTable,
    setSelectedRowsOnTable
  } = useListStates();

  const {
    standardFormulaList,
    isLoadingStandardList,
    refetchStandardFormulaList,
    deleteStandardFormula
  } = useListCallAPI();

  const {
    onDeleteStandardFormula
  } = useListFunctions({ 
    deleteStandardFormula
  });

  const {
    headerOptions,
    rowsOptions,
    deleteModal
  } = useListMemorizations({
    selectedRowsOnTable
  });

  return (
    <PageLoading loading={isLoadingStandardList}>
      <Table
        columns={standardFormulaColumns(t)}
        loading={isLoadingStandardList}
        headerOptions={headerOptions}
        editOptions={editTableStatus()}
        selectedRowsOnTable={setSelectedRowsOnTable}
        hasSelection
        rowsOptions={rowsOptions}
        pagination={standardFormulaList}
        onRefetch={refetchStandardFormulaList}
        onDelete={onDeleteStandardFormula}
        deleteModal={deleteModal}
      ></Table>
    </PageLoading>
  );
};

export default StandardFormulaList;
