import { useCallback } from "react";
import { FormikProps, FormikValues } from 'formik';
import { IModulo, IUserGroup } from "@/Data/Interfaces/response/User/IGetUserReponse";
import { IRow } from "@/Components/CollapseList";
import { IconName } from "@/Components/Icon/IconClasses";
import { createPermissionRequest } from '@/Data/Interfaces/request/UserGroup/IUpdateUserGroupPermission';
import { useDispatch } from 'react-redux';
import { UserGroupActions } from "@/Store/Group/Group.actions";

export const usePermissionFormFunctions = (userGroup?: IUserGroup) => {
  const dispatch = useDispatch();

  const transformModule = useCallback((module: IModulo): IRow => {
    let itens: IRow[] | undefined;
    let totalPermissions: number | undefined = 0;
    let activePermissions: number | undefined = 0;

    if (module.itens) {
      itens = module.itens.map((x) => transformModule(x));
    }

    if (!module.permissaoId) {
      totalPermissions +=
        itens
          ?.map((x) => x.totalPermissions)
          .reduce((a, b) => (a || 0) + (b || 0), 0) || 0;
      totalPermissions += itens?.filter((x) => !!x.permissionId).length || 0;

      activePermissions +=
        itens
          ?.map((x) => x.activePermissions)
          .reduce((a, b) => (a || 0) + (b || 0), 0) || 0;
      activePermissions +=
        itens?.filter((x) => !!x.permissionId && x.active === true).length || 0;
    }

    return {
      name: module.permissaoId?.toString(),
      displayName: module.nome,
      permissionId: module.permissaoId,
      isCollapse: !module.permissaoId,
      active: module.ativo,
      children: itens,
      totalPermissions,
      activePermissions,
      inheritedFrom: module.grupos || [],
      hasInherited: false,
      icon: {
        backgroundColor: module.corDeFundo,
        icon: module.icone as IconName,
        color: !!module?.corDeFundo ? 'white' : 'text-50',
      },
    };
  }, []);

  const onPermissionChange = useCallback(
    (props: FormikProps<FormikValues>) =>
      (
        permission: { id: string; name: string },
        prevValue: boolean | 'inherited' | 'not-inherited',
        nextValue: boolean | 'inherited' | 'not-inherited'
      ) => {
        const params = createPermissionRequest(
          userGroup?.externalId!,
          permission.id,
          nextValue as boolean,
          permission.name,
          props
        );
        dispatch(UserGroupActions.updatePermission(params));
      },
    [dispatch, userGroup]
  );

  return {
    transformModule,
    onPermissionChange
  };
};