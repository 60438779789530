import { IGetSaleOrderResponse } from '@/Data/Interfaces/response/Sales/SalesOrder/ISalesOrderResponse';
import { convertCurrencyToInteger } from '@/Utils/Currency';
import DateUtils from '@/Utils/DateUtils';
import StorageUtils from '@/Utils/StorageUtils';
import { UseMutateAsyncFunction } from '@tanstack/react-query';
import _ from 'lodash';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

interface IuseWithSaleOrderMemorization {
  saleOrder?: IGetSaleOrderResponse;
  mutateAsync: UseMutateAsyncFunction<unknown, unknown, unknown, unknown>;
}

export const useWithSaleOrderMemorization = ({
  saleOrder,
  mutateAsync,
}: IuseWithSaleOrderMemorization) => {
  const preferences = StorageUtils.getPreference();
  const { t } = useTranslation();

  const patchSaleOrder = useMemo(
    () =>
      _.debounce(async (value: any, propName: string) => {
        if (!saleOrder?.externalId) return;

        let data: any = {};

        if (typeof value === 'string')
          data[propName] = convertCurrencyToInteger(value);
        else {
          data[propName] =
            typeof value !== 'object' ? value : value?.target.value;
        }
        return await mutateAsync({
          externalId: saleOrder?.externalId,
          data,
        });
      }, 500),
    [mutateAsync, saleOrder?.externalId]
  );

  const initialValue = saleOrder && {
    ...saleOrder,
    codigoPedido: `#${saleOrder.codigoPedido}`,
    dataLancamento: `${DateUtils.convertDate(
      saleOrder?.dataLancamento,
      preferences?.padraoData
    )} ${t('common.complementDateHour')} ${DateUtils.convertDate(
      saleOrder?.dataLancamento,
      preferences?.padraoHora
    )}`,
    desconto: saleOrder.valorDescontoTotal,
    taxa: saleOrder.taxaEntrega,
  };

  return { patchSaleOrder, initialValue };
};
