import { IUseFunctions } from './interfaces';
import { useMemo } from 'react';
import _ from 'lodash';

export const useInfoCardFunctions = ({
  messageProductAlreadyRegistred,
}: IUseFunctions) => {
  const fetchProductMessageName = useMemo(
    () =>
      _.debounce(async (value: string) => {
        try {
          return await messageProductAlreadyRegistred(value);
        } catch {
          return undefined;
        }
      }, 500),
    [messageProductAlreadyRegistred]
  );

  const onChange = (text: string) => {
    fetchProductMessageName(text);
  };

  return {
    onChange,
    fetchProductMessageName,
  };
};
