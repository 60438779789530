import { useControllerQueryListApiHook } from '@/ControllerApiHook/Controller';
import { CasAPI } from '@/Data/API/Production/Cas';
import { FETCH_LIST_DROPDOWN_CAS } from '@/ControllerApiHook/UniqIds/Production/CasKeys';

export const useCasCallAPI = () => {
  const {
    data: casList,
    isLoading: isLoadingCas,
    fetchNewPage: fetchNewCasPage,
    refetch: refetchSearchCasPage,
  } = useControllerQueryListApiHook({
    uniqId: FETCH_LIST_DROPDOWN_CAS,
    entityApi: CasAPI.listDropdownCas,
    autoIncrement: true,
    autoIncrementCustomId: 'id',
  });

  return {
    casList,
    isLoadingCas,
    fetchNewCasPage,
    refetchSearchCasPage,
  };
};
