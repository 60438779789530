import { Button, Card, Checkbox, Form } from '@/Components';
import { Col, Row } from 'antd';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { Product } from './Components/Product';
import { PharmaceuticalForm } from './Components/PharmaceuticalForm';
import { DosageInputs } from './Components/DosageInputs';
import { useCreateDilutedProductCallAPI } from './Hooks/useCreateDilutedProductCallAPI';
import { useCreateDilutedProductFunctions } from './Hooks/useCreateDilutedProductFunctions';

export const CreateDilutedProduct: FC = () => {
  const { t } = useTranslation();
  const { externalId: productExternalId } = useParams<{ externalId: string }>();

  const { createDilutedProduct } = useCreateDilutedProductCallAPI();

  const { handleSubmit, disableSubmitButton, onClickCancelButton } =
    useCreateDilutedProductFunctions({
      createDilutedProduct,
      productExternalId,
    });

  return (
    <Form onSubmit={handleSubmit}>
      {(props: any) => (
        <Card
          title={t('products.diluted.newDilutedProductTitle')}
          withoutCollapse
        >
          <Row gutter={[16, 0]}>
            <Product productExternalId={productExternalId} />
          </Row>
          <Row gutter={[16, 0]}>
            <PharmaceuticalForm props={props} />
            <DosageInputs />
          </Row>
          <Row gutter={[16, 0]}>
            <Col span={8}>
              <Checkbox
                name="seTodasFormasFarmaceuticas"
                style={{
                  marginTop: -15,
                  marginBottom: 20,
                  fontWeight: '500',
                }}
                label="Todas as Formas Farmacêuticas"
              />
            </Col>
            <Col span={8}>
              <Checkbox
                name="seQualquerDosagem"
                style={{
                  marginTop: -15,
                  marginBottom: 20,
                  fontWeight: '500',
                }}
                label="Qualquer Dosagem"
              />
            </Col>
          </Row>
          <div style={{ display: 'flex' }}>
            <Button
              type="secondary"
              style={{ marginRight: 8 }}
              children={t('common.cancel')}
              onClick={onClickCancelButton}
            />
            <Button
              type="primary"
              children={
                createDilutedProduct.isLoading
                  ? t('common.creating')
                  : t('common.create')
              }
              htmlType="submit"
              loading={createDilutedProduct.isLoading}
              disabled={disableSubmitButton(props.values)}
            />
          </div>
        </Card>
      )}
    </Form>
  );
};
