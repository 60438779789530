import { Button, Card, Form, TextArea } from '@/Components';
import { NumberInput } from '@/Components/Inputs/NumberInput';
import { PercentageInput } from '@/Components/Inputs/PercentageInput';
import { Col, Row } from 'antd';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { Product } from './Components/Product';
import { SynonymInput } from './Components/SynonymInput';
import { useCreateSynonymCallAPI } from './Hooks/useCreateSynonymCallAPI';
import { useCreateSynonymFunctions } from './Hooks/useCreateSynonymFunctions';

export const CreateSynonym: FC = () => {
  const { externalId: productExternalId } = useParams<{ externalId: string }>();
  const { t } = useTranslation();

  const {
    currentProduct,
    createSynonym
  } = useCreateSynonymCallAPI({
    productExternalId
  });

  const {
    handleSubmit,
    onClickCancelButton
  } = useCreateSynonymFunctions({
    createSynonym,
    productExternalId
  });

  return (
    <Form onSubmit={handleSubmit}>
      {(props: any) => (
        <>
          <Card
            title={t('products.synonym.registerSynonymTitle')}
            withoutCollapse
          >
            <Row gutter={[16, 0]}>
              <Product currentProduct={currentProduct} />
            </Row>
            <Row gutter={[16, 0]}>
              <Col span={8}>
                <SynonymInput
                  currentProductDescription={
                    currentProduct ? currentProduct.descricao : ''
                  }
                />
              </Col>
              <Col span={4}>
                <NumberInput
                  name="fatorEquivalencia"
                  label={t('products.synonym.equivalenceFactor')}
                  placeHolder="Ex: 1"
                />
              </Col>
              <Col span={4}>
                <PercentageInput
                  name="percentualCorrecao"
                  label={t('products.synonym.correctionPercentage')}
                  placeHolder="Ex: 5%"
                />
              </Col>
            </Row>
            <Row gutter={[16, 0]}>
              <Col span={24}>
                <TextArea
                  name="descricaoRotulo"
                  label={t('products.synonym.labelDescription')}
                  maxLength={200}
                  placeholder={t('products.synonym.descriptionPlaceholder')}
                />
              </Col>
            </Row>
          </Card>
          <div style={{ display: 'flex' }}>
            <Button
              type="secondary"
              style={{ marginRight: 8 }}
              children={t('common.cancel')}
              onClick={onClickCancelButton}
            />
            <Button
              type="primary"
              children={
                createSynonym.isLoading
                  ? t('common.creating')
                  : t('common.create')
              }
              htmlType="submit"
              loading={createSynonym.isLoading}
              disabled={!props.values.sinonimo}
            />
          </div>
        </>
      )}
    </Form>
  );
};
