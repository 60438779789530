import { FC } from 'react';
import { InputWithSearch } from '@/Components';
import { Col } from 'antd';
import { useTranslation } from 'react-i18next';
import { IRegisterInput } from './interfaces';
import { useRegisterInputMemorizations } from './Hooks/useRegisterInputMemorizations';
import { useRegisterInputFunctions } from './Hooks/useRegisterInputFunctions';

import styles from './RegisterInput.module.scss';

export const RegisterInput: FC<IRegisterInput> = ({
  recordTypeList,
  fetchNewRecordPage,
  refetchRecordTypeList,
  fetchValidateRegistrationPrescriber,
  form,
}) => {
  const { t } = useTranslation();

  const { items } = useRegisterInputMemorizations({ recordTypeList });

  const { onSearch } = useRegisterInputFunctions({ refetchRecordTypeList });

  return (
    <Col flex="17%">
      <InputWithSearch
        name="tipoRegistroId"
        placeHolder={t('prescribers.createPage.info.registerPlaceholder')}
        label={t('prescribers.createPage.info.register')}
        required
        items={items}
        onScrollEnd={fetchNewRecordPage}
        className={styles['register-type']}
        onSearch={onSearch}
        withoutSearchIcon
        onChange={(x: string) => {
          if (!!form.values.codigoRegistro) {
            fetchValidateRegistrationPrescriber(
              form.values.codigoRegistro,
              +x,
              form.values.ufRegistroExternalId
            );
          }
        }}
      />
    </Col>
  );
};
