import { Tooltip } from '@/Components';
import { TextInputWithMask } from '@/Components/Inputs/TextInputWithMask';
import { IAddFinishedProductRequest } from '@/Data/Interfaces/request/Sales/Service/IServiceRequest';
import DateUtils from '@/Utils/DateUtils';
import { IPreferences } from '@/Utils/ThemeUtils';
import { FormikContextType, useFormikContext } from 'formik';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

interface IPrescriptionDate {
  datePattern?: IPreferences;
}

export const PrescriptionDate: FC<IPrescriptionDate> = ({ datePattern }) => {
  const { t } = useTranslation();

  const [dateGreaterThanCurrentDay, setDateGreaterThanCurrentDay] =
    useState(false);

  const form: FormikContextType<IAddFinishedProductRequest> =
    useFormikContext();

  useEffect(() => {
    const parsedDate = DateUtils.convertStringToDate(
      form.values.dataPrescricao
    );
    setDateGreaterThanCurrentDay(parsedDate > new Date());
  }, [form.values.dataPrescricao]);

  return (
    <Tooltip
      title={t(
        'saleOrder.editOrder.SaleOrder.addProductModal.dateGreaterThanCurrentDay'
      )}
      showMe={dateGreaterThanCurrentDay}
    >
      <TextInputWithMask
        name="dataPrescricao"
        placeHolder={DateUtils.getDatePlaceholder(
          datePattern?.idioma,
          datePattern?.padraoData
        )}
        label={t(
          'saleOrder.editOrder.SaleOrder.addProductModal.prescriptionDate'
        )}
        mask="dataMask"
        leftIcon="calendar"
        withoutMarginBottom
        error={dateGreaterThanCurrentDay}
      />
    </Tooltip>
  );
};
