import React, { FC } from 'react';
import { Icon } from '../../../../../../Components';

import styles from './Check.module.scss';

export const Check: FC = () => {
	return (
		<div className={styles['container']}>
			<Icon color='white' icon='check' size='SM' />
		</div>
	)
}