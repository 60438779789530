import { IListUserData } from "@/Data/Interfaces/response/User/IGetUserReponse";
import { useState } from "react";

export const useAddUserGroupStates = () => {
  const [usersSelected, setUsersSelected] = useState<IListUserData[]>([]);

  return {
    usersSelected,
    setUsersSelected
  };
};