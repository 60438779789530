import { useControllerMutateApiHook } from '@/ControllerApiHook/Controller';
import { IUseCallAPI } from './interface';
import { THERE_IS_PACKING_CLASSIFICATION_TYPE } from '@/ControllerApiHook/UniqIds/Production/PackingClassificationKeys';
import { PackingClassificationAPI } from '@/Data/API/Production/PackingClassification';

export const useEditCallAPI = ({ form, t }: IUseCallAPI) => {
  const { mutateAsync }: any = useControllerMutateApiHook({
    uniqId: THERE_IS_PACKING_CLASSIFICATION_TYPE,
    entityApi:
      PackingClassificationAPI.isPackingClassificationNameAlreadyRegistred,
    options: {
      onSuccess: () => {
        form.setFieldError('descricao', undefined);
      },
      onError: () => {
        form.setFieldError(
          'descricao',
          t(
            'production.packingClassification.create.erros.errorPackingClassificationNameExist'
          )
        );
      },
    },
  });

  return { mutateAsync };
};
