import * as yup from 'yup';
import i18n from '../../../../../../../Components/i18n';
import DateUtils from '../../../../../../../Utils/DateUtils';
import _ from 'lodash';

export const DetailSchema = () =>
  yup.object().shape({
    nome: yup
      .string()
      .required(
        i18n.t('admin.editUserPage.personalDataForm.errors.requiredName')
      ),
    sobrenome: yup
      .string()
      .required(
        i18n.t('admin.editUserPage.personalDataForm.errors.requiredLastName')
      ),
    email: yup
      .string()
      .email(i18n.t('admin.editUserPage.personalDataForm.errors.invalidEmail'))
      .required(
        i18n.t('admin.editUserPage.personalDataForm.errors.requiredEmail')
      ),
    emailAlternativo: yup
      .string()
      .email(i18n.t('admin.editUserPage.personalDataForm.errors.invalidEmail'))
      .notOneOf(
        [yup.ref('email')],
        i18n.t(
          'admin.editUserPage.personalDataForm.errors.invalidAlternativeEmail'
        )
      )
      .nullable(),
    dataNascimento: yup
      .string()
      .test(
        'date validation',
        i18n.t('common.dateInvalid'),
        (val) => _.isNil(val) || DateUtils.isDateValid(val)
      )
      .nullable(),
    grupos: yup
      .string()
      .required(
        i18n.t('admin.editUserPage.personalDataForm.errors.requiredGroup')
      ),
  });
