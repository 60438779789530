import { Route, Switch } from 'react-router-dom';
import BalanceAdjustmentsList from '@/Pages/Sectors/Inventory/BalanceAdjustments/List';
import BalanceAdjustmentDetails from '@/Pages/Sectors/Inventory/BalanceAdjustments/Details';
import { CreateBalanceAdjustment } from '@/Pages/Sectors/Inventory/BalanceAdjustments/Create';

export const BalanceAdjustmentsRoutes = () => (
  <Switch>
    <Route
      path="/inventory/balance-adjustments/create"
      component={CreateBalanceAdjustment}
    />
    <Route
      path="/inventory/balance-adjustments/:externalId/details"
      component={BalanceAdjustmentDetails}
    />
    <Route
      path="/inventory/balance-adjustments"
      component={BalanceAdjustmentsList}
    />
  </Switch>
);
