import { TextInput } from '@/Components';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormikContext } from 'formik';
import { ISynonymInput } from './interfaces';
import { useSynonymInputCallAPI } from './Hooks/useSynonymInputCallAPI';
import { useSynonymInputFunctions } from './Hooks/useSynonymInputFunctions';

export const SynonymInput: FC<ISynonymInput> = ({
  currentProductDescription
}) => {
  const { t } = useTranslation();
  const form: any = useFormikContext();

  const {
    onProductValidationError
  } = useSynonymInputFunctions({});

  const {
    mutateAsync,
    error
  } = useSynonymInputCallAPI({
    onProductValidationError
  });

  const {
    onChange
  } = useSynonymInputFunctions({
    currentProductDescription,
    mutateAsync
  });

  return (
    <TextInput
      name="sinonimo"
      label={t('products.synonym.synonym')}
      required
      placeHolder={t('products.synonym.synonymPlaceholder')}
      onChange={onChange}
      error={!!error && form.errors.sinonimo}
    />
  );
};
