import { useControllerMutateApiHook } from '@/ControllerApiHook/Controller';
import {
  DELETE_LABORATORY_DETAILS,
  FETCH_LIST_FOR_LABORATORY,
} from '@/ControllerApiHook/UniqIds/Production/LaboratoryKeys';
import { LaboratoryAPI } from '@/Data/API/Production/Laboratory';
import { history } from '@/Store';
import { useQueryClient } from '@tanstack/react-query';

export const useEditCallAPI = () => {
  const queryClient = useQueryClient();
  const {
    mutateAsync: deleteLaboratory,
    isLoading: isLoadingDeleteLaboratory,
  }: any = useControllerMutateApiHook({
    uniqId: DELETE_LABORATORY_DETAILS,
    entityApi: LaboratoryAPI.deleteLaboratoryDetails,
    options: {
      onSuccess: () => {
        queryClient.invalidateQueries([FETCH_LIST_FOR_LABORATORY]);
        history.push('/production/labs');
      },
    },
  });

  return { deleteLaboratory, isLoadingDeleteLaboratory };
};
