import { 
  ICreateSynonymProductRequest 
} from '@/Data/Interfaces/request/Product/ICreateSynonymProductRequest';
import {
  FETCH_SYNONYM_PRODUCT
} from '@/ControllerApiHook/UniqIds/Supply/ProductKeys';
import { history } from '@/Store';
import { useQueryClient } from '@tanstack/react-query';
import { iUseFunctions } from './interfaces';

export const useSynonymDetailsFunctions = ({
  updateSynonym,
  associationProductId,
  productExternalId,
  disableForm
}: iUseFunctions) => {
  const queryClient = useQueryClient();

  const handleSubmit = async (values: ICreateSynonymProductRequest) => {
    const result = await updateSynonym.mutateAsync({
      ...values,
      produtoExternalId: associationProductId,
      descricaoRotulo: values.descricaoRotulo ?? '',
    });
    if (result) {
      disableForm();
      queryClient.invalidateQueries([FETCH_SYNONYM_PRODUCT]);
      history.push(`/inventory/products/${productExternalId}/synonym`);
    }
  };

  const onClickBackButton = () => {
    history.push(
      `/inventory/products/${productExternalId}/synonym`
    );
  };

  return {
    handleSubmit,
    onClickBackButton
  }
};