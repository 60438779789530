import { EditTickets } from '@/Pages/Sectors/Sales/Tickets/Edit';
import { List } from '@/Pages/Sectors/Sales/Tickets/List';
import { Route, Switch } from 'react-router-dom';

export const TicketsRoutes = () => (
  <Switch>
    <Route path="/sales/tickets/:externalId/:tabs" component={EditTickets} />
    <Route path="/sales/tickets" component={List} />
  </Switch>
);
