import { FC } from 'react';
import { IconName } from '../Icon/IconClasses';
import { Color, Colors } from '@/Utils/ColorUtils';
import { Divflex } from '@/Layouts/DivWhithFlex';
import { Icon, Text } from '..';

import styles from './StatusChip.module.scss';

interface IStatusTag {
  children: string;
  statusColor: Color;
  icon: IconName;

  onClick?: () => void;
}
export const StatusTag: FC<IStatusTag> = ({
  children,
  icon,
  statusColor,
  onClick,
}) => {
  return (
    <Divflex className={styles['container']} onClick={onClick}>
      <div
        className={styles['status']}
        style={{ backgroundColor: Colors[statusColor] }}
      />
      <Divflex className={styles['status-tag']}>
        <Icon
          icon={icon}
          size="M"
          className={styles['channel-icon']}
          color="text-50"
        />
        <Text type="small-text-bold" children={children} color="text-50" />
        <Icon
          icon="external-link"
          size="SG"
          className={styles['external-link-icon-tag']}
          color="text-300"
        />
      </Divflex>
    </Divflex>
  );
};
