import { IListPrescribersData } from '../../../../../Data/Interfaces/response/Prescribers/IPrescribersResponse';
import { Icon, Link, Status, Text, i18n } from '../../../../../Components';
import { Divflex } from '../../../../../Layouts/DivWhithFlex';
import { IListClientData } from '../../../../../Data/Interfaces/response/Client/IClientResponse';
import { MaskForCNPJ, MaskForCPF } from '../../../../../Utils/MasksUtils';
import { formatPhoneNumber } from 'react-phone-number-input';
import { UppercaseFirstLetter } from '../../../../../Utils/CapitalFirstLetterUtils';
import { getCurrentUserRoles } from '@/Utils/PermissionUtils';
import { PermissionEnum } from '@/Data/Interfaces/model/permissionEnum';
import { IColumn } from '@/Components/Table';

export const customersCollums = (t: any): IColumn[] => [
  {
    title: t('client.listPage.code'),
    dataIndex: 'codigo',
    key: 'codigo',
    width: '9%',
    isHighlightable: true,
    sortColumn: 'codigo',
  },
  {
    title: t('client.listPage.clientName'),
    dataIndex: 'nome',
    key: 'nome',
    sortColumn: 'nome',
    isHighlightable: true,
    defaultSortOrder: 'ascend',
    render: (x: string, row: any) => (
      <Link
        type="ui-tiny-bold"
        to={
          getCurrentUserRoles().some(
            (x) => PermissionEnum.Vendas_Clientes_VerDetalhes === x
          )
            ? `/sales/customers/${row?.externalId}/details`
            : undefined
        }
        style={{ width: 'fit-content' }}
      >
        {UppercaseFirstLetter(x)}
      </Link>
    ),
  },
  {
    title: 'CPF/CNPJ',
    dataIndex: 'tipoPessoa',
    key: 'tipoPessoa',
    width: '16%',
    isHighlightable: true,
    render: (type: any, row: IListClientData) => (
      <Text
        type="ui-tiny-content"
        children={type === 0 ? MaskForCPF(row.cpf) : MaskForCNPJ(row.cnpj)}
      />
    ),
  },
  {
    title: t('client.listPage.contact'),
    dataIndex: 'contatoPrincipal',
    key: 'contatoPrincipal',
    width: '18%',
    isHighlightable: true,
    sortColumn: 'contatoPrincipal',
    render: (x: string, row: IListPrescribersData) => (
      <Divflex>
        <Icon
          icon={row.tipoContatoIcon}
          size="SM"
          style={{ marginRight: 15 }}
          color="text-50"
        />
        <Text
          type="ui-tiny-content"
          color="text-50"
          children={x && x[0] === '+' ? formatPhoneNumber(x) : x}
        />
      </Divflex>
    ),
  },
  {
    title: t('client.listPage.personType'),
    dataIndex: 'tipoPessoaDescricao',
    key: 'tipoPessoaDescricao',
    width: '11%',
  },
  {
    title: 'Status',
    dataIndex: 'ativo',
    key: 'ativo',
    width: '11%',
    render: (text: any) => (
      <Status type={text ? 'success' : 'disabled'}>
        {i18n.t(`common.${text ? 'activate' : 'inactivated'}`)}
      </Status>
    ),
  },
];
