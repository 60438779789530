import {
  GET_USER_LIST_FROM_USER_GROUP_TYPE,
  REMOVE_USER_IN_USER_GROUP,
} from '@/ControllerApiHook/UniqIds/Accountmanager/UserGroupKeys';
import {
  useControllerMutateApiHook,
  useControllerQueryListApiHook,
} from '@/ControllerApiHook/Controller';
import { UserGroupAPI } from '@/Data/API/Accountmanager/UserGroupApi';
import { useQueryClient } from '@tanstack/react-query';
import { IUseCallAPI } from './interfaces';

export const useUserFormCallAPI = ({ externalId }: IUseCallAPI) => {
  const queryClient = useQueryClient();

  const {
    data: userListData,
    isLoading: userListLoading,
    refetch: userListRefetch,
  } = useControllerQueryListApiHook({
    uniqId: GET_USER_LIST_FROM_USER_GROUP_TYPE,
    entityApi: UserGroupAPI.getUserListFromUserGroup,
    pagination: {
      sorter: { column: 'nome', direction: 'ASC' },
      filter: [
        {
          filterName: 'grupoExternalId',
          value: externalId,
        },
      ],
    },
  });

  const removeUserInUserGroup = useControllerMutateApiHook({
    uniqId: REMOVE_USER_IN_USER_GROUP,
    entityApi: UserGroupAPI.removeUserInGroup,
    options: {
      onSuccess: () =>
        queryClient.invalidateQueries([GET_USER_LIST_FROM_USER_GROUP_TYPE]),
    },
  });

  return {
    userListData,
    userListLoading,
    userListRefetch,
    removeUserInUserGroup,
  };
};
