import { FC } from 'react';
import { Row } from 'antd';
import { IDetailsForm } from '../../interfaces';
import { SupplierDilution } from './Components/SupplierDilution';
import { DilutionFactor } from './Components/DilutionFactor';
import { WaterConcentration } from './Components/WaterConcentration';
import { WaterConcentrationFactor } from './Components/WaterConcentrationFactor';
import { InternalDilution } from './Components/InternalDilution';
import { InternalDilutionFactor } from './Components/InternalDilutionFactor';
import { BatchSearch } from './Components/BatchSearch';

import styles from "./Dilutions.module.scss";
import { useDilutionsCallAPI } from './Hooks/useDilutionsCallAPI';

export const TechnicalInfoDiluitionsRows: FC<IDetailsForm> = ({ disabled }) => {
  const {
    lots,
    lotList,
    isLotLoading,
    fetchNewLotPage
  } = useDilutionsCallAPI();

  return (
    <>
      <Row gutter={[16, 0]}>
        <SupplierDilution
          lots={lots}
          disabled={disabled}
        />
        <DilutionFactor
          lots={lots}
          disabled={disabled}
        />
        <div className={styles["divider"]} />
        <WaterConcentration
          lots={lots}
          disabled={disabled}
        />
        <WaterConcentrationFactor
          lots={lots}
          disabled={disabled}
        />
      </Row>
      <div className={styles["horizontal-divider"]}>
        <Row gutter={[16, 0]} style={{ marginTop: '20px' }}>
          <InternalDilution
            lots={lots}
            disabled={disabled}
          />
          <InternalDilutionFactor
            lots={lots}
            disabled={disabled}
          />
          <BatchSearch
            lots={lots}
            disabled={disabled}
            lotList={lotList}
            isLotLoading={isLotLoading}
            fetchNewLotPage={fetchNewLotPage}
          />
        </Row>
      </div>
    </>
  );
};
