import { isNumeric } from '@/Utils/IsNumericUtils';
import { IUseFunctions } from './interfaces';
import { useFormikContext } from 'formik';

export const useUnitaryDiscountFunctions = ({
  setTotal,
  getTotalValue,
  index,
}: IUseFunctions) => {
  const form: any = useFormikContext();

  const onChange = (x: any) => {
    if (x.includes('-')) {
      const numberValue = Number(x.replace('-', '').replace(',', '.'));
      form.setFieldValue(
        `pedidoCompraItens.${index}.valorDescontoUnitario`,
        numberValue
      );

      setTotal(
        getTotalValue({
          newDiscountValue: parseFloat(
            isNumeric(numberValue) ? `${numberValue}` : '0'
          ),
        })
      );

      return;
    }

    setTotal(
      getTotalValue({
        newDiscountValue: parseFloat(isNumeric(x) ? x : 0),
      })
    );
  };

  return {
    onChange,
  };
};
