import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { Table } from '../../../../../../../Components/Table';
import { linkedProductCollumn } from './Linked.column';
import { ContainerPage } from '../../../../../../../Layouts/ContainerPage';
import { PageLoading } from '../../../../../../../Layouts/PageLoading';
import { useEditStates } from './Hooks/useEditStates';
import { useEditCallAPI } from './Hooks/useEditCallAPI';
import { useEditMemorizations } from './Hooks/useEditMemorizations';
import { useEditFunctions } from './Hooks/useEditFunctions';

interface ILinkedProduct {
  packingClassification?: string;
}

export const LinkedProduct: FC<ILinkedProduct> = ({
  packingClassification,
}) => {
  const { t } = useTranslation();
  const { externalId } = useParams<{ externalId: string }>();

  const { selectedRowsOnTable, setSelectedRowsOnTable } = useEditStates();

  const {
    productLinkedList,
    isLoading,
    removeProductLinked,
    refetchPackingClassificationLinkedList,
  } = useEditCallAPI({
    externalId,
  });

  const { headerOptions, deleteModal } = useEditMemorizations({
    productLinkedList,
    selectedRowsOnTable,
    packingClassification,
  });

  const { onRemoveProductLinkedPacking } = useEditFunctions({
    removeProductLinked,
    externalId,
  });

  return (
    <PageLoading loading={isLoading}>
      <ContainerPage>
        <Table
          columns={linkedProductCollumn(t)}
          headerOptions={headerOptions}
          pagination={productLinkedList}
          paginationCustomExternalId="produtoExternalId"
          onRefetch={refetchPackingClassificationLinkedList}
          loading={isLoading}
          hasSelection
          selectedRowsOnTable={setSelectedRowsOnTable}
          onDelete={onRemoveProductLinkedPacking}
          deleteModal={deleteModal}
        ></Table>
      </ContainerPage>
    </PageLoading>
  );
};
