import { IEditProductGroupRequest } from "@/Data/Interfaces/request/Product/IProductRequest";
import { FETCH_GET_GROUP } from "@/ControllerApiHook/UniqIds/Supply/ProductKeys";
import { useQueryClient } from "@tanstack/react-query";
import { FormikHelpers, FormikValues } from "formik";
import { useCallback } from "react";

import { IUseFunctions } from "./interfaces";

export const useDetailsFormFunctions = ({
  data,
  editGroup,
  toogleDisabled,
  disabled
}: IUseFunctions) => {
  const queryClient = useQueryClient();

  const onSubmit = useCallback(async (
    values: IEditProductGroupRequest,
    helpers: FormikHelpers<FormikValues>
  ) => {
    const body: IEditProductGroupRequest = { ...values, helpers };

    if(data) {
      const res = await editGroup.mutateAsync({
        grupoExternalId: data?.externalId,
        descricao: body?.descricao,
        helpers,
      });
      if (res.success) {
        queryClient.invalidateQueries([FETCH_GET_GROUP]);
        toogleDisabled(!disabled);
      }
    }
  }, [
    data, 
    editGroup, 
    queryClient, 
    disabled, 
    toogleDisabled
  ]);

  return {
    onSubmit
  };
};