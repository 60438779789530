import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { Tag, Tooltip } from '../../Components';

import styles from './TagsUtils.module.scss';

interface ITagsRender {
  data: string[];
  widthCollum: number;
  rowSelected?: boolean;
}

export const TagsRender = ({ data, widthCollum, rowSelected }: ITagsRender) => {
  const [widthLastTag, setWidthLastTag] = useState<number>(-1);
  const [lastTagIndex, setLastTagIndex] = useState<number>(-1);
  const [firstTagIsBigger, setFirstTagIsBigger] = useState<boolean>(false);
  const [
    tagWidth, 
    setTagWidth
  ] = useState<{ index: number; width: number; }[]>();

  const ellipseDivWidth = useMemo(() => 19, []);
  const maxWidthLastTag = useMemo(() => 40, []);
  const widthCollumMinusEllipseDiv = useMemo(
    () => widthCollum - ellipseDivWidth,
    [ellipseDivWidth, widthCollum]
  );

  useEffect(() => {
    let widthTagsSum = 0;
    let lastIndex = 0;
    let lastWidth = 0;
    let firstTagIsBiggerAux = false;

    tagWidth?.forEach((x, index) => {
      if (firstTagIsBiggerAux) return;
      if (index === 0 && x.width > widthCollumMinusEllipseDiv) {
        firstTagIsBiggerAux = true;
        setWidthLastTag(widthCollumMinusEllipseDiv);
        setLastTagIndex(0);
        setFirstTagIsBigger(true);
        return;
      }
      widthTagsSum += x.width;

      if (widthTagsSum > widthCollumMinusEllipseDiv && lastIndex === 0) {
        lastIndex = index;
        lastWidth = widthCollum - (widthTagsSum - x.width + ellipseDivWidth);
        return;
      }
    });

    if (firstTagIsBiggerAux) return;

    lastWidth > maxWidthLastTag && setWidthLastTag(lastWidth);
    
    setLastTagIndex(lastWidth > maxWidthLastTag ? lastIndex : lastIndex - 1);
  }, [
    widthCollum,
    tagWidth,
    ellipseDivWidth,
    firstTagIsBigger,
    maxWidthLastTag,
    widthCollumMinusEllipseDiv,
  ]);

  useEffect(() => {
    let width: { index: number; width: number }[] = [];
  
    data?.forEach((x, index) => {
      const elementId = `${x}-${index}`;
      const tagElement = document?.getElementById(elementId);
  
      if (tagElement) {
        const elementWidth = tagElement?.getBoundingClientRect().width || 0;

        if(elementWidth === 0) {
          const tempElement = document.createElement('div');
          tempElement.style.position = 'absolute';
          tempElement.style.visibility = 'hidden';
          tempElement.textContent = x;
          document.body.appendChild(tempElement);
    
          const tempWidth = tempElement.getBoundingClientRect().width || 0;
    
          width.push({
            index: index,
            width: tempWidth,
          });
    
          document.body.removeChild(tempElement);
        } else {
          width.push({
            index: index,
            width: elementWidth,
          });
        }
      }
    });

    setTagWidth(width);
  }, [data]);

  const styleTag = useCallback(
    (index: number) =>
      index > lastTagIndex && lastTagIndex >= 0
        ? { display: 'none' }
        : lastTagIndex === index && lastTagIndex >= 0 && widthLastTag >= 0
        ? { width: widthLastTag, color: 'red' }
        : {
            width:
              firstTagIsBigger && index === 0
                ? widthCollumMinusEllipseDiv
                : 'fitContent',
          },

    [firstTagIsBigger, lastTagIndex, widthCollumMinusEllipseDiv, widthLastTag]
  );

  const tags = useMemo(
    () =>
      data?.map((x: string, index: any) => (
        <div style={styleTag(index)} key={index}>
          <TagOnTable
            index={index}
            groupName={x}
            lastTagIndex={lastTagIndex}
            rowSelected={rowSelected}
          />
        </div>
      )),
    [data, lastTagIndex, rowSelected, styleTag]
  );

  return (
    <div
      className={styles['container']}
      style={{
        width: widthCollum,
      }}
    >
      <div
        className={styles['tag-container']}
        style={{
          width: widthCollum,
        }}
      >
        <div style={{ width: 'fitContent', display: 'flex' }}>{tags}</div>
      </div>
      {((firstTagIsBigger && data.length > 1) || lastTagIndex > 0) && (
        <Tooltip
          placement="topLeft"
          title={data?.map(
            (x: any, i: any) =>
              i > lastTagIndex && (
                <div key={i}>
                  {x}
                  <br />
                </div>
              )
          )}
          showMe
        >
          <div
            className={`${styles['ellipse']} ${
              rowSelected ? styles['row-selected'] : ''
            }`}
          >
            ...
          </div>
        </Tooltip>
      )}
    </div>
  );
};

interface ITagOnTable {
  index: number;
  groupName: string;
  lastTagIndex: number;
  rowSelected?: boolean;
}

const TagOnTable: FC<ITagOnTable> = ({
  index,
  groupName,
  lastTagIndex,
  rowSelected,
}) => (
  <Tag
    tooltip={lastTagIndex >= index || lastTagIndex < 0 ? groupName : undefined}
    className={`${styles['tag']} ${
      lastTagIndex > index ? styles['tag-invisible'] : ''
    } ${rowSelected ? styles['row-selected'] : ''}`}
    type="secondary"
    id={`${groupName}-${index}`}
  >
    {groupName}
  </Tag>
);
