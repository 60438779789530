import { IUseOrderListFunctions } from './interface';

export const useOrderListFunctions = ({
  createSaleOrder,
}: IUseOrderListFunctions) => {
  const onSubmit = async (values: any) => {
    await createSaleOrder.mutateAsync({
      ...values,
      criarPedidoVenda: true,
    });
  };
  return { onSubmit };
};
