import { FC } from "react";
import {
  Dropdown
} from '@/Components';
import { Col } from 'antd';
import { useTranslation } from 'react-i18next';
import { IAssociateCapsules } from "./interfaces";
import { useAssociateCapsulesCallAPI } from "./Hooks/useAssociateCapsulesCallAPI";
import { useAssociateCapsulesMemorizations } from "./Hooks/useAssociateCapsulesMemorizations";
import { useAssociateCapsuleFunctions } from "./Hooks/useAssociateCapsuleFunctions";

export const AssociateCapsules: FC<IAssociateCapsules> = ({
  disabledForm,
  index,
  disabledCapsulesTypes,
  setDisabledCapsulesTypes,
  associateCapsulesListController,
  setAssociateCapsulesListController
}) => {
  const { t } = useTranslation();

  const {
    listCapsuleSize,
    isLoadingCapsuleSize,
    fetchNewPageCapsuleSize
  } = useAssociateCapsulesCallAPI();

  const { items } = useAssociateCapsulesMemorizations({
    listCapsuleSize,
    disabledCapsulesTypes
  });

  const {
    onChange
  } = useAssociateCapsuleFunctions({
    index,
    disabledCapsulesTypes,
    setDisabledCapsulesTypes,
    associateCapsulesListController,
    setAssociateCapsulesListController
  });

  return(
    <Col span={4}>
      <Dropdown
        name={`numeroCapsulaAssociacao.${index}.numeroCapsulaId`}
        label={
          index === 0 ? t('products.create.associateCapsules') : ''
        }
        placeHolder={t('products.create.associateCapsulesPlaceholder')}
        isLoading={isLoadingCapsuleSize}
        onScrollEnd={fetchNewPageCapsuleSize}
        disabled={disabledForm}
        items={items}
        onChange={onChange}
      />
    </Col>
  );
};