import { FC } from 'react';
import { Text } from '@/Components';
import { Divflex } from '@/Layouts/DivWhithFlex';
import { FooterThisActionIsPermanent } from '@/Components/Table/components/Modals/DeleteModal';
import ReactMarkdown from 'react-markdown';
import { IBodyModal } from './interfaces';
import { useTranslation } from 'react-i18next';

export const BodyModal: FC<IBodyModal> = ({ productSubGroup }) => {
  const { t } = useTranslation();

  return (
    <div style={{ padding: '20px' }}>
      <Divflex style={{ flexDirection: 'column', width: '400px' }}>
        <Text color="text-50" type="ui-tiny-content">
          <ReactMarkdown>
            {t('modal.deleteModal.single.subGroupQuestion', {
              subgrupo: `**${productSubGroup?.subGrupoDescricao}**`,
            })}
          </ReactMarkdown>
        </Text>
      </Divflex>
      <FooterThisActionIsPermanent />
    </div>
  );
};
