import { i18n } from '../../../../../../../Components';

export const useTypes = [
  {
    id: 1,
    name: i18n.t('production.pharmaceuticalForm.create.useType.internalType'),
  },
  {
    id: 2,
    name: i18n.t('production.pharmaceuticalForm.create.useType.externalType'),
  },
  {
    id: 3,
    name: i18n.t('production.pharmaceuticalForm.create.useType.topicalType'),
  },
];

export const calculationTypeDropdown = [
  {
    id: 1,
    name: i18n.t(
      'production.pharmaceuticalForm.create.calculationTypeDropdown.baseRecipe'
    ),
  },
  {
    id: 2,
    name: i18n.t(
      'production.pharmaceuticalForm.create.calculationTypeDropdown.baseQSP'
    ),
  },
];
