import { useControllerQueryApiHook } from '@/ControllerApiHook/Controller';
import { FETCH_GET_BALANCE_ADJUSTMENTS } from '@/ControllerApiHook/UniqIds/Supply/BalanceAdjustmentsKeys';
import { BalanceAdjustmentsAPI } from '@/Data/API/Supply/BalanceAdjustmentsApi';
import { IBalanceAdjustmentsResponse } from '@/Data/Interfaces/response/BalanceAdjustments/IBalanceAdjustmentsResponse';
import StorageUtils from '@/Utils/StorageUtils';
import { IUseCallAPI } from './interface';

export const useEditCallAPI = ({ externalId }: IUseCallAPI) => {
  const preferences = StorageUtils.getPreference();
  const { data: balanceAdjustment, isLoading: loadingBalance } =
    useControllerQueryApiHook<IBalanceAdjustmentsResponse>({
      uniqId: FETCH_GET_BALANCE_ADJUSTMENTS,
      entityApi: BalanceAdjustmentsAPI.getBalanceAdjustment,
      externalIds: [externalId],
    });

  return { preferences, balanceAdjustment, loadingBalance };
};
