import { useTranslation } from 'react-i18next';
import { IUseMemorizations, IWithTooltip } from './interfaces';

export const useInfoCardCallMemorizations = ({
  documentProvider
}: IUseMemorizations) => {
  const { t } = useTranslation();

  const withTooltipMainContact: IWithTooltip | undefined = {
    icon: 'exclamation-upside-down',
    title: documentProvider?.observacaoContatoFavorito
      ? `${t('common.observation')} ${
          documentProvider.observacaoContatoFavorito
        }`
      : '',
  };

  return {
    withTooltipMainContact
  };
};