import { 
  useControllerMutateApiHook, 
  useControllerQueryApiHook 
} from "@/ControllerApiHook/Controller";
import { ProductAPI } from "@/Data/API/Supply/Product";
import { 
  CREATE_ASSOCIATED_PRODUCT,
  FETCH_GET_PRODUCT
} from "@/ControllerApiHook/UniqIds/Supply/ProductKeys";
import { IUseCallAPI } from "./interfaces";
import { IProductResponse } from "@/Data/Interfaces/response/Product/IProductRespose";

export const useCreateAssociatedProductCallAPI = ({
  productExternalId
}: IUseCallAPI) => {
  const { 
    data: currentProduct 
  } = useControllerQueryApiHook<IProductResponse>({
    uniqId: FETCH_GET_PRODUCT,
    entityApi: ProductAPI.getProduct,
    externalIds: [productExternalId]
  });

  const createAssociatedProduct: any = useControllerMutateApiHook({
    uniqId: CREATE_ASSOCIATED_PRODUCT,
    entityApi: ProductAPI.createAssociatedProduct
  });

  return {
    currentProduct,
    createAssociatedProduct
  };
};