import { IUseFunctions } from "./interfaces";

export const useAmountFunctions = ({
  setTotal,
  getTotalValue
}: IUseFunctions) => {
  const onChange = (x: any) =>
    setTotal(getTotalValue({ newAmountValue: parseFloat(x) }));

  return {
    onChange
  };
};