import { IUseFunctions } from './interfaces';
import { useMemo } from 'react';
import _ from 'lodash';

export const useEditFunctions = ({
  messageProductInitialValue,
  messageProductAlreadyRegistred,
}: IUseFunctions) => {
  const fetchProductMessageName = useMemo(
    () =>
      _.debounce(async (value: string) => {
        if (messageProductInitialValue === value) {
          return;
        }
        try {
          return await messageProductAlreadyRegistred(value);
        } catch {
          return undefined;
        }
      }, 500),
    [messageProductAlreadyRegistred, messageProductInitialValue]
  );

  const onChange = (text: string) => {
    fetchProductMessageName(text);
  };

  return {
    onChange,
  };
};
