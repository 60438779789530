import { IProductGroupListData } from "@/Data/Interfaces/response/Product/IProductRespose";
import { useState } from "react";

export const useProductLineStates = () => {
  const [
    currentProduct, 
    setCurrentProduct
  ] = useState<IProductGroupListData>();

  const [
    currentProductExternalId, 
    setCurrentProductExternalId
  ] = useState<string>('');

  return {
    currentProduct,
    setCurrentProduct,
    currentProductExternalId,
    setCurrentProductExternalId
  };
};