import { Icon, Tooltip } from '@/Components';
import { InputLabel } from '@/Components/Inputs/Label';
import { Divflex } from '@/Layouts/DivWhithFlex';
import { FC } from 'react';
import { IInputWithSearchLabel } from '../../Interface';

import styles from './Label.module.scss';

export const InputWithSearchLabel: FC<IInputWithSearchLabel> = ({
  label,
  required,
  withStatusIcon,
  withTooltip,
  props,
}) => {
  return (
    <Divflex>
      <InputLabel
        label={label}
        required={required}
        withStatusIcon={withStatusIcon}
        props={props}
      />
      {withTooltip && (
        <Tooltip
          title={withTooltip.title}
          showMe
          children={
            <Icon
              className={styles['icon-with-tooltip']}
              icon={withTooltip.icon}
              color="text-300"
              size="S"
            />
          }
        />
      )}
    </Divflex>
  );
};
