import { FC } from "react";
import { Col } from 'antd';
import { Tooltip } from '@/Components';
import { useTranslation } from "react-i18next";
import { useFormikContext } from "formik";
import { setInitialValuesFormProviderDiluitionFactor } from '../../diluitionsHelpers';
import { PercentageInput } from '@/Components/Inputs/PercentageInput';
import { ISupplierDilution } from "./interfaces";

export const SupplierDilution: FC<ISupplierDilution> = ({
  lots,
  disabled
}) => {
  const { t } = useTranslation();
  const form: any = useFormikContext();
  const technicalInfos = lots?.loteInformacaoTecnica;

  return (
    <Col span={5}>
      <Tooltip
        title={`${t('supply.lot.details.toolTips.originalDilution')} ${
          !!technicalInfos
            ? lots?.loteInformacaoTecnica?.diluicaoFornecedor
            : 0
        }%`}
        showMe={
          lots?.loteInformacaoTecnica?.diluicaoFornecedor !==
            form?.values?.loteInformacaoTecnica?.diluicaoFornecedor &&
          !disabled
        }
      >
        <PercentageInput
          name="loteInformacaoTecnica.diluicaoFornecedor"
          label={t('supply.lot.details.details.supplierDilution')}
          placeHolder={t('supply.lot.details.details.supplierDilution')}
          disabled={disabled}
          withTooltip={{
            icon: 'exclamation-upside-down',
            title: t('supply.lot.create.toolTips.supplierDilution'),
          }}
          withStatusIcon
          required={!disabled}
          onChange={() => setInitialValuesFormProviderDiluitionFactor(form)}
        />
      </Tooltip>
    </Col>
  );
};