import { FC } from "react";
import { Col } from 'antd';
import { Label, Text } from '@/Components';
import { IRegister } from "./interfaces";
import { useTranslation } from "react-i18next";

export const Register: FC<IRegister> = ({ prescriber }) => {
  const { t } = useTranslation();

  return (
    <Col span={24}>
      <Label
        type="ui-tiny-content"
        color="text-200"
        children={t('prescribers.editPage.record')}
        withBottomMargin
      />
      <Text
        type="ui-tiny-content"
        color="text-50"
        children={`${prescriber?.siglaRegistro}/${
          prescriber?.abreviacaoUfRegistro
        } ${prescriber?.codigoRegistro?.toLocaleString('pt-BR')}`}
      />
    </Col>
  );
};