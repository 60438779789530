import { useControllerMutateApiHook } from '@/ControllerApiHook/Controller';
import { ProductAPI } from '@/Data/API/Supply/Product';
import {
  CREATE_PRODUCT
} from '@/ControllerApiHook/UniqIds/Supply/ProductKeys';

export const useCreateCallAPI = () => {
  const createProduct: any = useControllerMutateApiHook({
    uniqId: CREATE_PRODUCT,
    entityApi: ProductAPI.createProduct
  });

  return {
    createProduct
  };
};