import React, { FC } from 'react';
import CurrencyFormat from 'react-currency-format';
import StorageUtils from '../../Utils/StorageUtils';
import { getCurrencySymbol } from '@/Utils/Currency';

export interface ICurrencyText {
  value: number;
  decimalScale?: number;
  suffix?: string;
  currencyPrefix?: boolean;
  customPrefix?: string;
}

export const CurrencyText: FC<ICurrencyText> = ({
  value,
  suffix,
  currencyPrefix,
  decimalScale,
  customPrefix,
}) => {
  const preference = StorageUtils.getPreference();

  const currencySymbol = getCurrencySymbol();
  const thousandSeparator = preference?.idioma === 'pt-BR' ? '.' : ',';
  const decimalSeparator = preference?.idioma === 'pt-BR' ? ',' : '.';

  return (
    <CurrencyFormat
      value={value}
      displayType="text"
      decimalScale={decimalScale}
      fixedDecimalScale
      thousandSeparator={thousandSeparator}
      decimalSeparator={decimalSeparator}
      prefix={currencyPrefix ? `${currencySymbol} ` : customPrefix}
      suffix={suffix}
    />
  );
};
