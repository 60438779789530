import { Button, Icon, InputWithSearch, TextInput } from '@/Components';
import { CurrencyInput } from '@/Components/Inputs/CurrencyInput';
import { WeightInput } from '@/Components/Inputs/WeightInput';
import { Col, Row } from 'antd';
import { useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';
import { useListProducts } from '@/Hooks/HooksServices/Supply/Products/useListProducts';
import { useListNcm } from '@/Hooks/HooksServices/Purchase/Purchase/useListNcm';
import { useListCst } from '@/Hooks/HooksServices/Purchase/Purchase/useListCst';
import { useListCfop } from '@/Hooks/HooksServices/Purchase/Purchase/useListCfop';
import { useEffect, useState } from 'react';
import DeleteProductModal from './modal/DeleteProductModal';
import { useGetTotalValue } from './hooks/useGetTotalValue';
import { useSetProductData } from './hooks/useSetProductData';
import { useListUnitByClass } from '@/Hooks/HooksServices/Supply/UnitMeasurement/useListUnitByClass';

interface IProductRow {
  index: number;
  scrollContainerId: string;
  cardId: string;
  addRow: (index: number) => void;
  removeRow: (index: number) => void;
}

export const ProductRow: React.FC<IProductRow> = ({
  index,
  scrollContainerId,
  cardId,
  addRow,
  removeRow,
}) => {
  const { t } = useTranslation();
  const form: any = useFormikContext();
  const { getTotalProduct } = useGetTotalValue(index);
  const [productDescription, setProductDescription] = useState('');
  const [modalVisible, setModalVisible] = useState(false);
  const [extraProductOptions, setExtraProductOptions] = useState<any>();
  const { setProductData, classId, setClassId } = useSetProductData();
  const nfItemId = form.values.itens[index]
    ? form.values.itens[index].notaFiscalEntradaItemId
    : undefined;

  const selectedItemFromForm = nfItemId ? `item-${nfItemId}` : undefined;

  const {
    fullData,
    items: itemsProduct,
    ...products
  } = useListProducts({
    fieldName: `itens.${index}.produtoExternalId`,
    enabled: true,
    returnFullData: true,
  });

  const ncm = useListNcm({
    fieldName: `itens.${index}.ncmId`,
  });

  const cst = useListCst({
    fieldName: `itens.${index}.cstCsosnId`,
  });

  const cfop = useListCfop({
    fieldName: `itens.${index}.cfopId`,
  });

  const unitMeasurement = useListUnitByClass({
    classIds: classId,
    fieldName: `itens.${index}.unidadeMedidaId`,
    isDropdownRight: true,
  });

  useEffect(() => {
    if (form.values.itens[index] && form.values.itens[index].classeProdutoId) {
      setClassId([form.values.itens[index].classeProdutoId]);
      setExtraProductOptions(form.values.itens[index].productExtraOption);
    }
  }, [form.values.itens[index]]);

  return (
    <>
      <Row wrap={false} gutter={[16, 0]} style={{ position: 'relative' }}>
        <Col
          span={2}
          style={{
            position: 'sticky',
            left: 0,
            top: -10,
            zIndex: 1,
            background: 'var(--color-background-01)',
          }}
        >
          <TextInput
            label={
              index === 0 ? t('purchasing.invoice.create.productCode') : ''
            }
            disabled
            name={`itens.${index}.codProduto`}
            required
          />
        </Col>
        <Col
          span={4}
          style={{
            position: 'sticky',
            left: '125px',
            zIndex: 1,
            background: 'var(--color-background-01)',
          }}
        >
          <InputWithSearch
            {...products}
            actionOnFocus={() => {
              const element = document.getElementById(scrollContainerId)!;
              element.scroll(0, 0);
            }}
            items={
              extraProductOptions
                ? [...itemsProduct, extraProductOptions]
                : itemsProduct
            }
            getPopupContainerId={cardId}
            label={index === 0 ? t('purchasing.invoice.create.product') : ''}
            required
            placeHolder={t(
              'purchasing.invoice.create.placeHolder.searchForProduct'
            )}
            dropdownMinWidth={300}
            onChange={(value) => {
              const product = fullData.filter((e: any) => {
                return e.externalId === value;
              })[0];
              setProductData(product, index);
              setProductDescription(product.descricao);

              addRow(index);
            }}
            selectedItemFromForm={selectedItemFromForm}
          />
        </Col>
        <Col span={2}>
          <InputWithSearch
            {...ncm}
            label={index === 0 ? t('purchasing.invoice.create.NCM') : ''}
            getPopupContainerId={cardId}
            required
            placeHolder={t(
              'purchasing.invoice.create.placeHolder.NCMPlaceHolder'
            )}
            onChange={() => addRow(index)}
            dropdownMinWidth={300}
          />
        </Col>
        <Col span={2}>
          <InputWithSearch
            {...cst}
            label={index === 0 ? t('purchasing.invoice.create.CST') : ''}
            getPopupContainerId={cardId}
            required
            placeHolder={t(
              'purchasing.invoice.create.placeHolder.CSTPlaceHolder'
            )}
            onChange={() => addRow(index)}
            dropdownMinWidth={300}
          />
        </Col>
        <Col span={2}>
          <InputWithSearch
            {...cfop}
            label={index === 0 ? t('purchasing.invoice.create.CFOP') : ''}
            getPopupContainerId={cardId}
            required
            placeHolder={t(
              'purchasing.invoice.create.placeHolder.CFOPPlaceHolder'
            )}
            onChange={() => addRow(index)}
            dropdownMinWidth={300}
          />
        </Col>
        <Col span={2}>
          <CurrencyInput
            label={index === 0 ? t('purchasing.invoice.create.unitValue') : ''}
            name={`itens.${index}.valorUnitario`}
            required
            placeHolder={t(
              'purchasing.invoice.create.placeHolder.CurrencyPlaceHolder'
            )}
            onChange={() => addRow(index)}
          />
        </Col>
        <Col span={3}>
          <WeightInput
            name={`itens.${index}.quantidadeComprada`}
            label={index === 0 ? t('purchasing.invoice.create.quantity') : ''}
            dropdownRight={unitMeasurement}
            required={true}
            placeHolder="0,0000"
            onChange={() => addRow(index)}
          />
        </Col>
        <Col span={2}>
          <CurrencyInput
            label={index === 0 ? t('purchasing.invoice.create.total') : ''}
            name={`itens.${index}.total`}
            required
            disabled
            placeHolder={t(
              'purchasing.invoice.create.placeHolder.CurrencyPlaceHolder'
            )}
            onChange={() => addRow(index)}
            value={getTotalProduct()}
          />
        </Col>
        <Col span={2}>
          <CurrencyInput
            label={index === 0 ? t('purchasing.invoice.create.calcICMS') : ''}
            name={`itens.${index}.baseCalculoIcms`}
            placeHolder={t(
              'purchasing.invoice.create.placeHolder.CurrencyPlaceHolder'
            )}
            onChange={() => addRow(index)}
          />
        </Col>
        <Col span={2}>
          <CurrencyInput
            label={index === 0 ? t('purchasing.invoice.create.ICMSVal') : ''}
            name={`itens.${index}.valorIcms`}
            placeHolder={t(
              'purchasing.invoice.create.placeHolder.CurrencyPlaceHolder'
            )}
            onChange={() => addRow(index)}
          />
        </Col>
        <Col span={2}>
          <CurrencyInput
            label={index === 0 ? t('purchasing.invoice.create.IPIVal') : ''}
            name={`itens.${index}.valorIpi`}
            placeHolder={t(
              'purchasing.invoice.create.placeHolder.CurrencyPlaceHolder'
            )}
            onChange={() => addRow(index)}
          />
        </Col>
        <Col span={2}>
          <CurrencyInput
            label={index === 0 ? t('purchasing.invoice.create.aliquota') : ''}
            name={`itens.${index}.aliquotaIpi`}
            placeHolder={t(
              'purchasing.invoice.create.placeHolder.CurrencyPlaceHolder'
            )}
            onChange={() => addRow(index)}
          />
        </Col>
        <Col>
          <Button
            disabled={index === 0 && form.values?.itens?.length === 1}
            style={{ marginTop: `${index === 0 ? '20px' : 0}` }}
            type="secondary"
            onClick={() => setModalVisible(true)}
          >
            <Icon
              style={{
                cursor:
                  index === 0 && !form.values?.itens?.length
                    ? 'not-allowed'
                    : 'default',
              }}
              icon="trash"
              size="M"
            />
          </Button>
        </Col>
      </Row>
      <DeleteProductModal
        visible={modalVisible}
        deleteRow={() => removeRow(index)}
        changeVisibleModal={(value) => setModalVisible(value)}
        product={productDescription}
      />
    </>
  );
};
