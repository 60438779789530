import { useMemo } from 'react';
import { IUseMemorizations } from './interfaces';
import { DetailsTab } from '../Tabs/Details';
import { useTranslation } from 'react-i18next';
import { history } from '@/Store';
import { IPermissionEditButon } from '@/Components/HorizontalTabs';
import { PermissionEnum } from '@/Data/Interfaces/model/permissionEnum';
import { IButtonDropDownItems } from '@/Components/Button/ButtonDropdown';

export const useEditMemorizations = ({
  prescriber,
  setVisibleDeleteModal,
}: IUseMemorizations) => {
  const { t } = useTranslation();

  const items = useMemo(
    () => [
      {
        label: t('common.details'),
        key: 'details',
        children: prescriber && <DetailsTab prescriber={prescriber} />,
      },
    ],
    [t, prescriber]
  );

  const leftButton = useMemo(
    (): IPermissionEditButon | undefined => ({
      onClick: () => history.push(`/inventory/products//details`),
      leftIcon: 'Box',
      children: t('supply.lot.details.goToProducts'),
    }),
    [t]
  );

  const dropdownItems = useMemo(
    (): IButtonDropDownItems | undefined => [
      {
        label: t('common.delete'),
        icon: 'trash',
        onClick: () => setVisibleDeleteModal(true),
        permission: {
          permission: PermissionEnum.Vendas_Prescritores_Excluir,
          tooltip: 'noPermission.sale.prescriber.delete',
        },
      },
    ],
    [t, setVisibleDeleteModal]
  );

  return {
    items,
    leftButton,
    dropdownItems,
  };
};
