import { 
  IListUnitMeasurementByClassData 
} from "@/Data/Interfaces/response/UnitMeasurement/IUnitMeasurementResponse";
import { IUseMemorizations } from "./interfaces";

export const useAmountDefaultMemorizations = ({
  unidadeMedidaId,
  listunitsMeasurements
}: IUseMemorizations) => {
  const dropdownRight = {
    name: 'unidadeMedidaId',
    defaultValue: unidadeMedidaId,
    options:
      listunitsMeasurements?.data?.map((x: IListUnitMeasurementByClassData) => ({
        id: x.id,
        content: x.unidadeAbreviacao,
      })) || [],
  };

  return {
    dropdownRight
  };
};