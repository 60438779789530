import { IListUserGroupData } from "@/Data/Interfaces/response/User/IGetUserReponse";
import { IUseMemorizations } from "./interfaces";
import { useMemo } from "react";

export const usePersonalDataCardMemorizations = ({
  group,
  user
}: IUseMemorizations) => {
  const groupsItems = useMemo(() => {
    return group?.data?.map((x: IListUserGroupData) => ({
      id: x.externalId,
      label: x.nome,
      disabledItem: user?.grupos.some(
        (group) => group.grupoExternalId === x.externalId
      ),
    })) || [];
  }, [group, user]);

  return {
    groupsItems
  };
};