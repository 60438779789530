import { useState } from "react";

export const useLinkedProductMessageModalStates = () => {
  const [
    visibleLinkedProductMessageModalSearch,
    setVisibleLinkedProductMessageModalSearch,
  ] = useState<boolean>(true);

  const [typeMessageSelected, setTypeMessageSelected] = useState<number>(1);

  return {
    visibleLinkedProductMessageModalSearch,
    setVisibleLinkedProductMessageModalSearch,
    typeMessageSelected,
    setTypeMessageSelected
  };
};