import CreateLot from '@/Pages/Sectors/Inventory/Lot/CreateForm';
import { EditLot } from '@/Pages/Sectors/Inventory/Lot/Edit';
import LotList from '@/Pages/Sectors/Inventory/Lot/List';
import { Route, Switch } from 'react-router-dom';

export const LotRoutes = () => (
  <Switch>
    <Route path="/inventory/lot/create" component={CreateLot} />
    <Route path="/inventory/lot/:externalId/:tab" component={EditLot} />
    <Route path="/inventory/lot" component={LotList} />
  </Switch>
);
