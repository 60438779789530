import { FC } from 'react';
import { Col, Row } from 'antd';
import { TextArea } from '@/Components/Inputs/TextArea';
import { useTranslation } from 'react-i18next';

export const StandardFormulaSpecifications: FC = () => {
  const { t } = useTranslation();
  
  return (
    <>
      <Row gutter={[16, 0]} justify="start">
        <Col span={24}>
          <TextArea
            name="procedimento"
            label={t('production.standardFormula.create.procedures')}
            placeholder={t(
              'production.standardFormula.create.proceduresPlaceholder'
            )}
            maxLength={1000}
            rows={4}
          />
        </Col>
      </Row>
      <Row gutter={[16, 0]} justify="start">
        <Col span={24} style={{ marginTop: '24px' }}>
          <TextArea
            name="rodape"
            label={t('production.standardFormula.create.baseboard')}
            placeholder={t(
              'production.standardFormula.create.baseboardPlaceholder'
            )}
            maxLength={1000}
            rows={3}
          />
        </Col>
      </Row>
    </>
  );
};
