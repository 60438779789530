import { CurrencyInput } from '@/Components/Inputs/CurrencyInput';
import { Col, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import { useGetTotalInvoice } from './hooks/useGetTotalInvoice';
import { useFormikContext } from 'formik';
import { currencyFormater } from '@/Utils/Currency';
import { Tooltip } from '@/Components';

export const RowThree: React.FC = () => {
  const { t } = useTranslation();
  const { getTotalInvoice } = useGetTotalInvoice();
  const form: any = useFormikContext();

  return (
    <Row gutter={[16, 0]}>
      <Col span={4}>
        <Tooltip
          showMe={
            +getTotalInvoice() !== form.initialValues.valorTotalNotaFiscal
          }
          title={`${t(
            'purchasing.invoice.edit.originalTotalInvoice'
          )}${currencyFormater(form.initialValues.valorTotalNotaFiscal)}`}
        >
          <CurrencyInput
            label={t('purchasing.invoice.create.totalInvoice')}
            name="valorTotalNotaFiscal"
            placeHolder={t(
              'purchasing.invoice.create.placeHolder.CurrencyPlaceHolder'
            )}
            disabled
            withTooltip={{
              icon: 'exclamation-upside-down',
              title: t('purchasing.invoice.create.totalInvoiceTooltip'),
            }}
            value={getTotalInvoice()}
            withStatusIcon
          />
        </Tooltip>
      </Col>
    </Row>
  );
};
