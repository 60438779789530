import { useFormikContext } from 'formik';

export const useGetTotalValue = (index: number) => {
  const form: any = useFormikContext();

  const getTotalProduct = () => {
    if (!form.values.itens) return '';
    const unitValue = form.values.itens[index]?.valorUnitario;
    const quantity = form.values.itens[index]?.quantidadeComprada;
    if (unitValue && quantity) {
      const result = unitValue * quantity;
      return result.toString().replace('.', ',');
    } else {
      return '';
    }
  };

  return { getTotalProduct };
};
