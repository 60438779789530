import CreateLaboratory from '@/Pages/Sectors/Production/Laboratory/Create';
import FormLaboratory from '@/Pages/Sectors/Production/Laboratory/Form';
import LaboratoryList from '@/Pages/Sectors/Production/Laboratory/List';
import { Route, Switch } from 'react-router-dom';

export const LabsRoutes = () => (
  <Switch>
    <Route path="/production/labs/create" component={CreateLaboratory} />
    <Route
      path="/production/labs/:externalId/:tab"
      component={FormLaboratory}
    />
    <Route path="/production/labs" component={LaboratoryList} />
  </Switch>
);
