import { Col } from "antd";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { IProvider } from "./interfaces";
import { InputWithSearch, Tooltip } from "@/Components";
import { useFormikContext } from "formik";
import { useProviderCallAPI } from "./Hooks/useProviderCallAPI";
import { useProviderMemorizations } from "./Hooks/useProviderMemorizations";
import { useProviderFunctions } from "./Hooks/useProviderFunctions";

export const Provider: FC<IProvider> = ({
  product,
  disabledForm
}) => {
  const { t } = useTranslation();
  const form: any = useFormikContext();

  const {
    listProviders,
    fetchNewProviderPage,
    refetchSearchProviderPage
  } = useProviderCallAPI();

  const { items } = useProviderMemorizations({ listProviders });

  const { onSearch } = useProviderFunctions({
    refetchSearchProviderPage
  });

  return (
    <Col span={8}>
      <Tooltip
        title={`${t('products.toolTips.products.provider')} ${
          product?.fornecedor
        }`}
        showMe={
          product &&
          !disabledForm &&
          product.fornecedorExternalId !==
            form?.values?.fornecedorExternalId
        }
      >
        <InputWithSearch
          items={items}
          name="fornecedorExternalId"
          label={t('products.create.provider')}
          placeHolder={t('products.create.providerPlaceholder')}
          disabled={disabledForm}
          withTooltip={{
            icon: 'question-mark',
            title: t('products.create.providerTooltip'),
          }}
          withStatusIcon={!!product}
          onScrollEnd={fetchNewProviderPage}
          onSearch={onSearch}
        />
      </Tooltip>
    </Col>
  );
};