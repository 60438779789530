import { IUseFunctions } from './interfaces';
import { useCallback } from 'react';
import { IListPrescribersData } from '@/Data/Interfaces/response/Prescribers/IPrescribersResponse';
import { IPagination } from '@/Components/Table';
import { IQueryListParams } from '@/Utils/Http';

export const useListFunctions = ({
  deletePrescribers,
  changePrescriberStatus,
}: IUseFunctions) => {
  const onDelete = useCallback(
    (prescribers: IListPrescribersData[]) =>
      deletePrescribers(prescribers.map((prescriber) => prescriber.externalId)),
    [deletePrescribers]
  );

  const onEdit = useCallback(
    (prescriber: any[], values: any) =>
      changePrescriberStatus({
        prescritoresExternalId: prescriber.map((x) => x.externalId),
        ativo: values.status === 'success',
      }),
    [changePrescriberStatus]
  );

  return {
    onDelete,
    onEdit,
  };
};
