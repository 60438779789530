import { CreateStandardFormula } from '@/Pages/Sectors/Production/StandardFormula/Create';
import FormStandardFormula from '@/Pages/Sectors/Production/StandardFormula/Edit';
import StandardFormulaList from '@/Pages/Sectors/Production/StandardFormula/List';
import { Route, Switch } from 'react-router-dom';

export const StandardFormulaRoutes = () => (
  <Switch>
    <Route
      path="/production/standard-formula/create"
      component={CreateStandardFormula}
    />
    <Route
      path="/production/standard-formula/:externalId/:tab"
      component={FormStandardFormula}
    />
    <Route
      path="/production/standard-formula"
      component={StandardFormulaList}
    />
  </Switch>
);
