import { IProductGroupListData } from '@/Data/Interfaces/response/Product/IProductRespose';
import { useFormikContext } from 'formik';
import { IUseFunctions } from './interfaces';

export const useProductCallFunctions = ({
  index,
  totalRows,
  setProductRowAmount,
  uuidV4,
  refetchSearchProductPage,
  refetchUnitsMesurements,
  setClasseProdutoId,
}: IUseFunctions) => {
  const form = useFormikContext<any>();

  const addRow = () => {
    if (index === totalRows - 1) {
      setProductRowAmount((oldState) => [...oldState, uuidV4()]);
    }
  };

  const onChange = (item: IProductGroupListData) => {
    form.setFieldValue(
      `pedidoCompraItens.${index}.unidadeMedidaId`,
      item?.unidadeMedidaId
    );
    addRow();
    refetchUnitsMesurements({
      filter: [
        {
          filterName: 'tipoClasseProduto',
          value: item?.classeProdutoId,
        },
      ],
    });
    setClasseProdutoId(item?.classeProdutoId);
  };

  const onSearch = (search: string | undefined) => {
    refetchSearchProductPage({
      search: search,
      sorter: { column: 'descricao', direction: 'DESC' },
    });
  };

  return {
    onChange,
    onSearch,
  };
};
