import { CreatePharmaceuticalForm } from '@/Pages/Sectors/Production/PharmaceuticalForm/Create';
import FormPharmaceuticalForm from '@/Pages/Sectors/Production/PharmaceuticalForm/Form';
import PharmaceuticalFormList from '@/Pages/Sectors/Production/PharmaceuticalForm/List';
import { Route, Switch } from 'react-router-dom';

export const DosageFormRoutes = () => (
  <Switch>
    <Route
      path="/production/dosage-form/create"
      component={CreatePharmaceuticalForm}
    />
    <Route
      path="/production/dosage-form/:externalId/:tab"
      component={FormPharmaceuticalForm}
    />
    <Route path="/production/dosage-form" component={PharmaceuticalFormList} />
  </Switch>
);
