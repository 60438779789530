import { Checkbox, TextInput } from '@/Components';
import _ from 'lodash';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Row } from 'antd';
import styles from '../../../CapsuleColors.module.scss';
import { FormikContextType, useFormikContext } from 'formik';
import { useCreateCallAPI } from './Hooks/useCreateCallAPI';
import { useCreateFunctions } from './Hooks/useCreateFunctions';

export const DescriptionRow: FC = () => {
  const form: FormikContextType<unknown> = useFormikContext();

  const { t } = useTranslation();

  const { mutateAsync } = useCreateCallAPI({ form, t });

  const { fetchCapsuleColorName } = useCreateFunctions({
    mutateAsync,
  });

  return (
    <Row gutter={[16, 0]} justify="start">
      <Col span={8}>
        <TextInput
          name="corCapsula"
          label={t('production.capsuleColors.create.capsuleColor')}
          placeHolder={t('production.capsuleColors.create.namePlaceholder')}
          required
          dataTestId="capsule-color-description"
          onChange={(value) => fetchCapsuleColorName(value)}
        />
      </Col>

      <Col span={6} className={styles['checkbox']}>
        <Checkbox
          name="transparente"
          label={t('production.capsuleColors.create.transparentCapsule')}
          dataTestId="capsule-color-checkbox"
        />
      </Col>
    </Row>
  );
};
