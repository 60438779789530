import { useCallback } from 'react';
import { IUseFunctions } from './interfaces';
import { useFormikContext } from 'formik';

export const useSaltMolecularWeightFunctions = ({
  setEquivalenceFactor,
}: IUseFunctions) => {
  const form: any = useFormikContext();

  const onChange = useCallback(
    (value: any) => {
      const useDot = !value.includes(',');
      if (!value) {
        return setEquivalenceFactor('');
      }
      const formattedValue = +value.replace(',', '.');
      const baseMolecularWeight = form.values.pesoMolecularBase;
      if (!baseMolecularWeight) return;
      const equivalenceFactor = formattedValue / baseMolecularWeight;
      if (!useDot) {
        return setEquivalenceFactor(
          equivalenceFactor.toFixed(3).replace('.', ',')
        );
      }
      setEquivalenceFactor(equivalenceFactor.toFixed(3));
    },
    [setEquivalenceFactor, form.values.pesoMolecularBase]
  );

  return { onChange };
};
