import { useControllerQueryListApiHook } from '@/ControllerApiHook/Controller';
import { FETCH_PROVIDER_TYPE } from '@/ControllerApiHook/UniqIds/People/PersonKeys';
import { PersonAPI } from '@/Data/API/People/PersonApi';

export const useInfoSectionCallAPI = () => {
  const { data: providerTypes } = useControllerQueryListApiHook({
    uniqId: FETCH_PROVIDER_TYPE,
    entityApi: PersonAPI.listProviderTypes,
  });
  return {
    providerTypes,
  };
};
