import * as yup from 'yup';
import i18n from '../../../../../Components/i18n';
export const CreateSchema = (personType: number) =>
  yup.object().shape({
    nome: yup
      .string()
      .nullable()
      .required(
        i18n.t(
          `client.error.${
            personType === 0 ? 'requiredName' : 'requiredFantasyName'
          }`
        )
      ),
  });
