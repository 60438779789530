import React from 'react';
import { PageLoading } from '../../../../../Layouts/PageLoading';
import { useTranslation } from 'react-i18next';
import { Table } from '../../../../../Components';
import { invoiceColumn } from './invoice.columns';
import { App } from 'antd';
import { useInvoiceListStates } from './Hooks/useInvoiceListStates';
import { useInvoiceListCallAPI } from './Hooks/useInvoiceListCallAPI';
import { useInvoiceListFunctions } from './Hooks/useInvoiceListFunctions';
import { useInvoiceListMemorizations } from './Hooks/useInvoiceListMemorizations';
import StorageUtils from '@/Utils/StorageUtils';

const InvoiceList: React.FC = () => {
  const { t } = useTranslation();
  const { selectedRowsOnTable, setSelectedRowsOnTable } =
    useInvoiceListStates();

  const { deleteInvoice, invoiceList } = useInvoiceListCallAPI();

  const { onDelete } = useInvoiceListFunctions({
    setSelectedRowsOnTable,
    deleteInvoice,
  });

  const { headerOptions, rowOptions, deleteModal } =
    useInvoiceListMemorizations({
      selectedRowsOnTable,
    });

  const preferences = StorageUtils.getPreference();

  const app = App.useApp();

  return (
    <PageLoading loading={invoiceList.isLoading}>
      <Table
        columns={invoiceColumn(t, preferences)}
        headerOptions={headerOptions}
        onRefetch={invoiceList.refetch}
        hasSelection
        pagination={invoiceList.data}
        loading={invoiceList.isLoading}
        rowsOptions={rowOptions}
        selectedRowsOnTable={(x) => setSelectedRowsOnTable(x ?? [])}
        deleteModal={deleteModal}
        onDelete={async (invoices) => await onDelete(invoices, app)}
      />
    </PageLoading>
  );
};

export default InvoiceList;
