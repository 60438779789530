import { FC } from "react";
import {
  Tooltip
} from '@/Components';
import { Col } from 'antd';
import { useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';
import { NumberInput } from '@/Components/Inputs/NumberInput';
import { IVolume } from "./interfaces";

export const Volume: FC<IVolume> = ({
  product,
  disabledForm
}) => {
  const { t } = useTranslation();
  const form: any = useFormikContext();

  return(
    <Col span={3}>
      <Tooltip
        title={`${t('products.toolTips.products.originalVolume')} ${
          product && product.embalagem
            ? product?.embalagem?.volume + ' ml'
            : ''
        }`}
        showMe={
          product &&
          !disabledForm &&
          product?.embalagem?.volume !== form?.values?.volume
        }
      >
        <NumberInput
          name="volume"
          label={t('products.create.volume')}
          placeHolder="0"
          supportText="mL"
          disabled={disabledForm}
          withStatusIcon={!!product}
        />
      </Tooltip>
    </Col>
  );
};