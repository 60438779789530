import { useCallback } from 'react';
import { handleModalError } from '@/Utils/ErrorCodeUtils/ErrorHandlingUtils';
import { App } from 'antd';
import { useTranslation } from 'react-i18next';
import { IUseFunctions } from './interfaces';
import { ICapsulseColorsListData } from '@/Data/Interfaces/response/CapsuleColors/ICapsuleColorsResponse';
import { cannotDeleteCapsuleColorsWithMovementsInTheSystemProps } from '@/Utils/ErrorCodeUtils/ErrorModalUtils/ErrorModalCapsuleColors';

export const useListFunctions = ({
  setSelectedRowsOnTable,
  deleteCapsuleColors,
}: IUseFunctions) => {
  const { t } = useTranslation();
  const app = App.useApp();

  const onSelectedRowsOnTable = useCallback(
    (x: ICapsulseColorsListData[]) => setSelectedRowsOnTable(x),
    []
  );

  const onDelete = useCallback(
    async (capsuleColors: ICapsulseColorsListData[]) => {
      await deleteCapsuleColors.mutateAsync(
        {
          externalId: capsuleColors.map((x) => x?.externalId),
        },
        {
          onError: handleModalError(
            app,
            cannotDeleteCapsuleColorsWithMovementsInTheSystemProps(t)
          ),
        }
      );
    },
    [deleteCapsuleColors, app, t]
  );

  return {
    onSelectedRowsOnTable,
    onDelete,
  };
};
