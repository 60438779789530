import { FC } from 'react';
import { Col } from 'antd';
import { useTranslation } from 'react-i18next';
import { IPharmaceuticalForm } from './interfaces';
import { InputWithSearch, Tooltip } from '@/Components';
import { usePharmaceuticalFormCallAPI } from './Hooks/usePharmaceuticalFormCallAPI';
import { usePharmaceuticalFormMemorizations } from './Hooks/usePharmaceuticalFormMemorizations';
import { usePharmaceuticalFormFunctions } from './Hooks/usePharmaceuticalFormFunctions';

export const PharmaceuticalForm: FC<IPharmaceuticalForm> = ({
  productExternalId,
  associatedProductData,
  associatedProductExternalId,
  props,
  disabledForm,
}) => {
  const { t } = useTranslation();

  const {
    listPharmaceuticalForm,
    isPharmaceuticalFormLoading,
    fetchNewFarmaceuticalFormPage,
    refetchSearchPharmaceuticalFormPage,
  } = usePharmaceuticalFormCallAPI({
    productExternalId,
    associatedProductExternalId,
  });

  const { items } = usePharmaceuticalFormMemorizations({
    listPharmaceuticalForm,
  });

  const { onSearch } = usePharmaceuticalFormFunctions({
    refetchSearchPharmaceuticalFormPage,
  });

  return (
    <Col span={4}>
      <Tooltip
        title={`${t('products.associated.originalPharmaceuticalForm')}${
          associatedProductData?.formaFarmaceuticaDescricao
        }`}
        showMe={
          associatedProductData &&
          associatedProductData.formaFarmaceuticaExternalId !==
            props?.values?.formaFarmaceuticaExternalId
        }
        targetOffset={[80, -10]}
      >
        <InputWithSearch
          name="formaFarmaceuticaExternalId"
          required={!disabledForm}
          withStatusIcon={!disabledForm}
          label={t('products.associated.pharmaceuticalForm')}
          placeHolder={t('products.diluted.searchForPharmaceuticalForm')}
          withTooltip={{
            icon: 'exclamation-upside-down',
            title: t('products.associated.pharmaceuticalFormTooltip'),
          }}
          items={items}
          isLoading={isPharmaceuticalFormLoading}
          onScrollEnd={fetchNewFarmaceuticalFormPage}
          onSearch={onSearch}
          disabled={disabledForm}
        />
      </Tooltip>
    </Col>
  );
};
