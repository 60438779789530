import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Tooltip
} from '@/Components';
import { useFormikContext } from 'formik';
import { DensityInput } from '@/Components/Inputs/DensityInput';
import StorageUtils from '@/Utils/StorageUtils';
import { IDensity } from "./interfaces";

export const Density: FC<IDensity> = ({
  standardFormula,
  disabled
}) => {
  const { t } = useTranslation();
  const form: any = useFormikContext();
  const preferences = StorageUtils.getPreference();
  let languageUser = preferences?.idioma;
  let density: number | undefined = standardFormula?.densidade;
  let densityFormated: number | string;

  if (density && density > 0) {
    densityFormated = parseFloat(density.toFixed(3)).toLocaleString(
      languageUser,
      {
        minimumFractionDigits: 3,
      }
    );
  } else densityFormated = 0;

  return (
    <Tooltip
      title={`${t(
        'production.standardFormula.details.tooltips.previousDensity'
      )} ${densityFormated}`}
      targetOffset={[0, 0]}
      showMe={
        !disabled && standardFormula?.densidade !== form.values.densidade
      }
    >
      <DensityInput
        name="densidade"
        label={t('production.standardFormula.details.density')}
        placeHolder={t(
          'production.standardFormula.details.densityPlaceholder'
        )}
        withoutMarginBottom={true}
        disabled={disabled}
        withStatusIcon
      />
    </Tooltip>
  );
};