import { useControllerQueryApiHook } from '@/ControllerApiHook/Controller';
import { UserAPI } from '@/Data/API/Accountmanager/UserApi';
import { IUseEditUserCallAPI } from './interface';
import { IGetUserResponse } from '@/Data/Interfaces/response/User/IGetUserReponse';
import { GET_USER_TYPE } from '@/ControllerApiHook/UniqIds/Accountmanager/UserKeys';

export const useEditUserCallAPI = ({ externalId }: IUseEditUserCallAPI) => {
  const userData = useControllerQueryApiHook<IGetUserResponse>({
    uniqId: GET_USER_TYPE,
    entityApi: UserAPI.getUser,
    externalIds: [externalId],
  });

  return {
    userData,
  };
};
