import { FECTH_LIST_STOCK_LOCATION_FOR_DROPDOWN_TYPE } from '@/ControllerApiHook/UniqIds/Supply/SupplyKeys';
import { useControllerQueryListApiHook } from '@/ControllerApiHook/Controller';
import { StorageLocationApi } from '@/Data/API/Supply/StorageLocationApi';

export const useBatchLocalCallAPI = () => {
  const {
    data: storageLocationList,
    fetchNewPage,
    isLoading,
  } = useControllerQueryListApiHook({
    uniqId: FECTH_LIST_STOCK_LOCATION_FOR_DROPDOWN_TYPE,
    entityApi: StorageLocationApi.getListStorageLocationDropDown,
    autoIncrement: true,
  });

  return {
    storageLocationList,
    fetchNewPage,
    isLoading,
  };
};
