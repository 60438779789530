import DateUtils from '@/Utils/DateUtils';
import { initialValue } from '../util/constants';

export const useConfigInitialValue = () => {
  const configInvoice = (invoice: any) => {
    if (!invoice) return initialValue;

    const itemsInitial: any = {};
    invoice.itens.forEach((item: any) => {
      itemsInitial[`item-${item.notaFiscalEntradaItemId}`] = {
        id: item.produtoExternalId,
        label: item.produtoDescricao,
      };
    });

    return {
      ...invoice,
      externalId: invoice?.externalId ?? '',
      cnpj: invoice?.cnpj ?? '',
      dataEmissao: invoice?.dataEmissao
        ? DateUtils.convertDate(invoice?.dataEmissao, 'dd/MM/yyyy')
        : '',
      dataEntrega: invoice?.dataEntrega
        ? DateUtils.convertDate(invoice?.dataEntrega, 'dd/MM/yyyy')
        : '',
      dataLancamento: invoice?.dataLancamento
        ? DateUtils.convertDate(invoice?.dataLancamento, 'dd/MM/yyyy')
        : '',
      fornecedorExternalId: invoice?.fornecedorExternalId ?? '',
      informacoesComplementares: invoice?.informacoesComplementares ?? '',
      itens: invoice?.itens
        ? invoice.itens.map((e: any) => ({
            ...e,
            codProduto: e.produtoCodigo,
            unidadeId: e.unidadeMedidaId,
            ncmId: e.ncmId ? e.ncmId : undefined,
            cstCsosnId: e.cstCsosnId ? e.cstCsosnId : undefined,
            cfopId: e.cfopId ? e.cfopId : undefined,
          }))
        : [],
      naturezaOperacaoExternalId: invoice?.naturezaOperacaoExternalId ?? '',
      numero: invoice?.numero ? `${invoice.numero}` : '',
      razaoSocial: invoice?.razaoSocial ?? '',
      serie: invoice?.serie ?? '',
      tipoFreteId: invoice?.tipoFreteId ?? '',
      transportadoraExternalId: invoice?.transportadoraExternalId ?? '',
      valorBaseIcms: invoice?.valorBaseIcms ?? '',
      valorBaseIcmsSubstituicao: invoice?.valorBaseIcmsSubstituicao ?? '',
      valorDesconto: invoice?.valorDesconto ?? '',
      valorFrete: invoice?.valorFrete ?? '',
      valorIcms: invoice?.valorIcms ?? '',
      valorIcmsSubstituicao: invoice?.valorIcmsSubstituicao ?? '',
      valorIpi: invoice?.valorIpi ?? '',
      valorOutrasDespesas: invoice?.valorOutrasDespesas ?? '',
      valorSeguro: invoice?.valorSeguro ?? '',
      valorProdutos: invoice?.valorProdutos ?? '',
      naturezaOperacaoId: invoice?.naturezaOperacaoId ?? '',
      pedidosExternalId: invoice?.pedidosVinculados.map((e: any) => ({
        ...e,
        externalId: e.pedidoCompraExternalId,
        itens: e.pedidoItensVinculados,
      })),
      fornecedorInicial: {
        id: invoice?.fornecedorExternalId,
        label: invoice?.fornecedorDescricao,
      },
      ...itemsInitial,
      fornecedorExternalIdReal: invoice?.fornecedorExternalId,
      valorTotalNotaFiscal:
        invoice.valorProdutos +
        invoice.valorFrete +
        -invoice.valorDesconto +
        invoice.valorOutrasDespesas +
        invoice.valorSeguro,
    };
  };

  return { configInvoice };
};
