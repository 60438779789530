import { QueryFunctionContext } from '@tanstack/react-query';
import { Http, IQueryListParams } from '@/Utils/Http';
import { IListCapsuleSizeResponse } from '@/Data/Interfaces/response/CapsuleSize/ICapsuleSizeResponse';

export class CapsuleSizeAPI {
  public static async listDropdownCapsuleSize(
    context: QueryFunctionContext
  ): Promise<IListCapsuleSizeResponse | null> {
    if (!context.queryKey[1]) return null;

    return await Http.fetcher<IListCapsuleSizeResponse>(
      Http.AddQueryParams(
        `/pharma/producao/v1/CapsulaTamanho/Listar`,
        context.queryKey[1] as IQueryListParams
      )
    );
  }
}
