export const alternativeUnit1 = [
  {
    id: 7,
    description: '/g',
  },
  {
    id: 6,
    description: '/mg',
  },
];

export const alternativeUnit2 = [
  {
    id: 14,
    description: 'mi',
  },
  {
    id: 15,
    description: 'bi',
  },
];
