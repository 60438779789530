import { useControllerQueryListApiHook } from '@/ControllerApiHook/Controller';
import { FormatDataToDropdownUtils } from '@/Utils/FormatDataToDropdownUtils';
import { IQueryListParams } from '@/Utils/Http';
import { ListCst } from '@/Services/Purchase/Purchase/ListCst.service';

export const FETCH_LIST_DROPDOWN_CST = '[INVOICE] FETCH_LIST_DROPDOWN_CST';

interface IUseListCst {
  fieldName: string;
  enabled?: boolean;
  pagination?: IQueryListParams;
}

export const useListCst = ({ fieldName, enabled, pagination }: IUseListCst) => {
  const { data, refetch, isLoading, fetchNewPage } =
    useControllerQueryListApiHook({
      uniqId: FETCH_LIST_DROPDOWN_CST,
      entityApi: ListCst,
      autoIncrement: true,
      enabled,
      pagination,
      autoIncrementCustomId: 'id',
    });

  const items = FormatDataToDropdownUtils({
    data: data && data.data ? data.data : [],
    idKey: 'id',
    labelKey: 'codigo',
    subLabelKey: 'descricao',
  });

  const onSearch = (search?: string) => {
    refetch({
      search,
    });
  };

  return {
    name: fieldName,
    items,
    onSearch,
    isLoading,
    onScrollEnd: fetchNewPage,
  };
};
