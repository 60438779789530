import { IUseFunctions } from './interfaces';
import { FormikHelpers, FormikValues } from 'formik';
import { IEditProductMessage } from '@/Data/Interfaces/request/ProductMensage/IProductMensageRequest';

export const useEditFunctions = ({
  editProductMessage,
  productMessage,
}: IUseFunctions) => {
  const onSubmit = async (
    values: IEditProductMessage,
    helpers: FormikHelpers<FormikValues>
  ) => {
    var body: IEditProductMessage = { ...values, helpers };

    if (productMessage) {
      return await editProductMessage({
        externalId: productMessage.externalId,
        descricao: body.descricao.trim(),
        exibeVenda: body.exibeVenda ?? false,
        exibeRotulagem: body.exibeRotulagem ?? false,
        exibeFichaPesagem: body.exibeFichaPesagem ?? false,
        exibeImpressaoRotulo: body.exibeImpressaoRotulo ?? false,
        helpers,
      });
    }
  };

  return {
    onSubmit,
  };
};
