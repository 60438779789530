import { useState } from 'react';

export const useCreateStates = () => {
  const [selectedOrigin, setSelectedOrigin] = useState<string>();

  const [selectedDestiny, setSelectedDestiny] = useState<string>();

  return {
    selectedOrigin,
    setSelectedOrigin,
    selectedDestiny,
    setSelectedDestiny
  };
};
