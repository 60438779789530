export const FETCH_GET_CAPSULE_COLORS =
  '[CAPSULE_COLORS] FETCH_GET_CAPSULE_COLORS';

export const FETCH_LIST_CAPSULE_COLORS =
  '[CAPSULE_COLORS] FETCH_LIST_CAPSULE_COLORS';
export const FETCH_LIST_DROPDOWN_CAPSULE_COLOR =
  '[CAPSULE_TYPE] FETCH_LIST_DROPDOWN_CAPSULE_COLOR';

export const CREATE_CAPSULE_COLORS = '[CAPSULE_COLORS] CREATE_CAPSULE_COLORS';
export const EDIT_CAPSULE_COLORS = '[CAPSULE_COLORS] EDIT_CAPSULE_COLORS';
export const DELETE_CAPSULE_COLORS = '[CAPSULE_COLORS] DELETE_CAPSULE_COLORS';

export const THERE_IS_CAPSULE_COLORS_TYPE =
  '[CAPSULE_COLORS] THERE_IS_CAPSULE_COLORS_TYPE';

