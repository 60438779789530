import React, { FC, ReactNode } from 'react';

import styles from './Row.module.scss';

interface IRow {
  children?: ReactNode;
}

export const Row: FC<IRow> = ({ children }) => (
  <div className={styles['Row']}>{children}</div>
);
