export const FETCH_GET_ORDER_PURCHASE =
'[ORDER_PURCHASE] FETCH_GET_ORDER_PURCHASE';
export const FETCH_GET_OPEN_ORDER_PURCHASE =
  '[ORDER_PURCHASE] FETCH_GET_OPEN_ORDER_PURCHASE';

  export const FETCH_LIST_PURCHASE_ORDER =
    '[PURCHASE_ORDER] FETCH_LIST_PURCHASE_ORDER';

export const CREATE_ORDER_PURCHASE = '[PURCHASE_ORDER] CREATE_ORDER_PURCHASE';
export const EDIT_ORDER_PURCHASE = '[PURCHASE_ORDER] EDIT_ORDER_PURCHASE';
export const DELETE_ORDER_PURCHASE = '[ORDER_PURCHASE] DELETE_ORDER_PURCHASE';

export const REPROVE_ORDER_PURCHASE = '[ORDER_PURCHASE] REPROVE_ORDER_PURCHASE';
export const REVERSE_CONFIRMED_SUPPLIER_ORDER_PURCHASE =
  '[ORDER_PURCHASE] REVERSE_CONFIRMED_SUPPLIER_ORDER_PURCHASE';
export const REVERSE_RELEASED_ORDER_PURCHASE =
  '[ORDER_PURCHASE] REVERSE_RELEASED_ORDER_PURCHASE';
export const CONFIRM_WITH_SUPPLIER_ORDER_PURCHASE =
  '[ORDER_PURCHASE] CONFIRM_WITH_SUPPLIER_ORDER_PURCHASE';
export const CANCEL_ORDER_PURCHASE = '[ORDER_PURCHASE] CANCEL_ORDER_PURCHASE';
export const APPROVE_ORDER_PURCHASE = '[ORDER_PURCHASE] APPROVE_ORDER_PURCHASE';
export const SAVE_POST_DRAFT_ORDER_PURCHASE =
  '[ORDER_PURCHASE] SAVE_POST_DRAFT_ORDER_PURCHASE';
export const SAVE_PUT_DRAFT_ORDER_PURCHASE =
  '[ORDER_PURCHASE] SAVE_PUT_DRAFT_ORDER_PURCHASE';
