import { IProductGroupListData } from '@/Data/Interfaces/response/Product/IProductRespose';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { history } from '@/Store';
import { TitleHandler, BodyHandler } from '../groupListUtils';
import { IUseMemorizations } from './interfaces';
import { IDeleteModalTable } from '@/Components/Table';

export const useListMemorizations = ({
  setVisibleModal,
  selectedRowsOnTable,
  setVisibleEditModal,
}: IUseMemorizations) => {
  const abbreviatedI18n = 'products.groupList';
  const { t } = useTranslation();

  const headerOptions = useMemo(
    () => ({
      hasSearch: true,
      newButton: {
        label: t(`${abbreviatedI18n}.newButton`),
        onClick: () => setVisibleModal(true),
        permission: {
          permission: 3010,
          tooltip: 'noPermission.supply.groups.create',
        },
        dataTestId: 'inventory-group-new-button',
      },
      editButton:
        selectedRowsOnTable?.length === 1
          ? {
              editButtonWithoutDropdown: true,
              editButtonWithoutDropdownClick: () => {
                setVisibleEditModal(true);
              },
              permission: {
                permission: 3011,
                tooltip: 'noPermission.supply.groups.edit',
              },
              dataTestId: 'edit-group-button',
            }
          : undefined,
      deleteButton: {
        permission: {
          permission: 3012,
          tooltip: 'noPermission.supply.groups.delete',
        },
        dataTestId: 'delete-group-button',
      },

      nameKey: 'descricao',
      searchPlaceholder: t(`${abbreviatedI18n}.placeholder`),
    }),
    [t, selectedRowsOnTable, setVisibleModal, setVisibleEditModal]
  );

  const rowOptions = useMemo(
    () => ({
      onRowDoubleClick: (x: IProductGroupListData) =>
        history.push(`/inventory/groups/${x.externalId}/details`),
      permission: {
        permission: 3009,
        tooltip: 'noPermission.supply.groups.detail',
      },
    }),
    []
  );

  const deleteModal = useMemo(
    (): IDeleteModalTable | undefined => ({
      title: TitleHandler(selectedRowsOnTable, t),
      body: BodyHandler(selectedRowsOnTable, t),
      buttons: {
        okButtonColor: 'status-danger-base',
        okButtonDataTestId: 'ok-delete-group-modal-button',
      },
    }),
    [t, selectedRowsOnTable]
  );

  return {
    headerOptions,
    rowOptions,
    deleteModal,
  };
};
