import React from 'react';
import { Modal } from '../../../../../../../Components/Modal';
import { useTranslation } from 'react-i18next';
import { Divflex } from '../../../../../../../Layouts/DivWhithFlex';
import { Text } from '../../../../../../../Components';
import ReactMarkdown from 'react-markdown';

interface IDeleteModal {
  visible: boolean;
  onOkClick: () => void;
  onCancelClick: () => void;
}

export const ThrowLotsModal: React.FC<IDeleteModal> = ({
  onOkClick,
  onCancelClick,
  visible,
}) => {
  const { t } = useTranslation();

  return (
    <Modal
      onOkClick={onOkClick}
      visible={visible}
      title={`${t('purchasing.invoice.details.throwLotsModalTitle')}`}
      body={
        <Divflex
          style={{ flexDirection: 'column', width: '400px', padding: '20px' }}
        >
          <Text color="text-50" type="ui-tiny-content">
            <ReactMarkdown>
              {t('purchasing.invoice.details.throwLotsModalQuestion')}
            </ReactMarkdown>
          </Text>
        </Divflex>
      }
      onCancelClick={onCancelClick}
      okButtonName={t('purchasing.invoice.details.throwLotsModalOk')}
    />
  );
};
