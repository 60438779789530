import { useState } from 'react';

export const useListStates = () => {
  const [selectedRowsOnTable, setSelectedRowsOnTable] = useState<any>([]);

  return {
    selectedRowsOnTable,
    setSelectedRowsOnTable,
  };
};
