import {
  CREATE_LOT,
  FETCH_LIST_LOTS,
} from '@/ControllerApiHook/UniqIds/Supply/LotKeys';
import { useQueryClient } from '@tanstack/react-query';
import { useControllerMutateApiHook } from '@/ControllerApiHook/Controller';
import { history } from '@/Store';
import { IErrorState, IUseCallAPI } from './interfaces';
import { LotsAPI } from '@/Data/API/Supply/LotApi';

export const useLotCallAPI = ({ setErrors }: IUseCallAPI) => {
  const queryClient = useQueryClient();

  const createLot: any = useControllerMutateApiHook({
    uniqId: CREATE_LOT,
    entityApi: LotsAPI.createLot,
    options: {
      onError: () => {
        setErrors((state: IErrorState) => ({
          ...state,
          creatingLotError: true,
        }));
      },
      onSuccess: () => {
        queryClient.invalidateQueries([FETCH_LIST_LOTS]);
        history.push('/inventory/lot');
      },
    },
  });

  return {
    createLot,
  };
};
