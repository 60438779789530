import { useControllerMutateApiHook } from '@/ControllerApiHook/Controller';
import { IUseCallAPI } from './interfaces';
import { CapsulseColorsAPI } from '@/Data/API/Production/CapsuleColors';
import { THERE_IS_CAPSULE_COLORS_TYPE } from '@/ControllerApiHook/UniqIds/Production/CapsuleColorsKeys';

export const useCreateCallAPI = ({ form, t }: IUseCallAPI) => {
  const { mutateAsync }: any = useControllerMutateApiHook({
    uniqId: THERE_IS_CAPSULE_COLORS_TYPE,
    entityApi: CapsulseColorsAPI.isCapsuleColorNameAlreadyRegistred,
    options: {
      onSuccess: () => {
        form?.setErrors({
          ...form?.errors,
          corCapsula: undefined,
        });
      },
      onError: () => {
        form?.setErrors({
          ...form?.errors,
          corCapsula: t(
            'production.capsuleColors.errors.errorCapsuleColorNameExist'
          ),
        });
      },
    },
  });

  return { mutateAsync };
};
