import { useFormikContext } from 'formik';

export const useControlSupplierId = () => {
  const form: any = useFormikContext();

  const setRealSupplierId = (value: string) => {
    form.setFieldValue('fornecedorExternalIdReal', value);
  };

  return {
    setRealSupplierId,
  };
};
