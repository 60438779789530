import { FC } from "react";
import { BatchCode } from "./Components/BatchCode";
import { AvailableQuantity } from "./Components/AvailableQuantity";
import { Row } from 'antd';

export const CodeQuantityInfo: FC = () => {
  return (
    <div style={{ width: '100%' }}>
      <Row gutter={[16, 0]}>
        <BatchCode />
        <AvailableQuantity />
      </Row>
    </div>
  );
};