import React from 'react';
import { useTranslation } from 'react-i18next';
import { lossesColumns } from './losses.columns';
import { Table } from '../../../../../Components/Table';
import StorageUtils from '@/Utils/StorageUtils';
import { useListMemorizations } from './Hooks/useListMemorizations';
import { useListCallAPI } from './Hooks/useListCallAPI';

export const LossesList: React.FC = () => {
  const { t } = useTranslation();

  const { lossesList, isLoadingList, refetchLossesList } = useListCallAPI();

  const { headerOption, rowsOptions } = useListMemorizations();

  const preferences = StorageUtils.getPreference();

  return (
    <Table
      columns={lossesColumns(t, preferences)}
      headerOptions={headerOption}
      loading={isLoadingList}
      hasSelection={false}
      rowsOptions={rowsOptions}
      pagination={lossesList}
      onRefetch={refetchLossesList}
    />
  );
};

export default LossesList;
