import { useCallback } from "react";
import { history } from '@/Store';
import { IUseFunctions } from "./interfaces";

export const useFooterFunctions = ({
  params,
  instance
}: IUseFunctions) => {
  const onOkClick = useCallback(() => {
    history.push(
      `/inventory/groups/${params.gruposExternalId[0]}/products`
    );
    instance.destroy();
  }, [params]);

  return { onOkClick };
};