import { Card, TextInput } from '@/Components';
import { Col, Row } from 'antd';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

export const PersonalDataCard: FC = () => {
  const { t } = useTranslation();
  return (
    <Card title={t('admin.createUserPage.personalData')} withoutCollapse>
      <Row gutter={[16, 0]} justify="space-between">
        <Col span={12}>
          <TextInput
            name="nome"
            dataTestId='nome'
            label={t('admin.createUserPage.personalDataCard.name')}
            placeHolder={t(
              'admin.createUserPage.personalDataCard.namePlaceholder'
            )}
            required
            withoutMarginBottom
          />
        </Col>
        <Col span={12}>
          <TextInput
            name="sobrenome"
            dataTestId='sobrenome'
            label={t('admin.createUserPage.personalDataCard.lastName')}
            placeHolder={t(
              'admin.createUserPage.personalDataCard.lastNamePlaceholder'
            )}
            required
            withoutMarginBottom
          />
        </Col>
      </Row>
    </Card>
  );
};
