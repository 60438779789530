import { convertCurrencyToInteger } from '@/Utils/Currency';
import { DiscountTypes } from '@/Utils/DiscountTypeDefault';
import _ from 'lodash';

class AddProductUtils {
  static getTotalValue = (
    form: any,
    prop: {
      unitValue?: number;
      amount?: number;
      discount?: string;
    }
  ) => {
    const unitValue = prop.unitValue ?? form.values.valorUnitario ?? 0;

    const amount = prop.amount ?? form.values.quantidade ?? 1;

    const discount =
      prop.discount !== undefined
        ? convertCurrencyToInteger(prop.discount)
        : form.values.desconto ?? 0;

    const discountType = form && form.values.tipoDesconto;

    const totalWithoutDiscount = unitValue * amount;

    if (!totalWithoutDiscount) return;

    let total = 0;

    if (_.isNil(discountType)) return undefined;
    if (discountType === DiscountTypes.currency) {
      const totalDiscount = discount * amount;
      total = totalWithoutDiscount - totalDiscount;
    } else {
      const discountPercentage = discount * unitValue;
      let discountValue = 0;
      if (discountPercentage) discountValue = discountPercentage / 100;

      const totalDiscountPercetage = discountValue * amount;
      total = totalWithoutDiscount - totalDiscountPercetage;
    }

    return total;
  };

  static getDiscountValue = (form: any, discountType: number) => {
    const unitValue = form.values.valorUnitario ?? 0;

    const discount = form.values.desconto ?? 0;

    if (discount)
      if (unitValue)
        return discountType === 1
          ? (100 * discount) / unitValue
          : (unitValue * discount) / 100;
    return discount;
  };
}

export default AddProductUtils;
