import { IProductSubGroupListData } from '@/Data/Interfaces/response/Product/IProductRespose';
import { useMemo } from 'react';
import { IUseMemorizations } from './interfaces';
import { history } from '@/Store';
import { useTranslation } from 'react-i18next';
import { TitleHandler, BodyHandler } from '../subgroupListUtils';
import { IDeleteModalTable } from '@/Components/Table';

export const useListMemorizations = ({
  subGroupList,
  setVisibleModal,
  setVisibleEditModal,
  selectedRowsOnTable,
}: IUseMemorizations) => {
  const { t } = useTranslation();
  const abbreviatedI18n = 'products.subGroupList';

  const headerOptions = {
    hasSearch: true,
    newButton: {
      label: t(`${abbreviatedI18n}.newButton`),
      onClick: () => setVisibleModal(true),
      permission: {
        permission: 3015,
        tooltip: 'noPermission.supply.subGroups.create',
      },
      dataTestId: 'inventory-subgroup-new-button',
    },
    deleteButton: {
      permission: {
        permission: 3017,
        tooltip: 'noPermission.supply.subGroups.delete',
      },
      dataTestId: 'delete-subgroup-button',
    },
    editButton:
      selectedRowsOnTable?.length === 1
        ? {
            editButtonWithoutDropdown: true,
            editButtonWithoutDropdownClick: () => setVisibleEditModal(true),
            permission: {
              permission: 3016,
              tooltip: 'noPermission.supply.subGroups.edit',
            },
          }
        : undefined,
    searchPlaceholder: t(`${abbreviatedI18n}.placeholder`),
    nameKey: 'descricao',
  };

  const rowOptions = {
    onRowDoubleClick: (x: IProductSubGroupListData) =>
      history.push(`/inventory/sub-groups/${x.externalId}/details`),
    permission: {
      permission: 3014,
      tooltip: 'noPermission.supply.subGroups.detail',
    },
  };

  const deleteModal = useMemo(
    (): IDeleteModalTable | undefined => ({
      title: TitleHandler(selectedRowsOnTable, t),
      body: BodyHandler(selectedRowsOnTable, t),
      buttons: {
        okButtonColor: 'status-danger-base',
        okButtonDataTestId: 'ok-delete-subgroup-modal-button',
      },
    }),
    [t, selectedRowsOnTable]
  );

  return {
    headerOptions,
    rowOptions,
    deleteModal,
  };
};
