import React from 'react';
import { useTranslation } from 'react-i18next';
import { Table } from '../../../../../Components/Table';
import { PageLoading } from '../../../../../Layouts/PageLoading';
import { posologyColumns } from './Posology.column';
import { editTableStatus } from '../../../../../Utils/EditModalTableUtils';
import { useListStates } from './Hooks/useListStates';
import { useListCallAPI } from './Hooks/useListCallAPI';
import { useListMemorizations } from './Hooks/useListMemorizations';
import { useListFunctions } from './Hooks/useListFunctions';

export const CapsuleColorsList: React.FC = () => {
  const { t } = useTranslation();

  const { selectedRowsOnTable, setSelectedRowsOnTable } = useListStates();

  const {
    deletePosology,
    editStatusPosology,
    posologyList,
    isLoadingPosologyList,
    refetchPosologyList,
  } = useListCallAPI();

  const { headerOptions, rowOptions, deleteModal, editModal } =
    useListMemorizations({
      posologyList,
      selectedRowsOnTable,
    });

  const { onSelectedRowsOnTable, onEdit, onDelete } = useListFunctions({
    setSelectedRowsOnTable,
    editStatusPosology,
    deletePosology,
  });

  return (
    <PageLoading loading={isLoadingPosologyList}>
      <Table
        columns={posologyColumns(t)}
        loading={isLoadingPosologyList}
        headerOptions={headerOptions}
        fieldStatus="ativo"
        editOptions={editTableStatus()}
        editModal={editModal}
        editStatusModalLoading={editStatusPosology.isLoading}
        onEdit={onEdit}
        selectedRowsOnTable={onSelectedRowsOnTable}
        hasSelection
        rowsOptions={rowOptions}
        pagination={posologyList}
        onRefetch={refetchPosologyList}
        onDelete={onDelete}
        deleteModal={deleteModal}
        dataTestId="production-posology"
      ></Table>
    </PageLoading>
  );
};

export default CapsuleColorsList;
