import { useState } from 'react';

export const useContentBodyStates = () => {
  const [openDeliveryFeeModal, setOpenDeliveryFeeModal] = useState(false);
  const [openAddressModalOpen, setIsAddressModalOpen] = useState(false);

  return {
    openDeliveryFeeModal,
    setOpenDeliveryFeeModal,
    openAddressModalOpen,
    setIsAddressModalOpen,
  
  };
};
