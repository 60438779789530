import { QueryFunctionContext } from '@tanstack/react-query';
import { Http, IQueryListParams } from '@/Utils/Http';
import { IListCasResponse } from '@/Data/Interfaces/response/Cas/ICasResponse';

export class CasAPI {
  public static async listDropdownCas(
    context: QueryFunctionContext
  ): Promise<IListCasResponse | null> {
    if (!context.queryKey[1]) return null;

    return await Http.fetcher<IListCasResponse>(
      Http.AddQueryParams(
        `/pharma/producao/v1/Cas/Listar`,
        context.queryKey[1] as IQueryListParams
      )
    );
  }
}
