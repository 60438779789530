import { IPostSynonymResponse } from '@/Data/Interfaces/response/Synonym/ISynonymResponse';
import { Http } from '../../../Utils/Http';
import { IPostSynonymRequest } from '@/Data/Interfaces/request/Synonym/ISynonymRequest';

export class SynonymAPI {
  public static async postSynonym(
    body: IPostSynonymRequest
  ): Promise<IPostSynonymResponse | null> {
    if (!body.produtoExternalId || !body.sinonimo) return null;
    return await Http.fetcher('/pharma/estoque/v1/ProdutoSinonimo', {
      method: 'POST',
      body: JSON.stringify(body),
    });
  }

  public static async validateSynonym(
    description: string
  ): Promise<IPostSynonymResponse | null> {
    if (!description) return null;
    return await Http.fetcher(
      '/pharma/estoque/v1/ProdutoSinonimo/ValidarDescricao',
      {
        method: 'POST',
        body: JSON.stringify({ descricao: description }),
      }
    );
  }
}
