import { i18n } from '../Components';

export const editTableStatus = () => [
  {
    id: true,
    name: i18n.t('common.activate'),
    status: 'success' as any,
  },
  {
    id: false,
    name: i18n.t('common.inactivated'),
    status: 'disabled' as any,
  },
];
