import { IRecoveryRequest } from '@/Data/Interfaces/request/Auth/ILoginRequest';
import { FormikHelpers, FormikValues } from 'formik';
import { IUseRequestFunctions } from './interfaces';

export const useRequestFunctions = ({
  recoveryPassword,
}: IUseRequestFunctions) => {
  const onSubmit = (
    values: IRecoveryRequest,
    helpers: FormikHelpers<FormikValues>
  ) => {
    const body: IRecoveryRequest = { ...values, helpers };

    recoveryPassword.mutateAsync(body);
  };

  return { onSubmit };
};
