import { useCallback } from "react";
import { useQueryClient } from '@tanstack/react-query';
import {
  FETCH_LIST_PRODUCT
} from '@/ControllerApiHook/UniqIds/Supply/ProductKeys';
import { history } from '@/Store';
import { handleModalError } from '@/Utils/ErrorCodeUtils/ErrorHandlingUtils';
import { App } from 'antd';
import { IUseFunctions } from "./interfaces";

export const useDeleteModalFunctions = ({
  product,
  deleteProduct,
  changeVisibleModal
}: IUseFunctions) => {
  const queryClient = useQueryClient();
  const app = App.useApp();

  const onOkClick = useCallback(async () => {
    changeVisibleModal(false);
    try {
      if (product) {
        const res = await deleteProduct.mutateAsync(
          {
            produtosExternalId: [product?.externalId] as any,
          },
          {
            onError: handleModalError(app),
          }
        );

        if (res.success) {
          queryClient.invalidateQueries([FETCH_LIST_PRODUCT]);
          history.push(`/inventory/products`);
        }
      }
    } catch {}
  }, [changeVisibleModal, product, deleteProduct]);

  const onCancelClick = () => changeVisibleModal(false);
  
  const onClose = () => changeVisibleModal(false);

  return {
    onOkClick,
    onCancelClick,
    onClose
  };
};