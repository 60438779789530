import { useCallback } from "react";
import {
  FETCH_INCOMPATIBLE_PRODUCT,
  FETCH_LIST_FOR_DROPDOWN_INCOMPATIBLE_PRODUCT
} from '@/ControllerApiHook/UniqIds/Supply/ProductKeys';
import { useQueryClient } from '@tanstack/react-query';
import { IUseFunctions } from "./interfaces";

export const useIncompatibleProductFunctions = ({
  deleteProductIncompatible,
  setSelectedRowsOnTable
}: IUseFunctions) => {
  const queryClient = useQueryClient();

  const onDelete = useCallback(async (invoice: any[]) => {
    const productsExternalId = invoice.map((e: any) => e.externalId);
    await deleteProductIncompatible.mutateAsync({
      produtosIncompativeisExternalId: productsExternalId,
    });
    queryClient.invalidateQueries([FETCH_INCOMPATIBLE_PRODUCT]);
    queryClient.invalidateQueries([
      FETCH_LIST_FOR_DROPDOWN_INCOMPATIBLE_PRODUCT,
    ]);
  }, [deleteProductIncompatible]);
  
  const selectRowsOnTable = (x: any) => setSelectedRowsOnTable(x ?? []);

  return {
    onDelete,
    selectRowsOnTable
  };
};