import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Table } from '@/Components';
import { lotColumns } from './lot.columns';
import { PageLoading } from '@/Layouts/PageLoading';
import { useListStates } from './Hooks/useListStates';
import { useListCallAPI } from './Hooks/useListCallAPI';
import { useListMemorizations } from './Hooks/useListMemorizations';
import { useListFunctions } from './Hooks/useListFunctions';
import StorageUtils from '@/Utils/StorageUtils';

const LotList: FC = () => {
  const { t } = useTranslation();

  const preferences = StorageUtils.getPreference();

  const { selectedRowsOnTable, setSelectedRowsOnTable } = useListStates();

  const {
    lotsList,
    isLoading,
    statusList,
    refetchLotList,
    situationLot,
    deleteLot,
    editStatusLot,
    patchLotStatus,
  } = useListCallAPI();

  const { headerOptions, deleteModal, rowOptions, editOptions, editModal } =
    useListMemorizations({
      selectedRowsOnTable,
      statusList,
    });

  const { onEdit, onDelete, onSelectedRowsOnTable } = useListFunctions({
    patchLotStatus,
    deleteLot,
    setSelectedRowsOnTable,
  });

  return (
    <PageLoading loading={isLoading}>
      <Table
        columns={lotColumns(t, preferences, situationLot)}
        headerOptions={headerOptions}
        deleteModal={deleteModal}
        loading={isLoading}
        hasSelection
        fieldStatus="situacao"
        rowsOptions={rowOptions}
        pagination={lotsList}
        selectedRowsOnTable={onSelectedRowsOnTable}
        editOptions={editOptions}
        editModal={editModal}
        editStatusModalLoading={editStatusLot.isLoading}
        onEdit={onEdit}
        onDelete={onDelete}
        onRefetch={refetchLotList}
      />
    </PageLoading>
  );
};

export default LotList;
