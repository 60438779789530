import { FC } from "react";
import { TextInputNumber } from '@/Components/Inputs/TextInputNumber';
import { Col } from 'antd';
import { useTranslation } from "react-i18next";

export const FormulaDiscount: FC = () => {
  const { t } = useTranslation();

  return (
    <Col flex="17%">
      <TextInputNumber
        name="descontoFormulas"
        label={[{ children: t('prescribers.createPage.info.descFormula') }]}
        placeHolder="0,00"
        leftIcon={{ titleString: '%' }}
      />
    </Col>
  );
};