import { useState } from 'react';

export const useEditStates = () => {
  const [selectedRowsOnTable, setSelectedRowsOnTable] = useState<any[]>([]);

  return {
    selectedRowsOnTable,
    setSelectedRowsOnTable,
  };
};
