import { FC } from "react";
import {
  ICommonError
} from '@/Utils/ErrorCodeUtils/ErrorHandlingUtils';
import { useTranslation } from 'react-i18next';

export const Title: FC<ICommonError> = ({ content }) => {
  const { t } = useTranslation();
  return content?.length > 1 ? (
    <>
      {t('supply.subgroup.modal.cannotDelete.batchTitle', {
        subGroup: content.length,
      })}
    </>
  ) : (
    <>
      {t('supply.subgroup.modal.cannotDelete.title', {
        subGroup: content[0].subGrupoDescricao,
      })}
    </>
  );
};