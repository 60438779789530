import { useCallback } from 'react';
import { IUseFunctions } from './interfaces';

export const useCasFunctions = ({ refetchSearchCasPage }: IUseFunctions) => {
  const onSearch = useCallback(
    (search?: string) => {
      refetchSearchCasPage({
        search: search,
      });
    },
    [refetchSearchCasPage]
  );

  return { onSearch };
};
