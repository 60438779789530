import React, { ReactNode, useState } from 'react';
import { Collapse as AntdCollapse } from 'antd';
import { IFChildren } from '../Form';
import { Button, Tooltip } from '@/Components';

import { Icon, Text } from '..';

import styles from './Card.module.scss';
import './Card.override.scss';
import { IconName } from '../Icon/IconClasses';

const { Panel } = AntdCollapse;

export const getCardStatus = (
  fieldNames: string[],
  formProps: IFChildren
): 'brand' | 'default' | 'success' | 'error' => {
  if (!formProps) return 'default';

  const errorKeys = Object.keys(formProps.errors || {});
  const touchedKeys = getKeysAsString(formProps.touched);
  const hasErrors = fieldNames.some((x) => errorKeys.includes(x));

  if (hasErrors) return 'error';
  if (formProps.submitCount > 0) return 'success';

  const isTouched = fieldNames.some((x) => touchedKeys.includes(x));
  return isTouched ? 'brand' : 'default';
};

const getKeysAsString = (obj: any) => {
  const arr: string[] = [];

  const keys = Object.keys(obj);

  keys.forEach((key) => {
    if (typeof obj[key] === 'object') {
      const deepKeys = getKeysAsString(obj[key]);

      arr.push(...deepKeys.map((deepKey) => `${key}.${deepKey}`));
    } else {
      arr.push(key);
    }
  });

  return arr;
};

export interface ICard {
  id?: string;
  title?: any;
  status?: 'brand' | 'default' | 'success' | 'error';
  onClose?: boolean;
  children: ReactNode;
  withoutCollapse?: boolean;
  insideCard?: boolean;
  showButtonTitle?: boolean;
  buttonTittleText?: string;
  isButtonTitleDisabled?: boolean;
  onButtonTitleClick?: () => void;
  withTooltip?: {
    title: string;
    icon: IconName;
  };
}

export const Card: React.FC<ICard> = ({
  id,
  title,
  status,
  onClose,
  children,
  withoutCollapse,
  insideCard,
  showButtonTitle = false,
  buttonTittleText = 'your text here',
  isButtonTitleDisabled = false,
  onButtonTitleClick,
  withTooltip,
}) => {
  const [isActiveIcon, setIsActiveIcon] = useState(false);

  return (
    <AntdCollapse
      className={`${styles['collapse']} ${
        withoutCollapse ? 'collapse-withoutCollapse' : ''
      } ${insideCard ? styles['insideCard'] : ''} ${
        insideCard ? 'Card-inside-arrow' : ''
      }`}
      defaultActiveKey={onClose ? undefined : ['1']}
      expandIcon={({ isActive }) => {
        setIsActiveIcon(isActive ?? false);

        return (
          insideCard && (
            <div className={styles['Title-insideCard']}>
              <Icon
                className={styles['rotate-' + isActive]}
                size="L"
                icon="chevron-down"
                color="text-300"
              />
              <Text
                className={`${styles['Title']} ${styles['Title-insideCard']}`}
                type="heading4"
                color={'text-50'}
                children={
                  <>
                    {title}
                    {withTooltip && withTooltip.icon && (
                      <Tooltip
                        title={withTooltip.title}
                        showMe
                        children={
                          <Icon
                            className={styles['icon-with-tooltip']}
                            icon={withTooltip.icon}
                            color="text-300"
                            size="S"
                          />
                        }
                      />
                    )}
                  </>
                }
              />
            </div>
          )
        );
      }}
      collapsible={withoutCollapse ? 'disabled' : 'header'}
    >
      <Panel
        id={id}
        className={styles['panel']}
        header={
          <div
            style={{
              display: 'flex',
              width: '100%',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            {!insideCard && (
              <Text
                className={styles['Title']}
                type="heading4"
                color={'text-50'}
                children={
                  <>
                    {title}
                    {withTooltip && withTooltip.icon && (
                      <Tooltip
                        title={withTooltip.title}
                        showMe
                        targetOffset={[-5, 0]}
                        children={
                          <>
                            <Icon
                              className={styles['icon-with-tooltip']}
                              icon={withTooltip.icon}
                              color="text-300"
                              size="SM"
                              style={{ marginLeft: 10 }}
                            />
                          </>
                        }
                      />
                    )}
                    {!withoutCollapse && (
                      <Icon
                        className={styles['rotate-' + isActiveIcon]}
                        size="XXL"
                        icon="chevron-down"
                        color="text-50"
                        style={{ marginLeft: 6 }}
                      />
                    )}
                  </>
                }
              />
            )}

            {showButtonTitle && (
              <Button
                type="secondary"
                disabled={isButtonTitleDisabled}
                size="xl"
                onClick={() => {
                  onButtonTitleClick?.();
                }}
              >
                {buttonTittleText}
              </Button>
            )}
          </div>
        }
        key="1"
      >
        <div className={styles['body']}>{children}</div>
      </Panel>
      {!withoutCollapse && (
        <div
          className={`${styles['div-color']} ${status ? styles[status] : ''}`}
        />
      )}
    </AntdCollapse>
  );
};
