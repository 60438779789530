import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '../..';

import styles from './ButtonCard.module.scss';
import { IPermissionWrapper, PermissionWrapper } from '../../PermissionWrapper';
import { AdderListType } from '../../AdderList';

interface IButtonCard {
  disabled: boolean;
  onDisabledChange: (value: boolean) => void;
  permission?: IPermissionWrapper;

  type?: AdderListType;
}

export const ButtonCard: FC<IButtonCard> = ({
  disabled,
  onDisabledChange,
  permission,
  type,
}) => {
  const { t } = useTranslation();
  return disabled ? (
    <PermissionWrapper
      permission={permission?.permission}
      tooltip={permission?.tooltip || 'noPermission.default'}
    >
      <Button
        type="secondary"
        children={
          type === 'adrressType'
            ? t('prescribers.editPage.editAddressButton')
            : type === 'contactType'
            ? t('prescribers.editPage.editContactButton')
            : type === 'documentType'
            ? t('prescribers.editPage.editDocumentButton')
            : t('prescribers.editPage.editButton')
        }
        onClick={() => onDisabledChange(false)}
        className={styles['edit-footer-buttom']}
      />
    </PermissionWrapper>
  ) : (
    <div className={styles['footer-buttom']}>
      <Button
        type="secondary"
        children={t('common.close')}
        onClick={() => onDisabledChange(true)}
      />
    </div>
  );
};
