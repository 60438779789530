import { FC, useCallback, useEffect, useState } from 'react';
import { Col, List, Row } from 'antd';
import { Text } from '../Text';
import { Button } from '../Button';
import { Modal } from '../Modal';
import { renderCard } from './AdderListType';
import { compareEqualObj } from '../../Utils/CompareEqualObj';
import { DeleteModal } from './Modal';
import { AdderListSchema } from './AdderList.schema';

import styles from './AdderList.module.scss';

export type ItemsType = {
  description: any[] | string[];
  values?: any;
  favorite?: boolean;
};

export type ColumnType = {
  label?: string;
  width?: number;
  key: string;
};

export type AdderListType = 'contactType' | 'adrressType' | 'documentType';

export interface IAdderList {
  items?: ItemsType[];
  collum: ColumnType[];
  favoriteFormName?: string;
  modal: {
    body: any;
    addTitle: string;
    editTitle: string;
    okEditButtonLabel?: string;
    okAddButtonLabel?: string;
    modalFormName: string[];
    modalFormRequiredName: string[];
    widthModal?: number;
    initialValues?: any;
  };

  buttonLabel: string;

  onAdd?: (value: any) => void;
  onDelete?: (value: ItemsType) => void;
  onEdit?: (value: any) => void;
  onChangeFavorite?: (externalId: string, value: boolean) => void;

  onChange?: (value?: any[]) => void;

  empytDataDescription: string;
  type?: AdderListType;

  actions?: boolean;
  withoutAddButton?: boolean;
  withoutFavorite?: boolean;

  entityExternalIdName: string;
}

export const AdderList: FC<IAdderList> = ({
  type,
  items,
  collum,
  favoriteFormName,
  modal,
  buttonLabel,
  onAdd,
  onDelete,
  onEdit,
  onChangeFavorite,
  onChange,
  empytDataDescription,
  actions,
  withoutAddButton,
  withoutFavorite,
  entityExternalIdName,
}) => {
  const [dataItems, setDataItems] = useState<ItemsType[] | []>([]);
  const [addModalOpen, setAddModalOpen] = useState(false);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [deleteItemIndex, setDeleteItemIndex] = useState<number>(0);
  const [editModalInitialValues, setEditModalInitialValues] = useState<any>();
  const [favoriteIndex, setFavoriteIndex] = useState<number | undefined>();

  const arrayToObj = useCallback(
    (values: any) =>
      Object.keys(values)
        .map((objKey) => ({
          [objKey]:
            typeof values[objKey] === 'object'
              ? values[objKey]?.id
              : values[objKey],
        }))
        .reduce((result: any, obj: any) => {
          return { ...result, ...obj };
        }, {}),
    []
  );

  useEffect(() => {
    if (compareEqualObj({ obj: { firstObj: dataItems, secondObj: items } }))
      return;
    items && setDataItems(items);
    const favoriteIndexAux = items?.findIndex((x) => x.favorite);
    if (favoriteIndexAux !== undefined && favoriteIndexAux >= 0)
      setFavoriteIndex(favoriteIndexAux);
    else setFavoriteIndex(undefined);
  }, [items, dataItems]);

  useEffect(() => {
    !editModalOpen && setEditModalInitialValues(undefined);
  }, [editModalOpen]);

  const onChangeDataItems = useCallback(() => {
    if (!onChange) return;
    let auxDataItems: any[] = [];

    dataItems?.forEach((x, index) => {
      Object.keys(x.values).map((objKey) =>
        x.values[objKey] && typeof x.values[objKey] === 'object'
          ? (auxDataItems[index] = {
              ...auxDataItems[index],
              [objKey]: x.values[objKey].id,
            })
          : (auxDataItems[index] = {
              ...auxDataItems[index],
              [objKey]: x.values[objKey],
            })
      );
      if (favoriteFormName)
        auxDataItems[index] = {
          ...auxDataItems[index],
          [favoriteFormName]: !!x.favorite,
        };
    });

    onChange(auxDataItems);
  }, [dataItems, onChange, favoriteFormName]);

  useEffect(() => {
    onChangeDataItems();
    // eslint-disable-next-line
  }, [dataItems]);

  const addNewItem = (values: any) => {
    let auxDataItens: any = dataItems;
    !items &&
      auxDataItens?.push({
        description: collum.map((x) =>
          typeof values[x.key] === 'object' ? values[x.key].id : values[x.key]
        ),
        ...(auxDataItens.length === 0 && { favorite: false }),
        values: values,
      });
    setAddModalOpen(false);
    onAdd && onAdd(arrayToObj(values));
    onChangeDataItems();
  };

  const editItem = (data: any) => {
    setEditModalOpen(false);
    let auxDataItems = dataItems;
    const documentIndex = data.index;
    collum.forEach(
      (x, indexCollum) =>
        (auxDataItems[documentIndex].description[indexCollum] =
          typeof data[x.key] === 'object' ? data[x.key]?.id : data[x.key])
    );
    data = {
      ...data,
      [entityExternalIdName]:
        auxDataItems[documentIndex].values[entityExternalIdName],
    };
    Object.keys(data).forEach((objKey) =>
      typeof data[objKey] === 'object'
        ? (data = {
            ...data,
            [objKey]: data[objKey]?.id,
          })
        : (data = {
            ...data,
            [objKey]: data[objKey],
          })
    );

    auxDataItems[documentIndex].values = data;
    setDataItems(auxDataItems);

    onChangeDataItems();
    onEdit &&
      onEdit(
        arrayToObj({
          ...data,
          [entityExternalIdName]:
            dataItems[documentIndex].values[entityExternalIdName],
        })
      );
  };

  const deleteItem = (index: any) => {
    setDataItems(
      dataItems?.filter((_dataItem, itemIndex) => index !== itemIndex)
    );
    setDeleteModalOpen(false);
    onDelete && onDelete(dataItems[index]);
  };

  return (
    <div>
      {!items && (
        <Row className={styles['collum']} gutter={[16, 0]}>
          {collum?.map((collum) => (
            <Col span={collum.width} key={collum.label}>
              <Text
                type="ui-tiny-content"
                children={collum.label}
                color="text-50"
              />
            </Col>
          ))}
        </Row>
      )}

      <List
        className={styles['adder-list']}
        loading={!dataItems}
        itemLayout="horizontal"
        locale={{
          emptyText: (
            <>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  marginBottom: '16px',
                  marginTop: '-5px',
                }}
              >
                <Text
                  type="ui-tiny-content"
                  color="text-300"
                  children={empytDataDescription}
                />
              </div>
            </>
          ),
        }}
        dataSource={dataItems}
        renderItem={(item, index) => (
          <List.Item
            actions={
              actions
                ? [
                    !withoutFavorite && (
                      <Button
                        type="secondary"
                        onClick={() => {
                          if (!items) {
                            let auxDataItems = dataItems;
                            if (favoriteIndex === index) {
                              auxDataItems[index].favorite = false;
                              setFavoriteIndex(undefined);
                            } else {
                              if (auxDataItems) {
                                if (favoriteIndex)
                                  auxDataItems[favoriteIndex].favorite = false;
                                auxDataItems[index].favorite = true;
                                setFavoriteIndex(index);
                              }
                            }
                            auxDataItems && setDataItems(auxDataItems);
                            onChangeDataItems();
                          }
                          onChangeFavorite &&
                            onChangeFavorite(
                              item.values[entityExternalIdName],
                              favoriteIndex !== index
                            );
                        }}
                        leftIcon={
                          favoriteIndex === index ? 'Unfavorite' : 'favorite'
                        }
                      />
                    ),
                    <Button
                      type="secondary"
                      onClick={() => {
                        const initialValueObject = Object.assign(
                          {},
                          ...modal.modalFormName.map(
                            (x: any, indexNames: any) => ({
                              [modal.modalFormName[indexNames]]: item.values[x],
                            })
                          )
                        );
                        setEditModalInitialValues({
                          ...initialValueObject,
                          index,
                        });
                        setEditModalOpen(true);
                      }}
                      leftIcon="edit"
                    />,
                    <Button
                      type="secondary"
                      onClick={() => {
                        setDeleteItemIndex(index);
                        setDeleteModalOpen(true);
                      }}
                      leftIcon="trash"
                    />,
                  ]
                : []
            }
          >
            {renderCard(type, index, item, favoriteIndex, collum, !items)}
          </List.Item>
        )}
      />
      {!withoutAddButton && (
        <Button
          className={styles['add-buttom']}
          type="secondary"
          children={buttonLabel}
          leftIcon="plus"
          onClick={() => setAddModalOpen(true)}
        />
      )}
      <Modal
        body={modal.body}
        title={editModalOpen ? modal.editTitle : modal.addTitle}
        visible={editModalOpen || addModalOpen}
        onOkClickForm={addModalOpen ? addNewItem : editItem}
        withForm
        schema={AdderListSchema}
        widthModal={modal.widthModal}
        htmlType="submit"
        initialValues={editModalOpen ? editModalInitialValues : undefined}
        onCancelClick={() => {
          setEditModalOpen(false);
          setAddModalOpen(false);
        }}
        onClose={() => {
          setEditModalOpen(false);
          setAddModalOpen(false);
        }}
        okButtonName={
          editModalOpen ? modal.okEditButtonLabel : modal.okAddButtonLabel
        }
        disabledButtonFormNames={modal.modalFormRequiredName}
      />
      <DeleteModal
        type={type}
        deleteItem={deleteItem}
        deleteItemIndex={deleteItemIndex}
        onChangeModalOpen={() => setDeleteModalOpen(false)}
        visible={deleteModalOpen}
        dataItems={dataItems}
      />
    </div>
  );
};
