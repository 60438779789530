import { 
  IListAlternativeUnitMeasurementData 
} from "@/Data/Interfaces/response/UnitMeasurement/IUnitMeasurementResponse";
import { alternativeUnit1, alternativeUnit2 } from '../utils';
import { IAlternativeUnit, IUseFunctions } from "./interfaces";

export const useInfoForFormCalcFunctions = ({
  product,
  unidadeAlternativaList
}: IUseFunctions) => {
  const defineOriginalUnit = () => {
    if (product && unidadeAlternativaList) {
      let unitAbbreviation1 = '';
      let unitAbbreviation2 = '';
      let alternativeUnit1Abbreviation = '';
      let alternativeUnit2Abbreviation = '';

      const unitAlternative1 = unidadeAlternativaList.data
        ? unidadeAlternativaList.data.find(
            (e: IListAlternativeUnitMeasurementData) => {
              return e.id === product.materiaPrima?.unidadeAlternativaId1;
            }
          )
        : undefined;

      if (unitAlternative1)
        unitAbbreviation1 = unitAlternative1?.unidadeAbreviacao;

      const unitAlternative2 = unidadeAlternativaList.data
        ? unidadeAlternativaList.data.find(
            (e: IListAlternativeUnitMeasurementData) => {
              return e.id === product.materiaPrima?.unidadeAlternativaId2;
            }
          )
        : undefined;

      if (unitAlternative2)
        unitAbbreviation2 = unitAlternative2?.unidadeAbreviacao;

      const alternativeUnit1Description = alternativeUnit1.find((e: IAlternativeUnit) => {
        return e.id === product.materiaPrima?.unidadeAlternativaConversaoId1;
      });

      if (alternativeUnit1Description)
        alternativeUnit1Abbreviation = alternativeUnit1Description.description;

      const alternativeUnit2Description = alternativeUnit2.find((e: IAlternativeUnit) => {
        return e.id === product.materiaPrima?.unidadeAlternativaConversaoId2;
      });

      if (alternativeUnit2Description)
        alternativeUnit2Abbreviation = alternativeUnit2Description.description;

      return {
        originalUnitAlternative1: `${unitAbbreviation1} ${
          product.materiaPrima?.quantidadeUnidadeAlternativa1 ?? 0
        } ${alternativeUnit1Abbreviation}`,
        originalUnitAlternative2: `${unitAbbreviation2} ${
          product.materiaPrima?.quantidadeUnidadeAlternativa2 ?? 0
        } ${alternativeUnit2Abbreviation}`,
      };
    }
  };

  return {
    defineOriginalUnit
  };
};