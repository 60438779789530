import { IconName } from "@/Components/Icon/IconClasses";
import { useState } from "react";

export const useMainContactStates = () => {
  const [iconContact, setIconContact] = useState<IconName>();

  return {
    iconContact,
    setIconContact
  };
};