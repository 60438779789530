import { FormikHelpers, FormikValues } from 'formik';
import {
  FETCH_GET_PRODUCT,
  FETCH_LIST_PRODUCT
} from '@/ControllerApiHook/UniqIds/Supply/ProductKeys';
import { IEditProductRequest } from '@/Data/Interfaces/request/Product/IProductRequest';
import { useQueryClient } from '@tanstack/react-query';
import { IUseFunctions } from './interfaces';

export const useDetailsFunctions = ({
  product,
  editProduct,
  toogleDisabled,
  disabledForm,
  externalId
}: IUseFunctions) => {
  const queryClient = useQueryClient();

  const onSubmit = async (
    values: IEditProductRequest,
    helpers: FormikHelpers<FormikValues>
  ) => {
    var body: IEditProductRequest = { ...values, helpers };

    if (product) {
      const res = await editProduct.mutateAsync({
        ...body,
        externalId: externalId,
        descricao: body?.descricao,
        descricaoRotulo: body?.descricaoRotulo,
        classeProdutoId: body?.classeProdutoId,
        subGrupoExternalId: body?.subGrupoExternalId,
        unidadeEstoqueId: body?.unidadeEstoqueId,
        fornecedorExternalId: body?.fornecedorExternalId,
        controlaLote: body?.controlaLote,
        usoContinuo: body?.usoContinuo,
        etiqueta: body?.etiqueta,
        controlaQualidade: body?.controlaQualidade,
        helpers,
      });
      if (res.success) {
        queryClient.invalidateQueries([FETCH_GET_PRODUCT]);
        queryClient.invalidateQueries([FETCH_LIST_PRODUCT]);
        toogleDisabled(!disabledForm);
      }
    }
  };

  return {
    onSubmit
  };
};