import {
  QueryClient,
  UseMutationResult,
  useQueryClient,
} from '@tanstack/react-query';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Icon } from '../../../../../..';
import { IGetCompanyResponse } from '../../../../../../../Data/Interfaces/response/Company/ICompanyResponse';
import { IGetConglomerateResponse } from '../../../../../../../Data/Interfaces/response/Conglomerate/IConglomerateResponde';
import {
  CHANGE_STATUS_TYPE,
  GET_COMPANY_ADMIN_TYPE,
} from '../../../../../../../ControllerApiHook/UniqIds/Accountmanager/CompanyKeys';
import { GET_CONGLOMERATE_TYPE } from '../../../../../../../ControllerApiHook/UniqIds/Accountmanager/ConglomerateKeys';
import { Themes } from '../../../../../../../Utils/ThemeUtils';
import { Modal } from '../../../../../../Modal';
import { SimpleStatus } from '../../../../../../SimpleStatus';
import { Text } from '../../../../../../Text';
import ReactMarkdown from 'react-markdown';
import { useControllerMutateApiHook } from '@/ControllerApiHook/Controller';
import { CompanyAPI } from '@/Data/API/Accountmanager/CompanyApi';

interface IDeleteProductModal {
  company?: IGetCompanyResponse;
  status?: boolean;
  visibleModal: boolean;
  changeVisibleModal: (value: boolean) => void;
}

export const ConfirmationChangeStatusCompanyModal: FC<IDeleteProductModal> = ({
  company,
  status,
  visibleModal,
  changeVisibleModal,
}) => {
  const { t } = useTranslation();

  const changeStatus = useControllerMutateApiHook<any>({
    uniqId: CHANGE_STATUS_TYPE,
    entityApi: CompanyAPI.patchCompanyStatus,
  });

  const queryClient = useQueryClient();

  return (
    <Modal
      title={`${t('common.inactivate')} "${company?.nomeFantasia}"?`}
      body={
        <div style={{ padding: '20px' }}>
          <Text
            type="ui-tiny-content"
            children={
              <ReactMarkdown>
                {t(
                  'adminBootis.editCompany.modal.status.inativeStatusDescription',
                  {
                    companyName: `**${company?.nomeFantasia}**`,
                  }
                )}
              </ReactMarkdown>
            }
          />
          <Text
            type="ui-tiny-content"
            children={t('modal.editModal.single.confirm.footer')}
          />
        </div>
      }
      okButtonColor="status-danger-base"
      visible={visibleModal}
      onOkClick={async () => {
        if (status === undefined || !company) return;
        const res = await changeStatus.mutateAsync({
          empresasExternalId: [company.externalId],
          ativa: status,
        });

        if (res.success)
          queryClient.invalidateQueries([GET_COMPANY_ADMIN_TYPE]);
      }}
      okButtonName={t('common.inactivate')}
      onCancelClick={() => changeVisibleModal(false)}
      onClose={() => changeVisibleModal(false)}
    />
  );
};

interface IInativeConglomerateModal {
  visibleConfirmationStatusModal: boolean;
  conglomerate: IGetConglomerateResponse;
  changeStatus: UseMutationResult<any>;
  onClose: () => void;
  queryClient: QueryClient;
  preferenceTheme?: number;
  dataTestIdOkButton?: string;
}

export const InativeConglomerateModal: FC<IInativeConglomerateModal> = ({
  visibleConfirmationStatusModal,
  conglomerate,
  changeStatus,
  onClose,
  queryClient,
  preferenceTheme,
  dataTestIdOkButton,
}) => {
  const { t } = useTranslation();
  return (
    <Modal
      title={`${t('adminBootis.editConglomerate.conflictModal.title')} "${
        conglomerate?.nome
      }"?`}
      dataTestIdOkButton={dataTestIdOkButton}
      body={
        <InativeConglomerateModalBody
          conglomerate={conglomerate}
          preferenceTheme={preferenceTheme}
        />
      }
      visible={visibleConfirmationStatusModal}
      okButtonName={t('adminBootis.editConglomerate.conflictModal.okButton')}
      onClose={onClose}
      onCancelClick={onClose}
      onOkClick={async () => {
        const res = await changeStatus.mutateAsync({
          conglomeradosExternalId: [conglomerate.externalId],
          ativa: false,
        });

        if (res.success) {
          queryClient.invalidateQueries([GET_CONGLOMERATE_TYPE]);
          onClose();
        }
      }}
    />
  );
};

interface IInativeConglomerateModalBody {
  conglomerate: IGetConglomerateResponse;
  preferenceTheme?: number;
}

const InativeConglomerateModalBody: FC<IInativeConglomerateModalBody> = ({
  conglomerate,
  preferenceTheme,
}) => {
  const { t } = useTranslation();
  return (
    <div style={{ margin: '20px' }}>
      <div style={{ marginBottom: '24px' }}>
        <Text
          type="ui-tiny-content"
          children={
            <ReactMarkdown>
              {
                t(
                  'adminBootis.editConglomerate.conflictModal.content',
                  {
                    conglomerateName: `**${conglomerate?.nome}**`
                  }
                )
              }
            </ReactMarkdown>
          }
        />
      </div>
      <div
        style={{
          display: 'flex',
          width: '100%',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <SimpleStatus
          type="success"
          darkMode={preferenceTheme !== Themes['lightMode']}
        />
        <Icon
          icon="long-arrow-right"
          size="SS"
          color="text-400"
          style={{ margin: '0 15px' }}
        />
        <SimpleStatus
          type="disabled"
          darkMode={preferenceTheme !== Themes['lightMode']}
        />
      </div>
    </div>
  );
};
