import { useCallback } from "react";
import { useFormikContext } from "formik";
import { useTranslation } from "react-i18next";

  export const useModalBodyFunctions = () => {
  const form: any = useFormikContext();
  const { t } = useTranslation();

  const onSubGroupValidationError = useCallback(() => {
    form?.setErrors({
      ...form.errors,
      descricao: t('products.create.errors.errorSubGroupExist'),
    });
  }, [form, t]);

  const onSubGroupValidationSuccess = useCallback(() => {
    form?.setErrors({
      ...form.errors,
      descricao: undefined,
    });
  }, [form, t]);

  return {
    onSubGroupValidationError,
    onSubGroupValidationSuccess
  };
};