import { useMemo } from "react";
import { IUseMemorizations } from "./interfaces";
import { useTranslation } from "react-i18next";

export const useDetailsMemorizations = ({
  specialtie,
  editSpecialtie
}: IUseMemorizations) => {
  const { t } = useTranslation();

  const initialValues = {
    descricao: specialtie?.descricao,
    especialidadePrescritorExternalId: specialtie?.externalId,
  };

  const submitButtonChildren = editSpecialtie.isLoading
  ? `${t('common.saving')}...`
  : t('common.save');

  const modalBody = useMemo(() => ( 
    <div style={{ padding: 20, maxWidth: 400 }}>
      {t('supply.balanceAdjustment.backModal.body')}
    </div>
  ), [t]);

  return {
    initialValues,
    submitButtonChildren,
    modalBody
  };
};