import { i18n } from '@/Components';
import { useControllerMutateApiHook } from '@/ControllerApiHook/Controller';
import { CreateInvoice } from '@/Services/Purchase/Invoice/CreateInvoice.service';
import { history } from '@/Store';
import {
  messageErrorToast,
  messageSucessToast,
} from '@/Utils/MessageErrorToast';

export const CREATE_INVOICE = '[INVOICE] CREATE_INVOICE';

export const useCreateInvoice = () => {
  const createInvoice: any = useControllerMutateApiHook({
    uniqId: CREATE_INVOICE,
    entityApi: CreateInvoice,
    options: {
      onError: (x) => messageErrorToast(x),
      onSuccess: () => {
        messageSucessToast(
          i18n.t('purchasing.invoice.successMessageToasts.create')
        );
        history.push('/order/invoice');
      },
    },
  });

  return { createInvoice };
};
