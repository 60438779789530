import { FC, ReactElement } from 'react';
import { Button } from '../..';
import { Text } from '../../Text';
import { ButtonType } from '..';
import { IPermissionWrapper } from '../../PermissionWrapper';
import { IOptions } from '../../Table/components/HeaderTable';
import { ButtonDropdown } from '../ButtonDropdown';

export interface ISaveDraft {
  onClick?: () => void;
  htmlType?: 'submit' | 'button' | 'reset';
  loading?: boolean;
  children?: string | ReactElement;
  type: ButtonType;
  withStatusIcon?: boolean;
  withStatusMessage?: string;
  disabled?: boolean;

  withDropdownEditButton?: {
    permission?: IPermissionWrapper;
    options?: IOptions[];
    editButtonWithoutDropdown?: boolean;
    editButtonWithoutDropdownClick?: () => void;
  };
}

export const ButtonDraft: FC<ISaveDraft> = ({
  onClick,
  htmlType,
  loading,
  children,
  type,
  withStatusIcon,
  withStatusMessage,
  disabled,
  withDropdownEditButton,
}) => {
  return (
    <div>
      <Text
        color="text-300"
        type="small-text"
        withStatusIcon={withStatusIcon}
        children={withStatusMessage}
        style={{ marginRight: 15 }}
      />
      <Button
        disabled={disabled}
        type={type}
        children={children}
        onClick={onClick}
        htmlType={htmlType}
        leftIcon={loading ? undefined : 'diskette'}
        loading={loading}
        style={withDropdownEditButton && { marginRight: -13 }}
        widthChild={withDropdownEditButton && 110}
      />
      {withDropdownEditButton ? (
        <ButtonDropdown
          type="secondary"
          dropdownItens={withDropdownEditButton.options}
          dropdownDirection="bottomRight"
          rightIcon="chevron-down"
          dangerStatus
        />
      ) : (
        <></>
      )}
    </div>
  );
};
