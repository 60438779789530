import { IUseMemorizations } from './interfaces';
import { useTranslation } from 'react-i18next';
import { history } from '@/Store';
import { PermissionEnum } from '@/Data/Interfaces/model/permissionEnum';
import { useMemo } from 'react';
import { IDeleteModalTable } from '@/Components/Table';
import { DeleteModalOnTable } from '@/Utils/DeleteModalUtils';
import { DeleteModalBody } from '../Modal/deleteModal';

export const useListMemorizations = ({
  selectedRowsOnTable,
}: IUseMemorizations) => {
  const { t } = useTranslation();

  const headerOptions = {
    hasSearch: true,
    newButton: {
      label: t('supply.productMessage.list.newButton'),
      onClick: () => history.push('/inventory/product-message/create'),
      permission: {
        permission: PermissionEnum.Estoque_MensagemProduto_Cadastrar,
        tooltip: 'noPermission.supply.messageProduct.create',
      },
    },
    deleteButton: {
      permission: {
        permission: PermissionEnum.Estoque_MensagemProduto_Excluir,
        tooltip: 'noPermission.supply.messageProduct.delete',
      },
    },
    nameKey: 'descricao',
    searchPlaceholder: t('supply.productMessage.list.placeholder'),
  };

  const rowOptions = {
    onRowDoubleClick: (x: any) =>
      history.push(`/inventory/product-message/${x.externalId}/details`),
    permission: {
      permission: PermissionEnum.Estoque_MensagemProduto_VerDetalhes,
      tooltip: 'noPermission.supply.messageProduct.detail',
    },
  };

  const deleteModal = useMemo(
    (): IDeleteModalTable | undefined => ({
      title:
        selectedRowsOnTable &&
        `${t('supply.productMessage.details.deleteTitle')}  ${
          selectedRowsOnTable.length > 1
            ? `${t('supply.productMessage.details.deleteManyTitle')}?`
            : `${t('supply.productMessage.details.deleteSingleTitle')}?`
        }`,
      customEndTitle: t('common.productMessage'),
      body:
        selectedRowsOnTable && selectedRowsOnTable?.length > 1 ? (
          DeleteModalOnTable(
            selectedRowsOnTable,
            `${t('modal.deleteModal.many.title')} ${t(
              'common.theProductMessage'
            )}`,
            'descricao',
            t('common.productMessage')
          )
        ) : (
          <DeleteModalBody selectedRowsOnTable={selectedRowsOnTable} />
        ),
      buttons: {
        okButtonColor: 'status-danger-base',
      },
      widthModal: 400,
    }),
    [selectedRowsOnTable, t]
  );

  return {
    headerOptions,
    rowOptions,
    deleteModal,
  };
};
