import {
  FETCH_GET_PRODUCT
} from '@/ControllerApiHook/UniqIds/Supply/ProductKeys';
import { useControllerQueryApiHook } from '@/ControllerApiHook/Controller';
import { IProductResponse } from '@/Data/Interfaces/response/Product/IProductRespose';
import { ProductAPI } from '@/Data/API/Supply/Product';
import { IUseCallAPI } from './interfaces';

export const useProductCallAPI = ({
  productExternalId
}: IUseCallAPI) => {
  const { data: currentProduct } = useControllerQueryApiHook<IProductResponse>({
    uniqId: FETCH_GET_PRODUCT,
    entityApi: ProductAPI.getProduct,
    externalIds: [productExternalId]
  });

  return {
    currentProduct
  };
};