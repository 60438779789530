import { i18n } from '@/Components';
import DateUtils from '@/Utils/DateUtils';
import _ from 'lodash';
import * as yup from 'yup';
import { PersonType } from '../../Tabs/Details/Components/InfoCard';

export const ModalSchema = (personType: number) =>
  yup.object().shape({
    nome: yup.string().required(i18n.t('client.error.requiredName')),
    ...(personType === PersonType.legalEntity && {
      CPF: yup.string().required(i18n.t('common.requiredCpf')),
    }),
    dataNascimento: yup
      .string()
      .test(
        'date validation',
        i18n.t('common.dateInvalid'),
        (val) => _.isNil(val) || DateUtils.isDateValid(val)
      )
      .nullable(),
  });
