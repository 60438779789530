import { FETCH_GET_PRODUCT } from '@/ControllerApiHook/UniqIds/Supply/ProductKeys';
import { FETCH_LIST_UNITS_BY_CLASS } from '@/ControllerApiHook/UniqIds/Supply/UnitMeasurementKeys';
import { IProductResponse } from '@/Data/Interfaces/response/Product/IProductRespose';
import { ProductAPI } from '@/Data/API/Supply/Product';
import {
  useControllerQueryApiHook,
  useControllerQueryListApiHook,
} from '@/ControllerApiHook/Controller';
import { UnitMeasurementAPI } from '@/Data/API/Supply/UnitMeasurementApi';
import { IUseCallAPI } from './interfaces';
import { useMemo } from 'react';

export const useUnitaryValueCallAPI = ({ formValues }: IUseCallAPI) => {
  const productExternalId = formValues.productExternalId;

  const externalIds = useMemo(() => {
    return productExternalId ? [productExternalId] : [];
  }, [productExternalId]);

  const { data: unitsMeasurements, refetch: refetchUnits } =
    useControllerQueryListApiHook({
      uniqId: FETCH_LIST_UNITS_BY_CLASS,
      entityApi: UnitMeasurementAPI.listUnitMeasurementByClassAny,
      pagination: {
        filter: [
          {
            filterName: 'tipoClasseProduto',
            value: '',
          },
        ],
      },
      enabled: !!formValues.productExternalId,
    });

  const result = useControllerQueryApiHook<IProductResponse>({
    uniqId: FETCH_GET_PRODUCT,
    entityApi: ProductAPI.getProduct,
    externalIds,
    onSuccess: (data) => {
      refetchUnits({
        filter: [
          {
            filterName: 'tipoClasseProduto',
            value: data?.classeProdutoId,
          },
        ],
      });
    },
  });

  return {
    result,
    unitsMeasurements,
  };
};
