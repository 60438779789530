import { FC } from 'react';
import {
  Dropdown
} from '@/Components';
import { Col } from 'antd';
import { useTranslation } from 'react-i18next';
import { useLevelIncompatibleMemorizations } from './Hooks/useLevelIncompatibleMemorizations';
import { useLevelIncompatibleFunctions } from './Hooks/useLevelIncompatibleFunctions';

export const LevelIncompatible: FC = () => {
  const { t } = useTranslation();

  const {
    items
  } = useLevelIncompatibleMemorizations();

  const {
    onChange
  } = useLevelIncompatibleFunctions();

  return (
    <Col span={5}>
      <Dropdown
        name="nivelIncompatibilidade"
        label={t('products.incompatible.levelIncompatibility')}
        placeHolder={t(
          'products.incompatible.levelIncompatibilityPlaceholder'
        )}
        required
        items={items}
        onChange={onChange}
      />
    </Col>
  );
};