import { i18n } from '@/Components';
import DateUtils from '@/Utils/DateUtils';
import * as yup from 'yup';

const prescriberI18n = 'prescribers.createPage.error';

export const InfoModalSchema = yup.object().shape({
  nomeCompleto: yup
    .string()
    .required(i18n.t(`${prescriberI18n}.nameIsRequired`)),
  tipoRegistroId: yup
    .string()
    .required(i18n.t(`${prescriberI18n}.registerTypeIsRequired`)),

  ufRegistroExternalId: yup
    .string()
    .required(i18n.t(`${prescriberI18n}.registerUfIsRequired`)),
  codigoRegistro: yup
    .string()
    .required(i18n.t(`${prescriberI18n}.registerCodeIsRequired`)),
  dataNascimento: yup
    .string()
    .nullable()
    .test('date validation', i18n.t('common.dateInvalid'), (val) =>
      val ? DateUtils.isDateValid(val) : true
    ),
});
