import React, { useEffect } from 'react';
import { Form } from '@/Components';
import { CompanyDataCard } from '@/Layouts/CompanyFormLayout/CompanyDataCard';
import { CompanyCard } from '@/Layouts/CompanyFormLayout/CompanyAdressCard';
import { CompanyGroupsCardWrapper } from '@/Layouts/CompanyFormLayout/CompanyGroupsCard';
import { CompanyCardSchema } from './CompanyForm.schema';
import { 
  CreateCompanyBillingsCard 
} from '@/Layouts/CompanyFormLayout/CompanyBillingsCard/Components/CreateForm';
import { FooterButton } from '../Components/FooterButton';
import { 
  CompanyResponsibleUserCardWrapperAdmin 
} from '@/Layouts/CompanyFormLayout/CompanyResponsibleUserCard/EditAdmin';
import { ConnectedFocusError } from '@/Components/ConnectedFocusError';
import { useCompanyCreateMemorizations } from './Hooks/useCompanyCreateMemorizations';
import { useCompanyCreateCallAPI } from './Hooks/useCompanyCreateCallAPI';
import { useCompanyCreateFunctions } from './Hooks/useCompanyCreateFunctions';
import { useCompanyCreateStates } from './Hooks/useCompanyCreateStates';
import { ICompanyForm } from './interfaces';

export const CompanyCreate: React.FC<ICompanyForm> = ({ editForm }) => {
  const {
    radioCompanyGroup,
    setRadioCompanyGroup,
    radioUser,
    setRadioUser,
    radioCompanyBillings,
    setRadioCompanyBillings,
    validateAfterSubmit,
    setValidateAfterSubmit,
    companyCardSchema,
    setCompanyCardSchema
  } = useCompanyCreateStates();

  const {
    orderedErrors
  } = useCompanyCreateMemorizations();

  const {
    createCompany,
    createConglomerate
  } = useCompanyCreateCallAPI();

 const {
  onSubmit
 } =useCompanyCreateFunctions({
  radioCompanyGroup,
  createCompany,
  createConglomerate
 });

  useEffect(() => {
    setCompanyCardSchema(
      CompanyCardSchema({
        radioCompanyGroup,
        radioUser,
        radioCompanyBillings,
      })
    );
  }, [radioCompanyBillings, radioUser, radioCompanyGroup]);

  return (
    <Form
      onSubmit={onSubmit}
      schema={companyCardSchema}
      validateOnChange={validateAfterSubmit}
    >
      <>
        <ConnectedFocusError orderedErrors={orderedErrors} />
        <CompanyDataCard />
        <CompanyCard />
        <CompanyGroupsCardWrapper
          onChangeRadioCompanyGroup={(value) => setRadioCompanyGroup(value)}
          radioCompanyGroup={radioCompanyGroup}
        />
        <CompanyResponsibleUserCardWrapperAdmin
          radioCompanyGroup={radioCompanyGroup}
          radioUser={radioUser}
          onChangeRadioUser={setRadioUser}
          isLoading={createCompany.isLoading || createConglomerate.isLoading}
        />
        <CreateCompanyBillingsCard
          radioCompanyGroup={radioCompanyGroup}
          onChangeRadioBillings={setRadioCompanyBillings}
          radioBillings={radioCompanyBillings}
        />
        <FooterButton
          onClick={setValidateAfterSubmit}
          loading={createConglomerate.isLoading || createCompany.isLoading}
        />
      </>
    </Form>
  );
};
