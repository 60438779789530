import { useCallback, useEffect } from 'react';
import { IUseCreateFunctions } from './interfaces';

export const useCreateFunctions = ({
  form,
  convertUnitOfMeasureAsync,
  unitMeasureIdDefault,
}: IUseCreateFunctions) => {
  const onUnitOfMeasureChange = useCallback(
    async (selectedId: any) => {
      const currentUnitOfMeasureId = form.values.unidadeMedidaId;
      const quantity = form.values.quantidade;
      const currentQuantity = form.values.saldoAtual;

      const result = await convertUnitOfMeasureAsync([
        {
          quantidade: currentQuantity || 0,
          unidadeMedidaConversaoId: selectedId,
          unidadeMedidaOrigemId: currentUnitOfMeasureId,
        },
        {
          quantidade: quantity || 0,
          unidadeMedidaConversaoId: selectedId,
          unidadeMedidaOrigemId: currentUnitOfMeasureId,
        },
      ]);

      if (result && result.length > 0) {
        const saldoAtual = result[0].quantidadeConvertida;
        const quantity = result[1].quantidadeConvertida;
        form.setFieldValue('saldoAtual', saldoAtual);
        form.setFieldValue('quantidade', quantity);
        form.setFieldValue('novoSaldo', saldoAtual - quantity);
      }
    },
    [convertUnitOfMeasureAsync, form]
  );

  const validateCalcLoss = useEffect(() => {
    let quantityLossNumber: number = parseFloat(form.values.quantidade);
    let newValueNumber: number = parseFloat(form.values.saldoAtual);
    if (isNaN(newValueNumber)) {
      newValueNumber = 0;
    }
    if (isNaN(quantityLossNumber)) {
      quantityLossNumber = 0;
    }
    let newValue = newValueNumber - quantityLossNumber;
    form?.setFieldValue('novoSaldo', newValue || 0);
  }, [form.values.quantidade]);

  useEffect(() => {
    if (unitMeasureIdDefault) {
      form.setFieldValue('unidadeMedidaId', unitMeasureIdDefault);
    }
  }, [unitMeasureIdDefault]);

  return { onUnitOfMeasureChange, validateCalcLoss };
};
