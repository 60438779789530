import { FC } from 'react';
import { TextInputWithMask } from '@/Components/Inputs/TextInputWithMask';
import { useTranslation } from 'react-i18next';
import { Col } from 'antd';
import { ICompanyInfo } from './interfaces';

export const CompanyInfo: FC<ICompanyInfo> = ({ validateCnpj }) => {
  const { t } = useTranslation();

  return (
    <Col flex="32%">
      <TextInputWithMask
        name="cnpj"
        label={'CNPJ'}
        mask="cnpjMask"
        placeHolder={`${t('common.exampleAbbreviation')}: 42.163.509/0001-03`}
        onChange={(x) => validateCnpj(x)}
      />
    </Col>
  );
};
