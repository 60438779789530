import { useTranslation } from "react-i18next";
import { IUseMemorizations } from "./interfaces";

export const useAddUserGroupMemorizations = ({
  usersSelected
}: IUseMemorizations) => {
  const { t } = useTranslation();

  const titleModal = `${t('userGroups.editGroups.modal.add')}${
      usersSelected.length === 1
        ? ` ${usersSelected.length} ${t(
            'userGroups.editGroups.modal.participant'
          )}`
        : usersSelected.length > 1
        ? ` ${usersSelected.length} ${t('userGroups.editGroups.modal.users')}`
        : ` ${t('userGroups.editGroups.modal.users')}`
    }`;

    return { titleModal };
};