import { FC } from 'react';
import { ICommonError } from '@/Utils/ErrorCodeUtils/ErrorHandlingUtils';
import { useTranslation } from 'react-i18next';
import { BodyModal } from '@/Components/Modal';
import { Text } from '@/Components';
import { DeleteModalOnTableNotFooter } from '@/Utils/DeleteModalUtils';
import { ReactMarkdown } from 'react-markdown/lib/react-markdown';

import styles from './Content.module.scss';

export const Content: FC<ICommonError> = ({ content }) => {
  const { t } = useTranslation();

  return content?.length > 1 ? (
    <>
      <BodyModal
        body={
          <>
            <div className={styles['container']}>
              {DeleteModalOnTableNotFooter(
                content,
                `${t('supply.subgroup.modal.cannotDelete.batchSubTitle')}`,
                'subGrupoDescricao',
                ''
              )}
              <Text type="ui-tiny-content">
                {t('supply.subgroup.modal.cannotDelete.batchDescription')}
              </Text>
            </div>
          </>
        }
        style={{ padding: '13px 27px' }}
      />
    </>
  ) : (
    <>
      <BodyModal
        body={
          <div className={styles['container']}>
            <Text type="ui-tiny-content">
              <ReactMarkdown>
                {t('supply.subgroup.modal.cannotDelete.subTitle', {
                  subGroup: `**${content[0].subGrupoDescricao?.trim()}**`,
                })}
              </ReactMarkdown>
            </Text>
            <Text type="ui-tiny-content" style={{ marginTop: 10 }}>
              <ReactMarkdown>
                {content[0].produtoCount > 1
                  ? t(
                      'supply.subgroup.modal.cannotDelete.descriptionManyProducts',
                      {
                        products: content[0].produtoCount,
                        subgroups: content[0].subGrupoCount,
                      }
                    )
                  : t(
                      'supply.subgroup.modal.cannotDelete.descriptionOneProduct',
                      {
                        products: content[0].produtoCount,
                        subgroups: content[0].subGrupoCount,
                      }
                    )}
              </ReactMarkdown>
            </Text>
          </div>
        }
        style={{ padding: '10px 20px 10px 20px' }}
      />
    </>
  );
};
