import { IColumn } from '.';
import { Highlight } from '../Highlight';
import { IPermissionWrapper, PermissionWrapper } from '../PermissionWrapper';

export const AddHighlight = (
  columns: IColumn[],
  search?: string,
  selectedRows?: any[]
) => {
  return columns.map((col) => {
    if (!col.isHighlightable || !search) {
      return col;
    }

    if (col.render) {
      return {
        ...col,
        render: (text: string, record: any, index: number) => (
          <Highlight highlight={search}>
            {col.render && col.render(text, record, index, selectedRows)}
          </Highlight>
        ),
      };
    }

    col.render = (text) => {
      return <Highlight highlight={search}>{text}</Highlight>;
    };
    return col;
  });
};

export const AddPermission = (
  columns: IColumn[],
  permission?: IPermissionWrapper,
  selectedRows?: any[]
): IColumn[] => {
  if (!permission) return columns;

  return columns.map((col) => {
    if (col.render) {
      return {
        ...col,
        render: (text: string, record: any, index: number) => (
          <PermissionWrapper {...permission}>
            {col.render && col.render(text, record, index, selectedRows)}
          </PermissionWrapper>
        ),
      };
    }

    return {
      ...col,
      render: (text: string) => (
        <PermissionWrapper {...permission}>
          <span>{text}</span>
        </PermissionWrapper>
      ),
    };
  });
};
