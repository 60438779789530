import { NumberInput } from '@/Components/StateInputs/NumberInput';
import { Divflex } from '@/Layouts/DivWhithFlex';
import { Col, Row } from 'antd';
import { Dispatch, FC, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import { Icon, Text } from '@/Components';

interface IDoseModalBody {
  pharmaceuticalSelected?: string | undefined;
  doseModalValues: {
    amount?: number | undefined;
    dose?: number | undefined;
    totalDose?: number | undefined;
  };
  setDoseModalValues: Dispatch<
    SetStateAction<{
      amount?: number | undefined;
      dose?: number | undefined;
      totalDose?: number | undefined;
    }>
  >;
  form: any;
}

export const DoseModalBody: FC<IDoseModalBody> = ({
  pharmaceuticalSelected,
  doseModalValues,
  setDoseModalValues,
  form,
}) => {
  const { t } = useTranslation();

  const totalDose = (doseModalValues.amount || 1) / (doseModalValues.dose || 1);

  return (
    <Divflex style={{ padding: 20 }}>
      <Row gutter={[16, 0]}>
        <Col flex="31%">
          <NumberInput
            label={t(
              'saleOrder.editOrder.SaleOrder.addManipulatedRecipe.amount'
            )}
            value={doseModalValues.amount || 0}
            required
            placeHolder="0"
            rightIcon={{
              titleString: pharmaceuticalSelected,
            }}
            onChange={(x) => {
              form.setFieldValue('quantidade', +x);
              setDoseModalValues((prevState) => ({
                ...prevState,
                amount: x,
              }));
            }}
          />
        </Col>
        <div>
          <div
            style={{
              display: 'flex',
              alignItems: 'flex-end',
              height: '100%',
            }}
          >
            <Text
              type="ui-sub-content"
              children="/"
              color="text-300"
              style={{ marginBottom: '10px' }}
            />
          </div>
        </div>
        <Col flex="31%">
          <NumberInput
            label={t('saleOrder.editOrder.SaleOrder.addManipulatedRecipe.dose')}
            placeHolder="0"
            value={doseModalValues.dose || 0}
            rightIcon={{
              titleString: pharmaceuticalSelected,
            }}
            onChange={(x) =>
              setDoseModalValues((prevState) => ({
                ...prevState,
                dose: x,
              }))
            }
          />
        </Col>
        <div>
          <div
            style={{
              display: 'flex',
              alignItems: 'flex-end',
              height: '100%',
            }}
          >
            <Icon
              icon="Equal"
              size="S"
              color="text-300"
              style={{ marginBottom: '12px' }}
            />
          </div>
        </div>
        <Col flex="31%">
          <NumberInput
            label={t(
              'saleOrder.editOrder.SaleOrder.addManipulatedRecipe.totalDose'
            )}
            placeHolder="0 doses"
            disabled
            value={doseModalValues.dose ? totalDose : 0}
          />
        </Col>
      </Row>
    </Divflex>
  );
};
