import { Dropdown, InputWithSearch, Tooltip } from '@/Components';
import { Col, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import { useCarrierList } from './hooks/useCarrierList';
import { useListFreightType } from '@/Hooks/HooksServices/Purchase/Purchase/useListFreightType';
import { useFormikContext } from 'formik';

export const RowFour: React.FC = () => {
  const { t } = useTranslation();
  const form: any = useFormikContext();

  const { carrier } = useCarrierList();
  const freightType = useListFreightType({
    fieldName: 'tipoFreteId',
    enabled: true,
  });

  return (
    <Row gutter={[16, 0]}>
      <Col span={6}>
        <Tooltip
          showMe={
            form.values.transportadoraExternalId !==
            form.initialValues.transportadoraExternalId
          }
          title={`${t('purchasing.invoice.edit.originalCarrier')}${
            form.initialValues.transportadoraDescricao
          }`}
          targetOffset={[100, 0]}
        >
          <InputWithSearch
            {...carrier}
            placeHolder={t(
              'purchasing.invoice.create.placeHolder.searchForCarrier'
            )}
            label={t('purchasing.invoice.create.carrierCode')}
            withStatusIcon
          />
        </Tooltip>
      </Col>
      <Col span={6}>
        <Tooltip
          showMe={form.values.tipoFreteId !== form.initialValues.tipoFreteId}
          title={`${t('purchasing.invoice.edit.originalFrightType')}${
            form.initialValues.tipoFreteDescricao
          }`}
          targetOffset={[100, 0]}
        >
          <Dropdown
            {...freightType}
            label={t('purchasing.invoice.create.frightType')}
            required
            withStatusIcon
          />
        </Tooltip>
      </Col>
    </Row>
  );
};
