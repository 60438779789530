export type IconName =
  | 'logo-bootys'
  | 'simple-logo'
  | 'exclamation'
  | 'users'
  | 'check'
  | 'attach'
  | 'double-chevron-right'
  | 'chevron-down'
  | 'archive'
  | 'download'
  | 'like'
  | 'chevron-right'
  | 'chevron-up'
  | 'delete-folder'
  | 'lock'
  | 'bell'
  | 'search'
  | 'plus'
  | 'close-x'
  | 'exclamation-upside-down'
  | 'menu-elipsis'
  | 'email'
  | 'calculation'
  | 'double-chevron-left'
  | 'chevron-left'
  | 'notext-logo'
  | 'user-mark'
  | 'question-mark'
  | 'exit'
  | 'eye'
  | 'close-eye'
  | 'multimidia-equalizer'
  | 'logo-bootys-login'
  | 'search-action'
  | 'arrow-down'
  | 'arrow-up'
  | 'trash'
  | 'edit'
  | 'user'
  | 'buildings'
  | 'document-info'
  | 'cube'
  | 'cubes'
  | 'connection'
  | 'bin-full'
  | 'document'
  | 'tag'
  | 'tags'
  | 'water-glass'
  | 'library'
  | 'basket'
  | 'shipping-truck'
  | 'cash'
  | 'invoice'
  | 'conical-flask'
  | 'setting'
  | 'shopping-bag'
  | 'gear'
  | 'Building'
  | 'invoice-list'
  | 'menu-ellipsis-horizontal'
  | 'keyhole'
  | 'account'
  | 'users-outline'
  | 'clipboard'
  | 'simple-cube'
  | 'external-link'
  | 'remove-account'
  | 'Box'
  | 'calendar'
  | 'copy'
  | 'Arrow-right'
  | 'status-attention'
  | 'favorite'
  | 'pencil'
  | 'whatsapp'
  | 'phone'
  | 'instagram'
  | 'mobile'
  | 'telegran'
  | 'disapproved'
  | 'partialcube'
  | 'time'
  | 'packaging-classification'
  | 'capsules-colors'
  | 'dosage'
  | 'pharmaceutical-form'
  | 'add-book'
  | 'edit-file'
  | 'Unfavorite'
  | 'reply'
  | 'diskette'
  | 'BoxCorrect'
  | 'chat-bubble'
  | 'prescriber'
  | 'conical-flask-gear'
  | 'invoiceList'
  | 'counter'
  | 'exclamation-warning'
  | 'trash-line'
  | 'cart'
  | 'box-wrong'
  | 'gral-add'
  | 'backup'
  | 'box-add-alternative'
  | 'calculator'
  | 'Broken-Chain-3'
  | 'landscape-text-file'
  | 'Approve'
  | 'weight-scale'
  | 'growth-graph'
  | 'approve-file'
  | 'gral'
  | 'rename'
  | 'drag'
  | 'Equal'
  | 'synonym-square'
  | 'medicine'
  | 'mask'
  | 'capsules'
  | 'medical-clipboard'
  | 'medicine-bottle'
  | 'facebook'
  | 'mobile-chat'
  | 'shopping-bag-chat'
  | 'shopping-bag-ecommerce'
  | 'house'
  | 'long-arrow-right'
  | 'skype';

export const iconClasses = {
  'logo-bootys': 'icon-logo-bootys',
  'simple-logo': 'icon-simple-logo',
  lock: 'icon-lock',
  exclamation: 'icon-exclamation-mark',
  users: 'icon-users-outline',
  'users-outline': 'icon-users-outline',
  'simple-cube': 'icon-simple-cube',
  clipboard: 'icon-clipboard',
  check: 'icon-approve-tick',
  attach: 'icon-attach-outline',
  'double-chevron-right': 'icon-double-chevron-right',
  'chevron-down': 'icon-chevron-down',
  'chevron-right': 'icon-chevron-right',
  'chevron-up': 'icon-chevron-up',
  archive: 'icon-archive',
  download: 'icon-download',
  like: 'icon-like',
  'delete-folder': 'icon-delete-folder',
  bell: 'icon-bell',
  search: 'icon-search',
  plus: 'icon-plus',
  'close-x': 'icon-close-x',
  'exclamation-upside-down': 'icon-exclamation-upside-down',
  'menu-elipsis': 'icon-menu-elipse',
  email: 'icon-email',
  calculation: 'icon-calculation',
  'double-chevron-left': 'icon-double-chevron-left',
  'chevron-left': 'icon-chevron-left',
  'notext-logo': 'icon-notext-logo',
  'user-mark': 'icon-user-mark',
  'question-mark': 'icon-question-mark',
  exit: 'icon-exit',
  eye: 'icon-eye',
  'close-eye': 'icon-close-eye',
  'multimidia-equalizer': 'icon-multimidia-equalizer',
  'logo-bootys-login': 'icon-logo-bootys-login',
  'search-action': 'icon-search-action',
  'arrow-down': 'icon-arrow-down',
  'arrow-up': 'icon-arrow-up',
  'Arrow-right': 'icon-Arrow-right',
  edit: 'icon-edit',
  trash: 'icon-trash',
  'trash-line': 'icon-trash-line',
  user: 'icon-account',
  account: 'icon-account',
  buildings: 'icon-buildings',
  'document-info': 'icon-document-info',
  cube: 'icon-cube',
  cubes: 'icon-cubes',
  connection: 'icon-connection',
  'bin-full': 'icon-bin-full',
  document: 'icon-document',
  tag: 'icon-tag',
  tags: 'icon-tags',
  'water-glass': 'icon-water-glass',
  library: 'icon-library',
  basket: 'icon-basket',
  'shipping-truck': 'icon-shipping-truck',
  cash: 'icon-cash',
  invoice: 'icon-invoice',
  'conical-flask': 'icon-conical-flask',
  setting: 'icon-setting',
  'shopping-bag': 'icon-shopping-bag',
  gear: 'icon-gear',
  Building: 'icon-Building',
  'invoice-list': 'icon-invoice-list',
  'menu-ellipsis-horizontal': 'icon-menu-ellipsis-horizontal',
  keyhole: 'icon-keyhole',
  'external-link': 'icon-external-link',
  'remove-account': 'icon-remove-account',
  Box: 'icon-Box',
  calendar: 'icon-calendar',
  copy: 'icon-copy',
  'status-attention': 'icon-status-attention',
  favorite: 'icon-favorite',
  pencil: 'icon-pencil',
  whatsapp: 'icon-whatsapp',
  phone: 'icon-phone',
  instagram: 'icon-instagram',
  mobile: 'icon-mobile',
  telegran: 'icon-telegran',
  disapproved: 'icon-disapproved',
  partialcube: 'icon-partialcube',
  time: 'icon-time',
  'packaging-classification': 'icon-packaging-classification',
  'capsules-colors': 'icon-capsules-colors',
  dosage: 'icon-dosage',
  'pharmaceutical-form': 'icon-pharmaceutical-form',
  'add-book': 'icon-add-book',
  'edit-file': 'icon-edit-file',
  Unfavorite: 'icon-Unfavorite',
  reply: 'icon-reply',
  diskette: 'icon-diskette',
  BoxCorrect: 'icon-BoxCorrect',
  'chat-bubble': 'icon-chat-bubble',
  prescriber: 'icon-prescriber',
  'conical-flask-gear': 'icon-conical-flask-gear',
  invoiceList: 'icon-invoice',
  counter: 'icon-counter',
  'exclamation-warning': 'icon-exclamation-warning',
  cart: 'icon-cart',
  'box-wrong': 'icon-box-wrong',
  'gral-add': 'icon-gral-add',
  backup: 'icon-backup',
  'box-add-alternative': 'icon-box-add-alternative',
  calculator: 'icon-calculator',
  'Broken-Chain-3': 'icon-Broken-Chain-3',
  'landscape-text-file': 'icon-landscape-text-file',
  Approve: 'icon-Approve',
  'weight-scale': 'icon-weight-scale',
  'growth-graph': 'icon-growth-graph',
  'approve-file': 'icon-approve-file',
  gral: 'icon-gral',
  rename: 'icon-Rename',
  drag: 'icon-Drag',
  'synonym-square': 'icon-synonym-square',
  Equal: 'icon-Equal',
  medicine: 'icon-medicine',
  mask: 'icon-mask',
  capsules: 'icon-capsules',
  'medical-clipboard': 'icon-medical-clipboard',
  'medicine-bottle': 'icon-medicine-bottle',
  facebook: 'icon-facebook',
  'mobile-chat': 'icon-mobile-chat',
  'shopping-bag-chat': 'icon-shopping-bag-chat',
  'shopping-bag-ecommerce': 'icon-shopping-bag-ecommerce',
  house: 'icon-house',
  'long-arrow-right': 'icon-long-arrow-right',
  skype: 'icon-skype',
};
