import React from 'react';
import { useTranslation } from 'react-i18next';
import { Modal } from '@/Components/Modal';
import { IEditModal } from './interfaces';
import { BodyModal } from './Components/BodyModal';
import { useDeleteGroupModalCallAPI } from './Hooks/useDeleteGroupModalCallAPI';
import { useDeleteGroupModalFunctions } from './Hooks/useDeleteGroupModalFunctions';

export const DeleteSubGroupModal: React.FC<IEditModal> = ({
  visible,
  productSubGroup,
  onCancelClick,
  onOkClick,
}) => {
  const { t } = useTranslation();

  const { deleteSubGroup } = useDeleteGroupModalCallAPI();

  const { onDelete } = useDeleteGroupModalFunctions({
    deleteSubGroup,
    productSubGroup,
    onOkClick
  });

  return (
    <Modal
      onOkClick={onDelete}
      title={`${t('common.delete')} ${t('common.subGroup')} “${
        productSubGroup.subGrupoDescricao
      }” ?`}
      body={<BodyModal productSubGroup={productSubGroup} />}
      visible={visible}
      onCancelClick={onCancelClick}
      htmlType="submit"
      okButtonName={t('modal.delete')}
      okButtonColor="status-danger-base"
      onClose={onCancelClick}
    />
  );
};