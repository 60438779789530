import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Modal } from '@/Components/Modal';
import { Divflex } from '@/Layouts/DivWhithFlex';
import { Text } from '@/Components';
import { 
  FooterThisActionIsPermanent 
} from '@/Components/Table/components/Modals/DeleteModal';
import { IDeleteStandardFormulaModal } from './interfaces';
import { 
  useDeleteStandardFormulaModalCallAPI 
} from './Hooks/useDeleteStandardFormulaModalCallAPI';
import { 
  useDeleteStandardFormulaModalFunctions 
} from './Hooks/useDeleteStandardFormulaModalFunctions';

export const DeleteStandardFormulaModal: FC<IDeleteStandardFormulaModal> = ({
  standardFormula,
  visibleModal,
  changeVisibleModal
}) => {
  const { t } = useTranslation();

  const {
    deleteStandardFormula
  } = useDeleteStandardFormulaModalCallAPI();

  const {
    onDelete,
    onCancelClick,
    onClose
  } = useDeleteStandardFormulaModalFunctions({
    standardFormula,
    deleteStandardFormula,
    changeVisibleModal
  });

  return (
    <>
      {visibleModal && (
        <Modal
          title={`${t('common.deleteStandardFormulaDetail')}  "${
            standardFormula?.descricaoProdutoFormula
          }"?`}
          body={
            <div style={{ padding: '20px' }}>
              <Divflex style={{ flexDirection: 'column', marginBottom: 10 }}>
                <Text
                  color="text-50"
                  type="ui-tiny-content"
                  children={`${t(
                    'production.standardFormula.details.modal.messageQuestionDelete'
                  )} `}
                />

                <Text
                  color="text-50"
                  type="ui-tiny-bold"
                  children={`${standardFormula?.descricaoProdutoFormula}?`}
                />
              </Divflex>
              <FooterThisActionIsPermanent />
            </div>
          }
          okButtonColor="status-danger-base"
          visible={visibleModal}
          onOkClick={onDelete}
          okButtonName={t('common.delete')}
          onCancelClick={onCancelClick}
          onClose={onClose}
          okButtonType="danger"
        />
      )}
    </>
  );
};
