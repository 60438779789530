import { useCallback } from 'react';
import { IUseFunctions } from './interfaces';
import { App } from 'antd';
import { handleModalError } from '@/Utils/ErrorCodeUtils/ErrorHandlingUtils';
import { cannotDeleteSpecialtyWithBondProps } from '@/Utils/ErrorCodeUtils/ErrorModalUtils/ErrorModalSpecialties';
import { useTranslation } from 'react-i18next';

export const useDeleteModalFunctions = ({
  changeVisibleModal,
  specialtie,
  deleteSpecialties,
}: IUseFunctions) => {
  const { t } = useTranslation();
  const app = App.useApp();

  const onCancelClick = useCallback(
    () => changeVisibleModal(false),
    [changeVisibleModal]
  );

  const onClose = useCallback(
    () => changeVisibleModal(false),
    [changeVisibleModal]
  );

  const onOkClick = useCallback(
    async () =>
      specialtie?.externalId &&
      (await deleteSpecialties.mutateAsync(
        {
          especialidadePrescritorExternalId: [specialtie.externalId],
        },
        {
          onError: handleModalError(
            app,
            cannotDeleteSpecialtyWithBondProps(t),
            changeVisibleModal
          ),
        }
      )),
    [specialtie, deleteSpecialties, app, t, changeVisibleModal]
  );

  return {
    onCancelClick,
    onClose,
    onOkClick,
  };
};
