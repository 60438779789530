import { Icon } from '@/Components/Icon';
import { Label } from '@/Components/Inputs/Label';
import { Divflex } from '@/Layouts/DivWhithFlex';
import { Col, Row } from 'antd';
import { Skeleton, Tag, Text } from '@/Components';
import React, { FC, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { IconName } from '@/Components/Icon/IconClasses';
import { TagsRender } from '@/Utils/TagsUtils/TagsUtils';

import styles from './contentAvatar.module.scss';

export interface IContentAvatarPopover {
  content: {
    loading?: boolean;
    forClient?: {
      firstLine?: string | number | ReactNode;
      secondLine?: {
        date?: string;
        age?: number;
      };
      thirdLine?: {
        icon?: IconName;
        content?: string | number | ReactNode;
      };
    };
    forPrescriber?: {
      firstLine?: string | number | ReactNode;
      secondLine?: {
        specialties?: string[];
      };
      thirdLine?: {
        icon?: IconName;
        content?: string | number | ReactNode;
      };
    };
  };
}

export const ContentAvatarPopover: FC<IContentAvatarPopover> = ({
  content,
}) => {
  const { t } = useTranslation();

  return (
    <>
      {!content.loading ? (
        <div className={styles['container']}>
          <Row gutter={[16, 16]}>
            <div className={styles['horizontal-divider']} />
            <Col span={24}>
              <Label
                type="ui-tiny-content"
                color="text-200"
                children={content.forClient ? 'CPF' : 'Registro'}
                withBottomMargin
              />
              <Text
                type="ui-tiny-content"
                color="text-50"
                children={
                  content?.forClient
                    ? content?.forClient.firstLine
                    : content.forPrescriber?.firstLine
                }
              />
            </Col>
            <Col span={24}>
              <Label
                type="ui-tiny-content"
                color="text-200"
                children={
                  content?.forClient
                    ? t('client.editPage.birthDate')
                    : 'Especialidades'
                }
                withBottomMargin
              />
              <Divflex>
                {content?.forClient ? (
                  <>
                    <Text
                      type="ui-tiny-content"
                      color="text-50"
                      children={content?.forClient?.secondLine?.date}
                      style={{ marginRight: '8px' }}
                    />
                    <Tag
                      type="secondary"
                      children={`${content.forClient?.secondLine?.age} ${t(
                        'common.years'
                      )}`}
                    />
                  </>
                ) : (
                  <TagsRender
                    data={content.forPrescriber?.secondLine?.specialties!}
                    widthCollum={250}
                  />
                )}
              </Divflex>
            </Col>
            <Col span={24}>
              <Label
                type="ui-tiny-content"
                color="text-200"
                children={t('client.listPage.contact')}
                withBottomMargin
              />
              <Divflex>
                <Icon
                  icon={
                    content?.forClient
                      ? content?.forClient?.thirdLine?.icon
                      : content?.forPrescriber?.thirdLine?.icon
                  }
                  size="M"
                  color="text-300"
                />
                <Text
                  type="ui-tiny-content"
                  color="text-50"
                  children={
                    content?.forClient
                      ? content?.forClient?.thirdLine?.content
                      : content?.forPrescriber?.thirdLine?.content
                  }
                  style={{ margin: '-1px 0px 5px 9px' }}
                />
              </Divflex>
            </Col>
          </Row>
        </div>
      ) : (
        <div className={styles['container']}>
          <br />
          <Skeleton size="md" />
          <br />
          <Skeleton size="md" />
          <br />
          <Skeleton size="md" />
        </div>
      )}
    </>
  );
};
