import Select from 'antd/lib/select';
import { Field, useField } from 'formik';
import React, {
  useCallback,
  useState,
  useEffect,
  KeyboardEventHandler,
  useMemo,
  FC,
  ReactNode,
} from 'react';
import { Highlight, Icon, Tooltip } from '../..';
import { LocalizaPropriedadePorPath } from '../../../Utils/FormikUtils';
import { Tag, TagType } from '../../Tag';
import { IField } from '../interfaces/IField';
import { ITextInput } from '../TextInput';
import { Text } from '../../../Components';
import Spinner from '../../Spinner';
import { getScrollPercentage } from '../../../Utils/ScrollUtils';
import { IUserInfo } from '../../HeaderMenu/Components/UserInfo';
import { Avatar } from '../../Avatar';
import { InputLabel } from '../Label';
import _, { uniqBy } from 'lodash';
import { useTranslation } from 'react-i18next';
import { IconName } from '../../Icon/IconClasses';
import { Divflex } from '../../../Layouts/DivWhithFlex';
import { v4 as uuidV4 } from 'uuid';
import { Checkbox } from '@/Components/StateInputs/Checkbox';
import { PopOver } from '@/Components/PopOver';
import { Color } from '@/Utils/ColorUtils';
import { AvatarPopOver } from './Components/Popover/avatarPopover';
import { TitleAvatarPopover } from './Components/Popover/Titles';
import { ContentAvatarPopover } from './Components/Popover/Contents';

import styles from './TextInputWithSearch.module.scss';
import './TextInputWithSearch.override.scss';

const { Option } = Select;

export interface IItems {
  id?: any;
  label?: string;
  subLabel?: string;
  avatar?: IUserInfo;
  icon?: { color: Color; icon: IconName };
  disabledItem?: boolean;

  allItem?: any;
}

interface IFilterSearchItems {
  id: string | number;
  label: string;
  checked?: boolean;
}

export interface IInputWithSearchControlled
  extends Omit<
    ITextInput,
    | 'leftIcon'
    | 'maxLength'
    | 'dataTestId'
    | 'nulable'
    | 'onBlur'
    | 'rightIconTooltipOnly'
    | 'style'
    | 'suffix'
    | 'supportText'
    | 'type'
    | 'typeLabel'
    | 'value'
    | 'withBoldContent'
    | 'onChange'
  > {
  items?: IItems[];

  /**
   * This prop merges the current value with items
   * avoiding it not being present on the pagination
   *
   * NOTE: Those strings are FORM names like "fornecedor.id" and the value does need to be the same of items prop (IItems)
   */
  selectedItemFromForm?: string;
  emptyState?: {
    prefix?: string;
    suffix?: string;
    onClick: (currentSearch: string) => void;
  };

  /**
   * This prop add static options on the bottom of the dropdown popup
   */
  staticOptions?: {
    name: string;
    icon: IconName;
    onClick: () => void;
  }[];
  name: string;

  required?: boolean;

  onTag?: boolean;
  typeTag?: TagType;

  isLoading?: boolean | undefined;

  withTooltip?: {
    title: string;
    icon: IconName;
  };
  withStatusIcon?: boolean;
  autoIncrementBy?: string;

  withoutSearchIcon?: boolean;
  notShowSelectedItem?: boolean;

  cleanDropdown?: string;
  allowClear?: boolean;
  getPopupContainerId?: string;

  actionOnFocus?: () => void;
  onChange?: (value: string, itemSelected?: any) => void;
  onChangeInputText?: (value: string) => void;
  onInput?:
    | KeyboardEventHandler<HTMLInputElement | HTMLTextAreaElement>
    | undefined;
  onSearch?: (search?: string, filters?: (string | number)[]) => void;
  onScrollEnd?: () => void;

  onClick?: () => void;
  disableOptions?: boolean;

  placeholderWithAvatar?: boolean;

  filterSearch?: {
    items: IFilterSearchItems[];
    title: string;
  };

  withoutLeftIconSelected?: boolean;

  listHeight?: number;

  dataTestId?: string;

  withAvatarPopover?: {
    onOpenChange?: (visible: boolean) => void;
    link: string;
    titleContent?: {
      name?: string;
      lastName?: string;
      typeUser?: string;
      code?: number;
    };
    isLoading?: boolean;
    content: {
      forClient?: {
        firstLine?: string | number | ReactNode;
        secondLine?: {
          date?: string;
          age?: number;
        };
        thirdLine?: {
          icon?: IconName;
          content?: string | number | ReactNode;
        };
      };
      forPrescriber?: {
        firstLine?: string | number | ReactNode;
        secondLine?: {
          specialties?: string[];
        };
        thirdLine?: {
          icon?: IconName;
          content?: string | number | ReactNode;
        };
      };
    };
  };

  focus?: boolean;

  open?: boolean;
}

export const InputWithSearchControlled: React.FC<
  IInputWithSearchControlled
> = ({
  onTag,
  typeTag,
  items,
  name,
  emptyState,
  selectedItemFromForm,
  onChange,
  onChangeInputText,
  onInput,
  onSearch,
  label,
  className,
  required,
  disabled,
  error,
  placeHolder,
  rightIcon,
  success,
  withoutMarginBottom,
  onScrollEnd,
  isLoading,
  withTooltip,
  withStatusIcon,
  autoIncrementBy,
  withoutSearchIcon,
  notShowSelectedItem,
  cleanDropdown,
  allowClear,
  getPopupContainerId,
  staticOptions,
  actionOnFocus,
  onClick,
  disableOptions = false,
  placeholderWithAvatar,
  filterSearch,
  withoutLeftIconSelected,
  listHeight,
  dataTestId,
  withAvatarPopover,
  focus,
  open,
}) => {
  const [clened, setClened] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [itemsInput, setItemsInput] = useState<IItems[] | undefined>();
  const [autoIncrement, setAutoIncrement] = useState(itemsInput || []);
  const [currentSearch, setCurrentSearch] = useState('');
  const [{ value: selectedItem }] = useField(
    selectedItemFromForm || 'prop-not-set'
  );

  const [filterSearchItem, setFilterSearchItem] = useState(filterSearch?.items);

  const { t } = useTranslation();

  useEffect(() => {
    setAutoIncrement([]);
    setItemsInput([]);
    setClened(true);
  }, [cleanDropdown]);

  const onScroll = useCallback(
    (event: React.UIEvent<HTMLElement>) => {
      const scrollPercentage = getScrollPercentage(event);
      const scrollPercentageThreshold = 80;

      // Call hook when you scroll to 80% or more
      if (scrollPercentage > scrollPercentageThreshold && !isLoading) {
        onScrollEnd && onScrollEnd();
      }
    },
    [onScrollEnd, isLoading]
  );

  const setFocusState = () => {
    if (actionOnFocus) {
      actionOnFocus();
    }
  };

  useEffect(() => {
    if (clened) {
      setClened(false);
      return;
    }
    if (!autoIncrementBy) return;

    const allItems = [
      ...(autoIncrement || []),
      ...(itemsInput || []),
      ...(items || []),
    ];
    const uniqueItems = uniqBy(allItems, autoIncrementBy);

    if (uniqueItems.length === autoIncrement.length) return;

    setAutoIncrement(uniqueItems);
  }, [autoIncrementBy, autoIncrement, itemsInput, items, clened]);

  useEffect(() => {
    let currentItems = items;
    if (selectedItem) {
      currentItems = uniqBy([...(items || []), selectedItem], 'id');
    }

    setItemsInput(currentItems);
  }, [items]);

  const onChangeFunc = useCallback(
    (value: string, props: IField, selected?: boolean) => {
      !selected && setItemsInput(undefined);
      props.form.setFieldValue(name, value);

      onChange &&
        onChange(
          value,
          itemsInput?.find((x: IItems) => x.id === value)
        );
    },
    [name, onChange, itemsInput]
  );

  const onChangeFilterFunc = useCallback(
    (filters: (string | number)[]) => {
      onSearch && onSearch('', filters);
    },
    [onSearch]
  );

  const onSearchFunc = useMemo(
    () =>
      _.debounce(async (value: string, selected?: boolean) => {
        !selected && setItemsInput(undefined);
        onSearch && onSearch(value);
      }, 1000),
    []
  );

  const randomId = useMemo(() => uuidV4(), []);

  const [isPopoverVisible, setIsPopoverVisible] = useState(false);

  return (
    <div
      className={`${withoutMarginBottom ? '' : styles['margin-bottom']} ${
        styles['Container']
      }`}
    >
      <Field name={name}>
        {(props: IField) => {
          const hasError =
            error ||
            !!(
              props.form &&
              props.form.errors &&
              LocalizaPropriedadePorPath(props.form.errors, name)
            );
          const classNames = className;

          return (
            <>
              {label && (
                <Divflex>
                  <InputLabel
                    label={label}
                    required={required}
                    withStatusIcon={withStatusIcon}
                    props={props}
                  />
                  {withTooltip && (
                    <Tooltip
                      title={withTooltip.title}
                      showMe
                      children={
                        <Icon
                          className={styles['icon-with-tooltip']}
                          icon={withTooltip.icon}
                          color="text-300"
                          size="S"
                        />
                      }
                    />
                  )}
                </Divflex>
              )}
              <div
                id={randomId}
                onClick={() => onClick?.()}
                data-testid={dataTestId}
                className={`${styles['input-container']} ${classNames} ${
                  rightIcon || success || hasError
                    ? styles['with-right-icon']
                    : null
                } 
                ${hasError ? styles['error'] : null} ${
                  success ? styles['success'] : null
                } ${
                  disabled || props.form.isSubmitting
                    ? styles['disabled']
                    : null
                }`}
              >
                <Select
                  autoFocus={focus}
                  listHeight={listHeight}
                  style={{ pointerEvents: disableOptions ? 'none' : undefined }}
                  value={notShowSelectedItem ? null : props.field.value}
                  onPopupScroll={onScroll}
                  mode={onTag ? 'multiple' : undefined}
                  onChange={(x) => onChangeFunc(x, props, true)}
                  className={`${styles['TextInput']} ${
                    withoutSearchIcon
                      ? styles['TextInput-without-searchIcon']
                      : ''
                  } ${
                    withoutLeftIconSelected ? 'selected-input-with-search' : ''
                  }`}
                  notFoundContent={
                    emptyState ? (
                      <div
                        onClick={() => {
                          emptyState.onClick(currentSearch);
                          setCurrentSearch('');
                        }}
                        className={styles['empty-state']}
                      >
                        {emptyState.prefix}
                        {currentSearch}
                        {emptyState.suffix}
                      </div>
                    ) : undefined
                  }
                  dropdownRender={
                    staticOptions
                      ? (menu) => (
                          <>
                            {menu}
                            <div className={styles['divider']} />
                            {staticOptions.map((option) => (
                              <div
                                key={uuidV4()}
                                onClick={option.onClick}
                                className={styles['static-option']}
                              >
                                <Icon
                                  icon={option.icon}
                                  size="XXL"
                                  color="background-08"
                                />
                                <span className={styles['text']}>
                                  {option.name}
                                </span>
                              </div>
                            ))}
                          </>
                        )
                      : undefined
                  }
                  showArrow={!notShowSelectedItem}
                  allowClear={!!allowClear === undefined ? true : false}
                  tagRender={(props: any) => {
                    const label =
                      props?.label[1]?.props?.children[0]?.props?.children
                        ?.props?.children;
                    return (
                      <Tag
                        className={styles['tag']}
                        type={typeTag ? typeTag : 'secondary'}
                        closable={props.closable}
                        onClose={props.onClose}
                        children={!!props?.label[1] ? label : props.label}
                      />
                    );
                  }}
                  disabled={disabled || props.form.isSubmitting}
                  placeholder={
                    placeholderWithAvatar ? (
                      <Divflex className={styles['container-placeholder']}>
                        <div />
                        <Text
                          type="ui-tiny-content"
                          color="text-400"
                          children={placeHolder}
                        />
                      </Divflex>
                    ) : (
                      placeHolder
                    )
                  }
                  showSearch
                  onInputKeyDown={(inputProps) => {
                    onInput && onInput(inputProps);
                  }}
                  optionFilterProp="id"
                  suffixIcon={
                    !filterSearch && (
                      <Icon
                        className={styles['chevron-up']}
                        icon="chevron-down"
                      />
                    )
                  }
                  getPopupContainer={() =>
                    document.getElementById(
                      getPopupContainerId ?? randomId
                    ) as any
                  }
                  onFocus={() => setFocusState()}
                  onBlur={() => setFocusState()}
                  onSelect={() => setCurrentSearch('')}
                  onClear={() => setCurrentSearch('')}
                  onDeselect={() => setCurrentSearch('')}
                  onDropdownVisibleChange={(x) => {
                    setCurrentSearch('');
                    setIsOpen(x);
                  }}
                  open={open || isOpen}
                  searchValue={currentSearch}
                  loading={isLoading}
                  onSearch={(x) => {
                    onSearchFunc(x);
                    setCurrentSearch(x);
                    onChangeInputText && onChangeInputText(x);
                  }}
                  children={(autoIncrementBy
                    ? autoIncrement || []
                    : itemsInput || []
                  )?.map(
                    (x) =>
                      x.id &&
                      (x.avatar ? (
                        <Option
                          id={x.label}
                          className={`${styles['option']} ${styles['option-avatar']}`}
                          key={`input-with-search-${x.id}`}
                          value={x.id}
                          label={x.label}
                          style={{ width: '100%' }}
                        >
                          <div
                            className={`${styles['divContent']}`}
                            style={{ width: '100%' }}
                          >
                            <Avatar
                              fisrtName={x.avatar.name}
                              lastName={x.avatar.lastName}
                              size="S"
                              type="small-text-bold"
                              color="white"
                            />

                            <div className={styles['div-avatar']}>
                              <Text
                                type="ui-tiny-content"
                                color="text-50"
                                children={`${x.avatar.name} ${
                                  x.avatar.lastName || ''
                                }`}
                                style={{
                                  maxWidth: '95%',
                                  display: 'block',
                                  overflow: 'hidden',
                                  whiteSpace: 'nowrap',
                                  textOverflow: 'ellipsis',
                                }}
                              />
                              <Text
                                type="small-text"
                                color="text-400"
                                children={x.avatar.email}
                                style={{
                                  maxWidth: '95%',
                                  display: 'block',
                                  overflow: 'hidden',
                                  whiteSpace: 'nowrap',
                                  textOverflow: 'ellipsis',
                                }}
                              />
                              {x.subLabel && (
                                <Text
                                  className="input-with-search-subLabel"
                                  type="small-text"
                                  color="text-300"
                                  children={x.subLabel}
                                  style={{
                                    maxWidth: '95%',
                                    display: 'block',
                                    overflow: 'hidden',
                                    whiteSpace: 'nowrap',
                                    textOverflow: 'ellipsis',
                                  }}
                                />
                              )}
                            </div>
                          </div>
                        </Option>
                      ) : (
                        <Option
                          id={x.label}
                          className={
                            x.disabledItem
                              ? styles['option-disabled']
                              : styles['option']
                          }
                          key={`input-with-search-${x.id}`}
                          value={x.id}
                          label={x.label}
                          style={{ width: '100%' }}
                        >
                          {x.icon && (
                            <Icon
                              icon={x.icon.icon}
                              color={x.icon.color}
                              className={styles['left-icon']}
                              size="ML"
                            />
                          )}
                          <div style={{ width: '100%' }}>
                            <Text
                              type="ui-tiny-content"
                              color="text-50"
                              style={{ width: '100%' }}
                            >
                              <Highlight
                                highlight={currentSearch}
                                style={{
                                  maxWidth: onTag ? '100%' : '95%',
                                  display: 'block',
                                  overflow: 'hidden',
                                  whiteSpace: 'nowrap',
                                  textOverflow: 'ellipsis',
                                }}
                              >
                                {x.label || ''}
                              </Highlight>
                            </Text>
                            {x.subLabel && (
                              <Text
                                className="input-with-search-subLabel"
                                type="small-text"
                                color="text-300"
                                children={x.subLabel}
                                style={{
                                  maxWidth: '95%',
                                  display: 'block',
                                  overflow: 'hidden',
                                  whiteSpace: 'nowrap',
                                  textOverflow: 'ellipsis',
                                }}
                              />
                            )}
                          </div>
                        </Option>
                      ))
                  )}
                />
                {isOpen && isLoading && (
                  <div className={styles['spinner']}>
                    <Spinner />
                  </div>
                )}
                {isOpen &&
                  !isLoading &&
                  (itemsInput?.length === 0 || !itemsInput) && (
                    <div className={styles['empyt']}>
                      <Text
                        type="ui-tiny-content"
                        color="text-300"
                        children={t('dropDown.empty')}
                      />
                    </div>
                  )}
                {success ? (
                  <Icon
                    className={styles['Icon']}
                    icon="check"
                    size="M"
                    color="status-success-base"
                  />
                ) : null}
                {rightIcon?.icon && !success ? (
                  <Icon
                    className={styles['Icon']}
                    icon={rightIcon.icon}
                    size="M"
                    color="text-50"
                  />
                ) : null}
                {filterSearchItem ? (
                  <FilterSearch
                    filterSearchItem={filterSearchItem}
                    changeFilterSearchItemSelected={(search) => {
                      const auxFilterSearchItem = search?.filter(
                        (x) => x.checked
                      );
                      setFilterSearchItem(search);
                      onChangeFilterFunc(auxFilterSearchItem.map((x) => x.id));
                    }}
                    title={filterSearch?.title}
                  />
                ) : null}
                {!withoutSearchIcon && (
                  <Icon
                    className={styles['search']}
                    icon="search"
                    size="M"
                    color="text-400"
                  />
                )}
                {props.form &&
                props.form.errors &&
                LocalizaPropriedadePorPath(props.form.errors, name) ? (
                  <p className={styles['errorText']}>
                    {LocalizaPropriedadePorPath(props.form.errors, name)}
                  </p>
                ) : null}
              </div>
            </>
          );
        }}
      </Field>

      {withAvatarPopover?.titleContent?.name &&
        items?.every((x) => x.avatar) && (
          <AvatarPopOver
            onOpenChange={withAvatarPopover.onOpenChange}
            setIsPopoverVisible={setIsPopoverVisible}
            setIsOpen={setIsOpen}
            isOpen={isOpen}
            title={
              <TitleAvatarPopover
                link={withAvatarPopover.link}
                avatar={{
                  name: withAvatarPopover?.titleContent?.name,
                  lastName: withAvatarPopover.titleContent?.lastName,
                }}
                subTitle={{
                  code: withAvatarPopover.titleContent?.code,
                  typeUser: withAvatarPopover.titleContent?.typeUser,
                }}
              />
            }
            content={
              <ContentAvatarPopover
                content={
                  withAvatarPopover.content.forClient
                    ? {
                        loading: withAvatarPopover.isLoading,
                        forClient: {
                          firstLine:
                            withAvatarPopover.content?.forClient?.firstLine,
                          secondLine: {
                            date: withAvatarPopover.content?.forClient
                              ?.secondLine?.date,
                            age: withAvatarPopover.content?.forClient
                              ?.secondLine?.age,
                          },
                          thirdLine: {
                            icon: withAvatarPopover.content?.forClient
                              ?.thirdLine?.icon,
                            content:
                              withAvatarPopover.content?.forClient?.thirdLine
                                ?.content,
                          },
                        },
                      }
                    : {
                        loading: withAvatarPopover.isLoading,

                        forPrescriber: {
                          firstLine:
                            withAvatarPopover.content?.forPrescriber?.firstLine,
                          secondLine: {
                            specialties:
                              withAvatarPopover.content?.forPrescriber
                                ?.secondLine?.specialties,
                          },
                          thirdLine: {
                            icon: withAvatarPopover.content?.forPrescriber
                              ?.thirdLine?.icon,
                            content:
                              withAvatarPopover.content?.forPrescriber
                                ?.thirdLine?.content,
                          },
                        },
                      }
                }
              />
            }
          />
        )}
    </div>
  );
};

interface IFilterSearch {
  filterSearchItem: IFilterSearchItems[];
  changeFilterSearchItemSelected: (search: IFilterSearchItems[]) => void;
  title?: string;
}
const FilterSearch: FC<IFilterSearch> = ({
  filterSearchItem,
  changeFilterSearchItemSelected,
  title,
}) => {
  const [open, setOpen] = useState(false);

  return (
    <PopOver
      visible={open}
      onOpenChange={() => {
        !open && changeFilterSearchItemSelected(filterSearchItem);
        setOpen(!open);
      }}
      placement="bottom"
      title={
        <Text
          type="small-text"
          className={styles['filter-title']}
          color="text-300"
          children={title}
        />
      }
      content={
        <div className={styles['filter-container']}>
          {filterSearchItem.map((x, index) => (
            <Divflex
              className={styles['filter-content']}
              onClick={() => {
                let tempFilterSearchItem = filterSearchItem;
                if (!filterSearchItem) return;
                tempFilterSearchItem[index].checked =
                  !tempFilterSearchItem[index].checked;
                changeFilterSearchItemSelected(tempFilterSearchItem);
                setOpen(false);
                setTimeout(() => setOpen(true));
              }}
              key={x.id}
            >
              <Checkbox checked={x.checked} />
              <Text
                type="ui-tiny-content"
                color="text-50"
                children={x.label}
                style={{ marginLeft: '8px' }}
              />
            </Divflex>
          ))}
        </div>
      }
      trigger="click"
    >
      <Icon
        className={styles['Icon']}
        icon="multimidia-equalizer"
        size="M"
        color="text-400"
      />
    </PopOver>
  );
};
