import { useCallback } from 'react';
import { disableTechnicalInformationLink } from '../util/functions';
import { IUseFunctions } from './interfaces';

export const useEditFunctions = ({ 
  data,
  editPermissions,
  messageTooltipPermissions,
  setVisibleModal 
}: IUseFunctions) => {
  const disabledDropdownLinks = disableTechnicalInformationLink(
    data?.classeProdutoId
  );

  const defineEditPermission = useCallback((tab: string) => {
    if (tab === 'product-messages') {
      tab = 'messageDetail';
    }
    
    return editPermissions[tab as keyof typeof editPermissions];
  }, [editPermissions]);

  const defineMessageTooltipPermission = useCallback((tab: string) => {
    if (tab === 'product-messages') {
      tab = 'messageDetail';
    }

    return messageTooltipPermissions[
      tab as keyof typeof messageTooltipPermissions
    ];
  }, [messageTooltipPermissions]);

  const onChange = useCallback(
    (value: boolean) => setVisibleModal(value), []
  );

  return {
    disabledDropdownLinks,
    defineEditPermission,
    defineMessageTooltipPermission,
    onChange
  };
};