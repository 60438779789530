import { useMemo } from 'react';
import { useFormikContext } from 'formik';
import { currencyFormater } from '@/Utils/Currency';
import { IUseMemorizations } from './interfaces';
import { useTranslation } from 'react-i18next';

export const useUnitaryDiscountMemorizations = ({
  index,
}: IUseMemorizations) => {
  const form = useFormikContext<any>();
  const { t } = useTranslation();

  const discountCurrencyTooltipValue = useMemo(() => {
    return (
      (form?.values?.pedidoCompraItens?.[index]?.precoUnitario *
        form?.values?.pedidoCompraItens?.[index]?.valorDescontoUnitario) /
      100
    );
  }, [index, form]);

  const discountPorcentageTooltipValue = useMemo(() => {
    return (
      (form?.values?.pedidoCompraItens?.[index]?.valorDescontoUnitario * 100) /
      form?.values?.pedidoCompraItens?.[index]?.precoUnitario
    );
  }, [index, form]);

  const title =
    form?.values?.pedidoCompraItens?.[index]?.precoUnitario <
      form?.values?.pedidoCompraItens?.[index]?.valorDescontoUnitario ||
    form?.values?.pedidoCompraItens?.[index]?.percentualDesconto > 100
      ? t('purchasing.purchaseOrder.create.errors.discountCannotBeGreater')
      : form?.values?.pedidoCompraItens?.[index]?.tipoDescontoProdutoId === 1
      ? currencyFormater(discountCurrencyTooltipValue)
      : `${discountPorcentageTooltipValue}%`;

  const label =
    index === 0
      ? t('purchasing.purchaseOrder.create.productsCardField.unitaryDiscount')
      : undefined;

  const dropdownLeft = {
    name: `pedidoCompraItens.${index}.tipoDescontoProdutoId`,
  };

  const onChange = (value: string) => {
    if (value.includes('-')) {
      const numberValue = Number(value.replace('-', '').replace(',', '.'));
      form.setFieldValue(
        `pedidoCompraItens.${index}.valorDescontoUnitario`,
        numberValue
      );
    }
  };

  return {
    title,
    label,
    dropdownLeft,
    onChange,
  };
};
