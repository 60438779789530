import { Button } from '@/Components';
import { UseMutationResult } from '@tanstack/react-query';
import { FC } from 'react';
import { history } from '@/Store';
import { useTranslation } from 'react-i18next';

import styles from './FooterButton.module.scss';

interface IFooterButton {
  createUser: UseMutationResult;
}

export const FooterButton: FC<IFooterButton> = ({ createUser }) => {
  const { t } = useTranslation();
  return (
    <div className={styles['footer-button']}>
      <Button
        type="secondary"
        children={t('common.cancel')}
        onClick={() => history.push('/admin/user')}
      />
      <Button
        type="primary"
        children={
          createUser.isLoading ? t('common.creating') : t('common.create')
        }
        htmlType="submit"
        dataTestId='cadastrar'
        loading={createUser.isLoading}
      />
    </div>
  );
};
