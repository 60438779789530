import { Dropdown } from '@/Components';
import { Col, Row } from 'antd';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { TextInputNumber } from '@/Components/Inputs/TextInputNumber';
import DescriptionPharmaceuticalForm from './pharmaceuticalFormDescription';
import { PercentageInput } from '@/Components/Inputs/PercentageInput';
import { calculationTypeDropdown } from '../../../../Utils/utils';

export const RowDescription: FC = () => {
  const { t } = useTranslation();

  return (
    <Row gutter={[16, 0]}>
      <Col span={2}>
        <TextInputNumber
          name="ordem"
          placeHolder={t(
            'production.pharmaceuticalForm.create.orderPlaceholder'
          )}
          formaterNumberZeroMask={3}
          required
          label={t('production.pharmaceuticalForm.create.order')}
          withoutArrows
        />
      </Col>
      <Col span={11}>
        <DescriptionPharmaceuticalForm />
      </Col>
      <Col span={6}>
        <Dropdown
          name="tipoCalculo"
          items={calculationTypeDropdown?.map((values) => ({
            id: values.id,
            label: values.name,
          }))}
          placeHolder={t(
            'production.pharmaceuticalForm.create.calculationTypePlaceholder'
          )}
          required
          label={t('production.pharmaceuticalForm.create.calculationType')}
        />
      </Col>
      <Col span={5}>
        <PercentageInput
          name="percentualMinimoExcipiente"
          label={t('production.pharmaceuticalForm.create.excipientMinimum')}
          placeHolder={t(
            'production.pharmaceuticalForm.create.excipientMinimumPlaceholder'
          )}
          withoutMarginBottom
          required
        />
      </Col>
    </Row>
  );
};
