import { useMemo } from 'react';
import { IUseBodyMemorizations } from './interfaces';

export const useBodyMemorizations = ({
  clientTypeField,
}: IUseBodyMemorizations) => {
  const clientType = useMemo(
    () => clientTypeField.length > 0 && clientTypeField[0].value,
    [clientTypeField]
  );
  const createClientModalI18n = useMemo(
    () => 'callCenter.createService.createClientModal',
    []
  );

  return { clientType, createClientModalI18n };
};
