import { FC } from "react";
import { NumberInput, Tooltip } from '@/Components';
import { useTranslation } from 'react-i18next';
import { useFormikContext } from 'formik';
import { IConcentrationWaterFactor } from "./interfaces";
import { useDilutionFunctions } from "./Hooks/useDilutionFunctions";

export const ConcentrationWaterFactor: FC<IConcentrationWaterFactor> = ({
  product,
  disabledForm
}) => {
  const { t } = useTranslation();
  const form: any = useFormikContext();

  const {
    onChange
  } = useDilutionFunctions();

  return (
    <Tooltip
      title={`${t(
        'products.create.originalConcentrationWaterFactorTooltip'
      )} ${
        product && product.materiaPrima
          ? product.materiaPrima?.fatorConcentracaoAgua
          : ''
      }`}
      showMe={
        product &&
        !disabledForm &&
        product.materiaPrima?.fatorConcentracaoAgua !==
          form?.values?.informacaoTecnica?.fatorConcentracaoAgua
      }
    >
      <NumberInput
        label={t('products.create.concentrationWaterFactor')}
        name="informacaoTecnica.fatorConcentracaoAgua"
        placeHolder="1"
        maxLength={6}
        withTooltip={{
          icon: 'question-mark',
          title: t('products.create.concentrationWaterFactorTooltip'),
        }}
        acceptOnlyNumbers
        disabled={disabledForm}
        withStatusIcon={!!product}
        onChange={onChange}
        withDecimalSeparator
      />
    </Tooltip>
  );
};