import { Row } from 'antd';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Card } from '@/Components';
import { BatchLocal } from './Components/BatchLocal';
import { InitialQuantity } from './Components/InitialQuantity';
import { IInitialBalanceCard } from './interfaces';

export const InitialBalanceCard: FC<IInitialBalanceCard> = ({
  typeClassProduct
}) => {
  const { t } = useTranslation();

  return (
    <Card
      title={t('supply.lot.create.titleInitialBalanceInformation')}
      withoutCollapse={true}
    >
      <Row gutter={[16, 0]}>
        <BatchLocal />
        <InitialQuantity typeClassProduct={typeClassProduct} />
      </Row>
    </Card>
  );
};
