import { PermissionWrapper } from '../../../../../../PermissionWrapper';
import { Skeleton } from '../../../../../../Skeleton';
import { Status } from '../../../Status';
import {
  CHANGE_USER_GROUP_STATUS,
  GET_USER_GROUP_TYPE,
} from '@/ControllerApiHook/UniqIds/Accountmanager/UserGroupKeys';
import i18n from '../../../../../../i18n';
import {
  useControllerMutateApiHook,
  useControllerQueryApiHook,
} from '@/ControllerApiHook/Controller';
import { IUserGroup } from '@/Data/Interfaces/response/User/IGetUserReponse';
import { UserGroupAPI } from '@/Data/API/Accountmanager/UserGroupApi';
import { useQueryClient } from '@tanstack/react-query';

export const DynamicEditUserGroupStatusBreadcrumb = ({ match }: any) => {
  const queryClient = useQueryClient();
  const { data: userGroup, isLoading: userGroupLoading } =
    useControllerQueryApiHook<IUserGroup>({
      uniqId: GET_USER_GROUP_TYPE,
      entityApi: UserGroupAPI.getUserGroup,
      externalIds: [match.params.externalId],
    });
  const { mutateAsync, isLoading: changeStatusLoading } =
    useControllerMutateApiHook<IUserGroup>({
      uniqId: CHANGE_USER_GROUP_STATUS,
      entityApi: UserGroupAPI.ChangeUserGroupStatus,
      options: {
        onSuccess: () => queryClient.invalidateQueries([GET_USER_GROUP_TYPE]),
      },
    });

  if (!userGroup) {
    return (
      <div style={{ width: 200 }}>
        <Skeleton />
      </div>
    );
  }

  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      {`${i18n.t('common.userGroup')} | ${userGroup?.nome}`}
      <PermissionWrapper
        permission={1013}
        tooltip="noPermission.administrative.userGroups.statusEdit"
      >
        <Status
          type={userGroup?.seAtivo ? 'success' : 'disabled'}
          ative={userGroup?.seAtivo || false}
          loading={userGroupLoading || changeStatusLoading}
          onClick={(value) =>
            mutateAsync({
              gruposId: [match.params.externalId],
              ativa: value,
            })
          }
        />
      </PermissionWrapper>
    </div>
  );
};

export const DynamicEditUserGroupNameBreadcrumb = ({ match }: any) => {
  const { data: userGroup } = useControllerQueryApiHook<IUserGroup>({
    uniqId: GET_USER_GROUP_TYPE,
    entityApi: UserGroupAPI.getUserGroup,
    externalIds: [match.params.externalId],
  });

  if (!userGroup) {
    return (
      <div style={{ width: 100 }}>
        <Skeleton />
      </div>
    );
  }
  return <span>{userGroup?.nome}</span>;
};
