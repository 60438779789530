import { useState } from "react";
import { v4 as uuidV4 } from 'uuid';

export const useCapsuleGranelCardStates = () => {
  
  const [productListController, setProductListController] = useState<string[]>([
    uuidV4(),
  ]);

  const [disabledProductList, setDisabledProductList] = useState<string[]>([]);

  return {
    productListController,
    setProductListController,
    disabledProductList,
    setDisabledProductList
  };
};