import { history } from '@/Store';
import {
  FETCH_LIST_CLIENT,
  DELETE_CLIENT,
} from '@/ControllerApiHook/UniqIds/People/ClientKeys';
import { useQueryClient } from '@tanstack/react-query';
import { IUseCallAPI } from './interfaces';
import { useControllerMutateApiHook } from '@/ControllerApiHook/Controller';
import { ClientAPI } from '@/Data/API/People/ClientApi';
import { i18n } from '@/Components';

export const useModalCallAPI = ({ changeVisibleModal }: IUseCallAPI) => {
  const queryClient = useQueryClient();

  const deleteClient: any = useControllerMutateApiHook({
    uniqId: DELETE_CLIENT,
    entityApi: ClientAPI.deleteClient,
    successToast: i18n.t('client.messages.success.deletedCustomer'),
    options: {
      onSuccess: () => {
        queryClient.invalidateQueries([FETCH_LIST_CLIENT]);

        history.push(`/sales/customers`);

        changeVisibleModal(false);
      },
    },
  });

  return {
    deleteClient,
  };
};
