import { useFormikContext } from 'formik';
import { useState } from 'react';
import { v4 as uuidV4 } from 'uuid';

interface IUseFormRows {
  key: string;
}

export const useFormRows = ({ key }: IUseFormRows) => {
  const [arrayControl, setArrayControl] = useState<string[]>([uuidV4()]);

  const form: any = useFormikContext();

  const addRow = (index: number) => {
    if (arrayControl[index + 1]) return;
    setArrayControl((prev) => [...prev, uuidV4()]);
  };

  const removeRow = (index: number) => {
    if (arrayControl.length === 1) return;
    setArrayControl((prev) => prev.filter((_, i) => i !== index));
    form.setFieldValue(
      key,
      form.values[key].filter((_: any, i: number) => i !== index)
    );
  };

  const pushRow = () => {
    setArrayControl((prev) => [...prev, uuidV4()]);
  };

  return { addRow, removeRow, arrayControl, pushRow };
};
