import { ICreateUserGroupRequest } from "@/Data/Interfaces/request/UserGroup/IUserGroupRequest";
import { IUseFunctions } from "./interfaces";

export const useCreateUserGroupsFunctions = ({
  createUserGroup
}: IUseFunctions) => {
  const onSubmit = async (values: ICreateUserGroupRequest) => {
    var body: ICreateUserGroupRequest = { ...values };
    if (values.usuarios) 
      body.usuarios = values.usuarios;

    body.seAtivo = true;

    await createUserGroup(body);
  };

  return {
    onSubmit
  };
};
