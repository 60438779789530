import { useCallback } from "react";
import { IUseFunctions } from "./interfaces";
import { NotificationActions } from '@/Store/Notification/Notification.actions';
import { useDispatch } from 'react-redux';

export const useAddUserGroupsFunctions = ({
  addUserInUserGroup,
  externalId,
  setVisible,
  setUsersSelected
}: IUseFunctions) => {
  const dispatch = useDispatch();
  
  const onSubmit = useCallback(
    (selectedUser?: string[]) => {
      if (!selectedUser || selectedUser.length <= 0) return;

      addUserInUserGroup.mutateAsync({
        usuariosExternalId: selectedUser,
        grupoExternalId: externalId,
      });
    },
    [addUserInUserGroup, externalId]
  );

  const onCancelClick = () => {
    setVisible(false);
    setUsersSelected([]);
    dispatch(NotificationActions.setCurrentFormIsDirty(false));
  };

  const onClose = () => {
    setVisible(false);
    setUsersSelected([]);
    dispatch(NotificationActions.setCurrentFormIsDirty(false));
  };

  return { 
    onSubmit,
    onCancelClick,
    onClose
  };
};
