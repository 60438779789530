import { ModalFuncProps } from 'antd';
import { FC } from 'react';
import { BodyModal } from '../../../../../Components/Modal';
import { Footer as ModalFooter } from '../../../../../Components/Modal/Components/Footer';
import {
  ICommonError,
  IGlobalModal,
} from '../../../../../Utils/ErrorCodeUtils/ErrorHandlingUtils';
import { Icon, Text } from '../../../../../Components';
import { useTranslation } from 'react-i18next';
import { ReactMarkdown } from 'react-markdown/lib/react-markdown';
import { Divflex } from '@/Layouts/DivWhithFlex';

const Content: FC<ICommonError> = ({ content }) => {
  const { t } = useTranslation();
  return (
    <>
      <BodyModal
        body={
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <Text type="ui-tiny-content">
              <ReactMarkdown>
                {t('production.laboratory.list.canNotDeleteModal.body', {
                  laboratorio: `**${content[0].nomeLaboratorio}**`,
                  empresa: `**${content[0].nomeFantasiaEmpresa}**`,
                })}
              </ReactMarkdown>
            </Text>

            <Text type="ui-tiny-content">
              {t('production.laboratory.list.canNotDeleteModal.bodyComent')}
            </Text>
            <div>
              <Divflex style={{ marginTop: 24 }}>
                <Icon
                  icon={'exclamation-upside-down'}
                  size={'SM'}
                  color={'text-400'}
                  style={{ padding: '18px 18px 0 8px' }}
                />
                <Text type="paragraph2" color={'text-400'}>
                  <ReactMarkdown>
                    {t(
                      'production.laboratory.list.canNotDeleteModal.bodyExclamation',
                      {}
                    )}
                  </ReactMarkdown>
                </Text>
              </Divflex>
            </div>
          </div>
        }
        style={{ padding: 20, maxWidth: 399 }}
      />
    </>
  );
};

const Title: FC<ICommonError> = ({ content }) => {
  const { t } = useTranslation();
  return (
    <>
      {t('production.laboratory.list.canNotDeleteModal.title', {
        laboratorio: content[0].nomeLaboratorio,
      })}
    </>
  );
};

const Footer: FC<{
  params: { nomeLaboratorio: string[] };
  instance: IGlobalModal;
}> = ({ instance }) => {
  const { t } = useTranslation();
  return (
    <ModalFooter
      onCancelClick={() => {
        instance.destroy();
      }}
      withoutOkButton={true}
      cancelButtonName={t('purchasing.purchaseOrder.modal.cannotDelete.ok')}
    />
  );
};

export const CannotDeleteModalDefaultLaboratory: (
  error: ICommonError,
  params: { nomeLaboratorio: string[] },
  instance: IGlobalModal
) => ModalFuncProps = (error, params, instance) => ({
  closable: true,
  icon: <></>,
  title: <Title {...error} />,
  content: <Content {...error} />,
  footer: <Footer params={params} instance={instance} />,
});
