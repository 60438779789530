import { useTranslation } from "react-i18next";
import { alternativeUnit1 } from '../../../utils';
import { IUseMemorization } from "./interfaces";

export const useAlternativeUnitMemorizations = ({
  unidadeAlternativaList
}: IUseMemorization) => {
  const { t } = useTranslation();
  const uiUnitDefault: number = 1;
  const grUnitDefault: number = 7;

  const label = [
    {
      children: t('supply.lot.create.alternativeUnitOne'),
    }
  ];

  const dropdownLeft = {
    name: 'loteInformacaoTecnica.loteUnidadeAlternativa1.unidadeAlternativaId',
    defaultValue: uiUnitDefault,
    options:
      unidadeAlternativaList?.data.map((x) => ({
        id: x.id,
        content: x.unidadeAbreviacao,
      })) || [],
  };
  
  const dropdownRight = {
    name: 'loteInformacaoTecnica.loteUnidadeAlternativa1.unidadeAlternativaConversaoId',
    defaultValue: grUnitDefault,
    options:
      alternativeUnit1?.map((x) => ({
        id: x.id,
        content: x.description,
      })) || [],
  };

  return {
    label,
    dropdownLeft,
    dropdownRight
  };
};