import { useState } from 'react';
import { v4 as uuidV4 } from 'uuid';

export const useProductCardStates = () => {
  const [productRowAmount, setProductRowAmount] = useState([uuidV4()]);

  return {
    productRowAmount,
    setProductRowAmount
  };
};