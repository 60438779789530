import { useFormikContext } from 'formik';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import { useControllerMutateApiHook } from '@/ControllerApiHook/Controller';
import { PersonAPI } from '@/Data/API/People/PersonApi';
import { VALIDATION_CNPJ_SUPPLIER } from '@/ControllerApiHook/UniqIds/People/ SupplierKeys';
import { cnpj } from 'cpf-cnpj-validator';

export const useInputCnpjFunctions = () => {
  const { t } = useTranslation();
  const form: any = useFormikContext();

  const onCnpjValidationError = useCallback(() => {
    form.setErrors({
      ...form.errors,
      cnpj: t('purchasing.provider.create.errors.cnpjAlreadyUsed'),
    });
  }, [form, t]);

  const { mutateAsync, error } = useControllerMutateApiHook({
    uniqId: VALIDATION_CNPJ_SUPPLIER,
    entityApi: PersonAPI.cnpjValidation,
    options: {
      onError: onCnpjValidationError,
    },
  });

  const fetchCnpjValidation = useCallback(
    _.debounce(async (cnpj: string) => {
      if (cnpj.trim() !== '') {
        try {
          await mutateAsync({
            cnpj: cnpj,
          });
          form.setErrors({
            ...form.errors,
            cnpj: undefined,
          });
        } catch {}
      } else {
        form.setErrors({
          ...form.errors,
          cnpj: undefined,
        });
      }
    }, 1000),
    [mutateAsync, form.errors, form.values.descricao]
  );

  const onChange = useCallback(
    (desc: string) => {
      form.setErrors({
        ...form.errors,
        cnpj: undefined,
      });
      const formattedCnpj = desc.replace(/\D/g, '');
      if (!cnpj.isValid(desc)) return;

      fetchCnpjValidation(formattedCnpj);
    },
    [fetchCnpjValidation]
  );

  return {
    onChange,
    error,
  };
};
