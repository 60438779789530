import DateUtils from '@/Utils/DateUtils';
import StorageUtils from '@/Utils/StorageUtils';
import { formatPhoneNumber } from 'react-phone-number-input';
import { IUseMemorizations } from './interfaces';

export const useEditOrderMemorizations = ({
  purchaseOrder,
}: IUseMemorizations) => {
  const preferences = StorageUtils.getPreference();
  const pedidoCompraItensInicial: any = {};

  if (
    purchaseOrder &&
    purchaseOrder.pedidoCompraItens &&
    purchaseOrder.pedidoCompraItens.length
  ) {
    purchaseOrder.pedidoCompraItens.forEach((item) => {
      pedidoCompraItensInicial[`item_${item.pedidoCompraItemExternalId}`] = {
        id: item.produtoExternalId,
        label: item.descricao,
      };
    });
  }

  const initialValues = purchaseOrder
    ? {
        ...purchaseOrder,
        fornecedorContatoPrincipal:
          purchaseOrder.tipoContatoId === 2
            ? formatPhoneNumber(purchaseOrder.fornecedorContatoPrincipal)
            : purchaseOrder.fornecedorContatoPrincipal,
        previsaoEntrega: DateUtils.format(
          purchaseOrder?.previsaoEntrega,
          preferences?.padraoData?.replace('mm', 'MM') || 'dd/MM/yyyy'
        ),
        tipoDesconto: purchaseOrder.tipoDescontoId,
        fornecedorItemInicial: {
          id: purchaseOrder.fornecedorExternalId,
          label: purchaseOrder.fornecedorContatoPrincipal,
        },
        ...pedidoCompraItensInicial,
      }
    : {};
  return {
    initialValues,
  };
};
