import React, { useEffect, FC, useMemo } from 'react';
import { Row, Col } from 'antd';
import { useFormikContext } from 'formik';
import { Card, Dropdown, Form, i18n } from '../../../Components';
import { TextInput } from '../../../Components/Inputs/TextInput';
import { InputWithSearch } from '../../../Components/Inputs/InputWithSearch';
import {
  CONGLOMERATE_IS_ALREADY_REGISTRED,
  LIST_CONGLOMERATE_ADMIN_FOR_DROPDOWN_TYPE,
} from '../../../ControllerApiHook/UniqIds/Accountmanager/ConglomerateKeys';
import { IGetCompanyResponse } from '../../../Data/Interfaces/response/Company/ICompanyResponse';
import { RadioGroup } from '../../../Components/StateInputs/RadioButton/RadioGroup';
import { RadioButton } from '../../../Components/StateInputs/RadioButton';
import {
  useControllerMutateApiHook,
  useControllerQueryListApiHook,
} from '@/ControllerApiHook/Controller';
import { ConglomerateAPI } from '@/Data/API/Accountmanager/ConglomerateApi';
import { IListConglomerateForDropdownData } from '@/Data/Interfaces/response/Conglomerate/IConglomerateResponde';

import styles from './CompanyGroupCard.module.scss';
import _ from 'lodash';
import { ConnectedFocusError } from '@/Components/ConnectedFocusError';

export enum CompanyGroupTypeEnum {
  NEW = 1,
  EXISTENT = 2,
}

interface ICompanyGroups {
  editForm?: boolean;
  onChange?: (value: boolean) => void;
  radioCompany?: boolean;
  companyData?: IGetCompanyResponse;
}

export const CompanyGroupsCard: FC<ICompanyGroups> = ({
  editForm,
  onChange,
  radioCompany,
  companyData,
}) => {
  const form = useFormikContext();
  const { data: listConglomerate, fetchNewPage } =
    useControllerQueryListApiHook({
      uniqId: LIST_CONGLOMERATE_ADMIN_FOR_DROPDOWN_TYPE,
      entityApi: ConglomerateAPI.listConglomerateForDropdown,
      autoIncrement: true,
    });
  const { mutateAsync } = useControllerMutateApiHook({
    uniqId: CONGLOMERATE_IS_ALREADY_REGISTRED,
    entityApi: ConglomerateAPI.isCompanyAlreadyRegistred,
    withoutToastError: true,
    options: {
      onError: () =>
        form.setFieldError(
          'nomeConglomerado',
          i18n.t(
            'adminBootis.createCompany.companyGroupCard.errors.alreadyRegistredConglomerateName'
          )
        ),
      onSuccess: () => form.setFieldError('nomeConglomerado', undefined),
    },
  });

  const getConglomerateNameAlreadyRegistred = useMemo(
    () =>
      _.debounce(async (value: string) => {
        try {
          return await mutateAsync(value);
        } catch {
          return undefined;
        }
      }, 500),
    [mutateAsync]
  );

  return (
    <Card
      title={i18n.t('adminBootis.createCompany.companyGroup')}
      withoutCollapse={editForm}
    >
      {!editForm && (
        <RadioGroup
          className={styles['radio-button']}
          valueChecked={radioCompany ? 1 : 2}
        >
          <RadioButton
            value={1}
            label={i18n.t(
              'adminBootis.createCompany.companyGroupCard.newCompany'
            )}
            onChange={() => {
              onChange && onChange(true);
            }}
            disabled={editForm}
          />
          <RadioButton
            value={2}
            label={i18n.t(
              'adminBootis.createCompany.companyGroupCard.existingCompany'
            )}
            onChange={() => onChange && onChange(false)}
            disabled={editForm}
          />
        </RadioGroup>
      )}
      {!radioCompany || editForm ? (
        <Row gutter={[20, 0]}>
          <Col flex={editForm ? '22%' : '34%'}>
            <InputWithSearch
              name="conglomeradoExternalId"
              selectedItemFromForm="conglomeradoExternalIdInitialValue"
              withoutMarginBottom
              label={i18n.t(
                'adminBootis.createCompany.companyGroupCard.companyGroup'
              )}
              placeHolder={i18n.t(
                'adminBootis.createCompany.companyGroupCard.companyGroupPlaceholder'
              )}
              items={listConglomerate?.data?.map(
                (x: IListConglomerateForDropdownData) => ({
                  id: x.externalId,
                  label: x.nome,
                })
              )}
              disabled={editForm}
              required
              onScrollEnd={fetchNewPage}
            />
          </Col>

          <Col flex="10.5%">
            {companyData && (
              <Dropdown
                name="tipoId"
                items={[
                  {
                    id: 1,
                    label: i18n.t(
                      'adminBootis.createCompany.companyGroupCard.headquarters'
                    ),
                  },
                  {
                    id: 2,
                    label: i18n.t(
                      'adminBootis.createCompany.companyGroupCard.branch'
                    ),
                  },
                ]}
                label={i18n.t(
                  'adminBootis.createCompany.companyGroupCard.roleInGroup'
                )}
                disabled={editForm}
              />
            )}
          </Col>
        </Row>
      ) : (
        <Row gutter={[8, 0]}>
          <Col flex="34%">
            <TextInput
              name="nomeConglomerado"
              withoutMarginBottom
              label={i18n.t(
                'adminBootis.createCompany.companyGroupCard.groupName'
              )}
              placeHolder={i18n.t(
                'adminBootis.createCompany.companyGroupCard.groupNamePlaceholder'
              )}
              disabled={editForm}
              required
              dataTestId="company-group-name"
              onChange={getConglomerateNameAlreadyRegistred}
            />
          </Col>
        </Row>
      )}
    </Card>
  );
};

interface ICompanyGroupsCard {
  onChangeRadioCompanyGroup: (value: boolean) => void;
  radioCompanyGroup: boolean;
  company?: IGetCompanyResponse;
}

export const CompanyGroupsCardWrapper: React.FC<ICompanyGroupsCard> = ({
  company,
  onChangeRadioCompanyGroup,
  radioCompanyGroup,
}) => {
  const form = useFormikContext();

  useEffect(() => {
    radioCompanyGroup &&
      form?.setFieldValue('conglomeradoExternalId', undefined);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [company, radioCompanyGroup]);

  return company ? (
    <CompanyGroupsCardEditWrapper
      onChangeRadioCompanyGroup={onChangeRadioCompanyGroup}
      radioCompanyGroup={radioCompanyGroup}
      company={company}
    />
  ) : (
    <CompanyGroupsCard
      onChange={onChangeRadioCompanyGroup}
      radioCompany={radioCompanyGroup}
    />
  );
};

interface ICompanyGroupsCardEditWrapper {
  onChangeRadioCompanyGroup: (value: boolean) => void;
  radioCompanyGroup: boolean;
  company: IGetCompanyResponse;
}

export const CompanyGroupsCardEditWrapper: React.FC<
  ICompanyGroupsCardEditWrapper
> = ({ company, onChangeRadioCompanyGroup, radioCompanyGroup }) => (
  <Form
    initialValues={{
      conglomeradoExternalId: company.conglomeradoExternalId,
      conglomeradoExternalIdInitialValue: {
        id: company.conglomeradoExternalId,
        label: company.nomeConglomerado,
      },
    }}
  >
    <ConnectedFocusError />
    <CompanyGroupsCard
      onChange={onChangeRadioCompanyGroup}
      radioCompany={radioCompanyGroup}
      companyData={company}
      editForm
    />
  </Form>
);
