import { useCallback } from 'react';
import { FETCH_DILUTED_PRODUCT } from '@/ControllerApiHook/UniqIds/Supply/ProductKeys';
import { history } from '@/Store';
import { useQueryClient } from '@tanstack/react-query';
import { IUseFunctions } from './interfaces';
import { handleModalError } from '@/Utils/ErrorCodeUtils/ErrorHandlingUtils';
import { App } from 'antd';

export const useCreateDilutedProductFunctions = ({
  createDilutedProduct,
  productExternalId,
}: IUseFunctions) => {
  const defaultUnitId = 6;
  const queryClient = useQueryClient();
  const app = App.useApp();

  const handleSubmit = useCallback(
    async (values: any) => {
      const result = await createDilutedProduct.mutateAsync(
        {
          ...values,
          unidadeMedidaId: values.UnidadeMedidaId ?? defaultUnitId,
          produtoExternalId: productExternalId,
        },
        {
          onError: handleModalError(app),
        }
      );
      if (result) {
        queryClient.invalidateQueries([FETCH_DILUTED_PRODUCT]);
        history.push(`/inventory/products/${productExternalId}/diluted`);
      }
    },
    [createDilutedProduct]
  );

  const disableSubmitButton = (values: any) => {
    const miniMalDosageWrong =
      values.dosagemMaxima < values.dosagemMinima ||
      !values.dosagemMaxima ||
      !values.dosagemMinima;
    const formPharmaceuticalId = !!values.formaFarmaceuticaExternalId;
    const anyDosage = values.seQualquerDosagem;
    const anyPharmaceuticalForm = !!values.seTodasFormasFarmaceuticas;

    const firstCondition = miniMalDosageWrong && !anyDosage;
    const secondCondition = !formPharmaceuticalId && !anyPharmaceuticalForm;
    const thirdCondition = !values.diluicao;

    return firstCondition || secondCondition || thirdCondition;
  };

  const onClickCancelButton = () => {
    history.push(`/inventory/products/${productExternalId}/diluted`);
  };

  return {
    handleSubmit,
    disableSubmitButton,
    onClickCancelButton,
  };
};
