import { CONVERT_UNIT_OF_MEASURE_MONETARY_VALUE } from '@/ControllerApiHook/UniqIds/Supply/SupplyKeys';
import { useControllerMutateApiHook } from '@/ControllerApiHook/Controller';
import { UnitMeasurementAPI } from '@/Data/API/Supply/UnitMeasurementApi';

export const useUnitaryValueCallAPI = () => {
  const { mutateAsync: convertUnitOfMeasureMonetaryValue }: any =
    useControllerMutateApiHook({
      uniqId: CONVERT_UNIT_OF_MEASURE_MONETARY_VALUE,
      entityApi: UnitMeasurementAPI.convertUnitOfMeasureMonetaryValue,
    });

  return {
    convertUnitOfMeasureMonetaryValue,
  };
};
