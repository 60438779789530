import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { InputWithSearch, Tooltip } from '@/Components';
import { Col } from 'antd';
import { IPharmaceuticalForm } from './interfaces';
import { usePharmaceuticalFormCallAPI } from './Hooks/usePharmaceuticalFormCallAPI';
import { usePharmaceuticalFormMemorizations } from './Hooks/usePharmaceuticalFormMemorizations';
import { usePharmaceuticalFormFunctions } from './Hooks/usePharmaceuticalFormFunctions';

export const PharmaceuticalForm: FC<IPharmaceuticalForm> = ({
  props,
  dilutedProductData,
  disabledForm,
}) => {
  const { t } = useTranslation();

  const {
    listPharmaceuticalForm,
    isPharmaceuticalFormLoading,
    fetchNewFarmaceuticalFormPage,
    refetchSearchPharmaceuticalFormPage,
  } = usePharmaceuticalFormCallAPI();

  const { items } = usePharmaceuticalFormMemorizations({
    listPharmaceuticalForm,
  });

  const { onSearch } = usePharmaceuticalFormFunctions({
    refetchSearchPharmaceuticalFormPage,
  });

  return (
    <Col span={8}>
      <Tooltip
        title={`${t('products.diluted.originalPharmaceuticalForm')}${
          dilutedProductData?.formaFarmaceuticaDescricao
        }`}
        showMe={
          dilutedProductData &&
          dilutedProductData.formaFarmaceuticaExternalId !==
            props?.values?.formaFarmaceuticaExternalId
        }
        targetOffset={[80, -10]}
      >
        <InputWithSearch
          required={!disabledForm}
          name="formaFarmaceuticaExternalId"
          label={t('products.diluted.pharmaceuticalForm')}
          withStatusIcon
          placeHolder={t('products.diluted.searchForPharmaceuticalForm')}
          items={items}
          disabled={disabledForm || props.values.seTodasFormasFarmaceuticas}
          isLoading={isPharmaceuticalFormLoading}
          onScrollEnd={fetchNewFarmaceuticalFormPage}
          onSearch={onSearch}
          selectedItemFromForm="pharmaceuticalFormInitialValue"
        />
      </Tooltip>
    </Col>
  );
};
