import { useMemo } from 'react';
import { IUseMemorizations } from './interfaces';

export const useCompanyFormMemorizations = ({
  externalId,
}: IUseMemorizations) => {
  const isEditForm = useMemo(() => !!externalId, [externalId]);

  return { isEditForm };
};
