import React from 'react';
import { useTranslation } from 'react-i18next';
import { Card, Form } from '../../../../../Components';
import { CreateSchema } from './CreateForm.schema';
import _ from 'lodash';
import { useCreateCallAPI } from './Hooks/useCreateCallAPI';
import { useCreateFunctions } from './Hooks/useCreateFunctions';
import { DescriptionRow } from './Components/Rows/DescriptionRow';
import { FooterButtons } from './Components/FooterButtons';
import { ConnectedFocusError } from '@/Components/ConnectedFocusError';

const CreateCapsuleColors: React.FC = () => {
  const { t } = useTranslation();

  const { createCapsuleColors, isLoadingCreateCapsuleColors } =
    useCreateCallAPI();

  const { onSubmit } = useCreateFunctions({
    createCapsuleColors,
  });

  return (
    <Form onSubmit={onSubmit} schema={CreateSchema}>
      <>
        <Card title={t('common.details')} withoutCollapse={true}>
          <DescriptionRow />
        </Card>
        <FooterButtons
          isLoadingCreateCapsuleColors={isLoadingCreateCapsuleColors}
        />
        <ConnectedFocusError />
      </>
    </Form>
  );
};

export default CreateCapsuleColors;
