import { useControllerMutateApiHook } from '@/ControllerApiHook/Controller';
import { ProductAPI } from '@/Data/API/Supply/Product';
import {
  CREATE_GROUP,
  EDIT_GROUP
} from '@/ControllerApiHook/UniqIds/Supply/ProductKeys';

export const useFormGroupModalCallAPI = () => {
  const createGroup: any = useControllerMutateApiHook({
    uniqId: CREATE_GROUP,
    entityApi: ProductAPI.createGroup
  });

  const editGroup: any = useControllerMutateApiHook({
    uniqId: EDIT_GROUP,
    entityApi: ProductAPI.editProductGroup
  });

  return {
    createGroup,
    editGroup
  };
};