import * as yup from 'yup';
import { i18n } from '../../../../../Components';

export const CreateSchema = yup.object().shape({
  descricao: yup
    .string()
    .required(i18n.t('supply.supplyPlaces.errors.requiredName')),
  empresaId: yup
    .string()
    .required(i18n.t('supply.supplyPlaces.errors.requiredCompany')),
});
