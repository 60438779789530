import { cnpj, cpf } from 'cpf-cnpj-validator';
import { useCallback } from 'react';
import { IUseBodyFunctions } from './interfaces';
import { useTranslation } from 'react-i18next';

export const useBodyFunctions = ({ form }: IUseBodyFunctions) => {
  const { t } = useTranslation();
  const validateCpf = useCallback(
    (value: string) => {
      const cpfNotMask = value.replace(/[^\d]/g, '');

      if (cpfNotMask.length === 11) {
        !cpf.isValid(value)
          ? form.setErrors({
              ...form.errors,
              cpf: t(
                'adminBootis.createCompany.responsibleUserCard.errors.invalidCpf'
              ),
            })
          : form.setErrors({
              ...form.errors,
              cpf: undefined,
            });
      }
    },
    [form, t]
  );
  const validateCnpj = useCallback(
    (value: string) => {
      const cnpjNotMask = value.replace(/[^\d]/g, '');

      if (cnpjNotMask.length === 14) {
        !cnpj.isValid(value)
          ? form.setErrors({
              ...form.errors,
              cnpj: t(
                'adminBootis.createCompany.companyDataCard.errors.invalidCNPJ'
              ),
            })
          : form.setErrors({
              ...form.errors,
              cnpj: undefined,
            });
      }
    },
    [form, t]
  );

  return { validateCnpj, validateCpf };
};
