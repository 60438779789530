import i18next from 'i18next';
import { Languages } from '../Components/i18n';

export const ChangeLanguage = (language?: Languages) => {
  switch (language) {
    case Languages.ptBR:
      i18next.changeLanguage(Languages.ptBR);
      break;
    case Languages.enUS:
      i18next.changeLanguage(Languages.enUS);
      break;
  }
};
