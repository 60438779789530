import { FC } from "react";
import { Col } from 'antd';
import { TextInput } from '@/Components';
import { useTranslation } from "react-i18next";

export const RegisterNumber: FC = () => {
  const { t } = useTranslation();
  
  return (
    <Col flex="24%">
      <TextInput
        name="codigoRegistro"
        placeHolder={`${t('common.exampleAbbreviation')}: 14.975`}
        label={t('prescribers.createPage.info.registerNumber')}
        required
      />
    </Col>
  );
};