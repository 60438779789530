import { useCallback } from 'react';
import { IUseFunctions } from './interfaces';

export const useRegisterTypeFunctions = ({
  refetchStateList,
}: IUseFunctions) => {
  const onSearch = useCallback(
    (search?: string) => {
      refetchStateList({
        search: search,
      });
    },
    [refetchStateList]
  );

  return { onSearch };
};
