import { FC } from "react";
import { Tooltip } from '@/Components';
import { Col } from 'antd';
import { PercentageInput } from '@/Components/Inputs/PercentageInput';
import { useTranslation } from "react-i18next";
import { ICorrectionPercentage } from "./interfaces";

export const CorrectionPercentage: FC<ICorrectionPercentage> = ({
  synonymData,
  props,
  disabledForm
}) => {
  const { t } = useTranslation();

  return (
    <Col span={4}>
      <Tooltip
        title={`${t(
          'products.synonym.originalCorrectionPercentage'
        )}${synonymData?.percentualCorrecao}%`}
        showMe={
          synonymData &&
          synonymData.percentualCorrecao !==
            props?.values?.percentualCorrecao
        }
      >
        <PercentageInput
          name="percentualCorrecao"
          label={t('products.synonym.correctionPercentage')}
          placeHolder="Ex: 5%"
          disabled={disabledForm}
          withStatusIcon
        />
      </Tooltip>
    </Col>
  );
};