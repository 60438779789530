import { Col, Row } from 'antd';
import { FC, useEffect } from 'react';
import { Dropdown, InputWithSearch, TextArea, TextInput } from '../..';
import { useTranslation } from 'react-i18next';
import { TextInputWithMask } from '../../Inputs/TextInputWithMask';
import { FETCH_CONTACT_TYPE } from '../../../ControllerApiHook/UniqIds/People/PersonKeys';
import { GET_LIST_CITY } from '../../../ControllerApiHook/UniqIds/Locality/CityKeys';
import { GET_LIST_STATE } from '../../../ControllerApiHook/UniqIds/Locality/StateKeys';
import { FormikValues, useFormikContext } from 'formik';
import { TextInputPhoneMask } from '../../Inputs/TextInputPhoneMask';
import { IGetCEPResponse } from '../../../Data/Interfaces/response/CEP/ICEPResponse';
import _ from 'lodash';
import {
  useControllerQueryApiHook,
  useControllerQueryListApiHook,
} from '@/ControllerApiHook/Controller';
import { IListDocumentData } from '@/Data/Interfaces/response/Document/IDocumentResponse';
import { PersonAPI } from '@/Data/API/People/PersonApi';
import { IContactTypeResponse } from '@/Data/Interfaces/response/Person/ContactTypeResponse';
import { CityAPI } from '@/Data/API/Locality/CityApi';
import { IGetCityResponse } from '@/Data/Interfaces/response/Locality/ICityResponse';
import { StateAPI } from '@/Data/API/Locality/StateApi';
import { IGetStateResponse } from '@/Data/Interfaces/response/Locality/IStateResponse';
import { GET_CEP } from '@/ControllerApiHook/UniqIds/Locality/CEPKeys';
import { CEPAPI } from '@/Data/API/CEP/CEPApi';
import { IFChildren } from '@/Components/Form';

export const ContactModal: FC = () => {
  const { t } = useTranslation();
  const form = useFormikContext<FormikValues>();

  const { data: contactTypes } = useControllerQueryListApiHook({
    uniqId: FETCH_CONTACT_TYPE,
    entityApi: PersonAPI.listContactType,
  });

  return (
    <div style={{ padding: '20px' }}>
      <Row gutter={[16, 0]} justify="start">
        <Col span={8}>
          <Dropdown
            name="tipoContatoId"
            items={contactTypes?.data?.map((x: IContactTypeResponse) => ({
              id: x.id,
              label: x.nome,
              leftIcon: x.icon,
            }))}
            withStatusIcon
            label={t('contact.contactType')}
            required
          />
        </Col>
        <Col span={12}>
          {form.values.tipoContatoId === 2 ||
          form.values.tipoContatoId === 3 ||
          form.values.tipoContatoId === 4 ? (
            <TextInputPhoneMask
              name="identificacao"
              label={t('contact.contact')}
              withStatusIcon
              required
            />
          ) : (
            <TextInput
              name="identificacao"
              label={t('contact.contact')}
              required
              withStatusIcon
            />
          )}
        </Col>
      </Row>
      <Row gutter={[16, 0]} justify="start">
        <Col span={24}>
          <TextArea
            name="observacao"
            label={t('contact.observation')}
            placeholder={t('contact.observationPlaceholder')}
            maxLength={125}
            withStatusIcon
            heightTextArea={100}
          />
        </Col>
      </Row>
    </div>
  );
};

interface IDocumentModal {
  props?: IFChildren;
  documentList?: any;
  fetchNewPageDocument: () => void;
}

export const DocumentModal: FC<IDocumentModal> = ({
  props,
  documentList,
  fetchNewPageDocument,
}) => {
  const { t } = useTranslation();

  return (
    <div style={{ padding: '20px' }}>
      <Row gutter={[16, 0]} justify="start" style={{ marginBottom: 20 }}>
        <Col span={24}>
          <Dropdown
            name="tipoDocumentoId"
            items={documentList?.data?.map((x: IListDocumentData) => ({
              id: x.id,
              label: x.nome,
              disabledItem: props?.values.documentos.some(
                (value: any) => value.tipoDocumentoId === x.id
              ),
            }))}
            placeHolder={t('document.modal.documentPlaceholder')}
            label={t('document.documentType')}
            required
            withoutMarginBottom
            onScrollEnd={fetchNewPageDocument}
          />
        </Col>
      </Row>
      <Row gutter={[16, 0]} justify="start">
        <Col span={24}>
          <TextInput
            name="identificacao"
            label={t('document.document')}
            placeHolder={t('document.document')}
            required
          />
        </Col>
      </Row>
      <Row gutter={[16, 0]} justify="start">
        <Col span={24}>
          <TextArea
            name="observacao"
            label={t('document.observation')}
            maxLength={125}
            placeholder={t('document.modal.observationPlaceholder')}
            heightTextArea={100}
          />
        </Col>
      </Row>
    </div>
  );
};

export const AddressModal: FC = () => {
  const { t } = useTranslation();
  const form = useFormikContext<FormikValues>();

  const {
    data: listCity,
    fetchNewPage: fetchNewCityPage,
    refetch: refetchCityList,
    isLoading: cityListLoading,
  } = useControllerQueryListApiHook({
    uniqId: GET_LIST_CITY,
    entityApi: CityAPI.getListCity,
    pagination: {
      pageIndex: 0,
      pageSize: 50,
      sorter: { column: 'descricao', direction: 'ASC' },
    },
  });

  const {
    data: listState,
    fetchNewPage: fetchNewStatePage,
    refetch: refetchStateList,
    isLoading: stateListLoading,
  } = useControllerQueryListApiHook({
    uniqId: GET_LIST_STATE,
    entityApi: StateAPI.getListState,
    pagination: {
      pageIndex: 0,
      pageSize: 50,
      sorter: { column: 'descricao', direction: 'ASC' },
    },
  });

  const { 
    isLoading: cepLoading 
  } = useControllerQueryApiHook<IGetCEPResponse>({
    uniqId: GET_CEP,
    entityApi: CEPAPI.getCEP,
    externalIds: _.isNil(form.values.cep)
      ? []
      : [form.values.cep],
    onSuccess: (x: IGetCEPResponse) => {
      if (x) {
        form.setFieldError('cep', undefined);
        form.setFieldValue('logradouro', x.logradouro);
        form.setFieldValue('bairro', x.bairro);
        form.setFieldValue('cidadeExternalId', x.cidadeExternalId);
        form.setFieldValue('cidade', x.cidade);
        form.setFieldValue('estadoExternalId', x.estadoExternalId);
        form.setFieldValue('cep', x.cep);
        form.setFieldValue('paisExternalId', x.paisExternalId);
        form.setFieldValue('cityLabel', {
          id: x.cidadeExternalId,
          label: x.cidade,
        });
        form.setFieldValue('stateLabel', {
          id: x.estadoExternalId,
          label: x.estado,
        });
      }

      refetchCityList({
        pageIndex: 0,
        pageSize: 50,
        filter: [
          {
            filterName: 'estadoExternalId',
            value: x?.estadoExternalId,
          },
        ],
        sorter: { column: 'descricao', direction: 'ASC' },
      });
    },
    options: { refetchOnWindowFocus: false },
  });

  useEffect(() => {
    refetchCityList({
      pageIndex: 0,
      pageSize: 50,
      filter: [
        {
          filterName: 'estadoExternalId',
          value: form?.values?.estadoExternalId,
        },
      ],
      sorter: { column: 'descricao', direction: 'ASC' },
    });
  }, [form?.values?.estadoExternalId, refetchCityList]);

  return (
    <div style={{ padding: '20px' }}>
      <Row gutter={[16, 0]} justify="start">
        <Col flex="26%">
          <TextInput
            name="descricao"
            label={t('address.modal.description')}
            placeHolder={t('address.modal.descriptionPlaceholder')}
            required
            withStatusIcon
          />
        </Col>
        <Col flex="26%">
          <TextInputWithMask
            name="cep"
            label={t('address.modal.CEP')}
            mask="cepMask"
            placeHolder={'00000-000'}
            loading={form?.values?.cep?.length > 0 ? cepLoading : false}
            withStatusIcon
            onChange={(x) => {
              if(x.length >= 1 && /\d/.test(x)) {
                form.setFieldError('cep', t('address.modal.invalidCEP'));
              } else {
                form.setFieldError('cep', undefined);
              }
            }}
          />
        </Col>
      </Row>

      <Row gutter={[16, 0]}>
        <Col flex="36%">
          <InputWithSearch
            name="estadoExternalId"
            label={t('address.modal.state')}
            placeHolder={t('address.modal.statePlaceholder')}
            items={listState?.data?.map((x: IGetStateResponse) => ({
              id: x.externalId,
              label: x.descricao,
            }))}
            onScrollEnd={fetchNewStatePage}
            selectedItemFromForm="stateLabel"
            isLoading={stateListLoading}
            onSearch={(search) => {
              refetchStateList({
                pageIndex: 0,
                pageSize: 10,
                search: search,
                sorter: { column: 'descricao', direction: 'DESC' },
              });
            }}
            withStatusIcon
            withoutSearchIcon
            onChange={(x) => {
              refetchCityList({
                pageIndex: 0,
                pageSize: 50,
                filter: [
                  {
                    filterName: 'estadoExternalId',
                    value: x,
                  },
                ],
                sorter: { column: 'descricao', direction: 'ASC' },
              });
              form.setFieldValue('cidadeExternalId', undefined);
            }}
          />
        </Col>
        <Col flex="36%">
          <InputWithSearch
            name="cidadeExternalId"
            label={t('address.modal.city')}
            placeHolder={t('address.modal.cityPlaceholder')}
            items={listCity?.data?.map((x: IGetCityResponse) => ({
              id: x.externalId,
              label: x.descricao,
            }))}
            selectedItemFromForm="cityLabel"
            onScrollEnd={fetchNewCityPage}
            isLoading={cityListLoading}
            withStatusIcon
            onSearch={(search) => {
              refetchCityList({
                pageIndex: 0,
                pageSize: 50,
                search: search,
                sorter: { column: 'descricao', direction: 'DESC' },
                filter: [
                  {
                    filterName: 'estadoExternalId',
                    value: form.values.estadoExternalId,
                  },
                ],
              });
            }}
            withoutSearchIcon
            disabled={!form.values.estadoExternalId}
          />
        </Col>
        <Col flex="27%">
          <TextInput
            name="bairro"
            label={t('address.modal.district')}
            placeHolder={t('address.modal.districtPlaceholder')}
            withStatusIcon
          />
        </Col>
      </Row>
      <Row gutter={[16, 0]}>
        <Col flex="60%">
          <TextInput
            name="logradouro"
            withoutMarginBottom
            label={t('address.modal.street')}
            placeHolder={t('address.modal.streetPlaceholder')}
            withStatusIcon
          />
        </Col>
        <Col flex="20%">
          <TextInput
            name="numero"
            withoutMarginBottom
            label={t('address.modal.streetNumber')}
            placeHolder={t('address.modal.streetNumberPlaceholder')}
            withStatusIcon
          />
        </Col>
        <Col flex="20%">
          <TextInput
            name="complemento"
            withoutMarginBottom
            label={t('address.modal.complement')}
            placeHolder={t('address.modal.complementPlaceholder')}
            withStatusIcon
          />
        </Col>
      </Row>
    </div>
  );
};
