import { useState } from 'react';

export const useEditStates = () => {
  const [selectedOptionsStock, setSelectedOptionsStock] = useState<any>(0);

  const [disabled, setDisabled] = useState(true);
  const [visibleModal, setVisibleModal] = useState(false);

  return {
    selectedOptionsStock,
    setSelectedOptionsStock,
    disabled,
    setDisabled,
    visibleModal,
    setVisibleModal,
  };
};
