import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Modal } from '@/Components/Modal';
import { IDeletePrescriberModal } from './interfaces';
import { useDeleteModalCallAPI } from './Hooks/useDeleteModalCallAPI';
import { useDeleteModalMemorizations } from './Hooks/useDeleteModalMemorizations';
import { useDeleteModalFunctions } from './Hooks/useDeleteModalFunctions';

export const DeletePrescriberModal: FC<IDeletePrescriberModal> = ({
  prescriberName,
  prescriberExternalId,
  visibleModal,
  changeVisibleModal,
}) => {
  const { t } = useTranslation();

  const { deletePrescriber } = useDeleteModalCallAPI({ changeVisibleModal });

  const {
    modalBody
  } = useDeleteModalMemorizations({ prescriberName })

  const {
    onOkClick,
    onCancelClick,
    onClose
  } = useDeleteModalFunctions({
    prescriberExternalId,
    deletePrescriber,
    changeVisibleModal
  });

  return (
    <Modal
      title={`${t('common.delete')} ${t(
        'prescribers.editPage.deleteModal.prescriber'
      )}?`}
      body={modalBody}
      okButtonColor="status-danger-base"
      visible={visibleModal}
      onOkClick={onOkClick}
      okButtonName={t('common.delete')}
      onCancelClick={onCancelClick}
      onClose={onClose}
      loading={deletePrescriber.isLoading}
    />
  );
};
