export enum PermissionEnum {
  Administrativo_Usuarios_VerLista = 1001,
  Administrativo_Usuarios_VerDetalhes = 1002,
  Administrativo_Usuarios_Cadastrar = 1003,
  Administrativo_Usuarios_EditarDadosPessoais = 1004,
  Administrativo_Usuarios_AlterarStatus = 1005,
  Administrativo_Usuarios_EditarGrupo = 1006,
  Administrativo_Usuarios_EditarPermissao = 1007,
  Administrativo_Usuarios_Excluir = 1008,
  Administrativo_GrupoUsuarios_VerLista = 1009,
  Administrativo_GrupoUsuarios_VerDetalhes = 1010,
  Administrativo_GrupoUsuarios_Criar = 1011,
  Administrativo_GrupoUsuarios_EditarDetalhes = 1012,
  Administrativo_GrupoUsuarios_AlterarStatus = 1013,
  Administrativo_GrupoUsuarios_EditarPermissao = 1014,
  Administrativo_GrupoUsuarios_Excluir = 1015,
  Administrativo_Empresa_VerLista = 1016,
  Administrativo_Empresa_VerDetalhes = 1017,
  Administrativo_Empresa_EditarDados = 1018,
  Administrativo_Empresa_EditarEndereco = 1019,
  Administrativo_Empresa_EditarUsuarioResponsavel = 1020,
  Administrativo_Empresa_AlterarStatus = 1021,

  Estoque_Lotes_VerLista = 2001,
  Estoque_Lotes_VerDetalhes = 2002,
  Estoque_Lotes_Cadastrar = 2003,
  Estoque_Lotes_EditarInformacoes = 2004,
  Estoque_Lotes_AlterarStatus = 2005,
  Estoque_Lotes_Excluir = 2006,

  Estoque_LocaisEstoque_VerLista = 2007,
  Estoque_LocaisEstoque_VerDetalhess = 2008,
  Estoque_LocaisEstoque_Cadastrar = 2009,
  Estoque_LocaisEstoque_EditarDetalhes = 2010,
  Estoque_LocaisEstoque_AlterarStatus = 2011,
  Estoque_LocaisEstoque_Excluir = 2012,
  Estoque_Transferencias_VerLista = 2013,
  Estoque_Transferencias_VerDetalhes = 2014,
  Estoque_Transferencias_Cadastrar = 2015,
  Estoque_Perdas_VerLista = 2016,
  Estoque_Perdas_VerDetalhes = 2017,
  Estoque_Perdas_Cadastrar = 2018,
  Estoque_Perdas_EditarDetalhes = 2019,
  Estoque_AjusteSaldo_VerLista = 2020,
  Estoque_AjusteSaldo_VerDetalhes = 2021,
  Estoque_AjusteSaldo_Cadastrar = 2022,
  Estoque_MensagemProduto_VerLista = 3018,
  Estoque_MensagemProduto_VerDetalhes = 3019,
  Estoque_MensagemProduto_Cadastrar = 3020,
  Estoque_MensagemProduto_EditarDetalhes = 3021,
  Estoque_MensagemProduto_RemoverProduto = 3022,
  Estoque_MensagemProduto_Excluir = 3023,
  Estoque_ProdutosDiluido_Visualizar = 3029,
  Estoque_ProdutosDiluido_Cadastrar = 3030,
  Estoque_ProdutosDiluido_EditarDetalhes = 3031,
  Estoque_ProdutosDiluido_Excluir = 3032,
  Estoque_ProdutosIncompativel_Visualizar = 3033,
  Estoque_ProdutosIncompativel_Cadastrar = 3034,
  Estoque_ProdutosIncompativel_EditarDetalhes = 3035,
  Estoque_ProdutosIncompativel_Excluir = 3036,
  Estoque_ProdutosSinonimo_Visualizar = 3037,
  Estoque_ProdutosSinonimo_Cadastrar = 3038,
  Estoque_ProdutosSinonimo_EditarDetalhes = 3039,
  Estoque_ProdutosSinonimo_Excluir = 3040,

  Estoque_ProdutosMensagem_Visualizar = 3041,
  Estoque_ProdutosMensagem_Cadastrar = 3042,
  Estoque_ProdutosMensagem_EditarDetalhes = 3043,
  Estoque_ProdutosMensagem_Excluir = 3044,

  Produtos_Produtos_VerLista = 3001,
  Produtos_Produtos_VerDetalhes = 3002,
  Produtos_Produtos_Cadastrar = 3003,
  Produtos_Produtos_EditarInformacoes = 3004,
  Produtos_Produtos_EditarInformacoesFinanceiras = 3005,
  Produtos_Produtos_AlterarStatus = 3006,
  Produtos_Produtos_Excluir = 3007,
  Produtos_Grupos_VerLista = 3008,
  Produtos_Grupos_VerDetalhes = 3009,
  Produtos_Grupos_Cadastrar = 3010,
  Produtos_Grupos_EditarDetalhes = 3011,
  Produtos_Grupos_Excluir = 3012,
  Produtos_SubGrupos_VerLista = 3013,
  Produtos_SubGrupos_VerDetalhes = 3014,
  Produtos_SubGrupos_Cadastrar = 3015,
  Produtos_SubGrupos_EditarDetalhes = 3016,
  Produtos_SubGrupos_Excluir = 3017,

  Compras_Fornecedores_VerLista = 4001,
  Compras_Fornecedores_VerDetalhes = 4002,
  Compras_Fornecedores_Cadastrar = 4003,
  Compras_Fornecedores_EditarDetalhes = 4004,
  Compras_Fornecedores_EditarContatos = 4005,
  Compras_Fornecedores_EditarEndereco = 4006,
  Compras_Fornecedores_EditarDocumento = 4007,
  Compras_Fornecedores_AlterarStatus = 4008,
  Compras_Fornecedores_Excluir = 4009,

  Compras_PedidoCompra_VerLista = 4010,
  Compras_PedidoCompra_VerDetalhes = 4011,
  Compras_PedidoCompra_Cadastrar = 4012,
  Compras_PedidoCompra_EditarDetalhes = 4013,
  Compras_PedidoCompra_Aprovar = 4014,
  Compras_PedidoCompra_Reprovar = 4015,
  Compras_PedidoCompra_Cancelar = 4016,
  Compras_PedidoCompra_ConfirmarComFornecedor = 4017,
  Compras_PedidoCompra_EstornarLiberado = 4018,
  Compras_PedidoCompra_EstornarConfirmadoComFornecedor = 4019,
  Compras_PedidoCompra_Excluir = 4020,

  Compras_NotaFiscalEntrada_VerLista = 4021,
  Compras_NotaFiscalEntrada_VerDetalhes = 4022,
  Compras_NotaFiscalEntrada_Cadastrar = 4023,
  Compras_NotaFiscalEntrada_EditarDetalhes = 4024,
  Compras_NotaFiscalEntrada_AlterarStatus = 4025,
  Compras_NotaFiscalEntrada_Excluir = 4026,
  Compras_NotaFiscalEntrada_LancarLotes = 4027,

  Vendas_EspecialidadePrescritor_VerLista = 5001,
  Vendas_EspecialidadePrescritor_VerDetalhes = 5002,
  Vendas_EspecialidadePrescritor_Cadastrar = 5003,
  Vendas_EspecialidadePrescritor_EditarDetalhes = 5004,
  Vendas_EspecialidadePrescritor_AlterarStatus = 5005,
  Vendas_EspecialidadePrescritor_Excluir = 5006,
  Vendas_Clientes_VerLista = 5007,
  Vendas_Clientes_VerDetalhes = 5008,
  Vendas_Clientes_Cadastrar = 5009,
  Vendas_Clientes_EditarDetalhes = 5010,
  Vendas_Clientes_EditarContatos = 5011,
  Vendas_Clientes_EditarEndereco = 5012,
  Vendas_Clientes_EditarDocumento = 5013,
  Vendas_Clientes_AlterarStatus = 5014,
  Vendas_Clientes_Excluir = 5015,
  Vendas_Prescritores_VerLista = 5016,
  Vendas_Prescritores_VerDetalhes = 5017,
  Vendas_Prescritores_Cadastrar = 5018,
  Vendas_Prescritores_EditarDetalhes = 5019,
  Vendas_Prescritores_EditarContatos = 5020,
  Vendas_Prescritores_EditarEnderecos = 5021,
  Vendas_Prescritores_EditarDocumentos = 5022,
  Vendas_Prescritores_AlterarStatus = 5023,
  Vendas_Prescritores_Excluir = 5024,
  Vendas_Atendimentos_VerLista = 5025,
  Vendas_Atendimentos_VerDetalhes = 5026,
  Vendas_Atendimentos_Cadastrar = 5027,
  Vendas_PedidosVenda_VerLista = 5028,
  Vendas_PedidosVenda_VerDetalhes = 5029,
  Vendas_PedidosVenda_Cadastrar = 5030,
  Vendas_PedidosVenda_EditarDetalhes = 5031,
  Vendas_PedidosVenda_Aprovar = 5032,
  Vendas_PedidosVenda_Reprovar = 5033,
  Vendas_PedidosVenda_Cancelar = 5034,
  Vendas_PedidosVenda_Estornar = 5035,

  Producao_ClassificacaoEmbalagem_VerLista = 6001,
  Producao_ClassificacaoEmbalagem_VerDetalhes = 6002,
  Producao_ClassificacaoEmbalagem_Cadastrar = 6003,
  Producao_ClassificacaoEmbalagem_EditarDetalhes = 6004,
  Producao_ClassificacaoEmbalagem_AlterarStatus = 6005,
  Producao_ClassificacaoEmbalagem_Excluir = 6006,
  Producao_CapsulaCor_VerLista = 6007,
  Producao_CapsulaCor_VerDetalhes = 6008,
  Producao_CapsulaCor_Cadastrar = 6009,
  Producao_CapsulaCor_EditarDetalhes = 6010,
  Producao_CapsulaCor_Excluir = 6011,
  Producao_FormaFarmaceutica_VerLista = 6012,
  Producao_FormaFarmaceutica_VerDetalhes = 6013,
  Producao_FormaFarmaceutica_Cadastrar = 6014,
  Producao_FormaFarmaceutica_EditarDetalhes = 6015,
  Producao_FormaFarmaceutica_AlterarStatus = 6016,
  Producao_FormaFarmaceutica_Excluir = 6017,
  Producao_FormulaPadrao_VerLista = 6018,
  Producao_FormulaPadrao_VerDetalhes = 6019,
  Producao_FormulaPadrao_Cadastrar = 6020,
  Producao_FormulaPadrao_EditarDetalhes = 6021,
  Producao_FormulaPadrao_Excluir = 6022,
  Producao_Laboratorio_VerLista = 6023,
  Producao_Laboratorio_VerDetalhes = 6024,
  Producao_Laboratorio_Cadastrar = 6025,
  Producao_Laboratorio_EditarDetalhes = 6026,
  Producao_Laboratorio_AlterarStatus = 6027,
  Producao_Laboratorio_Excluir = 6028,
  Producao_Posologia_VerLista = 6029,
  Producao_Posologia_VerDetalhes = 6030,
  Producao_Posologia_Cadastrar = 6031,
  Producao_Posologia_EditarDetalhes = 6032,
  Producao_Posologia_AlterarStatus = 6033,
  Producao_Posologia_Excluir = 6034,
}
