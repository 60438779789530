import { useCallback, useMemo } from 'react';
import { useFormikContext } from 'formik';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { IUseFunctions } from './interfaces';
import { IProductGroupListData } from '@/Data/Interfaces/response/Product/IProductRespose';

export const useInputProductFunctions = ({
  mutateAsync,
  refetchSearchProductPage,
  productList,
  setClassificationProductId,
  setUnidadeMedidaId,
  produtoExternalId,
}: IUseFunctions) => {
  const { t } = useTranslation();
  const form: any = useFormikContext();

  const onStandardFormulaError = useCallback(() => {
    form?.setErrors({
      ...form?.errors,
      produtoExternalId: t(
        'production.standardFormula.details.errors.errorStandardFormulaNameExist'
      ),
    });
  }, [form, t]);

  const fetchStandardFormulaName = useMemo(
    () =>
      _.debounce(async (value: string) => {
        try {
          return await mutateAsync?.(value);
        } catch {
          return undefined;
        }
      }, 500),
    [mutateAsync]
  );

  const onSearch = (search: string | undefined) => {
    refetchSearchProductPage({
      search: search,
    });
  };

  const onChange = useCallback(
    (product: string) => {
      const currentProduct = productList?.data?.find(
        (x: IProductGroupListData) => x.externalId === product
      );

      if (currentProduct?.externalId) {
        setClassificationProductId?.(currentProduct.classeProdutoId);
        setUnidadeMedidaId?.(currentProduct.unidadeMedidaId);
        if (produtoExternalId !== currentProduct.externalId) {
          fetchStandardFormulaName(currentProduct.externalId);
        }
      }
    },
    [
      productList,
      setClassificationProductId,
      setUnidadeMedidaId,
      fetchStandardFormulaName,
    ]
  );

  return {
    onStandardFormulaError,
    fetchStandardFormulaName,
    onSearch,
    onChange,
  };
};
