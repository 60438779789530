import { FETCH_LIST_RECORD_TYPE } from '@/ControllerApiHook/UniqIds/People/RecordKeys';
import { GET_LIST_STATE } from '@/ControllerApiHook/UniqIds/Locality/StateKeys';
import { useControllerQueryListApiHook } from '@/ControllerApiHook/Controller';
import { RecordAPI } from '@/Data/API/People/RecordApi';
import { StateAPI } from '@/Data/API/Locality/StateApi';

export const useInfoBodyModalCallAPI = () => {
  const recordType = useControllerQueryListApiHook({
    uniqId: FETCH_LIST_RECORD_TYPE,
    entityApi: RecordAPI.listRecordType,
    autoIncrement: true,
  });

  const listState = useControllerQueryListApiHook({
    uniqId: GET_LIST_STATE,
    entityApi: StateAPI.getListState,
    pagination: {
      pageSize: 50,
      sorter: { column: 'descricao', direction: 'ASC' },
    },
  });

  return {
    recordType,
    listState,
  };
};
