import { useQueryClient } from '@tanstack/react-query';
import {
  CHANGE_RESPONSIBLE_USER_TYPE,
  LIST_COMPANY_TYPE,
} from '@/ControllerApiHook/UniqIds/Accountmanager/CompanyKeys';
import {
  useControllerMutateApiHook,
  useControllerQueryListApiHook,
} from '@/ControllerApiHook/Controller';
import { CompanyAPI } from '@/Data/API/Accountmanager/CompanyApi';

export const useListCallAPI = () => {
  const queryClient = useQueryClient();

  const companysList = useControllerQueryListApiHook({
    uniqId: LIST_COMPANY_TYPE,
    entityApi: CompanyAPI.listCompany,
  });

  const changeResponsibilytiUser = useControllerMutateApiHook({
    uniqId: CHANGE_RESPONSIBLE_USER_TYPE,
    entityApi: CompanyAPI.changeResponsibilytiUser,
    options: {
      onSuccess: () => queryClient.invalidateQueries([LIST_COMPANY_TYPE]),
    },
  });

  return {
    companysList,
    changeResponsibilytiUser,
  };
};
