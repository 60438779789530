import { useControllerQueryListApiHook } from '@/ControllerApiHook/Controller';
import { FETCH_GET_INVENTORY_BY_STOCK_LOCATION } from '@/ControllerApiHook/UniqIds/Supply/SupplyKeys';
import { StorageLocationApi } from '@/Data/API/Supply/StorageLocationApi';
import { useParams } from 'react-router';

export const useListCallAPI = () => {
  const { externalId } = useParams<{ externalId: string }>();

  const {
    data: inventoryBySupplyPlaceList,
    isLoading: isLoadingInventoryBySupplyPlaceList,
    refetch,
  } = useControllerQueryListApiHook({
    uniqId: FETCH_GET_INVENTORY_BY_STOCK_LOCATION,
    entityApi: StorageLocationApi.getInventoryByStockLocationList,
    pagination: {
      sorter: { column: 'descricao', direction: 'ASC' },
      filter: [{ filterName: 'localEstoqueExternalId', value: externalId }],
    },
    autoIncrement: true,
  });

  return {
    inventoryBySupplyPlaceList,
    isLoadingInventoryBySupplyPlaceList,
    refetch,
  };
};
