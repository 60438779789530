import { NewPassword } from '@/Pages/Auth/ForgotPassword/NewPassword';
import { ForgotPasswordRequest } from '@/Pages/Auth/ForgotPassword/Request';
import { ForgotPasswordSuccess } from '@/Pages/Auth/ForgotPassword/Success';
import { Login } from '@/Pages/Auth/Login';
import React, { FC } from 'react';

import { Route, Switch, Redirect } from 'react-router-dom';

const UnauthenticatedApp: FC = () => {
  return (
    <React.Suspense fallback={<span>Loading...</span>}>
      <Switch>
        <Route path="/login" component={Login} />

        <Route
          path="/forgot-password/success"
          component={ForgotPasswordSuccess}
        />
        <Route path="/forgot-password" component={ForgotPasswordRequest} />
        <Route
          path="/new-password/:mail/:externalId/:code"
          component={NewPassword}
        />

        <Redirect to="/login" />
      </Switch>
    </React.Suspense>
  );
};

export default UnauthenticatedApp;
