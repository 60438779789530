import React from 'react';
import { Button, Form, TextInput, Link, Checkbox } from '../../../Components';
import { LoginLayout } from '../../../Components/Layouts';
import { LoginSchema } from './Login.schema';
import { useTranslation } from 'react-i18next';
import { TextInputPassword } from '../../../Components/Inputs/TextInputPassword';
import { useLoginFunctions } from './Hooks/useLoginFunctions';
import { useLoginCallAPI } from './Hooks/useLoginCallAPI';

import styles from './Login.module.scss';

export const Login = () => {
  const { t } = useTranslation();

  const { authLogin } = useLoginCallAPI();

  const { onSubmit } = useLoginFunctions({ authLogin });

  return (
    <LoginLayout title={t('login.title')}>
      <Form
        schema={LoginSchema}
        onSubmit={onSubmit}
        className={styles['Form']}
        noLeaveWithoutLeaving
      >
        <TextInput
          name="email"
          label={t('login.email')}
          leftIcon="email"
          dataTestId="email"
          placeHolder={t('login.emailPlaceholder')}
        />
        <TextInputPassword
          name="password"
          type="password"
          label={t('login.pass')}
          leftIcon="lock"
          dataTestId="password"
          placeHolder={t('login.passPlaceholder')}
        />
        <div className={styles['divRememberLogin']}>
          <Checkbox label={t('login.rememberMe')} name="rememberMe" />
          <Link
            type="ui-tiny-bold"
            color="primary-base"
            to="/forgot-password"
            className={styles['forgot-login-link']}
            children={t('login.forgotPassword')}
          />
        </div>
        <Button
          type="primary"
          htmlType="submit"
          fullWidth
          dataTestId="login-submit"
          loading={authLogin.isLoading}
          children={t('login.action')}
        />
      </Form>
    </LoginLayout>
  );
};
