import { Col } from "antd";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { IProductName } from "./interfaces";
import { Tooltip } from "@/Components";
import { useFormikContext } from "formik";
import { InputProductName } from "../InputProductName";

export const ProductName: FC<IProductName> = ({
  product,
  disabledForm
}) => {
  const { t } = useTranslation();
  const form: any = useFormikContext();

  return (
    <Col span={product ? 10 : 12}>
      <Tooltip
        title={`${t('products.toolTips.products.nameOfProduct')} ${
          product?.descricao
        }`}
        showMe={
          product &&
          !disabledForm &&
          product.descricao !== form?.values?.descricao
        }
      >
        <InputProductName
          disabledForm={disabledForm}
          product={!!product}
        />
      </Tooltip>
    </Col>
  );
};