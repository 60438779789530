import { InputLabel } from '@/Components/Inputs/Label';
import { useTranslation } from 'react-i18next';
import styles from './styles.module.scss';
import { useFormikContext } from 'formik';
import RemovePurchaseOrderModal from './RemovePurchaseOrderModal';
import { useState } from 'react';
import { useRemovePurchaseOrder } from '../hooks/useRemovePurchaseOrder';
import { Tooltip } from '@/Components';
import { ListTooltip } from './ListTooltip';

interface IPlusInputProps {
  showEdit: boolean;
  disabled?: boolean;
  setVisiblePurchaseOrderTable: any;
  allDataPurchaseOrder: any[];
}

export const PlusInput = ({
  showEdit,
  disabled,
  setVisiblePurchaseOrderTable,
  allDataPurchaseOrder,
}: IPlusInputProps) => {
  const [visibleRemovePurchaseOrderModal, setVisibleRemovePurchaseOrderModal] =
    useState(false);
  const form: any = useFormikContext();
  const purchaseOrders = form.values.pedidosExternalId;
  const { t } = useTranslation();
  const lengthToScrollStart = 5;

  const { removePurchaseOrder } = useRemovePurchaseOrder();

  return (
    <>
      <div>
        <InputLabel
          withStatusIcon={showEdit ? 'show' : undefined}
          label={t('purchasing.invoice.create.purchaseOrder')}
        />
        <div
          className={`${styles.mainContainer} ${
            disabled ? styles.disabledStyle : ''
          }`}
        >
          <div
            className={`${
              purchaseOrders.length < lengthToScrollStart
                ? styles.purchaseOrderListDefault
                : styles.purchaseOrderList
            }`}
          >
            {purchaseOrders.map((e: any) => {
              return (
                <Tooltip
                  title={<ListTooltip purchaseOrder={e} />}
                  showMe={true}
                  key={e.sequenciaGroupTenant}
                >
                  <div className={styles.purchaseOrderContainer}>
                    <div className={styles.purchaseNumber}>
                      {e.sequenciaGroupTenant}
                    </div>
                    <div
                      onClick={() => setVisibleRemovePurchaseOrderModal(true)}
                      className={styles.removePurchase}
                    >
                      x
                    </div>
                    <RemovePurchaseOrderModal
                      visible={visibleRemovePurchaseOrderModal}
                      number={e.sequenciaGroupTenant}
                      onCancelClick={() =>
                        setVisibleRemovePurchaseOrderModal(false)
                      }
                      removePurchaseOrder={removePurchaseOrder}
                    />
                  </div>
                </Tooltip>
              );
            })}
            {!purchaseOrders.length && (
              <p className={styles.placeHolder}>
                {t('purchasing.invoice.create.placeHolder.noPurchaseOrder')}
              </p>
            )}
          </div>
          <div className={styles.addPurchaseOrderContainer}>
            <div
              onClick={() => {
                if (allDataPurchaseOrder.length) {
                  setVisiblePurchaseOrderTable(true);
                }
              }}
              className={styles.addPurchaseOrderButton}
            >
              +
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const PurchaseElement = () => {};
