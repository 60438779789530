import InvoiceCreate from '@/Pages/Sectors/Order/Invoice/Create';
import InvoiceCreateRefactor from '@/Pages/Sectors/Order/Invoice/CreateRefact';
import { InvoiceDetails } from '@/Pages/Sectors/Order/Invoice/Details';
import DraftInvoiceEdit from '@/Pages/Sectors/Order/Invoice/Draft';
import DraftInvoice from '@/Pages/Sectors/Order/Invoice/DraftRefact';
import { InvoiceEdit } from '@/Pages/Sectors/Order/Invoice/Edit';
import EditInvoice from '@/Pages/Sectors/Order/Invoice/EditRefact';
import InvoiceList from '@/Pages/Sectors/Order/Invoice/List';
import { RegisterLots } from '@/Pages/Sectors/Order/Invoice/RegisterLots';
import { Route, Switch } from 'react-router-dom';

export const InvoiceRoutes = () => (
  <Switch>
    <Route path="/order/invoice/:externalId/edit" component={EditInvoice} />
    <Route
      path="/order/invoice/:externalId/register-lots"
      component={RegisterLots}
    />
    <Route
      path="/order/invoice/create/:externalId/draft"
      component={DraftInvoice}
    />
    <Route path="/order/invoice/:externalId/:tabs" component={InvoiceDetails} />

    <Route path="/order/invoice/create" component={InvoiceCreateRefactor} />
    <Route path="/order/invoice" component={InvoiceList} />
  </Switch>
);
