import { useMemo } from "react";
import { PermissionEnum } from '@/Data/Interfaces/model/permissionEnum';

export const useDetailsMemorizations = () => {
  const entityContactPermission = useMemo(() => (
    {
      permission: PermissionEnum.Vendas_Prescritores_EditarContatos,
      tooltip: 'noPermission.sale.prescriber.editContacts',
    }
  ), []);

  const entityAddressPermission = useMemo(() => (
    {
      permission: PermissionEnum.Vendas_Prescritores_EditarEnderecos,
      tooltip: 'noPermission.sale.prescriber.editAddress',
    }
  ), []);

  const entityDocumentPermission = useMemo(() => (
    {
      permission: PermissionEnum.Vendas_Prescritores_EditarDocumentos,
      tooltip: 'noPermission.sale.prescriber.editDocuments',
    }
  ), []);

  return {
    entityContactPermission,
    entityAddressPermission,
    entityDocumentPermission
  };
};