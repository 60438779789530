import { Dropdown, InputWithSearch } from '@/Components';
import { Col, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import { useCarrierList } from './hooks/useCarrierList';
import { useListFreightType } from '@/Hooks/HooksServices/Purchase/Purchase/useListFreightType';

export const RowFour: React.FC = () => {
  const { t } = useTranslation();

  const { carrier } = useCarrierList();
  const freightType = useListFreightType({
    fieldName: 'tipoFreteId',
    enabled: true,
  });

  return (
    <Row gutter={[16, 0]}>
      <Col span={6}>
        <InputWithSearch
          {...carrier}
          placeHolder={t(
            'purchasing.invoice.create.placeHolder.searchForCarrier'
          )}
          label={t('purchasing.invoice.create.carrierCode')}
        />
      </Col>
      <Col span={6}>
        <Dropdown
          {...freightType}
          label={t('purchasing.invoice.create.frightType')}
          required
        />
      </Col>
    </Row>
  );
};
