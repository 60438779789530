import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Modal } from '@/Components/Modal';
import { IInfoModal } from '../interfaces';
import { useModalCallAPI } from './Hooks/useModalCallAPI';
import { useModalMemorizations } from './Hooks/useModalMemorizatrions';
import { useModalFunctions } from './Hooks/useModalFunctions';
import { InfoModalBody } from './ModalBody';
import { ModalSchema } from './Modal.schema';

export const InfoModal: FC<IInfoModal> = ({
  visible,
  changeVisibleModal,
  client,
}) => {
  const { t } = useTranslation();

  const { editInfo } = useModalCallAPI({ changeVisibleModal });

  const { initialValuesInfoModal } = useModalMemorizations({
    client,
  });

  const { onOkClickInfoModal } = useModalFunctions({ editInfo });

  return (
    <Modal
      body={<InfoModalBody client={client} />}
      minWidth={800}
      title={t('client.editPage.editDetails')}
      visible={visible}
      withForm
      htmlType="submit"
      onCancelClick={() => changeVisibleModal(true)}
      onClose={() => changeVisibleModal(true)}
      initialValues={initialValuesInfoModal}
      onOkClickForm={onOkClickInfoModal}
      loading={editInfo.isLoading}
      schema={ModalSchema(client.tipoPessoa)}
    />
  );
};
