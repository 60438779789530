import { useMemo } from 'react';
import { IUseMemorizations } from './interfaces';
import { useTranslation } from 'react-i18next';

export const useCreateMemorizations = ({
  productBalanceList,
  storageLocationDropDownList,
}: IUseMemorizations) => {
  const { t } = useTranslation();

  const productBalanceListData = useMemo(() => {
    return productBalanceList?.data?.map((value: any) => ({
      id: value?.externalId,
      label: value?.descricao,
      subLabel: `${t('supply.losses.create.codeSubLabel')} ${
        value?.sequenciaGroupTenant
      }`,
      allItem: value,
    }));
  }, [productBalanceList]);

  const storageLocationListData = useMemo(() => {
    return storageLocationDropDownList.map((value: any) => ({
      id: value?.externalId,
      label: value?.descricao,
      code: value?.nomeEmpresa,
      customSelectedLabel: `${value.descricao} (${value.nomeEmpresa})`,
    }));
  }, [storageLocationDropDownList]);

  return { productBalanceListData, storageLocationListData };
};
