import { IListMessageProductDropdownData } from "@/Data/Interfaces/response/ProductMensage/IProductMensageResponse";
import { IStaticOptions, IUseMemorizations } from "./interfaces";
import { useTranslation } from "react-i18next";

export const useRadioButtonMessageTypeMemorizations = ({
  listMessageProductDropdown,
  messageSearch,
  createMessageProductLinked
}: IUseMemorizations) => {
  const { t } = useTranslation();

  const items = listMessageProductDropdown?.data?.map(
    (item: IListMessageProductDropdownData) => {
      return {
        id: item.externalId,
        label: item.descricao,
      };
    }
  );

  const staticOptions: IStaticOptions[] = [
    {
      name: `${t(
        'products.productList.productMessage.modalProductLinkedMessage.newMessageDropdown'
      )}  ${messageSearch ? `"${messageSearch}"` : ''}`,
      icon: 'plus',
      onClick: () => {
        createMessageProductLinked(messageSearch);
      },
    },
  ];

  return {
    items,
    staticOptions
  };
};