import { IUseFunctions } from './interfaces';
import { ICreatePackingClassification } from '../../../../../../Data/Interfaces/request/PackingClassification/IPackingClassificationRequest';

export const useCreateFunctions = ({
  createPackingClassification,
}: IUseFunctions) => {
  const onSubmit = async (values: ICreatePackingClassification) => {
    const body: ICreatePackingClassification = { ...values };
    return await createPackingClassification({
      descricao: body?.descricao,
      ativo: true,
    });
  };

  return { onSubmit };
};
