import React, { FC, ReactNode } from 'react';
import { PopOver } from '@/Components/PopOver';
import styles from './avatarPopover.module.scss';

export interface IAvatarPopOver {
  setIsPopoverVisible: React.Dispatch<React.SetStateAction<boolean>>;
  title: ReactNode;
  content: ReactNode;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  isOpen: boolean;
  onOpenChange?: (visible: boolean) => void;
}

export const AvatarPopOver: FC<IAvatarPopOver> = ({
  setIsPopoverVisible,
  title,
  content,
  setIsOpen,
  isOpen,
  onOpenChange,
}) => {
  return (
    <div className={styles['container']}>
      <PopOver
        visible={isOpen === true ? false : undefined}
        trigger="hover"
        placement="bottomLeft"
        title={title}
        content={content}
        mouseEnterDelay={1}
        align={[0, 10]}
        onOpenChange={(visible) => {
          onOpenChange && onOpenChange(visible);
        }}
      >
        <div
          className={styles['avatar-popover']}
          onClick={() => {
            setIsPopoverVisible(false);
            setIsOpen(true);
          }}
        />
      </PopOver>
    </div>
  );
};
