import { IListUserData } from '@/Data/Interfaces/response/User/IGetUserReponse';
import { useCallback } from 'react';
import { IStatus, IUseFunctions } from './interfaces';

export const useUserListFunctions = ({
  changeUserStatus,
  deleteUser
}: IUseFunctions) => {
  const onEdit = useCallback(
    async (user: IListUserData[], status: IStatus) => {
      try {
        await changeUserStatus.mutateAsync({
          usuariosExternalId: user?.map((x) => x.externalId),
          ativa: status?.status === 'success' ? true : false,
        });
      } catch {};
    },
    [changeUserStatus]
  );

  const onDelete = useCallback(
    async (users: IListUserData[]) => {
      try {
        await deleteUser.mutateAsync(users?.map((user) => user?.externalId));
      } catch {};
    },
    [deleteUser]
  );

  return {
    onEdit,
    onDelete
  };
};
