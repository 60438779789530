import { IGetConglomerateResponse } from '../Data/Interfaces/response/Conglomerate/IConglomerateResponde';

export const CreateInitialValuesForUserGroups = () => {
  const initialValues = {
    nome: '',
    descricao: '',
    usuarios: [],
  };

  return initialValues;
};

export const CreateInitialValuesForConglomerate = (
  conglomerate?: IGetConglomerateResponse
) => {
  if (conglomerate) {
    const index = conglomerate?.empresas.findIndex(
      (x) => x.tipoEmpresaId === 1
    );
    const initialValues = {
      conglomeradoExternalId: conglomerate?.externalId,
      nome: conglomerate?.nome,
      empresaExternalIdMatriz:
        index !== -1 ? conglomerate?.empresas[index].externalId : '',
      ativo: conglomerate?.ativo,
      dataInclusao: conglomerate?.dataInclusao,
    };

    return initialValues;
  } else return {};
};
