import { QueryFunctionContext } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';
import { IPagination } from '../../../Components/Table';
import { Http, IQueryListParams } from '../../../Utils/Http';
import {
  IListAlternativeUnitMeasurementResponse,
  IListUnitMeasurementAllResponse,
  IListUnitMeasurementByClassResponse,
  IListUnitMeasurementPosologyResponse,
  IListUnitMeasurementResponse,
  IListUnitPrescriptionMeasurementResponse,
} from '../../Interfaces/response/UnitMeasurement/IUnitMeasurementResponse';
import { IGetConverionUnitMeasure } from '../../Interfaces/request/UnitMeasurement/ICreateUnitMeasurement';
import {
  IConversionDone,
  IConvertUnitOfMeasure,
  IConvertUnitOfMeasureMonetaryValue,
} from '../../Interfaces/response/UnitMeasurement/IConvertUnitOfMeasure';

export class UnitMeasurementAPI {
  public static async listUnitMeasurement(
    body: IPagination
  ): Promise<AxiosResponse<IListUnitMeasurementResponse>> {
    const axios = await Http.axios();
    return axios.get<IListUnitMeasurementResponse>(
      Http.AddQueryParams('/pharma/estoque/v1/UnidadeMedida/Listar', body)
    );
  }

  public static async listUnitMeasurementByClassAny(
    context: any
  ): Promise<IListUnitMeasurementByClassResponse | null> {
    return await Http.fetcher<IListUnitMeasurementByClassResponse>(
      Http.AddQueryParams(
        `/pharma/estoque/v1/UnidadeMedida/ListarDetalhadoUnidadesPorClasseProduto`,
        context.queryKey[1] as IQueryListParams
      )
    );
  }

  public static async listUnitMeasurementByClass(
    context: any
  ): Promise<IListUnitMeasurementByClassResponse | null> {
    return await Http.fetcher<IListUnitMeasurementByClassResponse>(
      Http.AddQueryParams(
        `/pharma/estoque/v1/UnidadeMedida/ListarDetalhadoUnidadesPorClasseProduto`,
        context.queryKey[1] as IQueryListParams
      )
    );
  }

  public static async listAlternativeUnitMeasurement(
    context: QueryFunctionContext
  ): Promise<IListAlternativeUnitMeasurementResponse> {
    return await Http.fetcher<IListAlternativeUnitMeasurementResponse>(
      Http.AddQueryParams(
        '/pharma/estoque/v1/UnidadeMedida/ListarUnidadeAlternativa',
        context.queryKey[1] as IQueryListParams
      )
    );
  }

  public static async postConversionUnitMeasure(
    body: IGetConverionUnitMeasure
  ) {
    const res = await Http.fetcher(
      `/pharma/estoque/v1/ConversaoUnidadeMedida/CalcularComLote`,
      {
        method: 'POST',
        body: JSON.stringify(body),
      }
    );
    if (!res) return [];
    return res;
  }

  public static async convertUnitOfMeasure(
    body: IConvertUnitOfMeasure[]
  ): Promise<IConversionDone[] | null> {
    const res = await Http.fetcher(
      `/pharma/estoque/v1/ConversaoUnidadeMedida/Calcular`,
      {
        method: 'POST',
        body: JSON.stringify({ conversoes: body }),
      }
    );
    if (!res) return null;

    return res.conversoesRealizadas;
  }

  public static async convertUnitOfMeasureMonetaryValue(
    body: IConvertUnitOfMeasureMonetaryValue[]
  ): Promise<IConversionDone[] | null> {
    const res = await Http.fetcher(
      `/pharma/estoque/v1/ConversaoUnidadeMedida/CalcularValor`,
      {
        method: 'POST',
        body: JSON.stringify({ conversoes: body }),
      }
    );
    if (!res) return null;

    return res.conversoesRealizadas;
  }

  public static async getListUnitMeasurementDropDown(
    context: QueryFunctionContext
  ): Promise<IListUnitMeasurementAllResponse | null> {
    if (!context.queryKey[1]) return null;
    return await Http.fetcher<IListUnitMeasurementAllResponse>(
      Http.AddQueryParams(
        '/pharma/estoque/v1/UnidadeMedida/Listar',
        context.queryKey[1] as IQueryListParams
      )
    );
  }

  public static async getListUnitMeasurementPrescriptionDropDown(
    context: any
  ): Promise<IListUnitPrescriptionMeasurementResponse | null> {
    return await Http.fetcher<IListUnitPrescriptionMeasurementResponse>(
      Http.AddQueryParams(
        '/pharma/estoque/v1/UnidadeMedida/ListarFormula',
        context.queryKey[1] as IQueryListParams
      )
    );
  }

  public static async getListUnitMeasurementPosologyDropDown(
    context: QueryFunctionContext
  ): Promise<IListUnitMeasurementPosologyResponse | null> {
    if (!context.queryKey[1]) return null;
    return await Http.fetcher<IListUnitMeasurementPosologyResponse>(
      Http.AddQueryParams(
        '/pharma/estoque/v1/UnidadeMedida/ListarPosologia',
        context.queryKey[1] as IQueryListParams
      )
    );
  }
}
