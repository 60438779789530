import { 
  useControllerMutateApiHook
} from '@/ControllerApiHook/Controller';
import { ProductAPI } from '@/Data/API/Supply/Product';
import {
  CREATE_DILUTED_PRODUCT
} from '@/ControllerApiHook/UniqIds/Supply/ProductKeys';

export const useCreateDilutedProductCallAPI = () => {
  const createDilutedProduct: any = useControllerMutateApiHook({
    uniqId: CREATE_DILUTED_PRODUCT,
    entityApi: ProductAPI.createDilutedProduct
  });

  return {
    createDilutedProduct
  };
};