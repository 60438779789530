import { IProviderRequest } from '@/Data/Interfaces/request/Purchasing/Providers/IProviderRequest';
import { IUseFunctions } from './interfaces';
import { TypePersonEnum } from '@/Data/Interfaces/model/typePersonEnum';

export const useCreateFunctions = ({ createProvider }: IUseFunctions) => {
  const onSubmit = async (values: any) => {
    var body: IProviderRequest = {
      ...values,
      cnpj:
        values.tipoPessoa === TypePersonEnum.juridical_person
          ? values.cnpj
          : undefined,
      cpf:
        values.tipoPessoa === TypePersonEnum.physical_person
          ? values.cpf
          : undefined,
    };
    return await createProvider(body);
  };

  return {
    onSubmit,
  };
};
