import { useTranslation } from "react-i18next";

export const useStockBalanceMemorizations = () => {
  const { t } = useTranslation();

  const headerOptions = {
    hasSearch: true,
    searchPlaceholder: t(
      'products.productList.stockBalance.searchPlaceHolder'
    ),
  };

  return {
    headerOptions
  };
};