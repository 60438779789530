import { FC } from 'react';
import { Button } from '@/Components';
import { history } from '@/Store';
import { useTranslation } from 'react-i18next';
import styles from '../../LossesRegistration.module.scss';
import { IFChildren } from '@/Components/Form';

interface IFooterButtons {
  isLoadingCreateLoss: boolean;
  form: IFChildren;
}

export const FooterButtons: FC<IFooterButtons> = ({
  isLoadingCreateLoss,
  form,
}) => {
  const { t } = useTranslation();
  return (
    <div className={styles['footer-button']}>
      <Button
        type="secondary"
        children={t('common.cancel')}
        onClick={() => {
          history.push('/inventory/losses');
        }}
      />
      <Button
        type="primary"
        children={t('common.create')}
        htmlType="submit"
        onClick={() => {
          'submit';
        }}
        loading={isLoadingCreateLoss}
      />
    </div>
  );
};
