import { Dropdown, Link, TextArea, TextInput } from '@/Components';
import { SelectedAvatar } from '@/Components/SelectedAvatar';
import { Col, Row } from 'antd';
import { Dispatch, FC, SetStateAction } from 'react';
import { AfterSelectedClientAddressModal } from '../SelectedAddressCard';
import { useTranslation } from 'react-i18next';
import { FormikContextType, useFormikContext } from 'formik';
import { IGetSaleOrderResponse } from '@/Data/Interfaces/response/Sales/SalesOrder/ISalesOrderResponse';
import { StatusSalesOrderEnum } from '../StatusHeader/Components/ActionButtons';
import { useContentBodyStates } from './Hooks/useContentBodyStates';
import { SelectAddress } from './Modals/SelectAddress';
import { useContentBodyCallAPI } from './Hooks/useContentBodyCallAPI';
import { useContentBodyFunctions } from './Hooks/useContentBodyFunctions';

import styles from './ContentBody.module.scss';

export const retiradaNoBalcao = 1;
export const entregaEmDomicilo = 2;

interface IContentBody {
  saleOrder: IGetSaleOrderResponse;
  patchSaleOrder: _.DebouncedFunc<
    (value: any, propName: string) => Promise<any>
  >;
  patchSaleOrderLoading: boolean;
  patchSaleOrderSuccess: boolean;
  patchVariables: string;

  approveSaleOrderModal: boolean;
  cancelSaleOrderModal: boolean;
  reproveSaleOrderModal: boolean;
  reverseSaleOrderModal: boolean;

  setApproveSaleOrderModal: Dispatch<SetStateAction<boolean>>;
  setCancelSaleOrderModal: Dispatch<SetStateAction<boolean>>;
  setReproveSaleOrderModal: Dispatch<SetStateAction<boolean>>;
  setReverseSaleOrderModal: Dispatch<SetStateAction<boolean>>;
}

export const ContentBody: FC<IContentBody> = ({
  patchSaleOrder,
  patchSaleOrderLoading,
  patchSaleOrderSuccess,
  patchVariables,
  saleOrder,

  approveSaleOrderModal,
  cancelSaleOrderModal,
  reproveSaleOrderModal,
  reverseSaleOrderModal,

  setApproveSaleOrderModal,
  setCancelSaleOrderModal,
  setReproveSaleOrderModal,
  setReverseSaleOrderModal,
}) => {
  const form: FormikContextType<any> = useFormikContext();

  const { t } = useTranslation();

  const {
    setOpenDeliveryFeeModal,
    setIsAddressModalOpen,
    openAddressModalOpen,
    openDeliveryFeeModal,
  } = useContentBodyStates();

  const { isLoading, isSuccess, myUser, updateDelivery } =
    useContentBodyCallAPI();

  const { updateDeliveryFee } = useContentBodyFunctions({
    form,
    saleOrder,
    setIsAddressModalOpen,
    updateDelivery,
  });

  const deliveryInfos =
    !!form.values.taxaEntrega ||
    !!form.values.trocoEntrega ||
    !!form.values.observacaoEntrega;

  return (
    <Row gutter={[16, 16]}>
      <Col span={10}>
        <TextInput
          name="codigoPedido"
          label={t(
            'saleOrder.editOrder.sideRightMenu.infosSaleOrder.orderNumber'
          )}
          disabled
          withBoldContent
          withoutMarginBottom
        />
      </Col>
      <Col span={14}>
        <TextInput
          name="dataLancamento"
          label={t(
            'saleOrder.editOrder.sideRightMenu.infosSaleOrder.releaseDate'
          )}
          disabled
          withoutMarginBottom
        />
      </Col>

      <Col span={24}>
        <SelectedAvatar
          name={myUser?.nome}
          lastName={myUser?.sobrenome}
          label={t('saleOrder.editOrder.sideRightMenu.infosSaleOrder.seller')}
          withoutMarginBottom
        />
      </Col>

      <Col span={24}>
        <Dropdown
          name="tipoEntregaId"
          onChange={(x) => updateDeliveryFee(x)}
          allowClear
          withoutMarginBottom
          withLoadingLabel={isLoading}
          isSuccessIcon={isSuccess}
          items={[
            {
              id: retiradaNoBalcao,
              label: t(
                'saleOrder.editOrder.sideRightMenu.infosSaleOrder.pickUpATheCounter'
              ),
              leftIcon: 'counter',
            },
            {
              id: entregaEmDomicilo,
              label: t(
                'saleOrder.editOrder.sideRightMenu.infosSaleOrder.homeDelivery'
              ),
              leftIcon: 'shipping-truck',
            },
          ]}
          label={t('saleOrder.editOrder.sideRightMenu.infosSaleOrder.delivery')}
          disabled={saleOrder?.statusPedido !== StatusSalesOrderEnum.Orcado}
          getPopupContainerId="container-side-right-menu-sale-order"
        />
      </Col>
      {form.values.tipoEntregaId !== retiradaNoBalcao &&
        !!form.values.clienteEnderecoExternalId && (
          <>
            <Col span={24}>
              <AfterSelectedClientAddressModal
                saleOrder={saleOrder}
                onClick={() => setIsAddressModalOpen(true)}
              />
            </Col>
            <Col span={24} style={{ marginTop: '-13px' }}>
              <Link
                type="ui-tiny-semibold"
                color="primary-base"
                className={styles['link-delivery-button']}
                onClick={() => setOpenDeliveryFeeModal(true)}
                style={deliveryInfos ? { width: 158 } : { width: 179 }}
                children={
                  deliveryInfos
                    ? t(
                        'saleOrder.editOrder.sideRightMenu.infosSaleOrder.editChangeOrNote'
                      )
                    : t(
                        'saleOrder.editOrder.sideRightMenu.infosSaleOrder.addChangeOrNote'
                      )
                }
              />
            </Col>
          </>
        )}

      <Col span={24}>
        <TextArea
          name="observacao"
          maxLength={120}
          label={t(
            'saleOrder.editOrder.sideRightMenu.infosSaleOrder.observations'
          )}
          heightTextArea={125}
          withLoadingLabel={!!patchVariables && patchSaleOrderLoading}
          isSuccessIcon={!!patchVariables && patchSaleOrderSuccess}
          onChange={(value) => patchSaleOrder(value, 'observacao')}
          placeholder={t('common.writeAComment')}
        />
      </Col>
      <SelectAddress
        approveSaleOrderModal={approveSaleOrderModal}
        cancelSaleOrderModal={cancelSaleOrderModal}
        openAddressModalOpen={openAddressModalOpen}
        openDeliveryFeeModal={openDeliveryFeeModal}
        reproveSaleOrderModal={reproveSaleOrderModal}
        reverseSaleOrderModal={reverseSaleOrderModal}
        saleOrder={saleOrder}
        setApproveSaleOrderModal={setApproveSaleOrderModal}
        setCancelSaleOrderModal={setCancelSaleOrderModal}
        setIsAddressModalOpen={setIsAddressModalOpen}
        setOpenDeliveryFeeModal={setOpenDeliveryFeeModal}
        setReproveSaleOrderModal={setReproveSaleOrderModal}
        setReverseSaleOrderModal={setReverseSaleOrderModal}
        updateDelivery={updateDelivery}
      />
    </Row>
  );
};
