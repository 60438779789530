import { useCallback } from "react";
import { IUseFunctions } from "./interfaces";

export const useAccumulateFunctions = ({
  setAccumulate
}: IUseFunctions) => {
  const onChange = useCallback((value: string) => {
    if (+value === 1) {
      return setAccumulate(true);
    }

    setAccumulate(false);
  }, [setAccumulate]);

  return { onChange };
};