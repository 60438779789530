import { QueryFunctionContext } from '@tanstack/react-query';
import { Http, IQueryListParams } from '../../../Utils/Http';
import { IListRecordTypeResponse } from '../../Interfaces/response/Record/IRecordResponse';

export class RecordAPI {
  public static async listRecordType(
    context: QueryFunctionContext
  ): Promise<IListRecordTypeResponse> {
    return await Http.fetcher<IListRecordTypeResponse>(
      Http.AddQueryParams(
        '/pharma/pessoa/v1/Prescritor/ListarTipoRegistro',
        context.queryKey[1] as IQueryListParams
      )
    );
  }
}
