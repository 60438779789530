import {
  APPROVE_ORDER_PURCHASE,
  CONFIRM_WITH_SUPPLIER_ORDER_PURCHASE,
  REPROVE_ORDER_PURCHASE,
  REVERSE_CONFIRMED_SUPPLIER_ORDER_PURCHASE,
  REVERSE_RELEASED_ORDER_PURCHASE
} from '@/ControllerApiHook/UniqIds/Purchase/PurchaseOrderKeys';
import { i18n } from '@/Components';
import { useControllerMutateApiHook } from '@/ControllerApiHook/Controller';
import { PurchaseAPI } from '@/Data/API/Purchase/PurchaseOrderApi';
import { messageErrorToast, messageSucessToast } from '@/Utils/MessageErrorToast';

export const useStatusCardCallAPI = () =>  {
  const reproveOrderPurchase: any = useControllerMutateApiHook({
    uniqId: REPROVE_ORDER_PURCHASE,
    entityApi: PurchaseAPI.reproveOrderPurchase,
    options: {
      onError: (x) => messageErrorToast(x),
      onSuccess: () =>
        messageSucessToast(
          i18n.t('purchasing.purchaseOrder.successMessageToasts.reprove')
        )
    }
  });

  const reverseConfirmWithSupplierOrderPurchase = useControllerMutateApiHook({
    uniqId: REVERSE_CONFIRMED_SUPPLIER_ORDER_PURCHASE,
    entityApi: PurchaseAPI.reverseConfirmWithSupplierOrderPurchase,
    options: {
      onError: (x) => messageErrorToast(x),
      onSuccess: () =>
        messageSucessToast(
          i18n.t('purchasing.purchaseOrder.successMessageToasts.reverse')
        )
    }
  });

  const reverseReleasedOrderPurchase: any = useControllerMutateApiHook({
    uniqId: REVERSE_RELEASED_ORDER_PURCHASE,
    entityApi: PurchaseAPI.reverseReleasedOrderPurchase,
    options: {
      onError: (x) => messageErrorToast(x),
      onSuccess: () =>
        messageSucessToast(
          i18n.t('purchasing.purchaseOrder.successMessageToasts.reverse')
        )
    }
  });

  const confirmWithSupplier: any = useControllerMutateApiHook({
    uniqId: CONFIRM_WITH_SUPPLIER_ORDER_PURCHASE,
    entityApi: PurchaseAPI.confirmWithSupplierOrderPurchase,
    options: {
      onError: (x) => messageErrorToast(x),
      onSuccess: () =>
        messageSucessToast(
          i18n.t(
            'purchasing.purchaseOrder.successMessageToasts.confirmWithProvider'
          )
        )
    }
  });

  const approveOrderPurchase: any = useControllerMutateApiHook({
    uniqId: APPROVE_ORDER_PURCHASE,
    entityApi: PurchaseAPI.approveOrderPurchase,
    options: {
      onError: (x) => messageErrorToast(x),
      onSuccess: () =>
        messageSucessToast(
          i18n.t('purchasing.purchaseOrder.successMessageToasts.approve')
        )
    }
  });

  return {
    reproveOrderPurchase,
    reverseConfirmWithSupplierOrderPurchase,
    reverseReleasedOrderPurchase,
    confirmWithSupplier,
    approveOrderPurchase
  };
};