import { useCallback } from 'react';
import { IuseCreateAddressFunctions } from './interfaces';
import { FormikHelpers, FormikValues } from 'formik';

export const useCreateAddressFunctions = ({
  saleOrder,
  mutateAsync,
}: IuseCreateAddressFunctions) => {
  const onOkClickForm = useCallback(
    async (x: any, formik: FormikHelpers<FormikValues>) =>
      saleOrder.clienteExternalId &&
      (await mutateAsync(
        {
          ...x,
          clienteExternalId: saleOrder.clienteExternalId,
        },
        { onError: () => formik.setSubmitting(false) }
      )),
    [mutateAsync, saleOrder.clienteExternalId]
  );

  return { onOkClickForm };
};
