import { TFunction } from 'react-i18next';
import { ICannotDeleteModalInfo } from '../ErrorHandlingUtils';

export const cannotDeletePharmaceuticalFormWithMovementsInTheSystemProps = (
  t: TFunction<'translation', undefined>
): ICannotDeleteModalInfo => ({
  formName: 'descricao',
  oneErrorModal: {
    titleModal: `${t('common.modalCannotDelete.messageTitle')} ${t(
      'production.pharmaceuticalForm.modalCannotDelete.messageTitle'
    )}`,
    subTitleModal: `${t('common.modalCannotDelete.messageSubTitle')} ${t(
      'production.pharmaceuticalForm.modalCannotDelete.messageSubtitle'
    )}`,
    reasonTranslation: `${t(
      'production.pharmaceuticalForm.modalCannotDelete.messageReasonCannotDelete'
    )}`,
    warningModal: t(
      'production.pharmaceuticalForm.modalCannotDelete.messageWarningCannotDelete'
    ),
  },
  manyErrorsModal: {
    entity: `${t('common.modalCannotDelete.messageTitle')}`,
    complement: `${t('common.pharmaceuticalForms')}`,
    subTitleModal: `${t('common.modalCannotDelete.messageSubTitle')} ${t(
      'production.pharmaceuticalForm.modalCannotDelete.manyMessageSubtitle'
    )}:`,
    reasonTranslation: `${t(
      'production.pharmaceuticalForm.modalCannotDelete.manyMessageReasonCannotDelete'
    )}`,
    warningModal: t(
      'production.pharmaceuticalForm.modalCannotDelete.manyMessageWarningCannotDelete'
    ),
  },
});
