import { FC } from "react";
import { Col } from 'antd';
import { useTranslation } from 'react-i18next';
import {
  TextInput
} from '@/Components';

export const BatchCode: FC = () => {
  const { t } = useTranslation();

  return (
    <Col span={4}>
      <TextInput
        name="numero"
        label={t('supply.lot.create.lot')}
        placeHolder={t('supply.lot.placeholder.lot')}
        required
        withoutMarginBottom
      />
    </Col>
  );
};