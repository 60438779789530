import { QueryFunctionContext } from '@tanstack/react-query';
import { Http, IQueryListParams } from '../../../Utils/Http';
import {
  IGetCityResponse,
  IListCityResponse,
} from '../../Interfaces/response/Locality/ICityResponse';

export class CityAPI {
  public static async getListCity(
    context: any
  ): Promise<IListCityResponse | null> {
    return await Http.fetcher<IListCityResponse>(
      Http.AddQueryParams(
        '/pharma/localidade/v1/Cidade/Listar',
        context.queryKey[1] as IQueryListParams
      )
    );
  }
  public static async getCity(
    context: QueryFunctionContext
  ): Promise<IGetCityResponse | null> {
    if (context.queryKey[1])
      return await Http.fetcher<IGetCityResponse>(
        `/pharma/localidade/v1/Cidade/${context.queryKey[1]}`
      );
    return null;
  }
}
