import { SideMenuItems } from './SideMenu.items';
import { IMenuItem } from './MenuItem';

export const redirectToDefaultContext = (
  updateContext: (context: string) => void,
  location: any
) => {
  const searchRoutes = (item: IMenuItem): boolean => {
    const hasLink = location.pathname.includes(item.link);

    if (!hasLink) {
      const childrens = item.children;

      if (!childrens || childrens.length <= 0) {
        return false;
      }

      return childrens.some((items) => searchRoutes(items));
    }
    return hasLink;
  };

  const currentContext = SideMenuItems.find((context) =>
    context.items.some((route) => searchRoutes(route))
  );

  if (!!currentContext) updateContext(currentContext.context.id);
};
