import { useCallback } from 'react';
import { 
  IProductLinkedMessageListData
} from '@/Data/Interfaces/response/Product/IProductRespose';
import { messageErrorToast } from '@/Utils/MessageErrorToast';
import { handleModalError } from '@/Utils/ErrorCodeUtils/ErrorHandlingUtils';
import { App } from 'antd';
import { useQueryClient } from '@tanstack/react-query';
import {
  FETCH_LIST_PRODUCT_LINKED_MESSAGE 
} from '@/ControllerApiHook/UniqIds/Supply/ProductKeys';
import { IUseFunctions } from './interfaces';

export const useProductMessageFunctions = ({
  removeProductLinkedMsg,
  externalId,
  setSelectedRowsOnTable,
  setVisibleLinkedProductMessageModal
}: IUseFunctions) => {
  const app = App.useApp();
  const queryClient = useQueryClient();

  const removeProductLinkedMessage = useCallback(
    async (productMessageLinked: IProductLinkedMessageListData[]) => {
      try {
        const res = await removeProductLinkedMsg.mutateAsync(
          {
            produtoExternalId: externalId,
            mensagensExternalIds: productMessageLinked?.map(
              (x) => x?.mensagemExternalId
            ),
          },
          { onError: handleModalError(app) }
        );
        if (res.success) {
          queryClient.invalidateQueries([FETCH_LIST_PRODUCT_LINKED_MESSAGE]);
        }
      } catch (error) {
        messageErrorToast(error);
      }
    },
    [removeProductLinkedMsg, queryClient, app, externalId]
  );

  const selectRowsOnTable = (x: any) => setSelectedRowsOnTable(x);

  const onDelete = (productLinkedMessage: any[]) =>
    removeProductLinkedMessage(productLinkedMessage);

  const changeVisibleModal = (x: boolean) => setVisibleLinkedProductMessageModal(x);

  return {
    removeProductLinkedMessage,
    selectRowsOnTable,
    onDelete,
    changeVisibleModal
  };
};