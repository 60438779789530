import { UserGroupActionUnion, UserGroupActionKeys } from './Group.actions';
import { IGroupState } from './interfaces/IGroupState';
import { IModulo } from '../../Data/Interfaces/response/User/IGetUserReponse';

const initialData: IGroupState = {
  isLoading: false,
  showPermissionDependencyModal: false,
};

export const groupReducer = (
  state = initialData,
  action: UserGroupActionUnion
): IGroupState => {
  switch (action.type) {
    case UserGroupActionKeys.UPDATE_USER_GROUP_PERMISSION:
      return {
        ...state,
        isLoading: true,
        error: undefined,
      };
    case UserGroupActionKeys.SHOW_PERMISSION_DEPENDENCY_MODAL:
      return {
        ...state,
        showPermissionDependencyModal: true,
        permissionDependencyModalData: action.payload,
      };
    case UserGroupActionKeys.HIDE_PERMISSION_DEPENDENCY_MODAL:
      return {
        ...state,
        showPermissionDependencyModal: false,
        permissionDependencyModalData: undefined,
      };

    case UserGroupActionKeys.UPDATE_USER_GROUP_MODULE_STATUS:
      const modules = state.userGroup?.modulos?.slice();
      const updateModule = (module: IModulo) => {
        if (module.permissaoId?.toString() === action.payload.permissionId) {
          module.ativo = action.payload.value;
        }

        if (module.itens?.length && module.itens?.length > 0) {
          module.itens.forEach((x) => updateModule(x));
        }
      };
      modules?.forEach((x) => updateModule(x));
      return {
        ...state,
        userGroup: { ...state.userGroup, modulos: modules! } as any,
      };
    default:
      return state;
  }
};

export default groupReducer;
