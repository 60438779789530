import { useMemo } from 'react';
import { IUseEditMemorizations } from './interfaces';
import { IReasonLossListData } from '@/Data/Interfaces/response/Loss/ILossResponse';
import { uniqBy } from 'lodash';

export const useEditMemorizations = ({
  lossesReason,
  getLossSingle,
}: IUseEditMemorizations) => {
  const lossReasonsList = useMemo(() => {
    const reasons = lossesReason?.data || [];
    if (!getLossSingle) {
      return reasons;
    }
    return uniqBy(
      [
        ...reasons,
        {
          externalId: getLossSingle.motivoPerdaExternalId,
          descricao: getLossSingle.motivoPerdaDescricao,
        },
      ] as IReasonLossListData[],
      'externalId'
    );
  }, [getLossSingle, lossesReason]);

  const dropdownRigthUntMedidaId = getLossSingle
    ? {
        name: 'unidadeMedidaId',
        defaultValue: getLossSingle.unidadeMedidaId,
        options: [
          {
            id: getLossSingle.unidadeMedidaId,
            content: getLossSingle.unidadeMedida.abreviacao,
          },
        ],
      }
    : undefined;

  const itemsReasonDropdown = lossReasonsList
    ? lossReasonsList.map((reason: any) => ({
        id: reason.externalId,
        label: `${reason.id} - ${reason.descricao}`,
      }))
    : [];

  return { lossReasonsList, dropdownRigthUntMedidaId, itemsReasonDropdown };
};
