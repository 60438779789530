import { useParams } from 'react-router-dom';
import {
  DELETE_PRODUCT,
  EDIT_STATUS_PRODUCT,
  FETCH_GET_SUBGROUP,
  FETCH_LIST_PRODUCTS_SUBGROUP,
} from '@/ControllerApiHook/UniqIds/Supply/ProductKeys';
import StorageUtils from '@/Utils/StorageUtils';
import {
  useControllerMutateApiHook,
  useControllerQueryApiHook,
  useControllerQueryListApiHook,
} from '@/ControllerApiHook/Controller';
import { ProductAPI } from '@/Data/API/Supply/Product';
import { IProductSubGroupResponse } from '@/Data/Interfaces/response/Product/IProductRespose';

export const useProductFormCallAPI = () => {
  const { externalId } = useParams<{ externalId: string }>();

  const preference = StorageUtils.getPreference();

  const editProductStatus: any = useControllerMutateApiHook({
    uniqId: EDIT_STATUS_PRODUCT,
    entityApi: ProductAPI.productStatus,
  });

  const deleteProduct: any = useControllerMutateApiHook({
    uniqId: DELETE_PRODUCT,
    entityApi: ProductAPI.deleteProduct,
  });

  const { data: subGroup } =
    useControllerQueryApiHook<IProductSubGroupResponse>({
      uniqId: FETCH_GET_SUBGROUP,
      entityApi: ProductAPI.getproductSubGroup,
      externalIds: [externalId],
    });

  const {
    data: subGroupProductList,
    isLoading,
    refetch: refetchSubGroupList,
  } = useControllerQueryListApiHook({
    uniqId: FETCH_LIST_PRODUCTS_SUBGROUP,
    entityApi: ProductAPI.productsOfSubGroupsList,
    pagination: {
      sorter: { column: 'descricao', direction: 'ASC' },
      filter: [{ filterName: 'subGrupoExternalId', value: externalId }],
    },
  });

  return {
    preference,
    editProductStatus,
    deleteProduct,
    subGroup,
    subGroupProductList,
    isLoading,
    refetchSubGroupList,
  };
};
