import { FC } from "react";
import { Dropdown, Tooltip } from '@/Components';
import { Col } from 'antd';
import { useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';
import { ICapsuleType } from "./interfaces";
import { useCapsuleTypeCallAPI } from "./Hooks/useCapsuleTypeCallAPI";
import { useCapsuleTypeMemorizations } from "./Hooks/useCapsuleTypeMemorizations";

export const CapsuleType: FC<ICapsuleType> = ({
  product,
  disabledForm
}) => {
  const { t } = useTranslation();
  const form: any = useFormikContext();

  const {
    listCapsuleType,
    isLoadingCapsuleType,
    fetchNewPage
  } = useCapsuleTypeCallAPI();

  const {
    title,
    showMe,
    items
  } = useCapsuleTypeMemorizations({
    product,
    disabledForm,
    listCapsuleType
  });

  return (
    <Col span={4}>
      <Tooltip
        title={title}
        showMe={showMe}
      >
        <Dropdown
          name="tipoCapsulaId"
          label={t('products.create.capsuleType')}
          placeHolder={t('products.create.capsuleTypePlaceholder')}
          isLoading={isLoadingCapsuleType}
          onScrollEnd={fetchNewPage}
          items={items}
          disabled={disabledForm}
          withStatusIcon={!!product}
        />
      </Tooltip>
    </Col>
  );
};