import { Link, Status, Text, i18n } from '../../../../../Components';

export const pharmaceuticalFormColumns = (t: any) => [
  {
    title: t('production.pharmaceuticalForm.list.orderNumber'),
    dataIndex: 'ordem',
    key: 'ordem',
    isHighlightable: true,
    sortColumn: 'ordem',
    sorter: true,
    defaultSortOrder: 'ascend',
    render: (order: string, row: any) => (
      <Text
        type="ui-sub-bold"
        style={{ width: 'fit-content', cursor: 'default' }}
      >
        {order.toString().padStart(3, '0')}
      </Text>
    ),
  },
  {
    title: t('production.pharmaceuticalForm.list.description'),
    dataIndex: 'descricao',
    key: 'descricao',
    sortColumn: 'descricao',
    width: '25%',
    isHighlightable: true,
    render: (order: string, row: any) => (
      <Link
        type="ui-tiny-content"
        to={`/production/dosage-form/${row.externalId}/details`}
        style={{ width: 'fit-content' }}
      >
        {order.toString().padStart(3, '0')}
      </Link>
    ),
  },
  {
    title: t('production.pharmaceuticalForm.list.presentation'),
    dataIndex: 'apresentacao',
    key: 'apresentacao',
    sortColumn: 'apresentacao',
    width: '15%',
    isHighlightable: true,
  },

  {
    title: t('production.pharmaceuticalForm.list.usePharmaceuticalForm'),
    dataIndex: 'usoFormaFarmaceutica',
    key: 'usoFormaFarmaceutica',
    sortColumn: 'usoFormaFarmaceutica',
    width: '15%',
    isHighlightable: true,
    render: (prop: any) => `${Use(prop)}`,
  },

  {
    title: t('production.pharmaceuticalForm.list.laboratoryDescription'),
    dataIndex: 'laboratorioDescricao',
    key: 'laboratorioDescricao',
    sortColumn: 'laboratorioDescricao',
    width: '30%',
    isHighlightable: true,
  },

  {
    title: t('production.pharmaceuticalForm.list.status'),
    dataIndex: 'ativo',
    key: 'ativo',
    width: '15%',
    render: (prop: any) => (
      <Status type={prop ? 'success' : 'disabled'}>
        {t(
          `production.pharmaceuticalForm.list.statusTypes.${
            prop ? 'active' : 'inactive'
          }`
        )}
      </Status>
    ),
  },
];

const Use = (value: any) => {
  switch (value) {
    case 1:
      return `${i18n.t('production.pharmaceuticalForm.list.internal')}`;
    case 2:
      return `${i18n.t('production.pharmaceuticalForm.list.external')}`;
    case 3:
      return `${i18n.t('production.pharmaceuticalForm.list.topical')}`;
    default:
      return `${i18n.t('production.pharmaceuticalForm.list.internal')}`;
  }
};
