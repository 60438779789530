import { Col, Row } from 'antd';
import React from 'react';
import { TextInput } from '@/Components';
import { useTranslation } from 'react-i18next';
import { TextInputWithMask } from '@components/Inputs/TextInputWithMask';

const ThirdRow: React.FC = () => {
  const { t } = useTranslation();

  return (
    <Row gutter={[16, 0]}>
      <Col span={8}>
        <TextInput
          name="fornecedorDescricao"
          withoutMarginBottom
          placeHolder={t('purchasing.invoice.create.placeHolder.provider')}
          label={t('purchasing.invoice.create.provider')}
          disabled
        />
      </Col>
      <Col span={9}>
        <TextInput
          name="razaoSocial"
          label={t('purchasing.invoice.create.socialReason')}
          disabled
        />
      </Col>
      <Col span={7}>
        <TextInputWithMask
          name="cnpj"
          mask="cnpjMask"
          label={t('purchasing.invoice.create.cnpj')}
          withoutMarginBottom={false}
          disabled
        />
      </Col>
    </Row>
  );
};

export default ThirdRow;
