import { IUseCallAPI } from "./interfaces";
import { useControllerQueryApiHook } from "@/ControllerApiHook/Controller";
import { IProductResponse } from "@/Data/Interfaces/response/Product/IProductRespose";
import { FETCH_GET_PRODUCT } from "@/ControllerApiHook/UniqIds/Supply/ProductKeys";
import { ProductAPI } from "@/Data/API/Supply/Product";

export const useEditCallAPI = ({
  externalId
}: IUseCallAPI) => {
  const { data, isLoading } = useControllerQueryApiHook<IProductResponse>({
    uniqId: FETCH_GET_PRODUCT,
    entityApi: ProductAPI.getProduct,
    externalIds: [externalId]
  });

  return {
    data,
    isLoading
  };
};