import { Row, Col } from 'antd';
import React from 'react';
import PurchaseOrderInput from '../../../../../../../Create/components/PurchaseOrderInput';

interface ISixthRow {
  purchaseOrders?: any[];
}

const SixthRow: React.FC<ISixthRow> = ({ purchaseOrders }) => {
  return (
    <Row gutter={[16, 0]} style={{ marginBottom: '20px' }}>
      <Col span={24}>
        <PurchaseOrderInput
          disabled={true}
          purchaseOrderList={purchaseOrders ?? []}
        />
      </Col>
    </Row>
  );
};

export default SixthRow;
