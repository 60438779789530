import { FC } from 'react';
import {
  ICommonError
} from '@/Utils/ErrorCodeUtils/ErrorHandlingUtils';
import { useTranslation } from 'react-i18next';

export const Title: FC<ICommonError> = ({ content }) => {
  const { t } = useTranslation();
  return (
    <>
      {content?.length === 1
        ? t('supply.product.modal.cannotDelete.title', {
            product: content[0].descricao,
          })
        : t('supply.product.modal.cannotDelete.batchTitle', {
            product: content.length,
          })}
    </>
  );
};