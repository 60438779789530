import { currencyFormater } from '@/Utils/Currency';
import { IUseMemorizations } from './interfaces';
import { useFormikContext } from 'formik';
import { 
  TypePurchaseOrderEnum 
} from '@/Data/Interfaces/model/typePurchaseOrderEnum';

export const useTotalDiscountsMemorizations = ({
  withSelectedRows,
  productsValue,
  purchaseOrder,
  hasProducts
}: IUseMemorizations) => {
  const form: any = useFormikContext();

  const labelValue = withSelectedRows
  ? currencyFormater(productsValue.totalDiscount)
  : currencyFormater(
      purchaseOrder?.totalizadores?.totalProdutosDescontos ?? 0
    );

  const style = !hasProducts && withSelectedRows
  ? {
      textDecorationLine: 'line-through',
      color: 'var(--color-text-300)',
    }
  : form.values.status === TypePurchaseOrderEnum.canceled ||
    form.values.status === TypePurchaseOrderEnum.disapproved
  ? { textDecorationLine: 'line-through' }
  : {};

  return {
    labelValue,
    style
  };
};