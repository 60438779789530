import { FC } from "react";
import { Col } from 'antd';
import { useTranslation } from "react-i18next";
import { IType } from "./interfaces";
import { formulaProductTypes } from '@/Pages/Sectors/Production/StandardFormula/Create/Components/utils';
import { Dropdown } from "@/Components";

export const Type: FC<IType> = ({
  index,
  addRow
}) => {
  const { t } = useTranslation();

  return (
    <Col span={3}>
      <Dropdown
        name={`produtos.${index}.tipoItem`}
        items={formulaProductTypes?.map((values) => ({
          id: values.id,
          label: values.name,
        }))}
        label={
          index === 0
            ? t('production.standardFormula.create.productLine.type')
            : ''
        }
        placeHolder={t(
          'production.standardFormula.create.productLine.typePlaceholder'
        )}
        required
        onChange={addRow}
      />
    </Col>
  );
};