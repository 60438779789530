import { IPurchasingState } from './interfaces/IPurchasingState';
import {
  PurchasingActionKeys,
  PurchasingActionUnion,
} from './Purchasing.actions';

const initialData: IPurchasingState = {
  isLoading: false,
  provider: {
    addContactModal: {
      isOpen: false,
    },
    addDocumentModal: {
      isOpen: false,
    },
    addAddressModal: {
      isOpen: false,
    },
  },
  totalValueState: [],
};

export const purchasingReducer = (
  state = initialData,
  action: PurchasingActionUnion
): IPurchasingState => {
  switch (action.type) {
    case PurchasingActionKeys.SET_LOADING:
      return {
        ...state,
        isLoading: action.payload,
      };

    case PurchasingActionKeys.TOGGLE_CONTACT_MODAL:
      return {
        ...state,
        provider: {
          ...state.provider,
          addContactModal: {
            isOpen: !state.provider.addContactModal.isOpen,
          },
        },
      };

    case PurchasingActionKeys.OPEN_EDIT_CONTACT_MODAL:
      return {
        ...state,
        provider: {
          ...state.provider,
          addContactModal: {
            isOpen: true,
            ...action.payload,
          },
        },
      };

    case PurchasingActionKeys.TOGGLE_DOCUMENT_MODAL:
      return {
        ...state,
        provider: {
          ...state.provider,
          addDocumentModal: {
            isOpen: !state.provider.addDocumentModal.isOpen,
          },
        },
      };

    case PurchasingActionKeys.OPEN_EDIT_DOCUMENT_MODAL:
      return {
        ...state,
        provider: {
          ...state.provider,
          addDocumentModal: {
            isOpen: true,
            ...action.payload,
          },
        },
      };

    case PurchasingActionKeys.TOGGLE_ADDRESS_MODAL:
      return {
        ...state,
        provider: {
          ...state.provider,
          addAddressModal: {
            isOpen: !state.provider.addAddressModal.isOpen,
          },
        },
      };

    case PurchasingActionKeys.OPEN_EDIT_ADDRESS_MODAL:
      return {
        ...state,
        provider: {
          ...state.provider,
          addAddressModal: {
            isOpen: true,
            ...action.payload,
          },
        },
      };

    case PurchasingActionKeys.SET_TOTAL_VALUES_CARD:
      return {
        ...state,
        totalValueState: action.payload,
      };

    default:
      return state;
  }
};

export default purchasingReducer;
