import { RemoveMask } from "@/Utils/MasksUtils";
import StorageUtils from "@/Utils/StorageUtils";
import { IUseFunctions } from "./interfaces";

export const useCompanyCreateFunctions = ({
  radioCompanyGroup,
  createCompany,
  createConglomerate
}: IUseFunctions) => {
  const preferences = StorageUtils.getPreference();

  const onSubmit = async (values: any) => {
    values.endereco.cep = RemoveMask(values?.endereco?.cep) || '';

    const body = {
      ...values,
      cnpj: RemoveMask(values?.cnpj) || '',
      ...(values.usuario && {
        usuario: {
          nome: values.usuario.nome,
          sobrenome: values.usuario.sobrenome,
          email: values.usuario.email,
          celular: values.usuario.celular,
          dataNascimento:
            RemoveMask(
              values?.usuario?.dataNascimento,
              preferences?.padraoData
            ) || '',
          cpf: values.usuario.cpf,
          ativo: true,
        },
      }),
    };

    if (radioCompanyGroup) await createConglomerate.mutateAsync(body);
    else await createCompany.mutateAsync(body);
  };

  return {
    onSubmit
  };
};