import _ from 'lodash';
import { useMemo } from 'react';
import { IuseBodyMemorizations } from './interfaces';

export const useBodyMemorizations = ({ setCEP }: IuseBodyMemorizations) => {
  const changeCEP = useMemo(
    () => _.debounce((value: string) => setCEP(value), 600),
    []
  );
  return { changeCEP };
};
