import { useQueryClient } from '@tanstack/react-query';
import {
  useControllerMutateApiHook,
  useControllerQueryListApiHook,
} from '@/ControllerApiHook/Controller';
import { UserAPI } from '@/Data/API/Accountmanager/UserApi';
import {
  CHANGE_USER_STATUS_TYPE,
  DELETE_USER_TYPE,
  GET_USER_LIST_TYPE,
} from '@/ControllerApiHook/UniqIds/Accountmanager/UserKeys';

export const useUserListCallAPI = () => {
  const queryClient = useQueryClient();

  const changeUserStatus = useControllerMutateApiHook({
    entityApi: UserAPI.changeUserStatus,
    uniqId: CHANGE_USER_STATUS_TYPE,
    options: {
      onSuccess: () => queryClient.invalidateQueries([GET_USER_LIST_TYPE]),
    },
  });

  const userList = useControllerQueryListApiHook({
    uniqId: GET_USER_LIST_TYPE,
    entityApi: UserAPI.listUser,
    pagination: {
      sorter: { column: 'nome', direction: 'ASC' },
    },
  });

  const deleteUser = useControllerMutateApiHook({
    uniqId: DELETE_USER_TYPE,
    entityApi: UserAPI.deleteUser,
    options: {
      onSuccess: () => queryClient.invalidateQueries([GET_USER_LIST_TYPE]),
    },
  });

  return {
    changeUserStatus,
    userList,
    deleteUser,
  };
};
