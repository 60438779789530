import { useFormikContext } from 'formik';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Modal } from '../../../../../../../Components/Modal';
import { IUpdatePermissionRequest } from '@/Data/Interfaces/request/User/IUpdatePermissionRequest';
import { useRequiredPermissionModalFunctions } from './Hooks/useRequiredPermissionModalFunctions';
import { useRequiredPermissionModalCallAPI } from './Hooks/useRequiredPermissionModalCallAPI';
import { ModalBody } from './Components/ModalBody';

export type ErrorModalData = {
  permissaoId: string;
  nome: string;
  ativo: boolean;
  herdado: boolean;
  grupos: [];
  dependencias: {
    permissaoId: string;
    nome: string;
    ativo: boolean;
    modulo: {
      id: string;
      nome: string;
      icone: string;
      corDeFundo: string;
    };
    subModulo: {
      id: string;
      nome: string;
      icone: string;
      corDeFundo: string;
    };
  }[];
};

export const RequiredPermissionModal: FC<{
  show: boolean;
  error: ErrorModalData;
  payloadRequest: IUpdatePermissionRequest;
  changeVisibleModal: () => void;
}> = ({ show, error, payloadRequest, changeVisibleModal }) => {
  const form = useFormikContext();

  const { t } = useTranslation();

  const { updateUserPermission } = useRequiredPermissionModalCallAPI({
    changeVisibleModal,
    error,
    form,
  });

  const { onCloseClick, onOkClick } = useRequiredPermissionModalFunctions({
    error,
    updateUserPermission,
    payloadRequest,
    changeVisibleModal,
    form,
  });

  return (
    <Modal
      visible={show}
      onClose={onCloseClick}
      onCancelClick={onCloseClick}
      onOkClick={onOkClick}
      body={<ModalBody modalData={error} payloadRequest={payloadRequest} />}
      title={t('admin.editUserPage.permission.modal.modalTitle')}
      okButtonColor={payloadRequest.ativo! ? undefined : 'status-danger-base'}
      okButtonName={t(
        `admin.editUserPage.permission.modal.ok${
          payloadRequest.ativo! ? 'Active' : 'Inactive'
        }Button`
      )}
      minWidth={450}
      loading={updateUserPermission.isLoading}
    />
  );
};
