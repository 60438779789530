import { CurrencyText } from '../../../../../../../../Components/CurrencyText';
import { Text } from '../../../../../../../../Components/Text';

export const stockBalanceColumns = (t: any) => [
  {
    title: t('products.productList.stockBalance.code'),
    dataIndex: 'sequenciaGroupTenant',
    key: 'sequenciaGroupTenant',
  },
  {
    title: t('products.productList.stockBalance.placeName'),
    dataIndex: 'descricao',
    key: 'descricao',
    sortColumn: 'descricao',
    width: '50%',
    sorter: true,
    isHighlightable: true,
    defaultSortOrder: 'ascend',
    render: (x: any) => <Text type={'ui-tiny-bold'}>{x}</Text>,
  },
  {
    title: t('products.productList.stockBalance.company'),
    dataIndex: 'nomeFantasia',
    key: 'nomeFantasia',
    width: '20%',
  },
  {
    title: t('products.productList.stockBalance.availableQuantity'),
    dataIndex: 'saldo',
    key: 'saldo',
    render: (quantidy: any, unity: any) => (
      <Text type={'ui-tiny-content'}>
        <CurrencyText
          value={quantidy}
          suffix={` ${unity.unidadeAbreviacao}`}
          decimalScale={4}
        />
      </Text>
    ),
  },
];
