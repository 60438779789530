import { FC, useState } from 'react';
import { Icon, PopOver, Text } from '@/Components';
import { Divflex } from '@/Layouts/DivWhithFlex';
import { Checkbox } from '@/Components/StateInputs/Checkbox';

import styles from './FilterSearch.module.scss';
import { IFilterSearchItems } from '../../Interface';

interface IFilterSearch {
  filterSearchItem: IFilterSearchItems[];
  changeFilterSearchItemSelected: (search: IFilterSearchItems[]) => void;
  title?: string;
}
export const FilterSearch: FC<IFilterSearch> = ({
  filterSearchItem,
  changeFilterSearchItemSelected,
  title,
}) => {
  const [open, setOpen] = useState(false);

  return (
    <PopOver
      visible={open}
      onOpenChange={() => {
        !open && changeFilterSearchItemSelected(filterSearchItem);
        setOpen(!open);
      }}
      placement="bottom"
      title={
        <div className={styles['filter-title']}>
          <Text type="small-text" color="text-300" children={title} />
        </div>
      }
      content={
        <div className={styles['filter-container']}>
          {filterSearchItem.map((x, index) => (
            <Divflex
              className={styles['filter-content']}
              onClick={() => {
                if (x.disabled) return;
                let tempFilterSearchItem = filterSearchItem;
                if (!filterSearchItem) return;
                tempFilterSearchItem[index].checked =
                  !tempFilterSearchItem[index].checked;
                changeFilterSearchItemSelected(tempFilterSearchItem);
              }}
              key={x.id}
            >
              <Checkbox checked={x.checked} disabled={x.disabled} />
              <Text
                type="ui-tiny-content"
                color={x.disabled ? 'text-300' : 'text-50'}
                children={x.label}
                style={{ marginLeft: '8px' }}
              />
            </Divflex>
          ))}
        </div>
      }
      trigger="click"
    >
      <Icon
        className={styles['Icon']}
        icon="multimidia-equalizer"
        size="M"
        color="text-400"
      />
    </PopOver>
  );
};
