import {
  DynamicEditBalanceAdjustmentBreadcrumb,
  DynamicInfoEditBalanceAdjustmentBreadcrumb,
} from './DynamicRoutes/BalanceAdjustments';
import {
  DynamicEditCompanyStatusBreadcrumb,
  DynamicEditCompanyBreadcrumbCNPJ,
  DynamicUserBreadcrumbConglomerate,
  DynamicUserBreadcrumbStatusConglomerate,
  DynamicEditCompanyBreadcrumb,
} from './DynamicRoutes/Company';
import {
  DynamicEditUserGroupNameBreadcrumb,
  DynamicEditUserGroupStatusBreadcrumb,
} from './DynamicRoutes/Group';
import {
  DynamicEditProductBreadcrumb,
  DynamicEditProductOnSubGroupTitleBreadcrumb,
  DynamicEditProductTitleBreadcrumb,
  DynamicEditSubGroupOnGroupTitleBreadcrumb,
  DynamicEditSubGroupProductBreadcrumb,
  DynamicEditSubGroupProductTitleBreadcrumb,
} from './DynamicRoutes/Product';
import {
  DynamicEditUserStatusBreadcrumb,
  DynamicUserBreadcrumbEmail,
} from './DynamicRoutes/User';
import {
  DynamicEditStoragePlaceStatusBreadcrumb,
  DynamicEditStorageLocationBreadcrumb,
} from './DynamicRoutes/StorageLocation';
import {
  DynamicEditLosseBreadcrumb,
  DynamicEditLosseBreadcrumbTitle,
} from './DynamicRoutes/Losses';

import {
  DynamicEditLotBreadcrumb,
  DynamicEditLotStatusBreadcrumb,
} from './DynamicRoutes/Lot';
import {
  DynamicInfosTransfersBreadcrumb,
  DynamicTicketCodeBreadcrumb,
  DynamicTicketsBreadcrumb,
  DynamicTransfersBreadcrumb,
} from './DynamicRoutes/Transfer';
import {
  DynamicEditSupplierBreadcrumb,
  DynamicEditSupplierTitleBreadcrumb,
} from './DynamicRoutes/Suppliers';
import {
  DynamicEditPrescribertBreadcrumb,
  EditPrescribertBreadcrumb,
} from './DynamicRoutes/Sales/Prescribers';
import {
  DynamicEditPackingClassificationBreadcrumb,
  DynamicEditPackingClassificationStatusBreadcrumb,
} from './DynamicRoutes/PackingClassification';
import {
  DynamicEditPharmaceuticalFormBreadcrumb,
  DynamicEditPharmaceuticalFormStatusBreadcrumb,
} from './DynamicRoutes/PharmaceuticalForm';
import {
  DynamicEditLaboratoryBreadcrumb,
  DynamicEditLaboratoryStatusBreadcrumb,
} from './DynamicRoutes/Laboratory';
import {
  DynamicEditInfosPurchaseOrderBreadcrumb,
  DynamicInfosPurchaseOrderBreadcrumb,
  DynamicPurchaseOrderBreadcrumb,
} from './DynamicRoutes/PurchaseOrder';
import {
  DynamicCapsuleColorBreadcrumb,
  DynamicInfoEditCapsuleColorBreadcrumb,
} from './DynamicRoutes/CapsuleColor';
import {
  DynamicEditClientBreadcrumb,
  EditClientBreadcrumb,
} from './DynamicRoutes/Sales/Client';
import {
  DynamicEditSpecialtiesBreadcrumb,
  EditSpecialtiesBreadcrumb,
  EditSpecialtiesTitle,
} from './DynamicRoutes/Sales/Specialties';
import {
  DynamicEditInfosInvoiceBreadcrumb,
  DynamicInvoiceBreadcrumb,
  DynamicInvoiceRegisterLotsBreadcrumb,
} from './DynamicRoutes/Invoice';
import {
  DynamicEditPosologyBreadcrumb,
  DynamicEditPosologyStatusBreadcrumb,
} from './DynamicRoutes/Posology';
import { DynamicInvoiceDetailBreadcrumb } from './DynamicRoutes/Invoice';
import {
  DynamicInfoEditStandardFormulaBreadcrumb,
  DynamicStandardFormulaBreadcrumb,
} from './DynamicRoutes/StandardFormula';
import {
  DynamicEditProductMessageSubTitleBreadcrumb,
  DynamicEditProductMessageTabSubTitleBreadcrumb,
  DynamicEditProductMessageTitleBreadcrumb,
} from './DynamicRoutes/ProductMensage';
import { BreadcrumbsRoute } from 'use-react-router-breadcrumbs';

export const titles: BreadcrumbsRoute[] = [
  { path: '/', breadcrumb: 'breadCrumb.home' },

  { path: '/user-preference', breadcrumb: 'breadCrumb.preference' },
  { path: '/user-preference/currency', breadcrumb: 'breadCrumb.currency' },
  {
    path: '/user-preference/place-and-time',
    breadcrumb: 'breadCrumb.placeAndTime',
  },
  {
    path: '/user-preference/accessibility',
    breadcrumb: 'breadCrumb.accessibility',
  },
  { path: '/user-preference/theme', breadcrumb: 'breadCrumb.theme' },

  { path: '/account-manager', breadcrumb: 'breadCrumb.accountManager' },
  {
    path: '/account-manager/account-details',
    breadcrumb: 'breadCrumb.accountDetails',
  },
  {
    path: '/account-manager/account-security',
    breadcrumb: 'breadCrumb.accountSecurity',
  },
  {
    path: '/account-manager/account-notification',
    breadcrumb: 'breadCrumb.notification',
  },

  { path: '/system-configurations', breadcrumb: 'breadCrumb.systemConfig' },
  {
    path: '/system-configurations/personalization',
    breadcrumb: 'breadCrumb.personalization',
  },
  {
    path: '/system-configurations/currency',
    breadcrumb: 'breadCrumb.currency',
  },

  { path: '/admin-bootis', breadcrumb: 'breadCrumb.adminBootis' },
  {
    path: '/admin-bootis/company',
    breadcrumb: 'breadCrumb.adminBootisRoute.company',
  },
  {
    path: '/admin-bootis/company/create/:externalId',
    breadcrumb: 'breadCrumb.adminBootisRoute.companyPage.create',
  },
  {
    path: '/admin-bootis/company/create',
    breadcrumb: 'breadCrumb.adminBootisRoute.companyPage.create',
  },
  {
    path: '/admin-bootis/company/:externalId',
    breadcrumb: DynamicEditCompanyStatusBreadcrumb,
  },
  {
    path: '/admin-bootis/company/:externalId/:tab',
    breadcrumb: DynamicEditCompanyStatusBreadcrumb,
  },
  {
    path: '/admin-bootis/conglomerate',
    breadcrumb: 'breadCrumb.adminBootisRoute.conglomerate',
  },
  {
    path: '/admin-bootis/conglomerate/:externalId',
    breadcrumb: DynamicUserBreadcrumbStatusConglomerate,
  },
  {
    path: '/admin-bootis/conglomerate/:externalId/details',
    breadcrumb: DynamicUserBreadcrumbStatusConglomerate,
  },
  {
    path: '/admin-bootis/conglomerate/:externalId/Companys',
    breadcrumb: DynamicUserBreadcrumbStatusConglomerate,
  },

  { path: '/admin', breadcrumb: 'breadCrumb.admin' },
  { path: '/admin/user', breadcrumb: 'breadCrumb.adminRoute.user' },
  {
    path: '/admin/user/create',
    breadcrumb: 'breadCrumb.adminRoute.userPage.create',
  },
  {
    path: '/admin/user/:externalId/permissions',
    breadcrumb: DynamicEditUserStatusBreadcrumb,
  },
  {
    path: '/admin/user/:externalId/details',
    breadcrumb: DynamicEditUserStatusBreadcrumb,
  },
  {
    path: '/admin/user/:externalId',
    breadcrumb: DynamicEditUserStatusBreadcrumb,
  },
  {
    path: '/admin/user-group',
    breadcrumb: 'breadCrumb.adminRoute.groupPage.list',
  },
  {
    path: '/admin/user-group/create',
    breadcrumb: 'breadCrumb.adminRoute.groupPage.create',
  },
  {
    path: '/admin/user-group/:externalId',
    breadcrumb: DynamicEditUserGroupStatusBreadcrumb,
  },
  {
    path: '/admin/user-group/:externalId/details',
    breadcrumb: DynamicEditUserGroupStatusBreadcrumb,
  },
  {
    path: '/admin/user-group/:externalId/users',
    breadcrumb: DynamicEditUserGroupStatusBreadcrumb,
  },
  {
    path: '/admin/user-group/:externalId/permissions',
    breadcrumb: DynamicEditUserGroupStatusBreadcrumb,
  },
  {
    path: '/admin/company/:externalId/details',
    breadcrumb: DynamicEditCompanyBreadcrumb,
  },
  {
    path: '/admin/company',
    breadcrumb: 'breadCrumb.adminRoute.company',
  },
  {
    path: '/inventory',
    breadcrumb: 'breadCrumb.supply',
  },
  {
    path: '/inventory/products',
    breadcrumb: 'breadCrumb.products.list',
  },
  {
    path: '/inventory/products/create',
    breadcrumb: 'breadCrumb.products.create',
  },
  {
    path: '/inventory/products/create/:externalId/:group',
    breadcrumb: 'breadCrumb.products.create',
  },
  {
    path: '/inventory/products/:externalId/details',
    breadcrumb: DynamicEditProductBreadcrumb,
  },
  {
    path: '/inventory/products/:externalId/finance',
    breadcrumb: DynamicEditProductBreadcrumb,
  },
  {
    path: '/inventory/products/:externalId/incompatible',
    breadcrumb: DynamicEditProductBreadcrumb,
  },
  {
    path: '/inventory/products/:externalId/product-messages/:mensagemExternalId/messageDetail',
    breadcrumb: DynamicEditProductBreadcrumb,
  },
  {
    path: '/inventory/products/:externalId/incompatible/:incompatibleAssociation/incompatibleDetails',
    breadcrumb: DynamicEditProductBreadcrumb,
  },
  {
    path: '/inventory/products/:externalId/incompatible/createIncompatible',
    breadcrumb: DynamicEditProductBreadcrumb,
  },
  {
    path: '/inventory/products/:externalId/synonym',
    breadcrumb: DynamicEditProductBreadcrumb,
  },
  {
    path: '/inventory/products/:externalId/synonym/createSynonym',
    breadcrumb: DynamicEditProductBreadcrumb,
  },
  {
    path: '/inventory/products/:externalId/synonym/:synonymAssociation/synonymDetails',
    breadcrumb: DynamicEditProductBreadcrumb,
  },
  {
    path: '/inventory/products/:externalId/diluted',
    breadcrumb: DynamicEditProductBreadcrumb,
  },
  {
    path: '/inventory/products/:externalId/diluted/createDiluted',
    breadcrumb: DynamicEditProductBreadcrumb,
  },
  {
    path: '/inventory/products/:externalId/diluted/:dilutedAssociation/dilutedDetails',
    breadcrumb: DynamicEditProductBreadcrumb,
  },
  {
    path: '/inventory/products/:externalId/associated',
    breadcrumb: DynamicEditProductBreadcrumb,
  },
  {
    path: '/inventory/products/:externalId/associated/:dilutedAssociation/associatedDetails',
    breadcrumb: DynamicEditProductBreadcrumb,
  },
  {
    path: '/inventory/products/:externalId/associated/createAssociated',
    breadcrumb: DynamicEditProductBreadcrumb,
  },
  {
    path: '/inventory/products/:externalId/inventory',
    breadcrumb: DynamicEditProductBreadcrumb,
  },
  {
    path: '/inventory/products/:externalId/product-messages',
    breadcrumb: DynamicEditProductBreadcrumb,
  },
  {
    path: '/inventory/groups',
    breadcrumb: 'breadCrumb.products.group.list',
  },
  {
    path: '/inventory/groups/:externalId',
    breadcrumb: DynamicEditSubGroupOnGroupTitleBreadcrumb,
  },
  {
    path: '/inventory/groups/:externalId/details',
    breadcrumb: DynamicEditSubGroupOnGroupTitleBreadcrumb,
  },
  {
    path: '/inventory/groups/:externalId/subgroups',
    breadcrumb: DynamicEditSubGroupOnGroupTitleBreadcrumb,
  },
  {
    path: '/inventory/groups/:externalId/products',
    breadcrumb: DynamicEditSubGroupOnGroupTitleBreadcrumb,
  },
  {
    path: '/inventory/sub-groups',
    breadcrumb: 'breadCrumb.products.subGroup.list',
  },
  {
    path: '/inventory/sub-groups/:externalId/details',
    breadcrumb: DynamicEditSubGroupProductBreadcrumb,
  },
  {
    path: '/inventory/sub-groups/:externalId/products',
    breadcrumb: DynamicEditSubGroupProductBreadcrumb,
  },
  {
    path: '/inventory/products/create',
    breadcrumb: 'breadCrumb.products.create',
  },
  {
    path: '/inventory/supply-places',
    breadcrumb: 'breadCrumb.supplyRoutes.list',
  },
  {
    path: '/inventory/supply-places/:externalId/details',
    breadcrumb: DynamicEditStoragePlaceStatusBreadcrumb,
  },
  {
    path: '/inventory/supply-places/:externalId/inventory',
    breadcrumb: DynamicEditStoragePlaceStatusBreadcrumb,
  },
  {
    path: '/inventory/losses/:externalId/details',
    breadcrumb: DynamicEditLosseBreadcrumbTitle,
  },

  {
    path: '/inventory/supply-places/:externalId/inventory',
    breadcrumb: 'breadCrumb.supplyPlaces.inventory',
  },
  {
    path: '/inventory/supply-places/:externalId/inventoryMovements',
    breadcrumb: 'breadCrumb.supplyPlaces.inventoryMovements',
  },

  {
    path: '/inventory/lot/:externalId/details',
    breadcrumb: DynamicEditLotStatusBreadcrumb,
  },

  {
    path: '/inventory/lot/:externalId/stockLocations',
    breadcrumb: DynamicEditLotStatusBreadcrumb,
  },

  {
    path: '/inventory/lot',
    breadcrumb: 'breadCrumb.supplyRoutes.lots',
  },
  {
    path: '/inventory/losses',
    breadcrumb: 'breadCrumb.supplyRoutes.lossesList',
  },

  {
    path: '/inventory/lot/create',
    breadcrumb: 'breadCrumb.lots.create',
  },

  {
    path: '/inventory/supply-places/create',
    breadcrumb: 'breadCrumb.supplyPlaces.create',
  },
  {
    path: '/inventory/losses/create',
    breadcrumb: 'breadCrumb.losses.create',
  },

  {
    path: '/inventory/balance-adjustments',
    breadcrumb: 'breadCrumb.balanceAdjustments.list',
  },
  {
    path: '/inventory/balance-adjustments/create',
    breadcrumb: 'breadCrumb.balanceAdjustments.create',
  },
  {
    path: '/inventory/balance-adjustments/:externalId/details',
    breadcrumb: DynamicInfoEditBalanceAdjustmentBreadcrumb,
  },

  {
    path: '/inventory/transfers',
    breadcrumb: 'breadCrumb.supplyRoutes.stockTransfers',
  },
  {
    path: '/inventory/transfers/create',
    breadcrumb: 'breadCrumb.transfers.create.breadCrumb',
  },
  {
    path: '/inventory/transfers/:externalId/details',
    breadcrumb: DynamicInfosTransfersBreadcrumb,
  },

  {
    path: '/inventory/product-message/create',
    breadcrumb: 'breadCrumb.messageProduct.productMensageCreate',
  },
  {
    path: '/inventory/product-message/:externalId/details',
    breadcrumb: DynamicEditProductMessageTitleBreadcrumb,
  },
  {
    path: '/inventory/product-message/:externalId/linked-products',
    breadcrumb: DynamicEditProductMessageTitleBreadcrumb,
  },
  {
    path: '/inventory/product-message',
    breadcrumb: 'breadCrumb.messageProduct.productMensage',
  },

  {
    path: '/sales/tickets/:externalId/order',
    breadcrumb: DynamicTicketsBreadcrumb,
  },
  {
    path: '/sales/tickets',
    breadcrumb: 'breadCrumb.salesRoute.service',
  },
  {
    path: '/sales/order',
    breadcrumb: 'breadCrumb.salesRoute.saleOrder',
  },
  {
    path: '/sales/prescribers',
    breadcrumb: 'breadCrumb.salesRoute.prescriber',
  },
  {
    path: '/sales/prescribers/create',
    breadcrumb: 'breadCrumb.salesRoute.createPrescriber',
  },
  {
    path: '/sales/prescribers/:externalId/details',
    breadcrumb: DynamicEditPrescribertBreadcrumb,
  },
  {
    path: '/sales/expertise',
    breadcrumb: 'breadCrumb.salesRoute.specialties',
  },
  {
    path: '/sales/expertise/create',
    breadcrumb: 'breadCrumb.salesRoute.specialties',
  },
  {
    path: '/sales/expertise/:externalId/details',
    breadcrumb: DynamicEditSpecialtiesBreadcrumb,
  },
  {
    path: '/sales/expertise/:externalId/prescribers',
    breadcrumb: EditSpecialtiesTitle,
  },
  {
    path: '/sales/customers',
    breadcrumb: 'breadCrumb.salesRoute.client',
  },
  {
    path: '/sales/customers/create',
    breadcrumb: 'breadCrumb.salesRoute.createclient',
  },
  {
    path: '/sales/customers/:externalId/details',
    breadcrumb: DynamicEditClientBreadcrumb,
  },
  {
    path: '/sales/customers/:externalId/tickets',
    breadcrumb: DynamicEditClientBreadcrumb,
  },
  {
    path: '/sales/customers/:externalId/sale-order',
    breadcrumb: DynamicEditClientBreadcrumb,
  },

  {
    path: '/order/purchase/create',
    breadcrumb: 'breadCrumb.purchasing.purchaseOrder.create',
  },
  {
    path: '/order/purchase/create/:externalId/draft',
    breadcrumb: DynamicEditInfosPurchaseOrderBreadcrumb,
  },
  {
    path: '/order/purchase/:externalId/edit',
    breadcrumb: DynamicEditInfosPurchaseOrderBreadcrumb,
  },
  {
    path: '/order/purchase/:externalId/details',
    breadcrumb: DynamicInfosPurchaseOrderBreadcrumb,
  },
  {
    path: '/order/purchase',
    breadcrumb: 'breadCrumb.purchasing.purchaseOrder.purchaseList',
  },

  {
    path: '/order/supplier/create',
    breadcrumb: 'breadCrumb.purchasing.provider.create.title',
  },
  {
    path: '/order/supplier/:externalId/details',
    breadcrumb: DynamicEditSupplierTitleBreadcrumb,
  },
  {
    path: '/order/supplier',
    breadcrumb: 'breadCrumb.purchasing.provider.title',
  },
  {
    path: '/order/invoice',
    breadcrumb: 'breadCrumb.purchasing.invoice.title',
  },
  {
    path: '/order/invoice/create',
    breadcrumb: 'breadCrumb.purchasing.invoice.create.title',
  },
  {
    path: '/order/invoice/create/:externalId/draft',
    breadcrumb: DynamicEditInfosInvoiceBreadcrumb,
  },
  {
    path: '/order/invoice/:externalId/details',
    breadcrumb: DynamicInvoiceDetailBreadcrumb,
  },
  {
    path: '/order/invoice/:externalId/edit',
    breadcrumb: DynamicInvoiceDetailBreadcrumb,
  },
  {
    path: '/order/invoice/:externalId/register-lots',
    breadcrumb: DynamicInvoiceRegisterLotsBreadcrumb,
  },
  {
    path: '/order/invoice/:externalId/lots',
    breadcrumb: DynamicInvoiceDetailBreadcrumb,
  },

  {
    path: '/production',
    breadcrumb:
      'breadCrumb.production.packingClassification.packingClassificationList',
  },
  {
    path: '/production/packing-classification/:externalId/details',
    breadcrumb: DynamicEditPackingClassificationStatusBreadcrumb,
  },
  {
    path: '/production/packing-classification/:externalId/linkedProducts',
    breadcrumb: DynamicEditPackingClassificationStatusBreadcrumb,
  },
  {
    path: '/production/packing-classification',
    breadcrumb:
      'breadCrumb.production.packingClassification.packingClassificationList',
  },
  {
    path: '/production/packing-classification/create',
    breadcrumb:
      'breadCrumb.production.packingClassification.packingClassificationCreate',
  },
  {
    path: '/production/capsule-colors/:externalId/details',
    breadcrumb: DynamicInfoEditCapsuleColorBreadcrumb,
  },
  {
    path: '/production/capsule-colors',
    breadcrumb: 'breadCrumb.production.capsuleColors.capsuleColorsListTitle',
  },
  {
    path: '/production/capsule-colors/create',
    breadcrumb: 'breadCrumb.production.capsuleColors.capsuleColorsCreateTitle',
  },

  {
    path: '/production/posology/create',
    breadcrumb: 'breadCrumb.production.posology.posologyCreateTitle',
  },
  {
    path: '/production/posology/:externalId/details',
    breadcrumb: DynamicEditPosologyStatusBreadcrumb,
  },
  {
    path: '/production/posology',
    breadcrumb: 'breadCrumb.production.posology.posologyListTitle',
  },

  {
    path: '/production/dosage-form/create',
    breadcrumb:
      'breadCrumb.production.pharmaceuticalForm.pharmaceuticalFormCreateTitle',
  },
  {
    path: '/production/dosage-form/:externalId/details',
    breadcrumb: DynamicEditPharmaceuticalFormStatusBreadcrumb,
  },
  {
    path: '/production/dosage-form/:externalId/packingClassification',
    breadcrumb: DynamicEditPharmaceuticalFormStatusBreadcrumb,
  },
  {
    path: '/production/dosage-form',
    breadcrumb:
      'breadCrumb.production.pharmaceuticalForm.pharmaceuticalFormListTitle',
  },

  {
    path: '/production/labs/create',
    breadcrumb: 'breadCrumb.production.laboratory.laboratoryCreateTitle',
  },
  {
    path: '/production/labs/:externalId/details',
    breadcrumb: DynamicEditLaboratoryStatusBreadcrumb,
  },
  {
    path: '/production/labs',
    breadcrumb: 'breadCrumb.production.laboratory.laboratoryListTitle',
  },

  {
    path: '/production/standard-formula/create',
    breadcrumb:
      'breadCrumb.production.standardFormula.standardFormulaCreateTitle',
  },
  {
    path: '/production/standard-formula/:externalId/details',
    breadcrumb: DynamicInfoEditStandardFormulaBreadcrumb,
  },
  {
    path: '/production/standard-formula',
    breadcrumb:
      'breadCrumb.production.standardFormula.standardFormulaListTitle',
  },
];

export const routes = [
  { path: '/', breadcrumb: 'breadCrumb.home' },

  { path: '/user-preference', breadcrumb: 'breadCrumb.preference' },
  { path: '/user-preference/currency', breadcrumb: 'breadCrumb.currency' },
  {
    path: '/user-preference/place-and-time',
    breadcrumb: 'breadCrumb.placeAndTime',
  },
  {
    path: '/user-preference/accessibility',
    breadcrumb: 'breadCrumb.accessibility',
  },
  { path: '/user-preference/theme', breadcrumb: 'breadCrumb.theme' },

  { path: '/account-manager', breadcrumb: 'breadCrumb.accountManager' },
  {
    path: '/account-manager/account-details',
    breadcrumb: 'breadCrumb.accountDetails',
  },
  {
    path: '/account-manager/account-security',
    breadcrumb: 'breadCrumb.accountSecurity',
  },
  {
    path: '/account-manager/account-notification',
    breadcrumb: 'breadCrumb.notification',
  },

  { path: '/system-configurations', breadcrumb: 'breadCrumb.systemConfig' },
  {
    path: '/system-configurations/personalization',
    breadcrumb: 'breadCrumb.personalization',
  },
  {
    path: '/system-configurations/currency',
    breadcrumb: 'breadCrumb.currency',
  },

  { path: '/admin-bootis', breadcrumb: 'breadCrumb.adminBootis' },
  {
    path: '/admin-bootis/company',
    breadcrumb: 'breadCrumb.adminBootisRoute.company',
  },
  {
    path: '/admin-bootis/company/create',
    breadcrumb: 'breadCrumb.adminBootisRoute.companyPage.create',
  },
  {
    path: '/admin-bootis/company/:externalId',
    breadcrumb: DynamicEditCompanyBreadcrumbCNPJ,
  },
  {
    path: '/admin-bootis/company/:externalId/details',
    breadcrumb: 'breadCrumb.adminBootisRoute.companyPage.details',
  },
  {
    path: '/admin-bootis/conglomerate',
    breadcrumb: 'breadCrumb.adminBootisRoute.conglomerate',
  },
  {
    path: '/admin-bootis/conglomerate/:externalId',
    breadcrumb: DynamicUserBreadcrumbConglomerate,
  },
  {
    path: '/admin-bootis/conglomerate/:externalId/details',
    breadcrumb: 'breadCrumb.adminBootisRoute.conglomeratePage.details',
  },
  {
    path: '/admin-bootis/conglomerate/:externalId/Companys',
    breadcrumb: 'breadCrumb.adminBootisRoute.conglomeratePage.company',
  },

  { path: '/admin', breadcrumb: 'breadCrumb.admin' },
  { path: '/admin/user', breadcrumb: 'breadCrumb.adminRoute.user' },
  {
    path: '/admin/user/create',
    breadcrumb: 'breadCrumb.adminRoute.userPage.create',
  },
  { path: '/admin/user/:externalId', breadcrumb: DynamicUserBreadcrumbEmail },
  {
    path: '/admin/user/:externalId/details',
    breadcrumb: 'breadCrumb.adminRoute.userPage.details',
  },
  {
    path: '/admin/user/:externalId/permissions',
    breadcrumb: 'breadCrumb.adminRoute.userPage.permissions',
  },
  {
    path: '/admin/user-group',
    breadcrumb: 'breadCrumb.adminRoute.groupPage.list',
  },
  {
    path: '/admin/user-group/create',
    breadcrumb: 'breadCrumb.adminRoute.groupPage.create',
  },
  {
    path: '/admin/user-group/:externalId',
    breadcrumb: DynamicEditUserGroupNameBreadcrumb,
  },
  {
    path: '/admin/user-group/:externalId/details',
    breadcrumb: 'breadCrumb.adminRoute.groupPage.edit.tabs.detail',
  },
  {
    path: '/admin/user-group/:externalId/users',
    breadcrumb: 'breadCrumb.adminRoute.groupPage.edit.tabs.users',
  },
  {
    path: '/admin/user-group/:externalId/permissions',
    breadcrumb: 'breadCrumb.adminRoute.groupPage.edit.tabs.permission',
  },

  {
    path: '/admin/company/:externalId/details',
    breadcrumb: 'breadCrumb.adminRoute.companyPage.details',
  },
  {
    path: '/admin/company/:externalId',
    breadcrumb: DynamicEditCompanyBreadcrumb,
  },
  {
    path: '/admin/company',
    breadcrumb: 'breadCrumb.adminRoute.company',
  },

  {
    path: '/inventory',
    breadcrumb: 'breadCrumb.supply',
  },
  {
    path: '/inventory/products',
    breadcrumb: 'breadCrumb.products.list',
  },
  {
    path: '/inventory/products/create',
    breadcrumb: 'breadCrumb.products.create',
  },
  {
    path: '/inventory/products/:externalId',
    breadcrumb: DynamicEditProductTitleBreadcrumb,
    props: { noRedirect: true },
  },
  {
    path: '/inventory/products/:externalId/incompatible/:incompatibleAssociation',
    breadcrumb: DynamicEditProductOnSubGroupTitleBreadcrumb,
  },
  {
    path: '/inventory/products/:externalId/details',
    breadcrumb: 'breadCrumb.products.details',
  },
  {
    path: '/inventory/products/:externalId/finance',
    breadcrumb: 'breadCrumb.products.financial',
  },
  {
    path: '/inventory/products/:externalId/incompatible',
    breadcrumb: 'breadCrumb.products.incompatible',
  },
  {
    path: '/inventory/products/:externalId/diluted',
    breadcrumb: 'breadCrumb.products.diluted',
  },
  {
    path: '/inventory/products/:externalId/diluted/createDiluted',
    breadcrumb: 'breadCrumb.products.dilutedRegister',
  },
  {
    path: '/inventory/products/:externalId/associated',
    breadcrumb: 'breadCrumb.products.associated',
  },
  {
    path: '/inventory/products/:externalId/associated/createAssociated',
    breadcrumb: 'breadCrumb.products.associatedRegister',
  },
  {
    path: '/inventory/products/:externalId/incompatible/createIncompatible',
    breadcrumb: 'breadCrumb.products.registerIncompatibleProduct',
  },
  {
    path: '/inventory/products/:externalId/synonym',
    breadcrumb: 'breadCrumb.products.synonym',
  },
  {
    path: '/inventory/products/:externalId/synonym/createSynonym',
    breadcrumb: 'breadCrumb.products.registerSynonym',
  },
  {
    path: '/inventory/products/:externalId/inventory',
    breadcrumb: 'breadCrumb.products.inventory',
  },
  {
    path: '/inventory/products/:externalId/product-messages',
    breadcrumb: 'breadCrumb.products.productMessage',
  },
  {
    path: '/inventory/groups',
    breadcrumb: 'breadCrumb.products.group.list',
  },
  {
    path: '/inventory/groups/:externalId',
    breadcrumb: DynamicEditSubGroupOnGroupTitleBreadcrumb,
    props: { noRedirect: true },
  },
  {
    path: '/inventory/groups/:externalId/details',
    breadcrumb: 'breadCrumb.products.details',
  },
  {
    path: '/inventory/groups/:externalId/subgroups',
    breadcrumb: 'breadCrumb.products.subGroups',
  },
  {
    path: '/inventory/groups/:externalId/products',
    breadcrumb: 'breadCrumb.products.products',
  },
  {
    path: '/inventory/sub-groups',
    breadcrumb: 'breadCrumb.products.subGroup.list',
  },
  {
    path: '/inventory/sub-groups/:externalId',
    breadcrumb: DynamicEditSubGroupProductTitleBreadcrumb,
    props: { noRedirect: true },
  },
  {
    path: '/inventory/sub-groups/:externalId/details',
    breadcrumb: 'breadCrumb.products.details',
  },
  {
    path: '/inventory/sub-groups/:externalId/products',
    breadcrumb: 'breadCrumb.products.products',
  },
  {
    path: '/inventory/products/create',
    breadcrumb: 'breadCrumb.products.create',
  },
  {
    path: '/inventory',
    breadcrumb: 'breadCrumb.supply',
  },
  {
    path: '/inventory/supply-places',
    breadcrumb: 'breadCrumb.supplyPlaces.title',
  },
  {
    path: '/inventory/supply-places/create',
    breadcrumb: 'breadCrumb.supplyPlaces.create',
  },
  {
    path: '/inventory/supply-places/:externalId',
    breadcrumb: DynamicEditStorageLocationBreadcrumb,
    props: { noRedirect: true },
  },
  {
    path: '/inventory/supply-places/:externalId/:tab',
    breadcrumb: 'breadCrumb.supplyPlaces.detail.title',
  },
  {
    path: '/inventory/losses/create',
    breadcrumb: 'breadCrumb.losses.create',
  },
  {
    path: '/inventory/losses',
    breadcrumb: 'breadCrumb.losses.losses',
  },
  {
    path: '/inventory/losses/:externalId',
    breadcrumb: DynamicEditLosseBreadcrumb,
    props: { noRedirect: true },
  },
  {
    path: '/inventory/losses/:externalId/:tab',
    breadcrumb: 'breadCrumb.losses.detail.details',
  },

  {
    path: '/inventory',
    breadcrumb: 'breadCrumb.supply',
  },

  {
    path: '/inventory/lot',
    breadcrumb: 'breadCrumb.supplyRoutes.lots',
  },

  {
    path: '/inventory/lot/create',
    breadcrumb: 'breadCrumb.lots.create',
  },

  {
    path: '/inventory/lot/:externalId',
    breadcrumb: DynamicEditLotBreadcrumb,
    props: { noRedirect: true },
  },

  {
    path: '/inventory/lot/:externalId/details',
    breadcrumb: 'breadCrumb.lots.detail.details',
  },

  {
    path: '/inventory/lot/:externalId/stockLocations',
    breadcrumb: 'breadCrumb.lots.detail.stockLocations',
  },
  {
    path: '/inventory/products/:externalId/product-messages/:mensagemExternalId/messageDetail',
    breadcrumb: DynamicEditProductMessageTabSubTitleBreadcrumb,
  },

  {
    path: '/inventory/balance-adjustments',
    breadcrumb: 'breadCrumb.balanceAdjustments.list',
  },
  {
    path: '/inventory/balance-adjustments/create',
    breadcrumb: 'breadCrumb.balanceAdjustments.create',
  },
  {
    path: '/inventory/balance-adjustments/:externalId',
    breadcrumb: DynamicEditBalanceAdjustmentBreadcrumb,
    props: { noRedirect: true },
  },
  {
    path: '/inventory/balance-adjustments/:externalId/details',
    breadcrumb: 'Detalhes',
  },
  {
    path: '/inventory/transfers',
    breadcrumb: 'breadCrumb.supplyRoutes.transfers',
  },
  {
    path: '/inventory/transfers/create',
    breadcrumb: 'breadCrumb.transfers.create.breadCrumb',
  },
  {
    path: '/inventory/transfers/:externalId',
    breadcrumb: DynamicTransfersBreadcrumb,
    props: { noRedirect: true },
  },
  {
    path: '/inventory/transfers/:externalId/details',
    breadcrumb: 'Detalhes',
  },

  {
    path: '/inventory/product-message',
    breadcrumb: 'breadCrumb.messageProduct.productMensage',
  },
  {
    path: '/inventory/product-message/create',
    breadcrumb: 'breadCrumb.messageProduct.productMensageCreate',
  },
  {
    path: '/inventory/product-message/:externalId/details',
    breadcrumb: DynamicEditProductMessageSubTitleBreadcrumb,
  },
  {
    path: '/inventory/product-message/:externalId/linked-products',
    breadcrumb: DynamicEditProductMessageSubTitleBreadcrumb,
  },

  {
    path: '/sales',
    breadcrumb: 'breadCrumb.sales',
  },
  {
    path: '/sales/tickets',
    breadcrumb: 'breadCrumb.salesRoute.service',
  },
  {
    path: '/sales/tickets/:externalId',
    breadcrumb: DynamicTicketCodeBreadcrumb,
    props: { noRedirect: true },
  },
  {
    path: '/sales/tickets/:externalId/order',
    breadcrumb: 'breadCrumb.salesRoute.serviceOrder.order',
  },
  {
    path: '/sales/order',
    breadcrumb: 'breadCrumb.salesRoute.saleOrder',
  },
  {
    path: '/sales/prescribers',
    breadcrumb: 'breadCrumb.salesRoute.prescriber',
  },
  {
    path: '/sales/prescribers/create',
    breadcrumb: 'breadCrumb.salesRoute.createPrescriber',
  },
  {
    path: '/sales/prescribers/:externalId/details',
    breadcrumb: EditPrescribertBreadcrumb,
    props: { noRedirect: true },
  },
  {
    path: '/sales/expertise',
    breadcrumb: 'breadCrumb.salesRoute.specialties',
  },
  {
    path: '/sales/expertise/create',
    breadcrumb: 'breadCrumb.salesRoute.specialties',
  },
  {
    path: '/sales/expertise/:externalId',
    breadcrumb: EditSpecialtiesBreadcrumb,
    props: { noRedirect: true },
  },
  {
    path: '/sales/expertise/:externalId/prescribers',
    breadcrumb: 'breadCrumb.salesRoute.prescriber',
  },
  {
    path: '/sales/customers',
    breadcrumb: 'breadCrumb.salesRoute.client',
  },
  {
    path: '/sales/customers/create',
    breadcrumb: 'breadCrumb.salesRoute.createclient',
  },
  {
    path: '/sales/customers/:externalId',
    breadcrumb: EditClientBreadcrumb,
    props: { noRedirect: true },
  },
  {
    path: '/sales/customers/:externalId/tickets',
    breadcrumb: 'breadCrumb.salesRoute.service',
  },
  {
    path: '/sales/customers/:externalId/sale-order',
    breadcrumb: 'breadCrumb.salesRoute.saleOrder',
  },

  {
    path: '/order',
    breadcrumb: 'breadCrumb.purchase',
  },
  {
    path: '/order/purchase/create',
    breadcrumb: 'breadCrumb.purchasing.purchaseOrder.create',
  },
  {
    path: '/order/purchase/create/:externalId/draft',
    breadcrumb: 'breadCrumb.purchasing.purchaseOrder.create',
  },
  {
    path: '/order/purchase/:externalId/edit',
    breadcrumb: 'common.edit',
  },
  {
    path: '/order/purchase/:externalId/details',
    breadcrumb: DynamicPurchaseOrderBreadcrumb,
  },
  {
    path: '/order/purchase/:externalId/',
    breadcrumb: DynamicPurchaseOrderBreadcrumb,
    props: { noRedirect: true },
  },
  {
    path: '/order/purchase',
    breadcrumb: 'breadCrumb.purchasing.purchaseOrder.purchaseList',
  },

  {
    path: '/order/supplier/create',
    breadcrumb: 'breadCrumb.purchasing.provider.create.title',
  },
  {
    path: '/order/supplier/:externalId/:tab',
    breadcrumb: 'common.details',
  },
  {
    path: '/order/supplier/:externalId',
    breadcrumb: DynamicEditSupplierBreadcrumb,
    props: { noRedirect: true },
  },
  {
    path: '/order/supplier',
    breadcrumb: 'breadCrumb.purchasing.provider.title',
  },
  {
    path: '/order/invoice',
    breadcrumb: 'breadCrumb.purchasing.invoice.title',
  },
  {
    path: '/order/invoice/create',
    breadcrumb: 'breadCrumb.purchasing.invoice.create.title',
  },
  {
    path: '/order/invoice/create/:externalId/draft',
    breadcrumb: '',
  },
  {
    path: '/order/invoice/:externalId/details',
    breadcrumb: '',
  },

  {
    path: '/order/invoice/:externalId/register-lots',
    breadcrumb: 'breadCrumb.purchasing.invoice.registerLots.breadCrumb',
  },
  {
    path: '/order/invoice/:externalId/',
    breadcrumb: DynamicInvoiceBreadcrumb,
    props: { noRedirect: true },
  },
  {
    path: '/order/invoice/:externalId/edit',
    breadcrumb: 'breadCrumb.purchasing.invoice.edit',
  },

  {
    path: '/production',
    breadcrumb: 'breadCrumb.production.production',
  },
  {
    path: '/production/packing-classification/create',
    breadcrumb:
      'breadCrumb.production.packingClassification.packingClassificationCreate',
  },
  {
    path: '/production/packing-classification/:externalId/linkedProducts',
    breadcrumb: 'breadCrumb.production.packingClassification.productsLinked',
  },
  {
    path: '/production/packing-classification/:externalId',
    breadcrumb: DynamicEditPackingClassificationBreadcrumb,
    props: { noRedirect: true },
  },
  {
    path: '/production/packing-classification/:externalId/:tab',
    breadcrumb: 'breadCrumb.production.packingClassification.details',
  },
  {
    path: '/production/packing-classification',
    breadcrumb:
      'breadCrumb.production.packingClassification.packingClassificationList',
  },

  {
    path: '/production/capsule-colors',
    breadcrumb: 'breadCrumb.production.capsuleColors.capsuleColorsListTitle',
  },
  {
    path: '/production/capsule-colors/create',
    breadcrumb: 'breadCrumb.production.capsuleColors.capsuleColorsCreateTitle',
  },
  {
    path: '/production/capsule-colors/:externalId',
    breadcrumb: DynamicCapsuleColorBreadcrumb,
    props: { noRedirect: true },
  },
  {
    path: '/production/capsule-colors/:externalId/:tab',
    breadcrumb: 'breadCrumb.production.capsuleColors.details',
  },

  {
    path: '/production/posology',
    breadcrumb: 'breadCrumb.production.posology.posologyListTitle',
  },
  {
    path: '/production/posology/create',
    breadcrumb: 'breadCrumb.production.posology.posologyCreateTitle',
  },
  {
    path: '/production/posology/:externalId',
    breadcrumb: DynamicEditPosologyBreadcrumb,
    props: { noRedirect: true },
  },
  {
    path: '/production/posology/:externalId/:tab',
    breadcrumb: 'breadCrumb.production.posology.details',
  },

  {
    path: '/production/dosage-form/create',
    breadcrumb:
      'breadCrumb.production.pharmaceuticalForm.pharmaceuticalFormCreateTitle',
  },
  {
    path: '/production/dosage-form/:externalId',
    breadcrumb: DynamicEditPharmaceuticalFormBreadcrumb,
    props: { noRedirect: true },
  },
  {
    path: '/production/dosage-form/:externalId/:tab',
    breadcrumb: 'breadCrumb.production.pharmaceuticalForm.details',
  },
  {
    path: '/production/dosage-form',
    breadcrumb:
      'breadCrumb.production.pharmaceuticalForm.pharmaceuticalFormListTitle',
  },

  {
    path: '/production/labs/create',
    breadcrumb: 'breadCrumb.production.laboratory.laboratoryCreateTitle',
  },
  {
    path: '/production/labs/:externalId',
    breadcrumb: DynamicEditLaboratoryBreadcrumb,
    props: { noRedirect: true },
  },
  {
    path: '/production/labs/:externalId/:tab',
    breadcrumb: 'breadCrumb.production.pharmaceuticalForm.details',
  },
  {
    path: '/production/labs',
    breadcrumb: 'breadCrumb.production.laboratory.laboratoryListTitle',
  },

  {
    path: '/production/standard-formula/create',
    breadcrumb:
      'breadCrumb.production.standardFormula.standardFormulaCreateTitle',
  },
  {
    path: '/production/standard-formula/:externalId',
    breadcrumb: DynamicStandardFormulaBreadcrumb,
    props: { noRedirect: true },
  },
  {
    path: '/production/standard-formula',
    breadcrumb:
      'breadCrumb.production.standardFormula.standardFormulaListTitle',
  },
];
