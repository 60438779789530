import { DeleteModalOnTable } from '../../../../../../Utils/DeleteModalUtils';
import { DeleteModal } from './deleteModal';

export const TitleHandler = (selectedRowsOnTable: any, t: any) =>
  selectedRowsOnTable &&
  `${t('production.laboratory.deleteTitle')}  ${
    selectedRowsOnTable.length > 1
      ? `${t('common.deleteLaboratoryModal.laboratories')}?`
      : `${t('common.deleteLaboratoryModal.laboratory')} "${
          selectedRowsOnTable[0]?.laboratorio
        }"?`
  }`;

export const BodyHandler = (selectedRowsOnTable: any, t: any) =>
  selectedRowsOnTable && selectedRowsOnTable?.length > 1 ? (
    DeleteModalOnTable(
      selectedRowsOnTable,
      `${t('modal.deleteModal.many.title')} ${t('common.theLaboratory')}:`,
      'laboratorio',
      t('common.laboratory'),
      'empresa'
    )
  ) : (
    <DeleteModal selectedRowsOnTable={selectedRowsOnTable} />
  );
