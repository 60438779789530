import { IListRecordTypeData } from "@/Data/Interfaces/response/Record/IRecordResponse";
import { useMemo } from "react";
import { IUseMemorizations } from "./interfaces";

export const useRegisterInputMemorizations = ({ recordTypeList }: IUseMemorizations) => {
  const items = useMemo(() => {
    return recordTypeList?.data?.map((x: IListRecordTypeData) => ({
      id: x.id,
      label: `${x.sigla} (${x.descricao})`,
    }));
  }, [recordTypeList]);

  return {
    items
  };
};