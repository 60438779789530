import { useState } from 'react';

export const useEditStates = () => {
  const [disabled, setDisabled] = useState(true);
  const [visibleModal, setVisibleModal] = useState(false);
  return {
    disabled,
    setDisabled,
    visibleModal,
    setVisibleModal,
  };
};
