export const useCreateUserMemorizations = () => {
  const orderedErrors = [
    'nome', 
    'sobrenome', 
    'email', 
    'emailAlternativo', 
    'grupos'
  ];

  return {
    orderedErrors
  }
};