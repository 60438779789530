import { FC, useEffect } from 'react';
import { WeightInput } from '@/Components/Inputs/WeightInput';
import { Col } from 'antd';
import { useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';
import { useDosageInputsStates } from './Hooks/useDosageInputsStates';
import { useDosageInputsCallAPI } from './Hooks/useDosageInputsCallAPI';
import { IIListUnitMeasurementAllData } from '@/Data/Interfaces/response/UnitMeasurement/IUnitMeasurementResponse';
import { useDosageInputsFunctions } from './Hooks/useDosageInputsFunctions';
import { useDosageInputsMemorizations } from './Hooks/useDosageInputsMemorizations';

export const DosageInputs: FC = () => {
  const { t } = useTranslation();
  const form: any = useFormikContext();

  const { unitAbbreviation, setUnitAbbreviation } = useDosageInputsStates();

  const { unitMeasurementList } = useDosageInputsCallAPI();

  const { onChangeMaximalDosage } = useDosageInputsFunctions();

  const { dropdownRightMinimalDosage, dropdownRightAssociatedQuantity } =
    useDosageInputsMemorizations({
      unitMeasurementList,
      setUnitAbbreviation,
    });

  useEffect(() => {
    if (form.values.dosagemMinima && unitMeasurementList) {
      const unit = unitMeasurementList.data
        ? unitMeasurementList?.data?.find(
            (e: IIListUnitMeasurementAllData) =>
              e.id === form.values.unidadeMedidaDosagem
          )
        : undefined;
      setUnitAbbreviation(unit?.unidadeAbreviacao || '');
    }
  }, [form.values.dosagemMinima, unitMeasurementList]);

  return (
    <>
      <Col span={3}>
        <WeightInput
          name="dosagemMinima"
          label={t('products.diluted.minimalDosage')}
          dropdownRight={dropdownRightMinimalDosage}
          placeHolder="0,000"
          withTooltip={{
            icon: 'exclamation-upside-down',
            title: t('products.associated.minimalDosageTooltip'),
          }}
        />
      </Col>
      <Col span={3}>
        <WeightInput
          name="dosagemMaxima"
          label={t('products.diluted.maximalDosage')}
          placeHolder="0,000"
          rightIcon={{
            titleString: unitAbbreviation || 'mg',
          }}
          onChange={onChangeMaximalDosage}
          withTooltip={{
            icon: 'exclamation-upside-down',
            title: t('products.associated.maximalDosageTooltip'),
          }}
        />
      </Col>
      <Col span={3}>
        <WeightInput
          name="quantidadeAssociada"
          label={t('products.associated.associatedQuantity')}
          required
          placeHolder="0,000"
          withTooltip={{
            icon: 'exclamation-upside-down',
            title: t('products.associated.associatedQuantityTooltip'),
          }}
          dropdownRight={dropdownRightAssociatedQuantity}
        />
      </Col>
    </>
  );
};
