import { useState } from "react";

export const usePurchaseOrderInfoStates = () => {
  const [
    externalIdProvider, 
    setExternalIdProvider
  ] = useState<string>();

  return {
    externalIdProvider,
    setExternalIdProvider
  };
};