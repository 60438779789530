import { 
  IListAlternativeUnitMeasurementData 
} from '@/Data/Interfaces/response/UnitMeasurement/IUnitMeasurementResponse';
import { alternativeUnit2 } from '../../../utils';
import { IUseMemorizations } from './interfaces';

export const useAlternativeUnit2Memorizations = ({
  unidadeAlternativaList
}: IUseMemorizations) => {
  const utrUnitDefault = 9;
  const ufcMiUnitDefault = 14;

  const dropdownLeft = {
    name: 'informacaoTecnica.unidadeAlternativaId2',
    defaultValue: utrUnitDefault,
    options: unidadeAlternativaList.data
      ? unidadeAlternativaList?.data?.map(
          (x: IListAlternativeUnitMeasurementData) => ({
            id: x.id,
            content: x.unidadeAbreviacao,
          })
        )
      : [],
  };

  const dropdownRight = {
    name: 'informacaoTecnica.unidadeAlternativaConversaoId2',
    defaultValue: ufcMiUnitDefault,
    options:
      alternativeUnit2?.map((x) => ({
        id: x.id,
        content: x.description,
      })) || [],
  };

  return {
    dropdownLeft,
    dropdownRight
  };
};