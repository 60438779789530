import { Row } from 'antd';
import _ from 'lodash';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import styles from '../../../StorageLocation.module.scss';
import { RadioGroup } from '@/Components/StateInputs/RadioButton/RadioGroup';
import { RadioButton } from '@/Components/StateInputs/RadioButton';

interface IOtherRow {
  setSelectedOptionsStock: React.Dispatch<React.SetStateAction<number>>;
  selectedOptionsStock: number;
}

export const OtherRow: FC<IOtherRow> = ({
  setSelectedOptionsStock,
  selectedOptionsStock,
}) => {
  const { t } = useTranslation();

  return (
    <Row gutter={[16, 0]}>
      <RadioGroup
        className={styles['radio-button']}
        valueChecked={selectedOptionsStock}
      >
        <RadioButton
          value={1}
          label={t('supply.supplyPlaces.create.stockType.stockStorage')}
          onChange={() => setSelectedOptionsStock(1)}
        />
        <RadioButton
          value={2}
          label={t('supply.supplyPlaces.create.stockType.stockSale')}
          onChange={() => setSelectedOptionsStock(2)}
        />
      </RadioGroup>
    </Row>
  );
};
