import { useCallback } from 'react';
import _ from 'lodash';
import { IUseFunctions } from './interfaces';

export const useCreateFunctions = ({
  fetchDescriptionLaboratory,
}: IUseFunctions) => {
  const fetchInputCompanyDescription = useCallback(
    _.debounce(async (form: any, value: string) => {
      try {
        return await fetchDescriptionLaboratory({
          descricao: value,
          empresaExternalId: form.values.empresaExternalId,
        });
      } catch {
        return undefined;
      }
    }, 500),
    [fetchDescriptionLaboratory]
  );

  const fetchInputCompanyInput = useCallback(
    _.debounce(async (form: any, value: string) => {
      try {
        return await fetchDescriptionLaboratory({
          descricao: form.values.nomeLaboratorio,
          empresaExternalId: value,
        });
      } catch {
        return undefined;
      }
    }, 500),
    [fetchDescriptionLaboratory]
  );

  return {
    fetchInputCompanyDescription,
    fetchInputCompanyInput,
  };
};
