import { FC } from 'react';
import { BodyModal } from '@/Components/Modal';
import {
  ICommonError
} from '@/Utils/ErrorCodeUtils/ErrorHandlingUtils';
import { Text } from '@/Components';
import { useTranslation } from 'react-i18next';
import { ReactMarkdown } from 'react-markdown/lib/react-markdown';

export const Content: FC<ICommonError> = ({ content }) => {
  const { t } = useTranslation();
  
  return (
    <>
      <BodyModal
        body={
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <Text type="ui-tiny-content">
              <ReactMarkdown>
                {t('purchasing.purchaseOrder.modal.cannotDelete.subTitle', {
                  orderPurchaseNumber: `**${content[0].numero}**`,
                })}
              </ReactMarkdown>
            </Text>

            <Text type="ui-tiny-content" style={{ marginTop: 25 }}>
              {t('purchasing.purchaseOrder.modal.cannotDelete.description')}
            </Text>
          </div>
        }
        style={{ padding: 20 }}
      />
    </>
  );
};