import { Http, IQueryListParams } from '@/Utils/Http';
import { QueryFunctionContext } from '@tanstack/react-query';

export interface IListCstResponse {
  pageIndex: number;
  pageSize: number;
  count: number;
  data: ICstListData[];
}

export interface ICstListData {
  id: number;
  codigo: string;
  descricao: string;
}

export const ListCst = async (
  context: QueryFunctionContext
): Promise<IListCstResponse | undefined> => {
  return await Http.fetcher<IListCstResponse>(
    Http.AddQueryParams(
      '/pharma/compras/v1/ListarCstCsosn',
      context.queryKey[1] as IQueryListParams
    )
  );
};
