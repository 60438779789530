import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Modal } from '../../../../../../../../Components/Modal';
import { IGetStorageLocationResponse } from '../../../../../../../../Data/Interfaces/response/StorageLocation/IStorageLocationResponse';
import { Divflex } from '../../../../../../../../Layouts/DivWhithFlex';
import { Text } from '../../../../../../../../Components';
import { FooterThisActionIsPermanent } from '../../../../../../../../Components/Table/components/Modals/DeleteModal';

interface IDeleteStorageLocationModal {
  storageLocation: IGetStorageLocationResponse;
  visibleModal: boolean;
  changeVisibleModal: (value: boolean) => void;
  onDelete: () => Promise<any>;
}

export const DeleteStorageLocationModal: FC<IDeleteStorageLocationModal> = ({
  storageLocation,
  visibleModal,
  changeVisibleModal,
  onDelete,
}) => {
  const { t } = useTranslation();

  return (
    <Modal
      title={`${t('common.deleteStockLocationDetail')}  "${
        storageLocation?.descricao
      }"?`}
      body={
        <div style={{ padding: '20px' }}>
          <Divflex style={{ flexDirection: 'column', marginBottom: 10 }}>
            <Text
              color="text-50"
              type="ui-tiny-content"
              children={`${t(
                'supply.supplyPlaces.details.modal.messageQuestionDelete'
              )} `}
            />

            <Text
              color="text-50"
              type="ui-tiny-bold"
              children={`${storageLocation?.descricao}?`}
            />
          </Divflex>
          <FooterThisActionIsPermanent />
        </div>
      }
      okButtonColor="status-danger-base"
      visible={visibleModal}
      onOkClick={onDelete}
      okButtonName={t('common.delete')}
      onCancelClick={() => changeVisibleModal(false)}
      onClose={() => changeVisibleModal(false)}
      widthModal={399}
      okButtonType="danger"
    />
  );
};
