import { Link, Status } from '../../../../../Components';

export const laboratoryColumns = (t: any) => [
  {
    title: t('production.laboratory.list.laboratoryName'),
    dataIndex: 'laboratorio',
    key: 'laboratorio',
    sortColumn: 'laboratorio',
    isHighlightable: true,
    sorter: true,
    defaultSortOrder: 'ascend',
    render: (order: string, row: any) => (
      <Link
        type="ui-tiny-bold"
        to={`/production/labs/${row.externalId}/details`}
        style={{ width: 'fit-content' }}
      >
        {order}
      </Link>
    ),
  },
  {
    title: t('production.laboratory.list.company'),
    dataIndex: 'empresa',
    key: 'empresa',
    sortColumn: 'empresa',
    width: '40%',
    isHighlightable: true,
  },

  {
    title: t('production.laboratory.list.supplyPlace'),
    dataIndex: 'localEstoque',
    key: 'localEstoque',
    sortColumn: 'localEstoque',
    width: '25%',
    isHighlightable: true,
  },

  {
    title: t('production.laboratory.list.status'),
    dataIndex: 'ativo',
    key: 'ativo',
    width: '10%',
    render: (prop: any) => (
      <Status type={prop ? 'success' : 'disabled'}>
        {t(
          `production.laboratory.list.statusTypes.${
            prop ? 'active' : 'inactive'
          }`
        )}
      </Status>
    ),
  },
];
