import React, { FC, ReactNode, useCallback, useEffect, useState } from 'react';
import { Select, Spin } from 'antd';
import { Label } from '../../Inputs/Label';
import { Icon, Status, Text } from '../..';
import { useTranslation } from 'react-i18next';
import Spinner from '../../Spinner';
import { Flag } from '../../Flag';
import { IInputLabel } from '../../Inputs/Dropdown';
import { Avatar } from '../../Avatar';
import { getScrollPercentage } from '../../../Utils/ScrollUtils';
import { statusTypes } from '../../../Utils/StatusUtils';

import styles from './Dropdown.module.scss';
import '@static/scss/SelectStatic/Select.override.scss';

export interface IDropdown {
  items:
    | {
        id: any;
        name: string;
        avatar?: { name: string; lastName: string };
        img?: ReactNode;
        status?: statusTypes;
        statusDarkmode?: boolean;
      }[]
    | undefined;
  label?: IInputLabel | string;
  placeholder?: string;

  onFocus?: (event: React.FocusEvent<HTMLElement>) => void;
  onBlur?: (event: React.FocusEvent<HTMLElement>) => void;
  onChange?: (value: string) => void;
  onScrollEnd?: () => void;

  className?: string;

  width?: string;
  disabled?: boolean;
  isLoading?: boolean | undefined;

  value?: string;
  allowClear?: boolean;

  dataTestId?: string;
}

const { Option } = Select;

export const Dropdown: FC<IDropdown> = ({
  items,
  label,
  className,
  width,
  placeholder,
  isLoading,
  disabled,
  value,
  onChange,
  onScrollEnd,
  allowClear = true,
  dataTestId,
}) => {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState<boolean>();

  const { t } = useTranslation();

  useEffect(() => {
    items && isLoading ? setLoading(true) : setLoading(false);
  }, [items, isLoading]);

  const filtrarDados = (input: any, option: any): boolean => {
    return option?.children?.toLowerCase().indexOf(input.toLowerCase()) >= 0;
  };

  const onScroll = useCallback(
    (event: React.UIEvent<HTMLElement>) => {
      const scrollPercentage = getScrollPercentage(event);
      const scrollPercentageThreshold = 80;

      // Call hook when you scroll to 80% or more
      if (scrollPercentage > scrollPercentageThreshold && !isLoading) {
        onScrollEnd && onScrollEnd();
      }
    },
    [onScrollEnd, isLoading]
  );

  return (
    <div className={`${styles['Dropdown']} ${className}`}>
      {typeof label === 'string' ? (
        <Label
          type={'ui-tiny-semibold'}
          color={'text-50'}
          className={styles['label']}
          children="Status"
        />
      ) : (
        label && (
          <Label
            type={label.typeLabel || 'ui-tiny-semibold'}
            color={label.colorLabel || 'text-50'}
            className={styles['label']}
            children={label.children}
          />
        )
      )}

      <div style={{ display: 'flex' }}>
        <div
          style={{ maxWidth: width, minWidth: width, position: 'relative' }}
          className={`${
            items && items.find((item) => item.img) ? 'with-flag' : ''
          } ${styles['divDropdown']}`}
        >
          {items && items.find((item) => item.img) && (
            <div className={styles['div-flag']} />
          )}
          <Select
            onPopupScroll={onScroll}
            disabled={disabled}
            style={{ width: width }}
            placeholder={placeholder || t('dropDown.placeHolder')}
            open={open}
            onChange={onChange}
            onKeyUp={() => setOpen(true)}
            onClick={() => setOpen(!open)}
            onBlur={() => setOpen(false)}
            onSelect={() => setOpen(false)}
            filterOption={filtrarDados}
            className={`bootys-dropdown ${
              isLoading ? 'dropdown-is-loading' : ''
            } ${className}`}
            allowClear={allowClear}
            suffixIcon={<Icon icon="chevron-down" color="text-50" size="M" />}
            notFoundContent={
              isLoading ? <Spin tip="Buscando..."></Spin> : undefined
            }
            getPopupContainer={(trigger) => trigger.parentNode}
            value={value}
            data-testid={dataTestId}
          >
            {loading ? (
              <Option className={'dropdown-body-spinner'} value={1}>
                <Spinner />
              </Option>
            ) : !isLoading && items && items.length === 0 ? (
              <Text
                className={'dropdown-empty'}
                type="small-text"
                color="text-400"
                children={t('dropDown.empty')}
              />
            ) : (
              items &&
              items.length > 0 &&
              items.map((x, index) => {
                if (x.status) {
                  return (
                    <Option
                      className={styles['option']}
                      key={`dropdown-${index}`}
                      value={x.id}
                    >
                      <div className={`${styles['statusContent']}`}>
                        <Status type={x.status as any} children={x.name} />
                      </div>
                    </Option>
                  );
                }

                if (x.avatar) {
                  return (
                    <Option
                      className={`${styles['option']} ${styles['option-avatar']}`}
                      key={`dropdown-${index}`}
                      value={x.id}
                    >
                      <div className={`${styles['divContent']}`}>
                        <Avatar
                          fisrtName={x.avatar.name}
                          lastName={x.avatar.lastName}
                          size="SM"
                          type="paragraph2-bold"
                          color="white"
                        />
                        <div className={styles['div-avatar']}>
                          <Text
                            className="text-input-simple-avatar"
                            type="ui-sub-content"
                            color="text-50"
                            children={`${x.avatar.name} ${
                              x.avatar.lastName || ''
                            }`}
                          />
                        </div>
                      </div>
                    </Option>
                  );
                }

                return (
                  <Option
                    className={styles['option']}
                    key={`dropdown-${index}`}
                    value={x.id}
                  >
                    <div className={`${styles['divContent']}`}>
                      {x.img && <Flag image={x.img} />}
                      <Text
                        type="ui-sub-content"
                        color="text-50"
                        children={x.name}
                      />
                    </div>
                  </Option>
                );
              })
            )}
          </Select>
          {isLoading && (
            <div className={styles['spinner']}>
              <Spinner />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
