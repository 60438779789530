import { Avatar } from '@/Components/Avatar';
import { Icon } from '@/Components/Icon';
import { TextShortner } from '@/Components/TextShortner';
import { Divflex } from '@/Layouts/DivWhithFlex';
import { FC } from 'react';
import styles from './titleAvatar.module.scss';
import { Link } from 'react-router-dom';

export interface ITitleAvatarPopOver {
  avatar: {
    name?: string;
    lastName?: string;
  };
  subTitle: {
    typeUser?: string;
    code?: number;
  };

  link: string;
}

export const TitleAvatarPopover: FC<ITitleAvatarPopOver> = ({
  avatar,
  subTitle,
  link,
}) => {
  return (
    <Divflex className={styles['container']}>
      <Avatar
        fisrtName={avatar.name}
        lastName={avatar.lastName}
        size="M"
        type="small-text-bold"
        color="white"
      />
      <div
        style={{
          display: 'block',
          margin: '-2px 0px 5px 9px',
        }}
      >
        <TextShortner
          type="ui-sub-bold"
          color="text-50"
          children={`${avatar.name} ${avatar.lastName ? avatar.lastName : ''}`}
          maxCharacters={23}
          width={191}
        />
        <TextShortner
          type="ui-tiny-content"
          color="text-300"
          children={`${subTitle.typeUser} • #${subTitle.code}`}
          maxCharacters={30}
          style={{ width: '130px' }}
        />
      </div>

      <Link to={link} target="_blank" rel="noopener noreferrer">
        <Icon
          icon="external-link"
          size="M"
          color="text-300"
          className={styles['link-icon']}
        />
      </Link>
    </Divflex>
  );
};
