import { useCallback } from 'react';
import { handleModalError } from '@/Utils/ErrorCodeUtils/ErrorHandlingUtils';
import { App } from 'antd';
import { useTranslation } from 'react-i18next';
import { IUseFunctions } from './interfaces';
import { IProductMessageListData } from '@/Data/Interfaces/response/ProductMensage/IProductMensageResponse';
import { cannotDeleteProductMessagermWithMovementsInTheSystemProps } from '@/Utils/ErrorCodeUtils/ErrorModalUtils/ErrorModalProductMessage';
import { useQueryClient } from '@tanstack/react-query';

export const useListFunctions = ({ deleteProductMessage }: IUseFunctions) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const app = App.useApp();

  const onDelete = useCallback(
    async (message: IProductMessageListData[]) => {
      await deleteProductMessage(
        { mensagemExternalIds: message.map((x) => x?.externalId) },
        {
          onError: handleModalError(
            app,
            cannotDeleteProductMessagermWithMovementsInTheSystemProps(t)
          ),
        }
      );
    },
    [deleteProductMessage, queryClient]
  );

  return {
    onDelete,
  };
};
