import { AuthAPI } from '@/Data/API/Identity/AuthApi';
import { useControllerMutateApiHook } from '@/ControllerApiHook/Controller';
import { CURRENT_USER } from '@/Utils/ConstantsUtils';
import { setRoles } from '@/Utils/PermissionUtils';
import _ from 'lodash';
import { Cookies } from 'react-cookie';
import { ILoginResponse } from '@/Data/Interfaces/response/Auth/ILoginResponse';
import { AUTH_TYPE } from '@/ControllerApiHook/UniqIds/Identity/AuthHooks';
import { GET_ME } from '@/ControllerApiHook/UniqIds/Accountmanager/UserKeys';
import { useQueryClient } from '@tanstack/react-query';
import React from 'react';
import { Context, CurrentContextType } from '@/Contexts/context';
import { GET_COMPANY_CONFIG_TYPE } from '@/ControllerApiHook/UniqIds/Accountmanager/CompanyKeys';
import { keepDataStorageLogin } from '@/Utils/ThemeUtils';

export const useLoginCallAPI = () => {
  const queryClient = useQueryClient();

  const { updateContext } = React.useContext(Context) as CurrentContextType;

  const authLogin = useControllerMutateApiHook({
    uniqId: AUTH_TYPE,
    entityApi: AuthAPI.login,
    options: {
      onSuccess: (data: ILoginResponse) => {
        updateContext(undefined);
        queryClient.invalidateQueries([GET_ME]);
        queryClient.invalidateQueries([GET_COMPANY_CONFIG_TYPE]);
        const cookies = new Cookies();
        const dataAux = _.omit(data, 'preference');
        cookies.set(CURRENT_USER, dataAux, { path: '/' });
        setRoles(data?.access);
        keepDataStorageLogin({
          contrasteAumentado: data.preference.contrastIncreased,
          idioma: data.preference.language,
          padraoData: data.preference.dateDefault,
          padraoHora: data.preference.hourDefault,
          temaUsuario: data.preference.theme,
          textoAmpliado: data.preference.extendedText,
          textoNegrito: data.preference.boldText,
          timeZone: data.preference.timeZone,
          tipoMoedaId: 0,
        });
      },
    },
  });

  return { authLogin };
};
