import { FormikHelpers, FormikValues } from 'formik';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Dropdown, Form } from '../../../Components';
import { TableLayout } from '../../../Components/Tabs/TablePreference';
import { IPatchCurrencyRequest } from '../../../Data/Interfaces/request/Company/ICompanyRequest';
import { isFlag } from './Currency.helpers';
import { useControllerQueryApiHook } from '@/ControllerApiHook/Controller';
import { GET_CURRENCY } from '@/ControllerApiHook/UniqIds/Accountmanager/CurrencyHooks';
import { CompanyAPI } from '@/Data/API/Accountmanager/CompanyApi';
import { IGetCurrencyResponse } from '@/Data/Interfaces/response/Company/ICompanyResponse';
import StorageUtils from '@/Utils/StorageUtils';

import styles from './Currency.module.scss';

export const Currency: React.FC = () => {
  const [buttonDisabled, setButtonDesabled] = useState(true);

  const { t } = useTranslation();

  const { data: currency } = useControllerQueryApiHook<IGetCurrencyResponse>({
    uniqId: GET_CURRENCY,
    entityApi: CompanyAPI.getCurrency,
  });

  const preferences = StorageUtils.getPreference();

  const onSubmit = (values: any, helpers: FormikHelpers<FormikValues>) => {
    const body: IPatchCurrencyRequest = { ...values, helpers };
  };

  currency && isFlag(currency);

  return (
    <TableLayout
      title={t('systemConfig.currencyPage.title')}
      description={t('systemConfig.currencyPage.description')}
      body={
        <Form
          className={styles['form']}
          onSubmit={onSubmit}
          initialValues={{ tipoMoedaId: preferences?.tipoMoedaId }}
        >
          <Dropdown
            name="tipoMoedaId"
            label={t('systemConfig.currencyPage.inputCurrency')}
            items={currency?.moedas}
            onChange={() => setButtonDesabled(false)}
          />
          <Button
            className={styles['button']}
            type="primary"
            children={t('systemConfig.currencyPage.button')}
            disabled={buttonDisabled}
            htmlType="submit"
          />
        </Form>
      }
    />
  );
};
