import { CurrencyInput } from '@/Components/Inputs/CurrencyInput';
import { Col, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import { useGetTotalProducts } from './hooks/useGetTotalproducts';

export const RowOne: React.FC = () => {
  const { t } = useTranslation();
  const { getTotalProduct } = useGetTotalProducts();

  return (
    <Row gutter={[16, 0]}>
      <Col span={4}>
        <CurrencyInput
          label={t('purchasing.invoice.create.ICMSBase')}
          name="valorBaseIcms"
          placeHolder={t(
            'purchasing.invoice.create.placeHolder.CurrencyPlaceHolder'
          )}
        />
      </Col>
      <Col span={4}>
        <CurrencyInput
          label={t('purchasing.invoice.create.ICMSValue')}
          name="valorIcms"
          placeHolder={t(
            'purchasing.invoice.create.placeHolder.CurrencyPlaceHolder'
          )}
        />
      </Col>
      <Col span={4}>
        <CurrencyInput
          label={t('purchasing.invoice.create.ICMSBaseSubstitution')}
          name="valorBaseIcmsSubstituicao"
          placeHolder={t(
            'purchasing.invoice.create.placeHolder.CurrencyPlaceHolder'
          )}
        />
      </Col>
      <Col span={4}>
        <CurrencyInput
          label={t('purchasing.invoice.create.ICMSValueSubstitution')}
          name="valorIcmsSubstituicao"
          placeHolder={t(
            'purchasing.invoice.create.placeHolder.CurrencyPlaceHolder'
          )}
        />
      </Col>
      <Col span={4}>
        <CurrencyInput
          label={t('purchasing.invoice.create.totalProducts')}
          name="valorProdutos"
          placeHolder={t(
            'purchasing.invoice.create.placeHolder.CurrencyPlaceHolder'
          )}
          disabled
          value={getTotalProduct()}
        />
      </Col>
    </Row>
  );
};
