import { FC } from "react";
import { TextInput } from '@/Components';
import { Col } from 'antd';
import { useTranslation } from "react-i18next";

export const Name: FC = () => {
  const { t } = useTranslation();

  return (
    <Col flex="36%">
      <TextInput
        name="nomeCompleto"
        placeHolder={`${t('common.exampleAbbreviation')}: Marcos Almeida`}
        label={t('prescribers.createPage.info.name')}
        required
      />
    </Col>
  );
};