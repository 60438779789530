import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { TextInput } from '@/Components/Inputs/TextInput';
import { useUserGroupNameFieldMemorizations } from './Hooks/useUserGroupNameFieldMemorizations';

export const UserGroupNameField: FC = () => {
  const { t } = useTranslation();

  const { thereIsUserGroupName } = useUserGroupNameFieldMemorizations();

  return (
    <TextInput
      name="nome"
      dataTestId='nome'
      label={t('admin.createUserGroupPage.detaildCard.name')}
      placeHolder={t('admin.createUserGroupPage.detaildCard.namePlaceholder')}
      required
      onChange={(x) => thereIsUserGroupName(x)}
    />
  );
};
