import { useCallback } from 'react';
import { IUseFunctions } from './interfaces';

export const useCreateFunctions = ({ onLotSelectedInfo }: IUseFunctions) => {
  const onLotSelected = useCallback((lotExternalId: string) => {
    onLotSelectedInfo(lotExternalId);
  }, []);

  return { onLotSelected };
};
