import { history } from '../../../../../../Store';
import { useTranslation } from 'react-i18next';
import { IUseMemorizations } from './interfaces';
import { useMemo } from 'react';
import { PermissionEnum } from '@/Data/Interfaces/model/permissionEnum';
import StorageUtils from '@/Utils/StorageUtils';

export const useListMemorizations = ({
  setVisibleCreateModal,
}: IUseMemorizations) => {
  const { t } = useTranslation();

  const headerOptions = useMemo(
    () => ({
      hasSearch: true,
      searchPlaceholder: t('callCenter.listPage.searchPlaceholder'),
      newButton: {
        label: t('callCenter.listPage.newButton'),
        onClick: () => setVisibleCreateModal(true),
        permission: {
          permission: PermissionEnum.Vendas_Atendimentos_Cadastrar,
          tooltip: t('noPermission.sale.service.create'),
        },
        dataTestId: 'ticket-test-id'
      },
      editButton: {
        options: [
          {
            label: t('common.status'),
            isStatusOnly: true,
          },
        ],
      },
    }),
    [t, setVisibleCreateModal]
  );

  const rowOptions = useMemo(
    () => ({
      onRowDoubleClick: (x: any) =>
        history.push(`/sales/tickets/${x.externalId}/order`),
      permission: {
        permission: PermissionEnum.Vendas_Atendimentos_VerDetalhes,
        tooltip: t('noPermission.sale.service.detail'),
      },
    }),
    [t]
  );

  const preferences = useMemo(() => StorageUtils.getPreference(), []);

  return {
    preferences,
    headerOptions,
    rowOptions,
  };
};
