import { TFunction } from 'react-i18next';
import { ICannotDeleteModalInfo } from '../ErrorHandlingUtils';

export const cannotDeleteStandardFormulaWithMovementsInTheSystemProps = (
  t: TFunction<'translation', undefined>
): ICannotDeleteModalInfo => ({
  formName: 'descricao',
  oneErrorModal: {
    titleModal: `${t('common.modalCannotDelete.messageTitle')} ${t(
      'production.standardFormula.modalCannotDelete.messageTitle'
    )}`,
    subTitleModal: `${t('common.modalCannotDelete.canNotDelete')} ${t(
      'production.standardFormula.modalCannotDelete.messageSubtitle'
    )}`,
    reasonTranslation: `${t(
      'production.standardFormula.modalCannotDelete.messageReasonCannotDelete'
    )}`,
  },
  manyErrorsModal: {
    entity: `${t('common.modalCannotDelete.messageTitle')} ${t(
      'production.standardFormula.modalCannotDelete.messageTitle'
    )}`,
    subTitleModal: `${t('common.modalCannotDelete.canNotDelete')} ${t(
      'production.standardFormula.modalCannotDelete.messageSubtitle'
    )}`,
    reasonTranslation: `${t(
      'production.standardFormula.modalCannotDelete.messageReasonCannotDelete'
    )}`,
  },
});
