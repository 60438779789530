import { useCallback } from 'react';
import { IUseFunctions } from './interfaces';

export const useCapsuleColorFunctions = ({
  refetchCapsuleColor,
}: IUseFunctions) => {
  const onSearch = useCallback(
    (search?: string) => {
      refetchCapsuleColor({
        search: search,
      });
    },
    [refetchCapsuleColor]
  );

  return { onSearch };
};
