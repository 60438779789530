import { useCallback } from 'react';
import { createPermissionRequest } from '@/Data/Interfaces/request/User/IUpdatePermissionRequest';
import { IUseCardsPermissionFunctions } from './interfaces';

export const useCardsPermissionFunctions = ({
  updateUserPermission,
  user,
}: IUseCardsPermissionFunctions) => {
  const onPermissionChange = useCallback(
    (
      permission: { id: string; name: String },
      prevValue: boolean | 'not-inherited' | 'inherited',
      nextValue: boolean | 'not-inherited' | 'inherited'
    ) => {
      const params = createPermissionRequest(
        user?.externalId!,
        permission.id,
        nextValue
      );

      updateUserPermission.mutateAsync(params);
    },
    [updateUserPermission, user]
  );

  return { onPermissionChange };
};
