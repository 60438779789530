export const GET_UNITS_MEASURE_CONVERSION =
  '[UNIT_MEASUREMENT] CREATE_UNITS_MEASURE_CONVERSION';

export const FETCH_LIST_UNITS_BY_CLASS =
  '[UNIT_MEASUREMENT] FETCH_LIST_UNITS_BY_CLASS';
export const FETCH_LIST_ALTERNATIVE_UNITS =
  '[UNIT_MEASUREMENT] FETCH_LIST_ALTERNATIVE_UNITS';
export const FETCH_LIST_UNIT_MEASUREMENT_FOR_DROPDOWN_TYPE =
  '[UNIT_MEASUREMENT] FETCH_LIST_UNIT_MEASUREMENT_FOR_DROPDOWN_TYPE';
export const FETCH_LIST_UNIT_MEASUREMENT_PRESCRIPTION_FOR_DROPDOWN_TYPE =
  '[UNIT_MEASUREMENT] FETCH_LIST_UNIT_MEASUREMENT_PRESCRIPTION_FOR_DROPDOWN_TYPE';
export const FETCH_LIST_UNIT_MEASUREMENT_POSOLOGY =
  '[UNIT_MEASUREMENT] FETCH_LIST_UNIT_MEASUREMENT_POSOLOGY';
