import { FC, useEffect } from 'react';
import { useField, useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';
import { v4 as uuidV4 } from 'uuid';
import { Card, Icon, Text } from '@/Components';
import { ProductLine } from './Components/ProductLine';
import { useDispatch } from 'react-redux';
import { PurchasingActions } from '@/Store/Purchasing/Purchasing.actions';
import { useParams } from 'react-router';
import { Divflex } from '../../../../../../../../Layouts/DivWhithFlex';
import { useProductCardStates } from './Hooks/useProductCardStates';
import { useProductCardCallAPI } from './Hooks/useProductCardCallAPI';
import { useProductCardCallFunctions } from './Hooks/useProductCardFunctions';

export const PurchaseOrderProductEditCard: FC = () => {
  const form: any = useFormikContext();
  const totalItems = useField('pedidoCompraItens');
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { externalId } = useParams<{ externalId: string }>();

  const { productRowAmount, setProductRowAmount } = useProductCardStates();

  const { orderPurchase } = useProductCardCallAPI({
    externalId,
  });

  const { onDelete } = useProductCardCallFunctions({
    productRowAmount,
    setProductRowAmount,
  });

  useEffect(() => {
    if (orderPurchase && orderPurchase.pedidoCompraItens) {
      const additionalRow = uuidV4();
      const existingRows = orderPurchase.pedidoCompraItens.map(
        (item: any) => item.id
      );

      const updatedRows = [additionalRow, ...existingRows];

      setProductRowAmount(updatedRows);
    }
  }, [orderPurchase]);

  useEffect(() => {
    dispatch(PurchasingActions.setTotalValuesCard(totalItems[1].value));
  }, [totalItems]);

  return (
    <>
      <Card
        title={t('purchasing.purchaseOrder.create.orderItems')}
        withoutCollapse={true}
      >
        {productRowAmount?.map((uuid, index) => (
          <ProductLine
            key={uuid}
            index={index}
            onDelete={onDelete}
            totalRows={productRowAmount.length}
            uuidV4={uuidV4}
            setProductRowAmount={setProductRowAmount}
            orderPurchase={orderPurchase}
          />
        ))}
        {form?.values?.pedidoCompraItens?.length === 1 && (
          <Divflex style={{ marginTop: 7 }}>
            <Icon
              icon={'exclamation-warning'}
              size={'SM'}
              color={'status-danger-base'}
            />
            <Text
              type="paragraph2"
              color={'status-danger-base'}
              style={{
                marginLeft: '6px',
                marginTop: '-1px',
                cursor: 'pointer',
                fontSize: 'var(--font-size-xxs)',
              }}
            >
              {t('purchasing.purchaseOrder.create.dangerMessage')}
            </Text>
          </Divflex>
        )}
      </Card>
    </>
  );
};
