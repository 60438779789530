import { FC } from 'react';
import { Col } from 'antd';
import { IInitialQuantity } from './interfaces';
import { useInitialQuantityCallAPI } from './Hooks/useInitialQuantityCallAPI';
import { useTranslation } from 'react-i18next';
import { IListUnitMeasurementByClassData } from '@/Data/Interfaces/response/UnitMeasurement/IUnitMeasurementResponse';
import { WeightInput } from '@/Components/Inputs/WeightInput';

export const InitialQuantity: FC<IInitialQuantity> = ({ typeClassProduct }) => {
  const { t } = useTranslation();

  const { listunitsMeasurements } = useInitialQuantityCallAPI({
    typeClassProduct,
  });

  const grUnitMeasurementDefault = 7;

  return (
    <Col span={4}>
      <WeightInput
        name="saldoInicial.quantidade"
        label={t('supply.lot.create.quantityInitial')}
        placeHolder={t('supply.lot.placeholder.quantityInitial')}
        dropdownRight={{
          name: 'saldoInicial.unidadeId',
          defaultValue: grUnitMeasurementDefault,
          options:
            listunitsMeasurements?.data?.map(
              (x: IListUnitMeasurementByClassData) => ({
                id: x.id,
                content: x.unidadeAbreviacao,
              })
            ) || [],
        }}
      />
    </Col>
  );
};
