export const GET_PRESCRIBER = '[PRESCRIBERS] GET_PRESCRIBER';
export const GET_SIMPLIFIED_PRESCRIBER =
  '[PRESCRIBERS] GET_SIMPLIFIED_PRESCRIBER';

export const FETCH_LIST_PRESCRIBERS = '[PRESCRIBERS] FETCH_LIST_PRESCRIBERS';
export const FETCH_SIMPLE_LIST_PRESCRIBERS =
  '[PRESCRIBERS] FETCH_SIMPLE_LIST_PRESCRIBERS';

export const CREATE_PROVIDER = '[PROVIDER] CREATE_PROVIDER';
export const EDIT_INFO_PRESCRIBERS = '[PRESCRIBERS] EDIT_INFO_PRESCRIBERS';
export const DELETE_PRESCRIBER = '[PRESCRIBERS] DELETE_PRESCRIBER';
export const CREATE_FAST_PROVIDER = '[PROVIDER] CREATE_FAST_PROVIDER';
export const ADD_CONTACT_PRESCRIBERS = '[PRESCRIBERS] ADD_CONTACT_PRESCRIBERS';
export const EDIT_CONTACT_PRESCRIBERS =
  '[PRESCRIBERS] EDIT_CONTACT_PRESCRIBERS';
export const DELETE_CONTACT_PRESCRIBERS =
    '[PRESCRIBERS] DELETE_CONTACT_PRESCRIBERS';
export const ADD_ADRESS_PRESCRIBERS = '[PRESCRIBERS] ADD_ADRESS_PRESCRIBERS';
export const EDIT_ADRESS_PRESCRIBERS = '[PRESCRIBERS] EDIT_ADRESS_PRESCRIBERS';
export const DELETE_ADRESS_PRESCRIBERS =
  '[PRESCRIBERS] DELETE_ADRESS_PRESCRIBERS';
export const ADD_DOCUMENT_PRESCRIBERS =
  '[PRESCRIBERS] ADD_DOCUMENT_PRESCRIBERS';
export const EDIT_DOCUMENT_PRESCRIBERS =
  '[PRESCRIBERS] EDIT_DOCUMENT_PRESCRIBERS';
export const DELETE_DOCUMENT_PRESCRIBERS =
  '[PRESCRIBERS] DELETE_DOCUMENT_PRESCRIBERS';

export const CHANGE_PRESCRIBER_STATUS =
  '[PRESCRIBERS] CHANGE_PRESCRIBER_STATUS';
export const CHANGE_FAVORITE_CONTACT_PRESCRIBERS =
  '[PRESCRIBERS] CHANGE_FAVORITE_CONTACT_PRESCRIBERS';
export const CHANGE_FAVORITE_ADRESS_PRESCRIBERS =
  '[PRESCRIBERS] CHANGE_FAVORITE_ADRESS_PRESCRIBERS';
export const CHANGE_FAVORITE_DOCUMENT_PRESCRIBERS =
  '[PRESCRIBERS] CHANGE_FAVORITE_DOCUMENT_PRESCRIBERS';
export const VALIDATE_REGISTRATION_PRESCRIBER =
  '[PRESCRIBERS] VALIDATE_REGISTRATION_PRESCRIBER';