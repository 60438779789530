import { FC } from "react";
import { Col } from 'antd';
import { Label, Tag } from '@/Components';
import { ISpecialties } from "./interfaces";
import { useTranslation } from "react-i18next";

import styles from "./Specialties.module.scss";

export const Specialties: FC<ISpecialties> = ({ prescriber }) => {
  const { t } = useTranslation();

  return (
    <Col span={24}>
      <Label
        type="ui-tiny-content"
        color="text-200"
        children={t('prescribers.editPage.specialties')}
        withBottomMargin
      />
      <div className={styles['tags-wrapper']}>
        {prescriber?.prescritorEspecialidades.map((x) => (
          <Tag
            key={x.especialidadeExternalId}
            type="secondary"
            children={x.descricao}
          />
        ))}
      </div>
    </Col>
  );
};