import { FC } from 'react';
import { Highlight, Icon, Text } from '@/Components';

import styles from './SelectItem.module.scss';
import { Divflex } from '@/Layouts/DivWhithFlex';
import { IGetSaleOrderResponse } from '@/Data/Interfaces/response/Sales/SalesOrder/ISalesOrderResponse';

export interface SelectItemProps {
  externalId: string;
  title: string;
  favorite?: boolean;
  body: string;

  onEdit: (externalId: string) => void;
  onSelect: (externalId: string) => void;
  saleOrder?: IGetSaleOrderResponse;
  currentSearch: string;
}

export const SelectItem: FC<SelectItemProps> = ({
  externalId,
  title,
  favorite,
  body,
  onEdit,
  onSelect,
  saleOrder,
  currentSearch,
}) => {
  return (
    <div
      className={styles['body']}
      style={
        saleOrder?.clienteEnderecoExternalId === externalId
          ? { borderColor: 'var(--color-primary-base)' }
          : {}
      }
    >
      <div className={styles['content']}>
        <button className={styles['info']} onClick={() => onSelect(externalId)}>
          <Divflex>
            <Text
              type="ui-tiny-content"
              style={{ marginBottom: 6, textAlign: 'left' }}
            >
              <Highlight highlight={currentSearch}>{title}</Highlight>
            </Text>
            {favorite && (
              <Icon
                icon="favorite"
                size="S"
                color="users09"
                style={{ margin: '3px 4px' }}
              />
            )}
          </Divflex>
          <Text type="ui-tiny-content" style={{ textAlign: 'left' }}>
            <Highlight highlight={currentSearch}>{body}</Highlight>
          </Text>
        </button>

        <div className={styles['actions']}>
          <Icon
            icon="edit"
            size="M"
            color="text-300"
            onClick={() => onEdit(externalId)}
            className={styles['icon-button']}
          />
        </div>
      </div>
    </div>
  );
};
