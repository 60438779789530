import React, { useState } from 'react';
import { Button, Form } from '@/Components';
import { useTranslation } from 'react-i18next';
import { InvoiceCreateCreateSchema } from './InvoiceCreate.schema';

import { ConnectedFocusError } from '@/Components/ConnectedFocusError';
import { InfoSection } from './Sections/InfoSection';
import { ValueSection } from './Sections/ValueSection';
import { ProductSection } from './Sections/ProductSection';

import styles from './styles.module.scss';
import { history } from '@/Store';

import { useFormatData } from './Hooks/useFormatData';

import { useParams } from 'react-router';

import { useGetInvoice } from './Hooks/useGetInvoice';
import { useConfigInitialValue } from './Hooks/useConfigInitialValue';
import { useEditInvoice } from './Hooks/useEditInvoice';
import { useQueryClient } from '@tanstack/react-query';
import { FETCH_GET_INVOICE } from '@/ControllerApiHook/UniqIds/Purchase/InvoiceKeys';
import { useDispatch } from 'react-redux';
import { NotificationActions } from '@/Store/Notification/Notification.actions';

const EditInvoice: React.FC = () => {
  const { t } = useTranslation();
  const { editInvoice } = useEditInvoice();
  const queryClient = useQueryClient();
  const dispatch = useDispatch();

  const { formatData } = useFormatData();

  const { externalId } = useParams<{ externalId: string }>();

  const { invoice } = useGetInvoice(externalId);
  const { configInvoice } = useConfigInitialValue();
  const initialValue = configInvoice(invoice);

  const handleSubmit = async (values: any) => {
    const valuesToPost = formatData(values);
    const res = await editInvoice.mutateAsync(valuesToPost);

    if (res.success) {
      queryClient.invalidateQueries([FETCH_GET_INVOICE]);
      dispatch(NotificationActions.setCurrentFormIsDirty(false));
    }
  };

  return (
    <>
      <Form
        onSubmit={handleSubmit}
        schema={InvoiceCreateCreateSchema}
        initialValues={initialValue}
      >
        {() => (
          <>
            <ConnectedFocusError />
            <InfoSection />
            <ValueSection />
            <ProductSection />
            <div className={styles['footer-button']}>
              <div>
                <Button
                  type="secondary"
                  style={{ marginRight: 8 }}
                  children={t('common.cancel')}
                  onClick={() =>
                    history.push(`/order/invoice/${externalId}/details`)
                  }
                />
                <Button
                  type="primary"
                  children={
                    editInvoice.isLoading
                      ? t('common.saving')
                      : t('common.save')
                  }
                  htmlType="submit"
                  loading={editInvoice.isLoading}
                />
              </div>
            </div>
          </>
        )}
      </Form>
    </>
  );
};

export default EditInvoice;
