import { useFormikContext } from "formik";
import { useMemo } from "react";
import { IUseMemorizations } from "./interfaces";

export const useProductLineMemorizations = ({
  index
}: IUseMemorizations) => {
  const form = useFormikContext<any>();

  const formValues = useMemo(
    () => ({
      unitValue: form?.values?.pedidoCompraItens?.[index]
        ?.precoUnitario as number,
      discountValue: form?.values?.pedidoCompraItens?.[index]
        ?.valorDescontoUnitario as number,
      discountType: form?.values?.pedidoCompraItens?.[index]
        ?.tipoDesconto as number,
      amountValue: form?.values?.pedidoCompraItens?.[index]
        ?.quantidade as number,
      productExternalId: form?.values?.pedidoCompraItens?.[index]
        ?.produtoExternalId as string,
      unidadeMedidaId: form?.values?.pedidoCompraItens?.[index]
        ?.unidadeMedidaId as string,
    }),

    [form?.values?.pedidoCompraItens, index]
  );

  return {
    formValues
  };
};