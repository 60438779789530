import { FC } from 'react';
import { Dropdown, i18n } from '@/Components';
import { useModalCallAPI } from './Hooks/useModalCallAPI';
import { useModalMemorizations } from './Hooks/useModalMemorizations';

import styles from './Modal.module.scss';

export const ModalBody: FC = () => {
  const { 
    users,
    fetchNewPageUsers,
    isLoadingUsers
  } = useModalCallAPI();

  const { items } = useModalMemorizations({ users });

  return (
    <div className={styles['container']}>
      <Dropdown
        name="usuarioResponsavelExternalId"
        label={i18n.t('common.responsible')}
        items={items}
        autoIncrementBy="id"
        onScrollEnd={fetchNewPageUsers}
        withoutMarginBottom
        isLoading={isLoadingUsers}
      />
    </div>
  );
};
