import { Link } from '../../../../../Components';
import { Text } from '../../../../../Components/Text';
import { LinkButton } from '../../../../../Components/Layouts/LinkButton';
import { hasPermission } from '../../../../../Components/PermissionWrapper';
import { PermissionEnum } from '../../../../../Data/Interfaces/model/permissionEnum';

export const productMensageColumns = (t: any) => [
  {
    title: t('supply.productMessage.list.description'),
    dataIndex: 'descricao',
    key: 'descricao',
    isHighlightable: true,
    width: '85%',
    sortColumn: 'descricao',
    defaultSortOrder: 'ascend',
    sorter: true,
    render: (x: any, row: any) =>
      !hasPermission(PermissionEnum.Estoque_MensagemProduto_VerDetalhes) ? (
        <Text type="ui-tiny-bold" style={{ width: 'fit-content' }}>
          {x}
        </Text>
      ) : (
        <Link
          type="ui-tiny-bold"
          to={`/inventory/product-message/${row.externalId}/details`}
          style={{ width: 'fit-content' }}
        >
          {x}
        </Link>
      ),
  },
  {
    title: t('supply.productMessage.list.products'),
    dataIndex: 'produtosVinculados',
    key: 'produtosVinculados',
    render: (x: any, value: any) => {
      return (
        <LinkButton
          link={`/inventory/product-message/${value.externalId}/products`}
          text={
            x === 1
              ? `${x} ${t('products.common.product')}`
              : `${x} ${t('products.common.products')}`
          }
        />
      );
    },
  },
];
