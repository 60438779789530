import { useCallback } from 'react';
import { IUseFunctions } from './interfaces';
import { IInvoiceListData } from '@/Data/Interfaces/response/Purchase/IInvoiceResponse';
import { handleModalError } from '@/Utils/ErrorCodeUtils/ErrorHandlingUtils';

export const useInvoiceListFunctions = ({
  setSelectedRowsOnTable,
  deleteInvoice,
}: IUseFunctions) => {
  const onDelete = useCallback(
    async (invoices: IInvoiceListData[], app: any) => {
      await deleteInvoice.mutateAsync(
        {
          notaFiscalEntradaExternalId: invoices.map(
            (e: IInvoiceListData) => e.externalId
          ),
        },
        { onError: handleModalError(app) }
      );
    },
    [deleteInvoice]
  );

  const onSelectedRowsOnTable = useCallback(
    (rows: any) => {
      setSelectedRowsOnTable(rows);
    },
    [setSelectedRowsOnTable]
  );

  return {
    onDelete,
    onSelectedRowsOnTable,
  };
};
