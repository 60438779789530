import { Button } from '@/Components/Button';
import { Col, Row } from 'antd';
import { FC, useMemo } from 'react';

import styles from './SelectBody.module.scss';
import { SelectItem, SelectItemProps } from '../SelectItem';
import { TextInputState } from '@/Components/StateInputs/TextInput';
import { debounce } from 'lodash';
import { Icon } from '@/Components/Icon';
import { Text } from '@/Components/Text';
import { IGetSaleOrderResponse } from '@/Data/Interfaces/response/Sales/SalesOrder/ISalesOrderResponse';

export interface SelectCardModalTranslations {
  footerButton: string;
  emptyDataLabel: string;
  inputPlaceHolder: string;
}

export interface SelectBodyProps {
  items?: Omit<SelectItemProps, 'onEdit' | 'onSelect'>[];

  translations: SelectCardModalTranslations;

  onEdit: (externalId: string) => void;
  onSelect: (externalId: string) => void;

  onFooterButtonClick?: () => void;
  onSearch?: (search: string) => void;

  saleOrder?: IGetSaleOrderResponse;
  currentSearch: string;
}

export const SelectBody: FC<SelectBodyProps> = ({
  items,
  translations,
  onEdit,
  onSelect,
  onFooterButtonClick,
  onSearch,
  saleOrder,
  currentSearch,
}) => {
  const onSearchHandler = useMemo(
    () =>
      debounce((search: string) => {
        onSearch && onSearch(search);
      }, 500),
    [onSearch]
  );

  return (
    <div className={styles['body']}>
      <Row gutter={[16, 0]}>
        <Col span={24}>
          <TextInputState
            placeHolder={translations.inputPlaceHolder}
            leftIcon={{ icon: 'search', color: 'text-400' }}
            onChange={onSearchHandler}
          />
        </Col>
      </Row>

      <div className={items && items?.length > 4 ? styles['cards'] : undefined}>
        {items?.length === 0 ? (
          <div className={styles['no-data-content']}>
            <Icon icon="house" size="L" color="text-500" />
            <Text
              type="ui-tiny-content"
              color="text-300"
              children={translations.emptyDataLabel}
              style={{ marginTop: 6 }}
            />
          </div>
        ) : (
          items?.map((item) => (
            <Row gutter={[16, 0]}>
              <Col span={24}>
                <SelectItem
                  {...item}
                  onEdit={onEdit}
                  onSelect={onSelect}
                  saleOrder={saleOrder}
                  currentSearch={currentSearch}
                />
              </Col>
            </Row>
          ))
        )}
      </div>

      <Row gutter={[16, 0]}>
        <Col span={24}>
          <Button
            type="secondary"
            fullWidth
            style={{ marginTop: 6 }}
            onClick={onFooterButtonClick}
          >
            {translations.footerButton}
          </Button>
        </Col>
      </Row>
    </div>
  );
};
