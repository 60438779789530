import { FC } from 'react';
import { Row, Col } from 'antd';
import { getCardStatus } from '@/Components/Card';
import { InputWithSearch } from '@/Components/Inputs/InputWithSearch';
import { List } from '@/Components/List';
import { Card } from '@/Components';
import { TextInput } from '@/Components/Inputs/TextInput';
import { useTranslation } from 'react-i18next';
import { UserGroupNameField } from './Components/UserGroupNameField';
import { useDetailsCardMemorizations } from './Hooks/useDetailsCardMemorizations';
import { useDetailsCardCallAPI } from './Hooks/useDetailsCardCallAPI';
import { useDetailsCardFunctions } from './Hooks/useDetailsCardFunctions';
import { IDetailsCard } from './interfaces';
import { useDetailsCardStates } from './Hooks/useDetailsCardStates';

export const DetailsCard: FC<IDetailsCard> = ({ 
  props
 }) => {
  const { t } = useTranslation();

  const {
    userList
  } = useDetailsCardCallAPI();

  const {
    usersSelected,
    setUsersSelected
  } = useDetailsCardStates();

  const { 
    onSearch,
    addOrRemoveUsers
  } = useDetailsCardFunctions({
    userList,
    usersSelected,
    setUsersSelected
  });

  const { userListData, items } = useDetailsCardMemorizations({
    usersSelected,
    userList
  });

  return (
    <Card
      title={t('admin.createUserGroupPage.details')}
      status={getCardStatus(['nome', 'descricao', 'usuarios'], props)}
      withoutCollapse={true}
    >
      <Row gutter={[16, 0]} justify="space-between">
        <Col span={12}>
          <UserGroupNameField />
        </Col>
        <Col span={12}>
          <TextInput
            name="descricao"
            dataTestId='descricao'
            label={t('admin.createUserGroupPage.detaildCard.description')}
            placeHolder={t(
              'admin.createUserGroupPage.detaildCard.descriptionPlaceholder'
            )}
          />
        </Col>
      </Row>
      <Row gutter={[16, 0]} justify="space-between">
        <Col span={24}>
          <InputWithSearch
            name="usuarios"
            dataTestId='usuarios'
            items={userListData}
            label={t('admin.createUserGroupPage.detaildCard.users')}
            placeHolder={t(
              'admin.createUserGroupPage.detaildCard.usersPlaceholder'
            )}
            withoutMarginBottom={true}
            onChange={(value) => addOrRemoveUsers(value, false, props)}
            onScrollEnd={userList.fetchNewPage}
            withoutSearchIcon
            multipleSelection
            hideSelectedItem
            onSearch={onSearch}
            currentItemsSelected={usersSelected.map((x) => x.externalId)}
          />
        </Col>
      </Row>
      <Row gutter={[16, 0]} justify="space-between">
        <Col span={24}>
          <List
            items={items}
            onDelete={(value) => addOrRemoveUsers(value, true, props)}
          />
        </Col>
      </Row>
    </Card>
  );
};
