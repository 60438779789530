import { useEffect, useMemo } from 'react';
import { IUseFunctions } from './interfaces';
import _ from 'lodash';

export const useEditFunctions = ({
  mutateAsync,
  setPharmaceuticalFormExternalIdState,
  pharmaceuticaFormDetail,
  setIdUntabbreviated,
  setUntabbreviated,
  posology,
}: IUseFunctions) => {
  useEffect(() => {
    if (posology?.formaFarmaceuticaExternalId) {
      setPharmaceuticalFormExternalIdState(
        posology?.formaFarmaceuticaExternalId
      );
    }
  }, [posology?.formaFarmaceuticaExternalId]);

  const handlerInputPharmaceuticalFormChange = (value: string) => {
    setPharmaceuticalFormExternalIdState(value);
  };

  const { abreviacaoUnidadeMedida, unidadeMedidaId } =
    pharmaceuticaFormDetail ?? {};

  if (abreviacaoUnidadeMedida && unidadeMedidaId) {
    setUntabbreviated(abreviacaoUnidadeMedida);
    setIdUntabbreviated(unidadeMedidaId);
  }
  const fetchPosologyName = useMemo(
    () =>
      _.debounce(async (form: any, value: string) => {
        try {
          return await mutateAsync({
            descricao: value,
            formaFarmaceuticaExternalId:
              form.values.formaFarmaceuticaExternalId,
            posologiaExternalId: form.values.externalId,
          });
        } catch {
          return undefined;
        }
      }, 500),
    [mutateAsync]
  );

  const fetchPosologyDropdownPharmaceuticalForm = useMemo(
    () =>
      _.debounce(async (form: any, value: string) => {
        try {
          return await mutateAsync({
            descricao: form.values.descricao,
            formaFarmaceuticaExternalId: value,
          });
        } catch {
          return undefined;
        }
      }, 500),
    [mutateAsync]
  );

  return {
    fetchPosologyName,
    fetchPosologyDropdownPharmaceuticalForm,
    handlerInputPharmaceuticalFormChange,
  };
};
