import { currencyFormater } from '@/Utils/Currency';
import { useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';
import { IUseMemorizations } from './interfaces';

export const useUnitaryDiscountMemorizations = ({
  totalUnitaryDiscountValue,
  orderPurchase,
}: IUseMemorizations) => {
  const { t } = useTranslation();
  const form: any = useFormikContext();

  const withStatusIconInLabel =
    totalUnitaryDiscountValue !==
    form?.values?.totalizadores?.totalProdutosDescontos;

  const withTooltip = {
    showMe:
      totalUnitaryDiscountValue !==
      form?.values?.totalizadores?.totalProdutosDescontos,
    title: `${t('common.originalValue')} ${currencyFormater(
      orderPurchase?.totalizadores?.totalProdutosDescontos
    )}`,
  };

  return {
    withStatusIconInLabel,
    withTooltip,
  };
};
