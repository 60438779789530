import { useMemo } from "react";
import { Text } from '@/Components';
import { useTranslation } from 'react-i18next';
import ReactMarkdown from 'react-markdown';
import { IUseMemorizations } from "./interfaces";

export const useCancelModalMemorizations = ({
  purchaseOrder
}: IUseMemorizations) => {
  const { t } = useTranslation();

  const body = useMemo(() => (
    <div style={{ padding: '20px' }}>
      <Text type="ui-tiny-content">
        <ReactMarkdown>
          {t('purchasing.purchaseOrder.modal.cancel.description', {
            numeroPedido: `**#${purchaseOrder?.numeroPedido}**`,
            fornecedor: `**${purchaseOrder?.nomeFornecedor}**`,
          })}
        </ReactMarkdown>
      </Text>
    </div>
  ), [purchaseOrder, t]);

  return {
    body
  };
};