import { i18n } from '@/Components';
import {
  useControllerMutateApiHook,
  useControllerQueryListApiHook,
} from '@/ControllerApiHook/Controller';
import { FETCH_LIST_SALES_ORDER } from '@/ControllerApiHook/UniqIds/Sales/SalesOrderKeys';
import { CREATE_SERVICE } from '@/ControllerApiHook/UniqIds/Sales/ServiceKeys';
import { SalesOrderAPI } from '@/Data/API/Sales/SalesOrderApi';
import { ServiceAPI } from '@/Data/API/Sales/ServiceApi';
import {
  messageErrorToast,
  messageSucessToast,
} from '@/Utils/MessageErrorToast';
import { useQueryClient } from '@tanstack/react-query';
import { IUseOrderListCallAPI } from './interface';

export const useOrderListCallAPI = ({
  setVisibleCreateModal,
}: IUseOrderListCallAPI) => {
  const queryClient = useQueryClient();

  const {
    data: serviceList,
    isLoading: serviceListLoading,
    refetch: refetchServiceList,
  } = useControllerQueryListApiHook({
    uniqId: FETCH_LIST_SALES_ORDER,
    entityApi: SalesOrderAPI.listSalesOrder,
    pagination: {
      sorter: { column: 'codigoPedidoVenda', direction: 'DESC' },
    },
  });

  const createSaleOrder = useControllerMutateApiHook({
    uniqId: CREATE_SERVICE,
    entityApi: ServiceAPI.createService,
    options: {
      onSuccess: () => {
        setVisibleCreateModal(false);
        queryClient.invalidateQueries([FETCH_LIST_SALES_ORDER]);
        messageSucessToast(i18n.t('callCenter.messages.create'));
      },
      onError: (x) => messageErrorToast(x),
    },
  });
  return {
    serviceList,
    serviceListLoading,
    refetchServiceList,
    createSaleOrder,
  };
};
