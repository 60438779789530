import { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
  TextInput
} from '@/Components';
import { formatPhoneNumber } from 'react-phone-number-input';
import { useFormikContext } from 'formik';
import { IMainContact } from './interfaces';
import { useMainContactStates } from './Hooks/useMainContactStates';

export const MainContact: FC<IMainContact> = ({
  documentProvider,
  externalIdProvider
}) => {
  const { t } = useTranslation();
  const form: any = useFormikContext();

  const {
    iconContact,
    setIconContact
  } = useMainContactStates();

  useEffect(() => {
    if (documentProvider && form.values.fornecedorExternalId) {
      form?.setFieldValue('razaoSocial', documentProvider?.razaoSocial);
      form?.setFieldValue('cnpj', documentProvider?.cnpj);
      form?.setFieldValue(
        'fornecedorContatoPrincipal',
        documentProvider.tipoContatoId === 2
          ? formatPhoneNumber(documentProvider?.fornecedorContatoFavorito)
          : documentProvider?.fornecedorContatoFavorito
      );
      setIconContact(documentProvider?.icon);
    }
  }, [externalIdProvider, documentProvider, form.values.fornecedorExternalId]);

  return (
    <>
      <TextInput
        name="fornecedorContatoPrincipal"
        leftIcon={iconContact || form?.values?.icon}
        label={t('purchasing.purchaseOrder.create.mainContact')}
        withoutMarginBottom
        disabled
        withTooltip={{
          icon: 'exclamation-upside-down',
          title: documentProvider?.observacaoContatoFavorito
            ? `${t('common.observation')} ${
                documentProvider.observacaoContatoFavorito
              }`
            : '',
        }}
      />
    </>
  );
};