import { useTranslation } from 'react-i18next';
import { Card, Tooltip } from '../../../../../../../../Components';
import { Col, Row } from 'antd';
import { CurrencyInput } from '../../../../../../../../Components/Inputs/CurrencyInput';
import StorageUtils from '../../../../../../../../Utils/StorageUtils';
import { IGetPharmaceuticalForm } from '@/Data/Interfaces/response/PharmaceuticalForm/IPharmaceuticalFormResponse';
import { useFormikContext } from 'formik';

interface IPharmaceuticalFormFinancial {
  disabled: boolean;
  pharmaceuticalForm?: IGetPharmaceuticalForm | null;
}

export const FinancialCard: React.FC<IPharmaceuticalFormFinancial> = ({
  disabled,
  pharmaceuticalForm,
}) => {
  const form: any = useFormikContext();
  const { t } = useTranslation();
  const preference = StorageUtils.getPreference();
  const currencyTypeReal = 0;
  return (
    <Card title={t('common.financial')} withoutCollapse={true}>
      <Row gutter={[16, 0]}>
        <Col span={5}>
          <Tooltip
            title={`${t(
              'production.pharmaceuticalForm.details.tooltips.previousOperationalCost'
            )}${t(
              'production.pharmaceuticalForm.details.money'
            )}${pharmaceuticalForm?.custoOperacional?.toFixed(2)}`}
            targetOffset={[2, -2]}
            showMe={
              !disabled &&
              pharmaceuticalForm?.custoOperacional !==
                form.values.custoOperacional
            }
          >
            <CurrencyInput
              name="custoOperacional"
              label={[
                {
                  children: t(
                    'production.pharmaceuticalForm.create.operationalCost'
                  ),
                },
              ]}
              placeHolder={t('products.create.costPricePlaceholder')}
              withoutMarginBottom={true}
              required={!disabled}
              disabled={disabled}
              withStatusIcon
              prefix={
                preference?.tipoMoedaId === currencyTypeReal ? 'R$ ' : '$ '
              }
            />
          </Tooltip>
        </Col>
      </Row>
    </Card>
  );
};
