import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { IUseMemorizations } from './interfaces';
import { IHeaderTableOptions } from '@/Components/Table/components/HeaderTable';
import { deleteModalBody, deleteModalTitle } from '../UserForm.helpers';

export const useUserFormMemorizations = ({
  userGroup,
  selectedRowsOnTable,
  setVisible,
}: IUseMemorizations) => {
  const { t } = useTranslation();

  const headerOptions: IHeaderTableOptions | undefined = {
    hasSearch: true,
    nameKey: 'nome',
    searchPlaceholder: t('admin.editUserGroups.userForm.searchPlaceholder'),
    newButton: {
      label: t('admin.editUserGroups.userForm.add'),
      onClick: () => setVisible(true),
    },
    deleteButton: { icon: 'remove-account', label: t('common.remove') },
  };

  const deleteModal = useMemo(
    () =>
      selectedRowsOnTable && {
        title: deleteModalTitle(selectedRowsOnTable, t),
        body: userGroup && deleteModalBody(selectedRowsOnTable, t, userGroup),
        buttons: {
          okButtonColor: 'status-danger-base',
        },
        okButtonName: 'common.remove',
      },
    [selectedRowsOnTable, userGroup, t]
  );

  return {
    headerOptions,
    deleteModal,
  };
};
