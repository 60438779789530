import { FC } from "react";
import { RadioButton } from '@/Components/Inputs/RadioButton';
import { RadioGroup } from '@/Components/Inputs/RadioButton/RadioGroup';
import { useTranslation } from "react-i18next";
import { useFormikContext } from "formik";
import { Col } from 'antd';
import { IPersonType } from "./interfaces";

import styles from "../../body.module.scss";

export const PersonType: FC<IPersonType> = ({ createClientModalI18n }) => {
  const { t } = useTranslation();
  const form = useFormikContext();

  return (
    <Col>
      <RadioGroup
        className={styles['radio-button']}
        name="pessoa"
        defaultValue={0}
        onChange={(x) => form.setFieldValue('clientType', x)}
      >
        <RadioButton
          name="tipoPessoaCPF"
          value={0}
          label={t(`${createClientModalI18n}.physicalPerson`)}
          checked
        />
        <RadioButton
          name="tipoPessoaCNPJ"
          value={1}
          label={t(`${createClientModalI18n}.legalPerson`)}
        />
      </RadioGroup>
    </Col>
  );
};