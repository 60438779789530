import { useControllerMutateApiHook } from '@/ControllerApiHook/Controller';
import {
  FETCH_GET_SALES_ORDER,
  PATCH_SALES_ORDER,
} from '@/ControllerApiHook/UniqIds/Sales/SalesOrderKeys';
import { SalesOrderAPI } from '@/Data/API/Sales/SalesOrderApi';
import { messageErrorToast } from '@/Utils/MessageErrorToast';
import { useQueryClient } from '@tanstack/react-query';

export const useWithSaleOrderCallAPI = () => {
  const queryClient = useQueryClient();

  const { mutateAsync, isLoading, isSuccess, variables }: any =
    useControllerMutateApiHook({
      uniqId: PATCH_SALES_ORDER,
      entityApi: SalesOrderAPI.patchSaleOrder,
      options: {
        onSuccess: () => {
          queryClient.invalidateQueries([FETCH_GET_SALES_ORDER]);
        },
        onError: (x) => messageErrorToast(x),
      },
    });

  return { mutateAsync, isLoading, isSuccess, variables };
};
