import { useState } from 'react';

export const useSideRightMenuStates = () => {
  const [approveSaleOrderModal, setApproveSaleOrderModal] = useState(false);
  const [reproveSaleOrderModal, setReproveSaleOrderModal] = useState(false);
  const [cancelSaleOrderModal, setCancelSaleOrderModal] = useState(false);
  const [reverseSaleOrderModal, setReverseSaleOrderModal] = useState(false);

  return {
    approveSaleOrderModal,
    setApproveSaleOrderModal,
    reproveSaleOrderModal,
    setReproveSaleOrderModal,
    cancelSaleOrderModal,
    setCancelSaleOrderModal,
    reverseSaleOrderModal,
    setReverseSaleOrderModal,
  };
};
