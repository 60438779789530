import React from 'react';
import { ICardTotal } from './interfaces';

import styles from './CartTotal.module.scss';

export const CardTotal: React.FC<ICardTotal> = ({ children, title, style }) => {
  return (
    <div className={`${styles['cardTotal']}`} style={style}>
      <div className={`${styles['title']}`}>
        <h2>{title}</h2>
      </div>
      <div>{children}</div>
    </div>
  );
};
