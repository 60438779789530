import { Col, Row } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { CircularStatus } from '../../../../../../../../../../Components/CircularStatus';
import {
  defineIconToProductRow,
  defineIconTypeToProductRow,
  defineProductToolTip,
  formatProduct,
} from '../../../../../utils/functions';
import {
  productItemColumns,
  productKeys,
} from '../../../../../utils/constants';
import { Tooltip } from '../../../../../../../../../../Components';

import styles from './productLine.module.scss';

interface IProductRow {
  product: any;
}

export const ProductRow: React.FC<IProductRow> = ({ product }) => {
  const tooltipText = defineProductToolTip(product.status);
  product = formatProduct(product);

  return (
    <Row wrap={false} gutter={[16, 0]} style={{ marginTop: '20px' }}>
      <Col span={4}>
        <div className={styles.product}>
          <CircularStatus
            showTooltip={true}
            tooltipText={tooltipText}
            icon={defineIconToProductRow(product.status)}
            type={defineIconTypeToProductRow(product.status)}
            color={defineIconTypeToProductRow(product.status)}
            marginTop="8px"
          />
          <div className={styles.productText}>{product.produtoDescricao}</div>
        </div>
      </Col>
      {productKeys.map((item) => {
        return (
          <Col key={item} span={2}>
            {product[item]}
          </Col>
        );
      })}
    </Row>
  );
};

export const ProductRowTitles: React.FC = () => {
  const { t } = useTranslation();
  return (
    <Row wrap={false} gutter={[16, 0]}>
      <Col span={4}>
        <div className={styles.title}>
          {t('purchasing.invoice.create.product')}
        </div>
      </Col>
      {productItemColumns.map((item) => {
        if (item === 'purchasing.invoice.create.unitValue') {
          return (
            <Col key={item} span={2}>
              <Tooltip
                placement="topLeft"
                key={item}
                showMe={true}
                title={t('purchasing.invoice.details.unitValueToolTip')}
              >
                <div className={styles.title}>{t(item)}</div>
              </Tooltip>
            </Col>
          );
        }

        if (item === 'purchasing.invoice.create.calcICMS') {
          return (
            <Col key={item} span={2}>
              <Tooltip
                placement="topLeft"
                key={item}
                showMe={true}
                title={t('purchasing.invoice.details.baseIcmsToolTip')}
              >
                <div className={styles.title}>{t(item)}</div>
              </Tooltip>
            </Col>
          );
        }

        return (
          <Col key={item} span={2}>
            <div className={styles.title}>{t(item)}</div>
          </Col>
        );
      })}
    </Row>
  );
};
