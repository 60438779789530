import { i18n } from '@/Components';
import { useControllerMutateApiHook } from '@/ControllerApiHook/Controller';
import { SAVE_PUT_INVOICE } from '@/ControllerApiHook/UniqIds/Purchase/InvoiceKeys';
import { InvoiceAPI } from '@/Data/API/Purchase/InvoiceApi';
import {
  messageErrorToast,
  messageSucessToast,
} from '@/Utils/MessageErrorToast';

export const useEditInvoice = () => {
  const editInvoice: any = useControllerMutateApiHook({
    uniqId: SAVE_PUT_INVOICE,
    entityApi: InvoiceAPI.savePutInvoice,
    options: {
      onError: (x) => messageErrorToast(x),
      onSuccess: () =>
        messageSucessToast(
          i18n.t('purchasing.invoice.successMessageToasts.editInvoice')
        ),
    },
  });

  return { editInvoice };
};
