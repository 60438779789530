import { SelectCardModal } from '@/Components/SelectCardModal';
import { Dispatch, FC, SetStateAction, useCallback, useEffect } from 'react';
import { EditDeliveryModal } from '../AddressDelivery/EditAddress';
import { CreateDeliveryModal } from '../AddressDelivery/CreateAddress';
import { ApproveSaleOrderModal } from '../StatusOrder/approveOrderModal';
import { ReproveSaleOrderModal } from '../StatusOrder/reproveOrderModal';
import { CancelSaleOrderModal } from '../StatusOrder/cancelOrderModal';
import { ReverseSaleOrderModal } from '../StatusOrder/reverseOrderModal';
import {
  FormikContextType,
  FormikHelpers,
  FormikValues,
  useFormikContext,
} from 'formik';
import { messageSucessToast } from '@/Utils/MessageErrorToast';
import { useSelectAddres } from './Hooks/useSelectAddresStates';
import { UseMutateAsyncFunction } from '@tanstack/react-query';
import { IGetSaleOrderResponse } from '@/Data/Interfaces/response/Sales/SalesOrder/ISalesOrderResponse';
import { useControllerQueryListApiHook } from '@/ControllerApiHook/Controller';
import { FETCH_ADDRESS_CLIENT_LIST } from '@/ControllerApiHook/UniqIds/People/ClientKeys';
import { ClientAPI } from '@/Data/API/People/ClientApi';
import { entregaEmDomicilo, retiradaNoBalcao } from '../..';
import { useTranslation } from 'react-i18next';
import { DeliveryModal } from '../../../../Modals/deliveryModal';

interface ISelectAddress {
  updateDelivery: UseMutateAsyncFunction<unknown, unknown, unknown, unknown>;
  saleOrder: IGetSaleOrderResponse;

  approveSaleOrderModal: boolean;
  cancelSaleOrderModal: boolean;
  reproveSaleOrderModal: boolean;
  reverseSaleOrderModal: boolean;
  openDeliveryFeeModal: boolean;
  openAddressModalOpen: boolean;

  setApproveSaleOrderModal: Dispatch<SetStateAction<boolean>>;
  setCancelSaleOrderModal: Dispatch<SetStateAction<boolean>>;
  setReproveSaleOrderModal: Dispatch<SetStateAction<boolean>>;
  setReverseSaleOrderModal: Dispatch<SetStateAction<boolean>>;
  setIsAddressModalOpen: Dispatch<SetStateAction<boolean>>;
  setOpenDeliveryFeeModal: Dispatch<SetStateAction<boolean>>;
}

export const SelectAddress: FC<ISelectAddress> = ({
  updateDelivery,
  saleOrder,

  approveSaleOrderModal,
  setApproveSaleOrderModal,

  reproveSaleOrderModal,
  setReproveSaleOrderModal,

  cancelSaleOrderModal,
  setCancelSaleOrderModal,

  reverseSaleOrderModal,
  setReverseSaleOrderModal,

  openAddressModalOpen,
  setIsAddressModalOpen,

  openDeliveryFeeModal,
  setOpenDeliveryFeeModal,
}) => {
  const form: FormikContextType<any> = useFormikContext();

  const { t } = useTranslation();

  const {
    currentSearch,
    openCreateAddressModal,
    openEditAddressModal,
    selectedAddressExternalId,
    setCurrentSearch,
    setOpenCreateAddressModal,
    setOpenEditAddressModal,
    setSelectedAddressExternalId,
  } = useSelectAddres();

  const addressClientList = useControllerQueryListApiHook({
    uniqId: FETCH_ADDRESS_CLIENT_LIST,
    entityApi: ClientAPI.addressClientList,
    autoIncrement: true,
    pagination: {
      filter: [],
    },
  });

  useEffect(() => {
    addressClientList.refetch({
      filter: [
        {
          filterName: 'clienteExternalId',
          value: saleOrder?.clienteExternalId,
        },
      ],
    });
  }, [saleOrder?.clienteExternalId]);

  const updateDeliveryForHome = useCallback(
    async (x?: any, formik?: FormikHelpers<FormikValues>) => {
      try {
        await updateDelivery(
          {
            pedidoVendaExternalId: saleOrder.externalId,
            tipo: entregaEmDomicilo,
            clienteEnderecoExternalId: x,
          },
          {
            onSuccess: () =>
              messageSucessToast(
                t('saleOrder.editOrder.listAddressDeliveryModal.success')
              ),
          }
        );
      } catch {
        formik?.setSubmitting(false);
      }
    },
    [updateDelivery, saleOrder.externalId, t]
  );

  return (
    <>
      <SelectCardModal
        title={t('saleOrder.editOrder.deliveryModal.selectAddress')}
        translations={{
          footerButton: t('saleOrder.editOrder.deliveryModal.newAddress'),
          emptyDataLabel: t('address.addressEmpyt'),
          inputPlaceHolder: t('address.searchForAddress'),
        }}
        onClose={() => {
          setIsAddressModalOpen(false);
          !saleOrder.clienteEnderecoExternalId &&
            form.setFieldValue('tipoEntregaId', retiradaNoBalcao);
        }}
        onSelect={(x) => {
          setIsAddressModalOpen(false);
          setOpenDeliveryFeeModal(true);
          updateDeliveryForHome(x);
        }}
        saleOrder={saleOrder}
        onEdit={(x) => {
          setIsAddressModalOpen(false);
          setOpenEditAddressModal(true);
          setSelectedAddressExternalId(x);
        }}
        currentSearch={currentSearch}
        onFooterButtonClick={() => {
          setIsAddressModalOpen(false);
          setOpenCreateAddressModal(true);
        }}
        onSearch={(x) => {
          setCurrentSearch(x);
          addressClientList.refetch({
            search: x,
          });
        }}
        items={addressClientList?.data?.data?.map((item: any) => ({
          externalId: item.clienteEnderecoExternalId,
          title: item.descricao,
          favorite: item.principal,
          body: `${item.logradouro}, ${item.numero}, ${item.bairro}, ${item.estadoDescricao}, ${item.paisDescricao}`,
        }))}
        visible={
          openAddressModalOpen && form.values.tipoEntregaId !== retiradaNoBalcao
        }
      />
      <DeliveryModal
        changeVisibleModal={() => setOpenDeliveryFeeModal(false)}
        saleOrderExternalId={saleOrder.externalId}
        visibleModal={openDeliveryFeeModal}
        saleOrder={saleOrder}
      />
      <EditDeliveryModal
        changeVisibleModal={() => {
          setOpenEditAddressModal(false);
          setIsAddressModalOpen(true);
        }}
        visibleModal={openEditAddressModal}
        saleOrder={saleOrder}
        selectedAddressExternalId={selectedAddressExternalId}
      />
      <CreateDeliveryModal
        changeVisibleModal={() => {
          setOpenCreateAddressModal(false);
          setIsAddressModalOpen(false);
          setOpenDeliveryFeeModal(true);
        }}
        visibleModal={openCreateAddressModal}
        saleOrder={saleOrder}
        updateDeliveryForHome={updateDeliveryForHome}
      />
      <ApproveSaleOrderModal
        visibleModal={approveSaleOrderModal}
        changeVisibleModal={setApproveSaleOrderModal}
        saleOrder={saleOrder}
      />
      <ReproveSaleOrderModal
        visibleModal={reproveSaleOrderModal}
        changeVisibleModal={setReproveSaleOrderModal}
        saleOrder={saleOrder}
      />
      <CancelSaleOrderModal
        visibleModal={cancelSaleOrderModal}
        changeVisibleModal={setCancelSaleOrderModal}
        saleOrder={saleOrder}
      />
      <ReverseSaleOrderModal
        visibleModal={reverseSaleOrderModal}
        changeVisibleModal={setReverseSaleOrderModal}
        saleOrder={saleOrder}
      />
    </>
  );
};
