import { QueryFunctionContext } from '@tanstack/react-query';
import { Http } from '../../../Utils/Http';
import { IGetCountryResponse } from '../../Interfaces/response/Locality/ICountryResponse';

export class CountryAPI {
  public static async getCountry(
    context: QueryFunctionContext
  ): Promise<IGetCountryResponse | null> {
    if (context.queryKey[1])
      return await Http.fetcher<IGetCountryResponse>(
        `/pharma/localidade/v1/Pais/${context.queryKey[1]}`
      );
    return null;
  }
}
