import { useState } from "react";

export const useEditStates = () => {
  const [visibleModal, setVisibleModal] = useState(false);
  
  const [disabled, setDisabled] = useState(true);

  return {
    visibleModal,
    setVisibleModal,
    disabled,
    setDisabled
  };
};