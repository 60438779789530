import { FC } from 'react';
import { Modal } from '@/Components/Modal';
import { useTranslation } from 'react-i18next';
import { ICancelOrderPurchaseModal } from './interfaces';
import { useCancelModalCallAPI } from './Hooks/useCancelModalCallAPI';
import { useCancelModalMemorizations } from './Hooks/useCancelModalMemorizations';
import { useCancelModalFunctions } from './Hooks/useCancelModalFunctions';

export const CancelOrderPurchaseModal: FC<ICancelOrderPurchaseModal> = ({
  purchaseOrder,
  visibleModal,
  changeVisibleModal
}) => {
  const { t } = useTranslation();

  const {
    cancelOrderPurchase
  } = useCancelModalCallAPI();

  const {
    body
  } = useCancelModalMemorizations({
    purchaseOrder
  });

  const {
    onOkClick,
    onCancelClick,
    onClose
  } = useCancelModalFunctions({
    purchaseOrder,
    cancelOrderPurchase,
    changeVisibleModal
  });

  return (
    <Modal
      title={`${t('purchasing.purchaseOrder.modal.cancel.title')}?`}
      body={body}
      okButtonColor="status-danger-base"
      visible={visibleModal}
      onOkClick={onOkClick}
      widthModal={399}
      okButtonName={t('common.cancel')}
      cancelButtonName={t('common.back')}
      onCancelClick={onCancelClick}
      onClose={onClose}
      loading={cancelOrderPurchase.isLoading}
    />
  );
};
