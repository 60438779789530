import { useState } from 'react';

export const useCreateStates = () => {
  const [classProductIdSelected, setOnClassProductIdSelected] =
    useState<number>();

  const [lotExternalIdSelected, setlotExternalIdSelected] = useState<string>();

  const [
    storageLocationExternalIdSelected,
    setStorageLocationExternalIdSelected,
  ] = useState<string>();

  return {
    classProductIdSelected,
    setOnClassProductIdSelected,
    lotExternalIdSelected,
    setlotExternalIdSelected,
    storageLocationExternalIdSelected,
    setStorageLocationExternalIdSelected,
  };
};
