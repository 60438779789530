import React from 'react';
import { Modal } from '../../../../../../../Components/Modal';
import { useTranslation } from 'react-i18next';
import { Divflex } from '../../../../../../../Layouts/DivWhithFlex';
import { Text } from '../../../../../../../Components';
import ReactMarkdown from 'react-markdown';

interface IDeleteModal {
  visible: boolean;
  onOkClick: () => void;
  onCancelClick: () => void;
  provider: string;
  number: string;
  series: string;
}

export const CanNotDeleteModal: React.FC<IDeleteModal> = ({
  onOkClick,
  onCancelClick,
  visible,
  provider,
  number,
  series,
}) => {
  const { t } = useTranslation();

  return (
    <Modal
      onOkClick={onOkClick}
      visible={visible}
      title={`${t('purchasing.invoice.modal.cannotDelete.title')}`}
      body={
        <Divflex
          style={{ flexDirection: 'column', width: '400px', padding: '20px' }}
        >
          <Text color="text-50" type="ui-tiny-content">
            <ReactMarkdown>
              {t('purchasing.invoice.modal.cannotDelete.subTitle', {
                fornecedor: `**${provider}**!`,
                numero: `**${number}**`,
                serie: `**${series}**`,
              })}
            </ReactMarkdown>
          </Text>
          <Text type="ui-tiny-content" style={{ marginTop: 10 }}>
            {t('purchasing.invoice.modal.cannotDelete.description')}
          </Text>
        </Divflex>
      }
      withoutOkButton
      okButtonColor="status-danger-base"
      okButtonName={t('common.delete')}
      cancelButtonName={t('common.close')}
      onCancelClick={onCancelClick}
    />
  );
};
