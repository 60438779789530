import React, { FC, useState } from 'react';
import { ForgotPasswordSchema } from './Request.schema';
import { useTranslation } from 'react-i18next';
import { LoginLayout } from '@/Components/Layouts';
import { Button, Form, TextInput } from '@/Components';
import { history } from '@/Store';
import { useRequestCallAPI } from './Hooks/useRequestCallAPI';
import { useRequestFunctions } from './Hooks/useRequestFunctions';

import styles from './Request.module.scss';

export const ForgotPasswordRequest: FC = () => {
  const { t } = useTranslation();

  const [email, setEmail] = useState<string>('');

  const { recoveryPassword } = useRequestCallAPI(email);

  const { onSubmit } = useRequestFunctions({ recoveryPassword });

  return (
    <LoginLayout title={t('recoverPassword.title')}>
      <Form
        schema={ForgotPasswordSchema}
        className={styles['Form']}
        onSubmit={onSubmit}
        noLeaveWithoutLeaving
      >
        <TextInput
          name="email"
          label={t('recoverPassword.email')}
          leftIcon="email"
          placeHolder={t('recoverPassword.emailPlaceholder')}
          value={email}
          onChange={(x) => setEmail(x)}
        />
        <Button
          className={styles['button-recovery']}
          type="primary"
          loading={recoveryPassword.isLoading}
          htmlType={'submit'}
          fullWidth
          children={t('recoverPassword.action')}
        />

        <Button
          className={styles['return-button']}
          type="link"
          onClick={() => history.push('/login')}
          fullWidth
          children={t('recoverPassword.back')}
        />
      </Form>
    </LoginLayout>
  );
};
