import { IGlobalReducerState } from '../Base/Interfaces/IGlobalReducerState';

export const getProviderAddContactModal = (state: IGlobalReducerState) =>
  state.purchasing.provider.addContactModal;

export const getProviderAddDocumentModal = (state: IGlobalReducerState) =>
  state.purchasing.provider.addDocumentModal;

export const getProviderAddAddressModal = (state: IGlobalReducerState) =>
  state.purchasing.provider.addAddressModal;

export const getTotalValuesCard = (state: IGlobalReducerState) =>
  state.purchasing.totalValueState;
