import React, { useState } from 'react';
import { PopOver } from '../../../../PopOver';
import { Status as ComponentStatus } from '../../../..';
import { Text } from '../../../../Text';

import styles from './Status.module.scss';
import { useTranslation } from 'react-i18next';
import i18n from '../../../../i18n';
import { statusTypes } from '../../../../../Utils/StatusUtils';
import { IListStatusLotResponse } from '../../../../../Data/Interfaces/response/Lot/IListLot';
import { lotStatusTypes } from '../../../../../Pages/Sectors/Inventory/Lot/List/lot.columns';
import { FETCH_LIST_STATUS_LOTS } from '../../../../../ControllerApiHook/UniqIds/Supply/LotKeys';
import {
  IPermissionWrapper,
  PermissionWrapper,
} from '../../../../PermissionWrapper';
import { useControllerQueryListApiHook } from '@/ControllerApiHook/Controller';
import { LotsAPI } from '@/Data/API/Supply/LotApi';

const Statustitle = () => {
  return (
    <div className={styles['divTitle']}>
      <Text
        className={styles['title']}
        type="small-text"
        color="text-400"
        children={
          i18n.t('breadCrumb.adminBootisRoute.status.changeStatus') as string
        }
      />
    </div>
  );
};

const Statuscontent = (
  status: (value?: boolean) => void,
  visible: (isVisible: boolean) => void,
  ative?: boolean,
  popoverDataTestId?: string
) => {
  const handleStatusClick = (isActive: boolean) => {
    if ((isActive && ative) || (!isActive && !ative)) {
      return;
    }
    status(isActive);
    visible(false);
  };

  return (
    <div className={styles['divContent']} data-testid={popoverDataTestId}>
      <div
        className={styles['status']}
        onClick={ative ? undefined : () => handleStatusClick(true)}
        style={{
          cursor: ative ? 'not-allowed' : 'pointer',
          opacity: ative ? 0.5 : 1,
        }}
      >
        <ComponentStatus
          type="success"
          children={i18n.t('breadCrumb.adminBootisRoute.status.ative')}
          disabled={ative ? true : false}
        />
      </div>
      <div
        className={styles['status']}
        onClick={!ative ? undefined : () => handleStatusClick(false)}
        style={{
          cursor: !ative ? 'not-allowed' : 'pointer',
          opacity: !ative ? 0.5 : 1,
        }}
      >
        <ComponentStatus
          type="disabled"
          children={i18n.t('breadCrumb.adminBootisRoute.status.inative')}
          disabled={!ative}
        />
      </div>
    </div>
  );
};

const StatuscontentSituation = (
  status: (value: number) => void,
  visible: (isVisible: boolean) => void,
  situationNumber?: number
) => {
  const { data: situationLot } = useControllerQueryListApiHook({
    uniqId: FETCH_LIST_STATUS_LOTS,
    entityApi: LotsAPI.statusLotList,
  });

  return (
    <div className={styles['divContentSituation']}>
      {situationLot &&
        lotStatusTypes.map((x, index) => (
          <div
            className={styles['status']}
            onClick={() => {
              if (situationLot[index]?.id !== situationNumber) {
                status(index + 1);
                visible(false);
              } else {
                return;
              }
            }}
            key={index}
            style={{
              cursor:
                situationLot[index]?.id === situationNumber
                  ? 'not-allowed'
                  : 'pointer',
              opacity: situationLot[index]?.id === situationNumber ? 0.5 : 1,
            }}
          >
            <ComponentStatus
              type={x}
              children={situationLot[index]?.descricao}
              disabled={situationLot[index]?.id === situationNumber}
            />
          </div>
        ))}
    </div>
  );
};

interface IStatus {
  type: statusTypes;
  onClick?: (value: any) => void;
  disabled?: boolean;
  ative?: boolean;
  situation?: IListStatusLotResponse;
  situationNumber?: number;
  withoutDropdown?: boolean;
  permission?: IPermissionWrapper;
  loading?: boolean;

  dataTestId?: string;
  popoverDataTestId?: string;
}

export const Status: React.FC<IStatus> = ({
  type,
  onClick,
  disabled,
  ative,
  situation,
  withoutDropdown,
  loading,
  permission,
  situationNumber,
  dataTestId,
  popoverDataTestId,
}) => {
  const [visible, setVisible] = useState<boolean>(false);

  const { t } = useTranslation();

  const StatusContentVerification = () =>
    situation?.id
      ? situation.descricao
      : ative
      ? t('breadCrumb.adminBootisRoute.status.ative')
      : t('breadCrumb.adminBootisRoute.status.inative');

  const StatusType = StatuscontentSituation(
    (value) => onClick && onClick(value),
    () => setVisible(!visible),
    situationNumber
  );

  const StatusContent = Statuscontent(
    (value) => onClick && onClick(value),
    () => setVisible(!visible),
    ative,
    popoverDataTestId
  );

  return withoutDropdown ? (
    <ComponentStatus type={type} disabled={disabled} dataTestId={dataTestId}>
      {StatusContentVerification()}
    </ComponentStatus>
  ) : (
    <div className={styles['content']}>
      <PermissionWrapper
        permission={permission?.permission}
        tooltip={permission?.tooltip || 'noPermission.default'}
      >
        <PopOver
          content={situation?.id ? StatusType : StatusContent}
          placement="bottomLeft"
          title={Statustitle}
          trigger="click"
          visible={visible}
          onOpenChange={() => !disabled && setVisible(!visible)}
        >
          <ComponentStatus
            type={type}
            rightIcon
            disabled={disabled}
            loading={loading}
            dataTestId={dataTestId}
          >
            {StatusContentVerification()}
          </ComponentStatus>
        </PopOver>
      </PermissionWrapper>
    </div>
  );
};
