import { createAction, ActionsUnion } from '../Base/actionHelpers';
import { IAddModal, ITotalValuesState } from './interfaces/IPurchasingState';

export enum PurchasingActionKeys {
  SET_LOADING = '[PURCHASING] SET_LOADING',

  TOGGLE_CONTACT_MODAL = '[PURCHASING] TOGGLE_CONTACT_MODAL',
  OPEN_EDIT_CONTACT_MODAL = '[PURCHASING] OPEN_EDIT_CONTACT_MODAL',

  TOGGLE_DOCUMENT_MODAL = '[PURCHASING] TOGGLE_DOCUMENT_MODAL',
  OPEN_EDIT_DOCUMENT_MODAL = '[PURCHASING] OPEN_EDIT_DOCUMENT_MODAL',

  TOGGLE_ADDRESS_MODAL = '[PURCHASING] TOGGLE_ADDRESS_MODAL',
  OPEN_EDIT_ADDRESS_MODAL = '[PURCHASING] OPEN_EDIT_ADDRESS_MODAL',

  SET_TOTAL_VALUES_CARD = '[PURCHASING] SET_TOTAL_VALUES_CARD',
}

export const PurchasingActions = {
  setLoading: (loading: boolean) =>
    createAction(PurchasingActionKeys.SET_LOADING, loading),

  toggleContactModal: () =>
    createAction(PurchasingActionKeys.TOGGLE_CONTACT_MODAL),

  openContactModalEditMode: (data: Omit<IAddModal, 'isOpen'>) =>
    createAction(PurchasingActionKeys.OPEN_EDIT_CONTACT_MODAL, data),

  toggleDocumentModal: () =>
    createAction(PurchasingActionKeys.TOGGLE_DOCUMENT_MODAL),

  openDocumentModalEditMode: (data: Omit<IAddModal, 'isOpen'>) =>
    createAction(PurchasingActionKeys.OPEN_EDIT_DOCUMENT_MODAL, data),

  toggleAddressModal: () =>
    createAction(PurchasingActionKeys.TOGGLE_ADDRESS_MODAL),

  openAddressModalEditMode: (data: Omit<IAddModal, 'isOpen'>) =>
    createAction(PurchasingActionKeys.OPEN_EDIT_ADDRESS_MODAL, data),

  setTotalValuesCard: (payload: ITotalValuesState[]) =>
    createAction(PurchasingActionKeys.SET_TOTAL_VALUES_CARD, payload),
};

export type PurchasingActionUnion = ActionsUnion<typeof PurchasingActions>;
