import { useTranslation } from 'react-i18next';
import { InputLabel } from '../../../../../../../Components/Inputs/Label';
import { IPurchaseOrderOpenGetResponseData } from '../../../../../../../Data/Interfaces/response/Purchase/IPurchaseOrderResponse';
import RemovePurchaseOrderModal from '../../Sections/InfoSection/modal/RemovePurchaseOrderModal';
import { useEffect, useState } from 'react';
import { Tooltip } from '../../../../../../../Components';
import { formatUnitNumberOnPurchaseOrderList } from '../../utils/functions';
import { currencyFormater } from '../../../../../../../Utils/Currency';
import { useFormikContext } from 'formik';

import styles from './styles.module.scss';

const PurchaseOrderInput = ({
  purchaseOrderList,
  setPurchaseOrderSelected,
  setImportedProducts,
  setModalTablePurchaseOrder,
  disabled = false,
  showEdit = false,
}: {
  purchaseOrderList: IPurchaseOrderOpenGetResponseData[];
  setPurchaseOrderSelected?: React.Dispatch<
    React.SetStateAction<IPurchaseOrderOpenGetResponseData[]>
  >;
  setImportedProducts?: React.Dispatch<React.SetStateAction<any[]>>;
  setModalTablePurchaseOrder?: React.Dispatch<React.SetStateAction<boolean>>;
  disabled?: boolean;
  showEdit?: boolean;
}) => {
  const form: any = useFormikContext();
  const { t } = useTranslation();
  const lengthToScrollStart = 5;

  const removePurchaseOrder = (sequenciaGroupTenant: number) => {
    const newList = purchaseOrderList.filter(
      (e) => e.sequenciaGroupTenant !== sequenciaGroupTenant
    );
    if (setPurchaseOrderSelected) {
      setPurchaseOrderSelected(newList);
    }
  };

  useEffect(() => {
    if (!form.values.itens) {
      form.setFieldValue('itens', []);
    }
    const productsList = purchaseOrderList.map((e) => {
      return e.itens;
    });
    const mergedList = productsList.reduce(
      (acc, current) => [...acc, ...current],
      []
    );
    if (setImportedProducts) {
      setImportedProducts(mergedList);
    }
  }, [purchaseOrderList]);
  return (
    <div>
      <InputLabel
        withStatusIcon={showEdit ? 'show' : undefined}
        label={t('purchasing.invoice.create.purchaseOrder')}
      />
      <div
        className={`${styles.mainContainer} ${
          disabled ? styles.disabledStyle : ''
        }`}
      >
        <div
          className={`${
            purchaseOrderList.length < lengthToScrollStart
              ? styles.purchaseOrderListDefault
              : styles.purchaseOrderList
          }`}
        >
          {purchaseOrderList.map((e) => {
            return (
              <PurchaseOrderElement
                removePurchaseOrder={(e) => {
                  removePurchaseOrder(e);
                }}
                disabled={disabled}
                key={e.sequenciaGroupTenant}
                purchaseOrder={e}
              />
            );
          })}
          {purchaseOrderList.length === 0 && (
            <p className={styles.placeHolder}>
              {t('purchasing.invoice.create.placeHolder.noPurchaseOrder')}
            </p>
          )}
        </div>
        <div className={styles.addPurchaseOrderContainer}>
          <div
            onClick={() => {
              if (setModalTablePurchaseOrder) {
                setModalTablePurchaseOrder(true);
              }
            }}
            className={styles.addPurchaseOrderButton}
          >
            +
          </div>
        </div>
      </div>
    </div>
  );
};

const PurchaseOrderElement = ({
  removePurchaseOrder,
  purchaseOrder,
  disabled,
}: {
  removePurchaseOrder: (number: number) => void;
  purchaseOrder: IPurchaseOrderOpenGetResponseData;
  disabled: boolean;
}) => {
  const [visibleModal, setVisibleModal] = useState<boolean>(false);
  return (
    <>
      <Tooltip
        title={<ListTooltip purchaseOrder={purchaseOrder} />}
        showMe={true}
      >
        <div className={styles.purchaseOrderContainer}>
          <div className={styles.purchaseNumber}>
            {purchaseOrder.sequenciaGroupTenant}
          </div>
          <div
            onClick={() => {
              if (!disabled) {
                setVisibleModal(true);
              }
            }}
            className={styles.removePurchase}
          >
            x
          </div>
        </div>
      </Tooltip>

      <RemovePurchaseOrderModal
        onCancelClick={() => setVisibleModal(false)}
        visible={visibleModal}
        number={purchaseOrder.sequenciaGroupTenant}
        removePurchaseOrder={removePurchaseOrder}
      />
    </>
  );
};

export const ListTooltip = ({
  purchaseOrder,
  hasTotal = true,
}: {
  purchaseOrder: IPurchaseOrderOpenGetResponseData;
  hasTotal?: boolean;
}) => {
  const { t } = useTranslation();
  const itemsToRender: any = purchaseOrder.itens.slice(0, 3);
  const restItems = purchaseOrder.itens.length - 3;
  const product = restItems === 1 ? 'product' : 'products';
  return (
    <ul
      style={{ paddingBottom: hasTotal ? '20px' : '5px' }}
      className={styles.purchaseOrderListTooltip}
    >
      {itemsToRender.map((item: any) => (
        <li className={styles.purchaseOrderItem} key={item.descricao}>
          {item.descricao} (
          {formatUnitNumberOnPurchaseOrderList(item.quantidade)}{' '}
          {item.unidadeMedidaAbreviacao}){' '}
        </li>
      ))}
      {restItems >= 1 && (
        <li className={styles.purchaseOrderItem}>
          {t('purchasing.invoice.create.andMore')} {restItems}{' '}
          {t(`common.${product}`)}
        </li>
      )}
      {hasTotal && (
        <li className={styles.totalPurchaseOrder}>
          {t('common.total')} {currencyFormater(purchaseOrder.totalPedido)}
        </li>
      )}
    </ul>
  );
};

export default PurchaseOrderInput;
