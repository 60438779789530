import { FC } from 'react';
import { Modal } from '../../../../../../../../../Components/Modal';
import { useTranslation } from 'react-i18next';
import BodyModal from './Body/bodyModal';
import { useEditStates } from './Hooks/useEditStates';
import { useEditCallAPI } from './Hooks/useEditCallAPI';
import { useEditFunctions } from './Hooks/useEditFunctions';

import styles from './linkPackingClassificationModal.module.scss';

interface ILinkPackingClassificationModal {
  visible: boolean;
  changeVisible: (value: boolean) => void;
  pharmaceuticalFormExternalId?: string;
}

export const LinkPackingClassificationModal: FC<
  ILinkPackingClassificationModal
> = ({ changeVisible, visible, pharmaceuticalFormExternalId }) => {
  const { t } = useTranslation();

  const { packingClassificationRowAmount, setPackingClassificationRowAmount } =
    useEditStates();

  const { linkPackingClassification, isLoadingLinkPackingClassification } =
    useEditCallAPI({ t, changeVisible });

  const { removeRow, onSubmit } = useEditFunctions({
    setPackingClassificationRowAmount,
    linkPackingClassification,
    pharmaceuticalFormExternalId,
  });

  return (
    <Modal
      onOkClickForm={(form) => onSubmit(form)}
      okButtonName={t(
        'production.pharmaceuticalForm.create.linkedPackingClassificationButton'
      )}
      title={t(
        'production.pharmaceuticalForm.create.linkedPackingClassification'
      )}
      loading={isLoadingLinkPackingClassification}
      body={
        <div className={styles['container']} id="dropdown-itens-container">
          <div>
            {t('production.pharmaceuticalForm.create.packingClassification')}
          </div>
          <div
            style={{
              borderTop: '1px solid var(--color-background-04)',
              marginTop: 10,
              marginBottom: 15,
            }}
          />
          {packingClassificationRowAmount.map((uuid, index) => (
            <BodyModal
              totalRows={packingClassificationRowAmount.length}
              setPackingClassificationRowAmount={
                setPackingClassificationRowAmount
              }
              index={index}
              key={uuid}
              onDelete={removeRow}
            />
          ))}
        </div>
      }
      visible={visible}
      onCancelClick={() => changeVisible(!visible)}
      htmlType="submit"
      onClose={() => changeVisible(!visible)}
      widthModal={491}
      withForm
    />
  );
};
