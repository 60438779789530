import { useTranslation } from 'react-i18next';
import { Card } from '../../../../../../../../Components';
import { RowDescription } from './Rows/DescriptionRow';
import { OtherRow } from './Rows/OtherRow';

export const InfoCard: React.FC = () => {
  const { t } = useTranslation();

  return (
    <Card title={t('common.information')} withoutCollapse>
      <RowDescription />
      <OtherRow />
    </Card>
  );
};
