import { useControllerQueryListApiHook } from '@/ControllerApiHook/Controller';
import { FETCH_LIST_DROPDOWN_SUPPLIER } from '@/ControllerApiHook/UniqIds/Purchase/SupplierKeys';
import { SupplierAPI } from '@/Data/API/Purchase/SupplierApi';

export const useProviderCallAPI = () => {
  const {
    data: listProvider,
    isLoading: isLoadingProvider,
    fetchNewPage: fetchNewProviderPage,
    refetch: refetchSearchProviderPage,
  } = useControllerQueryListApiHook({
    uniqId: FETCH_LIST_DROPDOWN_SUPPLIER,
    entityApi: SupplierAPI.listDropdownSupplier,
    autoIncrement: true,
  });

  return {
    listProvider,
    isLoadingProvider,
    fetchNewProviderPage,
    refetchSearchProviderPage,
  };
};
