import { FC } from 'react';
import { Icon, InputWithSearch } from '@/Components';
import { Col, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import { IStorageLocationRow } from './interfaces';
import { useStorageLocationRowMemorizations } from './Hooks/useStorageLocationRowMemorizations';
import { useStorageLocationRowFunctions } from './Hooks/useStorageLocationRowFunctions';

export const StorageLocationRow: FC<IStorageLocationRow> = ({
  list,
  fetchNewPage,
  isLoadingStorageLocationList,
  destiny,
  origin,
  setChangeStorageLocationModal,
  onOriginChange,
  setDestiny,
  setOrigin,
  onDestinyChange,
  refetch,
}) => {
  const { t } = useTranslation();

  const { supplyPlaceLocationFromItems, supplyPlaceLocationToItems } =
    useStorageLocationRowMemorizations({
      list,
      destiny,
      origin,
    });

  const {
    onSearch,
    actionOnFocus,
    onChangeSupplyPlaceLocationFrom,
    onChangeSupplyPlaceLocationTo,
  } = useStorageLocationRowFunctions({
    refetch,
    onOriginChange,
    setChangeStorageLocationModal,
    setOrigin,
    setDestiny,
    destiny,
    onDestinyChange,
  });

  return (
    <Row gutter={[16, 0]} justify="space-between">
      <Col span={11}>
        <InputWithSearch
          name="origin"
          label={t('supply.transfers.fields.supplyPlaceLocationFrom')}
          placeHolder={t(
            'supply.transfers.placeHolders.supplyPlaceLocationFrom'
          )}
          required
          withoutMarginBottom
          onScrollEnd={fetchNewPage}
          isLoading={isLoadingStorageLocationList}
          items={supplyPlaceLocationFromItems}
          onChange={onChangeSupplyPlaceLocationFrom}
          onSearch={onSearch}
          actionOnFocus={actionOnFocus}
        />
      </Col>
      <Col
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Icon
          icon="arrow-down"
          size="XXL"
          style={{ transform: 'rotate(-90deg)', marginTop: '18px' }}
          color="text-400"
        />
      </Col>
      <Col span={11}>
        <InputWithSearch
          name="destiny"
          label={t('supply.transfers.fields.supplyPlaceLocationTo')}
          required
          onScrollEnd={fetchNewPage}
          withoutMarginBottom
          placeHolder={t('supply.transfers.placeHolders.supplyPlaceLocationTo')}
          items={supplyPlaceLocationToItems}
          onChange={onChangeSupplyPlaceLocationTo}
          onSearch={onSearch}
          actionOnFocus={actionOnFocus}
          isLoading={isLoadingStorageLocationList}
        />
      </Col>
    </Row>
  );
};
