import React, { FC } from 'react';
import { PageLoading } from '../../../../../Layouts/PageLoading';
import { InformationTransferForm } from './Components/Cards/InfoCard';
import { TransferForm } from './Components/Cards/TransfersCard';
import { useEditCallAPI } from './Hooks/useEditCallAPI';
import { useParams } from 'react-router';

export const TransfersDetails: FC = () => {
  const { externalId } = useParams<{ externalId: string }>();
  const { isLoadingTransfers } = useEditCallAPI({ externalId });

  return (
    <PageLoading loading={isLoadingTransfers}>
      <InformationTransferForm />
      <TransferForm />
    </PageLoading>
  );
};

export default TransfersDetails;
