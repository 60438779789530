import { useControllerQueryListApiHook } from '@/ControllerApiHook/Controller';
import { FormatDataToDropdownUtils } from '@/Utils/FormatDataToDropdownUtils';
import { IQueryListParams } from '@/Utils/Http';
import { ListProducts } from '@/Services/Supply/Products/ListProducts.service';
import { useTranslation } from 'react-i18next';

export const FETCH_LIST_PRODUCT = '[PRODUCT] FETCH_LIST_PRODUCT';

interface IUseListProducts {
  fieldName: string;
  enabled?: boolean;
  pagination?: IQueryListParams;
  returnFullData?: boolean;
}

export const useListProducts = ({
  fieldName,
  enabled,
  pagination,
  returnFullData,
}: IUseListProducts) => {
  const { t } = useTranslation();
  const { data, refetch, isLoading, fetchNewPage } =
    useControllerQueryListApiHook({
      uniqId: FETCH_LIST_PRODUCT,
      entityApi: ListProducts,
      autoIncrement: true,
      enabled,
      pagination,
    });

  const items = FormatDataToDropdownUtils({
    data: data && data.data ? data.data : [],
    idKey: 'externalId',
    labelKey: 'descricao',
    subLabelKey: 'sequenciaGroupTenant',
    prefixToSubLabel: `${t(
      'purchasing.purchaseOrder.create.subLabelProduct'
    )} `,
  });

  const onSearch = (search?: string) => {
    refetch({
      search,
    });
  };

  return {
    fullData: returnFullData ? (data && data.data ? data.data : []) : undefined,
    name: fieldName,
    items,
    onSearch,
    isLoading,
    onScrollEnd: fetchNewPage,
  };
};
