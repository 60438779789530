import { useMemo } from 'react';
import { history } from '@/Store';
import { useTranslation } from 'react-i18next';

export const useListMemorizations = () => {
  const { t } = useTranslation();

  const headerOption = useMemo(
    () => ({
      hasSearch: true,
      searchPlaceholder: t('supply.losses.list.searchPlaceholder'),
      newButton: {
        onClick: () => history.push('/inventory/losses/create'),
        label: 'supply.losses.newButton',
        permission: {
          permission: 2018,
          tooltip: 'noPermission.supply.losses.create',
        },
      },
      editButton: {},
      deleteButton: {},
      nameKey: 'dataPerda',
    }),
    [t]
  );
  const rowsOptions = useMemo(
    (): { onRowDoubleClick?: (record: any) => void } => ({
      onRowDoubleClick: (x) =>
        history.push(`/inventory/losses/${x.externalId}/details`),
    }),
    []
  );

  return { headerOption, rowsOptions };
};
