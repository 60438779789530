import { IconName } from '../Components/Icon/IconClasses';

export const ContactIcons = [
  '',
  'email',
  'phone',
  'mobile',
  'whatsapp',
  'telegran',
  'facebook',
  'instagram',
  'skype',
] as IconName[];
