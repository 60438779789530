export const modalEN = {
  back: 'Close',
  cancel: 'Cancel',
  save: 'Save',
  delete: 'Delete',
  edit: 'Edit',
  inactive: 'Inactive',

  deleteModal: {
    users: 'users',
    many: {
      title: 'Are you sure you want to delete',
      items: 'items',
      footer: 'This action is permanent and cannot be undone!',
      users: 'Users',

      productIncompatibleTitle: 'Remove incompatible products?',
      productIncompatibleQuestion:
        'Are you sure you want to remove the incompatible products from the {{produto}} product?',
      productSynonym: 'Remove synonyms?',
      productSynonymQuestion:
        'Are you sure you want to remove the synonyms from the {{produto}} product?',

      dilutedProductTitle: 'Remove diluted products?',
      dilutedProductQuestion:
        'Are you sure you want to remove the diluted products from the {{produto}} product?',

      associatedProductTitle: 'Remove associated products?',
      associatedProductQuestion:
        'Are you sure you want to remove the associated products from the product {{produto}}?',
    },
    single: {
      question:
        'Are you sure you want to delete batch {{numero}} from product {{produto}}?',
      productQuestion:
        'Are you sure you want to delete the product {{produto}}?',
      subGroupQuestion:
        'Are you sure you want to delete the subgroup {{subgrupo}}??',
      groupQuestion: 'Are you sure you want to delete the group {{grupo}}??',
      supplierQuestion:
        'Are you sure you want to delete the supplier {{fornecedor}}?',
      invoiceQuestion:
        'Are you sure you want to delete the Invoice Number {{numero}} series {{serie}} from supplier {{fornecedor}}?',

      footerStockBalance:
        'This action will also delete the initial inventory movement. ',
      footerStockBalanceBatch:
        'This action will also delete the initial inventory movements. ',

      productIncompatibleTitle:
        'Are you sure you want to delete the incompatible product?',
      productIncompatibleQuestion:
        'Are you sure you want to delete the incompatible product "{{produtoIncompatível}}" from product "{{produtoPrincipal}}"?',
      productSynonymTitle: 'Remove the synonym?',
      productSynonymQuestion:
        'Are you sure you want to remove the synonym "{{produtoSinônimo}}" from the product "{{produtoPrincipal}}"?',

      dilutedProductTitle: 'Remove diluted product?',
      dilutedProductQuestion:
        'Are you sure you want to remove the diluted product "{{produtoDiluido}}" from the product "{{produtoPrincipal}}"?',

      associatedProductTitle: 'Remove associated product?',
      associatedProductQuestion:
        'Are you sure you want to remove the associated product "{{associatedProduct}}" from the main product {{mainProduct}}?',
    },
    footer: 'This action is {{action}} and cannot be undone!',
    simpleFooter: 'This action is permanent and cannot be undone!',
    permanent: 'permanent',
  },

  editModal: {
    many: {
      title: {
        before: 'Change Status of',
        after: 'items',
        inactive: 'Deactivate users',
      },
      confirm: {
        copy: 'Are you sure that you want to deactivate {{user}} usuários?\nThis action will prevent users from accessing the system!',
      },
    },
    single: {
      title: {
        before: 'Change Status of',
        inactive: 'Deactivate user',
      },
      confirm: {
        inactivate: 'Deactivate',
        areYouSure: 'Are you sure that you want to deactivate {{description}}?',
        footer: 'This action will prevent the user from accessing the system!',
        areYouSureLotStatus:
          'Are you sure you want to change the status of batch {{numero}} of product {{produto}} to {{status}}?',
        editStatus: 'Change status',
        editStatusLoading: 'Changing',
      },
    },
    statusPlaceHolder: 'Select a status',
  },

  leaveWithoutSave: {
    title: 'Want to leave?',
    body: 'Are you sure that you want to leave this page? Some changes may not be saved.',
    okButton: 'Leave without save',
  },

  removeLinkedModal: {
    removeQuestion: 'Are you sure you want to remove the link',
  },

  removeProductLinkedMessageModal: {
    removeQuestion:
      'Are you sure you want to remove the product link for messages',
  },
};

export const modalPT = {
  back: 'Voltar',
  cancel: 'Cancelar',
  save: 'Salvar',
  delete: 'Excluir',
  edit: 'Alterar',
  inactive: 'Inativar',

  deleteModal: {
    users: 'usuários',
    many: {
      title: 'Você tem certeza que deseja excluir',
      items: 'itens',
      footer: 'Essa ação é permanente e não pode ser desfeita!',
      users: 'Usuários',

      productIncompatibleTitle: 'Remover os produtos incompatíveis?',
      productIncompatibleQuestion:
        'Você tem certeza que deseja remover os produtos incompatíveis do produto {{produto}}?',

      productSynonym: 'Remover os sinônimos?',
      productSynonymQuestion:
        'Você tem certeza que deseja remover os sinônimos do produto {{produto}}?',

      dilutedProductTitle: 'Remover os produtos diluídos?',
      dilutedProductQuestion:
        'Você tem certeza que deseja remover os produtos diluídos do produto {{produto}}?',

      associatedProductTitle: 'Remover os produtos associados?',
      associatedProductQuestion:
        'Você tem certeza que deseja remover os produtos associados do produto {{produto}}?',
    },
    single: {
      question:
        'Você tem certeza que deseja excluir o lote {{numero}} do produto {{produto}}?',
      productQuestion:
        'Você tem certeza que deseja remover o produto {{produto}}?',
      subGroupQuestion:
        'Você tem certeza que deseja excluir o subgrupo {{subgrupo}}?',
      groupQuestion: 'Você tem certeza que deseja excluir o grupo {{grupo}}?',
      supplierQuestion:
        'Você tem certeza que deseja excluir o fornecedor {{fornecedor}}?',
      invoiceQuestion:
        'Você tem certeza que deseja excluir a Nota Fiscal Número {{numero}} série {{serie}} do fornecedor {{fornecedor}}?',

      footerStockBalance:
        'Essa ação irá excluir também a movimentação de estoque inicial. ',
      footerStockBalanceBatch:
        'Essa ação irá excluir também as movimentações de estoque inicial. ',

      productIncompatibleTitle: 'Rmover o produto incompatível?',
      productIncompatibleQuestion:
        'Você tem certeza que deseja remover o produto incompatível "{{produtoIncompatível}}" do produto {{produtoPrincipal}}?',

      productSynonymTitle: 'Remover o sinônimo?',
      productSynonymQuestion:
        'Você tem certeza que deseja remover o sinônimo "{{produtoSinônimo}}" do produto {{produtoPrincipal}}?',

      dilutedProductTitle: 'Remover o produto diluído?',
      dilutedProductQuestion:
        'Você tem certeza que deseja remover o produto diluído "{{produtoDiluido}}" do produto {{produtoPrincipal}}?',

      associatedProductTitle: 'Remover o produto associado?',
      associatedProductQuestion:
        'Você tem certeza que deseja remover o produto associado "{{produtoAssociado}}" do produto {{produtoPrincipal}}?',
    },
    footer: 'Essa ação é {{action}} e não poderá ser desfeita!',
    simpleFooter: 'Essa ação é permanente e não pode ser desfeita!',
    permanent: 'permanente',
  },

  editModal: {
    many: {
      title: {
        before: 'Alterar Status de',
        after: 'itens',
        inactive: 'Inativar usuários',
      },
      confirm: {
        copy: 'Você tem certeza que deseja inativar {{user}} **usuários**?\nEssa ação impedirá os usuários de acessarem o sistema!',
      },
    },
    single: {
      title: {
        inactive: 'Inativar usuário',
        before: 'Alterar Status de',
      },
      confirm: {
        inactivate: 'Inativar',
        areYouSure: 'Você tem certeza que deseja inativar {{description}}?',
        footer: 'Essa ação impedirá o(a) usuário(a) de acessar o sistema!',
        areYouSureLotStatus:
          'Você tem certeza que deseja alterar o status do lote {{numero}} do produto {{produto}} para {{status}}?',
        editStatus: 'Alterar status',
        editStatusLoading: 'Alterando',
      },
    },
    statusPlaceHolder: 'Selecione um status',
  },

  leaveWithoutSave: {
    title: 'Deseja sair sem salvar?',
    body: 'Você tem certeza que deseja sair da página sem salvar? As alterações que você fez talvez não sejam salvas.',
    okButton: 'Sair sem salvar',
  },

  removeLinkedModal: {
    removeQuestion: 'Você tem certeza que deseja remover o vínculo',
  },

  removeProductLinkedMessageModal: {
    removeQuestion:
      'Você tem certeza que deseja remover o vínculo do produto para as mensagens',
  },
};
