import { FC } from 'react';
import { Col, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import { Card } from '@/Components';
import { IStandardFormulaSpecificationsDetails } from './interfaces';
import { Procedures } from './Components/Procedures';
import { Baseboard } from './Components/Baseboard';
import { useStandardFormulaSpecificationsDetailsCallAPI } from './Hooks/useStandardFormulaSpecificationsDetailsCallAPI';

export const StandardFormulaSpecificationsDetails: FC<IStandardFormulaSpecificationsDetails> =
  ({ disabled }) => {
    const { t } = useTranslation();
    
    const {
      standardFormula
    } = useStandardFormulaSpecificationsDetailsCallAPI();

    return (
      <>
        <Card title={t('common.specifications')} withoutCollapse={true}>
          <Row gutter={[16, 0]} justify="start">
            <Col span={24}>
              <Procedures
                standardFormula={standardFormula}
                disabled={disabled}
              />
            </Col>
          </Row>
          <Row gutter={[16, 0]} justify="start">
            <Col span={24} style={{ marginTop: '24px' }}>
              <Baseboard
                standardFormula={standardFormula}
                disabled={disabled}
              />
            </Col>
          </Row>
        </Card>
      </>
    );
  };
