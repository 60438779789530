import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Card } from '@/Components';
import { IDetailsForm } from './interfaces';
import { useInfoCardCallAPI } from './Hooks/useInfoCardCallAPI';
import { CodeQuantityInfo } from './Components/CodeQuantityInfo';
import { BatchInfo } from './Components/BatchInfo';
import { IdentityInfo } from './Components/IdentityInfo';
import { InvoiceInfo } from './Components/InvoiceInfo';

export const InfoCard: FC<IDetailsForm> = ({ disabled }) => {
  const { t } = useTranslation();

  const { lots } = useInfoCardCallAPI();

  return (
    <Card
      title={t('supply.lot.details.details.titleInformation')}
      withoutCollapse
    >
      <CodeQuantityInfo />
      <BatchInfo
        lots={lots}
        disabled={disabled}
      />
      <IdentityInfo />
      <InvoiceInfo />
    </Card>
  );
};
