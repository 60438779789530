import { IUseFunctions } from './interfaces';

export const useProviderFunctions = ({
  setExternalIdProvider,
  refetchSearchProviderPage,
}: IUseFunctions) => {
  const onChange = (value: string) => {
    setExternalIdProvider(value);
  };

  const onSearch = (search: string | undefined) => {
    refetchSearchProviderPage({
      search: search,
    });
  };

  return {
    onChange,
    onSearch,
  };
};
