import { useState } from 'react';

export const useCreateStates = () => {
  const [isBackModalOpen, setIsBackModalOpen] = useState(false);

  const [personType, setPersonType] = useState(0);

  return {
    isBackModalOpen,
    setIsBackModalOpen,

    personType,
    setPersonType,
  };
};
