import { CurrencyInput } from '@/Components/Inputs/CurrencyInput';
import { Col, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import { useGetTotalProducts } from './hooks/useGetTotalproducts';
import { useFormikContext } from 'formik';
import { Tooltip } from '@/Components';
import { currencyFormater } from '@/Utils/Currency';

export const RowOne: React.FC = () => {
  const { t } = useTranslation();
  const { getTotalProduct } = useGetTotalProducts();
  const form: any = useFormikContext();

  return (
    <Row gutter={[16, 0]}>
      <Col span={4}>
        <Tooltip
          showMe={
            form.values.valorBaseIcms !== form.initialValues.valorBaseIcms
          }
          title={`${t(
            'purchasing.invoice.edit.originalIcmsBase'
          )}${currencyFormater(form.initialValues.valorBaseIcms)}`}
        >
          <CurrencyInput
            label={t('purchasing.invoice.create.ICMSBase')}
            name="valorBaseIcms"
            placeHolder={t(
              'purchasing.invoice.create.placeHolder.CurrencyPlaceHolder'
            )}
            withStatusIcon
          />
        </Tooltip>
      </Col>
      <Col span={4}>
        <Tooltip
          showMe={form.values.valorIcms !== form.initialValues.valorIcms}
          title={`${t(
            'purchasing.invoice.edit.originalIcmsValue'
          )}${currencyFormater(form.initialValues.valorIcms)}`}
        >
          <CurrencyInput
            label={t('purchasing.invoice.create.ICMSValue')}
            name="valorIcms"
            placeHolder={t(
              'purchasing.invoice.create.placeHolder.CurrencyPlaceHolder'
            )}
            withStatusIcon
          />
        </Tooltip>
      </Col>
      <Col span={4}>
        <Tooltip
          showMe={
            form.values.valorBaseIcmsSubstituicao !==
            form.initialValues.valorBaseIcmsSubstituicao
          }
          title={`${t(
            'purchasing.invoice.edit.originalIcmsBaseSubstitution'
          )}${currencyFormater(form.initialValues.valorBaseIcmsSubstituicao)}`}
        >
          <CurrencyInput
            label={t('purchasing.invoice.create.ICMSBaseSubstitution')}
            name="valorBaseIcmsSubstituicao"
            placeHolder={t(
              'purchasing.invoice.create.placeHolder.CurrencyPlaceHolder'
            )}
            withStatusIcon
          />
        </Tooltip>
      </Col>
      <Col span={4}>
        <Tooltip
          showMe={
            form.values.valorIcmsSubstituicao !==
            form.initialValues.valorIcmsSubstituicao
          }
          title={`${t(
            'purchasing.invoice.edit.originalIcmsValueSubstitution'
          )}${currencyFormater(form.initialValues.valorIcmsSubstituicao)}`}
        >
          <CurrencyInput
            label={t('purchasing.invoice.create.ICMSValueSubstitution')}
            name="valorIcmsSubstituicao"
            placeHolder={t(
              'purchasing.invoice.create.placeHolder.CurrencyPlaceHolder'
            )}
            withStatusIcon
          />
        </Tooltip>
      </Col>
      <Col span={4}>
        <Tooltip
          showMe={+getTotalProduct() !== form.initialValues.valorProdutos}
          title={`${t(
            'purchasing.invoice.edit.originalTotalProducts'
          )}${currencyFormater(form.initialValues.valorProdutos)}`}
        >
          <CurrencyInput
            label={t('purchasing.invoice.create.totalProducts')}
            name="valorProdutos"
            placeHolder={t(
              'purchasing.invoice.create.placeHolder.CurrencyPlaceHolder'
            )}
            disabled
            value={getTotalProduct()?.toString()}
            withStatusIcon
          />
        </Tooltip>
      </Col>
    </Row>
  );
};
