import { useFormikContext } from 'formik';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { TextInput } from '../../../../../../../../../../Components';
import styles from './pharmaceuticalFormDescriptionDetails.module.scss';
import { useEditCallAPI } from '../../Hooks/useEditCallAPI';
import { useEditFunctions } from '../../Hooks/useEditFunctions';

interface IDescriptionPharmaceuticalFormInput {
  disabled: boolean;
  externalId: string;
}

export const DescriptionPharmaceuticalFormInput: FC<
  IDescriptionPharmaceuticalFormInput
> = ({ disabled, externalId }) => {
  const { t } = useTranslation();
  const form: any = useFormikContext();

  const { mutateAsync } = useEditCallAPI({ externalId, form, t });

  const { fetchInputPharmaceuticalFormDescription } = useEditFunctions({
    mutateAsync,
  });

  return (
    <TextInput
      className={styles['text-input']}
      name="descricao"
      placeHolder={t(
        'production.pharmaceuticalForm.create.descriptionPlaceholder'
      )}
      required={!disabled}
      withStatusIcon
      label={t('production.pharmaceuticalForm.create.description')}
      onChange={fetchInputPharmaceuticalFormDescription}
      disabled={disabled}
    />
  );
};
