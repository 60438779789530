import { useCallback } from "react";
import {
  FETCH_ASSOCIATED_PRODUCT,
} from '@/ControllerApiHook/UniqIds/Supply/ProductKeys';
import { useQueryClient } from '@tanstack/react-query';
import { IUseFunctions } from "./interfaces";

export const useAssociatedProductFunctions = ({
  deleteAssociatedProduct
}: IUseFunctions) => {
  const queryClient = useQueryClient();

  const onDelete = useCallback(async (invoice: any[]) => {
    const productsExternalId = invoice.map((e: any) => e.externalId);
    
    await deleteAssociatedProduct.mutateAsync({
      produtosAssociadoExternalId: productsExternalId,
    });
    queryClient.invalidateQueries([FETCH_ASSOCIATED_PRODUCT]);
  }, [deleteAssociatedProduct]);

  return {
    onDelete
  };
};