import { useState } from 'react';

export const useCreateStates = () => {
  const [showSupplyPlace, setShowSupplyPlace] = useState<Boolean>(false);

  const [companyExternalId, setCompanyExternalId] = useState<string>('');

  const [isErrorValidateName, setIsErrorValidateName] =
    useState<boolean>(false);

  return {
    showSupplyPlace,
    setShowSupplyPlace,
    companyExternalId,
    setCompanyExternalId,
    isErrorValidateName,
    setIsErrorValidateName,
  };
};
