import React from 'react';
import { Text } from '../../../../../../Components';
import { CurrencyText } from '../../../../../../Components/CurrencyText';

export const stockLocationColumns = (t: any) => [
  {
    title: t('supply.lot.details.stockLocation.code'),
    dataIndex: 'sequenciaGroupTenant',
    key: 'sequenciaGroupTenant',
  },
  {
    title: t('supply.lot.details.stockLocation.placeName'),
    dataIndex: 'localEstoqueDescricao',
    key: 'localEstoqueDescricao',
    sortColumn: 'localEstoqueDescricao',
    sorter: true,
    isHighlightable: true,
    defaultSortOrder: 'ascend',
    render: (x: any) => {
      return (
        <Text type="ui-tiny-bold" style={{ cursor: 'pointer' }}>
          {x}
        </Text>
      );
    },
  },
  {
    title: t('supply.lot.details.stockLocation.company'),
    dataIndex: 'empresaDescricao',
    key: 'empresaDescricao',
  },
  {
    title: t('supply.lot.details.stockLocation.availableQuantity'),
    dataIndex: 'saldo',
    width: 220,
    key: 'saldo',
    render: (x: any, row: any) => (
      <Text type={'ui-tiny-content'}>
        <CurrencyText
          value={x}
          suffix={` ${row?.unidadeAbreviacao}`}
          decimalScale={2}
        />
      </Text>
    ),
  },
];
