import { IProductGroupListData } from '@/Data/Interfaces/response/Product/IProductRespose';
import { useTranslation } from 'react-i18next';
import { IUseMemorizations } from './interfaces';
import { useFormikContext } from 'formik';

export const useProductCallMemorizations = ({
  productList,
  index,
}: IUseMemorizations) => {
  const { t } = useTranslation();
  const form: any = useFormikContext();
  let selectedItemFromForm;
  const itemExternalId =
    form?.values?.pedidoCompraItens?.[index]?.pedidoCompraItemExternalId;

  if (itemExternalId) {
    selectedItemFromForm = `item_${itemExternalId}`;
  }

  const productListFormatted =
    productList && productList.data ? productList.data : [];

  const items = productListFormatted.map((x: IProductGroupListData) => ({
    id: x?.externalId,
    label: x?.descricao,
    subLabel: `${t('purchasing.purchaseOrder.create.subLabelProduct')} ${
      x.sequenciaGroupTenant
    }`,
    allItem: x,
  }));

  const label =
    index === 0
      ? t('purchasing.purchaseOrder.create.productsCardField.product')
      : '';

  return {
    items,
    label,
    selectedItemFromForm,
  };
};
