import React from 'react';
import { Card, Form } from '../../../../../Components';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import { CreateSchema } from './CreateForm.schema';
import { useCreateStates } from './Hooks/useCreateStates';
import { useCreateCallAPI } from './Hooks/useCreateCallAPI';
import { useCreateFunctions } from './Hooks/useCreateFunctions';
import { FooterButtons } from './Components/FooterButton';
import { RowDescription } from './Components/Rows/DescriptionRow';
import { OtherRow } from './Components/Rows/OtherRow';
import { ConnectedFocusError } from '@/Components/ConnectedFocusError';

export const CreatePosology: React.FC = () => {
  const { t } = useTranslation();

  const { idUntAbbrev, setIdUnitAbbrev, untAbbrev, setUntAbbrev } =
    useCreateStates();

  const { createPosology, isLoadingCreatePosology } = useCreateCallAPI();

  const { onSubmit } = useCreateFunctions({
    createPosology,
  });

  return (
    <Form schema={CreateSchema} onSubmit={onSubmit}>
      <>
        <Card title={t('common.information')} withoutCollapse={true}>
          <RowDescription
            setUntabbreviated={setUntAbbrev}
            setIdUntabbreviated={setIdUnitAbbrev}
          />
          <OtherRow idUntAbbrev={idUntAbbrev} untAbbrev={untAbbrev} />
        </Card>
        <FooterButtons isLoadingCreatePosology={isLoadingCreatePosology} />
        <ConnectedFocusError />
      </>
    </Form>
  );
};
