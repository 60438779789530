import { FC, useEffect } from 'react';
import { Col, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import { useFormikContext } from 'formik';
import { DensityInput } from '@/Components/Inputs/DensityInput';
import { InputProduct } from './Components/InputProduct';
import { PharmaceuticalForm } from './Components/PharmaceuticalForm';
import { AmountDefault } from './Components/AmountDefault';
import { DismembermentType } from './Components/DismembermentType';
import { Validity } from './Components/Validity';
import { Dilution } from './Components/Dilution';
import { Reason } from './Components/Reason';
import { IStandardFormulaDetails } from './interfaces';
import { useStandardFormulaDetailsStates } from './Hooks/useStandardFormulaDetailsStates';
import { useStandardFormulaDetailsCallAPI } from './Hooks/useStandardFormulaDetailsCallAPI';

export const StandardFormulaDetails: FC<IStandardFormulaDetails> = ({
  setPharmaceuticalFormSelected,
}) => {
  const form: any = useFormikContext();
  const { t } = useTranslation();

  const {
    classificationProductId,
    setClassificationProductId,
    unidadeMedidaId,
    setUnidadeMedidaId
  } = useStandardFormulaDetailsStates();

  const {
    unitsMeasurementsDefault
  } = useStandardFormulaDetailsCallAPI();

  const unitMeasureIdDefault = unitsMeasurementsDefault?.unidadeEstoqueId;

  useEffect(() => {
    if (unitMeasureIdDefault) {
      form.setFieldValue('unidadeMedidaId', unitMeasureIdDefault);
    }
    if (form.values.produtoExternalId === undefined) {
      form.setFieldValue('unidadeMedidaId', undefined);
    }
  }, [unitMeasureIdDefault]);

  return (
    <>
      <Row gutter={[16, 0]} style={{ marginBottom: 20 }}>
        <Col span={8}>
          <InputProduct
            setClassificationProductId={setClassificationProductId}
            setUnidadeMedidaId={setUnidadeMedidaId}
          />
        </Col>
        <Col span={8}>
          <PharmaceuticalForm
            setPharmaceuticalFormSelected={setPharmaceuticalFormSelected}
          />
        </Col>
      </Row>
      <Row gutter={[16, 0]} style={{ marginBottom: 20 }}>
        <Col span={3}>
          <AmountDefault
            classificationProductId={classificationProductId}
            unidadeMedidaId={unidadeMedidaId}
          />
        </Col>
        <Col span={5}>
          <DismembermentType />
        </Col>
        <Col span={2}>
          <Validity />
        </Col>
        <Col span={2}>
          <Dilution />
        </Col>
        <Col span={2}>
          <Reason />
        </Col>
        <Col span={2}>
          <DensityInput
            name="densidade"
            label={t('production.standardFormula.create.density')}
            placeHolder={t(
              'production.standardFormula.create.densityPlaceholder'
            )}
            withoutMarginBottom={true}
          />
        </Col>
      </Row>
    </>
  );
};
