import { useState } from 'react';

export const useEditUserStates = () => {
  const [modalDeleteUserIsVisible, setModalDeleteUserIsVisible] =
    useState(false);

  return {
    modalDeleteUserIsVisible,
    setModalDeleteUserIsVisible,
  };
};
