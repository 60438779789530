import { FC } from 'react';
import { Label } from '@/Components';
import { Col, Row } from 'antd';
import { ICollumsTitle } from './interfaces';

import styles from './TableCollumTitle.module.scss';

export const CollumsTitle: FC<ICollumsTitle> = ({ t }) => {
  return (
    <>
      <Row gutter={[16, 0]}>
        <Col span={7}>
          <Label
            children={t(
              'purchasing.purchaseOrder.create.productsCardField.product'
            )}
            type="ui-tiny-bold"
          />
        </Col>
        <Col span={5}>
          <Label
            children={t(
              'purchasing.purchaseOrder.create.productsCardField.unValue'
            )}
            type="ui-tiny-semibold"
          />
        </Col>
        <Col span={4}>
          <Label
            children={t(
              'purchasing.purchaseOrder.create.productsCardField.unitaryDiscount'
            )}
            type="ui-tiny-semibold"
          />
        </Col>
        <Col span={4}>
          <Label
            children={t(
              'purchasing.purchaseOrder.create.productsCardField.amount'
            )}
            type="ui-tiny-semibold"
          />
        </Col>
        <Col span={4}>
          <Label
            children={t(
              'purchasing.purchaseOrder.create.productsCardField.total'
            )}
            type="ui-tiny-semibold"
          />
        </Col>
      </Row>
      <div className={styles['divider']} />
    </>
  );
};
