import { useFormikContext } from 'formik';
import DateUtils from '@/Utils/DateUtils';
import { useTranslation } from 'react-i18next';

export const useDeliveryForecastFunctions = () => {
  const form: any = useFormikContext();
  const { t } = useTranslation();

  const onDeliveryDateChange = (value: string) => {
    const parsedDate = DateUtils.convertStringToDate(value);
    if (!isNaN(parsedDate.getTime())) {
      if (parsedDate < new Date()) {
        form.setFieldError(
          'previsaoEntrega',
          t('purchasing.purchaseOrder.create.errors.dateIsEarlier')
        );
      }
    }
  };

  return {
    onDeliveryDateChange
  };
};