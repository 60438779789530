import { Route, Switch } from 'react-router-dom';
import LossesRegistration from '@/Pages/Sectors/Inventory/Losses/CreateForm';
import LossesEditPage from '@/Pages/Sectors/Inventory/Losses/EditPage';
import LossesList from '@/Pages/Sectors/Inventory/Losses/List';

export const LossesRoutes = () => (
  <Switch>
    <Route path="/inventory/losses/create" component={LossesRegistration} />
    <Route
      path="/inventory/losses/:externalId/:tab"
      component={LossesEditPage}
    />
    <Route path="/inventory/losses" component={LossesList} />
  </Switch>
);
