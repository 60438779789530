import React from 'react';
import { Divflex } from '../../../../../../Layouts/DivWhithFlex';
import { Text } from '../../../../../../Components';
import { FooterThisActionIsPermanent } from '../../../../../../Components/Table/components/Modals/DeleteModal';
import { DeleteModalOnTable } from '../../../../../../Utils/DeleteModalUtils';
import ReactMarkdown from 'react-markdown';

export const TitleHandler = (selectedRowsOnTable: any, t: any) =>
  selectedRowsOnTable?.length === 1
    ? `${t('common.delete')}  "${selectedRowsOnTable[0]?.nomeFantasia}"?`
    : `${t('modal.delete')} ${selectedRowsOnTable?.length} ${t(
        'common.suppliers'
      )}?`;

export const BodyHandler = (selectedRowsOnTable: any, t: any) =>
  selectedRowsOnTable?.length > 1 ? (
    DeleteModalOnTable(
      selectedRowsOnTable,
      `${t('modal.deleteModal.many.title')} ${t('common.theSuppliers')}:`,
      'nomeFantasia',
      t('common.suppliers')
    )
  ) : (
    <>
      <div style={{ padding: '20px' }}>
        <Divflex style={{ flexDirection: 'column' }}>
          <Text color="text-50" type="ui-tiny-content">
            <ReactMarkdown>
              {t('modal.deleteModal.single.supplierQuestion', {
                fornecedor: `**${
                  selectedRowsOnTable && selectedRowsOnTable[0]?.nomeFantasia
                }**`,
              })}
            </ReactMarkdown>
          </Text>
        </Divflex>
        <FooterThisActionIsPermanent />
      </div>
    </>
  );
