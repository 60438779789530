import { useCallback } from "react";
import { IUseFunctions } from "./interfaces";

export const useAddressCardFunctions = ({ props }: IUseFunctions) => {
  const onChange = useCallback((values: any[] | undefined) =>
    props.setFieldValue(
      'enderecos',
      values?.map((x) => ({
        ...x,
      }))
    )
  , [props.setFieldValue]);

  return {
    onChange
  };
};