import { IPackingClassificationListData } from '@/Data/Interfaces/response/PackingClassification/IPackingClassificationResponse';
import React from 'react';
import { useState } from 'react';

export const useCreateStates = () => {
  const [currentProduct, setCurrentPackingClassification] =
    React.useState<IPackingClassificationListData>();

  const [isDeleteButtonDisabled, setIsDeleteButtonDisabled] = useState(true);

  return {
    currentProduct,
    setCurrentPackingClassification,
    isDeleteButtonDisabled,
    setIsDeleteButtonDisabled,
  };
};
