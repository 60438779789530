import React from 'react';
import { MaskForCNPJ } from '../../../../../Utils/MasksUtils';
import { Status, i18n, Link } from '../../../../../Components';

export const companyCollums = (t: any) => [
  {
    title: t('adminBootis.companyList.tableTitle.fantasyName'),
    dataIndex: 'nomeFantasia',
    key: 'nomeFantasia',
    width: '42%',
    sortColumn: 'nomeFantasia',
    sorter: true,
    defaultSortOrder: 'ascend',
    isHighlightable: true,
    render: (x: any, row: any) => (
      <Link
        type="ui-tiny-bold"
        to={`/admin-bootis/company/${row.externalId}/details`}
        style={{ width: 'fit-content' }}
      >
        {x}
      </Link>
    ),
  },
  {
    title: t('adminBootis.companyList.tableTitle.cnpj'),
    dataIndex: 'cnpj',
    key: 'cnpj',
    sortColumn: 'cnpj',
    sorter: true,
    isHighlightable: true,
    width: '20%',
    render: (cnpj: string) => MaskForCNPJ(cnpj),
  },
  {
    title: t('adminBootis.companyList.tableTitle.city'),
    dataIndex: 'cidadeUf',
    key: 'cidadeUf',
    sortColumn: 'cidadeUf',
    isHighlightable: true,
    sorter: true,
    width: '20%',
  },
  {
    title: t('userGroups.list.status'),
    dataIndex: 'ativa',
    key: 'ativa',
    sortColumn: 'ativa',
    isHighlightable: true,
    sorter: true,
    render: (text: any) => (
      <Status type={text ? 'success' : 'disabled'}>
        {i18n.t(`userGroups.list.statusTypes.${text ? 'active' : 'inactive'}`)}
      </Status>
    ),
  },
];
