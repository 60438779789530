import { IListSpecialtiesData } from '@/Data/Interfaces/response/Specialties/ISpecialtiesResponse';
import { useState } from 'react';

export const useListStates = () => {
  const [createModalOpen, setCreateModalOpen] = useState<boolean>(false);

  const [selectedRowsOnTable, setSelectedRowsOnTable] = useState<
    IListSpecialtiesData[]
  >([]);

  return {
    createModalOpen,
    setCreateModalOpen,
    selectedRowsOnTable,
    setSelectedRowsOnTable,
  };
};
