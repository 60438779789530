import { 
  useControllerMutateApiHook, 
  useControllerQueryApiHook 
} from "@/ControllerApiHook/Controller";
import { ProductAPI } from "@/Data/API/Supply/Product";
import { IProductGroupResponse } from "@/Data/Interfaces/response/Product/IProductRespose";
import { 
  EDIT_GROUP, 
  FETCH_GET_GROUP 
} from "@/ControllerApiHook/UniqIds/Supply/ProductKeys";
import { useParams } from "react-router";

export const useDetailsFormCallAPI = () => {
  const { externalId } = useParams<{ externalId: string }>();

  const { data } = useControllerQueryApiHook<IProductGroupResponse>({
    uniqId: FETCH_GET_GROUP,
    entityApi: ProductAPI.getProductGroup,
    externalIds: [externalId]
  });

  const editGroup: any = useControllerMutateApiHook({
    uniqId: EDIT_GROUP,
    entityApi: ProductAPI.editProductGroup
  });

  return {
    data,
    editGroup
  };
};