export const FETCH_GET_STOCK_LOCATION =
  '[SUPPLY_STOCK_LOCATION] FETCH_GET_STOCK_LOCATION';
export const FETCH_GET_INVENTORY_BY_STOCK_LOCATION =
  '[SUPPLY_STOCK_LOCATION] FETCH_GET_INVENTORY_BY_STOCK_LOCATION';

export const FETCH_LIST_STOCK_LOCATION =
  '[SUPPLY_STOCK_LOCATION] FETCH_LIST_STOCK_LOCATION';
export const FECTH_LIST_STOCK_LOCATION_FOR_DROPDOWN_TYPE =
  '[SUPPLY_STOCK_LOCATION] FECTH_LIST_STOCK_LOCATION_FOR_DROPDOWN_TYPE';

export const CREATE_STOCK_LOCATION =
  '[SUPPLY_STOCK_LOCATION] CREATE_STOCK_LOCATION';
export const EDIT_STOCK_LOCATION =
  '[SUPPLY_STOCK_LOCATION] EDIT_STOCK_LOCATION';
export const DELETE_STOCK_LOCATION =
  '[SUPPLY_STOCK_LOCATION] DELETE_STOCK_LOCATION';
export const EDIT_STATUS_STOCK_LOCATION =
  '[SUPPLY_STOCK_LOCATION] EDIT_STATUS_STOCK_LOCATION';

export const THERE_IS_STOCK_LOCATION_TYPE =
  '[SUPPLY_STOCK_LOCATION] THERE_IS_STOCK_LOCATION_TYPE';
export const CONVERT_UNIT_OF_MEASURE =
  '[SUPPLY_STOCK_LOCATION] CONVERT_UNIT_OF_MEASURE';
export const CONVERT_UNIT_OF_MEASURE_MONETARY_VALUE =
  '[SUPPLY_STOCK_LOCATION] CONVERT_UNIT_OF_MEASURE_MONETARY_VALUE';
