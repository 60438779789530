import { IListProviderData } from '@/Data/Interfaces/response/Provider/IProviderResponse';
import { IUseMemorizations } from './interfaces';

export const useProviderMemorizations = ({
  providersList,
}: IUseMemorizations) => {
  const items = providersList?.data?.map((x: IListProviderData) => ({
    id: x.externalId,
    label: x.nome,
    subLabel: `${x.tipoPessoaDescricao} - ${x.tipoFornecedor}`,
  }));

  return {
    items,
  };
};
