import React, {
  CSSProperties,
  FC,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { ConfigProvider, Select } from 'antd';
import { Field, useField } from 'formik';
import { IField } from '../interfaces/IField';
import { InputLabel } from '../Label';
import { LocalizaPropriedadePorPath } from '../../../Utils/FormikUtils';
import { Icon, Text, Tooltip } from '../..';
import { useTranslation } from 'react-i18next';
import { TextType } from '../../Text';
import { Color } from '../../../Utils/ColorUtils';
import { getScrollPercentage } from '../../../Utils/ScrollUtils';
import { Divflex } from '../../../Layouts/DivWhithFlex';
import { IconName } from '../../Icon/IconClasses';
import { uniqBy } from 'lodash';
import { v4 as uuidV4 } from 'uuid';
import { OptionDropdown } from './Components/OptionDropdown';
import { StaticOptionsDropdown } from './Components/StaticOptionsDropdown';
import Spinner from '@/Components/Spinner';

import styles from './Dropdown.module.scss';
import '@static/scss/SelectStatic/Select.override.scss';

const { Option } = Select;

export interface IInputLabel {
  children: string;
  typeLabel?: TextType;
  colorLabel?: Color;
}

export interface IAvatarDropdown {
  name: string;
  lastName?: string;
  email?: string;
}

export interface IItem {
  id: string | number;
  label?: string;
  customSelectedLabel?: string;

  code?: string;
  img?: ReactNode;

  avatar?: IAvatarDropdown;

  leftIcon?: IconName;

  subLabel?: string;
  disabledItem?: boolean;

  allItem?: any;
}

export interface IDropdown {
  items?: IItem[];
  name: string;

  /**
   * This prop merges the current value with items
   * avoiding it not being present on the pagination
   *
   * NOTE: Those strings are FORM names like "fornecedor.id" and the value does need to be the same of items prop (IItems)
   */
  selectedItemFromForm?: string;

  /**
   * This prop add static options on the bottom of the dropdown popup
   */
  staticOptions?: {
    name: string;
    icon: IconName;
    onClick: () => void;
  }[];

  label?: IInputLabel[] | string;
  placeHolder?: string;

  onFocus?: (event: React.FocusEvent<HTMLElement>) => void;
  onBlur?: (event: React.FocusEvent<HTMLElement>) => void;
  onChange?: (value: string, option: any, selectedItem: any) => void;
  onScrollEnd?: () => void;

  className?: string;

  withoutMarginBottom?: boolean;

  withTooltip?: {
    title: string;
    icon: IconName;
  };

  required?: boolean;
  autoIncrementBy?: string;

  width?: string;
  disabled?: boolean;
  isLoading?: boolean | undefined;

  withStatusIcon?: boolean;
  value?: string;

  withLoadingLabel?: boolean;
  isSuccessIcon?: boolean;

  subText?: string;

  style?: CSSProperties;
  allowClear?: boolean;
  getPopupContainerId?: string;

  dataTestId?: string;
  containerDataTestId?: string;
  id?: string;

  optionLabelProp?: string;
}

export const Dropdown: FC<IDropdown> = ({
  items,
  name,
  selectedItemFromForm,
  label,
  withoutMarginBottom,
  withTooltip,
  required,
  width,
  placeHolder,
  isLoading,
  disabled,
  autoIncrementBy,
  onChange,
  onScrollEnd,
  withStatusIcon,
  value,

  className,

  staticOptions,
  withLoadingLabel,
  isSuccessIcon,
  subText,
  style,
  allowClear,
  getPopupContainerId,
  dataTestId,
  containerDataTestId,
  id,
  optionLabelProp,
}) => {
  const [autoIncrement, setAutoIncrement] = useState(items || []);
  const [open, setOpen] = useState(false);
  const [itemsInput, setItemsInput] = useState<IItem[] | undefined>(items);
  const [{ value: selectedItem }] = useField(
    selectedItemFromForm || 'prop-not-set'
  );

  const { t } = useTranslation();

  const filtrarDados = (input: any, option: any): boolean => {
    return option?.children?.toLowerCase().indexOf(input.toLowerCase()) >= 0;
  };

  useEffect(() => {
    if (!autoIncrementBy) return;

    const allItems = [...(autoIncrement || []), ...(itemsInput || [])];
    const uniqueItems = uniqBy(allItems, autoIncrementBy);
    if (uniqueItems.length === autoIncrement.length) return;

    setAutoIncrement(uniqueItems);
  }, [autoIncrementBy, autoIncrement, itemsInput]);

  useEffect(() => {
    let currentItems = itemsInput;

    if (selectedItem)
      currentItems = uniqBy([...(items || []), selectedItem], 'id');
    else currentItems = [...(items || [])];

    setItemsInput(currentItems);
  }, [items]);

  const onScroll = useCallback(
    (event: React.UIEvent<HTMLElement>) => {
      const scrollPercentage = getScrollPercentage(event);
      const scrollPercentageThreshold = 80;

      // Call hook when you scroll to 80% or more
      if (scrollPercentage > scrollPercentageThreshold && !isLoading) {
        onScrollEnd && onScrollEnd();
      }
    },
    [onScrollEnd, isLoading]
  );

  const randomId = useMemo(() => uuidV4(), []);
  const withFlag = useMemo(
    () =>
      itemsInput?.length === 0 ? false : itemsInput?.every((item) => item.img),
    [itemsInput]
  );

  return (
    <div className={styles['dropdown']}>
      <Field name={name}>
        {(props: IField) => (
          <>
            {label && (
              <Divflex>
                <InputLabel
                  label={label}
                  required={required}
                  withStatusIcon={withStatusIcon}
                  props={props}
                  withLoadingIcon={withLoadingLabel}
                  isSuccessIcon={isSuccessIcon}
                />
                {withTooltip && (
                  <Tooltip
                    title={withTooltip.title}
                    showMe
                    children={
                      <Icon
                        className={styles['icon-with-tooltip']}
                        icon={withTooltip.icon}
                        color="text-300"
                        size="S"
                      />
                    }
                  />
                )}
              </Divflex>
            )}
            <div>
              <div
                style={{
                  maxWidth: width,
                  minWidth: width,
                }}
                id={id}
                data-testid={containerDataTestId}
                className={`${styles['div-dropdown']} ${
                  withoutMarginBottom ? '' : styles['margin-bottom']
                }`}
              >
                {withFlag && <div className={styles['div-flag']} />}
                <ConfigProvider
                  renderEmpty={() => (
                    <Text
                      type="ui-tiny-content"
                      color="text-300"
                      children={t('common.empty')}
                    />
                  )}
                >
                  <Select
                    optionLabelProp={optionLabelProp}
                    onPopupScroll={onScroll}
                    disabled={disabled || props.form.isSubmitting}
                    value={props.field.value || value}
                    style={{ width: width, ...style }}
                    placeholder={placeHolder || t('dropDown.placeHolder')}
                    open={open}
                    onChange={(value, options) => {
                      props.form.setFieldValue(name, value);
                      const allItemSelected = items?.find(
                        (x: IItem) => x.id === value
                      )?.allItem;
                      onChange && onChange(value, options, allItemSelected);
                    }}
                    id={name}
                    data-testid={dataTestId}
                    onBlur={(e) => props.field.onBlur(e)}
                    onDropdownVisibleChange={(visible) => setOpen(visible)}
                    filterOption={filtrarDados}
                    className={`ant-select ${className} ${
                      withFlag ? 'dropdown-with-flag' : ''
                    }`}
                    allowClear={allowClear ? false : true}
                    suffixIcon={
                      <Icon
                        icon="chevron-down"
                        color="text-50"
                        size="M"
                        style={disabled ? { display: 'none' } : {}}
                      />
                    }
                    notFoundContent={
                      isLoading ? (
                        <div
                          className={styles['spinner']}
                          data-testid={`${dataTestId || name}-spinner`}
                        >
                          <Spinner />
                        </div>
                      ) : (
                        <div
                          className={styles['empyt']}
                          data-testid={`${dataTestId || name}-empty`}
                        >
                          <Text
                            type="ui-tiny-content"
                            color="text-300"
                            children={t('dropDown.empty')}
                          />
                        </div>
                      )
                    }
                    getPopupContainer={(trigger) => {
                      document.getElementById(
                        getPopupContainerId ?? randomId
                      ) as any;
                      return trigger.parentElement;
                    }}
                    dropdownRender={
                      staticOptions
                        ? (menu) => (
                            <StaticOptionsDropdown
                              menu={menu}
                              staticOptions={staticOptions}
                              inputDataTestId={dataTestId}
                              name={name}
                            />
                          )
                        : undefined
                    }
                  >
                    {(autoIncrementBy
                      ? autoIncrement || []
                      : itemsInput || []
                    ).map((item, index) => (
                      <Option
                        className={`${
                          item.disabledItem
                            ? styles['option-disabled']
                            : styles['option']
                        } ${item.avatar ? styles['option-avatar'] : ''}`}
                        key={`dropdown-${item.id}`}
                        data-testid={`${dataTestId || name}-option-${index}`}
                        value={item.id}
                        label={
                          item.customSelectedLabel ? (
                            item.customSelectedLabel
                          ) : item.avatar ? (
                            <OptionDropdown
                              item={{
                                id: item?.id,
                                label: item?.label,
                                avatar: {
                                  name: item?.avatar?.name,
                                  lastName: item?.avatar?.lastName,
                                },
                              }}
                            />
                          ) : (
                            item.label
                          )
                        }
                      >
                        <OptionDropdown item={item} />
                      </Option>
                    ))}
                  </Select>
                </ConfigProvider>

                {props.form &&
                props.form.errors &&
                LocalizaPropriedadePorPath(props.form.errors, name) ? (
                  <p className={styles['errorText']}>
                    {LocalizaPropriedadePorPath(props.form.errors, name)}
                  </p>
                ) : null}
              </div>
              {subText && (
                <Text
                  type="ui-tiny-content"
                  color="text-300"
                  children={subText}
                  className={styles['sub-text']}
                />
              )}
            </div>
          </>
        )}
      </Field>
    </div>
  );
};
