import { useQueryClient } from "@tanstack/react-query";
import { IUseFunctions } from "./interfaces";
import { useFormikContext } from "formik";
import { 
  FETCH_GET_ORDER_PURCHASE, 
  FETCH_LIST_PURCHASE_ORDER 
} from "@/ControllerApiHook/UniqIds/Purchase/PurchaseOrderKeys";
import { 
  TypePurchaseOrderEnum 
} from '@/Data/Interfaces/model/typePurchaseOrderEnum';
import { useDispatch } from "react-redux";
import { NotificationActions } from '@/Store/Notification/Notification.actions';

export const useStatusCardFunctions = ({
  statusType,
  setReproveModal,
  reproveOrderPurchase,
  statusPurchaseOrder,
  setReverseModal,
  reverseReleasedOrderPurchase,
  reverseConfirmWithSupplierOrderPurchase,
  confirmWithSupplier,
  setConfirmWithSupplierModal,
  approveOrderPurchase,
  setApproveModal
}: IUseFunctions) => {
  const queryClient = useQueryClient();
  const form: any = useFormikContext();
  const dispatch = useDispatch();

  const statusTranslationFunction = (statusId: number): string => {
    if (statusId >= 1 && statusId <= statusType.length) {
      return `purchasing.purchaseOrder.list.statusType.${statusId - 1}`;
    } else {
      return 'purchasing.purchaseOrder.list.invalidStatusId';
    }
  };

  const onCancelClickReproveOrder = () => setReproveModal(false);

  const onCloseReproveOrder = () => setReproveModal(false);
  
  const onOkClickReproveOrder = async () => {
    const res = await reproveOrderPurchase.mutateAsync({
      pedidoCompraExternalId: form.values.externalId,
    });

    if (res.success)
      queryClient.invalidateQueries([FETCH_GET_ORDER_PURCHASE]);
    queryClient.invalidateQueries([FETCH_LIST_PURCHASE_ORDER]);
    setReproveModal(false);
  };

  const onCancelClickReleasedOrder = () => setReverseModal(false);
  
  const onCloseReleasedOrder = () => setReverseModal(false);
  
  const onOkClickReleasedOrder = async () => {
    const res =
      statusPurchaseOrder === TypePurchaseOrderEnum.confirmedWithSupplier
        ? await reverseConfirmWithSupplierOrderPurchase.mutateAsync({
            pedidoCompraExternalId: form.values.externalId,
          })
        : await reverseReleasedOrderPurchase.mutateAsync({
            pedidoCompraExternalId: form.values.externalId,
          });

    if (res.success)
      queryClient.invalidateQueries([FETCH_GET_ORDER_PURCHASE]);
    queryClient.invalidateQueries([FETCH_LIST_PURCHASE_ORDER]);
    setReverseModal(false);
  };

  const onCancelClickConfirmWithSupplierOrder = () => setConfirmWithSupplierModal(false);

  const onCloseConfirmWithSupplierOrder = () => setConfirmWithSupplierModal(false);
  
  const onOkClickConfirmWithSupplierOrder = async () => {
    const res = await confirmWithSupplier.mutateAsync({
      pedidoCompraExternalId: form.values.externalId,
    });

    if (res.success)
      queryClient.invalidateQueries([FETCH_GET_ORDER_PURCHASE]);
    queryClient.invalidateQueries([FETCH_LIST_PURCHASE_ORDER]);
    setConfirmWithSupplierModal(false);
  };

  const onCancelClickApproveOrder = () => setApproveModal(false);

  const onCloseApproveOrder = () => setApproveModal(false);
  
  const onOkClickApproveOrder = async () => {
    const pedidoCompraItens =
      form.values.pedidoCompraItensExternalId.filter(
        (x: any) => x !== null
      );

    const res = await approveOrderPurchase.mutateAsync({
      pedidoCompraExternalId: form.values.externalId,
      pedidoCompraItensExternalId: pedidoCompraItens,
    });

    if (res.success) {
      dispatch(NotificationActions.setCurrentFormIsDirty(false));
      queryClient.invalidateQueries([FETCH_GET_ORDER_PURCHASE]);
      queryClient.invalidateQueries([FETCH_LIST_PURCHASE_ORDER]);
      setApproveModal(false);
    }
  };

  return {
    statusTranslationFunction,
    onCancelClickReproveOrder,
    onCloseReproveOrder,
    onOkClickReproveOrder,
    onCancelClickReleasedOrder,
    onCloseReleasedOrder,
    onOkClickReleasedOrder,
    onCancelClickConfirmWithSupplierOrder,
    onCloseConfirmWithSupplierOrder,
    onOkClickConfirmWithSupplierOrder,
    onCancelClickApproveOrder,
    onCloseApproveOrder,
    onOkClickApproveOrder
  };
};