import DateUtils from '@/Utils/DateUtils';
import { FormikHelpers, FormikValues } from 'formik';
import _ from 'lodash';
import { IUseAddProductModalFunctions } from './interfaces';

export const useAddProductModalFunctions = ({
  editModal,
  productSelected,
  setInsufficientFundsModalVisible,
  editProduct,
  addProduct,
}: IUseAddProductModalFunctions) => {
  const onClickForm = async (
    value: any,
    formik: FormikHelpers<FormikValues>
  ) => {
    try {
      if (
        !_.isNil(productSelected) &&
        productSelected.saldoEstoque <= value.quantidade
      )
        setInsufficientFundsModalVisible(true);
      else if (editModal)
        await editProduct.mutateAsync({
          ...value,
          ...(value.dataPrescricao && {
            dataPrescricao: DateUtils.convertDateToApi(value.dataPrescricao),
          }),
        });
      else
        await addProduct.mutateAsync({
          ...value,
          ...(value.dataPrescricao && {
            dataPrescricao: DateUtils.convertDateToApi(value.dataPrescricao),
          }),
        });
    } catch {
      formik.setSubmitting(false);
    }
  };

  return { onClickForm };
};
