import { Card } from '@/Components';
import { useTranslation } from 'react-i18next';
import { RowOne } from './rows/RowOne';
import { RowThree } from './rows/RowThree';
import { RowTwo } from './rows/RowTwo';

export const ValueSection: React.FC = () => {
  const { t } = useTranslation();

  return (
    <Card title={t('purchasing.invoice.create.value')} withoutCollapse>
      <RowOne />
      <RowTwo />
      <RowThree />
    </Card>
  );
};
