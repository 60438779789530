import { useControllerMutateApiHook } from "@/ControllerApiHook/Controller";
import { GET_USER_WITHOUT_GROUP_LIST_TYPE } from "@/ControllerApiHook/UniqIds/Accountmanager/UserKeys";
import { UserGroupAPI } from "@/Data/API/Accountmanager/UserGroupApi";
import { 
  ADD_USER_IN_USER_GROUP, 
  GET_USER_LIST_FROM_USER_GROUP_TYPE
} from "@/ControllerApiHook/UniqIds/Accountmanager/UserGroupKeys";
import { useQueryClient } from "@tanstack/react-query";
import { IUseCallAPI } from "./interfaces";

export const useAddUserGroupCallAPI = ({
  setUsersSelected,
  setVisible
}: IUseCallAPI) => {
  const queryClient = useQueryClient();

  const addUserInUserGroup = useControllerMutateApiHook({
    uniqId: ADD_USER_IN_USER_GROUP,
    entityApi: UserGroupAPI.addUserInGroup,
    options: {
      onSuccess: () => {
        setUsersSelected([]);
        setVisible(false);
        queryClient.invalidateQueries([GET_USER_LIST_FROM_USER_GROUP_TYPE]);
        queryClient.invalidateQueries([GET_USER_WITHOUT_GROUP_LIST_TYPE]);
      }
    }
  });

  return { addUserInUserGroup };
};