import { Card } from '@/Components';
import { CollapseList, IRow } from '@/Components/CollapseList';
import { IGetUserResponse } from '@/Data/Interfaces/response/User/IGetUserReponse';
import { UseMutationResult } from '@tanstack/react-query';
import { FC } from 'react';
import { TFunction } from 'react-i18next';
import { useCardsPermissionFunctions } from './Hooks/useCardsPermissionFunctions';

interface ICardsPermissions {
  t: TFunction<'translation', undefined>;
  modules?: IRow[];
  user?: IGetUserResponse;

  updateUserPermission: UseMutationResult;
}

export const CardsPermissions: FC<ICardsPermissions> = ({
  t,
  modules,
  user,
  updateUserPermission,
}) => {
  const { onPermissionChange } = useCardsPermissionFunctions({
    updateUserPermission,
    user,
  });

  return (
    <Card
      title={t('admin.editUserGroups.tab.permissions')}
      status="default"
      withoutCollapse={true}
    >
      <CollapseList rows={modules || []} onChange={onPermissionChange} />
    </Card>
  );
};
