import { useCallback } from 'react';
import { IUseFunctions } from './interfaces';
import { handleModalError } from '@/Utils/ErrorCodeUtils/ErrorHandlingUtils';
import { useQueryClient } from '@tanstack/react-query';
import { FETCH_LIST_LOTS } from '@/ControllerApiHook/UniqIds/Supply/LotKeys';
import { App } from 'antd';
import {
  IListStatusLotResponse,
  ILotListData,
} from '@/Data/Interfaces/response/Lot/IListLot';

export const useListFunctions = ({
  patchLotStatus,
  deleteLot,
  setSelectedRowsOnTable,
}: IUseFunctions) => {
  const queryClient = useQueryClient();
  const app = App.useApp();

  const onEdit = useCallback(
    (lots: ILotListData[], values: IListStatusLotResponse) => {
      patchLotStatus({
        lotesExternalId: lots.map((x) => x.externalId),
        situacao: values.id,
      });
    },
    [patchLotStatus]
  );

  const onDelete = useCallback(
    async (lot: ILotListData[]) => {
      try {
        const res = await deleteLot.mutateAsync(
          {
            lotesExternalId: lot.map((x) => x?.externalId),
          },
          {
            onError: handleModalError(app),
          }
        );
        if (res.success) queryClient.invalidateQueries([FETCH_LIST_LOTS]);
      } catch {}
    },
    [deleteLot, app, queryClient]
  );

  const onSelectedRowsOnTable = useCallback(
    (x: ILotListData[]) => setSelectedRowsOnTable(x),
    [setSelectedRowsOnTable]
  );

  return {
    onEdit,
    onDelete,
    onSelectedRowsOnTable,
  };
};
