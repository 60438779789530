import { ICapsuleSizeListData } from "@/Data/Interfaces/response/CapsuleSize/ICapsuleSizeResponse";
import { IUseMemorizations } from "./interfaces";
import { IProductGroupListData } from "@/Data/Interfaces/response/Product/IProductRespose";

export const useAssociatePackingMemorizations = ({
  productList,
  productPackingClassId,
  disabledPackingTypes
}: IUseMemorizations) => {
  const items = productList
  ? productList?.data?.filter(
        (e: IProductGroupListData) => e.classeProdutoId === productPackingClassId
      )
      .map((e: IProductGroupListData) => ({
        id: e.externalId,
        label: e.descricao,
        disabledItem: disabledPackingTypes.includes(
          e.externalId
        ),
      }))
  : [];

  return { items };
};