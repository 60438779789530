import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Divflex } from '@/Layouts/DivWhithFlex';
import { Icon, Text } from '@/Components';
import { FooterThisActionIsPermanent } from '@/Components/Table/components/Modals/DeleteModal';

interface ILinkedProduct {
  selectedRowsOnTable: any;
}

export const RemoveLinkedModal: FC<ILinkedProduct> = ({
  selectedRowsOnTable
}) => {
  const { t } = useTranslation();

  return (
    <div style={{ padding: '20px' }}>
      <Divflex
        style={{ flexDirection: 'column', marginBottom: 10, width: '400px' }}
      >
        <Text type="ui-tiny-content" withoutMarginBottom>
          {t(
            'products.productList.productMessage.modalRemoveProductLinkedMessage.messageStartBody'
          )}
        </Text>

        <Text
          type="ui-tiny-bold"
          withoutMarginBottom
          children={`"${selectedRowsOnTable[0]?.mensagemDescricao}"?`}
        ></Text>
      </Divflex>
      <FooterThisActionIsPermanent />

      <Divflex style={{ marginTop: 25 }}>
        <Icon
          icon={'exclamation-upside-down'}
          size={'SM'}
          color={'text-400'}
          style={{ padding: '18px 18px 0 8px' }}
        />
        <Text
          type="paragraph2"
          color={'text-400'}
          children={t(
            'products.productList.productMessage.modalRemoveProductLinkedMessage.messageWarning'
          )}
        />
      </Divflex>
    </div>
  );
};
