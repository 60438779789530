import React from 'react';
import i18n from 'i18next';
import { Link, Status } from '../../../../../Components';
import { LinkButton } from '../../../../../Components/Layouts/LinkButton';
import { IListUserGroupData } from '../../../../../Data/Interfaces/response/User/IGetUserReponse';

const justOneUser = 1;

export const userGroupsColumns = (t: any) => [
  {
    title: t('userGroups.list.name'),
    dataIndex: 'nome',
    key: 'nome',
    width: '60%',
    sortColumn: 'nome',
    sorter: true,
    isHighlightable: true,
    defaultSortOrder: 'ascend',
    render: (name: string, row: IListUserGroupData) => (
      <Link
        type="ui-tiny-bold"
        to={`/admin/user-group/${row.externalId}/details`}
        style={{ width: 'fit-content' }}
      >
        {name}
      </Link>
    ),
  },
  {
    title: t('userGroups.list.status'),
    dataIndex: 'seAtivo',
    key: 'seAtivo',
    sortColumn: 'seAtivo',
    sorter: true,
    width: '20%',
    render: (status: any) => (
      <Status type={status ? 'success' : 'disabled'}>
        {i18n.t(
          `userGroups.list.statusTypes.${status ? 'active' : 'inactive'}`
        )}
      </Status>
    ),
  },
  {
    title: t('userGroups.list.users'),
    dataIndex: 'usuarios',
    key: 'usuarios',
    sortColumn: 'usuarios',
    sorter: true,
    width: '20%',
    render: (usersAmount: any, row: IListUserGroupData) => (
      <LinkButton
        link={`/admin/user-group/${row?.externalId}/users`}
        text={
          usersAmount !== justOneUser
            ? `${usersAmount} ${i18n.t('common.users')}`
            : `${usersAmount} ${i18n.t('common.user')}`
        }
      />
    ),
  },
];
