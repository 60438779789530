import { i18n } from '@/Components';
import { useControllerMutateApiHook } from '@/ControllerApiHook/Controller';
import { CREATE_SALES_ORDER } from '@/ControllerApiHook/UniqIds/Sales/SalesOrderKeys';
import { GET_SERVICE } from '@/ControllerApiHook/UniqIds/Sales/ServiceKeys';
import { SalesOrderAPI } from '@/Data/API/Sales/SalesOrderApi';
import {
  messageErrorToast,
  messageSucessToast,
} from '@/Utils/MessageErrorToast';
import { useQueryClient } from '@tanstack/react-query';

export const useWithoutSaleOrderCallAPI = () => {
  const queryClient = useQueryClient();

  const createSaleOrder = useControllerMutateApiHook({
    uniqId: CREATE_SALES_ORDER,
    entityApi: SalesOrderAPI.createSaleOrder,
    options: {
      onError: (x) => messageErrorToast(x),
      onSuccess: () => {
        queryClient.invalidateQueries([GET_SERVICE]);
        messageSucessToast(i18n.t('callCenter.messages.create'));
      },
    },
  });

  return { createSaleOrder };
};
