import { useFormikContext } from 'formik';

export const useWaterConcentrationFactorFunctions = () => {
  const form: any = useFormikContext();

  const onChange = (stringValue: string | number) => {
    const parsedValue =
      typeof stringValue === 'string'
        ? stringValue.replace(',', '.')
        : String(stringValue);
    const numberValue = parsedValue ? +parsedValue : 0;

    if (isNaN(numberValue) || numberValue === 0) {
      return form.setFieldValue('loteInformacaoTecnica.concentracaoAgua', '');
    }

    const currentFormWaterConcentration = 100 - 100 / numberValue;

    form.setFieldValue(
      'loteInformacaoTecnica.concentracaoAgua',
      currentFormWaterConcentration
    );
  };

  return { onChange };
};
