import { FormikProps, FormikValues } from 'formik';

export interface IUpdateUserGroupPermission {
  grupoId: string;
  permissaoId: string;
  ativo: boolean;
  dependencias?: string[];
  permissionName?: string;
  helpers?: FormikProps<FormikValues>;
}

export const createPermissionRequest = (
  grupoId: string,
  permissaoId: string,
  ativo: boolean,
  permissionName: string,
  helpers: FormikProps<FormikValues>
): IUpdateUserGroupPermission => {
  return {
    grupoId,
    permissaoId,
    permissionName,
    ativo,
    helpers,
  };
};
