import { FC } from 'react';
import { InputWithSearch } from '@/Components';
import { Col } from 'antd';
import { useTranslation } from 'react-i18next';
import { IUfRegister } from './interfaces';
import { useUfRegisterMemorizations } from './Hooks/useUfRegisterMemorizations';
import { useUfRegisterFunctions } from './Hooks/useUfRegisterFunctions';

export const UfRegister: FC<IUfRegister> = ({
  listState,
  fetchNewStatePage,
  refetchStateList,
  fetchValidateRegistrationPrescriber,
  form,
}) => {
  const { t } = useTranslation();

  const { items } = useUfRegisterMemorizations({ listState });

  const { onSearch } = useUfRegisterFunctions({ refetchStateList });

  return (
    <Col flex="13%">
      <InputWithSearch
        name="ufRegistroExternalId"
        placeHolder="UF"
        label={t('prescribers.createPage.info.UF')}
        required
        items={items}
        onScrollEnd={fetchNewStatePage}
        onSearch={onSearch}
        withoutSearchIcon
        onChange={(x) =>
          fetchValidateRegistrationPrescriber(
            form.values.codigoRegistro,
            form.values.tipoRegistroId,
            x
          )
        }
      />
    </Col>
  );
};
