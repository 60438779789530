import { useTranslation } from "react-i18next";
import { IUseMemorizations } from "./interfaces";

export const useLevelIncompatibleMemorizations = ({
  incompatibleProductData
}: IUseMemorizations) => {
  const { t } = useTranslation();

  const incompatibilityLevel = [
    {
      id: 1,
      label: t('products.incompatible.justWarn'),
    },
    {
      id: 2,
      label: t('products.incompatible.passwordLiberation'),
    },
    {
      id: 3,
      label: t('products.incompatible.block'),
    },
  ];

  const originalIncompatibleLevel = incompatibleProductData
  ? incompatibilityLevel.filter(
      (e) => e.id === incompatibleProductData.nivelIncompatibilidade
    )[0]
  : null;

  return {
    incompatibilityLevel,
    originalIncompatibleLevel
  };
};