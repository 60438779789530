import { FC } from 'react';
import {
  ICommonError
} from '@/Utils/ErrorCodeUtils/ErrorHandlingUtils';
import { useTranslation } from 'react-i18next';

export const Title: FC<ICommonError> = ({ content }) => {
  const { t } = useTranslation();
  
  return (
    <>
      {t('purchasing.purchaseOrder.modal.cannotDelete.title', {
        orderPurchaseNumber: content[0].numero,
      })}
    </>
  );
};