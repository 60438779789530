import { useQueryClient } from '@tanstack/react-query';
import {
  CHANGE_USER_GROUP_STATUS,
  DELETE_USER_GROUP,
  LIST_USER_GROUP_TYPE,
} from '@/ControllerApiHook/UniqIds/Accountmanager/UserGroupKeys';
import {
  useControllerMutateApiHook,
  useControllerQueryListApiHook,
} from '@/ControllerApiHook/Controller';
import { UserGroupAPI } from '@/Data/API/Accountmanager/UserGroupApi';

export const useListCallAPI = () => {
  const queryClient = useQueryClient();

  const userGroupList = useControllerQueryListApiHook({
    uniqId: LIST_USER_GROUP_TYPE,
    entityApi: UserGroupAPI.listUserGroup,
    pagination: {
      sorter: { column: 'nome', direction: 'ASC' },
    },
  });

  const changeUserGroupStatus = useControllerMutateApiHook({
    uniqId: CHANGE_USER_GROUP_STATUS,
    entityApi: UserGroupAPI.ChangeUserGroupStatus,
    options: {
      onSuccess: () => queryClient.invalidateQueries([LIST_USER_GROUP_TYPE]),
    },
  });

  const deleteUserGroup = useControllerMutateApiHook({
    uniqId: DELETE_USER_GROUP,
    entityApi: UserGroupAPI.DeleteUserGroup,
    options: {
      onSuccess: () => queryClient.invalidateQueries([LIST_USER_GROUP_TYPE]),
    },
  });

  return {
    userGroupList,
    changeUserGroupStatus,
    deleteUserGroup,
  };
};
