import { InputWithSearch } from '@/Components';
import { ProductClass } from '@/Data/Interfaces/model/productClassEnum';
import { IProductPrescriptionListData } from '@/Data/Interfaces/response/Product/IProductRespose';
import { FormikContextType } from 'formik';
import { Dispatch, FC, SetStateAction, useCallback, useMemo } from 'react';
import { v4 as uuidV4 } from 'uuid';
import { ComponentType, ComponentTypeIcon } from '../..';
import { QSPType } from '../OptionsButton';
import { useTranslation } from 'react-i18next';

interface ISelectComponent {
  index: number;

  setEditingComponent: Dispatch<SetStateAction<boolean>>;

  setComponentsEditing: Dispatch<SetStateAction<number[]>>;

  componentsEditing: number[];

  setStandardFormulaExternalId: Dispatch<SetStateAction<string | undefined>>;

  form: FormikContextType<any>;

  productPrescriptionList: any;

  focus: boolean;

  validatingComponents?: boolean;
}
export const SelectComponent: FC<ISelectComponent> = ({
  index,

  setEditingComponent,

  setComponentsEditing,

  componentsEditing,

  setStandardFormulaExternalId,

  form,

  productPrescriptionList,

  focus,

  validatingComponents,
}) => {
  const { t } = useTranslation();

  const componenti18n = useMemo(
    () => 'saleOrder.editOrder.SaleOrder.addManipulatedRecipe.components',
    []
  );
  const optionsFilteri18n = useMemo(
    () =>
      'saleOrder.editOrder.SaleOrder.addManipulatedRecipe.components.optionsFilter',
    []
  );

  const filterSearchs = useMemo(
    () =>
      [
        {
          label: t(optionsFilteri18n + '.rawMaterial'),
          id: ProductClass.Feedstock,
          checked: true,
          disabled: true,
        },
        {
          label: t(optionsFilteri18n + '.finishedProduct'),
          id: ProductClass.FinishedProduct,
        },
        {
          label: t(optionsFilteri18n + '.packaging'),
          id: ProductClass.Packaging,
        },
        {
          label: t(optionsFilteri18n + '.capsuleType'),
          id: ProductClass.TypeCapsule,
        },
        {
          label: t(optionsFilteri18n + '.service'),
          id: ProductClass.Service,
        },
        {
          label: t(optionsFilteri18n + '.readyCapsule'),
          id: ProductClass.ReadyCapsule,
        },
        {
          label: t(optionsFilteri18n + '.useAndConsumption'),
          id: ProductClass.UsageConsumption,
        },
      ].map((x, index) => ({
        ...x,
      })),
    [optionsFilteri18n, t]
  );

  const addNewLine = useCallback(
    (value: string, itemSelected: IProductPrescriptionListData) => {
      (!form.values?.itens || form.values?.itens?.length === index + 1) &&
        form.setFieldValue('itens', [...form.values.itens, { id: uuidV4() }]);
      form.setFieldValue(`itens[${index}].produtoExternalId`, value);
      form.setFieldValue(`itens[${index}].itemReceitaDescricao`, undefined);
      form.setFieldValue(`itens[${index}].tipo`, QSPType.normal);
      if (
        ComponentType.rawMaterial !== itemSelected.tipoComponente &&
        ComponentType.formula !== itemSelected.tipoComponente
      )
        form.setFieldValue(`itens[${index}].ocultaRotulo`, true);

      setEditingComponent(false);
      setComponentsEditing((currentState) =>
        currentState.filter((value) => value !== index)
      );
      itemSelected.produtoOriginalExternalId &&
        form.setFieldValue(
          `itens[${index}].produtoSinonimoExternalId`,
          itemSelected.produtoPrescricaoExternalId
        );

      form.setFieldValue(`itens[${index}].description`, itemSelected.descricao);

      form.setFieldValue(
        `itens[${index}].initialDescription`,
        itemSelected.descricao
      );
      form.setFieldValue(`itens[${index}].code`, itemSelected.codigo);
      form.setFieldValue(
        `itens[${index}].componentType`,
        itemSelected.tipoComponente
      );

      setStandardFormulaExternalId(itemSelected.formulaPadraoExternalId);
    },
    [
      index,
      form,
      setEditingComponent,
      setComponentsEditing,
      setStandardFormulaExternalId,
    ]
  );

  return (
    <InputWithSearch
      focus={focus}
      name={`itens[${index}].produtoExternalId`}
      placeHolder={t(componenti18n + '.searchPlaceholder')}
      filterSearch={{
        items: filterSearchs,
        title: t(componenti18n + '.searchFilterDescription'),
      }}
      onScrollEnd={productPrescriptionList.fetchNewPage}
      items={productPrescriptionList.data?.data?.map(
        (x: IProductPrescriptionListData) => ({
          id: x.produtoPrescricaoExternalId,
          label: x.descricao,
          subLabel: `${t('common.code')}: ${x.codigo}${
            x.sinonimoDe
              ? ` | ${t(componenti18n + '.synonymOf')} ${x.sinonimoDe}`
              : ''
          }`,
          icon: {
            icon: ComponentTypeIcon[x.tipoComponente],
            color: 'text-300',
          },
          allItem: x,
        })
      )}
      error={
        validatingComponents &&
        componentsEditing.some((value) => value === index)
      }
      onSearch={(search, filters) => {
        productPrescriptionList.refetch({
          search: search,
        });
      }}
      onChange={(x, itemSelected: IProductPrescriptionListData) =>
        addNewLine(x, itemSelected)
      }
      isLoading={productPrescriptionList.isLoading}
      withoutMarginBottom
      getPopupContainerId="add-manipulated-recipe-dropdown-components-container"
      withoutLeftIconSelected
    />
  );
};
