import { useState } from "react";

export const useEditStates = () => {
  const [
    modalDeleteProductGroup, 
    setModalDeleteProductGroup
  ] = useState(false);

  const [
    disabled, 
    setDisabled
  ] = useState(true);

  return {
    modalDeleteProductGroup,
    setModalDeleteProductGroup,
    disabled,
    setDisabled
  };
};