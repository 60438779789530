export const FETCH_GET_PACKING_CLASSIFICATION =
  '[PACKING_CLASSIFICATION] FETCH_GET_PACKING_CLASSIFICATION';

export const FETCH_LIST_PACKING_CLASSIFICATION =
'[PACKING_CLASSIFICATION] FETCH_LIST_PACKING_CLASSIFICATION';
export const FETCH_LIST_PACKING_CLASSIFICATION_FOR_DROPDOWN_TYPE =
  '[PACKING_CLASSIFICATION] FETCH_LIST_PACKING_CLASSIFICATION_FOR_DROPDOWN_TYPE';
export const FETCH_LIST_PRODUCT_LINKED_BY_PACKING =
  '[PACKING_CLASSIFICATION] FETCH_LIST_PRODUCT_LINKED_BY_PACKING';

export const CREATE_PACKING_CLASSIFICATION =
  '[PACKING_CLASSIFICATION] CREATE_PACKING_CLASSIFICATION';
export const EDIT_PACKING_CLASSIFICATION =
  '[PACKING_CLASSIFICATION] EDIT_PACKING_CLASSIFICATION';
export const DELETE_PACKING_CLASSIFICATION =
  '[PACKING_CLASSIFICATION] DELETE_PACKING_CLASSIFICATION';
export const DELETE_PRODUCT_LINKED_PACKING =
  '[PACKING_CLASSIFICATION] DELETE_PRODUCT_LINKED_PACKING';
export const EDIT_STATUS_PACKING_CLASSIFICATION =
  '[PACKING_CLASSIFICATION] EDIT_STATUS_PACKING_CLASSIFICATION';

export const THERE_IS_PACKING_CLASSIFICATION_TYPE =
  '[PACKING_CLASSIFICATION] THERE_IS_PACKING_CLASSIFICATION_TYPE';
