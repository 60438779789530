import { IGetStorageLocationDropDownResponse } from '@/Data/Interfaces/response/StorageLocation/IStorageLocationResponse';
import { IUseMemorizations } from './interfaces';

export const useBatchLocalMemorizations = ({
  storageLocationList,
}: IUseMemorizations) => {
  const items = storageLocationList?.data?.map(
    (x: IGetStorageLocationDropDownResponse) => ({
      id: x.externalId,
      label: x.descricao,
      code: x.nomeEmpresa,
    })
  );

  return {
    items,
  };
};
