export const recoverPasswordEN = {
    title: 'Password Recovery',

    email: 'E-mail',
    emailPlaceholder: 'Insert your E-mail',

    action: 'Recover your Password',
    back: 'Back to Login Page',

    errors: {
        invalidEmail: 'Invalid E-mail',
        requiredEmail: 'E-mail is required',
    },
}

export const recoverPasswordPT = {
    title: 'Recuperação de Senha',

    email: 'E-mail',
    emailPlaceholder: 'Informe seu e-mail',

    action: 'Recuperar senha',
    back: 'Voltar ao login',

    errors: {
        invalidEmail: 'Insira um e-mail válido',
        requiredEmail: 'E-mail é obrigatório',
    },
}
