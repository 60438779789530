import { FC } from 'react';
import { Text } from '@/Components';
import { DeleteModalOnTable } from '@/Utils/DeleteModalUtils';
import { useTranslation } from 'react-i18next';
import ReactMarkdown from 'react-markdown';
import { IDeleteModalBody } from './interfaces';

export const DeleteModalBody: FC<IDeleteModalBody> = ({ client }) => {
  const { t } = useTranslation();

  return (
    <div>
      {client.length > 1 ? (
        DeleteModalOnTable(
          client,
          t('client.listPage.deleteModal.manyClientDescription'),
          'nome',
          client.length > 5 ? t('client.clients') : t('client.client')
        )
      ) : (
        <div style={{ padding: '20px' }}>
          <Text type="ui-tiny-content" withoutMarginBottom color="text-50">
            <ReactMarkdown>
              {t('client.listPage.deleteModal.description', {
                client: `**${client[0].nome}**`,
              })}
            </ReactMarkdown>
          </Text>
          <Text
            type="ui-tiny-content"
            color="text-50"
            children={t('modal.deleteModal.simpleFooter')}
          />
        </div>
      )}
    </div>
  );
};
