import { Text } from '../../../../../Components/Text';
import { hasPermission } from '../../../../../Components/PermissionWrapper';
import { Link } from '../../../../../Components';
import { PermissionEnum } from '../../../../../Data/Interfaces/model/permissionEnum';

export const capsuleColorsColumns = (t: any) => [
  {
    title: t('production.capsuleColors.list.capsuleColors'),
    dataIndex: 'corCapsula',
    key: 'corCapsula',
    isHighlightable: true,
    sortColumn: 'corCapsula',
    sorter: true,
    defaultSortOrder: 'ascend',
    render: (x: string, row: any) => {
      return !hasPermission(PermissionEnum.Producao_CapsulaCor_VerDetalhes) ? (
        <Text type="ui-tiny-bold" style={{ width: 'fit-content' }}>
          {x}
        </Text>
      ) : (
        <Link
          type="ui-tiny-bold"
          to={`/production/capsule-colors/${row.externalId}/details`}
          style={{ width: 'fit-content' }}
        >
          <Text type="ui-tiny-bold" color="text-50">
            {x}
          </Text>
        </Link>
      );
    },
  },
  {
    title: t('production.capsuleColors.list.capsuleTransparent'),
    dataIndex: 'transparente',
    key: 'transparente',
    width: '15%',
    render: (prop: any) => (
      <Text type="ui-tiny-content">
        {prop
          ? t('production.capsuleColors.list.yesValue')
          : t('production.capsuleColors.list.noValue')}
      </Text>
    ),
  },
];
