import { FC } from "react";
import { Row, Col } from 'antd';
import { RadioGroup } from '@/Components/Inputs/RadioButton/RadioGroup';
import { RadioButton } from '@/Components/Inputs/RadioButton';
import { IOptions } from "./interfaces";
import { useTranslation } from "react-i18next";

import styles from './Options.module.scss';

export const Options: FC<IOptions> = ({ personType, changePersonType }: IOptions) => {
  const { t } = useTranslation();

  return (
    <Row className={styles['row-radio-group']}>
      <Col>
        <RadioGroup
          className={styles['radio-group']}
          name="pessoa"
          valueChecked={personType}
          onChange={(x) => changePersonType(x)}
        >
          <RadioButton
            name="tipoPessoaCPF"
            value={0}
            label={t('client.createPage.legalEntity')}
          />
          <RadioButton
            name="tipoPessoaCNPJ"
            value={1}
            label={t('client.createPage.juridicalPerson')}
          />
        </RadioGroup>
      </Col>
    </Row>
  );
};