import { AuthAPI } from '@/Data/API/Identity/AuthApi';
import { useControllerMutateApiHook } from '@/ControllerApiHook/Controller';
import { NEW_PASSWORD } from '@/ControllerApiHook/UniqIds/Identity/AuthHooks';
import { history } from '@/Store';
import { messageSucessToast } from '@/Utils/MessageErrorToast';

export const useNewPasswordCallAPI = (t: any) => {
  const newPassword = useControllerMutateApiHook({
    uniqId: NEW_PASSWORD,
    entityApi: AuthAPI.newpassword,
    options: {
      onSuccess: () => {
        messageSucessToast(t('notifications.newPasswordChanged'));
        history.push('/login');
      },
    },
  });

  return { newPassword };
};
