import { useControllerQueryListApiHook } from '@/ControllerApiHook/Controller';
import { CapsulseColorsAPI } from '@/Data/API/Production/CapsuleColors';
import { FETCH_LIST_DROPDOWN_CAPSULE_COLOR } from '@/ControllerApiHook/UniqIds/Production/CapsuleColorsKeys';

export const useCapsuleColorCallAPI = () => {
  const {
    data: listCapsuleColor,
    isLoading: isLoadingCapsuleColor,
    fetchNewPage: fetchNewPageCapsuleColor,
    refetch: refetchCapsuleColor,
  } = useControllerQueryListApiHook({
    uniqId: FETCH_LIST_DROPDOWN_CAPSULE_COLOR,
    entityApi: CapsulseColorsAPI.listDropdownCapsuleColor,
    autoIncrement: true,
  });

  return {
    listCapsuleColor,
    isLoadingCapsuleColor,
    fetchNewPageCapsuleColor,
    refetchCapsuleColor,
  };
};
