import { useCallback } from "react";
import { FormikHelpers, FormikValues } from 'formik';
import { 
  IEditProductSubGroupRequest
} from '@/Data/Interfaces/request/Product/IProductRequest';
import {
  FETCH_LIST_SUBGROUP,
} from '@/ControllerApiHook/UniqIds/Supply/ProductKeys';
import { useQueryClient } from "@tanstack/react-query";
import { IUseFunctions } from "./interfaces";

export const useFormSubGroupModalFunctions = ({
  title,
  selectedRowsOnTable,
  editSubGroup,
  createSubGroup,
  changeVisible,
  visible
}: IUseFunctions) => {
  const queryClient = useQueryClient();

  const onSubmit = useCallback(async (
    values: IEditProductSubGroupRequest,
    helpers: FormikHelpers<FormikValues>
  ) => {
    const body: IEditProductSubGroupRequest = { ...values, helpers };

    if (title && selectedRowsOnTable) {
      const res = await editSubGroup.mutateAsync({
        subGrupoExternalId: selectedRowsOnTable[0]?.externalId,
        descricao: body.descricao,
        grupoPaiExternalId: body.grupoPaiExternalId,
        helpers,
      });
      if (res.success) queryClient.invalidateQueries([FETCH_LIST_SUBGROUP]);
    } else {
      const res = await createSubGroup.mutateAsync({
        descricao: body?.descricao,
        grupoPaiExternalId: body.grupoPaiExternalId,
        helpers,
      });
      if (res.success) queryClient.invalidateQueries([FETCH_LIST_SUBGROUP]);
    }
  }
  , [
    title,
    selectedRowsOnTable,
    editSubGroup,
    createSubGroup
  ]);

  const onOkClickForm = useCallback(
    (
      values: IEditProductSubGroupRequest, 
      formik: FormikHelpers<FormikValues>
    ) => {
      onSubmit(values, formik);
      changeVisible(!visible);
    }, [changeVisible, visible]
  );

  const onCancelClick = useCallback(() => changeVisible(!visible), [changeVisible, visible]);

  const onClose = useCallback(() => changeVisible(!visible), [changeVisible, visible]);

  return {
    onOkClickForm,
    onCancelClick,
    onClose
  };
};