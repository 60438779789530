import { useMemo } from 'react';
import {
  HistoricStatusIcon,
  StatusSaleOrderHistoric,
} from '../historicStatus.utils';
import ReactMarkdown from 'react-markdown';
import DateUtils from '@/Utils/DateUtils';
import { Divflex } from '@/Layouts/DivWhithFlex';
import { Avatar, Icon, Tooltip } from '@/Components';
import { UseQueryResult } from '@tanstack/react-query';
import { IHistoricSaleOrder } from '@/Data/Interfaces/response/Sales/SalesOrder/ISalesOrderResponse';
import { TFunction } from 'react-i18next';
import StorageUtils from '@/Utils/StorageUtils';

import styles from './historicMemorizations.module.scss';

interface IUseHistoricMemorizations {
  historicSaleOrder: UseQueryResult<IHistoricSaleOrder, unknown>;
  t: TFunction<'translation', undefined>;
}

export const useHistoricMemorizations = ({
  historicSaleOrder,
  t,
}: IUseHistoricMemorizations) => {
  const preferences = useMemo(() => StorageUtils.getPreference(), []);
  const historicI18n = useMemo(
    () => 'saleOrder.editOrder.SaleOrder.historicModal',
    []
  );

  const historicItems = useMemo(
    () =>
      historicSaleOrder?.data?.historico?.map((historic: any) => {
        const statusStyles = HistoricStatusIcon(historic.status);
        return {
          title: (
            <ReactMarkdown
              components={{
                strong: (props) => (
                  <span style={{ fontWeight: 'var(--font-weight-normal)' }}>
                    {props.children}
                  </span>
                ),
              }}
              children={t(
                `${historicI18n}.${
                  historic.status === StatusSaleOrderHistoric.started
                    ? 'defaultCreateSaleOrderDescription'
                    : historic.estorno
                    ? 'historicSaleOrderDescriptionReversal'
                    : 'historicSaleOrderDescription'
                }`,
                {
                  vendedor: `**${historic.vendedor}**`,
                  statusDescricao: `**${historic.statusDescricao}**`,
                }
              )}
            />
          ),
          description: `${DateUtils.convertLongDate(
            historic.data,
            preferences?.padraoData
          )} 
              `,
          icon: (
            <Divflex>
              <Tooltip
                title={t(`${historicI18n}.statusHistoricTooltips`, {
                  status: historic.statusDescricao,
                })}
                showMe
                placement="topLeft"
                targetOffset={[5, -10]}
              >
                <div
                  className={styles['container-status']}
                  style={{ background: statusStyles.background }}
                >
                  <Icon
                    icon={statusStyles.icon}
                    size="SM"
                    color={statusStyles.iconColor}
                  />
                </div>
              </Tooltip>
              <Avatar
                fisrtName={historic.vendedor}
                size="M"
                type="ui-tiny-bold"
                color="white"
              />
            </Divflex>
          ),
        };
      }),

    [
      historicI18n,
      historicSaleOrder?.data?.historico,
      preferences?.padraoData,
      t,
    ]
  );

  return { historicItems };
};
