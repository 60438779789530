import { TextInput } from '@/Components';
import { PercentageInput } from '@/Components/Inputs/PercentageInput';
import { WeightInput } from '@/Components/Inputs/WeightInput';
import { Col } from 'antd';
import { useFormikContext } from 'formik';
import { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDosageInputsCallAPI } from './Hooks/useDosageInputsCallAPI';
import { useDosageInputsStates } from './Hooks/useDosageInputsStates';
import { useDosageInputsMemorizations } from './Hooks/useDosageInputsMemorizations';
import { useDosageInputsFunctions } from './Hooks/useDosageInputsFunctions';

export const DosageInputs: FC = () => {
  const { t } = useTranslation();
  const form: any = useFormikContext();

  const { unitAbbreviation, setUnitAbbreviation, ratio, setRatio } =
    useDosageInputsStates();

  const { unitMeasurementList } = useDosageInputsCallAPI();

  const { dropdownRightMinimalDosage } = useDosageInputsMemorizations({
    unitMeasurementList,
    setUnitAbbreviation,
  });

  const { onChangeMaximalDosage } = useDosageInputsFunctions();

  useEffect(() => {
    if (form.values.diluicao) {
      const ratio = 100 / form.values.diluicao;
      const finalResult = Math.round(ratio);
      setRatio(`1:${finalResult}`);
    } else {
      setRatio('');
    }
  }, [form.values.diluicao]);

  return (
    <>
      <Col span={3}>
        <WeightInput
          name="dosagemMinima"
          required
          label={t('products.diluted.minimalDosage')}
          dropdownRight={dropdownRightMinimalDosage}
          placeHolder="0,000"
          disabled={form.values.seQualquerDosagem}
        />
      </Col>
      <Col span={3}>
        <WeightInput
          name="dosagemMaxima"
          required
          label={t('products.diluted.maximalDosage')}
          placeHolder="0,000"
          rightIcon={{
            titleString: unitAbbreviation || 'mg',
          }}
          disabled={form.values.seQualquerDosagem}
          onChange={onChangeMaximalDosage}
        />
      </Col>
      <Col span={2}>
        <PercentageInput
          required
          name="diluicao"
          label={'Diluição'}
          withTooltip={{
            icon: 'exclamation-upside-down',
            title: t('supply.lot.create.toolTips.internalDilution'),
          }}
          placeHolder="0.0000"
        />
      </Col>
      <Col span={2}>
        <TextInput
          name="ratio"
          label={t('products.diluted.ratio')}
          placeHolder="0.0000"
          disabled
          value={ratio}
        />
      </Col>
    </>
  );
};