import { InputWithSearch } from '@/Components';
import { List } from '@/Components/List';
import { IListUserData } from '@/Data/Interfaces/response/User/IGetUserReponse';
import { SelectableUserList } from '@/Pages/Sectors/Administrative/UserGroups/Components/SelectableUserList';
import { useFormikContext } from 'formik';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useBodyCallAPI } from './Hooks/useBodyCallAPI';

interface IBodyModal {
  usersSelected: any[];
  setUsersSelected: React.Dispatch<React.SetStateAction<any[]>>;
  groupExternalId: string;
}

export const BodyModal: FC<IBodyModal> = ({
  usersSelected,
  setUsersSelected,
  groupExternalId,
}) => {
  const form = useFormikContext();
  const { t } = useTranslation();

  const { usersList } = useBodyCallAPI(groupExternalId);

  return (
    <div style={{ padding: 20 }}>
      <InputWithSearch
        name="users"
        label={t('userGroups.editGroups.modal.user')}
        items={usersList?.data?.data?.map((x: any) => ({
          id: x.externalId,
          label: x.nome,
          avatar: {
            name: x.nome,
            lastName: x.sobrenome,
            email: x.email,
          },
          allItem: x,
        }))}
        hideSelectedItem
        onScrollEnd={usersList.fetchNewPage}
        placeHolder={t('admin.editUserGroups.userForm.placeholder')}
        isLoading={usersList.isLoading}
        multipleSelection
        onChange={(_, item) => setUsersSelected([...usersSelected, item])}
        onSearch={(search) => {
          usersList.refetch({
            search: search,
          });
        }}
        currentItemsSelected={usersSelected.map((x) => x.externalId)}
      />
      <List
        items={usersSelected?.map((x: IListUserData) => ({
          id: x.externalId,
          data: SelectableUserList(x.nome, x.sobrenome, x.email),
        }))}
        onDelete={(x) => {
          const newUsersSelected = usersSelected.filter(
            (item) => item.externalId !== x
          );
          setUsersSelected(newUsersSelected);

          form.setFieldValue(
            'users',
            newUsersSelected.map((item) => item.externalId)
          );
        }}
      />
    </div>
  );
};
