import React from 'react';
import { useTranslation } from 'react-i18next';
import { Modal } from '@/Components/Modal';
import { Text } from '@/Components';
import { Divflex } from '@/Layouts/DivWhithFlex';
import { FooterThisActionIsPermanent } from '@/Components/Table/components/Modals/DeleteModal';
import ReactMarkdown from 'react-markdown';
import { IEditModal } from './interfaces';
import { useDeleteProductGroupModalCallAPI } from './Hooks/useDeleteProductGroupModalCallAPI';
import { useDeleteProductGroupModalFunctions } from './Hooks/useDeleteProductGroupModalFunctions';

export const DeleteProductGroupModal: React.FC<IEditModal> = ({
  visible,
  productGroup,
  onCancelClick,
  onOkClick,
}) => {
  const { t } = useTranslation();

  const { deleteGroup } = useDeleteProductGroupModalCallAPI();

  const { onDelete } = useDeleteProductGroupModalFunctions({
    deleteGroup,
    productGroup,
  });

  return (
    <Modal
      onOkClick={() => {
        onDelete();
        onOkClick();
      }}
      title={`${t('common.delete')} ${t('common.group')} “${
        productGroup.descricao
      }”?`}
      body={
        <>
          <div style={{ padding: '20px' }}>
            <Divflex style={{ flexDirection: 'column', width: '400px' }}>
              <Text color="text-50" type="ui-tiny-content">
                <ReactMarkdown>
                  {t('modal.deleteModal.single.groupQuestion', {
                    grupo: `**${productGroup?.descricao}**`,
                  })}
                </ReactMarkdown>
              </Text>
            </Divflex>
            <FooterThisActionIsPermanent />
          </div>
        </>
      }
      visible={visible}
      onCancelClick={onCancelClick}
      htmlType="submit"
      okButtonName={t('modal.delete')}
      okButtonColor="status-danger-base"
      onClose={onCancelClick}
      widthModal={400}
      dataTestIdOkButton="delete-group-submit"
    />
  );
};
