import React, { useState, useEffect, FC } from 'react';
import { Row, Col } from 'antd';
import { FooterButton } from '../Components/FooterButton';
import { FormikHelpers, FormikValues, useFormikContext } from 'formik';
import { Card, Form, i18n, NumberInput } from '../../../Components';
import { TextInputWithMask } from '../../../Components/Inputs/TextInputWithMask';
import { TextInput } from '../../../Components/Inputs/TextInput';
import { CompanyAdressCardSchema } from './CompanyAdressCard.schema';
import { IChangeCompanyAdressRequest } from '../../../Data/Interfaces/request/Company/ICompanyRequest';
import { RemoveMask } from '../../../Utils/MasksUtils';
import { CHANGE_ADRESS_TYPE } from '../../../ControllerApiHook/UniqIds/Accountmanager/CompanyKeys';
import { initialValues } from './CompanyAdressCard.InitialValues';
import { IGetCompanyResponse } from '../../../Data/Interfaces/response/Company/ICompanyResponse';
import {
  useControllerMutateApiHook,
  useControllerQueryApiHook,
} from '@/ControllerApiHook/Controller';
import { CompanyAPI } from '@/Data/API/Accountmanager/CompanyApi';
import { CEPAPI } from '@/Data/API/CEP/CEPApi';
import { IGetCEPResponse } from '@/Data/Interfaces/response/CEP/ICEPResponse';
import { GET_CEP } from '@/ControllerApiHook/UniqIds/Locality/CEPKeys';
import { ConnectedFocusError } from '@/Components/ConnectedFocusError';

interface ICompanyCard {
  editForm?: boolean;
  buttonDisabled?: boolean;
  onButtonChange?: (value: boolean) => void;
  isLoading?: boolean;
}

export const CompanyCard: FC<ICompanyCard> = ({
  editForm,
  buttonDisabled,
  onButtonChange,
  isLoading,
}) => {
  const form = useFormikContext<FormikValues>();
  const { data: adress, isLoading: cepLoading } =
    useControllerQueryApiHook<IGetCEPResponse>({
      uniqId: GET_CEP,
      entityApi: CEPAPI.getCEP,
      externalIds: [form?.values?.endereco?.cep],
    });

  useEffect(() => {
    const formAddress = form.values['endereco'];
    const addressDidChange =
      formAddress?.logradouro !== (adress?.logradouro || '') ||
      formAddress?.bairro !== (adress?.bairro || '') ||
      formAddress?.cidade !== (adress?.cidade || '') ||
      formAddress?.estado !== (adress?.estado || '');

    if (!!adress && !!form && addressDidChange) {
      form.setFieldValue('endereco.logradouro', adress.logradouro);
      form.setFieldValue('endereco.bairro', adress.bairro);
      form.setFieldValue('endereco.cidade', adress.cidade);
      form.setFieldValue('endereco.estado', adress.estado);
    } else if (!editForm && !formAddress) {
      form.setFieldValue('endereco.logradouro', '');
      form.setFieldValue('endereco.bairro', '');
      form.setFieldValue('endereco.cidade', '');
      form.setFieldValue('endereco.estado', '');
    }
  }, [adress, form, editForm]);

  return (
    <Card
      title={i18n.t('adminBootis.createCompany.CompanyAdress')}
      withoutCollapse={editForm}
    >
      <Row>
        <Col flex="24%">
          <TextInputWithMask
            name="endereco.cep"
            label={i18n.t('adminBootis.createCompany.CompanyAdressCard.cep')}
            mask="cepMask"
            placeHolder={i18n.t(
              'adminBootis.createCompany.CompanyAdressCard.cepPlaceholder'
            )}
            disabled={buttonDisabled}
            required
            loading={cepLoading}
            dataTestId="cep"
          />
        </Col>
      </Row>
      <Row gutter={[16, 0]}>
        <Col flex="85%">
          <TextInput
            name="endereco.logradouro"
            label={i18n.t('adminBootis.createCompany.CompanyAdressCard.street')}
            placeHolder={i18n.t(
              'adminBootis.createCompany.CompanyAdressCard.streetPlaceholder'
            )}
            disabled
            required
          />
        </Col>
        <Col flex="15%">
          <NumberInput
            name="endereco.numero"
            label={i18n.t(
              'adminBootis.createCompany.CompanyAdressCard.houseNumber'
            )}
            placeHolder={i18n.t(
              'adminBootis.createCompany.CompanyAdressCard.houseNumberPlaceholder'
            )}
            disabled={buttonDisabled}
            required
            dataTestId="house-number"
          />
        </Col>
      </Row>
      <Row gutter={[20, 0]}>
        <Col span={6}>
          <TextInput
            name="endereco.complemento"
            withoutMarginBottom
            label={i18n.t(
              'adminBootis.createCompany.CompanyAdressCard.complement'
            )}
            placeHolder={i18n.t(
              'adminBootis.createCompany.CompanyAdressCard.complementPlaceholder'
            )}
            disabled={buttonDisabled}
          />
        </Col>
        <Col span={6}>
          <TextInput
            name="endereco.bairro"
            withoutMarginBottom
            label={i18n.t(
              'adminBootis.createCompany.CompanyAdressCard.neighborhood'
            )}
            placeHolder={i18n.t(
              'adminBootis.createCompany.CompanyAdressCard.neighborhoodPlaceholder'
            )}
            disabled
            required
          />
        </Col>
        <Col span={6}>
          <TextInput
            name="endereco.cidade"
            withoutMarginBottom
            label={i18n.t('adminBootis.createCompany.CompanyAdressCard.city')}
            placeHolder={i18n.t(
              'adminBootis.createCompany.CompanyAdressCard.cityPlaceholder'
            )}
            disabled
            required
          />
        </Col>
        <Col span={6}>
          <TextInput
            name="endereco.estado"
            withoutMarginBottom
            label={i18n.t('adminBootis.createCompany.CompanyAdressCard.state')}
            placeHolder={i18n.t(
              'adminBootis.createCompany.CompanyAdressCard.statePlaceholder'
            )}
            disabled
            required
          />
        </Col>
      </Row>
      {onButtonChange && form && (
        <FooterButton
          disabled={!!buttonDisabled}
          editLabel={i18n.t('adminBootis.createCompany.CompanyAdressCard.edit')}
          onChange={onButtonChange}
          loading={!!isLoading}
          dataTestId="address-edit-button"
        />
      )}
    </Card>
  );
};

interface ICompanyAdressCard {
  editForm?: boolean;
  company: IGetCompanyResponse;
}

export const CompanyAdressCard: React.FC<ICompanyAdressCard> = ({
  editForm,
  company,
}) => {
  const [buttonDisabled, setButtonDisabled] = useState(true);

  const { mutateAsync, isLoading } = useControllerMutateApiHook({
    uniqId: CHANGE_ADRESS_TYPE,
    entityApi: CompanyAPI.changeAdressCompany,
    options: {
      onSuccess: () => setButtonDisabled(true),
    },
  });

  const onSubmit = async (
    values: IChangeCompanyAdressRequest,
    helpers: FormikHelpers<FormikValues>
  ) => {
    try {
      var body: IChangeCompanyAdressRequest = {
        ...values,
      };
      const cep = RemoveMask(body.endereco.cep);
      if (cep) body.endereco.cep = cep;
      await mutateAsync(body);
    } finally {
      helpers.setSubmitting(false);
    }
  };

  return (
    <>
      {editForm ? (
        <Form
          onSubmit={onSubmit}
          initialValues={company && initialValues(company)}
          schema={CompanyAdressCardSchema}
        >
          <ConnectedFocusError />
          <CompanyCard
            editForm={editForm}
            onButtonChange={(value) => setButtonDisabled(value)}
            buttonDisabled={buttonDisabled}
            isLoading={isLoading}
          />
        </Form>
      ) : (
        <CompanyCard editForm={!!editForm} />
      )}
    </>
  );
};
