import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  TextInput,
  Tooltip
} from '@/Components';
import { DilutionRatio } from '@/Utils/DilurionRatio';
import { useFormikContext } from 'formik';
import { IReason } from "./interfaces";
import { useReasonStates } from './Hooks/useReasonStates';

export const Reason: FC<IReason> = ({
  standardFormula,
  disabled
}) => {
  const { t } = useTranslation();
  const form: any = useFormikContext();

  const {
    reasonOriginal,
    setReasonOriginal
  } = useReasonStates();

  useEffect(() => {
    let diluition: any;
    if (standardFormula?.diluicao && standardFormula?.diluicao > 0) {
      diluition = DilutionRatio(standardFormula?.diluicao);
      setReasonOriginal(diluition);
    } else {
      setReasonOriginal('0:0');
    }
  }, [standardFormula?.diluicao]);

  return (
    <Tooltip
      title={`${t(
        'production.standardFormula.details.tooltips.previousReason'
      )} ${reasonOriginal}`}
      targetOffset={[0, 0]}
      showMe={
        !disabled && standardFormula?.diluicao !== form.values.diluicao
      }
    >
      <TextInput
        name="razao"
        label={t('production.standardFormula.details.reason')}
        placeHolder={t(
          'production.standardFormula.details.reasonPlaceholder'
        )}
        withoutMarginBottom={true}
        withStatusIcon
        disabled={true}
      />
    </Tooltip>
  );
};