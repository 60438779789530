import { Button } from '@/Components';
import _ from 'lodash';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { history } from '../../../../../../../Store';
import { IFChildren } from '@/Components/Form';
import styles from './PackingClassification.module.scss';

interface IFooterButtons {
  isLoadingCreatePackingClassification: boolean;
  props: IFChildren;
}

export const FooterButtons: FC<IFooterButtons> = ({
  isLoadingCreatePackingClassification,
  props,
}) => {
  const { t } = useTranslation();

  return (
    <div className={styles['footer-button']}>
      <Button
        type="secondary"
        children={t(
          'production.packingClassification.create.footerButton.cancel'
        )}
        onClick={() => {
          history.push('/production/packing-classification');
        }}
      />
      <Button
        type="primary"
        children={t(
          'production.packingClassification.create.footerButton.okButton'
        )}
        htmlType="submit"
        loading={isLoadingCreatePackingClassification}
        dataTestId="submit-packaging-classification"
      />
    </div>
  );
};
