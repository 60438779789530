import { useControllerQueryListApiHook } from '@/ControllerApiHook/Controller';
import { ProductAPI } from '@/Data/API/Supply/Product';
import { FETCH_LIST_FOR_DROPDOWN_TYPE } from '@/ControllerApiHook/UniqIds/Supply/ProductKeys';

export const useProductCallAPI = () => {
  const {
    data: productList,
    isLoading: isProductLoading,
    fetchNewPage: fetchNewProductPage,
    refetch: refetchSearchProductPage,
  } = useControllerQueryListApiHook({
    uniqId: FETCH_LIST_FOR_DROPDOWN_TYPE,
    entityApi: ProductAPI.getListProductDropDown,
    pagination: {
      sorter: { column: 'descricao', direction: 'ASC' },
    },
  });

  return {
    productList,
    isProductLoading,
    fetchNewProductPage,
    refetchSearchProductPage,
  };
};
