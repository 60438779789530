export const useCompareValues = () => {
  const compareValues = (obj1: any, obj2: any) => {
    const clone1 = JSON.parse(JSON.stringify(obj1));
    const clone2 = JSON.parse(JSON.stringify(obj2));

    delete clone1.metadata;
    delete clone2.metadata;

    const firstJson = JSON.stringify(clone1);
    const secondJson = JSON.stringify(clone2);

    if (firstJson === secondJson) {
      return { isEqual: true, differences: [] };
    }

    const differences: string[] = [];

    const findDifferences = (o1: any, o2: any, path = '') => {
      const allKeys = Array.from(
        new Set([...Object.keys(o1), ...Object.keys(o2)])
      );

      for (const key of allKeys) {
        const currentPath = path ? `${path}.${key}` : key;

        if (!(key in o1)) {
          differences.push(
            `A propriedade "${currentPath}" está presente apenas no segundo objeto`
          );
        } else if (!(key in o2)) {
          differences.push(
            `A propriedade "${currentPath}" está presente apenas no primeiro objeto`
          );
        } else if (
          typeof o1[key] === 'object' &&
          typeof o2[key] === 'object' &&
          o1[key] !== null &&
          o2[key] !== null
        ) {
          findDifferences(o1[key], o2[key], currentPath);
        } else if (o1[key] !== o2[key]) {
          differences.push(
            `A propriedade "${currentPath}" difere: ${o1[key]} !== ${o2[key]}`
          );
        }
      }
    };

    findDifferences(clone1, clone2);

    return { isEqual: false, differences };
  };

  return { compareValues };
};
