import { TFunction } from 'react-i18next';

export type statusTypes =
  | 'success'
  | 'danger'
  | 'attention'
  | 'primary'
  | 'default'
  | 'disabled';

export const StatusSaleOrderType = ({
  statusId,
  t,
}: {
  statusId: number;
  t: TFunction<'translation', undefined>;
}): { type: statusTypes; children: string } => {
  switch (statusId) {
    case 1:
      return {
        type: 'attention',
        children: t('saleOrder.editOrder.statusSaleOrder.budget'),
      };
    case 2:
      return {
        type: 'success',
        children: t('saleOrder.editOrder.statusSaleOrder.approved'),
      };
    case 3:
      return {
        type: 'danger',
        children: t('saleOrder.editOrder.statusSaleOrder.rejected'),
      };
    case 4:
      return {
        type: 'success',
        children: t('saleOrder.editOrder.statusSaleOrder.delivered'),
      };
    case 5:
      return {
        type: 'attention',
        children: t('saleOrder.editOrder.statusSaleOrder.partialDelivery'),
      };
    case 6:
      return {
        type: 'danger',
        children: t('saleOrder.editOrder.statusSaleOrder.canceled'),
      };
    default:
      return {
        type: 'default',
        children: t('saleOrder.editOrder.statusSaleOrder.pending'),
      };
  }
};
