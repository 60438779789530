import React from 'react';
import { Card } from '../../../../../../../../../Components';
import { useTranslation } from 'react-i18next';
import FirstRow from './rows/FirstRow';
import SecondRow from './rows/SecondRow';
import ThirdRow from './rows/ThirdRow';
import FourthRow from './rows/FourthRow';
import FifthRow from './rows/FifthRow';
import SixthRow from './rows/SixthRow';
import FormBreakLine from '../../../../../../../../../Components/FormBreakLine';

interface IInfoSection {
  purchaseOrders?: any[];
}

export const InfoSection: React.FC<IInfoSection> = ({ purchaseOrders }) => {
  const { t } = useTranslation();

  return (
    <Card title={t('purchasing.invoice.create.info')} withoutCollapse>
      <FirstRow />
      <SecondRow />
      <ThirdRow />
      <FourthRow />
      <FifthRow />
      <FormBreakLine />
      <SixthRow purchaseOrders={purchaseOrders} />
    </Card>
  );
};
