export const FETCH_GET_PRODUCT = '[PRODUCT] FETCH_GET_PRODUCT';
export const FETCH_GET_GROUP = '[PRODUCT_GROUP] FETCH_GET_GROUP';
export const FETCH_GET_DROPDOWN_GROUP = '[PRODUCT_SUBGROUP] FETCH_GET_DROPDOWN_GROUP';
export const FETCH_GET_SUBGROUP = '[PRODUCT_SUBGROUP] FETCH_GET_SUBGROUP';
export const FETCH_GET_DROPDOWN_SUBGROUP =
'[PRODUCT_SUBGROUP] FETCH_GET_DROPDOWN_SUBGROUP';
export const FETCH_GET_INCOMPATIBLE_PRODUCT =
'[PRODUCT] FETCH_GET_INCOMPATIBLE_PRODUCT';
export const FETCH_GET_SYNONYM_PRODUCT = '[PRODUCT] FETCH_GET_SYNONYM_PRODUCT';
export const FETCH_GET_ASSOCIATED_PRODUCT =
'[PRODUCT] FETCH_GET_ASSOCIATED_PRODUCT';
export const FETCH_GET_DILUTED_PRODUCT = '[PRODUCT] FETCH_GET_DILUTED_PRODUCT';

export const FETCH_LIST_PRODUCT = '[PRODUCT] FETCH_LIST_PRODUCT';
export const FETCH_LIST_FINISHED_PRODUCT =
  '[PRODUCT] FETCH_LIST_FINISHED_PRODUCT';
export const FETCH_LIST_FOR_DROPDOWN_TYPE =
  '[PRODUCT] FETCH_LIST_FOR_DROPDOWN_TYPE';
export const FETCH_LIST_PRODUCT_PRESCRIPTION =
  '[PRODUCT] FETCH_LIST_PRODUCT_PRESCRIPTION';
export const FETCH_LIST_GROUP = '[PRODUCT_GROUP] FETCH_LIST_GROUP';
export const FETCH_LIST_SUBGROUPS_GROUP =
  '[PRODUCT_GROUP] FETCH_LIST_SUBGROUPS_GROUP';
export const FETCH_LIST_PRODUCTS_GROUP =
  '[PRODUCT_GROUP] FETCH_LIST_PRODUCTS_GROUP';
export const FETCH_LIST_SUBGROUP = '[PRODUCT_SUBGROUP] FETCH_LIST_SUBGROUP';
export const FETCH_LIST_SUBGROUP_BY_GROUP =
  '[PRODUCT_SUBGROUP] FETCH_LIST_SUBGROUP_BY_GROUP';
export const FETCH_LIST_PRODUCTS_SUBGROUP =
  '[PRODUCT_SUBGROUP] FETCH_LIST_PRODUCTS_SUBGROUP';
export const FETCH_LIST_PRODUCT_LINKED_MESSAGE =
  '[PRODUCT] FETCH_LIST_PRODUCT_LINKED_MESSAGE';
export const FETCH_LIST_FOR_DROPDOWN_INCOMPATIBLE_PRODUCT =
  '[PRODUCT] FETCH_LIST_FOR_DROPDOWN_INCOMPATIBLE_PRODUCT';
export const FETCH_LIST_FOR_DROPDOWN_ASSOCIATED_PRODUCT_PHARMACEUTICAL_FORM =
  '[PRODUCT] FETCH_LIST_FOR_DROPDOWN_ASSOCIATED_PRODUCT_PHARMACEUTICAL_FORM';


export const CREATE_PRODUCT = '[PRODUCT] CREATE_PRODUCT';
export const DELETE_PRODUCT = '[PRODUCT] DELETE_PRODUCT';
export const EDIT_PRODUCT = '[PRODUCT] EDIT_PRODUCT';
export const EDIT_FINANCIAL_PRODUCT = '[PRODUCT] EDIT_FINANCIAL_PRODUCT';
export const EDIT_STATUS_PRODUCT = '[PRODUCT] EDIT_STATUS_PRODUCT';
export const CREATE_GROUP = '[PRODUCT_GROUP] CREATE_GROUP';
export const DELETE_GROUP = '[PRODUCT_GROUP] DELETE_GROUP';
export const EDIT_GROUP = '[PRODUCT_GROUP] EDIT_GROUP';
export const CREATE_SUBGROUP = '[PRODUCT_SUBGROUP] CREATE_SUBGROUP';
export const DELETE_SUBGROUP = '[PRODUCT_SUBGROUP] DELETE_SUBGROUP';
export const EDIT_SUBGROUP = '[PRODUCT_SUBGROUP] EDIT_SUBGROUP';
export const DELETE_INCOMPATIBLE_PRODUCT =
  '[PRODUCT] DELETE_INCOMPATIBLE_PRODUCT';
export const CREATE_INCOMPATIBLE_PRODUCT =
  '[PRODUCT] CREATE_INCOMPATIBLE_PRODUCT';
export const DELETE_PRODUCT_LINKED_MESSAGE =
  '[PRODUCT] DELETE_PRODUCT_LINKED_MESSAGE';
export const CREATE_PRODUCT_LINKED_MESSAGE =
  '[PRODUCT] CREATE_PRODUCT_LINKED_MESSAGE';
export const CREATE_PRODUCT_MESSAGE_LINKED =
  '[PRODUCT] CREATE_PRODUCT_MESSAGE_LINKED';
export const CREATE_DILUTED_PRODUCT = '[PRODUCT] CREATE_DILUTED_PRODUCT';
export const DELETE_DILUTED_PRODUCT = '[PRODUCT] DELETE_DILUTED_PRODUCT';
export const CREATE_ASSOCIATED_PRODUCT = '[PRODUCT] CREATE_DILUTED_PRODUCT';
export const DELETE_ASSOCIATED_PRODUCT = '[PRODUCT] DELETE_ASSOCIATED_PRODUCT';
export const PUT_INCOMPATIBLE_PRODUCT = '[PRODUCT] PUT_INCOMPATIBLE_PRODUCT';
export const PUT_SYNONYM_PRODUCT = '[PRODUCT] PUT_SYNONYM_PRODUCT';
export const PUT_DILUTED_PRODUCT = '[PRODUCT] PUT_DILUTED_PRODUCT';

export const VALIDATION_NAME_PRODUCT = '[PRODUCT] VALIDATION_NAME_PRODUCT';
export const VALIDATION_NAME_GROUP = '[PRODUCT_GROUP] VALIDATION_NAME_GROUP';
export const VALIDATION_NAME_SUBGROUP = '[PRODUCT_SUBGROUP] VALIDATION_NAME_SUBGROUP';
export const VALIDATION_SYNONYM_PRODUCT = '[PRODUCT] VALIDATION_SYNONYM_PRODUCT';
export const FETCH_INCOMPATIBLE_PRODUCT =
  '[PRODUCT] FETCH_INCOMPATIBLE_PRODUCT';
export const FETCH_SYNONYM_PRODUCT = '[PRODUCT] FETCH_SYNONYM_PRODUCT';
export const FETCH_DILUTED_PRODUCT = '[PRODUCT] FETCH_DILUTED_PRODUCT';
export const FETCH_ASSOCIATED_PRODUCT = '[PRODUCT] FETCH_ASSOCIATED_PRODUCT';


