import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Modal } from '@/Components/Modal';
import { ICreateGroupModal } from './interfaces';
import { CreateFormGroupModalSchema } from './CreateFormGroupModal.schema';
import { useFormGroupModalStates } from './Hooks/useFormGroupModalStates';
import { useFormGroupModalCallAPI } from './Hooks/useFormGroupModalCallAPI';
import { useFormGroupModalFunctions } from './Hooks/useFormGroupModalFunctions';
import { useFormGroupModalMemorizations } from './Hooks/useFormGroupModalMemorizations';

const FormGroupModal: FC<ICreateGroupModal> = ({
  visible,
  changeVisible,
  title,
  selectedRowsOnTable,
}) => {
  const { t } = useTranslation();
  const abbreviatedI18n = 'products.groupList.modal';

  const { productGroupAlreadyRegistred, setProductGroupAlreadyRegistred } =
    useFormGroupModalStates();

  const { createGroup, editGroup } = useFormGroupModalCallAPI();

  const { onOkClickForm, onCancelClick, onClose } = useFormGroupModalFunctions({
    title,
    selectedRowsOnTable,
    createGroup,
    editGroup,
    visible,
    changeVisible,
  });

  const { body, initialValues } = useFormGroupModalMemorizations({
    selectedRowsOnTable,
    setProductGroupAlreadyRegistred,
  });

  return (
    <Modal
      onOkClickForm={onOkClickForm}
      title={title ? title : t(`${abbreviatedI18n}.title`)}
      body={body}
      visible={visible}
      schema={CreateFormGroupModalSchema}
      htmlType="submit"
      okButtonName={title ? '' : t(`${abbreviatedI18n}.okButton`)}
      onCancelClick={onCancelClick}
      onClose={onClose}
      initialValues={initialValues}
      disabledButton={productGroupAlreadyRegistred}
      disabledButtonFormNames={['descricao']}
      withForm
      widthModal={400}
      dataTestIdOkButton="submit-group"
      loading={title ? editGroup.isLoading : createGroup.isLoading}
    />
  );
};

export default FormGroupModal;
