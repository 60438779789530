export const useAmountMemorizations = () => {
  const unitsMeasurementsMapping: any = {
    1: 'UI',
    2: 'un',
    3: 'mL',
    4: 'L',
    5: 'mcg',
    6: 'mg',
    7: 'g',
    8: 'kg',
    9: 'UTR',
    10: 'UFC',
    11: 'mEq',
    12: 'Mil',
    13: 'PER',
    14: 'MILHAO',
    15: 'BILHAO',
  };

  return {
    unitsMeasurementsMapping
  };
};