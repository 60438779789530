import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { IUseMemorizations } from './interfaces';

export const useCreateMemorizations = ({ lotList }: IUseMemorizations) => {
  const { t } = useTranslation();
  const lotDropdownList = useMemo(() => {
    return lotList?.data?.map((value) => ({
      id: value?.loteExternalId,
      label: value?.loteNumero,
      subLabel: `${t('supply.losses.create.invoiceSubLabel')} ${
        value?.numeroNf
      }-${value?.serieNf}`,
      allItem: value,
    }));
  }, [lotList]);

  return { lotDropdownList };
};
