import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { HorizontalTabs } from '@/Components';
import { PageLoading } from '@/Layouts/PageLoading';
import { CancelOrderPurchaseModal } from './Modal/CancelModal';
import { useParams } from 'react-router';
import { useDetailsCallAPI } from './Hooks/useDetailsCallAPI';
import { useDetailsStates } from './Hooks/useDetailsStates';
import { useDetailsMemorizations } from './Hooks/useDetailsMemorizations';

export const PurchaseDetails: FC = () => {
  const { t } = useTranslation();
  const { externalId } = useParams<{ externalId: string }>();
  
  const {
    cancelModal,
    setCancelModal
  } = useDetailsStates();

  const {
    orderPurchase
  } = useDetailsCallAPI({ externalId });

  const {
    items,
    dropdownItems,
    rightButton
  } = useDetailsMemorizations({
    orderPurchase,
    setCancelModal,
    externalId
  });

  return (
    <PageLoading>
      <HorizontalTabs
        type="secondary"
        items={items}
        withoutTabs
        dropdownDirection="bottomRight"
        dropdownTitle={t('admin.editUserPage.tab.buttonActions.title')}
        leftIcon="menu-ellipsis-horizontal"
        dropdownItens={dropdownItems}
        rightButton={rightButton}
      />
      <CancelOrderPurchaseModal
        purchaseOrder={orderPurchase}
        visibleModal={cancelModal}
        changeVisibleModal={(value) => setCancelModal(value)}
      />
    </PageLoading>
  );
};
