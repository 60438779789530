import { useMemo } from 'react';
import { IUseFunctions } from './interfaces';
import _ from 'lodash';

export const useModalBodyFunctions = ({ mutateAsync }: IUseFunctions) => {
  const fetchSpecialtiesValidationName = useMemo(
    () =>
      _.debounce(async (description: string) => {
        return await mutateAsync({
          descricao: description,
        });
      }, 500),
    [mutateAsync]
  );

  return {
    fetchSpecialtiesValidationName,
  };
};
