import { FormikHelpers, FormikValues } from 'formik';
import { useQueryClient } from '@tanstack/react-query';
import { 
  FETCH_LIST_PRODUCT_LINKED_MESSAGE 
} from '@/ControllerApiHook/UniqIds/Supply/ProductKeys';
import { 
  IEditProductMessage 
} from '@/Data/Interfaces/request/ProductMensage/IProductMensageRequest';
import { history } from '@/Store';
import { IUseFunctions } from './interfaces';

export const useDetailFunctions = ({
  productMessage,
  editProductMessage,
  productExternalId,
  toogleDisabled,
  disabledForm
}: IUseFunctions) => {
  const queryClient = useQueryClient();

  const onSubmit = async (
    values: IEditProductMessage,
    helpers: FormikHelpers<FormikValues>
  ) => {
    var body: IEditProductMessage = { ...values, helpers };

    if (productMessage) {
      const res = await editProductMessage.mutateAsync({
        externalId: productMessage.externalId,
        descricao: body.descricao,
        exibeVenda: body.exibeVenda ?? false,
        exibeRotulagem: body.exibeRotulagem ?? false,
        exibeFichaPesagem: body.exibeFichaPesagem ?? false,
        exibeImpressaoRotulo: body.exibeImpressaoRotulo ?? false,
        helpers,
      });

      if (res.success) {
        queryClient.invalidateQueries([FETCH_LIST_PRODUCT_LINKED_MESSAGE]);
        history.push(
          `/inventory/products/${productExternalId}/product-messages`
        );
      }
    }
  };

  const onClickBackButton = () => {
    history.push(
      `/inventory/products/${productExternalId}/product-messages`
    );
  };

  const onClickCancelButton = () => {
    toogleDisabled(!disabledForm);
  };

  return {
    onSubmit,
    onClickBackButton,
    onClickCancelButton
  };
};