import { FC } from "react";
import { Card } from '@/Components';
import { AdderList } from '@/Components/AdderList';
import { IFChildren } from "@/Components/Form";
import { useTranslation } from "react-i18next";
import { useContactCardFunctions } from "./Hooks/useContactCardFunctions";
import { useContactCardMemorizations } from "./Hooks/useContactCardMemorizations";

export const ContactCard: FC<{ props: IFChildren }> = ({ props }) => {
  const { column, modal } = useContactCardMemorizations();

  const { onChange } = useContactCardFunctions({ props });
  
  const { t } = useTranslation();

  return (
    <Card title={t('contact.contacts')} withoutCollapse>
      <AdderList
        favoriteFormName="principal"
        onChange={onChange}
        collum={column}
        modal={modal}
        buttonLabel={t('contact.addContact')}
        empytDataDescription={t('contact.contactEmpyt')}
        entityExternalIdName="prescritorContatoExternalId"
        type="contactType"
        actions
      />
    </Card>
  );
};