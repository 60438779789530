import { IUseMemorizations } from "./interfaces";

export const useDetailMemorizations = ({
  productMessage
}: IUseMemorizations) => {
  const initialValues = {
    ...productMessage,
    externalId: productMessage?.externalId,
    descricao: productMessage?.descricao,
    produtosVinculados: productMessage?.produtosVinculados,
  };

  return {
    initialValues
  };
};