import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { PageLoading } from '../../../../../../Layouts/PageLoading';
import { Form } from '../../../../../../Components';
import InfoCard from './Components/InfoCard';
import { useEditCallAPI } from './Hooks/useEditCallAPI';
import { useEditFunctions } from './Hooks/useEditFunctions';
import { FooterButtons } from './Components/FooterButtons';
import { ConnectedFocusError } from '@/Components/ConnectedFocusError';
import { ProductMensageEditSchema } from './EditProductMensage.schema';

interface IDetailsForm {
  disabled: boolean;
  onChangeDisabled: (x: boolean) => void;
}

export const ProductMessageDetail: FC<IDetailsForm> = ({
  disabled,
  onChangeDisabled,
}) => {
  const { externalId } = useParams<{ externalId: string }>();
  const { t } = useTranslation();

  const {
    editProductMessage,
    isLoadingEditProductMessage,
    isLoadingProductMessage,
    productMessage,
  } = useEditCallAPI({ t, externalId });

  const { onSubmit } = useEditFunctions({ editProductMessage, productMessage });

  return (
    <PageLoading loading={isLoadingProductMessage}>
      <Form
        initialValues={{
          ...productMessage,
          externalId: productMessage?.externalId,
          descricao: productMessage?.descricao,
          produtosVinculados: productMessage?.produtosVinculados,
        }}
        onSubmit={onSubmit}
        schema={ProductMensageEditSchema}
      >
        {(props) => (
          <>
            <ConnectedFocusError />
            <InfoCard
              disabled={disabled}
              messageProductInitialValue={productMessage?.descricao}
            />
            {!disabled && (
              <FooterButtons
                isLoadingEditProductMessage={isLoadingEditProductMessage}
                productMessage={productMessage}
                props={props}
                onChangeDisabled={onChangeDisabled}
              />
            )}
          </>
        )}
      </Form>
    </PageLoading>
  );
};

export default ProductMessageDetail;
