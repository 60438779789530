import { useFormikContext } from "formik";
import { useCallback } from "react";
import { IUseFunctions } from "./interfaces";

export const useClassFunctions = ({
  listClassTypes
}: IUseFunctions) => {
  const form: any = useFormikContext();

  const onChange = useCallback((value: any) => {
    form.setFieldValue(
      'unidadeEstoqueId',
      listClassTypes?.data.find((x: any) => x.id === value)
        ?.unidadePadraoVisualizacaoId
    );
  }, [listClassTypes]);

  return { onChange };
};