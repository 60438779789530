import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { AdderList, Card } from '../../../../../../../../Components';
import { IFChildren } from '@/Components/Form';
import { AddressModal } from '@/Components/EntityInformation/Modal';

interface IAddressCard {
  props: IFChildren;
}

export const AddressCard: FC<IAddressCard> = ({ props }) => {
  const { t } = useTranslation();

  return (
    <Card title={t('address.adresses')} withoutCollapse>
      <AdderList
        favoriteFormName="principal"
        onChange={(values) => props.setFieldValue('enderecos', values)}
        collum={[
          {
            label: t('purchasing.provider.create.addressTable.desc'),
            width: 6,
            key: 'descricao',
          },
          {
            label: t('address.address'),
            key: 'logradouro',
          },
          {
            key: 'numero',
          },
          {
            key: 'complemento',
          },
          {
            key: 'bairro',
          },
          {
            key: 'cidadeDescricao',
          },
          {
            key: 'estadoDescricao',
          },
          {
            key: 'paisDescricao',
          },
        ]}
        modal={{
          editTitle: t('address.editAddress'),
          addTitle: t('address.addAddress'),
          modalFormName: [
            'descricao',
            'cep',
            'logradouro',
            'numero',
            'bairro',
            'cidade',
            'cidadeExternalId',
            'estadoExternalId',
            'paisExternalId',
            'complemento',
          ],
          modalFormRequiredName: ['descricao'],
          body: <AddressModal />,
          okAddButtonLabel: t('common.add'),
          okEditButtonLabel: t('common.save'),
          widthModal: 800,
        }}
        buttonLabel={t('address.addAddress')}
        empytDataDescription={t('address.addressEmpyt')}
        entityExternalIdName="prescritorEnderecoExternalId"
        actions
        type="adrressType"
      />
    </Card>
  );
};
