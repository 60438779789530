import React, { FC } from 'react';
import { Tooltip } from '..';
import { Text } from '..';
import { IText } from '../Text';

export interface ITextShortner extends IText {
  maxCharacters?: number;
  children: string;
}

export const TextShortner: FC<ITextShortner> = ({
  children,
  maxCharacters,
  ...props
}) => {
  let textContent = children;
  const currentLength = children?.length; // less the ...

  if (!maxCharacters) {
    return <Text {...props}>{textContent}</Text>;
  }

  const hasMaxChars = currentLength > maxCharacters;

  if (hasMaxChars) {
    textContent = `${textContent.substring(0, maxCharacters)}...`;
  }

  return (
    <Tooltip title={children} showMe={hasMaxChars}>
      <Text style={{ margin: 0 }} {...props}>
        {textContent}
      </Text>
    </Tooltip>
  );
};
