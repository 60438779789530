import React from 'react';
import { PageLoading } from '@/Layouts/PageLoading';
import { HorizontalTabs } from '@/Components';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { DeleteStandardFormulaModal } from './Modal/DeleteStandardFormulaModal';
import { useEditStates } from './Hooks/useEditStates';
import { useEditCallAPI } from './Hooks/useEditCallAPI';
import { useEditMemorizations } from './Hooks/useEditMemorizations';

const FormStandardFormula: React.FC = () => {
  const { t } = useTranslation();
  const { externalId, tab } = useParams<{ externalId: string; tab: string }>();

  const {
    visibleModal,
    setVisibleModal,
    disabled,
    setDisabled
  } = useEditStates();

  const {
    standardFormula
  } = useEditCallAPI({ externalId });

  const {
    items,
    dropdownItems,
    rightButton
  } = useEditMemorizations({
    disabled,
    setDisabled,
    setVisibleModal,
    tab,
    standardFormula
  })

  return (
    <PageLoading>
      <HorizontalTabs
        type="secondary"
        items={items}
        dropdownItens={dropdownItems}
        dropdownDirection="bottomRight"
        dropdownTitle={t('common.moreActions')}
        leftIcon="menu-ellipsis-horizontal"
        rightButton={rightButton}
      />
      {standardFormula && (
        <DeleteStandardFormulaModal
          standardFormula={standardFormula}
          visibleModal={visibleModal}
          changeVisibleModal={(value) => setVisibleModal(value)}
        />
      )}
    </PageLoading>
  );
};

export default FormStandardFormula;
