//@ts-nocheck
import * as yup from 'yup';
import i18n from '../../../../../Components/i18n';
import { cnpj, cpf } from 'cpf-cnpj-validator';

yup.addMethod(yup.string, 'cnpj', function (message) {
  return this.test('validar-cnpj', message, function (value) {
    return cnpj.isValid(value);
  });
});

yup.addMethod(yup.string, 'cpf', function (message) {
  return this.test('validar-cpf', message, function (value) {
    return cpf.isValid(value);
  });
});

export const ProviderCreateSchema = yup.object().shape({
  tipoPessoa: yup.number(),
  tipoFornecedorId: yup
    .number()
    .required(i18n.t('purchasing.provider.create.errors.requiredProviderType')),
  nome: yup
    .string()
    .required(i18n.t('purchasing.provider.create.errors.requiredName')),
  cnpj: yup.string().when('tipoPessoa', {
    is: 1,
    then: yup
      .string()
      .cnpj(i18n.t('purchasing.provider.create.errors.requiredCnpj')),
  }),

  cpf: yup.string().when('tipoPessoa', {
    is: 0,
    then: yup
      .string()
      .cpf(i18n.t('purchasing.provider.create.errors.requiredCpf')),
  }),
});
