import { useFormikContext } from 'formik';

export const useInternalDilutionsFactorFunctions = () => {
  const form: any = useFormikContext();

  const onChange = (value: string | number) => {
    const currentFormInternalDiluitionFactor =
      typeof value === 'number' ? value : parseFloat(value);

    if (isNaN(currentFormInternalDiluitionFactor)) {
      return;
    }

    const currentFormInternalDiluition =
      100 / currentFormInternalDiluitionFactor;

    form.setFieldValue(
      'loteInformacaoTecnica.diluicaoInterna',
      currentFormInternalDiluition
    );
  };
  return { onChange };
};
