import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { PageLoading } from '@/Layouts/PageLoading';
import { Button, Form } from '@/Components';
import ProductMessageDescription from './Component/ProductMessageDescription';
import { IProductMessageTabDetail } from './interfaces';
import { useDetailCallAPI } from './Hooks/useDetailCallAPI';
import { useDetailFunctions } from './Hooks/useDetailFunctions';
import { useDetailMemorizations } from './Hooks/useDetailMemorizations';

import styles from './ProductMessage.module.scss';

export const ProductMessageTabDetail: FC<IProductMessageTabDetail> = ({
  productExternalId,
  disabledForm,
  toogleDisabled
}) => {
  const { t } = useTranslation();

  const {
    productMessage,
    loading,
    editProductMessage
  } = useDetailCallAPI();

  const {
    onSubmit,
    onClickBackButton,
    onClickCancelButton
  } = useDetailFunctions({
    productMessage,
    editProductMessage,
    productExternalId,
    toogleDisabled,
    disabledForm
  });

  const {
    initialValues
  } = useDetailMemorizations({
    productMessage
  });

  return (
    <PageLoading loading={loading}>
      <Form
        initialValues={initialValues}
        onSubmit={onSubmit}
      >
        {() => (
          <>
            <ProductMessageDescription disabled={disabledForm} />
            {!!disabledForm && (
              <Button
                type="secondary"
                children={t('common.back')}
                onClick={onClickBackButton}
              />
            )}

            {!disabledForm && (
              <div className={styles['footer-button']}>
                <Button
                  type="secondary"
                  children={t('common.cancel')}
                  onClick={onClickCancelButton}
                />
                <Button
                  type="primary"
                  children={t('common.save')}
                  htmlType="submit"
                  disabled={disabledForm}
                />
              </div>
            )}
          </>
        )}
      </Form>
    </PageLoading>
  );
};

export default ProductMessageTabDetail;
