import { TextInput } from '@/Components';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import styles from '../laboratoryName.module.scss';

interface ILaboratoryName {
  onChange: (value: string) => void;
}

export const LaboratoryName: FC<ILaboratoryName> = ({ onChange }) => {
  const { t } = useTranslation();

  return (
    <TextInput
      className={styles['text-input']}
      name="nomeLaboratorio"
      placeHolder={t('production.laboratory.create.namePlaceholder')}
      onChange={(e) => {
        onChange(e);
      }}
      required
      label={t('production.laboratory.create.name')}
      dataTestId="laboratory-description"
    />
  );
};
