import { IUseMemorizations } from './interfaces';

export const useModalBodyMemorizations = ({
  listGroups,
}: IUseMemorizations) => {
  const items = listGroups?.data?.map((x: any) => ({
    id: x.externalId,
    label: x.descricao,
  }));

  return {
    items,
  };
};
