import { IconName } from '@/Components/Icon/IconClasses';
import { Http } from '@/Utils/Http';
import { QueryFunctionContext } from '@tanstack/react-query';

export interface IGetDocumentProviderResponse {
  nome: string;
  razaoSocial: string;
  cnpj: string;
  fornecedorContatoFavorito: string;
  observacaoContatoFavorito: string;
  tipoContatoId: number;
  icon: IconName;
}

export const GetDocumentProvider = async (
  context: QueryFunctionContext
): Promise<IGetDocumentProviderResponse | undefined> => {
  if (!context.queryKey[1]) return undefined;
  return await Http.fetcher<IGetDocumentProviderResponse>(
    `/pharma/pessoa/v1/Fornecedor/ObterRazaoCnpj/${context.queryKey[1]}`
  );
};
