import { FC } from "react";
import { Col } from 'antd';
import { useTranslation } from "react-i18next";
import { WeightInput } from '@/Components/Inputs/WeightInput';
import { IAlternativeUnit2 } from "./interfaces";
import { useAlternativeUnit2Memorizations } from "./Hooks/useAlternativeUnit2Memorizations";

export const AlternativeUnit2: FC<IAlternativeUnit2> = ({
  unidadeAlternativaList
}) => {
  const { t } = useTranslation();

  const {
    label,
    dropdownLeft,
    dropdownRight
  } = useAlternativeUnit2Memorizations({
    unidadeAlternativaList
  });

  return (
    <Col span={6}>
      <WeightInput
        name="loteInformacaoTecnica.loteUnidadeAlternativa2.quantidadeUnidadeAlternativa"
        label={label}
        placeHolder={t('supply.lot.create.alternativeUnitTwo')}
        dropdownLeft={dropdownLeft}
        dropdownRight={dropdownRight}
      />
    </Col>
  );
};