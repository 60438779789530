import { FC } from 'react';
import { TextInput } from '@/Components';
import { Col } from 'antd';
import { useTranslation } from 'react-i18next';
import { IRegisterNumber } from './interfaces';

export const RegisterNumber: FC<IRegisterNumber> = ({
  fetchValidateRegistrationPrescriber,
  form,
}) => {
  const { t } = useTranslation();

  return (
    <Col flex="17%">
      <TextInput
        name="codigoRegistro"
        placeHolder={`${t('common.exampleAbbreviation')}: 14.975`}
        label={t('prescribers.createPage.info.registerNumber')}
        required
        onChange={(x) =>
          fetchValidateRegistrationPrescriber(
            x,
            form.values.tipoRegistroId,
            form.values.ufRegistroExternalId
          )
        }
      />
    </Col>
  );
};
