import { Button, Card, Icon } from '@/Components';
import FormBreakLine from '@/Components/FormBreakLine';
import { Col, Row } from 'antd';
import { useFormikContext } from 'formik';
import { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { v4 as uuidV4 } from 'uuid';
import { IProductCard } from './interfaces';
import { PackingClassification } from './Components/PackingClassification';
import { Volume } from './Components/Volume';
import { AssociateCapsules } from './Components/AssociateCapsules';
import { CapsulesQuantity } from './Components/CapsulesQuantity';
import { AssociatePackings } from './Components/AssociatePackings';
import { PackingQuantity } from './Components/PackingQuantity';
import { usePackingCardStates } from './Hooks/usePackingCardStates';

export const PackingCard: FC<IProductCard> = ({ disabledForm, product }) => {
  const { t } = useTranslation();
  const form: any = useFormikContext();

  const {
    associateCapsulesListController,
    setAssociateCapsulesListController,
    associatePackingListController,
    setAssociatePackingListController,
    disabledCapsulesTypes,
    setDisabledCapsulesTypes,
    disabledPackingTypes,
    setDisabledPackingTypes,
  } = usePackingCardStates();

  useEffect(() => {
    if (disabledForm === true) {
      let newAssociateCapsulesListController = [
        ...associateCapsulesListController,
      ];
      newAssociateCapsulesListController.pop();
      setAssociateCapsulesListController(newAssociateCapsulesListController);

      let newAssociatePackingListController = [
        ...associatePackingListController,
      ];
      newAssociatePackingListController.pop();
      setAssociatePackingListController(newAssociatePackingListController);
    } else if (disabledForm === false && product) {
      setAssociateCapsulesListController((prev) => [...prev, uuidV4()]);
      setAssociatePackingListController((prev) => [...prev, uuidV4()]);
    }
  }, [disabledForm, product]);

  useEffect(() => {
    if (product && product.embalagem) {
      const newAssociateCapsuleNumber: string[] = [];
      const disabledCapsules: number[] = [];

      product.embalagem.numeroCapsulaAssociacoes.forEach((e: any) => {
        disabledCapsules.push(e.numeroCapsulaId);
        newAssociateCapsuleNumber.push(uuidV4());
      });
      setAssociateCapsulesListController(newAssociateCapsuleNumber);
      setDisabledCapsulesTypes(disabledCapsules);

      const newAssociatePackingNumber: string[] = [];
      product.embalagem.embalagemAssociacoes.forEach(() => {
        newAssociatePackingNumber.push(uuidV4());
      });
      setAssociatePackingListController(newAssociatePackingNumber);
    }
  }, [product]);

  return (
    <Card title={t('products.create.additionalInformation')}>
      <Row gutter={[16, 0]}>
        <PackingClassification product={product} disabledForm={disabledForm} />
        <Volume product={product} disabledForm={disabledForm} />
      </Row>
      <FormBreakLine />

      {(associateCapsulesListController.length === 0
        ? ['empty']
        : associateCapsulesListController
      ).map((key, index) => (
        <Row gutter={[16, 0]} key={key}>
          <AssociateCapsules
            disabledForm={disabledForm}
            index={index}
            disabledCapsulesTypes={disabledCapsulesTypes}
            setDisabledCapsulesTypes={setDisabledCapsulesTypes}
            associateCapsulesListController={associateCapsulesListController}
            setAssociateCapsulesListController={
              setAssociateCapsulesListController
            }
          />
          <CapsulesQuantity index={index} disabledForm={disabledForm} />
          <Col span={1}>
            {!disabledForm && key !== 'empty' && (
              <Button
                type="secondary"
                style={{ marginTop: index === 0 ? '20px' : '5px' }}
                disabled={index === associateCapsulesListController.length - 1}
                onClick={() => {
                  setAssociateCapsulesListController((prev) =>
                    prev.filter((e) => e !== key)
                  );
                  const newNumeroCapsulaAssociacao =
                    form.values.numeroCapsulaAssociacao.filter(
                      (_: any, indexElement: number) => indexElement !== index
                    );
                  form.setFieldValue(
                    'numeroCapsulaAssociacao',
                    newNumeroCapsulaAssociacao
                  );
                  setDisabledCapsulesTypes((prev) =>
                    prev.filter(
                      (e) =>
                        e !==
                        +form.values.numeroCapsulaAssociacao[index]
                          .numeroCapsulaId
                    )
                  );
                }}
              >
                <Icon icon="trash" size="M" />
              </Button>
            )}
          </Col>
        </Row>
      ))}

      <FormBreakLine />

      {(associatePackingListController.length === 0
        ? ['empty']
        : associatePackingListController
      ).map((key, index) => (
        <Row gutter={[16, 0]} key={key}>
          <AssociatePackings
            disabledForm={disabledForm}
            index={index}
            associatePackingListController={associatePackingListController}
            setAssociatePackingListController={
              setAssociatePackingListController
            }
            setDisabledPackingTypes={setDisabledPackingTypes}
            disabledPackingTypes={disabledPackingTypes}
          />
          <PackingQuantity index={index} disabledForm={disabledForm} />
          <Col span={1}>
            {!disabledForm && key !== 'empty' && (
              <Button
                type="secondary"
                style={{ marginTop: index === 0 ? '20px' : 0 }}
                disabled={index === associatePackingListController.length - 1}
                onClick={() => {
                  setAssociatePackingListController((prev) =>
                    prev.filter((e) => e !== key)
                  );
                  const newEmbalagemAssociacao =
                    form.values.embalagemAssociacao.filter(
                      (_: any, indexElement: number) => indexElement !== index
                    );
                  form.setFieldValue(
                    'embalagemAssociacao',
                    newEmbalagemAssociacao
                  );
                  setDisabledPackingTypes((prev) =>
                    prev.filter(
                      (e) =>
                        e !==
                        form.values.embalagemAssociacao[index]
                          .produtoEmbalagemExternalId
                    )
                  );
                }}
              >
                <Icon
                  icon="trash"
                  size="M"
                  color={
                    index === associatePackingListController.length - 1
                      ? 'status-disabled-base'
                      : undefined
                  }
                />
              </Button>
            )}
          </Col>
        </Row>
      ))}
    </Card>
  );
};
