import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Modal } from '@/Components/Modal';
import { IDeleteSpecialtieModal } from './interfaces';
import { useDeleteModalCallAPI } from './Hooks/useDeleteModalCallAPI';
import { useDeleteModalMemorizations } from './Hooks/useDeleteModalMemorizations';
import { useDeleteModalFunctions } from './Hooks/useDeleteModalFunctions';

export const DeleteSpecialtieModal: FC<IDeleteSpecialtieModal> = ({
  specialtie,
  visibleModal,
  changeVisibleModal
}) => {
  const { t } = useTranslation();

  const {
    deleteSpecialties
  } = useDeleteModalCallAPI();

  const {
    modalBody
  } = useDeleteModalMemorizations({
    specialtie
  });

  const {
    onCancelClick,
    onClose,
    onOkClick
  } = useDeleteModalFunctions({
    changeVisibleModal,
    specialtie,
    deleteSpecialties
  });

  return (
    <Modal
      title={`${t('common.delete')} ${t('common.product')} "${
        specialtie?.descricao
      }"?`}
      body={modalBody}
      okButtonColor="status-danger-base"
      visible={visibleModal}
      okButtonName={t('common.delete')}
      onCancelClick={onCancelClick}
      onClose={onClose}
      onOkClick={onOkClick}
      loading={deleteSpecialties.isLoading}
    />
  );
};
