import { useState } from 'react';

export const useCreateStates = () => {
  const [origin, setOrigin] = useState<string>();
  const [destiny, setDestiny] = useState<string>();

  const [changeStorageLocationModal, setChangeStorageLocationModal] =
    useState(false);

  return {
    origin,
    destiny,
    setOrigin,
    setDestiny,
    changeStorageLocationModal,
    setChangeStorageLocationModal
  };
};
