import { useMemo } from "react";
import { Status } from '../../../../../List/Components/Status';
import DateUtils from '@/Utils/DateUtils';
import { Divflex } from '@/Layouts/DivWhithFlex';
import { Avatar } from '@/Components/Avatar';
import ReactMarkdown from 'react-markdown';
import { Tooltip } from '@/Components';
import { useFormikContext } from "formik";
import StorageUtils from "@/Utils/StorageUtils";
import { useTranslation } from "react-i18next";

import styles from '../TimeLine.module.scss';

export const useTimeLineMemorizations = () => {
  const { t } = useTranslation();
  const form: any = useFormikContext();
  const preferences = StorageUtils.getPreference();

  const orderPurchaseStatusDefault = useMemo(() => {
    return   {
      title: (
        <ReactMarkdown
          components={{
            strong: (props) => (
              <span style={{ fontWeight: 'var(--font-weight-normal)' }}>
                {props.children}
              </span>
            ),
          }}
        >
          {t('purchasing.purchaseOrder.list.defaultCreateStatus', {
            nomeUsuario: `**${form.values.pedidoCompraStatus[0].nomeUsuario}**`,
          })}
        </ReactMarkdown>
      ),
      description: `${DateUtils.convertLongDate(
        form.values.pedidoCompraStatus[0].dataAprovacao,
        preferences.padraoData
      )} 
      `,
      icon: (
        <Divflex>
          <Tooltip
            title={t('purchasing.purchaseOrder.list.statusHistoryTooltips.0')}
            showMe
            placement="topLeft"
            targetOffset={[5, -10]}
          >
            <Status
              descriptionType="ui-tiny-bold"
              withoutDescription
              typePurchase={1}
              customIcon="calendar"
            />
          </Tooltip>
          <Avatar
            fisrtName={form.values.pedidoCompraStatus[0].nomeUsuario}
            size="M"
            type="ui-tiny-bold"
            color="white"
            className={styles['avatar']}
          />
        </Divflex>
      ),
    };
  }, [form, t, preferences]);

  const statusTimeLineMapping = useMemo(() => {
    return form.values.pedidoCompraStatus.map(
      (x: any) => ({
        title: (
          <ReactMarkdown
            components={{
              strong: (props) => (
                <span style={{ fontWeight: 'var(--font-weight-normal)' }}>
                  {props.children}
                </span>
              ),
            }}
          >
            {t(
              `purchasing.purchaseOrder.list.statusTypeDescription.${
                x.estorno === true ? 8 : x.statusAlterado - 1
              }`,
              {
                nomeUsuario: `**${x.nomeUsuario}**`,
                itensAprovados: `**${x.itensAprovados}**`,
                totalItens: `**${x.totalItens}**`,
              }
            )}
          </ReactMarkdown>
        ),
        description: `${DateUtils.convertLongDate(
          form.values.pedidoCompraStatus[0].dataAprovacao,
          preferences.padraoData
        )} 
        `,
        icon: (
          <Divflex>
            <Tooltip
              title={t(
                `purchasing.purchaseOrder.list.statusHistoryTooltips.${x.statusAlterado}`
              )}
              showMe
              placement="topLeft"
              targetOffset={[5, -10]}
            >
              <Status
                descriptionType="ui-tiny-bold"
                withoutDescription
                typePurchase={x.statusAlterado}
              />
            </Tooltip>
            <Avatar
              fisrtName={x.nomeUsuario}
              size="M"
              type="ui-tiny-bold"
              color="white"
              className={styles['avatar']}
            />
          </Divflex>
        ),
      })
    );
  }, [form, t]);

  const items = [...statusTimeLineMapping, orderPurchaseStatusDefault];

  return {
    orderPurchaseStatusDefault,
    statusTimeLineMapping,
    items
  };
};