import React from 'react';
import { Row, Col } from 'antd';
import { useTranslation } from 'react-i18next';
import { TextInput } from '@/Components';
import { NumberInput } from '@components/Inputs/NumberInput';

const FirstRow: React.FC = () => {
  const { t } = useTranslation();

  return (
    <Row gutter={[16, 0]}>
      <Col span={6}>
        <TextInput
          name="notaFiscal"
          label={t('purchasing.invoice.create.invoiceModel')}
          disabled
          value={t('purchasing.invoice.create.placeHolder.invoiceModelValue')}
        />
      </Col>
      <Col span={4}>
        <NumberInput
          name="numero"
          label={t('purchasing.invoice.create.invoiceNumber')}
          placeHolder={`${t('common.exampleAbbreviation')}: 0025`}
          disabled
          withThousandSeparator
          withDecimalSeparator
        />
      </Col>
      <Col span={2}>
        <NumberInput
          name="serie"
          label={t('purchasing.invoice.create.invoiceSeries')}
          placeHolder={`${t('common.exampleAbbreviation')}: 5`}
          disabled
        />
      </Col>
      <Col span={12}>
        <TextInput
          label={t('purchasing.invoice.create.natureOfOperation')}
          name="naturezaOperacaoDescricao"
          placeHolder={t(
            'purchasing.invoice.create.placeHolder.natureOfOperation'
          )}
          disabled
        />
      </Col>
    </Row>
  );
};

export default FirstRow;
