import { FC, useEffect } from "react";
import { Col } from 'antd';
import { useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';
import { Tooltip } from '@/Components';
import { IProfitMargin } from "./interfaces";
import { PercentageInput } from "@/Components/Inputs/PercentageInput";
import { useProfitMarginStates } from "./Hooks/useProfitMarginStates";
import { useProfitMarginFunctions } from "./Hooks/useProfitMarginFunctions";

export const ProfitMargin: FC<IProfitMargin> = ({
  product,
  disabledForm
}) => {
  const { t } = useTranslation();
  const form: any = useFormikContext();

  const {
    profitMargin,
    setProfitMargin
  } = useProfitMarginStates();

  const {
    onChange
  } = useProfitMarginFunctions({ setProfitMargin });

  useEffect(() => {
    const currentFormValorVenda = form?.values?.valorVenda;
    const valorVenda =
      form?.values.valorCusto * (form?.values.margemLucro / 100 + 1);
    const hasChanges = currentFormValorVenda !== valorVenda;

    if (hasChanges) {
      form.setFieldValue('valorVenda', valorVenda || 0);
    }
  }, [profitMargin]);

  return (
    <Col span={4}>
      <Tooltip
        title={`${t('products.toolTips.products.profitMargin')} ${
          product?.margemLucro
        }${'%'}`}
        showMe={
          product &&
          product?.margemLucro !== form?.values?.margemLucro &&
          !disabledForm
        }
      >
        <PercentageInput
          name="margemLucro"
          label={[
            {
              children: t('products.create.profit'),
            },
          ]}
          placeHolder={t('products.create.profitPlaceholder')}
          withoutMarginBottom={true}
          disabled={disabledForm}
          onChange={onChange}
          required={!product}
          withStatusIcon={!!product}
        />
      </Tooltip>
    </Col>
  );
};