import { useTranslation } from 'react-i18next';
import { Card } from '../../../../../../../../Components';
import { Col, Row } from 'antd';
import { CurrencyInput } from '../../../../../../../../Components/Inputs/CurrencyInput';
import StorageUtils from '../../../../../../../../Utils/StorageUtils';
import { currencyType } from '@/Utils/Currency';

export const FinancialCard: React.FC = () => {
  const { t } = useTranslation();
  const preference = StorageUtils.getPreference();

  return (
    <Card title={t('common.financial')} withoutCollapse={true}>
      <Row gutter={[16, 0]}>
        <Col span={5}>
          <CurrencyInput
            name="custoOperacional"
            label={[
              {
                children: t(
                  'production.pharmaceuticalForm.create.operationalCost'
                ),
              },
            ]}
            placeHolder={t('products.create.costPricePlaceholder')}
            withoutMarginBottom
            required
            prefix={
              preference?.tipoMoedaId === currencyType.real ? 'R$ ' : '$ '
            }
          />
        </Col>
      </Row>
    </Card>
  );
};
