import { IUseMemorizations } from "./interfaces";

export const useDetailsMemorizations = ({
  data
}: IUseMemorizations) => {
  const initialValues = {
    ...data,
    descricao: data?.subGrupoDescricao,
    grupoLabelInitialValue: {
      id: data?.grupoExternalId,
      label: data?.grupoDescricao,
    },
    grupoPaiExternalId: data?.grupoExternalId,
  };

  return {
    initialValues
  };
};