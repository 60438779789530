import { useParams } from 'react-router';
import {
  EDIT_SUBGROUP,
  FETCH_GET_SUBGROUP
} from '@/ControllerApiHook/UniqIds/Supply/ProductKeys';
import { useControllerMutateApiHook, useControllerQueryApiHook } from '@/ControllerApiHook/Controller';
import { ProductAPI } from '@/Data/API/Supply/Product';
import { IProductSubGroupResponse } from '@/Data/Interfaces/response/Product/IProductRespose';

export const useDetailsCallAPI = () => {
  const { externalId } = useParams<{ externalId: string }>();

  const { data } = useControllerQueryApiHook<IProductSubGroupResponse>({
    uniqId: FETCH_GET_SUBGROUP,
    entityApi: ProductAPI.getproductSubGroup,
    externalIds: [externalId]
  });

  const editSubGroup: any = useControllerMutateApiHook({
    uniqId: EDIT_SUBGROUP,
    entityApi: ProductAPI.editProductSubGroup
  });

  return {
    data,
    editSubGroup
  };
};