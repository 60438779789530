import { FC, useCallback, useMemo } from 'react';
import { AdderListType, ColumnType, ItemsType } from '..';
import { Icon, Text } from '../../';
import { Col, Row } from 'antd';
import { GET_CITY } from '../../../ControllerApiHook/UniqIds/Locality/CityKeys';
import { GET_STATE } from '../../../ControllerApiHook/UniqIds/Locality/StateKeys';
import { GET_COUNTRY } from '../../../ControllerApiHook/UniqIds/Locality/CountryKeys';
import { UppercaseFirstLetter } from '../../../Utils/CapitalFirstLetterUtils';
import { GET_DOCUMENT } from '../../../ControllerApiHook/UniqIds/People/DocumentKeys';
import { ContactIcons } from '../../../Utils/ContactsIcon';
import { formatPhoneNumber } from 'react-phone-number-input';
import { numberPhoneType } from '../../../Data/Interfaces/model/cellPhoneEnum';
import { useTranslation } from 'react-i18next';
import { useControllerQueryApiHook } from '@/ControllerApiHook/Controller';
import { DocumentAPI } from '@/Data/API/People/DocumentApi';
import { IListDocumentData } from '@/Data/Interfaces/response/Document/IDocumentResponse';
import { IGetCityResponse } from '@/Data/Interfaces/response/Locality/ICityResponse';
import { CityAPI } from '@/Data/API/Locality/CityApi';
import { CountryAPI } from '@/Data/API/Locality/CountryApi';
import { IGetCountryResponse } from '@/Data/Interfaces/response/Locality/ICountryResponse';
import { IGetStateResponse } from '@/Data/Interfaces/response/Locality/IStateResponse';
import { StateAPI } from '@/Data/API/Locality/StateApi';

export const renderCard = (
  type: AdderListType | undefined,
  index: number,
  item: ItemsType,
  favoriteIndex: number | undefined,
  column: ColumnType[],
  createType: boolean
) => {
  switch (type) {
    case 'contactType':
      return createType ? (
        <CreateContactCardTransfers
          itemIndex={index}
          item={item}
          column={column}
          favoriteIndex={favoriteIndex}
        />
      ) : (
        <ContactCardTransfers
          itemIndex={index}
          item={item}
          favoriteIndex={favoriteIndex}
        />
      );
    case 'adrressType':
      return createType ? (
        <CreateAddressCardTransfers
          itemIndex={index}
          item={item}
          column={column}
          favoriteIndex={favoriteIndex}
        />
      ) : (
        <AddressCardTransfers
          itemIndex={index}
          item={item}
          favoriteIndex={favoriteIndex}
        />
      );
    case 'documentType':
      return createType ? (
        <CreateDocumentCardTransfers
          itemIndex={index}
          item={item}
          column={column}
          favoriteIndex={favoriteIndex}
        />
      ) : (
        <DocumentCardTransfers item={item} column={column} />
      );
    default:
      return (
        <CardTransfers
          itemIndex={index}
          item={item}
          column={column}
          favoriteIndex={favoriteIndex}
        />
      );
  }
};

interface ICardTransfers {
  itemIndex: number;
  item: ItemsType;
  favoriteIndex?: number;
  column: ColumnType[];
}

const CardTransfers: FC<ICardTransfers> = ({
  column,
  favoriteIndex,
  itemIndex,
  item,
}) => (
  <div>
    <Row gutter={[16, 0]}>
      {column?.map((column, collumIndex) => (
        <Col span={column.width} key={column.label}>
          {item.description && (
            <Text
              type={
                favoriteIndex === itemIndex ? 'ui-tiny-bold' : 'ui-tiny-content'
              }
              children={
                typeof item.description[collumIndex] === 'string'
                  ? item.description[collumIndex]
                  : item.description[collumIndex]?.value
              }
              favoriteIcon={favoriteIndex === itemIndex && collumIndex === 0}
            />
          )}
        </Col>
      ))}
    </Row>
  </div>
);

const CreateContactCardTransfers: FC<ICardTransfers> = ({
  column,
  favoriteIndex,
  itemIndex,
  item,
}) => {
  const { t } = useTranslation();

  const CollumDescription = useCallback(
    (collumIndex: number) => {
      if (collumIndex === 0) {
        return t(`contact.descriptions.${ContactIcons[item?.description[0]]}`);
      } else if (collumIndex === 1) {
        if (
          item.values.tipoContatoId === numberPhoneType.phoneId ||
          item.values.tipoContatoId === numberPhoneType.cellPhoneId ||
          item.values.tipoContatoId === numberPhoneType.mediaSocialCellPhoneId
        ) {
          return formatPhoneNumber(item.description[collumIndex]);
        } else {
          return item.description[collumIndex];
        }
      } else if (collumIndex === 2) {
        return item.description[collumIndex];
      }
      return null;
    },
    [item.description, item.values.tipoContatoId, t]
  );

  return (
    <div>
      <Row gutter={[16, 0]}>
        {column?.map((column, collumIndex) => (
          <Col
            span={column.width}
            key={column.label}
            style={{ display: 'flex', alignItems: 'center' }}
          >
            {collumIndex === 0 && (
              <Icon
                icon={ContactIcons[item?.description[0]]}
                style={{ marginRight: 6 }}
                size="M"
                color="text-300"
              />
            )}
            {item.description && (
              <Text
                type={
                  favoriteIndex === itemIndex
                    ? 'ui-tiny-bold'
                    : 'ui-tiny-content'
                }
                children={CollumDescription(collumIndex)}
                favoriteIcon={favoriteIndex === itemIndex && collumIndex === 0}
                color="text-50"
              />
            )}
          </Col>
        ))}
      </Row>
    </div>
  );
};

const ContactCardTransfers: FC<Omit<ICardTransfers, 'column'>> = ({
  favoriteIndex,
  itemIndex,
  item,
}) => {
  const { t } = useTranslation();
  return item.description.length === 3 ? (
    <div>
      <Row style={{ alignItems: 'baseline' }}>
        <Col>
          <Icon
            icon={ContactIcons[item?.description[0]]}
            style={{ margin: '0 12px 6px 0' }}
            size="S"
            color={item.favorite ? 'text-50' : 'text-300'}
          />
        </Col>
        <Col>
          <Text
            type={
              favoriteIndex === itemIndex ? 'ui-tiny-bold' : 'ui-tiny-content'
            }
            children={
              item.values.tipoContatoId === numberPhoneType.phoneId ||
              item.values.tipoContatoId === numberPhoneType.cellPhoneId ||
              item.values.tipoContatoId ===
                numberPhoneType.mediaSocialCellPhoneId
                ? formatPhoneNumber(item?.values.identificacao)
                : item?.values.identificacao
            }
            favoriteIcon={favoriteIndex === itemIndex}
            color="text-50"
          />
        </Col>
      </Row>
      {item.values.observacao && (
        <Row>
          <Col>
            <Text
              type={'ui-tiny-content'}
              color="text-300"
              style={{ fontStyle: 'italic' }}
              children={`${t('common.observation')} ${item.values.observacao}`}
            />
          </Col>
        </Row>
      )}
    </div>
  ) : (
    <></>
  );
};

const CreateAddressCardTransfers: FC<ICardTransfers> = ({
  column,
  favoriteIndex,
  itemIndex,
  item,
}) => {
  const { data: city } = useControllerQueryApiHook<IGetCityResponse>({
    uniqId: GET_CITY,
    entityApi: CityAPI.getCity,
    externalIds: [item.values.cidadeExternalId],
  });

  const { data: state } = useControllerQueryApiHook<IGetStateResponse>({
    uniqId: GET_STATE,
    entityApi: StateAPI.getState,
    externalIds: [item.values.estadoExternalId],
  });

  const { data: country } = useControllerQueryApiHook<IGetCountryResponse>({
    uniqId: GET_COUNTRY,
    entityApi: CountryAPI.getCountry,
    externalIds: [item.values.paisExternalId],
  });

  return (
    <div>
      <Row gutter={[16, 0]}>
        {column?.map((column, collumIndex) => (
          <Col
            span={column.width}
            key={column.label}
            style={{ display: 'flex' }}
          >
            {item.values && collumIndex === 0 && (
              <Text
                type={
                  favoriteIndex === itemIndex
                    ? 'ui-tiny-bold'
                    : 'ui-tiny-content'
                }
                children={item.values.descricao}
                favoriteIcon={favoriteIndex === itemIndex}
                color="text-50"
              />
            )}
            {item.values && collumIndex === 1 && (
              <Text
                type={
                  favoriteIndex === itemIndex
                    ? 'ui-tiny-bold'
                    : 'ui-tiny-content'
                }
                children={`${
                  item.values.logradouro
                    ? `${UppercaseFirstLetter(item.values.logradouro)}, `
                    : ''
                }${
                  item.values.numero
                    ? `${UppercaseFirstLetter(item.values.numero)}, `
                    : ''
                }${
                  item.values.complemento
                    ? `${UppercaseFirstLetter(item.values.complemento)}, `
                    : ''
                }${
                  item.values.bairro
                    ? `${UppercaseFirstLetter(item.values.bairro)}, `
                    : ''
                }${city ? `${UppercaseFirstLetter(city?.descricao)}, ` : ''}${
                  state ? `${UppercaseFirstLetter(state?.descricao)}, ` : ''
                }${
                  country ? `${UppercaseFirstLetter(country?.descricao)}` : ''
                }.`}
                color="text-50"
              />
            )}
          </Col>
        ))}
      </Row>
    </div>
  );
};

const AddressCardTransfers: FC<Omit<ICardTransfers, 'column'>> = ({
  favoriteIndex,
  itemIndex,
  item,
}) => {
  const description = useMemo(() => lineDescription(item), [item]);
  const { t } = useTranslation();
  return (
    <div>
      <Row>
        <Col>
          {item.description[0] && (
            <Text
              type={
                favoriteIndex === itemIndex ? 'ui-tiny-bold' : 'ui-tiny-content'
              }
              children={
                item.description[0][0]?.toUpperCase() +
                item.description[0]?.substring(1)
              }
              favoriteIcon={favoriteIndex === itemIndex}
              color={favoriteIndex === itemIndex ? 'text-50' : 'text-300'}
            />
          )}
        </Col>
      </Row>
      <Row>
        <Col>
          <Text
            type={
              favoriteIndex === itemIndex ? 'ui-tiny-bold' : 'ui-tiny-content'
            }
            color={description ? 'text-50' : 'text-200'}
            children={description ? description : t('address.noAddress')}
          />
        </Col>
      </Row>
    </div>
  );
};

const lineDescription = (item: ItemsType) => {
  const description = [
    `${UppercaseFirstLetter(item.values.logradouro)}`,
    `${item.values.numero}`,
    `${UppercaseFirstLetter(item.values.bairro)}`,
    `${UppercaseFirstLetter(item.values.cidadeDescricao)}`,
    `${UppercaseFirstLetter(item.values.estadoDescricao)}`,
    `${UppercaseFirstLetter(item.values.paisDescricao)}`,
  ];

  return `${description
    .filter((x) => x !== 'null' && x !== 'undefined')
    .join(', ')}${
    item.description.filter((x) => x !== null).length > 1 ? '.' : ''
  }`;
};

const CreateDocumentCardTransfers: FC<ICardTransfers> = ({ column, item }) => {
  const { data: documentType } = useControllerQueryApiHook<IListDocumentData>({
    uniqId: GET_DOCUMENT,
    entityApi: DocumentAPI.getDocument,
    externalIds:
      typeof item.values.tipoDocumentoId === 'object'
        ? item.values.tipoDocumentoId.id
        : item.values.tipoDocumentoId,
  });

  return (
    <div>
      <Row gutter={[16, 0]}>
        {column?.map((column, collumIndex) => (
          <Col
            span={column.width}
            key={column.label}
            style={{ display: 'flex' }}
          >
            {item.description && (
              <Text
                type={'ui-tiny-content'}
                children={
                  collumIndex === 0
                    ? documentType?.nome
                    : item.description[collumIndex]
                }
                color="text-50"
              />
            )}
          </Col>
        ))}
      </Row>
    </div>
  );
};
const DocumentCardTransfers: FC<
  Omit<ICardTransfers, 'favoriteIndex' | 'itemIndex'>
> = ({ item, column }) => {
  const { t } = useTranslation();

  const { data: documentType } = useControllerQueryApiHook<IListDocumentData>({
    uniqId: GET_DOCUMENT,
    entityApi: DocumentAPI.getDocument,
    externalIds: [item?.values?.tipoDocumentoId],
  });

  return (
    <div>
      <Row>
        <Col>
          {column?.map((x, collumIndex) => (
            <div key={x.key}>
              <Text
                type="ui-tiny-content"
                children={
                  collumIndex === 0
                    ? documentType?.nome
                    : collumIndex === 2
                    ? item.values.observacao &&
                      `${t('common.observation')} ${
                        item.description[collumIndex]
                      }`
                    : item.description[collumIndex]
                }
                color={
                  collumIndex === 0
                    ? 'text-200'
                    : collumIndex === 1
                    ? 'text-50'
                    : 'text-300'
                }
              />
            </div>
          ))}
        </Col>
      </Row>
    </div>
  );
};
