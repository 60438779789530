import React from 'react';
import { useTranslation } from 'react-i18next';
import { HorizontalTabs } from '@/Components';
import { PageLoading } from '@/Layouts/PageLoading';
import { DeleteSubGroupModal } from './Modal/DeleteSubGroupModal';
import { useEditStates } from './Hooks/useEditStates';
import { useEditCallAPI } from './Hooks/useEditCallAPI';
import { useEditMemorizations } from './Hooks/useEditMemorizations';
import { useEditFunctions } from './Hooks/useEditFunctions';

export const EditSubGroup: React.FC = () => {
  const { t } = useTranslation();

  const {
    modalDeleteSubGroup,
    setModalDeleteSubGroup,
    disabled,
    setDisabled
  } = useEditStates();

  const {
    data,
    isLoading
  } = useEditCallAPI();

  const {
    items,
    dropdownItems,
    rightButton,
    leftButton
  } = useEditMemorizations({
    disabled,
    setDisabled,
    setModalDeleteSubGroup,
    data
  });

  const {
    onCancelClick,
    onOkClick
  } = useEditFunctions({ setModalDeleteSubGroup });

  return (
    <PageLoading loading={isLoading}>
      <HorizontalTabs
        type="secondary"
        items={items}
        dropdownItens={dropdownItems}
        dropdownDirection="bottomRight"
        dropdownTitle={t('admin.editUserPage.tab.buttonActions.title')}
        leftIcon="menu-ellipsis-horizontal"
        rightButton={rightButton}
        leftButton={leftButton}
      />
      {data?.externalId ? (
        <DeleteSubGroupModal
          productSubGroup={data}
          onCancelClick={onCancelClick}
          onOkClick={onOkClick}
          visible={modalDeleteSubGroup}
        />
      ) : null}
    </PageLoading>
  );
};
