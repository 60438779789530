export const documentEN = {
  addDocument: 'Add document',
  editDocument: 'Edit document',

  documentType: 'Document Type',

  document: 'Document',
  documents: 'Documents',

  observation: 'Observations',

  deleteTitleQuestion: 'Delete document?',
  deleteQuestion: 'Are you sure you want to delete the document {{documento}}?',

  modal: {
    documentPlaceholder: 'Select a document type',

    observationPlaceholder: 'Add a note...',
  },
  documentEmpyt: 'No registered documents',

  areYouSureDelete: 'Are you sure you want to delete this document?',
  messages: {
    createDocument: 'Document created!',
    changeDocument: 'Document edited!',
    deleteDocument: 'Document deleted!',
  },
};

export const documentPT = {
  addDocument: 'Adicionar documento',
  editDocument: 'Editar documento',

  documentType: 'Tipo de Documento',

  document: 'Documento',
  documents: 'Documentos',

  observation: 'Observações',

  deleteTitleQuestion: 'Excluir documento?',
  deleteQuestion:
    'Você tem certeza que deseja excluir o documento {{documento}}?',

  modal: {
    documentPlaceholder: 'Selecione um tipo de documento',

    observationPlaceholder: 'Adicione uma observação...',
  },
  documentEmpyt: 'Nenhum documento cadastrado',

  areYouSureDelete: 'Você tem certeza que deseja excluir este documento?',
  messages: {
    createDocument: 'Documento criado!',
    changeDocument: 'Documento editado!',
    deleteDocument: 'Documento deletado!',
  },
};
