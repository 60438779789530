import { useControllerQueryListApiHook } from '@/ControllerApiHook/Controller';
import { CapsuleTypeAPI } from '@/Data/API/Production/CapsuleType';
import { FETCH_LIST_DROPDOWN_CAPSULE_TYPE } from '@/ControllerApiHook/UniqIds/Production/CapsuleTypeKeys';

export const useCapsuleTypeCallAPI = () => {
  const {
    data: listCapsuleType,
    isLoading: isLoadingCapsuleType,
    fetchNewPage,
  } = useControllerQueryListApiHook({
    uniqId: FETCH_LIST_DROPDOWN_CAPSULE_TYPE,
    entityApi: CapsuleTypeAPI.listDropdownCapsuleType,
    autoIncrement: true,
  });

  return {
    listCapsuleType,
    isLoadingCapsuleType,
    fetchNewPage,
  };
};
