import { QueryFunctionContext } from '@tanstack/react-query';
import { Http, IQueryListParams } from '../../../Utils/Http';
import {
  IListDocumentData,
  IListDocumentResponse,
} from '../../Interfaces/response/Document/IDocumentResponse';

export class DocumentAPI {
  public static async listDocument(
    context: QueryFunctionContext
  ): Promise<IListDocumentResponse> {
    return await Http.fetcher<IListDocumentResponse>(
      Http.AddQueryParams(
        '/pharma/pessoa/v1/TipoDocumento/Listar',
        context.queryKey[1] as IQueryListParams
      )
    );
  }
  public static async getDocument(
    context: QueryFunctionContext
  ): Promise<IListDocumentData | null> {
    if (!context?.queryKey[1]) return null;
    return await Http.fetcher<IListDocumentData>(
      `/pharma/pessoa/v1/TipoDocumento/Obter/${context.queryKey[1]}`
    );
  }
}
