interface IFormatDataToDropdownUtils {
  data: any[];
  labelKey: string;
  idKey: string;
  subLabelKey?: string;
  addIndexAsPrefix?: boolean;
  prefixToSubLabel?: string;
}

export const FormatDataToDropdownUtils = ({
  data,
  idKey,
  labelKey,
  subLabelKey,
  addIndexAsPrefix,
  prefixToSubLabel,
}: IFormatDataToDropdownUtils) => {
  return data.map((item, index) => {
    let subLabel = subLabelKey ? `${item[subLabelKey]}` : '';
    if (prefixToSubLabel) {
      subLabel = `${prefixToSubLabel}${subLabel}`;
    }

    return {
      label: addIndexAsPrefix ? `${index} - ${item[labelKey]}` : item[labelKey],
      id: item[idKey],
      subLabel,
    };
  });
};
