import { useControllerQueryListApiHook } from '@/ControllerApiHook/Controller';
import { FETCH_SIMPLE_LIST_PRESCRIBERS } from '@/ControllerApiHook/UniqIds/People/PrescribersKeys';
import { PrescribersAPI } from '@/Data/API/People/PrescribersApi';

export const useBodyCallAPI = () => {
  const {
    data: prescriberList,
    fetchNewPage,
    refetch,
    isLoading,
  } = useControllerQueryListApiHook({
    uniqId: FETCH_SIMPLE_LIST_PRESCRIBERS,
    entityApi: PrescribersAPI.listPrescribersForDropdown,
    autoIncrement: true,
    pagination: {
      sorter: { column: 'nomeCompleto', direction: 'DESC' },
    },
  });
  return { prescriberList, fetchNewPage, refetch, isLoading };
};
