import { QueryFunctionContext } from '@tanstack/react-query';
import { Http, IQueryListParams } from '../../../Utils/Http';
import {
  IGetSpecialtiesResponse,
  IListSpecialtiesResponse,
} from '../../Interfaces/response/Specialties/ISpecialtiesResponse';
import {
  IChangeSpecialtiesStatusRequest,
  ICreateSpecialtiesRequest,
  IDeleteSpecialtiesRequest,
  IEditSpecialtieRequest,
  IValidationSpecialtiesNameRequest,
} from '../../Interfaces/request/Specialties/ISpecialtiesRequest';
import { IBaseResponse } from '../../Interfaces/response/Base/IBase';

export class SpecialtiesAPI {
  public static async createSpecialties(body: ICreateSpecialtiesRequest) {
    return await Http.fetcher('/pharma/pessoa/v1/EspecialidadePrescritor', {
      method: 'POST',
      body: JSON.stringify(body),
    });
  }

  public static async editSpecialtie(body: IEditSpecialtieRequest) {
    return await Http.fetcher('/pharma/pessoa/v1/EspecialidadePrescritor', {
      method: 'PUT',
      body: JSON.stringify(body),
    });
  }

  public static async changeSpecialtiesStatus(
    body: IChangeSpecialtiesStatusRequest
  ) {
    return await Http.fetcher(
      '/pharma/pessoa/v1/EspecialidadePrescritor/AtualizarStatus',
      {
        method: 'PUT',
        body: JSON.stringify(body),
      }
    );
  }
  public static async deleteSpecialties(body: IDeleteSpecialtiesRequest) {
    return await Http.fetcher('/pharma/pessoa/v1/EspecialidadePrescritor', {
      method: 'DELETE',
      body: JSON.stringify(body),
    });
  }
  public static async validationSpecialtiesName(
    body: IValidationSpecialtiesNameRequest
  ): Promise<IBaseResponse<any>> {
    return await Http.fetcher(
      '/pharma/pessoa/v1/EspecialidadePrescritor/ValidarDescricao',
      {
        method: 'POST',
        body: JSON.stringify(body),
      }
    );
  }

  public static async listSpecialties(
    context: QueryFunctionContext
  ): Promise<IListSpecialtiesResponse> {
    return await Http.fetcher<IListSpecialtiesResponse>(
      Http.AddQueryParams(
        '/pharma/pessoa/v1/EspecialidadePrescritor/ListarDetalhado',
        context.queryKey[1] as IQueryListParams
      )
    );
  }
  public static async listSpecialtiesForDropdown(
    context: QueryFunctionContext
  ): Promise<IListSpecialtiesResponse> {
    return await Http.fetcher<IListSpecialtiesResponse>(
      Http.AddQueryParams(
        '/pharma/pessoa/v1/EspecialidadePrescritor/Listar',
        context.queryKey[1] as IQueryListParams
      )
    );
  }
  public static async getSpecialties(
    context: QueryFunctionContext
  ): Promise<IGetSpecialtiesResponse | null> {
    if (!context.queryKey[1]) return null;
    return await Http.fetcher<IGetSpecialtiesResponse>(
      `/pharma/pessoa/v1/EspecialidadePrescritor/${context.queryKey[1]}`
    );
  }
}
