import { useControllerMutateApiHook } from '@/ControllerApiHook/Controller';
import { ProductAPI } from '@/Data/API/Supply/Product';
import {
  CREATE_SUBGROUP,
  EDIT_SUBGROUP
} from '@/ControllerApiHook/UniqIds/Supply/ProductKeys';

export const useFormSubGroupModalCallAPI = () => {
  const createSubGroup: any = useControllerMutateApiHook({
    uniqId: CREATE_SUBGROUP,
    entityApi: ProductAPI.createSubGroup
  });

  const editSubGroup: any = useControllerMutateApiHook({
    uniqId: EDIT_SUBGROUP,
    entityApi: ProductAPI.editProductSubGroup
  });

  return {
    createSubGroup,
    editSubGroup
  };
};