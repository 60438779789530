import { FC } from "react";
import { Col } from 'antd';
import { useTranslation } from 'react-i18next';
import { IAccumulate } from "./interfaces";
import { Dropdown, Tooltip } from "@/Components";
import { useAccumulateMemorizations } from "./Hooks/useAccumulateMemorizations";
import { useAccumulateFunctions } from "./Hooks/useAccumulateFunctions";

export const Accumulate: FC<IAccumulate> = ({
  accumulate,
  setAccumulate,
  associatedProductData,
  disabledForm
}) => {
  const { t } = useTranslation();

  const {
    items
  } = useAccumulateMemorizations();

  const {
    onChange
  } = useAccumulateFunctions({
    setAccumulate
  });

  return (
    <Col span={3}>
      <Tooltip
        title={`${t('products.associated.originalAcumulate')}${
          associatedProductData?.acumula
            ? t('products.associated.yes')
            : t('products.associated.no')
        }`}
        showMe={
          associatedProductData &&
          associatedProductData.acumula !== accumulate
        }
        targetOffset={[80, -10]}
      >
        <Dropdown
          name="acumula"
          withStatusIcon={!disabledForm}
          items={items}
          label={t('products.associated.acumulate')}
          placeHolder={t('products.associated.select')}
          withTooltip={{
            icon: 'exclamation-upside-down',
            title: t('products.associated.acumulateTooltip'),
          }}
          onChange={onChange}
          disabled={disabledForm}
        />
      </Tooltip>
    </Col>
  );
};