import { QueryFunctionContext } from '@tanstack/react-query';
import { Http, IQueryListParams } from '../../../Utils/Http';
import {
  IPurchaseOrderGetResponse,
  IPurchaseOrderList,
  IPurchaseOrderOpenList,
} from '../../Interfaces/response/Purchase/IPurchaseOrderResponse';
import {
  ICreateOrderPurchaseRequest,
  IDeleteOrderPurchaseRequest,
  ISaveDraftOrderPurchaseRequest,
  IReproveOrderPurchaseRequest,
  IApproveOrderPurchaseRequest,
} from '../../Interfaces/request/OrderPurchase/IOrderPurchaseRequest';

export class PurchaseAPI {
  public static async postOrderPurchase(body: ICreateOrderPurchaseRequest) {
    const res = await Http.fetcher(`/pharma/compras/v1/PedidoCompra`, {
      method: 'POST',
      body: JSON.stringify(body),
    });
    if (!res) return [];
    return res;
  }

  public static async editOrderPurchase(body: ICreateOrderPurchaseRequest) {
    return await Http.fetcher(`/pharma/compras/v1/PedidoCompra`, {
      method: 'PUT',
      body: JSON.stringify(body),
    });
  }

  public static async getPurchaseOrderList(
    context: QueryFunctionContext
  ): Promise<IPurchaseOrderList | null> {
    if (!context.queryKey[1]) return null;

    return await Http.fetcher<IPurchaseOrderList>(
      Http.AddQueryParams(
        `/pharma/compras/v1/PedidoCompra/ListarDetalhado`,
        context.queryKey[1] as IQueryListParams
      )
    );
  }

  public static async deleteOrderPurchase(body: IDeleteOrderPurchaseRequest) {
    const res = await Http.fetcher(`/pharma/compras/v1/PedidoCompra`, {
      method: 'DELETE',
      body: JSON.stringify(body),
    });

    if (!res) return [];
    return res;
  }

  public static async reproveOrderPurchase(body: IReproveOrderPurchaseRequest) {
    const res = await Http.fetcher(`/pharma/compras/v1/PedidoCompra/Reprovar`, {
      method: 'PUT',
      body: JSON.stringify(body),
    });

    if (!res) return [];
    return res;
  }

  public static async reverseConfirmWithSupplierOrderPurchase(
    body: IReproveOrderPurchaseRequest
  ) {
    const res = await Http.fetcher(
      `/pharma/compras/v1/PedidoCompra/EstornarConfirmadoComFornecedor`,
      {
        method: 'PUT',
        body: JSON.stringify(body),
      }
    );

    if (!res) return [];
    return res;
  }

  public static async reverseReleasedOrderPurchase(
    body: IReproveOrderPurchaseRequest
  ) {
    const res = await Http.fetcher(
      `/pharma/compras/v1/PedidoCompra/EstornarLiberado`,
      {
        method: 'PUT',
        body: JSON.stringify(body),
      }
    );

    if (!res) return [];
    return res;
  }

  public static async confirmWithSupplierOrderPurchase(
    body: IReproveOrderPurchaseRequest
  ) {
    const res = await Http.fetcher(
      `/pharma/compras/v1/PedidoCompra/ConfirmarComFornecedor`,
      {
        method: 'PUT',
        body: JSON.stringify(body),
      }
    );

    if (!res) return [];
    return res;
  }

  public static async cancelOrderPurchase(body: IReproveOrderPurchaseRequest) {
    const res = await Http.fetcher(`/pharma/compras/v1/PedidoCompra/Cancelar`, {
      method: 'PUT',
      body: JSON.stringify(body),
    });

    if (!res) return [];
    return res;
  }

  public static async approveOrderPurchase(body: IApproveOrderPurchaseRequest) {
    const res = await Http.fetcher(`/pharma/compras/v1/PedidoCompra/Aprovar`, {
      method: 'PUT',
      body: JSON.stringify(body),
    });

    if (!res) return [];
    return res;
  }

  public static async getOrderPurchase(context: QueryFunctionContext) {
    if (!context.queryKey[1]) return null;
    return await Http.fetcher<IPurchaseOrderGetResponse>(
      `/pharma/compras/v1/PedidoCompra/${context.queryKey[1]}`
    );
  }

  public static async savePostDraftOrderPurchase(
    body: ISaveDraftOrderPurchaseRequest
  ) {
    return await Http.fetcher(`/pharma/compras/v1/PedidoCompra/Rascunho`, {
      method: 'POST',
      body: JSON.stringify(body),
    });
  }

  public static async savePutDraftOrderPurchase(
    body: ISaveDraftOrderPurchaseRequest
  ) {
    return await Http.fetcher(`/pharma/compras/v1/PedidoCompra/Rascunho`, {
      method: 'PUT',
      body: JSON.stringify(body),
    });
  }

  public static async getOpenPurchaseOrderList(context: QueryFunctionContext) {
    const checkContext: any = context.queryKey[1];
    if (!checkContext.filter[0].value) return [];
    return await Http.fetcher<IPurchaseOrderOpenList>(
      Http.AddQueryParams(
        '/pharma/compras/v1/PedidoCompra/ListarEmAberto',
        context.queryKey[1] as IQueryListParams
      )
    );
  }
}
