import { useFormikContext } from "formik";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Divflex } from '@/Layouts/DivWhithFlex';
import ReactMarkdown from 'react-markdown';
import { Text } from '@/Components';
import { IUseMemorizations } from "./interfaces";

export const useReleasedOrderModalMemorizations = ({
  statusPurchaseOrder
}: IUseMemorizations) => {
  const { t } = useTranslation();
  const form: any = useFormikContext();

  const body = useMemo(() => (
    <div style={{ padding: '20px' }}>
      <Divflex
        style={{
          flexDirection: 'column',
        }}
      >
        <Text type="ui-tiny-content">
          <ReactMarkdown>
            {t('purchasing.purchaseOrder.modal.released.description', {
              numeroPedido: `**#${form.values.numeroPedido}**`,
              fornecedor: `**${form.values.nomeFornecedor}**`,
              status: `**${t(
                `purchasing.purchaseOrder.list.statusType.${
                  statusPurchaseOrder === 3 ? 0 : 2
                }`
              )}**`,
            })}
          </ReactMarkdown>
        </Text>
      </Divflex>
    </div>
  ), [form, t, statusPurchaseOrder]);

  return {
    body
  };
};