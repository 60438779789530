import { IProductGroupListData } from "@/Data/Interfaces/response/Product/IProductRespose";
import { useTranslation } from "react-i18next";
import { IUseMemorizations } from "./interfaces";

export const useProductNameMemorizations = ({
  productList
}: IUseMemorizations) => {
  const { t } = useTranslation();

  const items = productList && productList.data ? productList?.data?.map((x: IProductGroupListData) => ({
    id: x?.externalId,
    label: x?.descricao,
    subLabel: `${t(
      'production.standardFormula.details.productLine.productNameSubCode'
    )} ${x.sequenciaGroupTenant}`,
  })) : [];

  return {
    items
  };
};