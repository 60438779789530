import { useCallback } from "react";
import { useQueryClient } from '@tanstack/react-query';
import { 
  IEditFinancialProductRequest 
} from '@/Data/Interfaces/request/Product/IProductRequest';
import {
  FETCH_GET_PRODUCT
} from '@/ControllerApiHook/UniqIds/Supply/ProductKeys';
import { IUseFunctions } from "./interfaces";

export const useFinanceFunctions = ({
  product,
  editFinancialProduct,
  toogleDisabled,
  disabledForm
}: IUseFunctions) => {
  const queryClient = useQueryClient();

  const onSubmit = useCallback(async (values: IEditFinancialProductRequest) => {
    const body: IEditFinancialProductRequest = { ...values };

    if (product) {
      const res = await editFinancialProduct.mutateAsync({
        ...body,
      });
      if (res.success) {
        queryClient.invalidateQueries([FETCH_GET_PRODUCT]);
        toogleDisabled(!disabledForm);
      }
    }
  }, [editFinancialProduct]);

  return {
    onSubmit
  };
};