import { useTranslation } from "react-i18next";

export const useLevelIncompatibleMemorizations = () => {
  const { t } = useTranslation();

  const items = [
    {
      id: 1,
      label: t('products.incompatible.justWarn'),
    },
    {
      id: 2,
      label: t('products.incompatible.passwordLiberation'),
    },
    {
      id: 3,
      label: t('products.incompatible.block'),
    },
  ];

  return {
    items
  };
};