import { useFormikContext } from 'formik';
import { IUseFunctions } from './interfaces';

export const useProductLineFunctions = ({
  index,
  totalRows,
  setProductRowAmount,
  uuidV4,
  onDelete
}: IUseFunctions) => {
  const form: any = useFormikContext();

  const addRow = () => {
    if (index === totalRows - 1) {
      setProductRowAmount((oldState: string[]) => [...oldState, uuidV4()]);
    }
  };

  const deleteRow = () => {
    if (!form.values.produtos) return;
    form.values.produtos = form.values.produtos.filter(
      (_: any, i: number) => i !== index
    );
    if (totalRows === 1) {
      setProductRowAmount((oldState: string[]) => [...oldState, uuidV4()]);
    }
    onDelete(index);
  };

  return {
    addRow,
    deleteRow
  };
};