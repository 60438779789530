import {
  CREATE_PRODUCT_LINKED_MESSAGE,
  CREATE_PRODUCT_MESSAGE_LINKED
} from '@/ControllerApiHook/UniqIds/Supply/ProductKeys';
import { useControllerMutateApiHook } from '@/ControllerApiHook/Controller';
import { ProductAPI } from '@/Data/API/Supply/Product';

export const useLinkedProductMessageModalCallAPI = () => {
  const createProductLinkedMessage: any = useControllerMutateApiHook({
    uniqId: CREATE_PRODUCT_LINKED_MESSAGE,
    entityApi: ProductAPI.createProductlinkedMessage
  });

  const createProductMessageLinked: any = useControllerMutateApiHook({
    uniqId: CREATE_PRODUCT_MESSAGE_LINKED,
    entityApi: ProductAPI.createProductMessageLinked
  });

  return {
    createProductLinkedMessage,
    createProductMessageLinked
  };
};