import { Card } from '@/Components';
import { useTranslation } from 'react-i18next';
import { ProductRow } from './row/ProductRow';
import style from './productSection.module.scss';
import { useControlRows } from './row/hooks/useControlRows';
import { useFormikContext } from 'formik';
import { useEffect } from 'react';

export const ProductSection: React.FC = () => {
  const form: any = useFormikContext();
  const { t } = useTranslation();
  const scrollContainerId = 'scrollContainer';
  const cardId = 'productSection';
  const { addRow, arrayControl, removeRow, pushRow } = useControlRows();

  useEffect(() => {
    if (form.values.itens.length > arrayControl.length) {
      const diff = form.values.itens.length - arrayControl.length;
      Array.from({ length: diff + 1 }).forEach(() => {
        pushRow();
      });
    }
    if (
      form.values.itens.length === arrayControl.length &&
      arrayControl.length !== 1
    ) {
      pushRow();
    }
  }, [form.values.itens]);

  return (
    <Card
      title={t('purchasing.invoice.create.products')}
      withoutCollapse
      id={cardId}
    >
      <div
        id={scrollContainerId}
        style={{ overflowX: 'scroll' }}
        className={style.customScrollbar}
      >
        {arrayControl.map((item, index) => {
          return (
            <ProductRow
              key={item}
              index={index}
              scrollContainerId={scrollContainerId}
              cardId={cardId}
              addRow={addRow}
              removeRow={removeRow}
            />
          );
        })}
      </div>
    </Card>
  );
};
