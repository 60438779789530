import { IListSpecialtiesData } from '@/Data/Interfaces/response/Specialties/ISpecialtiesResponse';
import { IUseMemorizations } from './interfaces';
import { useTranslation } from 'react-i18next';
import { PermissionEnum } from '@/Data/Interfaces/model/permissionEnum';
import { history } from '@/Store';
import { useMemo } from 'react';
import { DeleteModalBody } from '../Modal/DeleteModal';
import { IDeleteModalTable } from '@/Components/Table';

export const useListMemorizations = ({
  setCreateModalOpen,
  selectedRowsOnTable,
}: IUseMemorizations) => {
  const { t } = useTranslation();

  const headerOptions = {
    nameKey: 'descricao',
    hasSearch: true,
    searchPlaceholder: t('specialties.listPage.searchPlaceholder'),
    newButton: {
      label: t('specialties.listPage.newButton'),
      onClick: () => setCreateModalOpen(true),
      permission: {
        permission: PermissionEnum.Vendas_EspecialidadePrescritor_Cadastrar,
        tooltip: t('specialties.permition.newButton'),
      },
    },

    editButton: {
      permission: {
        permission: PermissionEnum.Vendas_EspecialidadePrescritor_AlterarStatus,
        tooltip: t('specialties.permition.editButton'),
      },
      options: [
        {
          label: t('common.status'),
          isStatusOnly: true,
        },
      ],
    },
    deleteButton: {
      permission: {
        permission: PermissionEnum.Vendas_EspecialidadePrescritor_Excluir,
        tooltip: t('specialties.permition.deleteButton'),
      },
    },
  };

  const rowOptions = {
    onRowDoubleClick: (x: IListSpecialtiesData) =>
      history.push(`/sales/expertise/${x.externalId}/details`),
    permission: {
      permission: PermissionEnum.Vendas_EspecialidadePrescritor_VerDetalhes,
      tooltip: t('specialties.permition.viewDetails'),
    },
  };

  const deleteModal: IDeleteModalTable | undefined = useMemo(
    () =>
      selectedRowsOnTable && {
        title:
          selectedRowsOnTable.length === 1
            ? `${t('common.delete')} "${selectedRowsOnTable[0].descricao}"?`
            : `${t('common.delete')} ${selectedRowsOnTable.length} ${t(
                'specialties.specialties'
              )}?`,
        body: <DeleteModalBody specialties={selectedRowsOnTable} />,
        buttons: {
          okButtonColor: 'status-danger-base',
        },
      },
    [selectedRowsOnTable, t]
  );

  return {
    headerOptions,
    rowOptions,
    deleteModal,
  };
};
