import { useCallback } from 'react';
import { DiscountTypes } from '@/Utils/DiscountTypeDefault';
import { IUseFunctions } from './interfaces';

export const useProductLineFunctions = ({
  formValues
}: IUseFunctions) => {
  const getTotalValue = useCallback(
    ({
      newUnitValue,
      newDiscountValue,
      newDiscountType,
      newAmountValue,
    }: {
      newUnitValue?: number;
      newDiscountValue?: number;
      newDiscountType?: number;
      newAmountValue?: number;
    }) => {
      const unitValue = newUnitValue ?? formValues.unitValue;
      const discountValue = newDiscountValue ?? formValues.discountValue ?? 0;
      const discountType =
        newDiscountType ?? formValues.discountType ?? DiscountTypes.currency;
      const amountValue = newAmountValue ?? formValues.amountValue ?? 0;

      if (discountType === DiscountTypes.currency)
        return (unitValue - discountValue) * amountValue;
      else if (discountType === DiscountTypes.porcentage) {
        const discountPorcentageValue = (unitValue / 100) * discountValue;
        return (unitValue - discountPorcentageValue) * amountValue;
      }
      return unitValue * amountValue;
    },
    [formValues]
  );

  return {
    getTotalValue
  };
};