import { FC } from "react";
import { Col } from 'antd';
import {
  InputWithSearch,
} from '@/Components';
import { useTranslation } from "react-i18next";
import { IProduct } from "./interfaces";
import { useProductMemorizations } from "./Hooks/useProductMemorizations";
import { useProductFunctions } from "./Hooks/useProductFunctions";
import { useProductCallAPI } from "./Hooks/useProductCallAPI";

export const Product: FC<IProduct> = ({
  disabledForm,
  onClassTypeSelected
}) => {
  const { t } = useTranslation();

  const {
    productList,
    isProductLoading,
    fetchNewProductPage,
    refetchSearchProductPage
  } = useProductCallAPI();

  const {
    items
  } = useProductMemorizations({ productList });

  const {
    onChange,
    onSearch
  } = useProductFunctions({
    refetchSearchProductPage,
    productList,
    onClassTypeSelected
  });

  return (
    <Col span={7}>
      <InputWithSearch
        name="produtoExternalId"
        label={t('supply.lot.create.product')}
        placeHolder={t('supply.lot.placeholder.product')}
        disabled={disabledForm}
        isLoading={isProductLoading}
        withTooltip={{
          icon: 'exclamation-upside-down',
          title: t('products.create.providerTooltip'),
        }}
        items={items}
        onScrollEnd={fetchNewProductPage}
        onChange={onChange}
        onSearch={onSearch}
        required
      />
    </Col>
  );
};