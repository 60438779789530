import { FC } from 'react';
import { Col } from 'antd';
import { Tooltip, TextInput } from '@/Components';
import { useTranslation } from 'react-i18next';
import { useFormikContext } from 'formik';
import { WeightInput } from '@/Components/Inputs/WeightInput';
import { alternativeUnit2 } from '../../../../../../../../CreateForm/CardsForm/TechnicalInfoCard/utils';
import { IAlternateUnit2 } from './interfaces';

export const AlternateUnit2: FC<IAlternateUnit2> = ({
  lots,
  disabled,
  unidadeAlternativaList,
}) => {
  const { t } = useTranslation();
  const form: any = useFormikContext();
  const utrUnitDefault: number = 9;
  const ufcMiUnitDefault: number = 14;

  return (
    <Col span={6}>
      <Tooltip
        title={`${t('supply.lot.details.toolTips.originalAlternateUnit2')} ${
          lots?.loteInformacaoTecnica?.loteUnidadeAlternativa2
            ?.quantidadeUnidadeAlternativa
        }`}
        showMe={
          lots?.loteInformacaoTecnica?.loteUnidadeAlternativa2
            ?.quantidadeUnidadeAlternativa !==
            form?.values?.loteInformacaoTecnica?.loteUnidadeAlternativa2
              ?.quantidadeUnidadeAlternativa && !disabled
        }
      >
        <WeightInput
          name="loteInformacaoTecnica.loteUnidadeAlternativa2.quantidadeUnidadeAlternativa"
          label={t('supply.lot.details.details.alternativeUnitTwo')}
          placeHolder={t('supply.lot.details.details.alternativeUnitTwo')}
          disabled={disabled}
          dropdownLeft={{
            name: 'loteInformacaoTecnica.loteUnidadeAlternativa2.unidadeAlternativaId',
            defaultValue: utrUnitDefault,
            options:
              unidadeAlternativaList?.data?.map((x) => ({
                id: x.id,
                content: x.unidadeAbreviacao,
              })) || [],
          }}
          dropdownRight={{
            name: 'loteInformacaoTecnica.loteUnidadeAlternativa2.unidadeAlternativaConversaoId',
            defaultValue: ufcMiUnitDefault,
            options:
              alternativeUnit2?.map((x) => ({
                id: x.id,
                content: x.description,
              })) || [],
          }}
          withStatusIcon
        />
      </Tooltip>
    </Col>
  );
};
