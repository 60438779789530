import { FC } from 'react';
import { Col, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import { Checkbox, Tooltip } from '@/Components';
import { useFormikContext } from 'formik';
import SimpleText from '@/Components/Text';
import { Status } from '../../../../../../List/Components/Status';
import { CollumsTitle } from '../TableCollumTitle';
import { useTableProductItemMemorizations } from './Hooks/useTableProductItemMemorizations';
import { IItemsProduct } from './interfaces';

import styles from './TableProductItem.module.scss';

export const TableProductItem: FC<IItemsProduct> = ({
  index,
  withSelectedRows
}) => {
  const { t } = useTranslation();
  const form: any = useFormikContext();

  const {
    statusProductOrder
  } = useTableProductItemMemorizations({ index });

  return (
    <div>
      {index === 0 && <CollumsTitle t={t} />}
      <Row
        gutter={[16, 0]}
        className={`${styles['row-products-table']} ${
          withSelectedRows ? styles['rows'] : ''
        }`}
      >
        <Col span={7} className={styles['first-collum']}>
          {!withSelectedRows ? (
            <Tooltip
              title={t(
                `purchasing.purchaseOrder.list.statusDescriptionTooltips.${
                  statusProductOrder - 1
                }`
              )}
              showMe
              placement="top"
              targetOffset={[5, -18]}
            >
              <Status
                descriptionType="ui-tiny-bold"
                typePurchase={statusProductOrder}
                withoutDescription
              />
            </Tooltip>
          ) : (
            <Checkbox
              name={`pedidoCompraItensExternalId.${index}`}
              value={
                form?.values?.pedidoCompraItens?.[index]
                  ?.pedidoCompraItemExternalId
              }
            />
          )}

          <Tooltip
            title={form?.values?.pedidoCompraItens?.[index]?.descricaoRotulo}
            showMe={!withSelectedRows}
            placement="top"
            targetOffset={[5, -20]}
          >
            <SimpleText
              className={styles[`status-${statusProductOrder}`]}
              name={`descricao.${index}`}
              type="ui-tiny-bold"
            />
          </Tooltip>
        </Col>
        <Col span={5}>
          <SimpleText
            className={styles[`status-${statusProductOrder}`]}
            name={`precoUnitario.${index}`}
            type="ui-tiny-content"
          />
        </Col>
        <Col span={4}>
          <SimpleText
            className={styles[`status-${statusProductOrder}`]}
            name={`valorDescontoUnitario.${index}`}
            type="ui-tiny-content"
          />
        </Col>
        <Col span={4}>
          <SimpleText
            className={styles[`status-${statusProductOrder}`]}
            name={`quantidade.${index}`}
            type="ui-tiny-content"
          />
        </Col>
        <Col span={4}>
          <SimpleText
            className={styles[`status-${statusProductOrder}`]}
            name={`precoTotalLiquido.${index}`}
            type="ui-tiny-content"
          />
        </Col>
      </Row>
    </div>
  );
};
