import { useControllerQueryApiHook } from '@/ControllerApiHook/Controller';
import {
  GetDocumentProvider,
  IGetDocumentProviderResponse,
} from '@/Services/People/Supplier/GetDocumentProvider.service';

export const GET_DOCUMENT_PROVIDER = '[PROVIDER] GET_DOCUMENT_PROVIDER';

interface IUserGetDocumentProvider {
  externalIdProvider: string[];
}

export const useGetDocumentProvider = ({
  externalIdProvider = [],
}: IUserGetDocumentProvider) => {
  const queryResult = useControllerQueryApiHook<IGetDocumentProviderResponse>({
    uniqId: GET_DOCUMENT_PROVIDER,
    entityApi: GetDocumentProvider,
    externalIds: externalIdProvider,
    enabled: !!externalIdProvider.length,
  });

  return queryResult;
};
