import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Switch } from '../../../../Components/StateInputs/Switch';
import { TableLayout } from '../../../../Components/Tabs/TablePreference';
import StorageUtils from '../../../../Utils/StorageUtils';
import { useQueryClient } from '@tanstack/react-query';
import { useControllerMutateApiHook } from '@/ControllerApiHook/Controller';
import {
  CHANGE_USER_PREFERENCES,
  GET_ME,
} from '@/ControllerApiHook/UniqIds/Accountmanager/UserKeys';
import { UserAPI } from '@/Data/API/Accountmanager/UserApi';

export const Accessibility: FC = () => {
  const preferences = StorageUtils.getPreference();

  const [switchText, setSwitchText] = useState<boolean>(
    preferences?.textoAmpliado || false
  );
  const [switchBold, setSwitchBold] = useState<boolean>(
    preferences?.textoNegrito || false
  );

  const queryClient = useQueryClient();

  const { t } = useTranslation();

  const changePreference = useControllerMutateApiHook({
    uniqId: CHANGE_USER_PREFERENCES,
    entityApi: UserAPI.patchCurrentUserPreference,
    options: { onSuccess: () => queryClient.invalidateQueries([GET_ME]) },
  });

  return (
    <TableLayout
      title={t('preferences.accessibilityPage.title')}
      description={t('preferences.accessibilityPage.description')}
      tableConfig={[
        {
          head: t('preferences.accessibilityPage.amplifyText'),
          body: [
            {
              type: 'paragraph2-bold',
              color: 'text-200',
              children: t('preferences.accessibilityPage.amplifyBody'),
            },
          ],
          rightItem: (
            <Switch
              onChange={async (check) => {
                setSwitchText(check);
                await changePreference.mutateAsync({ textoAmpliado: check });
              }}
              checked={switchText}
            />
          ),
          widthRightItem: 143,
        },
        {
          head: t('preferences.accessibilityPage.boldText'),
          body: [
            {
              type: 'paragraph2-bold',
              color: 'text-200',
              children: t('preferences.accessibilityPage.boldBody'),
            },
          ],
          rightItem: (
            <Switch
              onChange={async (check) => {
                setSwitchBold(check);
                await changePreference.mutateAsync({ textoNegrito: check });
              }}
              checked={switchBold}
            />
          ),
          widthRightItem: 143,
        },
      ]}
    />
  );
};
