import { useState } from 'react';

export const useCreateStates = () => {
  const [selectedOptionsStock, setSelectedOptionsStock] = useState<number>(1);

  return {
    selectedOptionsStock,
    setSelectedOptionsStock,
  };
};
