import { CreatePurchase } from '@/Pages/Sectors/Order/Purchase/Create';
import { PurchaseDetails } from '@/Pages/Sectors/Order/Purchase/Details';
import { EditPurchase } from '@/Pages/Sectors/Order/Purchase/Edit/EditOrder';
import PurchaseList from '@/Pages/Sectors/Order/Purchase/List';
import { Route, Switch } from 'react-router-dom';

export const PurchaseRoutes = () => (
  <Switch>
    <Route
      path="/order/purchase/create/:externalId/draft"
      component={CreatePurchase}
    />
    <Route path="/order/purchase/:externalId/edit" component={EditPurchase} />
    <Route
      path="/order/purchase/:externalId/:tab"
      component={PurchaseDetails}
    />
    <Route path="/order/purchase/create" component={CreatePurchase} />
    <Route path="/order/purchase" component={PurchaseList} />
  </Switch>
);
