import { useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';
import { IUseFunctions } from './interfaces';

export const useInputDescriptionSubGroupFunctions = ({
  setIsErrorValidadeDescription,
}: IUseFunctions) => {
  const form: any = useFormikContext();
  const { t } = useTranslation();

  const onGroupValidationError = () => {
    form?.setErrors({
      ...form.errors,
      descricao: t('products.create.errors.errorSubGroupExist'),
    });
    setIsErrorValidadeDescription(true);
  };

  const onSuccessValidationError = () => {
    form?.setErrors({
      ...form.errors,
      descricao: undefined,
    });
    setIsErrorValidadeDescription(false);
  };

  return {
    onGroupValidationError,
    onSuccessValidationError,
  };
};
