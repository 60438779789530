import { GET_PRESCRIBER } from '@/ControllerApiHook/UniqIds/People/PrescribersKeys';
import { useParams } from 'react-router';
import { PrescribersAPI } from '@/Data/API/People/PrescribersApi';
import { useControllerQueryApiHook } from '@/ControllerApiHook/Controller';

export const useEditCallAPI = () => {
  const { externalId } = useParams<{ externalId: string }>();

  const { data: prescriber }: any = useControllerQueryApiHook({
    uniqId: GET_PRESCRIBER,
    entityApi: PrescribersAPI.getPrescribers,
    externalIds: [externalId],
  });

  return {
    prescriber,
  };
};
