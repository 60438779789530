import { ILoginRequest } from '@/Data/Interfaces/request/Auth/ILoginRequest';
import StorageUtils from '@/Utils/StorageUtils';
import { FormikHelpers, FormikValues } from 'formik';
import { useCallback } from 'react';
import { IUseLoginFunctions } from './interfaces';

export const useLoginFunctions = ({ authLogin }: IUseLoginFunctions) => {
  const onSubmit = useCallback(
    async (values: ILoginRequest, helpers: FormikHelpers<FormikValues>) => {
      try {
        const body: ILoginRequest = { ...values };

        await authLogin.mutateAsync(body);

        StorageUtils.SetRememberMe(body?.rememberMe === true);
      } finally {
        helpers.setSubmitting(false);
      }
    },
    [authLogin]
  );

  return { onSubmit };
};
