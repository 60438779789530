import { useControllerMutateApiHook } from '@/ControllerApiHook/Controller';
import { CREATE_USER_TYPE } from '@/ControllerApiHook/UniqIds/Accountmanager/UserKeys';
import { UserAPI } from '@/Data/API/Accountmanager/UserApi';
import { history } from '@/Store';

export const useCreateUserCallAPI = () => {
  const createUser = useControllerMutateApiHook({
    uniqId: CREATE_USER_TYPE,
    entityApi: UserAPI.createUser,
    options: { onSuccess: () => history.push('/admin/user') },
  });

  return {
    createUser,
  };
};
