import React, { useEffect, useState } from 'react';
import { Table } from '../../../../../Components';
import { history } from '../../../../../Store';
import { useTranslation } from 'react-i18next';
import { conglomerateCollums } from './conglomerate.columns';
import { Modal } from '../../../../../Components/Modal';
import { DeleteModalBody, getDeleteModalTitle } from './Modals/DeleteModal';
import StorageUtils from '@/Utils/StorageUtils';
import { useControllerQueryListApiHook } from '@/ControllerApiHook/Controller';
import { LIST_CONGLOMERATE_ADMIN_TYPE } from '@/ControllerApiHook/UniqIds/Accountmanager/ConglomerateKeys';
import { ConglomerateAPI } from '@/Data/API/Accountmanager/ConglomerateApi';

import './Styles/List.override.scss';

export const List: React.FC = () => {
  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
  const [hasNoCompany, setHasNoCompany] = useState(false);
  const [selectedRowsOnTable, setSelectedRowsOnTable] = useState<any>([]);

  const { t } = useTranslation();

  const { data, isLoading, refetch } = useControllerQueryListApiHook({
    uniqId: LIST_CONGLOMERATE_ADMIN_TYPE,
    entityApi: ConglomerateAPI.listConglomerate,
    pagination: {
      sorter: { column: 'nome', direction: 'ASC' },
    },
    autoIncrement: true,
  });

  const preferences = StorageUtils.getPreference();

  useEffect(
    () =>
      setHasNoCompany(
        selectedRowsOnTable?.some((row: any) => row.empresas === 0)
      ),
    [selectedRowsOnTable]
  );

  return (
    <>
      <Table
        columns={conglomerateCollums(
          t,
          preferences?.padraoData || 'dd/MM/yyyy'
        )}
        headerOptions={{
          hasSearch: true,
          searchPlaceholder: t(
            'adminBootis.conglomerateList.searchPlaceholder'
          ),
          nameKey: 'Nome',
        }}
        loading={isLoading}
        onRefetch={refetch}
        pagination={data}
        rowsOptions={{
          onRowDoubleClick: (x) =>
            history.push(`/admin-bootis/conglomerate/${x.externalId}/details`),
        }}
        selectedRowsOnTable={setSelectedRowsOnTable}
        dataTestId="admin-bootis-conglomerate"
      />
      <Modal
        visible={isDeleteModalOpen}
        onCancelClick={() => setDeleteModalOpen(false)}
        onClose={() => setDeleteModalOpen(false)}
        title={getDeleteModalTitle(t, selectedRowsOnTable, hasNoCompany)}
        okButtonColor={hasNoCompany ? 'status-danger-base' : undefined}
        okButtonName={
          hasNoCompany
            ? t('common.delete')
            : t('adminBootis.companyList.deleteModal.delete')
        }
        body={
          <DeleteModalBody
            rows={selectedRowsOnTable}
            hasCompany={!hasNoCompany}
          />
        }
        withoutOkButton={hasNoCompany}
      />
    </>
  );
};
