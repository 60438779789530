import { TFunction } from 'i18next';
import { Text } from '@/Components';

export const productIncompatibleColumns = (t: TFunction) => {
  const incompabilityLevel = [
    {
      id: 1,
      label: t('products.incompatible.justWarn'),
    },
    {
      id: 2,
      label: t('products.incompatible.passwordLiberation'),
    },
    {
      id: 3,
      label: t('products.incompatible.block'),
    },
  ];

  return [
    {
      title: t('products.incompatible.incompatibleProduct'),
      dataIndex: 'produtoIncompativelDescricao',
      key: 'produtoIncompativelDescricao',
      sortColumn: 'produtoIncompativelDescricao',
      sorter: true,
      defaultSortOrder: 'ascend',
      isHighlightable: true,
      width: '75%',
      render: (x: string, row: any) => {
        return (
          <Text type="ui-tiny-bold" color="text-50">
            {row.produtoIncompativelDescricao}
          </Text>
        );
      },
    },
    {
      title: t('products.incompatible.levelIncompatibility'),
      dataIndex: 'nivelIncompatibilidadeDescricao',
      key: 'nivelIncompatibilidadeDescricao',
      isHighlightable: true,
      width: '20%',
      render: (x: string, row: any) => (
        <Text type="ui-tiny-bold" color="text-50">
          {
            incompabilityLevel.filter(
              (e) => e.id === row.nivelIncompatibilidade
            )[0].label
          }
        </Text>
      ),
    },
  ];
};
