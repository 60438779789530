import React from 'react';
import { DeleteModalOnTable } from '../../../../../../Utils/DeleteModalUtils';
import { Text } from '../../../../../../Components';
import { Divflex } from '../../../../../../Layouts/DivWhithFlex';
import { FooterThisActionIsPermanent } from '../../../../../../Components/Table/components/Modals/DeleteModal';
import ReactMarkdown from 'react-markdown';

export const TitleHandler = (selectedRowsOnTable: any, t: any) =>
  selectedRowsOnTable?.length === 1
    ? `${t('common.delete')} ${t('common.group')} "${
        selectedRowsOnTable ? selectedRowsOnTable[0]?.descricao : ''
      }"?`
    : `${t('common.delete')} ${selectedRowsOnTable?.length} ${t(
        'common.groups'
      )}?`;

export const BodyHandler = (selectedRowsOnTable: any, t: any) =>
  selectedRowsOnTable?.length > 1 ? (
    DeleteModalOnTable(
      selectedRowsOnTable,
      `${t('modal.deleteModal.many.title')} ${t('common.theGroups')}:`,
      'descricao',
      t('common.groups'),
      undefined,
      4
    )
  ) : (
    <>
      <div style={{ padding: '20px' }}>
        <Divflex style={{ flexDirection: 'column' }}>
          <Text color="text-50" type="ui-tiny-content">
            <ReactMarkdown>
              {t('modal.deleteModal.single.groupQuestion', {
                grupo: `**${
                  selectedRowsOnTable && selectedRowsOnTable[0]?.descricao
                }**`,
              })}
            </ReactMarkdown>
          </Text>
        </Divflex>
        <FooterThisActionIsPermanent />
      </div>
    </>
  );
