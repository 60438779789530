import { useCallback } from "react";
import { useQueryClient } from '@tanstack/react-query';
import {
  FETCH_GET_ORDER_PURCHASE
} from '@/ControllerApiHook/UniqIds/Purchase/PurchaseOrderKeys';
import { IUseFunctions } from "./interfaces";

export const useCancelModalFunctions = ({
  purchaseOrder,
  cancelOrderPurchase,
  changeVisibleModal
}: IUseFunctions) => {
  const queryClient = useQueryClient();

  const onOkClick = useCallback(async () => {
    if (purchaseOrder) {
      const res = await cancelOrderPurchase.mutateAsync({
        pedidoCompraExternalId: purchaseOrder?.externalId,
      });

      if (res.success)
        queryClient.invalidateQueries([FETCH_GET_ORDER_PURCHASE]);
      changeVisibleModal(false);
    }
  }, [purchaseOrder, cancelOrderPurchase]);

  const onCancelClick = () => changeVisibleModal(false);
  
  const onClose = () => changeVisibleModal(false);

  return {
    onOkClick,
    onCancelClick,
    onClose
  };
};