import { useEffect } from 'react';
import { IUseFunctions } from './interfaces';

export const useCreateFunctions = ({
  form,
  packingClassificationList,
  setProductRowAmount,
  index,
  totalRows,
  onDelete,
  uuidV4,
  setCurrentPackingClassification,
  setIsDeleteButtonDisabled,
}: IUseFunctions) => {
  useEffect(() => {
    setIsDeleteButtonDisabled(
      !form.values.embalagemClassificacaoExternalId ||
        !form.values.embalagemClassificacaoExternalId[index]?.externalId
    );
  }, [form.values.embalagemClassificacaoExternalId, index]);

  const setPackingClassification = (value: string) => {
    packingClassificationList?.data?.forEach((e: any) => {
      if (e.externalId === value) {
        setCurrentPackingClassification(e);
        if (!form.values.embalagemClassificacaoExternalId) {
          form.values.embalagemClassificacaoExternalId = [];
        }
        form.values.embalagemClassificacaoExternalId[index] =
          form.values.embalagemClassificacaoExternalId[index] ?? {};
        form.values.embalagemClassificacaoExternalId[index].descricao =
          e.descricao;
        form.values.embalagemClassificacaoExternalId[index].externalId =
          e.externalId;
      }
    });
    addRow();
  };

  const addRow = () => {
    if (index === totalRows - 1) {
      setProductRowAmount((oldState) => [...oldState, uuidV4()]);
    }
  };

  const deleteRow = (index: number) => {
    if (!form.values.embalagemClassificacaoExternalId) return;
    form.values.embalagemClassificacaoExternalId =
      form.values.embalagemClassificacaoExternalId.filter(
        (_: any, i: number) => i !== index
      );
    if (totalRows === 1) {
      setProductRowAmount((oldState) => [...oldState, uuidV4()]);
    }
    onDelete(index);
  };

  return {
    addRow,
    deleteRow,
    setPackingClassification,
  };
};
