import { IProductGroupListData } from "@/Data/Interfaces/response/Product/IProductRespose";
import { useTranslation } from "react-i18next";
import { IUseMemorizations } from "./interfaces";

export const useProductMemorizations = ({
  productList
}: IUseMemorizations) => {
  const { t } = useTranslation();

  const productListFormatted = productList && productList.data ? productList.data : [];

  const items = productListFormatted.map((x: IProductGroupListData) => ({
    id: x?.externalId,
    label: x?.descricao,
    subLabel: `${t(
      'purchasing.purchaseOrder.create.subLabelProduct'
    )} ${x.sequenciaGroupTenant}`,
  }));

  return {
    items
  };
};