import { UserGroupAPI } from '@/Data/API/Accountmanager/UserGroupApi';
import { LIST_USER_GROUP_TYPE } from '@/ControllerApiHook/UniqIds/Accountmanager/UserGroupKeys';
import { useControllerQueryListApiHook } from '@/ControllerApiHook/Controller';

export const useAccessCardCallAPI = () => {
  const userGroupListResponse = useControllerQueryListApiHook({
    entityApi: UserGroupAPI.listUserGroupForDropdown,
    uniqId: LIST_USER_GROUP_TYPE,
    autoIncrement: true,
  });

  return {
    userGroupListResponse,
  };
};
