import { FC } from 'react';
import {
  TextArea
} from '@/Components';
import { Col } from 'antd';
import { useTranslation } from 'react-i18next';

export const Description: FC = () => {
  const { t } = useTranslation();

  return (
    <Col span={24}>
      <TextArea
        name="descricao"
        label={t('products.incompatible.description')}
        maxLength={1000}
        required
        placeholder={t(
          'products.incompatible.descriptionPlaceholder'
        )}
      />
    </Col>
  );
};