import React, { useState, useEffect, FC, useCallback } from 'react';
import { Row, Col } from 'antd';
import { FooterButton } from '../Components/FooterButton';
import { useFormikContext } from 'formik';
import { Card, i18n, InputWithSearch, TextInput } from '../../../Components';
import { TextInputWithMask } from '../../../Components/Inputs/TextInputWithMask';
import { TextInputPhoneMask } from '../../../Components/Inputs/TextInputPhoneMask';
import { useTranslation } from 'react-i18next';
import DateUtils from '../../../Utils/DateUtils';
import { RadioGroup } from '../../../Components/StateInputs/RadioButton/RadioGroup';
import { RadioButton } from '../../../Components/StateInputs/RadioButton';
import { THERE_IS_EMAIL_TYPE } from '@/ControllerApiHook/UniqIds/Identity/EmailHooks';
import { useControllerMutateApiHook } from '@/ControllerApiHook/Controller';
import { emailAPI } from '@/Data/API/Identity/EmailApi';
import StorageUtils from '@/Utils/StorageUtils';

import styles from './CompanyResponsibleUserCard.module.scss';

interface ICompanyResponsibleUser {
  editForm?: boolean;
  radioCompanyGroup: boolean;
  radioUser: boolean;
  onChangeRadioUser: (value: boolean) => void;
  isLoading?: boolean;

  buttonDisabled: boolean;
  setButtonDisabled: React.Dispatch<React.SetStateAction<boolean>>;

  users?: any;
}

export const CompanyResponsibleUserCard: FC<ICompanyResponsibleUser> = ({
  editForm,
  radioCompanyGroup,
  radioUser,
  onChangeRadioUser,
  isLoading,

  buttonDisabled,
  setButtonDisabled,

  users,
}) => {
  const form: any = useFormikContext();

  const preferences = StorageUtils.getPreference();

  useEffect(() => {
    onChangeRadioUser(true);
  }, [radioCompanyGroup, onChangeRadioUser]);

  useEffect(() => {
    if (editForm) return;
    form?.setFieldValue('usuario.nome', undefined);
    form?.setFieldValue('usuario.sobrenome', undefined);
    form?.setFieldValue('usuario.email', undefined);
    form?.setFieldValue('usuario.dataNascimento', undefined);
    form?.setFieldValue('usuario.cpf', undefined);
    form?.setFieldValue('usuario.ativo', undefined);
    form?.setFieldValue('usuario.celular.tipoId', undefined);
    form?.setFieldValue('usuario.celular.numero', undefined);
    form?.setFieldValue('usuarioResponsavelExternalId', undefined);
    form?.setFieldValue('usuario', undefined);
    if (radioUser) {
      form?.setFieldValue('usuario.ativo', true);
      form?.setFieldValue('usuario.celular.tipoId', 1);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [radioUser]);

  useEffect(() => {
    if (!!form.values.conglomeradoExternalId) onChangeRadioUser(true);
  }, [form?.values?.conglomeradoExternalId, onChangeRadioUser]);
 
  return (
    <Card
      title={i18n.t('adminBootis.createCompany.responsibleUser')}
      withoutCollapse={editForm}
    >
      {!editForm && (
        <RadioGroup
          className={styles['radio-button']}
          valueChecked={radioUser ? 1 : 2}
        >
          <RadioButton
            value={1}
            label={i18n.t(
              'adminBootis.createCompany.responsibleUserCard.newUser'
            )}
            onChange={() => onChangeRadioUser(true)}
            disabled={buttonDisabled}
          />
          <RadioButton
            value={2}
            label={i18n.t(
              'adminBootis.createCompany.responsibleUserCard.existingUser'
            )}
            onChange={() => onChangeRadioUser(false)}
            disabled={buttonDisabled || !form?.values.conglomeradoExternalId}
            tooltip={
              !buttonDisabled && !form?.values.conglomeradoExternalId
                ? i18n.t(
                    'adminBootis.createCompany.responsibleUserCard.radioTooltip'
                  )
                : ''
            }
          />
        </RadioGroup>
      )}

      {radioUser && !editForm ? (
        <>
          <Row>
            <Col flex="27%">
              <EmailField buttonDisabled={!!buttonDisabled} />
            </Col>
          </Row>
          <Row gutter={[10, 0]}>
            <Col span={12}>
              <TextInput
                name="usuario.nome"
                label={i18n.t(
                  'adminBootis.createCompany.responsibleUserCard.name'
                )}
                placeHolder={i18n.t(
                  'adminBootis.createCompany.responsibleUserCard.namePlaceholder'
                )}
                disabled={buttonDisabled}
                required
                dataTestId="user-name"
              />
            </Col>
            <Col span={12}>
              <TextInput
                name="usuario.sobrenome"
                label={i18n.t(
                  'adminBootis.createCompany.responsibleUserCard.lastName'
                )}
                placeHolder={i18n.t(
                  'adminBootis.createCompany.responsibleUserCard.lastNamePlaceholder'
                )}
                disabled={buttonDisabled}
                required
                dataTestId="last-name"
              />
            </Col>
          </Row>
          <Row gutter={[10, 0]}>
            <Col span={8}>
              <TextInputWithMask
                name="usuario.cpf"
                withoutMarginBottom
                label={i18n.t(
                  'adminBootis.createCompany.responsibleUserCard.cpf'
                )}
                placeHolder={i18n.t(
                  'adminBootis.createCompany.responsibleUserCard.cpfPlaceholder'
                )}
                mask="cpfMask"
                disabled={buttonDisabled}
                dataTestId="cpf"
                required
              />
            </Col>
            <Col span={8}>
              <TextInputWithMask
                name="usuario.dataNascimento"
                withoutMarginBottom
                label={i18n.t(
                  'adminBootis.createCompany.responsibleUserCard.bithDate'
                )}
                placeHolder={
                  preferences &&
                  DateUtils.getDatePlaceholder(
                    preferences?.idioma,
                    preferences?.padraoData
                  )
                }
                mask="dataMask"
                disabled={buttonDisabled}
                dataTestId="birthday"
              />
            </Col>
            <Col span={8}>
              <TextInputPhoneMask
                name="usuario.celular.numero"
                withoutMarginBottom
                label={i18n.t(
                  'adminBootis.createCompany.responsibleUserCard.cell'
                )}
                placeHolder={i18n.t(
                  'adminBootis.createCompany.responsibleUserCard.cellPlaceholder'
                )}
                disabled={buttonDisabled}
              />
            </Col>
          </Row>
        </>
      ) : (
        <Row>
          <Col flex="45%">
            <InputWithSearch
              name="usuarioResponsavelExternalId"
              selectedItemFromForm="usuarioResponsavelLabelInitialValue"
              items={Array.isArray(users?.data?.data) ? users?.data?.data?.map((x: any) => ({
                id: x.externalId,
                label: x.nome,
                avatar: {
                  email: x.email,
                  name: x.nome,
                  lastName: x.sobrenome,
                },
              })) : []}
              placeHolder={i18n.t(
                'adminBootis.createCompany.responsibleUserCard.userPlaceholder'
              )}
              label={i18n.t(
                'adminBootis.createCompany.responsibleUserCard.user'
              )}
              disabled={buttonDisabled}
              required
              withoutMarginBottom
              onScrollEnd={users.fetchNewPage}
              withoutSearchIcon
              dataTestId="responsible-user"
              onSearch={
                (search) => users.refetch({ search: search })
              }
              actionOnFocus={
                () => users.refetch({ search: '' })
              }
            />
          </Col>
        </Row>
      )}
      {editForm && form && (
        <FooterButton
          disabled={!!buttonDisabled}
          editLabel={i18n.t(
            'adminBootis.createCompany.responsibleUserCard.edit'
          )}
          onChange={(value) => setButtonDisabled(value)}
          loading={isLoading}
          dataTestId="responsible-edit-button"
        />
      )}
    </Card>
  );
};

interface IEmailField {
  buttonDisabled: boolean;
}

const EmailField: FC<IEmailField> = ({ buttonDisabled }) => {
  const [email, setEmail] = useState('');
  const { t } = useTranslation();

  const form: any = useFormikContext();

  const onEmailTypeError = useCallback(() => {
    form.setErrors({
      ...form.errors,
      usuario: {
        email: t('common.error.emailAlreadyRegistred'),
      },
    });
  }, [form, t]);

  const { mutateAsync } = useControllerMutateApiHook({
    uniqId: THERE_IS_EMAIL_TYPE,
    entityApi: emailAPI.isEmailAlreadyRegistred,
    options: { onError: onEmailTypeError },
  });

  useEffect(() => {
    mutateAsync(email);
  }, [email, mutateAsync]);

  return (
    <TextInput
      name="usuario.email"
      label={i18n.t('adminBootis.createCompany.responsibleUserCard.email')}
      placeHolder={i18n.t(
        'adminBootis.createCompany.responsibleUserCard.emailPlaceholder'
      )}
      disabled={buttonDisabled}
      required
      onBlur={(x) => setEmail(x)}
      dataTestId="email-user"
    />
  );
};
