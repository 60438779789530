import { FC } from 'react';
import { Table } from '@/Components';
import { UsersColumns } from './UserForm.columns';
import { AddUserModal } from './Modals/AddUserGroup';
import { IUserGroup } from '@/Data/Interfaces/response/User/IGetUserReponse';
import { useUserFormStates } from './Hooks/useUserFormStates';
import { useUserFormFunctions } from './Hooks/useUserFormFunctions';
import { useUserFormMemorizations } from './Hooks/useUserFormMemorizations';
import { useUserFormCallAPI } from './Hooks/useUserFormCallAPI';
import { useTranslation } from 'react-i18next';

interface IUserForm {
  externalId: string;
  userGroup?: IUserGroup | null;
}

export const UserForm: FC<IUserForm> = ({ externalId, userGroup }) => {
  const { t } = useTranslation();
  const { 
    selectedRowsOnTable, 
    setSelectedRowsOnTable, 
    visible, 
    setVisible 
  } = useUserFormStates();

  const {
    userListData,
    userListLoading,
    userListRefetch,
    removeUserInUserGroup,
  } = useUserFormCallAPI({ externalId });

  const { headerOptions, deleteModal } = useUserFormMemorizations({
    userGroup,
    selectedRowsOnTable,
    setVisible,
  });

  const { deleteUsers, selectRowsOnTable } = useUserFormFunctions({
    externalId,
    removeUserInUserGroup,
    setSelectedRowsOnTable,
  });

  return (
    <>
      <Table
        headerOptions={headerOptions}
        hasSelection
        columns={UsersColumns(t)}
        onRefetch={userListRefetch}
        onDelete={deleteUsers}
        pagination={userListData}
        loading={userListLoading}
        selectedRowsOnTable={selectRowsOnTable}
        deleteModal={deleteModal}
      />

      <AddUserModal
        visible={visible}
        setVisible={setVisible}
        externalId={externalId}
      />
    </>
  );
};
