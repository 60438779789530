import { 
  GET_STANDARD_FORMULA 
} from '@/ControllerApiHook/UniqIds/Production/StandardFormulaKeys';
import { useControllerQueryApiHook } from '@/ControllerApiHook/Controller';
import { 
  IGetStandardFormula 
} from '@/Data/Interfaces/response/StandardFormula/IStandardFormulaResponse';
import { StandardFormulaAPI } from '@/Data/API/Production/StandardFormula';
import { IUseCallAPI } from './interfaces';

export const useEditCallAPI = ({
  externalId
}: IUseCallAPI) => {
  const { data: standardFormula } = useControllerQueryApiHook<IGetStandardFormula>({
    uniqId: GET_STANDARD_FORMULA,
    entityApi: StandardFormulaAPI.getStandardFormula,
    externalIds: [externalId]
  });

  return {
    standardFormula
  };
};