import { Text } from '../../../../../../Components/Text';
import { Status } from '../../../../../../Components';

export const linkedProductMessageCollumn = (t: any) => [
  {
    title: t('supply.productMessage.details.linkedListProduct.code'),
    dataIndex: 'sequenciaGroupTenant',
    key: 'sequenciaGroupTenant',
    width: '5%',
  },
  {
    title: t('supply.productMessage.details.linkedListProduct.productName'),
    dataIndex: 'descricao',
    key: 'descricao',
    isHighlightable: true,
    sortColumn: 'descricao',
    sorter: true,
    defaultSortOrder: 'ascend',
    render: (x: any, row: any) => {
      return (
        <Text type="ui-tiny-bold" color="text-50">
          {`${row?.descricao}`}
        </Text>
      );
    },
  },
  {
    title: t('supply.productMessage.details.linkedListProduct.class'),
    dataIndex: 'classeProduto',
    key: 'classeProduto',
    isHighlightable: true,
    render: (x: any, row: any) => {
      return (
        <Text type="ui-tiny-content" color="text-50">
          {`${row?.classeProduto.abreviacao}`}
        </Text>
      );
    },
  },
  {
    title: t('supply.productMessage.details.linkedListProduct.group'),
    dataIndex: 'grupoDescricao',
    key: 'grupoDescricao',
    isHighlightable: true,
    render: (x: any, row: any) => {
      return (
        <Text type="ui-tiny-content" color="text-50">
          {`${row?.grupoDescricao}`}
        </Text>
      );
    },
  },
  {
    title: t('supply.productMessage.details.linkedListProduct.subGroup'),
    dataIndex: 'subGrupoDescricao',
    key: 'subGrupoDescricao',
    isHighlightable: true,
    render: (x: any, row: any) => {
      return (
        <Text type="ui-tiny-content" color="text-50">
          {`${row?.subGrupoDescricao}`}
        </Text>
      );
    },
  },
  {
    title: t('production.packingClassification.list.status'),
    dataIndex: 'ativo',
    key: 'ativo',
    width: '15%',
    render: (prop: any) => (
      <Status type={prop ? 'success' : 'disabled'}>
        {t(
          `production.packingClassification.list.statusTypes.${
            prop ? 'active' : 'inactive'
          }`
        )}
      </Status>
    ),
  },
];
