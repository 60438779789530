import { useControllerQueryListApiHook } from '@/ControllerApiHook/Controller';
import { FormatDataToDropdownUtils } from '@/Utils/FormatDataToDropdownUtils';
import { IQueryListParams } from '@/Utils/Http';
import { ListSupplier } from '@/Services/People/Supplier/ListSupplier.service';

export const FETCH_LIST_DROPDOWN_SUPPLIER =
  '[SUPPLIER] FETCH_LIST_DROPDOWN_SUPPLIER';

interface IUseListSupplier {
  fieldName: string;
  enabled?: boolean;
  pagination?: IQueryListParams;
}

export enum TypeSupplierEnum {
  distributorId = 1,
  shippingCompanyId = 2,
  serviceId = 3,
}

export const useListSupplier = ({
  fieldName,
  enabled,
  pagination,
}: IUseListSupplier) => {
  const { data, refetch, isLoading, fetchNewPage } =
    useControllerQueryListApiHook({
      uniqId: FETCH_LIST_DROPDOWN_SUPPLIER,
      entityApi: ListSupplier,
      autoIncrement: true,
      enabled,
      pagination,
    });

  const items = FormatDataToDropdownUtils({
    data: data && data.data ? data.data : [],
    idKey: 'externalId',
    labelKey: 'nome',
    subLabelKey: 'tipoFornecedor',
  });

  const onSearch = (search?: string) => {
    refetch({
      search,
    });
  };

  return {
    name: fieldName,
    items,
    onSearch,
    isLoading,
    onScrollEnd: fetchNewPage,
  };
};
