import { DeleteModalOnTableForLot } from '@/Utils/DeleteModalUtils';
import { Text } from '@/Components';
import { Divflex } from '@/Layouts/DivWhithFlex';
import {
  FooterThisActionInitialStockBalance,
  FooterThisActionIsPermanent,
} from '@/Components/Table/components/Modals/DeleteModal';
import { ReactMarkdown } from 'react-markdown/lib/react-markdown';

export const TitleHandler = (selectedRowsOnTable: any, t: any) =>
  selectedRowsOnTable?.length === 1
    ? `${t('common.deleteLot')} "${
        selectedRowsOnTable && selectedRowsOnTable[0]?.numero
      }" ?`
    : '';

export const BodyHandler = (selectedRowsOnTable: any, t: any) =>
  selectedRowsOnTable?.length > 1 ? (
    DeleteModalOnTableForLot(
      selectedRowsOnTable,
      `${t('modal.deleteModal.many.title')} ${t('common.theLots')}:`,
      'numero',
      'numero'
    )
  ) : (
    <>
      <div style={{ padding: '20px' }}>
        <Divflex style={{ flexDirection: 'column', marginBottom: 15 }}>
          <Text color="text-50" type="ui-tiny-content">
            <ReactMarkdown>
              {t('modal.deleteModal.single.question', {
                numero: `**${
                  selectedRowsOnTable && selectedRowsOnTable[0]?.numero
                }**`,
                produto: `**${
                  selectedRowsOnTable && selectedRowsOnTable[0]?.produto
                }**`,
              })}
            </ReactMarkdown>
          </Text>
          <FooterThisActionInitialStockBalance />
        </Divflex>

        <FooterThisActionIsPermanent />
      </div>
    </>
  );
