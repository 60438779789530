import { useCallback } from "react";
import { useQueryClient } from '@tanstack/react-query';
import { FETCH_LIST_LOTS } from '@/ControllerApiHook/UniqIds/Supply/LotKeys';
import { handleModalError } from '@/Utils/ErrorCodeUtils/ErrorHandlingUtils';
import { history } from '@/Store';
import { App } from 'antd';
import { IUseFunctions } from "./interfaces";

export const useModalFunctions = ({
  changeVisibleModal,
  lot,
  deleteLot
}: IUseFunctions) => {
  const app = App.useApp();
  const queryClient = useQueryClient();

  const onOkClick = useCallback(async () => {
    try {
      changeVisibleModal(false);
      if (lot) {
        const res = await deleteLot.mutateAsync(
          {
            lotesExternalId: [lot?.externalId] as any,
          },
          {
            onError: handleModalError(app),
          }
        );
        if (res.success) {
          queryClient.invalidateQueries([FETCH_LIST_LOTS]);
          history.push(`/inventory/lot`);
        }
      }
    } catch {}
  }, [changeVisibleModal, deleteLot, lot]);

  const onCancelClick = useCallback(() => changeVisibleModal(false), [changeVisibleModal]);

  const onClose = useCallback(() => changeVisibleModal(false), [changeVisibleModal]);

  return {
    onOkClick,
    onCancelClick,
    onClose
  };
};