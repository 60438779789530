import React, { FC, useState } from 'react';
import { Link, Avatar, Text, Icon, PopOver } from '../../..';
import StorageUtils from '../../../../Utils/StorageUtils';
import { useTranslation } from 'react-i18next';
import { history } from '../../../../Store';
import { CURRENT_USER } from '@/Utils/ConstantsUtils';
import { Cookies } from 'react-cookie';
import { CURRENT_USER_ROLES } from '@/Utils/PermissionUtils';

import styles from './UserInfo.module.scss';

export const HeaderMenutitle = (
  onClick: () => void,
  name: string,
  lastName?: string,
  email?: string
) => {
  return (
    <>
      <div className={styles['divTitle']}>
        <div className={styles['divAvatar']}>
          <Avatar
            fisrtName={name}
            lastName={lastName}
            size="L"
            type="heading4"
            color="white"
          />
        </div>

        <div className={styles['divAvatarItem']}>
          <div className={styles['name']}>
            <Text type="paragraph2-bold" color="text-50" children={name} />
            <Text
              type="paragraph2-bold"
              color="text-50"
              children={` ${lastName}`}
            />
          </div>
          <Text type="paragraph2" color="text-300" children={email} />
        </div>
      </div>
    </>
  );
};

export const HeaderMenucontent = (onClick: () => void) => {
  const { t } = useTranslation();

  const cookies = new Cookies();

  return (
    <>
      <div>
        <div className={styles['divContentItem']}>
          <div
            className={styles['divItem']}
            onClick={() => {
              onClick();
              history.push('/account-manager/account-details');
            }}
          >
            <Icon
              className={styles['icon']}
              color="text-50"
              icon="user-mark"
              size="M"
            />
            <Link
              className={styles['itemLink']}
              type="paragraph2-bold"
              color="text-50"
              children={t('header.manageAccount')}
              to="/account-manager/account-details"
              onClick={onClick}
            />
          </div>
          <div
            className={styles['divItem']}
            onClick={() => {
              onClick();
              history.push('/user-preference/place-and-time');
            }}
          >
            <Icon
              className={styles['icon']}
              color="text-50"
              icon="multimidia-equalizer"
              size="M"
            />
            <Link
              className={styles['itemLink']}
              type="paragraph2-bold"
              color="text-50"
              children={t('header.preference')}
              to="/user-preference/place-and-time"
              onClick={onClick}
            />
          </div>
          <div className={styles['divItem']}>
            <Icon
              className={styles['icon']}
              color="text-50"
              icon="exclamation-upside-down"
              size="M"
            />
            <Link
              className={styles['itemLink']}
              type="paragraph2-bold"
              color="text-50"
              children={t('header.about')}
              to=""
            />
          </div>
          <div className={`${styles['divItem']} ${styles['divItem-help']}`}>
            <Icon
              className={styles['icon']}
              color="text-50"
              icon="question-mark"
              size="M"
            />
            <Link
              className={styles['itemLink']}
              type="paragraph2-bold"
              color="text-50"
              children={t('header.help')}
              to=""
            />
          </div>
        </div>
        <div
          className={`${styles['divContentItem']} ${styles['divContentItem-exit']}`}
          onClick={() => {
            cookies.remove(CURRENT_USER_ROLES, { path: '/' });
            cookies.remove(CURRENT_USER, { path: '/' });
            StorageUtils.SetRememberMe(false);
          }}
        >
          <div className={`${styles['divItem-exit']} ${styles['divItem']}`}>
            <Icon
              className={styles['icon']}
              color="text-50"
              icon="exit"
              size="M"
            />
            <Link
              className={styles['itemLink']}
              type="paragraph2-bold"
              color="text-50"
              children={t('header.logout')}
              to="/login"
            />
          </div>
        </div>
      </div>
    </>
  );
};
export interface IUserInfo {
  name: string;
  lastName?: string;
  email?: string;
}

export const UserInfo: FC<IUserInfo> = ({ name, lastName, email }) => {
  const [visible, setVisible] = useState<boolean>(false);

  return (
    <div className={styles['circleAvatar']}>
      <PopOver
        visible={visible}
        onOpenChange={() => setVisible(!visible)}
        placement="bottomRight"
        title={HeaderMenutitle(
          () => setVisible(!visible),
          name,
          lastName,
          email
        )}
        content={HeaderMenucontent(() => setVisible(!visible))}
        trigger="click"
      >
        <Avatar
          size="SM"
          type="paragraph2-bold"
          color="white"
          fisrtName={name}
          lastName={lastName}
        />
      </PopOver>
    </div>
  );
};
