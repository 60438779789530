import { useQueryClient } from '@tanstack/react-query';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Text } from '../../../../../../Components';
import { Modal } from '../../../../../../Components/Modal';
import { FooterThisActionIsPermanent } from '../../../../../../Components/Table/components/Modals/DeleteModal';
import { IGetCompanyResponse } from '../../../../../../Data/Interfaces/response/Company/ICompanyResponse';
import {
  FETCH_DELETE_TYPE,
  LIST_COMPANY_ADMIN_TYPE
} from '../../../../../../ControllerApiHook/UniqIds/Accountmanager/CompanyKeys';
import { Divflex } from '../../../../../../Layouts/DivWhithFlex';
import { history } from '../../../../../../Store';
import { useControllerMutateApiHook } from '@/ControllerApiHook/Controller';
import { CompanyAPI } from '@/Data/API/Accountmanager/CompanyApi';
import { messageErrorToast } from '@/Utils/MessageErrorToast';

interface IDeleteProductModal {
  company?: IGetCompanyResponse;
  visibleModal: boolean;
  changeVisibleModal: (value: boolean) => void;
}

export const DeleteCompanyModal: FC<IDeleteProductModal> = ({
  company,
  visibleModal,
  changeVisibleModal,
}) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  const deleteCompany = useControllerMutateApiHook<any>({
    uniqId: FETCH_DELETE_TYPE,
    entityApi: CompanyAPI.deleteCompany,
    options: {
      onError: (x) => messageErrorToast(x),
    }
  });

  return (
    <Modal
      title={`${t('common.delete')} "${company?.nomeFantasia}"?`}
      body={
        <div style={{ padding: '20px' }}>
          <Divflex>
            <Text
              color="text-50"
              type="ui-tiny-content"
              children={`${t('modal.deleteModal.single.question')} ${t(
                'common.theCompany'
              )} `}
            />
            <Text
              color="text-50"
              type="ui-tiny-bold"
              children={`${company?.nomeFantasia}?`}
            />
          </Divflex>
          <FooterThisActionIsPermanent />
        </div>
      }
      okButtonColor="status-danger-base"
      visible={visibleModal}
      onOkClick={async () => {
        if (company) {
          const res = await deleteCompany.mutateAsync({
            empresasExternalId: [company?.externalId],
          });
          if (res.success) {
            queryClient.invalidateQueries([LIST_COMPANY_ADMIN_TYPE]);
            history.push(`/admin-bootis/company`);
          }
        }
      }}
      okButtonName={t('common.delete')}
      onCancelClick={() => changeVisibleModal(false)}
      onClose={() => changeVisibleModal(false)}
    />
  );
};
