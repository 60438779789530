import { useState } from "react";

export const useEditUserGroupsStates = () => {
  const [modalDeleteUserGroup, setModalDeleteUserGroup] = useState(false);
  const [formDisabled, setFormDisabled] = useState(true);

  return {
    modalDeleteUserGroup,
    setModalDeleteUserGroup,
    formDisabled,
    setFormDisabled
  };
};