import { Languages, TypeLanguages } from '../../../../Components/i18n';

export const languages = [
  { id: Languages.ptBR, label: TypeLanguages['pt-BR'] },
  { id: Languages.enUS, label: TypeLanguages['en-US'] },
];

export const standardDate = [
  { id: 'dd/MM/yyyy', label: 'dd/mm/yyyy' },
  { id: 'MM/dd/yyyy', label: 'mm/dd/yyyy' },
];

export const standardTime = [
  { id: 'h:mm tt', label: '0:00 (h:mm tt)' },
  { id: 'hh:mm tt', label: '00:00 (hh:mm tt)' },
  { id: 'H:mm', label: '0:00 (H:mm)' },
  { id: 'HH:mm', name: '00:00 (HH:mm)' },
  { id: 'HH:mm "Uhr"', label: '00:00 Uhr (HH:mm "Uhr")' },
  { id: 'H.mm', label: '0.00 (H.mm)' },
  { id: 'HH.mm', label: '00.00 (HH.mm)' },
  { id: 'HH"H"mm', label: '00H00 (HH"H"mm)' },
];
