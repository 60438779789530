import { useCallback } from "react";
import { IUseFunctions } from "./interfaces";

export const useGroupFunctions = ({
  handlerGroupDropdown
}: IUseFunctions) => {
  const onChange = useCallback((groupExternalId: string) => {
    handlerGroupDropdown(groupExternalId);
  }, [handlerGroupDropdown]);

  return { onChange };
};