import { useControllerMutateApiHook } from '@/ControllerApiHook/Controller';
import { UserGroupAPI } from '@/Data/API/Accountmanager/UserGroupApi';
import {
  GET_USER_GROUP_TYPE,
  PATCH_NAME_AND_DESCRIPTION_USER_GROUP,
  USER_GROUP_NAME_ALREADY_REGISTRED_TYPE,
} from '@/ControllerApiHook/UniqIds/Accountmanager/UserGroupKeys';
import { useQueryClient } from '@tanstack/react-query';
import { IUseCallAPI } from './interfaces';
import { useTranslation } from 'react-i18next';

export const useDetailFormCallAPI = ({ onDisabledForm, form }: IUseCallAPI) => {
  const queryClient = useQueryClient();
  const { t } = useTranslation();

  const editUserGroup = useControllerMutateApiHook({
    uniqId: PATCH_NAME_AND_DESCRIPTION_USER_GROUP,
    entityApi: UserGroupAPI.patchNameAndDescription,
    options: {
      onSuccess: () => {
        onDisabledForm(true);
        queryClient.invalidateQueries([GET_USER_GROUP_TYPE]);
      },
    },
  });

  const { mutateAsync: mutateAsyncGroupNameAlreadyRegistered } =
    useControllerMutateApiHook<string>({
      uniqId: USER_GROUP_NAME_ALREADY_REGISTRED_TYPE,
      entityApi: UserGroupAPI.isUserGroupNameAlreadyRegistred,
      options: {
        onSuccess: () => form?.setFieldError('nome', undefined),
        onError: () =>
          form?.setFieldError('nome', t('common.error.userGroupNameRegistred')),
      },
    });

  return { editUserGroup, mutateAsyncGroupNameAlreadyRegistered };
};
