import { useMemo } from 'react';
import { DetailForm } from '../Components/DetailForm';
import { useTranslation } from 'react-i18next';
import { UserForm } from '../Components/UserForm';
import { PermissionForm } from '../Components/PermissionForm';
import { useEditUserGroupsStates } from './useEditUserGroupsStates';
import { IButtonDropDownItems } from '@/Components/Button/ButtonDropdown';
import { IUseMemorizations } from './interfaces';

export const useEditUserGroupsMemorizations = ({
  userGroup,
  externalId,
  setModalDeleteUserGroup,
}: IUseMemorizations) => {
  const { formDisabled, setFormDisabled } = useEditUserGroupsStates();

  const { t } = useTranslation();

  const items = useMemo(
    () => [
      {
        label: t('admin.editUserGroups.tab.details'),
        key: 'details',
        children: (
          <DetailForm
            userGroup={userGroup || undefined}
            disabledForm={formDisabled}
            onDisabledForm={setFormDisabled}
          />
        ),
      },
      {
        label: t('admin.editUserGroups.tab.user'),
        key: 'users',
        children: externalId && (
          <UserForm externalId={externalId} userGroup={userGroup} />
        ),
      },
      {
        label: t('admin.editUserGroups.tab.permissions'),
        key: 'permissions',
        children: <PermissionForm userGroup={userGroup || undefined} />,
      },
    ],
    [externalId, userGroup, formDisabled, t, setFormDisabled]
  );

  const dropdownItems = useMemo(
    (): IButtonDropDownItems | undefined => [
      {
        label: t('common.delete'),
        icon: 'trash',
        onClick: () => setModalDeleteUserGroup(true),
      },
    ],
    [t, setModalDeleteUserGroup]
  );

  return {
    items,
    dropdownItems,
  };
};
