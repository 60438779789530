import { 
  IListAlternativeUnitMeasurementData 
} from '@/Data/Interfaces/response/UnitMeasurement/IUnitMeasurementResponse';
import { alternativeUnit1 } from '../../../utils';
import { IUseMemorizations } from './interfaces';

export const useAlternativeUnit1Memorizations = ({
  unidadeAlternativaList
}: IUseMemorizations) => {
  const uiUnitDefault = 1;
  const grUnitDefault = 7;

  const dropdownLeft = {
    name: 'informacaoTecnica.unidadeAlternativaId1',
    defaultValue: uiUnitDefault,
    options: unidadeAlternativaList.data
      ? unidadeAlternativaList?.data?.map(
          (x: IListAlternativeUnitMeasurementData) => ({
            id: x.id,
            content: x.unidadeAbreviacao,
          })
        )
      : [],
  };

  const dropdownRight = {
    name: 'informacaoTecnica.unidadeAlternativaConversaoId1',
    defaultValue: grUnitDefault,
    options:
      alternativeUnit1?.map((x) => ({
        id: x.id,
        content: x.description,
      })) || [],
  };

  return {
    dropdownLeft,
    dropdownRight
  };
};