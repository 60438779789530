import React from 'react';
import { useTranslation } from 'react-i18next';
import { Modal } from '../../../../../../../Components/Modal';
import { IGetUserResponse } from '../../../../../../../Data/Interfaces/response/User/IGetUserReponse';
import { ModalBody } from './Components/ModalBody';
import { useDeleteUserCallAPI } from './Hooks/useDeleteUserModalCallAPI';
import { useDeleteUserFunctions } from './Hooks/useDeleteUserModalFunctions';

interface IEditModal {
  visible: boolean;
  user: IGetUserResponse;
  onCancelClick: () => void;
}

export const DeleteUserModal: React.FC<IEditModal> = ({
  visible,
  user,
  onCancelClick,
}) => {
  const { t } = useTranslation();

  const { deleteUser } = useDeleteUserCallAPI();

  const { onDelete } = useDeleteUserFunctions({
    deleteUser,
    userExternalId: user.externalId,
  });

  return (
    <Modal
      onOkClick={() => onDelete()}
      title={`${t('admin.editUserPage.modal.deleteUserModal.title')} “${
        user.nome
      } ${user.sobrenome}”?`}
      body={<ModalBody name={user.nome} lastName={user.sobrenome} t={t} />}
      visible={visible}
      onCancelClick={onCancelClick}
      htmlType="submit"
      okButtonName={t('admin.editUserPage.modal.deleteUserModal.inativeButton')}
      onClose={onCancelClick}
      okButtonType="danger"
      loading={deleteUser.isLoading}
    />
  );
};
