import { FC } from 'react';
import { Card, Icon, Text } from '@/Components';
import { Divflex } from '@/Layouts/DivWhithFlex';
import { useTranslation } from 'react-i18next';

export const TechnicalInfoNotRawMaterialCard: FC = () => {
  const { t } = useTranslation();

  return (
    <Card
      title={t('supply.lot.create.titleTechnicalInformation')}
      withoutCollapse
    >
      <Divflex>
        <Icon icon={'exclamation-upside-down'} size={'SM'} color={'text-400'} />
        <Text
          type="paragraph2"
          color={'text-400'}
          style={{ marginLeft: '6px', marginTop: '-1px', cursor: 'pointer' }}
        >
          {t('supply.lot.card.notRawMaterialCard')}
        </Text>
      </Divflex>
    </Card>
  );
};
