import { IUseFunctions } from './interfaces';
import { ICreatePosology } from '@/Data/Interfaces/request/Posology/IPosologyRequest';

export const useCreateFunctions = ({ createPosology }: IUseFunctions) => {
  const onSubmit = async (values: ICreatePosology) => {
    const body: ICreatePosology = { ...values };

    return await createPosology({
      descricao: body.descricao,
      formaFarmaceuticaExternalId: body.formaFarmaceuticaExternalId,
      periodo: body.periodo,
      quantidadeDosePorPeriodo: body.quantidadeDosePorPeriodo,
    });
  };

  return {
    onSubmit,
  };
};
