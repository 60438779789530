import { useFormikContext } from "formik";
import { useTranslation } from "react-i18next";
import { currencyFormater } from '@/Utils/Currency';
import { IUseMemorizations } from "./interfaces";

export const useFinalValueMemorizations = ({
  totalValueOrderPurchase,
  orderPurchase
}: IUseMemorizations) => {
  const { t } = useTranslation();
  const form: any = useFormikContext();

  const withStatusIconInLabel = totalValueOrderPurchase !==
    form?.values?.totalizadores?.totalPedido
  
  const withTooltip = {
    showMe:
      totalValueOrderPurchase !==
      form?.values?.totalizadores?.totalPedido,
    title: `${t('common.originalValue')} ${currencyFormater(
      orderPurchase?.totalizadores?.totalPedido
    )}`,
    targetOffset: [70, -2],
  };

  return {
    withStatusIconInLabel,
    withTooltip
  };
};