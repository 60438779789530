import { Tooltip } from '@/Components';
import { CurrencyInput } from '@/Components/Inputs/CurrencyInput';
import { currencyFormater } from '@/Utils/Currency';
import { Col, Row } from 'antd';
import { useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';

export const RowTwo: React.FC = () => {
  const { t } = useTranslation();
  const form: any = useFormikContext();

  return (
    <Row gutter={[16, 0]}>
      <Col span={4}>
        <Tooltip
          showMe={form.values.valorFrete !== form.initialValues.valorFrete}
          title={`${t(
            'purchasing.invoice.edit.originalFreight'
          )}${currencyFormater(form.initialValues.valorFrete)}`}
        >
          <CurrencyInput
            label={t('purchasing.invoice.create.freight')}
            name="valorFrete"
            placeHolder={t(
              'purchasing.invoice.create.placeHolder.CurrencyPlaceHolder'
            )}
            withStatusIcon
          />
        </Tooltip>
      </Col>
      <Col span={4}>
        <Tooltip
          showMe={form.values.valorSeguro !== form.initialValues.valorSeguro}
          title={`${t(
            'purchasing.invoice.edit.originalInsurance'
          )}${currencyFormater(form.initialValues.valorSeguro)}`}
        >
          <CurrencyInput
            label={t('purchasing.invoice.create.insurance')}
            name="valorSeguro"
            placeHolder={t(
              'purchasing.invoice.create.placeHolder.CurrencyPlaceHolder'
            )}
            withStatusIcon
          />
        </Tooltip>
      </Col>
      <Col span={4}>
        <Tooltip
          showMe={
            form.values.valorDesconto !== form.initialValues.valorDesconto
          }
          title={`${t(
            'purchasing.invoice.edit.originalDiscount'
          )}${currencyFormater(form.initialValues.valorDesconto)}`}
        >
          <CurrencyInput
            label={t('purchasing.invoice.create.discount')}
            name="valorDesconto"
            placeHolder={t(
              'purchasing.invoice.create.placeHolder.CurrencyPlaceHolder'
            )}
            withStatusIcon
          />
        </Tooltip>
      </Col>
      <Col span={4}>
        <Tooltip
          showMe={
            form.values.valorOutrasDespesas !==
            form.initialValues.valorOutrasDespesas
          }
          title={`${t(
            'purchasing.invoice.edit.originalOtherExpenses'
          )}${currencyFormater(form.initialValues.valorOutrasDespesas)}`}
        >
          <CurrencyInput
            label={t('purchasing.invoice.create.otherExpenses')}
            name="valorOutrasDespesas"
            placeHolder={t(
              'purchasing.invoice.create.placeHolder.CurrencyPlaceHolder'
            )}
            withStatusIcon
          />
        </Tooltip>
      </Col>
      <Col span={4}>
        <Tooltip
          showMe={form.values.valorIpi !== form.initialValues.valorIpi}
          title={`${t(
            'purchasing.invoice.edit.originalIpiValue'
          )}${currencyFormater(form.initialValues.valorIpi)}`}
        >
          <CurrencyInput
            label={t('purchasing.invoice.create.IPIValue')}
            name="valorIpi"
            placeHolder={t(
              'purchasing.invoice.create.placeHolder.CurrencyPlaceHolder'
            )}
            withStatusIcon
          />
        </Tooltip>
      </Col>
    </Row>
  );
};
