import { useMemo } from 'react';
import _ from 'lodash';
import { IUseMemorizations } from './interfaces';

export const useInputDescriptionSubGroupMemorizations = ({
  mutateAsync,
  dataGroupDropDown,
}: IUseMemorizations) => {
  const fetchProductSubGroupValidationName = useMemo(
    () =>
      _.debounce(async (value?: string, groupExternalId?: string) => {
        try {
          return (
            groupExternalId &&
            value &&
            (await mutateAsync({
              descricao: value,
              grupoExternalId: groupExternalId,
            }))
          );
        } catch {}
      }, 500),
    [mutateAsync]
  );

  const items = dataGroupDropDown?.data?.map((x: any) => ({
    id: x.externalId,
    label: x.descricao,
  }));

  return {
    fetchProductSubGroupValidationName,
    items,
  };
};
