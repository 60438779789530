import * as yup from 'yup';
import i18n from '../../../../../../Components/i18n';

export const ProductCreateSchema = yup.object().shape({
  descricao: yup
    .string()
    .required(i18n.t('products.create.errors.requiredName')),
  classeProdutoId: yup
    .string()
    .required(i18n.t('products.create.errors.requiredProductClass')),
  grupoExternalId: yup
    .string()
    .required(i18n.t('products.create.errors.requiredGroupdId')),
  subGrupoExternalId: yup
    .string()
    .required(i18n.t('products.create.errors.requiredSubGroupdId')),
  unidadeEstoqueId: yup
    .string()
    .required(i18n.t('products.create.errors.requiredMeasure')),

  valorCusto: yup
    .number()
    .required(i18n.t('products.create.errors.requiredCostPrice')),
  margemLucro: yup
    .number()
    .required(i18n.t('products.create.errors.requiredProfit')),
  valorVenda: yup
    .number()
    .required(i18n.t('products.create.errors.requiredSellPrice')),
});
