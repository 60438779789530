import { AdminCompanyRoutes } from './Company';
import { AdminUserRoutes } from './User';
import { AdminUserGroupRoutes } from './UserGroup';

export const AdminRoutes = () => (
  <>
    <AdminUserRoutes />
    <AdminUserGroupRoutes />
    <AdminCompanyRoutes />
  </>
);
