import { IconName } from '@/Components/Icon/IconClasses';
import { Color, Colors } from '@/Utils/ColorUtils';

export enum StatusSaleOrderHistoric {
  started = 0,
  budget = 1,
  approved = 2,
  disapproved = 3,
  delivered = 4,
  partialDelivery = 5,
  canceled = 6,
}

interface IObjStatusProps {
  icon?: IconName | undefined;
  background?: string;
  iconColor?: Color;
}

export const HistoricStatusIcon = (status: number): IObjStatusProps => {
  switch (status) {
    case StatusSaleOrderHistoric.started:
      return {
        icon: 'time',
        background: Colors['status-default-t15'],
        iconColor: 'status-default-base',
      };
    case StatusSaleOrderHistoric.budget:
      return {
        icon: 'calculator',
        background: Colors['status-warning-t15'],
        iconColor: 'status-warning-base',
      };
    case StatusSaleOrderHistoric.approved:
      return {
        icon: 'like',
        background: Colors['status-success-t15'],
        iconColor: 'status-success-base',
      };
    case StatusSaleOrderHistoric.disapproved:
      return {
        icon: 'disapproved',
        background: Colors['status-danger-t15'],
        iconColor: 'status-danger-base',
      };
    case StatusSaleOrderHistoric.delivered:
      return {
        icon: 'simple-cube',
        background: Colors['status-success-t15'],
        iconColor: 'status-success-base',
      };
    case StatusSaleOrderHistoric.partialDelivery:
      return {
        icon: 'partialcube',
        background: Colors['status-warning-t15'],
        iconColor: 'status-warning-base',
      };
    case StatusSaleOrderHistoric.canceled:
      return {
        icon: 'close-x',
        background: Colors['status-danger-t15'],
        iconColor: 'status-danger-base',
      };

    default:
      return {};
  }
};
