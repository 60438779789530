import { FC } from 'react';
import {
  InputWithSearch,
  Tooltip
} from '@/Components';
import { Col } from 'antd';
import { useTranslation } from 'react-i18next';
import { IIncompatibleProduct } from './interfaces';
import { useIncompatibleProductCallAPI } from './Hooks/useIncompatibleProductCallAPI';
import { useIncompatibleProductMemorizations } from './Hooks/useIncompatibleProductMemorizations';
import { useIncompatibleProductFunctions } from './Hooks/useIncompatibleProductFunctions';

export const IncompatibleProduct: FC<IIncompatibleProduct> = ({
  productExternalId,
  props,
  incompatibleProductData,
  disabledForm
}) => {
  const { t } = useTranslation();

  const {
    productList,
    fetchNewProductPage,
    refetchSearchProductPage
  } = useIncompatibleProductCallAPI({
    productExternalId
  });

  const {
    items
  } = useIncompatibleProductMemorizations({
    productList,
    productExternalId
  });

  const {
    onSearch
  } = useIncompatibleProductFunctions({
    refetchSearchProductPage
  });

  return (
    <Col span={8}>
      <Tooltip
        title={`${t(
          'products.incompatible.originalIncompatibleProduct'
        )}${incompatibleProductData?.produtoIncompativelDescricao}`}
        showMe={
          incompatibleProductData &&
          incompatibleProductData.produtoIncompativelExternalId !==
            props?.values?.produtoIncompativelExternalId
        }
      >
        <InputWithSearch
          name="produtoIncompativelExternalId"
          label={t('products.incompatible.incompatibleProduct')}
          placeHolder={t(
            'products.incompatible.productIncompatiblePlaceholder'
          )}
          items={items}
          onScrollEnd={fetchNewProductPage}
          onSearch={onSearch}
          required={!disabledForm}
          disabled={disabledForm}
          withStatusIcon
        />
      </Tooltip>
    </Col>
  );
};