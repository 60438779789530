import React from 'react';
import { Modal } from '../../../../../../../Components/Modal';
import { useTranslation } from 'react-i18next';
import { Divflex } from '../../../../../../../Layouts/DivWhithFlex';
import { Text } from '../../../../../../../Components';
import ReactMarkdown from 'react-markdown';

interface IDeleteModal {
  visible: boolean;
  onOkClick: () => void;
  onCancelClick: () => void;
  provider: string;
  number: string;
  series: string;
}

export const DeleteModal: React.FC<IDeleteModal> = ({
  onOkClick,
  onCancelClick,
  visible,
  provider,
  number,
  series,
}) => {
  const { t } = useTranslation();

  return (
    <Modal
      onOkClick={onOkClick}
      visible={visible}
      title={`${t('common.delete')} ${t('common.invoice')}?`}
      body={
        <Divflex
          style={{ flexDirection: 'column', width: '400px', padding: '20px' }}
        >
          <Text color="text-50" type="ui-tiny-content">
            <ReactMarkdown>
              {t('modal.deleteModal.single.invoiceQuestion', {
                fornecedor: `**${provider}**`,
                numero: `**${number}**`,
                serie: `**${series}**`,
              })}
            </ReactMarkdown>
          </Text>
          <Text color="text-50" type="ui-tiny-content">
            <ReactMarkdown>
              {t('modal.deleteModal.footer', {
                action: `**${t('modal.deleteModal.permanent')}**`,
              })}
            </ReactMarkdown>
          </Text>
        </Divflex>
      }
      onCancelClick={onCancelClick}
      okButtonColor="status-danger-base"
      okButtonName={t('common.delete')}
    />
  );
};
