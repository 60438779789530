import { useCallback } from 'react';
import { handleModalError } from '@/Utils/ErrorCodeUtils/ErrorHandlingUtils';
import { App } from 'antd';
import { ISupplierListData } from '@/Data/Interfaces/response/Purchase/ISupplierResponse';
import { cannotDeleteProviderWithMovementsInTheSystemProps } from '@/Utils/ErrorCodeUtils/ErrorModalUtils/ErrorModalProvider';
import { useTranslation } from 'react-i18next';
import { IUseFunctions } from './interfaces';
import { IEditSupplierStatusRequest } from '@/Data/Interfaces/request/Purchasing/Providers/IProviderRequest';

export const useListFunctions = ({
  setSelectedRowsOnTable,
  editSupplierStatus,
  deleteSupplier,
}: IUseFunctions) => {
  const { t } = useTranslation();
  const app = App.useApp();

  const onSelectedRowsOnTable = useCallback(
    (x: ISupplierListData[]) => setSelectedRowsOnTable(x),
    [setSelectedRowsOnTable]
  );

  const changeSupplierStatus = useCallback(
    async (values: IEditSupplierStatusRequest) => {
      var body: IEditSupplierStatusRequest = { ...values };

      await editSupplierStatus.mutateAsync({
        ...body,
      });
    },
    [editSupplierStatus]
  );

  const onEdit = useCallback(
    (provider: ISupplierListData[], values: any) =>
      changeSupplierStatus({
        fornecedoresExternalId: provider.map(
          (x: ISupplierListData) => x.externalId
        ),
        ativo: values.status === 'success',
      }),
    [changeSupplierStatus]
  );

  const onDelete = useCallback(
    async (supplier: ISupplierListData[]) => {
      await deleteSupplier.mutateAsync(
        {
          externalId: supplier.map((x) => x?.externalId),
        },
        {
          onError: handleModalError(
            app,
            cannotDeleteProviderWithMovementsInTheSystemProps(t)
          ),
        }
      );
    },
    [deleteSupplier]
  );

  return {
    onSelectedRowsOnTable,
    onEdit,
    onDelete,
  };
};
