import { IProductGroupListData } from "@/Data/Interfaces/response/Product/IProductRespose";
import { IUseMemorizations } from "./interfaces";

export const useInputProductMemorizations = ({
  productList
}: IUseMemorizations) => {
  const items = productList?.data?.map((value: IProductGroupListData) => ({
    id: value?.externalId,
    label: value?.descricao,
  }));

  return {
    items
  };
};