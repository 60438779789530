export const FETCH_GET_LOT = '[LOT] FETCH_GET_LOT';
export const GET_LOT_DETAIL_BY_STOCKLOCATION =
  '[SUPLLY_LOT] GET_LOT_DETAIL_BY_STOCKLOCATION';
export const FETCH_GET_BALANCE_ORIGIN_DESTINY =
  '[STOCK_BALANCE] FETCH_GET_BALANCE_ORIGIN_DESTINY';

export const FETCH_LIST_LOTS = '[LOT] FETCH_LIST_LOTS';
export const FETCH_LIST_STOCKLOCATIONS_OF_LOTS =
  '[LOT] FETCH_LIST_STOCKLOCATIONS_OF_LOTS';
export const FETCH_LIST_STATUS_LOTS = '[LOT] FETCH_LIST_STATUS_LOTS';
export const FETCH_LIST_LOTS_DROPDOWN = '[LOT] FETCH_LIST_LOTS_DROPDOWN';
export const FETCH_LIST_LOT_BY_PRODUCT_STOCKLOCATION_DROPDOWN_TYPE =
  '[SUPLLY_LOT] FETCH_LIST_LOT_BY_PRODUCT_STOCKLOCATION_DROPDOWN_TYPE';

export const CREATE_LOT = '[LOT] CREATE_LOT';
export const EDIT_LOT = '[LOT] EDIT_LOT';
export const DELETE_LOT = '[LOT] DELETE_LOT';
export const EDIT_STATUS_LOT = '[LOT] EDIT_STATUS_LOT';
