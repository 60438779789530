import { Col, Row } from 'antd';
import { CircularStatus } from '@/Components/CircularStatus';
import { WeightInput } from '@/Components/Inputs/WeightInput';
import { FETCH_LIST_UNIT_MEASUREMENT_FOR_DROPDOWN_TYPE } from '@/ControllerApiHook/UniqIds/Supply/UnitMeasurementKeys';
import { TextInput } from '@/Components';
import { TextInputWithMask } from '@/Components/Inputs/TextInputWithMask';
import DateUtils from '@/Utils/DateUtils';
import { Tooltip } from '@/Components/Tooltip';
import { useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import StorageUtils from '@/Utils/StorageUtils';
import { CONVERT_UNIT_OF_MEASURE } from '@/ControllerApiHook/UniqIds/Supply/SupplyKeys';
import {
  useControllerMutateApiHook,
  useControllerQueryListApiHook,
} from '@/ControllerApiHook/Controller';
import { UnitMeasurementAPI } from '@/Data/API/Supply/UnitMeasurementApi';
import { IIListUnitMeasurementAllData } from '@/Data/Interfaces/response/UnitMeasurement/IUnitMeasurementResponse';

import styles from './productRow.module.scss';
import { IPreferences } from '@/Utils/ThemeUtils';

interface IProductRowProps {
  product: any;
  index: number;
  selectedRow: number | null;
  selectRow: (rowNumber: number) => void;
  showTechnicalInformation: boolean;
  showTechInformation: () => void;
  lotsListController: any[];
  selectLotIndex: (index: number) => void;
}

interface ICalendarInputProps {
  name: string;
  placeHolder: string;
  label: string;
  error: boolean;
  errorMessage: string;
  onClick?: () => void;
  onChange?: (value: string) => void;
}

export const ProductRow: React.FC<IProductRowProps> = ({
  product,
  index,
  selectedRow,
  selectRow,
  showTechnicalInformation,
  showTechInformation,
  lotsListController,
  selectLotIndex,
}) => {
  const form: any = useFormikContext();

  const preferences = StorageUtils.getPreference();

  const { t } = useTranslation();
  const [lots, setLots] = useState<any[]>([]);

  const { mutateAsync: convertUnitOfMeasureAsync } = useControllerMutateApiHook(
    {
      uniqId: CONVERT_UNIT_OF_MEASURE,
      entityApi: UnitMeasurementAPI.convertUnitOfMeasure,
    }
  );

  const { data: unitOfMeasure } = useControllerQueryListApiHook({
    uniqId: FETCH_LIST_UNIT_MEASUREMENT_FOR_DROPDOWN_TYPE,
    entityApi: UnitMeasurementAPI.getListUnitMeasurementDropDown,
    pagination: {
      pageSize: 50,
      filter: [{ filterName: 'tipoUnidade', value: product.tipoUnidade }],
    },
  });

  const validateDates = (): boolean => {
    const formManufacturingDate = form.values.lotes[index]?.dataFabricacao;
    const formExpirationDate = form.values.lotes[index]?.dataValidade;
    const formReleaseDate = form.values.dataLancamento;

    if (formReleaseDate && formExpirationDate) {
      const expirationDate = parseDate(formExpirationDate);
      const releaseDate = parseDate(formReleaseDate);

      return releaseDate > expirationDate;
    }

    if (formManufacturingDate && formExpirationDate) {
      const manufacturingDate = parseDate(formManufacturingDate);
      const expirationDate = parseDate(formExpirationDate);
      return manufacturingDate > expirationDate;
    }
    return false;
  };

  useEffect(() => {
    const indexedLots = lotsListController.map((e, index) => {
      return { ...e, lotIndex: index };
    });
    const initialLots = indexedLots.filter(
      (e) => e.notaFiscalEntradaItemId === product.notaFiscalEntradaItemId
    );
    setLots(initialLots);
  }, [lotsListController]);

  useEffect(() => {
    const formatProductQuantity = async () => {
      if (lots.length) {
        const currentUnitId = product.unidadeMedidaId;
        const unitToConvert = lots[0].unidadeMedidaId;
        const quantityToConvert = product.quantidadeComprada;

        await convertUnitOfMeasureAsync([
          {
            quantidade: quantityToConvert,
            unidadeMedidaConversaoId: unitToConvert,
            unidadeMedidaOrigemId: currentUnitId,
          },
        ]);
      }
    };
    formatProductQuantity();
  }, [lots]);

  return (
    <>
      {lots.map((e, indexLot) => {
        return (
          <Row
            key={e.uuid}
            wrap={false}
            gutter={[16, 0]}
            className={`${styles['productRow']} ${
              selectedRow === e.lotIndex ? styles['selectedRow'] : ''
            }`}
            onClick={() => {
              selectRow(e.lotIndex);
              showTechInformation();
              selectLotIndex(e.lotIndex);
            }}
          >
            <Col span={showTechnicalInformation ? 15 : 10}>
              <div
                className={styles['productMainInfo']}
                style={{ display: indexLot !== 0 ? 'none' : '' }}
              >
                <CircularStatus
                  icon={defineIconToProductRow('check')}
                  type={defineColorTypeToProductRow('check')}
                  color={defineColorTypeToProductRow('check')}
                />
                <div className={styles['textContainer']}>
                  <p className={styles['productDescription']}>
                    {product.produtoDescricao}
                  </p>
                  <p className={styles['productQuantity']}>
                    {formatQuantity(
                      product.quantidadeComprada,
                      preferences ? preferences?.idioma : 'pt-BR'
                    )}{' '}
                    {product.unidadeMedidaAbreviacao}
                  </p>
                </div>
              </div>
            </Col>
            <Col
              span={showTechnicalInformation ? 4 : 3}
              className={styles['alignVerticalContent']}
              onClick={(e) => e.stopPropagation()}
            >
              <WeightInput
                disabled
                name={`lotes.${e.lotIndex}.quantidade`}
                dropdownRight={{
                  options: unitOfMeasure
                    ? unitOfMeasure?.data?.map(
                        (e: IIListUnitMeasurementAllData) => ({
                          ...e,
                          content: e.unidadeAbreviacao,
                        })
                      )
                    : [],
                  name: `lotes.${e.lotIndex}.unidadeMedidaId`,
                }}
                placeHolder="0,0000"
                required
              />
            </Col>
            <Col
              span={showTechnicalInformation ? 5 : 3}
              className={styles['alignVerticalContent']}
              onClick={(e) => e.stopPropagation()}
            >
              <TextInput
                disabled
                name={`lotes.${e.lotIndex}.numeroLote`}
                placeHolder={t(
                  'purchasing.invoice.registerLots.lotPlaceHolder'
                )}
              />
            </Col>
            <Col
              span={3}
              className={`${styles['alignVerticalContent']} ${
                showTechnicalInformation ? styles['hideInformation'] : ''
              }`}
              onClick={(e) => e.stopPropagation()}
            >
              <CalendarInput
                name={`lotes.${e.lotIndex}.dataFabricacao`}
                placeHolder={configDatePlaceHolder(preferences) as string}
                error={validateDates()}
                errorMessage={t(
                  'purchasing.invoice.registerLots.manufacturingDateError'
                )}
                label=""
              />
            </Col>
            <Col
              span={3}
              className={`${styles['alignVerticalContent']} ${
                showTechnicalInformation ? styles['hideInformation'] : ''
              }`}
              onClick={(e) => e.stopPropagation()}
            >
              <CalendarInput
                name={`lotes.${e.lotIndex}.dataValidade`}
                placeHolder={configDatePlaceHolder(preferences) as string}
                error={validateDates()}
                errorMessage={t(
                  'purchasing.invoice.registerLots.expiryDateError'
                )}
                label=""
              />
            </Col>
          </Row>
        );
      })}
    </>
  );
};

const formatQuantity = (numero: number, language?: string) => {
  return numero.toLocaleString(language, {
    minimumFractionDigits: 4,
    maximumFractionDigits: 4,
  });
};

const configDatePlaceHolder = (preference: IPreferences) => {
  if (preference) {
    return DateUtils.getDatePlaceholder(
      preference?.idioma,
      preference?.padraoData
    );
  }
};

const CalendarInput = ({
  name,
  placeHolder,
  label,
  error,
  errorMessage,
  onClick,
  onChange,
}: ICalendarInputProps) => {
  return (
    <div onClick={() => onClick?.()}>
      <Tooltip title={errorMessage} showMe={error}>
        <TextInputWithMask
          disabled
          name={name}
          leftIcon="calendar"
          placeHolder={placeHolder}
          required
          label={label}
          mask="dataMask"
          error={error}
          onChange={(value) => onChange?.(value)}
        />
      </Tooltip>
    </div>
  );
};

const parseDate = (date: string) => {
  const parts = date.split('/');
  return new Date(+parts[2], +parts[1] - 1, +parts[0]);
};

const defineIconToProductRow = (status: 'default' | 'error' | 'check'): any => {
  const icon = {
    default: 'time',
    error: 'close-x',
    check: 'check',
  };

  return icon[status];
};

const defineColorTypeToProductRow = (
  status: 'default' | 'error' | 'check'
): any => {
  const type = {
    default: 'default',
    error: 'danger',
    check: 'success',
  };

  return type[status];
};
