import { history } from '@/Store';
import { useLocation } from 'react-router-dom';
import { ClientAPI } from '@/Data/API/People/ClientApi';
import {
  messageErrorToast,
  messageSucessToast,
} from '@/Utils/MessageErrorToast';
import { i18n } from '@/Components';
import { useControllerMutateApiHook } from '@/ControllerApiHook/Controller';
import { CREATE_CLIENT } from '@/ControllerApiHook/UniqIds/People/ClientKeys';

export const useCreateCallAPI = () => {
  const location = useLocation();

  const createClient: any = useControllerMutateApiHook({
    uniqId: CREATE_CLIENT,
    entityApi: ClientAPI.createClient,
    options: {
      onSuccess: () => {
        messageSucessToast(i18n.t('client.messages.success.createClient'));

        const searchParams = new URLSearchParams(location.search);

        const hasParams = [
          'nome',
          'cpf',
          'clientType',
          'razaoSocial',
          'cnpj',
        ].some((param) => searchParams.has(param));

        history.push(hasParams ? '/sales/tickets' : '/sales/customers');
      },
      onError: (x) => messageErrorToast(x),
    },
  });

  return {
    createClient,
  };
};
