import { BodyHandler, TitleHandler } from '../Modal/deleteModal';
import { history } from '../../../../../../Store';
import { IDeleteModalTable } from '@/Components/Table';
import { useTranslation } from 'react-i18next';
import { IUseMemorizations } from './interfaces';
import { useMemo } from 'react';
import { PermissionEnum } from '@/Data/Interfaces/model/permissionEnum';

export const useInvoiceListMemorizations = ({
  selectedRowsOnTable,
}: IUseMemorizations) => {
  const { t } = useTranslation();
  const draftStatus = 1;

  const headerOptions = {
    hasSearch: true,
    newButton: {
      onClick: () => history.push('/order/invoice/create'),
      label: 'purchasing.invoice.newButton',
      permission: {
        permission: PermissionEnum.Compras_NotaFiscalEntrada_Cadastrar,
        tooltip: 'noPermission.purchasing.invoice.create',
      },
    },
    searchPlaceholder: t('purchasing.invoice.list.searchPlaceholder'),
  };

  const rowOptions = {
    permission: {
      permission: PermissionEnum.Compras_NotaFiscalEntrada_VerDetalhes,
      tooltip: 'noPermission.purchasing.invoice.detail',
    },
    onRowDoubleClick: (x: any) => {
      if (x.status === draftStatus) {
        return history.push(`/order/invoice/create/${x.externalId}/draft`);
      }
      history.push(`/order/invoice/${x.externalId}/details`);
    },
  };

  const deleteModal = useMemo(
    (): IDeleteModalTable =>
      selectedRowsOnTable
        ? {
            title: TitleHandler(selectedRowsOnTable, t),
            body: BodyHandler(selectedRowsOnTable, t),
             
            buttons: {
              okButtonColor: 'status-danger-base',
            },
          }
        : {},
    [selectedRowsOnTable, t]
  );

  return {
    headerOptions,
    rowOptions,
    deleteModal,
  };
};
