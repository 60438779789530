import { AxiosResponse } from 'axios';
import { IBadRequestResponse } from '../Data/Interfaces/response/Base/IBase';

export function LocalizaPropriedadePorPath(obj: any, path: string) {
  if (!path) return;

  if (Object.keys(obj).length === 0) {
    return undefined;
  }
  var paths = path.split('.'),
    current = obj,
    i;

  for (i = 0; i < paths.length; ++i) {
    if (current[paths[i]] === undefined) {
      return undefined;
    } else {
      current = current[paths[i]];
    }
  }
  return current;
}

export function GetFieldErrors(response: AxiosResponse<IBadRequestResponse>) {
  if (response.status === 400) {
    const { fieldErrors } = response.data;

    if (!fieldErrors) return undefined;
    const obj: any = {};

    fieldErrors.forEach((field) => {
      obj[field.fieldName.toLowerCase()] = field.message;
    });

    return obj;
  }

  return undefined;
}
