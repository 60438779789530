import { FC } from 'react';
import { Footer as ModalFooter } from '@/Components/Modal/Components/Footer';
import {
  IGlobalModal,
} from '@/Utils/ErrorCodeUtils/ErrorHandlingUtils';
import { useTranslation } from 'react-i18next';

export const Footer: FC<{
  params: { produtosExternalId: string[] };
  instance: IGlobalModal;
}> = ({ instance }) => {
  const { t } = useTranslation();

  return (
    <ModalFooter
      onCancelClick={instance.destroy}
      withoutOkButton
      cancelButtonName={t('common.close')}
    />
  );
};