import React, { FC } from 'react';
import './Dashboard.css';

const Dashboard: FC = () => {
    return (
        <div className='App'>
            <header
                className='App-header'
                style={{ backgroundColor: 'var(--color-background-01)' }}
            >
                <h1>Dashboard</h1>
            </header>
        </div>
    );
};

export default Dashboard;
