import { TextArea } from '@/Components';
import { Col, Row } from 'antd';
import { useTranslation } from 'react-i18next';

export const RowFive: React.FC = () => {
  const { t } = useTranslation();

  return (
    <Row gutter={[16, 0]} style={{ marginBottom: '20px' }}>
      <Col span={24}>
        <TextArea
          name="informacoesComplementares"
          label={t('purchasing.invoice.create.complementaryInformation')}
          placeholder={t(
            'purchasing.invoice.create.placeHolder.complementaryInformation'
          )}
          maxLength={5000}
          rows={1}
          heightTextArea={100}
        />
      </Col>
    </Row>
  );
};
