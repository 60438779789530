import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Form } from '../../../../Components';
import { TextInputPassword } from '../../../../Components/Inputs/TextInputPassword';
import { TableLayout } from '../../../../Components/Tabs/TablePreference';
import { IChangePasswordRequest } from '../../../../Data/Interfaces/request/User/IUserRequest';
import { SecuritySchema } from './AccountSecurity.schema';
import { FormikHelpers, FormikValues } from 'formik';
import { messageErrorToast } from '@/Utils/MessageErrorToast';
import { useControllerMutateApiHook } from '@/ControllerApiHook/Controller';
import { CHANGE_USER_PASSWORD } from '@/ControllerApiHook/UniqIds/Accountmanager/UserKeys';
import { UserAPI } from '@/Data/API/Accountmanager/UserApi';

import styles from './AccountSecurity.module.scss';

export const AccountSecurity: React.FC = () => {
  const { t } = useTranslation();

  const changePassword = useControllerMutateApiHook({
    uniqId: CHANGE_USER_PASSWORD,
    entityApi: UserAPI.changePassword,
  });

  const onSubmit = async (
    values: any,
    helpers: FormikHelpers<FormikValues>
  ) => {
    const body: IChangePasswordRequest = { ...values };

    try {
      await changePassword.mutateAsync(body);
    } catch {
      messageErrorToast();
    } finally {
      helpers.setSubmitting(false);
    }
  };

  return (
    <TableLayout
      title={t('accountManager.accountSecurityPage.title')}
      description={t('accountManager.accountSecurityPage.description')}
      body={
        <Form onSubmit={onSubmit} schema={SecuritySchema}>
          <div className={styles['divSecurity']}>
            <TextInputPassword
              className={styles['input']}
              name="oldPassword"
              placeHolder={t('accountManager.accountSecurityPage.oldPassword')}
              label={t('accountManager.accountSecurityPage.oldPassword')}
              withoutMarginBottom
            />
            <TextInputPassword
              className={styles['input']}
              name="newPassword"
              placeHolder={t('accountManager.accountSecurityPage.newPassword')}
              label={t('accountManager.accountSecurityPage.newPassword')}
              withoutMarginBottom
            />
            <TextInputPassword
              className={styles['last-input']}
              name="confirmPassword"
              placeHolder={t(
                'accountManager.accountSecurityPage.confirmPassword'
              )}
              label={t('accountManager.accountSecurityPage.confirmPassword')}
              withoutMarginBottom
            />
            <Button
              type="primary"
              children={t('accountManager.accountSecurityPage.securityButton')}
              htmlType="submit"
              loading={changePassword.isLoading}
            />
          </div>
        </Form>
      }
    />
  );
};
