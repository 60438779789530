import { Popover as AntdPopover } from 'antd';
import React, { CSSProperties, FC, ReactNode } from 'react';

import './PopOver.override.scss';

export interface IPopOver {
  placement:
    | 'top'
    | 'left'
    | 'right'
    | 'bottom'
    | 'topLeft'
    | 'topRight'
    | 'bottomLeft'
    | 'bottomRight'
    | 'leftTop'
    | 'leftBottom'
    | 'rightTop'
    | 'rightBottom'
    | undefined;
  title?: any;
  content: ReactNode;
  trigger?: 'click' | 'hover' | 'focus' | string[];
  className?: string;
  children: ReactNode;
  visible?: boolean;
  onOpenChange?: (visible: boolean) => void;
  style?: CSSProperties;
  align?: number[];
  mouseEnterDelay?: number;
}

export const PopOver: FC<IPopOver> = ({
  placement,
  title,
  content,
  trigger,
  className,
  children,
  visible,
  onOpenChange,
  style,
  align,
  mouseEnterDelay,
}) => {
  return (
    <AntdPopover
      open={visible}
      onOpenChange={onOpenChange}
      className={className}
      placement={placement}
      title={title}
      content={content}
      trigger={trigger}
      style={style}
      align={{ targetOffset: align }}
      mouseEnterDelay={mouseEnterDelay}
    >
      {children}
    </AntdPopover>
  );
};
