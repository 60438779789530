import React, { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { TableLayout } from '../../../../Components/Tabs/TablePreference';
import ThemeMode from './Components/ThemeMode';
import { Text } from '../../../../Components/Text';
import { Themes } from '../../../../Utils/ThemeUtils';
import { useQueryClient } from '@tanstack/react-query';
import {
  useControllerMutateApiHook,
  useControllerQueryApiHook,
} from '@/ControllerApiHook/Controller';
import {
  CHANGE_USER_PREFERENCES,
  GET_ME,
} from '@/ControllerApiHook/UniqIds/Accountmanager/UserKeys';
import { UserAPI } from '@/Data/API/Accountmanager/UserApi';
import { IGetUserResponse } from '@/Data/Interfaces/response/User/IGetUserReponse';

import styles from './Personalization.module.scss';

export const Theme: FC = () => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  const { mutateAsync: changePreference, isLoading: changePreferenceLoading } =
    useControllerMutateApiHook({
      uniqId: CHANGE_USER_PREFERENCES,
      entityApi: UserAPI.patchCurrentUserPreference,
      options: { onSuccess: () => queryClient.invalidateQueries([GET_ME]) },
    });

  const { data: currentUser, isLoading: userLoading } =
    useControllerQueryApiHook<IGetUserResponse>({
      uniqId: GET_ME,
      entityApi: UserAPI.getMe,
    });

  const currentTheme = useMemo(
    () => currentUser?.preferencias.temaUsuario,
    [currentUser?.preferencias.temaUsuario]
  );

  const onSubmit = async (themeMode: Themes) => {
    await changePreference({ temaUsuario: themeMode });
  };

  return (
    <TableLayout
      title={t('preferences.themePage.title')}
      description={t('preferences.themePage.description')}
      body={
        <div className={styles['divThemes']}>
          <div>
            <ThemeMode
              theme="lightMode"
              onClick={() => onSubmit(Themes.lightMode)}
              check={currentTheme === Themes.lightMode}
              loading={changePreferenceLoading || userLoading}
            />
            <Text
              className={styles['text']}
              type="paragraph2"
              color="text-100"
              children={t('preferences.themePage.lightMode')}
            />
          </div>
          <div>
            <ThemeMode
              theme="midnightMode"
              onClick={() => onSubmit(Themes.midnightMode)}
              check={currentTheme === Themes.midnightMode}
              loading={changePreferenceLoading || userLoading}
            />
            <Text
              className={styles['text']}
              type="paragraph2"
              color="text-100"
              children={t('preferences.themePage.midnightMode')}
            />
          </div>
          <div>
            <ThemeMode
              theme="darkMode"
              onClick={() => onSubmit(Themes.darkMode)}
              check={currentTheme === Themes.darkMode}
              loading={changePreferenceLoading || userLoading}
            />
            <Text
              className={styles['text-last']}
              type="paragraph2"
              color="text-100"
              children={t('preferences.themePage.darkMode')}
            />
          </div>
        </div>
      }
    />
  );
};
