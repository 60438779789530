import { FETCH_SYNONYM_PRODUCT } from "@/ControllerApiHook/UniqIds/Supply/ProductKeys";
import { useQueryClient } from "@tanstack/react-query";
import { IUseFunctions } from "./interfaces";

export const useSynonymProductFunctions = ({
  deleteSynonym,
  setSelectedRowsOnTable
}: IUseFunctions) => {
  const queryClient = useQueryClient();

  const onDelete = async (invoice: any[]) => {
    const productsExternalId = invoice.map((e: any) => e.externalId);

    await deleteSynonym.mutateAsync({
      externalId: productsExternalId,
    });

    queryClient.invalidateQueries([FETCH_SYNONYM_PRODUCT]);
  };

  const selectRowsOnTable = (x: any) => setSelectedRowsOnTable(x ?? []);

  return {
    onDelete,
    selectRowsOnTable
  };
};