import { FETCH_LIST_MESSAGE_PRODUCT_DROPDOWN_TYPE } from '@/ControllerApiHook/UniqIds/Supply/ProductMessageKeys';
import { useControllerQueryListApiHook } from '@/ControllerApiHook/Controller';
import { ProductMessageAPI } from '@/Data/API/Supply/ProductMensage';

export const useRadioButtonMessageTypeCallAPI = () => {
  const {
    data: listMessageProductDropdown,
    isLoading: isLoadingMessageProductDropdown,
    fetchNewPage: fetchNewMessageProductDropdown,
    refetch: refetchMessageProductDropdown,
  } = useControllerQueryListApiHook({
    uniqId: FETCH_LIST_MESSAGE_PRODUCT_DROPDOWN_TYPE,
    entityApi: ProductMessageAPI.listMessageProductDropDown,
    pagination: {
      sorter: { column: 'descricao', direction: 'ASC' },
    },
    autoIncrement: true,
  });

  return {
    listMessageProductDropdown,
    isLoadingMessageProductDropdown,
    fetchNewMessageProductDropdown,
    refetchMessageProductDropdown,
  };
};
