import { CardTotal } from '../../../../Create/Components/TotalCard/Components/CardTotal';
import { TotalColumns } from './Components/TotalTableColumns';

export const PurchaseOrderTotalDetails = () => {
  return (
    <CardTotal>
      <TotalColumns />
    </CardTotal>
  );
};
