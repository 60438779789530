import { FC } from 'react';
import { Steps } from '@/Components/Steps';
import { 
  CardTotal 
} from '@/Pages/Sectors/Order/Purchase/Create/Components/TotalCard/Components/CardTotal';
import { useTimeLineMemorizations } from './Hooks/useTimeLineMemorizations';

export const TimeLineCard: FC = () => {
  const {
    items
  } = useTimeLineMemorizations();

  return (
    <div className="purchase-oder-time-line">
      <CardTotal style={{ overflow: 'auto', maxHeight: '65vh' }}>
        <Steps type="historical" items={items} />
      </CardTotal>
    </div>
  );
};
