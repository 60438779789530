import { 
  THERE_IS_MESSAGE_PRODUCT_TYPE 
} from '@/ControllerApiHook/UniqIds/Supply/ProductMessageKeys';
import { useControllerMutateApiHook } from '@/ControllerApiHook/Controller';
import { ProductMessageAPI } from '@/Data/API/Supply/ProductMensage';
import { IUseCallAPI } from './interfaces';

export const useProductMessageDescriptionCallAPI = ({
  onProductMessageError,
  setHasErrorAlreadyExistsProductName
}: IUseCallAPI) => {
  const { mutateAsync }: any = useControllerMutateApiHook({
    uniqId: THERE_IS_MESSAGE_PRODUCT_TYPE,
    entityApi: ProductMessageAPI.isMessageProductAlreadyRegistred,
    options: {
      onError: onProductMessageError,
      onSuccess: () => {
        setHasErrorAlreadyExistsProductName?.(false);
      }
    }
  });

  return {
    mutateAsync
  };
};