export const productClasses = {
  feedStock: 1,
  packaging: 2,
  finishedProduct: 3,
  service: 4,
  capsuleGranel: 5,
  useAndConsume: 6,
  capsuleType: 7,
};

export const disableTechnicalInformationLink = (productClassId?: number) => {
  if (productClassId === productClasses.feedStock) {
    return {
      incompatible: true,
      synonym: true,
      diluted: true,
      associated: true,
    };
  }

  if (
    productClassId === productClasses.finishedProduct ||
    productClassId === productClasses.capsuleGranel ||
    productClassId === productClasses.packaging ||
    productClassId === productClasses.capsuleType
  ) {
    return {
      incompatible: false,
      synonym: false,
      diluted: false,
      associated: true,
    };
  }

  if (
    productClassId === productClasses.service ||
    productClassId === productClasses.useAndConsume
  ) {
    return {
      incompatible: false,
      synonym: false,
      diluted: false,
      associated: false,
    };
  }

  return {
    incompatible: true,
    synonym: true,
    diluted: true,
    associated: true,
  };
};
