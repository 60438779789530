import React from 'react';
import { i18n, Link, Status } from '../../../../../Components';
import { LinkButton } from '../../../../../Components/Layouts/LinkButton';
import DateUtils from '../../../../../Utils/DateUtils';

enum StatusType {
  ative = 1,
}

export const conglomerateCollums = (t: any, datePatter: string) => [
  {
    title: t('adminBootis.conglomerateList.tableTitle.name'),
    dataIndex: 'nome',
    key: 'nome',
    sortColumn: 'nome',
    sorter: true,
    isHighlightable: true,
    width: '26%',
    defaultSortOrder: 'descend',
    render: (x: any, row: any) => (
      <Link
        type="ui-tiny-bold"
        to={`/admin-bootis/conglomerate/${row.externalId}/details`}
        style={{ width: 'fit-content' }}
      >
        {x}
      </Link>
    ),
  },
  {
    title: t('adminBootis.conglomerateList.tableTitle.headquarters'),
    dataIndex: 'matriz',
    key: 'matriz',
    width: '22%',
    sortColumn: 'matriz',
    sorter: true,
    isHighlightable: true,
    render: (x: any, data: any) =>
      x && (
        <LinkButton
          link={`/admin-bootis/company/${data?.externalId}/details`}
          text={x}
        />
      ),
  },
  {
    title: t('adminBootis.conglomerateList.tableTitle.companys'),
    dataIndex: 'empresas',
    key: 'empresas',
    width: '18%',
    sortColumn: 'empresas',
    sorter: true,
    isHighlightable: true,
    render: (x: any, data: any) => (
      <LinkButton
        link={`/admin-bootis/conglomerate/${data?.externalId}/companys`}
        text={
          parseInt(x) === 1
            ? `${x} ${i18n.t('common.company')}`
            : `${x} ${i18n.t('common.companys')}`
        }
      />
    ),
  },
  {
    title: t('adminBootis.conglomerateList.tableTitle.inclusionDate'),
    dataIndex: 'dataInclusao',
    key: 'dataInclusao',
    width: '18%',
    sortColumn: 'dataInclusao',
    sorter: true,
    isHighlightable: true,
    render: (date: any) => DateUtils.convertDate(date, datePatter),
  },
  {
    title: t('userGroups.list.status'),
    dataIndex: 'ativo',
    key: 'ativo',
    sortColumn: 'ativo',
    width: '13%',
    isHighlightable: true,
    sorter: true,
    render: (status: any) => (
      <Status type={status === StatusType.ative ? 'success' : 'disabled'}>
        {i18n.t(
          `userGroups.list.statusTypes.${
            status === StatusType.ative ? 'active' : 'inactive'
          }`
        )}
      </Status>
    ),
  },
];
