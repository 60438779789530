import { CreatePackingClassification } from '@/Pages/Sectors/Production/PackingClassification/Create';
import FormPackingClassification from '@/Pages/Sectors/Production/PackingClassification/Form';
import PackingClassificationList from '@/Pages/Sectors/Production/PackingClassification/List';
import { Route, Switch } from 'react-router-dom';

export const PackingClassificationRoutes = () => (
  <Switch>
    <Route
      path="/production/packing-classification/create"
      component={CreatePackingClassification}
    />
    <Route
      path="/production/packing-classification/:externalId/:tab"
      component={FormPackingClassification}
    />
    <Route
      path="/production/packing-classification"
      component={PackingClassificationList}
    />
  </Switch>
);
