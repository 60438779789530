import { useMemo } from 'react';
import { IUseMemorizations } from './interfaces';
import { IListPharmaceuticalFormDropdownData } from '@/Data/Interfaces/response/PharmaceuticalForm/IPharmaceuticalFormResponse';

export const useCreateMemorizations = ({
  listPharmaceuticalForm,
}: IUseMemorizations) => {
  const pharmaceuticalFormList = useMemo(() => {
    return listPharmaceuticalForm?.data?.map(
      (item: IListPharmaceuticalFormDropdownData) => {
        return {
          id: item.externalId,
          label: item.descricao,
        };
      }
    );
  }, [listPharmaceuticalForm]);

  return {
    pharmaceuticalFormList,
  };
};
