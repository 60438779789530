import { FC } from 'react';
import { Col } from 'antd';
import { Label, Text } from '@/Components';
import { IBirthday } from './interfaces';
import { useTranslation } from 'react-i18next';
import DateUtils from '@/Utils/DateUtils';

export const Birthday: FC<IBirthday> = ({ prescriber, datePattern }) => {
  const { t } = useTranslation();

  return (
    <Col span={24}>
      <Label
        type="ui-tiny-content"
        color="text-200"
        children={t('prescribers.editPage.birthday')}
        withBottomMargin
      />
      <Text
        type="ui-tiny-content"
        color="text-50"
        children={DateUtils.convertDate(
          prescriber?.dataNascimento,
          datePattern?.padraoData
        )}
      />
    </Col>
  );
};
