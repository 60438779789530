import { IAddFinishedProductRequest } from '@/Data/Interfaces/request/Sales/Service/IServiceRequest';
import { convertCurrencyToInteger } from '@/Utils/Currency';
import { DiscountTypes } from '@/Utils/DiscountTypeDefault';
import { FormikContextType } from 'formik';
import _ from 'lodash';
import { useCallback } from 'react';

export const usePriceRowFunctions = () => {
  const changeTotalInput = useCallback(
    (form: FormikContextType<IAddFinishedProductRequest>, total?: number) => {
      const currentTotal = total ?? 0;

      const unitValue = form.values.valorUnitario ?? 0;

      const amount = form.values.quantidade ?? 0;

      const discountType = form.values.tipoDesconto;

      const lastValueWithoutDiscount = unitValue * amount;
      if (!lastValueWithoutDiscount) return;

      if (lastValueWithoutDiscount <= currentTotal) {
        form.setFieldValue('total', lastValueWithoutDiscount);
        form.setFieldValue('desconto', undefined);
      } else {
        let discount = 0;
        const currentDiscount = lastValueWithoutDiscount - currentTotal;

        if (amount > 0) discount = currentDiscount / amount;
        else discount = currentDiscount;

        if (discountType === 2) {
          form.setFieldValue('desconto', discount);
        } else {
          form.setFieldValue('desconto', (discount * 100) / unitValue);
        }
        if (currentTotal === 0) form.setFieldValue('total', 0);
      }
    },
    []
  );
  const changeTotalValue = useCallback(
    (
      form: FormikContextType<IAddFinishedProductRequest>,
      prop: {
        unitValue?: number;
        amount?: number;
        discount?: string;
      }
    ) => {
      const unitValue = prop.unitValue ?? form.values.valorUnitario ?? 0;

      const amount = prop.amount ?? form.values.quantidade ?? 1;

      const discount =
        prop.discount !== undefined
          ? convertCurrencyToInteger(prop.discount)
          : form.values.desconto ?? 0;

      const discountType = form && form.values.tipoDesconto;

      const totalWithoutDiscount = unitValue * amount;

      if (!totalWithoutDiscount) return;

      if (_.isNil(discountType))
        form.setFieldValue('total', unitValue * amount);
      else if (discountType === DiscountTypes.currency) {
        const totalDiscount = discount * amount;
        form.setFieldValue('total', totalWithoutDiscount - totalDiscount);
      } else {
        const discountPercentage = discount * unitValue;
        let discountValue = 0;
        if (discountPercentage) discountValue = discountPercentage / 100;

        const totalDiscountPercetage = discountValue * amount;
        form.setFieldValue(
          'total',
          totalWithoutDiscount - totalDiscountPercetage
        );
      }
    },
    []
  );
  return { changeTotalInput, changeTotalValue };
};
