import {
  TypeSupplierEnum,
  useListSupplier,
} from '@/Hooks/HooksServices/People/Supplier/useListSupplier';

export const useSupplierList = () => {
  const supplier = useListSupplier({
    fieldName: 'fornecedorExternalId',
    pagination: {
      pageSize: 10,
      pageIndex: 1,
      filter: [
        {
          filterName: 'tipoFornecedorId',
          value: TypeSupplierEnum.distributorId,
        },
        { filterName: 'tipoFornecedorId', value: TypeSupplierEnum.serviceId },
      ],
    },
  });

  return { supplier };
};
