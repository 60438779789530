import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Dropdown, Form } from '../../../../Components';
import { TableLayout } from '../../../../Components/Tabs/TablePreference';
import { languages, standardDate, standardTime } from './HardCode.items';
import DateUtils from '../../../../Utils/DateUtils';
import { CreateInitialValuesForPlaceAndTime } from './PlaceAndTimeUtils';
import { useQueryClient } from '@tanstack/react-query';

import styles from './PlaceAndTime.module.scss';
import {
  CHANGE_USER_PREFERENCES,
  GET_ME,
} from '@/ControllerApiHook/UniqIds/Accountmanager/UserKeys';
import { UserAPI } from '@/Data/API/Accountmanager/UserApi';
import { IGetUserResponse } from '@/Data/Interfaces/response/User/IGetUserReponse';
import {
  useControllerMutateApiHook,
  useControllerQueryApiHook,
} from '@/ControllerApiHook/Controller';

export const PlaceAndTime: FC = () => {
  const [buttonDisabled, setButtonDesabled] = useState(true);

  const { t } = useTranslation();
  const queryClient = useQueryClient();

  const { data: user } = useControllerQueryApiHook<IGetUserResponse>({
    uniqId: GET_ME,
    entityApi: UserAPI.getMe,
  });

  const changePreference = useControllerMutateApiHook({
    uniqId: CHANGE_USER_PREFERENCES,
    entityApi: UserAPI.patchCurrentUserPreference,
    options: { onSuccess: () => queryClient.invalidateQueries([GET_ME]) },
  });

  return (
    <TableLayout
      title={t('preferences.placeAndTimePage.title')}
      body={
        <Form
          initialValues={CreateInitialValuesForPlaceAndTime(user)}
          onSubmit={async (x) => await changePreference.mutateAsync(x)}
        >
          <div className={styles['container']}>
            <Dropdown
              name="idioma"
              items={languages}
              label={[
                {
                  typeLabel: 'ui-tiny-semibold',
                  colorLabel: 'text-50',
                  children: t('preferences.placeAndTimePage.selectLanguage'),
                },
              ]}
              onChange={() => setButtonDesabled(false)}
              withoutMarginBottom
            />
            <Dropdown
              name="padraoData"
              items={standardDate}
              label={[
                {
                  typeLabel: 'ui-tiny-semibold',
                  colorLabel: 'text-50',
                  children: t(
                    'preferences.placeAndTimePage.selectStandardDate'
                  ),
                },
              ]}
              onChange={() => setButtonDesabled(false)}
              withoutMarginBottom
            />
            <Dropdown
              name="padraoHora"
              items={standardTime}
              label={[
                {
                  typeLabel: 'ui-tiny-semibold',
                  colorLabel: 'text-50',
                  children: t(
                    'preferences.placeAndTimePage.selectStandardTime'
                  ),
                },
              ]}
              onChange={() => setButtonDesabled(false)}
              withoutMarginBottom
            />
            <Dropdown
              name="timeZone"
              items={DateUtils.getAllTimezones().map((timezone) => ({
                id: timezone.value,
                label: timezone.label,
              }))}
              label={[
                {
                  typeLabel: 'ui-tiny-semibold',
                  colorLabel: 'text-50',
                  children: t('preferences.placeAndTimePage.selectTimeZone'),
                },
              ]}
              onChange={() => setButtonDesabled(false)}
              withoutMarginBottom
            />
            <Button
              className={styles['button']}
              type="primary"
              children={t('preferences.placeAndTimePage.button')}
              disabled={buttonDisabled}
              htmlType="submit"
            />
          </div>
        </Form>
      }
    />
  );
};
