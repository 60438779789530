import { FC } from "react";
import { Col, Row } from 'antd';
import { TextInputNumber } from '@/Components/Inputs/TextInputNumber';
import { useTranslation } from "react-i18next";

export const Discounts: FC = () => {
  const { t } = useTranslation();

  return (
    <Row gutter={[16, 0]}>
      <Col flex="17%">
        <TextInputNumber
          name="descontoProdutosAcabados"
          label={[{ children: t('client.createPage.descProduct') }]}
          placeHolder="0,00"
          leftIcon={{ titleString: '%' }}
          withoutArrows
        />
      </Col>
      <Col flex="17%">
        <TextInputNumber
          name="descontoFormulas"
          label={[{ children: t('client.createPage.descFormula') }]}
          placeHolder="0,00"
          leftIcon={{ titleString: '%' }}
          withoutArrows
        />
      </Col>
    </Row>
  );
};