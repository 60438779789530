import { history } from '@/Store';
import { useQueryClient } from '@tanstack/react-query';
import { useControllerMutateApiHook } from '@/ControllerApiHook/Controller';
import {
  CREATE_POSOLOGY,
  FETCH_GET_POSOLOGY,
  FETCH_LIST_POSOLOGY,
} from '@/ControllerApiHook/UniqIds/Production/PosologyKeys';
import { PosologyAPI } from '@/Data/API/Production/Posology';

export const useCreateCallAPI = () => {
  const queryClient = useQueryClient();

  const {
    mutateAsync: createPosology,
    isLoading: isLoadingCreatePosology,
  }: any = useControllerMutateApiHook({
    uniqId: CREATE_POSOLOGY,
    entityApi: PosologyAPI.createPosology,
    options: {
      onSuccess: () => {
        queryClient.invalidateQueries([FETCH_LIST_POSOLOGY]);
        queryClient.invalidateQueries([FETCH_GET_POSOLOGY]);
        history.push('/production/posology');
      },
    },
  });

  return {
    createPosology,
    isLoadingCreatePosology,
  };
};
