import React, { FC, ReactNode, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Message } from '../../Components/Feedback/Message';
import { HeaderMenu } from '../../Components/HeaderMenu';
import { SideMenu } from '../../Components/SideMenu';
import { getMenuById } from '../../Components/SideMenu/SideMenu.items';
import StorageUtils from '../../Utils/StorageUtils';
import { PageLayout } from '../PageLayout';
import { IGetUserResponse } from '@/Data/Interfaces/response/User/IGetUserReponse';
import { Context, CurrentContextType } from '@/Contexts/context';

interface IWithSideBar {
  children: ReactNode;
  getMe?: IGetUserResponse;
}

const WithSideBar: FC<IWithSideBar> = ({ children, getMe }) => {
  const params: { pathname: string } = useLocation();

  const { currentContext } = React.useContext(Context) as CurrentContextType;

  const menu = useMemo(() => getMenuById(currentContext), [currentContext]);

  const current = params.pathname.split('/');

  const COLLAPSE_SIDEBAR = 'COLLAPSE-SIDEBAR';
  const [isCollapsed, setIsCollapsed] = useState(
    StorageUtils.GetItem(COLLAPSE_SIDEBAR) === 'true'
  );

  return (
    <div>
      <Message />
      <HeaderMenu
        logo="logo-bootys"
        logoCollapsed="simple-logo"
        isCollapsed={isCollapsed}
        getMe={getMe}
      />
      {current[1] === 'account-manager' ||
      current[1] === 'user-preference' ||
      current[1] === 'system-configurations' ? (
        <React.Suspense fallback={<span>Loading...</span>}>
          {children}
        </React.Suspense>
      ) : (
        <SideMenu
          items={menu?.items}
          onCollapsedChange={(collapsed) => setIsCollapsed(collapsed)}
          getMe={getMe}
        >
          <React.Suspense fallback={<span>Loading...</span>}>
            <PageLayout children={children} currentContext={currentContext} />
          </React.Suspense>
        </SideMenu>
      )}
    </div>
  );
};

export default WithSideBar;
