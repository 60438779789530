import { FC } from "react";
import { Row } from 'antd';
import { Provider } from "./Components/Provider";
import { CorporateName } from "./Components/CorporateName";
import { Cnpj } from "./Components/Cnpj";

export const IdentityInfo: FC = () => {
  return (
    <Row gutter={[16, 0]}>
      <Provider />
      <CorporateName />
      <Cnpj />
    </Row>
  );
};