import { history } from '@/Store';
import { useQueryClient } from '@tanstack/react-query';
import { useControllerMutateApiHook } from '@/ControllerApiHook/Controller';
import { LaboratoryAPI } from '@/Data/API/Production/Laboratory';
import {
  CREATE_LABORATORY,
  FETCH_LIST_FOR_LABORATORY,
} from '@/ControllerApiHook/UniqIds/Production/LaboratoryKeys';

export const useCreateCallAPI = () => {
  const queryClient = useQueryClient();

  const {
    mutateAsync: createLaboratory,
    isLoading: isLoadingCreateLaboratory,
  }: any = useControllerMutateApiHook({
    uniqId: CREATE_LABORATORY,
    entityApi: LaboratoryAPI.createLaboratory,
    options: {
      onSuccess: () => {
        queryClient.invalidateQueries([FETCH_LIST_FOR_LABORATORY]);
        history.push('/production/labs');
      },
    },
  });

  return {
    createLaboratory,
    isLoadingCreateLaboratory,
  };
};
