import { IUseMemorizations } from './interfaces';
import DateUtils from '@/Utils/DateUtils';
import { useTranslation } from 'react-i18next';

export const useEditMemorizations = ({
  transfers,
  preferences,
}: IUseMemorizations) => {
  const { t } = useTranslation();

  const initialValuesInfoCard = {
    ...transfers,
    localDeEstoqueOrigem: transfers
      ? `${transfers?.localDeEstoqueOrigem?.localEstoqueOrigemDescricao} (${transfers?.localDeEstoqueOrigem?.empresaOrigemDescricao})`
      : '',
    localEstoqueDestino: transfers
      ? `${transfers?.localEstoqueDestino?.localEstoqueDestinoDescricao} (${transfers?.localEstoqueDestino?.empresaDestinoDescricao})`
      : '',
    responsavel: transfers ? transfers?.responsavel?.usuarioExternalId : '',
    sequenciaNumeroTransferencia: transfers
      ? transfers?.sequenciaNumeroTransferencia
      : '',
    dataTransferencia: transfers
      ? `${DateUtils.convertDate(
          transfers?.dataTransferencia,
          preferences?.padraoData
        )} ${t('common.dateBreakApart')} ${DateUtils.convertDate(
          transfers?.dataTransferencia,
          preferences?.padraoHora
        )}`
      : '',
  };

  const initialValuesTransfersCard = {
    ...transfers,
    produtoResponse: transfers
      ? transfers?.transferenciaLoteItens?.map(
          (x) => x?.produtoResponse?.descricao
        )
      : '',

    loteResponse: transfers
      ? transfers?.transferenciaLoteItens?.map(
          (x) => `${x?.responseLote?.numero} / NF: ${x?.responseLote?.numeroNf}`
        )
      : '',

    movimentoEstoqueOrigem: transfers
      ? transfers?.transferenciaLoteItens?.map(
          (x) => x?.movimentoEstoqueOrigem?.saldoFinalOrigem
        )
      : '',

    quantidadeTransferida: transfers
      ? transfers?.transferenciaLoteItens?.map((x) => x.quantidadeTransferida)
      : '',

    movimentoEstoqueDestino: transfers
      ? transfers?.transferenciaLoteItens?.map(
          (x) => x?.movimentoEstoqueDestino?.saldoFinalDestino
        )
      : '',

    unidadeMedidaEstoqueDestino: transfers
      ? transfers?.transferenciaLoteItens?.map(
          (x) => x?.movimentoEstoqueDestino?.unidadeMedidaDestino?.abreviacao
        )
      : '',
  };
  return {
    initialValuesInfoCard,
    initialValuesTransfersCard,
  };
};
