import { history } from '@/Store';
import { useQueryClient } from '@tanstack/react-query';
import { useControllerMutateApiHook } from '@/ControllerApiHook/Controller';
import {
  CREATE_PRODUCT_MESSAGE,
  FETCH_GET_PRODUCT_MESSAGE,
  FETCH_LIST_PRODUCT_MESSAGE,
  THERE_IS_MESSAGE_PRODUCT_TYPE,
} from '@/ControllerApiHook/UniqIds/Supply/ProductMessageKeys';
import { ProductMessageAPI } from '@/Data/API/Supply/ProductMensage';
import { useTranslation } from 'react-i18next';
import { IUseCallAPI } from './interfaces';

export const useCreateCallAPI = ({ form }: IUseCallAPI) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  const {
    mutateAsync: createProductMessage,
    isLoading: isLoadingCreateProductMessage,
  }: any = useControllerMutateApiHook({
    uniqId: CREATE_PRODUCT_MESSAGE,
    entityApi: ProductMessageAPI.createProductMessage,
    options: {
      onSuccess: () => {
        queryClient.invalidateQueries([FETCH_LIST_PRODUCT_MESSAGE]);
        queryClient.invalidateQueries([FETCH_GET_PRODUCT_MESSAGE]);
        history.push('/inventory/product-message');
      },
    },
  });

  const { mutateAsync: messageProductAlreadyRegistred }: any =
    useControllerMutateApiHook({
      uniqId: THERE_IS_MESSAGE_PRODUCT_TYPE,
      entityApi: ProductMessageAPI.isMessageProductAlreadyRegistred,
      options: {
        onSuccess: () => {
          form?.setErrors({
            ...form?.errors,
            mensagem: undefined,
          });
        },
        onError: () => {
          form?.setErrors({
            ...form?.errors,
            mensagem: t(
              'supply.productMessage.create.erros.errorMessageNameExist'
            ),
          });
        },
      },
    });

  return {
    createProductMessage,
    isLoadingCreateProductMessage,
    messageProductAlreadyRegistred,
  };
};
