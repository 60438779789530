import React from 'react';
import { TableLayout } from '../../../Components/Tabs/TablePreference';

const Personalization: React.FC = () => {
	return (
		<TableLayout
			title='personalization'
		/>
	)
}

export default Personalization;