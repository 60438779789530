import { useState } from "react";

export const useProductMessageDescriptionStates = () => {
  const [
    hasErrorAlreadyExistsProductName,
    setHasErrorAlreadyExistsProductName,
  ] = useState<boolean>(false);

  return {
    hasErrorAlreadyExistsProductName,
    setHasErrorAlreadyExistsProductName
  };
};