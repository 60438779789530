import React from 'react';
import { Table } from '../../../../../Components';
import { editTableStatus } from '../../../../../Utils/EditModalTableUtils';
import { userCollums } from './user.columns';
import { useUserListCallAPI } from './Hooks/useUserListCallAPI';
import { useUserListMemorizations } from './Hooks/useUserListMemorizations';
import { useUserListFunctions } from './Hooks/useUserListFunctions';
import { useTranslation } from 'react-i18next';
import { useUserListStates } from './Hooks/useUserListStates';
import { PageLoading } from '@/Layouts/PageLoading';

export const List: React.FC = () => {
  const { t } = useTranslation();

  const { 
    selectedRowsOnTable, 
    setSelectedRowsOnTable 
  } = useUserListStates();

  const { 
    userList, 
    changeUserStatus, 
    deleteUser 
  } = useUserListCallAPI();

  const { 
    onEdit, 
    onDelete 
  } =useUserListFunctions({
    changeUserStatus,
    deleteUser
  });

  const {
    headerOptions,
    rowOptions,
    editModal,
    deleteModal,
    confirmEditModal,
    translations,
  } = useUserListMemorizations({ selectedRowsOnTable });

  return (
    <PageLoading loading={userList.isLoading}>
      <Table
        columns={userCollums(t)}
        fieldStatus="ativo"
        headerOptions={headerOptions}
        loading={userList.isLoading}
        hasSelection
        rowsOptions={rowOptions}
        pagination={userList.data}
        onRefetch={userList.refetch}
        editOptions={editTableStatus()}
        onEdit={onEdit}
        onDelete={onDelete}
        selectedRowsOnTable={setSelectedRowsOnTable}
        editModal={editModal}
        deleteModal={deleteModal}
        editStatusModalLoading={changeUserStatus.isLoading}
        confirmEditModal={confirmEditModal}
        translations={translations}
      />
    </PageLoading>
  );
};
