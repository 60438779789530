import { FC, ReactNode } from 'react';
import { useParams } from 'react-router-dom';
import { Link } from '..';
import { history } from '../../Store';
import { UserPreferenceLayout } from '../Layouts';

import styles from './Tabs.module.scss';

interface IItemsTabs {
  label?: ReactNode;
  key: string;
  children?: ReactNode;
  disabled?: boolean;
  tabPermission?: number;
  dropdownLinks?: {
    label: string;
    href: string;
    permission?: number;
    permissionTooltip?: string;
    disabled?: boolean;
  }[];
}

export interface ITabs {
  items: IItemsTabs[];
  withoutTabs?: boolean;
}

export const Tabs: FC<ITabs> = ({ items }) => {
  const params: Readonly<Partial<{ tab: string | undefined }>> = useParams();

  return (
    <UserPreferenceLayout
      tabs={
        <table className={styles['divTab']}>
          <tbody>
            {items.map(
              (x) =>
                x.label && (
                  <tr className={styles['tab-tr']} key={`tabs-${x.key}`}>
                    {x.key === params.tab ? (
                      <td
                        className={styles['tab']}
                        onClick={() => x.key && history.push(x.key)}
                      >
                        <div className={styles['divColor']} />
                        <Link
                          to={x.key}
                          children={x.label}
                          type="ui-tiny-bold"
                          color="primary-base"
                        />
                      </td>
                    ) : (
                      <td
                        className={styles['tab']}
                        onClick={() => x.key && history.push(x.key)}
                        key={`tabs-${x.key}`}
                      >
                        <Link
                          to={x.key || ''}
                          children={x.label}
                          type="ui-tiny-bold"
                          color="text-50"
                        />
                      </td>
                    )}
                  </tr>
                )
            )}
          </tbody>
        </table>
      }
      children={items.map(
        (x) =>
          x.key === params.tab && (
            <div key={`children-tab-layout-${x.key}`}>{x.children}</div>
          )
      )}
    />
  );
};
