import { useFormikContext } from "formik";

export const usePelletsFunctions = () => {
  const form: any = useFormikContext();

  const onClick = () => {
    form.setFieldValue('isExcipiente', false);
    form.setFieldValue('isQsp', false);
  };

  return { onClick };
};