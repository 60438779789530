import { IListCompanyData } from '@/Data/Interfaces/response/Company/ICompanyResponse';
import { useState } from 'react';

export const useListStates = () => {
  const [rowSelected, setRowSelected] = useState<IListCompanyData[]>();

  return {
    rowSelected,
    setRowSelected,
  };
};
