import { IItems } from '@/Components/Inputs/InputWithSearch/Interface';
import { IListProviderData } from '../../../../../../Data/Interfaces/response/Provider/IProviderResponse';
import {
  ICfopListData,
  ICstListData,
  INcmListData,
} from '../../../../../../Data/Interfaces/response/Purchase/IInvoiceResponse';
import { INatureOfOperationListData } from '../../../../../../Data/Interfaces/response/Purchase/INatureOfOperationResponse';

export const listProviderConfig = (listProvider: IListProviderData[]) => {
  const newListProvider: any = listProvider;

  if (!newListProvider) return [];
  if (newListProvider.data) {
    const mapResult = newListProvider.data.map((value: any) => ({
      id: value.externalId,
      label: value.nome,
      subLabel: `${value.tipoPessoaDescricao} - ${value.tipoFornecedor}`,
    }));

    return mapResult;
  }

  const mapResult = listProvider.map((value) => ({
    id: value.externalId,
    label: value.nome,
    subLabel: `${value.tipoPessoaDescricao} - ${value.tipoFornecedor}`,
  }));

  return mapResult;
};

export const configNcmList = (listNcm: INcmListData[]): IItems[] => {
  const newListNcm: any = listNcm;
  if (!listNcm) return [];

  if (newListNcm.data) {
    const mapResult = newListNcm.data.map((value: any) => ({
      id: value.id,
      label: `${value.codigo}`,
      subLabel: value.descricao,
    }));

    return mapResult;
  }

  const mapResult = listNcm.map((value) => ({
    id: value.id,
    label: `${value.codigo}`,
    subLabel: value.descricao,
  }));

  return mapResult;
};

export const configCfopList = (listCfop: ICfopListData[]): IItems[] => {
  const newListCfop: any = listCfop;

  if (!listCfop) return [];

  if (newListCfop.data) {
    const mapResult = newListCfop.data.map((value: any) => ({
      id: value.id,
      label: `${value.codigo}`,
      subLabel: value.descricao,
    }));

    return mapResult;
  }

  const mapResult = listCfop.map((value) => ({
    id: value.id,
    label: `${value.codigo}`,
    subLabel: value.descricao,
  }));

  return mapResult;
};

export const configCstList = (listCst: ICstListData[]): IItems[] => {
  const newListCst: any = listCst;

  if (!listCst) return [];

  if (newListCst.data) {
    const mapResult = newListCst.data.map((value: any) => ({
      id: value.id,
      label: `${value.codigo}`,
      subLabel: value.descricao,
    }));

    return mapResult;
  }

  const mapResult = listCst.map((value) => ({
    id: value.id,
    label: `${value.codigo}`,
    subLabel: value.descricao,
  }));

  return mapResult;
};

export const configNatureOfOperationList = (
  listNatureOfOperation: INatureOfOperationListData[]
): IItems[] => {
  const newListNatureOfOperation: any = listNatureOfOperation;
  if (!listNatureOfOperation) return [];

  if (newListNatureOfOperation.data) {
    const mapResult = newListNatureOfOperation.data.map((value: any) => ({
      id: value.id,
      label: `${value.descricao}`,
    }));

    return mapResult;
  }

  const mapResult = listNatureOfOperation.map((value) => ({
    id: value.id,
    label: `${value.descricao}`,
  }));

  return mapResult;
};

export const configInvoiceValues = (values: any) => {
  if (!values.valorBaseIcms) values.valorBaseIcms = 0;
  if (!values.valorIcms) values.valorIcms = 0;
  if (!values.valorBaseIcmsSubstituicao) values.valorBaseIcmsSubstituicao = 0;
  if (!values.valorIcmsSubstituicao) values.valorIcmsSubstituicao = 0;
  if (!values.valorFrete) values.valorFrete = 0;
  if (!values.valorSeguro) values.valorSeguro = 0;
  if (!values.valorDesconto) values.valorDesconto = 0;
  if (!values.valorOutrasDespesas) values.valorOutrasDespesas = 0;
  if (!values.valorIpi) values.valorIpi = 0;
  const newItemsValues = values.itens.map((item: any) => {
    return {
      ...item,
      baseCalculoIcms: item.baseCalculoIcms || 0,
      valorIcms: item.valorIcms || 0,
      valorIpi: item.valorIpi || 0,
      aliquotaIpi: item.aliquotaIpi || 0,
    };
  });

  return { ...values, itens: newItemsValues };
};

export const formatUnitNumberOnPurchaseOrderList = (value: number) => {
  const formattedValue = value.toFixed(4);

  return formattedValue.replace(/\.(\d+)$/, (_, p1) => {
    const zerosToAdd = 4 - p1.length;
    const zeros = '0'.repeat(zerosToAdd);
    return `.${p1}${zeros}`;
  });
};
