import { useControllerQueryListApiHook } from '@/ControllerApiHook/Controller';
import { FETCH_LIST_LOSSES } from '@/ControllerApiHook/UniqIds/Supply/LosseKeys';
import { LossesAPI } from '@/Data/API/Supply/LossesApi';

export const useListCallAPI = () => {
  const {
    data: lossesList,
    isLoading: isLoadingList,
    refetch: refetchLossesList,
  } = useControllerQueryListApiHook({
    uniqId: FETCH_LIST_LOSSES,
    entityApi: LossesAPI.getLossesList,
    pagination: {
      sorter: { column: 'dataPerda', direction: 'ASC' },
    },
  });

  return { lossesList, isLoadingList, refetchLossesList };
};
