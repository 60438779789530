import { IEditStorageLocationRequest } from '@/Data/Interfaces/request/StorageLocation/IStorageLocationRequest';
import { IUseFunctions } from './interfaces';
import { FormikHelpers, FormikValues } from 'formik';
import { useCallback } from 'react';
import _ from 'lodash';
import { handleModalError } from '@/Utils/ErrorCodeUtils/ErrorHandlingUtils';
import { App } from 'antd';

export const useEditFunctions = ({
  editStorageLocation,
  selectedOptionsStock,
  storageLocation,
  deleteStorageLocation,
  setVisibleModal
}: IUseFunctions) => {
  const app = App.useApp();

  const onSubmit = async (
    values: IEditStorageLocationRequest,
    helpers: FormikHelpers<FormikValues>
  ) => {
    var body: IEditStorageLocationRequest = { ...values, helpers };
    if (storageLocation) {
      return await editStorageLocation({
        localEstoqueExternalId: storageLocation?.externalId,
        empresaId: body.empresaId,
        descricao: body.descricao,
        tipoEstoque: !selectedOptionsStock
          ? storageLocation.tipoEstoque
          : selectedOptionsStock,
        helpers,
      });
    }
  };

  const onDelete = useCallback(async () => {
    try {
      if (!storageLocation) return;
      return await deleteStorageLocation(
        {
          locaisEstoqueExternalId: [storageLocation.externalId],
        },
        { onError: handleModalError(app) }
      );
    } catch {}

    setVisibleModal?.(false);
  }, [deleteStorageLocation, storageLocation, setVisibleModal]);

  return {
    onSubmit,
    onDelete,
  };
};
