import { TFunction } from 'react-i18next';
import { ICannotDeleteModalInfo } from '../ErrorHandlingUtils';

export const cannotDeleteProductMessagermWithMovementsInTheSystemProps = (
  t: TFunction<'translation', undefined>
): ICannotDeleteModalInfo => ({
  formName: 'descricao',
  oneErrorModal: {
    titleModal: `${t('common.modalCannotDelete.messageTitle')} ${t(
      'supply.productMessage.modalCannotDelete.messageTitle'
    )}`,
    subTitleModal: `${t('common.modalCannotDelete.messageSubTitle')} ${t(
      'supply.productMessage.modalCannotDelete.messageSubtitle'
    )}`,
    reasonTranslation: `${t(
      'supply.productMessage.modalCannotDelete.messageReasonCannotDelete'
    )}`,
  },
  manyErrorsModal: {
    entity: `${t('common.modalCannotDelete.messageTitle')} ${t(
      'supply.productMessage.modalCannotDelete.messageManyTitles'
    )}`,
    subTitleModal: `${t('common.modalCannotDelete.messageSubTitle')} ${t(
      'supply.productMessage.modalCannotDelete.messageManySubtitle'
    )}`,
    reasonTranslation: `${t(
      'supply.productMessage.modalCannotDelete.messageManyReasonCannotDelete'
    )}`,
  },
  hideObjCannotDelete: true,
});
