import { useFormikContext } from 'formik';
import { FC } from 'react';
import { InputLabel } from '@/Components/Inputs/LabelTotal';
import { useTranslation } from 'react-i18next';
import { ITotal } from './interfaces';
import { useTotalMemorizations } from './Hooks/useTotalMemorizations';

export const Total: FC<ITotal> = ({
  withSelectedRows,
  productsValue,
  purchaseOrder,
  hasProducts,
}) => {
  const { t } = useTranslation();
  const form: any = useFormikContext();

  const { labelValue, withTooltip, style } = useTotalMemorizations({
    withSelectedRows,
    productsValue,
    purchaseOrder,
    hasProducts,
  });

  return (
    <>
      <InputLabel
        labelTitle={t('purchasing.purchaseOrder.create.total')}
        labelValue={labelValue}
        withTooltip={withTooltip}
        withStatusIconInText={withSelectedRows && hasProducts && form.dirty}
        customSize={14}
        type="ui-tiny-semibold"
        style={style}
        labelTitleStyle={{
          display: 'flex',
          justifyContent: 'start',
        }}
      />
    </>
  );
};
