import { useControllerQueryListApiHook } from '@/ControllerApiHook/Controller';
import { LIST_DOCUMENT } from '@/ControllerApiHook/UniqIds/People/DocumentKeys';
import { DocumentAPI } from '@/Data/API/People/DocumentApi';

export const useDocumentCardCallAPI = () => {
  const { data: documentList, fetchNewPage: refetchNewPageDocument } =
    useControllerQueryListApiHook({
      uniqId: LIST_DOCUMENT,
      entityApi: DocumentAPI.listDocument,
      autoIncrement: true,
      pagination: {
        sorter: { column: 'nome', direction: 'ASC' },
      },
    });

  return { documentList, refetchNewPageDocument };
};
