import { useCallback } from "react";
import { v4 as uuidV4 } from 'uuid';
import { IUseFunctions } from "./interfaces";

export const useAssociateCapsuleFunctions = ({
  index,
  disabledCapsulesTypes,
  setDisabledCapsulesTypes,
  associateCapsulesListController,
  setAssociateCapsulesListController
}: IUseFunctions) => {
  const onChange = useCallback((value: string) => {
    const newDisabledCapsulesTypes = disabledCapsulesTypes.filter(
      (_: number, i: number) => i !== index
    );

    setDisabledCapsulesTypes([
      ...newDisabledCapsulesTypes,
      +value,
    ]);
    if (index === associateCapsulesListController.length - 1) {
      setAssociateCapsulesListController((prev: string[]) => [
        ...prev,
        uuidV4(),
      ]);
    }
  }, [
    index,
    disabledCapsulesTypes,
    setDisabledCapsulesTypes,
    associateCapsulesListController,
    setAssociateCapsulesListController
  ]);

  return { onChange };
};