import React, {
  FC,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { Table as AntdTable } from 'antd';
import { AddHighlight, AddPermission } from './Table.helpers';
import {
  IPagination,
  IPaginationInput,
  Pagination,
} from './components/Pagination';
import { AddDataLoading } from './components/Loading';
import { IHeaderTableOptions, TableHeader } from './components/HeaderTable';
import {
  DeleteModal,
  IDeleteModalTranslations,
} from './components/Modals/DeleteModal';
import './Table.override.scss';
import { Skeleton } from '..';
import { Color } from '../../Utils/ColorUtils';
import {
  EditStatusModal,
  IConfirmEdit,
  IEditModalTranslations,
} from './components/Modals/EditStatusModal';
import { hasPermission, IPermissionWrapper } from '../PermissionWrapper';
import _ from 'lodash';
import { statusTypes } from '../../Utils/StatusUtils';

export type { IPagination } from './components/Pagination';

interface IEditSelectedItem {
  id: any;
  name: string;
  status?: statusTypes | string;
}
export interface IColumn {
  title: ReactNode | string;
  dataIndex: string;
  key: string;

  actionItems?: IActionItem[];

  isHighlightable?: boolean;
  defaultSortOrder?: string;
  sortColumn?: string;

  width?: string | number;
  render?: (
    text: string,
    record: any,
    index: number,
    selectedRows?: any[]
  ) => ReactNode;
}

export interface ISorter {
  column: string;
  direction: 'ASC' | 'DESC';
}

export interface IQueryParams {
  pagination?: IPagination;
  sorter?: ISorter;
  select?: string;
  orderBy: string;
  filter?: string;
  expand?: IExpandParams[];
}

export interface IQuery extends Omit<IQueryParams, 'pagination'> {
  pagination?: IPagination;
}

export interface IExpandParams {
  expandTitle: string;
  sorter?: ISorter;
  select?: string;
  orderBy?: string;
  filter?: string;
  count?: boolean;
  pagination?: IPagination;
  expand?: IExpandParams[];
  custom?: string;
}

export interface IActionItem {
  name: string;
  onClick: (record: any) => void;
  onDoubleClick?: (record: any) => void;
}

export type editOptions = {
  id: any;
  name: string;
  status?: statusTypes;
  statusDarkmode?: boolean;
}[];

export interface IDeleteModalTable {
  body?: any;
  title?: string;
  customEndTitle?: string;
  buttons?: {
    okButtonColor?: Color;
    okButtonDataTestId?: string;
    cancelButtonDatTestId?: string;
  };
  okButtonName?: string;
  className?: string;
  widthModal?: number;
}

export interface ITranslationsTable {
  deleteModal?: IDeleteModalTranslations;
  editModal?: IEditModalTranslations;
}

export interface ITable {
  columns: IColumn[];
  headerOptions?: IHeaderTableOptions;
  editOptions?: editOptions;
  pagination?: IPaginationInput;
  paginationCustomExternalId?: string;
  loading?: boolean;

  hasSelection?: boolean;

  translations?: ITranslationsTable;

  className?: string;

  deleteModal?: IDeleteModalTable;

  editModal?: {
    body?: any;
    title?: string;
    className?: string;
    okButtonName?: string;
    okButtonDataTestId?: string;
    withForm?: boolean;
    disabledButtonFormNames?: string[];
    widthModal?: number;
  };
  editModalLoading?: boolean;
  editStatusModalLoading?: boolean;

  confirmEditModal?: IConfirmEdit;

  rowsOptions?: {
    permission?: IPermissionWrapper;
    onRowDoubleClick?: (record: any) => void;
    onRowClick?: (record: any) => void;
  };

  cleanSelectedRows?: boolean;
  selectedRowsOnTable?: (x: any) => void;
  fieldStatus?: string;
  onRefetch?: (pagination: IPagination) => void;
  onDelete?: (rows: any[]) => void;
  onEdit?: (rows: any[], selectedItem: IEditSelectedItem | any) => void;
  hideItemsPerPage?: boolean;
  alignPagination?: 'left' | 'center' | 'right' | '';
  disabledLines?: any[];
  keyToSelect?: string;
  initialRowsSelected?: any[];

  dataTestId?: string;
}

export const Table: FC<ITable> = ({
  columns,
  headerOptions,
  pagination,
  paginationCustomExternalId,
  loading,
  hasSelection,
  translations,
  editOptions,
  deleteModal,
  className,
  confirmEditModal,
  rowsOptions,
  onRefetch,
  onDelete,
  onEdit,
  selectedRowsOnTable,
  cleanSelectedRows,
  editModal,
  editModalLoading,
  editStatusModalLoading,
  fieldStatus,
  hideItemsPerPage,
  alignPagination,
  keyToSelect = '',
  disabledLines,
  initialRowsSelected,

  dataTestId,
}) => {
  const [search, setSearch] = useState<string>();
  const [hasRowSelected, setHasRowSelected] = useState(false);
  const [selectedRows, setSelectedRows] = useState<any[]>([]);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [status, setStatus] = useState<boolean | undefined>(undefined);

  useEffect(() => {
    if (!fieldStatus) return;
    if (selectedRows && selectedRows.length < 1) setStatus(undefined);
    else if (selectedRows?.every((x) => x[fieldStatus] === true))
      setStatus(true);
    else if (selectedRows?.every((x) => x[fieldStatus] === false))
      setStatus(false);
    else setStatus(undefined);
  }, [fieldStatus, selectedRows]);

  const closeModal = useCallback(() => {
    setShowEditModal(false);
    setTimeout(() => {
      setSelectedRows([]);
      setHasRowSelected(false);
    }, 1);
  }, []);

  const hasSorter = columns && columns.some((x) => x.sortColumn);

  let fixedColumns = columns;

  if (headerOptions?.hasSearch)
    fixedColumns = AddHighlight(columns, search, selectedRows);
  if (hasSorter)
    fixedColumns = fixedColumns.map((x) => ({
      ...x,
      sorter: !!x.sortColumn,
    }));

  const disableRow = (record: any) => {
    if (!disabledLines) return false;
    return disabledLines.some((e) => e[keyToSelect] === record[keyToSelect]);
  };

  const selectedRowsDefault = initialRowsSelected ? initialRowsSelected : [];

  const rowSelection = useMemo(
    () => ({
      selectedRowKeys: Array.from(
        new Set([
          ...selectedRows?.map((x) => x.key),
          ...selectedRowsDefault?.map((e) => e[keyToSelect]),
        ])
      ),
      onChange: (selectedRowKeys: React.Key[], selectedRows: any[]) => {
        setSelectedRows(selectedRows);
        setHasRowSelected(selectedRows.length > 0);
      },
      getCheckboxProps: (record: any) => ({
        disabled: disableRow(record),
      }),
    }),
    [selectedRows]
  );

  useEffect(() => {
    if (initialRowsSelected) {
      setSelectedRows([]);
    }
  }, [initialRowsSelected]);

  useEffect(() => {
    if (cleanSelectedRows) rowSelection.selectedRowKeys = [];
  }, [cleanSelectedRows, rowSelection]);

  const getColumns = useCallback(
    (loading?: boolean) => {
      const loadingColumns = [...fixedColumns].map((column) => ({
        ...column,
        render: undefined,
      }));
      return loading
        ? loadingColumns
        : AddPermission(fixedColumns, rowsOptions?.permission, selectedRows);
    },
    [fixedColumns, rowsOptions, selectedRows]
  );

  useEffect(() => {
    selectedRowsOnTable && selectedRowsOnTable(selectedRows);
  }, [selectedRows, selectedRowsOnTable]);

  const onSearching = _.debounce((searching: any) => {
    searching = searching.trim();
    setSearch(searching);

    const newPagination: any = {
      ...pagination,
      pageIndex: 0,
    };

    onRefetch &&
      onRefetch({
        sorter: pagination ? pagination.sorter : undefined,
        search: searching,
        pageIndex: newPagination.pageIndex,
        pageSize: (pagination && pagination.pageSize) || 10,
      });
  }, 1000);

  useEffect(() => {
    !editModalLoading && closeModal();
    !editStatusModalLoading && closeModal();
  }, [editModalLoading, editStatusModalLoading, closeModal]);

  return (
    <>
      <TableHeader
        {...headerOptions}
        editButton={
          headerOptions?.editButton && {
            ...headerOptions?.editButton,
            options: headerOptions?.editButton?.options?.map((x) => ({
              ...x,
              onClick: x.isStatusOnly
                ? () => setShowEditModal(true)
                : x.onClick,
            })),
          }
        }
        loading={!!loading}
        deleteButton={
          headerOptions?.deleteButton || deleteModal
            ? {
                ...headerOptions?.deleteButton,
                onClick: headerOptions?.deleteButton?.onClick
                  ? headerOptions?.deleteButton?.onClick
                  : () => setShowDeleteModal(true),
              }
            : undefined
        }
        hasSomeItemSelected={hasRowSelected}
        onRefetch={onSearching}
      />
      <AntdTable
        data-testid={dataTestId}
        rowClassName={(row) => {
          if (!keyToSelect || !disabledLines) return '';
          let disabledStyle = '';
          disabledLines.forEach((e) => {
            if (e[keyToSelect] === row[keyToSelect]) disabledStyle = 'disabled';
          });
          return disabledStyle;
        }}
        className={`table ${className} ${!pagination ? 'not-pagination' : ''} ${
          hasSelection ? 'with-selection' : ''
        }`}
        rowSelection={
          hasSelection
            ? {
                type: 'checkbox',
                renderCell: loading
                  ? () => {
                      return <Skeleton />;
                    }
                  : undefined,
                ...rowSelection,
              }
            : undefined
        }
        columns={getColumns(loading) as any}
        showSorterTooltip={false}
        onChange={(_, filters, sorter) => {
          const sort = Array.isArray(sorter) ? sorter[0] : sorter;
          const hasSort = !!sort.column;
          const column = sort.column as any;

          const pag = {
            Search: search,
            pageIndex: (pagination && pagination.pageIndex - 1) || 0,
            pageSize: (pagination && pagination.pageSize) || 10,
          };

          const tmp: ISorter | undefined = hasSort
            ? {
                column: column?.sortColumn as string,
                direction: sort.order === 'ascend' ? 'ASC' : 'DESC',
              }
            : undefined;
          onRefetch && onRefetch({ ...pag, sorter: tmp });
        }}
        dataSource={
          loading
            ? pagination
              ? AddDataLoading(columns, pagination.pageSize)
              : AddDataLoading(columns, 3)
            : pagination?.data?.map((value: any) => ({
                ...value,
                key: `table_item_${
                  paginationCustomExternalId
                    ? value[paginationCustomExternalId]
                    : value.externalId
                }`,
              }))
        }
        pagination={false}
        onRow={(record: any) => ({
          onClick: hasPermission(rowsOptions?.permission?.permission)
            ? rowsOptions &&
              rowsOptions?.onRowClick &&
              rowsOptions?.onRowClick.bind({}, record)
            : undefined,
          onDoubleClick: hasPermission(rowsOptions?.permission?.permission)
            ? rowsOptions &&
              rowsOptions?.onRowDoubleClick &&
              rowsOptions?.onRowDoubleClick.bind({}, record)
            : undefined,
        })}
      />
      {pagination ? (
        <Pagination
          loading={!!loading || false}
          onRefetch={(pagination) => {
            onRefetch && onRefetch({ ...pagination, search: search });
          }}
          {...(pagination || {})}
          hideItemsPerPage={hideItemsPerPage}
          alignPagination={alignPagination}
        />
      ) : null}
      <DeleteModal
        visible={showDeleteModal}
        itemNames={
          selectedRows &&
          selectedRows.map((x) => [
            headerOptions?.nameKey && x[headerOptions.nameKey],
            headerOptions?.lastNameKey && x[headerOptions.lastNameKey],
          ])
        }
        translations={translations?.deleteModal}
        onCancelClick={() => setShowDeleteModal(false)}
        onOkClick={() => {
          onDelete && onDelete(selectedRows!);
          setShowDeleteModal(false);
          setSelectedRows([]);
          setHasRowSelected(false);
        }}
        cancelButtonDataTestId={deleteModal?.buttons?.cancelButtonDatTestId}
        okButtonDataTestId={deleteModal?.buttons?.okButtonDataTestId}
        okButtonColor={deleteModal?.buttons?.okButtonColor}
        body={deleteModal?.body}
        title={deleteModal?.title}
        customEndTitle={deleteModal?.customEndTitle}
        className={deleteModal?.className}
        okButtonName={deleteModal?.okButtonName}
        widthModal={deleteModal?.widthModal}
      />

      <EditStatusModal
        visible={showEditModal}
        items={editOptions}
        translations={translations?.editModal}
        confirmationModal={confirmEditModal}
        disabledButtonFormNames={editModal?.disabledButtonFormNames}
        itemNames={
          selectedRows &&
          selectedRows.map((x) =>
            headerOptions?.nameKey
              ? `${x[headerOptions.nameKey]}${
                  headerOptions?.lastNameKey
                    ? ` ${x[headerOptions.lastNameKey]}`
                    : ''
                }`
              : ''
          )
        }
        onCancelClick={() => setShowEditModal(false)}
        onOkClick={(selectedItem) =>
          onEdit && onEdit(selectedRows!, selectedItem)
        }
        okButtonName={editModal?.okButtonName}
        withForm={editModal?.withForm}
        body={editModal?.body}
        title={editModal?.title}
        statusSelectedOnRow={status}
        loading={editStatusModalLoading}
        widthModal={editModal?.widthModal}
        dataTestIdOkButton={editModal?.okButtonDataTestId}
      />
    </>
  );
};
