import { IconName } from "@/Components/Icon/IconClasses";
import { useState } from "react";

export const useInfoCardStates = () => {
  const [
    externalIdProvider, 
    setExternalIdProvider
  ] = useState<string>();

  const [
    iconContact, 
    setIconContact
  ] = useState<IconName>();

  return {
    externalIdProvider,
    setExternalIdProvider,
    iconContact,
    setIconContact
  };
};