import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { currencyFormater } from '@/Utils/Currency';
import { IFinalValue } from './interfaces';
import { InputLabel } from '@/Components/Inputs/LabelTotal';
import { useFinalValueMemorizations } from './Hooks/useFinalValueMemorizations';

export const FinalValue: FC<IFinalValue> = ({
  orderPurchase,
  totalValueOrderPurchase
}) => {
  const { t } = useTranslation();

  const {
    withStatusIconInLabel,
    withTooltip
  } = useFinalValueMemorizations({
    orderPurchase,
    totalValueOrderPurchase
  });

  return (
    <>
      <InputLabel
        labelTitle={t(
          'purchasing.purchaseOrder.create.labelTotalValueName'
        )}
        labelValue={currencyFormater(totalValueOrderPurchase)}
        withStatusIconInLabel={withStatusIconInLabel}
        withTooltip={withTooltip}
        customSize={24}
      />
    </>
  );
};