import { useFormikContext } from "formik";
import { useCallback } from "react";
import { IUseFunctions } from "./interfaces";

export const useUnitaryValueFunctions = ({
  index,
  convertUnitOfMeasureMonetaryValue
}: IUseFunctions) => {
  const form = useFormikContext<any>();

  const onUnitOfMeasureChange = useCallback(
    async (selectedId: any) => {
      const currentUnitOfMeasureId =
        form?.values?.pedidoCompraItens?.[index]?.unidadeMedidaId;
      const quantity = form?.values?.pedidoCompraItens?.[index]?.precoUnitario;
      const currentQuantity =
        form?.values?.pedidoCompraItens?.[index]?.precoUnitario;

      const result = await convertUnitOfMeasureMonetaryValue([
        {
          quantidade: currentQuantity || 0,
          unidadeMedidaConversaoId: selectedId,
          unidadeMedidaOrigemId: currentUnitOfMeasureId,
        },
        {
          quantidade: quantity || 0,
          unidadeMedidaConversaoId: selectedId,
          unidadeMedidaOrigemId: currentUnitOfMeasureId,
        },
      ]);

      if (result && result.length > 0) {
        const quantity = result[1].quantidadeConvertida;

        form.setFieldValue(
          `pedidoCompraItens.${index}.precoUnitario`,
          quantity
        );
      }
    },
    [
      convertUnitOfMeasureMonetaryValue,
      form?.values?.pedidoCompraItens?.[index]?.precoUnitario,
    ]
  );

  return {
    onUnitOfMeasureChange
  };
};