import { FC } from "react";
import { Col } from 'antd';
import { TextInput } from '@/Components';
import { useTranslation } from "react-i18next";

export const Name: FC = () => {
  const { t } = useTranslation();
  
  return (
    <Col flex="46%">
      <TextInput
        name="nomeCompleto"
        placeHolder={`${t(
          'common.exampleAbbreviation'
        )}: Marcos Almeida`}
        label={t('prescribers.createPage.info.name')}
        required
      />
    </Col>
  );
};