import { useTranslation } from 'react-i18next';
import { List as ProductList } from '../Components/ProductForm';
import { DetailsForm } from '../Components/Details';
import { IUseMemorizations } from './interfaces';
import { IButtonDropDownItems } from '@/Components/Button/ButtonDropdown';
import { history } from '@/Store';
import { IPermissionEditButon } from '@/Components/HorizontalTabs';

export const useEditMemorizations = ({
  disabled,
  setDisabled,
  setModalDeleteSubGroup,
  data,
}: IUseMemorizations) => {
  const { t } = useTranslation();

  const items = [
    {
      label: t('admin.editUserPage.tab.details'),
      key: 'details',
      children: (
        <DetailsForm
          disabled={disabled}
          onChangeDisabled={(x: boolean) => setDisabled(x)}
        />
      ),
    },
    {
      label: t('products.common.products'),
      key: 'products',
      children: <ProductList />,
    },
  ];

  const dropdownItems: IButtonDropDownItems | undefined = [
    {
      label: t('admin.editUserPage.tab.buttonActions.delete'),
      icon: 'trash',
      onClick: () => setModalDeleteSubGroup(true),
    },
  ];

  const rightButton = {
    onClick: () => setDisabled(false),
    isVisible: disabled,
    codePermissionEdit: 3016,
    messageTooltipPermission: 'noPermission.supply.subGroups.edit',
    dataTestId: 'edit-subgroup-button',
  };

  const leftButton: IPermissionEditButon | undefined = {
    isVisible: true,
    onClick: () =>
      history.push(`/inventory/groups/${data?.grupoExternalId}/details`),
    leftIcon: 'tag',
    children: t('products.subGroupList.goToGroup'),
  };

  return {
    items,
    dropdownItems,
    rightButton,
    leftButton,
  };
};
