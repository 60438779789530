import { TFunction } from 'react-i18next';
import { ICannotDeleteModalInfo } from '../ErrorHandlingUtils';

export const cannotDeletePackingClassificationWithMovementsInTheSystemProps = (
  t: TFunction<'translation', undefined>
): ICannotDeleteModalInfo => ({
  formName: 'descricao',
  oneErrorModal: {
    titleModal: `${t('common.modalCannotDelete.messageTitle')} ${t(
      'production.packingClassification.modalCannotDelete.messageTitle'
    )}`,
    subTitleModal: `${t('common.modalCannotDelete.messageSubTitle')} ${t(
      'production.packingClassification.modalCannotDelete.messageSubtitle'
    )}`,
    reasonTranslation: `${t(
      'production.packingClassification.modalCannotDelete.messageReasonCannotDelete'
    )}`,
    warningModal: t(
      'production.packingClassification.modalCannotDelete.messageWarning'
    ),
  },
  manyErrorsModal: {
    entity: `${t('common.modalCannotDelete.messageTitle')}`,
    complement: `${t('common.thePackingClassification')}`,
    subTitleModal: `${t('common.modalCannotDelete.messageSubTitle')} ${t(
      'production.packingClassification.modalCannotDelete.manyMessageSubtitle'
    )}:`,
    reasonTranslation: `${t(
      'production.packingClassification.modalCannotDelete.manyMessageReasonCannotDelete'
    )}`,
    warningModal: t(
      'production.packingClassification.modalCannotDelete.manyMessageWarning'
    ),
  },
});
