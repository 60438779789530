import { useCallback, useMemo } from 'react';
import _ from 'lodash';
import { IUseFunctions } from './interfaces';

export const useInputDescriptionGroupFunctions = ({
  mutateAsync,
  initialDescription,
}: IUseFunctions) => {
  const fetchProductGroupValidationName = useMemo(
    () =>
      _.debounce(async (description: string) => {
        if (initialDescription === description) return;
        try {
          return await mutateAsync?.({
            descricao: description,
          });
        } catch {}
      }, 500),
    [mutateAsync, initialDescription]
  );

  const onChange = useCallback(
    (x: string) => fetchProductGroupValidationName(x),
    [fetchProductGroupValidationName]
  );

  return {
    fetchProductGroupValidationName,
    onChange,
  };
};
