import { useState } from "react";
import { 
  IProductGroupListData 
} from "@/Data/Interfaces/response/Product/IProductRespose";

export const useTransfersStates = () => {
  const [productSelected, setProductSelected] = useState<IProductGroupListData>();

  return {
    productSelected,
    setProductSelected
  };
};