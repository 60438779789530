import { FC } from 'react';
import { Text } from '..';
import { Avatar } from '../Avatar';
import { InputLabel } from '../Inputs/Label';
import { Loading } from '../Loading';

import styles from './SelectedAvatar.module.scss';
import { Divflex } from '@/Layouts/DivWhithFlex';

interface ISelectedAvatar {
  name?: string;
  lastName?: string;

  label?: string;

  withoutMarginBottom?: boolean;
}

export const SelectedAvatar: FC<ISelectedAvatar> = ({
  name,
  lastName,
  label,
  withoutMarginBottom,
}) => (
  <div style={{ width: '100%' }}>
    <InputLabel label={label} />
    <div
      className={`${styles['divContent']} ${
        withoutMarginBottom ? styles['without-margin-bottom'] : ''
      }`}
    >
      <div>
        {name && (
          <Divflex>
            <Avatar
              fisrtName={name}
              lastName={lastName}
              size="S"
              type="paragraph2-bold"
              color="white"
            />
            <div className={styles['div-description']}>
              <Text
                className="text-input-simple-avatar"
                type="ui-sub-content"
                color="text-50"
                children={`${name} ${lastName || ''}`}
              />
            </div>
          </Divflex>
        )}
      </div>

      <div>{!name && <Loading className={styles['loading']} />}</div>
    </div>
  </div>
);
