import { useControllerMutateApiHook } from '@/ControllerApiHook/Controller';
import { FETCH_CREATE_LOSSE } from '@/ControllerApiHook/UniqIds/Supply/LosseKeys';
import { LossesAPI } from '@/Data/API/Supply/LossesApi';
import StorageUtils from '@/Utils/StorageUtils';
import { history } from '@/Store';

export const useCreateCallAPI = () => {
  const { mutateAsync: createLosses, isLoading }: any =
    useControllerMutateApiHook({
      uniqId: FETCH_CREATE_LOSSE,
      entityApi: LossesAPI.createLosse,
      options: {
        onSuccess: () => {
          history.push('/inventory/losses');
        },
      },
    });

  const preferences = StorageUtils.getPreference();

  return { createLosses, isLoading, preferences };
};
