import { useMemo } from 'react';
import { IUseMemorizations } from './interfaces';
import { useTranslation } from 'react-i18next';
import { BodyHandler, TitleHandler } from '../Modal/DeleteModal';
import { IDeleteModalTable } from '@/Components/Table';

export const useSubGroupFormMemorizations = ({
  setVisibleModal,
  setVisibleEditModal,
  selectedRowsOnTable,
}: IUseMemorizations) => {
  const { t } = useTranslation();
  const abbreviatedI18n = 'products.subGroupList';

  const headerOptions = {
    hasSearch: true,
    newButton: {
      label: t(`${abbreviatedI18n}.newButton`),
      onClick: () => setVisibleModal(true),
      permission: {
        permission: 3015,
        tooltip: 'noPermission.supply.subGroups.create',
      },
      dataTestId: 'create-subgroup-of-groups-button',
    },
    deleteButton: {
      permission: {
        permission: 3012,
        tooltip: 'noPermission.supply.groups.delete',
      },
      dataTestId: 'delete-subgroup-button',
    },
    ...(selectedRowsOnTable?.length === 1 && {
      editButton: {
        editButtonWithoutDropdownClick: () => setVisibleEditModal(true),
        editButtonWithoutDropdown: true,
        dataTestId: 'edit-subgroup-button',
      }
    }),
    searchPlaceholder: t(`${abbreviatedI18n}.placeholder`),
    nameKey: 'descricao',
  };

  const deleteModal: IDeleteModalTable | undefined = useMemo(
    () => ({
      title: TitleHandler(selectedRowsOnTable, t),
      body: BodyHandler(selectedRowsOnTable, t),
      buttons: {
        okButtonColor: 'status-danger-base',
        okButtonDataTestId: 'submit-delete-subgroup',
      },
      widthModal: 399,
    }),
    [selectedRowsOnTable, t]
  );

  return {
    headerOptions,
    deleteModal,
  };
};
