import { FC } from "react";
import { Col, Row } from 'antd';
import { useTranslation } from "react-i18next";
import { TextArea } from '@/Components';

export const Observation: FC = () => {
  const { t } = useTranslation();
  
  return (
    <Row>
      <Col span={24}>
        <TextArea
          name="observacao"
          label={t('client.createPage.observation')}
          placeholder={t('client.createPage.observationPlaceholder')}
          maxLength={1000}
          rows={6}
        />
      </Col>
    </Row>
  );
};