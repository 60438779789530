import { useField } from 'formik';
import StorageUtils from '@/Utils/StorageUtils';
import CurrencyFormat from 'react-currency-format';
import { VisualInput } from './VisualInput';
import { isNumeric } from '@/Utils/IsNumericUtils';
import { Text } from '@/Components/Text';
import { INumberInput } from '../NumberInput';

import styles from './VisualInput/VisualInput.module.scss';
import { useEffect } from 'react';
import { formatAsNumber } from '@/Utils/numberUtils';

export const DensityInput = ({
  name,
  prefix,
  suffix,
  dataTestId,
  subText,
  subTextTestId,
  dropdownLeft,
  onBlur,
  onChange,
  ...props
}: Omit<
  INumberInput,
  | 'fixedDecimalScale'
  | 'withCurrencyPrefix'
  | 'decimalScale'
  | 'withDecimalSeparator'
  | 'withThousandSeparator'
>) => {
  const field = useField(name);
  const preference = StorageUtils.getPreference();

  const [numberFieldConfig, _, numberFieldHelpers] = useField(name);
  const [metadataConfig, _1, metadataHelpers] = useField(`metadata.${name}`);

  const thousandSeparator = preference?.idioma === 'pt-BR' ? '.' : ',';
  const decimalSeparator = preference?.idioma === 'pt-BR' ? ',' : '.';

  const DECIMAL_SCALE = 3;
  const FIXED_DECIMAL_SCALE = true;

  // The main reason of this useEffect is to detect changes in the input value that
  // are out of sync with metadata (means that you changed the input value manualy with 'setFieldValue')
  useEffect(() => {
    const currentFieldValue = numberFieldConfig.value;
    const currentMetadataFloatValue = metadataConfig.value?.floatValue;

    const hasDifference = currentFieldValue !== currentMetadataFloatValue;

    const isStartingNegativeNumber =
      metadataConfig?.value?.formattedValue === '-';
    if (!hasDifference || isStartingNegativeNumber) {
      return;
    }

    if (
      !currentFieldValue &&
      (metadataConfig?.value?.formattedValue !== '' ||
        metadataConfig?.value?.value !== '' ||
        metadataConfig?.value?.floatValue !== undefined)
    ) {
      metadataHelpers.setValue({
        formattedValue: '',
        value: '',
        floatValue: undefined,
      });
      return;
    }

    const strValue = currentFieldValue?.toString();

    const formattedValue = formatAsNumber(
      strValue,
      DECIMAL_SCALE,
      thousandSeparator,
      decimalSeparator,
      prefix,
      suffix,
      false
    );

    metadataHelpers.setValue({
      formattedValue,
      value: strValue,
      floatValue: currentFieldValue,
    });
  }, [
    numberFieldConfig,
    metadataConfig,
    metadataHelpers,
    thousandSeparator,
    decimalSeparator,
    prefix,
    suffix,
  ]);

  return (
    <>
      <CurrencyFormat
        name={name}
        decimalScale={DECIMAL_SCALE}
        thousandSeparator={thousandSeparator}
        decimalSeparator={decimalSeparator}
        fixedDecimalScale={FIXED_DECIMAL_SCALE}
        customInput={VisualInput}
        value={metadataConfig?.value?.formattedValue || ''}
        onBlur={onBlur}
        onValueChange={(x) => {
          onChange && onChange(isNumeric(x.floatValue) ? x.floatValue : 0);

          const valueWithoutSuffix = x?.formattedValue?.replace(
            suffix || '',
            ''
          );
          const lastChar = valueWithoutSuffix[valueWithoutSuffix.length - 1];

          const availableChars = [',', '.', '-'];
          if (availableChars.includes(lastChar)) {
            metadataHelpers.setValue(x);
            return x?.formattedValue;
          }

          metadataHelpers.setValue(x);
          return numberFieldHelpers.setValue(
            isNaN(x.floatValue) ? undefined : x.floatValue
          );
        }}
        {...({
          ...props,
          name,
          separators: {
            decimalScale: DECIMAL_SCALE,
            decimalSeparator,
            thousandSeparator,
          },
          dataTestId,
        } as any)}
      />
      {subText && (
        <Text
          id={subTextTestId}
          type="ui-tiny-content"
          color="text-300"
          children={subText}
          className={styles['sub-text']}
        />
      )}
    </>
  );
};
