import { useTranslation } from 'react-i18next';
import { IUseMemorizations } from './interfaces';
import { useFormikContext } from 'formik';
import { ICapsuleSizeListData } from '@/Data/Interfaces/response/CapsuleSize/ICapsuleSizeResponse';

export const useCapsuleGranelCardMemorizations = ({
  product,
  disabledForm,
  listCapsuleSize,
}: IUseMemorizations) => {
  const { t } = useTranslation();
  const form: any = useFormikContext();

  const additionalInfoTitle = `${t(
    'products.toolTips.products.originalValue'
  )} ${
    product && product.capsulaPronta
      ? `${product.capsulaPronta.numeroCapsula} (${product.capsulaPronta.numeroCapsulaVolume} ml)`
      : ''
  }`;

  const additionalInfoShowMe =
    product &&
    !disabledForm &&
    product?.capsulaPronta?.numeroCapsulaId !== form?.values?.capsulaTamanhoId;

  const capsuleSizeItems = listCapsuleSize.data
  ? listCapsuleSize?.data?.map((e: ICapsuleSizeListData) => ({
      id: e.id,
      label: `${e.numeroCapsula} (${e.volumeMl}ml)`.replace(
        '.',
        ','
      ),
      subLabel: `${e.volumeMl}ml`.replace('.', ','),
      customSelectedLabel: `${e.numeroCapsula}(${e.volumeMl}ml)`,
    }))
  : [];

  return {
    additionalInfoTitle,
    additionalInfoShowMe,
    capsuleSizeItems,
  };
};
