import { 
  FETCH_ASSOCIATED_PRODUCT, 
  FETCH_LIST_FOR_DROPDOWN_ASSOCIATED_PRODUCT_PHARMACEUTICAL_FORM 
} from "@/ControllerApiHook/UniqIds/Supply/ProductKeys";
import { history } from '@/Store';
import { useQueryClient } from '@tanstack/react-query';
import { IUseFunctions } from "./interfaces";
import { IFChildren } from "@/Components/Form";

export const useCreateAssociatedProductFunctions = ({
  createAssociatedProduct,
  productExternalId,
  accumulate
}: IUseFunctions) => {
  const queryClient = useQueryClient();
  const defaultUnitId = 6;

  const handleSubmit = async (values: any) => {
    const result = await createAssociatedProduct.mutateAsync({
      ...values,
      acumula: accumulate,
      produtoExternalId: productExternalId,
      unidadeMedidaDosagem: values.unidadeMedidaDosagem ?? defaultUnitId,
      unidadeMedidaQuantidadeAssociada:
        values.unidadeMedidaQuantidadeAssociada ?? defaultUnitId,
    });
    if (result) {
      queryClient.invalidateQueries([FETCH_ASSOCIATED_PRODUCT]);
      queryClient.invalidateQueries([
        FETCH_LIST_FOR_DROPDOWN_ASSOCIATED_PRODUCT_PHARMACEUTICAL_FORM,
      ]);
      history.push(`/inventory/products/${productExternalId}/associated`);
    }
  };

  const onClickCancelButton = () => {
    history.push(
      `/inventory/products/${productExternalId}/associated`
    );
  };

  return {
    handleSubmit,
    onClickCancelButton
  };
};