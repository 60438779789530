import { IQueryListParams } from "@/Utils/Http";
import { useState } from "react";
import { IUseStates } from "./interfaces";

export const useDilutedProductStates = () => {
  const [selectedRowsOnTable, setSelectedRowsOnTable] = useState<any[]>([]);

  return {
    selectedRowsOnTable,
    setSelectedRowsOnTable
  };
};