import { useFormikContext } from 'formik';

export const useSupplierDilutionFunctions = () => {
  const form: any = useFormikContext();

  const onChange = (value: string) => {
    if (!value)
      form.setFieldValue('loteInformacaoTecnica.diluicaoFornecedor', '');

    const supplierDilution = +value.replace(',', '.').replace('%', '');
    const currentFormProviderDiluitionFactor = (100 / supplierDilution).toFixed(
      3
    );
    form.setFieldValue(
      'loteInformacaoTecnica.fatorDiluicaoFornecedor',
      currentFormProviderDiluitionFactor
    );
  };

  return { onChange };
};
