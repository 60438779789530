import { ConglomerateForm } from '@/Pages/Sectors/AdminBootis/Conglomerate/ConglomerateForm';
import { List } from '@/Pages/Sectors/AdminBootis/Conglomerate/List';
import { Route, Switch } from 'react-router-dom';

export const AdminBootisConglomerateRoutes = () => (
  <Switch>
    <Route
      path="/admin-bootis/conglomerate/:externalId/:tab"
      component={ConglomerateForm}
    />
    <Route path="/admin-bootis/conglomerate" component={List} />
  </Switch>
);
