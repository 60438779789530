import {
  Button,
  Card,
  Form,
  TextInput
} from '@/Components';
import { history } from '@/Store';
import { Col, Row } from 'antd';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { IncompatibleProduct } from './Components/IncompatibleProduct';
import { LevelIncompatible } from './Components/LevelIncompatible';
import { Description } from './Components/Description';
import { IncompatibleProductDetailsProps } from './interfaces';
import { 
  useIncompatibleProductDetailsCallAPI 
} from './Hooks/useIncompatibleProductDetailsCallAPI';
import { 
  useIncompatibleProductDetailsFunctions 
} from './Hooks/useIncompatibleProductDetailsFunctions';

export const IncompatibleProductDetails: FC<
  IncompatibleProductDetailsProps
> = ({ disabledForm, disableForm }) => {
  const { t } = useTranslation();
  const { associationProductId, externalId: productExternalId } = useParams<{
    associationProductId: string;
    externalId: string;
  }>();

  const {
    incompatibleProductData,
    editIncompatibleProduct
  } = useIncompatibleProductDetailsCallAPI({
    associationProductId
  });

  const {
    handleSubmit,
    onClickBackButton
  } = useIncompatibleProductDetailsFunctions({
    editIncompatibleProduct,
    productExternalId,
    associationProductId,
    disableForm
  });

  return (
    <Form
      onSubmit={handleSubmit}
      initialValues={incompatibleProductData ? incompatibleProductData : {}}
    >
      {(props: any) => (
        <>
          <>
            <Card
              title={`${t('products.incompatible.incompatibleProducts')} | ${
                incompatibleProductData
                  ? incompatibleProductData.produtoIncompativelDescricao
                  : ''
              }`}
              withoutCollapse
            >
              <Row gutter={[16, 0]}>
                <Col span={7}>
                  <TextInput
                    name="produtoExternalId"
                    value={
                      incompatibleProductData
                        ? incompatibleProductData.produtoDescricao
                        : ''
                    }
                    label={t('products.incompatible.product')}
                    disabled
                  />
                </Col>
              </Row>
              <Row gutter={[16, 0]}>
                <IncompatibleProduct
                  productExternalId={productExternalId}
                  props={props}
                  incompatibleProductData={incompatibleProductData}
                  disabledForm={disabledForm}
                />
                <LevelIncompatible
                  incompatibleProductData={incompatibleProductData}
                  props={props}
                  disabledForm={disabledForm}
                />
              </Row>
              <Row gutter={[16, 0]}>
                <Description
                  incompatibleProductData={incompatibleProductData}
                  props={props}
                  disabledForm={disabledForm}
                />
              </Row>
            </Card>
            {!disabledForm && (
              <div style={{ display: 'flex' }}>
                <Button
                  type="secondary"
                  style={{ marginRight: 8 }}
                  children={t('common.cancel')}
                  onClick={() => {
                    if (props.dirty) {
                      history.push(
                        `/inventory/products/${productExternalId}/incompatible`
                      );
                    }
                    disableForm();
                  }}
                />
                <Button
                  type="primary"
                  children={
                    editIncompatibleProduct.isLoading
                      ? t('common.saving')
                      : t('common.save')
                  }
                  htmlType="submit"
                  loading={editIncompatibleProduct.isLoading}
                  disabled={
                    !props.values.descricao ||
                    !props.values.produtoIncompativelExternalId ||
                    !props.values.nivelIncompatibilidade
                  }
                />
              </div>
            )}
          </>
          {disabledForm && (
            <Button
              type="secondary"
              onClick={onClickBackButton}
              children={t('common.back')}
            />
          )}
        </>
      )}
    </Form>
  );
};
