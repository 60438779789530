import { FC } from "react";
import { Col } from 'antd';
import { useTranslation } from 'react-i18next';
import { IRelationship } from "./interfaces";
import { Dropdown, Tooltip } from "@/Components";

export const Relationship: FC<IRelationship> = ({
  associatedProductData,
  props,
  disabledForm
}) => {
  const { t } = useTranslation();

  return (
    <Col span={5}>
      <Tooltip
        title={`${t('products.associated.originalRelationship')}${
          associatedProductData?.tipoRelacaoQuantidade === 1
            ? t('products.associated.quantityProduct')
            : t('products.associated.quantityForm')
        }`}
        showMe={
          associatedProductData &&
          associatedProductData.tipoRelacaoQuantidade !==
            props?.values?.tipoRelacaoQuantidade
        }
        targetOffset={[80, -10]}
      >
        <Dropdown
          name="tipoRelacaoQuantidade"
          withStatusIcon={!disabledForm}
          items={[
            {
              id: 1,
              label: t('products.associated.quantityProduct'),
            },
            { id: 2, label: t('products.associated.quantityForm') },
          ]}
          label={t('products.associated.quantityRelationType')}
          placeHolder={t('products.associated.selectReletionShip')}
          withTooltip={{
            icon: 'exclamation-upside-down',
            title: t('products.associated.relationShipTooltip'),
          }}
          disabled={disabledForm}
        />
      </Tooltip>
    </Col>
  );
};