import React, { FC, useCallback } from 'react';
import { Icon, Text } from '../..';
import { Color } from '../../../Utils/ColorUtils';
import { IconName } from '../../Icon/IconClasses';
import { PermissionToggle } from '../../Inputs/PermissionToggle';

import './Content.override.scss';
import styles from './Content.module.scss';

export interface IContentIcon {
  icon: IconName;
  color?: Color;
  backgroundColor?: string;
}

export interface IContent {
  name?: string;
  displayName: string;

  isCollapse?: boolean;
  icon?: IContentIcon;
  secondaryIcon?: IContentIcon;
  disabled?: boolean;
  hasInherited?: boolean;
  totalPermissions?: number;
  activePermissions?: number;
  inheritedFrom?: string[];
  onChange?: (
    permission: { id: string; name: string },
    prevValue: boolean | 'inherited' | 'not-inherited',
    newValue: boolean | 'inherited' | 'not-inherited'
  ) => void;
}

export const Content: FC<IContent> = ({
  icon,
  name,
  secondaryIcon,
  displayName,
  isCollapse,
  disabled,
  hasInherited,
  totalPermissions,
  activePermissions,
  inheritedFrom,

  onChange,
}) => {
  const onPermissionChange = useCallback(
    (
      id: string,
      prevValue: boolean | 'inherited' | 'not-inherited',
      newValue: boolean | 'inherited' | 'not-inherited'
    ) => {
      onChange && onChange({ id, name: displayName }, prevValue, newValue);
    },
    [onChange, displayName]
  );

  return (
    <div className={`${styles['Header']} header`}>
      {isCollapse && (
        <Icon
          className={`${styles['chevron']} chevron`}
          icon="chevron-right"
          size="M"
          color="text-50"
        />
      )}
      {icon && (
        <div
          className={styles['icon']}
          style={{ backgroundColor: icon?.backgroundColor }}
        >
          <Icon icon={icon.icon} size="M" color={icon?.color} />
        </div>
      )}

      {secondaryIcon && (
        <div
          className={styles['icon']}
          style={{ backgroundColor: secondaryIcon?.backgroundColor }}
        >
          <Icon
            icon={secondaryIcon.icon}
            size="M"
            color={secondaryIcon?.color}
          />
        </div>
      )}

      <div style={{ marginLeft: '8px' }}>
        <Text type="ui-tiny-bold" color="text-50" children={displayName} />
      </div>

      <span className={styles['permissions']}>
        {!isCollapse ? (
          <PermissionToggle
            name={name || ''}
            disabled={disabled}
            hasUndetermined={hasInherited}
            inheritedFrom={inheritedFrom}
            onChange={onPermissionChange}
          />
        ) : (
          `${activePermissions}/${totalPermissions} Permissões`
        )}
      </span>
    </div>
  );
};
