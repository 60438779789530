import { FETCH_LIST_UNITS_BY_CLASS } from '@/ControllerApiHook/UniqIds/Supply/UnitMeasurementKeys';
import { CONVERT_UNIT_OF_MEASURE_MONETARY_VALUE } from '@/ControllerApiHook/UniqIds/Supply/SupplyKeys';
import {
  useControllerMutateApiHook,
  useControllerQueryListApiHook,
} from '@/ControllerApiHook/Controller';
import { UnitMeasurementAPI } from '@/Data/API/Supply/UnitMeasurementApi';
import { IProductLineCallAPI } from './interfaces';
import { useEffect } from 'react';

export const useProductLineCallAPI = ({
  classeProdutoId,
}: IProductLineCallAPI) => {
  const { data: unitsMeasurements, refetch: refetchUnitsMesurements } =
    useControllerQueryListApiHook({
      uniqId: FETCH_LIST_UNITS_BY_CLASS,
      entityApi: UnitMeasurementAPI.listUnitMeasurementByClassAny,
      pagination: {
        filter: [
          {
            filterName: 'tipoClasseProduto',
            value: classeProdutoId,
          },
        ],
      },
    });

  const { mutateAsync: convertUnitOfMeasureMonetaryValue }: any =
    useControllerMutateApiHook({
      uniqId: CONVERT_UNIT_OF_MEASURE_MONETARY_VALUE,
      entityApi: UnitMeasurementAPI.convertUnitOfMeasureMonetaryValue,
    });

  useEffect(() => {
    refetchUnitsMesurements({
      filter: [
        {
          filterName: 'tipoClasseProduto',
          value: classeProdutoId,
        },
      ],
    });
  }, [classeProdutoId]);

  return {
    unitsMeasurements: unitsMeasurements.data ?? [],
    refetchUnitsMesurements,
    convertUnitOfMeasureMonetaryValue,
  };
};
