import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Modal } from '../../../../../../../Components/Modal';
import { Divflex } from '../../../../../../../Layouts/DivWhithFlex';
import { Text } from '../../../../../../../Components';
import { FooterThisActionIsPermanent } from '../../../../../../../Components/Table/components/Modals/DeleteModal';
import { IGetLaboratory } from '../../../../../../../Data/Interfaces/response/Laboratory/ILaboratoryResponse';
import { useEditCallAPI } from './Hooks/useEditCallAPI';
import { useEditFunctions } from './Hooks/useEditFunctions';

interface IDeleteLaboratoryModal {
  laboratory: IGetLaboratory;
  visibleModal: boolean;
  changeVisibleModal: (value: boolean) => void;
}

export const DeleteLaboratoryModal: FC<IDeleteLaboratoryModal> = ({
  laboratory,
  visibleModal,
  changeVisibleModal,
}) => {
  const { t } = useTranslation();

  const { deleteLaboratory, isLoadingDeleteLaboratory } = useEditCallAPI();

  const { onDelete } = useEditFunctions({
    laboratory,
    changeVisibleModal,
    deleteLaboratory,
  });

  return (
    <Modal
      title={`${t('production.laboratory.deleteTitle')}  "${
        laboratory?.laboratorio
      }"?`}
      body={
        <div style={{ padding: '20px' }}>
          <Divflex
            style={{
              flexDirection: 'column',
              marginBottom: 10,
              width: '400px',
            }}
          >
            <Text
              color="text-50"
              type="ui-tiny-content"
              children={`${t('common.deleteLaboratoryList')} `}
            />

            <Divflex>
              <Text
                color="text-50"
                type="ui-tiny-bold"
                children={`${laboratory?.laboratorio} `}
              />
              <Text
                color="text-50"
                type="ui-tiny-content"
                children={`${t('common.deleteLaboratoryListCompany')} `}
              />
              <Text
                color="text-50"
                type="ui-tiny-bold"
                children={`${laboratory?.empresa}`}
              />
              <Text
                color="text-50"
                type="ui-tiny-content"
                children={`${'?'} `}
              />
            </Divflex>
          </Divflex>
          <FooterThisActionIsPermanent />
        </div>
      }
      okButtonColor="status-danger-base"
      visible={visibleModal}
      onOkClick={onDelete}
      okButtonName={t('common.delete')}
      onCancelClick={() => changeVisibleModal(false)}
      onClose={() => changeVisibleModal(false)}
      widthModal={400}
      okButtonType="danger"
      loading={isLoadingDeleteLaboratory}
    />
  );
};
