export const GET_CONGLOMERATE_TYPE = '[CONGLOMERATE] GET_CONGLOMERATE_TYPE';

export const LIST_CONGLOMERATE_ADMIN_TYPE =
  '[CONGLOMERATE] LIST_CONGLOMERATE_ADMIN_TYPE';
export const LIST_CONGLOMERATE_ADMIN_FOR_DROPDOWN_TYPE =
  '[CONGLOMERATE] LIST_CONGLOMERATE_ADMIN_FOR_DROPDOWN_TYPE';

export const CREATE_CONGLOMERATE_TYPE =
  '[CONGLOMERATE] CREATE_CONGLOMERATE_TYPE';
export const DELETE_CONGLOMERATE_TYPE =
  '[CONGLOMERATE] DELETE_CONGLOMERATE_TYPE';
export const EDIT_CONGLOMERATE_TYPE = '[CONGLOMERATE] EDIT_CONGLOMERATE_TYPE';

export const CHANGE_STATUS_CONGLOMERATE_TYPE =
  '[CONGLOMERATE] CHANGE_STATUS_CONGLOMERATE_TYPE';

export const CONGLOMERATE_IS_ALREADY_REGISTRED =
  '[CONGLOMERATE] CONGLOMERATE_IS_ALREADYrEGISTRED';
