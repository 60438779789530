import { Dropdown, Tooltip } from '@/Components';
import _ from 'lodash';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { TextInputNumber } from '@/Components/Inputs/TextInputNumber';
import { periodTypes } from '../../utils';
import { Col, Row } from 'antd';
import { IGetPosology } from '@/Data/Interfaces/response/Posology/IPosologyResponse';
import { IFChildren } from '@/Components/Form';

interface IRowOtherPosology {
  idUntAbbrev: number;
  untAbbrev: string;
  posology: IGetPosology | undefined;
  props: IFChildren;
  disabled: boolean;
}

export const OtherRow: FC<IRowOtherPosology> = ({
  idUntAbbrev,
  untAbbrev,
  posology,
  props,
  disabled,
}) => {
  const { t } = useTranslation();

  return (
    <Row gutter={[16, 0]} justify="start">
      <Col span={5}>
        <Tooltip
          title={`${t('production.posology.details.toolTips.amountPeriod')} ${
            posology?.quantidadeDosePorPeriodo
          } (${untAbbrev})`}
          showMe={
            !disabled &&
            posology?.quantidadeDosePorPeriodo !==
              props.values.quantidadeDosePorPeriodo
          }
          placement="top"
          targetOffset={[5, -6]}
        >
          <TextInputNumber
            name="quantidadeDosePorPeriodo"
            label={t('production.posology.create.amountDosePeriod')}
            rightIcon={{
              titleString: untAbbrev,
              id: idUntAbbrev,
            }}
            disabled={disabled}
            withStatusIcon
            withoutArrows
            required={!disabled}
          />
        </Tooltip>
      </Col>
      <Col span={5}>
        <Tooltip
          title={`${t('production.posology.details.toolTips.period')} ${
            posology?.periodoDescricao
          }`}
          showMe={!disabled && posology?.periodoId !== props.values.periodo}
          placement="top"
          targetOffset={[5, -18]}
        >
          <Dropdown
            items={periodTypes?.map((value) => ({
              id: value.id,
              label: value.description,
            }))}
            name="periodo"
            label={t('production.posology.create.period')}
            placeHolder={t('production.posology.create.periodPlaceholder')}
            disabled={disabled}
            withStatusIcon
            required={!disabled}
          />
        </Tooltip>
      </Col>
    </Row>
  );
};
