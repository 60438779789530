import { InputWithSearch, TextInput } from '@/Components';
import { Col, Row } from 'antd';
import { FormikContextType, useFormikContext } from 'formik';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useCreateStates } from './Hooks/useCreateStates';
import { useCreateCallAPI } from './Hooks/useCreateCallAPI';
import { useCreateFunctions } from './Hooks/useCreateFunctions';
import { useCreateMemorizations } from './Hooks/useCreateMemorizations';

interface IRowDescriptionPosology {
  setUntabbreviated: React.Dispatch<React.SetStateAction<string>>;
  setIdUntabbreviated: React.Dispatch<React.SetStateAction<number>>;
}

export const RowDescription: FC<IRowDescriptionPosology> = ({
  setUntabbreviated,
  setIdUntabbreviated,
}) => {
  const form: FormikContextType<unknown> = useFormikContext();
  const { t } = useTranslation();

  const { pharmaceuticaFormExternalId, setpharmaceuticaFormExternalId } =
    useCreateStates();

  const {
    fetchNewPharmaceuticalFormListPage,
    isLoadingPharmaceuticalFormList,
    listPharmaceuticalForm,
    pharmaceuticaFormDetail,
    refetchSearchPharmaceuticalFormListPage,
    mutateAsync,
  } = useCreateCallAPI({ pharmaceuticaFormExternalId, form, t });

  const {
    fetchPosologyName,
    fetchPosologyDropdownPharmaceuticalForm,
    handlerInputPharmaceuticalFormChange,
  } = useCreateFunctions({
    mutateAsync,
    setpharmaceuticaFormExternalId,
    pharmaceuticaFormDetail,
    setIdUntabbreviated,
    setUntabbreviated,
  });

  const { pharmaceuticalFormList } = useCreateMemorizations({
    listPharmaceuticalForm,
  });

  return (
    <Row gutter={[16, 0]} justify="start">
      <Col span={16}>
        <TextInput
          name="descricao"
          label={t('production.posology.create.posology')}
          placeHolder={t('production.posology.create.namePlaceholder')}
          required
          onChange={(value) => fetchPosologyName(form, value)}
          dataTestId="posology-description"
        />
      </Col>
      <Col span={8}>
        <InputWithSearch
          items={pharmaceuticalFormList}
          name="formaFarmaceuticaExternalId"
          label={t('production.posology.create.pharmaceuticalForm')}
          placeHolder={t(
            'production.posology.create.pharmaceuticalFormPlaceholder'
          )}
          isLoading={isLoadingPharmaceuticalFormList}
          required
          onChange={(value) => {
            fetchPosologyDropdownPharmaceuticalForm(form, value);
            handlerInputPharmaceuticalFormChange(value);
          }}
          onScrollEnd={fetchNewPharmaceuticalFormListPage}
          onSearch={(search) =>
            refetchSearchPharmaceuticalFormListPage({
              search: search,
            })
          }
          dataTestId="pharmaceutical-form-posology-dropdown"
        />
      </Col>
    </Row>
  );
};
