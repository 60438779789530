import { IPurchaseOrderOpenGetResponseData } from '@/Services/Purchase/PurchaseOrder/GetOpenPurchaseOrderList.service';
import { useTranslation } from 'react-i18next';
import styles from './styles.module.scss';
import { currencyFormater } from '@/Utils/Currency';
import { useFormatUnitNumberOnPurchaseOrderList } from '../hooks/useFormatUnitNumberOnPurchaseOrderList';

export const ListTooltip = ({
  purchaseOrder,
  hasTotal = true,
}: {
  purchaseOrder: IPurchaseOrderOpenGetResponseData;
  hasTotal?: boolean;
}) => {
  const { t } = useTranslation();
  const itemsToRender: any = purchaseOrder.itens.slice(0, 3);
  const restItems = purchaseOrder.itens.length - 3;
  const product = restItems === 1 ? 'product' : 'products';
  const { formatUnitNumberOnPurchaseOrderList } =
    useFormatUnitNumberOnPurchaseOrderList();

  return (
    <ul
      style={{ paddingBottom: hasTotal ? '20px' : '5px' }}
      className={styles.purchaseOrderListTooltip}
    >
      {itemsToRender.map((item: any) => (
        <li className={styles.purchaseOrderItem} key={item.descricao}>
          {item.descricao} (
          {formatUnitNumberOnPurchaseOrderList(item.quantidade)}{' '}
          {item.unidadeMedidaAbreviacao}){' '}
        </li>
      ))}
      {restItems >= 1 && (
        <li className={styles.purchaseOrderItem}>
          {t('purchasing.invoice.create.andMore')} {restItems}{' '}
          {t(`common.${product}`)}
        </li>
      )}
      {hasTotal && (
        <li className={styles.totalPurchaseOrder}>
          {t('common.total')} {currencyFormater(purchaseOrder.totalPedido)}
        </li>
      )}
    </ul>
  );
};
