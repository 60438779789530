import {
  useControllerQueryApiHook,
  useControllerQueryListApiHook,
} from '@/ControllerApiHook/Controller';
import { GET_CEP } from '@/ControllerApiHook/UniqIds/Locality/CEPKeys';
import { GET_LIST_CITY } from '@/ControllerApiHook/UniqIds/Locality/CityKeys';
import { GET_LIST_STATE } from '@/ControllerApiHook/UniqIds/Locality/StateKeys';
import { CEPAPI } from '@/Data/API/CEP/CEPApi';
import { CityAPI } from '@/Data/API/Locality/CityApi';
import { StateAPI } from '@/Data/API/Locality/StateApi';
import { IEditAdressClientRequest } from '@/Data/Interfaces/request/Client/IClientRequest';
import { IGetCEPResponse } from '@/Data/Interfaces/response/CEP/ICEPResponse';
import { FormikContextType } from 'formik';

interface IuseBodyCallAPI {
  CEP?: string;
  form: FormikContextType<IEditAdressClientRequest>;
}

export const useBodyCallAPI = ({ CEP, form }: IuseBodyCallAPI) => {
  const {
    data: listCity,
    fetchNewPage: fetchNewCityPage,
    refetch: refetchCityList,
    isLoading: cityListLoading,
  } = useControllerQueryListApiHook({
    uniqId: GET_LIST_CITY,
    entityApi: CityAPI.getListCity,
    pagination: {
      pageSize: 50,
      sorter: { column: 'descricao', direction: 'DESC' },
      filter: [
        {
          filterName: 'estadoExternalId',
          value: form.values.estadoExternalId,
        },
      ],
    },
  });

  const {
    data: listState,
    fetchNewPage: fetchNewStatePage,
    refetch: refetchStateList,
    isLoading: stateListLoading,
  } = useControllerQueryListApiHook({
    uniqId: GET_LIST_STATE,
    entityApi: StateAPI.getListState,
    pagination: {
      pageSize: 1000 * 1000,
      sorter: { column: 'descricao', direction: 'DESC' },
    },
  });

  const { isLoading: cepLoading } = useControllerQueryApiHook<IGetCEPResponse>({
    uniqId: GET_CEP,
    entityApi: CEPAPI.getCEP,
    externalIds: [CEP],
    onSuccess: (x: IGetCEPResponse) => {
      if (!x) return;
      form.setFieldValue('logradouro', x.logradouro);
      form.setFieldValue('bairro', x.bairro);
      form.setFieldValue('cidadeExternalId', x.cidadeExternalId);
      form.setFieldValue('estadoExternalId', x.estadoExternalId);
      form.setFieldValue('cep', x.cep);
      form.setFieldValue('paisExternalId', x.paisExternalId);
    },
    options: { refetchOnWindowFocus: false },
  });

  return {
    listCity,
    fetchNewCityPage,
    refetchCityList,
    cityListLoading,

    listState,
    fetchNewStatePage,
    refetchStateList,
    stateListLoading,

    cepLoading,
  };
};
