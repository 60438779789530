import { FC } from 'react';
import { Col } from 'antd';
import { PercentageInput } from '@/Components/Inputs/PercentageInput';
import { useTranslation } from 'react-i18next';
import { useWaterConcentrationFunctions } from './Hooks/useWaterConcentrationFunctions';

export const WaterConcentration: FC = () => {
  const { t } = useTranslation();

  const { onChange } = useWaterConcentrationFunctions();

  return (
    <Col span={6}>
      <PercentageInput
        name="loteInformacaoTecnica.concentracaoAgua"
        label={[
          {
            children: t('supply.lot.create.waterConcentration'),
          },
        ]}
        placeHolder={t('supply.lot.create.waterConcentration')}
        required
        withTooltip={{
          icon: 'exclamation-upside-down',
          title: t('supply.lot.create.toolTips.waterConcentration'),
        }}
        onChange={onChange}
      />
    </Col>
  );
};