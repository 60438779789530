import { FC } from 'react';
import {
  Tooltip
} from '@/Components';
import { useFormikContext } from 'formik';
import DateUtils from '@/Utils/DateUtils';
import { useTranslation } from 'react-i18next';
import { IDeliveryForecast } from './interfaces';
import { TextInputWithMask } from '@/Components/Inputs/TextInputWithMask';
import StorageUtils from '@/Utils/StorageUtils';
import { useDeliveryForecastFunctions } from './Hooks/useDeliveryForecastFunctions';

export const DeliveryForecast: FC<IDeliveryForecast> = ({
  orderPurchase
}) => {
  const form: any = useFormikContext();
  const { t } = useTranslation();
  const preferences = StorageUtils.getPreference();

  const {
    onDeliveryDateChange
  } = useDeliveryForecastFunctions();

  return (
    <Tooltip
      title={`${t('common.originalDate')} ${DateUtils.convertDate(
        orderPurchase?.previsaoEntrega
      )}`}
      showMe={
        DateUtils.convertDate(orderPurchase?.previsaoEntrega) !==
        form?.values?.previsaoEntrega
      }
    >
      <TextInputWithMask
        name="previsaoEntrega"
        mask="dataMask"
        leftIcon="calendar"
        withStatusIcon={
          DateUtils.convertDate(orderPurchase?.previsaoEntrega) !==
          form?.values?.previsaoEntrega
        }
        label={t('purchasing.purchaseOrder.create.deliveryForecast')}
        placeHolder={
          preferences &&
          DateUtils.getDatePlaceholder(
            preferences?.idioma,
            preferences?.padraoData
          )
        }
        onChange={onDeliveryDateChange}
        withoutMarginBottom={true}
      />
    </Tooltip>
  );
};