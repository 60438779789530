import { Http } from '@/Utils/Http';

export const CreateInvoice = async (body: any) => {
  const res = await Http.fetcher(`/pharma/compras/v1/NotaFiscalEntrada`, {
    method: 'POST',
    body: JSON.stringify(body),
  });
  if (!res) return [];
  return res;
};
