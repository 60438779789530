import { InputWithSearch } from '@/Components';
import { Col, Row } from 'antd';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { IFinishedProductListData } from '@/Data/Interfaces/response/Product/IProductRespose';
import { useFormikContext } from 'formik';
import { UseMutationResult } from '@tanstack/react-query';
import { NotificationActions } from '@/Store/Notification/Notification.actions';
import { useDispatch } from 'react-redux';
import { ISimpleListPrescribersData } from '@/Data/Interfaces/response/Prescribers/IPrescribersResponse';
import { IPreferences } from '@/Utils/ThemeUtils';
import { ConnectedFocusError } from '@/Components/ConnectedFocusError';
import { CreateFastPrescriberModal } from '../Modals/CreateFastPrescriber/createFastPrescriberModal';
import { InsufficientFunds } from '../Modals/InsuficientFunds/InsufficientFundsModal';
import { PrescriptionDate } from './Components/PrescriptionDate';
import { PriceRow } from './Components/PriceRow';
import { useBodyStates } from './Hooks/useBodyStates';
import { useBodyCallAPI } from './Hooks/useBodyCallAPI';

interface IAddProductModalBody {
  productList: any;
  datePattern?: IPreferences;
  addProduct: UseMutationResult;
  editProduct: UseMutationResult;
  productSelected?: IFinishedProductListData;
  setProductSelected: (value: IFinishedProductListData) => void;
  insufficientFundsModalVisible: boolean;
  setInsufficientFundsModalVisible: (value: boolean) => void;
  editModal: boolean;
}

export const AddProductModalBody: FC<IAddProductModalBody> = ({
  productList,
  addProduct,
  editProduct,
  datePattern,
  productSelected,
  setProductSelected,
  insufficientFundsModalVisible,
  setInsufficientFundsModalVisible,
  editModal,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const form = useFormikContext();

  const {
    createFastPrescriber,
    setCreateFastPrescriber,
    prescriberName,
    setPrescriberName,
  } = useBodyStates();

  const { fetchNewPage, isLoading, prescriberList, refetch } = useBodyCallAPI();

  return (
    <div style={{ padding: '20px' }}>
      <ConnectedFocusError />
      <Row gutter={[16, 0]}>
        <Col flex="100%">
          <InputWithSearch
            name="produtoExternalId"
            label={t('saleOrder.editOrder.SaleOrder.addProductModal.product')}
            placeHolder={t(
              'saleOrder.editOrder.SaleOrder.addProductModal.productPlaceholder'
            )}
            required
            items={productList.data?.data?.map(
              (x: IFinishedProductListData) => ({
                id: x.externalId,
                label: x.descricao,
                subLabel: `${t(
                  'saleOrder.editOrder.SaleOrder.addProductModal.code'
                )}: ${x.codigo} | ${
                  x.saldoEstoque > 0
                    ? `${x.saldoEstoque} un ${
                        x.saldoEstoque === 1
                          ? t(
                              'saleOrder.editOrder.SaleOrder.addProductModal.balanceAvaible'
                            )
                          : t(
                              'saleOrder.editOrder.SaleOrder.addProductModal.balanceAvaibles'
                            )
                      }`
                    : t(
                        'saleOrder.editOrder.SaleOrder.addProductModal.noBalanceAvailable'
                      )
                }`,
                allItem: x,
              })
            )}
            onChange={(_, item) => {
              form.setFieldValue('valorUnitario', item.valorUnitario);
              setProductSelected(item);
            }}
            onScrollEnd={productList.fetchNewPage}
            isLoading={productList.isLoading}
            onSearch={(search) =>
              productList.refetch({
                search: search,
              })
            }
            dataTestId="product-data-id"
          />
        </Col>
      </Row>
      <PriceRow productSelected={productSelected} />
      <Row gutter={[16, 0]}>
        <Col flex="70%">
          <InputWithSearch
            name="prescritorExternalId"
            label={t(
              'saleOrder.editOrder.SaleOrder.addProductModal.prescriber'
            )}
            placeHolder={t(
              'saleOrder.editOrder.SaleOrder.addProductModal.prescriberPlaceholder'
            )}
            items={prescriberList?.data?.map(
              (x: ISimpleListPrescribersData) => ({
                id: x.externalId,
                label: x.nomeCompleto,
                subLabel: `${x.siglaRegistro}/${x.siglaRegistro} ${x.codigoRegistro}`,
              })
            )}
            onScrollEnd={fetchNewPage}
            isLoading={isLoading}
            onSearch={(search) =>
              refetch({
                search: search,
              })
            }
            onChangeInputText={(x) => setPrescriberName(x)}
            emptyState={{
              onClick: () => {
                dispatch(NotificationActions.setCurrentFormIsDirty(false));
                setCreateFastPrescriber(true);
              },
              suffix: ` (${t('common.newPrescriber')})`,
            }}
            withoutMarginBottom
          />
        </Col>
        <Col flex="30%">
          <PrescriptionDate datePattern={datePattern} />
        </Col>
      </Row>
      <InsufficientFunds
        visible={insufficientFundsModalVisible}
        BalanceStock={productSelected?.saldoEstoque}
        productName={productSelected?.descricao}
        addProduct={addProduct}
        editProduct={editProduct}
        editModal={editModal}
        datePattern={datePattern}
        changeVisible={() => setInsufficientFundsModalVisible(false)}
      />
      <CreateFastPrescriberModal
        visible={createFastPrescriber}
        changeVisible={() => setCreateFastPrescriber(false)}
        nameClient={prescriberName}
        t={t}
      />
    </div>
  );
};
