import { useControllerQueryListApiHook } from '@/ControllerApiHook/Controller';
import { FECTH_LIST_STOCK_LOCATION_FOR_DROPDOWN_TYPE } from '@/ControllerApiHook/UniqIds/Supply/SupplyKeys';
import { StorageLocationApi } from '@/Data/API/Supply/StorageLocationApi';

export const useCreateCallAPI = () => {
  const {
    data: storageLocationList,
    isLoading: isLoadingStorageLocationList,
    fetchNewPage,
    refetch: refetchStorageLocation
  } = useControllerQueryListApiHook({
    uniqId: FECTH_LIST_STOCK_LOCATION_FOR_DROPDOWN_TYPE,
    entityApi: StorageLocationApi.getListStorageLocationDropDown,
    pagination: { sorter: { column: 'descricao', direction: 'ASC' } },
    autoIncrement: true
  });

  return {
    storageLocationList,
    isLoadingStorageLocationList,
    fetchNewPage,
    refetchStorageLocation
  };
};
