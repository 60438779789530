import { ICreateBalanceAdjustments } from '@/Data/Interfaces/request/BalanceAdjustments/ICreateBalanceAdjustments';
import { IUseFunctions } from './interface';
import { useCallback } from 'react';

export const useCreateFunctions = ({
  preferences,
  createBalanceAdjustment,
  setOnClassProductIdSelected,
  setStorageLocationExternalIdSelected,
  setlotExternalIdSelected,
}: IUseFunctions) => {
  const currentDate = new Date();
  let year = currentDate.getFullYear();
  let month = ('0' + (currentDate.getMonth() + 1)).slice(-2);
  let day = ('0' + currentDate.getDate()).slice(-2);
  let currentDateFormated = `${year}-${month}-${day}`;
  const currentHourFormated = currentDate.toLocaleTimeString(
    preferences.idioma
  );
  const currentDateHour = `${currentDateFormated} ${currentHourFormated}`;

  const onSubmit = async (values: ICreateBalanceAdjustments) => {
    var body: any = { ...values };
    let typeOperation: number = 0;
    if (body.amount < 0) {
      typeOperation = 1;
    }
    return await createBalanceAdjustment({
      dataLancamento: currentDateHour,
      localEstoqueExternalId: body.localEstoqueExternalId,
      loteExternalId: body.loteExternalId,
      quantidadeDeSaldo: body.amount,
      tipoOperacao: typeOperation,
      unidadeMedidaId: body.unidadeMedidaId,
    });
  };

  const onProductSelected = useCallback(
    (_: any, classProductId: number) => {
      setOnClassProductIdSelected(classProductId);
    },
    [setOnClassProductIdSelected]
  );

  const onLotSelected = useCallback(
    (lotExternalId: string) => {
      setlotExternalIdSelected(lotExternalId);
    },
    [setlotExternalIdSelected]
  );

  const onStorageLocationSelected = useCallback(
    (storageLocationExternalId: string) => {
      setStorageLocationExternalIdSelected(storageLocationExternalId);
    },
    [setStorageLocationExternalIdSelected]
  );

  return {
    currentDateHour,
    onSubmit,
    onProductSelected,
    onLotSelected,
    onStorageLocationSelected,
  };
};
