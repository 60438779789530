import { useFormikContext } from "formik";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Divflex } from '@/Layouts/DivWhithFlex';
import ReactMarkdown from 'react-markdown';
import { Text } from '@/Components';
import { 
  FooterThisActionIsPermanent 
} from "@/Components/Table/components/Modals/DeleteModal";

export const useReproveOrderModalMemorizations = () => {
  const { t } = useTranslation();
  const form: any = useFormikContext();

  const body = useMemo(() => (
    <div style={{ padding: '20px' }}>
      <Divflex
        style={{
          flexDirection: 'column',
        }}
      >
        <Text type="ui-tiny-content">
          <ReactMarkdown>
            {t('purchasing.purchaseOrder.modal.reprove.description', {
              numeroPedido: `**#${form.values.numeroPedido}**`,
              fornecedor: `**${form.values.nomeFornecedor}**`,
            })}
          </ReactMarkdown>
        </Text>
      </Divflex>
      <FooterThisActionIsPermanent />
    </div>
  ), [form, t]);

  return {
    body
  };
};