export const toastEN = {
  ok: 'Ok',
  help: 'Help',
  close: 'Close Alert',

  default: {
    title: 'We got an undefined error with code',
    description: 'Please try again in some minutes',
  },

  inventory: {
    lot: {
      create: {
        title: 'Error: Batch already registered!',
        description:
          'A record was identified with the same batch code and invoice number.',
      },
    },
  },

  errors: {
    getPreferences: 'Error loading preferences.',
  },
};

export const toastPT = {
  ok: 'Ok',
  help: 'Ajuda',
  close: 'Fechar Alerta',

  default: {
    title: 'Recebemos um erro indefinido com o código',
    description: 'Por favor, tente novamente em alguns minutos',
  },

  inventory: {
    lot: {
      create: {
        title: 'Erro: Lote já cadastrado!',
        description:
          'Foi identificado um cadastro com o mesmo código de lote e número de nota fiscal.',
      },
    },
  },

  errors: {
    getPreferences: 'Erro ao carregar as preferências.',
  },
};
