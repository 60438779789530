import { cnpj, cpf } from 'cpf-cnpj-validator';
import { FormikContextType } from 'formik';
import { TFunction } from 'react-i18next';

export const ValidateCpf = (
  cpfData: string,
  form: FormikContextType<any>,
  t: TFunction<'translation', undefined>
) =>
  form.setFieldError(
    'cpf',
    cpf.isValid(cpfData) ? undefined : t('common.invalidCpf')
  );

export const ValidateCnpj = (
  cnpjData: string,
  form: FormikContextType<any>,
  t: TFunction<'translation', undefined>
) =>
  form.setFieldError(
    'cnpj',
    cnpj.isValid(cnpjData) ? undefined : t('common.invalidCNPJ')
  );
