import { useCallback } from 'react';
import DateUtils from '@/Utils/DateUtils';
import { IUseFunctions } from './interfaces';

export const useModalFunctions = ({ editInfo }: IUseFunctions) => {
  const onOkClickInfoModal = useCallback(
    async (x: any) =>
      await editInfo?.mutateAsync({
        ...x,
        dataNascimento: DateUtils.convertDateToApi(x.dataNascimento),
      }),
    [editInfo]
  );

  return { onOkClickInfoModal };
};
