import { useCallback } from "react";
import { 
  FETCH_INCOMPATIBLE_PRODUCT
} from "@/ControllerApiHook/UniqIds/Supply/ProductKeys";
import { useQueryClient } from "@tanstack/react-query";
import { history } from '@/Store';
import { IUseFunctions } from "./interfaces";
import { 
  ICreateIncompatibleProductRequest 
} from "@/Data/Interfaces/request/Product/ICreateIncompatibleProductRequest";

export const useIncompatibleProductDetailsFunctions = ({
  editIncompatibleProduct,
  productExternalId,
  associationProductId,
  disableForm
}: IUseFunctions) => {
  const queryClient = useQueryClient();

  const handleSubmit = useCallback(async (values: ICreateIncompatibleProductRequest) => {
    try {
      const result = await editIncompatibleProduct.mutateAsync({
        ...values,
        externalId: associationProductId,
      } as ICreateIncompatibleProductRequest);
      if (result) {
        queryClient.invalidateQueries([FETCH_INCOMPATIBLE_PRODUCT]);
        disableForm();
        history.push(`/inventory/products/${productExternalId}/incompatible`);
      }
    } catch (error) {}
  }, [editIncompatibleProduct]);

  const onClickBackButton = () => {
    history.push(
      `/inventory/products/${productExternalId}/incompatible`
    );
  };

  return {
    handleSubmit,
    onClickBackButton
  };
};