import { useFormikContext } from 'formik';

export const useRemovePurchaseOrder = () => {
  const form: any = useFormikContext();
  const purchaseOrders = form.values.pedidosExternalId;

  const removePurchaseOrder = (number: number) => {
    const newPurchaseOrders = purchaseOrders.filter(
      (e: any) => e.sequenciaGroupTenant !== number
    );
    form.setFieldValue('pedidosExternalId', newPurchaseOrders);
  };

  return { removePurchaseOrder };
};
