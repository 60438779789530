export const splitDecimal = (numStr: string) => {
  const hasNagation = numStr[0] === '-';
  const addNegation = hasNagation; // allowNegative;
  numStr = numStr.replace('-', '');

  const parts = numStr.split('.');
  const beforeDecimal = parts[0];
  const afterDecimal = parts[1] || '';

  return {
    beforeDecimal,
    afterDecimal,
    hasNagation,
    addNegation,
  };
};

/**
 * limit decimal numbers to given scale
 * Not used .fixedTo because that will break with big numbers
 */
export function limitToScale(
  numStr: string,
  scale: number,
  fixedDecimalScale: boolean
) {
  let str = '';
  const filler = fixedDecimalScale ? '0' : '';
  for (let i = 0; i <= scale - 1; i++) {
    str += numStr[i] || filler;
  }
  return str;
}

/**
 * Format the given string according to thousand separator and thousand spacing
 * @param {*} beforeDecimal
 * @param {*} thousandSeparator
 * @param {*} thousandSpacing
 */
export const formatThousand = (
  beforeDecimal: any,
  thousandSeparator: any,
  thousandSpacing: any
) => {
  let digitalGroup = /(\d)(?=(\d{3})+(?!\d))/g;
  return beforeDecimal.replace(digitalGroup, '$1' + thousandSeparator);
};

export const formatAsNumber = (
  numStr: string,
  decimalScale?: number,
  thousandSeparator?: string,
  decimalSeparator?: string,
  prefix?: string,
  suffix?: string,
  fixedDecimalScale = true
) => {
  if (!numStr) {
    return undefined;
  }

  const hasDecimalSeparator = numStr.indexOf('.') !== -1 || decimalScale;
  let { beforeDecimal, afterDecimal, addNegation } = splitDecimal(numStr);

  //apply decimal precision if its defined
  if (decimalScale !== undefined)
    afterDecimal = limitToScale(afterDecimal, decimalScale, fixedDecimalScale);

  if (thousandSeparator) {
    beforeDecimal = formatThousand(beforeDecimal, thousandSeparator, '3'); // thousandSpacing);
  }

  //add prefix and suffix
  if (prefix) beforeDecimal = prefix + beforeDecimal;
  if (suffix) afterDecimal = afterDecimal + suffix;

  //restore negation sign
  if (addNegation) beforeDecimal = '-' + beforeDecimal;

  numStr =
    beforeDecimal +
    ((hasDecimalSeparator && afterDecimal && decimalSeparator) || '') +
    afterDecimal;

  return numStr;
};
