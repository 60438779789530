import { FETCH_LIST_COUNTRY } from '@/ControllerApiHook/UniqIds/Supply/CountryKeys';
import { useControllerQueryListApiHook } from '@/ControllerApiHook/Controller';
import { CountryAPI } from '@/Data/API/Supply/CountryApi';

export const useOriginCountryCallAPI = () => {
  const { data: paisOrigemList, fetchNewPage } = useControllerQueryListApiHook({
    uniqId: FETCH_LIST_COUNTRY,
    entityApi: CountryAPI.listCountry,
    autoIncrement: true,
    pagination: {
      sorter: { column: 'descricao', direction: 'ASC' },
    },
  });

  return { paisOrigemList, fetchNewPage };
};
