import { useCallback } from 'react';
import { App } from 'antd';
import { IUseFunctions } from './interfaces';
import { cannotDeleteLaboratoryWithMovementsInTheSystemProps } from '@/Utils/ErrorCodeUtils/ErrorModalUtils/ErrorModalLaboratory';
import { useQueryClient } from '@tanstack/react-query';
import { handleModalError } from '@/Utils/ErrorCodeUtils/ErrorHandlingUtils';
import { useTranslation } from 'react-i18next';

export const useEditFunctions = ({
  laboratory,
  changeVisibleModal,
  deleteLaboratory,
}: IUseFunctions) => {
  const app = App.useApp();
  const queryClient = useQueryClient();
  const { t } = useTranslation();

  const onDelete = useCallback(async () => {
    try {
      if (!laboratory) return;
      return await deleteLaboratory(
        { externalId: [laboratory.externalId] },
        {
          onError: handleModalError(
            app,
            cannotDeleteLaboratoryWithMovementsInTheSystemProps(t)
          ),
        }
      );
    } catch {
      changeVisibleModal(false);
    }
  }, [deleteLaboratory, queryClient, laboratory, app]);

  return { onDelete };
};
