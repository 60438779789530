import { FC } from 'react';
import { Col } from 'antd';
import { Tooltip, TextInput } from '@/Components';
import { useTranslation } from 'react-i18next';
import { useFormikContext } from 'formik';
import { WeightInput } from '@/Components/Inputs/WeightInput';
import { alternativeUnit1 } from '../../../../../../../../CreateForm/CardsForm/TechnicalInfoCard/utils';
import { IAlternateUnit } from './interfaces';

export const AlternateUnit: FC<IAlternateUnit> = ({
  lots,
  disabled,
  unidadeAlternativaList,
}) => {
  const { t } = useTranslation();
  const form: any = useFormikContext();
  const uiUnitDefault: number = 1;
  const grUnitDefault: number = 7;

  return (
    <Col span={6}>
      <Tooltip
        title={`${t('supply.lot.details.toolTips.originalAlternateUnit')} ${
          lots?.loteInformacaoTecnica?.loteUnidadeAlternativa1
            ?.quantidadeUnidadeAlternativa
        } ${
          lots?.loteInformacaoTecnica?.loteUnidadeAlternativa1
            ?.quantidadeUnidadeAlternativa
        }`}
        showMe={
          lots?.loteInformacaoTecnica?.loteUnidadeAlternativa1
            ?.quantidadeUnidadeAlternativa !==
            form?.values?.loteInformacaoTecnica?.loteUnidadeAlternativa1
              ?.quantidadeUnidadeAlternativa && !disabled
        }
      >
        <WeightInput
          name="loteInformacaoTecnica.loteUnidadeAlternativa1.quantidadeUnidadeAlternativa"
          label={t('supply.lot.details.details.alternativeUnitOne')}
          placeHolder={t('supply.lot.details.details.alternativeUnitOne')}
          disabled={disabled}
          dropdownLeft={{
            name: 'loteInformacaoTecnica.loteUnidadeAlternativa1.unidadeAlternativaId',
            defaultValue: uiUnitDefault,
            options:
              unidadeAlternativaList?.data?.map((x) => ({
                id: x.id,
                content: x.unidadeAbreviacao,
              })) || [],
          }}
          dropdownRight={{
            name: 'loteInformacaoTecnica.loteUnidadeAlternativa1.unidadeAlternativaConversaoId',
            defaultValue: grUnitDefault,
            options:
              alternativeUnit1?.map((x) => ({
                id: x.id,
                content: x.description,
              })) || [],
          }}
          withStatusIcon
        />
      </Tooltip>
    </Col>
  );
};
