import { discountTypeDefault } from '@/Utils/DiscountTypeDefault';
import { useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';
import { IUseMemorizations } from './interfaces';

export const useFullDiscountMemorizations = ({
  orderPurchase
}: IUseMemorizations) => {
  const { t } = useTranslation();
  const form: any = useFormikContext();

  const discountDefault = discountTypeDefault;

  const showMe = orderPurchase?.valorDesconto !== form?.values?.valorDesconto ||
  orderPurchase?.tipoDescontoId !== form?.values?.tipoDesconto;

  const placeholder = discountDefault === 'R$'
  ? t(
      'purchasing.purchaseOrder.create.productsCardPlaceHolders.discountValue'
    )
  : t(
      'purchasing.purchaseOrder.create.productsCardPlaceHolders.discountPercentage'
    );

  const dropdownLeft = {
    name: 'tipoDesconto',
  };

  return {
    showMe,
    placeholder,
    dropdownLeft
  };
};