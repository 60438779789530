import * as yup from 'yup';
import i18n from '../../../../../Components/i18n';
import DateUtils from '@/Utils/DateUtils';
import _ from 'lodash';

export const EditransferSchema = yup.object().shape({
  lossDate: yup
    .string()
    .nullable()
    .required(i18n.t('supply.losses.create.errors.dataPerda'))
    .test(
      'date validation',
      i18n.t('common.dateInvalid'),
      (val) => _.isNil(val) || DateUtils.isDateValid(val)
    )
    .test(
      'date not in future',
      i18n.t('supply.losses.create.errors.dataPerdaIsHigher'),
      (val) => {
        if (_.isNil(val) || !DateUtils.isDateValid(val)) return true;

        const lossDate = new Date(val);
        const today = new Date();
        today.setHours(0, 0, 0, 0);

        return lossDate.getTime() <= today.getTime();
      }
    ),
  lossReason: yup.string().required(i18n.t('common.error.requiredField')),
});
