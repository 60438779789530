import { useState } from 'react';

export const useListStates = () => {
  const [selectedRowsOnTable, setSelectedRowsOnTable] = useState<any>([]);

  const [
    visibleBindPackingClassificationPharmaceuticalFormModal,
    setVisibleBindPackingClassificationPharmaceuticalFormModal,
  ] = useState(false);

  return {
    selectedRowsOnTable,
    setSelectedRowsOnTable,
    visibleBindPackingClassificationPharmaceuticalFormModal,
    setVisibleBindPackingClassificationPharmaceuticalFormModal,
  };
};
