import { IIListUnitMeasurementAllData } from '@/Data/Interfaces/response/UnitMeasurement/IUnitMeasurementResponse';
import { IUseMemorizations } from './interfaces';

export const useDosageInputsMemorizations = ({
  unitMeasurementList,
  setUnitAbbreviation,
  form,
}: IUseMemorizations) => {
  const dropdownRightMinimalDosage = {
    name: 'unidadeMedidaId',
    defaultValue: form?.values.unidadeMedidaId,
    options: unitMeasurementList.data
      ? unitMeasurementList?.data?.map((e: IIListUnitMeasurementAllData) => ({
          content: e.unidadeAbreviacao,
          id: e.id,
        }))
      : [],
    onChange: (value: any) => {
      const unit = unitMeasurementList?.data?.find(
        (e: IIListUnitMeasurementAllData) => e.id === value
      );
      form?.setFieldValue('unidadeMedidaAbreviacao', unit?.unidadeAbreviacao);
    },
  };

  return {
    dropdownRightMinimalDosage,
  };
};
