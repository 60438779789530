import { FC } from "react";
import {
  TextInput
} from '@/Components';
import { Col } from 'antd';
import { useTranslation } from 'react-i18next';
import { IProduct } from "./interfaces";

export const Product: FC<IProduct> = ({
  currentProduct
}) => {
  const { t } = useTranslation();

  return (
    <Col span={7}>
      <TextInput
        name="product"
        label={t('products.incompatible.product')}
        disabled
        value={currentProduct ? currentProduct.descricao : ''}
      />
    </Col>
  );
};