import { Dropdown } from '@/Components';
import _ from 'lodash';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { TextInputNumber } from '@/Components/Inputs/TextInputNumber';
import { periodTypes } from '../../utils';
import { Col, Row } from 'antd';

interface IRowOtherPosology {
  idUntAbbrev: number;
  untAbbrev: string;
}

export const OtherRow: FC<IRowOtherPosology> = ({ idUntAbbrev, untAbbrev }) => {
  const { t } = useTranslation();

  return (
    <Row gutter={[16, 0]} justify="start">
      <Col span={6}>
        <TextInputNumber
          name="quantidadeDosePorPeriodo"
          label={t('production.posology.create.amountDosePeriod')}
          rightIcon={{
            titleString: untAbbrev,
            id: idUntAbbrev,
          }}
          required
          withoutArrows
          dataTestId="quantity-dose-posology"
        />
      </Col>
      <Col span={5}>
        <Dropdown
          items={periodTypes?.map((value) => ({
            id: value.id,
            label: value.description,
          }))}
          name="periodo"
          label={t('production.posology.create.period')}
          placeHolder={t('production.posology.create.periodPlaceholder')}
          required
          dataTestId="period-posology"
        />
      </Col>
    </Row>
  );
};
