import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { IUseMemorizations } from './interfaces';
import { PermissionEnum } from '@/Data/Interfaces/model/permissionEnum';
import { IButtonDropDownItems } from '@/Components/Button/ButtonDropdown';
import { DetailsTab } from '../Tabs/Details';
import { ClienteExternalId } from '../Tabs/Tickets';
import { OrderList } from '../Tabs/Order';

export const useEditClientMemorizations = ({
  client,
  setVisibleDeleteModal,
}: IUseMemorizations) => {
  const { t } = useTranslation();

  const items = useMemo(
    () => [
      {
        label: t('common.details'),
        key: 'details',
        children: client && <DetailsTab client={client} />,
      },
      {
        label: t('callCenter.service'),
        key: 'tickets',
        children: client && (
          <ClienteExternalId
            externalId={client.externalId}
            clientDescription={client.nome}
          />
        ),
      },
      {
        label: t('saleOrder.saleOrder'),
        key: 'sale-order',
        children: client && <OrderList externalId={client.externalId} />,
      },
    ],
    [t, client]
  );

  const dropdownItems = useMemo(
    (): IButtonDropDownItems | undefined => [
      {
        label: t('common.delete'),
        icon: 'trash',
        onClick: () => setVisibleDeleteModal(true),
        permission: {
          permission: PermissionEnum.Vendas_Clientes_Excluir,
          tooltip: 'noPermission.sale.client.delete',
        },
      },
    ],
    [t, setVisibleDeleteModal]
  );

  return {
    items,
    dropdownItems,
  };
};
