import { Button } from '@/Components';
import _ from 'lodash';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { history } from '../../../../../../../Store';
import { useFormikContext } from 'formik';
import { IFChildren } from '@/Components/Form';

interface IFooterButtons {
  isLoadingCreateLaboratory: boolean;
}

interface IFormValues {
  nomeLaboratorio: string;
}

export const FooterButtons: FC<IFooterButtons> = ({
  isLoadingCreateLaboratory,
}) => {
  const { t } = useTranslation();
  const { errors, isSubmitting } = useFormikContext<IFormValues>();
  const isFieldInError = !!errors.nomeLaboratorio;

  return (
    <div style={{ display: 'flex' }}>
      <Button
        type="secondary"
        children={t('common.cancel')}
        style={{ marginRight: '10px' }}
        onClick={() => {
          history.push('/production/labs');
        }}
      />
      <Button
        type="primary"
        children={
          isLoadingCreateLaboratory ? t('common.creating') : t('common.create')
        }
        htmlType="submit"
        loading={isLoadingCreateLaboratory}
        disabled={isFieldInError || isSubmitting}
        dataTestId="submit-laboratory"
      />
    </div>
  );
};
