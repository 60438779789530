import { FC } from 'react';
import { Text } from '@/Components';
import { DeleteModalOnTable } from '@/Utils/DeleteModalUtils';
import { useTranslation } from 'react-i18next';
import ReactMarkdown from 'react-markdown';
import { IDeleteModalBody } from './interfaces';

export const DeleteModalBody: FC<IDeleteModalBody> = ({ specialties }) => {
  const { t } = useTranslation();

  return (
    <div style={{ maxWidth: '400px' }}>
      {specialties.length > 1 ? (
        DeleteModalOnTable(
          specialties,
          t('specialties.listPage.deleteModal.describe'),
          'descricao',
          specialties.length > 5
            ? t('specialties.specialties')
            : t('specialties.specialtie')
        )
      ) : (
        <div style={{ padding: '20px' }}>
          <Text type="ui-tiny-content" withoutMarginBottom color="text-50">
            <ReactMarkdown>
              {t('specialties.listPage.deleteModal.description', {
                specialtie: `**${specialties[0].descricao}**`,
              })}
            </ReactMarkdown>
          </Text>
          <Text
            type="ui-tiny-content"
            color="text-50"
            children={t('modal.deleteModal.simpleFooter')}
          />
        </div>
      )}
    </div>
  );
};
