import { useCallback, useState } from 'react';
import { Link, Skeleton, Text } from '../../../../../..';
import {
  EDIT_STATUS_PRODUCT,
  FETCH_GET_GROUP,
  FETCH_GET_INCOMPATIBLE_PRODUCT,
  FETCH_GET_PRODUCT,
  FETCH_GET_SUBGROUP,
} from '../../../../../../../ControllerApiHook/UniqIds/Supply/ProductKeys';
import { PermissionWrapper } from '../../../../../../PermissionWrapper';
import { Status } from '../../../Status';
import { useQueryClient } from '@tanstack/react-query';
import { Modal } from '../../../../../../Modal';
import { Divflex } from '../../../../../../../Layouts/DivWhithFlex';
import { useTranslation } from 'react-i18next';
import {
  useControllerMutateApiHook,
  useControllerQueryApiHook,
} from '@/ControllerApiHook/Controller';
import { ProductAPI } from '@/Data/API/Supply/Product';
import {
  IProductGroupResponse,
  IProductResponse,
  IProductSubGroupResponse,
} from '@/Data/Interfaces/response/Product/IProductRespose';
import { IIncompatibleProductRequest } from '@/Data/Interfaces/request/Product/IIncompatibleProductRequest';
import ReactMarkdown from 'react-markdown';

export const DynamicEditSubGroupProductBreadcrumb = ({ match }: any) => {
  const { data } = useControllerQueryApiHook<IProductSubGroupResponse>({
    uniqId: FETCH_GET_SUBGROUP,
    entityApi: ProductAPI.getproductSubGroup,
    externalIds: [match.params.externalId],
  });

  if (!data) {
    return (
      <div style={{ width: 200 }}>
        <Skeleton />
      </div>
    );
  }

  return (
    <span style={{ display: 'flex' }}>
      <Link
        type="heading3"
        color="text-300"
        to={`/inventory/groups/${data.grupoExternalId}/details`}
        children={`${data.grupoDescricao} `}
      />
      <Text type="heading3" color="text-300" children={`/ `} />
      {data.subGrupoDescricao}
    </span>
  );
};

export const DynamicEditSubGroupProductTitleBreadcrumb = ({ match }: any) => {
  const { data } = useControllerQueryApiHook<IProductSubGroupResponse>({
    uniqId: FETCH_GET_SUBGROUP,
    entityApi: ProductAPI.getproductSubGroup,
    externalIds: [match.params.externalId],
  });

  if (!data) {
    return (
      <div style={{ width: 200 }}>
        <Skeleton />
      </div>
    );
  }

  return <div>{data?.subGrupoDescricao}</div>;
};

export const DynamicEditSubGroupOnGroupTitleBreadcrumb = ({ match }: any) => {
  const { data }: any = useControllerQueryApiHook<IProductGroupResponse>({
    uniqId: FETCH_GET_GROUP,
    entityApi: ProductAPI.getProductGroup,
    externalIds: [match.params.externalId],
  });

  if (!data) {
    return (
      <div style={{ width: 200 }}>
        <Skeleton />
      </div>
    );
  }

  return <div>{data.descricao}</div>;
};

export const DynamicEditProductBreadcrumb = ({ match }: any) => {
  const { data } = useControllerQueryApiHook<IProductResponse>({
    uniqId: FETCH_GET_PRODUCT,
    entityApi: ProductAPI.getProduct,
    externalIds: [match.params.externalId],
  });

  const { t } = useTranslation();

  const editProductStatus: any = useControllerMutateApiHook({
    uniqId: EDIT_STATUS_PRODUCT,
    entityApi: ProductAPI.productStatus,
  });

  const [modalVisible, setModalVisible] = useState(false);
  const [status, setStatus] = useState(undefined);
  const queryClient = useQueryClient();

  const onEditStatus = useCallback(
    async (value: any) => {
      const res = await editProductStatus.mutateAsync({
        produtosExternalId: [match.params.externalId],
        ativo: value,
      });
      if (res.success) {
        queryClient.invalidateQueries([FETCH_GET_PRODUCT]);
      }
    },
    [editProductStatus, match.params.externalId, queryClient]
  );

  if (!data) {
    return (
      <div style={{ width: 100 }}>
        <Skeleton />
      </div>
    );
  }

  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      {`${t('products.common.product')} | ${data.descricao}`}
      <PermissionWrapper
        permission={1005}
        tooltip="noPermission.administrative.users.statusEdit"
      >
        <Status
          type={data?.ativo ? 'success' : 'disabled'}
          ative={data?.ativo || false}
          onClick={(value) => {
            value ? onEditStatus(value) : setModalVisible(true);
            setStatus(value);
          }}
        />
      </PermissionWrapper>
      <Modal
        title={`${t('products.edit.status.inactivate')} "${data?.descricao}"?`}
        body={
          <Divflex style={{ padding: '20px' }}>
            <Text type="ui-tiny-content" withoutMarginBottom color="text-50" />
            <ReactMarkdown>
              {t('products.edit.status.confirmation', {
                description: `**${data?.descricao}** ?`,
              })}
            </ReactMarkdown>
          </Divflex>
        }
        onCancelClick={() => setModalVisible(false)}
        visible={modalVisible}
        onOkClick={() => {
          status !== undefined && onEditStatus(status);
          setModalVisible(false);
        }}
        onClose={() => setModalVisible(false)}
        okButtonName={t('products.edit.status.inactivate')}
      />
    </div>
  );
};

export const DynamicEditProductTitleBreadcrumb = ({ match }: any) => {
  const { data: product } = useControllerQueryApiHook<IProductResponse>({
    uniqId: FETCH_GET_PRODUCT,
    entityApi: ProductAPI.getProduct,
    externalIds: [match.params.externalId],
  });

  return <div>{product?.descricao}</div>;
};

export const DynamicEditProductOnSubGroupTitleBreadcrumb = ({ match }: any) => {
  const incompatibleAssociatedId = match.params.incompatibleAssociation;

  const { data: incompatibleAssociation } =
    useControllerQueryApiHook<IIncompatibleProductRequest>({
      uniqId: FETCH_GET_INCOMPATIBLE_PRODUCT,
      entityApi: ProductAPI.getIncompatibleProduct,
      externalIds: [incompatibleAssociatedId],
    });

  return <div>{incompatibleAssociation?.produtoIncompativelDescricao}</div>;
};
