import { CreatePosology } from '@/Pages/Sectors/Production/Posology/Create';
import FormPosology from '@/Pages/Sectors/Production/Posology/Form';
import List from '@/Pages/Sectors/Production/Posology/List';
import { Route, Switch } from 'react-router-dom';

export const PosologyRoutes = () => (
  <Switch>
    <Route path="/production/posology/create" component={CreatePosology} />
    <Route
      path="/production/posology/:externalId/:tab"
      component={FormPosology}
    />
    <Route path="/production/posology" component={List} />
  </Switch>
);
