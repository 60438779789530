import { FC } from 'react';
import {
  TextArea,
  Tooltip
} from '@/Components';
import { Col } from 'antd';
import { useTranslation } from 'react-i18next';
import { IDescription } from './interfaces';

export const Description: FC<IDescription> = ({
  incompatibleProductData,
  props,
  disabledForm
}) => {
  const { t } = useTranslation();

  return (
    <Col span={24}>
      <Tooltip
        title={`${t('products.incompatible.originalDescription')}${
          incompatibleProductData?.descricao
        }`}
        showMe={
          incompatibleProductData &&
          incompatibleProductData.descricao !==
            props?.values?.descricao
        }
      >
        <TextArea
          name="descricao"
          label={t('products.incompatible.description')}
          maxLength={1000}
          placeholder={t(
            'products.incompatible.descriptionPlaceholder'
          )}
          required={!disabledForm}
          disable={disabledForm}
          withStatusIcon
        />
      </Tooltip>
    </Col>
  );
};