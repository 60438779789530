import { ICasListData } from '@/Data/Interfaces/response/Cas/ICasResponse';
import { IUseMemorizations } from './interfaces';

export const useCasMemorizations = ({ casList }: IUseMemorizations) => {
  const items = casList
    ? casList?.data?.map((e: ICasListData) => ({
        id: e.id,
        label: e.nomeProduto,
        subLabel: `N° CAS: ${e.numeroCas}`,
      })) : [];

  return { items };
};
