import { useControllerQueryApiHook } from '@/ControllerApiHook/Controller';
import { FETCH_GET_INVOICE } from '@/ControllerApiHook/UniqIds/Purchase/InvoiceKeys';
import { InvoiceAPI } from '@/Data/API/Purchase/InvoiceApi';
import { IInvoiceGetResponse } from '@/Data/Interfaces/response/Purchase/IInvoiceResponse';

export const useGetInvoice = (externalId: string) => {
  const { data: invoice } = useControllerQueryApiHook<IInvoiceGetResponse>({
    uniqId: FETCH_GET_INVOICE,
    entityApi: InvoiceAPI.getInvoice,
    externalIds: [externalId],
  });

  return { invoice };
};
