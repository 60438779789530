import { QueryFunctionContext } from '@tanstack/react-query';
import { Http, IQueryListParams } from '../../../Utils/Http';
import { ICreateServiceRequest } from '../../Interfaces/request/Sales/Service/IServiceRequest';
import {
  IGetServiceResponse,
  IListServiceChannelResponse,
  IListServiceResponse,
} from '../../Interfaces/response/Sales/Service/IServiceResponse';

export class ServiceAPI {
  public static async createService(body: ICreateServiceRequest) {
    return await Http.fetcher('/pharma/vendas/v1/Atendimento', {
      method: 'POST',
      body: JSON.stringify(body),
    });
  }

  public static async listService(
    context: QueryFunctionContext
  ): Promise<IListServiceResponse> {
    return await Http.fetcher<IListServiceResponse>(
      Http.AddQueryParams(
        '/pharma/vendas/v1/Atendimento/ListarDetalhado',
        context.queryKey[1] as IQueryListParams
      )
    );
  }
  public static async listServiceChannel(
    context: QueryFunctionContext
  ): Promise<IListServiceChannelResponse> {
    return await Http.fetcher<IListServiceChannelResponse>(
      Http.AddQueryParams(
        '/pharma/vendas/v1/Atendimento/ListarCanaisAtendimento',
        context.queryKey[1] as IQueryListParams
      )
    );
  }

  public static async getService(
    context: QueryFunctionContext
  ): Promise<IGetServiceResponse> {
    return await Http.fetcher<IGetServiceResponse>(
      `/pharma/vendas/v1/Atendimento/${context.queryKey[1]}`
    );
  }
}
