import { useTranslation } from 'react-i18next';
import {
  CHANGE_CLIENT_STATUS,
  GET_CLIENT
} from '../../../../../../../../ControllerApiHook/UniqIds/People/ClientKeys';
import { Text } from '../../../../../../../Text';
import { useCallback, useState } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { i18n, Skeleton } from '../../../../../../..';
import { PermissionWrapper } from '../../../../../../../PermissionWrapper';
import { PermissionEnum } from '../../../../../../../../Data/Interfaces/model/permissionEnum';
import { Status } from '../../../../Status';
import { Modal } from '../../../../../../../Modal';
import { Divflex } from '../../../../../../../../Layouts/DivWhithFlex';
import ReactMarkdown from 'react-markdown';
import { UppercaseFirstLetter } from '../../../../../../../../Utils/CapitalFirstLetterUtils';
import { useControllerMutateApiHook, useControllerQueryApiHook } from '@/ControllerApiHook/Controller';
import { ClientAPI } from '@/Data/API/People/ClientApi';
import { IGetClientResponse } from '@/Data/Interfaces/response/Client/IClientResponse';
import { messageErrorToast, messageSucessToast } from '@/Utils/MessageErrorToast';

export const DynamicEditClientBreadcrumb = ({ match }: any) => {
  const { data: client } = useControllerQueryApiHook<IGetClientResponse>({
    uniqId: GET_CLIENT,
    entityApi: ClientAPI.getClient,
    externalIds: [match.params.externalId]
  });

  const { t } = useTranslation();
  
  const changeStatus: any = useControllerMutateApiHook({
    uniqId: CHANGE_CLIENT_STATUS,
    entityApi: ClientAPI.changeClientStatus,
    options: {
      onSuccess: () => {
        messageSucessToast(i18n.t('client.messages.success.changeStatus'));
      },
      onError: (x) => messageErrorToast(x)
    }
  });

  const [modalVisible, setModalVisible] = useState(false);
  const [status, setStatus] = useState(undefined);
  const queryClient = useQueryClient();

  const onEditStatus = useCallback(
    async (value: any) => {
      const res = await changeStatus.mutateAsync({
        clientesExternalId: [match.params.externalId],
        ativo: value,
      });
      if (res.success) {
        queryClient.invalidateQueries([GET_CLIENT]);
      }
    },
    [changeStatus, match.params.externalId, queryClient]
  );

  if (!client) {
    return (
      <div style={{ width: 400 }}>
        <Skeleton />
      </div>
    );
  }

  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <Text
        type="heading3-bold"
        children={`${UppercaseFirstLetter(t('client.client'))} | ${
          client.nome
        } | #${client.codigo}`}
      />
      <PermissionWrapper
        permission={PermissionEnum.Vendas_Clientes_AlterarStatus}
        tooltip="noPermission.sale.client.editStatus"
      >
        <Status
          type={client?.ativo ? 'success' : 'disabled'}
          ative={client?.ativo || false}
          onClick={(value) => {
            value ? onEditStatus(value) : setModalVisible(true);
            setStatus(value);
          }}
        />
      </PermissionWrapper>
      <Modal
        body={
          <Divflex style={{ padding: '20px', maxWidth: 400 }}>
            <Text type="ui-tiny-content" withoutMarginBottom>
              <ReactMarkdown>
                {t('client.editPage.statusModal.confirmation', {
                  client: `**${client.nome}**`,
                })}
              </ReactMarkdown>
            </Text>
          </Divflex>
        }
        title={`${t('common.inactivate')} ${t('client.client')}?`}
        onCancelClick={() => setModalVisible(false)}
        visible={modalVisible}
        onOkClick={() => {
          status !== undefined && onEditStatus(status);
          setModalVisible(false);
        }}
        onClose={() => setModalVisible(false)}
        okButtonName={t('common.inactivate')}
      />
    </div>
  );
};

export const EditClientBreadcrumb = (props: any) => {
  const { data: client } = useControllerQueryApiHook<IGetClientResponse>({
    uniqId: GET_CLIENT,
    entityApi: ClientAPI.getClient,
    externalIds: [props.match.params.externalId]
  });

  const urlArray: [] = props.location.pathname.split('/');

  return (
    <Text
      type={
        urlArray[urlArray.length - 1] === 'details'
          ? 'ui-tiny-bold'
          : 'ui-tiny-content'
      }
      children={client?.nome}
    />
  );
};
