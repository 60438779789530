import {
  VALIDATION_SYNONYM_PRODUCT
} from '@/ControllerApiHook/UniqIds/Supply/ProductKeys';
import { 
  useControllerMutateApiHook
} from '@/ControllerApiHook/Controller';
import { ProductAPI } from '@/Data/API/Supply/Product';
import { IUseCallAPI } from './interfaces';

export const useSynonymInputCallAPI = ({
  onProductValidationError
}: IUseCallAPI) => {
  const { mutateAsync, error }: any = useControllerMutateApiHook({
    uniqId: VALIDATION_SYNONYM_PRODUCT,
    entityApi: ProductAPI.productSynonymValidation,
    options: {
      onError: onProductValidationError
    }
  });

  return {
    mutateAsync,
    error
  };
};