import { Col, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import { Card, TextArea, TextInput } from '@/Components';
import { TextInputWithMask } from '@/Components/Inputs/TextInputWithMask';
import { Provider } from './Components/Provider';
import { MainContact } from './Components/MainContact';
import { DeliveryForecast } from './Components/DeliveryForecast';
import { usePurchaseOrderInfoStates } from './Hooks/usePurchaseOrderInfoStates';
import { usePurchaseOrderInfoCallAPI } from './Hooks/usePurchaseOrderInfoCallAPI';

export const PurchaseOrderInfo = () => {
  const { t } = useTranslation();

  const { externalIdProvider, setExternalIdProvider } =
    usePurchaseOrderInfoStates();

  const { documentProvider, isLoadingDocumentProvider } =
    usePurchaseOrderInfoCallAPI({
      externalIdProvider,
    });

  return (
    <Card
      title={t('purchasing.purchaseOrder.create.info')}
      withoutCollapse={true}
    >
      <Row gutter={[16, 0]} style={{ marginBottom: 20 }}>
        <Col span={12}>
          <Provider setExternalIdProvider={setExternalIdProvider} />
        </Col>
        <Col span={6}>
          <TextInput
            name="razaoSocial"
            label={t('purchasing.purchaseOrder.create.corporateName')}
            withoutMarginBottom
            disabled
          />
        </Col>
        <Col span={6}>
          <TextInputWithMask
            name="cnpj"
            mask="cnpjMask"
            label={t('purchasing.purchaseOrder.create.cnpj')}
            withoutMarginBottom
            disabled
          />
        </Col>
      </Row>
      <Row gutter={[16, 0]} style={{ marginBottom: 20 }}>
        <Col span={6}>
          <MainContact
            documentProvider={documentProvider}
            externalIdProvider={externalIdProvider}
          />
        </Col>
        <Col span={4}>
          <DeliveryForecast />
        </Col>
      </Row>
      <Row gutter={[16, 0]} justify="space-between">
        <Col span={24}>
          <TextArea
            name="observacao"
            label={t('supply.losses.create.comments')}
            placeholder={t(
              'purchasing.purchaseOrder.create.placeHolder.comments'
            )}
            maxLength={150}
            rows={3}
          />
        </Col>
      </Row>
    </Card>
  );
};
