import { FC } from 'react';
import { Button, Form } from '@/Components';
import { PurchaseOrderInfo } from './Components/PurchaseOrderInfo';
import { ProductCard } from './Components/ProductCard';
import { Col, Row } from 'antd';
import { PurchaseOrderCreateSchema } from './PurchaseOrderCreate.schema';
import { ButtonDraft } from '@/Components/Button/ButtonDraft';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { DeleteDraftModal } from './Modal/DeleteDraftModal';
import { TotalPurchase } from './Components/TotalPurchase';
import { useCreateStates } from './Hooks/useCreateStates';
import { useCreateCallAPI } from './Hooks/useCreateCallAPI';
import { useCreateFunctions } from './Hooks/useCreateFunctions';
import { useCreateMemorizations } from './Hooks/useCreateMemorizations';

import styles from './PurchaseOrderCreate.module.scss';
import { ConnectedFocusError } from '@/Components/ConnectedFocusError';
import { useCompareValues } from '@/Hooks/UtilHooks/useCompareValues.ts';
import { useDefineStatus } from './Hooks/useDefineStatus';

export const CreatePurchase: FC = () => {
  const { externalId } = useParams<{ externalId: string }>();
  const { t } = useTranslation();
  const { compareValues } = useCompareValues();

  const { isEdit, setIsEdit, openDeleteDraftModal, setOpenDeleteDraftModal } =
    useCreateStates();

  const {
    createOrderPurchase,
    savePostDraftOrderPurchase,
    savePutDraftOrderPurchase,
    purchaseOrder,
  } = useCreateCallAPI({
    externalId,
  });

  const {
    onSubmit,
    onSaveDraft,
    onClickCancelButton,
    changeVisibleDeleteDraftModal,
  } = useCreateFunctions({
    createOrderPurchase,
    purchaseOrder,
    isEdit,
    setIsEdit,
    savePostDraftOrderPurchase,
    savePutDraftOrderPurchase,
    externalId,
    setOpenDeleteDraftModal,
  });

  const { initialValues, withDropdownEditButton } = useCreateMemorizations({
    externalId,
    purchaseOrder,
    isEdit,
    setOpenDeleteDraftModal,
  });

  const { defineStatus } = useDefineStatus();

  return (
    <>
      <Form
        onSubmit={onSubmit}
        schema={PurchaseOrderCreateSchema}
        initialValues={initialValues}
      >
        {(form) => (
          <>
            <ConnectedFocusError />
            <PurchaseOrderInfo />
            <Row
              justify="space-between"
              style={{ marginBottom: '16px' }}
              gutter={[16, 0]}
            >
              <Col span={18}>
                <ProductCard />
                <div className={styles['footer-button']}>
                  <div>
                    <Button
                      type="secondary"
                      style={{ marginRight: 8 }}
                      children={t('common.cancel')}
                      onClick={onClickCancelButton}
                    />
                    <Button
                      type="primary"
                      children={
                        createOrderPurchase.isLoading
                          ? t('common.creating')
                          : t('common.create')
                      }
                      loading={createOrderPurchase.isLoading}
                      htmlType="submit"
                    />
                  </div>
                  <ButtonDraft
                    type="secondary"
                    htmlType="button"
                    disabled={compareValues(form.values, initialValues).isEqual}
                    onClick={() => {
                      onSaveDraft(form.values, form);
                    }}
                    withStatusMessage={defineStatus(
                      compareValues(form.values, initialValues).isEqual,
                      isEdit
                    )}
                    withDropdownEditButton={withDropdownEditButton}
                    withStatusIcon={
                      !compareValues(form.values, initialValues).isEqual
                    }
                    children={
                      savePostDraftOrderPurchase.isLoading ||
                      savePutDraftOrderPurchase.isLoading
                        ? t('common.saving')
                        : t('common.saveDraft')
                    }
                    loading={
                      savePostDraftOrderPurchase.isLoading ||
                      savePutDraftOrderPurchase.isLoading
                    }
                  />
                </div>
              </Col>
              <Col span={6}>
                <TotalPurchase />
              </Col>
            </Row>
          </>
        )}
      </Form>
      <DeleteDraftModal
        orderPurchase={purchaseOrder}
        visibleModal={openDeleteDraftModal}
        changeVisibleModal={changeVisibleDeleteDraftModal}
      />
    </>
  );
};
