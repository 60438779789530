import React from 'react';
import { Modal } from '@/Components/Modal';
import { useTranslation } from 'react-i18next';
import { Divflex } from '@/Layouts/DivWhithFlex';
import { ReactMarkdown } from 'react-markdown/lib/react-markdown';

interface IAddPurchaseOrderModalProps {
  visible?: boolean;
  number: number;
  onCancelClick: () => void;
  removePurchaseOrder: (number: number) => void;
}

const RemovePurchaseOrderModal: React.FC<IAddPurchaseOrderModalProps> = ({
  visible,
  number,
  onCancelClick,
  removePurchaseOrder,
}) => {
  const { t } = useTranslation();

  return (
    <Modal
      visible={visible}
      title={`${t(
        'purchasing.invoice.modal.removePurchaseOrderModal.title'
      )}#${number}`}
      body={
        <div style={{ padding: '20px' }}>
          <Divflex
            style={{
              flexDirection: 'column',
              marginBottom: 15,
            }}
          >
            <ReactMarkdown>
              {t(
                'purchasing.invoice.modal.removePurchaseOrderModal.description',
                { numero: `**#${number}**` }
              )}
            </ReactMarkdown>
          </Divflex>
        </div>
      }
      okButtonName={t(
        'purchasing.invoice.modal.removePurchaseOrderModal.unlink'
      )}
      okButtonColor="status-danger-base"
      onCancelClick={onCancelClick}
      onClose={onCancelClick}
      onOkClick={() => {
        removePurchaseOrder(number);
        onCancelClick();
      }}
    />
  );
};

export default RemovePurchaseOrderModal;
