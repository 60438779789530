import { useParams } from 'react-router-dom';
import {
  useControllerMutateApiHook,
  useControllerQueryApiHook,
  useControllerQueryListApiHook,
} from '@/ControllerApiHook/Controller';
import {
  DELETE_SUBGROUP,
  FETCH_GET_GROUP,
  FETCH_LIST_SUBGROUP,
} from '@/ControllerApiHook/UniqIds/Supply/ProductKeys';
import { ProductAPI } from '@/Data/API/Supply/Product';

export const useSubGroupFormCallAPI = () => {
  const { externalId } = useParams<{ externalId: string }>();

  const deleteSubGroup: any = useControllerMutateApiHook({
    uniqId: DELETE_SUBGROUP,
    entityApi: ProductAPI.deleteProductSubGroup,
  });

  const {
    data: subGroupList,
    isLoading,
    refetch: refetchGroupList,
  } = useControllerQueryListApiHook({
    uniqId: FETCH_LIST_SUBGROUP,
    entityApi: ProductAPI.subGroupList,
    pagination: {
      sorter: { column: 'descricao', direction: 'ASC' },
      filter: [{ filterName: 'grupoExternalId', value: externalId }],
    },
    autoIncrement: true,
  });

  const { data: productGroup }: any = useControllerQueryApiHook({
    uniqId: FETCH_GET_GROUP,
    entityApi: ProductAPI.getProductGroup,
    externalIds: [externalId],
  });

  return {
    deleteSubGroup,
    subGroupList,
    isLoading,
    productGroup,
    refetchGroupList,
  };
};
