import { useCallback } from 'react';
import { IUseFunctions } from './interfaces';
import _ from 'lodash';

export const useCreateFunctions = ({
  mutateAsync,
  setpharmaceuticaFormExternalId,
  pharmaceuticaFormDetail,
  setIdUntabbreviated,
  setUntabbreviated,
}: IUseFunctions) => {
  const handlerInputPharmaceuticalFormChange = (value: string) => {
    setpharmaceuticaFormExternalId(value);
  };

  const { abreviacaoUnidadeMedida, unidadeMedidaId } =
    pharmaceuticaFormDetail ?? {};

  if (abreviacaoUnidadeMedida && unidadeMedidaId) {
    setUntabbreviated(abreviacaoUnidadeMedida);
    setIdUntabbreviated(unidadeMedidaId);
  }

  const fetchPosologyName = useCallback(
    _.debounce(async (form: any, value: string) => {
      try {
        return await mutateAsync({
          descricao: value,
          formaFarmaceuticaExternalId: form.values.formaFarmaceuticaExternalId,
        });
      } catch {
        return undefined;
      }
    }, 500),
    [mutateAsync]
  );

  const fetchPosologyDropdownPharmaceuticalForm = useCallback(
    _.debounce(async (form: any, value: string) => {
      try {
        return await mutateAsync({
          descricao: form.values.descricao,
          formaFarmaceuticaExternalId: value,
        });
      } catch {
        return undefined;
      }
    }, 500),
    [mutateAsync]
  );

  return {
    fetchPosologyName,
    fetchPosologyDropdownPharmaceuticalForm,
    handlerInputPharmaceuticalFormChange,
  };
};
