import { IListUserData } from '@/Data/Interfaces/response/User/IGetUserReponse';
import { useState } from 'react';

export const useUserListStates = () => {
  const [selectedRowsOnTable, setSelectedRowsOnTable] = useState<
    IListUserData[]
  >([]);

  return {
    selectedRowsOnTable,
    setSelectedRowsOnTable,
  };
};
