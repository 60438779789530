import React, { ReactNode, FC } from 'react';
import BreadCrumbLayout from '../BreadCrumbLayout';
import { PageLoading } from '../PageLoading';

import styles from './PageLayout.module.scss';

export interface IPageLayout {
  children: ReactNode;
  currentContext?: string;
}

export const PageLayout: FC<IPageLayout> = ({ children, currentContext }) => (
  <PageLoading className={styles['container']} loading={!currentContext}>
    <BreadCrumbLayout />
    {children}
  </PageLoading>
);
