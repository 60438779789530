import {
  ILoginRequest,
  INewPasswordRequest,
  IRecoveryRequest,
} from '../../Interfaces/request/Auth/ILoginRequest';
import { Http } from '../../../Utils/Http';
import { ILoginResponse } from '../../Interfaces/response/Auth/ILoginResponse';

export class AuthAPI {
  public static async login(body: ILoginRequest): Promise<ILoginResponse> {
    return await Http.fetcher('/identity/v1/OAuth/login', {
      method: 'POST',
      body: JSON.stringify(body),
    });
  }

  public static async recovery(body: IRecoveryRequest) {
    return await Http.fetcher('/identity/v1/User/ForgetPassword', {
      method: 'PUT',
      body: JSON.stringify(body),
    });
  }

  public static async newpassword(body: INewPasswordRequest) {
    return await Http.fetcher('/identity/v1/User/CreatePassword', {
      method: 'PUT',
      body: JSON.stringify(body),
    });
  }
}
