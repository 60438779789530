import { useQueryClient } from '@tanstack/react-query';
import {
  useControllerMutateApiHook,
  useControllerQueryListApiHook,
} from '@/ControllerApiHook/Controller';
import {
  DELETE_PHARMACEUTICAL_FORM,
  EDIT_STATUS_PHARMACEUTICAL_FORM,
  FETCH_LIST_FOR_PHARMACEUTICAL_FORM,
} from '@/ControllerApiHook/UniqIds/Production/PharmaceuticalFormKeys';
import { PharmaceuticalFormAPI } from '@/Data/API/Production/PharmaceuticalForm';

export const useListCallAPI = () => {
  const queryClient = useQueryClient();

  const {
    data: pharmaceuticalFormList,
    isLoading: isLoadingPharmaceuticalFormList,
    refetch: refetchPharmaceuticalLit,
  } = useControllerQueryListApiHook({
    uniqId: FETCH_LIST_FOR_PHARMACEUTICAL_FORM,
    entityApi: PharmaceuticalFormAPI.getPharmaceuticalFormList,
    pagination: {
      sorter: { column: 'ordem', direction: 'ASC' },
    },
  });

  const editPharmaceuticalFormStatus = useControllerMutateApiHook({
    uniqId: EDIT_STATUS_PHARMACEUTICAL_FORM,
    entityApi: PharmaceuticalFormAPI.patchPharmaceuticalFormStatus,
    options: {
      onSuccess: () =>
        queryClient.invalidateQueries([FETCH_LIST_FOR_PHARMACEUTICAL_FORM]),
    },
  });

  const deletePharmaceuticalForm = useControllerMutateApiHook({
    uniqId: DELETE_PHARMACEUTICAL_FORM,
    entityApi: PharmaceuticalFormAPI.deletePharmaceuticalForm,
    options: {
      onSuccess: () =>
        queryClient.invalidateQueries([FETCH_LIST_FOR_PHARMACEUTICAL_FORM]),
    },
  });

  return {
    deletePharmaceuticalForm,
    editPharmaceuticalFormStatus,
    pharmaceuticalFormList,
    isLoadingPharmaceuticalFormList,
    refetchPharmaceuticalLit,
  };
};
