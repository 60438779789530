import React from 'react';
import { useTranslation } from 'react-i18next';
import { Card } from '../../../../../../../../../Components';
import { FirstRow } from './rows/FirstRow';
import { SecondRow } from './rows/SecondRow';
import { ThirdRow } from './rows/ThirdRow';

export const ValueSection: React.FC = () => {
  const { t } = useTranslation();

  return (
    <Card title={t('purchasing.invoice.create.value')} withoutCollapse>
      <FirstRow />
      <SecondRow />
      <ThirdRow />
    </Card>
  );
};
