import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Form, TextInput } from '../../../../Components';
import { Avatar } from '../../../../Components/Avatar';
import { TextInputWithMask } from '../../../../Components/Inputs/TextInputWithMask';
import { TableLayout } from '../../../../Components/Tabs/TablePreference';
import { IPutUserRequest } from '../../../../Data/Interfaces/request/User/IUserRequest';
import { RemoveMask } from '../../../../Utils/MasksUtils';
import { AccountDetailsSchema } from './AccountDetails.schema';
import { TextInputPhoneMask } from '../../../../Components/Inputs/TextInputPhoneMask';
import DateUtils from '@/Utils/DateUtils';
import { patternLabelStyle } from '@/Components/Inputs/Label';
import {
  useControllerMutateApiHook,
  useControllerQueryApiHook,
} from '@/ControllerApiHook/Controller';
import { UserAPI } from '@/Data/API/Accountmanager/UserApi';
import {
  EDIT_USER_TYPE,
  GET_ME,
} from '@/ControllerApiHook/UniqIds/Accountmanager/UserKeys';
import { IGetUserResponse } from '@/Data/Interfaces/response/User/IGetUserReponse';

import styles from './AccountDetails.module.scss';

export const AccountDetails: FC = () => {
  const [disabled, setDisebled] = useState(true);

  const { t } = useTranslation();

  const editUser = useControllerMutateApiHook({
    uniqId: EDIT_USER_TYPE,
    entityApi: UserAPI.editUser,
  });

  const { data: user } = useControllerQueryApiHook<IGetUserResponse>({
    uniqId: GET_ME,
    entityApi: UserAPI.getMe,
  });

  const onSubmit = (values: IPutUserRequest) => {
    var body: IPutUserRequest = { ...values };

    body.dataNascimento = RemoveMask(
      body.dataNascimento,
      body.preferencias.padraoData
    );

    editUser.mutateAsync(body);
    setDisebled(true);
  };

  return (
    <>
      <TableLayout
        title={t('accountManager.accountDetailsPage.title')}
        body={
          <Form
            initialValues={{
              ...user,
              dataNascimento: DateUtils.convertDate(user?.dataNascimento),
            }}
            onSubmit={onSubmit}
            schema={AccountDetailsSchema}
          >
            {(form) => (
              <div className={styles['divDetails']}>
                <div className={styles['divAvatar']}>
                  {user && (
                    <Avatar
                      fisrtName={user.nome}
                      lastName={user.sobrenome}
                      size="XXL"
                      type="heading3-bold"
                      color="white"
                    />
                  )}
                  <Button
                    className={styles['button-avatar']}
                    type="secondary"
                    children={t('accountManager.accountDetailsPage.addPhoto')}
                  />
                </div>
                <div className={styles['divName']}>
                  <div className={styles['name']}>
                    <TextInput
                      className={styles['input']}
                      name="nome"
                      label={t('accountManager.accountDetailsPage.name')}
                      placeHolder={
                        !disabled
                          ? t('accountManager.accountDetailsPage.name')
                          : ''
                      }
                      disabled={disabled}
                    />
                  </div>
                  <div className={styles['last-name']}>
                    <TextInput
                      className={styles['input']}
                      name="sobrenome"
                      label={t('accountManager.accountDetailsPage.lastName')}
                      placeHolder={
                        !disabled
                          ? t('accountManager.accountDetailsPage.lastName')
                          : ''
                      }
                      disabled={disabled}
                    />
                  </div>
                </div>
                <TextInput
                  className={styles['input']}
                  name="email"
                  label={t('accountManager.accountDetailsPage.email')}
                  placeHolder={
                    !disabled
                      ? t('accountManager.accountDetailsPage.placeHolderEmail')
                      : ''
                  }
                  disabled={disabled}
                />
                <TextInputWithMask
                  className={styles['input']}
                  mask="dataMask"
                  name="dataNascimento"
                  label={[
                    {
                      children: t('accountManager.accountDetailsPage.birthday'),
                      ...patternLabelStyle,
                    },
                    {
                      children: user
                        ? `(${user?.preferencias.padraoData})`
                        : '',
                      colorLabel: 'text-300',
                      typeLabel: 'ui-tiny-content',
                    },
                  ]}
                  disabled={disabled}
                  placeHolder={user?.preferencias.padraoData}
                />
                <TextInputPhoneMask
                  className={styles['input']}
                  name="celular"
                  label={t('accountManager.accountDetailsPage.phone')}
                  placeHolder={
                    !disabled
                      ? t('accountManager.accountDetailsPage.placeHolderPhone')
                      : ''
                  }
                  disabled={disabled}
                />
                {disabled ? (
                  <Button
                    type="secondary"
                    children={t('accountManager.accountDetailsPage.edit')}
                    onClick={() => setDisebled(false)}
                  />
                ) : (
                  <div className={styles['buttons']}>
                    <Button
                      className={styles['save-button']}
                      type="primary"
                      children={t('accountManager.accountDetailsPage.save')}
                      htmlType="submit"
                    />
                    <Button
                      type="secondary"
                      children={t('accountManager.accountDetailsPage.cancel')}
                      onClick={() => {
                        setDisebled(true);
                        form.resetForm();
                      }}
                    />
                  </div>
                )}
              </div>
            )}
          </Form>
        }
      />
    </>
  );
};
