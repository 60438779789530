import { useCallback, useMemo } from 'react';
import { useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import { IUseFunctions } from './interfaces';

export const useModalBodyFunctions = ({
  mutateAsync,
  groupExternalId,
  setGroupExternalId,
}: IUseFunctions) => {
  const form: any = useFormikContext();
  const { t } = useTranslation();

  const onSubGroupValidationError = useCallback(() => {
    form.setErrors({
      ...form.errors,
      descricao: t('products.create.errors.errorSubGroupExist'),
    });
  }, [form, t]);

  const fetchProductSubGroupValidationName = useMemo(
    () =>
      _.debounce(async (value?: string, groupExternalId?: string) => {
        try {
          return (
            groupExternalId &&
            value &&
            (await mutateAsync({
              descricao: value,
              grupoExternalId: groupExternalId,
            }))
          );
        } catch {}
      }, 500),
    [mutateAsync]
  );

  const onChangeInput = (x: string) => {
    form.setErrors({
      ...form.errors,
      descricao: undefined,
    });
    fetchProductSubGroupValidationName(x, form.values.grupoPaiExternalId);
  };

  const onChangeDropdown = (x: string) => {
    setGroupExternalId?.(x);
    fetchProductSubGroupValidationName(x, groupExternalId);
  };

  return {
    onSubGroupValidationError,
    fetchProductSubGroupValidationName,
    onChangeInput,
    onChangeDropdown,
  };
};
