import ProductMensageCreate from '@/Pages/Sectors/Inventory/ProductMessage/Create';
import { EditProductMensage } from '@/Pages/Sectors/Inventory/ProductMessage/Form';
import ProductMensageList from '@/Pages/Sectors/Inventory/ProductMessage/List';
import { Route, Switch } from 'react-router-dom';

export const ProductMessageRoutes = () => (
  <Switch>
    <Route
      path="/inventory/product-message/create"
      component={ProductMensageCreate}
    />
    <Route
      path="/inventory/product-message/:externalId/:tab"
      component={EditProductMensage}
    />
    <Route path="/inventory/product-message" component={ProductMensageList} />
  </Switch>
);
