import { FC } from "react";
import { Col } from 'antd';
import { NumberInput } from '@/Components';
import { useTranslation } from "react-i18next";

export const FormulaDiscount: FC = () => {
  const { t } = useTranslation();
  
  return (
    <Col flex="23%">
      <NumberInput
        name="descontoFormulas"
        label={[
          { children: t('prescribers.createPage.info.descFormula') },
        ]}
        placeHolder="0,00"
        leftIcon={{ titleString: '%' }}
        fixedDecimalScale
        decimalScale={2}
        withDecimalSeparator
        withThousandSeparator
      />
    </Col>
  );
};