import { ICapsuleSizeListData } from '@/Data/Interfaces/response/CapsuleSize/ICapsuleSizeResponse';
import { IUseMemorizations } from './interfaces';

export const useAssociateCapsulesMemorizations = ({
  listCapsuleSize,
  disabledCapsulesTypes,
}: IUseMemorizations) => {
  const items = listCapsuleSize.data
  ? listCapsuleSize.data.map((e: ICapsuleSizeListData) => ({
      id: e.id,
      label: e.numeroCapsula,
      subLabel: `${e.volumeMl}ml`,
      disabledItem: disabledCapsulesTypes.includes(e.id),
      customSelectedLabel: `${e.numeroCapsula}(${e.volumeMl}ml)`,
    }))
  : [];

  return { items };
};
