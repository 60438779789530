import { FC } from "react";
import {
  Dropdown,
  Tooltip
} from '@/Components';
import { Col } from 'antd';
import { useTranslation } from "react-i18next";
import { useFormikContext } from "formik";
import { IComponentType } from "./interfaces";

export const ComponentType: FC<IComponentType> = ({
  product,
  disabledForm,
  isUnitOfMeasurePrescriptionLoading,
  fetchNewDcbPage
})  => {
  const { t } = useTranslation();
  const form: any = useFormikContext();
  const componentTypes = [
    { id: 1, label: 'Normal' },
    { id: 2, label: 'Q.S.P.' },
    { id: 3, label: 'Q.S.' },
  ];

  return (
    <Col span={3}>
      <Tooltip
        title={`${t('products.toolTips.products.originalComponentType')} ${
          product && product.materiaPrima
            ? componentTypes.filter(
                (e) => e.id === product.materiaPrima?.tipoComponenteId
              )[0]
              ? componentTypes.filter(
                  (e) => e.id === product.materiaPrima?.tipoComponenteId
                )[0].label
              : ''
            : ''
        }`}
        showMe={
          product &&
          !disabledForm &&
          product.materiaPrima?.tipoComponenteId !==
            form?.values?.tipoComponenteId
        }
      >
        <Dropdown
          withStatusIcon={!!product}
          name="tipoComponenteId"
          label={t('products.create.componentType')}
          isLoading={isUnitOfMeasurePrescriptionLoading}
          onScrollEnd={fetchNewDcbPage}
          items={componentTypes}
          disabled={disabledForm}
        />
      </Tooltip>
    </Col>
  );
};