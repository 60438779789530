import { useTranslation } from 'react-i18next';
import { DetailsTab } from '../Tabs/Details';
import { PrescribersTab } from '../Tabs/Prescribers';
import { IUseMemorizations } from './interfaces';
import { useMemo } from 'react';
import { PermissionEnum } from '@/Data/Interfaces/model/permissionEnum';
import { IButtonDropDownItems } from '@/Components/Button/ButtonDropdown';

export const useEditMemorizations = ({
  disabledForm,
  setDisabledForm,
  setVisibleModal
}: IUseMemorizations) => {
  const { t } = useTranslation();

  const items = useMemo(() => (
    [
      {
        label: t('common.details'),
        key: 'details',
        children: (
          <DetailsTab
            disabledForm={disabledForm}
            onDisabledForm={() => setDisabledForm(true)}
            toogleDisabled={(x: boolean) => setDisabledForm(x)}
          />
        ),
      },
      {
        label: t('specialties.edit.prescribers'),
        key: 'prescribers',
        children: <PrescribersTab />,
      },
    ]
  ), [disabledForm, setDisabledForm, t]);

  const rightButton = {
    onClick: () => setDisabledForm(false),
    isVisible: disabledForm,
    codePermissionEdit:
      PermissionEnum.Vendas_EspecialidadePrescritor_EditarDetalhes,
    messageTooltipPermission: t('noPermission.sale.specialties.edit'),
  };

  const dropdownItems: IButtonDropDownItems | undefined = [
    {
      label: 'Excluir',
      icon: 'trash',
      onClick: () => setVisibleModal(true),
      permission: {
        permission: PermissionEnum.Vendas_EspecialidadePrescritor_Excluir,
        tooltip: 'noPermission.sale.specialties.delete',
      },
    },
  ];

  return {
    items,
    rightButton,
    dropdownItems
  };
};