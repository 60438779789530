import { FETCH_GET_LOT } from '@/ControllerApiHook/UniqIds/Supply/LotKeys';
import { IUseCallAPI } from './interfaces';
import { useControllerQueryApiHook } from '@/ControllerApiHook/Controller';
import { IGetLotResponse } from '@/Data/Interfaces/response/Lot/IListLot';
import { LotsAPI } from '@/Data/API/Supply/LotApi';

export const useEditCallAPI = ({ externalId }: IUseCallAPI) => {
  const { data: lot, isLoading } = useControllerQueryApiHook<IGetLotResponse>({
    uniqId: FETCH_GET_LOT,
    entityApi: LotsAPI.getLot,
    externalIds: [externalId]
  });

  return {
    lot,
    isLoading
  };
};