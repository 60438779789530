import { useState } from 'react';

export const useUserFormStates = () => {
  const [selectedRowsOnTable, setSelectedRowsOnTable] = useState<any>([]);
  const [visible, setVisible] = useState(false);

  return {
    selectedRowsOnTable,
    setSelectedRowsOnTable,
    visible,
    setVisible,
  };
};
