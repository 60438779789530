import { useControllerQueryListApiHook } from '@/ControllerApiHook/Controller';
import { PackingClassificationAPI } from '@/Data/API/Production/PackingClassification';
import { FETCH_LIST_PACKING_CLASSIFICATION_FOR_DROPDOWN_TYPE } from '@/ControllerApiHook/UniqIds/Production/PackingClassificationKeys';

export const usePackingClassificationCallAPI = () => {
  const {
    data: packingClassificationList,
    isLoading: isPackingClassificationList,
    fetchNewPage: fetchNewPagePackingClassification,
    refetch: refetchPackingClassification,
  } = useControllerQueryListApiHook({
    uniqId: FETCH_LIST_PACKING_CLASSIFICATION_FOR_DROPDOWN_TYPE,
    entityApi: PackingClassificationAPI.getListPackingClassificationDropDown,
    autoIncrement: true,
  });

  return {
    packingClassificationList,
    isPackingClassificationList,
    fetchNewPagePackingClassification,
    refetchPackingClassification,
  };
};
