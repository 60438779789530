import { FC } from 'react';
import { Table } from '@/Components';
import { userGroupsColumns } from './user-groups.columns';
import { editTableStatus } from '@/Utils/EditModalTableUtils';
import { useTranslation } from 'react-i18next';
import { useListStates } from './Hooks/useListStates';
import { useListCallAPI } from './Hooks/useListCallAPI';
import { useListMemorizations } from './Hooks/useListMemorizations';
import { useListFunctions } from './Hooks/useListFunctions';
import { PageLoading } from '@/Layouts/PageLoading';

const UserGroupsList: FC = () => {
  const { selectedRowsOnTable, setSelectedRowsOnTable } = useListStates();

  const { userGroupList, changeUserGroupStatus, deleteUserGroup } =
    useListCallAPI();

  const { headerOptions, rowOptions, deleteModal } = useListMemorizations({
    selectedRowsOnTable,
  });

  const { onEdit, onDelete } = useListFunctions({
    changeUserGroupStatus,
    deleteUserGroup,
  });

  const { t } = useTranslation();

  return (
    <PageLoading loading={userGroupList.isLoading}>
      <Table
        columns={userGroupsColumns(t)}
        headerOptions={headerOptions}
        loading={userGroupList.isLoading}
        hasSelection
        pagination={userGroupList.data}
        fieldStatus="seAtivo"
        onEdit={onEdit}
        onDelete={onDelete}
        onRefetch={userGroupList.refetch}
        rowsOptions={rowOptions}
        editOptions={editTableStatus()}
        translations={{
          editModal: { customEndTitle: t('common.userGroups') },
        }}
        editModal={{ okButtonName: t('common.save') }}
        editStatusModalLoading={changeUserGroupStatus.isLoading}
        selectedRowsOnTable={setSelectedRowsOnTable}
        deleteModal={deleteModal}
      />
    </PageLoading>
  );
};

export default UserGroupsList;
