import { i18n } from '@/Components';
import {
  DELETE_SPECIALTIES,
  FETCH_LIST_SPECIALTIES
} from '@/ControllerApiHook/UniqIds/People/SpecialtiesKeys';
import { history } from '@/Store';
import { useControllerMutateApiHook } from '@/ControllerApiHook/Controller';
import { SpecialtiesAPI } from '@/Data/API/People/SpecialtiesApi';
import { messageSucessToast } from '@/Utils/MessageErrorToast';
import { useQueryClient } from '@tanstack/react-query';

export const useDeleteModalCallAPI = () => {
  const queryClient = useQueryClient();

  const deleteSpecialties: any = useControllerMutateApiHook({
    uniqId: DELETE_SPECIALTIES,
    entityApi: SpecialtiesAPI.deleteSpecialties,
    options: {
      onSuccess: () => {
        messageSucessToast(i18n.t('specialties.listPage.deleteModal.success'));
        
        queryClient.invalidateQueries([FETCH_LIST_SPECIALTIES]);
        
        history.push(`/sales/expertise`);
      }
    }
  });

  return {
    deleteSpecialties
  };
};