import React from 'react';
import { Form } from '../../../../../Components';
import InfoCard from './Components/InfoCard';
import { useCreateCallAPI } from './Hooks/useCreateCallAPI';
import { useCreateFunctions } from './Hooks/useCreateFunctions';
import { FooterButtons } from './Components/FooterButtons';
import { ConnectedFocusError } from '@/Components/ConnectedFocusError';
import { ProductMensageCreateSchema } from './CreateProductMensage.schema';

const ProductMensageCreate: React.FC = () => {
  const { createProductMessage, isLoadingCreateProductMessage } =
    useCreateCallAPI({});

  const { onSubmit } = useCreateFunctions({ createProductMessage });

  return (
    <>
      <Form onSubmit={onSubmit} schema={ProductMensageCreateSchema}>
        {(props) => (
          <>
            <ConnectedFocusError />
            <InfoCard />
            <FooterButtons
              isLoadingCreateProductMessage={isLoadingCreateProductMessage}
              props={props}
            />
          </>
        )}
      </Form>
    </>
  );
};

export default ProductMensageCreate;
