import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { PageLoading } from '../../../../../Layouts/PageLoading';
import { Card, Form } from '../../../../../Components';
import { useParams } from 'react-router';
import { useEditStates } from './Hooks/useEditStates';
import { useEditCallAPI } from './Hooks/useEditCallAPI';
import { useEditFunctions } from './Hooks/useEditFunctions';
import { FooterButtons } from './Components/FooterButton';
import { DescriptionRow } from './Components/Rows/DescriptionRow';
import { OtherRow } from './Components/Rows/OtherRow';
import { ConnectedFocusError } from '@/Components/ConnectedFocusError';
import { EditPosologySchema } from './DetailsForm.schema';

interface IDetailsForm {
  disabled: boolean;
  onChangeDisabled: (x: boolean) => void;
}

export const PosologyDetail: FC<IDetailsForm> = ({
  disabled,
  onChangeDisabled,
}) => {
  const { externalId } = useParams<{ externalId: string }>();
  const { t } = useTranslation();

  const { idUntAbbrev, setIdUnitAbbrev, untAbbrev, setUntAbbrev } =
    useEditStates();

  const { editPosology, isLoadingEditPosology, posology, loadingPosology } =
    useEditCallAPI({
      externalId,
    });

  const { onSubmit } = useEditFunctions({ editPosology, posology });

  return (
    <PageLoading loading={loadingPosology}>
      <Form
        initialValues={{
          ...posology,
          descricao: posology?.posologiaDescricao,
          periodo: posology?.periodoId,
          formaFarmaceuticaExternalId: posology?.formaFarmaceuticaExternalId,
          pharmaceuticalFormLabelInitialValue: [{
            id: posology?.formaFarmaceuticaExternalId,
            label: posology?.formaFarmaceuticaDescricao,
          }],
        }}
        onSubmit={onSubmit}
        schema={EditPosologySchema}
      >
        {(props) => (
          <>
            <Card title={t('common.information')} withoutCollapse={true}>
              <DescriptionRow
                disabled={disabled}
                setUntabbreviated={setUntAbbrev}
                setIdUntabbreviated={setIdUnitAbbrev}
                descriptionOriginPosology={posology?.posologiaDescricao}
                externalIdOriginPharmaceuticalForm={
                  posology?.formaFarmaceuticaExternalId
                }
              />
              <OtherRow
                disabled={disabled}
                idUntAbbrev={idUntAbbrev}
                untAbbrev={untAbbrev}
                posology={posology}
                props={props}
              />
            </Card>
            {!disabled && (
              <FooterButtons
                disabled={disabled}
                form={props}
                isLoadingEditPosology={isLoadingEditPosology}
                onChangeDisabled={onChangeDisabled}
              />
            )}
            <ConnectedFocusError />
          </>
        )}
      </Form>
    </PageLoading>
  );
};

export default PosologyDetail;
