import { useState } from "react";

export const useModalBodyStates = () => {
  const [
    groupExternalId, 
    setGroupExternalId
  ] = useState<string>();

  return {
    groupExternalId,
    setGroupExternalId
  };
};