import { FC } from "react";
import { Col } from 'antd';
import { useTranslation } from 'react-i18next';
import { NumberInput } from '@/Components/Inputs/NumberInput';
import { ICapsulesQuantity } from "./interfaces";

export const CapsulesQuantity: FC<ICapsulesQuantity> = ({
  index,
  disabledForm
}) => {
  const { t } = useTranslation();

  return(
    <Col span={3} style={{ marginTop: index !== 0 ? '5px' : 0 }}>
      <NumberInput
        name={`numeroCapsulaAssociacao.${index}.quantidadeCapsula`}
        label={index === 0 ? t('products.create.quantity') : ''}
        placeHolder="0"
        disabled={disabledForm}
      />
    </Col>
  );
};