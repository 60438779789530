import { useCallback } from "react";
import { useDispatch } from 'react-redux';
import { NotificationActions } from '@/Store/Notification/Notification.actions';
import { IUseFunctions } from "./interfaces";

export const useLevelIncompatibleFunctions = ({
  incompatibleProductData,
  props
}: IUseFunctions) => {
  const dispatch = useDispatch();

  const onChange = useCallback((value: string) => {
    if (
      +value !==
        incompatibleProductData?.nivelIncompatibilidade ||
      incompatibleProductData.produtoIncompativelExternalId !==
        props?.values?.produtoIncompativelExternalId ||
      incompatibleProductData.descricao !==
        props?.values?.descricao
    ) {
      dispatch(
        NotificationActions.setCurrentFormIsDirty(true)
      );
    }
  }, [dispatch, incompatibleProductData, props]);

  return {
    onChange
  };
};