import { FC } from "react";
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { TextInput } from '@/Components';
import { Col } from 'antd';

export const BatchCode: FC = () => {
  const { t } = useTranslation();
  const { externalId } = useParams<{ externalId: string }>();

  return (
    <Col span={4}>
      <TextInput
        name="numero"
        label={t('supply.lot.details.details.batchCode')}
        placeHolder={t('supply.lot.details.details.batchCode')}
        disabled={!!externalId}
        rightIcon={{
          icon: 'copy',
          copyName: 'numero',
          toolTipTitle: [
            t('supply.lot.details.toolTips.copy'),
            t('supply.lot.details.toolTips.copySuccess'),
          ],
        }}
      />
    </Col>
  );
};