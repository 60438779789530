import { FC } from "react";
import { Col } from 'antd';
import { TextInputWithMask } from '@/Components/Inputs/TextInputWithMask';
import { useTranslation } from "react-i18next";

export const Birthday: FC = () => {
  const { t } = useTranslation();
  
  return (
    <Col flex="20%">
      <TextInputWithMask
        name="dataNascimento"
        placeHolder={`${t('common.exampleAbbreviation')}: 01/03/1988`}
        label={t('prescribers.createPage.info.birthday')}
        mask="dataMask"
        leftIcon="calendar"
      />
    </Col>
  );
};