import { IconName } from '@/Components/Icon/IconClasses';
import { Color, Colors } from '@/Utils/ColorUtils';

export enum StatusSaleOrderItem {
  inTyping = 1,
  budgeted = 2,
  aproved = 3,
  disapproved = 4,
  checked = 5,
  weighingSheetIssued = 6,
  issuedLabel = 7,
  weighing = 8,
  averageWeight = 9,
  finishedProductConference = 10,
  finished = 11,
  canceled = 12,
}

interface IObjStatusProps {
  icon?: IconName | undefined;
  background?: string;
  iconColor?: Color;
}

export const StatusIcon = (status: number): IObjStatusProps => {
  switch (status) {
    case StatusSaleOrderItem.aproved:
      return {
        icon: 'like',
        background: Colors['status-success-t15'],
        iconColor: 'status-success-base',
      };
    case StatusSaleOrderItem.disapproved:
      return {
        icon: 'disapproved',
        background: Colors['status-danger-t15'],
        iconColor: 'status-danger-base',
      };
    case StatusSaleOrderItem.checked:
      return {
        icon: 'Approve',
        background: Colors['status-success-t15'],
        iconColor: 'status-success-base',
      };
    case StatusSaleOrderItem.weighingSheetIssued:
      return {
        icon: 'clipboard',
        background: Colors['status-warning-t15'],
        iconColor: 'status-warning-base',
      };
    case StatusSaleOrderItem.issuedLabel:
      return {
        icon: 'tag',
        background: Colors['status-warning-t15'],
        iconColor: 'status-warning-base',
      };
    case StatusSaleOrderItem.weighing:
      return {
        icon: 'weight-scale',
        background: Colors['status-warning-t15'],
        iconColor: 'status-warning-base',
      };
    case StatusSaleOrderItem.averageWeight:
      return {
        icon: 'growth-graph',
        background: Colors['status-warning-t15'],
        iconColor: 'status-warning-base',
      };
    case StatusSaleOrderItem.finishedProductConference:
      return {
        icon: 'approve-file',
        background: Colors['status-warning-t15'],
        iconColor: 'status-warning-base',
      };
    case StatusSaleOrderItem.finished:
      return {
        icon: 'check',
        background: Colors['status-success-t15'],
        iconColor: 'status-success-base',
      };
    case StatusSaleOrderItem.canceled:
      return {
        icon: 'close-x',
        background: Colors['status-danger-t15'],
        iconColor: 'status-danger-base',
      };
    default:
      return {};
  }
};
