import {
  FETCH_GET_PRODUCT,
  FETCH_LIST_FOR_DROPDOWN_TYPE,
} from '@/ControllerApiHook/UniqIds/Supply/ProductKeys';
import { FETCH_LIST_UNIT_MEASUREMENT_FOR_DROPDOWN_TYPE } from '@/ControllerApiHook/UniqIds/Supply/UnitMeasurementKeys';
import { IUseCallAPI } from './interfaces';
import {
  useControllerQueryApiHook,
  useControllerQueryListApiHook,
} from '@/ControllerApiHook/Controller';
import { IProductResponse } from '@/Data/Interfaces/response/Product/IProductRespose';
import { ProductAPI } from '@/Data/API/Supply/Product';
import { UnitMeasurementAPI } from '@/Data/API/Supply/UnitMeasurementApi';

export const useProductRowCallAPI = ({ productId }: IUseCallAPI) => {
  const feedStockClassId = 1;

  const {
    data: productList,
    fetchNewPage: fetchNewProductPage,
    isLoading: isLoadingProduct,
    refetch: refetchSearchProductPage,
  } = useControllerQueryListApiHook({
    uniqId: FETCH_LIST_FOR_DROPDOWN_TYPE,
    entityApi: ProductAPI.getListProductDropDown,
    pagination: {
      filter: [
        {
          filterName: 'classesProdutoIds',
          value: [feedStockClassId],
        },
        {
          filterName: 'orderBy',
          value: 'descricao',
        },
      ],
    },
  });

  const {
    data: listunitsMeasurementsPrescription,
    isLoading: isUnitOfMeasurePrescriptionLoading,
  } = useControllerQueryListApiHook({
    uniqId: FETCH_LIST_UNIT_MEASUREMENT_FOR_DROPDOWN_TYPE,
    entityApi: UnitMeasurementAPI.getListUnitMeasurementDropDown,
  });

  const { data: unitsMeasurementsDefault } =
    useControllerQueryApiHook<IProductResponse>({
      uniqId: FETCH_GET_PRODUCT,
      entityApi: ProductAPI.getProduct,
      externalIds: [productId],
    });

  return {
    productList,
    fetchNewProductPage,
    isLoadingProduct,
    refetchSearchProductPage,
    listunitsMeasurementsPrescription,
    isUnitOfMeasurePrescriptionLoading,
    unitsMeasurementsDefault,
  };
};
