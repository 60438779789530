export const getUrlFormValues = (formValues: any) => {
  const keys = Object.keys(formValues);
  return keys
    .map((x) =>
      x !== 'phone' && x !== 'contatos' && x !== 'pessoa'
        ? `&${x}=${formValues[x]}`
        : ''
    )
    .toString()
    .replaceAll(',', '');
};

export enum ClientType {
  cpf = 0,
  cnpj = 1,
}
