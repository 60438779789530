import { Button } from '@/Components';
import _ from 'lodash';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { history } from '../../../../../../../Store';
import { IFChildren } from '@/Components/Form';
import styles from './Create.module.scss';

interface IFooterButtons {
  isLoadingCreatePosology: boolean;
}

export const FooterButtons: FC<IFooterButtons> = ({
  isLoadingCreatePosology,
}) => {
  const { t } = useTranslation();

  return (
    <div className={styles['footer-button']}>
      <Button
        type="secondary"
        children={t('common.cancel')}
        onClick={() => {
          history.push('/production/posology');
        }}
      />
      <Button
        type="primary"
        children={
          isLoadingCreatePosology ? t('common.creating') : t('common.create')
        }
        loading={isLoadingCreatePosology}
        htmlType="submit"
        dataTestId="submit-posology"
      />
    </div>
  );
};
