import { IUseFunctions } from "./interfaces";
import { NotificationActions } from '@/Store/Notification/Notification.actions';
import { useDispatch } from 'react-redux';

export const usePersonalDataCardFunctions = ({
  refetchGroupList,
  changeEditDisabled,
  form
}: IUseFunctions) => {
  const dispatch = useDispatch();

  const onSearch = (search: string | undefined) => {
    refetchGroupList({
      search: search,
    });
  }; 

  const actionOnFocus = () => refetchGroupList({ search: '' });

  const onClickCancel = () => {
    changeEditDisabled();
    form.resetForm();
    dispatch(NotificationActions.setCurrentFormIsDirty(false));
  };

  return {
    onSearch,
    actionOnFocus,
    onClickCancel
  };
};