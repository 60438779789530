import React from 'react';
import { useTranslation } from 'react-i18next';
import { Table } from '../../../../../Components/Table';
import { PageLoading } from '../../../../../Layouts/PageLoading';
import { capsuleColorsColumns } from './CapsuleColors.column';
import { useListStates } from './Hooks/useListStates';
import { useListCallAPI } from './Hooks/useListCallAPI';
import { useListMemorizations } from './Hooks/useListMemorizations';
import { useListFunctions } from './Hooks/useListFunctions';

export const CapsuleColorsList: React.FC = () => {
  const { t } = useTranslation();

  const { selectedRowsOnTable, setSelectedRowsOnTable } = useListStates();

  const {
    deleteCapsuleColors,
    capsuleColorsList,
    isLoadingCapsuleColorsList,
    refetchCapsuleColorsList,
  } = useListCallAPI();

  const { headerOptions, rowOptions, deleteModal } = useListMemorizations({
    selectedRowsOnTable,
  });

  const { onSelectedRowsOnTable, onDelete } = useListFunctions({
    setSelectedRowsOnTable,
    deleteCapsuleColors,
  });

  return (
    <PageLoading loading={isLoadingCapsuleColorsList}>
      <Table
        columns={capsuleColorsColumns(t)}
        loading={isLoadingCapsuleColorsList}
        headerOptions={headerOptions}
        selectedRowsOnTable={onSelectedRowsOnTable}
        hasSelection
        rowsOptions={rowOptions}
        pagination={capsuleColorsList}
        deleteModal={deleteModal}
        onRefetch={refetchCapsuleColorsList}
        onDelete={onDelete}
        dataTestId="production-capsule-colors"
      ></Table>
    </PageLoading>
  );
};

export default CapsuleColorsList;
