import React from 'react';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { Modal } from '@/Components/Modal';
import { ModalBody } from './Components/ModalBody';
import { IFormGroupModal } from './interfaces';
import { useSubGroupModalCallAPI } from './Hooks/useSubGroupModalCallAPI';
import { useSubGroupModalFunctions } from './Hooks/useSubGroupModalFunctions';
import { useSubGroupModalMemorizations } from './Hooks/useSubGroupModalMemorizations';

export const CreateSubGroupModal: React.FC<IFormGroupModal> = ({
  visible,
  changeVisible,
  title,
  selectedRowsOnTable,
  createSubGroup,
  editSubGroup,
  productGroup,
}) => {
  const { t } = useTranslation();
  const abbreviatedI18n = 'products.subGroupList.modal';

  const { onOkClickForm, onClose, onCancelClick } = useSubGroupModalFunctions({
    title,
    selectedRowsOnTable,
    editSubGroup,
    createSubGroup,
    changeVisible,
    visible,
  });

  const { okButtonName, initialValues } = useSubGroupModalMemorizations({
    title,
    createSubGroup,
    editSubGroup,
    productGroup,
    selectedRowsOnTable,
  });

  return (
    <Modal
      onOkClickForm={onOkClickForm}
      title={title ? title : t(`${abbreviatedI18n}.title`)}
      body={<ModalBody />}
      visible={visible}
      onCancelClick={onCancelClick}
      htmlType="submit"
      okButtonName={okButtonName}
      loading={title ? editSubGroup?.isLoading : createSubGroup?.isLoading}
      onClose={onClose}
      initialValues={initialValues}
      disabledButtonFormNames={['descricao']}
      withForm
      dataTestIdOkButton="submit-subgroup"
    />
  );
};
