import { useControllerQueryListApiHook } from '@/ControllerApiHook/Controller';
import { ProductAPI } from '@/Data/API/Supply/Product';
import { FETCH_LIST_SUBGROUP_BY_GROUP } from '@/ControllerApiHook/UniqIds/Supply/ProductKeys';

export const useProductCardCallAPI = () => {
  const {
    data: listSubGroups,
    fetchNewPage: fetchNewSubGroupPage,
    refetch: refetchNewPageSubGroupPege,
  } = useControllerQueryListApiHook({
    uniqId: FETCH_LIST_SUBGROUP_BY_GROUP,
    entityApi: ProductAPI.getSubGroupByGroupDropdown,
  });

  return {
    listSubGroups,
    fetchNewSubGroupPage,
    refetchNewPageSubGroupPege,
  };
};
