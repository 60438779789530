import { QueryFunctionContext } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';
import { Http, IQueryListParams } from '../../../Utils/Http';
import { IAddUserInGroup } from '../../Interfaces/request/UserGroup/IAddUserInGroup';
import { IUpdateUserGroupPermission } from '../../Interfaces/request/UserGroup/IUpdateUserGroupPermission';
import {
  IChangeUserGroupStatusRequest,
  ICreateUserGroupRequest,
  IDeleteUserGroup,
  IEditUserGroupRequest,
  IPatchUserGroupNameAndDescription,
  IRemoveUser,
} from '../../Interfaces/request/UserGroup/IUserGroupRequest';
import {
  IListUserGroupForDropdownResponse,
  IListUserGroupResponse,
  IUserGroup,
} from '../../Interfaces/response/User/IGetUserReponse';

export class UserGroupAPI {
  public static async getUserGroup(
    context: QueryFunctionContext
  ): Promise<IUserGroup | null> {
    if (!context.queryKey[1]) return null;
    return await Http.fetcher<IUserGroup>(
      `/pharma/accountmanager/v1/Grupo/${context.queryKey[1]}`
    );
  }

  public static async editUser(body: IEditUserGroupRequest) {
    return await Http.fetcher('/pharma/accountmanager/v1/Grupo', {
      method: 'PUT',
      body: JSON.stringify(body),
    });
  }

  public static async createUserGroup(body: ICreateUserGroupRequest) {
    return await Http.fetcher('/pharma/accountmanager/v1/Grupo', {
      method: 'POST',
      body: JSON.stringify(body),
    });
  }
  public static async ChangeUserGroupStatus(
    body: IChangeUserGroupStatusRequest
  ) {
    return await Http.fetcher(
      '/pharma/accountmanager/v1/Grupo/AtualizarStatus',
      {
        method: 'PUT',
        body: JSON.stringify(body),
      }
    );
  }
  public static async DeleteUserGroup(externalId: string[]) {
    return await Http.fetcher('/pharma/accountmanager/v1/Grupo', {
      method: 'DELETE',
      body: JSON.stringify({ externalId: externalId }),
    });
  }

  public static async listUserGroup(
    context: QueryFunctionContext
  ): Promise<IListUserGroupResponse> {
    return Http.fetcher<IListUserGroupResponse>(
      Http.AddQueryParams(
        '/pharma/accountmanager/v1/Grupo/ListarDetalhado',
        context.queryKey[1] as IQueryListParams
      )
    );
  }

  public static async listUserGroupForDropdown(
    context: QueryFunctionContext
  ): Promise<IListUserGroupForDropdownResponse> {
    return Http.fetcher<IListUserGroupForDropdownResponse>(
      Http.AddQueryParams(
        '/pharma/accountmanager/v1/Grupo/ListarGrupo',
        context.queryKey[1] as IQueryListParams
      )
    );
  }

  public static async getUserListFromUserGroup(
    context: any
  ): Promise<IListUserGroupForDropdownResponse | null> {
    return Http.fetcher<IListUserGroupForDropdownResponse>(
      Http.AddQueryParams(
        '/pharma/accountmanager/v1/Grupo/ListarDetalhadoUsuariosGrupo',
        context.queryKey[1] as IQueryListParams
      )
    );
  }

  public static async isUserGroupNameAlreadyRegistred(name: string) {
    return await Http.fetcher('/pharma/accountmanager/v1/Grupo/ValidacaoNome', {
      method: 'POST',
      body: JSON.stringify({ nome: name }),
    });
  }

  public static async deleteUserGroup(
    body: IDeleteUserGroup
  ): Promise<AxiosResponse> {
    const axios = await Http.axios();
    return axios.delete(`/pharma/accountmanager/v1/Grupo/${body.externalId}`);
  }

  public static async patchNameAndDescription(
    body: IPatchUserGroupNameAndDescription
  ) {
    return Http.fetcher(
      `/pharma/accountmanager/v1/Grupo/AtualizarNomeDescricao/${body.grupoExternalId}`,
      {
        method: 'PATCH',
        body: JSON.stringify(Http.ConvertFieldsToPatch(body.data)),
      }
    );
  }

  public static async addUserInGroup(
    body: IAddUserInGroup
  ): Promise<AxiosResponse> {
    return await Http.fetcher(
      '/pharma/accountmanager/v1/Grupo/AdicionarUsuario',
      {
        method: 'POST',
        body: JSON.stringify(body),
      }
    );
  }

  public static async removeUserInGroup(
    body: IRemoveUser
  ): Promise<AxiosResponse> {
    return await Http.fetcher(
      '/pharma/accountmanager/v1/Grupo/RemoverUsuario',
      {
        method: 'DELETE',
        body: JSON.stringify(body),
      }
    );
  }

  public static async updateGroupPermission(
    body: IUpdateUserGroupPermission
  ): Promise<AxiosResponse> {
    delete body.helpers;
    const axios = await Http.axios();
    return axios.put(
      '/pharma/accountmanager/v1/Grupo/AtualizarPermissao',
      body
    );
  }
}
