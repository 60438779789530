import React, { FC, ReactNode, useEffect } from 'react';
import { Radio } from 'antd';
import { useField } from 'formik';

export interface IRadioGroup {
  name: string;
  children: ReactNode;
  className?: string;
  defaultValue?: any;
  valueChecked?: number;
  changeValue?: number;

  onChange?: (value: string) => void;

  styles?: React.CSSProperties;
}

export const RadioGroup: FC<IRadioGroup> = ({
  name,
  children,
  className,
  defaultValue,
  valueChecked,
  onChange,
  changeValue,
  styles,
}) => {
  const [field, meta, helpers] = useField(name);

  const onChangeFunc = (e: any) => {
    helpers.setValue(e.target.value);
  };

  useEffect(() => {
    changeValue && helpers.setValue(changeValue);
  }, [changeValue, helpers]);

  return (
    <Radio.Group
      className={className}
      value={valueChecked !== undefined ? valueChecked : field.value}
      onChange={(x) => {
        onChangeFunc(x);
        onChange && onChange(x.target.value);
      }}
      defaultValue={defaultValue}
      style={styles}
    >
      {children}
    </Radio.Group>
  );
};
