import { FC, useEffect } from 'react';
import { BodyOrderContent } from './Components/BodyOrderContent';
import { SideRightMenu } from './Components/SideRightMenu';
import { BottomMenu } from './Components/BottomMenu';
import { IGetSaleOrderResponse } from '@/Data/Interfaces/response/Sales/SalesOrder/ISalesOrderResponse';
import { useWithSaleOrderCallAPI } from './Hooks/useWithSaleOrderCallAPI';
import { useWithSaleOrderMemorization } from './Hooks/useWithSaleOrderMemorization';
import { Loading } from '@/Components/Loading';
import { Form } from '@/Components';
import { useWithSaleOrderState } from './Hooks/useWithSaleOrderState';

import styles from './WithSaleOrder.module.scss';

interface IWithSaleOrder {
  saleOrder?: IGetSaleOrderResponse;
}

export const WithSaleOrder: FC<IWithSaleOrder> = ({ saleOrder }) => {
  const pageHeight = document.getElementById(
    'container-oder-sale'
  )?.clientHeight;

  const { mutateAsync, isLoading, isSuccess, variables } =
    useWithSaleOrderCallAPI();

  const { patchSaleOrder, initialValue } = useWithSaleOrderMemorization({
    mutateAsync,
    saleOrder,
  });

  const { contentPageHeight, setContentPageHeight } = useWithSaleOrderState();

  const bottomMenuHeight = 130;

  useEffect(() => {
    setContentPageHeight(pageHeight);
  }, [pageHeight, setContentPageHeight]);

  return (
    <Form className={styles['content']} initialValues={initialValue}>
      {saleOrder ? (
        <div className={styles['body-content']}>
          <div className={styles['body-order-content']}>
            <div
              style={
                contentPageHeight
                  ? { height: contentPageHeight - bottomMenuHeight }
                  : undefined
              }
              id="content-oder-item"
            >
              <BodyOrderContent saleOrder={saleOrder} />
            </div>
            <div style={{ height: '25' }}>
              <div className={styles['divider-horizontal']} />
              <BottomMenu
                saleOrder={saleOrder}
                patchSaleOrder={patchSaleOrder}
                patchSaleOrderLoading={isLoading}
                patchSaleOrderSuccess={isSuccess}
                patchVariables={variables?.data}
              />
            </div>
          </div>

          <div className={styles['divider']} />

          <div className={styles['right-menu-content']}>
            <SideRightMenu
              saleOrder={saleOrder}
              patchSaleOrder={patchSaleOrder}
              patchSaleOrderLoading={isLoading}
              patchSaleOrderSuccess={isSuccess}
              patchVariables={variables?.data?.observacao}
            />
          </div>
        </div>
      ) : (
        <Loading className={styles['loadingPage']} size="50px" />
      )}
    </Form>
  );
};
