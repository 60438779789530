import { IUseMemorizations } from "./interfaces";

export const useProviderMemorizations = ({
  listProviders
}: IUseMemorizations) => {
  const items = listProviders?.data?.map((x: any) => ({
    id: x.externalId,
    label: x.nome,
    subLabel: `${x.tipoPessoaDescricao} - ${x.tipoFornecedor}`,
  }));

  return {
    items
  };
};