import { Button } from '@/Components';
import _ from 'lodash';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './DetailsForm.module.scss';
import { NotificationActions } from '@/Store/Notification/Notification.actions';
import { useDispatch } from 'react-redux';

export const FooterButtons: FC<{ form: any; onChangeDisabled: (x: boolean) => void }> = ({ form, onChangeDisabled }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  return (
    <div className={styles['footer-button']}>
      <Button
        type="secondary"
        children={t('common.cancel')}
        onClick={() => {
          onChangeDisabled(true);
          form.resetForm();
          dispatch(NotificationActions.setCurrentFormIsDirty(false));
        }}
      />
      <Button
        type="primary"
        children={t('common.save')}
        htmlType="submit"
        dataTestId="submit-packaging-classification"
      />
    </div>
  );
};
