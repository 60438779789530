import { useCallback } from 'react';
import { IUseFunctions } from './interfaces';

export const usePackingClassificationFunctions = ({
  refetchPackingClassification,
}: IUseFunctions) => {
  const onSearch = useCallback(
    (search?: string) => {
      refetchPackingClassification({
        search: search,
      });
    },
    [refetchPackingClassification]
  );

  return { onSearch };
};
