import { Input, Select } from 'antd';
import { FC, ReactNode, useEffect, useState } from 'react';
import { InputLabel } from '@/Components/Inputs/Label';
import { IconName } from '@/Components/Icon/IconClasses';
import { Tooltip } from '@/Components/Tooltip';
import { TextShortner } from '@/Components/TextShortner';
import { Icon, Text } from '@/Components';

import './NumberInput.override.scss';
import styles from './NumberInput.module.scss';

type IconsOrTitleStrings = {
  id: number | string;
  icons?: IconName;
  content?: string;
};
interface IDropdown {
  id?: string;
  defaultValue?: string | number;
  loading?: boolean;
  options?: IconsOrTitleStrings[];
  onChange?: (value: number | string) => void;
  disabled?: boolean;
}

interface INumberInput {
  onChange?: (value: number) => void;
  value?: number | string;
  label?: string;
  disabled?: boolean;
  required?: boolean;
  placeHolder?: string;
  min?: number;
  rightIcon?: {
    icon?: IconName;
    copyName?: string;
    toolTipTitle?: ReactNode[];
    titleString?: string;
  };
  dropdownRight?: IDropdown;
}

export const NumberInput: FC<INumberInput> = ({
  onChange,
  value,
  disabled,
  label,
  required,
  placeHolder,
  rightIcon,
  min,
  dropdownRight,
}) => {
  const [stateValue, setStateValue] = useState<string | number>(0);
  const [copied, setCopied] = useState(false);

  useEffect(() => {
    value !== undefined && setStateValue(value);
  }, [value]);

  return (
    <div className={`${styles['Container']}`}>
      {label && <InputLabel label={label} required={required} />}

      <div
        className={`
            ${styles['input-container']} ${
          rightIcon ? styles['with-right-icon'] : null
        }`}
      >
        <Input
          className={`${styles['TextInput']} ${'state-input-number'}`}
          min={min}
          onChange={(event) => {
            const newValue = event.target.value;
            const parsedValue =
              newValue.trim() !== '' ? parseFloat(newValue) : 0;
            if (!isNaN(parsedValue) || parsedValue === undefined) {
              onChange && onChange(parsedValue);
              setStateValue(parsedValue);
            }
          }}
          value={stateValue}
          disabled={disabled}
          placeholder={placeHolder}
          addonAfter={
            rightIcon?.titleString ? (
              <Tooltip
                title={
                  rightIcon.toolTipTitle &&
                  (!copied
                    ? rightIcon.toolTipTitle[0]
                    : rightIcon.toolTipTitle[1])
                }
                showMe={!!rightIcon.toolTipTitle}
                placement="bottomRight"
                copied={copied}
              >
                <TextShortner
                  className={`${styles['Icon-TitleString']} ${styles['addon-center']}`}
                  type="ui-tiny-content"
                  color="text-300"
                  children={rightIcon?.titleString}
                  maxCharacters={2}
                />
              </Tooltip>
            ) : (
              dropdownRight && (
                <Select
                  defaultValue={dropdownRight.defaultValue}
                  style={{ width: 60 }}
                  loading={dropdownRight.loading}
                  disabled={dropdownRight.disabled || disabled}
                  onSelect={(id) =>
                    dropdownRight.onChange && dropdownRight.onChange(id)
                  }
                  className="number-input-dropdown-right"
                >
                  {dropdownRight?.options?.map((x) => (
                    <Select.Option key={`${x.id}_${x.content}`} value={x.id}>
                      {x.content ? (
                        <Text
                          type="ui-tiny-content"
                          color="text-300"
                          children={x.content}
                        />
                      ) : (
                        x.icons && <Icon icon={x.icons} color="text-300" />
                      )}
                    </Select.Option>
                  )) || []}
                </Select>
              )
            )
          }
        />
      </div>
    </div>
  );
};
