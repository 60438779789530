import { IUseMemorizations } from './interface';
import DateUtils from '../../../../../../Utils/DateUtils';
import { useTranslation } from 'react-i18next';

export const useEditMemorizations = ({
  balanceAdjustment,
  preferences,
  loadingBalance,
}: IUseMemorizations) => {
  const { t } = useTranslation();
  const initialValues = {
    ...balanceAdjustment,
    responsavel: balanceAdjustment?.usuarioNome,
    dataAjuste: `${DateUtils.convertDate(
      balanceAdjustment?.dataLancamento,
      preferences.padraoData
    )} ${t('common.dateBreakApart')} ${DateUtils.convertDate(
      balanceAdjustment?.dataLancamento,
      preferences.padraoHora
    )}`,

    produtoDescricao: balanceAdjustment?.produtoDescricao,
    descricaoLocalEstoque: balanceAdjustment?.localEstoqueDescricao,
    numeroLote: balanceAdjustment?.loteNumero,
    nomeFornecedor: balanceAdjustment?.fornecedorNome,
    numeroNotaFiscal: balanceAdjustment?.notaFiscalNumero,
    serieNotaFiscal: balanceAdjustment?.notaFiscalSerie,
  };

  const itemsResponsible = balanceAdjustment
    ? [
        {
          id: balanceAdjustment?.usuarioExternalId,
          avatar: {
            name: balanceAdjustment?.usuarioNome || '',
            lastName: '',
            email: '',
          },
          label: balanceAdjustment?.usuarioNome,
        },
      ]
    : undefined;

  const itemsPreviousBalance = balanceAdjustment
    ? {
        name: 'unidadeMedidaId',
        loading: loadingBalance,
        options: [
          {
            id: balanceAdjustment.unidadeMedidaId,
            content: balanceAdjustment.unidadeMedida.abreviacao,
          },
        ],
      }
    : undefined;

  const itemsAdjustmentQuantity = balanceAdjustment
    ? {
        name: 'unidadeMedidaId',
        loading: loadingBalance,
        options: [
          {
            id: balanceAdjustment.unidadeMedidaId,
            content: balanceAdjustment.unidadeMedida.abreviacao,
          },
        ],
      }
    : undefined;

  const itemsFinalBalance = balanceAdjustment
    ? {
        name: 'unidadeMedidaId',
        loading: loadingBalance,
        options: [
          {
            id: balanceAdjustment.unidadeMedidaId,
            content: balanceAdjustment.unidadeMedida.abreviacao,
          },
        ],
      }
    : undefined;

  return {
    initialValues,
    itemsResponsible,
    itemsPreviousBalance,
    itemsAdjustmentQuantity,
    itemsFinalBalance,
  };
};
