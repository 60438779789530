import { FC } from "react";
import { Col } from 'antd';
import { useTranslation } from 'react-i18next';
import { TextInputWithMask } from '@/Components/Inputs/TextInputWithMask';

export const Cnpj: FC = () => {
  const { t } = useTranslation();

  return (
    <Col span={6}>
      <TextInputWithMask
        name="Cnpj"
        mask="cnpjMask"
        label={t('supply.lot.create.cnpj')}
        disabled
        placeHolder="-"
      />
    </Col>
  );
};