import { useTranslation } from 'react-i18next';
import { Card } from '@/Components';
import { Col, Row } from 'antd';
import { FieldArray, useFormikContext } from 'formik';
import { FC } from 'react';
import { TableProductItem } from './Components/TableProductItem';
import { FinalValuesBottom } from './Components/FinalValuesBottom';

import styles from './ProductCard.module.scss';
import { IProductTableCollums } from './interfaces';

export const PurchaseOrderProductDetails = () => {
  const { t } = useTranslation();

  return (
    <Card title={t('common.productsUppercase')} withoutCollapse={true}>
      <ProductTableCollums />
    </Card>
  );
};

export const ProductTableCollums: FC<IProductTableCollums> = ({
  withSelectedRows,
}) => {
  const form: any = useFormikContext();

  return (
    <div className={styles['container']}>
      <FieldArray
        name="pedidoCompraItens"
        render={() => (
          <div>
            {form.values.pedidoCompraItens &&
              form.values.pedidoCompraItens.map((_x: any, index: any) => (
                <TableProductItem
                  index={index}
                  withSelectedRows={withSelectedRows}
                  key={index}
                />
              ))}
          </div>
        )}
      />

      <FinalValuesBottom withSelectedRows={withSelectedRows} />
    </div>
  );
};
