import { Dropdown, InputWithSearch, TextInput } from '@/Components';
import { Col, Row } from 'antd';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { TextInputNumber } from '@/Components/Inputs/TextInputNumber';
import { useTypes } from '../../../../Utils/utils';
import { useCreateCallAPI } from '../../Hooks/useCreateCallAPI';
import { ILaboratoryListData } from '@/Data/Interfaces/response/Laboratory/ILaboratoryResponse';
import { IListUnitMeasurementPosologyData } from '@/Data/Interfaces/response/UnitMeasurement/IUnitMeasurementResponse';
import { useCreateFunctions } from './Hooks/useCreateFunctions';

export const OtherRow: FC = () => {
  const { t } = useTranslation();

  const {
    unitMeasurementPosologyList,
    isLoadingUnitMeasurement,
    laboratoryList,
    isLoadingLaboratoryList,
    refetchSearchLaboratoryPage,
    fetchNewPageLaboratory,
  } = useCreateCallAPI();

  const { onSearch } = useCreateFunctions({ refetchSearchLaboratoryPage });

  return (
    <Row gutter={[16, 0]}>
      <Col span={9}>
        <InputWithSearch
          items={laboratoryList?.data?.map((value: ILaboratoryListData) => {
            return {
              id: value?.externalId,
              label: value?.laboratorio,
            };
          })}
          name="laboratorioExternalId"
          placeHolder={t(
            'production.pharmaceuticalForm.create.laboratoryPlaceholder'
          )}
          label={t('production.pharmaceuticalForm.create.laboratory')}
          required
          withoutMarginBottom
          isLoading={isLoadingLaboratoryList}
          onScrollEnd={fetchNewPageLaboratory}
          onSearch={onSearch}
        />
      </Col>
      <Col span={4}>
        <Dropdown
          name="usoFormaFarmaceutica"
          items={useTypes?.map((values) => ({
            id: values.id,
            label: values.name,
          }))}
          placeHolder={t('production.pharmaceuticalForm.create.usePlaceholder')}
          required
          label={t('production.pharmaceuticalForm.create.use')}
        />
      </Col>
      <Col span={3}>
        <TextInput
          name="apresentacao"
          placeHolder={t(
            'production.pharmaceuticalForm.create.presentationPlaceholder'
          )}
          required
          label={t('production.pharmaceuticalForm.create.presentation')}
        />
      </Col>
      <Col span={4}>
        <InputWithSearch
          items={unitMeasurementPosologyList?.data?.map(
            (value: IListUnitMeasurementPosologyData) => {
              return {
                id: value?.id,
                label: `${value?.descricao} (${value?.unidadeAbreviacao})`,
              };
            }
          )}
          name="unidadeMedidaId"
          placeHolder={t(
            'production.pharmaceuticalForm.create.unitMeasurementPlaceholder'
          )}
          label={t('production.pharmaceuticalForm.create.unitMeasurement')}
          required
          withoutSearchIcon
          withoutMarginBottom
          isLoading={isLoadingUnitMeasurement}
        />
      </Col>
      <Col span={4}>
        <TextInputNumber
          name="validadeDias"
          placeHolder={t(
            'production.pharmaceuticalForm.create.validityDurationInDaysPlaceholder'
          )}
          required
          withTooltip={{
            icon: 'exclamation-upside-down',
            title: t(
              'production.pharmaceuticalForm.create.validityDurationInDaysTooltips'
            ),
          }}
          label={t(
            'production.pharmaceuticalForm.create.validityDurationInDays'
          )}
          withoutArrows
        />
      </Col>
    </Row>
  );
};
