import { FC } from "react";
import { Col } from 'antd';
import { useTranslation } from 'react-i18next';
import { IAccumulate } from "./interfaces";
import { Dropdown } from "@/Components";
import { useAccumulateMemorizations } from "./Hooks/useAccumulateMemorizations";
import { useAccumulateFunctions } from "./Hooks/useAccumulateFunctions";

export const Accumulate: FC<IAccumulate> = ({
  setAccumulate
}) => {
  const { t } = useTranslation();

  const {
    items
  } = useAccumulateMemorizations();

  const {
    onChange
  } = useAccumulateFunctions({
    setAccumulate
  });

  return (
    <Col span={3}>
      <Dropdown
        name="acumula"
        items={items}
        label={t('products.associated.acumulate')}
        placeHolder={t('products.associated.select')}
        withTooltip={{
          icon: 'exclamation-upside-down',
          title: t('products.associated.acumulateTooltip'),
        }}
        onChange={onChange}
      />
    </Col>
  );
};