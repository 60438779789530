import React from 'react';
import { TFunction } from 'react-i18next';
import { IListSalesOrderData } from '../../../../../Data/Interfaces/response/Sales/SalesOrder/ISalesOrderResponse';
import { i18n, Link, Status, Tooltip } from '../../../../../Components';
import { Color } from '../../../../../Utils/ColorUtils';
import { IconName } from '../../../../../Components/Icon/IconClasses';
import DateUtils from '../../../../../Utils/DateUtils';
import { Avatar } from '../../../../../Components/Avatar';
import { currencyFormater } from '../../../../../Utils/Currency';
import { statusTypes } from '../../../../../Utils/StatusUtils';
import { UppercaseFirstLetter } from '../../../../../Utils/CapitalFirstLetterUtils';
import { history } from '../../../../../Store';
import { getCurrentUserRoles } from '@/Utils/PermissionUtils';
import { PermissionEnum } from '@/Data/Interfaces/model/permissionEnum';
import { StatusTag } from '@/Components/StatusChip';
import { statusTicketsTypeProp } from '../../Tickets/List/tickets.columns';

export const orderCollums = (t: TFunction<'translation', undefined>) => [
  {
    title: t('saleOrder.list.code'),
    dataIndex: 'codigoPedidoVenda',
    key: 'codigoPedidoVenda',
    sortColumn: 'codigoPedidoVenda',
    sorter: true,
    defaultSortOrder: 'descend',
    isHighlightable: true,
    width: '8%',
    render: (x: string, row: IListSalesOrderData) => (
      <Link
        type="ui-tiny-bold"
        to={
          getCurrentUserRoles().some(
            (x) => PermissionEnum.Vendas_PedidosVenda_VerDetalhes === x
          )
            ? `/sales/tickets/${row.atendimentoExternalId}/order`
            : undefined
        }
        children={`#${x}`}
      />
    ),
  },
  {
    title: t('client.listPage.clientName'),
    dataIndex: 'nomeCliente',
    key: 'nomeCliente',
    sortColumn: 'nomeCliente',
    isHighlightable: true,
    render: (x: string) => UppercaseFirstLetter(x),
  },
  {
    title: t('saleOrder.list.service'),
    dataIndex: 'codigoAtendimento',
    key: 'codigoAtendimento',
    sortColumn: 'statusPedidoVenda',
    isHighlightable: true,
    render: (x: string, row: IListSalesOrderData) => (
      <Tooltip
        title={row.statusAtendimento}
        placement="topLeft"
        showMe
        style={{ width: 'fitContent' }}
      >
        <StatusTag
          children={`#${x}`}
          statusColor={
            statusTicketsTypeProp[row.statusAtendimentoId].color as Color
          }
          icon={row.canalIcon as IconName}
          onClick={() =>
            getCurrentUserRoles().some(
              (x) => PermissionEnum.Vendas_PedidosVenda_VerDetalhes === x
            )
              ? history.push(
                  `/sales/tickets/${row.atendimentoExternalId}/order`
                )
              : undefined
          }
        />
      </Tooltip>
    ),
  },
  {
    title: t('saleOrder.list.releaseDate'),
    dataIndex: 'dataLancamento',
    key: 'dataLancamento',
    isHighlightable: true,
    sortColumn: 'dataLancamento',
    render: (date: string) =>
      `${DateUtils.convertDate(date)} ${t(
        'common.complementDateHour'
      )} ${DateUtils.convertDate(date)}`,
  },
  {
    title: t('saleOrder.list.attendant'),
    dataIndex: 'atendente',
    key: 'atendente',
    sortColumn: 'atendente',
    isHighlightable: true,
    render: (name: string) => (
      <Avatar
        type="ui-tiny-bold"
        size="M"
        fisrtName={name}
        description={{
          description: name,
          type: 'ui-tiny-bold',
          color: 'text-50',
        }}
        color="white"
      />
    ),
  },
  {
    title: t('saleOrder.list.value'),
    dataIndex: 'valorPedido',
    key: 'valorPedido',
    sortColumn: 'valorPedido',
    isHighlightable: true,
    render: (value: string) => currencyFormater(parseFloat(value)),
  },
  {
    title: 'Status',
    dataIndex: 'statusPedidoVenda',
    key: 'statusPedidoVenda',
    sortColumn: 'statusPedidoVenda',
    isHighlightable: true,

    render: (statusId: string) => (
      <Status
        type={statusOrderTypeProp[parseInt(statusId)]?.status as statusTypes}
        children={statusOrderTypeProp[parseInt(statusId)]?.description || ''}
      />
    ),
  },
];

const statusType = {
  pending: 0,
  budget: 1,
  approved: 2,
  failed: 3,
  delivered: 4,
  partialDelivery: 5,
  canceled: 6,
};

export const statusOrderTypeProp: {
  [x: number]: {
    status: statusTypes;
    description: string;
  };
} = {
  [statusType.pending]: {
    status: 'default',
    description: i18n.t('saleOrder.list.status.pending'),
  },
  [statusType.budget]: {
    status: 'attention',
    description: i18n.t('saleOrder.list.status.budget'),
  },
  [statusType.approved]: {
    status: 'success',
    description: i18n.t('saleOrder.list.status.approved'),
  },
  [statusType.failed]: {
    status: 'danger',
    description: i18n.t('saleOrder.list.status.failed'),
  },
  [statusType.delivered]: {
    status: 'success',
    description: i18n.t('saleOrder.list.status.delivered'),
  },
  [statusType.partialDelivery]: {
    status: 'attention',
    description: i18n.t('saleOrder.list.status.partialDelivery'),
  },
  [statusType.canceled]: {
    status: 'danger',
    description: i18n.t('saleOrder.list.status.canceled'),
  },
};
