import { SelectableUserList } from '../../../../Components/SelectableUserList';
import { IUseMemorizations } from './interfaces';
import { useMemo } from 'react';

export const useDetailsCardMemorizations = ({
  usersSelected,
  userList,
}: IUseMemorizations) => {
  const userListData = useMemo(
    () =>
      userList?.data?.data?.map((x: any) => ({
        id: x.externalId,
        label: x.nome,
        avatar: {
          name: x.nome,
          lastName: x.sobrenome,
          email: x.email,
        },
      })),
    [userList?.data]
  );

  const items = useMemo(() => {
    return usersSelected?.map((x) => ({
      id: x.externalId,
      data: SelectableUserList(x.nome, x.sobrenome, x.email),
    }));
  }, [usersSelected]);

  return { userListData, items };
};
