import * as yup from 'yup';
import i18n from '@/Components/i18n';

export const EditSchema = yup.object().shape({
  nome: yup
    .string()
    .test(
      'empty',
      i18n.t('userGroups.editGroups.errors.requiredName'),
      (name) => !!name && name?.replace(/\s/g, '').length > 0
    )
    .required(i18n.t('userGroups.editGroups.errors.requiredName')),
  descricao: yup
    .string()
    .max(200, i18n.t('userGroups.editGroups.errors.maxDescriptions')),
});
