import { useControllerMutateApiHook } from '@/ControllerApiHook/Controller';
import { BalanceAdjustmentsAPI } from '@/Data/API/Supply/BalanceAdjustmentsApi';
import {
  CREATE_BALANCE_ADJUSTMENTS,
  FETCH_LIST_BALANCE_ADJUSTMENTS,
} from '@/ControllerApiHook/UniqIds/Supply/BalanceAdjustmentsKeys';
import { useQueryClient } from '@tanstack/react-query';
import { history } from '@/Store';
import StorageUtils from '@/Utils/StorageUtils';

export const useCreateCallAPI = () => {
  const queryClient = useQueryClient();

  const { mutateAsync: createBalanceAdjustment, isLoading }: any =
    useControllerMutateApiHook({
      uniqId: CREATE_BALANCE_ADJUSTMENTS,
      entityApi: BalanceAdjustmentsAPI.postBalanceAdjustment,
      options: {
        onSuccess: () => {
          queryClient.invalidateQueries([FETCH_LIST_BALANCE_ADJUSTMENTS]);
          history.push('/inventory/balance-adjustments');
        },
      },
    });

  const preferences = StorageUtils.getPreference();

  return { createBalanceAdjustment, isLoading, preferences };
};
