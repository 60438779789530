import { useMemo } from 'react';
import { IUseCreateClientMemorizations } from './interfaces';
import { useTranslation } from 'react-i18next';
import { history } from '@/Store';
import { getUrlFormValues } from '../Utils';

export const useCreateClientMemorizations = ({
  formValues,
}: IUseCreateClientMemorizations) => {
  const { t } = useTranslation();

  const dropdownRight = useMemo(
    () => [
      {
        label: t(
          'callCenter.createService.createClientModal.createCompleteData'
        ),
        onClick: () =>
          history.push(`/sales/customers/create?${getUrlFormValues(formValues)}`),
      },
    ],
    [formValues, t]
  );

  return {
    dropdownRight,
  };
};
