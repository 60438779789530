import { 
  IGetStorageLocationDropDownResponse 
} from "@/Data/Interfaces/response/StorageLocation/IStorageLocationResponse";
import { IUseMemorizations } from "./interfaces";

export const useStorageLocationRowMemorizations = ({
  list,
  destiny,
  origin
}: IUseMemorizations) => {
  const supplyPlaceLocationFromItems = list?.data?.filter(
    (x: IGetStorageLocationDropDownResponse) => x.externalId !== destiny
  ).map((x: IGetStorageLocationDropDownResponse) => (
    {
      id: x.externalId,
      label: x.descricao,
      code: x.nomeEmpresa,
    }
  ));

  const supplyPlaceLocationToItems = list?.data?.filter(
    (x: IGetStorageLocationDropDownResponse) => x.externalId !== origin
  ).map((x: IGetStorageLocationDropDownResponse) => (
    {
      id: x.externalId,
      label: x.descricao,
      code: x.nomeEmpresa,
    }
  ));

  return {
    supplyPlaceLocationFromItems,
    supplyPlaceLocationToItems
  }
};