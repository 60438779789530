import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Card } from '../../../../../../../../Components';
import { Avatar } from '../../../../../../../../Components/Avatar';
import { Modal } from '../../../../../../../../Components/Modal';
import { EditModalInfoBody } from './EditModal/editModalBody';
import { useEditCallAPI } from '../../../Hooks/useEditCallAPI';
import { useEditStates } from '../../../Hooks/useEditStates';
import { useEditFunctions } from '../../../Hooks/useEditFunctions';
import { RowsSupplierInfoCard } from './Rows';
import { useParams } from 'react-router';

export interface IInfoSupplierInterface {
  supplier?: any;
}

export const InfoSupplierCard: FC<IInfoSupplierInterface> = ({ supplier }) => {
  const { externalId } = useParams<{ externalId: string }>();
  const { t } = useTranslation();

  const { editCard, setEditCard } = useEditStates();

  const { editSupplierInfo, isLoadingEditSupplierInfo } = useEditCallAPI({
    externalId,
  });

  const { onEditInfoSupplier } = useEditFunctions({
    editSupplierInfo,
    supplier,
  });

  return (
    <>
      <Card
        title={
          <Avatar
            fisrtName={supplier?.nome}
            size="M"
            type="ui-tiny-bold"
            description={{
              description: supplier?.nome,
              type: 'heading4',
              color: 'text-50',
            }}
            color="white"
          />
        }
        withoutCollapse
      >
        <RowsSupplierInfoCard supplier={supplier} setEditCard={setEditCard} />
      </Card>

      {supplier && (
        <Modal
          visible={editCard}
          onCancelClick={() => setEditCard(false)}
          onClose={() => setEditCard(false)}
          okButtonName={
            isLoadingEditSupplierInfo ? t('common.saving') : t('common.save')
          }
          loading={isLoadingEditSupplierInfo}
          title={t('purchasing.provider.details.cards.infoCard.editDetail')}
          body={<EditModalInfoBody supplier={supplier} />}
          withForm
          widthModal={800}
          htmlType="submit"
          initialValues={supplier}
          onOkClickForm={(values) => {
            onEditInfoSupplier(values);
            setEditCard(false);
          }}
        />
      )}
    </>
  );
};
