import { Http, IQueryListParams } from '@/Utils/Http';
import { QueryFunctionContext } from '@tanstack/react-query';

export interface IListFreightTypeData {
  id: number;
  codigo: number;
  descricao: string;
}

export const ListFreightType = async (
  context: QueryFunctionContext
): Promise<IListFreightTypeData[] | undefined> => {
  return await Http.fetcher<IListFreightTypeData[]>(
    Http.AddQueryParams(
      '/pharma/compras/v1/listarTipoFrete',
      context.queryKey[1] as IQueryListParams
    )
  );
};
