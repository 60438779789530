import { useFormikContext } from "formik";
import { useCallback } from "react";

export const useDosageInputsFunctions = () => {
  const form: any = useFormikContext();

  const onChangeMaximalDosage = useCallback((value: any) => {
    if (typeof value === 'number') {
      const minDosage = +form.values.dosagemMinima;
      if (minDosage > value) {
        form.setErrors({
          ...form.errors,
          dosagemMaxima: 'error message',
        });
      }
    } else if (value.includes(',')) {
      const withoutDot = value.replace('.', '');
      const newValue = +withoutDot.replace(',', '.');

      const minDosage = +form.values.dosagemMinima;
      if (minDosage > newValue) {
        form.setErrors({
          ...form.errors,
          dosagemMaxima: 'error message',
        });
      }
    }
  }, [form]);

  return {
    onChangeMaximalDosage
  };
};