import { useState } from 'react';

export const useBodyStates = () => {
  const [visibleClientModal, setVisibleClientModal] = useState(false);
  const [clientName, setClientName] = useState('');

  return {
    visibleClientModal,
    setVisibleClientModal,

    clientName,
    setClientName,
  };
};
