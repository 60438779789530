import { useQueryClient } from '@tanstack/react-query';
import {
  CHANGE_CLIENT_STATUS,
  DELETE_CLIENT,
  FETCH_LIST_CLIENT,
} from '@/ControllerApiHook/UniqIds/People/ClientKeys';
import {
  useControllerMutateApiHook,
  useControllerQueryListApiHook,
} from '@/ControllerApiHook/Controller';
import { ClientAPI } from '@/Data/API/People/ClientApi';
import {
  messageErrorToast,
  messageSucessToast,
} from '@/Utils/MessageErrorToast';
import { i18n } from '@/Components';

export const useListCallAPI = () => {
  const queryClient = useQueryClient();

  const changeStatus: any = useControllerMutateApiHook({
    uniqId: CHANGE_CLIENT_STATUS,
    entityApi: ClientAPI.changeClientStatus,
    options: {
      onSuccess: () => {
        messageSucessToast(i18n.t('client.messages.success.changeStatus'));

        queryClient.invalidateQueries([FETCH_LIST_CLIENT]);
      },
      onError: (x) => messageErrorToast(x),
    },
  });

  const deleteClient: any = useControllerMutateApiHook({
    uniqId: DELETE_CLIENT,
    entityApi: ClientAPI.deleteClient,
    options: {
      onSuccess: () => {
        queryClient.invalidateQueries([FETCH_LIST_CLIENT]);
      },
    },
  });

  const {
    data: clientList,
    isLoading,
    refetch: refetchClientList,
  } = useControllerQueryListApiHook({
    uniqId: FETCH_LIST_CLIENT,
    entityApi: ClientAPI.listClient,
    pagination: {
      sorter: { column: 'nome', direction: 'ASC' },
    },
  });

  return {
    changeStatus,
    deleteClient,
    clientList,
    refetchClientList,
    isLoading,
  };
};
