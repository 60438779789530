import React, { FC } from 'react';
import { IText, Text } from '../Text';
import { history } from '../../Store';

import styles from './Link.module.scss';
export interface ILink extends IText {
  to?: string;
  withBreadCrumb?: boolean;
}

export const Link: FC<ILink> = ({
  to,
  type,
  color,
  children,
  className,
  onClick,
  style,
}) => {
  return (
    <Text
      type={type}
      color={color}
      className={`${className} ${styles['Link']}`}
      onClick={() => {
        to && history.push(to);
        onClick && onClick();
      }}
      style={style}
    >
      {children}
    </Text>
  );
};
