import { useCallback } from 'react';
import { ICreateUserRequest } from '@/Data/Interfaces/request/User/IUserRequest';
import { IUseFunctions } from './interface';

export const useCreateUserFunctions = ({ createUser }: IUseFunctions) => {
  const onSubmit = useCallback(
    async (values: ICreateUserRequest) => await createUser.mutateAsync(values),
    [createUser]
  );

  return {
    onSubmit,
  };
};
