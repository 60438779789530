import { useCallback } from 'react';
import { IUseCreateClientFunctions } from './interfaces';
import { ClientType } from '../Utils';

export const useCreateClientFunctions = ({
  createClient,
}: IUseCreateClientFunctions) => {
  const onOkClickForm = useCallback(
    (x: any) => {
      createClient.mutateAsync(
        x.clientType === ClientType.cpf
          ? { nome: x.nome, cpf: x.cpf, contatos: x.contatos }
          : {
              nome: x.nome,
              cnpj: x.cnpj,
              razaoSocial: x.razaoSocial,
              contatos: x.contatos,
            }
      );
    },
    [createClient]
  );
  return { onOkClickForm };
};
