import { FC } from "react";
import { Col } from 'antd';
import { useTranslation } from "react-i18next";
import { WeightInput } from '@/Components/Inputs/WeightInput';
import { IAlternativeUnit } from "./interfaces";
import { useAlternativeUnitMemorizations } from "./Hooks/useAlternativeUnitMemorizations";

export const AlternativeUnit: FC<IAlternativeUnit> = ({
  unidadeAlternativaList
}) => {
  const { t } = useTranslation();

  const {
    label,
    dropdownLeft,
    dropdownRight
  } = useAlternativeUnitMemorizations({
    unidadeAlternativaList
  });

  return (
    <Col span={6}>
      <WeightInput
        name="loteInformacaoTecnica.loteUnidadeAlternativa1.quantidadeUnidadeAlternativa"
        label={label}
        placeHolder={t('supply.lot.create.alternativeUnitOne')}
        dropdownLeft={dropdownLeft}
        dropdownRight={dropdownRight}
      />
    </Col>
  );
};