import { IListProviderData } from "@/Data/Interfaces/response/Provider/IProviderResponse";
import { IUseMemorizations } from "./interfaces";

export const useProviderMemorizations = ({
  listProvider
}: IUseMemorizations) => {
  const formattedListProvider =
  listProvider && listProvider.data ? listProvider.data : [];

  const items = formattedListProvider?.map((value: IListProviderData) => ({
    id: value?.externalId,
    label: value?.nome,
    subLabel: `${value.tipoPessoaDescricao} - ${value.tipoFornecedor}`,
  }));

  return {
    items
  };
};