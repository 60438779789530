import {
  IProductGroupResponse,
  IProductSubGroupResponse,
} from '../../../../../../../Data/Interfaces/response/Product/IProductRespose';

export const CreateProductInitialValues = (
  queryParams: URLSearchParams,
  productGroup?: IProductGroupResponse,
  productSubGroup?: IProductSubGroupResponse
) => ({
  controlaLote: false,
  controlaQualidade: false,
  usoContinuo: false,
  etiqueta: false,
  ativo: true,
  grupoExternalId: queryParams.get('group')
    ? productGroup?.externalId
    : productSubGroup?.grupoExternalId,
  subGrupoExternalId: productSubGroup?.externalId,
});
