import { useFormikContext } from "formik";
import { useMemo } from "react";
import { IUseMemorizations } from "./interfaces";

export const useTableProductItemMemorizations = ({
  index
}: IUseMemorizations) => {
  const form: any = useFormikContext();

  const statusProductOrder = useMemo(
    () => form?.values?.pedidoCompraItens?.[index]?.statusCompra,
    [form?.values?.pedidoCompraItens, index]
  );

  return {
    statusProductOrder
  };
};