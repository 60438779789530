import * as yup from 'yup';
import { i18n } from '../../../Components';

export enum ResponsableUserTypeEnum {
  NEW = 1,
  EXISTENT = 2,
}

export const CompanyResponsibleUserCardSchema = yup.object().shape({
  usuario: yup.object().when('responsibleUserRadioButton', {
    is: (value) => value === ResponsableUserTypeEnum.NEW,
    then: yup.object().shape({
      email: yup
        .string()
        .required(
          i18n.t(
            'adminBootis.createCompany.responsibleUserCard.errors.invalidMail'
          )
        ),
      nome: yup
        .string()
        .required(
          i18n.t(
            'adminBootis.createCompany.responsibleUserCard.errors.requiredName'
          )
        ),

      sobrenome: yup
        .string()
        .required(
          i18n.t(
            'adminBootis.createCompany.responsibleUserCard.errors.requiredLastName'
          )
        ),

      dataNascimento: yup
        .string()
        .matches(
          /[0-9]{2}\/[0-9]{2}\/[0-9]{4}/,
          i18n.t(
            'adminBootis.createCompany.responsibleUserCard.errors.invalidBirthday'
          )
        ),

      cpf: yup
        .string()
        .matches(
          /[0-9]{3}\.[0-9]{3}\.[0-9]{3}-[0-9]{2}/,
          i18n.t(
            'adminBootis.createCompany.responsibleUserCard.errors.invalidCpf'
          )
        ),
    }),
  }),
  usuarioResponsavelExternalId: yup
    .string()
    .when('responsibleUserRadioButton', {
      is: (value) =>
        value === undefined || value === ResponsableUserTypeEnum.EXISTENT,
      then: yup
        .string()
        .required(
          i18n.t(
            'adminBootis.createCompany.responsibleUserCard.errors.requiredUser'
          )
        ),
    }),
});
