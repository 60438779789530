import { useCallback } from 'react';
import { IUseRequiredPermissionModalFunctions } from './interfaces';

export const useRequiredPermissionModalFunctions = ({
  error,
  updateUserPermission,
  payloadRequest,
  changeVisibleModal,
  form,
}: IUseRequiredPermissionModalFunctions) => {
  const onOkClick = useCallback(() => {
    if (error) {
      updateUserPermission.mutateAsync({
        ...payloadRequest,
        dependencias: error.dependencias.map((x) => x.permissaoId),
      });
    }
  }, [error, payloadRequest, updateUserPermission]);

  const onCloseClick = useCallback(() => {
    form.resetForm();
    changeVisibleModal();
  }, [changeVisibleModal, form]);

  return {
    onOkClick,
    onCloseClick,
  };
};
