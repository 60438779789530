import { useFormikContext } from 'formik';
import { IUseFunctions } from './interfaces';

export const useProductCardFunctions = ({
  productRowAmount,
  setProductRowAmount,
}: IUseFunctions) => {
  const form: any = useFormikContext();

  const onProductDeleteRowAmount = (index: number) => {
    if (productRowAmount.length === 1) return;
    const orderPurchase = form.values.pedidoCompraItens.slice();
    orderPurchase.splice(index, 1);
    form.setFieldValue('pedidoCompraItens', orderPurchase);

    const newUuids = productRowAmount.slice();
    newUuids.splice(index, 1);
    setProductRowAmount(newUuids);
  };

  const onDelete = (index: number) => onProductDeleteRowAmount(index);

  return {
    onProductDeleteRowAmount,
    onDelete,
  };
};
