import { Modal, Text } from '@/Components';
import { Divflex } from '@/Layouts/DivWhithFlex';
import { FormikContextType, useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';
import ReactMarkdown from 'react-markdown';

interface IChangeStorageLocationModalProps {
  visible: boolean;
  changeVisibleModal: (value: boolean) => void;
  onOkClick: () => void;
  origin?: string;
}

export const ChangeStorageLocationModal: React.FC<
  IChangeStorageLocationModalProps
> = ({ visible, changeVisibleModal, onOkClick, origin }) => {
  const { t } = useTranslation();

  const form: FormikContextType<any> = useFormikContext();

  return (
    <Modal
      visible={visible}
      title={t('supply.transfers.changeStorageLocationModal.title')}
      body={
        <div style={{ padding: '20px' }}>
          <Divflex>
            <Text color="text-50" type="ui-tiny-content">
              <ReactMarkdown>
                {t('supply.transfers.changeStorageLocationModal.description')}
              </ReactMarkdown>
            </Text>
          </Divflex>
        </div>
      }
      okButtonName={t('common.change')}
      onCancelClick={() => {
        form.setFieldValue('origin', origin);
        changeVisibleModal(false);
      }}
      onClose={() => changeVisibleModal(false)}
      onOkClick={onOkClick}
    />
  );
};
