import { useCallback } from 'react';
import { handleModalError } from '@/Utils/ErrorCodeUtils/ErrorHandlingUtils';
import { cannotDeleteClientWithMovementsInTheSystemProps } from '@/Utils/ErrorCodeUtils/ErrorModalUtils/ErrorModalClient';
import { App } from 'antd';
import { useTranslation } from 'react-i18next';
import { IUseFunctions } from './interfaces';

export const useModalFunctions = ({
  clientExternalId,
  deleteClient,
  changeVisibleModal,
}: IUseFunctions) => {
  const { t } = useTranslation();
  const app = App.useApp();

  const onOkClickDeleteModal = useCallback(async () => {
    if (clientExternalId)
      await deleteClient?.mutateAsync(
        {
          clientesExternalId: [clientExternalId],
        },
        {
          onError: handleModalError(
            app,
            cannotDeleteClientWithMovementsInTheSystemProps(t),
            changeVisibleModal
          ),
        }
      );
  }, [deleteClient, clientExternalId, t, app, changeVisibleModal]);

  return { onOkClickDeleteModal };
};
