import { useCallback } from 'react';
import { App } from 'antd';
import { useTranslation } from 'react-i18next';
import { IUseFunctions } from './interfaces';
import { IPackingClassificationListData } from '@/Data/Interfaces/response/PackingClassification/IPackingClassificationResponse';
import { IPathPackingClassificationStatusRequest } from '@/Data/Interfaces/request/PackingClassification/IPackingClassificationRequest';
import { IPatchPharmaceuticalFormStatusRequest } from '@/Data/Interfaces/request/PharmaceuticalForm/IPharmaceuticalFormRequest';
import { handleModalError } from '@/Utils/ErrorCodeUtils/ErrorHandlingUtils';
import { cannotDeletePackingClassificationWithMovementsInTheSystemProps } from '@/Utils/ErrorCodeUtils/ErrorModalUtils/ErrorModalPackingClassification';

export const useListFunctions = ({
  deletePackingClassificationForm,
  editPackingClassificationFormStatus,
  setSelectedRowsOnTable,
}: IUseFunctions) => {
  const { t } = useTranslation();
  const app = App.useApp();
  const onSelectedRowsOnTable = useCallback(
    (x: IPackingClassificationListData[]) => setSelectedRowsOnTable(x),
    [setSelectedRowsOnTable]
  );

  const changePharmaceuticalFormStatus = useCallback(
    async (values: IPathPackingClassificationStatusRequest) => {
      var body: IPatchPharmaceuticalFormStatusRequest = { ...values };
      await editPackingClassificationFormStatus.mutateAsync({
        ...body,
      });
    },
    [editPackingClassificationFormStatus]
  );

  const onEdit = useCallback(
    (
      packingClassificationForm: IPackingClassificationListData[],
      values: any
    ) =>
      changePharmaceuticalFormStatus({
        externalId: packingClassificationForm.map(
          (x: IPackingClassificationListData) => x.externalId
        ),
        ativo: values.status === 'success',
      }),
    [changePharmaceuticalFormStatus]
  );

  const onDelete = useCallback(
    async (packingClassificationForm: IPackingClassificationListData[]) => {
      await deletePackingClassificationForm.mutateAsync(
        {
          externalId: packingClassificationForm.map((x) => x?.externalId),
        },
        {
          onError: handleModalError(
            app,
            cannotDeletePackingClassificationWithMovementsInTheSystemProps(t)
          ),
        }
      );
    },
    [deletePackingClassificationForm, app, t]
  );

  return { onSelectedRowsOnTable, onEdit, onDelete };
};
