import { useCallback } from 'react';
import { IUseFunctions } from './interfaces';

export const useSpecialtiesFunctions = ({
  refetchSearchSpecialties,
}: IUseFunctions) => {
  const onSearch = useCallback(
    (search?: string) => {
      refetchSearchSpecialties({
        search: search,
      });
    },
    [refetchSearchSpecialties]
  );

  return { onSearch };
};
