import React, { useEffect, useState, FC } from 'react';
import BreadCrumb from '../../Components/HeaderMenu/Components/BreadCrumb';

import styles from './BreadCrumbLayout.module.scss';

export interface IBreadCrumbLayout {
  className?: string;
}

const BreadCrumbLayout: FC<IBreadCrumbLayout> = ({ className }) => {
  const [hideAmount, setHideAmount] = useState(0);
  const [width, setWidth] = useState(0);

  const containerRef = React.createRef<HTMLDivElement>();
  const containerRightMenuRef = React.createRef<HTMLDivElement>();

  useEffect(() => {
    var widthPorcent =
      containerRef.current?.getBoundingClientRect().width &&
      containerRightMenuRef.current?.getBoundingClientRect().width &&
      (containerRef.current?.getBoundingClientRect().width * 100) /
        containerRightMenuRef.current?.getBoundingClientRect().width;

    widthPorcent && widthPorcent < 57 && width !== widthPorcent
      ? hideAmount > 0 && setHideAmount(hideAmount - 1)
      : widthPorcent && width !== widthPorcent && setHideAmount(hideAmount + 1);

    widthPorcent && widthPorcent < 57 && setWidth(widthPorcent);
  }, [containerRef, containerRightMenuRef, hideAmount, width]);

  return (
    <div
      className={`${className} ${styles['bread-crumb']}`}
      ref={containerRightMenuRef}
      id="breadcrumb"
    >
      <BreadCrumb hideAmount={hideAmount} />
    </div>
  );
};

export default BreadCrumbLayout;
