import { UserForm } from '@/Pages/Sectors/Administrative/User/CreateForm';
import { EditUser } from '@/Pages/Sectors/Administrative/User/EditForm';
import { List } from '@/Pages/Sectors/Administrative/User/List';
import { Route, Switch } from 'react-router-dom';

export const AdminUserRoutes = () => (
  <Switch>
    <Route path="/admin/user/create" component={UserForm} />
    <Route path="/admin/user/:externalId/:tab" component={EditUser} />
    <Route path="/admin/user" component={List} />
  </Switch>
);
