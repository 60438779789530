import { Text } from '@/Components';
import { useTranslation } from 'react-i18next';
import { Divflex } from '@/Layouts/DivWhithFlex';
import ReactMarkdown from 'react-markdown';
import { 
  FooterThisActionIsPermanent 
} from '@/Components/Table/components/Modals/DeleteModal';

export const DeleteModalBody = ({
  selectedRowsOnTable,
  currentProduct,
}: {
  selectedRowsOnTable: any[];
  currentProduct: string;
}) => {
  const { t } = useTranslation();

  if (selectedRowsOnTable.length === 1) {
    return (
      <div style={{ padding: '20px' }}>
        <Divflex style={{ flexDirection: 'column', width: '100%' }}>
          <Text color="text-50" type="ui-tiny-content">
            <ReactMarkdown>
              {t('modal.deleteModal.single.productIncompatibleQuestion', {
                produtoIncompatível: `**${selectedRowsOnTable[0].produtoIncompativelDescricao}**`,
                produtoPrincipal: `**${currentProduct}**`,
              })}
            </ReactMarkdown>
          </Text>
        </Divflex>
        <FooterThisActionIsPermanent />
      </div>
    );
  }

  return (
    <div style={{ padding: '20px' }}>
      <Divflex style={{ flexDirection: 'column', width: '100%' }}>
        <Text color="text-50" type="ui-tiny-content">
          <ReactMarkdown>
            {t('modal.deleteModal.many.productIncompatibleQuestion', {
              produto: `**${currentProduct}**`,
            })}
          </ReactMarkdown>
        </Text>
      </Divflex>
      <FooterThisActionIsPermanent />
    </div>
  );
};