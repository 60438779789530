import { i18n } from '@/Components';
import * as yup from 'yup';

export const NewPasswordSchema = yup.object().shape({
  newPassword: yup
    .string()
    .min(8, i18n.t('forgotPassword.errors.passMin'))
    .max(25, i18n.t('forgotPassword.errors.passMax'))
    .required(i18n.t('forgotPassword.errors.passRequired')),

  confirmPassword: yup
    .string()
    .oneOf(
      [yup.ref('newPassword')],
      i18n.t('forgotPassword.errors.differenPassword')
    )
    .required(i18n.t('forgotPassword.errors.confirmPassRequired')),
});
