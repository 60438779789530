import {
  FETCH_LIST_STANDARD_FORMULA
} from '@/ControllerApiHook/UniqIds/Production/StandardFormulaKeys';
import { FormikHelpers, FormikValues } from 'formik';
import { history } from '@/Store';
import { useQueryClient } from '@tanstack/react-query';
import { IUseFunctions } from './interfaces';
import { NotificationActions } from '@/Store/Notification/Notification.actions';
import { useDispatch } from 'react-redux';

export const useDetailFunctions = ({
  editStandardFormula,
  externalId,
  onChangeDisabled
}: IUseFunctions) =>  {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();

  const onSubmit = async (
    values: any,
    helpers: FormikHelpers<FormikValues>
  ) => {
    const res = await editStandardFormula.mutateAsync({
      externalId: externalId,
      produtoExternalId: values.produtoExternalId,
      formaFarmaceuticaExternalId: values.formaFarmaceuticaExternalId,
      formulaPadraoDesmembramento: values.formulaPadraoDesmembramento,
      unidadeMedidaId: values.unidadeMedidaId,
      quantidadePadrao: values.quantidadePadrao,
      diasValidade: !values.diasValidade ? 0 : values.diasValidade,
      diluicao: values.diluicao,
      densidade: values.densidade,
      procedimento: values.procedimento,
      rodape: values.rodape,
      produtos: values.produtos,
      helpers,
    });
    if (res.success) {
      queryClient.invalidateQueries([FETCH_LIST_STANDARD_FORMULA]);
      history.push('/production/standard-formula');
    }
  };

  const onClickCancelButton = (resetForm: () => void) => {
    onChangeDisabled(true);
    resetForm();
    dispatch(NotificationActions.setCurrentFormIsDirty(false));
  }

  return {
    onSubmit,
    onClickCancelButton
  }
};