import { useState } from "react";

export const useFormGroupModalStates = () => {
  const [
    productGroupAlreadyRegistred, 
    setProductGroupAlreadyRegistred
  ] = useState<boolean>(false);

  return {
    productGroupAlreadyRegistred,
    setProductGroupAlreadyRegistred
  };
};