import { useState } from "react";

export const useStatusCardStates = () => {
  const [approveModal, setApproveModal] = useState(false);

  const [reproveModal, setReproveModal] = useState(false);

  const [reverseModal, setReverseModal] = useState(false);

  const [
    confirmWithSupplierModal, 
    setConfirmWithSupplierModal
  ] = useState(false);

  return {
    approveModal,
    setApproveModal,
    reproveModal,
    setReproveModal,
    reverseModal,
    setReverseModal,
    confirmWithSupplierModal,
    setConfirmWithSupplierModal
  };
};