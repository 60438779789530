import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { currencyFormater } from '@/Utils/Currency';
import { IFullDiscount } from './interfaces';
import { Tooltip } from '@/Components';
import { DiscountInput } from '@/Components/Inputs/DiscountInput';
import { useFullDiscountMemorizations } from './Hooks/useFullDiscountMemorizations';

export const FullDiscount: FC<IFullDiscount> = ({ orderPurchase }) => {
  const { t } = useTranslation();

  const { showMe, placeholder, dropdownLeft } = useFullDiscountMemorizations({
    orderPurchase,
  });

  return (
    <>
      <Tooltip
        title={`${t('common.originalValue')} ${currencyFormater(
          orderPurchase?.valorDesconto
        )}`}
        showMe={showMe}
        targetOffset={[60, -12]}
      >
        <DiscountInput
          name="valorDesconto"
          label={t('purchasing.purchaseOrder.create.totalCard.fullDiscount')}
          placeHolder={placeholder}
          withoutMarginBottom
          dropdownLeft={dropdownLeft}
          withStatusIcon={showMe}
        />
      </Tooltip>
    </>
  );
};
