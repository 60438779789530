import {
  useControllerMutateApiHook,
  useControllerQueryListApiHook,
} from '@/ControllerApiHook/Controller';
import {
  DELETE_PRODUCT_LINKED_MESSAGE,
  FETCH_LIST_PRODUCT_LINKED_BY_MESSAGE,
  FETCH_LIST_PRODUCT_MESSAGE,
} from '@/ControllerApiHook/UniqIds/Supply/ProductMessageKeys';
import { ProductMessageAPI } from '@/Data/API/Supply/ProductMensage';
import { useQueryClient } from '@tanstack/react-query';
import { useParams } from 'react-router';

export const useListCallAPI = () => {
  const { externalId } = useParams<{ externalId: string }>();
  const queryClient = useQueryClient();

  const {
    data: productLinkedByMessageList,
    isLoading: isLoadingProductLinkedByMessageList,
    refetch: refetchProductLinkedByMessageList,
  } = useControllerQueryListApiHook({
    uniqId: FETCH_LIST_PRODUCT_LINKED_BY_MESSAGE,
    entityApi: ProductMessageAPI.getProductLinkedByMessageList,
    pagination: {
      sorter: { column: 'descricao', direction: 'ASC' },
      filter: [{ filterName: 'mensagemExternalId', value: externalId }],
    },
  });

  const { mutateAsync: deleteProductLinkedByMessage } =
    useControllerMutateApiHook({
      uniqId: DELETE_PRODUCT_LINKED_MESSAGE,
      entityApi: ProductMessageAPI.deleteProductLinkedMessageRequest,
      options: {
        onSuccess: () =>
          queryClient.invalidateQueries([FETCH_LIST_PRODUCT_MESSAGE]),
      },
    });

  return {
    productLinkedByMessageList,
    isLoadingProductLinkedByMessageList,
    deleteProductLinkedByMessage,
    refetchProductLinkedByMessageList,
  };
};
