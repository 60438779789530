import { EditExpertise } from '@/Pages/Sectors/Sales/Expertise/Edit';
import { List } from '@/Pages/Sectors/Sales/Expertise/List';
import { Route, Switch } from 'react-router-dom';

export const ExpertiseRoutes = () => (
  <Switch>
    <Route path="/sales/expertise/:externalId/:tab" component={EditExpertise} />
    <Route path="/sales/expertise" component={List} />
  </Switch>
);
