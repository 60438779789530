import { useControllerMutateApiHook } from '@/ControllerApiHook/Controller';
import { UserGroupAPI } from '@/Data/API/Accountmanager/UserGroupApi';
import {
  USER_GROUP_NAME_ALREADY_REGISTRED_TYPE
} from '@/ControllerApiHook/UniqIds/Accountmanager/UserGroupKeys';
import { useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';

export const useUserGroupNameFieldCallAPI = () => {
  const { t } = useTranslation();

  const form = useFormikContext();

  const { mutateAsync: getThereIsUserGroupName } = useControllerMutateApiHook({
    uniqId: USER_GROUP_NAME_ALREADY_REGISTRED_TYPE,
    entityApi: UserGroupAPI.isUserGroupNameAlreadyRegistred,
    options: {
      onError: () => {
        form.setErrors({
          ...form.errors,
          nome: t('common.error.userGroupNameRegistred'),
        });
      },
      onSuccess: () => {
        form.setErrors({
          ...form.errors,
          nome: undefined,
        });
      }
    }
  });

  return {
    getThereIsUserGroupName
  };
};