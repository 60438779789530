import { AxiosResponse } from 'axios';
import { Http, IQueryListParams } from '../../../Utils/Http';
import {
  IEditLotRequest,
  IPatchLotStatusRequest,
} from '../../Interfaces/request/Lot/IPatchLotStatusRequest';
import {
  IGetLotDropDownResponse,
  IGetLotResponse,
  ILotListResponse,
  IStorageLocationListByLot,
} from '../../Interfaces/response/Lot/IListLot';
import { IListStatusLotResponse } from '../../Interfaces/response/Lot/IListLot';
import { IPostTransferLot } from '../../Interfaces/request/Transfer/IPostTransferLot';
import {
  ICreateLotRequest,
  IDeleteLotRequest,
} from '../../Interfaces/request/Lot/ILotResquest';
import { QueryFunctionContext } from '@tanstack/react-query';
import { ILotDetailtByStockLocationResponse } from '../../Interfaces/response/Lot/ILotResponse';
import { ILotSaldosOriginAndDestinyResponse } from '../../Interfaces/response/Lot/ILotSaldosOriginAndDestinyResponse';

export class LotsAPI {
  public static async getLotListDropDown(
    context: QueryFunctionContext
  ): Promise<IGetLotDropDownResponse | null> {
    return await Http.fetcher(
      Http.AddQueryParams(
        `/pharma/estoque/v1/Lote/Listar`,
        context.queryKey[1] as IQueryListParams
      )
    );
  }

  public static async createLot(body: ICreateLotRequest) {
    return await Http.fetcher('/pharma/estoque/v1/Lote', {
      method: 'POST',
      body: JSON.stringify(body),
    });
  }

  public static async listLots(
    context: QueryFunctionContext
  ): Promise<ILotListResponse> {
    return await Http.fetcher<ILotListResponse>(
      Http.AddQueryParams(
        '/pharma/estoque/v1/Lote/ListarDetalhado',
        context.queryKey[1] as IQueryListParams
      )
    );
  }

  public static async getLotByProductStockLocation(
    context: QueryFunctionContext
  ): Promise<IGetLotDropDownResponse | null> {
    return await Http.fetcher<IGetLotDropDownResponse>(
      Http.AddQueryParams(
        `/pharma/estoque/v1/SaldoEstoque/ListarDetalhadoPorLote`,
        context.queryKey[1] as IQueryListParams
      )
    );
  }

  public static async getLotDetailstByStockLocation(
    context: QueryFunctionContext
  ): Promise<ILotDetailtByStockLocationResponse | null> {
    if (!!context.queryKey[1] && !!context.queryKey[2])
      return await Http.fetcher<ILotDetailtByStockLocationResponse>(
        `/pharma/estoque/v1/SaldoEstoque/LotePorLocalEstoque/${context.queryKey[1]}/${context.queryKey[2]}`
      );
    else return null;
  }

  public static async patchLotStatus(body: IPatchLotStatusRequest) {
    return await Http.fetcher(`/pharma/estoque/v1/Lote/AtualizarStatus`, {
      method: 'PUT',
      body: JSON.stringify(body),
    });
  }

  public static async getLot(context: QueryFunctionContext) {
    return await Http.fetcher<IGetLotResponse>(
      `/pharma/estoque/v1/Lote/${context.queryKey[1]}`
    );
  }

  public static async getSaldosOriginAndDestiny(
    context: QueryFunctionContext
  ): Promise<ILotSaldosOriginAndDestinyResponse | null> {
    if (!context.queryKey[1]) return null;
    return await Http.fetcher<ILotSaldosOriginAndDestinyResponse>(
      `/pharma/estoque/v1/Lote/LoteSaldoOrigemDestino/${context.queryKey[1]}/${context.queryKey[2]}/${context.queryKey[3]}`
    );
  }

  public static async editLot(body: IEditLotRequest) {
    const res = await Http.fetcher(`/pharma/estoque/v1/Lote`, {
      method: 'PUT',
      body: JSON.stringify(body),
    });

    if (!res) return [];

    return res;
  }

  public static async statusLotList(): Promise<IListStatusLotResponse[]> {
    return await Http.fetcher<IListStatusLotResponse[]>(
      '/pharma/estoque/v1/SituacaoLote/Listar'
    );
  }

  public static async transferLotPost(
    transfer: IPostTransferLot
  ): Promise<AxiosResponse> {
    const axios = await Http.axios();
    return axios.post(
      '/pharma/estoque/v1/TransferenciaLote/Unificado',
      transfer
    );
  }

  public static async deleteLot(body: IDeleteLotRequest) {
    return await Http.fetcher(`/pharma/estoque/v1/Lote`, {
      method: 'DELETE',
      body: JSON.stringify(body),
    });
  }

  public static async stockLocationsOfLotsList(
    context: QueryFunctionContext
  ): Promise<IStorageLocationListByLot> {
    let url = Http.AddQueryParams(
      `/pharma/estoque/v1/Lote/ListarDetalhadoPorLocalEstoque`,
      context.queryKey[1] as IQueryListParams
    );
    return await Http.fetcher<IStorageLocationListByLot>(url);
  }
}
