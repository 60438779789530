import { FC } from 'react';
import { HorizontalTabs } from '@/Components';
import { PageLoading } from '@/Layouts/PageLoading';
import { useParams } from 'react-router';
import { DeleteModal } from './Modal/DeleteModal/deleteModal';
import { useEditClientStates } from './Hooks/useEditClientStates';
import { useEditClientCallAPI } from './Hooks/useEditClientCallAPI';
import { useEditClientFunctions } from './Hooks/useEditClientFunctions';
import { useEditClientMemorizations } from './Hooks/useEditClientMemorizations';

export const EditCustomers: FC = () => {
  const { externalId } = useParams<{ externalId: string }>();

  const { visibleDeleteModal, setVisibleDeleteModal } = useEditClientStates();

  const { client } = useEditClientCallAPI({ externalId });

  const { changeVisibleModal } = useEditClientFunctions({
    setVisibleDeleteModal,
  });

  const { items, dropdownItems } = useEditClientMemorizations({
    client,
    setVisibleDeleteModal,
  });

  return (
    <PageLoading>
      <HorizontalTabs
        type="secondary"
        items={items}
        dropdownDirection="bottomRight"
        dropdownTitle={'Mais ações'}
        leftIcon="menu-ellipsis-horizontal"
        dropdownItens={dropdownItems}
      />
      {client && (
        <DeleteModal
          clientName={client.nome}
          clientExternalId={client.externalId}
          visibleModal={visibleDeleteModal}
          changeVisibleModal={changeVisibleModal}
        />
      )}
    </PageLoading>
  );
};
