import { TextInput } from "@/Components";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { IMainContact } from "./interfaces";
import { useMainContactMemorizations } from "./Hooks/useMainContactMemorizations";

export const MainContact: FC<IMainContact> = ({
  iconContact,
  documentProvider
}) => {
  const { t } = useTranslation();

  const {
    withTooltip
  } = useMainContactMemorizations({
    documentProvider
  });

  return (
    <>
      <TextInput
        name="fornecedorContatoPrincipal"
        leftIcon={iconContact}
        label={t('purchasing.purchaseOrder.create.mainContact')}
        withoutMarginBottom={true}
        disabled={true}
        withTooltip={withTooltip}
      />
    </>
  );
};