import { useMemo } from 'react';
import { isFormError } from '@/Utils/ErrorUtils';
import { IUseFunctions } from './interfaces';
import _ from 'lodash';
import { v4 as uuidV4 } from 'uuid';

export const useEditFunctions = ({
  setPackingClassificationRowAmount,
  index,
  totalRows,
  onDelete,
  form,
  externalId,
  mutateAsync,
}: IUseFunctions) => {
  const fetchInputPharmaceuticalFormDescription = useMemo(
    () =>
      _.debounce(async (embalagemClassificacaoExternalId: string) => {
        try {
          return await mutateAsync!({
            embalagemClassificacaoExternalId,
            formaFarmaceuticaExternalId: externalId,
          });
        } catch {
          return undefined;
        }
      }, 500),
    [mutateAsync, externalId]
  );

  const addRow = () => {
    if (index === totalRows! - 1) {
      setPackingClassificationRowAmount?.((oldState) => [
        ...oldState,
        uuidV4(),
      ]);

      const itensContainer = document.querySelector(
        '#dropdown-itens-container'
      );
      setTimeout(() => {
        itensContainer?.scroll(0, itensContainer.clientHeight + 200);
      }, 1);
    }
  };

  const deleteRow = (index: number) => {
    if (!form.values.embalagemClassificacaoExternalId) return;
    form.values.embalagemClassificacaoExternalId =
      form.values.embalagemClassificacaoExternalId.filter(
        (_: any, i: number) => i !== index
      );
    if (totalRows === 1) {
      setPackingClassificationRowAmount?.((oldState) => [
        ...oldState,
        uuidV4(),
      ]);
    }
    onDelete && onDelete(index);

    if (isFormError(form.errors)) {
      form?.setErrors({
        ...form.errors,
        embalagemClassificacaoExternalId:
          form.values.embalagemClassificacaoExternalId.map((_: any) => ({
            externalId: undefined,
          })),
      });
    }
  };

  const disabledSelectedItems = (id: string) => {
    if (!form?.values?.embalagemClassificacaoExternalId) return false;
    const externalIdList = form?.values?.embalagemClassificacaoExternalId?.map(
      (value: any) => value?.externalId
    );
    if (externalIdList?.includes(id)) return true;
  };

  return {
    deleteRow,
    addRow,
    fetchInputPharmaceuticalFormDescription,
    disabledSelectedItems,
  };
};
