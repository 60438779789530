import { IGetSaleOrderResponse } from '@/Data/Interfaces/response/Sales/SalesOrder/ISalesOrderResponse';
import { UseMutateAsyncFunction } from '@tanstack/react-query';
import { FormikContextType, FormikHelpers, FormikValues } from 'formik';
import { Dispatch, SetStateAction, useCallback } from 'react';
import { retiradaNoBalcao } from '..';

interface IuseContentBodyFunctions {
  updateDelivery: UseMutateAsyncFunction<unknown, unknown, unknown, unknown>;
  saleOrder: IGetSaleOrderResponse;
  setIsAddressModalOpen: Dispatch<SetStateAction<boolean>>;

  form: FormikContextType<any>;
}

export const useContentBodyFunctions = ({
  updateDelivery,
  saleOrder,
  setIsAddressModalOpen,
  form,
}: IuseContentBodyFunctions) => {
  const updateDeliveryCounter = useCallback(
    async (x: any, formik?: FormikHelpers<FormikValues>) => {
      try {
        await updateDelivery({
          pedidoVendaExternalId: saleOrder.externalId,
          tipo: x,
        });
      } catch {
        formik?.setSubmitting(false);
      }
    },
    [updateDelivery, saleOrder.externalId]
  );

  const updateDeliveryFee = useCallback(
    (x: string | number) => {
      if (x === retiradaNoBalcao) {
        form.setFieldValue('taxa', 0);
        updateDeliveryCounter(x);
      } else setIsAddressModalOpen(true);
    },
    [form, updateDeliveryCounter, setIsAddressModalOpen]
  );

  return { updateDeliveryFee };
};
