import { FC } from 'react';
import { InputNumber, Select } from 'antd';
import { Field, useField } from 'formik';
import { IField } from '../interfaces/IField';
import { InputLabel } from '../Label';
import { Icon, Text, Tooltip } from '../..';
import { IconName } from '../../Icon/IconClasses';
import { LocalizaPropriedadePorPath } from '../../../Utils/FormikUtils';
import { TextType } from '../../Text';
import { IInputLabel } from '../Dropdown';
import { Divflex } from '../../../Layouts/DivWhithFlex';
import { Color, Colors } from '../../../Utils/ColorUtils';

import './TextInput.override.scss';
import styles from './TextInput.module.scss';

type IconsOrTitleStrings = {
  id: number;
  icons?: IconName;
  content?: string;
};
type IconOrTitleString = {
  id?: number;
  icon?: IconName;
  titleString?: string;
};

interface IDropdown {
  name: string;
  width?: number;
  defaultValue?: string | number;
  loading?: boolean;
  options: IconsOrTitleStrings[];
}

export interface IInputNumber {
  name: string;
  label?: IInputLabel[] | string;
  typeLabel?: TextType;
  disabled?: boolean;
  placeHolder?: string;
  color?: Color;
  nulable?: boolean;

  min?: number;

  dropdownLeft?: IDropdown;
  dropdownRight?: IDropdown;

  leftIcon?: IconOrTitleString;
  rightIcon?: IconOrTitleString;

  withTooltip?: {
    title?: string;
    icon?: IconName;
  };

  required?: boolean;

  withoutMarginBottom?: boolean;

  success?: boolean;
  error?: boolean;
  brand?: boolean;
  className?: string;

  onBlur?: (value: string) => void;
  onChange?: (value: string) => void;

  withStatusIcon?: boolean;

  formaterNumberZeroMask?: number;

  withoutArrows?: boolean;
  hideDropdownArrows?: boolean;
  dataTestId?: string;
}

export const TextInputNumber: FC<IInputNumber> = ({
  name,
  label,
  nulable,
  dropdownLeft,
  dropdownRight,
  required,
  placeHolder,
  color,
  min,
  error,
  success,
  brand,
  rightIcon,
  leftIcon,
  withTooltip,
  withoutMarginBottom,
  disabled,
  className,
  onBlur,
  onChange,
  withStatusIcon,
  formaterNumberZeroMask,
  withoutArrows,
  hideDropdownArrows = false,
  dataTestId,
}) => {
  const dropdownLeftField = useField(dropdownLeft?.name || 'dropdownLeft');
  const dropdownRightField = useField(dropdownRight?.name || 'dropdownRight');

  return (
    <Field name={name}>
      {(props: IField) => {
        const hasError =
          error ||
          !!(
            props.form &&
            props.form.errors &&
            props.meta.touched &&
            LocalizaPropriedadePorPath(props.form.errors, name)
          );
        const classNames = className;

        const onPointerLeave = (event: any) => {
          if (nulable && (event.target === null || event.target.value === ''))
            props.form.setFieldValue(name, null);
        };

        return (
          <>
            {label && (
              <Divflex>
                <InputLabel
                  label={label}
                  props={props}
                  withStatusIcon={withStatusIcon}
                  required={required}
                />
                {withTooltip?.icon && (
                  <Tooltip
                    title={withTooltip.title}
                    showMe
                    children={
                      <Icon
                        icon={withTooltip.icon}
                        color="text-300"
                        size="SM"
                        style={{ marginLeft: '5px', marginTop: '1px' }}
                      />
                    }
                  />
                )}
              </Divflex>
            )}

            <div
              className={`${
                withoutMarginBottom ? '' : styles['margin-bottom']
              } ${error || hasError ? styles['error'] : null} ${
                success ? styles['success'] : null
              } ${disabled ? styles['disabled'] : null} ${
                brand ? styles['brand'] : null
              } ${styles['Container']}`}
            >
              <div
                className={`${styles['input-container']} ${classNames} ${
                  rightIcon && dropdownRight && styles['right-icon']
                } ${leftIcon && dropdownLeft && styles['left-icon']}`}
                style={{
                  ...(rightIcon && {
                    paddingRight: '33px',
                  }),
                  ...(leftIcon && { paddingLeft: '33px' }),
                }}
              >
                <InputNumber
                  {...props.field}
                  id={name}
                  min={min}
                  type="number"
                  autoComplete="off"
                  data-testid={dataTestId}
                  formatter={(value) => {
                    if (!formaterNumberZeroMask) return value;
                    
                    if(value)
                      return value
                        .toString()
                        .padStart(formaterNumberZeroMask, '0')
                        .slice(0, formaterNumberZeroMask);
                    
                    return undefined;
                  }}
                  className={`${
                    dropdownLeft ? 'input-number-with-dropdown-left' : ''
                  } ${
                    dropdownRight ? 'input-number-with-dropdown-right' : ''
                  } ${leftIcon ? 'input-number-with-left-icon' : ''} ${
                    rightIcon ? 'input-number-with-right-icon' : ''
                  } ${withoutArrows ? 'without-select-arrows' : ''}`}
                  placeholder={placeHolder}
                  onPointerLeave={onPointerLeave}
                  disabled={disabled || props.form.isSubmitting}
                  onBlur={(x) => {
                    onBlur && onBlur(props.field.value);
                    props.field && props.field.onBlur(x);
                  }}
                  onChange={(x) => {
                    onChange && onChange(x);
                    props.field && props.form.setFieldValue(name, x);
                  }}
                  addonBefore={
                    dropdownLeft && (
                      <Select
                        value={dropdownLeftField[1]?.value}
                        defaultValue={dropdownLeft.defaultValue}
                        style={{
                          width: dropdownLeft.width || 60,
                        }}
                        onChange={(selectedValue) => {
                          props.field &&
                            props.form.setFieldValue(
                              dropdownLeft.name,
                              selectedValue
                            );
                        }}
                        disabled={disabled}
                      >
                        {dropdownLeft?.options.map((x) => (
                          <Select.Option
                            key={`${x.id}_${x.content}`}
                            value={x.id}
                          >
                            {x.content ? (
                              <Text
                                type="ui-tiny-content"
                                color="text-300"
                                children={x.content}
                              />
                            ) : (
                              x.icons && (
                                <Icon icon={x.icons} color="text-300" />
                              )
                            )}
                          </Select.Option>
                        ))}
                      </Select>
                    )
                  }
                  addonAfter={
                    dropdownRight && (
                      <Select
                        value={dropdownRightField[1]?.value}
                        defaultValue={dropdownRight.defaultValue}
                        style={{
                          width: dropdownRight.width || 60,
                        }}
                        onChange={(selectedValue) => {
                          props.field &&
                            props.form.setFieldValue(
                              dropdownRight.name,
                              selectedValue
                            );
                        }}
                        disabled={disabled}
                        options={dropdownRight?.options?.map((x) => ({
                          key: `${x.id}_${x.content}`,
                          label: (
                            <>
                              {x.content ? (
                                <Text
                                  type="ui-tiny-content"
                                  color="text-300"
                                  children={x.content}
                                  style={{ minWidth: 50 }}
                                />
                              ) : (
                                <Icon icon={x.icons} color="text-300" />
                              )}
                            </>
                          ),
                          value: x.id,
                        }))}
                      />
                    )
                  }
                  ref={(el) =>
                    color &&
                    el?.style.setProperty('color', Colors[color], 'important')
                  }
                />

                {!hideDropdownArrows && (
                  <SideLeftDropdownIcon
                    dropdownLeft={dropdownLeft}
                    leftIcon={leftIcon}
                  />
                )}
                {!hideDropdownArrows && (
                  <SideRightDropdownIcon
                    dropdownRight={dropdownRight}
                    rightIcon={rightIcon}
                  />
                )}
                {props.form &&
                props.form.errors &&
                LocalizaPropriedadePorPath(props.form.errors, name) ? (
                  <p className={styles['errorText']}>
                    {LocalizaPropriedadePorPath(props.form.errors, name)}
                  </p>
                ) : null}
              </div>
            </div>
          </>
        );
      }}
    </Field>
  );
};

interface ISideLeftDropdownIcon {
  leftIcon?: IconOrTitleString;
  dropdownLeft?: IDropdown;
}

const SideLeftDropdownIcon: FC<ISideLeftDropdownIcon> = ({
  dropdownLeft,
  leftIcon,
}) => {
  return (
    <>
      {leftIcon?.icon ? (
        <Icon
          className={styles['Left-Icon']}
          icon={leftIcon.icon}
          size="S"
          color="text-300"
        />
      ) : (
        <Text
          className={styles['Left-title-string']}
          type="ui-tiny-content"
          color="text-300"
          children={leftIcon?.titleString}
        />
      )}
      {dropdownLeft ? (
        <Icon
          className={styles['Left-Icon-arrow']}
          icon="arrow-down"
          size="S"
          color="text-300"
          style={{
            left: (dropdownLeft.width && dropdownLeft.width - 20) || 40,
          }}
        />
      ) : null}
    </>
  );
};

interface ISideRightDropdownIcon {
  rightIcon?: IconOrTitleString;
  dropdownRight?: IDropdown;
}

const SideRightDropdownIcon: FC<ISideRightDropdownIcon> = ({
  dropdownRight,
  rightIcon,
}) => {
  return (
    <>
      {dropdownRight ? (
        <Icon
          className={styles['Right-Icon-arrow']}
          icon="arrow-down"
          size="S"
          color="text-300"
          style={{
            right: (dropdownRight.width && dropdownRight.width + 20) || 10,
          }}
        />
      ) : null}
      {rightIcon?.icon ? (
        <Icon
          className={styles['Right-Icon']}
          icon={rightIcon.icon}
          size="S"
          color="text-300"
        />
      ) : (
        <Text
          className={styles['Right-title-string']}
          type="ui-tiny-content"
          color="text-300"
          children={rightIcon?.titleString}
        />
      )}
    </>
  );
};
