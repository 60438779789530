import { useCallback } from 'react';
import { FETCH_LIST_SUBGROUP } from '@/ControllerApiHook/UniqIds/Supply/ProductKeys';
import { useQueryClient } from '@tanstack/react-query';
import { App } from 'antd';
import { handleModalError } from '@/Utils/ErrorCodeUtils/ErrorHandlingUtils';
import { IUseFunctions } from './interfaces';

export const useSubGroupFormFunctions = ({
  deleteSubGroup,
  setVisibleModal,
  setVisibleEditModal,
  setSelectedRowsOnTable,
}: IUseFunctions) => {
  const app = App.useApp();
  const queryClient = useQueryClient();

  const onDelete = useCallback(
    async (subGroup: any[]) => {
      try {
        const res = await deleteSubGroup.mutateAsync(
          {
            externalId: subGroup.map((x) => x?.externalId),
          },
          {
            onError: handleModalError(app),
          }
        );
        if (res.success) queryClient.invalidateQueries([FETCH_LIST_SUBGROUP]);
      } catch {}
    },
    [deleteSubGroup]
  );

  const changeVisibleModal = useCallback(
    (x: boolean) => setVisibleModal(x),
    [setVisibleModal]
  );

  const changeVisibleEditModal = useCallback(
    (x: boolean) => setVisibleEditModal(x),
    [setVisibleEditModal]
  );

  const selectRowsOnTable = useCallback(
    (x: any) => setSelectedRowsOnTable(x),
    []
  );

  return {
    onDelete,
    changeVisibleModal,
    changeVisibleEditModal,
    selectRowsOnTable,
  };
};
