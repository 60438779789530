import { FC } from "react";
import { Tooltip } from '@/Components';
import { useTranslation } from 'react-i18next';
import { useFormikContext } from 'formik';
import { IAlternativeUnit2 } from "./interfaces";
import { WeightInput } from "@/Components/Inputs/WeightInput";
import { useAlternativeUnit2Memorizations } from "./Hooks/useAlternativeUnit2Memorizations";

export const AlternativeUnit2: FC<IAlternativeUnit2> = ({
  product,
  disabledForm,
  defineOriginalUnit,
  unidadeAlternativaList
}) => {
  const { t } = useTranslation();
  const form: any = useFormikContext();

  const {
    dropdownLeft,
    dropdownRight
  } = useAlternativeUnit2Memorizations({
    unidadeAlternativaList
  });

  return (
    <Tooltip
      title={`${t('products.create.originalAlternativeUnit2')} ${
        product && product.materiaPrima
          ? defineOriginalUnit()?.originalUnitAlternative2
          : ''
      }`}
      showMe={
        product &&
        !disabledForm &&
        (product.materiaPrima?.quantidadeUnidadeAlternativa2 !==
          form?.values?.informacaoTecnica
            ?.quantidadeUnidadeAlternativa2 ||
          product.materiaPrima?.unidadeAlternativaId2 !==
            form?.values?.informacaoTecnica?.unidadeAlternativaId2 ||
          product.materiaPrima?.unidadeAlternativaConversaoId2 !==
            form?.values?.informacaoTecnica
              ?.unidadeAlternativaConversaoId2)
      }
    >
      <WeightInput
        name="informacaoTecnica.quantidadeUnidadeAlternativa2"
        label={[
          {
            children: t('supply.lot.create.alternativeUnitTwo'),
          },
        ]}
        placeHolder={'999'}
        dropdownLeft={dropdownLeft}
        dropdownRight={dropdownRight}
        disabled={disabledForm}
        withStatusIcon={!!product}
      />
    </Tooltip>
  );
};