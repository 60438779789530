import { useState } from "react";

export const useLotStates = () => {
  const [errors, setErrors] = useState<{ creatingLotError: boolean }>({
    creatingLotError: false,
  });

  const [classTypeState, setClassTypeState] = useState<number>();

  return {
    errors,
    setErrors,
    classTypeState,
    setClassTypeState
  };
};