import {
  DELETE_STANDARD_FORMULA
} from '@/ControllerApiHook/UniqIds/Production/StandardFormulaKeys';
import { useControllerMutateApiHook } from '@/ControllerApiHook/Controller';
import { StandardFormulaAPI } from '@/Data/API/Production/StandardFormula';

export const useDeleteStandardFormulaModalCallAPI = () => {
  const deleteStandardFormula: any = useControllerMutateApiHook({
    uniqId: DELETE_STANDARD_FORMULA,
    entityApi: StandardFormulaAPI.deleteStandardFormula
  });

  return {
    deleteStandardFormula
  };
};