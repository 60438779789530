import { FC } from "react";
import { IDropdown, IIcon } from "../../types";
import { Tooltip, Text, Icon } from "@/Components";
import { Select } from "antd";
import { useField } from "formik";

import styles from '../VisualInput.module.scss';

export interface BeforeAddonProps {
    rightIcon?: IIcon;
    dropdownRight?: IDropdown;
    disabled?: boolean;
}

export const AfterAddon: FC<BeforeAddonProps> = ({ rightIcon, dropdownRight, disabled }) => {
    const dropdownRightField = useField(dropdownRight?.name || 'dropdownRight');

    if (rightIcon?.titleString) {
        return (
            <Tooltip
                title={
                rightIcon.toolTipTitle && rightIcon.toolTipTitle[0]
                }
                showMe={!!rightIcon.toolTipTitle}
                placement="bottomRight"
            >
                <Text
                className={`${styles['Icon-TitleString']} ${styles['addon-center']}`}
                type="ui-tiny-content"
                color="text-300"
                children={rightIcon?.titleString}
                />
            </Tooltip>
        )
    }
    
    
    if (!dropdownRight) {
        return null;
    }
    
    return (
        <Select
            value={dropdownRightField[1]?.value}
            defaultValue={dropdownRight.defaultValue}
            style={{ width: 60 }}
            loading={dropdownRight.loading}
            onChange={(selectedValue) => dropdownRightField[2].setValue(selectedValue)}
            disabled={disabled}
            onSelect={(id) =>
                dropdownRight.onChange && dropdownRight.onChange(id)
            }
            className="number-input-dropdown-right"
        >
            {
                dropdownRight?.options.map((x) => (
                    <Select.Option
                    key={`${x.id}_${x.content}`}
                    value={x.id}
                    >
                    {x.content ? (
                        <Text
                        type="ui-tiny-content"
                        color="text-300"
                        children={x.content}
                        />
                    ) : (
                        x.icons && (
                        <Icon icon={x.icons} color="text-300" />
                        )
                    )}
                    </Select.Option>
                ))
            }
        </Select>
    )
}