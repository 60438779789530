import {
  Card,
  Form,
  Button
} from '@/Components';
import { Row } from 'antd';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { Product } from './Components/Product';
import { IncompatibleProduct } from './Components/IncompatibleProduct';
import { LevelIncompatible } from './Components/LevelIncompatible';
import { Description } from './Components/Description';
import { useCreateIncompatibleProductCallAPI } from './Hooks/useCreateIncompatibleProductCallAPI';
import { useCreateIncompatibleProductFunctions } from './Hooks/useCreateIncompatibleProductFunctions';

export const CreateIncompatibleProduct: React.FC = () => {
  const { t } = useTranslation();
  const { externalId: productExternalId } = useParams<{ externalId: string }>();
  
  const {
    createIncompatibleProduct
  } = useCreateIncompatibleProductCallAPI();

  const {
    handleSubmit,
    onClickCancelButton
  } = useCreateIncompatibleProductFunctions({
    createIncompatibleProduct,
    productExternalId
  });

  return (
    <Form onSubmit={handleSubmit}>
      {(props: any) => (
        <>
          <Card
            title={t('products.incompatible.registerIncompatibleProductTitle')}
            withoutCollapse
          >
            <Row gutter={[16, 0]}>
              <Product productExternalId={productExternalId} />
            </Row>
            <Row gutter={[16, 0]}>
              <IncompatibleProduct productExternalId={productExternalId} />
              <LevelIncompatible />
            </Row>
            <Row gutter={[16, 0]}>
              <Description />
            </Row>
          </Card>
          <div style={{ display: 'flex' }}>
            <Button
              type="secondary"
              style={{ marginRight: 8 }}
              children={t('common.cancel')}
              onClick={onClickCancelButton}
            />
            <Button
              type="primary"
              children={false ? t('common.creating') : t('common.create')}
              htmlType="submit"
              loading={false}
              disabled={
                !props.values.descricao ||
                !props.values.produtoIncompativelExternalId ||
                !props.values.nivelIncompatibilidade
              }
            />
          </div>
        </>
      )}
    </Form>
  );
};
