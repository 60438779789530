import * as yup from 'yup';
import { i18n } from '../../../Components';

export const CompanyAdressCardSchema = yup.object().shape({
  endereco: yup.object().shape({
    cep: yup
      .string()
      .required(
        i18n.t('adminBootis.createCompany.CompanyAdressCard.errors.requiredCEP')
      ),
    numero: yup
      .string()
      .required(
        i18n.t(
          'adminBootis.createCompany.CompanyAdressCard.errors.requiredNumber'
        )
      ),
    bairro: yup
      .string()
      .required(
        i18n.t(
          'adminBootis.createCompany.CompanyAdressCard.errors.requiredNeighborhood'
        )
      ),
    cidade: yup
      .string()
      .required(
        i18n.t(
          'adminBootis.createCompany.CompanyAdressCard.errors.requiredCity'
        )
      ),
    estado: yup
      .string()
      .required(
        i18n.t(
          'adminBootis.createCompany.CompanyAdressCard.errors.requiredState'
        )
      ),
    logradouro: yup
      .string()
      .required(
        i18n.t(
          'adminBootis.createCompany.CompanyAdressCard.errors.requiredStreet'
        )
      ),
  }),
});
