import { Card } from '@components/Card';
import { useTranslation } from 'react-i18next';
import FirstRow from './rows/FirstRow';
import SecondRow from './rows/SecondRow';
import ThirdRow from './rows/ThirdRow';
import FourthRow from './rows/FourthRow';
import FifthRow from './rows/FifthRow';
import FormBreakLine from '@components/FormBreakLine';
import { SixthRow } from './rows/SixthRow';

interface IInfoSectionProps {
  selectSupplyPlace: (supplyPlaceId: string) => void;
}

export const InfoSection: React.FC<IInfoSectionProps> = ({
  selectSupplyPlace,
}) => {
  const { t } = useTranslation();

  return (
    <Card withoutCollapse title={t('purchasing.invoice.create.info')}>
      <FirstRow />
      <SecondRow />
      <ThirdRow />
      <FourthRow />
      <FifthRow />
      <FormBreakLine />
      <SixthRow selectSupplyPlace={selectSupplyPlace} />
    </Card>
  );
};
