import { FormikHelpers, FormikValues } from 'formik';
import { IUseFunctions } from './interfaces';
import { IEditPosology } from '@/Data/Interfaces/request/Posology/IPosologyRequest';
import { useCallback } from 'react';
import { handleModalError } from '@/Utils/ErrorCodeUtils/ErrorHandlingUtils';
import { App } from 'antd';

export const useEditFunctions = ({
  editPosology,
  posology,
  deletePosology,
}: IUseFunctions) => {
  const app = App.useApp();
  const onSubmit = async (
    values: IEditPosology,
    helpers: FormikHelpers<FormikValues>
  ) => {
    const body: IEditPosology = { ...values, helpers };
    if (posology) {
      return await editPosology({
        externalId: posology.externalId,
        descricao: body.descricao,
        formaFarmaceuticaExternalId: body.formaFarmaceuticaExternalId,
        quantidadeDosePorPeriodo: body.quantidadeDosePorPeriodo,
        unidadeMedidaId: body.unidadeMedidaId,
        periodo: body.periodo,
        helpers,
      });
    }
  };

  const onDelete = useCallback(async () => {
    try {
      if (!posology) return;
      return await deletePosology(
        { externalId: [posology.externalId] },
        { onError: handleModalError(app) }
      );
    } catch {}
  }, [deletePosology, posology]);

  return {
    onSubmit,
    onDelete,
  };
};
