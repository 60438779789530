import { Card, InputWithSearch, TextInput } from '@/Components';
import { TextInputEmailAlreadyExists } from '@/Components/Inputs/TextInputEmailAlreadyExists';
import { Col, Row } from 'antd';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useAccessCardCallAPI } from './Hooks/useAccessCardCallAPI';
import { useAccessCardMemorizations } from './Hooks/useAccessCardMemorizations';
import { useAccessCardFunctions } from './Hooks/useAccessCardFunctions';

export const AccessCard: FC = () => {
  const { t } = useTranslation();
  const { userGroupListResponse } = useAccessCardCallAPI();
  const { userDataList } = useAccessCardMemorizations({
    userGroupListResponse,
  });
  const { onSearchGroups } = useAccessCardFunctions({ userGroupListResponse });

  return (
    <Card title={t('admin.createUserPage.Access')} withoutCollapse>
      <Row gutter={[16, 0]}>
        <Col span={12}>
          <TextInputEmailAlreadyExists
            name="email"
            dataTestId='email'
            label={t('admin.createUserPage.AccessDataCard.email')}
            placeholder={t(
              'admin.createUserPage.AccessDataCard.emailPlaceholder'
            )}
            required
          />
        </Col>
        <Col span={12}>
          <TextInput
            name="emailAlternativo"
            dataTestId='emailAlternativo'
            label={t('admin.createUserPage.AccessDataCard.alternativeEmail')}
            placeHolder={t(
              'admin.createUserPage.AccessDataCard.alternativeEmailPlaceholder'
            )}
          />
        </Col>
      </Row>
      <Row gutter={[16, 0]}>
        <Col span={24}>
          <InputWithSearch
            name="grupos"
            dataTestId='grupos'
            withoutMarginBottom
            label={t('admin.createUserPage.AccessDataCard.group')}
            placeHolder={t(
              'admin.createUserPage.AccessDataCard.groupPlaceholder'
            )}
            typeTag="secondary"
            items={userDataList}
            required
            multipleSelection
            onScrollEnd={userGroupListResponse.fetchNewPage}
            isLoading={userGroupListResponse.isLoading}
            onSearch={onSearchGroups}
         
          />
        </Col>
      </Row>
    </Card>
  );
};
