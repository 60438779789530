import { combineReducers } from 'redux';

import notification from './Notification/Notification.reducer';
import group from './Group/Group.reducer';
import purchasing from './Purchasing/Purchasing.reducer';

const reducers = combineReducers({
  notification,
  group,
  purchasing,
});

export default reducers;
