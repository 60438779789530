import { FormikContextType } from 'formik';
import { IEditLotRequest } from '@/Data/Interfaces/request/Lot/IPatchLotStatusRequest';

export const setInitialValuesFormProviderDiluitionFactor = (
  form: FormikContextType<IEditLotRequest>
) => {
  const currentFormProviderDiluition =
    form?.values?.loteInformacaoTecnica?.diluicaoFornecedor;
  const currentFormProviderDiluitionFactor = 100 / currentFormProviderDiluition;
  const hasChanges =
    currentFormProviderDiluitionFactor !== currentFormProviderDiluition;
  if (hasChanges) {
    form.setFieldValue(
      'loteInformacaoTecnica.fatorDiluicaoFornecedor',
      currentFormProviderDiluitionFactor ||
        form?.values?.loteInformacaoTecnica?.fatorDiluicaoFornecedor
    );
  }
};

export const setInitialValuesFormProviderDiluition = (
  form: FormikContextType<IEditLotRequest>
) => {
  const currentFormProviderDiluitionFactor =
    form?.values?.loteInformacaoTecnica?.fatorDiluicaoFornecedor;
  const currentFormProviderDiluition = 100 / currentFormProviderDiluitionFactor;
  const hasChanges =
    currentFormProviderDiluitionFactor !== currentFormProviderDiluition;
  if (hasChanges) {
    form.setFieldValue(
      'loteInformacaoTecnica.diluicaoFornecedor',
      currentFormProviderDiluition ||
        form?.values?.loteInformacaoTecnica?.diluicaoFornecedor
    );
  }
};

export const setInitialValuesFormWaterConcentrationFactor = (
  form: FormikContextType<IEditLotRequest>
) => {
  const currentFormWaterConcentration =
    form?.values?.loteInformacaoTecnica?.concentracaoAgua;
  const currentFormWaterConcentrationFactor =
    100 / (100 - currentFormWaterConcentration);
  const hasChanges =
    currentFormWaterConcentrationFactor !== currentFormWaterConcentration;
  if (hasChanges) {
    form.setFieldValue(
      'loteInformacaoTecnica.fatorConcentracaoAgua',
      currentFormWaterConcentrationFactor ||
        form?.values?.loteInformacaoTecnica?.fatorConcentracaoAgua
    );
  }
};

export const setInitialValuesFormWaterConcentration = (
  form: FormikContextType<IEditLotRequest>
) => {
  const currentFormWaterConcentrationFactor =
    form?.values?.loteInformacaoTecnica?.fatorConcentracaoAgua;
  const currentFormWaterConcentration =
    //prettier-ignore
    100 - (100 / currentFormWaterConcentrationFactor);
  const hasChanges =
    currentFormWaterConcentrationFactor !== currentFormWaterConcentration;
  if (hasChanges) {
    form.setFieldValue(
      'loteInformacaoTecnica.concentracaoAgua',
      currentFormWaterConcentration ||
        form?.values?.loteInformacaoTecnica?.concentracaoAgua
    );
  }
};

export const setInitialValuesFormInternalDiluitionFactor = (
  form: FormikContextType<IEditLotRequest>
) => {
  const currentFormInternalDiluition =
    form?.values?.loteInformacaoTecnica?.diluicaoInterna;
  const currentFormInternalDiluitionFactor = 100 / currentFormInternalDiluition;
  const hasChanges =
    currentFormInternalDiluitionFactor !== currentFormInternalDiluition;
  if (hasChanges) {
    form.setFieldValue(
      'loteInformacaoTecnica.fatorDiluicaoInterna',
      currentFormInternalDiluitionFactor ||
        form?.values?.loteInformacaoTecnica?.fatorDiluicaoInterna
    );
  }
};

export const setInitialValuesFormInternalDiluition = (
  form: FormikContextType<IEditLotRequest>
) => {
  const currentFormInternalDiluitionFactor =
    form?.values?.loteInformacaoTecnica?.fatorDiluicaoInterna;
  const currentFormInternalDiluition = 100 / currentFormInternalDiluitionFactor;
  const hasChanges =
    currentFormInternalDiluitionFactor !== currentFormInternalDiluition;
  if (hasChanges) {
    form.setFieldValue(
      'loteInformacaoTecnica.diluicaoInterna',
      currentFormInternalDiluition ||
        form?.values?.loteInformacaoTecnica?.diluicaoInterna
    );
  }
};
