import * as yup from 'yup';
import i18n from '../../../Components/i18n';

export const LoginSchema = yup.object().shape({
  email: yup
    .string()
    .email(i18n.t('login.errors.invalidMail'))
    .required(i18n.t('login.errors.requiredMail')),
  password: yup.string().required(i18n.t('login.errors.requiredPass')),
});
