import { useField } from 'formik';
import { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { v4 as uuidV4 } from 'uuid';
import { Card } from '@/Components';
import { ProductLine } from './Components/ProductLine';
import { useDispatch } from 'react-redux';
import { PurchasingActions } from '@/Store/Purchasing/Purchasing.actions';
import { useProductCardStates } from './Hooks/useProductCardStates';
import { useProductCardCallAPI } from './Hooks/useProductCardCallAPI';
import { useParams } from 'react-router';
import { useProductCardFunctions } from './Hooks/useProductCardFunctions';

export const ProductCard: FC = () => {
  const { externalId } = useParams<{ externalId: string }>();
  const totalItems = useField('pedidoCompraItens');
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const {
    productRowAmount,
    setProductRowAmount
  } = useProductCardStates();

  const {
    orderPurchase
  } = useProductCardCallAPI({
    externalId
  });

  const {
    onDelete
  } = useProductCardFunctions({
    productRowAmount,
    setProductRowAmount
  })

  useEffect(() => {
    if (orderPurchase && orderPurchase.pedidoCompraItens) {
      const additionalRow = uuidV4();
      const existingRows = orderPurchase.pedidoCompraItens.map(
        (item: any) => item.id
      );

      const updatedRows = [additionalRow, ...existingRows];

      setProductRowAmount(updatedRows);
    }
  }, [orderPurchase]);

  useEffect(() => {
    dispatch(PurchasingActions.setTotalValuesCard(totalItems[1].value));
  }, [totalItems]);

  return (
    <>
      <Card
        title={t('purchasing.purchaseOrder.create.orderItems')}
        withoutCollapse={true}
      >
        {productRowAmount.map((uuid, index) => (
          <ProductLine
            key={uuid}
            index={index}
            onDelete={onDelete}
            totalRows={productRowAmount.length}
            setProductRowAmount={setProductRowAmount}
          />
        ))}
      </Card>
    </>
  );
};
