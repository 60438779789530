import { useControllerQueryListApiHook } from '@/ControllerApiHook/Controller';
import { LotsAPI } from '@/Data/API/Supply/LotApi';
import { FETCH_LIST_LOTS_DROPDOWN } from '@/ControllerApiHook/UniqIds/Supply/LotKeys';
import { IUseTechnicalInfoDilutionsCallAPI } from './interfaces';

export const useTechnicalInfoDilutionsRowsCallAPI = ({
  form,
}: IUseTechnicalInfoDilutionsCallAPI) => {
  const {
    data: lotList,
    isLoading: isLotLoading,
    fetchNewPage: fetchNewLotPage,
  } = useControllerQueryListApiHook({
    uniqId: FETCH_LIST_LOTS_DROPDOWN,
    entityApi: LotsAPI.getLotListDropDown,
    pagination: {
      filter: [
        {
          filterName: 'produtoExternalId',
          value: form?.values?.produtoExternalId,
        },
      ],
    },
  });

  return {
    lotList,
    isLotLoading,
    fetchNewLotPage,
  };
};
