import { App } from 'antd';
import { IUseFunctions } from './interfaces';
import { useCallback } from 'react';
import { handleModalError } from '@/Utils/ErrorCodeUtils/ErrorHandlingUtils';
import { cannotDeleteLaboratoryWithMovementsInTheSystemProps } from '@/Utils/ErrorCodeUtils/ErrorModalUtils/ErrorModalLaboratory';
import { useTranslation } from 'react-i18next';
import { ILaboratoryStatusRequest } from '@/Data/Interfaces/request/Laboratory/ILaboratoryRequest';
import { ILaboratoryData } from '@/Data/Interfaces/response/Laboratory/ILaboratoryResponse';

export const useListFunctions = ({
  deleteLaboratoryForm,
  setSelectedRowsOnTable,
  editLaboratoryFormStatus,
}: IUseFunctions) => {
  const app = App.useApp();
  const { t } = useTranslation();

  const onDelete = useCallback(
    async (laboratoryForm: ILaboratoryData[]) => {
      await deleteLaboratoryForm.mutateAsync(
        {
          externalId: laboratoryForm.map((x) => x?.externalId),
        },
        {
          onError: handleModalError(
            app,
            cannotDeleteLaboratoryWithMovementsInTheSystemProps(t)
          ),
        }
      );
    },
    [deleteLaboratoryForm, app, t]
  );

  const onSelectedRowsOnTable = useCallback(
    (x: string[]) => setSelectedRowsOnTable(x),
    [setSelectedRowsOnTable]
  );

  const changeLaboratoryFormStatus = useCallback(
    async (values: ILaboratoryStatusRequest) => {
      var body: ILaboratoryStatusRequest = { ...values };
      await editLaboratoryFormStatus.mutateAsync({
        ...body,
      });
    },
    [editLaboratoryFormStatus]
  );

  const onEdit = useCallback(
    (laboratoryForm: ILaboratoryData[], values: any) =>
      changeLaboratoryFormStatus({
        externalId: laboratoryForm.map((x: ILaboratoryData) => x.externalId),
        ativo: values.status === 'success',
      }),
    [changeLaboratoryFormStatus]
  );

  return {
    onDelete,
    onSelectedRowsOnTable,
    onEdit,
  };
};
