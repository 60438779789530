import { InputWithSearch, NumberInput, TextInput } from '@/Components';
import { TextInputWithMask } from '@/Components/Inputs/TextInputWithMask';
import { useListNatureOfOperation } from '@/Hooks/HooksServices/Purchase/NatureOfOperation/useListNatureOfOperation';
import { Col, Row } from 'antd';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

export const RowOne: React.FC = () => {
  const [seriesMaskVisible, setSeriesMaskVisible] = useState(false);
  const { t } = useTranslation();
  const natureOfOperation = useListNatureOfOperation({
    fieldName: 'naturezaOperacaoId',
  });

  return (
    <Row gutter={[16, 0]}>
      <Col span={6}>
        <TextInput
          name="notaFiscal"
          label={t('purchasing.invoice.create.invoiceModel')}
          disabled
          value={t('purchasing.invoice.create.placeHolder.invoiceModelValue')}
        />
      </Col>
      <Col span={4}>
        <NumberInput
          name="numero"
          required
          label={t('purchasing.invoice.create.invoiceNumber')}
          placeHolder={`${t('common.exampleAbbreviation')}: 0025`}
          withThousandSeparator
          withDecimalSeparator
          maxLength={11}
          acceptOnlyNumbers
        />
      </Col>
      <Col span={2}>
        <TextInputWithMask
          name="serie"
          mask={seriesMaskVisible ? 'invoiceSeries' : undefined}
          required
          onChange={(value) => {
            if (value.length === 10) {
              setSeriesMaskVisible(true);
            } else {
              setSeriesMaskVisible(false);
            }
          }}
          label={t('purchasing.invoice.create.invoiceSeries')}
          placeHolder={`${t('common.exampleAbbreviation')}: 5`}
        />
      </Col>
      <Col span={12}>
        <InputWithSearch
          label={t('purchasing.invoice.create.natureOfOperation')}
          placeHolder={t(
            'purchasing.invoice.create.placeHolder.natureOfOperation'
          )}
          required
          {...natureOfOperation}
        />
      </Col>
    </Row>
  );
};
