import { useState } from 'react';

export const useEditStates = () => {
  const [editCard, setEditCard] = useState(false);

  return {
    editCard,
    setEditCard,
  };
};
