import { getCurrencySymbol } from '@/Utils/Currency';
import StorageUtils from '@/Utils/StorageUtils';
import { TFunction } from 'react-i18next';

const preference = StorageUtils.getPreference();
export const DEFAULT_DECIMAL_SCALE = 2;
export const FLAT_DISCOUNT_ID = 2;
export const PERCENTAGE_DISCOUNT_ID = 1;

const getDiscountTypes = (t: TFunction<'translation', undefined>) => [
  {
    id: FLAT_DISCOUNT_ID,
    content: getCurrencySymbol(),
  },
  {
    id: PERCENTAGE_DISCOUNT_ID,
    content: t(
      'purchasing.purchaseOrder.create.discountType.discountPercentage'
    ),
  },
];

export const dropdownLeftConfig = (
  dropdownLeft: {
    name: string;
    onChange?: (value: string) => void;
    dataTestId?: string;
  },
  t: TFunction<'translation', undefined>
) => ({
  name: dropdownLeft.name,
  dataTestId: dropdownLeft.dataTestId,
  defaultValue: FLAT_DISCOUNT_ID,
  optionLabelProp: 'content',
  options:
    getDiscountTypes(t).map((x) => ({
      id: x.id,
      content: x.content,
      customDescription: `${
        x.id === PERCENTAGE_DISCOUNT_ID
          ? t('currency.porcentageValue')
          : t('currency.currencyValue')
      } (${x.content})`,
    })) || [],
  onChange: (x: any) =>
    dropdownLeft.onChange && dropdownLeft.onChange(x.toString()),
});

export const thousandSeparator: string =
  preference?.idioma === 'pt-BR' ? '.' : ',';
export const decimalSeparator: string =
  preference?.idioma === 'pt-BR' ? ',' : '.';
