import { FC } from "react";
import { Col } from 'antd';
import { useTranslation } from 'react-i18next';
import { IAssociatedProduct } from "./interfaces";
import { InputWithSearch, Tooltip } from "@/Components";
import { useAssociatedProductCallAPI } from "./Hooks/useAssociatedProductCallAPI";
import { useAssociatedProductMemorizations } from "./Hooks/useAssociatedProductMemorizations";
import { useAssociatedProductFunctions } from "./Hooks/useAssociatedProductFunctions";

export const AssociatedProduct: FC<IAssociatedProduct> = ({
  productExternalId,
  associatedProductData,
  props,
  setAssociatedProductExternalId,
  disabledForm
}) => {
  const { t } = useTranslation();

  const {
    productList,
    fetchNewProductPage,
    refetchSearchProductPage
  } = useAssociatedProductCallAPI({
    productExternalId
  });

  const {
    items
  } = useAssociatedProductMemorizations({
    productList,
    productExternalId
  });

  const {
    onSearch,
    onChange
  } = useAssociatedProductFunctions({
    refetchSearchProductPage,
    setAssociatedProductExternalId
  });

  return (
    <Col span={7}>
      <Tooltip
        title={`${t(
          'products.associated.originalAssociatedProduct'
        )}${associatedProductData?.produtoAssociadoDescricao}`}
        showMe={
          associatedProductData &&
          associatedProductData.produtoAssociadoExternalId !==
            props?.values?.produtoAssociadoExternalId
        }
        targetOffset={[80, -10]}
      >
        <InputWithSearch
          name="produtoAssociadoExternalId"
          required={!disabledForm}
          withStatusIcon={!disabledForm}
          label={t('products.associated.associatedProduct')}
          placeHolder={t(
            'products.associated.searchForAssociatedProductPlaceholder'
          )}
          items={items}
          onScrollEnd={fetchNewProductPage}
          onSearch={onSearch}
          onChange={onChange}
          disabled={disabledForm}
        />
      </Tooltip>
    </Col>
  );
};