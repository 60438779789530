import { ICountryList } from "@/Data/Interfaces/response/Country/ICountryResponse";
import { IGetLotResponse } from "@/Data/Interfaces/response/Lot/IListLot";
import { FC } from "react";
import { CountryOrigin } from "./Components/CountryOrigin";
import { Density } from "./Components/Density";
import { Row } from "antd";

interface ICountryDensityInfo {
  lots: IGetLotResponse | undefined;
  disabled: boolean;
  paisOrigemList: ICountryList | undefined;
};

export const CountryDensityInfo: FC<ICountryDensityInfo> = ({
  lots,
  disabled,
  paisOrigemList
}) => {
  return (
    <Row gutter={[16, 0]}>
      <CountryOrigin
        lots={lots}
        disabled={disabled}
        paisOrigemList={paisOrigemList}
      />
      <Density
        lots={lots}
        disabled={disabled}
      />
    </Row>
  );
};