import React, { CSSProperties, FC, ReactNode, useState } from 'react';
import { Input } from 'antd';
import { Field } from 'formik';
import { IField } from '../interfaces/IField';
import { InputLabel } from '../Label';
import { Icon, Tooltip } from '../..';
import { IconName } from '../../Icon/IconClasses';
import { LocalizaPropriedadePorPath } from '../../../Utils/FormikUtils';
import { TextType } from '../../Text';
import { IInputLabel } from '../Dropdown';
import Spinner from '../../Spinner';
import _ from 'lodash';
import { Divflex } from '../../../Layouts/DivWhithFlex';

import styles from './TextInput.module.scss';
import './TextInput.override.scss';

export interface ITextInput {
  type?: 'text' | 'password' | 'number';
  name: string;
  label?: IInputLabel[] | string;
  maxLength?: number;
  typeLabel?: TextType;
  disabled?: boolean;
  placeHolder?: string;
  nulable?: boolean;
  suffix?: string;
  style?: CSSProperties;
  dataTestId?: string;

  rightIcon?: {
    icon?: IconName;
    copyName?: string;
    toolTipTitle?: ReactNode[];
    copyValueForInput?: string;
  };

  rightIconTooltipOnly?: {
    icon?: IconName;
    toolTipTitle?: ReactNode[];
  };

  leftIcon?: IconName;

  withTooltip?: {
    title: string;
    icon: IconName;
  };

  required?: boolean;

  withoutMarginBottom?: boolean;

  success?: boolean;
  error?: boolean;
  className?: string;
  supportText?: string;

  onBlur?: (value: string) => void;
  onChange?: (value: string) => void;

  isLoading?: boolean | undefined;
  value?: string;

  withStatusIcon?: boolean;
  withBoldContent?: boolean;
}

export const TextInput: FC<ITextInput> = ({
  name,
  type,
  label,
  maxLength,
  nulable,
  required,
  placeHolder,
  error,
  success,
  rightIcon,
  rightIconTooltipOnly,
  leftIcon,
  withTooltip,
  withoutMarginBottom,
  disabled,
  className,
  supportText,
  onBlur,
  onChange,
  isLoading,
  value,
  withStatusIcon,
  suffix,
  style,
  withBoldContent,
  dataTestId,
}) => {
  const [copied, setCopied] = useState(false);

  let debounce_setCopied = _.debounce(() => {
    setCopied(false);
  }, 500);

  return (
    <div
      className={`${withoutMarginBottom ? '' : styles['margin-bottom']} ${
        styles['Container']
      }`}
    >
      <Field name={name}>
        {(props: IField) => {
          const hasError =
            error ||
            !!(
              props.form &&
              props.form.errors &&
              LocalizaPropriedadePorPath(props.form.errors, name)
            );
          const classNames = className;

          const onPointerLeave = (event: any) => {
            if (nulable && (event.target === null || event.target.value === ''))
              props.form.setFieldValue(name, null);
          };

          return (
            <>
              {label && (
                <Divflex>
                  <InputLabel
                    label={label}
                    props={props}
                    withStatusIcon={withStatusIcon}
                    required={required}
                  />
                  {withTooltip && (
                    <Tooltip
                      title={withTooltip.title}
                      showMe
                      children={
                        <Icon
                          className={styles['icon-with-tooltip']}
                          icon={withTooltip.icon}
                          color="text-300"
                          size="S"
                        />
                      }
                    />
                  )}
                </Divflex>
              )}
              <div
                className={`${styles['input-container']} ${classNames}  ${
                  leftIcon ? styles['with-left-icon'] : null
                }
            ${
              rightIcon || success || hasError
                ? styles['with-right-icon']
                : null
            } ${hasError ? styles['error'] : null}
            ${success ? styles['success'] : null}
            ${disabled ? styles['disabled'] : null}`}
              >
                {leftIcon ? (
                  <Icon
                    className={styles['Left-Icon']}
                    icon={leftIcon}
                    size="M"
                    color="text-50"
                  />
                ) : null}

                {supportText && (
                  <div className={styles['supportText']}>{supportText}</div>
                )}

                <Input
                  type={type}
                  id={name}
                  {...props.field}
                  className={`${
                    withBoldContent ? styles['bold-content-input'] : null
                  } ${styles['TextInput']}`}
                  maxLength={maxLength}
                  data-testid={dataTestId}
                  placeholder={placeHolder}
                  onPointerLeave={onPointerLeave}
                  disabled={disabled || props.form.isSubmitting}
                  value={value || props.field.value}
                  autoComplete="off"
                  onBlur={(x) => {
                    onBlur && onBlur(props.field.value);
                    props.field && props.field.onBlur(x);
                  }}
                  style={{ ...style }}
                  suffix={suffix}
                  onChange={(x) => {
                    onChange && onChange(x.target.value);
                    props.field && props.field.onChange(x);
                  }}
                />
                {hasError ? (
                  <Icon
                    className={styles['Icon']}
                    size="M"
                    color="status-danger-base"
                  />
                ) : null}

                {success ? (
                  <Icon
                    className={styles['Icon']}
                    icon="check"
                    size="M"
                    color="status-success-base"
                  />
                ) : null}

                {rightIcon?.icon ? (
                  <Tooltip
                    title={
                      rightIcon.toolTipTitle &&
                      (!copied
                        ? rightIcon.toolTipTitle[0]
                        : rightIcon.toolTipTitle[1])
                    }
                    showMe={!!rightIcon.toolTipTitle}
                    placement="bottomRight"
                    copied={copied}
                  >
                    <Icon
                      className={styles['Icon']}
                      icon={rightIcon.icon}
                      size="SM"
                      color="text-400"
                      onClick={() => {
                        var copyText;

                        if (rightIcon.copyName)
                          copyText = document.getElementById(
                            rightIcon.copyName
                          ) as HTMLInputElement;

                        if (copyText) {
                          copyText.select();
                          copyText.setSelectionRange(0, 99999);
                          navigator.clipboard.writeText(copyText.value);
                        }
                        if (rightIcon.copyValueForInput) {
                          props.form.setFieldValue(
                            rightIcon.copyValueForInput,
                            copyText?.value
                          );
                        }
                        setCopied(true);
                      }}
                      onMouseLeave={debounce_setCopied}
                    />
                  </Tooltip>
                ) : null}

                {rightIconTooltipOnly?.icon ? (
                  <Tooltip
                    title={rightIconTooltipOnly.toolTipTitle}
                    showMe={!!rightIconTooltipOnly.toolTipTitle}
                    placement="bottomRight"
                  >
                    <Icon
                      className={styles['Icon']}
                      icon={rightIconTooltipOnly.icon}
                      size="SM"
                      color="text-400"
                    />
                  </Tooltip>
                ) : null}

                {isLoading && (
                  <div className={styles['spinner']}>
                    <Spinner />
                  </div>
                )}
                {props.form &&
                props.form.errors &&
                LocalizaPropriedadePorPath(props.form.errors, name) ? (
                  <p className={styles['errorText']}>
                    {LocalizaPropriedadePorPath(props.form.errors, name)}
                  </p>
                ) : null}
              </div>
            </>
          );
        }}
      </Field>
    </div>
  );
};
