export const FETCH_GET_SUPPLIER = '[SUPPLIER] FETCH_GET_SUPPLIER';
export const GET_DOCUMENT_PROVIDER = '[PROVIDER] GET_DOCUMENT_PROVIDER';

export const FETCH_LIST_SUPPLIER = '[SUPPLIER] FETCH_LIST_SUPPLIER';
export const FETCH_LIST_DROPDOWN_SUPPLIER =
  '[SUPPLIER] FETCH_LIST_DROPDOWN_SUPPLIER';
  
export const ADD_CONTACT_SUPPLIER = '[SUPPLIER] ADD_CONTACT_SUPPLIER';
export const ADD_ADRESS_SUPPLIER = '[SUPPLIER] ADD_ADRESS_SUPPLIER';
export const ADD_DOCUMENT_SUPPLIER = '[SUPPLIER] ADD_DOCUMENT_SUPPLIER';
export const EDIT_STATUS_SUPPLIER = '[SUPPLIER] EDIT_STATUS_SUPPLIER';
export const EDIT_INFO_SUPPLIER = '[SUPPLIER] EDIT_INFO_SUPPLIER';
export const EDIT_ADRESS_SUPPLIER = '[SUPPLIER] EDIT_ADRESS_SUPPLIER';
export const EDIT_DOCUMENT_SUPPLIER = '[SUPPLIER] EDIT_DOCUMENT_SUPPLIER';
export const EDIT_CONTACT_SUPPLIER = '[SUPPLIER] EDIT_CONTACT_SUPPLIER';
export const DELETE_SUPPLIER = '[SUPPLIER] DELETE_SUPPLIER';
export const DELETE_ADRESS_SUPPLIER = '[SUPPLIER] DELETE_ADRESS_SUPPLIER';
export const DELETE_CONTACT_SUPPLIER = '[SUPPLIER] DELETE_CONTACT_SUPPLIER';
export const DELETE_DOCUMENT_SUPPLIER = '[SUPPLIER] DELETE_DOCUMENT_SUPPLIER';

export const CHANGE_FAVORITE_CONTACT_SUPPLIER =
  '[SUPPLIER] CHANGE_FAVORITE_CONTACT_SUPPLIER';
export const CHANGE_FAVORITE_ADRESS_SUPPLIER =
  '[SUPPLIER] CHANGE_FAVORITE_ADRESS_SUPPLIER';
export const CHANGE_FAVORITE_DOCUMENT_SUPPLIER =
  '[SUPPLIER] CHANGE_FAVORITE_DOCUMENT_SUPPLIER';
