import * as yup from 'yup';
import { i18n } from '../../../../../Components';

export const CreateSchema = yup.object().shape({
  descricao: yup
    .string()
    .required(i18n.t('production.posology.create.errors.requiredName')),
  formaFarmaceuticaExternalId: yup
    .string()
    .required(
      i18n.t('production.posology.create.errors.requiredPharmaceuticalForm')
    ),
  quantidadeDosePorPeriodo: yup
    .string()
    .nullable()
    .required(
      i18n.t('production.posology.create.errors.requiredDosePeriodForm')
    ),
  periodo: yup
    .string()
    .required(i18n.t('production.posology.create.errors.requiredPeriod')),
});
