import { useMemo } from 'react';
import { DiscountTypes } from '@/Utils/DiscountTypeDefault';
import { FormikContextType, useFormikContext } from 'formik';
import { 
  ISaveDraftOrderPurchaseRequest 
} from '@/Data/Interfaces/request/OrderPurchase/IOrderPurchaseRequest';
import { isNumeric } from '@/Utils/IsNumericUtils';

export const useTotalPurchaseFunctions = () => {
  const form: FormikContextType<ISaveDraftOrderPurchaseRequest> =
  useFormikContext();

  const productsTotal = useMemo(() => {
    let totalValue = 0;
    let totalDiscount = 0;
    form.values.pedidoCompraItens?.forEach((x) => {
      //sum the total discount
      if (x.valorDescontoUnitario && x.quantidade)
        x.tipoDesconto === DiscountTypes.currency
          ? (totalDiscount += x.valorDescontoUnitario * x.quantidade)
          : (totalDiscount +=
              (Math.trunc(x.valorDescontoUnitario * x.precoUnitario) / 100) *
              x.quantidade);

      //sum the total value
      x.precoUnitario &&
        x.quantidade &&
        (totalValue += x.precoUnitario * x.quantidade);
    });
    return { totalValue, totalDiscount };
  }, [form.values.pedidoCompraItens]);

  const productTotalWithDiscount = useMemo(
    () =>
      productsTotal.totalValue -
      (isNumeric(productsTotal.totalDiscount)
        ? productsTotal.totalDiscount
        : 0),
    [productsTotal.totalDiscount, productsTotal.totalValue]
  );

  const finalTotal = useMemo(() => {
    const totalProducts = productTotalWithDiscount ?? 0;
    const frete = form.values.frete ?? 0;
    const aditionalValue = form.values.valorAdicional ?? 0;
    let discountValue = form.values.valorDesconto ?? 0;
    const discountType = form.values.tipoDesconto;
    
    if (discountType === DiscountTypes.porcentage && discountValue > 0)
      discountValue = Math.trunc(totalProducts * discountValue) / 100;

    return totalProducts + frete + aditionalValue - discountValue;
  }, [
    form.values.frete,
    form.values.valorAdicional,
    form.values.valorDesconto,
    form.values.tipoDesconto,
    productTotalWithDiscount,
  ]);

  return {
    productsTotal,
    productTotalWithDiscount,
    finalTotal
  };
};