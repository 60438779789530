import { FC } from "react";
import { Col } from 'antd';
import { useTranslation } from "react-i18next";
import { IType } from "./interfaces";
import { Dropdown } from "@/Components";
import { formulaProductTypes } from "@/Pages/Sectors/Production/StandardFormula/Create/Components/utils";

export const Type: FC<IType> = ({
  index,
  disabled,
  addRow
}) => {
  const { t } = useTranslation();

  return (
    <Col span={3}>
      <Dropdown
        name={`produtos.${index}.tipoItem`}
        items={formulaProductTypes?.map((values) => ({
          id: values.id,
          label: values.name,
        }))}
        label={
          index === 0
            ? t('production.standardFormula.details.productLine.type')
            : ''
        }
        placeHolder={t(
          'production.standardFormula.details.productLine.typePlaceholder'
        )}
        disabled={disabled}
        required={!disabled}
        onChange={addRow}
      />
    </Col>
  );
};