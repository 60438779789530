import {
  useControllerMutateApiHook,
  useControllerQueryApiHook,
  useControllerQueryListApiHook,
} from '@/ControllerApiHook/Controller';
import {
  FETCH_GET_PHARMACEUTICAL_FORM,
  LIST_FOR_PHARMACEUTICAL_FORM_DROPDOWN_TYPE,
} from '@/ControllerApiHook/UniqIds/Production/PharmaceuticalFormKeys';
import { PharmaceuticalFormAPI } from '@/Data/API/Production/PharmaceuticalForm';
import { IUseCallAPI } from './interfaces';
import {
  FETCH_GET_POSOLOGY,
  THERE_IS_POSOLOGY_TYPE,
} from '@/ControllerApiHook/UniqIds/Production/PosologyKeys';
import { PosologyAPI } from '@/Data/API/Production/Posology';
import { IGetPosology } from '@/Data/Interfaces/response/Posology/IPosologyResponse';
import { IGetPharmaceuticalForm } from '@/Data/Interfaces/response/PharmaceuticalForm/IPharmaceuticalFormResponse';

export const useEditCallAPI = ({
  externalIdOriginPharmaceuticalForm,
  form,
  t,
  externalId,
  pharmaceuticalFormExternalIdChanged,
}: IUseCallAPI) => {
  const {
    data: listPharmaceuticalForm,
    isLoading: isPharmaceuticalFormLoading,
    fetchNewPage: fetchNewFarmaceuticalFormPage,
    refetch: refetchSearchPharmaceuticalFormPage,
  } = useControllerQueryListApiHook({
    uniqId: LIST_FOR_PHARMACEUTICAL_FORM_DROPDOWN_TYPE,
    entityApi: PharmaceuticalFormAPI.listPharmaceuticalFormForDropDown,
    pagination: {
      sorter: { column: 'descricao', direction: 'ASC' },
    },
    autoIncrement: true,
  });

  const { data: posology } = useControllerQueryApiHook<IGetPosology>({
    uniqId: FETCH_GET_POSOLOGY,
    entityApi: PosologyAPI.getPosology,
    externalIds: [externalId],
  });

  const { mutateAsync }: any = useControllerMutateApiHook({
    uniqId: THERE_IS_POSOLOGY_TYPE,
    entityApi: PosologyAPI.isPosologyNameAlreadyRegistred,
    options: {
      onSuccess: () => {
        form?.setErrors({
          ...form?.errors,
          descricao: undefined,
        });
      },
      onError: () => {
        form?.setErrors({
          ...form?.errors,
          descricao: t(
            'production.posology.create.errors.errorPosologyNameExist'
          ),
        });
      },
    },
  });

  const { data: pharmaceuticaFormDetail } =
    useControllerQueryApiHook<IGetPharmaceuticalForm>({
      uniqId: FETCH_GET_PHARMACEUTICAL_FORM,
      entityApi: PharmaceuticalFormAPI.getCurrentPharmaceuticalForm,
      externalIds: [
        !pharmaceuticalFormExternalIdChanged
          ? externalIdOriginPharmaceuticalForm
          : pharmaceuticalFormExternalIdChanged,
      ],
    });

  return {
    posology,
    mutateAsync,
    listPharmaceuticalForm,
    isPharmaceuticalFormLoading,
    fetchNewFarmaceuticalFormPage,
    refetchSearchPharmaceuticalFormPage,
    pharmaceuticaFormDetail,
  };
};
