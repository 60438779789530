import { ManyAvatars } from '@/Components/ManyAvatars';
import { IListUserData } from '@/Data/Interfaces/response/User/IGetUserReponse';
import { Text } from '@/Components';
import { TFunction } from 'react-i18next';
import ReactMarkdown from 'react-markdown';

import styles from './DeleteModal.module.scss';

export const deleteModalTitle = (
  selectedRowsOnTable: IListUserData[],
  t: TFunction<'translation', undefined>
) => {
  return selectedRowsOnTable.length === 1
    ? `${t('admin.userList.deleteUserModal.title.delete')} "${
        selectedRowsOnTable[0]?.nome
      } ${selectedRowsOnTable[0]?.sobrenome}"?`
    : `${t('admin.userList.deleteUserModal.title.delete')} ${
        selectedRowsOnTable.length
      } ${t('admin.userList.deleteUserModal.title.users')}?`;
};

export const deleteModalBody = (
  selectedRowsOnTable: IListUserData[],
  t: TFunction<'translation', undefined>
) => (
  <div className={styles['modal-container']}>
    <ManyAvatars
      users={selectedRowsOnTable.map((user) => ({
        name: user.nome,
        lastName: user.sobrenome,
      }))}
    ></ManyAvatars>
    <>
      <div className={styles['modal-body']}>
        <Text type="ui-tiny-content" color="text-50" withoutMarginBottom>
          <ReactMarkdown
            components={{
              strong: ({ node, ...props }) => {
                if(props.children.includes(t('admin.userList.deleteUserModal.deleteColorChange')))
                  return <strong className={styles['highlighted-text']} {...props} />
                return <strong {...props} />
              },
            }}
          >
            {selectedRowsOnTable.length > 1
              ? t('admin.userList.deleteUserModal.bodyDescriptionManyUsers', {
                  amount: selectedRowsOnTable.length,
                  deleteColorChange: t('admin.userList.deleteUserModal.deleteColorChange')
                })
              : t('admin.userList.deleteUserModal.bodyDescription', {
                  user: `**${selectedRowsOnTable[0]?.nome} ${selectedRowsOnTable[0]?.sobrenome}**`,
                  deleteColorChange: t('admin.userList.deleteUserModal.deleteColorChange')
                })}
          </ReactMarkdown>
        </Text>
      </div>
      <div className={styles['modal-footer']}>
        <Text
          color="text-50"
          type="ui-tiny-content"
          children={t('modal.deleteModal.simpleFooter')}
        />
      </div>
    </>
  </div>
);
