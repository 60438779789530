import { App } from 'antd';
import { useTranslation } from 'react-i18next';
import { IUseFunctions } from './interfaces';
import { useCallback } from 'react';
import { handleModalError } from '@/Utils/ErrorCodeUtils/ErrorHandlingUtils';
import { IProductPackingClassificationListData } from '@/Data/Interfaces/response/PackingClassification/IPackingClassificationResponse';

export const useEditFunctions = ({
  removeProductLinked,
  externalId,
}: IUseFunctions) => {
  const app = App.useApp();

  const onRemoveProductLinkedPacking = useCallback(
    async (
      removeProductLinkedForm: IProductPackingClassificationListData[]
    ) => {
      await removeProductLinked.mutateAsync(
        {
          classificacaoEmbalagemExternalId: externalId,
          produtosExternalIds: removeProductLinkedForm.map((x) => {
            return x?.produtoExternalId;
          }),
        },
        {
          onError: handleModalError(app),
        }
      );
    },
    [removeProductLinked]
  );

  return { onRemoveProductLinkedPacking };
};
