import { useFormikContext } from "formik";
import { useTranslation } from "react-i18next";
import { IUseMemorizations } from "./interfaces";

export const useAmountMemorizations = ({
  index
}: IUseMemorizations) => {
  const { t } = useTranslation();
  const form = useFormikContext<any>();

  const unitsMeasurementsMapping: any = {
    1: 'UI',
    2: 'un',
    3: 'mL',
    4: 'L',
    5: 'mcg',
    6: 'mg',
    7: 'g',
    8: 'kg',
    9: 'UTR',
    10: 'UFC',
    11: 'mEq',
    12: 'Mil',
    13: 'PER',
    14: 'MILHAO',
    15: 'BILHAO',
  };

  const label = index === 0
  ? t('purchasing.purchaseOrder.create.productsCardField.amount')
  : undefined;

  const rightIcon = {
    titleString:
      unitsMeasurementsMapping[
        form?.values?.pedidoCompraItens?.[index]?.unidadeMedidaId
      ] || 'g',
  };

  return {
    label,
    rightIcon
  };
};