import { DeleteModalOnTable } from '../../../../../../Utils/DeleteModalUtils';
import { DeleteModal } from './deleteModal';

export const TitleHandler = (selectedRowsOnTable: any, t: any) =>
  selectedRowsOnTable &&
  `${t('common.delete')} ${
    selectedRowsOnTable.length > 1
      ? `${t('common.theManyPackingClassification')}?`
      : `${t(
          'production.packingClassification.list.modalDelete.descriptionPackingClassification'
        )} "${selectedRowsOnTable[0]?.descricao}"?`
  }`;

export const BodyHandler = (selectedRowsOnTable: any, t: any) =>
  selectedRowsOnTable && selectedRowsOnTable?.length > 1 ? (
    DeleteModalOnTable(
      selectedRowsOnTable,
      `${t('modal.deleteModal.many.title')} ${t(
        'common.thePackingClassification'
      )}:`,
      'descricao',
      t('common.stockLocations')
    )
  ) : (
    <DeleteModal selectedRowsOnTable={selectedRowsOnTable} />
  );
