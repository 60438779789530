import { FC } from 'react';
import { Col } from 'antd';
import { Tooltip } from '@/Components';
import { useTranslation } from 'react-i18next';
import { useFormikContext } from 'formik';
import { ICountryOrigin } from './interfaces';
import { TextInputNumber } from '@/Components/Inputs/TextInputNumber';

export const Density: FC<ICountryOrigin> = ({ lots, disabled }) => {
  const { t } = useTranslation();
  const form: any = useFormikContext();
  const technicalInfos = lots?.loteInformacaoTecnica;

  return (
    <Col span={3}>
      <Tooltip
        title={`${t('supply.lot.details.toolTips.originalDensity')} ${
          !!technicalInfos ? lots?.loteInformacaoTecnica?.densidade : 0
        }`}
        showMe={
          !disabled &&
          lots?.loteInformacaoTecnica?.densidade !==
            form?.values?.loteInformacaoTecnica?.densidade
        }
      >
        <TextInputNumber
          name="loteInformacaoTecnica.densidade"
          label={t('supply.lot.details.details.density')}
          placeHolder={t('supply.lot.details.details.density')}
          disabled={disabled}
          withStatusIcon
          withoutArrows
        />
      </Tooltip>
    </Col>
  );
};
