import React from 'react';
import { Form } from '../../../../../Components';
import { InfoCard } from './Components/Cards/InfoCard';
import { FinancialCard } from './Components/Cards/FinancialCard';
import { PackagingsCard } from './Components/Cards/PackagingsCard';
import { PharmaceuticalFormCreateSchema } from './CreatePharmaceuticalForm.schema';
import { useCreateCallAPI } from './Hooks/useCreateCallAPI';
import { useCreateFunctions } from './Hooks/useCreateFunctions';
import { FooterButtons } from './Components/FooterButtons';
import { ConnectedFocusError } from '@/Components/ConnectedFocusError';

export const CreatePharmaceuticalForm: React.FC = () => {
  const { createPharmaceuticalForm, isLoadingCreatePharmaceuticalForm } =
    useCreateCallAPI();

  const { onSubmit } = useCreateFunctions({
    createPharmaceuticalForm,
  });

  return (
    <Form onSubmit={onSubmit} schema={PharmaceuticalFormCreateSchema}>
      <>
        <InfoCard />
        <FinancialCard />
        <PackagingsCard />
        <FooterButtons
          isLoadingCreatePharmaceuticalForm={isLoadingCreatePharmaceuticalForm}
          onSubmit={onSubmit}
        />
        <ConnectedFocusError />
      </>
    </Form>
  );
};
