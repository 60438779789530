import { CreateProduct } from '@/Pages/Sectors/Inventory/Products/Product/Create';
import { EditProduct } from '@/Pages/Sectors/Inventory/Products/Product/Edit';
import { List } from '@/Pages/Sectors/Inventory/Products/Product/List';
import { Route, Switch } from 'react-router-dom';

export const ProductRoutes = () => (
  <Switch>
    <Route path="/inventory/products/create" component={CreateProduct} />
    <Route
      path="/inventory/products/:externalId/:tab/:associationProductId"
      component={EditProduct}
    />
    <Route
      path="/inventory/products/:externalId/:tab"
      component={EditProduct}
    />
    <Route path="/inventory/products" component={List} />
  </Switch>
);
