import { Button } from '@/Components';
import _ from 'lodash';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { IFChildren } from '@/Components/Form';
import styles from '../../DetailsForm.module.scss';
import { NotificationActions } from '@/Store/Notification/Notification.actions';
import { useDispatch } from 'react-redux';

interface IFooterButtons {
  isLoadingEditStorageLocation: boolean;
  props: IFChildren;
  onChangeDisabled: (x: boolean) => void;
}

export const FooterButtons: FC<IFooterButtons> = ({
  isLoadingEditStorageLocation,
  props,
  onChangeDisabled,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  return (
    <div className={styles['footer-button']}>
      <Button
        type="secondary"
        children={t('common.cancel')}
        onClick={() => {
          props.resetForm()
          onChangeDisabled(true)
          dispatch(NotificationActions.setCurrentFormIsDirty(false));
        }}
      />
      <Button
        type="primary"
        children={
          isLoadingEditStorageLocation ? t('common.saving') : t('common.save')
        }
        htmlType="submit"
        loading={isLoadingEditStorageLocation}
      />
    </div>
  );
};
