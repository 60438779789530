import { FC } from "react";
import { TextArea } from '@/Components/Inputs/TextArea';
import { useTranslation } from 'react-i18next';
import { Tooltip } from '@/Components';
import { useFormikContext } from 'formik';
import { IProcedures } from "./interfaces";

export const Procedures: FC<IProcedures> = ({
  standardFormula,
  disabled
}) => {
  const { t } = useTranslation();
  const form: any = useFormikContext();

  return (
    <Tooltip
      title={`${t(
        'production.standardFormula.details.tooltips.previousProcedure'
      )}  ${
        standardFormula?.procedimento
          ? standardFormula?.procedimento
          : `${t('common.empty')}`
      }`}
      targetOffset={[-110, -15]}
      placement="topLeft"
      showMe={
        !disabled &&
        standardFormula?.procedimento !== form.values.procedimento
      }
    >
      <TextArea
        name="procedimento"
        label={t('production.standardFormula.details.procedures')}
        placeholder={t(
          'production.standardFormula.details.proceduresPlaceholder'
        )}
        disable={disabled}
        maxLength={1000}
        rows={4}
        withStatusIcon
      />
    </Tooltip>
  );
};