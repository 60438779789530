import { FC } from "react";
import {
  TextArea,
  Tooltip
} from '@/Components';
import { Col } from 'antd';
import { useTranslation } from "react-i18next";
import { useFormikContext } from "formik";
import { IStorageLabelNotes } from "./interfaces";

export const StorageLabelNotes: FC<IStorageLabelNotes> = ({
  product,
  disabledForm
})  => {
  const { t } = useTranslation();
  const form: any = useFormikContext();

  return (
    <Col span={24}>
      <Tooltip
        title={`${t('products.toolTips.products.originalValue')} ${
          product && product.materiaPrima
            ? product.materiaPrima?.observacaoRotuloArmazenagem
            : ''
        }`}
        showMe={
          product &&
          !disabledForm &&
          product.materiaPrima?.observacaoRotuloArmazenagem !==
            form?.values?.observacao
        }
      >
        <TextArea
          withStatusIcon={!!product}
          name="observacaoRotuloArmazenagem"
          label={t('products.create.storageLabelNotes')}
          maxLength={500}
          placeholder={t('products.create.storageLabelNotesPlaceholder')}
          disable={disabledForm}
        />
      </Tooltip>
    </Col>
  );
};