import { useFormikContext } from "formik";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Divflex } from '@/Layouts/DivWhithFlex';
import ReactMarkdown from 'react-markdown';
import { InputLabel } from '@/Components/Inputs/LabelTotal';
import { currencyFormater } from '@/Utils/Currency';
import { StatusCard, Text } from '@/Components';

import styles from '../ConfirmWithSupplierModal.module.scss';

export const useConfirmWithSupplierModalMemorizations = () => {
  const { t } = useTranslation();
  const form: any = useFormikContext();

  const body = useMemo(() => (
    <>
      <div style={{ padding: '20px' }}>
        <Divflex
          style={{
            flexDirection: 'column',
            marginBottom: 24,
          }}
        >
          <Text type="ui-tiny-content">
            <ReactMarkdown>
              {t(
                'purchasing.purchaseOrder.modal.confirmWithSupplier.description',
                {
                  numeroPedido: `**#${form.values.numeroPedido}**`,
                  fornecedor: `**${form.values.nomeFornecedor}**`,
                }
              )}
            </ReactMarkdown>
          </Text>
        </Divflex>
        <Divflex
          style={{
            justifyContent: 'space-between',
            marginBottom: 12,
          }}
        >
          <Text type="ui-tiny-semibold">
            {t('purchasing.purchaseOrder.create.totalCard.totalProducts')}
          </Text>
          <Text type="ui-tiny-content">
            {currencyFormater(
              form?.values?.totalizadores.totalProdutosLiquido
            )}
          </Text>
        </Divflex>
        <div
          style={{
            height: 1,
            width: '100%',
            border: '1px solid var(--color-background-04)',
            marginBottom: 12,
          }}
        />
        <Divflex
          style={{
            justifyContent: 'space-between',
            marginBottom: 12,
          }}
        >
          <Text type="ui-tiny-semibold">
            {t('purchasing.purchaseOrder.create.totalCard.freightCost')}
          </Text>
          <Text type="ui-tiny-content">
            {currencyFormater(form?.values?.frete)}
          </Text>
        </Divflex>
        <Divflex
          style={{
            justifyContent: 'space-between',
            marginBottom: 12,
          }}
        >
          <Text type="ui-tiny-semibold">
            {t('purchasing.purchaseOrder.create.totalCard.additionalValue')}
          </Text>
          <Text type="ui-tiny-content">
            {currencyFormater(form?.values?.valorAdicional)}
          </Text>
        </Divflex>
        <Divflex
          style={{
            justifyContent: 'space-between',
            marginBottom: 12,
          }}
        >
          <Text type="ui-tiny-semibold">
            {t('purchasing.purchaseOrder.create.totalCard.fullDiscount')}
          </Text>
          <Text type="ui-tiny-content">
            {currencyFormater(form?.values?.valorDesconto)}
          </Text>
        </Divflex>
        <div
          style={{
            height: 1,
            width: '100%',
            border: '1px solid var(--color-background-04)',
            marginBottom: 12,
          }}
        />
        <InputLabel
          type="ui-tiny-semibold"
          style={{ marginBottom: 24}}
          labelTitle={t(
            'purchasing.purchaseOrder.create.totalCard.totalValues'
          )}
          labelValue={currencyFormater(
            form?.values?.totalizadores.totalPedido
          )}
          customSize={24}
        />
        <StatusCard
          type="ui-tiny-semibold"
          height={60}
          className={styles['status']}
          icon={{ icon: 'shipping-truck' }}
        >
          {`${t('purchasing.purchaseOrder.create.deliveryForecast')}: ${
            form.values.previsaoEntrega
          }`}
        </StatusCard>
      </div>
    </>
  ), [form, t]);

  return {
    body
  };
};