import { useMemo } from "react";
import { IUseMemorizations } from "./interfaces";
import { IGetStateResponse } from "@/Data/Interfaces/response/Locality/IStateResponse";

export const useUfRegisterMemorizations = ({ listState }: IUseMemorizations) => {
  const items = useMemo(() => {
    return listState?.data?.map((x: IGetStateResponse) => ({
      id: x.externalId,
      label: x.abreviacao,
    }));
  }, [listState]);

  return { items };
};