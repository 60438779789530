import { ICreateStorageLocation } from '@/Data/Interfaces/request/StorageLocation/IStorageLocationRequest';
import { IUseFunctions } from './interfaces';
import { FormikHelpers, FormikValues } from 'formik';
import _ from 'lodash';

export const useCreateFunctions = ({
  createStorageLocation,
  selectedOptionsStock,
  fetchStorageLocationDescription,
  storageLocation,
}: IUseFunctions) => {
  const onSubmit = async (
    values: ICreateStorageLocation,
    helpers: FormikHelpers<FormikValues>
  ) => {
    try {
      var body: ICreateStorageLocation = { ...values, helpers };
      return await createStorageLocation({
        empresaId: body?.empresaId,
        descricao: body?.descricao,
        ativo: true,
        tipoEstoque: selectedOptionsStock,
        helpers,
      });
    } catch {
      helpers.setSubmitting(false);
    }
  };

  const fetchStorageLocationName = _.debounce(
    async (descricao: string, empresaId: string) => {
      try {
        await fetchStorageLocationDescription({
          descricao: descricao,
          empresaId: empresaId,
          localEstoqueExternalId: storageLocation?.externalId,
        });
      } catch {}
    },
    500
  );

  return {
    onSubmit,
    fetchStorageLocationName,
  };
};
