import { CompanyCreate } from '@/Pages/Sectors/AdminBootis/Company/CompanyCreate';
import { EditCompany } from '@/Pages/Sectors/AdminBootis/Company/CompanyEdit';
import { CompanyAdminList } from '@/Pages/Sectors/AdminBootis/Company/List';
import { Route, Switch } from 'react-router-dom';

export const AdminBootisCompanyRoutes = () => (
  <Switch>
    <Route
      path="/admin-bootis/company/:externalId/:tab"
      component={EditCompany}
    />
    <Route path="/admin-bootis/company/create" component={CompanyCreate} />
    <Route path="/admin-bootis/company" component={CompanyAdminList} />
  </Switch>
);
