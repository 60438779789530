import { IProductGroupListData } from "@/Data/Interfaces/response/Product/IProductRespose";
import { IUseMemorizations } from "./interfaces";

export const useIncompatibleProductMemorizations = ({
  productList,
  productExternalId
}: IUseMemorizations) => {
  const items = productList.data
  ? productList?.data?.map((e: IProductGroupListData) => ({
      id: e.externalId,
      label: e.descricao,
      disabledItem:
        e.externalId === productExternalId ||
        e.seVinculoProduto,
    }))
  : [];

  return {
    items
  };
};