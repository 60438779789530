import { QueryFunctionContext } from '@tanstack/react-query';
import { Http, IQueryListParams } from '../../../Utils/Http';
import {
  IGetStateResponse,
  IListStateResponse,
} from '../../Interfaces/response/Locality/IStateResponse';

export class StateAPI {
  public static async getListState(
    context: QueryFunctionContext
  ): Promise<IListStateResponse> {
    return await Http.fetcher<IListStateResponse>(
      Http.AddQueryParams(
        '/pharma/localidade/v1/Estado/Listar',
        context.queryKey[1] as IQueryListParams
      )
    );
  }
  public static async getState(
    context: QueryFunctionContext
  ): Promise<IGetStateResponse | null> {
    if (context.queryKey[1])
      return await Http.fetcher<IGetStateResponse>(
        `/pharma/localidade/v1/Estado/${context.queryKey[1]}`
      );
    return null;
  }
}
