import { i18n } from '@/Components';
import { useControllerMutateApiHook } from '@/ControllerApiHook/Controller';
import { CreateInvoiceDraft } from '@/Services/Purchase/Invoice/CreateInvoiceDraft.service';

import {
  messageErrorToast,
  messageSucessToast,
} from '@/Utils/MessageErrorToast';

export const CREATE_INVOICE_DRAFT = '[INVOICE] CREATE_INVOICE_DRAFT';

export const useCreateInvoiceDraft = () => {
  const createInvoiceDraft: any = useControllerMutateApiHook({
    uniqId: CREATE_INVOICE_DRAFT,
    entityApi: CreateInvoiceDraft,
    options: {
      onError: (x) => messageErrorToast(x),
      onSuccess: () => {
        messageSucessToast(
          i18n.t('purchasing.invoice.successMessageToasts.createDraft')
        );
      },
    },
  });

  return { createInvoiceDraft };
};
