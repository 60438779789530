import { Col, Row } from 'antd';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Card, Dropdown, TextInput } from '../../../../../../../Components';
import { ILossDetailResponse } from '@/Data/Interfaces/response/Loss/ILossDetailResponse';
import { useEditMemorizations } from '../LossInfo/Hooks/useEditMemorizations';

interface IInfo {
  getLossSingle?: ILossDetailResponse;
}

export const LossInfo: FC<IInfo> = ({ getLossSingle }) => {
  const { t } = useTranslation();
  const { itemsResponsible } = useEditMemorizations({ getLossSingle });
  return (
    <Card title={t('supply.balanceAdjustment.info')} withoutCollapse={true}>
      <Row gutter={[16, 0]}>
        <Col span={3}>
          <TextInput
            name="dataLancamentoFormatted"
            disabled
            label={t('supply.losses.detail.launchDate')}
            placeHolder={t('supply.balanceAdjustment.adjustDate')}
          />
        </Col>
        <Col span={5}>
          <Dropdown
            name="usuarioExternalId"
            disabled
            label={t('supply.balanceAdjustment.responsavel')}
            items={itemsResponsible}
          />
        </Col>
      </Row>

      <Row gutter={[16, 0]}>
        <Col span={12}>
          <TextInput
            name="descricaoProduto"
            disabled
            label={t('supply.balanceAdjustment.product')}
            placeHolder={t('supply.balanceAdjustment.product')}
          />
        </Col>
        <Col span={12}>
          <TextInput
            name="descricaoLocalEstoque"
            disabled
            label={t('supply.balanceAdjustment.supplyPlace')}
            placeHolder={t('supply.balanceAdjustment.supplyPlace')}
          />
        </Col>
      </Row>

      <Row gutter={[16, 0]}>
        <Col span={11}>
          <TextInput
            name="numeroLote"
            disabled
            label={t('supply.balanceAdjustment.lot')}
            placeHolder={t('supply.balanceAdjustment.lot')}
          />
        </Col>
        <Col span={6}>
          <TextInput
            name="nomeFornecedor"
            disabled
            label={t('supply.balanceAdjustment.provider')}
            placeHolder={t('supply.balanceAdjustment.provider')}
          />
        </Col>

        <Col span={5}>
          <TextInput
            name="numeroNotaFiscal"
            disabled
            label={t('supply.balanceAdjustment.identifier')}
            placeHolder={t('supply.balanceAdjustment.identifier')}
          />
        </Col>

        <Col span={2}>
          <TextInput
            name="serieNotaFiscal"
            disabled
            label={t('supply.balanceAdjustment.nfSeries')}
            placeHolder={t('supply.balanceAdjustment.nfSeries')}
          />
        </Col>
      </Row>
    </Card>
  );
};
