import { FC } from 'react';
import { BodyModal } from '@/Components/Modal';
import {
  ICommonError
} from '@/Utils/ErrorCodeUtils/ErrorHandlingUtils';
import { Icon, Text } from '@/Components';
import { useTranslation } from 'react-i18next';
import { Divflex } from '@/Layouts/DivWhithFlex';
import { ReactMarkdown } from 'react-markdown/lib/react-markdown';
import { DeleteModalOnTableErrorCode } from '@/Utils/DeleteModalUtils';

export const Content: FC<ICommonError> = ({ content }) => {
  const { t } = useTranslation();

  return (
    <>
      <BodyModal
        body={
          content?.length === 1 ? (
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <Text type="ui-tiny-content">
                <ReactMarkdown>
                  {t('supply.product.modal.cannotDelete.subTitle', {
                    product: `**${content[0].descricao}**`,
                  })}
                </ReactMarkdown>
              </Text>

              <Text type="ui-tiny-content" style={{ marginTop: 10 }}>
                {t('supply.product.modal.cannotDelete.description')}
              </Text>

              <Divflex style={{ marginTop: 25 }}>
                <Icon
                  icon={'exclamation-upside-down'}
                  size={'SM'}
                  color={'text-400'}
                  style={{ padding: '18px 18px 0 8px' }}
                />
                <Text
                  type="paragraph2"
                  color={'text-400'}
                  children={t(
                    'supply.product.modal.cannotDelete.warningFooter'
                  )}
                />
              </Divflex>
            </div>
          ) : (
            DeleteModalOnTableErrorCode(
              content,
              t('supply.product.modal.cannotDelete.batchSubTitle'),
              'descricao',
              'descricao',
              t('supply.product.modal.cannotDelete.batchDescription'),
              t('supply.product.modal.cannotDelete.batchWarningFooter')
            )
          )
        }
        style={{ padding: 20 }}
      />
    </>
  );
};