import React from 'react';
import { Table } from '../../../../../Components';
import { useTranslation } from 'react-i18next';
import { transfersCollums } from './transfers.columns';
import { PageLoading } from '../../../../../Layouts/PageLoading';
import { useListCallAPI } from './Hooks/useListCallAPI';
import { useListMemorizations } from './Hooks/useListMemorizations';
import StorageUtils from '@/Utils/StorageUtils';

export const TransfersList: React.FC = () => {
  const { t } = useTranslation();

  const preferences = StorageUtils.getPreference();

  const { transferList, isLoadingTransferList, refetch } = useListCallAPI();

  const { headerOptions, rowOptions } = useListMemorizations();

  return (
    <PageLoading loading={isLoadingTransferList}>
      <Table
        columns={transfersCollums(t, preferences)}
        headerOptions={headerOptions}
        loading={isLoadingTransferList}
        pagination={transferList}
        onRefetch={refetch}
        rowsOptions={rowOptions}
      />
    </PageLoading>
  );
};

export default TransfersList;
