import { useControllerQueryListApiHook } from '@/ControllerApiHook/Controller';
import { FormatDataToDropdownUtils } from '@/Utils/FormatDataToDropdownUtils';
import { ListUnitByClass } from '@/Services/Supply/UnitMeasurement/ListUnitByClass.service';
import { useEffect } from 'react';

export const FETCH_LIST_UNITS_BY_CLASS =
  '[UNIT_MEASUREMENT] FETCH_LIST_UNITS_BY_CLASS';

interface IUseListProducts {
  fieldName: string;
  enabled?: boolean;
  returnFullData?: boolean;
  isDropdownRight?: boolean;
  classIds: number[];
}

export const useListUnitByClass = ({
  fieldName,
  enabled,
  returnFullData,
  isDropdownRight,
  classIds = [],
}: IUseListProducts) => {
  const { data, refetch, isLoading, fetchNewPage } =
    useControllerQueryListApiHook({
      uniqId: FETCH_LIST_UNITS_BY_CLASS,
      entityApi: ListUnitByClass,
      autoIncrement: true,
      enabled: enabled || !!classIds.length,
      autoIncrementCustomId: 'id',
    });

  const items = FormatDataToDropdownUtils({
    data: data && data.data ? data.data : [],
    idKey: 'id',
    labelKey: isDropdownRight ? 'unidadeAbreviacao' : 'descricao',
  });

  const onSearch = (search?: string) => {
    refetch({
      search,
    });
  };

  useEffect(() => {
    if (classIds.length) {
      refetch({
        pageIndex: 1,
        pageSize: 50,
        filter: [
          ...classIds.map((id) => ({
            filterName: 'tipoClasseProduto',
            value: id,
          })),
        ],
      });
    }
  }, [classIds]);

  if (isDropdownRight) {
    return {
      name: fieldName,
      loading: isLoading,
      options: items.map((e) => ({
        id: e.id,
        content: e.label,
      })),
    } as any;
  }

  return {
    fullData: returnFullData ? (data && data.data ? data.data : []) : undefined,
    name: fieldName,
    items,
    onSearch,
    isLoading,
    onScrollEnd: fetchNewPage,
  };
};
