import { FC } from 'react';
import { BudgetButtons } from './BudgetButtons';
import { ReverseButton } from './ReverseButton';

export enum StatusSalesOrderEnum {
  Orcado = 1,
  Aprovado = 2,
  Reprovado = 3,
  Entregue = 4,
  EntregueParcial = 5,
  Cancelado = 6,
}

interface IActionButtons {
  typePurchase: number;
  disabledButton?: boolean;

  onOkClick?: () => void;
  onCancelClick?: () => void;
  onClickDropdownItem?: () => void;
}

export const ActionButtons: FC<IActionButtons> = ({
  typePurchase,
  disabledButton,
  onOkClick,
  onCancelClick,
  onClickDropdownItem,
}) => {
  return typePurchase === StatusSalesOrderEnum.Orcado ? (
    <BudgetButtons
      disabledButton={disabledButton}
      onCancelClick={onCancelClick}
      onClickDropdownItem={onClickDropdownItem}
      onOkClick={onOkClick}
    />
  ) : typePurchase === StatusSalesOrderEnum.Aprovado ||
    typePurchase === StatusSalesOrderEnum.Reprovado ||
    typePurchase === StatusSalesOrderEnum.Cancelado ? (
    <ReverseButton onCancelClick={onCancelClick} />
  ) : (
    <></>
  );
};
