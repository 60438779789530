import React, { CSSProperties, FC, ReactNode } from 'react';

interface IDivflex {
  children: ReactNode;
  style?: CSSProperties;
  className?: string;

  onClick?: () => void;
  ref?: React.LegacyRef<HTMLDivElement>;
}

export const Divflex: FC<IDivflex> = ({
  children,
  style,
  className,
  onClick,
  ref,
}) => (
  <div
    className={className}
    style={{ display: 'flex', ...style }}
    children={children}
    onClick={onClick}
    ref={ref}
  />
);
