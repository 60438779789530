import React, { useEffect } from 'react';
import { Form } from '../../../../../../../../Components';
import { InfoSection } from './InfoSection';
import { ValueSection } from './ValueSection';
import { IInvoiceGetResponse } from '../../../../../../../../Data/Interfaces/response/Purchase/IInvoiceResponse';
import { ProductsSection } from './ProductsSection';
import { formatInvoice } from '@/Pages/Sectors/Order/Invoice/Edit/utils/functions';

interface ISections {
  invoice?: IInvoiceGetResponse;
}

export const Sections: React.FC<ISections> = ({ invoice }) => {
  const [purchaseOrders, setPurchaseOrders] = React.useState<any[]>([]);
  const [products, setProducts] = React.useState<any[]>([]);
  const [formattedInvoice, setFormattedInvoice] =
    React.useState<IInvoiceGetResponse>();

  useEffect(() => {
    if (invoice) {
      const purchaseOrders = invoice.pedidosVinculados;
      const formattedPurchaseOrders = purchaseOrders.map((e) => {
        return { ...e, itens: e.pedidoItensVinculados };
      });
      setPurchaseOrders(formattedPurchaseOrders);
      setProducts(invoice.itens);
      setFormattedInvoice(formatInvoice(invoice));
    }
  }, [invoice]);

  return (
    <Form initialValues={formattedInvoice ?? {}}>
      {() => (
        <>
          <InfoSection purchaseOrders={purchaseOrders} />
          <ValueSection />
          <ProductsSection products={products} />
        </>
      )}
    </Form>
  );
};
