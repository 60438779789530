/* eslint-disable react/jsx-key */
import React, { FC } from 'react';
import WithSideBar from '../../Layouts/WithSideBar';
import { SectorsRoutes } from './Sectors';
import { DashboardRoutes } from './Dashboard';
import { SystemConfigurationsRoutes } from './SystemConfigurations';
import { UserSettingRoutes } from './UserSetting';
import { IGetUserResponse } from '@/Data/Interfaces/response/User/IGetUserReponse';

interface IAuthenticatedApp {
  getMe?: IGetUserResponse;
}

const AuthenticatedApp: FC<IAuthenticatedApp> = ({ getMe }) => (
  <WithSideBar getMe={getMe}>
    <DashboardRoutes />

    <UserSettingRoutes />

    <SystemConfigurationsRoutes />

    <SectorsRoutes />
  </WithSideBar>
);

export default AuthenticatedApp;
