import { QueryFunctionContext } from '@tanstack/react-query';
import { Http, IQueryListParams } from '../../../Utils/Http';
import {
  IGetPackingClassification,
  IPackingClassificationList,
  IPackingClassificationListData,
  IProductPackingClassificationResponse,
} from '../../Interfaces/response/PackingClassification/IPackingClassificationResponse';
import {
  ICreatePackingClassification,
  IDeletePackingClassificationRequest,
  IDeleteProductLinkedPackingRequest,
  IEditPackingClassification,
  IPathPackingClassificationStatusRequest,
} from '../../Interfaces/request/PackingClassification/IPackingClassificationRequest';
import { IBaseResponse } from '../../Interfaces/response/Base/IBase';

export class PackingClassificationAPI {
  public static async createPackingClassification(
    body: ICreatePackingClassification
  ) {
    const res = await Http.fetcher(
      '/pharma/producao/v1/EmbalagemClassificacao',
      {
        method: 'POST',
        body: JSON.stringify(body),
      }
    );
    if (!res) return [];
    return res;
  }

  public static async getPackingClassificationList(
    context: QueryFunctionContext
  ): Promise<IPackingClassificationList | null> {
    if (!context.queryKey[1]) return null;

    return await Http.fetcher<IPackingClassificationList>(
      Http.AddQueryParams(
        `/pharma/producao/v1/EmbalagemClassificacao/ListarDetalhado`,
        context.queryKey[1] as IQueryListParams
      )
    );
  }

  public static async getCurrentClassificationPacking(
    context: QueryFunctionContext
  ): Promise<IGetPackingClassification> {
    return await Http.fetcher<IGetPackingClassification>(
      `/pharma/producao/v1/EmbalagemClassificacao/${context.queryKey[1]}`
    );
  }

  public static async editPackingClassification(
    body: IEditPackingClassification
  ) {
    return await Http.fetcher('/pharma/producao/v1/EmbalagemClassificacao', {
      method: 'PUT',
      body: JSON.stringify(body),
    });
  }

  public static async isPackingClassificationNameAlreadyRegistred(
    descricao?: string
  ): Promise<IBaseResponse<{}> | null> {
    if (!descricao) return null;
    return await Http.fetcher(
      '/pharma/producao/v1/EmbalagemClassificacao/ValidarDescricao',
      {
        method: 'POST',
        body: JSON.stringify({ descricao }),
      }
    );
  }

  public static async patchPackingClassificationStatus(
    body: IPathPackingClassificationStatusRequest
  ) {
    const res = await Http.fetcher(
      `/pharma/producao/v1/EmbalagemClassificacao/AtualizarStatus`,
      {
        method: 'PUT',
        body: JSON.stringify(body),
      }
    );
    if (!res) return [];
    return res;
  }

  public static async useDeletePackingClassification(body: string[]) {
    const res = await Http.fetcher(
      `/pharma/producao/v1/EmbalagemClassificacao`,
      {
        method: 'DELETE',
        body: JSON.stringify(body),
      }
    );
    if (!res) return [];
    return res;
  }

  public static async getListPackingClassificationDropDown(
    context: QueryFunctionContext
  ): Promise<IPackingClassificationList | null> {
    if (!context.queryKey[1]) return null;
    return await Http.fetcher<IPackingClassificationList>(
      Http.AddQueryParams(
        '/pharma/producao/v1/EmbalagemClassificacao/Listar',
        context.queryKey[1] as IQueryListParams
      )
    );
  }

  public static async getProductLinkedByPackingList(
    context: QueryFunctionContext
  ): Promise<IProductPackingClassificationResponse> {
    let url = Http.AddQueryParams(
      `/pharma/producao/v1/EmbalagemClassificacao/ListarProdutosVinculados`,
      context.queryKey[1] as IQueryListParams
    );
    return await Http.fetcher<IProductPackingClassificationResponse>(url);
  }

  public static async deleteProductLinkedPacking(
    body: IDeleteProductLinkedPackingRequest
  ) {
    const res = await Http.fetcher(
      `/pharma/producao/v1/EmbalagemClassificacao/RemoverProdutosVinculados`,
      {
        method: 'DELETE',
        body: JSON.stringify(body),
      }
    );
    if (!res) return [];
    return res;
  }
}
