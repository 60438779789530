import { ConnectedFocusError } from '@/Components/ConnectedFocusError';
import { Row } from 'antd';
import { FC } from 'react';
import { Name } from './Components/Name';
import { Birthday } from './Components/Birthday';
import { RegisterType } from './Components/RegisterType';
import { UfRegister } from './Components/UfRegister';
import { RegisterNumber } from './Components/RegisterNumber';
import { Specialties } from './Components/Specialties';
import { ProductDiscount } from './Components/ProductDiscount';
import { FormulaDiscount } from './Components/FormulaDiscount';
import { Observation } from './Components/Observation';
import { useInfoBodyModalCallAPI } from './Hooks/useInfoBodyModalCallAPI';

export const InfoBodyModal: FC = () => {
  const { listState, recordType } = useInfoBodyModalCallAPI();

  return (
    <div style={{ padding: '20px' }}>
      <ConnectedFocusError />
      <Row gutter={[16, 0]}>
        <Name />
        <Birthday />
      </Row>
      <Row gutter={[16, 0]}>
        <RegisterType
          refetchStateList={listState.refetch}
          recordTypeList={recordType}
        />
        <UfRegister
          refetchRecordTypeList={recordType.refetch}
          listState={listState}
        />
        <RegisterNumber />
      </Row>
      <Row gutter={[16, 0]}>
        <Specialties />
      </Row>
      <Row gutter={[16, 0]}>
        <ProductDiscount />
        <FormulaDiscount />
      </Row>
      <Row gutter={[16, 0]}>
        <Observation />
      </Row>
    </div>
  );
};
