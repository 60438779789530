import { IGetStateResponse } from "@/Data/Interfaces/response/Locality/IStateResponse";
import { useMemo } from "react";
import { IUseMemorizations } from "./interfaces";

export const useUfRegisterMemorizations = ({ listState }: IUseMemorizations) => {
  const items = useMemo(() => {
    return listState?.data?.map((x: IGetStateResponse) => ({
      id: x.externalId,
      label: `${x.abreviacao} - ${x.descricao}`,
      content: x.descricao,
    }));
  }, [listState]);

  return {
    items
  };
};