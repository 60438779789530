import { useCallback } from "react";
import { IUseFunctions } from "./interfaces";

export const useEditFunctions = ({
  setVisibleModal
}: IUseFunctions) => {
  const changeVisibleModal = useCallback(
    (value: boolean) => setVisibleModal(value), [setVisibleModal]
  );

  return { changeVisibleModal };
};