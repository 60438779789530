import { FETCH_LIST_UNITS_BY_CLASS } from '@/ControllerApiHook/UniqIds/Supply/UnitMeasurementKeys';
import { IUsecallAPI } from './interfaces';
import { UnitMeasurementAPI } from '@/Data/API/Supply/UnitMeasurementApi';
import { useControllerQueryListApiHook } from '@/ControllerApiHook/Controller';
import { useEffect } from 'react';
import { isNil } from 'lodash';

export const useInitialQuantityCallAPI = ({
  typeClassProduct,
}: IUsecallAPI) => {
  const { data: listunitsMeasurements, refetch } =
    useControllerQueryListApiHook({
      uniqId: FETCH_LIST_UNITS_BY_CLASS,
      entityApi: UnitMeasurementAPI.listUnitMeasurementByClass,
      autoIncrement: true,
      enabled: !isNil(typeClassProduct),
    });

  useEffect(() => {
    refetch({
      pageIndex: 1,
      pageSize: 10,
      filter: [
        {
          value: typeClassProduct,
          filterName: 'tipoClasseProduto',
        },
      ],
    });
  }, [typeClassProduct]);

  return {
    listunitsMeasurements,
  };
};
