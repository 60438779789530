import { Http, IQueryListParams } from '../../../Utils/Http';
import { QueryFunctionContext } from '@tanstack/react-query';
import {
  IGetTransfer,
  ITransferList,
} from '../../Interfaces/response/Transfer/ITransferList';

export class TransferAPI {
  public static async listTransfer(
    context: QueryFunctionContext
  ): Promise<ITransferList> {
    return await Http.fetcher<ITransferList>(
      Http.AddQueryParams(
        '/pharma/estoque/v1/TransferenciaLote/ListarDetalhado',
        context.queryKey[1] as IQueryListParams
      )
    );
  }

  public static async getTransfer(context: QueryFunctionContext) {
    if (!context.queryKey[1]) return;
    return await Http.fetcher<IGetTransfer>(
      `/pharma/estoque/v1/TransferenciaLote/${context.queryKey[1]}`
    );
  }

  public static async createTransfer(body: any) {
    return await Http.fetcher('/pharma/estoque/v1/TransferenciaLote', {
      method: 'POST',
      body: JSON.stringify(body),
    });
  }
}
