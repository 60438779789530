import { useMemo } from 'react';
import { IUseMemorizations } from './interfaces';
import {
  IListCompanyToLaboratoryData,
  IListSupplyPlaceToLaboratoryData,
} from '@/Data/Interfaces/response/Laboratory/ILaboratoryResponse';

export const useCreateMemorizations = ({
  supplyPlaceList,
  companyList,
}: IUseMemorizations) => {
  const supplyPlacesList = useMemo(() => {
    return supplyPlaceList?.data?.map(
      (item: IListSupplyPlaceToLaboratoryData) => {
        return {
          id: item.externalId,
          label: item.descricao,
        };
      }
    );
  }, [supplyPlaceList]);

  const companyListlFormList = useMemo(() => {
    return companyList?.data?.map((item: IListCompanyToLaboratoryData) => {
      return {
        id: item.externalId,
        label: item.nomeFantasia,
      };
    });
  }, [companyList]);

  return {
    supplyPlacesList,
    companyListlFormList,
  };
};
