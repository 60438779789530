import { AdminRoutes } from './Admin';
import { AdminBootisRoutes } from './AdminBootis';
import { InventoryRoutes } from './Inventory';
import { OrderRoutes } from './Order';
import { ProductionRoutes } from './Production';
import { SalesRoutes } from './Sales';

export const SectorsRoutes = () => (
  <>
    <AdminRoutes />
    <AdminBootisRoutes />
    <InventoryRoutes />
    <ProductionRoutes />
    <OrderRoutes />
    <SalesRoutes />
  </>
);
