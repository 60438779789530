import { useParams } from 'react-router';
import {
  FETCH_GET_DROPDOWN_GROUP,
  FETCH_GET_SUBGROUP,
  VALIDATION_NAME_SUBGROUP,
} from '@/ControllerApiHook/UniqIds/Supply/ProductKeys';
import { IUseCallAPi } from './interfaces';
import {
  useControllerMutateApiHook,
  useControllerQueryApiHook,
  useControllerQueryListApiHook,
} from '@/ControllerApiHook/Controller';
import { ProductAPI } from '@/Data/API/Supply/Product';
import { IProductSubGroupResponse } from '@/Data/Interfaces/response/Product/IProductRespose';

export const useInputDescriptionSubGroupCallAPI = ({
  onGroupValidationError,
  onSuccessValidationError,
}: IUseCallAPi) => {
  const { externalId } = useParams<{ externalId: string }>();

  const { mutateAsync }: any = useControllerMutateApiHook({
    uniqId: VALIDATION_NAME_SUBGROUP,
    entityApi: ProductAPI.productSubGroupNameValidation,
    options: {
      onError: onGroupValidationError,
      onSuccess: onSuccessValidationError,
    },
  });

  const { data } = useControllerQueryApiHook<IProductSubGroupResponse>({
    uniqId: FETCH_GET_SUBGROUP,
    entityApi: ProductAPI.getproductSubGroup,
    externalIds: [externalId],
  });

  const { data: dataGroupDropDown, fetchNewPage: fetchNewGroupPage } =
    useControllerQueryListApiHook({
      uniqId: FETCH_GET_DROPDOWN_GROUP,
      entityApi: ProductAPI.getGroupListDropdown,
      autoIncrement: true,
    });

  return {
    mutateAsync,
    data,
    dataGroupDropDown,
    fetchNewGroupPage,
  };
};
