import { Col } from 'antd';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IQualityControl } from './interfaces';
import { Checkbox, Tooltip } from '@/Components';
import { useFormikContext } from 'formik';

import styles from './QualityControl.module.scss';

export const QualityControl: FC<IQualityControl> = ({
  product,
  disabledForm,
}) => {
  const { t } = useTranslation();
  const form: any = useFormikContext();
  const [showTooltip, setShowTooltip] = useState(true);

  return (
    <Col span={6} className={styles['checkbox']}>
      <Tooltip
        title={`${t('products.toolTips.products.originalValue')} ${
          product?.controlaQualidade === true
            ? t('products.toolTips.products.enabled')
            : t('products.toolTips.products.disabled')
        }`}
        showMe={
          product &&
          !disabledForm &&
          product.controlaQualidade !== form?.values?.controlaQualidade &&
          showTooltip
        }
      >
        <Checkbox
          name="controlaQualidade"
          label={t('products.create.qualityControl')}
          disabled={disabledForm}
          withStatusIcon={!!product}
          withTooltip={{
            icon: 'question-mark',
            title: t('products.create.qualityControlTooltip'),
            hoverAction: () => setShowTooltip(false),
            mouseLeaveAction: () => setShowTooltip(true),
          }}
        />
      </Tooltip>
    </Col>
  );
};
