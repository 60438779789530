import { FETCH_LIST_DROPDOWN_DCB } from '@/ControllerApiHook/UniqIds/Production/DcbKeys';
import { FETCH_LIST_UNITS_BY_CLASS } from '@/ControllerApiHook/UniqIds/Supply/UnitMeasurementKeys';
import { IUseCallAPI } from './interfaces';
import { DcbAPI } from '@/Data/API/Production/Dcb';
import { useControllerQueryListApiHook } from '@/ControllerApiHook/Controller';
import { isNil } from 'lodash';
import { UnitMeasurementAPI } from '@/Data/API/Supply/UnitMeasurementApi';
import { useEffect } from 'react';

export const useFeedstockInfoCardCallAPI = ({
  feedStockClassId,
}: IUseCallAPI) => {
  const {
    data: listUnitsMeasurementsPrescription,
    isLoading: isUnitOfMeasurePrescriptionLoading,
    refetch,
  } = useControllerQueryListApiHook({
    uniqId: FETCH_LIST_UNITS_BY_CLASS,
    entityApi: UnitMeasurementAPI.listUnitMeasurementByClass,
    autoIncrement: true,
    enabled: !isNil(feedStockClassId),
  });

  useEffect(() => {
    refetch({
      pageIndex: 1,
      pageSize: 10,
      filter: [
        {
          value: feedStockClassId,
          filterName: 'tipoClasseProduto',
        },
      ],
    });
  }, [feedStockClassId]);

  const {
    data: dcbList,
    isLoading: isLoadingDcb,
    fetchNewPage: fetchNewDcbPage,
    refetch: refetchSearchDcbPage,
  } = useControllerQueryListApiHook({
    uniqId: FETCH_LIST_DROPDOWN_DCB,
    entityApi: DcbAPI.listDropdownDcb,
    autoIncrement: true,
    autoIncrementCustomId: 'id',
  });

  return {
    listUnitsMeasurementsPrescription,
    isUnitOfMeasurePrescriptionLoading,
    dcbList,
    isLoadingDcb,
    fetchNewDcbPage,
    refetchSearchDcbPage,
  };
};
