export const GET_STANDARD_FORMULA = '[STANDARD_FORMULA] GET_STANDARD_FORMULA';

export const FETCH_LIST_STANDARD_FORMULA =
  '[STANDARD_FORMULA] FETCH_LIST_STANDARD_FORMULA';

export const CREATE_STANDARD_FORMULA =
  '[STANDARD_FORMULA] CREATE_STANDARD_FORMULA';
export const EDIT_STANDARD_FORMULA = '[STANDARD_FORMULA] EDIT_STANDARD_FORMULA';
export const DELETE_STANDARD_FORMULA =
  '[STANDARD_FORMULA] DELETE_STANDARD_FORMULA';
  
export const THERE_IS_STANDARD_FORMULA_TYPE =
  '[STANDARD_FORMULA] THERE_IS_STANDARD_FORMULA_TYPE';
