import { history } from '@/Store';
import { useQueryClient } from '@tanstack/react-query';
import { useControllerMutateApiHook } from '@/ControllerApiHook/Controller';
import {
  CREATE_CAPSULE_COLORS,
  FETCH_GET_CAPSULE_COLORS,
  FETCH_LIST_CAPSULE_COLORS,
} from '@/ControllerApiHook/UniqIds/Production/CapsuleColorsKeys';
import { CapsulseColorsAPI } from '@/Data/API/Production/CapsuleColors';

export const useCreateCallAPI = () => {
  const queryClient = useQueryClient();

  const {
    mutateAsync: createCapsuleColors,
    isLoading: isLoadingCreateCapsuleColors,
  }: any = useControllerMutateApiHook({
    uniqId: CREATE_CAPSULE_COLORS,
    entityApi: CapsulseColorsAPI.createCapsuleColors,
    options: {
      onSuccess: () => {
        queryClient.invalidateQueries([FETCH_LIST_CAPSULE_COLORS]);
        queryClient.invalidateQueries([FETCH_GET_CAPSULE_COLORS]);
        history.push('/production/capsule-colors');
      },
    },
  });

  return {
    createCapsuleColors,
    isLoadingCreateCapsuleColors,
  };
};
