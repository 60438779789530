import { useControllerQueryListApiHook } from '@/ControllerApiHook/Controller';
import { FormatDataToDropdownUtils } from '@/Utils/FormatDataToDropdownUtils';
import { IQueryListParams } from '@/Utils/Http';

import { GetOpenPurchaseOrderList } from '@/Services/Purchase/PurchaseOrder/GetOpenPurchaseOrderList.service';

export const FETCH_GET_OPEN_ORDER_PURCHASE =
  '[ORDER_PURCHASE] FETCH_GET_OPEN_ORDER_PURCHASE';

interface IUseListOpenPurchaseOrder {
  fieldName: string;
  enabled?: boolean;
  pagination?: IQueryListParams;
}

export const useGetOpenPurchaseOrderList = ({
  fieldName,
  enabled,
  pagination,
}: IUseListOpenPurchaseOrder) => {
  const { data, refetch, isLoading, fetchNewPage } =
    useControllerQueryListApiHook({
      uniqId: FETCH_GET_OPEN_ORDER_PURCHASE,
      entityApi: GetOpenPurchaseOrderList,
      autoIncrement: true,
      enabled,
      pagination,
    });

  const items = FormatDataToDropdownUtils({
    data: data && data.data ? data.data : [],
    idKey: 'externalId',
    labelKey: 'nomeFornecedor',
  });

  const onSearch = (search?: string) => {
    refetch({
      search,
    });
  };

  return {
    data: data && data.data ? data : undefined,
    name: fieldName,
    items,
    onSearch,
    isLoading,
    onScrollEnd: fetchNewPage,
    refetch,
  };
};
