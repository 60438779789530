import { IUseFunctions } from './interfaces';
import { useMemo } from 'react';
import _ from 'lodash';

export const useDetailFormFunctions = ({
  mutateAsyncGroupNameAlreadyRegistered,
  initialGroupName
}: IUseFunctions) => {
  const fetchUserGroupName = useMemo(
    () =>
      _.debounce(async (value: string) => {
        const auxText = new Date().getTime();

        try {
          if(value === initialGroupName) 
            return value && (await mutateAsyncGroupNameAlreadyRegistered(value + auxText));

          return (value || value === '') && (await mutateAsyncGroupNameAlreadyRegistered(value));
        } catch {
          return undefined;
        }
      }, 500),
    [mutateAsyncGroupNameAlreadyRegistered]
  );

  return { fetchUserGroupName };
};
