import { useState } from 'react';

export const useCreateStates = () => {
  const [pharmaceuticaFormExternalId, setpharmaceuticaFormExternalId] =
    useState<string>('');

  return {
    pharmaceuticaFormExternalId,
    setpharmaceuticaFormExternalId,
  };
};
