import { IUseMemorizations } from './interfaces';

export const useCreateMemorizations = ({
  listunitsMeasurements,
  isUnitOfMeasureLoading,
  onUnitOfMeasureChange,
}: IUseMemorizations) => {
  const unitOfMeasureDropdown = listunitsMeasurements
    ? {
        name: 'unidadeMedidaId',
        loading: isUnitOfMeasureLoading,
        options:
          listunitsMeasurements && listunitsMeasurements.data
            ? listunitsMeasurements?.data.map((x) => ({
                id: x.id,
                content: x.unidadeAbreviacao,
              }))
            : [],
        onChange: onUnitOfMeasureChange,
      }
    : undefined;

  return { unitOfMeasureDropdown };
};
