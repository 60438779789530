import { useCallback } from "react";
import { IUseFunctions } from "./interfaces";

export const useSpecialtyFunctions = ({
  refetchSearchSpecialties
}: IUseFunctions) => {
  const onSearch = useCallback((search: string | undefined) => {
    refetchSearchSpecialties({
      pageIndex: 1,
      pageSize: 10,
      search: search,
      sorter: { column: 'descricao', direction: 'DESC' },
    });
  }, [refetchSearchSpecialties]);

  return { onSearch };
};