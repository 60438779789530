import { IUseMemorizations } from './interfaces';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { IDeleteModalTable } from '@/Components/Table';
import { PermissionEnum } from '@/Data/Interfaces/model/permissionEnum';
import { DeleteModalOnTable } from '@/Utils/DeleteModalUtils';
import { IconName } from '@/Components/Icon/IconClasses';
import { RemoveLinkedModal } from '../Modal/DeletePackagingsModal/deleteModal';

export const useListMemorizations = ({
  packingClassificationList,
  selectedRowsOnTable,
  setVisibleBindPackingClassificationPharmaceuticalFormModal,
}: IUseMemorizations) => {
  const { t } = useTranslation();

  const headerOptions = {
    hasSearch: true,
    deleteButton: {
      icon: 'Broken-Chain-3' as IconName,
      label: t(
        'production.pharmaceuticalForm.details.packingClassification.unlink'
      ),
      permission: {
        permission: PermissionEnum.Producao_FormaFarmaceutica_EditarDetalhes,
        tooltip: 'noPermission.production.pharmaceuticalForm.permissionsUnlink',
      },
    },
    newButton: {
      label: t(
        'production.pharmaceuticalForm.details.packingClassification.newButton'
      ),
      onClick: () =>
        setVisibleBindPackingClassificationPharmaceuticalFormModal(true),
      permission: {
        permission: PermissionEnum.Producao_FormaFarmaceutica_EditarDetalhes,
        tooltip: 'noPermission.production.pharmaceuticalForm.permissionsLink',
      },
    },
    searchPlaceholder: t(
      'production.pharmaceuticalForm.details.packingClassification.searchPlaceHolder'
    ),
  };

  const deleteModal = useMemo(
    (): IDeleteModalTable | undefined => ({
      title:
        selectedRowsOnTable &&
        `${t(
          'production.pharmaceuticalForm.details.packingClassification.messageTitleOne'
        )} ${
          selectedRowsOnTable.length > 1
            ? ` ${t(
                'production.pharmaceuticalForm.details.packingClassification.messageTitleMultiple'
              )}?`
            : `"${selectedRowsOnTable[0]?.classificacaoEmbalagemDescricao}" ?`
        }`,

      body:
        selectedRowsOnTable && selectedRowsOnTable?.length > 1 ? (
          DeleteModalOnTable(
            selectedRowsOnTable,
            `${t(
              'production.pharmaceuticalForm.details.packingClassification.messageMultiple'
            )}:`,
            'classificacaoEmbalagemDescricao',
            ''
          )
        ) : (
          <RemoveLinkedModal
            selectedRowsOnTable={selectedRowsOnTable}
            packingClassificationList={packingClassificationList}
          />
        ),
      widthModal: 413,
      buttons: {
        okButtonColor: 'status-danger-base',
      },
      okButtonName: 'common.remove',
    }),
    [selectedRowsOnTable, t, packingClassificationList]
  );

  return {
    headerOptions,
    deleteModal,
  };
};
