import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Divflex } from '../../../../../../../../../Layouts/DivWhithFlex';
import { Text } from '../../../../../../../../../Components';
import { FooterThisActionIsPermanent } from '../../../../../../../../../Components/Table/components/Modals/DeleteModal';
import ReactMarkdown from 'react-markdown';
import { IPackingClassificationLinkedPharmaceuticalFormListResponse } from '../../../../../../../../../Data/Interfaces/response/PharmaceuticalForm/IPharmaceuticalFormResponse';

interface IPackingClassification {
  selectedRowsOnTable: any;
  packingClassification?: string;
  packingClassificationList?: IPackingClassificationLinkedPharmaceuticalFormListResponse | null;
}

export const RemoveLinkedModal: FC<IPackingClassification> = ({
  selectedRowsOnTable,
}) => {
  const { t } = useTranslation();
  return (
    <div style={{ padding: '20px' }}>
      <Divflex style={{ flexDirection: 'column', marginBottom: 10 }}>
        <Text type="ui-tiny-content" withoutMarginBottom>
          <ReactMarkdown>
            {t(
              'production.pharmaceuticalForm.details.packingClassification.messageFirst',
              {
                action: `**"${
                  selectedRowsOnTable[0]?.classificacaoEmbalagemDescricao
                }"** ${t(
                  'production.pharmaceuticalForm.details.packingClassification.messageFinishBody'
                )}"**${selectedRowsOnTable[0]?.descricaoFormaFarmaceutica}**"?`,
              }
            )}
          </ReactMarkdown>
        </Text>
      </Divflex>
      <FooterThisActionIsPermanent />
    </div>
  );
};
