import React, { FC } from 'react';
import { NewPasswordSchema } from './NewPassword.schema';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { LoginLayout } from '@/Components/Layouts';
import { Button, Form, TextInput } from '@/Components';
import { TextInputPassword } from '@/Components/Inputs/TextInputPassword';
import { history } from '@/Store';
import { useNewPasswordCallAPI } from './Hooks/useNewPasswordCallApi';
import { useNewPasswordFunctions } from './Hooks/useNewPasswordFunctions';

import styles from './NewPassword.module.scss';

export const NewPassword: FC = () => {
  const { t } = useTranslation();

  const { newPassword } = useNewPasswordCallAPI(t);

  const { mail, externalId, code } = useParams<{
    mail: string;
    externalId: string;
    code: string;
  }>();

  const { onSubmit } = useNewPasswordFunctions({
    externalId,
    code,
    newPassword,
  });

  return (
    <LoginLayout title={t('forgotPassword.title')}>
      <Form
        schema={NewPasswordSchema}
        className={styles['Form']}
        onSubmit={onSubmit}
        initialValues={{
          email: mail,
        }}
        noLeaveWithoutLeaving
      >
        <TextInput
          name="email"
          type="text"
          label={t('common.email')}
          leftIcon="email"
          disabled
        />
        <TextInputPassword
          name="newPassword"
          type="password"
          label={t('forgotPassword.newPass')}
          leftIcon="lock"
          placeHolder={t('forgotPassword.newPassPlaceholder')}
        />
        <TextInputPassword
          name="confirmPassword"
          type="password"
          label={t('forgotPassword.confirmPass')}
          leftIcon="lock"
          placeHolder={t('forgotPassword.confirmPassPlaceholder')}
        />
        <Button
          className={styles['button']}
          type="primary"
          htmlType="submit"
          loading={newPassword.isLoading}
          disabled={newPassword.isLoading}
          fullWidth
          children={t('forgotPassword.ok')}
        />
        <Button
          className={styles['return-button']}
          type="link"
          onClick={() => history.push('/login')}
          fullWidth
          children={t('forgotPassword.back')}
        />
      </Form>
    </LoginLayout>
  );
};
