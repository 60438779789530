import { useCallback } from 'react';
import { IUseFunctions } from './interfaces';

export const useInfoCardFunctions = ({
  setEditCardDisabled,
}: IUseFunctions) => {
  const changeVisibleModal = useCallback(
    (x: boolean) => setEditCardDisabled(x),
    [setEditCardDisabled]
  );

  return { changeVisibleModal };
};
