import React, { FC } from 'react';
import { Text } from '../../../../../../../Components/Text';
import ReactMarkdown from 'react-markdown';
import { useConfirmStatusMemorizations } from './Hooks/useConfirmStatusProperties';
import { useTranslation } from 'react-i18next';

import styles from './ConfirmStatusModal.module.scss';

export interface IManyItemsBody {
  itemNames: { name: string; lastName: string }[];
}

export const ConfirmBody: FC<IManyItemsBody> = ({ itemNames }) => {
  const { t } = useTranslation();

  const {
    avatarDescription,
    isSingleUser,
    manyAvatarsComponent,
    oneAvatarComponent,
  } = useConfirmStatusMemorizations({ itemNames, t });

  return (
    <div className={styles['content-avatar']}>
      {isSingleUser ? oneAvatarComponent : manyAvatarsComponent}
      <Text color="text-50" type="paragraph2" withoutMarginBottom>
        <ReactMarkdown children={avatarDescription} />
      </Text>
    </div>
  );
};
