import { FC } from "react";
import { TextArea, Tooltip } from '@/Components';
import { Col } from 'antd';
import { useTranslation } from "react-i18next";
import { ILabelDescription } from "./interfaces";

export const LabelDescription: FC<ILabelDescription> = ({
  synonymData,
  props,
  disabledForm
}) => {
  const { t } = useTranslation();

  return (
    <Col span={24}>
      <Tooltip
        title={`${t('products.synonym.originalLabelDescription')}"${
          synonymData?.descricaoRotulo ?? t('common.empty')
        }"`}
        showMe={
          synonymData &&
          synonymData.descricaoRotulo !==
            props?.values?.descricaoRotulo
        }
        targetOffset={[200, -10]}
      >
        <TextArea
          name="descricaoRotulo"
          label={t('products.synonym.labelDescription')}
          maxLength={200}
          placeholder={t('products.synonym.descriptionPlaceholder')}
          disable={disabledForm}
          withStatusIcon
        />
      </Tooltip>
    </Col>
  );
};