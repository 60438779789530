import { IEditMemorizations } from './interfaces';

export const useEditMemorizations = ({ getLossSingle }: IEditMemorizations) => {
  const itemsResponsible = getLossSingle
    ? [
        {
          id: getLossSingle?.usuarioExternalId,
          avatar: {
            name: getLossSingle?.responsavel || '',
          },
          label: getLossSingle?.responsavel,
        },
      ]
    : undefined;

  return { itemsResponsible };
};
