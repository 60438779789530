import { FETCH_GET_OPEN_ORDER_PURCHASE } from '@/Hooks/HooksServices/Purchase/PurchaseOrder/useGetOpenPurchaseOrderList';
import { useQueryClient } from '@tanstack/react-query';

export const useInvalidatePurchaseList = () => {
  const queryClient = useQueryClient();

  const invalidatePurchaseList = () => {
    queryClient.invalidateQueries([FETCH_GET_OPEN_ORDER_PURCHASE]);
  };

  return { invalidatePurchaseList };
};
