import React from 'react';
import { Table } from '@/Components';
import { useTranslation } from 'react-i18next';
import { companyCollums } from './company.columns';
import { useListStates } from './Hooks/useListStates';
import { useListCallAPI } from './Hooks/useListCallAPI';
import { useListMemorizations } from './Hooks/useListMemorizations';
import { useListFunctions } from './Hooks/useListFunctions';
import { PageLoading } from '@/Layouts/PageLoading';

export const List: React.FC = () => {
  const { rowSelected, setRowSelected } = useListStates();

  const { companysList, changeResponsibilytiUser } = useListCallAPI();

  const { onEdit, onRowDoubleClick, selectedRowsOnTable, modalTitle } =
    useListFunctions({
      rowSelected,
      changeResponsibilytiUser,
      setRowSelected,
    });

  const { headerOptions, editModal, rowOptions } = useListMemorizations({
    rowSelected,
    onRowDoubleClick,
    modalTitle,
  });

  const { t } = useTranslation();

  return (
    <PageLoading loading={companysList.isLoading}>
      <Table
        columns={companyCollums(t)}
        headerOptions={headerOptions}
        loading={companysList.isLoading}
        hasSelection
        pagination={companysList.data}
        onEdit={onEdit}
        editModal={editModal}
        editStatusModalLoading={changeResponsibilytiUser.isLoading}
        onRefetch={companysList.refetch}
        rowsOptions={rowOptions}
        selectedRowsOnTable={selectedRowsOnTable}
      />
    </PageLoading>
  );
};
