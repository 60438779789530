import { FC, useEffect } from "react";
import {
  Tooltip
} from '@/Components';
import { Col } from 'antd';
import { useTranslation } from "react-i18next";
import { useFormikContext } from "formik";
import { IBaseMolecularWeight } from "./interfaces";
import { WeightInput } from "@/Components/Inputs/WeightInput";
import { useBaseMolecularWeightFunctions } from "./Hooks/useBaseMolecularWeightFunctions";

export const BaseMolecularWeight: FC<IBaseMolecularWeight> = ({
  product,
  disabledForm,
  setEquivalenceFactor
})  => {
  const { t } = useTranslation();
  const form: any = useFormikContext();

  const {
    onChange
  } = useBaseMolecularWeightFunctions({
    setEquivalenceFactor
  });

  useEffect(() => {
    if (form.values.pesoMolecularBase > form.values.pesoMolecularSal) {
      form.setErrors({
        ...form.errors,
        pesoMolecularBase: t('products.create.errors.errorMolecularBase'),
      });
    } else
      form.setErrors({
        ...form.errors,
        pesoMolecularBase: '',
      });
  }, [form.values.pesoMolecularBase, form.values.pesoMolecularSal]);

  return (
    <Col span={4}>
      <Tooltip
        title={`${t(
          'products.toolTips.products.originalBaseMolecularWeight'
        )} ${
          product && product.materiaPrima
            ? product.materiaPrima?.pesoMolecularBase
            : ''
        }`}
        showMe={
          product &&
          !disabledForm &&
          product.materiaPrima?.pesoMolecularBase !==
            form?.values?.pesoMolecularBase
        }
      >
        <WeightInput
          withStatusIcon={!!product}
          name="pesoMolecularBase"
          placeHolder="0000,00"
          label={t('products.create.baseMolecularWeight')}
          onChange={onChange}
          disabled={disabledForm}
          withTooltip={{
            icon: 'exclamation-upside-down',
            title: t('products.create.tooltips.molecularBase'),
          }}
          error={!!form.errors.pesoMolecularBase}
        />
      </Tooltip>
    </Col>
  );
};