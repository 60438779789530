import { FC } from "react";
import { Row } from 'antd';
import { NumberInvoice } from "./Components/NumberInvoice";
import { SeriesInvoice } from "./Components/SeriesInvoice";

export const InvoiceInfo: FC = () => {
  return (
    <Row gutter={[16, 0]}>
      <NumberInvoice />
      <SeriesInvoice />
    </Row>
  );
};