import { IUseRequiredPermissionModalCallAPI } from './interfaces';
import { useControllerMutateApiHook } from '@/ControllerApiHook/Controller';
import { UPDATE_USER_PERMISSION } from '@/ControllerApiHook/UniqIds/Accountmanager/UserKeys';
import { UserAPI } from '@/Data/API/Accountmanager/UserApi';

export const useRequiredPermissionModalCallAPI = ({
  error,
  changeVisibleModal,
  form,
}: IUseRequiredPermissionModalCallAPI) => {
  const updateUserPermission = useControllerMutateApiHook({
    uniqId: UPDATE_USER_PERMISSION,
    entityApi: UserAPI.updateUserPermission,
    options: {
      onSuccess: () => {
        error?.dependencias?.forEach((dep: any) => {
          form.setFieldValue(dep.permissaoId, error.ativo);
        });
        changeVisibleModal();
      },
    },
  });

  return {
    updateUserPermission,
  };
};
