import { i18n } from '@/Components';
import {
  CANCEL_ORDER_PURCHASE
} from '@/ControllerApiHook/UniqIds/Purchase/PurchaseOrderKeys';
import { useControllerMutateApiHook } from '@/ControllerApiHook/Controller';
import { PurchaseAPI } from '@/Data/API/Purchase/PurchaseOrderApi';
import {
  messageErrorToast,
  messageSucessToast
} from '@/Utils/MessageErrorToast';

export const useCancelModalCallAPI = () => {
  const cancelOrderPurchase: any = useControllerMutateApiHook({
    uniqId: CANCEL_ORDER_PURCHASE,
    entityApi: PurchaseAPI.cancelOrderPurchase,
    options: {
      onError: (x) => messageErrorToast(x),
      onSuccess: () =>
        messageSucessToast(
          i18n.t('purchasing.purchaseOrder.successMessageToasts.cancel')
        ),
    },
  });

  return {
    cancelOrderPurchase
  };
};