import { CurrencyInput } from '@/Components/Inputs/CurrencyInput';
import { Col, Row } from 'antd';
import { useTranslation } from 'react-i18next';

export const RowTwo: React.FC = () => {
  const { t } = useTranslation();

  return (
    <Row gutter={[16, 0]}>
      <Col span={4}>
        <CurrencyInput
          label={t('purchasing.invoice.create.freight')}
          name="valorFrete"
          placeHolder={t(
            'purchasing.invoice.create.placeHolder.CurrencyPlaceHolder'
          )}
        />
      </Col>
      <Col span={4}>
        <CurrencyInput
          label={t('purchasing.invoice.create.insurance')}
          name="valorSeguro"
          placeHolder={t(
            'purchasing.invoice.create.placeHolder.CurrencyPlaceHolder'
          )}
        />
      </Col>
      <Col span={4}>
        <CurrencyInput
          label={t('purchasing.invoice.create.discount')}
          name="valorDesconto"
          placeHolder={t(
            'purchasing.invoice.create.placeHolder.CurrencyPlaceHolder'
          )}
        />
      </Col>
      <Col span={4}>
        <CurrencyInput
          label={t('purchasing.invoice.create.otherExpenses')}
          name="valorOutrasDespesas"
          placeHolder={t(
            'purchasing.invoice.create.placeHolder.CurrencyPlaceHolder'
          )}
        />
      </Col>
      <Col span={4}>
        <CurrencyInput
          label={t('purchasing.invoice.create.IPIValue')}
          name="valorIpi"
          placeHolder={t(
            'purchasing.invoice.create.placeHolder.CurrencyPlaceHolder'
          )}
        />
      </Col>
    </Row>
  );
};
