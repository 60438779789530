export const FETCH_GET_PHARMACEUTICAL_FORM =
  '[PHARMACEUTICAL_FORM] FETCH_GET_PHARMACEUTICAL_FORM';
export const FETCH_GET_PHARMACEUTICAL_FORM_DETAILS =
  '[PHARMACEUTICAL_FORM] FETCH_GET_PHARMACEUTICAL_FORM_DETAILS';

export const LIST_FOR_PHARMACEUTICAL_FORM_DROPDOWN_TYPE =
  '[PHARMACEUTICAL_FORM] LIST_FOR_PHARMACEUTICAL_FORM_DROPDOWN_TYPE';
export const FETCH_LIST_FOR_PHARMACEUTICAL_FORM =
  '[PHARMACEUTICAL_FORM] FETCH_LIST_FOR_PHARMACEUTICAL_FORM';
export const FETCH_LIST_PACKING_CLASSIFICATION_LINKED_PHARMACEUTICAL_FORM =
  '[PHARMACEUTICAL_FORM] FETCH_LIST_PACKING_CLASSIFICATION_LINKED_PHARMACEUTICAL_FORM';

export const CREATE_PHARMACEUTICAL_FORM =
  '[PHARMACEUTICAL_FORM] CREATE_PHARMACEUTICAL_FORM';
export const EDIT_PHARMACEUTICAL_FORM =
'[PHARMACEUTICAL_FORM] EDIT_PHARMACEUTICAL_FORM';
export const DELETE_PHARMACEUTICAL_FORM =
'[PHARMACEUTICAL_FORM] DELETE_PHARMACEUTICAL_FORM';
export const EDIT_STATUS_PHARMACEUTICAL_FORM =
  '[PHARMACEUTICAL_FORM] EDIT_STATUS_PHARMACEUTICAL_FORM';
export const DELETE_PHARMACEUTICAL_FORM_DETAILS =
'[PHARMACEUTICAL_FORM] DELETE_PHARMACEUTICAL_FORM_DETAILS';

export const FETCH_PHARMACEUTICAL_PRESCRIPTION =
'[PHARMACEUTICAL_FORM] FETCH_PHARMACEUTICAL_PRESCRIPTION';
export const THERE_IS_PHARMACEUTICAL_FORM_TYPE =
  '[PHARMACEUTICAL_FORM] THERE_IS_PHARMACEUTICAL_FORM_TYPE';
export const THERE_IS_APRESENTATION_PHARMACEUTICAL_FORM =
  '[PHARMACEUTICAL_FORM] THERE_IS_APRESENTATION_PHARMACEUTICAL_FORM';

