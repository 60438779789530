import { CustomersRoutes } from './Customers';
import { ExpertiseRoutes } from './Expertise';
import { OrderRoutes } from './Order';
import { PrescribersRoutes } from './Prescribers';
import { TicketsRoutes } from './Tickets';

export const SalesRoutes = () => (
  <>
    <TicketsRoutes />
    <OrderRoutes />
    <CustomersRoutes />
    <PrescribersRoutes />
    <ExpertiseRoutes />
  </>
);
