import React, { useState } from 'react';
import { IGetCompanyResponse } from '../../../../../../../Data/Interfaces/response/Company/ICompanyResponse';
import { CompanyAdressCard } from '../../../../../../../Layouts/CompanyFormLayout/CompanyAdressCard';
import { CompanyEditBillingsCard } from '../../../../../../../Layouts/CompanyFormLayout/CompanyBillingsCard/Components/EditForm';
import { CompanyDataCardWrapper } from '../../../../../../../Layouts/CompanyFormLayout/CompanyDataCard';
import { CompanyGroupsCardWrapper } from '../../../../../../../Layouts/CompanyFormLayout/CompanyGroupsCard';
import { EditCompanyUserAdmin } from '../../../../../../../Layouts/CompanyFormLayout/CompanyResponsibleUserCard/EditAdmin';
import { PageLoading } from '../../../../../../../Layouts/PageLoading';

interface ICompanyDetails {
  company?: IGetCompanyResponse;
}

export const CompanyDetails: React.FC<ICompanyDetails> = ({ company }) => {
  const [radioCompanyGroup, setRadioCompanyGroup] = useState(true);
  const [radioUser, setRadioUser] = useState(true);
  const [radioCompanyBillings, setRadioCompanyBillings] = useState(true);

  return (
    <PageLoading>
      <div>
        {company && (
          <>
            <CompanyDataCardWrapper company={company} />
            <CompanyAdressCard company={company} editForm />
            <EditCompanyUserAdmin
              radioCompanyGroup={radioCompanyGroup}
              radioUser={radioUser}
              onChangeRadioUser={setRadioUser}
              company={company}
            />
            <CompanyGroupsCardWrapper
              company={company}
              onChangeRadioCompanyGroup={(value) => setRadioCompanyGroup(value)}
              radioCompanyGroup={radioCompanyGroup}
            />
            <CompanyEditBillingsCard
              company={company}
              radioCompanyGroup={radioCompanyGroup}
              onChangeRadioBillings={setRadioCompanyBillings}
              radioBillings={radioCompanyBillings}
            />
          </>
        )}
      </div>
    </PageLoading>
  );
};
