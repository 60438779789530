import { useFormikContext } from "formik";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { IUseFunctions } from "./interfaces";
import _ from "lodash";

export const useSynonymInputFunctions = ({
  currentProductDescription,
  mutateAsync
}: IUseFunctions) => {
  const { t } = useTranslation();
  const form: any = useFormikContext();

  const onProductValidationError = useCallback(() => {
    form.setErrors({
      ...form.errors,
      sinonimo: t('products.synonym.thereIsOneSynonymRegisteredWithThisName'),
    });
  }, [form, t]);

  const validateSynonym = useCallback(
    _.debounce(async (description: string) => {
      if (
        description.toLowerCase() === currentProductDescription?.toLowerCase()
      ) {
        form.setErrors({
          ...form.errors,
          sinonimo: t(
            'products.synonym.theSynonymCannotHaveTheSameNameAsTheRegisteredProduct'
          ),
        });
        return;
      }

      if (description.trim() !== '') {
        try {
          await mutateAsync?.({
            descricao: description,
          });
          form.setErrors((prevErrors: any) => ({
            ...prevErrors,
            sinonimo: '',
          }));
        } catch {}
      } else {
        form.setErrors((prevErrors: any) => ({
          ...prevErrors,
          sinonimo: '',
        }));
      }
    }, 1000),
    [mutateAsync]
  );

  const onChange = useCallback(async (desc: string) => {
    await validateSynonym(desc);
  }, [validateSynonym]);

  return {
    onProductValidationError,
    onChange
  };
};