import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { TextInput } from '@/Components';
import _ from 'lodash';
import { IModalBody } from './interfaces';
import { useModalBodyFunctions } from './Hooks/useModalBodyFunctions';
import { useModalBodyCallAPI } from './Hooks/useModalBodyCallAPI';
import { useModalBodyMemorizations } from './Hooks/useModalBodyMemorizations';

import styles from './ModalBody.module.scss';

export const ModalBody: FC<IModalBody> = ({
  isProductGroupAlreadyRegistred,
}) => {
  const { t } = useTranslation();
  const abbreviatedI18n = 'products.groupList.modal';

  const { onGroupValidationError, onGroupValidationSuccess } =
    useModalBodyFunctions({
      isProductGroupAlreadyRegistred,
    });

  const { mutateAsync } = useModalBodyCallAPI({
    onGroupValidationError,
    onGroupValidationSuccess,
  });

  const { fetchProductGroupValidationName } = useModalBodyMemorizations({
    mutateAsync,
  });

  return (
    <div className={styles['container']}>
      <TextInput
        name="descricao"
        label={t(`${abbreviatedI18n}.label`)}
        placeHolder={t(`${abbreviatedI18n}.placeholder`)}
        type="text"
        withoutMarginBottom
        onChange={(x) => fetchProductGroupValidationName(x)}
        maxLength={50}
        dataTestId="group-description"
      />
    </div>
  );
};
