import { useFormikContext } from "formik";
import { useCallback } from "react";

export const useDosageInputsFunctions = () => {
  const form: any = useFormikContext();

  const onChangeMaximalDosage = useCallback((value: any) => {
    if (typeof value === 'number') {
      const minDosage = +form.values.dosagemMinima;

      form.setFieldError(
        'dosagemMaxima',
        minDosage > value ? 'error message' : undefined
      );
    } else if (value.includes(',')) {
      const withoutDot = value.replace('.', '');
      const newValue = +withoutDot.replace(',', '.');

      const minDosage = +form.values.dosagemMinima;
      form.setFieldError(
        'dosagemMaxima',
        minDosage > newValue ? 'error message' : undefined
      );
    }
  }, [form]);

  return {
    onChangeMaximalDosage
  };
};