import { FETCH_LIST_FOR_DROPDOWN_INCOMPATIBLE_PRODUCT } from '@/ControllerApiHook/UniqIds/Supply/ProductKeys';
import { useControllerQueryListApiHook } from '@/ControllerApiHook/Controller';
import { ProductAPI } from '@/Data/API/Supply/Product';
import { IUseCallAPI } from './interfaces';

export const useIncompatibleProductCallAPI = ({
  productExternalId,
}: IUseCallAPI) => {
  const feedStockClassId = 1;

  const {
    data: productList,
    fetchNewPage: fetchNewProductPage,
    refetch: refetchSearchProductPage,
  } = useControllerQueryListApiHook({
    uniqId: FETCH_LIST_FOR_DROPDOWN_INCOMPATIBLE_PRODUCT,
    entityApi: ProductAPI.getListProductIncompatibleDropDown,
    pagination: {
      filter: [
        {
          filterName: 'classesProdutoIds',
          value: [feedStockClassId],
        },
        {
          filterName: 'orderBy',
          value: 'descricao',
        },
        {
          filterName: 'produtoExternalId',
          value: productExternalId,
        },
      ],
    },
  });

  return {
    productList,
    fetchNewProductPage,
    refetchSearchProductPage,
  };
};
