import * as yup from 'yup';
import i18n from '../../../../../Components/i18n';

export const LaboratoryCreateSchema = yup.object().shape({
  nomeLaboratorio: yup
    .string()
    .nullable()
    .required(
      i18n.t('production.laboratory.create.errors.requiredLaboratoryName')
    ),

  empresaExternalId: yup
    .string()
    .nullable()
    .required(
      i18n.t('production.laboratory.create.errors.requiredNomeFantasia')
    ),

  localEstoqueExternalId: yup
    .string()
    .nullable()
    .required(i18n.t('production.laboratory.create.errors.requiredDescricao')),
});
