import { 
  useControllerMutateApiHook, 
  useControllerQueryApiHook 
} from "@/ControllerApiHook/Controller";
import { ProductAPI } from "@/Data/API/Supply/Product";
import { IAssociatedProductResponse } from "@/Data/Interfaces/response/Product/IAssociatedProductResponse";
import { 
  FETCH_GET_ASSOCIATED_PRODUCT,
  FETCH_GET_PRODUCT,
  PUT_DILUTED_PRODUCT
} from "@/ControllerApiHook/UniqIds/Supply/ProductKeys";
import { IUseCallAPi } from "./interfaces";
import { messageErrorToast, messageSucessToast } from "@/Utils/MessageErrorToast";
import { i18n } from "@/Components";
import { IProductResponse } from "@/Data/Interfaces/response/Product/IProductRespose";

export const useAssociatedProductDetaisCallAPI = ({
  productExternalId,
  associationProductId
}: IUseCallAPi) => {
  const { 
    data: currentProduct 
  } = useControllerQueryApiHook<IProductResponse>({
    uniqId: FETCH_GET_PRODUCT,
    entityApi: ProductAPI.getProduct,
    externalIds: [productExternalId]
  });

  const editAssociatedProduct: any = useControllerMutateApiHook({
    uniqId: PUT_DILUTED_PRODUCT,
    entityApi: ProductAPI.editAssociatedProduct,
    options: {
      onError: (x) => messageErrorToast(x),
      onSuccess: () =>
        messageSucessToast(i18n.t('products.associated.editSuccess'))
    }
  });
  
  const { 
    data: associatedProductData 
  } = useControllerQueryApiHook<IAssociatedProductResponse>({
    uniqId: FETCH_GET_ASSOCIATED_PRODUCT,
    entityApi: ProductAPI.getAssociatedProduct,
    externalIds: [associationProductId]
  });

  return {
    currentProduct,
    editAssociatedProduct,
    associatedProductData
  };
};