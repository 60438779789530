import { useState } from "react";

export const useDetailsStates = () => {
  const [
    isErrorValidateDescription, 
    setIsErrorValidateDescription
  ] = useState<boolean>(false);

  const [
    groupIsEdited, setGroupIsEdited
  ] = useState(false);

  return {
    isErrorValidateDescription,
    setIsErrorValidateDescription,
    groupIsEdited,
    setGroupIsEdited
  };
};