import { useState } from "react";

export const useCreateStates = () => {
  const [
    isEdit, 
    setIsEdit
  ] = useState(false);

  const [
    openDeleteDraftModal, 
    setOpenDeleteDraftModal
  ] = useState(false);

  return {
    isEdit,
    setIsEdit,
    openDeleteDraftModal,
    setOpenDeleteDraftModal
  };
};