import * as yup from 'yup';
import { i18n } from '../../../Components';
import { cnpj } from 'cpf-cnpj-validator';

export const CompanyDataCardSchema = yup.object().shape({
  cnpj: yup
    .string()
    .test(
      'is-valid-cnpj',
      i18n.t('adminBootis.createCompany.companyDataCard.errors.invalidCNPJ'),
      (value) => typeof value === 'string' && cnpj.isValid(value)
    )
    .required(
      i18n.t('adminBootis.createCompany.companyDataCard.errors.requiredCNPJ')
    ),
  razaoSocial: yup
    .string()
    .required(
      i18n.t(
        'adminBootis.createCompany.companyDataCard.errors.requiredSocialReason'
      )
    ),
  nomeFantasia: yup
    .string()
    .required(
      i18n.t(
        'adminBootis.createCompany.companyDataCard.errors.requiredFantasyName'
      )
    ),
  inscricaoEstadual: yup
    .string()
    .min(
      12,
      i18n.t(
        'adminBootis.createCompany.companyDataCard.errors.invalidStateRegistration'
      )
    )
    .required(
      i18n.t(
        'adminBootis.createCompany.companyDataCard.errors.requiredStateRegistration'
      )
    ),
  cnae: yup
    .string()
    .required(
      i18n.t('adminBootis.createCompany.companyDataCard.errors.requiredCNAE')
    ),
});
