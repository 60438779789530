import { Card, Dropdown, TextArea } from '@/Components';
import { TextInputWithMask } from '@/Components/Inputs/TextInputWithMask';
import { IReasonLossListData } from '@/Data/Interfaces/response/Loss/ILossResponse';
import { Col, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import { useCreataCallAPI } from './Hooks/useCreateCallAPI';
import { BalanceFinalLoss } from '../LossBalanceFinal';
import { FC } from 'react';
import { useCreateMemorizations } from './Hooks/useCreateMemorizations';
import styles from '../../LossesRegistration.module.scss';

interface LossRegister {
  typeClassProduct?: number;
}

export const LossRegister: FC<LossRegister> = ({ typeClassProduct }) => {
  const { t } = useTranslation();
  const { reasonLossList, loadingReasonLoss } = useCreataCallAPI();
  const { reasonLossListDropdown } = useCreateMemorizations({ reasonLossList });

  return (
    <>
      <Card
        title={t('supply.losses.create.titleInformationLossRecord')}
        withoutCollapse={true}
      >
        <Row gutter={[16, 0]}>
          <Col span={4}>
            <TextInputWithMask
              className={styles['Left-Icon']}
              name="dataPerda"
              mask="dataMask"
              leftIcon="calendar"
              label={t('supply.losses.create.dateLoss')}
              placeHolder={t('supply.losses.create.dateLossPlaceholder')}
              required
              withoutMarginBottom
            />
          </Col>

          <Col span={7}>
            <Dropdown
              items={reasonLossListDropdown}
              name="motivoPerdaExternalId"
              label={t('supply.losses.create.reasonloss')}
              placeHolder={t('supply.losses.create.reasonLossPlaceholder')}
              required
              isLoading={loadingReasonLoss}
            />
          </Col>
        </Row>

        <div className={styles['margin-balance']}>
          <BalanceFinalLoss typeClassProduct={typeClassProduct} />
        </div>

        <Row gutter={[16, 0]} justify="space-between">
          <Col span={24}>
            <TextArea
              name="observacao"
              label={t('supply.losses.create.comments')}
              placeholder={t('supply.losses.create.commentsPlaceholder')}
              maxLength={200}
              rows={3}
            />
          </Col>
        </Row>
      </Card>
    </>
  );
};
