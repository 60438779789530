import { Avatar } from '@/Components';
import { useMemo } from 'react';
import { v4 as uuidV4 } from 'uuid';
import { IUseMemorizations } from './interfaces';

import styles from './hooks.module.scss';

export const useConfirmStatusMemorizations = ({
  itemNames,
  t,
}: IUseMemorizations) => {
  const isSingleUser = useMemo(() => itemNames.length === 1, [itemNames]);

  const manyAvatarsComponent = useMemo(
    () => (
      <div className={styles['manyAvatarContainer']}>
        {itemNames.slice(0, 5).map((name, index) => (
          <Avatar
            style={{ zIndex: itemNames.length - index }}
            fisrtName={name.name}
            lastName={name.lastName}
            size="M"
            type="ui-tiny-bold"
            color="white"
            key={uuidV4()}
          />
        ))}
        {itemNames.length > 5 ? (
          <Avatar
            fisrtName={`+${itemNames.length - 5}`}
            size="M"
            type="paragraph2-bold"
            color="status-default-base"
            backgroundColor="text-700"
          />
        ) : null}
      </div>
    ),
    [itemNames]
  );

  const oneAvatarComponent = useMemo(
    () =>
      itemNames
        .slice(0, 5)
        .map((name) => (
          <Avatar
            className={styles['singleAvatarContainer']}
            fisrtName={name.name}
            lastName={name.lastName}
            size="L"
            type="ui-main-bold"
            color="white"
            key={uuidV4()}
          />
        )),
    [itemNames]
  );

  const avatarDescription = useMemo(
    () =>
      isSingleUser
        ? t('modal.editModal.single.confirm.areYouSure', {
            description: `**${itemNames[0].name} ${itemNames[0].lastName}**`,
          }) + '\n' + t('modal.editModal.single.confirm.footer')
        : t('modal.editModal.many.confirm.copy', {
            user: `**${itemNames.length}**`,
          }),
    [isSingleUser, itemNames, t]
  );

  return {
    avatarDescription,
    isSingleUser,
    oneAvatarComponent,
    manyAvatarsComponent,
  };
};
