import { QueryFunctionContext } from '@tanstack/react-query';
import { Http, IQueryListParams } from '../../../Utils/Http';
import {
  IGetSupplierResponse,
  ISupplierList,
} from '../../Interfaces/response/Purchase/ISupplierResponse';
import {
  IAddAdressSupllier,
  IAddContactSupplier,
  IAddDocumentSupllier,
  IDeleteSupplierRequest,
  IEditAdressSupplier,
  IEditContactSupplier,
  IEditDocumentSupplier,
  IEditSupplierInfoRequest,
  IEditSupplierStatusRequest,
} from '../../Interfaces/request/Purchasing/Providers/IProviderRequest';
import {
  IGetDocumentProviderResponse,
  IListProviderResponse,
} from '../../Interfaces/response/Provider/IProviderResponse';

export class SupplierAPI {
  public static async getSupplierList(
    context: QueryFunctionContext
  ): Promise<ISupplierList | null> {
    if (!context.queryKey[1]) return null;

    return await Http.fetcher<ISupplierList>(
      Http.AddQueryParams(
        `/pharma/pessoa/v1/Fornecedor/ListarDetalhado`,
        context.queryKey[1] as IQueryListParams
      )
    );
  }

  public static async getSupplier(context: QueryFunctionContext) {
    if (!context.queryKey[1]) return null;
    return await Http.fetcher<IGetSupplierResponse>(
      `/pharma/pessoa/v1/Fornecedor/${context.queryKey[1]}`
    );
  }

  public static async editSupplierStatus(body: IEditSupplierStatusRequest) {
    return await Http.fetcher(`/pharma/pessoa/v1/Fornecedor/AtualizarStatus`, {
      method: 'PUT',
      body: JSON.stringify(body),
    });
  }

  public static async editSupplierInfo(body: IEditSupplierInfoRequest) {
    return await Http.fetcher(`/pharma/pessoa/v1/Fornecedor`, {
      method: 'PUT',
      body: JSON.stringify(body),
    });
  }

  public static async deleteSupplier(body: IDeleteSupplierRequest) {
    return await Http.fetcher(`/pharma/pessoa/v1/Fornecedor`, {
      method: 'DELETE',
      body: JSON.stringify(body),
    });
  }

  public static async listDropdownSupplier(
    context: QueryFunctionContext
  ): Promise<IListProviderResponse> {
    return await Http.fetcher<IListProviderResponse>(
      Http.AddQueryParams(
        '/pharma/pessoa/v1/Fornecedor/Listar',
        context.queryKey[1] as IQueryListParams
      )
    );
  }

  public static async getDocumentProvider(context: QueryFunctionContext) {
    if (!context.queryKey[1]) return null;
    return await Http.fetcher<IGetDocumentProviderResponse>(
      `/pharma/pessoa/v1/Fornecedor/ObterRazaoCnpj/${context.queryKey[1]}`
    );
  }

  public static async addContactSupplier(body: IAddContactSupplier) {
    return await Http.fetcher('/pharma/pessoa/v1/Fornecedor/CadastrarContato', {
      method: 'POST',
      body: JSON.stringify(body),
    });
  }

  public static async editContactSupplier(body: IEditContactSupplier) {
    return await Http.fetcher('/pharma/pessoa/v1/Fornecedor/AtualizarContato', {
      method: 'PUT',
      body: JSON.stringify(body),
    });
  }

  public static async deleteContactSupplier(contactExternalId: string) {
    return await Http.fetcher(
      `/pharma/pessoa/v1/Fornecedor/RemoverContato/${contactExternalId}`,
      {
        method: 'DELETE',
      }
    );
  }

  public static async addAddressSupplier(body: IAddAdressSupllier) {
    return await Http.fetcher(
      '/pharma/pessoa/v1/Fornecedor/CadastrarEndereco',
      {
        method: 'POST',
        body: JSON.stringify(body),
      }
    );
  }

  public static async editAddressSupplier(body: IEditAdressSupplier) {
    return await Http.fetcher(
      '/pharma/pessoa/v1/Fornecedor/AtualizarEndereco',
      {
        method: 'PUT',
        body: JSON.stringify(body),
      }
    );
  }

  public static async deleteAddressSupplier(supplierExternalId: string) {
    return await Http.fetcher(
      `/pharma/pessoa/v1/Fornecedor/RemoverEndereco/${supplierExternalId}`,
      {
        method: 'DELETE',
      }
    );
  }

  public static async addDocumentSupplier(body: IAddDocumentSupllier) {
    return await Http.fetcher(
      '/pharma/pessoa/v1/Fornecedor/CadastrarDocumento',
      {
        method: 'POST',
        body: JSON.stringify(body),
      }
    );
  }

  public static async editDocumentSupplier(body: IEditDocumentSupplier) {
    return await Http.fetcher(
      '/pharma/pessoa/v1/Fornecedor/AtualizarDocumento',
      {
        method: 'PUT',
        body: JSON.stringify(body),
      }
    );
  }

  public static async deleteDocumentSupplier(documentExternalId: string) {
    return await Http.fetcher(
      `/pharma/pessoa/v1/Fornecedor/RemoverDocumento/${documentExternalId}`,
      {
        method: 'DELETE',
      }
    );
  }

  public static async changeFavoriteContactSupplier(body: {
    fornecedorContatoExternalId: string;
    principal: boolean;
  }) {
    return await Http.fetcher(
      '/pharma/pessoa/v1/Fornecedor/AtualizarContatoPrincipal',
      {
        method: 'PUT',
        body: JSON.stringify(body),
      }
    );
  }

  public static async changeFavoriteAddressSupplier(body: {
    fornecedorEnderecoExternalId: string;
    principal: boolean;
  }) {
    return await Http.fetcher(
      '/pharma/pessoa/v1/Fornecedor/AtualizarEnderecoPrincipal',
      {
        method: 'PUT',
        body: JSON.stringify(body),
      }
    );
  }
}
