import { useMemo } from "react";
import { IUseMemorizations } from "./interfaces";

export const useModalMemorizations = ({ users }: IUseMemorizations) => {
  const items = useMemo(() => {
    return users?.data?.map((x) => ({
      id: x.externalId,
      label: x.nome,
      avatar: {
        name: x.nome,
        lastName: x.sobrenome,
        email: x.email,
      },
    }))
  }, [users]);

  return {
    items
  };
};