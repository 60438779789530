import { Modal, Text } from '@/Components';
import { useControllerMutateApiHook } from '@/ControllerApiHook/Controller';
import { SynonymAPI } from '@/Data/API/Supply/SynonymApi';
import { IPostManipulatedRecipeRequest } from '@/Data/Interfaces/request/Sales/Service/IServiceRequest';
import { POST_SYNONYM } from '@/ControllerApiHook/UniqIds/Supply/SynonymKeys';
import { FormikContextType } from 'formik';
import { Dispatch, FC, SetStateAction, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import ReactMarkdown from 'react-markdown';

interface ICreateSynonymForComponent {
  productExternalId: string;
  synonymDescription?: string;

  visible: boolean;
  changeVisible: Dispatch<SetStateAction<boolean>>;
  setEditingSynonymComponent: Dispatch<SetStateAction<boolean>>;

  index: number;

  form: FormikContextType<IPostManipulatedRecipeRequest>;
}

export const CreateSynonymForComponent: FC<ICreateSynonymForComponent> = ({
  productExternalId,
  synonymDescription,

  visible,
  changeVisible,
  setEditingSynonymComponent,

  index,

  form,
}) => {
  const { t } = useTranslation();

  const synonymModali18n = useMemo(
    () =>
      'saleOrder.editOrder.SaleOrder.addManipulatedRecipe.components.createSynonymModal',
    []
  );

  const changeVisibleEnd = useCallback(() => {
    changeVisible(false);
    setEditingSynonymComponent(false);

    form.setFieldValue(`itens[${index}].descricao`, synonymDescription);
  }, [
    changeVisible,
    setEditingSynonymComponent,
    synonymDescription,
    form,
    index,
  ]);

  const postSynonym: any = useControllerMutateApiHook({
    uniqId: POST_SYNONYM,
    entityApi: SynonymAPI.postSynonym,
    options: {
      onSuccess: (x) => {
        form.setFieldValue(
          `itens[${index}].produtoSinonimoExternalId`,
          x.externalId
        );
        form.setFieldValue(`itens[${index}].itemReceitaDescricao`, undefined);
        changeVisibleEnd();
      },
    }
  });

  return (
    <Modal
      title={`${t(synonymModali18n + '.createSynonym')} ${
        form.values.itens[index].description
      }?`}
      body={
        <div style={{ padding: '20px' }}>
          <Text type="ui-tiny-content">
            <ReactMarkdown>
              {t(synonymModali18n + '.whantCreate', {
                descriptionComponent: `**${form.values.itens[index].description}**`,
                synonymDescription: `**${synonymDescription}**`,
                code: `**(#${form.values.itens[index].code})**`,
              })}
            </ReactMarkdown>
          </Text>
          <Text
            type="ui-tiny-content"
            children={t(synonymModali18n + '.descriptionFooter')}
          />
        </div>
      }
      visible={visible}
      okButtonName={
        postSynonym.isLoading
          ? `${t('common.creating')}...`
          : t('common.create')
      }
      cancelButtonName={t('common.dontRegister')}
      onCancelClick={() => {
        if (synonymDescription !== form.values.itens[index].description) {
          form.setFieldValue(
            `itens[${index}].itemReceitaDescricao`,
            synonymDescription
          );

          form.setFieldValue(`itens[${index}].descricao`, synonymDescription);
        }

        setEditingSynonymComponent(false);
        changeVisible(false);
      }}
      onClose={() => changeVisible(false)}
      loading={postSynonym.isLoading}
      htmlType="submit"
      onOkClick={() =>
        synonymDescription &&
        postSynonym.mutateAsync({
          produtoExternalId: productExternalId,
          sinonimo: synonymDescription,
        })
      }
    />
  );
};
