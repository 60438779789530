import { Text, Icon } from '../';
import { IconName } from '../Icon/IconClasses';
import { Color } from '../../Utils/ColorUtils';
import { TextType } from '../Text';

import styles from './StatusCard.module.scss';
import { Divflex } from '@/Layouts/DivWhithFlex';

export interface IStatus {
  className?: string;

  type: TextType;

  color?: Color;

  height?: number;

  children?: string;

  icon?: { icon: IconName; color?: Color };
}

export const StatusCard: React.FC<IStatus> = ({
  className,
  type,
  height,
  children,
  icon,
  color,
}) => (
  <Divflex
    className={`${className} ${styles['status']}`}
    style={{ height: height }}
  >
    {icon?.icon && (
      <div className={styles['div-icon']}>
        <Icon color={icon?.color} icon={icon?.icon} size="M" />
      </div>
    )}

    <Text
      type={type}
      children={children}
      style={{ whiteSpace: 'nowrap' }}
      color={color ?? 'text-50'}
    />
  </Divflex>
);
