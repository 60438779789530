import * as yup from 'yup';
import i18n from '../../../../../../../../Components/i18n';

export const FinanceProductSchema = yup.object().shape({
  valorCusto: yup
    .string()
    .required(i18n.t('products.create.errors.requiredCostPrice')),

  margemLucro: yup
    .string()
    .required(i18n.t('products.create.errors.requiredProfit')),
  valorVenda: yup
    .string()
    .required(i18n.t('products.create.errors.requiredSellPrice')),
});
