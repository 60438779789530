import { Avatar } from '@/Components/Avatar';
import { Text } from '@/Components';
import styles from './SelectableUserList.module.scss';

export const SelectableUserList = (
  name: string,
  lastName: string,
  email: string
) => (
  <div className={styles['avatar-list']}>
    <Avatar
      fisrtName={name}
      lastName={lastName}
      size="M"
      type="ui-tiny-bold"
      color="white"
    />
    <div>
      <Text
        type="ui-tiny-bold"
        color="text-50"
        children={`${name} ${lastName}`}
      />
      <Text type="small-text" color="text-50" children={email} />
    </div>
  </div>
);