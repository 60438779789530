import React, { FC } from 'react';
import { Check } from '../Check';
import WhiteMode from '@/Static/img/themes/light-theme.svg';
import DarkMode from '@/Static/img/themes/dark-theme.svg';
import Midnight from '@/Static/img/themes/midnight-theme.svg';
import { themes } from '@/Utils/ThemeUtils';
import Spinner from '@/Components/Spinner';

import styles from './ThemeMode.module.scss';

export interface IThemeMode {
  theme: themes;
  check: boolean;
  onClick: () => void;

  loading?: boolean;
}

const ThemeMode: FC<IThemeMode> = ({ theme, check, onClick, loading }) => {
  return (
    <div className={`${styles['divMode']} ${theme && styles['div-' + theme]}`}>
      <span
        className={`${styles[theme + '-mode']} ${styles['theme-mode']} ${
          loading ? styles['loading'] : ''
        } ${check && styles['border-check']}`}
        onClick={() => !loading && onClick && onClick()}
      >
        <div className={styles['check']}>
          {loading && check ? <Spinner /> : check && <Check />}
        </div>
        <img
          src={
            theme === 'darkMode'
              ? DarkMode
              : theme === 'lightMode'
              ? WhiteMode
              : Midnight
          }
          alt="BRL"
        />
      </span>
    </div>
  );
};

export default ThemeMode;
