import { TagsRender } from '@/Utils/TagsUtils/TagsUtils';
import { Link, i18n } from '@/Components';
import { hasPermission } from '../../../../../../../../Components/PermissionWrapper';
import { PermissionEnum } from '@/Data/Interfaces/model/permissionEnum';
import { Text } from '../../../../../../../../Components/Text';

export const productMessageColumns = (t: any, externalIdProduct: string) => [
  {
    title: t('products.productList.productMessage.productMessage'),
    dataIndex: 'mensagemDescricao',
    key: 'mensagemDescricao',
    sortColumn: 'mensagemDescricao',
    sorter: true,
    isHighlightable: true,
    defaultSortOrder: 'ascend',
    render: (x: any, row: any) =>
      !hasPermission(PermissionEnum.Estoque_MensagemProduto_EditarDetalhes) ? (
        <Text type="ui-tiny-bold" style={{ width: 'fit-content' }}>
          {x}
        </Text>
      ) : (
        <Link
          type="ui-tiny-bold"
          to={`/inventory/products/${externalIdProduct}/product-messages/${row.mensagemExternalId}/messageDetail`}
          style={{ width: 'fit-content' }}
        >
          {x}
        </Link>
      ),
  },
  {
    title: t('products.productList.productMessage.exhibition'),
    dataIndex: 'exibicoes',
    key: 'exibicoes',
    width: 310,
    textWrap: 'word-break',
    render: (exibicoes: any, row: any, _index: any, rowSelected: any) => {
      const arrayConverted: string[] = convertExhibition(exibicoes);
      return <TagsRender data={arrayConverted} widthCollum={310} />;
    },
  },
];

export const convertExhibition = (array: string[]) => {
  return array.map(function (value) {
    switch (value) {
      case 'ExibeVenda':
        return `${i18n.t(
          'products.productList.productMessage.exhibitionType.displaysSale'
        )}`;
      case 'ExibeImpressaoRotulo':
        return `${i18n.t(
          'products.productList.productMessage.exhibitionType.displayLabel'
        )}`;
      case 'ExibeFichaPesagem':
        return `${i18n.t(
          'products.productList.productMessage.exhibitionType.displaysWeighingSheet'
        )}`;
      case 'ExibeRotulagem':
        return `${i18n.t(
          'products.productList.productMessage.exhibitionType.displayLabeling'
        )}`;
      default:
        return '';
    }
  });
};
