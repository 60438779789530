import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { currencyFormater } from '@/Utils/Currency';
import { InputLabel } from '@/Components/Inputs/LabelTotal';
import { IProductValues } from './interfaces';
import { useProductValuesMemorizations } from './Hooks/useProductValuesMemorizations';

export const ProductValues: FC<IProductValues> = ({
  totalProductValue,
  orderPurchase,
  totalProductValueWithoutDiscount,
}) => {
  const { t } = useTranslation();

  const { withStatusIconInLabel, withTooltip } = useProductValuesMemorizations({
    totalProductValue,
    orderPurchase,
  });

  return (
    <>
      <InputLabel
        labelTitle={t('purchasing.purchaseOrder.create.productValues')}
        withStatusIconInLabel={withStatusIconInLabel}
        withTooltip={withTooltip}
        labelValue={currencyFormater(totalProductValueWithoutDiscount)}
        labelTitleStyle={{
          width: '100%',
          display: 'flex',
          justifyContent: 'start',
        }}
      />
    </>
  );
};
