import { Dropdown, Menu } from 'antd';
import React, { FC } from 'react';
import { Button, IButton } from '../';
import { Text, Icon, Tooltip } from '../..';
import { IconName } from '../../Icon/IconClasses';
import {
  hasPermission,
  IPermissionWrapper,
  PermissionWrapper,
} from '../../PermissionWrapper';

import './ButtonDropdown.override.scss';
import style from './ButtonDropdown.module.scss';

export type IButtonDropDownItems = {
  icon?: IconName;
  label: string;
  disabled?: boolean;
  messageTooltipDisabled?: string;
  targetOffsetTooltip?: number[];
  permission?: IPermissionWrapper;
  onClick?: () => void;
  dataTestId?: string;
}[];

export interface IButtonDropdown extends IButton {
  dropdownTitle?: string;
  dangerStatus?: boolean;
  dropdownItens?: IButtonDropDownItems;
  dropdownDirection?:
    | 'topRight'
    | 'topLeft'
    | 'topCenter'
    | 'bottomRight'
    | 'bottomLeft'
    | 'bottomCenter';
}

export const ButtonDropdown: FC<IButtonDropdown> = ({
  dropdownTitle,
  dropdownItens,
  dropdownDirection,
  type,
  htmlType,
  disabled,
  leftIcon,
  rightIcon,
  badge,
  children,
  onClick,
  onMouseEnter,
  onMouseLeave,
  loading,
  className,
  dangerStatus,
  dataTestId,
}) => {
  const newResource = (
    <Menu className={style['divDropdown']}>
      {dropdownTitle && (
        <div className={style['title']}>
          <Text type="small-text" color="text-300">
            {dropdownTitle}
          </Text>
        </div>
      )}
      {dropdownItens &&
        dropdownItens.map((link, index) => (
          <PermissionWrapper
            permission={link.permission?.permission}
            tooltip={link.permission?.tooltip || 'noPermission.default'}
            style={{ display: 'flex' }}
            key={index}
          >
            {link.disabled ? (
              <Tooltip
                title={link.messageTooltipDisabled}
                showMe={!!link.messageTooltipDisabled}
                placement="topRight"
                targetOffset={link.targetOffsetTooltip}
              >
                <Menu.Item
                  className={style['dropdownLabel']}
                  onClick={link.onClick}
                  key={index}
                  disabled={
                    !hasPermission(link.permission?.permission) || link.disabled
                  }
                  data-testid={`${dataTestId || 'table-edit'}-item-${
                    link.label
                  }`}
                >
                  {link.icon ? (
                    <Icon
                      className={style['icon']}
                      icon={link.icon}
                      color="text-300"
                    />
                  ) : null}
                  <Text type="ui-tiny-content" color="text-50">
                    {link.label}
                  </Text>
                </Menu.Item>
              </Tooltip>
            ) : (
              <Menu.Item
                className={style['dropdownLabel']}
                onClick={link.onClick}
                key={index}
                disabled={!hasPermission(link.permission?.permission)}
                data-testid={link.dataTestId}
              >
                {link.icon ? (
                  <Icon
                    className={style['icon']}
                    icon={link.icon}
                    color={dangerStatus ? 'status-danger-base' : 'text-300'}
                  />
                ) : null}
                <Text
                  type="ui-tiny-content"
                  color={dangerStatus ? 'status-danger-base' : 'text-50'}
                >
                  {link.label}
                </Text>
              </Menu.Item>
            )}
          </PermissionWrapper>
        ))}
    </Menu>
  );
  return (
    <div className={className}>
      <Dropdown
        disabled={disabled}
        dropdownRender={() => newResource}
        placement={dropdownDirection}
        trigger={['click']}
      >
        <Button
          type={type}
          htmlType={htmlType}
          disabled={disabled}
          leftIcon={leftIcon && leftIcon}
          rightIcon={rightIcon && rightIcon}
          badge={badge && badge}
          onClick={onClick}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
          loading={loading}
          dataTestId={dataTestId}
        >
          {children}
        </Button>
      </Dropdown>
    </div>
  );
};
