
import { EditSubGroup } from '@/Pages/Sectors/Inventory/Products/SubGroups/Edit';
import { List } from '@/Pages/Sectors/Inventory/Products/SubGroups/List';
import { Route, Switch } from 'react-router-dom';

export const SubGroupRoutes = () => (
  <Switch>
    <Route
      path="/inventory/sub-groups/:externalId/:tab"
      component={EditSubGroup}
    />
    <Route path="/inventory/sub-groups" component={List} />
  </Switch>
);
