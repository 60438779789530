import {
  IProductsOfGroupListData,
  IProductsOfSubGroupListData,
} from '@/Data/Interfaces/response/Product/IProductRespose';
import { useCallback } from 'react';
import { IUseFunctions } from './interfaces';
import { useQueryClient } from '@tanstack/react-query';
import { handleModalError } from '@/Utils/ErrorCodeUtils/ErrorHandlingUtils';
import { FETCH_LIST_PRODUCTS_SUBGROUP } from '@/ControllerApiHook/UniqIds/Supply/ProductKeys';
import { App } from 'antd';
import { IChangeProductStatusRequest } from '@/Data/Interfaces/request/Product/IProductRequest';

export const useProductFormFunctions = ({
  deleteProduct,
  setSelectedRowsOnTable,
  editProductStatus,
}: IUseFunctions) => {
  const queryClient = useQueryClient();
  const app = App.useApp();

  const patchProductStatus = useCallback(
    async (values: IChangeProductStatusRequest) => {
      var body: IChangeProductStatusRequest = { ...values };

      const res = await editProductStatus.mutateAsync({
        ...body,
      });
      if (res.success)
        queryClient.invalidateQueries([FETCH_LIST_PRODUCTS_SUBGROUP]);
    },
    [editProductStatus, queryClient]
  );

  const onEdit = useCallback(
    (user: IProductsOfGroupListData[], status: any) =>
      patchProductStatus({
        produtosExternalId: user.map((x) => x.externalId),
        ativo: status.status === 'success' ? true : false,
      }),
    [patchProductStatus]
  );

  const onDelete = useCallback(
    async (product: IProductsOfSubGroupListData[]) => {
      try {
        const res = await deleteProduct.mutateAsync(
          {
            produtosExternalId: product.map((x) => x?.externalId),
          },
          {
            onError: handleModalError(app),
          }
        );
        if (res.success)
          queryClient.invalidateQueries([FETCH_LIST_PRODUCTS_SUBGROUP]);
      } catch {}
    },
    [deleteProduct]
  );

  const onSelectedRowsOnTable = useCallback(
    (x: IProductsOfSubGroupListData[]) => setSelectedRowsOnTable(x),
    [setSelectedRowsOnTable]
  );

  return {
    onEdit,
    onDelete,
    onSelectedRowsOnTable,
    patchProductStatus,
  };
};
