import i18n from 'i18next';
import { Icon, Tooltip } from '@/Components';
import { ListTooltip } from '../components/ListTooltip';
import { currencyFormater } from '@/Utils/Currency';
import DateUtils from '@/Utils/DateUtils';

export const headerPurchaseOrderModalTable = {
  hasSearch: true,
  searchPlaceholder: i18n.t('purchasing.invoice.create.searchForPurchase'),
};

export const purchaseOrderModalTableColumns: any = (
  preferences: any,
  t: any
) => [
  {
    title: i18n.t('purchasing.invoice.create.requestNumber'),
    dataIndex: 'sequenciaGroupTenant',
    sortColumn: 'sequenciaGroupTenant',
    defaultSortOrder: 'ascend',
    key: '1',
    render: (text: string, purchase: any) => (
      <div style={{ display: 'flex', alignItems: 'center', gap: '6px' }}>
        <strong>#{text}</strong>
        <Tooltip
          showMe={true}
          title={<ListTooltip hasTotal={false} purchaseOrder={purchase} />}
        >
          <Icon color="status-disabled-base" icon="exclamation-upside-down" />
        </Tooltip>
      </div>
    ),
    sorter: (a: any, b: any) => {
      return a.sequenciaGroupTenant - b.sequenciaGroupTenant;
    },
  },
  {
    title: i18n.t('purchasing.invoice.create.releaseDate'),
    dataIndex: 'dataLancamento',
    key: '2',
    render: (text: string) => (
      <div style={{ display: 'flex', alignItems: 'center', gap: '6px' }}>
        {`${DateUtils.convertDate(text, preferences?.padraoData)} ${t(
          'common.dateBreakApart'
        )} ${DateUtils.convertDate(text, preferences?.padraoHora)}`}
      </div>
    ),
  },
  {
    title: i18n.t('purchasing.invoice.create.deliveryForecast'),
    dataIndex: 'previsaoEntrega',
    key: '3',
    render: (text: string) => (
      <div style={{ display: 'flex', alignItems: 'center', gap: '6px' }}>
        {DateUtils.convertDate(text)}
      </div>
    ),
  },
  {
    title: i18n.t('purchasing.invoice.create.totalValuePurchaseOrder'),
    dataIndex: 'totalPedido',
    key: '4',
    render: (text: string) => (
      <div style={{ display: 'flex', alignItems: 'center', gap: '6px' }}>
        {currencyFormater(+text)}
      </div>
    ),
  },
];
