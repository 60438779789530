import { IProductListData } from '@/Data/Interfaces/response/Product/IProductRespose';
import { useState } from 'react';

export const useListStates = () => {
  const [selectedRowsOnTable, setSelectedRowsOnTable] = useState<
    IProductListData[]
  >([]);

  return {
    selectedRowsOnTable,
    setSelectedRowsOnTable,
  };
};
