import { FC } from 'react';
import { Footer as ModalFooter } from '@/Components/Modal/Components/Footer';
import {
  IGlobalModal
} from '@/Utils/ErrorCodeUtils/ErrorHandlingUtils';
import { useTranslation } from 'react-i18next';
import { history } from '@/Store';

export const Footer: FC<{
  params: { numero: string[] };
  instance: IGlobalModal;
}> = ({ instance }) => {
  const { t } = useTranslation();
  
  return (
    <ModalFooter
      okButtonName={t('purchasing.purchaseOrder.modal.cannotDelete.ok')}
      onOkClick={() => {
        history.push(`/order/purchase`);
        instance.destroy();
      }}
      withoutOkCancel={true}
    />
  );
};