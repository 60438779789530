import { FC } from "react";
import { useTranslation } from 'react-i18next';
import { Tooltip } from '@/Components';
import DateUtils from '@/Utils/DateUtils';
import { TextInputWithMask } from '@/Components/Inputs/TextInputWithMask';
import { Col } from 'antd';
import { useFormikContext } from 'formik';
import { IExpirationDate } from "./interfaces";

export const ExpirationDate: FC<IExpirationDate> = ({
  lots,
  disabled
}) => {
  const { t } = useTranslation();
  const form: any = useFormikContext();
  const datePattern = DateUtils.getCurrentDatePattern();

  return (
    <Col span={4}>
      <Tooltip
        title={`${t(
          'supply.lot.details.toolTips.originalExpirationDate'
        )} ${
          lots &&
          DateUtils.format(lots.dataValidade, datePattern || 'dd/MM/yyyy')
        }`}
        showMe={
          lots &&
          DateUtils.format(
            lots.dataValidade,
            datePattern || 'dd/MM/yyyy'
          ) !== form?.values?.dataValidade &&
          !disabled
        }
      >
        <TextInputWithMask
          name="dataValidade"
          label={t('supply.lot.details.details.validity')}
          placeHolder={t('supply.lot.details.details.validity')}
          disabled={disabled}
          leftIcon="calendar"
          withStatusIcon
          required={!disabled}
          mask="dataMask"
        />
      </Tooltip>
    </Col>
  );
};