import React from 'react';
import { Link } from '../../../../../../Components';
import { LinkButton } from '../../../../../../Components/Layouts/LinkButton';

export const groupCollums = (t: any) => [
  {
    title: t('products.groupList.tableTitle.description'),
    dataIndex: 'descricao',
    key: 'descricao',
    isHighlightable: true,
    width: '50%',
    sortColumn: 'descricao',
    defaultSortOrder: 'ascend',
    sorter: true,
    render: (x: any, row: any) => (
      <Link
        type="ui-tiny-bold"
        to={`/inventory/groups/${row.externalId}/details`}
        style={{ width: 'fit-content' }}
      >
        {x}
      </Link>
    ),
  },
  {
    title: t('products.groupList.tableTitle.subGroups'),
    dataIndex: 'subGrupos',
    key: 'subGrupos',
    render: (x: any, value: any) => (
      <LinkButton
        link={`/inventory/groups/${value.externalId}/subgroups`}
        text={
          x !== 1
            ? `${x} ${t('products.common.subGroups')}`
            : `${x} ${t('products.common.subGroup')}`
        }
      />
    ),
  },
  {
    title: t('products.groupList.tableTitle.products'),
    dataIndex: 'produtos',
    key: 'produtos',
    render: (x: any, value: any) => {
      return (
        <LinkButton
          link={`/inventory/groups/${value.externalId}/products`}
          text={
            x === 1
              ? `${x} ${t('products.common.product')}`
              : `${x} ${t('products.common.products')}`
          }
        />
      );
    },
  },
];
