import { IEditSpecialtieRequest } from "@/Data/Interfaces/request/Specialties/ISpecialtiesRequest";
import { useCallback } from "react";
import { IUseFunctions } from "./interfaces";
import { IFChildren } from "@/Components/Form";
import { history } from '@/Store';

export const useDetailsFunctions = ({
  editSpecialtie,
  setIsBackModalOpen,
  toogleDisabled,
  disabledForm
}: IUseFunctions) => {
  const onSubmit = useCallback(async (values: IEditSpecialtieRequest) =>
    await editSpecialtie.mutateAsync(values)
  , [editSpecialtie]);

  const onClick = useCallback((props: IFChildren) =>
    !!props?.dirty
      ? setIsBackModalOpen(true)
      : toogleDisabled(!disabledForm)
  , [setIsBackModalOpen, toogleDisabled, disabledForm]);

  const onOkClick = () => history.push('/sales/expertise');

  const onCancelClick = () => setIsBackModalOpen(false);

  const onClose = () => setIsBackModalOpen(false);

  return {
    onSubmit,
    onClick,
    onOkClick,
    onCancelClick,
    onClose
  };
};