import { IPutUserRequest } from '@/Data/Interfaces/request/User/IUserRequest';
import { RemoveMask } from '@/Utils/MasksUtils';
import { IUseUserDetailsFunctions } from './interfaces';
import { FormikHelpers, FormikValues } from 'formik';

export const useUserDetailsFunctions = ({
  datePattern,
  editUser,
}: IUseUserDetailsFunctions) => {
  const onSubmit = async (
    values: IPutUserRequest,
    formikHelpers: FormikHelpers<FormikValues>
  ) => {
    try {
      const body: IPutUserRequest = { ...values };

      body.dataNascimento = RemoveMask(body.dataNascimento, datePattern);

      await editUser.mutateAsync(body);
    } finally {
      formikHelpers.setSubmitting(false);
    }
  };
  return { onSubmit };
};
