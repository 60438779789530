import { FC, useState } from 'react';
import { Button } from '@/Components';
import { AddProductModal } from './Modals/AddProductModal';
import { useTranslation } from 'react-i18next';
import { HistoricModal } from './Modals/Historic/HistoricModal';
import { v4 as uuidV4 } from 'uuid';
import { AddManipulatedRecipeModal } from './Modals/ManipulatedRecipe/index';
import { PermissionWrapper } from '@/Components/PermissionWrapper';
import { PermissionEnum } from '@/Data/Interfaces/model/permissionEnum';
import { Divflex } from '@/Layouts/DivWhithFlex';

import styles from './TopButtons.module.scss';

interface ITopButtons {
  saleOrderExternalId?: string;
  customerDiscount: number;
  clientExternalId?: string;
}

export const TopButtons: FC<ITopButtons> = ({
  saleOrderExternalId,
  customerDiscount,
  clientExternalId,
}) => {
  const [productModalOpen, setProductModalOpen] = useState(false);
  const [manipulatedRecipeModalOpen, setManipulatedRecipModalOpen] =
    useState(false);
  const [historicModalOpen, setHistoricModalOpen] = useState(false);

  const { t } = useTranslation();

  return (
    <div className={styles['container']}>
      <Divflex>
        <PermissionWrapper
          permission={PermissionEnum.Vendas_PedidosVenda_EditarDetalhes}
          tooltip={t('noPermission.sale.order.editOrder')}
        >
          <Button
            className={styles['first-button']}
            type="secondary"
            children={t('saleOrder.editOrder.topButtons.finishedProduct')}
            leftIcon="box-add-alternative"
            onClick={() => setProductModalOpen(true)}
            dataTestId="finished-product-test-id"
          />
        </PermissionWrapper>
        <PermissionWrapper
          permission={PermissionEnum.Vendas_PedidosVenda_EditarDetalhes}
          tooltip={t('noPermission.sale.order.editOrder')}
        >
          <Button
            type="secondary"
            children={t('saleOrder.editOrder.topButtons.manipulatedRecipe')}
            leftIcon="gral-add"
            onClick={() => setManipulatedRecipModalOpen(true)}
          />
        </PermissionWrapper>
      </Divflex>
      <Button
        type="secondary"
        children={t('saleOrder.editOrder.topButtons.historic')}
        leftIcon="backup"
        onClick={() => setHistoricModalOpen(true)}
      />

      <AddProductModal
        changeVisibleModal={(x) => setProductModalOpen(x)}
        visibleModal={productModalOpen}
        initialValues={{
          pedidoVendaExternalId: saleOrderExternalId,
          ...(customerDiscount > 0 && {
            desconto: customerDiscount,
            tipoDesconto: 1,
          }),
        }}
      />

      <AddManipulatedRecipeModal
        changeVisibleModal={(x: boolean) => setManipulatedRecipModalOpen(x)}
        visibleModal={manipulatedRecipeModalOpen}
        initialValues={{
          clienteExternalId: clientExternalId,
          itens: [{ id: uuidV4() }],
        }}
        countRecipe={1}
      />

      <HistoricModal
        changeVisibleModal={(x) => setHistoricModalOpen(x)}
        visibleModal={historicModalOpen}
        saleOrderExternalId={saleOrderExternalId}
      />
    </div>
  );
};
