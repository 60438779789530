import { Col, Row } from 'antd';
import React from 'react';
import { Dropdown, TextInput } from '@/Components';
import { useTranslation } from 'react-i18next';
import { FETCH_LIST_DROPDOWN_FREIGHT_TYPE } from '@/ControllerApiHook/UniqIds/Purchase/PurchaseKeys';
import { useControllerQueryListApiHook } from '@/ControllerApiHook/Controller';
import { PurchaseAPI } from '@/Data/API/Purchase/PurchaseApi';

const FourthRow: React.FC = () => {
  const { t } = useTranslation();
  const { data: freightTypeList } = useControllerQueryListApiHook({
    uniqId: FETCH_LIST_DROPDOWN_FREIGHT_TYPE,
    entityApi: PurchaseAPI.listDropdownFreightType,
    autoIncrement: true,
  });

  const freightTypeListDropDown = freightTypeList?.data
    ? freightTypeList.data.map((e: any, i: number) => ({
        id: e.id,
        label: `${i} - ${e.descricao}`,
      }))
    : [];

  return (
    <Row gutter={[16, 0]}>
      <Col span={6}>
        <TextInput
          name="transportadoraDescricao"
          placeHolder={t(
            'purchasing.invoice.create.placeHolder.searchForCarrier'
          )}
          disabled
          label={t('purchasing.invoice.create.carrierCode')}
        />
      </Col>
      <Col span={6}>
        <Dropdown
          name="tipoFreteId"
          items={freightTypeListDropDown}
          label={t('purchasing.invoice.create.frightType')}
          disabled
        />
      </Col>
    </Row>
  );
};

export default FourthRow;
