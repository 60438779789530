import { useCallback } from 'react';
import { handleModalError } from '@/Utils/ErrorCodeUtils/ErrorHandlingUtils';
import { App } from 'antd';
import { useTranslation } from 'react-i18next';
import { IUseFunctions } from './interfaces';
import { cannotDeletePharmaceuticalFormWithMovementsInTheSystemProps } from '@/Utils/ErrorCodeUtils/ErrorModalUtils/ErrorModalPharmaceuticalForm';
import { IPosologyListData } from '@/Data/Interfaces/response/Posology/IPosologyResponse';
import { IPutPosologyStatusRequest } from '@/Data/Interfaces/request/Posology/IPosologyRequest';

export const useListFunctions = ({
  setSelectedRowsOnTable,
  editStatusPosology,
  deletePosology,
}: IUseFunctions) => {
  const { t } = useTranslation();
  const app = App.useApp();

  const onSelectedRowsOnTable = useCallback(
    (x: IPosologyListData[]) => setSelectedRowsOnTable(x),
    []
  );

  const changeStatusPosology = useCallback(
    async (values: IPutPosologyStatusRequest) => {
      var body: IPutPosologyStatusRequest = { ...values };
      await editStatusPosology.mutateAsync({
        ...body,
      });
    },
    [editStatusPosology]
  );

  const onEdit = useCallback(
    (posology: IPosologyListData[], values: any) =>
      changeStatusPosology({
        externalId: posology.map((x) => x.externalId),
        ativo: values.status === 'success',
      }),
    [changeStatusPosology]
  );

  const onDelete = useCallback(
    async (posology: IPosologyListData[]) => {
      await deletePosology.mutateAsync(
        {
          externalId: posology.map((x) => x?.externalId),
        },
        {
          onError: handleModalError(
            app,
            cannotDeletePharmaceuticalFormWithMovementsInTheSystemProps(t)
          ),
        }
      );
    },
    [deletePosology, app, t]
  );

  return {
    onSelectedRowsOnTable,
    onEdit,
    onDelete,
  };
};
