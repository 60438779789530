import { useQueryClient } from '@tanstack/react-query';
import { history } from '@/Store';
import {
  FETCH_LIST_PURCHASE_ORDER
} from '@/ControllerApiHook/UniqIds/Purchase/PurchaseOrderKeys';
import { IUseFunctions } from './interfaces';

export const useDeleteDraftModalFunctions = ({
  deleteDraftOrderPurchase,
  orderPurchase,
  changeVisibleModal
}: IUseFunctions) => {
  const queryClient = useQueryClient();

  const onOkClick = async () => {
    const res = await deleteDraftOrderPurchase.mutateAsync({
      pedidoCompraExternalId: [orderPurchase?.externalId] as any,
    });
    if (res.success) {
      queryClient.invalidateQueries([FETCH_LIST_PURCHASE_ORDER]);
      history.push(`/order/purchase`);
    }
  };

  const onCancelClick = () => changeVisibleModal(false);

  const onClose = () => changeVisibleModal(false);

  return {
    onOkClick,
    onCancelClick,
    onClose
  };
};