import { QueryFunctionContext } from '@tanstack/react-query';
import { Http, IQueryListParams } from '@/Utils/Http';
import { IListCapsuleTypeResponse } from '@/Data/Interfaces/response/CapsuleType/ICapsuleTypeResponse';

export class CapsuleTypeAPI {
  public static async listDropdownCapsuleType(
    context: QueryFunctionContext
  ): Promise<IListCapsuleTypeResponse | null> {
    if (!context.queryKey[1]) return null;

    return await Http.fetcher<IListCapsuleTypeResponse>(
      Http.AddQueryParams(
        `/pharma/producao/v1/TipoCapsula/Listar`,
        context.queryKey[1] as IQueryListParams
      )
    );
  }
}
