import { useCallback } from "react";
import { DilutionRatio } from '@/Utils/DilurionRatio';
import { useFormikContext } from "formik";

export const useDilutionFunctions = () => {
  const form: any = useFormikContext();

  const handlerDilution = useCallback((event: any) => {
    const valueNumberEntered = parseFloat(event.replace(/[^0-9.]/g, ''));
    if (valueNumberEntered) {
      const reason: string = DilutionRatio(valueNumberEntered);
      form.setFieldValue('razao', reason);
    } else {
      form.setFieldValue('razao', null);
    }
    return;
  }, [form.setFieldValue]);

  const onChange = (value: any) => handlerDilution(value);

  return { onChange };
};