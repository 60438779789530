import { useCallback } from 'react';
import { handleModalError } from '@/Utils/ErrorCodeUtils/ErrorHandlingUtils';
import { App } from 'antd';
import { useTranslation } from 'react-i18next';
import { IUseFunctions } from './interfaces';
import { IPharmaceuticalFormData } from '@/Data/Interfaces/response/PharmaceuticalForm/IPharmaceuticalFormResponse';
import { IPatchPharmaceuticalFormStatusRequest } from '@/Data/Interfaces/request/PharmaceuticalForm/IPharmaceuticalFormRequest';
import { cannotDeletePharmaceuticalFormWithMovementsInTheSystemProps } from '@/Utils/ErrorCodeUtils/ErrorModalUtils/ErrorModalPharmaceuticalForm';

export const useListFunctions = ({
  setSelectedRowsOnTable,
  editPharmaceuticalFormStatus,
  deletePharmaceuticalForm,
}: IUseFunctions) => {
  const { t } = useTranslation();
  const app = App.useApp();

  const onSelectedRowsOnTable = useCallback(
    (x: IPharmaceuticalFormData[]) => setSelectedRowsOnTable(x),
    []
  );

  const changePharmaceuticalFormStatus = useCallback(
    async (values: IPatchPharmaceuticalFormStatusRequest) => {
      var body: IPatchPharmaceuticalFormStatusRequest = { ...values };
      await editPharmaceuticalFormStatus.mutateAsync({
        ...body,
      });
    },
    [editPharmaceuticalFormStatus]
  );

  const onEdit = useCallback(
    (pharmaceuticalForm: IPharmaceuticalFormData[], values: any) =>
      changePharmaceuticalFormStatus({
        externalId: pharmaceuticalForm.map(
          (x: IPharmaceuticalFormData) => x.externalId
        ),
        ativo: values.status === 'success',
      }),
    [changePharmaceuticalFormStatus]
  );

  const onDelete = useCallback(
    async (pharmaceuticalForm: IPharmaceuticalFormData[]) => {
      await deletePharmaceuticalForm.mutateAsync(
        {
          externalId: pharmaceuticalForm.map((x) => x?.externalId),
        },
        {
          onError: handleModalError(
            app,
            cannotDeletePharmaceuticalFormWithMovementsInTheSystemProps(t)
          ),
        }
      );
    },
    [deletePharmaceuticalForm, app, t]
  );

  return {
    onSelectedRowsOnTable,
    onEdit,
    onDelete,
  };
};
