import { Icon, Link, Status, Text, i18n } from '../../../../../../../Components';
import { IListPrescribersData } from '../../../../../../../Data/Interfaces/response/Prescribers/IPrescribersResponse';
import { Divflex } from '../../../../../../../Layouts/DivWhithFlex';
import { UppercaseFirstLetter } from '../../../../../../../Utils/CapitalFirstLetterUtils';
import { TagsRender } from '../../../../../../../Utils/TagsUtils/TagsUtils';

export const prescribersCollums = (t: any) => [
  {
    title: t('prescribers.listPage.prescriber'),
    dataIndex: 'nomeCompleto',
    key: 'nomeCompleto',
    sortColumn: 'nomeCompleto',
    sorter: true,
    defaultSortOrder: 'ascend',
    isHighlightable: true,
    width: '30%',
    render: (x: string, row: IListPrescribersData) => (
      <Link
        type="ui-tiny-bold"
        to={`/sales/prescribers/${row?.externalId}/details`}
        style={{ width: 'fit-content' }}
      >
        {UppercaseFirstLetter(x)}
      </Link>
    ),
  },
  {
    title: t('prescribers.listPage.record'),
    dataIndex: 'tipoRegistroSigla',
    key: 'tipoRegistroSigla',
    width: '18%',
    render: (x: string, row: IListPrescribersData) => (
      <Text
        type="ui-tiny-content"
        color="text-50"
        children={`${x}/${
          row.abreviacaoUfRegistro
        } ${row.codigoRegistro.toLocaleString('pt-BR')}`}
      />
    ),
  },
  {
    title: t('prescribers.listPage.mainContact'),
    dataIndex: 'contatoPrincipal',
    key: 'contatoPrincipal',
    width: '18%',
    render: (x: string, row: IListPrescribersData) => (
      <Divflex>
        <Icon
          icon={row.tipoContatoIcon}
          size="SM"
          style={{ marginRight: 15 }}
          color="text-50"
        />
        <Text type="ui-tiny-content" color="text-50" children={x} />
      </Divflex>
    ),
  },
  {
    title: t('prescribers.listPage.specialties'),
    dataIndex: 'especialidades',
    key: 'especialidades',
    width: '22%',
    render: (especialties: any, row: any, _index: any, rowSelected: any) => {
      return (
        <TagsRender
          data={especialties}
          widthCollum={280}
          rowSelected={rowSelected?.some(
            (rowSelectedCurrent: IListPrescribersData) =>
              row.externalId === rowSelectedCurrent.externalId
          )}
        />
      );
    },
  },
  {
    title: 'Status',
    dataIndex: 'ativo',
    key: 'ativo',
    sortColumn: 'ativo',
    sorter: true,
    width: '12%',
    render: (text: any) => (
      <Status type={text ? 'success' : 'disabled'}>
        {i18n.t(`common.${text ? 'activate' : 'inactivated'}`)}
      </Status>
    ),
  },
];
