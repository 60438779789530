import { IEditPharmaceuticalForm } from '@/Data/Interfaces/request/PharmaceuticalForm/IPharmaceuticalFormRequest';
import { IUseFunctions } from './interfaces';
import { FormikHelpers, FormikValues } from 'formik';
import { useCallback } from 'react';
import { handleModalError } from '@/Utils/ErrorCodeUtils/ErrorHandlingUtils';
import { App } from 'antd';
import { cannotDeletePharmaceuticalFormWithMovementsInTheSystemProps } from '@/Utils/ErrorCodeUtils/ErrorModalUtils/ErrorModalPharmaceuticalForm';
import { useTranslation } from 'react-i18next';

export const useEditFunctions = ({
  editPharmaceuticalForm,
  pharmaceuticalForm,
  changeVisibleModal,
  deletePharmaceuticalForm,
}: IUseFunctions) => {
  const { t } = useTranslation();
  const app = App.useApp();

  const onSubmit = async (
    values: IEditPharmaceuticalForm,
    helpers: FormikHelpers<FormikValues>
  ) => {
    var body: IEditPharmaceuticalForm = { ...values, helpers };

    if (pharmaceuticalForm) {
      let costOperational: any;
      costOperational = body.custoOperacional;

      return await editPharmaceuticalForm({
        formaFarmaceuticaExternalId: pharmaceuticalForm.externalId,
        ordem: body.ordem,
        descricao: body.descricao,
        apresentacao: body.apresentacao,
        usoFormaFarmaceutica: body.usoFormaFarmaceutica,
        tipoCalculo: body.tipoCalculo,
        unidadeMedidaId: body.unidadeMedidaId,
        percentualMinimoExcipiente: body.percentualMinimoExcipiente,
        validadeDias: body.validadeDias,
        laboratorioExternalId: body.laboratorioExternalId,
        custoOperacional: costOperational,
        ativo: body.ativo,
        helpers: body.helpers,
      });
    }
  };

  const onDelete = useCallback(async () => {
    try {
      if (!pharmaceuticalForm) return;
      return await deletePharmaceuticalForm(
        { externalId: [pharmaceuticalForm.externalId] },
        {
          onError: handleModalError(
            app,
            cannotDeletePharmaceuticalFormWithMovementsInTheSystemProps(t)
          ),
        }
      );
    } catch {
      changeVisibleModal && changeVisibleModal(false);
    }
  }, [deletePharmaceuticalForm, pharmaceuticalForm, app]);
  return {
    onSubmit,
    onDelete,
  };
};
