import { useState } from "react";
import { IUseStates } from "./interfaces";

export const useAssociatedProductDetailsStates = ({
  productExternalId
}: IUseStates) => {
  const [accumulate, setAccumulate] = useState<boolean>();
  
  const [
    associatedProductExternalId, 
    setAssociatedProductExternalId
  ] = useState<string>(productExternalId);

  return {
    accumulate,
    setAccumulate,
    associatedProductExternalId,
    setAssociatedProductExternalId
  };
};