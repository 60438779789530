import { IGetLotResponse } from "@/Data/Interfaces/response/Lot/IListLot";
import { IListAlternativeUnitMeasurementResponse } from "@/Data/Interfaces/response/UnitMeasurement/IUnitMeasurementResponse";
import { Row } from "antd";
import { FC } from "react";
import { AlternateUnit } from "./Components/AlternateUnit";
import { AlternateUnit2 } from "./Components/AlternateUnit2";

import styles from "./AlternateUnitInfo.module.scss";

interface IAlternateUnitInfo {
  lots: IGetLotResponse | undefined;
  disabled: boolean;
  unidadeAlternativaList: IListAlternativeUnitMeasurementResponse | undefined;
};

export const AlternateUnitInfo: FC<IAlternateUnitInfo> = ({
  lots,
  disabled,
  unidadeAlternativaList
}) => {
  return (
    <div className={styles["container"]}>
      <Row gutter={[16, 0]} style={{ marginTop: '20px' }}>
        <AlternateUnit
          lots={lots}
          disabled={disabled}
          unidadeAlternativaList={unidadeAlternativaList}
        />
        <AlternateUnit2
          lots={lots}
          disabled={disabled}
          unidadeAlternativaList={unidadeAlternativaList}
        />
      </Row>
    </div>
  );
};