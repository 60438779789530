import { FC } from 'react';
import { BodyModal } from '@/Components/Modal';
import { ICommonError } from '@/Utils/ErrorCodeUtils/ErrorHandlingUtils';
import { Text } from '@/Components';
import { useTranslation } from 'react-i18next';
import { DeleteModalOnTableNotFooter } from '@/Utils/DeleteModalUtils';
import { ReactMarkdown } from 'react-markdown/lib/react-markdown';

import styles from './Content.module.scss';

export const Content: FC<ICommonError> = ({ content }) => {
  const { t } = useTranslation();

  return content?.length > 1 ? (
    <>
      <BodyModal
        body={
          <>
            <div className={styles['container']}>
              {DeleteModalOnTableNotFooter(
                content,
                `${t('supply.group.modal.cannotDelete.batchSubTitle')}`,
                'grupoDescricao',
                ''
              )}
              <Text type="ui-tiny-content">
                <ReactMarkdown>
                  {t('supply.group.modal.cannotDelete.batchDescription')}
                </ReactMarkdown>
              </Text>
            </div>
          </>
        }
        style={{ padding: '13px 27px' }}
      />
    </>
  ) : (
    <>
      <BodyModal
        body={
          <div className={styles['container']}>
            <Text type="ui-tiny-content">
              <ReactMarkdown>
                {t('supply.group.modal.cannotDelete.subTitle', {
                  group: `**${content[0].grupoDescricao}**`,
                })}
              </ReactMarkdown>
            </Text>
            <Text type="ui-tiny-content">
              <ReactMarkdown>
                {t('supply.group.modal.cannotDelete.description', {
                  products: content[0].produtoCount,
                  products_plural: content[0].produtoCount !== 1 ? 's' : '',
                  subgroups: content[0].subGrupoCount,
                  subgroups_plural: content[0].subGrupoCount !== 1 ? 's' : '',
                })}
              </ReactMarkdown>
            </Text>
          </div>
        }
        style={{ padding: 20 }}
      />
    </>
  );
};
