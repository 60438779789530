import { CapsuleColorsRoutes } from './CapsuleColors';
import { DosageFormRoutes } from './DosageForm';
import { LabsRoutes } from './Labs';
import { PackingClassificationRoutes } from './PackingClassification';
import { PosologyRoutes } from './Posology';
import { StandardFormulaRoutes } from './StandardFormula';

export const ProductionRoutes = () => (
  <>
    <LabsRoutes />
    <StandardFormulaRoutes />
    <DosageFormRoutes />
    <PosologyRoutes />
    <PackingClassificationRoutes />
    <CapsuleColorsRoutes />
  </>
);
