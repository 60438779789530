import { Text } from '@/Components';
import { FC } from 'react';
import { TFunction } from 'react-i18next';
import ReactMarkdown from 'react-markdown';

import styles from './ModalBody.module.scss';

interface IModalBody {
  name: string;
  lastName: string;

  t: TFunction<'translation', undefined>;
}

export const ModalBody: FC<IModalBody> = ({ lastName, name, t }) => (
  <div className={styles['content']}>
    <Text type="ui-tiny-content" withoutMarginBottom color="text-50">
      <ReactMarkdown>
        {t('admin.editUserPage.modal.deleteUserModal.initialDescription', {
          user: `**${name} ${lastName}**?`,
        })}
      </ReactMarkdown>
    </Text>
    <Text
      type="paragraph2"
      children={t('admin.editUserPage.modal.deleteUserModal.finalDescription')}
      color="text-50"
    />
  </div>
);
