import { useCallback } from 'react';
import { useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';
import { IUseFunctions } from './interfaces';

export const useProductMessageDescriptionFunctions = ({
  setHasErrorAlreadyExistsProductName
}: IUseFunctions) => {
  const form: any = useFormikContext();
  const { t } = useTranslation();

  const onProductMessageError = useCallback(() => {
    form?.setErrors({
      ...form?.errors,
      descricao: t('supply.productMessage.create.erros.errorMessageNameExist'),
    });
    setHasErrorAlreadyExistsProductName(true);
  }, [setHasErrorAlreadyExistsProductName, form]);

  return {
    onProductMessageError
  };
};