import { FC } from "react";
import { Dropdown, Tooltip } from '@/Components';
import { Col } from 'antd';
import { useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';
import { ICapsuleNumber } from "./interfaces";
import { useCapsuleNumberCallAPI } from "./Hooks/useCapsuleNumberCallAPI";
import { useCapsuleNumberMemorizations } from "./Hooks/useCapsuleNumberMemorizations";

export const CapsuleNumber: FC<ICapsuleNumber> = ({
  product,
  disabledForm
}) => {
  const { t } = useTranslation();
  const form: any = useFormikContext();

  const {
    listCapsuleSize,
    isLoadingCapsuleSize,
    fetchNewPageCapsuleSize
  } = useCapsuleNumberCallAPI();

  const {
    title,
    showMe,
    items
  } = useCapsuleNumberMemorizations({
    product,
    disabledForm,
    listCapsuleSize
  })

  return (
    <Col span={4}>
      <Tooltip
        title={title}
        showMe={showMe}
      >
        <Dropdown
          name="capsulaTamanhoId"
          label={t('products.create.capsuleNumber')}
          placeHolder={t('products.create.capsuleNumberPlaceholder')}
          isLoading={isLoadingCapsuleSize}
          onScrollEnd={fetchNewPageCapsuleSize}
          items={items}
          disabled={disabledForm}
          withStatusIcon={!!product}
        />
      </Tooltip>
    </Col>
  );
};