import * as yup from 'yup';
import i18n from '../../../../../Components/i18n';

// Definindo o schema para o item individual separadamente
const itemSchema = yup.object().shape({
  produtoExternalId: yup
    .string()
    .required(i18n.t('purchasing.invoice.create.errors.requiredProduct')),
  ncmId: yup
    .number()
    .required(i18n.t('purchasing.invoice.create.errors.requiredNcm')),
  cstCsosnId: yup
    .number()
    .required(i18n.t('purchasing.invoice.create.errors.requiredCST')),
  cfopId: yup
    .number()
    .required(i18n.t('purchasing.invoice.create.errors.requiredCFOP')),
  valorUnitario: yup
    .number()
    .required(i18n.t('purchasing.invoice.create.errors.requiredUnitValue')),
  quantidadeComprada: yup
    .number()
    .required(i18n.t('purchasing.invoice.create.errors.requiredQuantity')),
});

export const InvoiceCreateCreateSchema = yup.object().shape({
  numero: yup
    .number()
    .required(i18n.t('purchasing.invoice.create.errors.requiredNumber')),
  serie: yup
    .number()
    .required(i18n.t('purchasing.invoice.create.errors.requiredSeries')),
  naturezaOperacaoId: yup
    .string()
    .required(
      i18n.t('purchasing.invoice.create.errors.requiredNatureOperation')
    ),
  dataEmissao: yup
    .string()
    .required(
      i18n.t('purchasing.invoice.create.errors.requiredInvoiceIssueDate')
    ),
  dataEntrega: yup
    .string()
    .required(
      i18n.t('purchasing.invoice.create.errors.requiredInvoiceDeliveryDate')
    ),
  fornecedorExternalId: yup
    .string()
    .required(i18n.t('purchasing.invoice.create.errors.requiredProvider')),
  tipoFreteId: yup
    .string()
    .required(
      i18n.t('purchasing.invoice.create.errors.requiredInvoiceFreight')
    ),
  itens: yup
    .array()
    .of(itemSchema)
    .test(
      'validate-all-but-last',
      'Invalid item in array',
      function (itens): boolean {
        if (!itens) return true;

        if (itens.length === 1) {
          // Se houver apenas um item, validá-lo normalmente
          return itemSchema.isValidSync(itens[0]);
        } else {
          // Validar todos os itens, exceto o último, se houver mais de um item
          const itemsToValidate = itens.slice(0, -1);
          return itemsToValidate.every((item) => itemSchema.isValidSync(item));
        }
      }
    ),
});
