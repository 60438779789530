import { useState } from 'react';

export const useSelectAddres = () => {
  const [openEditAddressModal, setOpenEditAddressModal] = useState(false);
  const [openCreateAddressModal, setOpenCreateAddressModal] = useState(false);
  const [selectedAddressExternalId, setSelectedAddressExternalId] =
    useState('');
  const [currentSearch, setCurrentSearch] = useState('');

  return {
    openEditAddressModal,
    setOpenEditAddressModal,

    openCreateAddressModal,
    setOpenCreateAddressModal,

    selectedAddressExternalId,
    setSelectedAddressExternalId,

    currentSearch,
    setCurrentSearch,
  };
};
