import React from 'react';

interface IFormBreakLineProps {
  marginBottom?: string;
}

const FormBreakLine: React.FC<IFormBreakLineProps> = ({
  marginBottom = '20px',
}) => {
  return (
    <div
      style={{
        borderTop: '1px solid var(--color-background-04)',
        marginBottom,
      }}
    />
  );
};

export default FormBreakLine;
