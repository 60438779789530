import { useControllerQueryApiHook } from '@/ControllerApiHook/Controller';
import { FETCH_GET_SALES_ORDER } from '@/ControllerApiHook/UniqIds/Sales/SalesOrderKeys';
import { GET_SERVICE } from '@/ControllerApiHook/UniqIds/Sales/ServiceKeys';
import { SalesOrderAPI } from '@/Data/API/Sales/SalesOrderApi';
import { ServiceAPI } from '@/Data/API/Sales/ServiceApi';
import { IGetSaleOrderResponse } from '@/Data/Interfaces/response/Sales/SalesOrder/ISalesOrderResponse';
import { IGetServiceResponse } from '@/Data/Interfaces/response/Sales/Service/IServiceResponse';
import { IUseEditCallAPI } from './iterface';

export const useEditCallAPI = ({ externalId }: IUseEditCallAPI) => {
  const { data: service, isLoading: getServiceLoading } =
    useControllerQueryApiHook<IGetServiceResponse>({
      uniqId: GET_SERVICE,
      entityApi: ServiceAPI.getService,
      externalIds: [externalId],
    });

  const { data: saleOrder } = useControllerQueryApiHook<IGetSaleOrderResponse>({
    uniqId: FETCH_GET_SALES_ORDER,
    entityApi: SalesOrderAPI.getSaleOrder,
    externalIds: [service?.pedidoVendaExternalId],
  });

  return { service, getServiceLoading, saleOrder };
};
