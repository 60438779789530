import React from 'react';
import { i18n, Link, Status, Text } from '../../../../../../Components';
import { CurrencyText } from '../../../../../../Components/CurrencyText';
import { WEIGHT_DECIMAL_AMOUNT } from '../../../../../../Utils/ConstantsUtils';

export const productCollums = (t: any) => [
  {
    title: t('products.productList.tableTitle.code'),
    dataIndex: 'sequenciaGroupTenant',
    key: 'sequenciaGroupTenant',
    width: '7%',
  },
  {
    title: t('products.productList.tableTitle.productName'),
    dataIndex: 'descricao',
    key: 'descricao',
    sortColumn: 'descricao',
    sorter: true,
    width: '25%',
    defaultSortOrder: 'ascend',
    isHighlightable: true,
    render: (x: string, row: any) => (
      <Link
        type="ui-tiny-bold"
        to={`/inventory/products/${row?.externalId}/details`}
        style={{ width: 'fit-content' }}
      >
        {x}
      </Link>
    ),
  },
  {
    title: t('products.productList.tableTitle.class'),
    dataIndex: 'classeProduto',
    key: 'classeProduto',
    isHighlightable: true,
  },
  {
    title: t('products.productList.tableTitle.group'),
    dataIndex: 'grupoDescricao',
    key: 'grupoDescricao',
    isHighlightable: true,
  },
  {
    title: t('products.productList.tableTitle.subGroup'),
    dataIndex: 'subGrupoDescricao',
    key: 'subGrupoDescricao',
    width: '13%',
    isHighlightable: true,
  },
  {
    title: t('products.productList.tableTitle.saleValue'),
    dataIndex: 'valorVenda',
    key: 'valorVenda',
    render: (x: any, row: any) => (
      <Text type={'ui-tiny-content'}>
        <CurrencyText
          value={x}
          suffix={` /${row?.unidadeMedida}`}
          decimalScale={WEIGHT_DECIMAL_AMOUNT}
        />
      </Text>
    ),
  },
  {
    title: t('products.productList.tableTitle.status'),
    dataIndex: 'ativo',
    key: 'ativo',
    render: (text: any) => (
      <Status type={text ? 'success' : 'disabled'}>
        {i18n.t(`userGroups.list.statusTypes.${text ? 'active' : 'inactive'}`)}
      </Status>
    ),
  },
];
