import { useTranslation } from 'react-i18next';
import { IUseMemorizations } from './interfaces';
import { IProductGroupListData } from '@/Data/Interfaces/response/Product/IProductRespose';

export const useProductMemorizations = ({ productList }: IUseMemorizations) => {
  const { t } = useTranslation();

  const items = productList?.data?.map((x: IProductGroupListData) => ({
    id: x.externalId,
    label: x.descricao,
    subLabel: `${t('supply.lot.create.code')}: ${x.sequenciaGroupTenant}`,
  }));

  return {
    items,
  };
};
