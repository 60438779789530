import { useTranslation } from 'react-i18next';
import { PageLoading } from '../../../../../Layouts/PageLoading';
import { HorizontalTabs } from '../../../../../Components';
import { useState } from 'react';
import { useParams } from 'react-router';
import ProductMessageDetail from '../Edit/Details';
import { DeleteProductMessageModal } from '../Edit/Details/Components/DeleteModal/deleteModal';
import { ProductLinkedMessage } from '../Edit/LinkedProductsList';
import { PermissionEnum } from '@/Data/Interfaces/model/permissionEnum';
import { useEditCallAPI } from '../Edit/Details/Hooks/useEditCallAPI';

export const EditProductMensage: React.FC = () => {
  const { t } = useTranslation();
  const [disabled, setDisabled] = useState(true);
  const [visibleModal, setVisibleModal] = useState(false);
  const { externalId, tab } = useParams<{ externalId: string; tab: string }>();

  const { productMessage } = useEditCallAPI({ t, externalId });

  return (
    <PageLoading>
      <HorizontalTabs
        type="secondary"
        items={[
          {
            label: t('common.productMessageName'),
            key: 'details',
            children: (
              <ProductMessageDetail
                disabled={disabled}
                onChangeDisabled={(x: boolean) => setDisabled(x)}
              />
            ),
          },
          {
            label: t('common.linkedProduct'),
            key: 'linked-products',
            children: (
              <ProductLinkedMessage messageLinked={productMessage?.descricao} />
            ),
          },
        ]}
        dropdownItens={[
          {
            label: t('common.delete'),
            icon: 'trash',
            permission: {
              permission: PermissionEnum.Estoque_MensagemProduto_Excluir,
              tooltip: 'noPermission.supply.messageProduct.delete',
            },
            onClick: () => setVisibleModal(true),
          },
        ]}
        dropdownDirection="bottomRight"
        dropdownTitle={t('admin.editUserPage.tab.buttonActions.title')}
        leftIcon="menu-ellipsis-horizontal"
        rightButton={{
          ...(disabled &&
            tab === 'details' &&
            productMessage && {
              onClick: () => setDisabled(false),
              isVisible: disabled,
            }),
          codePermissionEdit:
            PermissionEnum.Estoque_MensagemProduto_EditarDetalhes,
          messageTooltipPermission: 'noPermission.supply.messageProduct.edit',
        }}
      />
      {productMessage && (
        <DeleteProductMessageModal
          productMessage={productMessage}
          visibleModal={visibleModal}
          changeVisibleModal={(value: any) => setVisibleModal(value)}
        />
      )}
    </PageLoading>
  );
};
