import { Modal } from '@/Components';
import { FC } from 'react';
import {
  SelectBody,
  SelectCardModalTranslations,
} from './components/SelectBody';
import { SelectItemProps } from './components/SelectItem';
import { IGetSaleOrderResponse } from '@/Data/Interfaces/response/Sales/SalesOrder/ISalesOrderResponse';

export interface SelectCardModalProps {
  title: string;
  translations: SelectCardModalTranslations;

  isLoading?: boolean;
  items?: Omit<SelectItemProps, 'onEdit' | 'onSelect'>[];

  visible: boolean;
  maxWidth?: number;

  onEdit: (externalId: string) => void;
  onSelect: (externalId: string) => void;

  onClose?: () => void;
  onFooterButtonClick?: () => void;
  onSearch?: (search: string) => void;

  saleOrder?: IGetSaleOrderResponse;
  currentSearch: string;
}

export const SelectCardModal: FC<SelectCardModalProps> = ({
  title,
  visible,
  maxWidth,
  translations,
  items,
  onClose,
  onEdit,
  onSelect,
  onFooterButtonClick,
  onSearch,
  saleOrder,
  currentSearch,
}) => {
  return (
    <Modal
      title={title}
      body={
        <SelectBody
          translations={translations}
          items={items}
          onEdit={onEdit}
          onSelect={onSelect}
          onSearch={onSearch}
          onFooterButtonClick={onFooterButtonClick}
          saleOrder={saleOrder}
          currentSearch={currentSearch}
        />
      }
      visible={visible}
      onClose={onClose}
      maxWidth={maxWidth}
      footer={false}
      widthModal={399}
      maxHeight={540}
    />
  );
};
