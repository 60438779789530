import { 
  FETCH_ASSOCIATED_PRODUCT, 
  FETCH_LIST_FOR_DROPDOWN_ASSOCIATED_PRODUCT_PHARMACEUTICAL_FORM 
} from "@/ControllerApiHook/UniqIds/Supply/ProductKeys";
import { history } from '@/Store';
import { useQueryClient } from '@tanstack/react-query';
import { IUseFunctions } from "./interfaces";
import { IFChildren } from "@/Components/Form";

export const useAssociatedProductDetailsFunctions = ({
  editAssociatedProduct,
  productExternalId,
  associationProductId,
  accumulate,
  disableForm
}: IUseFunctions) => {
  const queryClient = useQueryClient();

  const handleSubmit = async (values: any) => {
    const result = await editAssociatedProduct.mutateAsync({
      ...values,
      unidadeMedidaDosagem: values.unidadeMedidaDosagem,
      produtoExternalId: productExternalId,
      externalId: associationProductId,
      acumula: accumulate,
    });
    if (result) {
      queryClient.invalidateQueries([FETCH_ASSOCIATED_PRODUCT]);
      queryClient.invalidateQueries([
        FETCH_LIST_FOR_DROPDOWN_ASSOCIATED_PRODUCT_PHARMACEUTICAL_FORM,
      ]);
      history.push(`/inventory/products/${productExternalId}/associated`);
    }
  };

  const onClickCancelButton = (props: IFChildren) => {
    disableForm();

    if (props.dirty) {
      return history.push(
        `/inventory/products/${productExternalId}/associated`
      );
    }
  };

  const onClickBackButton = () => {
    history.push(
      `/inventory/products/${productExternalId}/associated`
    );
  };

  return {
    handleSubmit,
    onClickCancelButton,
    onClickBackButton
  };
};