import { useCallback } from 'react';
import { IUseFunctions } from './interfaces';

export const useDocumentCardFunctions = ({ props }: IUseFunctions) => {
  const onChange = useCallback(
    (values: any[] | undefined) => props.setFieldValue('documentos', values),
    []
  );

  return {
    onChange,
  };
};
