import { PermissionEnum } from '@/Data/Interfaces/model/permissionEnum';
import { history } from '@/Store';
import { IUseOrderListMemorizations } from './interface';

export const useOrderListMemorizations = ({
  setVisibleCreateModal,
  t,
}: IUseOrderListMemorizations) => {
  const headerOptions = {
    hasSearch: true,
    searchPlaceholder: t('saleOrder.list.searchPlaceholder'),
    newButton: {
      label: t('saleOrder.list.new'),
      onClick: () => setVisibleCreateModal(true),
      permission: {
        permission: PermissionEnum.Vendas_PedidosVenda_Cadastrar,
        tooltip: t('noPermission.sale.order.create'),
      },
      dataTestId: 'create-sale-order',
    },
  };

  const rowsOptions = {
    onRowDoubleClick: (x: any) =>
      history.push(`/sales/tickets/${x.atendimentoExternalId}/order`),
    permission: {
      permission: PermissionEnum.Vendas_PedidosVenda_VerDetalhes,
      tooltip: t('noPermission.sale.order.detail'),
    },
  };

  return { headerOptions, rowsOptions };
};
