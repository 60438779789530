import { PermissionEnum } from '@/Data/Interfaces/model/permissionEnum';
import { Link, Status, i18n } from '../../../../../Components';
import { LinkButton } from '../../../../../Components/Layouts/LinkButton';
import { getCurrentUserRoles } from '@/Utils/PermissionUtils';

export const expertiseCollums = (t: any) => [
  {
    title: t('specialties.listPage.specialtiesName'),
    dataIndex: 'descricao',
    key: 'descricao',
    sortColumn: 'descricao',
    sorter: true,
    defaultSortOrder: 'ascend',
    isHighlightable: true,
    render: (x: string, row: any) => (
      <Link
        type="ui-tiny-bold"
        to={
          getCurrentUserRoles().some(
            (x) =>
              PermissionEnum.Vendas_EspecialidadePrescritor_VerDetalhes === x
          )
            ? `/sales/expertise/${row?.externalId}/details`
            : undefined
        }
        style={{ width: 'fit-content' }}
      >
        {x}
      </Link>
    ),
  },
  {
    title: t('specialties.listPage.prescribersAmount'),
    dataIndex: 'qtdePrescritores',
    key: 'qtdePrescritores',
    width: '22%',
    render: (amount: any, row: any) => (
      <LinkButton
        link={`/sales/expertise/${row?.externalId}/prescribers`}
        text={`${amount} ${
          amount === 1
            ? t('prescribers.prescriber')
            : t('prescribers.prescribers')
        }`}
      />
    ),
  },
  {
    title: 'Status',
    dataIndex: 'ativo',
    key: 'ativo',
    width: '18%',
    render: (text: any) => (
      <Status type={text ? 'success' : 'disabled'}>
        {i18n.t(`common.${text ? 'activate' : 'inactivated'}`)}
      </Status>
    ),
  },
];
