import React from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { HorizontalTabs } from '../../../../../../Components';
import { PageLoading } from '../../../../../../Layouts/PageLoading';
import { DeleteProductGroupModal } from './Modal/DeleteProductGroupModal';
import { useEditStates } from './Hooks/useEditStates';
import { useEditCallAPI } from './Hooks/useEditCallAPI';
import { useEditMemorizations } from './Hooks/useEditMemorizations';
import { useEditFunctions } from './Hooks/useEditFunctions';

export const EditProductGroupForm: React.FC = () => {
  const params: Readonly<Partial<{ externalId: string; tab: string }>> =
    useParams();
  const { t } = useTranslation();

  const {
    modalDeleteProductGroup,
    setModalDeleteProductGroup,
    disabled,
    setDisabled,
  } = useEditStates();

  const { data, isLoading } = useEditCallAPI();

  const { items, dropdownItems, rightButton } = useEditMemorizations({
    disabled,
    setDisabled,
    setModalDeleteProductGroup,
    params,
  });

  const { onCancelClick, onOkClick } = useEditFunctions({
    setModalDeleteProductGroup,
    params,
    setDisabled,
  });

  return (
    <PageLoading loading={isLoading}>
      <HorizontalTabs
        type="secondary"
        items={items}
        dropdownItens={dropdownItems}
        dropdownDirection="bottomRight"
        dropdownTitle={t('admin.editUserPage.tab.buttonActions.title')}
        leftIcon="menu-ellipsis-horizontal"
        rightButton={rightButton}
        dataTestIdDropdownButton="tab-dropdown-options"
      />
      {data?.externalId ? (
        <DeleteProductGroupModal
          productGroup={data}
          onCancelClick={onCancelClick}
          visible={modalDeleteProductGroup}
          onOkClick={onOkClick}
        />
      ) : null}
    </PageLoading>
  );
};
