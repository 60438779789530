import {
  useControllerQueryApiHook,
  useControllerQueryListApiHook,
} from '@/ControllerApiHook/Controller';
import { IProductResponse } from '@/Data/Interfaces/response/Product/IProductRespose';
import { FETCH_GET_PRODUCT } from '@/ControllerApiHook/UniqIds/Supply/ProductKeys';
import { ProductAPI } from '@/Data/API/Supply/Product';
import { FETCH_LIST_UNITS_BY_CLASS } from '@/ControllerApiHook/UniqIds/Supply/UnitMeasurementKeys';
import { UnitMeasurementAPI } from '@/Data/API/Supply/UnitMeasurementApi';
import { useFormikContext } from 'formik';
import { IUseCallAPI } from './interfaces';
import { useEffect } from 'react';
import { isNil } from 'lodash';

export const useQuantityCallAPI = ({ classeProdutoId }: IUseCallAPI) => {
  const form: any = useFormikContext();

  const { data: unitsMeasurementsDefault } =
    useControllerQueryApiHook<IProductResponse>({
      uniqId: FETCH_GET_PRODUCT,
      entityApi: ProductAPI.getProduct,
      externalIds: [form?.values?.produtoExternalId],
    });

  const {
    data: listunitsMeasurementsPrescription,
    isLoading: isUnitOfMeasurePrescriptionLoading,
    refetch,
  } = useControllerQueryListApiHook({
    uniqId: FETCH_LIST_UNITS_BY_CLASS,
    entityApi: UnitMeasurementAPI.listUnitMeasurementByClass,
    autoIncrement: true,
    pagination: {
      filter: [
        {
          filterName: 'tipoClasseProduto',
          value: classeProdutoId,
        },
      ],
    },
    enabled: !isNil(classeProdutoId),
  });

  useEffect(() => {
    refetch({
      pageIndex: 1,
      pageSize: 50,
      filter: [
        {
          filterName: 'tipoClasseProduto',
          value: classeProdutoId,
        },
      ],
    });
  }, [classeProdutoId]);

  return {
    unitsMeasurementsDefault,
    listunitsMeasurementsPrescription,
    isUnitOfMeasurePrescriptionLoading,
  };
};
