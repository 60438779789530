import { IUseFunctions } from './interfaces';
import { 
  ICreateProductMessage 
} from '@/Data/Interfaces/request/ProductMensage/IProductMensageRequest';

export const useCreateFunctions = ({
  createProductMessage,
}: IUseFunctions) => {
  const onSubmit = async (values: ICreateProductMessage) => {
    const body: any = { ...values };

    return await createProductMessage({
      descricao: body.mensagem.trim(),
      exibeVenda: body.exibeVenda ?? false,
      exibeRotulagem: body.exibeRotulagem ?? false,
      exibeFichaPesagem: body.exibeFichaPesagem ?? false,
      exibeImpressaoRotulo: body.exibeImpressaoRotulo ?? false,
    });
  };

  return {
    onSubmit
  };
};
