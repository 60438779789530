import { useCallback } from 'react';
import { IUseFunctions } from './interfaces';
import { useFormikContext } from 'formik';

export const useBatchControlFunctions = ({
  stockBalance,
  setLotControlModal,
  product,
}: IUseFunctions) => {
  const form: any = useFormikContext();
  const onClick = useCallback(() => {
    const initialValue = form.initialValues.controlaLote;
    if (stockBalance && stockBalance?.saldo > 0 && product?.controlaLote) {
      setLotControlModal(true);
      setTimeout(() => {
        form.setFieldValue('controlaLote', initialValue);
      }, 1);
    }
  }, [stockBalance, form.values]);

  return { onClick };
};
