import { ISubGroupsByGroupData } from '@/Data/Interfaces/response/Product/IProductRespose';
import { IUseMemorizations } from './interfaces';

export const useSubGroupMemorizations = ({
  listSubGroups,
}: IUseMemorizations) => {
  const listArray = listSubGroups ? listSubGroups.data : [];

  const items = listArray?.map((x: ISubGroupsByGroupData) => ({
    id: x.externalId,
    label: x.descricao,
  }));

  return { items };
};
