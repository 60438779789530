import { useMemo } from 'react';
import { IListPrescribersData } from '@/Data/Interfaces/response/Prescribers/IPrescribersResponse';
import { IUseMemorizations } from './interfaces';
import { useTranslation } from 'react-i18next';
import { history } from '@/Store';
import { PermissionEnum } from '@/Data/Interfaces/model/permissionEnum';
import { DeleteModalBody } from '@/Pages/Sectors/Sales/Prescribers/List/Modal/DeleteModal';
import { IDeleteModalTable } from '@/Components/Table';

export const usePrescribersMemorizations = ({
  selectedRowsOnTable,
}: IUseMemorizations) => {
  const { t } = useTranslation();

  const headerOptions = {
    nameKey: 'nomeCompleto',
    hasSearch: true,
    searchPlaceholder: t('prescribers.listPage.searchPlaceholder'),
    newButton: {
      label: t('prescribers.listPage.newButton'),
      onClick: () => history.push('/sales/prescribers/create'),
    },
    editButton: {
      options: [
        {
          label: t('common.status'),
          isStatusOnly: true,
        },
      ],
    },
  };

  const rowsOptions = {
    onRowDoubleClick: (x: IListPrescribersData) =>
      history.push(`/sales/prescribers/${x.externalId}/details`),
    permission: {
      permission: PermissionEnum.Vendas_Prescritores_VerDetalhes,
      tooltip: 'noPermission.supply.products.detail',
    },
  };

  const deleteModal: IDeleteModalTable | undefined = useMemo(
    () =>
      selectedRowsOnTable && {
        title:
          selectedRowsOnTable.length === 1
            ? `${t('common.delete')} "${selectedRowsOnTable[0].nomeCompleto}"?`
            : `${t('common.delete')} ${selectedRowsOnTable.length} ${t(
                'prescribers.prescribers'
              )}?`,
        body: <DeleteModalBody prescriber={selectedRowsOnTable} />,
        buttons: {
          okButtonColor: 'status-danger-base',
        },
      },
    [selectedRowsOnTable, t]
  );

  return {
    headerOptions,
    rowsOptions,
    deleteModal,
  };
};
