import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { FooterThisActionIsPermanent } from '../../../../../../Components/Table/components/Modals/DeleteModal';
import ReactMarkdown from 'react-markdown';

interface IDeleteModal {
  selectedRowsOnTable: any;
}

export const DeleteModal: FC<IDeleteModal> = ({ selectedRowsOnTable }) => {
  const { t } = useTranslation();
  return (
    <div style={{ padding: '20px', maxWidth: '400px' }}>
      <ReactMarkdown>
        {t('production.laboratory.list.deleteModal.description', {
          laboratorio: `**${selectedRowsOnTable[0]?.laboratorio}**`,
          empresa: `**${selectedRowsOnTable[0]?.empresa}**`,
        })}
      </ReactMarkdown>
      <FooterThisActionIsPermanent />
    </div>
  );
};
