import { FC } from 'react';
import { Modal } from '@/Components/Modal';
import { useTranslation } from 'react-i18next';
import {
  DELETE_INVOICE_LOT_DRAFT,
  FETCH_LIST_LOTS
} from '@/ControllerApiHook/UniqIds/Purchase/InvoiceKeys';
import { useQueryClient } from '@tanstack/react-query';
import { history } from '@/Store';
import { useControllerMutateApiHook } from '@/ControllerApiHook/Controller';
import { InvoiceAPI } from '@/Data/API/Purchase/InvoiceApi';

interface IDeleteDraftModal {
  invoiceId: string;
  visibleModal: boolean;
  changeVisibleModal: (value: boolean) => void;
}

export const DeleteDraftModal: FC<IDeleteDraftModal> = ({
  invoiceId,
  visibleModal,
  changeVisibleModal,
}) => {
  const { t } = useTranslation();

  const deleteDraftLot: any = useControllerMutateApiHook({
    uniqId: DELETE_INVOICE_LOT_DRAFT,
    entityApi: InvoiceAPI.deleteInvoiceLotDraft
  });

  const queryClient = useQueryClient();

  return (
    <Modal
      title={t('common.modalDraftDelete.title')}
      body={
        <div style={{ padding: '20px', width: 400 }}>
          {t('common.modalDraftDelete.subTitle')}
        </div>
      }
      okButtonColor="status-danger-base"
      visible={visibleModal}
      onOkClick={async () => {
        const res = await deleteDraftLot.mutateAsync(invoiceId as any);
        if (res.success) {
          queryClient.invalidateQueries([FETCH_LIST_LOTS]);
          history.push(`/order/invoice/${invoiceId}/details`);
        }
      }}
      okButtonName={
        deleteDraftLot.isLoading ? t('common.deleting') : t('common.delete')
      }
      loading={deleteDraftLot.isLoading}
      onCancelClick={() => changeVisibleModal(false)}
      onClose={() => changeVisibleModal(false)}
    />
  );
};
