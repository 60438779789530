import DateUtils from '@/Utils/DateUtils';
import { formatPhoneNumber } from 'react-phone-number-input';
import { IDropdownEditButton, IUseMemorizations } from './interfaces';
import { useTranslation } from 'react-i18next';

export const useCreateMemorizations = ({
  externalId,
  purchaseOrder,
  isEdit,
  setOpenDeleteDraftModal,
}: IUseMemorizations) => {
  const { t } = useTranslation();
  const pedidoCompraItensInicial: any = {};

  if (
    purchaseOrder &&
    purchaseOrder.pedidoCompraItens &&
    purchaseOrder.pedidoCompraItens.length
  ) {
    purchaseOrder.pedidoCompraItens.forEach((item) => {
      pedidoCompraItensInicial[`item_${item.pedidoCompraItemExternalId}`] = {
        id: item.produtoExternalId,
        label: item.descricao,
      };
    });
  }

  const initialValues =
    externalId && purchaseOrder
      ? {
          ...purchaseOrder,
          previsaoEntrega: DateUtils.convertDate(
            purchaseOrder?.previsaoEntrega
          ),
          fornecedorContatoPrincipal:
            purchaseOrder?.tipoContatoId === 2
              ? formatPhoneNumber(purchaseOrder?.fornecedorContatoPrincipal)
              : purchaseOrder?.fornecedorContatoPrincipal,

          ...pedidoCompraItensInicial,
          pedidoCompraItens: purchaseOrder.pedidoCompraItens.map((item) => ({
            ...item,
            tipoDesconto: item.tipoDescontoId,
          })),
        }
      : { pedidoCompraItens: [{}] };

  const withDropdownEditButton: IDropdownEditButton | undefined =
    isEdit || !!externalId
      ? {
          options: [
            {
              label: t('common.modalDraftDelete.title'),
              icon: 'trash',
              onClick: () => setOpenDeleteDraftModal(true),
            },
          ],
        }
      : undefined;

  return {
    initialValues,
    withDropdownEditButton,
  };
};
