import React, { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { SingleItemBody } from '../../../../../../Components/Table/components/Modals/DeleteModal';
import { DeleteModalOnTable } from '../../../../../../Utils/DeleteModalUtils';
import { Text } from '../../../../../../Components';
import { v4 as uuidV4 } from 'uuid';

export interface IDeleteModal {
  rows: any[];
  hasCompany: boolean;
}

export const getDeleteModalTitle = (
  t: any,
  rows: any[],
  hasNoCompany: boolean
): string => {
  const isMany = rows?.length > 1;

  if (!hasNoCompany && rows) {
    return !isMany
      ? `“${rows[0]?.nome}” ${t(
          'adminBootis.companyList.deleteModal.titleNotDelete'
        )}`
      : `${t('adminBootis.companyList.deleteModal.titleNotManyPre')}${
          rows?.length
        }${t('adminBootis.companyList.deleteModal.titleNotManyAfter')}`;
  }

  return !isMany
    ? `${t('modal.delete')} ${t('common.theCompanyGroup')}?`
    : `${t('modal.delete')} ${rows?.length} ${t('common.companyGroups')}?`;
};

export const DeleteModalBody: FC<IDeleteModal> = ({ rows, hasCompany }) => {
  const { t } = useTranslation();

  const isMany = useMemo(() => rows?.length > 1, [rows]);

  if (hasCompany) {
    return (
      <div style={{ maxWidth: '400px', padding: '20px' }}>
        {!isMany ? (
          <>
            <Text
              color="text-50"
              type="ui-sub-content"
              style={{ marginBottom: '15px' }}
            >
              {t('adminBootis.companyList.deleteModal.single.title')} {}
              <b>{`“${rows[0].nome}”.`}</b>
            </Text>
            <Text
              color="text-50"
              type="ui-sub-content"
              style={{ wordBreak: 'break-word', whiteSpace: 'break-spaces' }}
            >
              {t('adminBootis.companyList.deleteModal.single.bodyPre')}
              {rows[0].empresas}
              {t('adminBootis.companyList.deleteModal.single.bodyPos')}
            </Text>
          </>
        ) : (
          <>
            <Text color="text-50" type="ui-sub-content">
              {t('adminBootis.companyList.deleteModal.many.title')}
            </Text>

            <ul style={{ margin: '0', padding: '15px 22px' }}>
              {rows?.slice(0, 4).map((x: any) => (
                <li key={x.externalId}>
                  <Text color="text-50" type="ui-tiny-bold">
                    {x.nome}
                  </Text>
                </li>
              ))}
              {rows.length > 4 && (
                <li key={uuidV4()} style={{ height: '18px' }}>
                  <Text
                    color="text-300"
                    type="ui-tiny-content"
                    children={`+ ${rows.length - 4} ${t(
                      'common.companyGroups'
                    )}`}
                  />
                </li>
              )}
            </ul>

            <Text
              color="text-50"
              type="ui-sub-content"
              children={t('adminBootis.companyList.deleteModal.many.body')}
            />
          </>
        )}
      </div>
    );
  }

  return !isMany ? (
    <SingleItemBody itemNames={[[rows[0]?.nome]]} />
  ) : (
    DeleteModalOnTable(
      rows,
      `${t('modal.deleteModal.many.title')} ${t('common.theCompanyGroups')}:`,
      'nome',
      t('common.companyGroups')
    )
  );
};
