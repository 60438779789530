import { useTranslation } from "react-i18next";

export const useDensityMemorizations = () => {
  const { t } = useTranslation();

  const label = [
    {
      children: t('supply.lot.create.density'),
    },
  ];

  return {
    label
  };
};