import { CurrencyInput } from '@/Components/Inputs/CurrencyInput';
import { Col, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import { useGetTotalInvoice } from './hooks/useGetTotalInvoice';

export const RowThree: React.FC = () => {
  const { t } = useTranslation();
  const { getTotalInvoice } = useGetTotalInvoice();

  return (
    <Row gutter={[16, 0]}>
      <Col span={4}>
        <CurrencyInput
          label={t('purchasing.invoice.create.totalInvoice')}
          name="valorTotalNotaFiscal"
          placeHolder={t(
            'purchasing.invoice.create.placeHolder.CurrencyPlaceHolder'
          )}
          disabled
          withTooltip={{
            icon: 'exclamation-upside-down',
            title: t('purchasing.invoice.create.totalInvoiceTooltip'),
          }}
          value={getTotalInvoice()}
        />
      </Col>
    </Row>
  );
};
