import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Modal } from '@/Components/Modal';
import { IReleasedModal } from './interfaces';
import { 
  useReleasedOrderModalMemorizations 
} from './Hooks/useReleasedOrderModalMemorizations';

export const ReleasedOrderModal: FC<IReleasedModal> = ({
  isVisible,
  onCancelClick,
  onOkClick,
  onClose,
  okButtonName,
  loading,
  statusPurchaseOrder
}) => {
  const { t } = useTranslation();

  const {
    body
  } = useReleasedOrderModalMemorizations({
    statusPurchaseOrder
  });

  return (
    <Modal
      title={t('purchasing.purchaseOrder.modal.released.title')}
      body={body}
      widthModal={399}
      onCancelClick={onCancelClick}
      visible={isVisible}
      onOkClick={onOkClick}
      onClose={onClose}
      okButtonName={okButtonName}
      okButtonColor="status-danger-base"
      loading={loading}
    />
  );
};
