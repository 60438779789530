import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import {
  TextInput
} from '@/Components';

export const Reason: FC = () => {
  const { t } = useTranslation();

  return (
    <>
      <TextInput
        name="razao"
        label={t('production.standardFormula.create.reason')}
        placeHolder={t(
          'production.standardFormula.create.reasonPlaceholder'
        )}
        withoutMarginBottom={true}
        disabled={true}
      />
    </>
  );
};