import React, { FC, ReactNode, useState } from 'react';
import { Input } from 'antd';
import { Icon, Tooltip } from '../..';
import { IconName } from '../../Icon/IconClasses';
import { TextType } from '../../Text';
import Spinner from '../../Spinner';
import _ from 'lodash';
import { Divflex } from '../../../Layouts/DivWhithFlex';
import { IInputLabel } from '../../Inputs/Dropdown';
import { InputLabel } from '../../Inputs/Label';
import { Placements } from '@/Components/Tooltip';
import { IIconProps } from '@/Components/Icon';

import styles from './TextInput.module.scss';
import './TextInput.override.scss';

export type IconTooltip = {
  icon?: IconName;
  tooltip?: {
    title: ReactNode;
    placement?: Placements;
  };
  onClick?: () => void;
} & IIconProps;

export interface ITextInputState {
  type?: 'text' | 'password' | 'number';
  label?: IInputLabel[] | string;
  maxLength?: number;
  typeLabel?: TextType;
  disabled?: boolean;
  placeHolder?: string;
  nulable?: boolean;

  rightIconWithCopy?: {
    icon?: IconName;
    copyName?: string;
    toolTipTitle?: ReactNode[];
  };

  rightIcon?: IconTooltip[];

  leftIcon?: IconTooltip;

  withTooltip?: {
    title: string;
    icon: IconName;
  };

  required?: boolean;

  withoutMarginBottom?: boolean;

  success?: boolean;
  error?: boolean;
  className?: string;
  supportText?: string;

  onBlur?: (value: string) => void;
  onChange?: (value: string) => void;

  isLoading?: boolean | undefined;
  value?: string;

  withStatusIcon?: boolean;
  withoutFocusCss?: boolean;

  defaultValue?: string;

  id?: string;
}

export const TextInputState: FC<ITextInputState> = ({
  type,
  label,
  maxLength,
  nulable,
  required,
  placeHolder,
  error,
  success,
  rightIconWithCopy,
  rightIcon,
  leftIcon,
  withTooltip,
  withoutMarginBottom,
  disabled,
  className,
  supportText,
  onBlur,
  onChange,
  isLoading,
  value,
  withStatusIcon,
  withoutFocusCss,
  defaultValue,

  id,
}) => {
  const [copied, setCopied] = useState(false);

  let debounce_setCopied = _.debounce(() => {
    setCopied(false);
  }, 500);

  return (
    <div
      className={`${withoutMarginBottom ? '' : styles['margin-bottom']} ${
        styles['Container']
      }`}
      id={id}
    >
      <>
        {label && (
          <Divflex>
            <InputLabel
              label={label}
              withStatusIcon={withStatusIcon}
              required={required}
            />
            {withTooltip && (
              <Tooltip
                title={withTooltip.title}
                showMe
                children={
                  <Icon
                    className={styles['icon-with-tooltip']}
                    icon={withTooltip.icon}
                    color="text-300"
                    size="S"
                  />
                }
              />
            )}
          </Divflex>
        )}

        <div
          className={`
            ${styles['input-container']} 
            ${className} 
            ${leftIcon ? styles['with-left-icon'] : null}
            ${rightIconWithCopy || success ? styles['with-right-icon'] : null} 
            ${success ? styles['success'] : null}
            ${disabled ? styles['disabled'] : null}
            ${error ? styles['error'] : null}
            ${withoutFocusCss ? styles['without-focus'] : ''}`}
        >
          {leftIcon ? (
            <div className={styles['Left-Icon']}>
              <Tooltip
                title={leftIcon.tooltip?.title}
                showMe
                placement={leftIcon.tooltip?.placement}
              >
                <Icon
                  icon={leftIcon.icon}
                  size="M"
                  color="text-50"
                  {...leftIcon}
                />
              </Tooltip>
            </div>
          ) : null}

          {supportText && (
            <div className={styles['supportText']}>{supportText}</div>
          )}

          <Input
            type={type}
            className={styles['TextInput']}
            maxLength={maxLength}
            placeholder={placeHolder}
            disabled={disabled}
            value={value}
            defaultValue={defaultValue}
            onChange={(x) => onChange && onChange(x.target.value)}
          />

          {success ? (
            <Icon
              className={styles['right-icon']}
              icon="check"
              size="M"
              color="status-success-base"
            />
          ) : null}

          {rightIconWithCopy?.icon ? (
            <Tooltip
              title={
                rightIconWithCopy.toolTipTitle &&
                (!copied
                  ? rightIconWithCopy.toolTipTitle[0]
                  : rightIconWithCopy.toolTipTitle[1])
              }
              showMe={!!rightIconWithCopy.toolTipTitle}
              placement="bottomRight"
              copied={copied}
            >
              <Icon
                className={styles['right-icon']}
                icon={rightIconWithCopy.icon}
                size="M"
                color="text-400"
                onClick={() => {
                  var copyText;
                  if (rightIconWithCopy.copyName)
                    copyText = document.getElementById(
                      rightIconWithCopy.copyName
                    ) as HTMLInputElement;

                  if (copyText) {
                    copyText.select();
                    copyText.setSelectionRange(0, 99999);
                    navigator.clipboard.writeText(copyText.value);
                  }
                  setCopied(true);
                }}
                onMouseLeave={debounce_setCopied}
              />
            </Tooltip>
          ) : null}

          {rightIcon ? (
            <Divflex className={styles['right-icon']}>
              {rightIcon.map((x) => {
                return (
                  <Tooltip
                    title={x.tooltip?.title}
                    showMe
                    placement={x.tooltip?.placement}
                  >
                    <Icon
                      icon={x.icon}
                      size="M"
                      color="text-400"
                      onClick={x.onClick}
                    />
                  </Tooltip>
                );
              })}
            </Divflex>
          ) : null}

          {isLoading && (
            <div className={styles['spinner']}>
              <Spinner />
            </div>
          )}
        </div>
      </>
    </div>
  );
};
