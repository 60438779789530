import { useState } from "react";

export const useEditStates = () => {
  const [
    disabledForm, 
    setDisabledForm
  ] = useState(true);

  const [
    visibleModal,
    setVisibleModal
  ] = useState(false);

  return {
    disabledForm,
    setDisabledForm,
    visibleModal,
    setVisibleModal
  };
};