import { useTranslation } from 'react-i18next';
import { GET_STANDARD_FORMULA } from '../../../../../../../ControllerApiHook/UniqIds/Production/StandardFormulaKeys';
import { useControllerQueryApiHook } from '@/ControllerApiHook/Controller';
import { StandardFormulaAPI } from '@/Data/API/Production/StandardFormula';
import { IGetStandardFormula } from '@/Data/Interfaces/response/StandardFormula/IStandardFormulaResponse';

export const DynamicInfoEditStandardFormulaBreadcrumb = ({ match }: any) => {
  const { t } = useTranslation();

  const { data: standardFormula } = useControllerQueryApiHook<IGetStandardFormula>({
    uniqId: GET_STANDARD_FORMULA,
    entityApi: StandardFormulaAPI.getStandardFormula,
    externalIds: [match.params.externalId]
  });

  return (
    <span style={{ display: 'flex' }}>
      {`${t(
        'breadCrumb.production.standardFormula.standardFormulaDetailTitle'
      )} | ${standardFormula?.descricaoProdutoFormula}`}
    </span>
  );
};

export const DynamicStandardFormulaBreadcrumb = ({ match }: any) => {
  const { data: standardFormula } = useControllerQueryApiHook<IGetStandardFormula>({
    uniqId: GET_STANDARD_FORMULA,
    entityApi: StandardFormulaAPI.getStandardFormula,
    externalIds: [match.params.externalId]
  });

  return (
    <div style={{ display: 'flex' }}>
      {standardFormula?.descricaoProdutoFormula}
    </div>
  );
};
