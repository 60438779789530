import { useCallback } from "react";
import { useDispatch } from 'react-redux';
import { NotificationActions } from '@/Store/Notification/Notification.actions';

export const useLevelIncompatibleFunctions = () => {
  const dispatch = useDispatch();

  const onChange = useCallback((value: string) => {
    if (value) {
      dispatch(NotificationActions.setCurrentFormIsDirty(true));
    }
  }, [dispatch]);

  return {
    onChange
  };
};