import { useCallback } from "react";
import { history } from '@/Store';
import { IUseFunctions } from "./interfaces";

export const useFooterFunctions = ({
  params,
  instance
}: IUseFunctions) => {
  const onOkClick = useCallback(() => {
    history.push(
      `/inventory/sub-groups/${params.externalId[0]}/products`
    );
    instance.destroy();
  }, []);

  return { onOkClick };
};