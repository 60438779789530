import {
  Button,
  Dropdown,
  Icon,
  InputWithSearch,
  Label,
  Modal,
} from '@/Components';
import { NumberInput } from '@/Components/Inputs/NumberInput';
import { FETCH_DROPDOWN_LIST_CLIENT } from '@/ControllerApiHook/UniqIds/People/ClientKeys';
import { LIST_FOR_PHARMACEUTICAL_FORM_DROPDOWN_TYPE } from '@/ControllerApiHook/UniqIds/Production/PharmaceuticalFormKeys';
import { MaskForCNPJ, MaskForCPF } from '@/Utils/MasksUtils';
import { Col, Row } from 'antd';
import { useFormikContext } from 'formik';
import {
  Dispatch,
  FC,
  MutableRefObject,
  SetStateAction,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Divflex } from '@/Layouts/DivWhithFlex';
import { TextInputNumber } from '@/Components/Inputs/TextInputNumber';
import { FETCH_SIMPLE_LIST_PRESCRIBERS } from '@/ControllerApiHook/UniqIds/People/PrescribersKeys';
import { useTranslation } from 'react-i18next';
import { DoseModalBody } from './Modals/doseModalBody';
import { SortableList } from './ComponentLine/Components/SortableList';
import { ComponentsLine } from './ComponentLine';
import { FETCH_LIST_PRODUCT_PRESCRIPTION } from '@/ControllerApiHook/UniqIds/Supply/ProductKeys';
import { useControllerQueryListApiHook } from '@/ControllerApiHook/Controller';
import { ProductAPI } from '@/Data/API/Supply/Product';
import { ClientAPI } from '@/Data/API/People/ClientApi';
import { IListDropdownClientData } from '@/Data/Interfaces/response/Client/IClientResponse';
import { PrescribersAPI } from '@/Data/API/People/PrescribersApi';
import { ISimpleListPrescribersData } from '@/Data/Interfaces/response/Prescribers/IPrescribersResponse';
import { PharmaceuticalFormAPI } from '@/Data/API/Production/PharmaceuticalForm';
import { IListPharmaceuticalFormDropdownData } from '@/Data/Interfaces/response/PharmaceuticalForm/IPharmaceuticalFormResponse';

import styles from './AddManipulatedRecipe.module.scss';

interface IAddManipulatedRecipeModalBody {
  setComponentsEditing: Dispatch<SetStateAction<number[]>>;
  componentsEditing: number[];

  validatingComponents: boolean;
}

export const AddManipulatedRecipeModalBody: FC<
  IAddManipulatedRecipeModalBody
> = ({ setComponentsEditing, componentsEditing, validatingComponents }) => {
  const [pharmaceuticalSelected, setPharmaceuticalSelected] = useState<
    string | undefined
  >(undefined);

  const [
    pharmaceuticalCalculateTypeSelected,
    setPharmaceuticalCalculateTypeSelected,
  ] = useState<number | undefined>(undefined);

  const [doseModal, setDoseModal] = useState(false);

  const [doseValues, setDoseValues] = useState<{
    amount?: number;
    dose?: number;
  }>({
    amount: undefined,
    dose: undefined,
  });

  const form: any = useFormikContext();
  const { t } = useTranslation();

  const {
    data: clientList,
    isLoading: clientListLoading,
    fetchNewPage: fetchNewProductPage,
    refetch: refetchClientList,
  } = useControllerQueryListApiHook({
    uniqId: FETCH_DROPDOWN_LIST_CLIENT,
    entityApi: ClientAPI.listDropdownClient,
    pagination: {
      sorter: { column: 'nome', direction: 'DESC' },
    },
  });

  const {
    data: pharmaceuticalList,
    isLoading: pharmaceuticalListLoading,
    fetchNewPage: fetchNewPagePharmaceuticalList,
  } = useControllerQueryListApiHook({
    uniqId: LIST_FOR_PHARMACEUTICAL_FORM_DROPDOWN_TYPE,
    entityApi: PharmaceuticalFormAPI.listPharmaceuticalFormForDropDown,
    pagination: {
      sorter: { column: 'descricao', direction: 'DESC' },
    },
    autoIncrement: true,
  });

  const prescriberList = useControllerQueryListApiHook({
    uniqId: FETCH_SIMPLE_LIST_PRESCRIBERS,
    entityApi: PrescribersAPI.listPrescribersForDropdown,
    pagination: {
      sorter: { column: 'nomeCompleto', direction: 'DESC' },
    },
  });

  useEffect(() => {
    form.setFieldValue(
      'total',
      (form.values.quantidade || 0) * (form.values.repetir || 1)
    );
  }, [form.values.quantidade, form.values.repetir]);

  function formatDoseAndTotalDose(dose: number) {
    const formattedDose = Number.isInteger(dose)
      ? dose.toString()
      : dose.toFixed(5);
    if (formattedDose.length > 5) {
      return `${formattedDose.slice(0, 5)}...`;
    } else {
      return formattedDose;
    }
  }

  return (
    <div
      className={styles['container']}
      id="add-manipulated-recipe-dropdown-components-container"
    >
      <Row gutter={[12, 18]} style={{ marginBottom: 12 }}>
        <Col span={12}>
          <InputWithSearch
            label={t(
              'saleOrder.editOrder.SaleOrder.addManipulatedRecipe.patient'
            )}
            items={clientList?.data?.map((x: IListDropdownClientData) => ({
              avatar: { name: x.nome },
              id: x.externalId,
              label: x.nome,
              subLabel: `Código: ${x.codigo} | ${
                x.cpf
                  ? `CPF: ${MaskForCPF(x.cpf)}`
                  : `CNPJ: ${MaskForCNPJ(x.cnpj)}`
              }`,
            }))}
            name="clienteExternalId"
            withoutSearchIcon
            placeholderWithAvatar
            isLoading={clientListLoading}
            onScrollEnd={fetchNewProductPage}
            required
            onSearch={(search) =>
              refetchClientList({
                search: search,
              })
            }
            withoutMarginBottom
          />
        </Col>
        <Col span={12}>
          <InputWithSearch
            name="prescritorExternalId"
            label={t(
              'saleOrder.editOrder.SaleOrder.addManipulatedRecipe.prescriber'
            )}
            placeHolder={t(
              'saleOrder.editOrder.SaleOrder.addProductModal.prescriberPlaceholder'
            )}
            items={prescriberList?.data?.data?.map(
              (x: ISimpleListPrescribersData) => ({
                id: x.externalId,
                label: x.nomeCompleto,
                subLabel: `${x.siglaRegistro}/${x.siglaRegistro} ${x.codigoRegistro}`,
              })
            )}
            onScrollEnd={prescriberList.fetchNewPage}
            isLoading={prescriberList.isLoading}
            onSearch={(search) =>
              prescriberList.refetch({
                search: search,
              })
            }
            withoutMarginBottom
          />
        </Col>
        <Col flex="36%">
          <Dropdown
            name="formaFarmaceuticaExternalId"
            label={t(
              'saleOrder.editOrder.SaleOrder.addManipulatedRecipe.pharmaceuticalForm'
            )}
            required
            items={
              pharmaceuticalList?.data?.map(
                (x: IListPharmaceuticalFormDropdownData) => ({
                  id: x.externalId,
                  label: x.descricao,
                  allItem: x,
                })
              ) || undefined
            }
            isLoading={pharmaceuticalListLoading}
            onScrollEnd={fetchNewPagePharmaceuticalList}
            withoutMarginBottom
            onChange={(__, _, item) => {
              setPharmaceuticalSelected(item?.apresentacao);
              setPharmaceuticalCalculateTypeSelected(item?.tipoCalculo);
              setDoseValues((prevState) => ({
                ...prevState,
                dose: undefined,
              }));
              form.setFieldValue('dose', undefined);
            }}
          />
        </Col>
        <div className={styles['divider']} />
        <Col flex="17%">
          <NumberInput
            name="quantidade"
            label={t(
              'saleOrder.editOrder.SaleOrder.addManipulatedRecipe.amount'
            )}
            required
            placeHolder="0"
            rightIcon={{
              titleString: pharmaceuticalSelected,
            }}
            withoutMarginBottom
            value={doseValues.amount}
            onChange={(x) =>
              setDoseValues((prevState) => ({
                ...prevState,
                amount: x,
              }))
            }
            subText={
              form.values.dose
                ? `Dose: ${formatDoseAndTotalDose(
                    form.values.quantidade / form.values.dose
                  )} ${pharmaceuticalSelected}`
                : undefined
            }
          />
        </Col>
        <div>
          <div
            style={{
              display: 'flex',
              alignItems: 'flex-end',
              height: '100%',
            }}
          >
            <Icon
              icon="close-x"
              size="S"
              color="text-300"
              style={{
                marginBottom: !form.values.dose ? '12px' : '33px',
              }}
            />
          </div>
        </div>
        <Col flex="14%">
          <TextInputNumber
            name="repetir"
            label={t(
              'saleOrder.editOrder.SaleOrder.addManipulatedRecipe.repeat'
            )}
            placeHolder="0"
            withoutMarginBottom
            min={1}
            withTooltip={{
              icon: 'question-mark',
              title: t(
                'saleOrder.editOrder.SaleOrder.addManipulatedRecipe.repeatTooltip'
              ),
            }}
          />
        </Col>
        <div>
          <div
            style={{
              display: 'flex',
              alignItems: 'flex-end',
              height: '100%',
            }}
          >
            <Icon
              icon="Equal"
              size="S"
              color="text-300"
              style={{
                marginBottom: !form.values.dose ? '12px' : '33px',
              }}
            />
          </div>
        </div>
        <Col flex="17%">
          <NumberInput
            name="total"
            label={t(
              'saleOrder.editOrder.SaleOrder.addManipulatedRecipe.total'
            )}
            placeHolder="0"
            disabled
            rightIcon={{
              titleString: pharmaceuticalSelected,
            }}
            withoutMarginBottom
            subText={
              form.values.dose
                ? `${formatDoseAndTotalDose(
                    form.values.dose * form.values.repetir
                  )} doses`
                : undefined
            }
          />
        </Col>
        <Col>
          {pharmaceuticalCalculateTypeSelected === 2 ? (
            <Button
              type="secondary"
              children={t(
                'saleOrder.editOrder.SaleOrder.addManipulatedRecipe.dose'
              )}
              style={{ marginTop: 20 }}
              onClick={() => setDoseModal(true)}
            />
          ) : (
            <></>
          )}
        </Col>
        <Modal
          title={t(
            'saleOrder.editOrder.SaleOrder.addManipulatedRecipe.insertDose'
          )}
          body={
            <DoseModalBody
              pharmaceuticalSelected={pharmaceuticalSelected}
              doseModalValues={doseValues}
              setDoseModalValues={setDoseValues}
              form={form}
            />
          }
          visible={doseModal}
          onCancelClick={() => {
            setDoseValues((prevState) => ({
              ...prevState,
              dose: undefined,
            }));
            form.setFieldValue('dose', undefined);
            setDoseModal(false);
          }}
          onClose={() => {
            setDoseValues((prevState) => ({
              ...prevState,
              dose: undefined,
            }));
            form.setFieldValue('dose', undefined);
            setDoseModal(false);
          }}
          onOkClick={() => {
            form.setFieldValue('quantidade', doseValues.amount);
            form.setFieldValue(
              'dose',
              doseValues.dose && doseValues.dose * form.values.repetir
            );
            setDoseModal(false);
          }}
        />
      </Row>
      <ComponentsInput
        setComponentsEditing={setComponentsEditing}
        componentsEditing={componentsEditing}
        validatingComponents={validatingComponents}
      />
    </div>
  );
};

interface IComponentsInput {
  setComponentsEditing: Dispatch<SetStateAction<number[]>>;
  componentsEditing: number[];

  validatingComponents: boolean;
}

const ComponentsInput: FC<IComponentsInput> = ({
  setComponentsEditing,
  componentsEditing,
  validatingComponents,
}) => {
  const productPrescriptionList = useControllerQueryListApiHook({
    uniqId: FETCH_LIST_PRODUCT_PRESCRIPTION,
    entityApi: ProductAPI.getProductPrescriptionList,
    autoIncrement: true,
    autoIncrementCustomId: 'produtoPrescricaoExternalId',
    pagination: {
      sorter: { column: 'descricao', direction: 'DESC' },
      filter: [{ filterName: 'classeProdutoIds', value: [0, 1] }],
    },
  });

  const { t } = useTranslation();

  const form: any = useFormikContext();

  const componenti18n = useMemo(
    () => 'saleOrder.editOrder.SaleOrder.addManipulatedRecipe.components',
    []
  );

  const thereAreSomeComponentSelected = useMemo(() => {
    return form?.values?.itens.length > 1;
  }, [form?.values?.itens.length]);

  const dummy: MutableRefObject<any> = useRef(null);
  //when selecting a new component, the focus is redirected to the end of the list
  useEffect(() => {
    dummy.current && dummy.current.scrollIntoView({ behavior: 'smooth' });
  }, [form?.values?.itens.length]);

  return (
    <div className={styles['container-components']}>
      <div className={styles['horizontal-divider']} />
      <Row gutter={[6, 0]} className={styles['header-components']}>
        <Col flex={'78%'}>
          <Divflex style={{ justifyContent: 'space-between' }}>
            <Label
              type="ui-tiny-content"
              children={t(componenti18n + '.componentCollum')}
              required
              color="text-50"
            />
            {thereAreSomeComponentSelected && (
              <Label
                type="ui-tiny-content"
                children={`${form.values?.itens.length - 1} ${
                  form.values?.itens.length === 2
                    ? t(componenti18n + '.componentAmount')
                    : t(componenti18n + '.componentsAmount')
                }`}
                color="text-300"
              />
            )}
          </Divflex>
        </Col>
        <Col flex={'22%'}>
          <Label
            type="ui-tiny-content"
            children={t(componenti18n + '.amountCollum')}
            required
            color="text-50"
          />
        </Col>
      </Row>
      <div className={styles['horizontal-divider-bottom']} />
      <div className={styles['component-line']}>
        <SortableList
          items={form?.values?.itens}
          onChange={(x) => form.setFieldValue('itens', x)}
          renderItem={(item, index) => (
            <SortableList.Item id={item.id}>
              <ComponentsLine
                setItems={(x) => form.setFieldValue('itens', x)}
                index={index}
                productPrescriptionList={productPrescriptionList}
                setComponentsEditing={setComponentsEditing}
                componentsEditing={componentsEditing}
                validatingComponents={validatingComponents}
              />
            </SortableList.Item>
          )}
        />
        <div ref={dummy} />
      </div>
    </div>
  );
};
