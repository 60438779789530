import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { PageLoading } from '../../../../../Layouts/PageLoading';
import { HorizontalTabs } from '../../../../../Components';
import PharmaceuticalFormDetails from '../Details';
import { FETCH_GET_PHARMACEUTICAL_FORM } from '../../../../../ControllerApiHook/UniqIds/Production/PharmaceuticalFormKeys';
import { useParams } from 'react-router';
import { useControllerQueryApiHook } from '@/ControllerApiHook/Controller';
import { IGetPharmaceuticalForm } from '@/Data/Interfaces/response/PharmaceuticalForm/IPharmaceuticalFormResponse';
import { PharmaceuticalFormAPI } from '@/Data/API/Production/PharmaceuticalForm';
import { PackagingsClassificationsList } from '../Details/Components/PackagingsClassificationsList';
import { DeletePharmaceuticalFormModal } from '../Details/Components/DeleteModal/DetailsForm.modal';

const FormPharmaceuticalForm: React.FC = () => {
  const [disabled, setDisabled] = useState(true);
  const [visibleModal, setVisibleModal] = useState(false);
  const { externalId, tab } = useParams<{ externalId: string; tab: string }>();

  const { data: pharmaceuticalForm } =
    useControllerQueryApiHook<IGetPharmaceuticalForm>({
      uniqId: FETCH_GET_PHARMACEUTICAL_FORM,
      entityApi: PharmaceuticalFormAPI.getCurrentPharmaceuticalForm,
      externalIds: [externalId],
    });

  const { t } = useTranslation();

  return (
    <PageLoading>
      <HorizontalTabs
        type="secondary"
        items={[
          {
            label: t('common.details'),
            key: 'details',
            children: (
              <PharmaceuticalFormDetails
                disabled={disabled}
                onChangeDisabled={(x: boolean) => setDisabled(x)}
              />
            ),
          },
          {
            label: t('common.packingClassification'),
            key: 'packingClassification',
            children: <PackagingsClassificationsList />,
          },
        ]}
        dropdownItens={[
          {
            label: t('common.delete'),
            icon: 'trash',
            permission: {
              permission: 6017,
              tooltip:
                'noPermission.production.pharmaceuticalForm.permissionsDelete',
            },
            onClick: () => setVisibleModal(true),
          },
        ]}
        dropdownDirection="bottomRight"
        dropdownTitle={t('admin.editUserPage.tab.buttonActions.title')}
        leftIcon="menu-ellipsis-horizontal"
        rightButton={{
          ...(disabled &&
            tab === 'details' &&
            pharmaceuticalForm && {
              onClick: () => setDisabled(false),
              isVisible: disabled,
              codePermissionEdit: 6015,
              messageTooltipPermission:
                'noPermission.production.pharmaceuticalForm.permissionsEdit',
            }),
        }}
      />
      {pharmaceuticalForm && (
        <DeletePharmaceuticalFormModal
          pharmaceuticalForm={pharmaceuticalForm}
          visibleModal={visibleModal}
          changeVisibleModal={(value) => setVisibleModal(value)}
        />
      )}
    </PageLoading>
  );
};

export default FormPharmaceuticalForm;
