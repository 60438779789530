import { useMemo } from "react";
import _ from 'lodash';
import { IUseMemorizations } from "./interfaces";

export const useModalBodyMemorizations = ({ mutateAsync }: IUseMemorizations) => {
  const fetchProductGroupValidationName = useMemo(
    () =>
      _.debounce(async (description: string) => {
        return await mutateAsync({
          descricao: description,
        });
      }, 500),
    [mutateAsync]
  );

  return {
    fetchProductGroupValidationName
  }
};