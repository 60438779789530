import { ModalFuncProps } from 'antd';
import { FC } from 'react';
import { BodyModal } from '../../Components/Modal';
import { Footer as ModalFooter } from '../../Components/Modal/Components/Footer';
import {
  ICannotDeleteModalInfo,
  ICannotDeleteModalProps,
  ICommonError,
  IGlobalModal,
  IManyItemCannotDeleteModalProp,
} from '../../Utils/ErrorCodeUtils/ErrorHandlingUtils';
import { Icon, Text } from '../../Components';
import { useTranslation } from 'react-i18next';
import { ReactMarkdown } from 'react-markdown/lib/react-markdown';
import { Divflex } from '../../Layouts/DivWhithFlex';

const ContentOneEntity: FC<
  ICommonError & { obj: ICannotDeleteModalProps; formName: string }
> = ({ content, obj, formName }) => (
  <BodyModal
    body={
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <Text type="ui-tiny-content" withoutMarginBottom color="text-50">
          <ReactMarkdown>
            {`${obj.subTitleModal} **${content[0][formName]}!**`}
          </ReactMarkdown>
        </Text>

        <Text type="ui-tiny-content" style={{ marginTop: 20 }} color="text-50">
          {`${obj.reasonTranslation}`}
        </Text>

        {obj.warningModal && (
          <Divflex style={{ marginTop: 24 }}>
            <Icon
              icon={'exclamation-upside-down'}
              size={'SM'}
              color={'text-400'}
              style={{ padding: '18px 18px 0 8px' }}
            />
            <Text
              type="paragraph2"
              color={'text-400'}
              children={`${obj.warningModal}`}
            />
          </Divflex>
        )}
      </div>
    }
    style={{ padding: 20 }}
  />
);
const ContentManyEntity: FC<
  ICommonError & { obj: IManyItemCannotDeleteModalProp; formName: string }
> = ({ content, obj, formName }) => (
  <BodyModal
    body={
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <Text type="ui-tiny-content" color="text-50">
          <ReactMarkdown children={obj.subTitleModal} />
        </Text>
        <ul style={{ padding: '0 20px 1em 20px', margin: '0' }}>
          {content?.slice(0, 4).map((x: any) => (
            <li key={x.ExternalId} style={{ height: '18px' }}>
              <Text color="text-50" type="ui-tiny-bold">
                {x[formName]}
              </Text>
            </li>
          ))}
          {content?.length > 4 && (
            <li style={{ height: '18px' }}>
              <Text
                color="text-300"
                type="ui-tiny-content"
                children={`+ ${content?.length - 4} ${obj.entity}`}
              />
            </li>
          )}
        </ul>

        <Text
          type="ui-tiny-content"
          color="text-50"
        >{`${obj.reasonTranslation}`}</Text>

        {obj.warningModal && (
          <Divflex style={{ marginTop: 24 }}>
            <Icon
              icon={'exclamation-upside-down'}
              size={'SM'}
              color={'text-400'}
              style={{ padding: '18px 18px 0 8px' }}
            />
            <Text
              type="paragraph2"
              color={'text-400'}
              children={`${obj.warningModal}`}
            />
          </Divflex>
        )}
      </div>
    }
    style={{ padding: '12px 20px 20px 20px' }}
  />
);

interface ITitle {
  title: string;
  formName: string;
  hideObjCannotDelete?: boolean;
  complement?: string;
}

const TitleOneEntity = ({
  title,
  content,
  formName,
  hideObjCannotDelete,
}: ICommonError & ITitle) => (
  <Text
    type="heading4"
    children={`${title} ${
      hideObjCannotDelete ? '' : `"${content[0][formName]}"`
    }`}
    color="text-50"
  />
);
const TitleManyEntity = ({
  title,
  content,
  hideObjCannotDelete,
  complement,
}: ICommonError & ITitle) => (
  <Text
    type="heading4"
    children={`${
      hideObjCannotDelete
        ? `${title}`
        : `${title} ${content.length} ${complement || ''}`
    }`}
    color="text-50"
  />
);
const Footer: FC<{
  params: { localEstoqueExternalId: string[] };
  instance: IGlobalModal;
}> = ({ instance }) => {
  const { t } = useTranslation();

  return (
    <ModalFooter
      okButtonName={t('supply.supplyPlaces.modal.cannotDelete.ok')}
      onOkClick={() => instance.destroy()}
      withoutOkCancel
      okButtonType="secondary"
      cancelDataTestId="cancel-button-error-modal"
    />
  );
};

export const CannotDeleteModalDefault: (
  error: ICommonError,
  params: { localEstoqueExternalId: string[] },
  instance: IGlobalModal,
  modalCannotDeleteDefault: ICannotDeleteModalInfo
) => ModalFuncProps = (error, params, instance, modalCannotDeleteDefault) => ({
  closable: true,
  closeIcon: <Icon icon="close-x" color="text-400" size="XXL" />,
  icon: <></>,
  title:
    error.content.length > 1
      ? TitleManyEntity({
          ...error,
          title: modalCannotDeleteDefault.manyErrorsModal.entity,
          complement: modalCannotDeleteDefault.manyErrorsModal.complement,
          formName: modalCannotDeleteDefault.formName,
          hideObjCannotDelete: modalCannotDeleteDefault.hideObjCannotDelete,
        })
      : TitleOneEntity({
          ...error,
          title: modalCannotDeleteDefault.oneErrorModal.titleModal,
          formName: modalCannotDeleteDefault.formName,
          hideObjCannotDelete: modalCannotDeleteDefault.hideObjCannotDelete,
        }),
  content:
    error.content.length > 1 ? (
      <ContentManyEntity
        {...error}
        obj={modalCannotDeleteDefault.manyErrorsModal}
        formName={modalCannotDeleteDefault.formName}
      />
    ) : (
      <ContentOneEntity
        {...error}
        obj={modalCannotDeleteDefault.oneErrorModal}
        formName={modalCannotDeleteDefault.formName}
      />
    ),
  footer: <Footer params={params} instance={instance} />,
  width: '400px',
});
