import { IUseMemorizations } from './interfaces';

export const useDetailMemorizations = ({
  standardFormula,
  reason,
}: IUseMemorizations) => {
  const initialValues = standardFormula
    ? {
        ...standardFormula,
        produtoExternalId: standardFormula.produtoExternalId,
        productLabelInitialValue: {
          id: standardFormula?.produtoExternalId,
          label: standardFormula?.descricaoProdutoFormula,
        },
        formaFarmaceuticaInitialValue: {
          id: standardFormula?.formaFarmaceuticaExternalId,
          label: standardFormula?.descricaoFormaFarmaceutica,
        },
        quantidadePadrao: standardFormula?.quantidadePadrao,
        unidadeMedidaId: standardFormula?.unidadeMedidaAbreviacao,
        formulaPadraoDesmembramento:
          standardFormula?.formulaPadraoDesmembramentoId,
        diasValidade: standardFormula?.diasValidade,
        diluicao: standardFormula?.diluicao,
        razao: reason,
        densidade: standardFormula?.densidade,
        produtos: standardFormula?.produtos?.map((item) => ({
          ...item,
          productLabelInitialValue: {
            id: item?.produtoExternalId,
            label: item?.descricaoProduto,
          },
        })),
        procedimento: standardFormula?.procedimento,
        rodape: standardFormula?.rodape,
      }
    : undefined;

  return {
    initialValues,
  };
};
