import { useTranslation } from 'react-i18next';

export const useConfigChangeText = () => {
  const { t } = useTranslation();

  const configChangeText = (isEqual: boolean, isFirstRender: boolean) => {
    if (isEqual && isFirstRender) {
      return '';
    }

    if (isEqual && !isFirstRender) {
      return t('common.savedChanges');
    }

    return t('common.hearChanges');
  };

  return { configChangeText };
};
