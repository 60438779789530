import { useTranslation } from "react-i18next";

export const useAccumulateMemorizations = () => {
  const { t } = useTranslation();

  const items = [
    { id: 1, label: t('products.associated.yes') },
    { id: 2, label: t('products.associated.no') }
  ];

  return { items };
};