import { useState } from 'react';

export const useListStates = () => {
  const [visibleCreateModal, setVisibleCreateModal] = useState(false);

  return {
    visibleCreateModal,
    setVisibleCreateModal,
  };
};
