import { 
  TypePurchaseOrderEnum 
} from '@/Data/Interfaces/model/typePurchaseOrderEnum';
import { IUseFunctions } from './interfaces';

export const useDisapproveButtonFunctions = ({
  statusPurchaseOrder,
  setReproveModal,
  setReverseModal
}: IUseFunctions) => {
  const onClick = () =>
    statusPurchaseOrder === TypePurchaseOrderEnum.waitingApproval
      ? setReproveModal(true)
      : setReverseModal(true);

  return {
    onClick
  };
};