import {
  DELETE_PRODUCT
} from '@/ControllerApiHook/UniqIds/Supply/ProductKeys';
import { useControllerMutateApiHook } from '@/ControllerApiHook/Controller';
import { ProductAPI } from '@/Data/API/Supply/Product';

export const useDeleteModalCallAPI = () => {
  const deleteProduct: any = useControllerMutateApiHook({
    uniqId: DELETE_PRODUCT,
    entityApi: ProductAPI.deleteProduct
  });

  return {
    deleteProduct
  };
};