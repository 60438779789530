import { IDcbListData } from '@/Data/Interfaces/response/Dcb/IDcbResponse';
import { IUseMemorizations } from './interfaces';

export const useDcbMemorizations = ({ dcbList }: IUseMemorizations) => {
  const items = dcbList
    ? dcbList?.data?.map((e: IDcbListData) => ({
        id: e.id,
        label: e.nomeDcb,
        subLabel: `N° DCB: ${e.numeroDcb}`,
      })) : [];

  return { items };
};
