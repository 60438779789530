import { useFormikContext } from 'formik';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import { useControllerMutateApiHook } from '@/ControllerApiHook/Controller';
import { PersonAPI } from '@/Data/API/People/PersonApi';
import { VALIDATION_CPF_SUPPLIER } from '@/ControllerApiHook/UniqIds/People/ SupplierKeys';
import { cpf } from 'cpf-cnpj-validator';

export const useInputCpfFunctions = (cpfInitial: string) => {
  const { t } = useTranslation();
  const form: any = useFormikContext();

  const onCpfValidationError = useCallback(() => {
    form.setErrors({
      ...form.errors,
      cpf: t('purchasing.provider.create.errors.cpfAlreadyUsed'),
    });
  }, [form, t]);

  const { mutateAsync, error } = useControllerMutateApiHook({
    uniqId: VALIDATION_CPF_SUPPLIER,
    entityApi: PersonAPI.cpfValidation,
    options: {
      onError: onCpfValidationError,
    },
  });

  const fetchCpfValidation = useCallback(
    _.debounce(async (cpf: string) => {
      if (cpf.trim() !== '') {
        try {
          await mutateAsync({
            cpf: cpf,
          });
          form.setErrors({
            ...form.errors,
            cpf: undefined,
          });
        } catch {}
      } else {
        form.setErrors({
          ...form.errors,
          cpf: undefined,
        });
      }
    }, 1000),
    [mutateAsync, form.errors, form.values.descricao]
  );

  const onChange = useCallback(
    (desc: string) => {
      form.setErrors({
        ...form.errors,
        cpf: undefined,
      });
      const formattedCpf = desc.replace(/\D/g, '');
      if (cpfInitial === formattedCpf || !cpf.isValid(desc)) return;

      fetchCpfValidation(formattedCpf);
    },
    [fetchCpfValidation]
  );

  return {
    onChange,
    error,
  };
};
