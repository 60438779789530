import { useControllerQueryApiHook } from '@/ControllerApiHook/Controller';
import { FETCH_GET_PACKING_CLASSIFICATION } from '@/ControllerApiHook/UniqIds/Production/PackingClassificationKeys';
import { PackingClassificationAPI } from '@/Data/API/Production/PackingClassification';
import { IGetPackingClassification } from '@/Data/Interfaces/response/PackingClassification/IPackingClassificationResponse';
import { IUseCallAPI } from './interfaces';

export const useEditCallAPI = ({ externalId }: IUseCallAPI) => {
  const { data: packingClassification } =
    useControllerQueryApiHook<IGetPackingClassification>({
      uniqId: FETCH_GET_PACKING_CLASSIFICATION,
      entityApi: PackingClassificationAPI.getCurrentClassificationPacking,
      externalIds: [externalId],
    });

  return { packingClassification };
};
