import { Button } from '@/Components';
import _ from 'lodash';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import styles from '../../CapsuleColors.module.scss';
import { NotificationActions } from '@/Store/Notification/Notification.actions';
import { useDispatch } from 'react-redux';

interface IFooterButtons {
  form: any
  isLoadingEditCapsuleColor: boolean;
  onChangeDisabled: (x: boolean) => void;
}

export const FooterButtons: FC<IFooterButtons> = ({
  form,
  isLoadingEditCapsuleColor,
  onChangeDisabled,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  return (
    <div className={styles['footer-button']}>
      <Button
        type="secondary"
        children={t('common.cancel')}
        onClick={() => {
          onChangeDisabled(true)
          form.resetForm();
          dispatch(NotificationActions.setCurrentFormIsDirty(false));
        }}
      />
      <Button
        type="primary"
        children={
          isLoadingEditCapsuleColor ? t('common.saving') : t('common.save')
        }
        htmlType="submit"
        loading={isLoadingEditCapsuleColor}
        dataTestId="submit-capsule-color"
      />
    </div>
  );
};
