import { useState } from "react";

export const useProductCardStates = () => {
  const [
    productRowAmount, 
    setProductRowAmount
  ] = useState<string[]>([]);

  return {
    productRowAmount,
    setProductRowAmount
  };
};