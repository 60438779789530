import { useCallback } from 'react';
import { IUseFunctions } from './interfaces';

export const useCreateFunctions = ({
  refetchSearchLaboratoryPage,
}: IUseFunctions) => {
  const onSearch = useCallback(
    (search?: string) => {
      refetchSearchLaboratoryPage({
        search: search,
      });
    },
    [refetchSearchLaboratoryPage]
  );

  return { onSearch };
};
