import { Status, Text } from '../../../../../Components';
import { TFunction } from 'i18next';
import { IInvoiceListData } from '../../../../../Data/Interfaces/response/Purchase/IInvoiceResponse';
import DateUtils from '../../../../../Utils/DateUtils';
import { currencyFormater } from '../../../../../Utils/Currency';
import {
  filterStateColor,
  filterStateText,
  formatSerialNumber,
} from './utils/functions';
import { IPreferences } from '@/Utils/ThemeUtils';

export const invoiceColumn = (
  t: TFunction,
  preference: IPreferences | undefined
) => {
  return [
    {
      title: t('purchasing.invoice.list.nameOfSupplier'),
      dataIndex: 'nomeFornecedor',
      key: 'nomeFornecedor',
      width: '25%',
      isHighlightable: true,
      render: (x: string, row: IInvoiceListData) => (
        <Text type="ui-tiny-content" color="text-50">
          {row.fornecedor}
        </Text>
      ),
    },
    {
      title: t('purchasing.invoice.list.serialNumber'),
      dataIndex: 'numeroSerie',
      key: 'numeroSerie',
      width: '20%',
      render: (x: string, row: IInvoiceListData) => (
        <Text type="ui-tiny-content" color="text-50">
          {formatSerialNumber(row.numero, 9)} /{' '}
          {formatSerialNumber(row.serie, 3)}
        </Text>
      ),
    },
    {
      title: t('purchasing.invoice.list.releaseDate'),
      dataIndex: 'dataLancamento',
      key: 'dataLancamento',
      sorter: true,
      sortColumn: 'dataLancamento',
      defaultSortOrder: 'descend',
      render: (x: string, row: IInvoiceListData) => (
        <Text type="ui-tiny-bold" color="text-50">
          {`${DateUtils.convertDate(x, preference?.padraoData)} ${t(
            'common.dateBreakApart'
          )} ${DateUtils.convertDate(x, preference?.padraoHora)}`}
        </Text>
      ),
    },
    {
      title: t('purchasing.invoice.list.dateIssue'),
      dataIndex: 'tipoPessoaDescricao',
      key: 'tipoPessoaDescricao',
      render: (x: string, row: IInvoiceListData) => (
        <Text type="ui-tiny-content" color="text-50">
          {`${DateUtils.convertDate(row.dataEmissao, preference?.padraoData)}`}
        </Text>
      ),
    },
    {
      title: t('purchasing.invoice.list.invoiceValue'),
      dataIndex: 'ativo',
      key: 'ativo',
      render: (x: string, row: IInvoiceListData) => (
        <Text type="ui-tiny-content" color="text-50">
          {currencyFormater(row.valorTotalNotaFiscal)}
        </Text>
      ),
    },
    {
      title: t('purchasing.invoice.list.status'),
      dataIndex: 'ativo',
      key: 'ativo',
      render: (x: string, row: IInvoiceListData) => (
        <Status
          iconColor="status-disabled-base"
          leftIcon={row.status === 1 ? 'pencil' : undefined}
          type={filterStateColor(row.status)}
        >
          {t(filterStateText(row.status))}
        </Status>
      ),
    },
  ];
};
