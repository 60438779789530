import { FC } from 'react';
import { Col } from 'antd';
import { PercentageInput } from '@/Components/Inputs/PercentageInput';
import { useTranslation } from 'react-i18next';
import { useSupplierDilutionFunctions } from './Hooks/useSupplierDilutionFunctions';

export const SupplierDilution: FC = () => {
  const { t } = useTranslation();

  const { onChange } = useSupplierDilutionFunctions();

  return (
    <Col span={6}>
      <PercentageInput
        name="loteInformacaoTecnica.diluicaoFornecedor"
        label={[
          {
            children: t('supply.lot.create.supplierDilution'),
          },
        ]}
        placeHolder={t('supply.lot.create.supplierDilution')}
        required
        withTooltip={{
          icon: 'exclamation-upside-down',
          title: t('supply.lot.create.toolTips.supplierDilution'),
        }}
        onChange={onChange}
      />
    </Col>
  );
};
