import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { NumberInput } from '@/Components';
import { IAmount } from './interfaces';
import { useAmountMemorizations } from './Hooks/useAmountMemorizations';
import { useAmountFunctions } from './Hooks/useAmountFunctions';

export const Amount: FC<IAmount> = ({
  index,
  formValues,
  setTotal,
  getTotalValue
}) => {
  const { t } = useTranslation();

  const {
    unitsMeasurementsMapping
  } = useAmountMemorizations();

  const {
    onChange
  } = useAmountFunctions({
    setTotal,
    getTotalValue
  })

  return (
    <>
      <NumberInput
        name={`pedidoCompraItens.${index}.quantidade`}
        label={
          index === 0
            ? t('purchasing.purchaseOrder.create.productsCardField.amount')
            : undefined
        }
        required
        placeHolder={t(
          'purchasing.purchaseOrder.create.productsCardPlaceHolders.amount'
        )}
        withoutMarginBottom
        rightIcon={{
          titleString:
            unitsMeasurementsMapping[formValues.unidadeMedidaId] || ' ',
        }}
        disabled={!formValues.productExternalId}
        decimalScale={4}
        fixedDecimalScale
        withThousandSeparator
        withDecimalSeparator
        onChange={onChange}
      />
    </>
  );
};