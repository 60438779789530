import { FC } from 'react';
import { Text } from '@/Components';
import { DeleteModalOnTable } from '@/Utils/DeleteModalUtils';
import { useTranslation } from 'react-i18next';
import ReactMarkdown from 'react-markdown';
import { IDeleteModalBody } from './interfaces';

export const DeleteModalBody: FC<IDeleteModalBody> = ({ prescriber }) => {
  const { t } = useTranslation();
  
  return (
    <div style={{ maxWidth: '400px' }}>
      {prescriber.length > 1 ? (
        DeleteModalOnTable(
          prescriber,
          `Você tem certeza que deseja excluir os prescritores:`,
          'nomeCompleto',
          prescriber.length > 5 ? 'Prescritores' : 'Prescritor'
        )
      ) : (
        <div style={{ padding: '20px' }}>
          <Text type="ui-tiny-content" withoutMarginBottom color="text-50">
            <ReactMarkdown>
              {t('prescribers.listPage.deleteModal.description', {
                prescriber: `**${prescriber[0].nomeCompleto}**`,
              })}
            </ReactMarkdown>
          </Text>
          <Text
            type="ui-tiny-content"
            color="text-50"
            children={t('modal.deleteModal.simpleFooter')}
          />
        </div>
      )}
    </div>
  );
};
