import { i18n } from '@/Components';
import { 
  CREATE_COMPANY_TYPE 
} from '@/ControllerApiHook/UniqIds/Accountmanager/CompanyKeys';
import { 
  CREATE_CONGLOMERATE_TYPE 
} from '@/ControllerApiHook/UniqIds/Accountmanager/ConglomerateKeys';
import { useControllerMutateApiHook } from '@/ControllerApiHook/Controller';
import { ConglomerateAPI } from '@/Data/API/Accountmanager/ConglomerateApi';
import {
  messageErrorToast,
  messageSucessToast,
} from '@/Utils/MessageErrorToast';
import { CompanyAPI } from '@/Data/API/Accountmanager/CompanyApi';
import { history } from '@/Store';

export const useCompanyCreateCallAPI = () => {
  const createCompany = useControllerMutateApiHook<any>({
    uniqId: CREATE_COMPANY_TYPE,
    entityApi: CompanyAPI.createCompany,
    options: {
      onError: (x) => messageErrorToast(x),
      onSuccess: () => {
        messageSucessToast(
          i18n.t('adminBootis.createCompany.messages.success')
        );
        history.push('/admin-bootis/company');
      },
    },
  });

  const createConglomerate = useControllerMutateApiHook<any>({
    uniqId: CREATE_CONGLOMERATE_TYPE,
    entityApi: ConglomerateAPI.createConglomerate,
    options: {
      onError: (x) => messageErrorToast(x),
      onSuccess: () => {
        messageSucessToast(
          i18n.t('adminBootis.createCompany.messages.success')
        );
        history.push('/admin-bootis/company');
      },
    },
  });

  return {
    createCompany,
    createConglomerate
  };
};