import { useFormikContext } from "formik";
import { useCallback } from "react";

export const useDilutionFunctions = () => {
  const form: any = useFormikContext();

  const onChange = useCallback((e: any) => {
    if (!e) {
      return form.setFieldValue(
        'informacaoTecnica.concentracaoAgua',
        ''
      );
    }
    const concentrationWater = 100 - 100 / +e;
    form.setFieldValue(
      'informacaoTecnica.concentracaoAgua',
      concentrationWater.toFixed(2)
    );
  }, [form]);

  return {
    onChange
  };
};