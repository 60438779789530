import { FC } from 'react';
import { Form } from '../../../../../../../Components';
import { DetailSchema } from './DetailForm.schema';
import { useParams } from 'react-router';
import { PersonalDataCard } from './Components/Cards/PersonalDataCard';
import { useUserDetailsCallAPI } from './Hooks/useUserDetailsCallAPI';
import { useUserDetailsStates } from './Hooks/useUserDetailsStates';
import { useUserDetailsFunctions } from './Hooks/useUserDetailsFunctions';
import { useUserDetailsMemorizations } from './Hooks/useUserDetailsMemorizations';
import { ConnectedFocusError } from '@/Components/ConnectedFocusError';

export const DetailForm: FC = () => {
  const params: Readonly<Partial<{ externalId: string | undefined }>> =
    useParams();

  const { buttonDisabled, setButtonDisabled } = useUserDetailsStates();

  const { editUser, user } = useUserDetailsCallAPI({
    userExternalId: params.externalId,
    changeEditDisabled: () => setButtonDisabled(true),
  });

  const { 
    initialValues,
    orderedErrors 
  } = useUserDetailsMemorizations({ user });

  const { onSubmit } = useUserDetailsFunctions({
    editUser,
    datePattern: user?.preferencias.padraoData,
  });

  return (
    <Form
      initialValues={initialValues}
      onSubmit={onSubmit}
      schema={DetailSchema()}
    >
      {(form) => (
        <>        
          <ConnectedFocusError orderedErrors={orderedErrors} />
          <PersonalDataCard
            user={user}
            form={form}
            editDisabled={buttonDisabled}
            changeEditDisabled={() => setButtonDisabled(!buttonDisabled)}
            editLoading={editUser.isLoading}
          />
        </>
      )}
    </Form>
  );
};
