import { Divflex } from '../../../../../../Layouts/DivWhithFlex';
import { Text } from '../../../../../../Components';
import { FooterThisActionIsPermanent } from '../../../../../../Components/Table/components/Modals/DeleteModal';
import { DeleteModalOnTable } from '../../../../../../Utils/DeleteModalUtils';
import ReactMarkdown from 'react-markdown';
import { IInvoiceListData } from '../../../../../../Data/Interfaces/response/Purchase/IInvoiceResponse';
import { TFunction } from 'i18next';
import {
  formatInvoiceIdentifier,
  formatSerialNumber,
} from '../utils/functions';

export const TitleHandler = (
  rows: IInvoiceListData[],
  t: TFunction
): string => {
  if (rows.length === 1) {
    const identifier = formatInvoiceIdentifier(rows[0]);
    return `${t('common.delete')} ${t(
      'common.invoiceAbbreviation'
    )} "${identifier}"?`;
  }

  return `${t('common.delete')} ${t('common.invoices')}?`;
};

export const BodyHandler = (rows: IInvoiceListData[], t: TFunction) => {
  if (rows.length > 1) {
    const formattedRows = rows.map((row) => ({
      ...row,
      identifier: `${formatInvoiceIdentifier(row)} (${row.fornecedor})`,
    }));
    const title = `${t('modal.deleteModal.many.title')} ${t(
      'common.theInvoices'
    )}:`;
    return DeleteModalOnTable(
      formattedRows,
      title,
      'identifier',
      t('common.invoices'),
      undefined,
      undefined,
      'auto'
    );
  }

  if (rows.length === 1) {
    return (
      <div style={{ padding: '20px' }}>
        <Divflex style={{ flexDirection: 'column' }}>
          <Text color="text-50" type="ui-tiny-content">
            <ReactMarkdown>
              {t('modal.deleteModal.single.invoiceQuestion', {
                fornecedor: `**${rows[0].fornecedor}**`,
                numero: `**${formatSerialNumber(rows[0].numero, 9)}**`,
                serie: `**${formatSerialNumber(rows[0].serie, 3)}**`,
              })}
            </ReactMarkdown>
          </Text>
        </Divflex>
        <FooterThisActionIsPermanent />
      </div>
    );
  }
};
