import React, { FC, useRef } from 'react';
import { Input, InputRef } from 'antd';
import { Field } from 'formik';
import { IField } from '../../interfaces/IField';
import { InputLabel } from '../../Label';
import { Icon, Tooltip } from '../../..';
import { LocalizaPropriedadePorPath } from '../../../../Utils/FormikUtils';
import { Divflex } from '../../../../Layouts/DivWhithFlex';
import Spinner from '../../../Spinner';
import { INumberInput } from '../types';
import { BeforeAddon } from './components/before';
import { AfterAddon } from './components/after';

import './VisualInput.override.scss';
import styles from './VisualInput.module.scss';

export const VisualInput: FC<
  INumberInput & {
    currentPrefix?: string;
    currentSuffix?: string;
    separators?: {
      decimalScale?: number;
      thousandSeparator?: string;
      decimalSeparator?: string;
    };
    outsideRef?: any;
  }
> = ({
  name,
  label,
  maxLength,
  required,
  placeHolder,
  error,
  success,
  rightIcon,
  rightIconTooltipOnly,
  leftIcon,
  withTooltip,
  withoutMarginBottom,
  disabled,
  className,
  supportText,
  onChange,
  onBlur,
  isLoading,
  value,
  withStatusIcon,
  dropdownLeft,
  dropdownRight,
  outsideRef,
  withLoadingLabel,
  isSuccessIcon,
  withBoldContent,
  dataTestId,
  containerDataTestId,
  rightSelectDataTestId,
  leftSelectDataTestId,
}) => {
  const innerRef = useRef<React.Ref<InputRef> | null>();
  const inputRef = outsideRef || innerRef;

  return (
    <div
      className={`${withoutMarginBottom ? '' : styles['margin-bottom']} ${
        styles['Container']
      }`}
    >
      <Field name={name}>
        {(props: IField) => {
          const hasError =
            error ||
            !!(
              props.form &&
              props.form.errors &&
              props.meta.touched &&
              LocalizaPropriedadePorPath(props.form.errors, name)
            );
          const classNames = className;

          return (
            <>
              {label && (
                <Divflex>
                  <InputLabel
                    label={label}
                    props={props}
                    withStatusIcon={withStatusIcon}
                    required={required}
                    withLoadingIcon={withLoadingLabel}
                    isSuccessIcon={isSuccessIcon}
                  />
                  {withTooltip && (
                    <Tooltip
                      title={withTooltip.title}
                      showMe
                      children={
                        <Icon
                          className={styles['icon-with-tooltip']}
                          icon={withTooltip.icon}
                          color="text-300"
                          size="S"
                        />
                      }
                    />
                  )}
                </Divflex>
              )}

              <div
                data-testid={containerDataTestId}
                className={`
              ${styles['input-container']} 
              ${classNames} 
              ${leftIcon ? styles['with-left-icon'] : null}
              ${
                rightIcon || success || hasError
                  ? styles['with-right-icon']
                  : null
              } ${hasError ? styles['error'] : null}
              ${success ? styles['success'] : null}
              ${disabled ? styles['disabled'] : null}`}
              >
                {supportText && (
                  <div className={styles['supportText']}>{supportText}</div>
                )}

                <Input
                  id={name}
                  data-testid={dataTestId}
                  // @ts-ignore
                  ref={inputRef}
                  {...props.field}
                  className={`${
                    withBoldContent ? styles['bold-content-input'] : null
                  } ${styles['TextInput']}`}
                  style={{ paddingRight: supportText ? '48px' : undefined }}
                  autoComplete="off"
                  maxLength={maxLength}
                  placeholder={placeHolder}
                  disabled={disabled || props.form.isSubmitting}
                  value={value}
                  onBlur={onBlur}
                  onChange={(event) => onChange && onChange(event)}
                  addonBefore={
                    (leftIcon?.titleString || dropdownLeft) && (
                      <BeforeAddon
                        disabled={disabled}
                        dropdownLeft={dropdownLeft}
                        leftIcon={leftIcon}
                        dataTestId={leftSelectDataTestId}
                      />
                    )
                  }
                  addonAfter={
                    (rightIcon?.titleString || dropdownRight) && (
                      <AfterAddon
                        disabled={disabled}
                        dropdownRight={dropdownRight}
                        rightIcon={rightIcon}
                        dataTestId={rightSelectDataTestId}
                      />
                    )
                  }
                />
                {hasError ? (
                  <Icon
                    className={styles['Icon']}
                    size="M"
                    color="status-danger-base"
                  />
                ) : null}

                {success ? (
                  <Icon
                    className={styles['Icon']}
                    icon="check"
                    size="M"
                    color="status-success-base"
                  />
                ) : null}

                {rightIconTooltipOnly?.icon ? (
                  <Tooltip
                    title={rightIconTooltipOnly.toolTipTitle}
                    showMe={!!rightIconTooltipOnly.toolTipTitle}
                    placement="bottomRight"
                  >
                    <Icon
                      className={styles['Icon']}
                      icon={rightIconTooltipOnly.icon}
                      size="SM"
                      color="text-400"
                    />
                  </Tooltip>
                ) : null}

                {isLoading && (
                  <div className={styles['spinner']}>
                    <Spinner />
                  </div>
                )}
                {props.form &&
                props.form.errors &&
                LocalizaPropriedadePorPath(props.form.errors, name) ? (
                  <p className={styles['errorText']}>
                    {LocalizaPropriedadePorPath(props.form.errors, name)}
                  </p>
                ) : null}
              </div>
            </>
          );
        }}
      </Field>
    </div>
  );
};
