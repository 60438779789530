import { IGetCurrencyResponse } from '../../../Data/Interfaces/response/Company/ICompanyResponse';
import BRLFlag from '../../../Static/img/flags/BRL-flag.svg';
import USDFlag from '../../../Static/img/flags/USD-flag.svg';

export const isFlag = (currency: IGetCurrencyResponse) => {
  for (const moeda in currency.moedas) {
    switch (currency.moedas[moeda].label) {
      case 'BRL':
        currency.moedas[moeda].img = <img src={BRLFlag} alt="BRL" />;
        break;
      case 'USD':
        currency.moedas[moeda].img = <img src={USDFlag} alt="USD" />;
        break;
    }
  }
};
