import { IListDcbResponse } from '@/Data/Interfaces/response/Dcb/IDcbResponse';
import { QueryFunctionContext } from '@tanstack/react-query';
import { Http, IQueryListParams } from '@/Utils/Http';

export class DcbAPI {
  public static async listDropdownDcb(
    context: QueryFunctionContext
  ): Promise<IListDcbResponse | null> {
    if (!context.queryKey[1]) return null;

    return await Http.fetcher<IListDcbResponse>(
      Http.AddQueryParams(
        `/pharma/producao/v1/Dcb/Listar`,
        context.queryKey[1] as IQueryListParams
      )
    );
  }
}
