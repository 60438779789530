import { history } from '@/Store';
import { useQueryClient } from '@tanstack/react-query';
import {
  useControllerMutateApiHook,
  useControllerQueryApiHook,
} from '@/ControllerApiHook/Controller';

import { PharmaceuticalFormAPI } from '@/Data/API/Production/PharmaceuticalForm';
import {
  DELETE_PHARMACEUTICAL_FORM_DETAILS,
  EDIT_PHARMACEUTICAL_FORM,
  FETCH_GET_PHARMACEUTICAL_FORM,
  FETCH_LIST_FOR_PHARMACEUTICAL_FORM,
} from '@/ControllerApiHook/UniqIds/Production/PharmaceuticalFormKeys';
import { IGetPharmaceuticalForm } from '@/Data/Interfaces/response/PharmaceuticalForm/IPharmaceuticalFormResponse';
import { IUseCallAPI } from './interfaces';

export const useEditCallAPI = ({ externalId }: IUseCallAPI) => {
  const queryClient = useQueryClient();

  const {
    mutateAsync: editPharmaceuticalForm,
    isLoading: isLoadingEditPharmaceuticalForm,
  }: any = useControllerMutateApiHook({
    uniqId: EDIT_PHARMACEUTICAL_FORM,
    entityApi: PharmaceuticalFormAPI.editPharmaceuticalForm,
    options: {
      onSuccess: () => {
        queryClient.invalidateQueries([FETCH_LIST_FOR_PHARMACEUTICAL_FORM]);
        queryClient.invalidateQueries([FETCH_GET_PHARMACEUTICAL_FORM]);
        history.push('/production/dosage-form');
      },
    },
  });

  const { data: pharmaceuticalForm, isLoading: loading } =
    useControllerQueryApiHook<IGetPharmaceuticalForm>({
      uniqId: FETCH_GET_PHARMACEUTICAL_FORM,
      entityApi: PharmaceuticalFormAPI.getCurrentPharmaceuticalForm,
      externalIds: [externalId],
    });

  const {
    mutateAsync: deletePharmaceuticalForm,
    isLoading: isLoadingDeletePharmaceuticalForm,
  }: any = useControllerMutateApiHook({
    uniqId: DELETE_PHARMACEUTICAL_FORM_DETAILS,
    entityApi: PharmaceuticalFormAPI.deletePharmaceuticalFormDetails,
    options: {
      onSuccess: () => {
        queryClient.invalidateQueries([FETCH_LIST_FOR_PHARMACEUTICAL_FORM]);
        history.push('/production/dosage-form');
      },
    },
  });

  return {
    editPharmaceuticalForm,
    isLoadingEditPharmaceuticalForm,
    pharmaceuticalForm,
    loading,
    deletePharmaceuticalForm,
    isLoadingDeletePharmaceuticalForm,
  };
};
