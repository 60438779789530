import { useControllerQueryListApiHook } from '@/ControllerApiHook/Controller';
import { FETCH_LIST_TRANSFER } from '@/ControllerApiHook/UniqIds/Supply/TransferKeys';
import { TransferAPI } from '@/Data/API/Supply/TransferApi';

export const useListCallAPI = () => {
  const {
    data: transferList,
    isLoading: isLoadingTransferList,
    refetch,
  } = useControllerQueryListApiHook({
    uniqId: FETCH_LIST_TRANSFER,
    entityApi: TransferAPI.listTransfer,
    pagination: {
      sorter: { column: 'sequenciaNumeroTransferencia', direction: 'ASC' },
    },
  });

  return {
    transferList,
    isLoadingTransferList,
    refetch,
  };
};
