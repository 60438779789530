import { useParams } from 'react-router';
import {
  EDIT_PRODUCT_MESSAGE,
  FETCH_GET_PRODUCT_MESSAGE
} from '@/ControllerApiHook/UniqIds/Supply/ProductMessageKeys';
import { 
  useControllerMutateApiHook, 
  useControllerQueryApiHook 
} from '@/ControllerApiHook/Controller';
import { 
  IGetProductMessage 
} from '@/Data/Interfaces/response/ProductMensage/IProductMensageResponse';
import { ProductMessageAPI } from '@/Data/API/Supply/ProductMensage';

export const useDetailCallAPI = () => {
  const { associationProductId: mensagemExternalId } = useParams<{
    associationProductId: string;
  }>();

  const { 
    data: productMessage, 
    isLoading: loading 
  } = useControllerQueryApiHook<IGetProductMessage>({
    uniqId: FETCH_GET_PRODUCT_MESSAGE,
    entityApi: ProductMessageAPI.getCurrentProductMessage,
    externalIds: [mensagemExternalId]
  });

  const editProductMessage: any = useControllerMutateApiHook({
    uniqId: EDIT_PRODUCT_MESSAGE,
    entityApi: ProductMessageAPI.editProductMessage
  });

  return {
    productMessage,
    loading,
    editProductMessage
  };
};