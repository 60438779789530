import { useCallback } from "react";
import { IUseFunctions } from "./interfaces";

export const useEditFunctions = ({
  setModalDeleteSubGroup
}: IUseFunctions) => {
  const onCancelClick = useCallback(
    () => setModalDeleteSubGroup(false), [setModalDeleteSubGroup]
  );
  
  const onOkClick = useCallback(
    () => setModalDeleteSubGroup(false), [setModalDeleteSubGroup]
  );

  return {
    onCancelClick,
    onOkClick
  };
};