import React from 'react';

import styles from './Loading.module.scss';

export interface ISpinner {
  className?: string;
  size?: string;
}

export const Loading: React.FC<ISpinner> = ({ className, size }) => {
  return (
    <div className={`${className} ${styles['loader']}`} style={{ width: size }}>
      <div style={{ width: size, height: size }} />
      <div style={{ width: size, height: size }} />
      <div style={{ width: size, height: size }} />
      <div style={{ width: size, height: size }} />
      <div
        className={styles['background-loading']}
        style={{ width: size, height: size }}
      />
    </div>
  );
};
