import React, { CSSProperties, FC, ReactNode, useMemo } from 'react';
import { getCurrentUserRoles } from '../../Utils/PermissionUtils';
import { Tooltip } from '../../Components';

import './PermissionWrapper.override.scss';
import { useTranslation } from 'react-i18next';
import styles from './PermissionWrapper.module.scss';

export type Placement =
  | 'topRight'
  | 'topLeft'
  | 'bottomLeft'
  | 'bottomRight'
  | 'top';

export interface IPermissionWrapper {
  permission?: number;
  tooltip?: string;
  style?: CSSProperties;
  children?: ReactNode | ((props: any) => JSX.Element);
  placement?: Placement;
  targetOffset?: number[];
}

export const hasPermission = (permission?: number) => {
  if (!permission) return true;
  const currentUserPermissions = getCurrentUserRoles();
  return currentUserPermissions.includes(permission);
};

export const PermissionWrapper: FC<IPermissionWrapper> = ({
  permission,
  tooltip,
  children,
  style,
  placement,
  targetOffset,
}) => {
  const { t } = useTranslation();
  const currentUserPermissions = useMemo(() => getCurrentUserRoles(), []);

  const hasPermission = useMemo(
    () => permission && currentUserPermissions.includes(permission),
    [currentUserPermissions, permission]
  );

  if (hasPermission || !permission) {
    return <>{children}</>;
  }

  return tooltip ? (
    <Tooltip
      placement={placement ?? 'topLeft'}
      title={t(tooltip)}
      showMe
      targetOffset={targetOffset}
    >
      <div
        className={styles['content-permission-wrapper']}
        style={{ ...style }}
      >
        <ParentComponent hasPermission={hasPermission}>
          {children}
        </ParentComponent>
      </div>
    </Tooltip>
  ) : (
    <div style={{ ...style }}>
      <ParentComponent hasPermission={hasPermission}>
        {children}
      </ParentComponent>
    </div>
  );
};

export const ParentComponent = (props: any) => {
  const isStringChildren = typeof props.children === 'string';

  if (isStringChildren || !props.children) {
    return props.children;
  }

  return React.cloneElement(props.children, {
    disabled: !props.hasPermission,
    pointerEvents: 'none',
    cursor: 'not-allowed',
  });
};
