import React from 'react';

import styles from './Spinner.module.scss';

export interface ISpinner {
  className?: string;

  style?: React.CSSProperties;
}

const Spinner: React.FC<ISpinner> = ({ className, style }) => {
  return (
    <div className={`${className} ${styles['loader']}`} style={style}>
      <div />
      <div />
      <div />
      <div />
    </div>
  );
};

export default Spinner;
