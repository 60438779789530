import { IListPrescribersData } from '@/Data/Interfaces/response/Prescribers/IPrescribersResponse';
import { useState } from 'react';

export const usePrescribersStates = () => {
  const [selectedRowsOnTable, setSelectedRowsOnTable] = useState<
    IListPrescribersData[]
  >([]);

  return {
    selectedRowsOnTable,
    setSelectedRowsOnTable,
  };
};
