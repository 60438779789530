import { Row } from 'antd';
import { useField, useFormikContext } from 'formik';
import { FC } from 'react';
import { ClientType } from '../Utils';
import { useBodyMemorizations } from './Hooks/useBodyMemorizations';
import { useBodyFunctions } from './Hooks/useBodyFunctions';
import { PersonType } from './Components/PersonType';
import { FantasyName } from './Components/FantasyName';
import { IdentityInfo } from './Components/IdentityInfo';
import { CompanyInfo } from './Components/CompanyInfo';
import { Phone } from './Components/Phone';

import styles from './body.module.scss';

export const ModalBody: FC = () => {
  const form = useFormikContext();

  const clientTypeField = useField('pessoa');

  const { clientType, createClientModalI18n } = useBodyMemorizations({
    clientTypeField,
  });

  const { validateCnpj, validateCpf } = useBodyFunctions({ form });

  return (
    <div className={styles['content']}>
      <Row className={styles['radio-button-row']}>
        <PersonType createClientModalI18n={createClientModalI18n} />
      </Row>
      <Row gutter={[16, 0]}>
        <FantasyName
          createClientModalI18n={createClientModalI18n}
          clientType={clientType}
        />
        <IdentityInfo 
          createClientModalI18n={createClientModalI18n}
          clientType={clientType}
          validateCpf={validateCpf}
        />
      </Row>
      <Row gutter={[16, 0]}>
        {clientType === ClientType.cnpj && (
          <CompanyInfo validateCnpj={validateCnpj} />
        )}
        <Phone 
          createClientModalI18n={createClientModalI18n}
        />
      </Row>
    </div>
  );
};
