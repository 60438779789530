import { useFormikContext } from "formik";
import { useMemo } from "react";
import { IUseMemorizations } from "./interfaces";

export const useFinalValuesBottomMemorizations = ({
  purchaseOrder
}: IUseMemorizations) => {
  const form: any = useFormikContext();

  const hasProducts = useMemo(
    () =>
      form.values.pedidoCompraItensExternalId?.filter((x: any) => !!x).length >
      0,
    [form.values.pedidoCompraItensExternalId]
  );

  const productsValue = useMemo(() => {
    let totalProduct = 0;
    let totalDiscount = 0;
    totalProduct = purchaseOrder?.pedidoCompraItens
      .filter((x: any) =>
        form.values.pedidoCompraItensExternalId.includes(
          x.pedidoCompraItemExternalId
        )
      )
      .reduce((total: any, item: any) => {
        const productTotal = item.precoUnitario * item.quantidade;
        return total + productTotal;
      }, 0);
    totalDiscount = purchaseOrder?.pedidoCompraItens
      .filter((x: any) =>
        form.values.pedidoCompraItensExternalId.includes(
          x.pedidoCompraItemExternalId
        )
      )
      .reduce((totalDiscount: any, item: any) => {
        const discountPerQuantity =
          item.valorDescontoUnitario * item.quantidade;
        return totalDiscount + discountPerQuantity;
      }, 0);
    return { totalProduct, totalDiscount };
  }, [
    form.values.pedidoCompraItensExternalId,
    purchaseOrder?.pedidoCompraItens,
  ]);

  return {
    hasProducts,
    productsValue
  };
};