import { ModalFuncProps } from 'antd';
import { FC } from 'react';
import { BodyModal } from '../../../../../../../Components/Modal';
import { Footer as ModalFooter } from '../../../../../../../Components/Modal/Components/Footer';
import {
  ICommonError,
  IGlobalModal,
} from '../../../../../../../Utils/ErrorCodeUtils/ErrorHandlingUtils';
import { Icon, Text } from '../../../../../../../Components';
import { useTranslation } from 'react-i18next';
import { Divflex } from '../../../../../../../Layouts/DivWhithFlex';
import { ReactMarkdown } from 'react-markdown/lib/react-markdown';
import { DeleteModalOnTableErrorCode } from '../../../../../../../Utils/DeleteModalUtils';

const Content: FC<ICommonError> = ({ content }) => {
  const { t } = useTranslation();

  return (
    <>
      <BodyModal
        maxWidth={400}
        body={
          content?.length === 1 ? (
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <Text type="ui-tiny-content">
                <ReactMarkdown>
                  {t('purchasing.provider.modal.cannotDelete.subTitle', {
                    fornecedor: `**${content[0].nome}**`,
                  })}
                </ReactMarkdown>
              </Text>

              <Text type="ui-tiny-content" style={{ marginTop: 10 }}>
                {t('purchasing.provider.modal.cannotDelete.description')}
              </Text>

              <Divflex style={{ marginTop: 25 }}>
                <Icon
                  icon={'exclamation-upside-down'}
                  size={'SM'}
                  color={'text-400'}
                  style={{ padding: '18px 18px 0 8px' }}
                />
                <Text
                  type="paragraph2"
                  color={'text-400'}
                  children={t(
                    'purchasing.provider.modal.cannotDelete.warningFooter'
                  )}
                />
              </Divflex>
            </div>
          ) : (
            DeleteModalOnTableErrorCode(
              content,
              t('purchasing.provider.modal.cannotDelete.batchSubTitle'),
              'nome',
              'nome',
              t('purchasing.provider.modal.cannotDelete.batchDescription'),
              t('purchasing.provider.modal.cannotDelete.batchWarningFooter')
            )
          )
        }
        style={{ padding: '20px' }}
      />
    </>
  );
};

const Title: FC<ICommonError> = ({ content }) => {
  const { t } = useTranslation();
  return (
    <>
      {content?.length === 1
        ? t('purchasing.provider.modal.cannotDelete.title', {
            fornecedor: content[0].nome,
          })
        : t('purchasing.provider.modal.cannotDelete.batchTitle', {
            fornecedor: content?.length,
          })}
    </>
  );
};

const Footer: FC<{
  params: { loteExternalId: string[] };
  instance: IGlobalModal;
}> = ({ instance }) => {
  const { t } = useTranslation();

  return (
    <ModalFooter
      onCancelClick={instance.destroy}
      cancelButtonName={t('common.close')}
      withoutOkButton
    />
  );
};

export const CannotDeleteModal: (
  error: ICommonError,
  params: { loteExternalId: string[] },
  instance: IGlobalModal
) => ModalFuncProps = (error, params, instance) => ({
  closable: true,
  icon: <></>,
  title: <Title {...error} />,
  content: <Content {...error} />,
  footer: <Footer params={params} instance={instance} />,
});
