import axios, { AxiosInstance } from 'axios';
import React from 'react';
import { useLocation } from 'react-router';
import { Languages } from '../Components/i18n';
import {
  IExpandParams,
  IPagination,
  IQuery,
  ISorter,
} from '../Components/Table';
import { ILoginResponse } from '../Data/Interfaces/response/Auth/ILoginResponse';
import { CURRENT_USER } from './ConstantsUtils';
import { setRoles } from './PermissionUtils';
import StorageUtils from './StorageUtils';
import { Cookies } from 'react-cookie';

export enum ApisTypes {
  IDENTITY = 0,
  BFF = 1,
}

export interface ODataResponse<T> {
  '@odata.context': string;
  '@odata.count': number;
  value: T[];
}

export interface ITransformedOdataResponse<T> {
  pageSize: number;
  pageIndex: number;
  count: number;
  data: T[];
}

export interface IPatchOperation {
  operationType: number;
  path: string;
  op: 'add' | 'remove' | 'replace';
  value: string;
}

export interface ITransformODataResponse<T> {
  pageSize: number;
  pageIndex: number;
  count: number;
  data: T[];
}

export interface IQueryListFilter {
  filterName: string;
  value?: string | number | (string | number)[];
}

export interface IQueryListParams {
  url?: string;
  filter?: IQueryListFilter[];
  pageSize: number;
  pageIndex: number;
  search?: string;
  sorter?: ISorter;
}

let isRefreshing = false;
let failedQueue: any[] = [];

const processQueue = (error: any, token: string | null = null) => {
  failedQueue.forEach((promise) => {
    if (error) {
      promise.reject(error);
    } else {
      promise.resolve(token);
    }
  });

  failedQueue = [];
};

export class Http {
  static async axios(contentType = 'application/json'): Promise<AxiosInstance> {
    try {
      const cookies = new Cookies();

      const current_user = cookies.get(CURRENT_USER);
      const { access, refresh } = current_user;
      const language = localStorage.getItem('i18nextLng');

      const headers = {
        contentType: contentType,
        Authorization: 'Bearer ' + access,
        'Accept-Language': language?.toLowerCase() || Languages.ptBR,
      };

      return new Promise((resolve) => {
        const instance = axios.create({
          baseURL: process.env.REACT_APP_BASE_API_URL,
          headers,
        });

        instance.interceptors.response.use(undefined, (err) => {
          const res = err.response;
          const originalRequest = err.config;

          if (res.status !== 401) {
            throw err;
          }

          if (isRefreshing) {
            return new Promise(function (resolve, reject) {
              failedQueue.push({ resolve, reject });
            })
              .then((token) => {
                return axios({
                  ...originalRequest,
                  headers: {
                    ...originalRequest.headers,
                    Authorization: `Bearer ${token}`,
                  },
                });
              })
              .catch((err) => {
                return Promise.reject(err);
              });
          }

          isRefreshing = false;

          return new Promise(function (resolve, reject) {
            instance
              .post('/identity/v1/OAuth/refresh', {
                refreshToken: refresh,
              })
              .then(({ data }) => {
                const currentUser = cookies.get(CURRENT_USER) || '{}';

                StorageUtils.SetItem(
                  CURRENT_USER,
                  JSON.stringify({ ...currentUser, ...data })
                );
                setRoles(data.access);
                headers.Authorization = `Bearer ${data.access}`;
                processQueue(undefined, data.refresh);
                resolve(
                  axios({
                    ...originalRequest,
                    headers: {
                      ...originalRequest.headers,
                      Authorization: `Bearer ${data.access}`,
                    },
                  })
                );
              })
              .catch((err) => {
                failedQueue = [];
                // store.dispatch(LogoutActions.fetchLogout());
                processQueue(err, undefined);
                reject(err);
              });
          });
        });

        return resolve(instance);
      });
    } catch {
      return new Promise((resolve) => {
        const instance = axios.create({
          baseURL: process.env.REACT_APP_BASE_API_URL,
          headers: {
            contentType: contentType,
          },
        });
        return resolve(instance);
      });
    }
  }

  static AddQueryParams(url: string, obj: IQueryListParams) {
    const pageIndex = obj?.pageIndex || 1;
    const pageSize = obj?.pageSize || 10;
    const search = obj?.search;
    const sorter = obj?.sorter;

    let newUrl = `${url}?PageIndex=${pageIndex}&PageSize=${pageSize}${
      obj.filter
        ? obj.filter.reduce(
            (value, x) =>
              `${value}${
                typeof x.value === 'string' || typeof x.value === 'number'
                  ? `&${x.filterName}=${x.value}`
                  : x.value?.map((filter) => `&${x.filterName}=${filter}`)
              }`.replaceAll(',', ''),
            ''
          )
        : ''
    }`;

    if (search) {
      newUrl += `&Search=${search}`;
    }
    if (sorter) {
      newUrl += `&orderBy[${sorter.column}]=${
        sorter.direction === 'ASC' ? 0 : 1
      }`;
    }

    return newUrl;
  }

  static AddODataQueryParams(url: string, params: IQuery) {
    const pageIndex = params?.pagination?.pageIndex || 0;
    const pageSize = params?.pagination?.pageSize || 10;

    const filter = params.filter ? `&$filter=${params.filter}` : '';
    const select = params?.select ? `$select=${params?.select || '*'}` : '';
    const skip = params?.pagination?.pageIndex
      ? `$skip=${pageIndex * pageSize}`
      : '';
    const top = params?.pagination?.pageSize ? `$top=${pageSize || 10}` : '';
    const orderBy = `&$orderby=${params?.orderBy}`;
    let sorter = '';
    let search = '';

    const expand: IExpandParams[] = [] as IExpandParams[];
    let expands = '';

    params.expand?.map((x, index) => (expand[index] = x));

    const getExpand = (
      string: IExpandParams[],
      expanding: boolean
    ): string[] => {
      return string.map((x) =>
        !expanding
          ? (expands += `${expanding ? '' : '&'}$expand=${x.expandTitle}(${
              x.count ? '$count=true;' : ''
            }${x.select ? `$select=${x.select};` : ''}${
              x.orderBy ? `$orderby=${x.orderBy};` : ''
            }${
              x.pagination
                ? `$skip=${x.pagination.pageSize * x.pagination.pageIndex};`
                : ''
            }${x.pagination ? `$top=${x.pagination.pageSize};` : ''}${
              x.custom ? x.custom : ''
            }${
              x.pagination?.search ? `$search="${x.pagination.search}";` : ''
            }${x.expand ? `${getExpand(x.expand, true)}` : ''})`)
          : `${expanding ? '' : '&'}$expand=${x.expandTitle}(${
              x.count ? '$count=true;' : ''
            }${x.select ? `$select=${x.select};` : ''}${
              x.orderBy ? `$orderby=${x.orderBy};` : ''
            }${
              x.pagination
                ? `$skip=${x.pagination.pageSize * x.pagination.pageIndex};`
                : ''
            }${x.pagination ? `$top=${x.pagination.pageSize};` : ''}${
              x.custom ? x.custom : ''
            }${
              x.pagination?.search ? `$search="${x.pagination.search}";` : ''
            }${x.expand ? `${getExpand(x.expand, true)}` : ''})`
      );
    };

    if (params.sorter) {
      sorter = `&orderby=${
        params.sorter.column
      } ${params.sorter.direction.toLowerCase()}`;
    }

    getExpand(expand, false);

    if (params.pagination?.search) {
      search = `&$search="${params.pagination.search}"`;
    }

    return `${url}?$count=true${top ? `&${top}` : ''}${skip ? `&${skip}` : ''}${
      select ? `&${select}` : ''
    }${(!sorter && orderBy) || ''}${sorter}${filter || ''}${search}${
      expands ? `${expands}` : ''
    }`;
  }

  static TransformODataData<T>(
    data: ODataResponse<T>,
    pagination?: IPagination
  ): ITransformedOdataResponse<T> {
    return {
      pageSize: pagination?.pageSize || 0,
      pageIndex: pagination?.pageIndex || 0,
      count: data['@odata.count'],
      data: data.value || data,
    };
  }

  static ConvertFieldsToPatch(obj: any, prefix?: string): IPatchOperation[] {
    const result: IPatchOperation[] = [];

    if (!obj) return result;

    const keys = Object.keys(obj);

    for (const key of keys) {
      const isObject = typeof obj[key] === 'object';
      const path = `${prefix || ''}/${key}`;

      if (isObject) {
        result.push(...Http.ConvertFieldsToPatch(obj[key], path));
        continue;
      }

      result.push({
        operationType: 0,
        path,
        op: 'replace',
        value: obj[key],
      });
    }

    return result;
  }

  static async fetcher<JSON = any>(
    path: string,
    init?: RequestInit
  ): Promise<JSON> {
    const cookies = new Cookies();

    const current_user = cookies.get(CURRENT_USER);
    const userData: ILoginResponse = current_user;
    const language = localStorage.getItem('i18nextLng');
    const method = init?.method;

    const currentContentType =
      method?.toUpperCase() === 'PATCH'
        ? 'application/json-patch+json'
        : 'application/json';

    const headers: any = {
      'Content-Type': currentContentType,
      'Accept-Language': language?.toLowerCase() || Languages.ptBR,
    };

    if (userData?.access) {
      headers['Authorization'] = 'Bearer ' + userData.access;
    }

    const res = await fetch(`${process.env.REACT_APP_BASE_API_URL}${path}`, {
      ...init,
      headers: { ...headers, ...init?.headers },
    });

    if (!res.ok) {
      if (res.status !== 401) throw JSON.parse(await res.text());

      if (isRefreshing) {
        return new Promise(function (resolve, reject) {
          failedQueue.push({ resolve, reject });
        })
          .then(async (token) => {
            const newRes = await fetch(
              `${process.env.REACT_APP_BASE_API_URL}${path}`,
              {
                ...init,
                headers: {
                  ...headers,
                  ...init?.headers,
                  Authorization: `Bearer ${token}`,
                },
              }
            );

            return Promise.resolve(newRes.json());
          })
          .catch((err) => {
            return Promise.reject(err);
          });
      }

      isRefreshing = true;

      return new Promise(async function (resolve, reject) {
        if (!userData?.refresh) return;
        try {
          const refreshRes = await fetch(
            `${process.env.REACT_APP_BASE_API_URL}/identity/v1/OAuth/refresh`,
            {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
              },
              body: JSON.stringify({
                refreshToken: userData.refresh,
              }),
            }
          );

          const data = await refreshRes.json();
          const currentUser = JSON.parse(cookies.get(CURRENT_USER) || '{}');
          StorageUtils.SetItem(
            CURRENT_USER,
            JSON.stringify({ ...currentUser, ...data })
          );
          setRoles(data.access);
          processQueue(undefined, data.access);
          resolve(
            Http.fetcher(path, {
              ...init,
              headers: {
                ...init?.headers,
                Authorization: `Bearer ${data.access}`,
              },
            })
          );
        } catch (err) {
          failedQueue = [];
          // store.dispatch(LogoutActions.fetchLogout());
          processQueue(err, undefined);
          reject(err);
        }
      });
    }

    return res.json();
  }
}

export const useQueryParams = () => {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
};
