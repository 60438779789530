import { FC } from 'react';
import { Button } from '@/Components';
import { history } from '@/Store';
import { useTranslation } from 'react-i18next';

interface IFooterButtons {
  isLoadingCreateBalance: boolean;
}

export const FooterButtons: FC<IFooterButtons> = ({
  isLoadingCreateBalance,
}) => {
  const { t } = useTranslation();
  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
      }}
    >
      <Button
        type="secondary"
        style={{ marginRight: 8 }}
        onClick={() => history.push('/inventory/balance-adjustments')}
      >
        {t('common.cancel')}
      </Button>
      <Button
        type="primary"
        htmlType="submit"
        disabled={false}
        children={
          isLoadingCreateBalance ? t('common.creating') : t('common.create')
        }
        loading={isLoadingCreateBalance}
      />
    </div>
  );
};
