import { useCallback } from 'react';
import { FETCH_DILUTED_PRODUCT } from '@/ControllerApiHook/UniqIds/Supply/ProductKeys';
import { history } from '@/Store';
import { useQueryClient } from '@tanstack/react-query';
import { IUseFunctions } from './interfaces';

export const useDilutedProductDetailsFunctions = ({
  editDilutedProduct,
  productExternalId,
  associationProductId,
}: IUseFunctions) => {
  const defaultUnitId = 6;
  const queryClient = useQueryClient();

  const handleSubmit = useCallback(
    async (values: any) => {
      const result = await editDilutedProduct.mutateAsync({
        ...values,
        unidadeMedidaId: values.unidadeMedidaId ?? defaultUnitId,
        produtoExternalId: productExternalId,
        externalId: associationProductId,
      });
      if (result) {
        queryClient.invalidateQueries([FETCH_DILUTED_PRODUCT]);
        history.push(`/inventory/products/${productExternalId}/diluted`);
      }
    },
    [editDilutedProduct]
  );

  const disableSubmitButton = (values: any) => {
    const miniMalDosageWrong =
      values.dosagemMaxima < values.dosagemMinima ||
      !values.dosagemMaxima ||
      !values.dosagemMinima;
    const formPharmaceuticalId = !!values.formaFarmaceuticaExternalId;
    const anyDosage = values.seQualquerDosagem;
    const anyPharmaceuticalForm = !!values.seTodasFormasFarmaceuticas;

    const firstCondition = miniMalDosageWrong && !anyDosage;
    const secondCondition = !formPharmaceuticalId && !anyPharmaceuticalForm;
    const thirdCondition = !values.diluicao;

    return firstCondition || secondCondition || thirdCondition;
  };

  const onClickBackButton = () => {
    history.push(`/inventory/products/${productExternalId}/diluted`);
  };

  return {
    handleSubmit,
    disableSubmitButton,
    onClickBackButton,
  };
};
