import { useControllerQueryListApiHook } from '@/ControllerApiHook/Controller';
import { FormatDataToDropdownUtils } from '@/Utils/FormatDataToDropdownUtils';
import { IQueryListParams } from '@/Utils/Http';
import { ListFreightType } from '@/Services/Purchase/Purchase/ListFreightType.service';

export const FETCH_LIST_DROPDOWN_FREIGHT_TYPE =
  '[PURCHASE] FETCH_LIST_DROPDOWN_FREIGHT_TYPE';

interface IUseListFreightType {
  fieldName: string;
  enabled?: boolean;
  pagination?: IQueryListParams;
}

export const useListFreightType = ({
  fieldName,
  enabled,
  pagination,
}: IUseListFreightType) => {
  const { data, refetch, isLoading, fetchNewPage } =
    useControllerQueryListApiHook({
      uniqId: FETCH_LIST_DROPDOWN_FREIGHT_TYPE,
      entityApi: ListFreightType,
      autoIncrement: true,
      enabled,
      pagination,
    });

  const items = FormatDataToDropdownUtils({
    data: data && data.data ? data.data : [],
    idKey: 'id',
    labelKey: 'descricao',
    addIndexAsPrefix: true,
  });

  const onSearch = (search?: string) => {
    refetch({
      search,
    });
  };

  return {
    name: fieldName,
    items,
    onSearch,
    isLoading,
    onScrollEnd: fetchNewPage,
  };
};
