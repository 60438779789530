import { useControllerQueryListApiHook } from '@/ControllerApiHook/Controller';
import { FormatDataToDropdownUtils } from '@/Utils/FormatDataToDropdownUtils';
import { IQueryListParams } from '@/Utils/Http';
import { ListNcm } from '@/Services/Purchase/Purchase/ListNcm.service';

export const FETCH_LIST_DROPDOWN_NCM = '[INVOICE] FETCH_LIST_DROPDOWN_NCM';

interface IUseListNcm {
  fieldName: string;
  enabled?: boolean;
  pagination?: IQueryListParams;
}

export const useListNcm = ({ fieldName, enabled, pagination }: IUseListNcm) => {
  const { data, refetch, isLoading, fetchNewPage } =
    useControllerQueryListApiHook({
      uniqId: FETCH_LIST_DROPDOWN_NCM,
      entityApi: ListNcm,
      autoIncrement: true,
      enabled,
      pagination,
      autoIncrementCustomId: 'id',
    });

  const items = FormatDataToDropdownUtils({
    data: data && data.data ? data.data : [],
    idKey: 'id',
    labelKey: 'codigo',
    subLabelKey: 'descricao',
  });

  const onSearch = (search?: string) => {
    refetch({
      search,
    });
  };

  return {
    name: fieldName,
    items,
    onSearch,
    isLoading,
    onScrollEnd: fetchNewPage,
  };
};
