import React, { FC } from 'react';
import { Radio as AntRadio } from 'antd';
import { Field } from 'formik';
import { IField } from '../interfaces/IField';
import { Text, TextType } from '../../Text';

import styles from './Radio.module.scss';
import './Radio.override.scss';
import { Tooltip } from '../../Tooltip';

export interface IRadio {
  name: string;
  label?: string;
  labelType?: TextType;
  disabled?: boolean;
  value: any;
  checked?: boolean;
  onChange?: (e: any) => void;

  tooltip?: string;

  className?: string;

  dataTestId?: string;
  containerDatTestId?: string;
}

export const RadioButton: FC<IRadio> = ({
  name,
  label,
  labelType,
  disabled,
  value,
  checked,
  onChange,
  tooltip,
  className,
  dataTestId,
  containerDatTestId
}) => {
  return (
    <Field type="radio" name={name} value={value}>
      {(props: IField) => (
        <Tooltip title={tooltip} showMe={!!tooltip}>
          <div className={`${className} ${styles['Radio']}`} data-testid={containerDatTestId}>
            <AntRadio
              data-testid={dataTestId}
              value={props.field.value}
              disabled={disabled || props.form.isSubmitting}
              checked={checked}
              onClick={onChange}
              style={{ alignItems: 'center', width: '100%' }}
              children={
                label ? (
                  <Text
                    type={labelType ? labelType : 'ui-sub-content'}
                    color="text-50"
                  >
                    {label}
                  </Text>
                ) : null
              }
            />
          </div>
        </Tooltip>
      )}
    </Field>
  );
};
