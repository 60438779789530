import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { HorizontalTabs } from '../../../../../Components';
import { DetailForm } from './Components/DetailsForm';

const LossesEditPage: FC = () => {
  const [disabledFormInput, setDisabledFormInput] = useState(true);
  const { t } = useTranslation();
  return (
    <HorizontalTabs
      type="secondary"
      items={[
        {
          label: t('supply.balanceAdjustment.detail'),
          key: 'details',
          children: (
            <DetailForm
              disabledForm={disabledFormInput}
              onDisableEditButton={() => setDisabledFormInput(true)}
            />
          ),
        },
      ]}
      rightButton={{
        ...(disabledFormInput && {
          onClick: () => setDisabledFormInput(false),
          leftIcon: 'edit',
          children: t('common.edit'),
          isVisible: disabledFormInput,
          codePermissionEdit: 2019,
          messageTooltipPermission: 'noPermission.supply.losses.edit',
        }),
      }}
      dropdownDirection="bottomRight"
      dropdownTitle={t('admin.editUserPage.tab.buttonActions.title')}
      leftIcon="menu-ellipsis-horizontal"
    />
  );
};

export default LossesEditPage;
