import { useFormikContext } from 'formik';

export const useGetTotalProducts = () => {
  const form: any = useFormikContext();
  const items = form.values.itens;

  const getTotalProduct = () => {
    if (!items) return 0;
    let totalValue = 0;

    items.forEach((item: any) => {
      if (!item.quantidadeComprada || !item.valorUnitario) return;
      totalValue += item.quantidadeComprada * item.valorUnitario;
    });

    if (totalValue === 0) return 0;

    return totalValue.toString();
  };

  return { getTotalProduct };
};
