import { IListPharmaceuticalFormDropdownData } from "@/Data/Interfaces/response/PharmaceuticalForm/IPharmaceuticalFormResponse";
import { IUseMemorizations } from "./interfaces";

export const usePharmaceuticalFormMemorizations = ({
  listPharmaceuticalForm
}: IUseMemorizations) => {
  const items = listPharmaceuticalForm?.data?.map(
    (item: IListPharmaceuticalFormDropdownData) => {
      return {
        id: item.externalId,
        label: item.descricao,
      };
    }
  );

  return {
    items
  };
};