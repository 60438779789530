import { useFormikContext } from "formik";
import { useCallback } from "react";

export const useDilutionFunctions = () => {
  const form: any = useFormikContext();

  const onChange = useCallback((e: any) => {
    if (!e) {
      return form.setFieldValue(
        'informacaoTecnica.fatorConcentracaoAgua',
        ''
      );
    }
    const numericValue = +e.replace('%', '').replace(',', '.');
    const concentrationWaterFactor = 100 / (100 - numericValue);
    form.setFieldValue(
      'informacaoTecnica.fatorConcentracaoAgua',
      concentrationWaterFactor.toFixed(3)
    );
  }, [form]);

  return {
    onChange
  };
};