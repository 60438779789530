import { FC } from "react";
import { TextArea } from '@/Components/Inputs/TextArea';
import { useTranslation } from 'react-i18next';
import { Tooltip } from '@/Components';
import { useFormikContext } from 'formik';
import { IBaseboard } from "./interfaces";

export const Baseboard: FC<IBaseboard> = ({
  standardFormula,
  disabled
}) => {
  const { t } = useTranslation();
  const form: any = useFormikContext();

  return (
    <Tooltip
      title={`${t(
        'production.standardFormula.details.tooltips.previousBaseboard'
      )}  ${
        standardFormula?.rodape
          ? standardFormula?.rodape
          : `${t('common.empty')}`
      }`}
      targetOffset={[-65, -15]}
      placement="topLeft"
      showMe={
        !disabled && standardFormula?.rodape !== form.values.rodape
      }
    >
      <TextArea
        name="rodape"
        label={t('production.standardFormula.details.baseboard')}
        placeholder={t(
          'production.standardFormula.details.baseboardPlaceholder'
        )}
        disable={disabled}
        maxLength={1000}
        rows={3}
        withStatusIcon
      />
    </Tooltip>
  );
};