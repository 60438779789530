import { FC } from 'react';
import {
  InputWithSearch
} from '@/Components';
import { Col } from 'antd';
import { useTranslation } from 'react-i18next';
import { IIncompatibleProduct } from './interfaces';
import { useIncompatibleProductCallAPI } from './Hooks/useIncompatibleProductCallAPI';
import { useIncompatibleProductMemorizations } from './Hooks/useIncompatibleProductMemorizations';
import { useIncompatibleProductFunctions } from './Hooks/useIncompatibleProductFunctions';

export const IncompatibleProduct: FC<IIncompatibleProduct> = ({
  productExternalId
}) => {
  const { t } = useTranslation();

  const {
    productList,
    fetchNewProductPage,
    refetchSearchProductPage
  } = useIncompatibleProductCallAPI({
    productExternalId
  });

  const {
    items
  } = useIncompatibleProductMemorizations({
    productList,
    productExternalId
  });

  const {
    onSearch
  } = useIncompatibleProductFunctions({
    refetchSearchProductPage
  });

  return (
    <Col span={8}>
      <InputWithSearch
        required
        name="produtoIncompativelExternalId"
        label={t('products.incompatible.incompatibleProduct')}
        placeHolder={t(
          'products.incompatible.productIncompatiblePlaceholder'
        )}
        items={items}
        onScrollEnd={fetchNewProductPage}
        onSearch={onSearch}
      />
    </Col>
  );
};