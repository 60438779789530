import {
  CSSProperties,
  FC,
  ReactNode,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';

import './Highlight.override.scss';

export interface IHighlight {
  children: ReactNode;
  highlight?: string;
  style?: CSSProperties;

  className?: string;
}

export const Highlight: FC<IHighlight> = ({
  children,
  highlight,
  style,
  className,
}) => {
  const [innerHTML, setInnerHtml] = useState<string>();
  const [originalHTML, setOriginalHTML] = useState<string>();
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (containerRef.current == null) {
      return;
    }

    let innerHTML = containerRef.current.innerHTML;
    setOriginalHTML(innerHTML);
  }, [containerRef, children]);

  useLayoutEffect(() => {
    const timers = setTimeout(() => {
      if (containerRef.current === null) {
        return;
      }

      if (!originalHTML || !highlight) {
        containerRef.current.innerHTML = originalHTML || '';
        return;
      }

      let innerHTML = originalHTML;
      const regex = new RegExp(`(${highlight.replace(/[.*+?^${}()|[\]\\]/g, '\\$&')})(?!([^<]+)?>)`, 'i');
      const matchPlaceHolder = '~';
      const matches = [];

      // Wee need this logic to keep the Case of the original text
      do {
        const index = innerHTML.search(regex);

        if (index === -1) {
          break;
        }

        const substr = innerHTML.substr(index, highlight.length);
        matches.push({
          match: substr,
          start: index,
          end: index + highlight.length,
        });

        innerHTML = innerHTML.replace(regex, matchPlaceHolder);
      } while (innerHTML.search(regex) > -1);

      matches.forEach((match) => {
        innerHTML = innerHTML.replace(
          matchPlaceHolder,
          `<span style="background: rgba(255, 255, 0, 0.45);border: 3px">${match.match}</span>`
        );
      });

      setInnerHtml(innerHTML)
    }, 1);

    return () => clearTimeout(timers);
  }, [containerRef, originalHTML, highlight, children]);

  // The only way currently to fix highlight issues is using dangeouslySetInnerHtml
  // we should fix this is the future, we need it since the table need to highlight things when
  // it does have a "render" function
  //
  // TODO: remove "dangerouslySetInnerHTML" 
  return (
    <div ref={containerRef} style={style} className={className}>
      {innerHTML ? <div dangerouslySetInnerHTML={{ __html: innerHTML }}></div> :children}
    </div>
  );
};
