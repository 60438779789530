import { useQueryClient } from '@tanstack/react-query';
import { useControllerMutateApiHook } from '@/ControllerApiHook/Controller';
import { FETCH_LIST_PACKING_CLASSIFICATION_LINKED_PHARMACEUTICAL_FORM } from '@/ControllerApiHook/UniqIds/Production/PharmaceuticalFormKeys';
import { IUseCallAPI } from './interfaces';
import { BIND_PACKING_CLASSIFICATION_TO_PHARMACEUTICAL_FORM } from '@/ControllerApiHook/UniqIds/Production/PackingClassificationLinkedPharmaceuticalFormKeys';
import { PackingClassificationLinkedPharmaceuticalFormAPI } from '@/Data/API/Production/PackingClassificationPharmaceuticalForm';
import { messageSucessToast } from '@/Utils/MessageErrorToast';

export const useEditCallAPI = ({ t, changeVisible }: IUseCallAPI) => {
  const queryClient = useQueryClient();

  const {
    mutateAsync: linkPackingClassification,
    isLoading: isLoadingLinkPackingClassification,
  } = useControllerMutateApiHook({
    uniqId: BIND_PACKING_CLASSIFICATION_TO_PHARMACEUTICAL_FORM,
    entityApi:
      PackingClassificationLinkedPharmaceuticalFormAPI.linkPackingClassification,
    options: {
      onSuccess: () => {
        messageSucessToast(
          t(
            'production.pharmaceuticalForm.details.packingClassification.newLink'
          )
        );
        changeVisible && changeVisible(false);
        queryClient.invalidateQueries([
          FETCH_LIST_PACKING_CLASSIFICATION_LINKED_PHARMACEUTICAL_FORM,
        ]);
      },
    },
  });

  return {
    linkPackingClassification,
    isLoadingLinkPackingClassification,
  };
};
