import { StatusPurchaseOrderEnum } from '../../../enum';
import { 
  TypePurchaseOrderEnum 
} from '@/Data/Interfaces/model/typePurchaseOrderEnum';
import { PermissionEnum } from '@/Data/Interfaces/model/permissionEnum';
import { useTranslation } from 'react-i18next';
import { IUseMemorizations } from './interfaces';
import { IconName } from '@/Components/Icon/IconClasses';

export const useApproveButtonMemorizations = ({
  statusPurchaseOrder
}: IUseMemorizations) => {
  const { t } = useTranslation();

  const leftIcon: IconName | undefined = statusPurchaseOrder ===
  StatusPurchaseOrderEnum.ConfirmadoComFornecedor ||
  statusPurchaseOrder === StatusPurchaseOrderEnum.RecebidoParcial
  ? 'BoxCorrect'
  : 'check';

  const permission = statusPurchaseOrder === TypePurchaseOrderEnum.released
  ? {
      permission:
        PermissionEnum.Compras_PedidoCompra_ConfirmarComFornecedor,
      tooltip:
        'noPermission.purchasing.purchaseOrder.confirmWithProvider',
    }
  : {
      permission: PermissionEnum.Compras_PedidoCompra_Aprovar,
      tooltip: 'noPermission.purchasing.purchaseOrder.approve',
    };

  const isDisabled = statusPurchaseOrder === TypePurchaseOrderEnum.confirmedWithSupplier;

  const children = statusPurchaseOrder ===
  StatusPurchaseOrderEnum.AguardandoAprovacao
    ? t('common.approve')
    : statusPurchaseOrder === StatusPurchaseOrderEnum.Liberado
    ? t('common.confirmPurchase')
    : statusPurchaseOrder ===
        StatusPurchaseOrderEnum.ConfirmadoComFornecedor ||
      statusPurchaseOrder ===
        StatusPurchaseOrderEnum.RecebidoParcial
    ? t('common.receiveProducts')
    : '';

  return {
    leftIcon,
    permission,
    isDisabled,
    children
  };
};