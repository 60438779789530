import { useMemo } from 'react';
import { IUseMemorizations } from './interface';

export const useAccessCardMemorizations = ({
  userGroupListResponse,
}: IUseMemorizations) => {
  const userDataList = useMemo(
    () =>
      userGroupListResponse?.data?.data?.map((x: any) => ({
        id: x.externalId,
        label: x.nome,
      })),
    [userGroupListResponse]
  );

  return { userDataList };
};
