import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Modal } from '../../../../../../../Components/Modal';
import { Divflex } from '../../../../../../../Layouts/DivWhithFlex';
import { Text } from '../../../../../../../Components';
import { FooterThisActionIsPermanent } from '../../../../../../../Components/Table/components/Modals/DeleteModal';
import { IGetCapsuleColor } from '../../../../../../../Data/Interfaces/response/CapsuleColors/ICapsuleColorsResponse';
import { useEditCallAPI } from '../../Hooks/useEditCallAPI';
import { useEditFunctions } from '../../Hooks/useEditFunctions';
import { useParams } from 'react-router';

interface IDeleteProductModal {
  capsuleColor: IGetCapsuleColor;
  visibleModal: boolean;
  changeVisibleModal: (value: boolean) => void;
}

export const DeleteCapsuleColortModal: FC<IDeleteProductModal> = ({
  capsuleColor,
  visibleModal,
  changeVisibleModal,
}) => {
  const { externalId } = useParams<{ externalId: string }>();

  const { t } = useTranslation();

  const { deleteCapsuleColor } = useEditCallAPI({ t, externalId });

  const { onDelete } = useEditFunctions({
    t,
    capsuleColor,
    deleteCapsuleColor,
  });

  return (
    <Modal
      title={`${t(
        'production.capsuleColors.details.modal.deleteCapsuleColors'
      )}  "${capsuleColor?.corCapsula}"?`}
      body={
        <div style={{ padding: '20px' }}>
          <Divflex style={{ flexDirection: 'column', marginBottom: 10 }}>
            <Text
              color="text-50"
              type="ui-tiny-content"
              children={`${t(
                'production.capsuleColors.details.modal.messageQuestionDelete'
              )} `}
            />

            <Text
              color="text-50"
              type="ui-tiny-bold"
              children={`${capsuleColor?.corCapsula}?`}
            />
          </Divflex>
          <FooterThisActionIsPermanent />
        </div>
      }
      okButtonColor="status-danger-base"
      visible={visibleModal}
      onOkClick={() => {
        onDelete();
        changeVisibleModal(false);
      }}
      okButtonName={t('common.delete')}
      onCancelClick={() => changeVisibleModal(false)}
      onClose={() => changeVisibleModal(false)}
      okButtonType="danger"
      widthModal={400}
      dataTestIdOkButton="delete-posology-submit-button"
    />
  );
};
