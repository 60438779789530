import { useCallback } from 'react';
import { v4 as uuidV4 } from 'uuid';
import { IUseFunctions } from './interfaces';

export const useAssociatePackingFunctions = ({
  index,

  setDisabledPackingTypes,
  associatePackingListController,
  setAssociatePackingListController,
  refetchSearchProductPage,
}: IUseFunctions) => {
  const onChange = useCallback(
    (value: string) => {
      if (index === associatePackingListController.length - 1) {
        setAssociatePackingListController((prev: string[]) => [
          ...prev,
          uuidV4(),
        ]);
        setDisabledPackingTypes((prev: string[]) => [...prev, value]);
      }
    },
    [
      setDisabledPackingTypes,
      associatePackingListController,
      setAssociatePackingListController,
    ]
  );

  const onSearch = useCallback(
    (search?: string) => {
      refetchSearchProductPage({
        search: search,
      });
    },
    [refetchSearchProductPage]
  );

  return {
    onChange,
    onSearch,
  };
};
