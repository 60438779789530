import { useTranslation } from 'react-i18next';
import { List as ProductList } from '../Components/ProductForm';
import { DetailProductGroupForm } from '../Components/DetailsForm';
import { SubGroupForm } from '../Components/SubGroupForm';
import { IUseMemorizations } from './interfaces';
import { IButtonDropDownItems } from '@/Components/Button/ButtonDropdown';

export const useEditMemorizations = ({
  disabled,
  setDisabled,
  setModalDeleteProductGroup,
  params,
}: IUseMemorizations) => {
  const { t } = useTranslation();

  const items = [
    {
      label: t('admin.editUserPage.tab.details'),
      key: 'details',
      children: (
        <DetailProductGroupForm
          disabled={disabled}
          toogleDisabled={(x: boolean) => setDisabled(x)}
        />
      ),
    },
    {
      label: t('products.common.subGroups'),
      key: 'subgroups',
      children: <SubGroupForm />,
    },
    {
      label: t('products.common.products'),
      key: 'products',
      children: <ProductList />,
    },
  ];

  const dropdownItems: IButtonDropDownItems | undefined = [
    {
      label: t('admin.editUserPage.tab.buttonActions.delete'),
      icon: 'trash',
      permission: {
        permission: 3012,
        tooltip: 'noPermission.supply.groups.delete',
      },
      onClick: () => setModalDeleteProductGroup(true),
      dataTestId: 'delete-group-option',
    },
  ];

  const rightButton = {
    ...(disabled &&
      params.tab === 'details' && {
        onClick: () => setDisabled(false),
        isVisible: disabled,
        codePermissionEdit: 3011,
        messageTooltipPermission: 'noPermission.supply.groups.edit',
        dataTestId: 'edit-group-button',
      }),
  };

  return {
    items,
    dropdownItems,
    rightButton,
  };
};
