import { FC } from 'react';
import { Col } from 'antd';
import { InputWithSearch } from '@/Components';
import { useTranslation } from 'react-i18next';
import { useSpecialtiesFunctions } from './Hooks/useSpecialtiesFunctions';
import { useSpecialtiesMemorizations } from './Hooks/useSpecialtiesMemorizations';
import { useInfoModalCallAPI } from './Hooks/useSpecialtiesCallAPI';

export const Specialties: FC = () => {
  const { t } = useTranslation();

  const { fetchNewPageSpecialties, refetchSearchSpecialties, specialtiesData } =
    useInfoModalCallAPI();

  const { onSearch } = useSpecialtiesFunctions({ refetchSearchSpecialties });

  const { items } = useSpecialtiesMemorizations({
    specialtiesData,
  });

  return (
    <Col span={24}>
      <InputWithSearch
        name="especialidadesExternalId"
        selectedItemFromForm="specialtiesLabel"
        label={t('prescribers.createPage.info.specialties')}
        placeHolder={t('prescribers.createPage.info.specialtiesPlaceholder')}
        items={items}
        typeTag="secondary"
        multipleSelection
        onSearch={onSearch}
        onScrollEnd={fetchNewPageSpecialties}
        withoutSearchIcon
      />
    </Col>
  );
};
