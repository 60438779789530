import { useTranslation } from 'react-i18next';
import { useMemo } from 'react';
import { DetailForm } from '../Tabs/DetailForm';
import { PermissionForm } from '../Tabs/PermissionForm';

export const useEditUserMemorizations = () => {
  const { t } = useTranslation();

  const tabItems = useMemo(
    () => [
      {
        label: t('admin.editUserPage.tab.details'),
        key: 'details',
        children: <DetailForm />,
      },
      {
        label: t('admin.editUserPage.tab.permissions'),
        key: 'permissions',
        children: <PermissionForm />,
      },
    ],
    [t]
  );

  return {
    tabItems,
  };
};
