import { FC } from 'react';
import { ICommonError } from '@/Utils/ErrorCodeUtils/ErrorHandlingUtils';
import { useTranslation } from 'react-i18next';

export const Title: FC<ICommonError> = ({ content }) => {
  const { t } = useTranslation();
  return (
    <>
      {content?.length === 1
        ? t('supply.lot.details.modal.cannotDelete.title', {
            lot: content[0].numero,
          })
        : t('supply.lot.details.modal.cannotDelete.batchTitle', {
            lot: content?.length,
          })}
    </>
  );
};
