import { FETCH_DILUTED_PRODUCT } from "@/ControllerApiHook/UniqIds/Supply/ProductKeys";
import { useQueryClient } from "@tanstack/react-query";
import { useCallback } from "react";
import { IUseFunctions } from "./interfaces";

export const useDilutedProductFunctions = ({
  deleteDilutedProduct
}: IUseFunctions) => {
  const queryClient = useQueryClient();

  const onDelete = useCallback(async (invoice: any[]) => {
    const productsExternalId = invoice.map((e: any) => e.externalId);
    await deleteDilutedProduct.mutateAsync({
      produtosDiluidoExternalId: productsExternalId,
    });
    queryClient.invalidateQueries([FETCH_DILUTED_PRODUCT]);
  }, [deleteDilutedProduct]);

  return {
    onDelete
  };
};