import { useQueryClient } from '@tanstack/react-query';
import {
  useControllerMutateApiHook,
  useControllerQueryListApiHook,
} from '@/ControllerApiHook/Controller';
import {
  DELETE_POSOLOGY,
  EDIT_STATUS_POSOLOGY,
  FETCH_LIST_POSOLOGY,
} from '@/ControllerApiHook/UniqIds/Production/PosologyKeys';
import { PosologyAPI } from '@/Data/API/Production/Posology';

export const useListCallAPI = () => {
  const queryClient = useQueryClient();

  const {
    data: posologyList,
    isLoading: isLoadingPosologyList,
    refetch: refetchPosologyList,
  } = useControllerQueryListApiHook({
    uniqId: FETCH_LIST_POSOLOGY,
    entityApi: PosologyAPI.getPosologyList,
    pagination: {
      sorter: { column: 'descricaoPosologia', direction: 'ASC' },
    },
  });

  const deletePosology = useControllerMutateApiHook({
    uniqId: DELETE_POSOLOGY,
    entityApi: PosologyAPI.deletePosology,
    options: {
      onSuccess: () => queryClient.invalidateQueries([FETCH_LIST_POSOLOGY]),
    },
  });

  const editStatusPosology = useControllerMutateApiHook({
    uniqId: EDIT_STATUS_POSOLOGY,
    entityApi: PosologyAPI.putPosologyStatus,
    options: {
      onSuccess: () => queryClient.invalidateQueries([FETCH_LIST_POSOLOGY]),
    },
  });

  return {
    deletePosology,
    editStatusPosology,
    posologyList,
    refetchPosologyList,
    isLoadingPosologyList,
  };
};
