import { IInvoiceListData } from '../../../../../../Data/Interfaces/response/Purchase/IInvoiceResponse';
import { statusTypes } from '../../../../../../Utils/StatusUtils';

export const filterStateText = (state?: number): string => {
  const i18n = 'purchasing.invoice.list.statusTypes';

  if (state === 1) return i18n + '.draft';
  if (state === 2) return i18n + '.pending';
  if (state === 3) return i18n + '.released';
  if (state === 4) return i18n + '.finished';
  return '';
};

export const filterStateColor = (state: number): statusTypes => {
  if (state === 1) return 'disabled';
  if (state === 2) return 'default';
  if (state === 3) return 'attention';
  if (state === 4) return 'success';
  return 'disabled';
};

export const formatSerialNumber = (number: number, padStart: number): any => {
  return number.toString().padStart(padStart, '0');
};

export const formatInvoiceIdentifier = (element: IInvoiceListData): string => {
  const { numero, serie } = element;

  return `${formatSerialNumber(numero, 9)} / ${formatSerialNumber(serie, 3)}`;
};
