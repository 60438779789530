import * as yup from 'yup';
import { i18n } from '../../../../../Components';
import { cpf } from 'cpf-cnpj-validator';

interface ICompanyCardSchema {
  radioCompanyGroup: boolean;
  radioUser: boolean;
  radioCompanyBillings: boolean;
}

export const CompanyCardSchema = (value: ICompanyCardSchema) =>
  yup.object().shape({
    cnpj: yup
      .string()
      .required(
        i18n.t(`adminBootis.createCompany.companyDataCard.errors.requiredCNPJ`)
      ),
    razaoSocial: yup
      .string()
      .required(
        i18n.t(
          'adminBootis.createCompany.companyDataCard.errors.requiredSocialReason'
        )
      ),
    nomeFantasia: yup
      .string()
      .required(
        i18n.t(
          'adminBootis.createCompany.companyDataCard.errors.requiredFantasyName'
        )
      ),
    inscricaoEstadual: yup
      .string()
      .min(
        12,
        i18n.t(
          'adminBootis.createCompany.companyDataCard.errors.invalidStateRegistration'
        )
      )
      .required(
        i18n.t(
          'adminBootis.createCompany.companyDataCard.errors.requiredStateRegistration'
        )
      ),
    cnae: yup
      .string()
      .required(
        i18n.t('adminBootis.createCompany.companyDataCard.errors.requiredCNAE')
      ),
    email: yup
      .string()
      .email(
        i18n.t(
          'adminBootis.createCompany.CompanyAdressCard.errors.invalidEmail'
        )
      ),
    endereco: yup.object().shape({
      cep: yup
        .string()
        .required(
          i18n.t(
            'adminBootis.createCompany.CompanyAdressCard.errors.requiredCEP'
          )
        ),
      numero: yup
        .string()
        .required(
          i18n.t(
            'adminBootis.createCompany.CompanyAdressCard.errors.requiredNumber'
          )
        ),
      bairro: yup
        .string()
        .required(
          i18n.t(
            'adminBootis.createCompany.CompanyAdressCard.errors.requiredNeighborhood'
          )
        ),
      cidade: yup
        .string()
        .required(
          i18n.t(
            'adminBootis.createCompany.CompanyAdressCard.errors.requiredCity'
          )
        ),
      estado: yup
        .string()
        .required(
          i18n.t(
            'adminBootis.createCompany.CompanyAdressCard.errors.requiredState'
          )
        ),
      logradouro: yup
        .string()
        .required(
          i18n.t(
            'adminBootis.createCompany.CompanyAdressCard.errors.requiredStreet'
          )
        ),
    }),

    ...(value.radioUser && {
      usuario: yup.object().shape({
        email: yup
          .string()
          .required(
            i18n.t(
              'adminBootis.createCompany.responsibleUserCard.errors.invalidMail'
            )
          ),

        nome: yup
          .string()
          .required(
            i18n.t(
              'adminBootis.createCompany.responsibleUserCard.errors.requiredName'
            )
          ),

        sobrenome: yup
          .string()
          .required(
            i18n.t(
              'adminBootis.createCompany.responsibleUserCard.errors.requiredLastName'
            )
          ),

        dataNascimento: yup
          .string()
          .matches(
            /[0-9]{2}\/[0-9]{2}\/[0-9]{4}/,
            i18n.t(
              'adminBootis.createCompany.responsibleUserCard.errors.invalidBirthday'
            )
          ),

        cpf: yup
          .string()
          .test(
            'is-valid-cpf',
            i18n.t(
              'adminBootis.createCompany.responsibleUserCard.errors.invalidCpf'
            ),
            (value) => typeof value === 'string' && cpf.isValid(value)
          ),
      }),
    }),
    ...(!value.radioUser && {
      usuarioResponsavelExternalId: yup
        .string()
        .required(
          i18n.t(
            'adminBootis.createCompany.responsibleUserCard.errors.requiredUser'
          )
        ),
    }),
    ...(value.radioCompanyGroup && {
      nomeConglomerado: yup
        .string()
        .required(
          i18n.t(
            'adminBootis.createCompany.companyGroupCard.errors.requiredGroupName'
          )
        ),
    }),

    ...(!value.radioCompanyGroup && {
      conglomeradoExternalId: yup
        .string()
        .required(
          i18n.t(
            'adminBootis.createCompany.companyGroupCard.errors.requiredGroup'
          )
        ),
    }),
    ...(!value.radioCompanyBillings && {
      empresaPagadoraExternalId: yup
        .string()
        .required(
          i18n.t(
            'adminBootis.createCompany.billingCard.errors.requiredCompanyBilling'
          )
        ),
    }),
  });
