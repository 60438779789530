import {
  CREATE_PROVIDER,
  FETCH_LIST_PRESCRIBERS,
} from '@/ControllerApiHook/UniqIds/People/PrescribersKeys';
import { history } from '@/Store';
import { useQueryClient } from '@tanstack/react-query';
import { PrescribersAPI } from '@/Data/API/People/PrescribersApi';
import { useControllerMutateApiHook } from '@/ControllerApiHook/Controller';
import { messageSucessToast } from '@/Utils/MessageErrorToast';
import { i18n } from '@/Components';

export const useCreateCallAPI = () => {
  const queryClient = useQueryClient();

  const {
    mutateAsync: createPrescriber,
    isLoading: createPrescriberIsLoading,
  }: any = useControllerMutateApiHook({
    uniqId: CREATE_PROVIDER,
    entityApi: PrescribersAPI.createPrescriber,
    options: {
      onSuccess: () => {
        queryClient.invalidateQueries([FETCH_LIST_PRESCRIBERS]);
        messageSucessToast(i18n.t('prescribers.createPage.messages.success'));
        history.push('/sales/prescribers');
      },
    },
  });

  return {
    createPrescriber,
    createPrescriberIsLoading,
  };
};
