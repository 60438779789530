import { useFormikContext } from 'formik';
import { useGetTotalProducts } from '../../RowOne/hooks/useGetTotalproducts';

export const useGetTotalInvoice = () => {
  const form: any = useFormikContext();
  const { getTotalProduct } = useGetTotalProducts();
  const totalProducts = getTotalProduct() ?? 0;

  const getTotalInvoice = () => {
    const freight = form.values.valorFrete ?? 0;
    const insurance = form.values.valorSeguro ?? 0;
    const discount = form.values.valorDesconto ?? 0;
    const otherExpenses = form.values.valorOutrasDespesas ?? 0;

    const totalInvoice =
      +totalProducts + freight + insurance - discount + otherExpenses;

    return totalInvoice.toString();
  };

  return { getTotalInvoice };
};
