import { useCallback } from "react";
import { useFormikContext } from "formik";
import { useTranslation } from "react-i18next";
import { IUseFunctions } from "./interfaces";

export const useModalBodyFunctions = ({
  isProductGroupAlreadyRegistred
}: IUseFunctions) => {
  const form: any = useFormikContext();
  const { t } = useTranslation();

  const onGroupValidationError = useCallback(() => {
    form.setErrors({
      ...form.errors,
      descricao: t('products.groupList.modal.errorGroupNameExist'),
    });
    isProductGroupAlreadyRegistred && isProductGroupAlreadyRegistred(true);
  }, [form, t, isProductGroupAlreadyRegistred]);

  const onGroupValidationSuccess = useCallback(() => {
    form.setErrors({
      ...form.errors,
      descricao: undefined,
    });
    isProductGroupAlreadyRegistred && isProductGroupAlreadyRegistred(false);
  }, [form, isProductGroupAlreadyRegistred]);

  return {
    onGroupValidationError,
    onGroupValidationSuccess
  };
};