import * as yup from 'yup';
import i18n from '@/Components/i18n';

export const AdderListSchema = 
  yup.object().shape({
    descricao: yup
      .string()
      .nullable()
      .required(
        i18n.t('address.modal.requiredDescription')
      ),
  });