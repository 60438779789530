import { useFormikContext } from "formik";
import { useCallback } from "react";

export const useSupplierDilutionFactorFunctions = () => {
  const form: any = useFormikContext();

  const onChange = useCallback((e: any) => {
    if (!e) {
      return form.setFieldValue(
        'informacaoTecnica.diluicaoFornecedor',
        ''
      );
    }

    const supplierDilution = 100 / +e;
    form.setFieldValue(
      'informacaoTecnica.diluicaoFornecedor',
      supplierDilution.toFixed(2)
    );
  }, [form]);

  return {
    onChange
  };
};