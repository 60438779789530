import { FC } from 'react';
import { Steps as AntdSteps } from 'antd';

import './Steps.override.scss';
import styles from './Steps.module.scss';

interface ISteps {
  className?: string;
  current?: number;
  items: any[];
  type: 'step-by-step' | 'historical';
}

export const Steps: FC<ISteps> = ({ type, className, current, items }) =>
  GetStepType({
    items: items,
    type: type,
    className: className,
    current: current,
  });

const GetStepType = (props: ISteps) => {
  switch (props.type) {
    case 'step-by-step':
      return (
        <AntdSteps
          direction="horizontal"
          className={`${props.className} ${'horizontal-steps'} ${
            styles['container']
          }`}
          current={props.current}
          items={props.items.map((x) => ({ title: x }))}
        />
      );
    case 'historical':
      return (
        <AntdSteps
          direction="vertical"
          status="process"
          className={`${props.className} ${'vertical-time-line'}`}
          items={props.items}
        />
      );
  }
};
