import { Button } from '@/Components';
import _ from 'lodash';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import styles from './../../Laboratory.module.scss';
import { NotificationActions } from '@/Store/Notification/Notification.actions';
import { useDispatch } from 'react-redux';

interface IFooterButtons {
  resetForm: () => void;
  isLoadingEditLaboratory: boolean;
  onChangeDisabled: (x: boolean) => void;
  isErrorValidadeName: boolean;
}

export const FooterButtons: FC<IFooterButtons> = ({
  isLoadingEditLaboratory,
  onChangeDisabled,
  resetForm,
  isErrorValidadeName,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  return (
    <div className={styles['footer-button']}>
      <Button
        type="secondary"
        children={t('common.cancel')}
        onClick={() => {
          onChangeDisabled(true);
          resetForm();
          dispatch(NotificationActions.setCurrentFormIsDirty(false));
        }}
      />
      <Button
        type="primary"
        children={t('common.save')}
        htmlType="submit"
        loading={isLoadingEditLaboratory}
        disabled={isErrorValidadeName}
      />
    </div>
  );
};
