import { Card } from '@/Components';
import { useTranslation } from 'react-i18next';
import { RowOne } from './rows/RowOne';
import { RowTwo } from './rows/RowTwo';
import { RowThree } from './rows/RowThree';
import { RowFour } from './rows/RowFour';
import { RowFive } from './rows/RowFive';
import { RowSix } from './rows/RowSix';
import FormBreakLine from '@/Components/FormBreakLine';
import { useState } from 'react';

export const InfoSection: React.FC = () => {
  const { t } = useTranslation();
  const [keepSupplier, setKeepSupplier] = useState(false);

  return (
    <Card title={t('purchasing.invoice.create.info')} withoutCollapse>
      <RowOne />
      <RowTwo />
      <RowThree setKeepSupplier={setKeepSupplier} />
      <RowFour />
      <RowFive />
      <FormBreakLine />
      <RowSix keepSupplier={keepSupplier} setKeepSupplier={setKeepSupplier} />
    </Card>
  );
};
