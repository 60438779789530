export const GET_USER_GROUP_TYPE = '[USER_GROUP] GET_USER_GROUP_TYPE';
export const GET_USER_LIST_FROM_USER_GROUP_TYPE =
  '[USER_GROUP] GET_USER_LIST_FROM_USER_GROUP_TYPE';

export const LIST_USER_GROUP_TYPE = '[USER_GROUP] LIST_USER_GROUP_TYPE';
export const LIST_USER_GROUP_DROPDOWN_TYPE =
  '[USER_GROUP] LIST_USER_GROUP_DROPDOWN_TYPE';

export const CREATE_USER_GROUP_TYPE = '[USER] CREATE_USER_GROUP_TYPE';
export const EDIT_USER_GROUP_TYPE = '[USER_GROUP] EDIT_USER_GROUP_TYPE';
export const DELETE_USER_GROUP = '[USER_GROUP] DELETE_USER_GROUP';

export const PATCH_NAME_AND_DESCRIPTION_USER_GROUP =
  '[USER_GROUP] PATCH_NAME_AND_DESCRIPTION_USER_GROUP';
export const CHANGE_USER_GROUP_STATUS = '[USER_GROUP] CHANGE_USER_GROUP_STATUS';
export const USER_GROUP_NAME_ALREADY_REGISTRED_TYPE =
  '[USER_GROUP] USER_GROUP_NAME_ALREADY_REGISTRED_TYPE';
export const ADD_USER_IN_USER_GROUP = '[USER_GROUP] ADD_USER_IN_USER_GROUP';
export const REMOVE_USER_IN_USER_GROUP = '[USER_GROUP] REMOVE_USER_IN_USER_GROUP';