import TransfersDetails from '@/Pages/Sectors/Inventory/Transfers/Details';
import TransfersList from '@/Pages/Sectors/Inventory/Transfers/List';
import { Route, Switch } from 'react-router-dom';
import { CreateTransfers } from '@/Pages/Sectors/Inventory/Transfers/Create';

export const TransfersRoutes = () => (
  <Switch>
    <Route path="/inventory/transfers/create" component={CreateTransfers} />
    <Route
      path="/inventory/transfers/:externalId"
      component={TransfersDetails}
    />
    <Route path="/inventory/transfers" component={TransfersList} />
  </Switch>
);
