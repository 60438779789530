import { IProductGroupListData } from "@/Data/Interfaces/response/Product/IProductRespose";
import { 
  IStockBalanceByLotListData 
} from "@/Data/Interfaces/response/StockBalance/IStockBalanceResponse";
import { 
  IListUnitMeasurementByClassData 
} from "@/Data/Interfaces/response/UnitMeasurement/IUnitMeasurementResponse";
import { useTranslation } from "react-i18next";
import { IUseMemorizations } from "./interfaces";
import { useFormikContext } from "formik";

export const useTransfersMemorizations = ({
  productsList,
  lotsList,
  index,
  unitsMeasurements,
  onUnitOfMeasureChange
}: IUseMemorizations) => {
  const { t } = useTranslation();
  const form = useFormikContext<any>();

  const productsItems = productsList?.data?.map((x: IProductGroupListData) => ({
    id: x?.externalId,
    label: x?.descricao,
    subLabel: `${t('supply.transfers.subLabel')} ${
      x.sequenciaGroupTenant
    }`,
    allItem: x,
  })) || [];

  const lotsItems = lotsList?.data?.map((x: IStockBalanceByLotListData) => ({
    id: x.loteExternalId,
    label: x.loteNumero,
    subLabel: `NF: ${x.numeroNf}`,
  })) || [];

  const unitsMeasurementsDropdownRight = {
    name: `transfers.${index}.unidadeMedidaId`,
    defaultValue: form.values.transfers[index]?.originFunds ? unitsMeasurements?.data?.[0]?.id : '-',
    onChange: onUnitOfMeasureChange,
    options:
      unitsMeasurements?.data?.map(
        (x: IListUnitMeasurementByClassData) => ({
          id: x.id,
          content: x.unidadeAbreviacao,
        })
      ) || [],
  };

  return {
    productsItems,
    lotsItems,
    unitsMeasurementsDropdownRight
  };
};