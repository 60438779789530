import { IIListUnitMeasurementAllData } from "@/Data/Interfaces/response/UnitMeasurement/IUnitMeasurementResponse";
import { IUseMemorizations } from "./interfaces";

export const useDosageInputsMemorizations = ({
  unitMeasurementList,
  setUnitAbbreviation
}: IUseMemorizations) => {
  const defaultUnitId = 6;

  const dropdownRightMinimalDosage = {
    name: 'UnidadeMedidaId',
    defaultValue: defaultUnitId,
    options: unitMeasurementList.data
      ? unitMeasurementList?.data?.map((e: IIListUnitMeasurementAllData) => ({
          content: e.unidadeAbreviacao,
          id: e.id,
        }))
      : [],
    onChange: (value: any) => {
      const unit = unitMeasurementList?.data?.find((e: IIListUnitMeasurementAllData) => e.id === value);
      setUnitAbbreviation(unit?.unidadeAbreviacao || '');
    },
  };

  return {
    dropdownRightMinimalDosage
  };
};