import { FC } from 'react';
import { Col } from 'antd';
import { Tooltip } from '@/Components';
import { useTranslation } from 'react-i18next';
import { useFormikContext } from 'formik';
import { setInitialValuesFormProviderDiluition } from '../../diluitionsHelpers';
import { IDilutionFactor } from './interfaces';
import { TextInputNumber } from '@/Components/Inputs/TextInputNumber';

export const DilutionFactor: FC<IDilutionFactor> = ({ lots, disabled }) => {
  const { t } = useTranslation();
  const form: any = useFormikContext();
  const technicalInfos = lots?.loteInformacaoTecnica;

  return (
    <Col span={6}>
      <Tooltip
        title={`${t('supply.lot.details.toolTips.originalDilutionFactor')} ${
          !!technicalInfos
            ? lots?.loteInformacaoTecnica?.fatorDiluicaoFornecedor
            : 0
        }`}
        showMe={
          lots?.loteInformacaoTecnica?.fatorDiluicaoFornecedor !==
            form?.values?.loteInformacaoTecnica?.fatorDiluicaoFornecedor &&
          !disabled
        }
      >
        <TextInputNumber
          name="loteInformacaoTecnica.fatorDiluicaoFornecedor"
          label={t('supply.lot.details.details.supplierDilutionFactor')}
          placeHolder={t('supply.lot.details.details.supplierDilutionFactor')}
          disabled={disabled}
          withTooltip={{
            icon: 'exclamation-upside-down',
            title: t('supply.lot.create.toolTips.supplierDilutionFactor'),
          }}
          withStatusIcon
          withoutArrows
          onChange={() => setInitialValuesFormProviderDiluition(form)}
          required={!disabled}
        />
      </Tooltip>
    </Col>
  );
};
