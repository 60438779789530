import { useState } from "react";

export const useReasonStates = () => {
  const [
    reasonOriginal, 
    setReasonOriginal
  ] = useState<string>('');

  return {
    reasonOriginal,
    setReasonOriginal
  };
};