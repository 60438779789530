import { FETCH_LIST_UNIT_MEASUREMENT_FOR_DROPDOWN_TYPE } from '@/Hooks/Supply/UnitMeasurementHooks';
import { useControllerQueryListApiHook } from '@/ControllerApiHook/Controller';
import { UnitMeasurementAPI } from '@/Data/API/Supply/UnitMeasurementApi';

export const useDosageInputsCallAPI = () => {
  const { data: unitMeasurementList } = useControllerQueryListApiHook({
    uniqId: FETCH_LIST_UNIT_MEASUREMENT_FOR_DROPDOWN_TYPE,
    entityApi: UnitMeasurementAPI.getListUnitMeasurementDropDown,
    pagination: {
      sorter: { column: 'descricao', direction: 'ASC' },
    },
    autoIncrement: true,
  });

  return {
    unitMeasurementList,
  };
};
