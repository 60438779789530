import { IUseMemorizations } from "./interfaces";

export const useClassMemorizations = ({
  listClassTypes
}: IUseMemorizations) => {
  const items = listClassTypes?.data?.map((x: any) => ({
    id: x.id,
    label: x.descricao,
  }))

  return { items };
};