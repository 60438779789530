import { useState } from 'react';

export const useBodyStates = () => {
  const [createFastPrescriber, setCreateFastPrescriber] = useState(false);
  const [prescriberName, setPrescriberName] = useState('');

  return {
    createFastPrescriber,
    setCreateFastPrescriber,

    prescriberName,
    setPrescriberName,
  };
};
