import React, { FC, useState } from 'react';
import { Input } from 'antd';
import { Field } from 'formik';
import { IField } from '../interfaces/IField';
import { InputLabel } from '../Label';
import { Icon } from '../..';

import './TextInputPassword.override.scss';
import styles from './TextInputPassword.module.scss';
import { ITextInput } from '../TextInput';
import { LocalizaPropriedadePorPath } from '../../../Utils/FormikUtils';

export interface ITextInputPassword extends ITextInput {}

export const TextInputPassword: FC<ITextInputPassword> = ({
  name,
  label,
  placeHolder,
  required,
  disabled,
  className,
  leftIcon,
  withoutMarginBottom,
  dataTestId,
}) => {
  const [visiblePassword, setVisiblePassword] = useState(false);
  const [visibleEye, setVisibleEye] = useState(false);

  return (
    <div
      className={`${withoutMarginBottom ? '' : styles['margin-bottom']} ${
        styles['Container']
      }`}
    >
      <InputLabel label={label} required={required} />
      <Field name={name}>
        {(props: IField) => {
          const classNames = className;

          return (
            <div
              className={`
              	${styles['input-container']} 
								${leftIcon ? styles['with-left-icon'] : null}
              	${classNames}
								`}
            >
              {leftIcon ? (
                <Icon
                  className={styles['Left-Icon']}
                  icon={leftIcon}
                  size="M"
                  color="text-50"
                />
              ) : null}
              <Input
                type={visiblePassword ? 'text' : 'password'}
                {...props.field}
                autoComplete="off"
                data-testid={dataTestId}
                className={styles['PasswordInput']}
                placeholder={placeHolder}
                disabled={disabled || props.form.isSubmitting}
                onChange={(value) => {
                  setVisibleEye(true);
                  return props.field.onChange(value);
                }}
              />
              {visibleEye ? (
                visiblePassword ? (
                  <Icon
                    className={styles['Icon']}
                    icon="eye"
                    onClick={() => setVisiblePassword(!visiblePassword)}
                    color="text-50"
                  />
                ) : (
                  <Icon
                    className={styles['Icon']}
                    icon="close-eye"
                    onClick={() => setVisiblePassword(!visiblePassword)}
                    color="text-50"
                  />
                )
              ) : null}
              {props.form &&
              props.form.errors &&
              LocalizaPropriedadePorPath(props.form.errors, name) ? (
                <p className={styles['errorText']}>
                  {LocalizaPropriedadePorPath(props.form.errors, name)}
                </p>
              ) : null}
            </div>
          );
        }}
      </Field>
    </div>
  );
};
