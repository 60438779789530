import { FC } from "react";
import { Col } from 'antd';
import { useTranslation } from "react-i18next";
import { IPhase } from "./interfaces";
import { TextInputWithMask } from '@/Components/Inputs/TextInputWithMask';

export const Phase: FC<IPhase> = ({
  index,
  addRow
}) => {
  const { t } = useTranslation();

  return (
    <Col span={2}>
      <TextInputWithMask
        name={`produtos.${index}.fase`}
        label={
          index === 0
            ? t('production.standardFormula.create.productLine.phase')
            : undefined
        }
        placeHolder={t(
          'production.standardFormula.create.productLine.phasePlaceholder'
        )}
        onChange={addRow}
        mask="phaseMask"
      />
    </Col>
  );
};