import { CreateSupplier } from '@/Pages/Sectors/Order/Supplier/Create';
import { EditSupplier } from '@/Pages/Sectors/Order/Supplier/Form';
import { SupplierList } from '@/Pages/Sectors/Order/Supplier/List';
import { Route, Switch } from 'react-router-dom';

export const SupplierRoutes = () => (
  <Switch>
    <Route path="/order/supplier/create" component={CreateSupplier} />
    <Route
      path="/order/supplier/:externalId/details"
      component={EditSupplier}
    />
    <Route path="/order/supplier" component={SupplierList} />
  </Switch>
);
