import { Row } from 'antd';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Card } from '@/Components';
import { useFormikContext } from 'formik';
import { TechnicalInfoDilutionsRows } from './Components/TechnicalInfoDilutionsRows';
import { TechnicalInfoNotRawMaterialCard } from './Components/TechnicalInfoNotRawMaterialCard';
import { OriginCountry } from './Components/OriginCountry';
import { Density } from './Components/Density';
import { AlternativeUnit } from './Components/AlternativeUnit';
import { AlternativeUnit2 } from './Components/AlternativeUnit2';
import { useTechnicalInfoCardCallAPI } from './Hooks/useTechnicalInfoCardCallAPI';

export const TechnicalInfoCard: FC = () => {
  const { t } = useTranslation();
  const form: any = useFormikContext();

  const { unidadeAlternativaList } = useTechnicalInfoCardCallAPI();

  return form?.values?.classeId === 1 || !form?.values?.classeId ? (
    <Card
      title={t('supply.lot.create.titleTechnicalInformation')}
      withoutCollapse
    >
      <Row gutter={[16, 0]}>
        <OriginCountry />
        <Density />
      </Row>
      <TechnicalInfoDilutionsRows />
      <Card
        title={t('supply.lot.create.titleConversionRates')}
        insideCard
        onClose
      >
        <Row gutter={[16, 0]} style={{ marginTop: '20px' }}>
          <AlternativeUnit unidadeAlternativaList={unidadeAlternativaList} />
          <AlternativeUnit2 unidadeAlternativaList={unidadeAlternativaList} />
        </Row>
      </Card>
    </Card>
  ) : (
    <TechnicalInfoNotRawMaterialCard />
  );
};

export default TechnicalInfoCard;
