import { i18n } from '@/Components';
import { useControllerMutateApiHook } from '@/ControllerApiHook/Controller';
import {
  FETCH_GET_SALES_ORDER,
  UPDATE_DELIVERY_SALES_ORDER,
} from '@/ControllerApiHook/UniqIds/Sales/SalesOrderKeys';
import { SalesOrderAPI } from '@/Data/API/Sales/SalesOrderApi';
import {
  messageErrorToast,
  messageSucessToast,
} from '@/Utils/MessageErrorToast';
import { useQueryClient } from '@tanstack/react-query';

interface IuseDeliveryModalCallAPI {
  changeVisibleModal: (value: boolean) => void;
}

export const useDeliveryModalCallAPI = ({
  changeVisibleModal,
}: IuseDeliveryModalCallAPI) => {
  const queryClient = useQueryClient();

  const updateDeliverySaleOrder = useControllerMutateApiHook({
    uniqId: UPDATE_DELIVERY_SALES_ORDER,
    entityApi: SalesOrderAPI.updateDeliverySaleOrder,
    options: {
      onSuccess: () => {
        changeVisibleModal(false);
        queryClient.invalidateQueries([FETCH_GET_SALES_ORDER]);
        messageSucessToast(i18n.t('saleOrder.editOrder.deliveryModal.success'));
      },
      onError: (x) => messageErrorToast(x),
    },
  });
  return { updateDeliverySaleOrder };
};
