import React, { CSSProperties, FC, ReactElement, useState } from 'react';

import { Button as AntdButton } from 'antd';
import { IconName } from '../Icon/IconClasses';
import { Icon } from '../Icon';
import { Text } from '../Text';
import Spinner from './Components/Spinner';
import { Badge, BadgeType } from '../Badge';
import { IPermissionWrapper, PermissionWrapper } from '../PermissionWrapper';
import { ButtonDropdown } from './ButtonDropdown';
import { Divflex } from '@/Layouts/DivWhithFlex';
import { Color } from '@/Utils/ColorUtils';

import styles from './Button.module.scss';
import './Button.override.scss';
import { LeftIcon } from './Components/LeftIcon';

export type ButtonType =
  | 'primary'
  | 'secondary'
  | 'tertiary'
  | 'link'
  | 'danger'
  | 'no-color'
  | 'primary-company'
  | 'secondary-company';

export type ButtonStatus = 'default' | 'danger' | 'success' | 'warning';

export interface IButton {
  type: ButtonType;
  status?: ButtonStatus;

  htmlType?: 'submit' | 'button' | 'reset';

  disabled?: boolean;
  contentChild?: 'center' | 'start' | 'flex-end';
  widthChild?: number;
  size?: 'xs' | 'xl';

  leftIcon?: IconName;
  leftIconColor?: Color;
  rightIcon?: IconName;
  noIconPadding?: boolean;
  badge?: { amount: number; badgeType?: BadgeType };

  children?: string | ReactElement;

  className?: string;

  fullWidth?: boolean;
  style?: CSSProperties;

  onClick?: () => void;
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;

  dropdownRight?: {
    label: string;
    onClick: (value?: any) => void;
  }[];

  loading?: boolean;

  permission?: IPermissionWrapper;

  withStatusIcon?: boolean;
  statusIconText?: string;
  wrapperStyle?: React.CSSProperties;
  dataTestId?: string;
}

const InnerButton: FC<IButton> = ({
  type,
  status,
  htmlType,
  disabled,
  contentChild,
  widthChild,
  leftIcon,
  leftIconColor,
  rightIcon,
  badge,
  children,
  className,
  size = 'xl',
  style,
  noIconPadding,
  onClick,
  onMouseEnter,
  onMouseLeave,
  dropdownRight,
  loading,
  fullWidth,
  withStatusIcon,
  statusIconText,
  dataTestId,
}) => {
  const [onMouseDown, setOnMouseDown] = useState(false);

  const buttonStatus = status ? status : 'default';

  return (
    <Divflex>
      {withStatusIcon && (
        <Text
          color="text-50"
          type="ui-tiny-content"
          withStatusIcon={withStatusIcon}
          children={statusIconText}
          className={styles['status-icon-text']}
        />
      )}
      <AntdButton
        style={style}
        data-testid={dataTestId}
        className={`button_${type} button_status_${buttonStatus} ${
          className ? className : ''
        } ${
          children && (leftIcon || rightIcon || badge || type === 'link')
            ? styles['variable']
            : children
            ? styles['Button']
            : styles['circle']
        } ${fullWidth ? styles['fullWidth'] : ''} ${styles[`size-${size}`]} ${
          onMouseDown ? `${type}-button-mouseDown` : ''
        } ${dropdownRight ? styles['button-with-dropdown'] : ''}`}
        htmlType={htmlType}
        onClick={onClick}
        disabled={disabled}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        onMouseDown={() => setOnMouseDown(true)}
        onMouseUp={() => setOnMouseDown(false)}
      >
        {leftIcon && (
          <LeftIcon
            leftIcon={leftIcon}
            leftIconColor={leftIconColor}
            loading={loading}
            noIconPadding={noIconPadding}
            size={size}
            withOnlyIconDescription={!children}
          />
        )}

        <Text
          className={`${contentChild && styles[contentChild]} ${
            styles['children']
          }`}
          type="ui-tiny-bold"
          children={children}
          width={widthChild}
          style={{ overflow: 'hidden' }}
        />
        {loading && children && (
          <Spinner className="button-spinner" style={{ marginLeft: 10 }} />
        )}
        {badge && (
          <Badge
            className="button-badge"
            type={badge.badgeType}
            amount={badge.amount}
          />
        )}
        {rightIcon && (
          <div className={styles['div-rightIcon']}>
            <Icon
              className="button-rightIcon"
              size="M"
              icon={rightIcon}
              color="text-50"
            />
          </div>
        )}
      </AntdButton>
      {dropdownRight && (
        <Divflex style={{ position: 'relative' }}>
          <div className={styles['divider']} />
          <ButtonDropdown
            type="primary"
            children={<Icon icon="chevron-down" size="M" color="white" />}
            dropdownItens={dropdownRight?.map((x) => ({
              label: x.label,
              onClick: x.onClick,
            }))}
            className={styles['dropdown-button']}
          />
        </Divflex>
      )}
    </Divflex>
  );
};

export const Button: FC<IButton> = ({ permission, wrapperStyle, ...props }) => {
  return (
    <PermissionWrapper
      permission={permission?.permission}
      tooltip={permission?.tooltip || 'noPermission.default'}
      style={{ display: 'flex', ...wrapperStyle }}
    >
      <InnerButton {...props} />
    </PermissionWrapper>
  );
};
