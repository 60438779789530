import { useCallback } from 'react';
import { IUseFunctions } from './interfaces';

export const useIncompatibleProductFunctions = ({
  refetchSearchProductPage,
}: IUseFunctions) => {
  const onSearch = useCallback(
    (search?: string) => {
      refetchSearchProductPage({
        search: search,
      });
    },
    [refetchSearchProductPage]
  );

  return {
    onSearch,
  };
};
