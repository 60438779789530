import { currencyFormater } from '@/Utils/Currency';
import { IUseMemorizations } from './interfaces';
import { useTranslation } from 'react-i18next';
import { useFormikContext } from 'formik';
import { 
  TypePurchaseOrderEnum 
} from '@/Data/Interfaces/model/typePurchaseOrderEnum';

export const useTotalMemorizations = ({
  withSelectedRows,
  productsValue,
  purchaseOrder,
  hasProducts
}: IUseMemorizations) => {
  const { t } = useTranslation();
  const form: any = useFormikContext();

  const labelValue = withSelectedRows
  ? currencyFormater(
      productsValue.totalProduct - productsValue.totalDiscount
    )
  : currencyFormater(
      purchaseOrder?.totalizadores?.totalProdutosLiquido ?? 0
    );

  const withTooltip = withSelectedRows && hasProducts
  ? {
      title: `${t('common.originalValue')} ${currencyFormater(
        purchaseOrder?.totalizadores?.totalProdutosLiquido ?? 0
      )}`,
      showMe: form.dirty,
    }
  : undefined;

  const style = !hasProducts && withSelectedRows
  ? {
      textDecorationLine: 'line-through',
      color: 'var(--color-text-300)',
    }
  : form.values.status === TypePurchaseOrderEnum.canceled ||
    form.values.status === TypePurchaseOrderEnum.disapproved
  ? { textDecorationLine: 'line-through' }
  : {};

  return {
    labelValue,
    withTooltip,
    style
  };
};