import React from 'react';
import { i18n, Icon, Link, Status, Text } from '../../../../../Components';
import { MaskForCNPJ, MaskForCPF } from '../../../../../Utils/MasksUtils';
import { Divflex } from '../../../../../Layouts/DivWhithFlex';
import { ISupplierListData } from '../../../../../Data/Interfaces/response/Purchase/ISupplierResponse';
import { formatPhoneNumber } from 'react-phone-number-input';

export const providerColumn = (t: any) => [
  {
    title: t('purchasing.provider.list.nameOfSupplier'),
    dataIndex: 'nomeFantasia',
    key: 'nomeFantasia',
    sortColumn: 'nomeFantasia',
    sorter: true,
    width: '25%',
    defaultSortOrder: 'ascend',
    isHighlightable: true,
    render: (x: string, row: any) => (
      <Link
        type="ui-tiny-bold"
        to={`/order/supplier/${row?.externalId}/details`}
        style={{ width: 'fit-content' }}
      >
        {x}
      </Link>
    ),
  },
  {
    title: t('purchasing.provider.list.cnpjOrCpf'),
    dataIndex: 'cnpj',
    key: 'cnpj',
    width: '20%',
    sortColumn: 'cnpj',
    sorter: true,
    isHighlightable: true,
    render: (x: string, row: ISupplierListData) => (
      <Text
        type="ui-tiny-content"
        color="text-50"
        children={row.cpf === null ? MaskForCNPJ(x) : MaskForCPF(row.cpf)}
      />
    ),
  },
  {
    title: t('purchasing.provider.list.mainContact'),
    dataIndex: 'identificacao',
    key: 'identificacao',
    sortColumn: 'identificacao',
    sorter: true,
    isHighlightable: true,
    render: (x: string, row: ISupplierListData) =>
      x ? (
        <Divflex>
          <Icon
            icon={row.tipoContatoIcon}
            size="SM"
            style={{ marginRight: 15 }}
          />
          <Text
            type="ui-tiny-content"
            color="text-50"
            children={
              row.tipoContatoIcon === 'phone' ||
              row.tipoContatoIcon === 'mobile' ||
              row.tipoContatoIcon === 'whatsapp'
                ? formatPhoneNumber(x)
                : x
            }
          />
        </Divflex>
      ) : (
        <Text type="ui-tiny-content" color="text-50" children={'-'} />
      ),
  },
  {
    title: t('purchasing.provider.list.typePerson'),
    dataIndex: 'tipoPessoaDescricao',
    sortColumn: 'tipoPessoaDescricao',
    sorter: true,
    isHighlightable: true,
    key: 'tipoPessoaDescricao',
  },
  {
    title: t('purchasing.provider.list.status'),
    dataIndex: 'ativo',
    sortColumn: 'ativo',
    sorter: true,
    isHighlightable: true,
    key: 'ativo',
    render: (text: any) => (
      <Status type={text ? 'success' : 'disabled'}>
        {i18n.t(`userGroups.list.statusTypes.${text ? 'active' : 'inactive'}`)}
      </Status>
    ),
  },
];
