import { useEffect, useState } from 'react';
import {
  Button,
  Icon,
  InputWithSearch
} from '@/Components';
import { WeightInput } from '@/Components/Inputs/WeightInput';
import { Col, Row } from 'antd';
import { useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';
import { IProductRow } from './interfaces';
import { useProductRowCallAPI } from './Hooks/useProductRowCallAPI';
import { useProductRowMemorizations } from './Hooks/useProductRowMemorizations';
import { useProductRowFunctions } from './Hooks/useProductRowFunctions';

export const ProductRow = ({
  index,
  removeRow,
  addRow,
  disabledRemoveButton,
  disabled,
  disabledProductList,
  setDisabledProductList
}: IProductRow) => {
  const { t } = useTranslation();
  const [productId, setProductId] = useState<string>('');
  const form: any = useFormikContext();
  
  const {
    productList,
    fetchNewProductPage,
    isLoadingProduct,
    refetchSearchProductPage,
    listunitsMeasurementsPrescription,
    isUnitOfMeasurePrescriptionLoading,
    unitsMeasurementsDefault
  } = useProductRowCallAPI({ productId });

  const {
    items,
    dropdownRight
  } = useProductRowMemorizations({
    listunitsMeasurementsPrescription,
    isUnitOfMeasurePrescriptionLoading,
    unitsMeasurementsDefault,
    productList,
    disabledProductList,
    index
  });

  const {
    onSearch,
    onChange,
    onClick
  } = useProductRowFunctions({
    refetchSearchProductPage,
    disabledProductList,
    index,
    setDisabledProductList,
    addRow,
    setProductId,
    removeRow
  });

  useEffect(() => {
    if (unitsMeasurementsDefault && unitsMeasurementsDefault.unidadeEstoqueId) {
      form.setFieldValue(
        `materiaPrimaAssociacao.${index}.unidadeId`,
        unitsMeasurementsDefault.unidadeEstoqueId
      );
    }
  }, [unitsMeasurementsDefault]);

  return (
    <Row gutter={[16, 0]}>
      <Col span={6}>
        <InputWithSearch
          name={`materiaPrimaAssociacao.${index}.produtoMateriaPrimaExternalId`}
          items={items}
          isLoading={isLoadingProduct}
          onScrollEnd={fetchNewProductPage}
          onSearch={onSearch}
          placeHolder={t('products.create.productPlaceHolder')}
          onChange={onChange}
          disabled={disabled}
        />
      </Col>
      <Col span={3}>
        <WeightInput
          name={`materiaPrimaAssociacao.${index}.quantidade`}
          id={`materiaPrimaAssociacao.${index}.quantidade`}
          placeHolder="0,00"
          dropdownRight={dropdownRight}
          disabled={disabled}
        />
      </Col>
      <Col span={1}>
        {!disabled && (
          <Button
            type="secondary"
            disabled={disabledRemoveButton}
            onClick={onClick}
          >
            <Icon icon="trash" size="M" />
          </Button>
        )}
      </Col>
    </Row>
  );
};
