import StorageLocation from '@/Pages/Sectors/Inventory/SupplyPlaces/Create';
import FormStorageLocation from '@/Pages/Sectors/Inventory/SupplyPlaces/Form';
import List from '@/Pages/Sectors/Inventory/SupplyPlaces/List';
import { Route, Switch } from 'react-router-dom';

export const SupplyPlacesRoutes = () => (
  <Switch>
    <Route path="/inventory/supply-places/create" component={StorageLocation} />
    <Route
      path="/inventory/supply-places/:externalId/:tab"
      component={FormStorageLocation}
    />
    <Route path="/inventory/supply-places" component={List} />
  </Switch>
);
