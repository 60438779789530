import { useControllerMutateApiHook } from '@/ControllerApiHook/Controller';
import { LotsAPI } from '@/Data/API/Supply/LotApi';
import { DELETE_LOT } from '@/ControllerApiHook/UniqIds/Supply/LotKeys';

export const useModalCallAPI = () => {
  const deleteLot: any = useControllerMutateApiHook({
    uniqId: DELETE_LOT,
    entityApi: LotsAPI.deleteLot
  });

  return { deleteLot };
};