import { useState } from 'react';

export const useSpecialtyState = () => {
  const [isFastSpecialtyVisible, setIsFastSpecialtyVisible] = useState(false);

  const [descriptionValue, setDescriptionValue] = useState('');

  return {
    isFastSpecialtyVisible,
    setIsFastSpecialtyVisible,
    descriptionValue,
    setDescriptionValue,
  };
};
