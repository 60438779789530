import { VALIDATION_NAME_PRODUCT } from "@/ControllerApiHook/UniqIds/Supply/ProductKeys";
import { useFormikContext } from "formik";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import _ from "lodash";
import { useControllerMutateApiHook } from "@/ControllerApiHook/Controller";
import { ProductAPI } from "@/Data/API/Supply/Product";

export const useInputProductNameFunctions = () => {
  const { t } = useTranslation();
  const form: any = useFormikContext();

  const onProductValidationError = useCallback(() => {
    form.setErrors({
      ...form.errors,
      descricao: t('products.create.errors.errorNameExist')
    });
  }, [form, t]);

  const { mutateAsync, error } = useControllerMutateApiHook({
    uniqId: VALIDATION_NAME_PRODUCT,
    entityApi: ProductAPI.productNameValidation,
    options: {
      onError: onProductValidationError
    }
  });

  const fetchProductValidationName = useCallback(
    _.debounce(async (description: string) => {
      if (description.trim() !== '') {
        try {
          await mutateAsync({
            descricao: description,
          });
          form.setErrors({
            ...form.errors,
            descricao: '',
          });
        } catch {}
      } else {
        form.setErrors({
          ...form.errors,
          descricao: '',
        });
      }
    }, 1000),
    [mutateAsync, form.errors, form.values.descricao]
  );

  const onChange = useCallback((desc: string) => {
    fetchProductValidationName(desc);
  }, [fetchProductValidationName]);

  return {
    onChange,
    error
  };
};