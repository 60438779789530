import { FC } from "react";
import { Col } from 'antd';
import { useTranslation } from 'react-i18next';
import { TextInputNumber } from "@/Components/Inputs/TextInputNumber";

export const NumberInvoice: FC = () => {
  const { t } = useTranslation();

  return (
    <Col span={4}>
      <TextInputNumber
        name="numeroNf"
        label={t('supply.lot.create.numberInvoice')}
        placeHolder={t('supply.lot.placeholder.numberInvoice')}
        required
        withoutArrows
      />
    </Col>
  );
};