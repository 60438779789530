import { TFunction } from 'react-i18next';
import { ICannotDeleteModalInfo } from '../ErrorHandlingUtils';

export const cannotDeleteCapsuleColorsWithMovementsInTheSystemProps = (
  t: TFunction<'translation', undefined>
): ICannotDeleteModalInfo => ({
  formName: 'descricao',
  oneErrorModal: {
    titleModal: `${t('common.modalCannotDelete.messageTitle')} ${t(
      'production.capsuleColors.modalCannotDelete.messageTitle'
    )}`,
    subTitleModal: `${t('common.modalCannotDelete.messageSubTitle')} ${t(
      'production.capsuleColors.modalCannotDelete.messageSubtitle'
    )}`,
    reasonTranslation: `${t(
      'production.capsuleColors.modalCannotDelete.messageReasonCannotDelete'
    )}`,
  },
  manyErrorsModal: {
    entity: `${t('common.modalCannotDelete.messageTitle')}`,
    complement: `${t(
      'production.capsuleColors.modalCannotDelete.manyMessageTitle'
    )}`,
    subTitleModal: `${t('common.modalCannotDelete.messageSubTitle')} ${t(
      'production.capsuleColors.modalCannotDelete.manyMessageSubtitle'
    )}`,
    reasonTranslation: `${t(
      'production.capsuleColors.modalCannotDelete.manyMessageReasonCannotDelete'
    )}`,
  },
});
