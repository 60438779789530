import { FETCH_LIST_SPECIALTIES_FOR_DROPDOWN } from '@/ControllerApiHook/UniqIds/People/SpecialtiesKeys';
import { FETCH_LIST_RECORD_TYPE } from '@/ControllerApiHook/UniqIds/People/RecordKeys';
import { GET_LIST_STATE } from '@/ControllerApiHook/UniqIds/Locality/StateKeys';
import { StateAPI } from '@/Data/API/Locality/StateApi';
import { RecordAPI } from '@/Data/API/People/RecordApi';
import { SpecialtiesAPI } from '@/Data/API/People/SpecialtiesApi';
import {
  useControllerMutateApiHook,
  useControllerQueryListApiHook,
} from '@/ControllerApiHook/Controller';
import { VALIDATE_REGISTRATION_PRESCRIBER } from '@/ControllerApiHook/UniqIds/People/PrescribersKeys';
import { PrescribersAPI } from '@/Data/API/People/PrescribersApi';
import { FormikContextType } from 'formik';
import { TFunction } from 'react-i18next';

export const useInfoCardCallAPI = (
  form: FormikContextType<any>,
  t: TFunction<'translation', undefined>
) => {
  const {
    data: listState,
    refetch: refetchStateList,
    fetchNewPage: fetchNewStatePage,
  } = useControllerQueryListApiHook({
    uniqId: GET_LIST_STATE,
    entityApi: StateAPI.getListState,
    autoIncrement: true,
    pagination: {
      sorter: { column: 'descricao', direction: 'ASC' },
    },
  });

  const {
    data: recordTypeList,
    refetch: refetchRecordTypeList,
    fetchNewPage: fetchNewRecordPage,
  } = useControllerQueryListApiHook({
    uniqId: FETCH_LIST_RECORD_TYPE,
    entityApi: RecordAPI.listRecordType,
    autoIncrement: true,
    pagination: {
      sorter: { column: 'descricao', direction: 'ASC' },
    },
  });

  const {
    data: specialtiesData,
    isLoading: specialtiesLoading,
    refetch: refetchSearchSpecialties,
    fetchNewPage: fetchNewSpecialtiesPage,
  } = useControllerQueryListApiHook({
    uniqId: FETCH_LIST_SPECIALTIES_FOR_DROPDOWN,
    entityApi: SpecialtiesAPI.listSpecialtiesForDropdown,
    autoIncrement: true,
    pagination: {
      sorter: { column: 'descricao', direction: 'ASC' },
    },
  });

  const { mutateAsync: fetchValidateRegistration }: any =
    useControllerMutateApiHook({
      uniqId: VALIDATE_REGISTRATION_PRESCRIBER,
      entityApi: PrescribersAPI.validationRegistrationPrescriber,
      options: {
        onError: () =>
          form.setErrors({
            ...form.errors,
            codigoRegistro: t('prescribers.createPage.messages.errorRegister'),
          }),
        onSuccess: () => form.setFieldError('codigoRegistro', undefined),
      },
    });

  return {
    listState,
    refetchStateList,
    fetchNewStatePage,
    recordTypeList,
    refetchRecordTypeList,
    fetchNewRecordPage,
    specialtiesData,
    specialtiesLoading,
    refetchSearchSpecialties,
    fetchNewSpecialtiesPage,
    fetchValidateRegistration,
  };
};
