import { FC } from 'react';
import { Col } from 'antd';
import { useTranslation } from 'react-i18next';
import { TextInputWithMask } from '@/Components/Inputs/TextInputWithMask';

export const ManufacturingDate: FC = () => {
  const { t } = useTranslation();

  return (
    <Col span={4}>
      <TextInputWithMask
        name="dataFabricacao"
        mask="dataMask"
        leftIcon="calendar"
        label={t('supply.lot.create.manufacturing')}
        placeHolder={t('dd/mm/yyyy')}
        required
      />
    </Col>
  );
};
