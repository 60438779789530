import { useFormikContext } from "formik";
import { useCallback } from "react";

export const usePhoneFunctions = () => {
  const form = useFormikContext();

  const onChange = useCallback(
    (x: string) =>
      form.setFieldValue('contatos', [
        { tipoContatoId: '2', identificacao: x, principal: true },
      ])
    , [form]
  );

  return { onChange };
};