import {
  CREATE_INCOMPATIBLE_PRODUCT
} from '@/ControllerApiHook/UniqIds/Supply/ProductKeys';
import { useControllerMutateApiHook } from '@/ControllerApiHook/Controller';
import { ProductAPI } from '@/Data/API/Supply/Product';

export const useCreateIncompatibleProductCallAPI = () => {
  const createIncompatibleProduct: any = useControllerMutateApiHook({
    uniqId: CREATE_INCOMPATIBLE_PRODUCT,
    entityApi: ProductAPI.createIncompatibleProduct
  });

  return {
    createIncompatibleProduct
  };
};