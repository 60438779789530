import { FC, useEffect } from 'react';
import {
  Button,
  Card,
  Form
} from '@/Components';
import { Row } from 'antd';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { IAssociatedDetailProps } from './interfaces';
import { Product } from './Components/Product';
import { AssociatedProduct } from './Components/AssociatedProduct';
import { PharmaceuticalForm } from './Components/PharmaceuticalForm';
import { DosageInputs } from './Components/DosageInputs';
import { Relationship } from './Components/Relationship';
import { Accumulate } from './Components/Accumulate';
import { useAssociatedProductDetailsStates } from './Hooks/useAssociatedProductDetailsStates';
import { useAssociatedProductDetaisCallAPI } from './Hooks/useAssociatedProductDetailsCallAPI';
import { useAssociatedProductDetailsFunctions } from './Hooks/useAssociatedProductDetailsFunctions';
import { useAssociatedProductDetailsMemorizations } from './Hooks/useAssociatedProductDetailsMemorizations';

export const AssociatedProductDetails: FC<IAssociatedDetailProps> = ({
  disableForm,
  disabledForm
}) => {
  const { t } = useTranslation();
  const { externalId: productExternalId, associationProductId } = useParams<{
    externalId: string;
    associationProductId: string;
  }>();

  const {
    accumulate,
    setAccumulate,
    associatedProductExternalId,
    setAssociatedProductExternalId
  } = useAssociatedProductDetailsStates({
    productExternalId
  });
  
  const {
    currentProduct,
    editAssociatedProduct,
    associatedProductData
  } = useAssociatedProductDetaisCallAPI({
    productExternalId,
    associationProductId
  });

  const {
    handleSubmit,
    onClickCancelButton,
    onClickBackButton
  } = useAssociatedProductDetailsFunctions({
    editAssociatedProduct,
    productExternalId,
    associationProductId,
    accumulate,
    disableForm
  });

  const {
    initialValues
  } = useAssociatedProductDetailsMemorizations({
    associatedProductData
  });

  useEffect(() => {
    if (associatedProductData && associatedProductData.acumula) {
      setAccumulate(true);
    }

    if (associatedProductData) {
      setAssociatedProductExternalId(
        associatedProductData.produtoAssociadoExternalId
      );
    }
  }, [associatedProductData]);

  return (
    <Form
      initialValues={initialValues}
      onSubmit={handleSubmit}
    >
      {(props) => (
        <>
          <Card
            title={`${t('products.associated.associatedProduct')} | ${
              currentProduct?.descricao
            }`}
            withoutCollapse
          >
            <Row gutter={[16, 0]}>
              <Product currentProduct={currentProduct} />
            </Row>
            <Row gutter={[16, 0]}>
              <AssociatedProduct
                productExternalId={productExternalId}
                associatedProductData={associatedProductData}
                props={props}
                setAssociatedProductExternalId={setAssociatedProductExternalId}
                disabledForm={disabledForm}
              />
              <PharmaceuticalForm
                productExternalId={productExternalId}
                associatedProductData={associatedProductData}
                associatedProductExternalId={associatedProductExternalId}
                props={props}
                disabledForm={disabledForm}
              />
              <DosageInputs
                disabledForm={disabledForm}
                associatedProductData={associatedProductData}
              />
            </Row>
            <Row gutter={[16, 0]}>
              <Relationship
                associatedProductData={associatedProductData}
                props={props}
                disabledForm={disabledForm}
              />
              <Accumulate
                accumulate={accumulate}
                setAccumulate={setAccumulate}
                associatedProductData={associatedProductData}
                disabledForm={disabledForm}
              />
            </Row>
            {!disabledForm && (
              <div style={{ display: 'flex', marginTop: '15px' }}>
                <Button
                  type="secondary"
                  style={{ marginRight: 8 }}
                  children={t('common.cancel')}
                  onClick={() => onClickCancelButton(props)}
                />
                <Button
                  type="primary"
                  children={
                    editAssociatedProduct.isLoading
                      ? t('common.saving')
                      : t('common.save')
                  }
                  htmlType="submit"
                  loading={editAssociatedProduct.isLoading}
                  disabled={
                    props.values.produtoAssociadoExternalId <
                      props.values.dosagemMinima ||
                    !props.values.produtoAssociadoExternalId ||
                    !props.values.tipoRelacaoQuantidade ||
                    !props.values.acumula
                  }
                />
              </div>
            )}
          </Card>
          {disabledForm && (
            <Button
              type="secondary"
              onClick={onClickBackButton}
              children={t('common.back')}
            />
          )}
        </>
      )}
    </Form>
  );
};
