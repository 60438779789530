import _ from 'lodash';
import { useCallback } from 'react';
import { IUseFunctions } from './interfaces';

export const useCreateFunctions = ({ mutateAsync }: IUseFunctions) => {
  const fetchCapsuleColorName = useCallback(
    _.debounce(async (value: string) => {
      try {
        return await mutateAsync({
          descricao: value,
        });
      } catch {
        return undefined;
      }
    }, 500),
    [mutateAsync]
  );

  return { fetchCapsuleColorName };
};
