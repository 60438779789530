import { IGetCompanyResponse } from '../../../Data/Interfaces/response/Company/ICompanyResponse';

export const initialValues = (data: IGetCompanyResponse) => ({
  empresasExternalId: [data.externalId],
  usuarioResponsavelExternalId: data.usuario.usuarioResposavelExternalId,
  usuarioResponsavelLabelInitialValue: [{
    id: data.usuario.usuarioResposavelExternalId,
    label: data.usuario.nome,
    avatar: {
      email: data.usuario.email,
      name: data.usuario.nome,
      lastName: data.usuario.sobrenome,
    },
  }],
});
