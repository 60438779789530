import { FC, useEffect } from 'react';
import {
  Card,
  Dropdown,
  Tooltip
} from '@/Components';
import FormBreakLine from '@/Components/FormBreakLine';
import { InputLabel } from '@/Components/Inputs/Label';
import { Col, Row } from 'antd';
import { useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';
import { v4 as uuidV4 } from 'uuid';
import { ICapsuleGranelCard } from './interfaces';
import { ProductRow } from './Components/ProductRow';
import { useCapsuleGranelCardCallAPI } from './Hooks/useCapsuleGranelCardCallAPI';
import { useCapsuleGranelCardStates } from './Hooks/useCapsuleGranelCardStates';
import { useCapsuleGranelCardMemorizations } from './Hooks/useCapsuleGranelCardMemorizations';

export const CapsuleGranelCard: FC<ICapsuleGranelCard> = ({
  disabledForm,
  product
}) => {
  const { t } = useTranslation();
  const form: any = useFormikContext();

  const {
    listCapsuleSize,
    isLoadingCapsuleSize,
    fetchNewPageCapsuleSize
  } = useCapsuleGranelCardCallAPI();

  const {
    productListController,
    setProductListController,
    disabledProductList,
    setDisabledProductList
  } = useCapsuleGranelCardStates();

  const {
    additionalInfoTitle,
    additionalInfoShowMe,
    capsuleSizeItems
  } = useCapsuleGranelCardMemorizations({
    product,
    disabledForm,
    listCapsuleSize
  });

  useEffect(() => {
    if (disabledForm === true) {
      let newProductListController = [...productListController];
      newProductListController.pop();
      setProductListController(newProductListController);
    } else if (disabledForm === false && product) {
      setProductListController((prev) => [...prev, uuidV4()]);
    }
  }, [disabledForm, product]);

  useEffect(() => {
    if (product && product.capsulaPronta) {
      const disabledProductList: string[] = [];
      const newProductListController: string[] = [];

      product.capsulaPronta.materiaPrimaAssociacoes.forEach((e: any) => {
        newProductListController.push(uuidV4());
        disabledProductList.push(e.produtoMateriaPrimaExternalId);
      });

      setProductListController(newProductListController);
      setDisabledProductList(disabledProductList);
    }
  }, [product]);

  return (
    <Card title={t('products.create.additionalInformation')}>
      <Row gutter={[16, 0]}>
        <Col span={5}>
          <Tooltip
            title={additionalInfoTitle}
            showMe={additionalInfoShowMe}
          >
            <Dropdown
              label={t('products.create.capsuleSize')}
              required
              name="capsulaTamanhoId"
              placeHolder={t('products.create.capsuleSizePlaceholder')}
              withTooltip={{
                icon: 'exclamation-upside-down',
                title: t('products.create.capsuleSizeTooltip'),
              }}
              isLoading={isLoadingCapsuleSize}
              onScrollEnd={fetchNewPageCapsuleSize}
              items={capsuleSizeItems}
              disabled={disabledForm}
              withStatusIcon={!!product}
            />
          </Tooltip>
        </Col>
      </Row>
      <Row gutter={[16, 0]}>
        {productListController.length > 0 && (
          <>
            <Col span={6}>
              <InputLabel label={t('products.create.product')} />
            </Col>
            <Col span={3}>
              <InputLabel label={t('products.create.quantity')} />
            </Col>
          </>
        )}
      </Row>
      <FormBreakLine marginBottom="10px" />
      {productListController.map((e, index) => (
        <ProductRow
          index={index}
          key={e}
          removeRow={() => {
            const newProducts = form.values.materiaPrimaAssociacao.filter(
              (_: any, i: number) => i !== index
            );

            form.setFieldValue('materiaPrimaAssociacao', newProducts);
            setProductListController((old) =>
              old.filter((_, i) => i !== index)
            );
          }}
          addRow={() => {
            if (index === productListController.length - 1) {
              setProductListController((old) => [...old, uuidV4()]);
            }
          }}
          disabledRemoveButton={productListController.length - 1 === index}
          disabled={!!disabledForm}
          disabledProductList={disabledProductList}
          setDisabledProductList={(value: string[]) =>
            setDisabledProductList(value)
          }
        />
      ))}
      <FormBreakLine />
    </Card>
  );
};