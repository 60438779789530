import { ListNatureOfOperation } from '@/Services/Purchase/NatureOfOperation/ListNatureOfOperation.service';
import { useControllerQueryListApiHook } from '@/ControllerApiHook/Controller';
import { FormatDataToDropdownUtils } from '@/Utils/FormatDataToDropdownUtils';
import { IQueryListParams } from '@/Utils/Http';

export const FETCH_LIST_DROPDOWN_NATURE_OF_OPERATION =
  '[NATURE_OF_OPERATION] FETCH_LIST_DROPDOWN_NATURE_OF_OPERATION';

interface IUseListNatureOfOperation {
  fieldName: string;
  enabled?: boolean;
  pagination?: IQueryListParams;
}

export const useListNatureOfOperation = ({
  fieldName,
  enabled,
  pagination,
}: IUseListNatureOfOperation) => {
  const { data, refetch, isLoading, fetchNewPage } =
    useControllerQueryListApiHook({
      uniqId: FETCH_LIST_DROPDOWN_NATURE_OF_OPERATION,
      entityApi: ListNatureOfOperation,
      autoIncrement: true,
      enabled,
      pagination,
    });

  const items = FormatDataToDropdownUtils({
    data: data && data.data ? data.data : [],
    idKey: 'id',
    labelKey: 'descricao',
  });

  const onSearch = (search?: string) => {
    refetch({
      search,
    });
  };

  return {
    name: fieldName,
    items,
    onSearch,
    isLoading,
    onScrollEnd: fetchNewPage,
  };
};
