import { Card, Form } from '@/Components';
import { Col, Row } from 'antd';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './lotsStyles.module.scss';
import FormBreakLine from '@/Components/FormBreakLine';
import { useParams } from 'react-router';
import {
  FETCH_LIST_LOTS
} from '@/ControllerApiHook/UniqIds/Purchase/InvoiceKeys';
import { ProductRow } from '../../components/ProductRow';
import { TechnicalInformationCard } from '../../components/TecnicalInformationCard';
import { formatLotsGet } from '../../../RegisterLots/utils/functions';
import { useControllerQueryApiHook } from '@/ControllerApiHook/Controller';
import { InvoiceAPI } from '@/Data/API/Purchase/InvoiceApi';
import { IInvoiceGetLotResponse } from '@/Data/Interfaces/response/Purchase/IInvoiceResponse';

export const LotsPage: React.FC = () => {
  const { t } = useTranslation();
  const { externalId } = useParams<{ externalId: string }>();
  const { data: lots } = useControllerQueryApiHook<IInvoiceGetLotResponse>({
    uniqId: FETCH_LIST_LOTS,
    entityApi: InvoiceAPI.getInvoiceLots,
    externalIds: [externalId]
  });
  const [products, setProducts] = useState<any[]>([]);
  const [selectedProductIndex, setSelectedProductIndex] = useState<number>(-1);
  const [selectedLotIndex, setSelectedLotIndex] = useState<number>(0);
  const [formattedLots, setFormattedLots] = useState<any[]>([]);
  const [showTechnicalInformation, setShowTechnicalInformation] =
    useState<boolean>(false);
  const [selectedRow, setSelectedRow] = useState<number | null>(null);
  const configButtonText = useCallback(() => {
    if (showTechnicalInformation) {
      return t('purchasing.invoice.registerLots.hideDetails');
    } else {
      return t('purchasing.invoice.registerLots.showDetails');
    }
  }, [showTechnicalInformation, t]);
  const selectRow = (rowIndex: number) => {
    setSelectedRow(rowIndex);
  };

  useEffect(() => {
    if (lots) {
      setProducts(lots.itens);
    }
  }, [lots]);
  useEffect(() => {
    if (lots) {
      const temporaryLots: any[] = [];

      lots.itens.forEach((item: any) => {
        item.lotes.forEach((lot: any) => {
          temporaryLots.push({
            ...lot,
            notaFiscalEntradaItemId: item.notaFiscalEntradaItemId,
            produto: item.produto,
            unidadeMedida: item.unidadeMedida,
            valorUnitario: item.valorUnitario,
            valorTotal: item.valorTotal,
          });
        });
      });

      const formattedLotsTemp = formatLotsGet(temporaryLots);
      setFormattedLots(formattedLotsTemp);
    }
  }, [lots]);

  return (
    <Form initialValues={{ lotes: formattedLots }}>
      <div
        className={` ${
          showTechnicalInformation
            ? styles['showTechnicalCard']
            : styles['hideTechnicalCard']
        }`}
      >
        <Card
          withoutCollapse
          title={t('purchasing.invoice.registerLots.lotsCardTitle')}
          showButtonTitle
          isButtonTitleDisabled={selectedRow === null}
          buttonTittleText={configButtonText()}
          onButtonTitleClick={() => {
            setShowTechnicalInformation((prev) => !prev);
          }}
        >
          <HeaderRow showTechnicalInformation={showTechnicalInformation} />
          <FormBreakLine />
          {products.map((e, i) => {
            return (
              <div
                key={e.notaFiscalEntradaItemId}
                onClick={() => setSelectedProductIndex(i)}
              >
                <ProductRow
                  product={e}
                  index={i}
                  selectedRow={selectedRow}
                  selectRow={selectRow}
                  showTechnicalInformation={showTechnicalInformation}
                  showTechInformation={() => setShowTechnicalInformation(true)}
                  lotsListController={formattedLots ?? []}
                  selectLotIndex={(selectedLotIndex) =>
                    setSelectedLotIndex(selectedLotIndex)
                  }
                />
                {i < products.length - 1 && (
                  <FormBreakLine marginBottom="0px" />
                )}
              </div>
            );
          })}
        </Card>
        {products.length > 0 && products[selectedProductIndex] && (
          <TechnicalInformationCard
            selectedLotIndex={selectedLotIndex}
            selectedProduct={products[selectedProductIndex]}
          />
        )}
      </div>
    </Form>
  );
};

const HeaderRow: React.FC<{ showTechnicalInformation: boolean }> = ({
  showTechnicalInformation,
}) => {
  const { t } = useTranslation();
  return (
    <Row wrap={false} gutter={[16, 0]} className={styles['headerRow']}>
      <Col
        span={showTechnicalInformation ? 15 : 10}
        className={styles['headerItem']}
      >
        {t('purchasing.invoice.registerLots.lotsCardHeader.product')}
      </Col>
      <Col
        span={showTechnicalInformation ? 4 : 3}
        className={styles['headerItem']}
      >
        {t('purchasing.invoice.registerLots.lotsCardHeader.quantity')}
      </Col>
      <Col
        span={showTechnicalInformation ? 5 : 3}
        className={styles['headerItem']}
      >
        {t('purchasing.invoice.registerLots.lotsCardHeader.lot')}
      </Col>
      {!showTechnicalInformation && (
        <>
          <Col span={3} className={styles['headerItem']}>
            {t(
              'purchasing.invoice.registerLots.lotsCardHeader.manufacturingDate'
            )}
          </Col>
          <Col span={3} className={styles['headerItem']}>
            {t('purchasing.invoice.registerLots.lotsCardHeader.expiryDate')}
          </Col>
        </>
      )}
    </Row>
  );
};
