import { Link, i18n } from '@/Components';
import { Text } from '@/Components/Text';
import { CurrencyText } from '@/Components/CurrencyText';
import { hasPermission } from '@/Components/PermissionWrapper';
import { PermissionEnum } from '@/Data/Interfaces/model/permissionEnum';

export const standardFormulaColumns = (t: any) => [
  {
    title: t('production.standardFormula.list.productDescription'),
    dataIndex: 'produto',
    key: 'produto',
    isHighlightable: true,
    sortColumn: 'produto',
    sorter: true,
    defaultSortOrder: 'ascend',
    render: (standardFormula: string, row: any) =>
      !hasPermission(PermissionEnum.Producao_FormulaPadrao_VerDetalhes) ? (
        <Text type="ui-tiny-bold" style={{ width: 'fit-content' }}>
          {standardFormula}
        </Text>
      ) : (
        <Link
          type="ui-tiny-bold"
          to={`/production/standard-formula/${row.externalId}/details`}
          style={{ width: 'fit-content' }}
        >
          {standardFormula}
        </Link>
      ),
  },
  {
    title: t('production.standardFormula.list.pharmaceuticalFormDescription'),
    dataIndex: 'formaFarmaceutica',
    key: 'formaFarmaceutica',
    sortColumn: 'formaFarmaceutica',
    isHighlightable: true,
  },
  {
    title: t('production.standardFormula.list.amountDefaultDescription'),
    dataIndex: 'quantidadePadrao',
    key: 'quantidadePadrao',
    sortColumn: 'quantidadePadrao',
    render: (quantidy: any) => (
      <Text type={'ui-tiny-content'}>
        <CurrencyText value={quantidy} decimalScale={3} />
      </Text>
    ),
  },
  {
    title: t('production.standardFormula.list.unitMeasurementDescription'),
    dataIndex: 'unidadeMedidaDescricao',
    key: 'unidadeMedidaDescricao',
    sortColumn: 'unidadeMedidaDescricao',
    render: (unidMeasurement: any, unidMeasurementAbrev: any) => (
      <Text
        type={'ui-tiny-content'}
      >{`${unidMeasurement} (${unidMeasurementAbrev.unidadeMedidaAbreviacao})`}</Text>
    ),
  },
  {
    title: t('production.standardFormula.list.dismembermentDescription'),
    dataIndex: 'formulaPadraoDesmembramentoId',
    key: 'formulaPadraoDesmembramentoId',
    sortColumn: 'formulaPadraoDesmembramentoId',
    render: (prop: any) => `${TypeToken(prop)}`,
  },
];

const TypeToken = (value: any) => {
  switch (value) {
    case 1:
      return `${i18n.t(
        'production.standardFormula.list.typeToken.notDismember'
      )}`;
    case 2:
      return `${i18n.t(
        'production.standardFormula.list.typeToken.dismemberSameToken'
      )}`;
    case 3:
      return `${i18n.t(
        'production.standardFormula.list.typeToken.dismemberTokenSeparate'
      )}`;
    default:
      return `${i18n.t(
        'production.standardFormula.list.typeToken,notDismember'
      )}`;
  }
};
