import { DeleteModalOnTable } from '../../../../../../../../Utils/DeleteModalUtils';
import { RemoveLinkedModal } from './RemoveLinkedModal';

export const TitleHandler = (selectedRowsOnTable: any, t: any) =>
  selectedRowsOnTable &&
  `${t('common.remove')} ${
    selectedRowsOnTable.length > 1
      ? `${selectedRowsOnTable.length} ${t('common.linkedProducts')}?`
      : `"${selectedRowsOnTable[0]?.descricao}" ${t(
          'production.packingClassification.linkedList.modalRemoveLinked.messageTitle'
        )}?`
  }`;

export const BodyHandler = (
  selectedRowsOnTable: any,
  t: any,
  packingClassification?: string
) =>
  selectedRowsOnTable && selectedRowsOnTable?.length > 1 ? (
    DeleteModalOnTable(
      selectedRowsOnTable,
      `${t('modal.removeLinkedModal.removeQuestion')} ${t(
        'common.forTheProducts'
      )}:`,
      'descricao',
      ''
    )
  ) : (
    <RemoveLinkedModal
      selectedRowsOnTable={selectedRowsOnTable}
      packingClassificationSelected={packingClassification}
    />
  );
