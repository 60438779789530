import { IChangeProductStatusRequest } from '@/Data/Interfaces/request/Product/IProductRequest';
import { FETCH_LIST_PRODUCTS_GROUP } from '@/ControllerApiHook/UniqIds/Supply/ProductKeys';
import { useQueryClient } from '@tanstack/react-query';
import { useCallback } from 'react';
import { IUseFunctions } from './interfaces';
import { handleModalError } from '@/Utils/ErrorCodeUtils/ErrorHandlingUtils';
import { App } from 'antd';

export const useProductFormFunctions = ({
  editProductStatus,
  setSelectedRowsOnTable,
  deleteProduct,
}: IUseFunctions) => {
  const queryClient = useQueryClient();
  const app = App.useApp();

  const patchProductStatus = useCallback(
    async (values: IChangeProductStatusRequest) => {
      var body: IChangeProductStatusRequest = { ...values };

      const res = await editProductStatus.mutateAsync({
        ...body,
      });

      if (res.success)
        queryClient.invalidateQueries([FETCH_LIST_PRODUCTS_GROUP]);
    },
    [editProductStatus, queryClient]
  );

  const selectRowsOnTable = useCallback(
    (x: any) => setSelectedRowsOnTable(x),
    []
  );

  const onEdit = useCallback(
    (user: any[], status: any) =>
      patchProductStatus({
        produtosExternalId: user.map((x) => x.externalId),
        ativo: status.status === 'success' ? true : false,
      }),
    [patchProductStatus]
  );

  const onDelete = useCallback(
    async (product: any[]) => {
      try {
        const res = await deleteProduct.mutateAsync(
          {
            produtosExternalId: product.map((x) => x?.externalId),
          },
          {
            onError: handleModalError(app),
          }
        );
        if (res.success)
          queryClient.invalidateQueries([FETCH_LIST_PRODUCTS_GROUP]);
      } catch {}
    },
    [deleteProduct, app, queryClient]
  );
  return {
    patchProductStatus,
    selectRowsOnTable,
    onEdit,
    onDelete,
  };
};
