import { IEditLotRequest } from '@/Data/Interfaces/request/Lot/IPatchLotStatusRequest';

export function removeDefaultUnitMensures(body: IEditLotRequest) {
  let bodyAssistant = body;
  if (
    !bodyAssistant?.loteInformacaoTecnica?.loteUnidadeAlternativa1
      ?.quantidadeUnidadeAlternativa
  ) {
    bodyAssistant.loteInformacaoTecnica.loteUnidadeAlternativa1 = null;
  }
  if (
    !bodyAssistant?.loteInformacaoTecnica?.loteUnidadeAlternativa2
      ?.quantidadeUnidadeAlternativa
  ) {
    bodyAssistant.loteInformacaoTecnica.loteUnidadeAlternativa2 = null;
  }
  return bodyAssistant;
}
