export const prescribersEN = {
  prescribers: 'prescribers',
  prescriber: 'prescriber',
  prescriberTitle: 'Prescriber',
  createPage: {
    info: {
      infos: 'Information',

      name: 'Full name',

      register: 'Type of register',
      registerPlaceholder: 'Select a type',

      UF: 'State Registration',

      registerNumber: 'Registration Number',

      birthday: 'Date of birth',

      specialties: 'Specialties',
      specialtiesPlaceholder: 'Select specialties',

      descProduct: 'Discount in Prod. finished',
      descFormula: 'Discount on formulas',

      observation: 'Observation',
      observationPlaceholder: 'Add a note...',
    },

    messages: {
      success: 'Prescriber created!',
      errorRegister: 'Existing registration.',
    },

    error: {
      nameIsRequired: 'Name is required',
      registerTypeIsRequired: 'Registration Type is required',
      registerUfIsRequired: 'UF Registration is required',
      registerCodeIsRequired: 'Registration Number is required',
    },
  },
  editPage: {
    record: 'Record',

    specialties: 'Especialties',

    birthday: 'Date of birth',

    observation: 'Observation',

    descProduct: 'Discount in Prod. finished',
    descFormulas: 'Desconto em fórmulas',

    editButton: 'Edit details',
    editContactButton: 'Edit contacts',
    editAddressButton: 'Edit addresses',
    editDocumentButton: 'Edit documents',

    deleteModal: {
      prescriber: 'prescriber',

      description:
        'Are you sure you want to delete the prescriber {{prescriber}}?',
    },

    statusModal: {
      inactivate: 'Inactivate prescriber?',
      confirmation:
        'Are you sure you want to deactivate prescriber {{prescriber}}?',
    },

    success: {
      contact: 'Contact edited!',
      address: 'Address edited!',
    },
  },
  listPage: {
    searchPlaceholder:
      'Search for a customer, code, CNPJ/CPF, main contact, etc.',
    newButton: 'New Prescriber',

    prescriber: 'Prescriber',
    record: 'Record',
    mainContact: 'Main contact',
    specialties: 'Specialties',

    deleteModal: {
      description:
        'Are you sure you want to delete the prescriber {{prescriber}}?',
      success: 'Prescriber deleted!',
    },
  },
  modalCannotDelete: {
    oneEntity: {
      messageSubtitle: 'the prescriber',
      reasonTranslation:
        'This prescriber has already had movement within the system or is associated with a prescription and therefore its exclusion is not possible.',
    },
    manyEntity: {
      messageSubtitle: 'It is not possible to exclude prescribers:',
      reasonTranslation:
        'These prescribers have already had movements within the system or are associated with some prescription and therefore their exclusion is not possible.',
    },
    warning:
      'If you no longer want to use this prescriber, change its status to “Inactive”.',
  },
  messages: {
    changeStatus: 'Updated prescriber status!',
  },
};

export const prescribersPT = {
  prescribers: 'prescritores',
  prescriber: 'prescritor',
  prescriberTitle: 'Prescritor',
  createPage: {
    info: {
      infos: 'Informações',

      name: 'Nome completo',

      register: 'Tipo de Registro',
      registerPlaceholder: 'Selecione um tipo',

      UF: 'UF Registro',

      registerNumber: 'Número do Registro',

      birthday: 'Data de Nascimento',

      specialties: 'Especialidades',
      specialtiesPlaceholder: 'Selecione especialidades',

      descProduct: 'Desc. em Prod. Acabados',
      descFormula: 'Desconto em fórmulas',

      observation: 'Observação',
      observationPlaceholder: 'Adicione uma observação...',
    },
    messages: {
      success: 'Prescritor criado!',
      errorRegister: 'Registro já existente.',
    },

    error: {
      nameIsRequired: 'Nome é obrigatório',
      registerTypeIsRequired: 'Tipo de Registro é obrigatório',
      registerUfIsRequired: 'UF Registro é obrigatório',
      registerCodeIsRequired: 'Número do Registro é obrigatório',
    },
  },
  editPage: {
    record: 'Registro',

    specialties: 'Especialidades',

    birthday: 'Data de Nascimento',

    observation: 'Observações',

    descProduct: 'Desc. em Prod. Acabados',
    descFormulas: 'Desconto em fórmulas',

    editButton: 'Editar detalhes',
    editContactButton: 'Editar contatos',
    editAddressButton: 'Editar endereços',
    editDocumentButton: 'Editar documentos',

    deleteModal: {
      prescriber: 'prescritor(a)',

      description:
        'Você tem certeza que deseja excluir o(a) prescritor(a) {{prescriber}}?',
    },

    statusModal: {
      inactivate: 'Inativar prescritor(a)?',
      confirmation:
        'Você tem certeza que deseja inativar o(a) prescritor(a) {{prescriber}}?',
    },

    success: {
      contact: 'Contato editado!',
      address: 'Endereço editado!',
    },
  },
  listPage: {
    searchPlaceholder:
      'Pesquise por um cliente, código, CNPJ/CPF, contato principal, etc.',
    newButton: 'Novo Prescritor',

    prescriber: 'Prescritor',
    record: 'Registro',
    mainContact: 'Contato principal',
    specialties: 'Especialidades',

    deleteModal: {
      description:
        'Você tem certeza que deseja excluir o(a) prescritor(a) {{prescriber}}?',
      success: 'Prescritor deletado!',
    },
  },
  modalCannotDelete: {
    cannotDeletePrescitorWithMovementsInTheSystem: {
      oneEntity: {
        messageSubtitle: 'o(a) prescritor(a)',
        reasonTranslation:
          'Esse(a) prescritor(a) já teve movimentação dentro do sistema ou está associado a alguma receita e por isso não é possível a sua exclusão.',
      },
      manyEntity: {
        messageSubtitle: 'Não é possível excluir os prescritores:',
        reasonTranslation:
          'Esses prescritores já tiveram movimentações dentro do sistema ou estão associados a alguma receita e por isso não é possível suas exclusões.',
      },
      warning:
        'Caso não queira mais utilizar esse(a) prescritor(a), mude o status dele(a) para “Inativo”.',
    },
  },
  messages: {
    changeStatus: 'Status atualizado!',
  },
};
