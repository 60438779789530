import { FC } from "react";
import {
  Tooltip
} from '@/Components';
import { Col } from 'antd';
import { useTranslation } from "react-i18next";
import { useFormikContext } from "formik";
import { ISaltMolecularWeight } from "./interfaces";
import { WeightInput } from "@/Components/Inputs/WeightInput";
import { useSaltMolecularWeightFunctions } from "./Hooks/useSaltMolecularWeightFunctions";

export const SaltMolecularWeight: FC<ISaltMolecularWeight> = ({
  product,
  disabledForm,
  setEquivalenceFactor
})  => {
  const { t } = useTranslation();
  const form: any = useFormikContext();

  const { onChange } = useSaltMolecularWeightFunctions({
    setEquivalenceFactor
  });

  return (
    <Col span={4}>
      <Tooltip
        title={`${t(
          'products.toolTips.products.originalSaltMolecularWeight'
        )} ${
          product && product.materiaPrima
            ? product.materiaPrima?.pesoMolecularSal
            : ''
        }`}
        showMe={
          product &&
          !disabledForm &&
          product.materiaPrima?.pesoMolecularSal !==
            form?.values?.pesoMolecularSal
        }
      >
        <WeightInput
          withStatusIcon={!!product}
          name="pesoMolecularSal"
          placeHolder="0000,00"
          label={t('products.create.saltMolecularWeight')}
          onChange={onChange}
          disabled={disabledForm}
        />
      </Tooltip>
    </Col>
  );
};