import { useQueryClient } from '@tanstack/react-query';
import { handleModalError } from '@/Utils/ErrorCodeUtils/ErrorHandlingUtils';
import { App } from 'antd';
import { history } from '@/Store';
import {
  FETCH_LIST_SUBGROUP
} from '@/ControllerApiHook/UniqIds/Supply/ProductKeys';
import { IUseFunctions } from './interfaces';
import { useCallback } from 'react';

export const useDeleteGroupModalFunctions = ({
  deleteSubGroup,
  productSubGroup,
  onOkClick
}: IUseFunctions) => {
  const app = App.useApp();
  const queryClient = useQueryClient();

  const onDelete = useCallback(async () => {
    try {
      const res = await deleteSubGroup.mutateAsync(
        {
          externalId: [productSubGroup?.externalId] as any,
        },
        {
          onError: handleModalError(app),
        }
      );
      if (res.success) {
        queryClient.invalidateQueries([FETCH_LIST_SUBGROUP]);
        history.push('/inventory/sub-groups');
      }
    } catch {};

    onOkClick();
  }, [deleteSubGroup, productSubGroup]);

  return { onDelete };
};