import { useControllerMutateApiHook } from "@/ControllerApiHook/Controller";
import { ProductAPI } from "@/Data/API/Supply/Product";
import { DELETE_GROUP } from "@/ControllerApiHook/UniqIds/Supply/ProductKeys";

export const useDeleteProductGroupModalCallAPI = () => {
  const deleteGroup: any = useControllerMutateApiHook({
    uniqId: DELETE_GROUP,
    entityApi: ProductAPI.deleteProductGroup
  });

  return {
    deleteGroup
  };
};