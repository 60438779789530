import { IUserGroup } from "@/Data/Interfaces/response/User/IGetUserReponse";
import { useMemo } from "react";
import { usePermissionFormFunctions } from "./usePermissionFormFunctions";

export const usePermissionFormMemorizations = (userGroup?: IUserGroup) => {
  const { transformModule } = usePermissionFormFunctions(userGroup);
  
  const modules = useMemo(
    () => userGroup?.modulos.map((x) => transformModule(x)),
    [userGroup, transformModule]
  );

  return { modules };
};