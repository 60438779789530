import React from 'react';
import { IconName } from '../Icon/IconClasses';
import { Status } from '../Status';
import { Icon } from '../Icon';
import { statusTypes } from '../../Utils/StatusUtils';
import { Color } from '../../Utils/ColorUtils';
import { Tooltip } from '../../Components';

interface ICircularStatus {
  icon: IconName;
  type: statusTypes;
  customColor?: string;
  color?: Color;
  marginTop?: string;
  size?: 'SS' | 'SG' | 'S' | 'SM' | 'M' | 'L' | 'XXL' | 'XXX';
  showTooltip?: boolean;
  tooltipText?: string;
}

export const CircularStatus: React.FC<ICircularStatus> = ({
  icon,
  type,
  customColor,
  color,
  marginTop,
  size = 'M',
  showTooltip = false,
  tooltipText,
}) => {
  return (
    <Tooltip showMe={showTooltip} title={tooltipText} placement="top">
      <div style={{ marginTop: marginTop }}>
        <Status circular={true} type={type}>
          <Icon
            size={size}
            icon={icon}
            customColor={customColor}
            color={color}
          />
        </Status>
      </div>
    </Tooltip>
  );
};
