import { useCallback } from 'react';
import { IUseFunctions } from './interfaces';
import { TextWithoutExtraBlankValue } from '@/Utils/MasksUtils';

export const useBodyFunctions = ({
  setClientName,
  refetchClientList,
}: IUseFunctions) => {
  const handleInputChange = useCallback(
    (value: string) => {
      const formattedValue = TextWithoutExtraBlankValue(value);

      setClientName(formattedValue);
    },
    [setClientName]
  );

  const onChangeInputText = useCallback(
    (value: string) => {
      handleInputChange(value);
    },
    [handleInputChange]
  );

  const onSearch = useCallback(
    (search?: string) => {
      const formattedSearchValue = search
        ? TextWithoutExtraBlankValue(search)
        : '';

      refetchClientList({
        search: formattedSearchValue,
      });
    },
    [refetchClientList]
  );

  return {
    onChangeInputText,
    onSearch,
  };
};
