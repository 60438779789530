import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { history } from '@/Store';
import { IPermissionWrapper } from '@/Components/PermissionWrapper';

export const useListMemorizations = () => {
  const { t } = useTranslation();

  const headerOptions = useMemo(
    () => ({
      hasSearch: true,
      newButton: {
        onClick: () => history.push('/inventory/balance-adjustments/create'),
        label: 'supply.balanceAdjustment.list.newButton',
        permission: {
          permission: 2022,
          tooltip: 'noPermission.supply.balanceAdjustments.create',
        },
      },
      searchPlaceholder: t('supply.balanceAdjustment.list.searchPlaceHolder'),
      nameKey: 'DataTransferencia',
    }),
    []
  );

  const rowsOptions = useMemo(
    (): {
      permission: IPermissionWrapper;
      onRowDoubleClick?: (record: any) => void;
    } => ({
      onRowDoubleClick: (x) =>
        history.push(`/inventory/balance-adjustments/${x.externalId}/details`),
      permission: {
        permission: 2021,
        tooltip: 'noPermission.supply.balanceAdjustments.detail',
      },
    }),
    []
  );

  return { headerOptions, rowsOptions };
};
