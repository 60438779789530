import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Row } from 'antd';
import { Tooltip } from '@/Components';
import { WeightInput } from '@/Components/Inputs/WeightInput';
import { useFormikContext } from 'formik';
import { useCreateCallAPI } from './Hooks/useCreateCallAPI';
import { useCreateFunctions } from './Hooks/useCreateFunctions';
import { useCreateMemorizations } from './Hooks/useCreateMemorizations';

interface IlistunitsMeasurementsBalance {
  typeClassProduct?: number;
}

export const BalanceFinalLoss: FC<IlistunitsMeasurementsBalance> = ({
  typeClassProduct,
}) => {
  const { t } = useTranslation();
  const form: any = useFormikContext();

  const {
    listunitsMeasurements,
    unitMeasureIdDefault,
    isUnitOfMeasureLoading,
    convertUnitOfMeasureAsync,
  } = useCreateCallAPI({ typeClassProduct, form });

  const { onUnitOfMeasureChange } = useCreateFunctions({
    form,
    convertUnitOfMeasureAsync,
  });

  const { unitOfMeasureDropdown } = useCreateMemorizations({
    listunitsMeasurements,
    isUnitOfMeasureLoading,
    onUnitOfMeasureChange,
    unitMeasureIdDefault,
  });

  return (
    <Row gutter={[16, 20]}>
      <Col span={8}>
        <WeightInput
          name="saldoAtual"
          disabled
          dropdownRight={unitOfMeasureDropdown}
          label={t('supply.losses.create.currentBalance')}
          required={false}
          withoutMarginBottom
        />
      </Col>

      <Col span={8}>
        <Tooltip
          title={t('supply.losses.create.toolTips.currentQuantityNegative')}
          showMe={form.values.quantidade > form.values.saldoAtual}
        >
          <WeightInput
            name="quantidade"
            dropdownRight={unitOfMeasureDropdown}
            label={t('supply.losses.create.amountLoss')}
            required={true}
            withoutMarginBottom={true}
            disabled={!form.getFieldProps('loteExternalId').value}
            error={form.values.quantidade > form.values.saldoAtual}
          />
        </Tooltip>
      </Col>
      <Col span={8}>
        <Tooltip
          title={t('supply.losses.create.toolTips.currentBalanceNegative')}
          showMe={form.values.quantidade > form.values.saldoAtual}
        >
          <WeightInput
            name="novoSaldo"
            dropdownRight={unitOfMeasureDropdown}
            label={t('supply.losses.create.newBalance')}
            required={true}
            disabled={true}
            error={form.values.quantidade > form.values.saldoAtual}
          />
        </Tooltip>
      </Col>
    </Row>
  );
};
