import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Modal } from '@/Components/Modal';
import { useFormikContext } from 'formik';
import { IReproveModal } from './interfaces';
import { 
  useReproveOrderModalMemorizations 
} from './Hooks/useReproveOrderModalMemorizations';

export const ReproveOrderModal: FC<IReproveModal> = ({
  isVisible,
  onCancelClick,
  onOkClick,
  onClose,
  okButtonName,
  loading
}) => {
  const { t } = useTranslation();
  const form: any = useFormikContext();

  const {
    body
  } = useReproveOrderModalMemorizations();

  return (
    <Modal
      title={`${t('purchasing.purchaseOrder.modal.reprove.title')} "#${
        form.values.numeroPedido
      }"?`}
      body={body}
      widthModal={399}
      onCancelClick={onCancelClick}
      visible={isVisible}
      onOkClick={onOkClick}
      onClose={onClose}
      loading={loading}
      okButtonName={okButtonName}
      okButtonLeftIcon={!loading ? 'close-x' : undefined}
      okButtonStatus="danger"
    />
  );
};
