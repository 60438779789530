import { IIListUnitMeasurementAllData } from "@/Data/Interfaces/response/UnitMeasurement/IUnitMeasurementResponse";
import { IUseMemorizations } from "./interfaces";

export const useDosageInputsMemorizations = ({
  unitMeasurementList,
  setUnitAbbreviation
}: IUseMemorizations) => {
  const defaultUnitId = 6;

  const dropdownRightMinimalDosage = {
    name: 'unidadeMedidaDosagem',
    defaultValue: defaultUnitId,
    options: unitMeasurementList.data
      ? unitMeasurementList?.data?.map((e: IIListUnitMeasurementAllData) => ({
          content: e.unidadeAbreviacao,
          id: e.id,
        }))
      : [],
    onChange: (value: any) => {
      const unit = unitMeasurementList ? 
      unitMeasurementList?.data?.find((e: IIListUnitMeasurementAllData) => e.id === value) : undefined;

      setUnitAbbreviation(unit?.unidadeAbreviacao || '');
    },
  };

  const dropdownRightAssociatedQuantity = {
    name: 'unidadeMedidaQuantidadeAssociada',
    defaultValue: defaultUnitId,
    options: unitMeasurementList.data
      ? unitMeasurementList?.data?.map((e: IIListUnitMeasurementAllData) => ({
          content: e.unidadeAbreviacao,
          id: e.id,
        }))
      : [],
  };

  return {
    dropdownRightMinimalDosage,
    dropdownRightAssociatedQuantity
  };
};