import { Http } from '../../../Utils/Http';
import { IDeletePackingClassificationLinkedPharmaceuticalFormRequest, ILinkPackingBindClassificationRequest, ILinkPackingClassificationRequest } from '../../Interfaces/request/PackingClassificationLinkedPharmaceuticalForm/IPackingClassificationLinkedPharmaceuticalForm';
import { IBaseResponse } from '../../Interfaces/response/Base/IBase';


export class PackingClassificationLinkedPharmaceuticalFormAPI {
  public static async deletePackingClassificationLinkedPharmaceuticalFormRequest(
    body: IDeletePackingClassificationLinkedPharmaceuticalFormRequest
  ) {
    const res = await Http.fetcher(`/pharma/producao/v1/EmbalagemClassificacaoFormaFarmaceutica`, {
      method: 'DELETE',
      body: JSON.stringify(body),
    });
    if (!res) return [];
    return res;
  }

  public static async linkPackingClassification(body: ILinkPackingClassificationRequest) {
    return await Http.fetcher(`/pharma/producao/v1/EmbalagemClassificacaoFormaFarmaceutica`, {
      method: 'POST',
      body: JSON.stringify(body),
    });
  }


  public static async pharmaceuticalFormBindPackingClassification(
    body: ILinkPackingBindClassificationRequest
  ): Promise<IBaseResponse<{}> | null> {
    if (!body.embalagemClassificacaoExternalId || !body.formaFarmaceuticaExternalId) return null;
    return await Http.fetcher(
      '/pharma/producao/v1/FormaFarmaceutica/ValidarVinculoEmbalagemClassificacao',
      {
        method: 'POST',
        body: JSON.stringify(body),
      }
    );
  }
}