import { Button } from '@/Components';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { history } from '../../../../../../../Store';
import { IFChildren } from '@/Components/Form';

import styles from './FooterButtons.module.scss';

interface IFooterButtons {
  isLoadingCreateProductMessage: boolean;
  props: IFChildren;
}

export const FooterButtons: FC<IFooterButtons> = ({
  isLoadingCreateProductMessage,
  props,
}) => {
  const { t } = useTranslation();

  return (
    <div className={styles['footer-button']}>
      <Button
        type="secondary"
        children={t('common.cancel')}
        onClick={() => {
          history.push('/inventory/product-message');
        }}
      />
      <Button
        type="primary"
        children={
          isLoadingCreateProductMessage
            ? t('common.creating')
            : t('common.create')
        }
        htmlType="submit"
        loading={isLoadingCreateProductMessage}
        disabled={!!props.errors.mensagem}
      />
    </div>
  );
};
