import { useControllerMutateApiHook } from "@/ControllerApiHook/Controller";
import { UserGroupAPI } from "@/Data/API/Accountmanager/UserGroupApi";
import { CREATE_USER_GROUP_TYPE } from "@/ControllerApiHook/UniqIds/Accountmanager/UserGroupKeys";
import { history } from '@/Store';

export const useCreateUserGroupsCallAPI = () => {
  const { mutateAsync: createUserGroup } = useControllerMutateApiHook({
    uniqId: CREATE_USER_GROUP_TYPE,
    entityApi: UserGroupAPI.createUserGroup,
    options: {
      onSuccess: () => history.push('/admin/user-group')
    }
  });

  return {
    createUserGroup
  };
};