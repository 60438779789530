import { Create } from '@/Pages/Sectors/Administrative/Company/CompanyForm';
import { List } from '@/Pages/Sectors/Administrative/Company/List';
import { Switch, Route } from 'react-router-dom';

export const AdminCompanyRoutes = () => {
  return (
    <Switch>
      <Route path="/admin/company/:externalId/details" component={Create} />
      <Route path="/admin/company" component={List} />
    </Switch>
  );
};
