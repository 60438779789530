import { useTranslation } from "react-i18next";
import { IUseMemorizations, IWithTooltip } from "./interfaces";

export const useMainContactMemorizations = ({
  documentProvider
}: IUseMemorizations) => {
  const { t } = useTranslation();

  const withTooltip: IWithTooltip | undefined = {
    icon: 'exclamation-upside-down',
    title: documentProvider?.observacaoContatoFavorito
      ? `${t('common.observation')} ${
          documentProvider.observacaoContatoFavorito
        }`
      : '',
  };

  return {
    withTooltip
  };
};