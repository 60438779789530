export const GET_ME = '[USER] GET_ME';
export const GET_PREFERENCES_TYPE = '[USER] FETCH_PREFERENCES_TYPE';
export const GET_USER_TYPE = '[USER] GET_USER_TYPE';

export const GET_USER_LIST_TYPE = '[USER] GET_USER_LIST_TYPE';
export const GET_ACTIVE_USER_ADMIN_LIST_TYPE =
  '[USER] GET_ACTIVE_USER_ADMIN_LIST_TYPE';
export const GET_ACTIVE_USER_LIST_TYPE = '[USER] GET_ACTIVE_USER_LIST_TYPE';
export const GET_USER_WITHOUT_GROUP_LIST_TYPE =
  '[USER] GET_USER_WITHOUT_GROUP_LIST_TYPE';

export const CREATE_USER_TYPE = '[USER] CREATE_USER_TYPE';
export const EDIT_USER_TYPE = '[USER] EDIT_USER_TYPE';
export const DELETE_USER_TYPE = '[USER] DELETE_USER_TYPE';

export const CHANGE_USER_STATUS_TYPE = '[USER] CHANGE_USER_STATUS_TYPE';
export const CHANGE_USER_PREFERENCES = '[USER] CHANGE_USER_PREFERENCES';
export const CHANGE_USER_PASSWORD = '[USER] CHANGE_USER_PASSWORD';

export const UPDATE_USER_PERMISSION = '[USER] UPDATE_USER_PERMISSION';
