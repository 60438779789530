import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Text } from '@/Components/Text';
import {
  getPermissionDependencyModalData,
} from '@/Store/Group/Group.selector';
import { Form } from '@/Components';
import { Content } from '@/Components/CollapseList/Content';
import { IconName } from '@/Components/Icon/IconClasses';

import styles from '../../RequiredPermissionModal.module.scss';

export const ModalBody = () => {
  const { t } = useTranslation();
  const modalData = useSelector(getPermissionDependencyModalData);
  const defaultValues: any = {};

  modalData?.data.dependencias?.forEach((dependency) => {
    defaultValues[dependency.permissaoId] = modalData.request.ativo;
  });

  return (
    <div className={styles.container}>
      <Text
        type="paragraph2"
        color="text-50"
        style={{ marginBottom: 12, display: 'block' }}
      >
        {t('admin.editUserPage.permission.modal.title1')}
        <b>“{modalData?.data.nome}”</b>{' '}
        {t('admin.editUserPage.permission.modal.title2')}{' '}
        {modalData?.data.dependencias.length}{' '}
        {t('admin.editUserPage.permission.modal.permissions')}
      </Text>

      <Form initialValues={defaultValues}>
        {modalData?.data.dependencias.map((dependency, index) => (
          <div className={styles.permissionContainer}>
            <Content
              name={dependency.permissaoId}
              displayName={dependency.nome}
              icon={{
                icon: dependency.modulo.icone as IconName,
                backgroundColor: dependency.modulo.corDeFundo,
                color: 'white',
              }}
              secondaryIcon={{
                icon: dependency.subModulo.icone as IconName,
                backgroundColor: dependency.subModulo.corDeFundo,
                color: 'text-50',
              }}
              hasInherited={false}
              disabled
            />
          </div>
        ))}
      </Form>

      <Text type="paragraph2" color="text-50">
        {t('admin.editUserPage.permission.modal.footer')}
      </Text>
    </div>
  );
};