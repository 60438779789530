import { IListCompanyData } from '@/Data/Interfaces/response/Company/ICompanyResponse';
import { useCallback } from 'react';
import { IUseFunctions } from './interfaces';
import { history } from '@/Store';
import { i18n } from '@/Components';

export const useListFunctions = ({
  rowSelected,
  changeResponsibilytiUser,
  setRowSelected,
}: IUseFunctions) => {
  const modalTitle = useCallback((company?: IListCompanyData[]) => {
    return (
      company &&
      `${i18n.t('common.changeResponsible')} ${
        company.length > 1
          ? `${company.length} ${i18n.t('common.companys')}`
          : `"${company[0]?.nomeFantasia}"`
      }`
    );
  }, []);

  const onEdit = useCallback(
    async (_row: any[], value: any) => {
      try {
        if(value.usuarioResponsavelExternalId) {
          await changeResponsibilytiUser.mutateAsync({
            empresasExternalId: rowSelected?.map(
              (x: IListCompanyData) => x.externalId
            ),
            usuarioResponsavelExternalId: value?.usuarioResponsavelExternalId,
          })
        }
      } catch {}
    },
    [changeResponsibilytiUser, rowSelected]
  );

  const onRowDoubleClick = useCallback(
    (x: any) => history.push(`/admin/company/${x.externalId}/details`),
    []
  );

  const selectedRowsOnTable = useCallback(
    (x: any) => setRowSelected(x),
    [setRowSelected]
  );

  return {
    onEdit,
    onRowDoubleClick,
    selectedRowsOnTable,
    modalTitle,
  };
};
