import {
  GET_CLIENT,
  EDIT_INFO_CLIENT,
} from '@/ControllerApiHook/UniqIds/People/ClientKeys';
import { useQueryClient } from '@tanstack/react-query';
import { IUseCallAPI } from './interfaces';
import { useControllerMutateApiHook } from '@/ControllerApiHook/Controller';
import { ClientAPI } from '@/Data/API/People/ClientApi';
import {
  messageErrorToast,
  messageSucessToast,
} from '@/Utils/MessageErrorToast';
import { i18n } from '@/Components';

export const useModalCallAPI = ({ changeVisibleModal }: IUseCallAPI) => {
  const queryClient = useQueryClient();

  const editInfo: any = useControllerMutateApiHook({
    uniqId: EDIT_INFO_CLIENT,
    entityApi: ClientAPI.editInfoClient,
    options: {
      onError: (x) => messageErrorToast(x),
      onSuccess: () => {
        messageSucessToast(i18n.t('client.messages.success.changeInfo'));

        queryClient.invalidateQueries([GET_CLIENT]);

        changeVisibleModal(true);
      },
    },
  });

  return {
    editInfo,
  };
};
