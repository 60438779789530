import { Status } from '../../../../../../../Components';
import { Text } from '../../../../../../../Components/Text';

export const packingClassificationColumns = (t: any) => [
  {
    title: t(
      'production.pharmaceuticalForm.details.packingClassification.description'
    ),
    dataIndex: 'classificacaoEmbalagemDescricao',
    key: 'classificacaoEmbalagemDescricao',
    sortColumn: 'classificacaoEmbalagemDescricao',
    sorter: true,
    isHighlightable: true,
    defaultSortOrder: 'ascend',
    render: (x: any) => <Text type={'ui-tiny-bold'}>{x}</Text>,
  },
  {
    title: t(
      'production.pharmaceuticalForm.details.packingClassification.status'
    ),
    dataIndex: 'ativo',
    key: 'ativo',
    width: '10%',
    render: (prop: any) => (
      <Status type={prop ? 'success' : 'disabled'}>
        {t(
          `production.packingClassification.list.statusTypes.${
            prop ? 'active' : 'inactive'
          }`
        )}
      </Status>
    ),
  },
];
