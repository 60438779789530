import React from 'react';
import { Button, Icon, InputWithSearch } from '@/Components';
import { useTranslation } from 'react-i18next';
import { FormikContextType, useFormikContext } from 'formik';
import { Col, Row } from 'antd';
import { IIListPackagingClassificationData } from '@/Data/Interfaces/response/PharmaceuticalForm/IPharmaceuticalFormResponse';
import { useEditCallAPI } from './Hooks/useEditCallAPI';
import { useEditFunctions } from './Hooks/useEditFunctions';
import { useParams } from 'react-router';
import { ILinkPackingClassificationRequest } from '@/Data/Interfaces/request/PackingClassificationLinkedPharmaceuticalForm/IPackingClassificationLinkedPharmaceuticalForm';

interface IBodyModal {
  index: number;
  onDelete: (index: number) => void;
  totalRows: number;
  setPackingClassificationRowAmount: React.Dispatch<
    React.SetStateAction<string[]>
  >;
}

const BodyModal: React.FC<IBodyModal> = ({
  index,
  onDelete,
  totalRows,
  setPackingClassificationRowAmount,
}) => {
  const { externalId } = useParams<{ externalId: string }>();
  const { t } = useTranslation();
  const form: FormikContextType<ILinkPackingClassificationRequest> =
    useFormikContext();

  const {
    mutateAsync,
    packingClassification,
    fetchNewPagepackingClassification,
    isLoadingPackingClassification,
    refetchPackingClassification,
  } = useEditCallAPI({
    t,
    form,
    index,
    externalId,
  });

  const {
    addRow,
    deleteRow,
    disabledSelectedItems,
    fetchInputPharmaceuticalFormDescription,
  } = useEditFunctions({
    setPackingClassificationRowAmount,
    index,
    totalRows,
    onDelete,
    form,
    externalId,
    mutateAsync,
  });

  return (
    <div
      style={
        index === form?.values?.embalagemClassificacaoExternalId?.length - 1
          ? { padding: '0px 0px 5px 0px' }
          : {}
      }
    >
      <Row gutter={[16, 0]}>
        <Col span={21}>
          <InputWithSearch
            name={`embalagemClassificacaoExternalId.${index}.externalId`}
            placeHolder={t(
              'production.pharmaceuticalForm.create.packingClassificationPlaceholder'
            )}
            items={packingClassification?.data?.map(
              (x: IIListPackagingClassificationData) => ({
                id: x?.embalagemClassificacaoExternalId,
                label: x?.embalagemClassificacaoDescricao,
                disabledItem:
                  x?.seVinculoFormaFarmaceutica ||
                  disabledSelectedItems(x?.embalagemClassificacaoExternalId),
                titleTooltipItem: t('common.packagingAlreadyBound'),
              })
            )}
            onChange={(x) => {
              addRow();
              fetchInputPharmaceuticalFormDescription(x);
            }}
            onScrollEnd={fetchNewPagepackingClassification}
            onSearch={(search) =>
              refetchPackingClassification({
                search: search,
              })
            }
            getPopupContainerId="dropdown-itens-container"
            isLoading={isLoadingPackingClassification}
          />
        </Col>
        <Col span={3}>
          {index !== totalRows - 1 && (
            <Button type="secondary" onClick={() => deleteRow(index)}>
              <Icon icon="trash" size="M" />
            </Button>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default BodyModal;
