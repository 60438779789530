import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Divflex } from '@/Layouts/DivWhithFlex';
import { Text } from '@/Components';
import { FooterThisActionIsPermanent } from '@/Components/Table/components/Modals/DeleteModal';
import { IDeleteModal } from './interfaces';

export const DeleteModal: FC<IDeleteModal> = ({ selectedRowsOnTable }) => {
  const { t } = useTranslation();

  return (
    <div style={{ padding: '20px' }}>
      <Divflex
        style={{ flexDirection: 'column', marginBottom: 10, width: '301px' }}
      >
        <Text
          color="text-50"
          type="ui-tiny-content"
          children={`${t('common.deleteStandardFormulaList')}`}
        />
        <Divflex>
          <Text
            color="text-50"
            type="ui-tiny-bold"
            children={`${selectedRowsOnTable[0]?.produto}`}
          />
          <Text color="text-50" type="ui-tiny-content" children={`${'?'} `} />
        </Divflex>
      </Divflex>
      <FooterThisActionIsPermanent />
    </div>
  );
};
