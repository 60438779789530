import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { AddressModal } from '@/Components/EntityInformation/Modal';

export const useAddressCardMemorizations = () => {
  const { t } = useTranslation();

  const column = useMemo(() => (
    [
      {
        label: t('address.description'),
        width: 6,
        key: 'bairro',
      },
      {
        label: t('address.address'),
        width: 18,
        key: 'cep',
      },
    ]
  ), [t]);

  const modal = useMemo(() => (
    {
      editTitle: t('address.editAddress'),
      addTitle: t('address.addAddress'),
      modalFormName: [
        'descricao',
        'cep',
        'logradouro',
        'numero',
        'bairro',
        'cidadeExternalId',
        'estadoExternalId',
        'paisExternalId',
        'complemento',
        'cityLabel',
      ],
      modalFormRequiredName: ['descricao'],
      body: <AddressModal />,
      okAddButtonLabel: t('common.add'),
      okEditButtonLabel: t('common.save'),
      widthModal: 800,
    }
  ), [t]);

  return {
    column,
    modal
  };
};