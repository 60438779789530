import { useTranslation } from 'react-i18next';
import { history } from '@/Store';

export const useListMemorizations = () => {
  const { t } = useTranslation();

  const headerOptions = {
    nameKey: 'DataTransferencia',
    hasSearch: true,
    newButton: {
      onClick: () => history.push('/inventory/transfers/create'),
      label: 'supply.transfers.newButton',
      permission: {
        permission: 2015,
        tooltip: 'noPermission.supply.transfers.create',
      },
    },
    searchPlaceholder: t('supply.transfers.searchPlaceHolder'),
  };

  const rowOptions = {
    onRowDoubleClick: (x: any) =>
      history.push(`/inventory/transfers/${x?.externalId}/details`),
    permission: {
      permission: 2014,
      tooltip: 'noPermission.supply.transfers.detail',
    },
  };

  return {
    headerOptions,
    rowOptions,
  };
};
