import { Col, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import { Card, TextArea, TextInput } from '@/Components';
import { TextInputWithMask } from '@/Components/Inputs/TextInputWithMask';
import { useFormikContext } from 'formik';
import { useInfoCardCallAPI } from './Hooks/useInfoCardCallAPI';
import { useInfoCardCallMemorizations } from './Hooks/useInfoCardMemorizations';

export const PurchaseOrderDetailsInfo = () => {
  const form: any = useFormikContext();
  const { t } = useTranslation();

  const {
    documentProvider
  } = useInfoCardCallAPI();

  const {
    withTooltipMainContact
  } = useInfoCardCallMemorizations({
    documentProvider
  });

  return (
    <Card title={t('purchasing.purchaseOrder.create.info')} withoutCollapse>
      <Row gutter={[16, 0]} style={{ marginBottom: 20 }}>
        <Col span={12}>
          <TextInput
            name="nomeFornecedor"
            placeHolder={t(
              'purchasing.purchaseOrder.create.placeHolder.provider'
            )}
            label={t('purchasing.purchaseOrder.create.provider')}
            withoutMarginBottom
            disabled
          />
        </Col>
        <Col span={12}>
          <TextInput
            name="razaoSocial"
            label={t('purchasing.purchaseOrder.create.corporateName')}
            withoutMarginBottom
            disabled
          />
        </Col>
      </Row>

      <Row gutter={[16, 0]} style={{ marginBottom: 20 }}>
        <Col span={8}>
          <TextInputWithMask
            name="cnpj"
            mask="cnpjMask"
            label={t('purchasing.purchaseOrder.create.cnpj')}
            withoutMarginBottom
            disabled
          />
        </Col>
        <Col span={8}>
          <TextInput
            name="fornecedorContatoPrincipal"
            leftIcon={form?.values?.icon}
            label={t('purchasing.purchaseOrder.create.mainContact')}
            withoutMarginBottom
            disabled
            withTooltip={withTooltipMainContact}
          />
        </Col>
        <Col span={8}>
          <TextInputWithMask
            name="previsaoEntrega"
            mask="dataMask"
            leftIcon="calendar"
            label={t('purchasing.purchaseOrder.create.deliveryForecast')}
            withoutMarginBottom
            disabled
          />
        </Col>
      </Row>
      <Row gutter={[16, 0]} justify="space-between">
        <Col span={24}>
          <TextArea
            name="observacao"
            label={t('supply.losses.create.comments')}
            placeholder={t(
              'purchasing.purchaseOrder.create.placeHolder.comments'
            )}
            maxLength={200}
            rows={3}
            disable
          />
        </Col>
      </Row>
    </Card>
  );
};
