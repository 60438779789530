import React from 'react';
import { Text, Icon } from '@/Components';
import { Button as AntdButton } from 'antd';
import { Themes } from '@/Utils/ThemeUtils';
import { IconName } from '@/Components/Icon/IconClasses';
import { Divflex } from '@/Layouts/DivWhithFlex';
import StorageUtils from '@/Utils/StorageUtils';
import { IStatus } from './interfaces';

import styles from './Status.module.scss';
import './Status.override.scss';

export const Status: React.FC<IStatus> = ({
  descriptionType,
  typePurchase,
  children,
  withoutDescription,
  onClick,
  withoutIcon,
  style,
  customIcon,
  defaultStatus,
}) => {
  const preferences = StorageUtils.getPreference();

  const statusIcon = [
    'time',
    'disapproved',
    'like',
    'close-x',
    'shipping-truck',
    'partialcube',
    'simple-cube',
    'pencil',
  ];

  const statusColor = [
    'waitingApproval',
    'disapproved',
    'relesed',
    'canceled',
    'confirmedProvider',
    'partialReceived',
    'totalReceived',
    'draft',
  ];

  return (
    <>
      <AntdButton
        className={`${styles[`${statusColor[typePurchase - 1]}-status`]} ${
          styles['status']
        }  
      ${defaultStatus && styles[`default-status`]} 
      ${withoutDescription && styles['status-without-description']} 
      ${preferences.temaUsuario !== Themes.lightMode ? 'darkmode' : null}`}
        onClick={onClick}
        style={style}
      >
        {withoutDescription ? (
          <Icon
            className={`${styles['icon-without-description']} ${
              styles[`${statusColor[typePurchase - 1]}-icon-color`]
            }`}
            icon={
              customIcon
                ? customIcon
                : (statusIcon[typePurchase - 1] as IconName)
            }
            size="M"
          />
        ) : withoutIcon ? (
          <Text
            type="ui-tiny-bold"
            children={children}
            style={{
              whiteSpace: 'nowrap',
            }}
          />
        ) : (
          <Divflex>
            <Icon
              className={`${styles['icon']} ${
                styles[`${statusColor[typePurchase - 1]}-icon-color`]
              }`}
              icon={
                customIcon
                  ? customIcon
                  : (statusIcon[typePurchase - 1] as IconName)
              }
              backgroundColor={
                defaultStatus ? 'status-disabled-base' : undefined
              }
              customColor={defaultStatus ? 'var(--color-text-200)' : ''}
              style={{
                width: defaultStatus ? 30 : '',
                height: defaultStatus ? 30 : '',
                borderRadius: defaultStatus ? 20 : '',
              }}
            />
            <Text
              type={descriptionType}
              children={children}
              style={{
                whiteSpace: 'nowrap',
              }}
            />
          </Divflex>
        )}
      </AntdButton>
    </>
  );
};
