import { useMemo } from "react";
import { ProductTableCollums } from "../../../../../ProductCard";
import { TotalColumns } from "../../../../../TotalCard/Components/TotalTableColumns";
import { useFormikContext } from "formik";

export const useApproveOrderModalMemorizations = () => {
  const form: any = useFormikContext();

  const hasProducts = 
    form.values.pedidoCompraItensExternalId?.filter((x: any) => !!x).length > 0;
  
  const body = useMemo(() => (
    <>
      <div style={{ padding: 25 }}>
        <ProductTableCollums withSelectedRows />
        <div
          style={{
            height: 1,
            width: '100%',
            border: '1px solid var(--color-background-04)',
            marginBottom: 24,
          }}
        />
        <TotalColumns withSelectedRows />
      </div>
    </>
  ), []);

  return {
    hasProducts,
    body
  };
};