import React, { useEffect, useRef } from 'react';
import { useField } from 'formik';
import { Text } from '../..';
import CurrencyFormat from 'react-currency-format';
import StorageUtils from '../../../Utils/StorageUtils';
import { INumberInput } from '../NumberInput';
import { VisualInput } from './VisualInput';
import { InputRef } from 'antd';
import { formatAsNumber } from '@/Utils/numberUtils';

import styles from './VisualInput/VisualInput.module.scss';

export const PercentageInput = ({
  name,
  prefix,
  onChange,
  onBlur,
  subText,
  dataTestId,
  ...props
}: Omit<
  INumberInput,
  | 'suffix'
  | 'fixedDecimalScale'
  | 'withCurrencyPrefix'
  | 'decimalScale'
  | 'withDecimalSeparator'
  | 'withThousandSeparator'
>) => {
  const ref = useRef<React.Ref<InputRef> | null>();
  const [numberFieldConfig, , numberFieldHelpers] = useField(name);
  const [metadataConfig, metadataProps, metadataHelpers] = useField(
    `metadata.${name}`
  );
  const preference = StorageUtils.getPreference();

  const suffix = '%';
  const DEFAULT_DECIMAL_SCALE = 2;
  let thousandSeparator: string = preference?.idioma === 'pt-BR' ? '.' : ',';
  let decimalSeparator: string = preference?.idioma === 'pt-BR' ? ',' : '.';

  // The main reason of this useEffect is to detect changes in the input value that
  // are out of sync with metadata (means that you changed the input value manualy with 'setFieldValue')
  useEffect(() => {
    const currentFieldValue = numberFieldConfig.value;
    const currentMetadataFloatValue = metadataConfig.value?.floatValue;

    const hasDifference = currentFieldValue !== currentMetadataFloatValue;
    const isStartingNegativeNumber =
      metadataConfig?.value?.formattedValue === '-';
    if (!hasDifference || isStartingNegativeNumber) {
      return;
    }

    if (
      !currentFieldValue &&
      (metadataConfig?.value?.formattedValue !== '' ||
        metadataConfig?.value?.value !== '' ||
        metadataConfig?.value?.floatValue !== undefined)
    ) {
      metadataHelpers.setValue({
        formattedValue: '',
        value: '',
        floatValue: undefined,
      });
      return;
    }

    const strValue = currentFieldValue?.toString();

    const formattedValue = formatAsNumber(
      strValue,
      undefined,
      thousandSeparator,
      decimalSeparator,
      '',
      suffix,
      false
    );
    metadataHelpers.setValue({
      formattedValue,
      value: strValue,
      floatValue: currentFieldValue,
    });
  }, [
    numberFieldConfig,
    metadataConfig,
    metadataHelpers,
    thousandSeparator,
    decimalSeparator,
    suffix,
  ]);

  return (
    <>
      <CurrencyFormat
        decimalScale={DEFAULT_DECIMAL_SCALE}
        thousandSeparator={thousandSeparator}
        decimalSeparator={decimalSeparator}
        fixedDecimalScale={false}
        customInput={VisualInput}
        value={metadataProps?.value?.formattedValue || ''}
        suffix={suffix}
        onBlur={onBlur}
        onChange={(x) => onChange && onChange(x.target.value)}
        onValueChange={(x) => {
          const valueWithoutSuffix = x?.formattedValue?.replace(
            suffix || '',
            ''
          );
          const lastChar = valueWithoutSuffix[valueWithoutSuffix.length - 1];

          const availableChars = [',', '.', '-'];
          if (availableChars.includes(lastChar)) {
            metadataHelpers.setValue(x);
            return x?.formattedValue;
          }

          metadataHelpers.setValue(x);
          return numberFieldHelpers.setValue(
            isNaN(x.floatValue) ? undefined : x.floatValue
          );
        }}
        {...({
          ...props,
          dataTestId,
          currentSuffix: suffix,
          name,
          outsideRef: ref,
          separators: {
            decimalSeparator,
            thousandSeparator,
          },
        } as any)}
      />
      {subText && (
        <Text
          type="ui-tiny-content"
          color="text-300"
          children={subText}
          className={styles['sub-text']}
        />
      )}
    </>
  );
};
