import { QueryFunctionContext } from '@tanstack/react-query';
import {
  ICapsulseColorsList,
  IGetCapsuleColor,
  IListCapsuleColorResponse,
} from '../../Interfaces/response/CapsuleColors/ICapsuleColorsResponse';
import { Http, IQueryListParams } from '../../../Utils/Http';
import {
  ICreateCapsuleColors,
  IDeleteCapsuleColorRequest,
  IEditCapsuleColor,
} from '../../Interfaces/request/CapsuleColors/ICapsuleColorsRequest';
import { IBaseResponse } from '../../Interfaces/response/Base/IBase';

export class CapsulseColorsAPI {
  public static async createCapsuleColors(body: ICreateCapsuleColors) {
    const res = await Http.fetcher('/pharma/producao/v1/CapsulaCor', {
      method: 'POST',
      body: JSON.stringify(body),
    });
    if (!res) return [];
    return res;
  }

  public static async getCapsuleColorsList(
    context: QueryFunctionContext
  ): Promise<ICapsulseColorsList | null> {
    if (!context.queryKey[1]) return null;

    return await Http.fetcher<ICapsulseColorsList>(
      Http.AddQueryParams(
        `/pharma/producao/v1/CapsulaCor/Listar`,
        context.queryKey[1] as IQueryListParams
      )
    );
  }

  public static async getCurrentCapsuleColor(
    context: QueryFunctionContext
  ): Promise<IGetCapsuleColor> {
    return await Http.fetcher<IGetCapsuleColor>(
      `/pharma/producao/v1/CapsulaCor/${context.queryKey[1]}`
    );
  }

  public static async editCapsuleColor(body: IEditCapsuleColor) {
    const res = await Http.fetcher('/pharma/producao/v1/CapsulaCor', {
      method: 'PUT',
      body: JSON.stringify(body),
    });
    if (!res) return [];
    return res;
  }

  public static async deleteCapsuleColors(body: IDeleteCapsuleColorRequest) {
    const res = await Http.fetcher(`/pharma/producao/v1/CapsulaCor`, {
      method: 'DELETE',
      body: JSON.stringify(body),
    });
    if (!res) return [];
    return res;
  }

  public static async isCapsuleColorNameAlreadyRegistred(
    descricao?: string
  ): Promise<IBaseResponse<{}> | null> {
    if (!descricao) return null;
    return await Http.fetcher(
      '/pharma/producao/v1/CapsulaCor/ValidarDescricao',
      {
        method: 'POST',
        body: JSON.stringify({ descricao }),
      }
    );
  }

  public static async listDropdownCapsuleColor(
    context: QueryFunctionContext
  ): Promise<IListCapsuleColorResponse | null> {
    if (!context.queryKey[1]) return null;

    return await Http.fetcher<IListCapsuleColorResponse>(
      Http.AddQueryParams(
        `/pharma/producao/v1/CapsulaCor/Listar`,
        context.queryKey[1] as IQueryListParams
      )
    );
  }
}
