import { FC } from "react";
import { useTranslation } from 'react-i18next';
import {
  InputWithSearch
} from '@/Components';
import { usePharmaceuticalFormCallAPI } from "./Hooks/usePharmaceuticalFormCallAPI";
import { usePharmaceuticalFormMemorizations } from "./Hooks/usePharmaceuticalFormMemorizations";
import { usePharmaceuticalFormFunctions } from "./Hooks/usePharmaceuticalFormFunctions";
import { IPharmaceuticalForm } from "./interfaces";

export const PharmaceuticalForm: FC<IPharmaceuticalForm> = ({
  setPharmaceuticalFormSelected
}) => {
  const { t } = useTranslation();

  const {
    listPharmaceuticalForm,
    isPharmaceuticalFormLoading,
    fetchNewFarmaceuticalFormPage,
    refetchSearchPharmaceuticalFormPage
  } = usePharmaceuticalFormCallAPI();

  const {
    items
  } = usePharmaceuticalFormMemorizations({
    listPharmaceuticalForm
  });

  const {
    onSearch,
    onChange
  } = usePharmaceuticalFormFunctions({
    refetchSearchPharmaceuticalFormPage,
    setPharmaceuticalFormSelected
  });

  return (
    <>
      <InputWithSearch
        items={items}
        name="formaFarmaceuticaExternalId"
        label={t('production.standardFormula.create.pharmaceuticalForm')}
        placeHolder={t(
          'production.standardFormula.create.pharmaceuticalFormPlaceholder'
        )}
        isLoading={isPharmaceuticalFormLoading}
        required
        onScrollEnd={fetchNewFarmaceuticalFormPage}
        onSearch={onSearch}
        onChange={onChange}
      />
    </>
  );
};