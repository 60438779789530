import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Modal } from '../../../../../../../../Components/Modal';
import { Divflex } from '../../../../../../../../Layouts/DivWhithFlex';
import { Text } from '../../../../../../../../Components';
import { FooterThisActionIsPermanent } from '../../../../../../../../Components/Table/components/Modals/DeleteModal';
import { IGetProductMessage } from '@/Data/Interfaces/response/ProductMensage/IProductMensageResponse';
import { useEditCallAPI } from '../../Hooks/useEditCallAPI';
import { useEditFunctions } from './Hooks/useEditFunctions';

interface IDeleteProductMessageModal {
  productMessage: IGetProductMessage;
  visibleModal: boolean;
  changeVisibleModal: (value: boolean) => void;
}

export const DeleteProductMessageModal: FC<IDeleteProductMessageModal> = ({
  productMessage,
  visibleModal,
  changeVisibleModal,
}) => {
  const { t } = useTranslation();

  const { deleteProductMessage, isLoadingDeleteProductMessage } =
    useEditCallAPI({ t });

  const { onDelete } = useEditFunctions({
    productMessage,
    deleteProductMessage,
    changeVisibleModal,
  });

  return (
    <Modal
      title={`${t('supply.productMessage.details.deleteTitle')} 
      ${t('supply.productMessage.details.deleteSingleTitle')}?`}
      body={
        <div style={{ padding: '20px' }}>
          <Divflex
            style={{
              flexDirection: 'column',
              marginBottom: 10,
              width: '400px',
            }}
          >
            <Text
              color="text-50"
              type="ui-tiny-content"
              children={`${t('common.deleteProductMessageList')} `}
            />

            <Divflex>
              <Text
                color="text-50"
                type="ui-tiny-bold"
                children={` "${productMessage?.descricao}" `}
              />

              <Text
                color="text-50"
                type="ui-tiny-content"
                children={`${'?'} `}
              />
            </Divflex>
          </Divflex>
          <FooterThisActionIsPermanent />
        </div>
      }
      okButtonColor="status-danger-base"
      visible={visibleModal}
      onOkClick={onDelete}
      okButtonName={
        isLoadingDeleteProductMessage
          ? t('common.deleting')
          : t('common.delete')
      }
      loading={isLoadingDeleteProductMessage}
      onCancelClick={() => changeVisibleModal(false)}
      onClose={() => changeVisibleModal(false)}
      widthModal={400}
      okButtonType="danger"
    />
  );
};
