import { Http, IQueryListParams } from '@/Utils/Http';
import { QueryFunctionContext } from '@tanstack/react-query';

export interface IPurchaseOrderOpenList {
  pageIndex: number;
  pageSize: number;
  count: number;
  data: IPurchaseOrderOpenGetResponseData[];
}

export interface IPurchaseOrderOpenGetResponseData {
  externalId: string;
  fornecedorExternalId: string;
  nomeFornecedor: string;
  sequenciaGroupTenant: number;
  dataLancamento: string;
  previsaoEntrega: string;
  totalPedido: number;
  itens: {
    pedidoCompraExternalId: string;
    descricao: string;
    quantidade: number;
    descricaoUnidadeMedida: string;
  }[];
}

export const GetOpenPurchaseOrderList = async (
  context: QueryFunctionContext
) => {
  const checkContext: any = context.queryKey[1];
  // if (!checkContext.filter) return [];
  // if (!checkContext.filter[0].value) return [];
  return await Http.fetcher<IPurchaseOrderOpenList>(
    Http.AddQueryParams(
      '/pharma/compras/v1/PedidoCompra/ListarEmAberto',
      context.queryKey[1] as IQueryListParams
    )
  );
};
