import { history } from '@/Store';
import { useQueryClient } from '@tanstack/react-query';
import { useControllerMutateApiHook } from '@/ControllerApiHook/Controller';
import {
  CREATE_TRANSFER,
  FETCH_GET_TRANSFER,
  FETCH_LIST_TRANSFER,
} from '@/ControllerApiHook/UniqIds/Supply/TransferKeys';
import { TransferAPI } from '@/Data/API/Supply/TransferApi';

export const useCreateCallAPI = () => {
  const queryClient = useQueryClient();

  const {
    mutateAsync: createTransfer,
    isLoading: isLoadingCreateTransfer,
  }: any = useControllerMutateApiHook({
    uniqId: CREATE_TRANSFER,
    entityApi: TransferAPI.createTransfer,
    options: {
      onSuccess: () => {
        queryClient.invalidateQueries([FETCH_LIST_TRANSFER]);
        queryClient.invalidateQueries([FETCH_GET_TRANSFER]);
        history.push('/inventory/transfers');
      },
    },
  });

  return {
    createTransfer,
    isLoadingCreateTransfer,
  };
};
