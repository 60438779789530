import { IEditLaboratory } from '@/Data/Interfaces/request/Laboratory/ILaboratoryRequest';
import { IUseFunctions } from './interfaces';
import { FormikHelpers, FormikValues } from 'formik';

export const useEditFunctions = ({
  editLaboratory,
  laboratory,
}: IUseFunctions) => {
  const onSubmit = async (
    values: IEditLaboratory,
    helpers: FormikHelpers<FormikValues>
  ) => {
    var body: IEditLaboratory = { ...values, helpers };
    if (editLaboratory) {
      return await editLaboratory({
        laboratorioExternalId: laboratory?.externalId,
        nome: body.nome,
        empresaExternalId: body.empresaExternalId,
        localEstoqueExternalId: body.localEstoqueExternalId,
        helpers,
      });
    }
  };

  return { onSubmit };
};
