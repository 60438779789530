import { AuthAPI } from '@/Data/API/Identity/AuthApi';
import { useControllerMutateApiHook } from '@/ControllerApiHook/Controller';
import { RECOVERY_PASSWORD } from '@/ControllerApiHook/UniqIds/Identity/AuthHooks';
import { history } from '@/Store';

export const useRequestCallAPI = (email: string) => {
  const recoveryPassword = useControllerMutateApiHook({
    uniqId: RECOVERY_PASSWORD,
    entityApi: AuthAPI.recovery,
    options: {
      onSuccess: () => history.push('/forgot-password/success', { email }),
    },
  });

  return {
    recoveryPassword,
  };
};
