import { useState } from 'react';
import { IProductGroupListData } from '@/Data/Interfaces/response/Product/IProductRespose';

export const useListStates = () => {
  const [selectedRowsOnTable, setSelectedRowsOnTable] = useState<
    IProductGroupListData[]
  >([]);

  const [visibleModal, setVisibleModal] = useState(false);

  const [visibleEditModal, setVisibleEditModal] = useState(false);

  return {
    selectedRowsOnTable,
    setSelectedRowsOnTable,
    visibleModal,
    setVisibleModal,
    visibleEditModal,
    setVisibleEditModal,
  };
};
