import * as yup from 'yup'
import { i18n } from '../../../../Components'

export const SecuritySchema = yup.object().shape({
	oldPassword: yup.string()
	.required(i18n.t('accountManager.accountSecurityPage.errors.invalidPassword')),
	newPassword: yup.string()
	.required(i18n.t('accountManager.accountSecurityPage.errors.invalidPassword')),
	confirmPassword: yup.string()
		.oneOf([yup.ref('newPassword')], i18n.t('accountManager.accountSecurityPage.errors.samePassword'))
		.required(i18n.t('accountManager.accountSecurityPage.errors.requiredPassword')),
})
