import { useControllerMutateApiHook } from '@/ControllerApiHook/Controller';
import { ProductAPI } from '@/Data/API/Supply/Product';
import {
  DELETE_SUBGROUP
} from '@/ControllerApiHook/UniqIds/Supply/ProductKeys';

export const useDeleteGroupModalCallAPI = () => {
  const deleteSubGroup: any = useControllerMutateApiHook({
    uniqId: DELETE_SUBGROUP,
    entityApi: ProductAPI.deleteProductSubGroup
  });

  return { deleteSubGroup };
};