import { ICreatePrescriberRequest } from '@/Data/Interfaces/request/Prescribers/IPrescribersRequest';
import { useCallback } from 'react';
import DateUtils from '@/Utils/DateUtils';
import { IUseFunctions } from './interfaces';
import { history } from '@/Store';

export const useCreateFunctions = ({
  preferences,
  createPrescriber,
  setIsBackModalOpen,
}: IUseFunctions) => {
  const onSubmit = useCallback(
    (values: ICreatePrescriberRequest) => {
      var birthdayFormated;
      if (values.dataNascimento && preferences)
        birthdayFormated = DateUtils.convertDateToApi(
          values.dataNascimento,
          preferences.padraoData
        );
      createPrescriber({ ...values, dataNascimento: birthdayFormated });
    },
    [preferences, createPrescriber]
  );

  const onOkClick = useCallback(() => history.push('/inventory/products'), []);

  const onCancelClick = useCallback(
    () => setIsBackModalOpen(false),
    [setIsBackModalOpen]
  );

  const onClose = useCallback(
    () => setIsBackModalOpen(false),
    [setIsBackModalOpen]
  );

  return {
    onSubmit,
    onOkClick,
    onCancelClick,
    onClose,
  };
};
