import { useTranslation } from "react-i18next";
import { alternativeUnit2 } from '../../../utils';
import { IUseMemorization } from "./interfaces";

export const useAlternativeUnit2Memorizations = ({
  unidadeAlternativaList
}: IUseMemorization) => {
  const { t } = useTranslation();
  const utrUnitDefault: number = 9;
  const ufcMiUnitDefault: number = 14;

  const label = [
    {
      children: t('supply.lot.create.alternativeUnitTwo'),
    },
  ];

  const dropdownLeft = {
    name: 'loteInformacaoTecnica.loteUnidadeAlternativa2.unidadeAlternativaId',
    defaultValue: utrUnitDefault,
    options:
      unidadeAlternativaList?.data.map((x) => ({
        id: x.id,
        content: x.unidadeAbreviacao,
      })) || [],
  };
  
  const dropdownRight = {
    name: 'loteInformacaoTecnica.loteUnidadeAlternativa2.unidadeAlternativaConversaoId',
    defaultValue: ufcMiUnitDefault,
    options:
      alternativeUnit2?.map((x) => ({
        id: x.id,
        content: x.description,
      })) || [],
  };

  return {
    label,
    dropdownLeft,
    dropdownRight
  };
};