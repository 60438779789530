import { useFormikContext } from 'formik';

export const useSupplierDilutionFactorFunctions = () => {
  const form: any = useFormikContext();

  const onChange = (value: string | number) => {
    const stringValue = String(value);

    const supplierDilutionFactor = +stringValue.replace(',', '.');

    if (
      isNaN(supplierDilutionFactor) ||
      !stringValue ||
      supplierDilutionFactor === 0
    ) {
      return form.setFieldValue('loteInformacaoTecnica.diluicaoFornecedor', '');
    }

    const supplierDilution = 100 / supplierDilutionFactor;

    form.setFieldValue(
      'loteInformacaoTecnica.diluicaoFornecedor',
      supplierDilution
    );
  };

  return { onChange };
};
