import { FC } from "react";
import { InputLabel } from '@/Components/Inputs/LabelTotal';
import { currencyFormater } from '@/Utils/Currency';
import { 
TypePurchaseOrderEnum 
} from '@/Data/Interfaces/model/typePurchaseOrderEnum';
import { useTranslation } from "react-i18next";
import { useFormikContext } from "formik";

export const FullDiscount: FC = () => {
  const { t } = useTranslation();
  const form: any = useFormikContext();

  return (
    <>
      <InputLabel
        labelTitle={t(
          'purchasing.purchaseOrder.create.totalCard.fullDiscount'
        )}
        labelValue={currencyFormater(form?.values?.valorDesconto)}
        type="ui-tiny-content"
        customSize={14}
        style={
          form.values.status === TypePurchaseOrderEnum.canceled ||
          form.values.status === TypePurchaseOrderEnum.disapproved
            ? { textDecorationLine: 'line-through' }
            : {}
        }
      />
    </>
  );
};