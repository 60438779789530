import { FC } from "react";
import { TextInput } from '@/Components';
import { Col } from 'antd';
import { useTranslation } from "react-i18next";
import { ClientType } from '../../../Utils';
import { IFantasyName } from "./interfaces";

export const FantasyName: FC<IFantasyName> = ({ 
  createClientModalI18n,
  clientType
 }) => {
  const { t } = useTranslation();

  return (
    <Col flex="60%">
      <TextInput
        name="nome"
        label={t(
          `${createClientModalI18n}.${
            clientType === ClientType.cnpj ? 'fantasyName' : 'fullName'
          }`
        )}
        required
      />
    </Col>
  );
};