import {
  useControllerMutateApiHook,
  useControllerQueryListApiHook,
} from '@/ControllerApiHook/Controller';
import { IUseCallAPI } from './interfaces';
import { THERE_IS_BIND_PACKING_CLASSIFICATION_TO_PHARMACEUTICAL_FORM } from '@/ControllerApiHook/UniqIds/Production/PackingClassificationLinkedPharmaceuticalFormKeys';
import { PackingClassificationLinkedPharmaceuticalFormAPI } from '@/Data/API/Production/PackingClassificationPharmaceuticalForm';
import { FETCH_LIST_PACKING_CLASSIFICATION_FOR_DROPDOWN_TYPE } from '@/ControllerApiHook/UniqIds/Production/PackingClassificationKeys';
import { PharmaceuticalFormAPI } from '@/Data/API/Production/PharmaceuticalForm';
import { useEffect } from 'react';

export const useEditCallAPI = ({ t, index, form, externalId }: IUseCallAPI) => {
  const { mutateAsync } = useControllerMutateApiHook({
    uniqId: THERE_IS_BIND_PACKING_CLASSIFICATION_TO_PHARMACEUTICAL_FORM,
    entityApi:
      PackingClassificationLinkedPharmaceuticalFormAPI.pharmaceuticalFormBindPackingClassification,
    options: {
      onError: () => {
        form?.setFieldError(
          `embalagemClassificacaoExternalId.${index}`,
          'Error'
        );
      },
      onSuccess: () => {
        form?.setFieldError(
          `embalagemClassificacaoExternalId.${index}`,
          undefined
        );
      },
    },
  });

  const {
    data: packingClassification,
    refetch: refetchPackingClassification,
    fetchNewPage: fetchNewPagepackingClassification,
    isLoading: isLoadingPackingClassification,
  } = useControllerQueryListApiHook({
    uniqId: FETCH_LIST_PACKING_CLASSIFICATION_FOR_DROPDOWN_TYPE,
    entityApi: PharmaceuticalFormAPI.getListPackagingClassification,
    autoIncrement: true,
    pagination: {
      sorter: { column: 'embalagemClassificacaoDescricao', direction: 'ASC' },
      filter: [],
    },
    autoIncrementCustomId: 'embalagemClassificacaoExternalId',
  });

  useEffect(() => {
    refetchPackingClassification({
      filter: [{ filterName: 'externalId', value: externalId }],
    });
  }, [externalId, refetchPackingClassification]);

  return {
    mutateAsync,
    packingClassification,
    refetchPackingClassification,
    fetchNewPagepackingClassification,
    isLoadingPackingClassification,
  };
};
