import React, { FC, useMemo } from 'react';
import { useFormikContext } from 'formik';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { TextInput } from '../..';
import { emailIsValid } from '../../../Utils/ErrorCodeUtils/ErrorHandlingUtils';
import { IInputLabel } from '../Dropdown';
import { emailAPI } from '@/Data/API/Identity/EmailApi';
import { THERE_IS_EMAIL_TYPE } from '@/ControllerApiHook/UniqIds/Identity/EmailHooks';
import { useControllerMutateApiHook } from '@/ControllerApiHook/Controller';

interface ITextInputEmailAlreadyExists {
  name: string;
  label: string | IInputLabel[];
  placeholder: string;
  required?: boolean;
  dataTestId?: string;
}

export const TextInputEmailAlreadyExists: FC<ITextInputEmailAlreadyExists> = ({
  name,
  label,
  placeholder,
  required,
  dataTestId,
}) => {
  const { t } = useTranslation();

  const form = useFormikContext();

  const { mutateAsync: getThereIsEmail } = useControllerMutateApiHook({
    uniqId: THERE_IS_EMAIL_TYPE,
    entityApi: emailAPI.isEmailAlreadyRegistred,
    options: {
      onError: () =>
        form.setFieldError('email', t('common.error.emailAlreadyRegistred')),
      onSuccess: () => form.setFieldError('email', undefined),
    },
  });

  const thereIsEmailName = useMemo(
    () =>
      _.debounce(async (value: string) => await getThereIsEmail(value), 600),
    [getThereIsEmail]
  );

  return (
    <TextInput
      name={name}
      label={label}
      dataTestId={dataTestId}
      placeHolder={placeholder}
      required={required}
      onChange={(x) => emailIsValid(x) && thereIsEmailName(x)}
      onBlur={(x) =>
        !emailIsValid(x) &&
        form.setFieldError('email', t('common.error.invalidEmail'))
      }
    />
  );
};
